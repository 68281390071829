export const reg = {
    // 星号替换
    asteriskReplace: new RegExp(/(.{3}).*(.{4})/),
    // 校验邮件
    email: new RegExp(/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/),
    // 校验密码
    password: new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)(?![_]+$)[0-9A-Za-z_]{6,25}$/),
    // 校验手机号
    phone: new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/),
    // 支付宝账号校验
    alipayAccountRule: new RegExp(/^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/),
    //银行卡校验
    bankRule: new RegExp(/^[0-9]{16,19}$/),
    // 校验金额
    checkMoney: new RegExp(/^[-+]?(([0-9]+)([.]([0-9]+))?|([.]([0-9]+))?)$/),
    // 校验特殊字符
    specialCharacters: new RegExp("[`~!@$^&*()=|{}':;',\\[\\].<>/?~！_@#￥……&*（）——|{}【】‘；：”“'。，、？%+-]"),
    // 校验纯数字
    pureNumbers: new RegExp(/[^\d]/g),
    // 校验特殊字符
    specialImgCharacters: new RegExp(/^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z0-9_-]|[()（ ）]){0,}$/),
};