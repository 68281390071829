/**
 * 边缘切割
 */
function edgeCutting(stage, controller) {
    const condition = {
        getStatus: () => {
            return this.toolsType === 'edge'
        }
    }
    stage.event.click(condition, ({ shape }) => {
        const chromo = controller.getChromoByShape(shape);
        if (chromo) {
            // console.log(this.choosedChromo)
            this.edgeCuttingVisible = true;
        }
    })
}
export default edgeCutting