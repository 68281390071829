/*
 * @Author: your name
 * @Date: 2020-12-24 15:05:10
 * @LastEditTime: 2021-03-12 11:05:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \autovision-vue\src\components\selfDefineTemplate\config\utils.js
 */
const utils = {
    /**
     * @method: 参数
     * @Date: 2021-02-04 13:56:00
     */
    paramter() {
        const configObj = {
            draggable: true, //是否可拖拽
            resizable: true, //是否可调整大小
            axis: "both", //轴线
            snapToGrid: false, //拖动对齐
            aspectRatio: false, //方向比例
            zIndex: 1, //层级
            active: false, //是否获取焦点
            showLabelLine: true,
            showDashedLine: false,
            isRequired: false,
            format: "yyyy-MM-dd",
            datatype: 'date'
            // checked: false,
        };
        const styles = {
            width: 180, //宽度
            height: 24, //高度
            lineHeight: 24, //行高
            fontSize: 14, //字体大小
            fontWeight: 400, //字体加粗
            fontFamily: 'SimSun',
            top: 20, //距顶
            left: 0, //距左
            border: "",
            color: "#333",
        };
        return {
            configObj,
            styles
        };
    },
    updateControlStyle(childNodes, range, startOffset, endOffset, fontSize, fontWeight, red) {
        let style = "font-size: " + fontSize + "px;color: " + red + ";font-weight: " + fontWeight + ";";
        if (range.startContainer == range.endContainer) {
            for (let idx in childNodes) {
                if (
                    childNodes[idx] == range.startContainer ||
                    childNodes[idx] == range.startContainer.parentNode
                ) {
                    let nodeText = childNodes[idx].textContent;
                    let prefix = nodeText.substring(0, startOffset);
                    let middle =
                        '<span style="' +
                        style +
                        '">' +
                        nodeText.substring(startOffset, endOffset) +
                        "</span>";
                    let suffix = nodeText.substring(
                        endOffset,
                        nodeText.length
                    );
                    $(childNodes[idx]).replaceWith(
                        prefix + middle + suffix
                    );
                }
            }
        } else {
            let isStart = false;
            let repalce_span = "";
            for (let idx in childNodes) {
                if (
                    childNodes[idx] == range.startContainer ||
                    childNodes[idx] == range.startContainer.parentNode
                ) {
                    isStart = true;
                    let nodeText = childNodes[idx].textContent;
                    let prefix = nodeText.substring(0, startOffset);
                    let middle =
                        '<span style="' +
                        style +
                        '">' +
                        nodeText.substring(startOffset, nodeText.length);
                    repalce_span = prefix + suffix;
                    childNodes[idx].replaceWith("");
                } else if (
                    childNodes[idx] == range.endContainer ||
                    childNodes[idx] == range.endContainer.parentNode
                ) {
                    isStart = false;
                    let nodeText = childNodes[idx].textContent;
                    let prefix =
                        nodeText.substring(0, endOffset) + "</span>";
                    let suffix = nodeText.substring(
                        endOffset,
                        nodeText.length
                    );
                    repalce_span += prefix + suffix;
                    $(childNodes[idx]).replaceWith(repalce_span);
                    break;
                } else {
                    if (isStart) {
                        repalce_span += childNodes[idx].textContent;
                        childNodes[idx].replaceWith("");
                    }
                }
            }
        }
    },
    /**
     * @method:深拷贝
     * @Date: 2021-01-26 18:06:51
     * @param {*} target
     * @param {*} origin
     */
    // deepClone(target, origin) {
    //     let tar = target || {},
    //         toStr = Object.prototype.toString,
    //         arrType = "[object Array]";
    //     for (let key in origin) {
    //         if (origin.hasOwnProperty(key)) {
    //             if (
    //                 typeof origin[key] === "object" &&
    //                 origin[key] !== null
    //             ) {
    //                 toStr.call(origin[key]) === arrType ?
    //                     (target[key] = []) :
    //                     (target[key] = {});
    //                 this.deepClone(target[key], origin[key]);
    //             } else {
    //                 tar[key] = origin[key];
    //             }
    //         }
    //     }
    //     return tar;
    // },

    deepCopy(target) {
        if (typeof target == 'object') {
            const result = Array.isArray(target) ? [] : {}
            for (const key in target) {
                if (typeof target[key] == 'object') {
                    result[key] = this.deepCopy(target[key])
                } else {
                    result[key] = target[key]
                }
            }
            return result
        }
        return target
    },
    /**
     * @method: 获取字符串的长度
     * @Date: 2020-12-22 10:11:46
     */
    textWidth(text, rect) {
        if (!text) {
            text = "标签";
        }
        let sensor = $("<pre>" + text.replace(/\s+/g, "") + "</pre>").css({
            display: "none",
            fontFamily: rect.styles.fontFamily,
            fontSize: rect.styles.fontSize,
            fontWeight: rect.styles.fontWeight
        });
        $("body").append(sensor);
        let width = sensor.width();
        let height = sensor.height();
        let obj = {
            width: width,
            height: height
        }
        sensor.remove();
        return obj;
    },
    /**
     * @method: 生成随机数id
     * @Date: 2020-12-22 10:11:46
     */
    GenNonDuplicateID() {
        return Number(
            Math.random().toString().substr(3, 3) + Date.now()
        ).toString(36);
    },
    /**
     * @method: base64转图片
     * @Date: 2020-12-22 14:55:20
     */
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {
            type: mime
        });
    },

    /**
     * @method: 返回最大值
     * @Date: 2021-01-13 13:00:00
     */
    getMax(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i] > array[i + 1]) {
                let temp = array[i];
                array[i] = array[i + 1];
                array[i + 1] = temp;
            }
        }
        return array[i - 1];
    },
    generateID() {
        return id++
    }
}

export default utils