/*
 * @Author: your name
 * @Date: 2021-03-03 17:49:11
 * @LastEditTime: 2021-03-04 13:38:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \autovision-vue\src\assets\js\config.js
 */
import RELEASE_ENV from "./release.js";
console.log(RELEASE_ENV)
const config = {
    dev: {
        apiHost: "https://aiapi.autovision.online/",
        ossUrl: "https://autovision-pre-new.oss-cn-hangzhou.aliyuncs.com",
        cdnUrl: "https://prepic.autovision.online"
    },
    test: {
        apiHost: "http://test.api.autovision.online",
        ossUrl: "https://autovision-test.oss-cn-hangzhou.aliyuncs.com",
        cdnUrl: "https://devpic.autovision.online"
    },
    pre: {
        apiHost: "https:/aiapi.autovision.online/",
        ossUrl: "https://autovision-pre-new.oss-cn-hangzhou.aliyuncs.com",
        cdnUrl: "https://prepic.autovision.online"
    },
    pro: {
        apiHost: "https://api.autovision.online",
        ossUrl: "https://autovision.oss-cn-hangzhou.aliyuncs.com",
        cdnUrl: "https://pic.autovision.online"
    }
};
export default config[RELEASE_ENV];
