<template>
	<el-dialog id="contrast" class="contrast" width="1180px" :title="title" :visible.sync="visible" :before-close="contrastClose">
		<div class="contrast-header">
			<div class="contrast-number" v-for="(item,index) in chromos" :key="item.id">
				<div v-for="(tab, i) in tabsValid" :key="i" :class="[
					'contrast-chromo-num',
					{
						current: item.chromoId == tab.id,
						mar: tab.id ===25,
						disabled: !tab.valid || (chromos[index==0 ? 1 : 0] && chromos[index==0 ? 1 : 0].chromoId == tab.id)
					}
				]" @click="changeContrast(index,tab.id)">
					<span v-if="tab.id < 23">{{tab.id}}</span>
					<span v-if="tab.id == 23">X</span>
					<span v-if="tab.id == 24">Y</span>
					<span v-if="tab.id == 25">MAR</span>
				</div>
			</div>
		</div>
		<div :class="['contrast-wrap',{diff:chromo_list.length===2}]">
			<div class="contrast-container">
				<div class="contrast-main" v-for="item in chromo_list" :key="item.key" :class="['scale-'+scale]">
					<ul class="contrast-row" v-for="(chromos,index) in item.list" :key="index">
						<li class="contrast-item">
							<div :class="['contrast-standard']">
								<!-- <div :class="['standard_'+ item.key]"></div> -->
								<img v-if="item.key<23" :src="`https://cdn.autovision.online/standard/320/Chrom${ item.key > 9 ? item.key : ('0' + item.key) }ISCN09.jpg?v=20200410`" />
								<img v-if="item.key==23" :src="`https://cdn.autovision.online/standard/320/ChromXISCN09.jpg?v=20200410`" />
								<img v-if="item.key==24" :src="`https://cdn.autovision.online/standard/320/ChromYISCN09.jpg?v=20200410`" />
							</div>
							<div class="title" v-if="item.key < 23">{{item.key}}{{$t('unit.num')}}</div>
							<div class="title" v-if="item.key == 23">X{{$t('unit.num')}}</div>
							<div class="title" v-if="item.key == 24">Y{{$t('unit.num')}}</div>
							<div class="title" v-if="item.key == 25">MAR</div>
						</li>
						<li class="contrast-item" v-for="(chromo,$index) in chromos" :key="$index" @dblclick="changeKaryo(chromo.karyoId)">
							<div :class="[
								'contrast-chromo',
								{currentKaryo: chromo.karyoId == karyoId,
								hasCheckPL: chromo.isTypeSure && chromo.karyoId != karyoId,
								hasCheckCL: !chromo.isTypeSure && chromo.karyoId != karyoId,
								noborder: !chromo.justUrl}
							]">
								<img v-if="chromo.justUrl" :src="chromo.justUrl" :class="chromo.classname" @load="chromoImageLoaded(chromo)" />
							</div>
							<div class="title" v-if="chromo.index || chromo.index==0" :title="chromo.glassName + '/' + chromo.cellName" >{{chromo.glassName}}/{{chromo.cellName}}</div>
						</li>
						<li class="contrast-item" v-for="n in (scale - chromos.length)" :key="'n_' + n"></li>
					</ul>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
export default {
	name: 'contrast',
	props:{
		visible : Boolean,
		type : {
			type : String,
			value: 'single'
		},
		chromoList : Array,
		chromos : Array,
		patientId : Number,
		karyoId : Number
	},
	data(){
		return {
			title : '',	//标题
			dataCache : {},	//数据缓存
			chromo_list : [],	//染色体列表
			SAME_MAX_SCALE : 12,	//同号染色体对比最大缩放个数
			SAME_MIN_SCALE : 6,	//同号染色体对比最小缩放个数
			sameScale : 6,	//同号对比当前缩放个数
			DIFF_MAX_SCALE : 6,	//双号对比最大个数
			DIFF_MIN_SCALE : 2,	//双号对比最小个数
			diffScale : 2,	//双号对比当前个数
			scale : 6,	
			ids : [],
			tabsValid : [],	//有效tab
			tabmap : {},
		}
	},
	created() {
		let count = 0;
		this.wheelhandle = (e) => {
			if(e.ctrlKey && this.visible){
				e.preventDefault();
				const isContainer = e.path.some(ele=>{
					return ele.className.split(/\s+/g).indexOf("contrast-wrap") > -1;
				});
				// console.log(e.path,this.type);
				if(isContainer){
					count++;
					if(count==2){
						count = 0;
						const step = e.deltaY > 0 ? 2 : -2;
						if(this.type === 'signle'){
							this.sameScale = Math.min(this.SAME_MAX_SCALE,Math.max(this.SAME_MIN_SCALE, this.sameScale + step))
						}else{
							this.diffScale = Math.min(this.DIFF_MAX_SCALE,Math.max(this.DIFF_MIN_SCALE, this.diffScale + step))
						}
						// console.log(this.type);
						this.processDataByScale();
					}
				}
			}
		}
		window.addEventListener('mousewheel', this.wheelhandle, {
			passive: false
		})
	},
	beforeDestroy(){
		window.removeEventListener('mousewheel', this.wheelhandle, {
			passive: false
		})
	},
	methods:{
		changeKaryo(karyoId){
			if(this.karyoId === karyoId){
				return;
			}
			this.$parent.switchKaryoHandle(karyoId);
			this.contrastClose();
		},
		/**
		 * 关闭弹窗
		 */
		contrastClose(){
			this.$emit("closeContrast")
		},
		
		/**
		 * 获取弹窗title
		 */
		getTitle(){
			this.title = [
				this.chromos.length === 1 ? this.$t('case_analysis.single') : this.$t('case_analysis.double'),
                this.$t('case_analysis.num_karyo_compare'),
				" ",
				this.chromos.map(a=>a.chromoId+this.$t('case_analysis.num_karyo')).join("/")
			].join("")
		},
		
		/**
		 * 获取需要对比的染色体列表
		 */
		getListWithNumber(){
			const param = {
				ids : this.chromos.map(a=>a.id+"-"+a.chromoId).join(","),
				patientId : this.patientId,
				karyoId : this.karyoId
			}
			this.$api.withNumber(param).then(res=>{
				// console.log(res);
				if(res.code == 200){
					this.dataCache = res.data;
					this.processDataByScale();
				}else{
					this.$message({
						$message: res.message,
						type : 'warning'
					})
				}
			})
		},
		
		/**
		 * 缩放后 重新排列染色体dom列表
		 */
		processDataByScale(){
			this.scale = this.type === 'signle' ? this.sameScale : this.diffScale;
			const scale = this.scale;
			let total = 0, count = 0;
			const chromo_list = Object.keys(this.dataCache).map(key=>{
				let list = this.dataCache[key];
				let current = this.chromoList.filter(item=>item.chromoId == key).map(a=>{
					return {
						cellName : a.cellName,
						chromoId : a.chromoId,
						glassId : a.glassId,
						glassName : a.glassName || this.$parent.karyoList.filter(a=>a.id === this.karyoId)[0].glassName,
						id : a.id,
						imgHandledRotatedDegree : a.imgHandledRotatedDegree,
						index : a.index,
						isSingleSure : a.isSingleSure,
						isTypeSure : a.isTypeSure,
						justUrl : a.justUrl,
						karyoId : a.karyoId,
						patientId : a.patientId
					}
				});
				list = current.concat(list);
				const map = {};
				total += list.length;
				list.forEach((item,i)=>{
					const name = item.cellName;
					if(!map[name]){
						map[name] = [];
					}
					map[name].push(item)
					const img = new Image();
					img.onload = ()=>{
						const rate = img.width / img.height;
						// item.vertical = rate > 0.6;
						this.$set(item,"classname",rate > 0.6 ? 'horizontal' : 'vertical')
						count++;
						if(count>=total){
							this.$forceUpdate()
						}
					}
					img.src = this.$parent.getBase64FromEntries(item.karyoId,item.justUrl);
				})
				let arr = [], result = [];
				Object.keys(map).forEach(name=>{
					arr = arr.concat(map[name])
					if(map[name].length % 2 === 1){
						arr.push({})
					}
				})
				
				for(let i = 0; i < Math.ceil(arr.length / scale); i++){
					const _arr = arr.slice(i*scale, i*scale+scale);
					result.push([..._arr]);
				}
				return {
					key : key,
					list : result
				}
			})
			this.chromo_list = [];
			this.chromos.forEach(({chromoId})=>{
				const item = chromo_list.filter(item=>{
					return item.key == chromoId;
				})[0];
				this.chromo_list.push(item);
			})
			// console.log(this.chromo_list);
		},
		
		/**
		 * 切换染色体编号
		 * @param {Object} index
		 * @param {Object} num
		 */
		changeContrast(index, num){
			if(!this.tabmap[num].valid){
				return;
			}
			const isSingle = this.chromos.length == 1;
			let data;
			if(isSingle && index == 0){
				data = this.chromos[0];
			}
			if(!isSingle && index == 0){
				data = this.chromos[1];
			}
			if(!isSingle && index == 1){
				data = this.chromos[0];
			}
			if(data.chromoId == num){
				return;
			}
			console.log(index,num,data.chromoId,data.chromoId == num);
			this.chromos[index] = this.chromoList.filter(a=>a.chromoId==num)[0];
			this.$forceUpdate();
			this.getListWithNumber();
			this.getTitle();
		},
		
		chromoImageLoaded(e){
			// console.log(arguments);
		}
	},
	watch:{
		visible(nv, ov){
			if(nv){
				this.getTitle();
				this.tabmap = {};
				this.chromoList.forEach(chromo=>{
					if(!this.tabmap[chromo.chromoId] && chromo.chromoId > 0 && chromo.chromoId < 26){
						this.tabmap[chromo.chromoId] = {
							id : chromo.chromoId,
							valid : true
						}
					}
				})
				for(var i = 23; i < 26; i++){
					if(!this.tabmap[i]){
						this.tabmap[i] = {
							id : i,
							valid : false
						}
					}
				}
				this.tabsValid = Object.keys(this.tabmap).map(key=>{
					return this.tabmap[key];
				})
				this.getListWithNumber();
			}
		}
	}
}
</script>
<style lang="scss">
.contrast{
	.el-dialog__header{
		.el-dialog__headerbtn{
			// transform: translateY(0) !important;
		}
	}
}
</style>
<style lang="scss" scoped>
.contrast{
	
	.contrast-header{
		text-align: center;
		overflow: hidden;
	}
	.contrast-number{
		display: inline-block;
		margin: 10px auto;
		overflow: hidden;
		&:first-child{
			margin: 0 auto;
		}
		.contrast-chromo-num{
			width: 24px;
			height: 24px;
			border-radius: 2px;
			margin: 0 4px;
			text-align: center;
			line-height: 24px;
			border: 1px solid #E3E3E3;
			color: #666666;
			float: left;
			cursor: pointer;
			&.current{
				border: 1px solid #2D86EF;
				background-color: #2D86EF;
				color: #FFFFFF;
			}
			&:hover{
				border: 1px solid #2D86EF;
				color: #2D86EF;
			}
			&.disabled{
				color: #aaa;
				border: 1px solid #F0F0F0;
				cursor: not-allowed;
			}
			&.mar{
				width: 40px;
			}
		}
		.contrast-chromo-num.current{
			border: 1px solid #2D86EF;
			background-color: #2D86EF;
			color: #FFFFFF;
		}
	}
	
	.contrast-wrap{
		overflow: hidden;
		max-height: 500px;
		overflow-y: auto;
		margin-top: 10px;
		.contrast-container{
			overflow: hidden;
			display: flex;
		}
		.contrast-main{
			flex: 1;
		}
		&.diff{
			.contrast-main{
				border-left: 1px solid #333333;
				&:first-child{
					border-left: none;
					border-right: 1px solid #333333;
					margin-right: 3px;
				}
			}
		}
	}
	
	
	.contrast-row{
		width: 100%;
		overflow: hidden;
		padding-bottom: 30px;
		li{
			float: left;
			margin: 0 3px;
			overflow: hidden;
			.contrast-chromo,
			.contrast-standard{
				border-radius: 5px;
				overflow: hidden;
				text-align: center;
				position: relative;
				// &.hasCheckCL,
				// &.hasCheckPL{
				// 	border: 1px solid #1890FF;
				// }
				// &.hasCheckJS{
				// 	border: 1px solid #FBC200;
				// }
				&.currentKaryo{
					border: 1px solid #52C41A;
				}
				&.hasCheckPL{
					border: 1px solid #1890FF;
				}
				&.hasCheckCL{
					border: 1px solid #FBC200;
				}
				
				&.noborder{
					border-color: transparent !important;
				}
				img.vertical{
					height: 100%;
				}
				img.horizontal{
					position: absolute;
					width: 100%;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
				}
			}
			.contrast-standard{
				> div{
					background-image: url(../../assets/images/autovision/320.png);
					background-repeat: no-repeat;
					background-size: cover;
					height: 100%;
					margin: 0 auto;
					display: inline-block;
				}
				img{
					height: 100%;
				}
			}
			.title{
				width: 100%;
				height: 24px;
				line-height: 24px;
				text-align: center;
				font-size: 14px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
	
	.scale-6{
		.contrast-chromo,
		.contrast-standard{
			width: 150px;
			height: 250px;
			&+ .title{
				width: 150px;
			}
		}
	}
	.scale-8{
		.contrast-chromo,
		.contrast-standard{
			width: 115px;
			height: 192px;
			&+ .title{
				width: 115px;
			}
		}
	}
	.scale-10{
		.contrast-chromo,
		.contrast-standard{
			width: 94px;
			height: 157px;
			&+ .title{
				width: 94px;
			}
		}
	}
	.scale-12{
		.contrast-chromo,
		.contrast-standard{
			width: 78px;
			height: 130px;
			&+ .title{
				width: 78px;
			}
		}
	}
	.diff{
		.scale-2{
			.contrast-chromo,
			.contrast-standard{
				width: 177px;
				height: 295px;
				&+ .title{
					width: 177px;
				}
			}
		}
		.scale-4{
			.contrast-chromo,
			.contrast-standard{
				width: 103px;
				height: 172px;
				&+ .title{
					width: 103px;
				}
			}
		}
		.scale-6{
			.contrast-chromo,
			.contrast-standard{
				width: 72px;
				height: 120px;
				&+ .title{
					width: 72px;
				}
			}
		}
	}
}

</style>
