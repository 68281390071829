<template>
    <div class="addDiagnosisReportBox">
        <div
            style="position: absolute; top: -1000px; left: -1000px"
            id="divContainer"
        ></div>
        <div
            style="position: absolute; top: -1000px; left: -1000px"
            id="div01"
        ></div>
        <input type="file" id="selectfiles" style="display: none" />
        <el-dialog
            :title="$t('popup.old_report.addnew_report')"
            :visible.sync="newDialogVisible"
            width="684px"
            :before-close="closeFun"
            :close-on-click-modal="false"
        >
            <el-form
                ref="ruleForm"
                :rules="rules"
                :model="ruleForm"
                label-width="140px"
            >
                <el-form-item :label="$t('organize_approve.organName')">
                    <el-input
                        v-model="ruleForm.hospitalName"
                        :placeholder="$t('popup.old_report.input_organ_name')"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('popup.addnew_report.choose_report_template')" prop="patientStatus">
                    <el-select
                        v-model="ruleForm.patientStatus"
                        :disabled="reportId != '' ? true : false"
                        :placeholder="$t('select_button.choose')"
                        @change="changePatientStatusFun"
                    >
                        <el-option
                            v-for="item in patientStatusList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('popup.old_report.name')" prop="name">
                    <el-input
                        v-model="ruleForm.name"
                        maxlength="20"
                        :placeholder="$t('popup.old_report.input_name')"
                    ></el-input>
                </el-form-item>
                <div class="mergeAreaBox">
                    <el-form-item :label="$t('popup.old_report.age')" prop="age">
                        <el-input
                            v-model="ruleForm.age"
                            maxlength="3"
                            :placeholder="$t('popup.old_report.input_age')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('popup.old_report.sex')" prop="gender" class="genderBox">
                        <el-radio-group v-model="ruleForm.gender">
                            <el-radio :label="$t('popup.old_report.man')"></el-radio>
                            <el-radio :label="$t('popup.old_report.female')"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <el-form-item :label="$t('popup.old_report.sample_type')">
                    <el-select
                        v-model="ruleForm.sampleType"
                        :placeholder="$t('select_button.choose')"
                    >
                        <el-option
                            v-for="item in sampleTypeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('popup.old_report.karyo_checked_result')">
                    <el-input
                        v-model="ruleForm.result"
                        :placeholder="$t('popup.old_report.input_checked_result')"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('popup.old_report.sample_collect_date')">
                    <el-date-picker
                        v-model="ruleForm.collectDate"
                        type="date"
                        :placeholder="$t('popup.old_report.collect_date_choose')"
                        value-format="yyyy-MM-dd"
                        :editable="false"
                        v-if="flag"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('popup.old_report.sample_receive_date')">
                    <el-date-picker
                        v-model="ruleForm.receiveDate"
                        type="date"
                        :placeholder="$t('popup.old_report.receive_date_choose')"
                        value-format="yyyy-MM-dd"
                        :editable="false"
                        v-if="flag"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('popup.old_report.inspect_doc')">
                    <el-input
                        v-model="ruleForm.sendName"
                        :placeholder="$t('popup.old_report.input_inspect_doc')"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('popup.old_report.clinic_desc')">
                    <el-input
                        v-model="ruleForm.descBase"
                        :placeholder="$t('popup.old_report.input_clinic_desc')"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('popup.old_report.karyo_image_choose')" prop="karyoName">
                    <div
                        class="choiceBtn"
                        v-if="!ruleForm.karyoName"
                        @click="karyotypeSelectionFun"
                    >
                        {{$t('popup.old_report.karyo_image_choose')}}
                    </div>
                    <el-input
                        v-show="!!ruleForm.karyoName"
                        v-model="ruleForm.karyoName"
                        :placeholder="$t('popup.old_report.choose_karyo_image')"
                        readonly="readonly"
                        :disabled="!!reportId"
                        @click.native="karyotypeSelectionFun"
                    ></el-input>
                </el-form-item>
                <el-form-item label="">
                    <div class="btnBox">
                        <div class="btn updateBtn" @click="cancel">{{$t('btns.cancel')}}</div>
                        <el-button
                            class="btn determineNomal"
                            @click="onSubmit('ruleForm')"
                            :disabled="submitFlag"
                            v-html="submitFlag ? $t('popup.old_report.image_uploading') : $t('btns.submit')"
                        >
                        </el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <KaryotypeSelection
            :karyotypeDialogVisible="karyotypeSelectionStatus"
            :reportId="reportId"
            :patientId="newPatientId"
            :karyoId="karyotypeId"
            @childFun="parentFun($event)"
            @karyoDataFun="karyoObjFun($event)"
        ></KaryotypeSelection>
        <MessageBox
            :updateType="isConfirmation"
            :updateTips="confirmationTips"
            :openStatus="openStatus"
            @childFun="popupUpdateFun($event)"
        ></MessageBox>
    </div>
</template>

<script>
import "../../assets/scss/button.scss";
import "../../assets/scss/dialog.scss";
import KaryotypeSelection from "./karyotypeSelection.vue";
import MessageBox from "./../messageBox";
import { reg } from "./../../assets/js/reg.js";
import karyoCanvasController from "./../autovision/modules/karyoCanvasController.js";
import arrangeCanvasController from "./../autovision/modules/arrangeCanvasController.js";
import uploader from "./../../assets/utils/fileUploader.js";
export default {
    name: "AddDiagnosisReportBox",
    props: ["dialogVisible", "reportId", "patientId"],
    data() {
        var validateName = (rule, value, callback) => {
            if (reg.specialCharacters.test(value)) {
                callback(new Error(this.$t('account_manage.name_cannot_empty')));
            } else {
                callback();
            }
        };
        var validateAge = (rule, value, callback) => {
            if (value == "") {
                callback(new Error(this.$t('popup.old_report.input_age')));
            } else {
                if (reg.pureNumbers.test(value)) {
                    this.ruleForm.age = value.replace(reg.pureNumbers, "");
                } else {
                    if (value <= 0 || value > 200) {
                        callback(new Error(this.$t('new_interaction.age_check')));
                    } else {
                        callback();
                    }
                }
            }
        };
        return {
            submitFlag: false,
            newDialogVisible: false, // 弹窗初始状态
            karyotypeSelectionStatus: false, // 核型图弹窗初始状态
            flag: true, // 是否显示日期
            newPatientId: "", // 病例 id
            karyotypeId: "", // 选择核型图 id
            dname: "", // 选择核型图 name
            patientStatusList: [
                // 模板选择
                { value: "0", label: this.$t('onlineManage.options.normal') },
                { value: "1", label: this.$t('onlineManage.options.normal_variation') },
                { value: "2", label: this.$t('onlineManage.options.abnormal') },
                { value: "3", label: this.$t('onlineManage.options.amniotic_fluid') }
            ],
            sampleTypeList: [
                // 类型
                { value: this.$t('popup.old_report.blood'), label: this.$t('popup.old_report.blood') },
                { value: this.$t('popup.old_report.amniotic_fluid'), label: this.$t('popup.old_report.amniotic_fluid') },
                { value: this.$t('popup.old_report.villus'), label: this.$t('popup.old_report.villus') },
                { value: this.$t('popup.old_report.marrow'), label: this.$t('popup.old_report.marrow') }
            ],
            ruleForm: {
                confirm: 0, // 确认是否更新报告
                hospitalName: "", // 机构名称sampleTypeList
                patientStatus: "", // 报告模板选择
                name: "", // 患者姓名
                age: "", // 患者年龄
                gender: this.$t('popup.old_report.man'), // 患者性别
                sampleType: "", // 样本类型
                result: "", // 染色体检查结果
                collectDate: "", // 标本采集日期
                receiveDate: "", // 标本接收日期
                sendName: "", // 送检医生
                descBase: "", // 临床描述
                karyoName: "", // 选择核型图 name
                optUrl: "", // 核型图
                arrayUrl: "", // 排列图
                karyotypemap: ""
            },
            rules: {
                patientStatus: [
                    {
                        required: true,
                        message: this.$t('new_interaction.Select_report'),
                        trigger: "change"
                    }
                ],
                name: [
                    {
                        required: true,
                        message: this.$t('new_interaction.patient_ame'),
                        trigger: "change"
                    },
                    {
                        validator: validateName,
                        trigger: "change"
                    }
                ],
                age: [
                    {
                        required: true,
                        validator: validateAge,
                        trigger: "change"
                    }
                ],
                gender: [
                    {
                        required: true,
                        message: this.$t('new_interaction.Sex_selection'),
                        trigger: "change"
                    }
                ],
                karyoName: [
                    {
                        required: true,
                        message: this.$t('new_interaction.Select_karyotype'),
                        trigger: "change"
                    }
                ]
            },
            isConfirmation: true, // 是否显示弹窗
            confirmationTips: "", // 弹窗提示文案
            openStatus: false // 弹窗初始状态
        };
    },
    methods: {
        // 查询报告数据
        getReportQuery(id) {
            this.$api.reportQuery({ id: id }).then(res => {
                if (res.code == 200) {
                    const { report } = res.data;
                    report.karyoName = report.glassName + "/" + report.cellName;
                    this.karyotypeId = report.karyoId;
                    this.ruleForm = report;
                    this.ruleForm.collectDate = report.collectDateStr;
                    this.ruleForm.receiveDate = report.receiveDateStr;
                    this.ruleForm.patientStatus = report.patientStatus.toString();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        karyotypeSelectionFun() {
            if (this.reportId != "") return;
            this.karyotypeSelectionStatus = true;
        },
        parentFun(payload) {
            this.karyotypeSelectionStatus = payload;
        },
        // 返回code=150时调用
        popupUpdateFun(payload) {
            payload
                ? ((this.ruleForm.confirm = 1), this.postReportEdit())
                : (this.openStatus = payload);
        },
        // 选择核型图返回的数据
        karyoObjFun(payload) {
            this.ruleForm.karyoName =
                payload.glassName + "/" + payload.cellName;
            this.karyotypeId = payload.karyotypeId;
            this.dname = payload.dname;
            this.submitFlag = true;
            this.getQueryOssLocal(payload.karyotypeId, payload.dname, payload);
        },
        // 模板选择调用方法
        changePatientStatusFun() {
            this.getQueryOssLocal(this.karyotypeId, this.dname);
        },
        // 根据核型图id，获取染色体数据
        getQueryOssLocal(karyoId, dname, payload) {
            this.ruleForm.optUrl = "";
            this.ruleForm.arrayUrl = "";
            if (!karyoId) return;
            this.$api
                .queryOssLocal({
                    patientId: this.newPatientId,
                    karyoId: karyoId
                })
                .then(res => {
                    if (res.code == 200) {
                        const { chromoList, karyo } = res.data;
                        this.getBase64Fun(
                            chromoList,
                            karyo,
                            dname,
                            karyoId,
                            payload
                        );
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        // 返回base64方法
        getBase64Fun(chromoList, karyoList, dname, karyoId, payload) {
            const divContainer = document.getElementById("divContainer");
            const canvaskaryo = document.createElement("canvas");
            divContainer.appendChild(canvaskaryo);
            let isHideXY;
            if (this.ruleForm.patientStatus != 3 || !karyoId) {
                isHideXY = false;
                this.ruleForm.arrayUrl = !!payload
                    ? payload.permutationUrl
                    : "";
            } else {
                isHideXY = true;
            }

            const karyoController = new karyoCanvasController({
                element: canvaskaryo,
                chromoList: chromoList,
                // karyoInfo: karyoList[0],
                karyoInfo: {
                    id: karyoList[0].id,
                    optUrl: this.ossUrlWebFir(
                        karyoList[0].optUrl.split("?")[0],
                        1,
                        1600,
                        1200
                    ), //karyoList[0].optUrl
                    optBackUrl: this.ossUrlWebFir(
                        karyoList[0].optBackUrl.split("?")[0],
                        1,
                        1600,
                        1200
                    ), //karyoList[0].optBackUrl,
                    primaryUrl: this.ossUrlWebFir(
                        karyoList[0].primaryUrl.split("?")[0],
                        1,
                        1600,
                        1200
                    ), //karyoList[0].primaryUrl,
                    undetectedChromoPoints: karyoList[0].undetectedChromoPoints
                },
                arrowList: !karyoList[0].originalArrowLines
                    ? []
                    : JSON.parse(karyoList[0].originalArrowLines)
            });
            if (!karyoController.arrowList.length && !isHideXY) {
                this.ruleForm.optUrl = payload.karyotypeUrl;
                this.submitFlag = false;
                return;
            }
            this.submitFlag = true;
            let timerkaryo = setInterval(() => {
                if (karyoController.initFinished) {
                    clearInterval(timerkaryo);
                    timerkaryo = null;
                    divContainer.innerHTML = "";
                    karyoController
                        .exportBase64({ isHideXY, zoom: 0.2 })
                        .then(base64 => {
                            let blob = this.dataURLtoBlob(base64);
                            dname = dname.split(".")[0] + ".png";
                            let _file = this.blobToFile(blob, dname);
                            _file.upname =
                                new Date().getTime() +
                                "optUrl." +
                                _file.name.split(".").pop();
                            let options = {
                                scale: 1,
                                width: 200,
                                height: 150
                            };
                            this.uploadImg(_file, options, "optUrl");
                            // this.commonUpload(base64, options, dname, "optUrl");
                        });
                }
            });
            const canvasArrange = document.createElement("canvas");
            divContainer.appendChild(canvasArrange);
            const arrangeController = new arrangeCanvasController({
                element: canvasArrange,
                chromoList: chromoList,
                karyoInfo: karyoList[0],
                arrowList: !karyoList[0].arrangeArrowLines
                    ? []
                    : JSON.parse(karyoList[0].arrangeArrowLines)
            });
            let timerArrange = setInterval(() => {
                if (arrangeController.initFinished) {
                    clearInterval(timerArrange);
                    timerArrange = null;
                    divContainer.innerHTML = "";
                    arrangeController
                        .exportBase64({ isHideXY })
                        .then(base64 => {
                            let options = {};
                            if (
                                this.ruleForm.patientStatus == 0 ||
                                this.ruleForm.patientStatus == 1
                            ) {
                                options = {
                                    scale: 1,
                                    width: 400,
                                    height: 350
                                };
                            } else {
                                options = {
                                    scale: 1,
                                    width: 500,
                                    height: 400
                                };
                            }
                            this.commonUpload(
                                base64,
                                options,
                                dname,
                                "arrayUrl"
                            );
                        });
                }
            });
        },
        // 压缩图片
        async dealImage(base64, options) {
            return new Promise(resolve => {
                let newImage = new Image();
                newImage.src = base64;
                newImage.setAttribute("crossOrigin", "Anonymous"); //url为外域时需要
                newImage.onload = function() {
                    let that = this;
                    let canvas = document.createElement("canvas");
                    let ctx = canvas.getContext("2d");
                    let width, height;
                    if (options.width > options.height) {
                        height = options.height;
                        width = parseInt((4 * height) / 3);
                    } else {
                        width = options.width;
                        height = parseInt((3 * width) / 4);
                    }
                    canvas.setAttribute("width", width);
                    canvas.setAttribute("height", height);
                    ctx.drawImage(that, 0, 0, width, height);
                    let base64 = canvas.toDataURL("image/jpeg", options.scale); //压缩语句
                    resolve(base64);
                    // callback(base64); //必须通过回调函数返回，否则无法及时拿到该值
                };
            });
        },
        // 公共方法
        async commonUpload(base64, options, dname, imgName) {
            let newBase64 = await this.dealImage(base64, options);
            let blob = this.dataURLtoBlob(newBase64);
            dname = dname.split(".")[0] + ".png";
            let _file = this.blobToFile(blob, dname);
            _file.upname =
                new Date().getTime() +
                "arrayUrl." +
                _file.name.split(".").pop();
            this.uploadImg(_file, options, imgName);
        },
        //图片上传
        uploadImg(file, control, imgName) {
            uploader({
                files: [file],
                path: "user/certificate/",
                ossUploadAccess: this.$store.state.ossUploadAccess
            }).then(filelist => {
                if (imgName == "optUrl") {
                    this.ruleForm.optUrl = filelist[0].path;
                    // this.ruleForm.optUrl = this.ossUrlWebFir(
                    //     filelist[0].path,
                    //     1,
                    //     control.width,
                    //     control.height
                    // );
                } else {
                    this.ruleForm.arrayUrl = filelist[0].path;
                    // this.ruleForm.arrayUrl = this.ossUrlWebFir(
                    //     filelist[0].path,
                    //     1,
                    //     control.width,
                    //     control.height
                    // );
                }
                if (!!this.ruleForm.optUrl && !!this.ruleForm.arrayUrl) {
                    this.submitFlag = false;
                }
            });
        },
        //将base64转换为blob
        dataURLtoBlob: function(dataurl) {
            let arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        //将blob转换为file
        blobToFile: function(theBlob, fileName) {
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        },
        // 初始化数据
        clearRuleForm() {
            this.karyotypeId = ""; // 选择核型图 id
            this.ruleForm = {
                confirm: 0, // 确认是否更新报告
                hospitalName: "", // 机构名称sampleTypeList
                patientStatus: "", // 报告模板选择
                name: "", // 患者姓名
                age: "", // 患者年龄
                gender:this.$t('popup.old_report.man'), // 患者性别
                sampleType: "", // 样本类型
                result: "", // 染色体检查结果
                collectDate: "", // 标本采集日期
                receiveDate: "", // 标本接收日期
                sendName: "", // 送检医生
                descBase: "", // 临床描述
                karyoName: "", // 选择核型图 name
                optUrl: "", // 核型图
                arrayUrl: "", // 排列图
                karyotypemap: ""
            };
            this.setInitial();
        },
        // 点击 X 关闭方法
        closeFun() {
            this.flag = !this.flag;
            setTimeout(() => {
                this.cancel();
            });
        },
        // 点击取消方法
        cancel() {
            this.newDialogVisible = false;
            this.$emit("childFun", this.newDialogVisible);
        },
        // 设置模板、类型 初始值
        setInitial() {
            this.ruleForm.patientStatus = this.patientStatusList[0].value;
            this.ruleForm.sampleType = this.sampleTypeList[0].value;
        },
        // 报告提交方法
        postReportEdit() {
            this.ruleForm.karyoId = this.karyotypeId;
            this.ruleForm.optUrl = this.ruleForm.optUrl.split("?")[0];
            this.ruleForm.arrayUrl = this.ruleForm.arrayUrl.split("?")[0];
            // if (!!this.ruleForm.optUrl && !!this.ruleForm.arrayUrl) {
            this.$api.reportEdit(this.ruleForm).then(res => {
                if (res.code == 200) {
                    this.newDialogVisible = false;
                    this.openStatus = false;

                    this.$emit("childFun", this.newDialogVisible);
                    this.$store.commit("isUpdateListType", true);
                    this.$store.commit(
                        "addReviseGlassIdStyle",
                        this.ruleForm.karyoId
                    );
                    this.$parent.getReportListByPatientId();
                    this.$message.success(res.message);
                } else if (res.code == 150) {
                    this.confirmationTips = res.message;
                    this.openStatus = true;
                } else {
                    this.$message.error(res.message);
                }
            });
            // }
        },
        // 点击提交按钮
        onSubmit(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.postReportEdit();
                } else {
                    return false;
                }
            });
        },
        // 时间戳转换为日期
        // formatDate(new Date(), "yyyy-MM-dd hh:mm:ss")
        formatDate(date, fmt) {
            var currentDate = new Date(date);
            var o = {
                "M+": currentDate.getMonth() + 1, //月份
                "d+": currentDate.getDate(), //日
                "h+": currentDate.getHours(), //小时
                "m+": currentDate.getMinutes(), //分
                "s+": currentDate.getSeconds(), //秒
                "q+": Math.floor((currentDate.getMonth() + 3) / 3), //季度
                S: currentDate.getMilliseconds() //毫秒
            };
            if (/(y+)/.test(fmt))
                fmt = fmt.replace(
                    RegExp.$1,
                    (currentDate.getFullYear() + "").substr(
                        4 - RegExp.$1.length
                    )
                );
            for (var k in o)
                if (new RegExp("(" + k + ")").test(fmt))
                    fmt = fmt.replace(
                        RegExp.$1,
                        RegExp.$1.length == 1
                            ? o[k]
                            : ("00" + o[k]).substr(("" + o[k]).length)
                    );
            return fmt;
        },
        // 设置初始日期为当前日期
        setDate() {
            this.ruleForm.collectDate = this.formatDate(
                new Date().getTime(),
                "yyyy-MM-dd"
            ); // 标本采集日期
            this.ruleForm.receiveDate = this.formatDate(
                new Date().getTime(),
                "yyyy-MM-dd"
            ); // 标本接收日期
        }
    },
    created() {
        this.setInitial();
    },
    watch: {
        dialogVisible() {
            this.newDialogVisible = this.dialogVisible;
            this.newPatientId = this.patientId;
            this.flag = true; // 是否关闭日期
            if (this.reportId != "") {
                this.getReportQuery(this.reportId);
            }
            if (this.$refs["ruleForm"]) {
                this.$refs["ruleForm"].resetFields();
            }
            this.clearRuleForm();
            this.setDate();
        }
    },
    components: {
        KaryotypeSelection,
        MessageBox
    }
};
</script>

<style lang="scss" scoped>
.addDiagnosisReportBox {
    .mergeAreaBox {
        display: flex;
    }
    .choiceBtn {
        cursor: pointer;
        width: 130px;
        height: 40px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .btnBox {
        width: 455px;
        display: flex;
        justify-content: flex-end;
        .updateBtn {
            width: 80px;
            height: 30px;
        }

        .determineNomal {
            margin-left: 12px;
            width: 82px;
            height: 32px;
            color: #fff;
            border: 0;
            &.el-button--default {
                background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
            }
            &:hover {
                // opacity: 0.8;
                // background: #5092d4;
                background: linear-gradient(90deg, #3a83ce 0%, #5092d4 100%);
            }
            &:disabled {
                background: #8eaddc;
            }
        }
    }
}
</style>
<style lang="scss">
.addDiagnosisReportBox {
    .el-form-item {
        margin-bottom: 18px !important;
    }
    .el-input {
        width: 455px;
    }

    .mergeAreaBox {
        .el-input {
            width: 177px;
        }
        .genderBox {
            .el-form-item__content .el-radio-group .el-radio .el-radio__label {
                border-bottom: none !important;
            }
        }
    }
}
</style>
