<template>
  <div class="member-container">
    <el-button type="primary" @click="centerDialogVisible=true">{{$t('members.addMembers')}}</el-button>
    <el-table style="width: 100%;" stripe :data="memberData">
      <el-table-column :label="$t('members.nickname')" prop="nickName">
        <template slot-scope="scope">
          <span v-show="!scope.row.editShow">{{scope.row.nickName}}</span>
          <el-button class="edit-btn" @click="editShortName(scope.row)" v-show="!scope.row.editShow"><i class="el-icon-edit"></i></el-button>
          <el-input v-model="scope.row.nickName" v-show="scope.row.editShow" @blur="confirm(scope.row)" maxlength="10"></el-input>
          <!-- <el-button size="small" @click="confirm(scope.row)" v-show="scope.row.editShow">确认</el-button> -->
        </template>
      </el-table-column>
      <el-table-column :label="$t('members.name')" prop="realName"></el-table-column>
      <el-table-column :label="$t('members.color')" prop="color">
        <template slot-scope="scope">
          <!-- <colorPicker v-model="scope.row.color" v-on:change="chooseColor(scope)" /> -->
          <colorPicker v-model="scope.row.color" v-on:change="chooseColor(scope)" />
          <i class="el-icon-arrow-down"></i>
        </template>
      </el-table-column>
      <el-table-column :label="$t('members.role')" prop="mechanismId">
        <template slot-scope="scope">
          <div class="role-select" @click.stop="chooseRole(scope)" :id="'roleSelector'+scope.$index" v-if="roleData.length && adminId != scope.row.userId">
            <div>
              <span v-for="(role,index) in scope.row.roles" :key="index">{{role.name}}</span>
            </div>

            <i class="el-icon-arrow-down"></i>
          </div>
          <div v-else>
            <div>
              <span v-for="(role,index) in scope.row.roles" :key="index">{{role.name}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('members.username')" prop="userName"></el-table-column>
      <el-table-column :label="$t('members.phone')" prop="phone"></el-table-column>
      <el-table-column :label="$t('members.operation')">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="deleteMember(scope)" v-if="adminId != scope.row.userId && !scope.row.isMechanismAdmin">{{$t('members.remove')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="$t('members.invite')" center :visible.sync="centerDialogVisible" class="members-invite">
      <div class="members-invite-tabs">
        <div class="members-invite-tab" :class="{current:inviteType==2}" @click="inviteType=2">{{$t('members.mesg_invite')}}</div>
        <div class="members-invite-tab" :class="{current:inviteType==1}" @click="inviteType=1">{{$t('members.link_invite')}}</div>
      </div>
      <div style="display:flex; padding: 0 10px; margin-top:10px; border: 1px solid #e3e3e3;">
        <el-checkbox-group v-model="role">
          <el-row v-for="item in roleData" :key="item.id">
            <el-checkbox :label="item.id">{{item.name}}</el-checkbox>
          </el-row>
        </el-checkbox-group>
        <el-checkbox-group v-model="role" style="margin-left:20px; flex:1; overflow:hidden;">
          <el-row v-for="item in roleData" :key="item.id" style="width:100%;">
            <label v-if="item.remarks" :title="item.remarks" style="width:100%; display:inline-block; overflow:hidden; white-space: nowrap; text-overflow: ellipsis;">
              <span>{{item.remarks}}{{item.remarks}}</span>
            </label>
          </el-row>
        </el-checkbox-group>
      </div>

      <div class="members-invite-bottom">
        <div class="invite-sms" v-if="inviteType==2">
          <span>{{$t('account_manage.telNum')}}</span>
          <el-select v-model="phoneCode" :popper-append-to-body="false" filterable style="width:120px;">
            <el-option v-for="(item, index) in phoneData" :key="index" :label="item.useOption" :value="item.tel">
            </el-option>
          </el-select>
          <el-input type="number" v-model="phone" :placeholder="$t('personal_info.input_tel')" maxlength="20" style="width:150px;"></el-input>
        </div>
        <div class="invite-sms" v-else></div>
        <div class="invite-btns">
          <el-button type="default" class="copy-link" @click="centerDialogVisible=false">{{$t('btns.cancel')}}</el-button>
          <el-button type="primary" class="copy-link" @click="copyLink" :disabled="!role.length">{{$t('btns.submit')}}</el-button>
        </div>
      </div>
    </el-dialog>
    <ul class="role-list" v-if="roleVisible" :style="{left:roleOffset.left+'px',top:roleOffset.top+'px'}">
      <el-checkbox-group v-model="roleIds">
        <li class="role-list-item" v-for="(role,index) in roleData" :key="index">
          <el-checkbox :label="role.id">{{role.name}}</el-checkbox>
        </li>
      </el-checkbox-group>
    </ul>
  </div>
</template>
<script>
import phoneIntNatnData from "../../assets/loginJson/telLogin_intel_en.json"
import { cloneDeep } from 'lodash';
import colorPicker from "../personalSettings/components/color-picker.vue"
export default {
  name: 'Member',
  data() {
    return {
      centerDialogVisible: false,
      role: [],
      roleData: [],
      roleIds: [],
      roleRow: {},
      colors: ["#F7B500", "#00BA74", "#B620E0", "#32C5FF", "#6DD400"],
      colorOffset: { left: 0, top: 0 },
      roleList: [this.$t('members.administrator'), this.$t('members.seniorAnalyst'), this.$t('members.analysts'), this.$t('members.inspector')],
      roleVisible: false,
      roleOffset: { left: 0, top: 0 },
      inviteType: 2,
      phoneCode: '',
      phone: '',
      memberData: [
        // {
        //   id: 1,
        //   name: '金文露',
        //   color: '#F7B500',
        //   mechanismId: '管理员',
        //   userName: 'Kevin Rose',
        //   phone: '15812345678'
        // }
      ],
      adminId: ''
    }
  },
  components: {
    colorPicker
  },
  computed: {
    phoneData() {
      let telDataIntel = cloneDeep(phoneIntNatnData);
      // console.log(telDataIntel);
      if (this.$store.state.language === 'en') {
        telDataIntel = telDataIntel.map(a => a.en).sort().map(key => { return telDataIntel.filter(a => a.en === key)[0] })
        telDataIntel.forEach((item) => {
          item.useOption = item.en + '(+' + item.tel + ')';
          item.keyVal = item.tel + ',' + item.short
        })
      } else {
        telDataIntel = telDataIntel.map(a => a.name).sort().map(key => { return telDataIntel.filter(a => a.name === key)[0] })
        telDataIntel.forEach((item) => {
          item.useOption = item.name + '(+' + item.tel + ')';
          item.keyVal = item.tel + ',' + item.short
        })
      }

      const la = navigator.language.split("-")[1];
      let curr = telDataIntel.find(a => a.short === la);
      this.phoneCode = curr.tel;
      // let lang = localStorage.getItem('language') || (navigator.language || navigator.userLanguage).substr(0, 2);
      return telDataIntel;
    }
  },
  created() {
    this.init();
    document.onclick = (e) => {
      const bool1 = $(e.target).parents(".role-list").length;
      const bool2 = $(e.target).parents(".role-select").length;
      const bool3 = $(e.target).parents(".color-list").length;
      const bool4 = $(e.target).parents(".color-select").length;
      if (!bool1 && !bool2 && !bool3 && !bool4) {
        this.roleVisible = false;
      }
    }
    // window.member = this;
  },
  beforeDestroy() {
    document.onclick = null;
  },
  methods: {
    editShortName(item) {
      this.memberData = this.memberData.map(row => {
        row.editShow = row === item;
        return row;
      })
      this.$forceUpdate();
    },
    confirm(item) {
      item.editShow = false;
      this.$forceUpdate();
      let param = {
        "id": item.id,
        "nickName": item.nickName//昵称
      }
      this.$api.updateNickName(param).then(res => {
        if (res.code == 200) {
          this.$message.success(this.$t('members.name_success'))
          this.getMemberList();
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 初始化
     */
    async init() {
      await this.getListById();
      await this.getMemberList();
    },
    async getListById() {
      return this.$api.listById().then(res => {
        if (res.code == 200) {
          this.roleData = res.data
        }
      })
    },
    /**
     * 获取成员列表
     */
    async getMemberList() {
      return this.$api.memberList().then(res => {
        if (res.code == 200) {
          this.memberData = res.data.pageUtil.list;
          this.memberData.forEach(item => item.editShow = false)
          this.adminId = res.data.isMechanismAdmin === 1 ? JSON.parse(localStorage.loginObj).userId : '';
        }
      })
    },
    /**
     * 删除成员
     */
    deleteMember({ row }) {
      console.log(row);
      const name = row.realName || row.userName
      const content = this.$t('members.del') + `(${name})`;
      this.$confirm(content, this.$t('members.tip'), {
        type: 'warning',
        closeOnClickModal: false
      }).then(res => {
        this.$api.memberRemove({ userId: row.userId }).then(res => {
          this.getMemberList();
          this.$message({
            type: res.code == 200 ? "success" : "error",
            message: res.code == 200 ? this.$t('members.success') : this.$t('members.fail')
          })
        })
      }).catch(action => {
        console.log(action);
      })
    },
    /**
     * 验证手机号码
     */
    verifyPhone() {

    },
    /**
     * 复制链接
     */
    copyLink() {
      const param = {
        roles: this.role.join(","),
        type: this.inviteType,
      }
      if (this.inviteType == 2 && !this.phone) {
        this.$message({
          type: 'warning',
          message: this.$t('login.input_tel_warn')
        })
        return;
      }
      if (this.inviteType == 2 && !/^1\d{10}$/g.test(this.phone)) {
        this.$message({
          type: 'warning',
          message: this.$t('login.telnum_notCorrect')
        })
        return;
      }
      if (this.inviteType == 2 && this.phone) {
        param.phone = this.phone;
        param.phoneCode = this.phoneCode;
      }
      this.$api.copyUrl(param).then(res => {
        if (res.code == 200) {
          if (this.inviteType == 2) {
            this.$message({
              type: 'success',
              message: res.message
            })
          } else {
            let transfer = document.createElement('input');
            document.body.appendChild(transfer);
            const url = window.location.origin + "#/invitation?isReg=1&key=" + res.data;
            transfer.value = url || this.$t('members.want_copy_content');
            transfer.focus();
            transfer.select();
            if (document.execCommand('copy')) {
              document.execCommand('copy');
            }
            transfer.blur();
            // console.log('复制成功');
            document.body.removeChild(transfer);
            this.$message({
              type: 'success',
              message: this.$t('members.copied')
            })
          }
          this.centerDialogVisible = false;
        } else {
          this.$message({
            type: 'error',
            message: this.$t('members.sended')
          })
        }
      })

    },
    /**
     * 颜色下拉
     */
    chooseColor(scope) {
      this.roleVisible = false;
      this.updateColor(scope.row);
    },
    /**
     * 成员更新颜色
     */
    updateColor(row) {
      this.$api.updateMemberColor({ userId: row.userId, color: row.color }).then(res => {
        if (res.code == 200) {
          this.$message.success(this.$t('members.color_success'))
          let obj = {
            belongUserColor: row.color,
            belongUserId: row.userId
          }
          this.$store.commit('belongUserColorSave', obj)
        } else {
          this.$message.error(res.message);
        }
      })
    },
    /**
     * 角色下拉
     */
    chooseRole(scope) {
      this.roleVisible = !this.roleVisible;
      if (!this.roleVisible) {
        return;
      }
      const target = $("#roleSelector" + scope.$index);
      this.roleOffset = {
        left: target.offset().left,
        top: target.offset().top + target.height()
      }
      this.roleIds = scope.row.roles.map(a => a.id);
      this.roleRow = scope.row;
      this.roleCallback = (ids) => {
        this.updateRole(scope.row.userId, ids)
      }
    },
    /**
     * 成员更新角色
     */
    async updateRole(userId, ids) {
      await this.$api.updateRole({ userId: userId, roles: ids }).then(res => {
        if (res.code == 200) {
          this.$message.success(this.$t('members.role_success'))
        } else {
          this.$message.error(res.message);
        }
      });
      this.getMemberList();
    },
  },
  watch: {
    roleVisible(nv, ov) {
      if (!nv) {
        const roleids = this.roleIds.join(",");
        console.log(this.roleIds);
        const _ids = this.roleRow.roles.map(a => a.id).join(",");
        roleids != _ids && this.roleCallback(roleids);
      }
    },
    centerDialogVisible(nv) {
      if (!nv) {
        this.role = [];
      }
    }
  }
}
</script>
<style lang="scss">
.member-container {
  .el-button--default {
    height: 34px;
  }
  padding: 26px 20px;
  // overflow: hidden;
  > .el-button {
    width: 146px;
    height: 40px;
  }
  .m-colorPicker {
    width: 40px;
    cursor: pointer;
    // z-index: 99;
  }
  .m-colorPicker .box {
    position: fixed;
  }
  .m-colorPicker .colorBtn {
    border-radius: 15px;
    margin-left: 10px;
  }
  .el-icon-arrow-down {
    position: absolute;
    // top: 50px;
    left: 72px;
    margin-top: 2px;
  }
  .el-table {
    margin-top: 16px;
    border: 1px solid #dcdcdc;
    overflow: auto;
    th > .cell {
      color: #1a1a1a !important;
    }
  }
  .el-dialog {
    width: 880px !important;
    .el-checkbox-group {
      margin-top: 10px;
      border-radius: 4px;
      // border: 1px solid #e3e3e3;
      // padding: 0 20px;
    }
  }
  .el-table .cell {
    text-align: center;
  }
  .el-row {
    height: 34px;
    line-height: 34px;
    display: flex;
    align-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    .el-checkbox {
      // transform: translateY(9px);
    }
    > label:last-child {
      color: #666666;
      font-size: 14px;
    }
  }
  .edit-btn {
    width: 30px;
    margin-left: 0;
    border: none;
    background: none;
    color: #409eff;
    &:hover {
      color: #66b1ff;
    }
  }
  .copy-link {
    width: 100px;
    height: 34px;
    margin: 0px auto 0;
    display: block;
    &.is-disabled {
      background: #a0cfff !important;
    }
  }
  .el-table th.is-leaf {
    border-bottom: 1px solid #dcdcdc !important;
  }
  .color-select {
    position: relative;
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    > i {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    span {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      display: block;
    }
  }
  .role-select {
    position: relative;
    // height: 30px;
    display: flex;
    align-items: center;
    > i {
      margin-left: 5px;
    }
    span {
      display: block;
    }
  }
  .color-list {
    width: 30px;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
    // display: none;
    li {
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 5px;
      cursor: pointer;
      span {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        display: block;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
  .role-list {
    overflow: hidden;
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
    li {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.members-invite-tabs {
  display: flex;
  overflow: hidden;
}
.members-invite-tab {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #666666;
  cursor: pointer;
  padding: 0 6px 4px;
  border-bottom: 3px solid transparent;
  margin-right: 8px;
  &.current {
    color: #4773b8;
    border-color: #4773b8;
    cursor: default;
  }
}
.members-invite-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .invite-btns {
    display: flex;
    button {
      margin-left: 12px;
    }
  }
}
.invite-sms {
  display: flex;
  align-items: center;
  > span {
    display: block;
  }
  > * {
    margin-right: 5px;
  }
}
</style>