<template>
  <div class="zoom-image" v-if="isShowLayer">
    <div class="zoom-image-bg"></div>
    <div class="zoom-image-close" @click="close()"></div>
    <div class="image-box">
      <img :src="currentImage" :style="{ transform: 'rotate('+ rotateAngle +'deg) scale('+ scale/100 +')'}" />
    </div>
    <div class="layer-tools">
      <div class="layer-tools-page">
        <div class="prev" :class="{disabled:currentIndex===0}" @click="changeImageSrc('prev', -1)"></div>
        <div class="page-num">{{currentIndex+1}}/{{totalNum}}</div>
        <div class="next" :class="{disabled:currentIndex===totalNum-1}" @click="changeImageSrc('next', +1)"></div>
      </div>
      <div class="layer-tools-zoom">
        <div class="zoom-minus" @click="resetScale(-1)"></div>
        <div class="zoom-percent">{{scale}}%</div>
        <div class="zoom-plus" @click="resetScale(1)"></div>
      </div>
      <div class="layer-tools-rotate" @click="rotateImage"></div>
    </div>
  </div>
</template>
<script>
import shortKey from '../../../assets/utils/shortKey'
export default {
  name: 'ImageLarge',
  props: ['isShowLayer', 'currentImage', 'currentImageIndex', 'totalNum'],
  data() {
    return {
      rotateAngle: 0,
      scale: 60,
      uploading: false,
      uploadPercent: 0,
      currentIndex: this.currentImageIndex
    }
  },
  created() {
    shortKey.on('keydown', {
      key: 'arrowup',
    }, () => {
      this.resetScale(1)
    })
    shortKey.on('keydown', {
      key: 'arrowdown',
    }, () => {
      this.resetScale(-1)
    })
    shortKey.on('keydown', {
      key: 'arrowleft',
    }, () => {
      this.changeImageSrc('prev', - 1)
    })
    shortKey.on('keydown', {
      key: 'arrowright',
    }, () => {
      this.changeImageSrc('next', + 1)
    })
  },
  methods: {
    /**
     * 旋转图像
     */
    rotateImage() {
      let rotate = this.rotateAngle - 90;
      this.rotateAngle = rotate % 360;
    },
    /**
     * 切换图像
     */
    changeImageSrc(dirc, index) {
      this.currentIndex += index;
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
        return;
      }
      if (this.currentIndex > this.totalNum - 1) {
        this.currentIndex = this.totalNum - 1;
        return;
      }
      this.$emit('changeCurrentImage', this.currentIndex)
    },
    close() {
      this.$emit('showImage', false)
    },
    /**
     * 设置缩放比
     */
    resetScale(abs) {
      this.scale += 10 * abs;
      this.scale = Math.max(10, Math.min(500, this.scale));
    },
  },
  watch: {
    isShowLayer(nv, ov) {
      this.currentIndex = this.currentImageIndex;
      shortKey.disabled = ov;
    }
  }
}
</script>
<style lang="scss" scoped>
.zoom-image {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  .zoom-image-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .zoom-image-close {
    position: absolute;
    z-index: 2;
    width: 48px;
    height: 48px;
    top: 50px;
    right: 50px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACqElEQVRoQ92aO6gTQRSGv1UEHyjBztgJio2VjY2IlRaicIuAjUlEEbFSQbGzEwW1ElHEJIIIKQTFQiuxs7GyEQU7YydB8QGikT/syLpsdmd2N7kct7s3Z87835yZOWdmN6KepwHsALYDm4EmsA5YE7v/DnwFRsAH4A3wGhhX7T6q4GA9sAfYC2wFQn1NgHfAc+AF8KWMltBO1cdGYAnYB6wu02lGmx/AM+Ah8CnEZwjASuAQcLhG4WmtAnkAPAJ++YD4AmhOnwe2+DitweY9cDleM7nufAB2AaeBtTUIC3HxDbgOvMxrVASwHzgJrAjpuUbb38BN4Oksn3kAEn+qRjFVXN2YBTELQNPmwjKOfBpWkbiUNZ2yALRgNfcWPeeLIqQ1obWoZPj3SQNoq7y2wN2mSHT6d+1OZ5JbbBpACaob6nXB9r044U27TQIow96aY5Kqi1PJ7oTL2EmAY3GmraujefpRpr6TjIAKs7sGRt8NiqJwVAWgi8CBOCzBo9ZoNBiPy1XFVdrG0/2JA7gKbAtVLwGj0YjhcEi322UyUYVc/ERRRK/Xo9Vq0Ww2yw7AW+CsAHQYuVeinp8q7ff7tNttBoOBF4QT79p0Op1i4mwLjdYRAewGzpX1khaUF4kQW089VwRwHDjo2SDTzEeYj00JDY8FcBHYWaLxP03yBM5JvPp/JYDbwKaqANM9OV6cyTWh/2vBhqyTAC0fBXAf2BDQKNc0DSHjOYmX688C0EF6VV0A6Ujob98dqoSGn/8FgPkpZH4Rm99GzScy86WE+WJO26/pcloA5g805o+UioLpQ70AzF+rCML0xZYAzF8tCsL05a4ryU1frzsI0y84khBmXzElp5PZl3wOwvRrVgdh+kV38sLA7KcG6VsPsx97ZF3fLNvnNn8AJ1h+fk1clm4AAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    cursor: pointer;
    &:hover {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADQUlEQVRoQ92aTWgTURDH/5NgbPADERG9eGr1LIrNpQWP3jyI1bK7HlKiqIXi19GDR+tHoSoakoPZJX7gwZMehfaSitCzticviopItSRWNiMTdku6Jt2PbFpfc0vyZub/25ndN++9JcTw0XV9NxEdZeYMER1g5j4AO4hom7hn5p8AfhDRHDO/J6IKM78xTfNLp+EpqoNsNrtzaWnpNACdmY8QUShfzMxE9BaAmUqlnhSLxe9RtIQKKgE0TdubSCSuMPNZAFuiBG1hs0hEj+r1+i3Lsj6F8RkYIJfLbarVamP1ev06EW0NEyToWGb+lUgkbvT09Ezk8/k/QewCAWia1ktEzwEcDOI0hjGzzHzSsqx5P1++AJqmHSeixwC2+zmL+f8FZj5jWdbL1fyuCqDreg7AAwDJmMUFdWcDOG+aZr6dQVsAR/xDAL5ZCqom4jgGcK4dREtxTtm8WMcr72W1mflEq3L6B2B4eLgvmUy+W4ea90vQgm3bh8vl8lzzwBUA8qisVqsza/i08RPt/X82nU73Nz9iVwAYhnGVmW+G9bqW44noWqlUGndjLgPIDAvgQ7cmqbggZbIDsN+dsZcBDMO4zcyX4grUTT9EdKdUKl2WGA0ApzH7GGNv00394nsxlUrtkwawAaDr+gUA96JETafTqFarUUzRiS2Ai6Zp3ncBKgD6w6oQAZOTk6hUKigWi9L3B3IhnXc2m0Umk8Ho6GjUCzBjmmaGZDHCzJ/D9vOu0pGREQwODmJ6ehqFQsEXQsSLzcDAAKampho2UT7OemIPGYYxxMxPozhp3ERNgvwgwowNooeITkkG7gIYC2LQtqEKABG3eEfLhGTgFTMf6wTALxNdEi/Zfy0ZkN6it1OAdhDyu1vzfiUWQcO8AHwFsCuCcUsT79WWQXLDdkG8uP4mJVRj5s1xAXgzId+7JF5K6Lf6AMqXkPI38UZ4jCo/kSnfSqjdzDnrAXXb6Q2xoFF+SSlZUHpRLwDKb6s4WVB3Y0sAlN9aFIj/eXOXmQ95T2023va6u7BR+oDDA6HmEZMLofQhnwvh3NjP1vDgY9a27SHvaUyrdXvgAzylD7qbyZV91cCbPmVf9mhVh+v5us1fYxKag3s3/o0AAAAASUVORK5CYII=);
    }
  }
  .image-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: 50% 50%;
  }
  .layer-tools {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    position: absolute;
    left: 50%;
    bottom: 65px;
    z-index: 9;
    transform: translateX(-50%);
    display: flex;
    padding: 12px 0;
    .layer-tools-page {
      padding: 0 28px 0 38px;
      display: flex;
      border-right: 1px solid rgba(216, 216, 216, 0.4);
      .prev,
      .next {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-repeat: no-repeat;
        cursor: pointer;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAA0UlEQVRIS+3WQQoCMQyF4T8n0BPpShG9kV5IBHXtHdTbKO6eDHQhIkzaBGczXaf9mgcpNQZaNpDLCP8teVfUkibAHtiZ2T3jdl54DlyAB7DIwF1w16GkDXDMwt1wNl4FZ+LVcMHXwCkSexOcgTfDP/Clmd28oxaCI3gY/sKfZc57O8+CV8AZeBX42hd5GJZUjXaXCsGtaAiOoM1wFG2CM9BqOAutgjNRNyxpVj4C7jlNmWNJU+AAbM2s93HoQ90dew6qrQk9ILXYZ/0IR9Kr2vsG33V2H1ROdPQAAAAASUVORK5CYII=);
        &:hover {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAABFUlEQVRIS8XXMQ6CMBQG4P+NMJh4CZkIqwfwBO5wARLCROIJDByDGT2BB/AMLnADIyMLpkSNg4TX9iEdm6ZfX5v38koAEEXRpu/7I4AdgJWam2G0AC5EdCjL8kYv9ApgPQP2a8s7EW0pDMMTgP2f0DdzVvBjxusdi6dVcP/naAeOBTuOgziOUVUVmqYROScL9jwPWZah6zrkeS6Cs2AVYhAESJJEDGfD0rgWLIlrw1K4ESyBG8O2uBVsg1vDprgI7Ps+0jQdcrwoCtR1PVndrGETlF2rx45vilrBNqgxbIsawRKoNiyFasGSKBv+bgS4eTqVyKw8dl330/pwisMUyo6Ys5HumkXb22Ua+sW+MOptlvi0PQFmkQgqM0gXlgAAAABJRU5ErkJggg==);
        }
        &.disabled {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAA0UlEQVRIS+3WQQoCMQyF4T8n0BPpShG9kV5IBHXtHdTbKO6eDHQhIkzaBGczXaf9mgcpNQZaNpDLCP8teVfUkibAHtiZ2T3jdl54DlyAB7DIwF1w16GkDXDMwt1wNl4FZ+LVcMHXwCkSexOcgTfDP/Clmd28oxaCI3gY/sKfZc57O8+CV8AZeBX42hd5GJZUjXaXCsGtaAiOoM1wFG2CM9BqOAutgjNRNyxpVj4C7jlNmWNJU+AAbM2s93HoQ90dew6qrQk9ILXYZ/0IR9Kr2vsG33V2H1ROdPQAAAAASUVORK5CYII=);
          cursor: not-allowed;
        }
        background-size: 100%;
      }
      .prev {
        transform: rotate(180deg);
      }
      .page-num {
        width: 69px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        font-weight: 400;
        font-size: 16px;
      }
    }
    .layer-tools-zoom {
      padding: 0 29px;
      display: flex;
      border-right: 1px solid rgba(216, 216, 216, 0.4);
      .zoom-minus,
      .zoom-plus {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
        &:hover {
          background-color: #5d5d5d;
        }
        &.disabled {
          background-color: transparent !important;
          cursor: not-allowed;
        }
        background-size: 80%;
      }
      .zoom-minus {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACBUlEQVRIS7WVy4vOYRTHPx/KQrksJMtxKdcs3JKRUoiSJBZKxE4pKYkxWVhgpyymSSF/gNuUWJGFjWRWiHIZC0nKZS1Hp57RO+/M7/f+1Dtn9fb+nud8zvme7/M8MsnhJOenFhAR04FtwCpgLvADeAPcV782KW5CQERMA04Cp4CZwEfgCzAbWAhMBW4CZ9X8vzLGASJiDnC3VH0FGFA/jWaIiFnAfuAc8AfYpb6oIowBlMofAfOBnepw1cZSyB1gMbBOzS7HRTugD+gHeuuSt3XzDBhRc1bVgDLQz8CgerrJAHNNRGwHHgAb1aft+/51EBG7gdtAT6vmTUAR8Q4YUk/UAc4DB9QFLRKkg6ZUQH6pOeTs4jqwSN1UBxgEVqobyqaDxYpVTdxQj5S1F4A96pI6wKViuWVl0wxgc00Hw+pIWTuQtlbX1wEO54DzxKo/m2jfImU66bV6qA6QV0G66JiaoEYREUuBl8A+9VYloLR6DdhRZvGtEyEi0oVp0R5ghfq7E2Ae8Bz4UE5ypVQl+eXsGNiqPq49aC165s35EPgOHFfz95gosmTyLeXi61MvNgIUqbLlq1kZ8B54Um7TvOjWAGuBt8BRIJ2TNp0Q0uk96AX2Aqvb3oMh4N6o5hFxpgrStRetBbJcfTUqV9cARdo8pHkeYlIAjYfcyf//872rEk0E/gvfh78ZkwqqIQAAAABJRU5ErkJggg==);
      }
      .zoom-plus {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACN0lEQVRIS7WVS4iOYRTHfz/KQrksJMtxKdcs3JKRUoiSWyxExE4pKYkhxQI7ZTFNcsnCkmHKZUUWNpJZIcplLCQpt60cnXq+6ZtvvvfzpZmzenvf53l+55z//zmvjHI4yufTEhAR44F1wCJgKvAdeA3cVb+0k1xTQESMA44AR4GJwAfgMzAZmAmMBa4DJ9R8XxnDABExBbhdsr4IdKsfaydExCRgJ3AK+ANsUp9XEYYASuYPgenARrW/amNJpBeYDSxTs8ph0QjoAk4CnY2HR8QeoFf91VDNU2BATa2qAUXQT0CPeqx+ZUSkDj+AzWpfw7f1wH1gpfqkkTBYQURsAW4BHfU9zw0RkeJ+A7aqqc+QiIi3QJ96uBXgDLBbnVHXgsx8THHSALALuFe+/1RT5EzgKjBLXdUK0AMsVFeUTdnztGJVXFP3l7VngW3qnFaA88Vy88qmCcDqUkFeuBvAOSBFzehXs6qsoDttrS5vBdiXAueNVVPQwWhDg4S+Uve2AuQoSBcdVBPUFiAi5gIvgB3qzUpAKfUKsKFo8bVO7BT6MnC61payPl2YFu0AFqi//wWYBjwD3pebPKRVDVXl4ReyYmCt+qiZG5rNopycD4rvD6n5PCRKW/LwNWXwdalpgGFRNU2z5EuZGfAOeFymaQ66JcBS4A1wAEjnpE2bQv71P+gEtgOLG/4HOS7u1HoeEcerICP2R6uDzFdf1no1YoDiqrykeR9iVABti9xs4f++G9EWNUviL5Bc4RmQtObrAAAAAElFTkSuQmCC);
      }
      .zoom-percent {
        width: 72px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        font-weight: 400;
      }
    }
    .layer-tools-rotate {
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin: 0 40px 0 30px;
      border-radius: 4px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABVUlEQVRIS9WUsStFURzHP98sisFIBqNnUAbZ7GajlyJ5T8lABhQDIzLKy2yQEoNMYsc/IIvB4q029dWt++p6ue+e+7jFqbP9vudzvr/v7xxR8FLB5/N3ALaHgV1JE3lcZzqw3QdsA7NAh6RMTfICqcW2u4DVeHcnRPvAK3AHPEpyK0ftAE6AAWAMeAY2JZ2lQTLtxi3aAWaSLbLdAywCa8ApUJX00QzKBDQEaSHbHgKugBtJc20DWvXZdgm4B8qSLoJCzjOKUa3tLWBS0kgqwHYnsA70BgDegT1J0URFgH7gBShJemrov2RguwwcAOcBgHHgWtJKIqcIsJRsUzOgAlQkjWYBbB9FNZKqCcADcCjpOM3BTwFR0DVJtX8LiMa1LqleiIPvcvvVkEMA88AyMJ01RcAG8CZpIfg3tT0I3OZ4aFOSLoMBAbfOXRL8m+Y+ORYUDvgEHXqZGXga6fEAAAAASUVORK5CYII=)
        no-repeat;
      &:hover {
        background-color: #5d5d5d;
      }
    }
  }
}
</style>