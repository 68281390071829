/**
 * 缩放单个染色体
 * @param {*} stage 
 * @param {*} controller 
 */
function scaleSingleChromo(stage, controller){
    const condition = {
        shiftKey : true,
        ctrlKey : true,
        getStatus : ()=>{
            return ['count','general','select'].indexOf(this.toolsType) > -1
        }
    }
    console.log(condition);
    stage.event.mousewheel(condition,({e})=>{
        // const shape = stage.isPointInner(e, false, 'polygon');
        if(!this.choosedChromo){
            return;
        }
        // const chromo = controller.getChromoByShape(shape);
        const chromo = this.choosedChromo;
        const item = this.originOptUrl[chromo.index];
        if(!item) return;
        const {maxHeight, target} = getMaxHeight(chromo);
        const min = controller.minChromoScale / controller.chromoScale;
        const max = maxHeight / controller.maxChromoScale / item.img.height;
        const scale = Math.max(min, Math.min(max, item.scale - 1 / e.deltaY));
        // console.warn(item.scale,scale, min, max);
        if(scale !== item.scale){
            item.scale = scale;
            scaleChromoImage(chromo,item,target);
        }
    })
    
    const scaleChromoImage = (chromo,item,target)=>{
        const element = document.createElement("canvas");
        const canvas = element.getContext('2d');
        const width = item.img.width * item.scale;
        const height = item.img.height * item.scale;
        element.width = width;
        element.height = height;
        canvas.drawImage(item.img, 0, 0, width, height);
        const base64 = element.toDataURL('image/png', 1.0);
        const image = new Image();
		image.width = width;
		image.height = height;
        image.src = base64;
        image.onload = ()=>{
            chromo.justUrl = base64;
            target.image = image;
            this.cachePool.checkBase64(this.chromoList);
            controller.reCreateChromoImages();
            controller.reCreateStandChromo();
        }
    }

    /**
     * 获取每行的最大高度
     * @param {*} chromo 
     */
    const getMaxHeight = (chromo)=>{
        let groupNum = 0;
        let target;
        Object.keys(controller.group).forEach(key => {
            const group = controller.group[key];
            group.list.forEach(item => {
                if (item.chromo === chromo) {
                    groupNum = parseInt(key);
                    target = item;
                }
            })
        })
        let row = 0;
        Object.keys(controller.groupRowRange).forEach(key=>{
            const item = controller.groupRowRange[key];
            if(item.min <= groupNum && groupNum <= item.max){
                row = parseInt(key) - 1;
            }
        })
        return {
            target,
            maxHeight : controller.rowsHeight[row]
        };
    }
}
export default scaleSingleChromo