<template>
    <div class="myIdentityBox">
        <!-- <div v-if="isSuccess == 0">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="$t('my_identity.expert_approve')" name="first">
                    <ExpertCertification @childFun="parentFun($event)"></ExpertCertification>
                </el-tab-pane>
                <el-tab-pane :label="$t('my_identity.organ_approve')" name="second">
                    <AgencyCertification @childFun="parentFun($event)"></AgencyCertification>
                </el-tab-pane>
            </el-tabs>
        </div> -->
        <!-- <div v-if="isSuccess == 1">
            <WaitingReview></WaitingReview>
        </div>
        <div v-if="!isSuccess || isSuccess == 2"> -->
        <CompleteExpertCertification></CompleteExpertCertification>
        <!-- </div> -->
        <!-- <div v-if="isSuccess == 3">
            <CompleteAgencyCertification></CompleteAgencyCertification>
        </div> -->
    </div>
</template>

<script>
import ExpertCertification from "./expertCertification";
import AgencyCertification from "./agencyCertification";
import WaitingReview from "./waitingReview";
import CompleteExpertCertification from "./completeExpertCertification";
import CompleteAgencyCertification from "./completeAgencyCertification";
export default {
  name: "MyIdentity",
  data() {
    return {
      activeName: "first",
      isSuccess: null,
    };
  },
  created() {
    // this.getQueryUserByToken();
  },
  methods: {
    // getQueryUserByToken() {
    //   this.$api.getQueryUserByToken({}).then((res) => {
    //     if (res.code == 200) {
    //       const { user } = res.data;
    //       //0：初始化  1：通过  2：不通过  3：审核中
    //       if (user.isExamine == 0 || user.isExamine == 2) {
    //         this.isSuccess = 0;
    //       } else if (user.isExamine == 1 && user.isExpert == 1) {
    //         this.isSuccess = 2;
    //       } else if (user.isExamine == 1 && user.isMechanism == 1) {
    //         this.isSuccess = 3;
    //       } else if (user.isExamine == 3) {
    //         this.isSuccess = 1;
    //       }
    //     } else {
    //       this.$router.push({
    //         path: "/",
    //       });
    //       this.$message.error(res.message);
    //     }
    //   });
    // },
  },

  components: {
    ExpertCertification,
    AgencyCertification,
    WaitingReview,
    CompleteExpertCertification,
    CompleteAgencyCertification,
  },
};
</script>

<style lang="scss" scoped>
.myIdentityBox {
  margin-top: 19px;
  margin-left: 30px;
}
</style>
