const common = {
    substrFun(accountName, isBrackets) {
        let bracketsLeft = "";
        let bracketsRight = "";
        if (isBrackets) {
            bracketsLeft = "(";
            bracketsRight = ")";
        }
        return accountName.length > 1
            ? bracketsLeft +
                  accountName.substr(0, 1) +
                  "*" +
                  accountName.substr(accountName.length - 1, 1) +
                  bracketsRight
            : bracketsLeft + accountName.substr(0, 1) + "*" + bracketsRight;
    },
    formatTime(sec) {
        if (sec == null) return "--";
        var date = new Date(sec);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var seconds = date.getSeconds();
        var time = [hour, minute, seconds].map(formatNumber).join(":");
        var date = [year, month, day].map(formatNumber).join("/");
        return date;
    }
};

export default common;
