const zh = {
    backto_case_overview: "返回系统分析页",
    connect_service: "联系客服",
    full_screen: "全屏",
    quit_screen: "退出全屏",
    srcEnOrZh:
        "https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b9781770a92254d9ee918b7e361bc49c85ea64ced08be35eb9b86ef9211d759edc9b3b5d8bcea6209f334fc19f435da214c",
    Pagnation: {
        Each_page: "每页",
        article: "条",
        determine: "确定",
        Article_total: "条/共",
        page: "页",
        message: "每页条数不能设置为空或为0",
        title: "操作提示"
    },
    main: {
        Cases_in_the_overview: "个人病例",
        message_graphics: "图形算法模块加载失败,请刷新页面重试!",
        adjunct: "附件",
        account_info: "账户信息",
        default_color: "默认颜色",
        theme_color: "主题颜色",
        standard_color: "标准颜色",
        more_color: "更多颜色..."
    },
    new_interaction: {
        sort: "排序：",
        mark: "打分",
        progress_bar: "进度",
        color_filter: "颜色筛选：",
        batch_analysis: "批量分析",
        batch_delete: "批量删除",
        enter_karyotype_analysis: "进入核型分析",
        glass: "玻片",
        age_check: "请输入1~200岁之间的年龄",
        Select_report: "请选择报告模板",
        patient_ame: "请输入患者姓名",
        Sex_selection: "请选择性别",
        Select_karyotype: "请选择核型图",
        addnew_report: "添加报告",
        Karyotype_diagram_statistics:
            "当前病例无人工确认排列的核型图，请先至少确认排列一张核型图",
        inspection_report: "染色体检查报告单",
        Case_information: "病例信息",
        Deletion_of_chromosome: "删除染色体",
        message: "是否确认恢复至原始状态?",
        message_title: "正在恢复至原始状态",
        message_err: "算法服务超时，请稍后重试！",
        message_karyotype:
            "该核型图已被删除，操作失败，请选择其他核型图进行操作!",
        message_num: "正在重新加载数据",
        ptimize_the_image: "优化图像",
        optimize_the_image_on: "正在优化图片",
        code: "编号",
        autovision_account: "账号",
        result: "处理结果",
        team_setup: "机构设置",
        waiting_start: "待开始",
        waiting_audit: "待审核",
        audit_pass: "审核通过",
        audit_nopass: "审核未通过",
        no_analysis: "未分析",
        completely: "已完成",
        case_allot: "病例分配",
        case_transfer: "病例转交",

        cases: "病例",
        consult_case: "会诊病例",
        case_all_check: "病例总览",
        case_analysis_completely: "病例分析已完成",
        complete_case_analysis: "完成病例分析",
        isComplete_all_work: "是否已经完成该病例的全部分析工作？",
        operate_success: "操作成功",
        cannot_transfer: "您目前没有合作机构，暂时不能进行病例转交"
    },
    caseAllocation: {
        filter_by_source: "按来源筛选：",
        filter_by_progress: "按进度筛选：",
        advanced_setup: "高级设置",
        filter_by_caseno: "按病例编号筛选：",
        filter_by_person: "按归属人筛选：",
        enter_caseno: "请输入病例编号",
        belonger: "归属人",
        progress: "进度",
        transfer_status: "转交状态",
        all_checked: "全选",
        checked: "已选择",
        all_cases: "总病例",
        assign_to: "分配给：",
        confirm_assigned: "确认分配",
        no_transfer: "未转交",
        transfered: "已转交",
        no_analysis: "未分析",
        auto_completetly_analysis: "分析中",
        manu_completetly_analysis: "已完成",
        choose_allot_cases: "请选择要分配的病例",
        choose_assigner: "请选择分配人",
        cannot_transfer: "注：已完成或已转交的病例不能再进行病例转交",
        co_agency: "合作机构：",
        confirm_transfer: "确认转交",
        choose_transfer_cases: "请选择要转交的病例",
        opinion_cannot_empty: "输入意见信息不能为空",
        files_50_most: "最多上传10个文件",
        oversize: "上传失败,单文件不超过10M",
        only_support_files: "仅支持上传pdf,doc,docx,xls,xlsx格式的文件",
        year: "年",
        month: "月",
        day: "日"
    },
    uploadPdf: {
        case_info_upload: "病例信息文件上传",
        intotal: "总计",
        uopload_progress: "个文件，上传总进度",
        setup: "设置",
        userful_core_tool: "万能核心工具",
        deep_analysis_tool: "深度分析工具",
        all_karyos: "全部染色体",
        add_organ: "添加机构",
        system_backstage: "系统后台",
        account_setup: "账号设置",
        Chinese: "中文",
        personal_space: "个人空间",
        expert_space: "专家空间",
        no_right_check: "您无权查看",
        personal_cases: "个人病例"
    },
    accountInfo: {
        prompt:'提示',
        phone_immediately:"立即充值",
        Close_remind:"关闭提醒",
        account: "账号：",
        organ_shortname: "机构简称：",
        enter_organ_shortname: "请输入机构简称",
        organ_name: "机构名称：",
        team_info: "团队信息",
        member: "成员",
        cases_num: "病例数",
        upload_cases_num: "累计上传病例数",
        super_manager: "超级管理员",
        organ_name_cannot_empty: "机构名称不能为空",
        organ_manage: "机构管理",
        organ_approve: "机构认证",
        department: "科室/部门：",
        audit: "审核中",
        audit_waiting: "审核需要1—2天，请耐心等待~>",
        audit_nopass: "审核未通过",
        re_auth: "请重新认证>",
        add_organ: "添加机构",
        auth_rights: "认证权益：",
        auth_pass_rights: "机构认证通过后，您可获得的权益：",
        auth_right_one: "1、机构能够邀请成员进行协作分析。",
        auth_right_two: "2、机构提供部分病例免费专家会诊服务。",
        auth_right_three:
            "3、机构提供转交病例分析服务（签订合作后，可将病例转交给合作机构进行分析）。",
        back: "返回",
        my_approve: "我的认证",
        my_identity: "我的身份",
        name: "姓名：",
        affiliated_unit: "所属单位：",
        add_identity_approve: "添加身份认证",
        my_rights: "身份认证通过后，您可获得的权益：",
        right_one: "1、您将可以使用平台提供的染色体核型图智能分析功能。",
        right_two: "2、您将可以使用平台提供的分析工具进行图像调整。",
        file_uploading: "资料上传中，请上传完成后再提交",
        imgName_cannot_include_special: "图片名称不能包含特殊字符",
        to: "至",
        startDate: "开始日期",
        endDate: "结束日期",
        case_no: "病例编号",
        status: "状态：",
        statusNo: "状态",
        analysis_account: "分析账号",
        case_name: "病例名称",
        case_source: "病例来源",
        get_money: "已到账",
        not_get_money: "未到账",
        charge: "充值",
        expert_reply: "专家会诊回复",
        widthdraw: "提现",
        auto_snalysis: "自动分析",
        transfer_service: "转交病例分析服务",
        expert_consult: "专家会诊",
        transfer_cass: "转交病例",
        sys_charge: "系统充值",
        sys_widthdraw: "系统提现",
        case_transfer_back_money: "病例转交服务返还费用",
        expert_consult_back_money: "专家会诊分析服务返还",
        head: "头像：",
        change_head: "更换头像",
        third_apply: "第三方应用：",
        bind: "绑定",
        need_include:
            "必须且只能包含字母、数字、下划线的两种或两种以上，且在6~25位之间",
        edit_success: "修改成功",
        certified: "已认证"
    },
    new_tools: {
        Counting_mode: "计数模式",
        pattern: "排列模式",
        Karyotypes_interchangeably: "核型排列互换",
        Arrange_all: "全部自动排列",
        Arranged_to_arranged: "排列区自动排列",
        daub: "涂抹",
        rotating: "旋转",
        delete: "删除",
        multidelete: "多选删除",
        Chromosome_amplification: "染色体放大",
        Chromosome_reduction: "染色体缩小",
        Auxiliary_marker: "辅助标记",
        optimize_the_figure: "优化图",
        original_figure: "原始图",
        current_count: "当前计数：",
        current_count1: "已计数染色体",
        Karyotype_expression: "核型表达式",
        Case_information: "病例信息",
        Case_number: "病例编号",
        name: "姓名",
        Years_of_age: "年龄",
        Sex_don: "性别",
        Sample_type: "样本类型",
        Receiving_date: "接收日期",
        Clinical_diagnosis: "临床诊断",
        note: "备注",
        Slide_number: "玻片编号",
        Cell_number: "细胞编号",
        optimize_the_image: "图像优化",
        Chain_erase: "锁链擦除",
        Standard_ribbon: "标准染色体",
        Fixed_belt: "定带",
        Homologous_double_sign_contrast: "同源双号对比",
        Homologous_order_number_comparison: "同源单号对比",
        Color_tag: "颜色",
        arrow_mark: "箭头标注",
        Placed_at_the_top: "置顶",
        The_mirror: "镜像",
        Export_images: "导出图片",
        The_eraser: "点擦除",
        Reautomatic_analysis: "重新自动分析",
        notation: "批注",
        Please_enter_image: "请输入对图像的批注",
        On_one_piece: "上一张",
        Confirm_count: "确认计数并下一张",
        Confirm_the_count_and: "确认计数+排列",
        Cancel_the_confirmation: "取消确认",
        The_next: "下一张",
        List_of_case_karyotypes: "病例核型图列表",
        Top_area: "置顶区",
        The_top_section: "非置顶区",
        Enter_the_note: "输入备注,并确认",
        Please_enter_the_contents: "请输入内容",
        cancel: "取消",
        determine: "确定",
        message: "正在加载数据",
        Is_cancelled: "正在取消",
        Cancelled_confirmation: "已取消确认"
    },
    information: {
        Case_number: "病例编号",
        name: "姓名",
        age: "年龄",
        gender: "性别",
        Sample_type: "样本类型",
        Receiving_date: "接收日期",
        Clinical_diagnosis: "临床诊断",
        note: "备注",
        Case_information: "病例信息",
        Please_enter_name: "请输入姓名",
        male: "男",
        female: "女",
        other: "其他",
        Please_enter_your_diagnosis: "请输入诊断结果",
        Please_enter_remarks: "请输入备注",
        determine: "确定",
        cancel: "取消",
        confirm: "当前有附件正在上传，是否继续提交？",
        files: "附件",
        upload_file: "上传文件",
        support_files: "支持扩展名：pdf,jpg,png",
        select_please: "请选择",
        day: "日",
        week: "周",
        month: "月",
        year: "岁"
    },
    metadata: {
        Glass: "玻片号：",
        All_slides: "全部玻片",
        progress_of: "进度：",
        progress_of_on: "进度",
        All_images: "所有图片",
        Automatic_analysis: "自动分析",
        Manual_counting_confirmation: "人工计数确认",
        Manual_alignment_confirmation: "人工排列确认",
        Number_of_slides: "玻片数量：",
        a_num: "个",
        graphics: "图片数量：",
        zhang: "张",
        Confirm_the_count: "确认计数",
        Article_45: "45条",
        zhang_Num: "张：",
        Article_46: "46条",
        Article_47: "47条",
        Confirm_the_arrangement: "确认排列",
        karyotype: "核型",
        No_analysis: "未分析"
    },
    nameSorting: {
        My_case: "我的病例",
        Sort_by_name: "按名称排序",
        Chronological_order: "按时间排序",
        time: "时间",
        Descending_order: "降序",
        name_of_the: "名称",
        ascending: "升序"
    },
    searchLink: {
        name_search: "请输入病例号/姓名",
        Additional_analysis: "追加分析"
    },
    primarySetup: {
        infrastructure: "设置",
        amplification_setting: "染色体放大设置",
        Chromosome_size: "染色体大小：",
        Chromosome_Amplification: "启用染色体放大：",
        Analysis_of_setting: "分析设置",
        Count_the_number: "计数数量：",
        Please_enter_the: "请输入",
        zhang: "张",
        Arrange_the_number: "排列数量：",
        message: "说明：分析计数和排列达到数值时，即视为分析完成",
        Restore_the_default: "恢复默认",
		analysisModeDefault: "默认分析模式：",
		hasHideNumber: "是否隐藏染色体编号：",
		arrangeMode: "排列模式",
		counterMode: "计数模式",
		hide: "隐藏",
		show: "不隐藏",
        cancel: "取消",
        save: "保存",
        Set_up_success: "设置成功"
    },
    progressSetup: {
        Already_count: "已计数",
        Has_been_arranged: "已排列",
        Zhang_karyotype: "张核型",
        Analysis_of_setting: "分析设置",
        message: "说明：分析计数和排列达到数值时，即视为分析完成",
        Count_the_number_of: "计数数量：",
        Arrange_the_number: "排列数量：",
        Please_enter_the: "请输入",
        cancel: "取消",
        save: "保存",
        Set_up_the_success: "设置成功"
    },
    teamSeting: {
        case: "病例",
        unit: "元",
        automatic: {
            title: "自动分析",
            low: {
                title: "低质病例",
                desc: "优质核型图占比60%以下"
            },
            secondary: {
                title: "中等病例",
                desc: "优质核型图占比60%以上"
            },
            high: {
                title: "优质病例",
                desc: "优质核型图占比90%以上"
            }
        },
        transfer: {
            title: "转交病例设置",
            on: "开启",
            off: "关闭",
            agencyLabel: "合作机构：",
            placeholder: "请选择",
            desc: "单病例人工分析费用：",
            method: "合作方式：",
            method_1: "上传后自动发送给合作机构",
            method_2: "手动选择病例发送给合作机构"
        },
        analysis: {
            title: "病例分配设置",
            title1: "是否启用病例分配：",
            title2: "是否自动分配病例："
        },
        service: {
            title: "病例转交分析服务",
            cost: "单病例人工分析费用：",
            btnSubmit: "提交申请",
            btnSuccess: "待审核",
            tip:
                "若需开通病例转交服务，请与工作人员联系：0571-89055921 ，签订合作协议"
        }
    },
    members: {
        addMembers: "添加成员",
        name: "姓名",
        color: "颜色",
        role: "角色",
        username: "用户名",
        phone: "手机号",
        operation: "操作",
        remove: "移除",
        invite: "邀请成员",
        administrator: "管理员",
        seniorAnalyst: "高级分析师",
        analysts: "分析师",
        inspector: "查看员",
        copy: "复制链接",
        setting: "账号设置",
        member: "成员管理",
        patient: "病例管理",
        analyse: "病例分析",
        recheck: "复检",
        report: "报告管理",
        view: "查看病例",
        copied: "已复制到粘贴板",
        sended: "已发送邀请链接至短信",
        tip: "提示",
        success: "移除成功",
        fail: "移除失败",
        del: "确定要移除该成员吗？",
        nickname: "昵称",
        mesg_invite: "短信邀请",
        link_invite: "链接邀请",
        color_success: "颜色修改成功",
        name_success: "昵称修改成功",
        role_success: "角色修改成功",
        want_copy_content: "这里表示想要复制的内容"
    },
    noPermission: {
        tip: "没有权限访问该页面，点击返回",
        home: "病例总览页面"
    },
    checkhtml: {
        top_up:"充值",
        inform: "通知",
        human_online_diagnose_center: "人类染色体在线诊断咨询中心",
        preview_version: "(预览版)",
        surface_line1:
            "中国人类遗传在线诊断咨询中心部分功能，非chrome浏览器，存在兼容问题",
        surface_line2: "通过智能分析、异地诊断报告、疑难病例远程MDT等方法",
        surface_line3: "整合医疗资源，提升病患群体服务质量",
        surface_line4: "浙ICP备17045030号 浙公网安备33011002014406号",
        remind: "推荐使用Google Chrome浏览器以获得最佳产品体验。",
        install_chrome: "点击下载安装Chrome",
        analysis_tool: "online autovision 分析工具",
        analysis_system: "分析系统",
        privacy_statement: "隐私声明",
        service_agreement: "服务协议",
        pay_page: "支付页面",
        custom_template: "自定义报告模板",
        no_data: "暂无数据"
    },
    login: {
        login_width_num: "账号密码登录",
        input_num: "请输入账号/手机号",
        input_password: "请输入密码",
        input_password_warn: "请输入密码",
        mesage_login: "短信验证登录",
        input_tel: "请输入手机号码",
        input_tel_warn: "请输入手机号码",
        input_verifycode: "请输入验证码",
        get_verifycode: "获取验证码",
        login: "登录",
        new_register: "新用户注册",
        free_register: "免费注册",
        tologin: "已有账号？去登录",
        input_login_num: "请输入账号",
        telnum_notCorrect: "手机号码格式不正确，请重新输入",
        verifycode_cannot_empty: "验证码不能为空",
        verifycode_notCorrect: "验证码不正确，请重新输入",
        agree_deal: "请先同意隐私声明和服务协议",
        complete_smart_verify: "请先完成智能验证",
        Chinese_online_consult_center: "中国人类遗传在线诊断咨询中心",
        no_account: "还没有账号？",
		invitation: "您是否同意加入"
    },
    smart_verify: {
        hit_verify: "点击按钮开始智能验证",
        smart_verifying: "智能检测中...",
        verified_success: "验证成功",
        complete_smart_verify: "请完成智能验证",
        verify_failed: "验证失败，请在此点击按钮刷新"
    },
    case_overview: {
        my_case: "我的病例",
        case_manage: "病例管理",
        case_allot: "病例分配",
        input_caseNumAndName: "输入病例号、姓名",
        karyoImage: "核型图",
        report: "诊断报告",
        auto_analysis: "自动分析",
        add_analysis: "追加分析",
        slide_num: "玻片数",
        karyoImage_num: "核型图数",
        confirm_count: "确认计数",
        confirm_array: "确认排列",
        manual_count: "人工计数",
        small_image: "缩略图",
        array_image: "排列图",
        metaphase_image: "中期分裂相图",
        slide: "玻片",
        cell: "细胞",
        count_num: "计数数量",
        count_confirm: "计数确认",
        array_confirm: "排列确认",
        karyotype_express: "核型表达式",
        no_confirm: "未确认",
        confirmed: "已确认",
        nocase_tocheck: "暂时没有病例可查看",
        delete: "删除",
        goto_case_analysis_page: "进入分析工具页",
        analyzing: "分析进行中",
        auto_analyzing: "正在进行自动分析…",
        add_consult_opinion: "添加会诊意见",
        check_consult_opinion: "查看会诊意见",
        recheck_pass: "复审通过",
        recheck_cancel: "取消复审",
        submit_expert_consult: "提交专家会诊"
    },
    case_manage: {
        case_import: "病例导入",
        defined_import: "自定义导入",
        case_output: "病例导出",
        import_single_case: "导入单个病例",
        import_case_data: "导入病例数据库",
        import_autovision_case: "导入AutoVision病例",
        import_file: "导入文件夹",
        autovision_upload: "AutoVision病例上传",
        cases_upload: "病例上传",
        total: "总计",
        upload_progress: "个病例，上传总进度：",
        uploading: "正在上传",
        analysising: "正在分析",
        analysis_complete: "分析完成",
        retry: "点击重试",
        upload_time: "上传剩余时间",
        upload_failed: "上传失败",
        cases_exist: "病例已存在",
        waiting_upload: "等待上传",
        upload_completely: "上传完成",
        upload_successfully: "已成功上传",
        last: "个、剩余",
        upload_failed_nums: "个、上传失败",
        pieces: "个",
        stop: "停止",
        confirm: "确认",
        upload_remind: "上传提示",
        use_all: "应用全部",
        keep_both: "保留两者",
        cancel: "取消",
        change: "替换",
        unfind_rechoose: "未检索到病例，请重新选择！",
        rechoose: "重新选择",
        searching_case: "正在检索病例",
        only_show_choosed_cases: "仅显示指定时间段的病例：",
        end_time: "最后修改时间",
        size: "大小",
        choosed: "已选择",
        all_cases: "个病例，共",
        images_upload_progress: "张图片，上传总进度：",
        time_remaining: "剩余时间：",
        waiting: "等待中",
        saving: "正在保存",
        upload_success: "上传成功",
        save_failed: "保存失败",
        searching: "搜索",
        cases: "个病例",
        case_edit_time: "病例修改时间",
        only_export_choosed_images: "仅导出所选病例中的特定图片：",
        confirmed_images: "经人工计数、人工排列确认的图片",
        export_all_images: "导出所有图片",
        only_confimed_array_images: "仅人工排列确认的图片",
        export: "导出",
        export_progress: "个病例，导出总进度：",
        exporting: "正在导出",
        export_completely: "导出完成",
        export_failed: "导出失败",
        export_successfully: "导出成功",
        counting: "正在计算",
        new: "新",
        old: "旧",
        exist_name: "已经存在名称为",
        more: "的较",
        de_cases: "的病例",
        want_change: "您要用正在上传的病例替换它吗？",
        exceed: "超过",
        days: "天",
        is_counting: "计算中",
        completely: "已完成",
        no_complete: "个病例，未完成",
        confirm_stop: "个病例，是否确认停止?",
        continue: "个可导出病例，是否继续？",
        all: "一共",
        no_image_rechoose: "没有符合的图片，请重新选择！",
        search_by_casecode: "请输入病例编号搜索",
        lately_1week: "最近一周",
        lately_1month: "最近一个月",
        lately_3month: "最近三个月",
        lately_6month: "最近六个月",
        lately_1year: "最近一年",
        overlimit: "数量超过限制",
        overlimit_c: "单病例核型图数量超过${0}张，无法上传"
    },
    account_num: {
        data_manage: "数据管理",
        personal_setup: "个人设置",
        chinese_lang: "中文简体",
        exit_system: "退出系统"
    },
    case_analysis: {
        confirmed_count: "已确认计数：",
        confirmed_array: "已确认排列：",
        image_num: "图片编号",
        array_image: "排列图",
        tool_kit: "工具箱",
        mesophase: "中期分裂相",
        original_image: "原图",
        optimization_image: "优化图",
        karyoImage_list: "核型图列表",
        choose_band_info: "选择条带信息",
        powerful_tools: "万能工具",
        quondam_image: "原图",
        karyoArray_change: "核型/排列图互换",
        powerful_core_tools: "万能核心工具",
        depth_analysis_tools: "深度分析工具",
        current_count: "当前计数",
        karyotype_express: "核型表达式",
        annotation: "批注",
        input_analys_content: "请输入分析内容",
        need_recheck: "本图需要复检",
        confirm_count: "确认计数",
        confirm_array: "确认排列",
        save: "保存(ctrl+s)",
        save_exit: "保存并退出",
        load_karyoImage: "下载核型图",
        load_arrayImage: "下载排列图",
        single: "单",
        double: "双",
        num_karyo_compare: "号染色体对比",
        num_karyo: "号染色体",
        waited_array: "待排列",
        brush_thickness: "画笔粗细",
        brush_thickness1: "橡皮擦大小",
        case: "病例",
        close_case: "正在操作中，即将关闭该页面",
        loading_data: "正在加载数据"
    },
    tools_tip: {
        undo: "撤销",
        redo: "恢复",
        karyo_array_change: "核型/排列图互换",
        count: "计数",
        powerful_tool: "万能工具",
        autoArray_pendings: "自动排列待排列染色体",
        autoArray_all: "自动排列所有染色体",
        sameKaryos_contrast: "同号染色体对比",
        twoKaryos_contrast: "双号染色体对比",
        standard_karyos: "标准染色体",
        arrows_mark: "箭头标注",
        choose: "选择",
        delete: "删除",
        eraser: "橡皮擦",
        area_wipe: "区域擦除",
        smeartoAdd: "涂抹添加",
        karyos_connect: "染色体连接",
        karyos_cut: "染色体切割",
        karyos_cross_cut: "染色体十字切割",
        eade_cut: "边缘切割",
        rotate: "旋转"
    },
    powerful_tools: {
        line1: "1. Alt+单击视图空白区切换左右视图",
        line2: "2. 按下鼠标左键移动进行视图拖放",
        line3: "3. 左右视图中鼠标滚轮滚动进行视图缩放",
        line4: "4. 点击W键显示或隐藏染色体边缘和计数标识",
        line5: "5. 按下D键,Delete,Backspace或鼠标滚轮键将选中染色体删除",
        line6: "6. 按下鼠标右键划线进行画线切割",
        line7: "7. 按下E+鼠标左键移动鼠标使用区域擦除",
        line8: "8. 按下Ctrl+Z键撤销上一步操作",
        line9: "9. 按下Ctrl+Y键恢复上一步操作",
        line10: "10. 按下Ctrl+S键保存当前操作结果",
        line11: "11. 按下Ctrl+鼠标左键旋转鼠标旋转染色体",
        line12: "12. “左键点击”拖动染色体",
        line13: "13. 按下Ctrl+鼠标滚轮缩放染色体",
        line14: "14. 按下Ctrl+Shift+鼠标左键点击，染色体镜像旋转",
        title: "在中期分裂相中",
        line15: "15. 鼠标左键点中染色体按住移动到相邻染色体进行连接",
        line16: "16. 在染色体处按下鼠标右键进行推荐切割",
        line17: "17. 按下Shift键加上鼠标左键进行涂抹操作",
        line18: "18. 万能工具下按下X键加上鼠标右键进行十字切割",
        same_karyo_compare:
            "在【中期分裂相】中，鼠标左键点击染色体，弹出染色体同号对比界面",
        double_karyo_compare:
            "在【中期分裂相】中，鼠标左键点击染色体，弹出染色体双号对比界面",
        mark_karyo:
            "在【中期分裂相】或者【排列图】中，按下鼠标左键，拖动鼠标，进行标注",
        delete_mark: "鼠标右键点击箭头标注，删除标注",
        delete_arrow: "鼠标右键点击箭头，删除箭头",
        choose: "选择",
        karyo_moving: "染色体移动",
        delete_tools: "删除工具",
        area_wipe: "区域擦除工具",
        delete_choosed_karyo:
            "在【中期分裂相】或者【排列图】中，鼠标左键按下，删除命中的染色体",
        delete_choosed_arrow:
            "在【中期分裂相】或者【排列图】中，鼠标左键按下，删除命中的箭头",
        complete_wipe: "在【排列图】中，圈出染色体旁边的噪点杂质，完成擦除",
        add_tool: "涂抹添加工具",
        add_one_karyo:
            "在【中期分裂相】中，按下鼠标左键，移动鼠标，添加一条染色体",
        connect_tool: "连接工具",
        connect_to_one_karyo:
            "在【中期分裂相】中，鼠标移动到连接染色体上，将染色体连接为一体",
        cutting_tool: "划线切割工具",
        to_cut_karyo:
            "在【中期分裂相】或者【排列图】中，鼠标右键按下拖动鼠标，实现划线切割",
        cross_cutting: "十字切割",
        cross_cut_karyo:
            "在【中期分裂相】中，按下鼠标右键，命中染色体十字交叉中点，进行十字切割",
        edge_cutting_tool: "边缘切割工具",
        cut_choosed_karyo:
            "在【中期分裂相】或者【排列图】中，鼠标左键按下拖动鼠标，切割命中的染色体",
        rotating_karyo: "染色体旋转",
        rotate_karyos: "在【排列图】中，染色体旋转",
        click_to_count: "点击染色体进行计数点"
    },
    report: {
        print_report: "打印报告",
        edit_report: "编辑报告",
        delete_report: "删除报告",
        addnew_report: "新增报告",
        cannot_empty: "不能为空",
        input_content: "请输入内容",
        choose_date: "选择日期",
        image: "图片",
        karyoImage_upload_fully: "请将核型图上传完整",
        arrayImage_upload_fully: "请将排列图上传完整",
        add_success: "新增成功",
        modify_success: "修改成功",
        template_review: "模板预览"
    },
    personal_setup: {
        title: "个人设置",
        personal_info: "个人信息",
        account_info: "账号信息",
        my_identity: "我的身份",
        work_setting: "工作设置",
        account_manage: "账号管理",
        income_expenses_details: "收益记录",
        report_template: "报告模板",
        optimize_setup: "优化设置",
        member_manage: "成员管理"
    },
    personal_info: {
        user_name: "用户名：",
        real_name: "真实姓名：",
        password: "密码：",
        not_setup: "未设置",
        setted_up: "已设置",
        input_oldpsw: "请输入旧密码",
        input_newpsw: "请输入新密码",
        reinput_newpsw: "请重新输入新密码",
        bind_tel: "绑定手机：",
        input_tel: "请输入手机号",
        remind:
            "提示：手机注册用户修改手机号码后，登录名也一并修改，且半年内不能再修改，请谨慎填写！",
        bind_email: "绑定邮箱：",
        input_emial: "请输入您的邮箱",
        edit: "编辑",
        foldup: "收起",
        no_bind: "未绑定",
        bind: "绑定",
        name_demand:
            "必须且只能包含字母、数字、下划线的两种或两种以上，且在6~25位之间",
        input_correct_tel: "请输入正确的手机号码",
        input_correct_email: "请输入正确的邮箱",
        name_updated: "姓名已更新",
        password_updated: "密码已更新",
        tel_updated: "手机号码已更新",
        email_updated: "邮箱已更新",
        retry: "s后重发"
    },
    my_identity: {
        expert_approve: "专家认证",
        organ_approve: "机构认证",
        finish_expert_identity_verify: "完成身份认证",
        input_name: "请输入姓名",
        input_organName: "请输入机构名称"
    },
    expert_approve: {
        name: "姓名:",
        input_name: "请输入您的姓名",
        the_unit: "所在单位:",
        the_office: "所在科室/部门:",
        input_office_name: "请输入您所在科室/部门的名称",
        office_tel: "科室/部门电话:",
        input_office_tel: "请输入您所在科室/部门的电话号码",
        work_card: "工作胸牌:",
        card_demand: "请确保头像、姓名、医院名称等信息清晰可见",
        medical_practice_certificate: "医师执业证书:",
        person_photo_demand: "请拍摄帧数清晰照片，需包含姓名、个人照片及钢印",
        photo_demand:
            "请拍摄证书清晰照片，需包含姓名、个人照片及钢印、证书编码、置业意愿",
        license_code: "执业证编码:",
        input_license_code: "请输入医师执业证书编码",
        doc_credentials: "医师资格证书:",
        certification_code: "资格证编码:",
        front_back_IDcard: "身份证正反面:",
        input_IDcode: "请输入您的身份证",
        IDcode: "身份证号码:"
    },
    organize_approve: {
        organName: "机构名称:",
        input_organName: "请输入您所在单位的完整名称",
        organName_demand:
            "请根据组织机构代码证填写机构名称，限中文、数字或字母",
        organ_intrd: "什么是组织机构代码证? ",
        officeName: "科室/部门名称:",
        input_officeName: "请输入您所在科室/部门",
        organ_type: "机构类型:",
        public_hospital: "公立医院",
        private_hospital: "民营医院",
        theother_lab: "第三方医检所",
        organ_site: "机构地址:",
        detail_site: "详细地址",
        organ_site_demand: "请根据组织机构代码证填写地址",
        organ_code: "组织机构代码:",
        organize_code: "组织机构代码",
        unify_cridet_code: "统一社会信用代码",
        organName_length_demand: "长度在 2 到 30 个字符",
        check_deal: "请勾选协议",
        upload_image_demand: "上传图片大小不能超过 10MB!",
        input_certification_code: "请输入医师资格证书编码",
        organ_code_jpg: "范例：组织机构代码.jpg",
        organ_code_demand:
            "请输入9位组织机构代码，如12345678-9；或18位的统一社会信用代码",
        organ_code_certificate: "组织机构代码证明证件:",
        organ_code_certificate_demand:
            "请上传组织机构代码或统一社会信用代码的证明文件,组织机构代码证或三证合一证原件扫描件(复印件需加盖公章)支持上传pdf、jpg、png、 jpeg，多次上传，保留最新的上传内容（文件大小不超过1M） ",
        medc_inst_licensNum: "医疗机构执业许可证登记号:",
        medc_inst_licens_nums: "医疗机构执业许可证登记号",
        medc_inst_licens_jpg: "范例：医疗机构执业许可证.jpg",
        input_licensNum: "请输入22位医疗机构执业许可证登记号，限数字或者字母",
        medc_inst_licens_colon: "医疗机构执业许可证:",
        medc_inst_licens: "医疗机构执业许可证",
        medc_inst_licens_demand:
            "请上传医疗机构执业许可证原件扫描件(复印件需加盖公章)，支持上传pdf、jpg、png、jpeg，多次上传，保留最新的上传内容（文件大小不超过1M）",
        complete_inst_licens: "完成机构身份认证",
        office_name: "科室名称:",
        medc_inst_licens_document: "医疗机构执业许可证证件:",
        no_organ_code_licens:
            "若没有《组织机构代码证》可以使用三证合一或者五证合一后的《事业单位法人证书》证件，根据此证件填写上传即可。"
    },
    work_setting: {
        analysis_case_num: "可分析病例数量",
        analysis_case_cycle: "可分析病例数周期",
        enable_cases_allot: "是否启用病例分配",
        auto_cases_allot: "是否自动分配病例",
        opened: "已开启",
        closed: "已关闭"
    },
    account_manage: {
        failure_payment:"支付失败",
        Retry:"重试",
        Return_to_the_system:"秒后将自动返回支付界面",
        Payment_success:"支付成功",
        Payment_success_title:"预计2小时内到账，如超时未到账，请及时联系客服",
        Return:"返回",
        Cancel_payment:"取消支付",
        complete_payment:"请扫描二维码完成支付",
        account_balance: "账号余额",
        all_money: "总金额(元)",
        account_manage: "账号管理",
        Alipay: "支付宝",
        Wechat: "微信",
        bankcard: "银行卡",
        back: "返回",
        account_recharge: "账号充值",
        pay_width_Alipay: "支付宝支付",
        pay_width_Wechat: "微信支付",
        recharge_in: "确定支付",
        real_name: "真实姓名:",
        input_real_name: "请输入您的真实姓名",
        Alipay_code: "支付宝账号:",
        AlipayCode_tel: "支付宝账号/手机号码",
        input_Alipay_code: "请填写支付宝账号",
        input_correct_AlipayCode: "请填写正确的支付宝账号",
        name_cannot_empty: "姓名不能为空",
        add_bank_code: "添加银行卡",
        bank_code: "银行卡号:",
        input_bank_code: "请输入银行卡号",
        bank_open_site: "开户行:",
        input_bank_open_site: "请输入银行卡开户行",
        input_bankCode: "请填写银行卡账号",
        input_correct_bankCode: "请填写正确的银行卡账号",
        bank_openSite_cannot_empty: "开户行不能为空",
        add_Wechat: "添加微信",
        telNum: "手机号码:",
        input_Wechat_tel: "请填写微信绑定的手机号码",
        Wechat_pay_site:
            "微信收款请在“我”>“支付”>“…”“开启允许通过手机号向我转账”",
        as_show: "如图所示：",
        agree_payIn_searchTel: "允许通过手机号向我转账",
        input_telNum: "请填写手机号码",
        input_correct_telNum: "请填写正确的手机号码",
        name: "姓名:",
        account_last: "账号余额:",
        paying_mode: "支付方式:",
        money_choose: "金额选择:",
        other_amount: "其他金额",
        pay_amount: "支付金额:",
        account_widthdraw: "账号提现",
        gathering_mode: "收款方式:",
        withdrawAmount: "提现金额:",
        input_withdrawAmount: "请输入提现金额",
        creat_withdrawAcount_first: "请先创建提现账号",
        withdrawAmount_moreThan_one: "提现金额不能小于1",
        no_bind_Alipay: "未绑定支付宝，点击添加",
        no_bind_Wechat: "未绑定微信，点击添加",
        no_bind_bankcard: "未绑定银行卡，点击添加",
        goto_pay_page: "跳转到支付页面",
        add_Alipay: "添加支付宝"
    },
    optimize_setting: {
        remind_words:
            "提醒：图像优化设置影响所有核型图片显示效果，请谨慎修改。",
        optimize_choices: "优化选项",
        current_setup: "此选项为当前设置",
        parm_setup: "参数设置",
        denoising: "去噪",
        brightness: "亮度",
        acuity: "锐度",
        radius: "半径",
        contrast: "对比度",
        levels: "色阶",
        effect_preview: "效果预览",
        uploadImage: "上传图片",
        image_alayzing: "图片分析中...",
        uploading: "上传中...",
        save_use: "保存并使用",
        reset_parm: "重置参数",
        introduce: "说明：",
        intrd_line1: "1、CSIE：高兼容性的适配优化不同类型图像。",
        intrd_line2:
            "2、LKIE：在进行通用优化的基础上，更擅长于百倍物镜所拍摄图像的优化，且最高程度的保留细节。",
        intrd_line3:
            "3、MCIE：适合于低清晰度图像的优化，同时较好的支持百倍物镜所拍摄图像。",
        intrd_line4:
            "4、TAIE：适合标本质量欠佳，有染色体过度溶解等问题的核型图像。",
        intrd_line5: "5、BAIE：适合所有样本。",
        reset: "已重置",
        attention: "注意",
        upload_module_failed:
            "图像优化模块加载失败，图像优化结果预览不可用，请在刷新后重试！"
    },

    income_expenses_details: {
        begin_end_time: "起止时间",
        createTime: "创建时间",
        income_expense_amount: "收支金额(CNY)",
        record_code: "记录编号",
        income_expense_mode: "收支类型",
        no_record: "当前未生成记录"
    },
    report_template: {
        report_template: "报告模板",
        addnew_report: "新建空白模板",
        recommend_template: "推荐模板",
        use_this_template: "使用此模板",
        preview_image: "预览图",
        no_cases: "当前账户没有病例，请先上传病例"
    },
    selfDefined_template: {
        official_control: "官方控件",
        add_selfcontrol: "添加自定义控件",
        designation: "名称",
        label: "标签",
        date: "日期",
        select_box: "选择框",
        lable_words_box: "标签+文本域",
        title_box: "标题类框",
        words_box: "文本域",
        image: "图片",
        "e-signature": "电子签名",
        border: "边框",
        line: "横线",
        words: "文字",
        font: "字体",
        size: "大小",
        thickness: "粗细",
        color: "颜色",
        site: "位置",
        up: "上",
        left: "左",
        width: "宽",
        height: "高",
        condition_choose: "条件选择",
        change_size: "可更改尺寸",
        move: "可移动",
        show_border: "显示边框",
        set_required: "设为必填",
        date_mode: "日期格式",
        select_content: "下拉框内容",
        input_content: "请输入内容",
        add: "添加",
        special_choose: "特殊选项",
        metaphase_image: "中期分裂相图",
        relation: "关联",
        num_arrayImage: "号排列图",
        num_karyoImage: "号核型图",
        input_words: "请输入文字",
        saving: "保存中...",
        already_exit_code: "已经存在此编号",
        image_upload_demand:
            "图片名称不能包含除汉字、数字、字母和（）()_-以外的字符",
        image_size_demand: "上传图片格式不正确或者上传图片大小超过 10MB!",
        template_name_demand:
            "模板名称不能包含除汉字、数字、字母和（）()_-以外的字符",
        input_template_name: "请输入模板名称",
        personal_setup: "个人设置页",
        create_template: "创建模板",
        save_success: "保存成功",
        templateName_repeat: "模板名称有重复，请重新输入",
        need_toSave: "是否需要保存",
        dotted_line: "设为虚线",
        upload_esignature: "上传电子签名",
        selfdefined_template: "自定义模板"
    },
    template_choices: {
        SimSun: "宋体",
        SimHei: "黑体",
        Microsoft_YaHei: "微软雅黑",
        font_one: "一号",
        font_smallone: "小一",
        font_two: "二号",
        font_smalltwo: "小二",
        font_three: "三号",
        font_smallthree: "小三",
        font_four: "四号",
        font_smallfour: "小四",
        font_five: "五号",
        font_smallfive: "小五",
        font_slender: "纤细体",
        font_normal: "常规体",
        font_bold: "加粗体"
    },
    unit: {
        piece: "张",
        ge: "个",
        money: "¥",
        yuan: "元",
        num: "号",
        seconds: "秒"
    },
    radio_button: {
        all: "全部",
        no_analysis: "未分析",
        auto_analysis: "自动分析",
        manual_confirm_count: "人工计数确认",
        manual_confirm_array: "人工排列确认",
        one_day: "一天",
        aWeek: "一周"
    },
    checkbox_button: {
        all: "全选",
        need_recheck: "需要复检",
        agreeto_observe: "我同意并愿意遵守",
        privacy_statement: "《隐私声明》",
        service_agreement: "《服务协议》",
        and: "和",
        hide_karyos: "隐藏性染色体"
    },
    select_button: {
        all: "全部",
        choose: "请选择",
        choose_site: "选择省/市/区",
        startDate: "开始日期",
        endDate: "结束日期",
        alloted: "已分配",
        no_allot: "未分配",
        lately_7days: "最近7天",
        lately_30days: "最近30天",
        lately_1year: "最近一年",
        income: "收入",
        expenditure: "支出",
        Alipay: "支付宝",
        Wechat: "微信",
        income_consultion: "会诊收入",
        widthdraw_toAlipay: "提现到支付宝",
        widthdraw_toWechat: "提现到微信",
        widthdraw_toPaypal: "提现到paypal",
        widthdraw_toBankCard: "提现到银行卡"
    },
    popup: {
        add_analysis: {
            title: "追加分析",
            content: "张图片，是否继续？",
            cancel_add: "取消追加",
            contentNext:
                "张图片（含有低质量核型图，不适合继续分析），是否继续？",
            contentAnalysis: "含有低质量核型图，不适合继续分析,是否继续？"
        },
        case_allot: {
            title: "病例分配",
            filterby_uploadPerson: "按上传人员筛选",
            filterby_status: "按状态筛选",
            filterby_uploadTime: "按上传时间筛选",
            search: "搜索",
            manual_assign: "手动分配",
            case_code: "病例编号",
            operator: "操作人",
            uploadDate: "上传日期",
            assigner: "分配人",
            status: "状态",
            alloted: "已分配",
            allocation_date: "分配日期",
            operate: "操作",
            allot_case: "分配病例"
        },
        allot_case: {
            title: "选择分配病例",
            choose_user: "请选择用户"
        },
        addnew_report: {
            title: "新增报告",
            choose_report_template: "报告模板选择",
            edit_title: "编辑报告"
        },
        editAcct_remind: {
            title: "您将要修改绑定的账号, 是否继续?",
            no_more_than_20: "输入金额不能超过20位"
        },
        edit_setting: {
            title: "工作设置修改",
            analysis_case_num: "单人每天可分析病例数量:",
            input_case_num: "请输入可分析的病例数量",
            analysis_case_cycle: "可分析病例数周期:",
            enable_cases_allot: "是否启用病例分配:",
            auto_cases_allot: "是否自动分配病例:"
        },
        examine: {
            consult_ques_desc: "专家会诊",
            ques_desc: "问题描述:",
            ques_desc_words: "请输入问题描述文本",
            consult_answer: "专家会诊回复：",
            expert_answer_words: "专家会诊解答文本",
            input_ques_desc_words: "请输入问题描述文本",
            input_words_demand: "请输入10~140个字的内容",
            input_expert_answer_words: "请输入专家会诊解答文本",
            pass: "通过",
            submit: "提交",
            submit_evaluate: "提交评价",

            choose_expert: "选择专家：",
            show_case: "是否显示病例信息：",
            yes: "是",
            no: "否",
            problem_describe: "问题描述：",
            expert_reply: "专家会诊回复：",
            problem_cannot_empty: "问题不能为空",
            content_cannot_empty: "回复不能为空",
            file_type_error: "上传文件格式错误或超过10M，请重新上传"
        },
        edge_cutting: {
            title: "染色体切割",
            generate_image: "生成图片",
            reload: "重载继续",
            undo: "撤销",
            operation_tip: "操作提示：",
            press_toMove: "按下鼠标左键移动进行视图拖放",
            mouseWheel_toZoom: "鼠标滚轮滚动进行视图缩放",
            rightClick_toCut: "按下鼠标右键划线进行画线切割",
            leftClick_toChoose: "左键点中染色体选择切割后图片",
            cut_first: "请先划线进行切割",
            choose_karyo: "请点击染色体选择",
            add6_atMost: "最多允许添加6个",
            cut_failed: "切割失败，请重载继续"
        },
        old_report: {
            addnew_report: "新增报告",
            organ_name: "机构名称:",
            input_organ_name: "请输入机构名称",
            templateName_choose: "报告模板选择:",
            name: "姓名:",
            input_name: "请输入患者姓名",
            age: "年龄:",
            input_age: "请输入年龄",
            sex: "性别:",
            man: "男",
            female: "女",
            sample_type: "样本类型:",
            karyo_checked_result: "染色体检查结果:",
            input_checked_result: "请输入染色体检查结果",
            sample_collect_date: "标本采集日期:",
            collect_date_choose: "请选择标本采集日期",
            sample_receive_date: "标本接收日期:",
            receive_date_choose: "请选择标本接收日期",
            inspect_doc: "送检医生:",
            input_inspect_doc: "请输入送检医生",
            clinic_desc: "临床描述:",
            input_clinic_desc: "请输入临床描述",
            karyo_image_choose: "核型图选择:",
            choose_karyo_image: "选择核型图",
            image_uploading: "图片上传中",
            please_choose_karyoImage: "请选择核型图",
            blood: "外周血",
            amniotic_fluid: "羊水",
            villus: "绒毛血",
            marrow: "骨髓"
        },
        change_karyo: {
            title: "切换核型图",
            content: "页面已修改，是否保存？",
            changing: "正在切换",
            arraying: "正在排列",
            auto_array: "自动排列染色体",
            confirm_counter: "您确定要确认染色体计数吗？",
            confirm_arrange: "您确定要确认染色体计数+排列吗？"
        },
        warning: {
            remind: "提示",
            waitedKaryos_cannotTo_beCompared: "待排列染色体不能进行对比",
            longtime_remind: "由于您长时间未操作，为确保安全，请重新进入！",
            analyzing_try_later: "已有用户正在分析当前病例，请稍后再试！",
            move_karyo: "移动染色体",
            connect_karyo: "连接染色体",
            add_count_dot: "添加计数点",
            delete_count_dot: "删除计数点",
            karyo_cutting: "染色体切割",
            karyo_recmd_cutting: "染色体推荐切割",
            delete_karyo: "删除染色体",
            arrow_mark: "箭头标注",
            eraser_tool: "橡皮擦工具",
            area_wipe: "区域擦除",
            painting: "涂抹",
            rotate_karyo: "染色体旋转",
            rotate_level_karyo: "染色体水平翻转",
            rotate_vertical_karyo: "染色体垂直翻转",
            reloading_data: "正在重新加载数据",
            upload_failed: "上传失败，请重新上传",
            no_right_warning: "您没有操作权限，请管理员添加权限后再试",
            karyo_alysising_error:
                "当前病例正在分析，请切换到已经打开的工具页窗口",
            cannot_modify: "此模板已添加过报告，不能修改",
            cannot_delete: "此模板已添加过报告，不能删除",
            continue: "此操作将永久删除该模板, 是否继续？",
            isDelete_report: "是否删除此报告？",
            add_report_remind: "当前没有病例模板，是否去添加模板？",
            delete_cell: "删除后无法恢复，是否确认删除？",
            karyo_alysis: "核型分析",
            karyo_no_alysis_warning:
                "该核型图暂未智能分析，智能分析后才能进行手动分析，确定分析该核型图吗？",
            submit_success: "提交成功",
            thanks_words:
                "感谢您的入驻，我们将在第二个工作日与您联系，请耐心等待",
            confirm_count: "您是否要确认染色体计数？",
            confirming: "正在确认",
            confirm_success: "确认成功",
            confirm_array: "您是否要确认染色体排列？",
            saving: "正在保存",
            save_success: "保存成功",
            hide_sexKaryo: "是否需要隐藏性染色体",
            load: "下载",
            need: "需要",
            no_need: "不需要",
            network_abnormal: "网络异常，请稍候重试！",
            off_connect: "您已断开连接，请检查网络后重新进行！",
            not_save_toleave: "当前设置尚未保存，您确定要离开吗？",
            cannot_undo: "不能再撤销了",
            the_newest: "已经是最新了"
        },
        tips: {
            currentfps: "当前fps:",
            smallestfps: "，最小fps:",
            bigestfps: "，最大fps:",
            averagefps: "，平均fps:",
            input_confirm: "输入备注，并确认"
        }
    },
    btns: {
        cancel: "取消",
        confirm: "确定",
        submit: "提交",
        bind: "绑定",
        edit_setting: "修改设置",
        save_setting: "保存设置",
        recharge: "充值",
        widthdraw: "提现",
        modify: "修改",
        preview: "预览",
        eidt: "编辑",
        delete: "删除",
        continue_add: "继续新增",
        check_template: "查看已有模板",
        add_template: "添加模板",
        goback: "退回",
        save: "保存",
        not_save: "不保存",
        confirmed_count: "已确认计数",
        confirmed_array: "已确认排列"
    },
    pages: {
        all: "一共",
        counts: "条记录",
        to: "到第",
        page: "页"
    },
    connect_service_popup: {
        title: "在线客服",
        welcome_words: "您好，欢迎咨询中国人类遗传在线诊断咨询中心～"
    },
    agreement: {
        line1: "服务协议",
        line2:
            "感谢您选择杭州德适生物科技有限公司。人类遗传在线诊断咨询中心服务协议（以下简称“本协议”）由杭州德适生物科技有限公司（以下简称“我们”）和用户（以下简称“您”）签订。",
        line3:
            "本协议适用于人类遗传在线诊断咨询中心的账号注册、使用和付费相关事宜，构成对双方有约束力的法律文件。",
        line4: "1、服务协议确认和接受",
        line5:
            "1.1接受我们的服务前，请您认真阅读、充分理解本协议各项条款，特别是免除或限制责任的条款，若您使用人类遗传在线诊断咨询中心（包括但不限于账号注册、登录、人类遗传在线诊断咨询中心各种功能和服务的使用、付费等），您的使用行为将被视为对本服务协议全部内容的知晓、认可并同意遵守。",
        line6:
            "1.2若我们对本协议进行修改，我们将通过本软件所在网站，以公告的方式公布，变更后的协议自公布之日起满30日后生效。若您无法同意变更后的协议内容，您有权停止使用本服务。若继续使用的，则视为对本协议的同意。",
        line7:
            "1.3如您为无民事行为能力人或为限制民事行为能力人，请告知您的监护人，并在您监护人的指导下阅读本协议和使用我们的服务。若您非中华人民共和国境内用户，您还需同时遵守您所属国家或地区的法律，且您确认，订立并履行本协议不违反您所属、所居住的国家或地区的法律法规，如若您未确认是否违反您所属、所居住的国家或地区的法律法规，即同意本协议，您将全权承担相关的法律责任。",
        line8: "2、定义",
        line9:
            "2.1 用户：指注册、登录、使用人类遗传在线诊断咨询中心及服务的个人或机构。",
        line10:
            "2.2 账号：指根据要求在人类遗传在线诊断咨询中心上完成注册所获得的账号。",
        line11:
            "2.3身份信息：指我们识别您身份的信息要素，包括但不限于用户名、密码、手机号码、邮箱、真实姓名、是否是国内用户、机构编号、机构名称、机构电话号码、机构地址、联系人姓名、联系人手机号码、联系人QQ 账号、企业邮箱账号。",
        line12:
            "2.4用户信息：指个人/机构提交或上传的任何文件、图像、文档或类似材料，以及基于用户自身的原始数据或信息，使用产品所生成的用户的特定输出。",
        line13: "3、人类遗传在线诊断咨询中心为您提供的服务",
        line14:
            "人类遗传在线诊断咨询中心服务是我们向您提供的染色体图片自动分析服务，具体是指按照《人类细胞基因组学国际命名体系（ISCN2016）》的要求，在细胞遗传学家的手动操作下，对染色体中期分裂的图片，先进行初步的自动分析，再通过人工的辅助操作利用本服务提供的相应工具，对染色体进行进一步的人工辅助切割移动排列等操作。通过这一系列的人机交互操作，最终使染色体图片排列成符合上述国际命名体系要求的染色体核型排列图。您需注意的是最终染色体的排列需专业判别，本服务不自动出具诊断报告。",
        line15: "4、账号的注册、使用和注销",
        line16:
            "4.1注册：您注册账号时，须根据页面提示的要求，提供真实、准确、最新、完整的个人信息，包括但不限于您的用户名、密码、手机号码、邮箱、真实姓名、是否是国内用户、机构编号、机构名称、机构电话号码、机构地址、联系人姓名、联系人手机号码、联系人QQ 账号、企业邮箱账号、机构简介、网址。如因注册信息不真实而引起的问题（包括但不限于注册失败、无法找回密码和其它任何服务的暂停或终止使用）由您本人承担。如果您提供的任何注册信息不真实，不准确，不是最新或不完整，或者我们有合理的理由怀疑此类信息不真实，不正确，不是最新或不完整，我们将有权暂停或终止您的注册帐户。",
        line17:
            "4.2使用：身份信息是我们识别您身份的关键依据，请务必妥善保管。使用身份信息进行的操作、指令视为您本人的意愿。因您的原因造成的账号、密码等信息被冒用、盗用或非法使用，由此引起的风险和损失需要由您自行承担。",
        line18:
            "4.3 注销：在需要终止本服务使用时，符合以下条件可申请注销您的账号。",
        line19:
            "①您仅能申请注销您本人/本组织的账号，并依照我们的流程进行注销。",
        line20:
            "②您申请注销的账号处于正常状态，即您的账号不存在未了结的权利义务或其它因注销会产生纠纷的情况、不存在未完结交易。",
        line21:
            "③账号注销后，您将无法使用我们的人类遗传在线诊断咨询中心，双方的权利义务终止，同时还可能产生如下结果：",
        line22: "（1）与账号关联的权益均将作废；",
        line23:
            "（2）如您在注销账号前存在违约、侵权等不当行为，您仍应承担相应责任；",
        line24:
            "（3）一旦注销成功，账号记录、账号功能等将无法恢复或提供。其中账号内涉及的用户病例、染色体核型图像、分析结果等记录的处理详见《隐私声明》。",
        line25: "5、账号权益",
        line26:
            "您的账号旨在为您提供一个位置，您在该位置可以访问和管理您的账号信息并使用人类遗传在线诊断咨询中心。",
        line27: "6、隐私",
        line28:
            "我们致力于保护您的隐私，并确保您对我们如何处理您的用户信息知情。我们的隐私声明载明了我们如何收集、使用、储存和处理您的或涉及您的信息以及您如何要求删除您的信息。请您确认，您已经仔细阅读并理解该项隐私声明。",
        line29: "7、使用人类遗传在线诊断咨询中心须知",
        line30:
            "7.1 清楚人类遗传在线诊断咨询中心的用途，是用于为大众提供基于《人类细胞基因组学国际命名体系（ISCN2016）》的染色体图片自动分析的服务产品，其自动分析结果并不代表最终染色体的排列，其结果需专业判别，并由专业人员出具诊断报告。您需要明晰人类遗传在线诊断咨询中心只是工具，其目的只是为了在您对染色体进行分割切割排列等活动时为您提供协助，不能用于替代您的专业判断。",
        line31:
            "7.2您需要了解所有的用户信息（包括但不限于用户名、密码、手机号码、邮箱、真实姓名、是否是国内用户、机构编号、机构名称、机构电话号码、机构地址、付款信息、手动导入的病例、原始染色体核型图片、染色体核型排列图、系统导出的病例报告）将储存在杭州德适生物科技有限公司的数据库中，并将根据《隐私声明》处理。",
        line32: "8、订购",
        line33:
            "现阶段， 人 类 遗 传 在 线 诊 断 咨 询 中 心 的 销 售 渠 道 包 括 公 司 官网（https://www.autovision.online）和经过授权的销售渠道。如经过未授权途径购买产品服务的，我们不能保障提供本服务。如通过其他渠道购买到无效产品，我们有权拒绝提供服务，并且不参与产品交易处理。",
        line34: "9、用户合法使用人类遗传在线诊断咨询中心服务的承诺",
        line35:
            "您应仅根据所有适用法律访问和使用（以及允许访问和使用）人类遗传在线诊断咨询中心并应遵守所有适用法律。除本协议明确授权外或杭州德适生物科技有限公司另行以书面形式明确允许外，您不得：",
        line36:
            "①向第三方出售、出租、出借或以其他方式提供人类遗传在线诊断咨询中心的所有或任何部分（包括产品的任何功能和服务）；",
        line37:
            "②通过因特网（杭州德适生物科技有限公司通过网络提供的除外）、任何广域网（WAN）或任何其他非本地网络；任何虚拟专用网（VPN）；或任何应用虚拟技术、远程虚拟技术、虚拟主机、分时、软件即服务、平台即服务、基础设施即服务、云或其他基于网络、虚拟主机的或类似服务使用人类遗传在线诊断咨询中心。",
        line38: "此外，您不得",
        line39:
            "①从人类遗传在线诊断咨询中心中去除任何版权、商标、保密或其他专有权利告知；",
        line40:
            "②对我们的系统和程序采取反向工程手段进行破解，对上述系统和程序，进行复制、修改、编译、整合或篡改，通过网络攻击或其它黑客技术破坏我们的系统和程序，修改或增减我们系统的功能。",
        line41:
            "③以任何形式（包括但不限于上传、发送电子邮件和其它）其他方式提供任何下述信息或材料：",
        line42:
            "（1）虚假的、诽谤性、诋毁性、欺诈性或以其他方式不合法或侵权的；",
        line43:
            "（2）威胁性、骚扰性、侮辱性、可憎的或恐吓性的，或以其他方式不尊重他人权利和尊严的；",
        line44: "（3）淫秽的、下流的、色情的或以其他方式令人反感的；",
        line45:
            "（4）侵犯杭州德适生物科技有限公司或任何其他方的任何受版权、商标、设计权利、商业秘密权利、公开或隐私权利或任何其他专有权利保护的任何内容；",
        line46: "（5）保密码、会签、加密货币、密码或其他类似信息；",
        line47:
            "（6）广告、垃圾邮件、买卖任何货物或服务的要约、“连锁信”或任何其他形式的招揽；",
        line48:
            "（7）任何恶意软件（比如病毒、蠕虫、特洛伊木马、复活节彩蛋、定时炸弹或间谍软件）或具有或可能具有危害性或攻击性或旨在破坏或攻击任何硬件、软件或设备的运行、限制其使用或监控其使用的其他电脑代码、文档或程序；",
        line49:
            "①以欺诈性或不合法或侵权或具有任何欺诈性、不合法或侵权目的或效果的任何方式使用人类遗传在线诊断咨询中心；",
        line50:
            "②干扰或破坏产品或用于提供产品的服务器或网络的运行，包括通过攻击或破坏产品的任何部分；",
        line51:
            "③试图探查、扫描或测试产品的弱点或违反或规避产品使用的任何安全或认证措施；",
        line52:
            "④冒充任何其他个人或实体、虚假陈述或以其他方式谎称您与任何主体或实体有关联；",
        line53:
            "⑤使用自动方式（比如任何机器人、蜘蛛、网站搜索/检索应用或其他检索、索引、“抓取”或“数据挖掘”装置）从或以产品收集内容或信息；",
        line54: "10、责任声明",
        line55:
            "10.1对于基于用户知情同意所提供的染色体图片信息产生的科研成果（包括但不限于论文、专著、新产品、新技术、专利）的所有权归杭州德适生物科技有限公司所有，而与用户无关。",
        line56:
            "10.2如果您违背本协议中的条款，我们也将有权力拒绝为您提供现阶段或是将来任何形式的服务，您也需要为我们所付出的劳动力、费用或所带来的任何损失负责。",
        line57:
            "10.3您有义务对于在本网站注册的信息进行保密，如有意泄漏账号信息而对您个人或我们造成损失，我们不予以负责并有权对此提出相应赔偿。",
        line58: "10.4 因下列原因导致我们无法正常提供服务，我们免于承担责任：",
        line59: "（1）我们的系统停机维护或升级；",
        line60: "（2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；",
        line61: "（3）您的电脑软硬件和通信线路、供电线路出现故障的；",
        line62:
            "（4）因您操作不当或通过非经我们授权或认可的方式使用我们服务的；",
        line63:
            "（5）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。",
        line64: "尽管有前款约定，我们将采取合理行动积极促使服务恢复正常。",
        line65: "11、法律适用和管辖（此条文需考虑国外用户，由律师修改）",
        line66:
            "本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生的争议，均应依照中华人民共和国法律予以处理，并由杭州德适生物科技有限公司所在地人民法院管辖。"
    },
    statement: {
        line1: "隐私声明",
        line2: "本声明适用于人类遗传在线诊断咨询中心服务（“本服务”）的使用。",
        line3:
            "本声明阐明了我们如何收集、使用和处理您的用户信息，请您务必仔细阅读并透彻理解本声明，在确认充分理解并同意后方可使用本服务。一旦您使用本服务，即意味着您同意我们按照本声明收集、使用和处理您的相关信息。如果您不同意本声明全部或其任何部分内容，则不应该使用本服务。",
        line4: "本声明将帮助您了解以下内容",
        line5: "1、定义",
        line6: "2、我们与您的关系",
        line7: "3、我们收集的信息",
        line9: "4、我们为什么收集信息",
        line10: "5、我们如何使用您的信息",
        line11: "6、我们与第三方共享的信息",
        line12: "7、我们如何储存您的信息",
        line13: "8、您的选择",
        line14: "9、数据保护措施",
        line15: "10、儿童隐私",
        line16: "11、国际数据传输",
        line17: "12、隐私声明变更",
        line18: "13、联系我们",
        line19: "1、定义",
        line20: "原始染色体核型图片：指由用户上传的染色体中期分裂相的图片。",
        line21:
            "染色体核型排列图：指经用户手动操作并确认的已排列成符合《人类细胞基因组学国际命名体系（ISCN2016）》要求的染色体图片。",
        line22: "2、我们与您的关系",
        line23: "杭州德适生物科技有限公司是本隐私声明所述的您的信息的控制方。",
        line24: "3、我们收集的信息",
        line25:
            "当您使用我们的服务，如创建账号、订购或使用细胞染色体核型分析服务时，我们会收集以下信息：",
        line26: "（1）您直接提供给我们的信息",
        line27:
            "当您创建账号或购买我们的服务时，我们会收集个人信息，其中可能包括您的用户名、密码、手机号码、邮箱、真实姓名、是否是国内用户、组织机构代码证、医疗机构执业许可证、事业单位法人证书、联系人姓名、联系人手机号码、联系人QQ 账号、企业邮箱账号、机构简介、网址、付款信息。",
        line28: "（2）与我们的细胞染色体核型分析服务相关的信息",
        line29:
            "要使用我们的细胞染色体核型分析服务，您必须先导入待分析的染色体图片，通过我们的服务完成初步的自动分析，再根据您的专业知识进行最后的确认工作，得到最终的染色体核型分析结果。本服务所产生的相关信息，包括但不限于导入的病例、原始染色体核型图片、染色体核型排列图、系统导出的病例报告。",
        line30:
            "（3）通过跟踪技术（例如从 cookie 和类似技术中）收集的 Web行为信息，包括但不限于唯一标识符、用户偏好、个人配置信息以及通用和批量统计信息等。",
        line31: "（4）其它类型信息",
        line32:
            "我们会不断努力通过新功能来增强我们的服务，这可能会导致收集新的和不同类型的信息。我们将根据需要更新隐私声明和/或获得您对新处理的事先同意。",
        line33: "4、我们为什么收集信息",
        line34: "我们将出于以下目的收集使用您的信息。",
        line35: "（1）提供我们的服务",
        line36:
            "我们处理信息是为了提供我们的服务，例如用户注册、用户登录、处理付款、染色体自动分析服务等。",
        line37: "（2）维护和改进我们的服务",
        line38:
            "我们在维护服务正常提供的同时不断改进并提供更好的服务。例如我们一直在努力研究染色体自动分析算法等相关内容，并最大限度的提高染色体自动分析的效率，我们还会根据用户对于网站的使用情况和反馈改善用户的体验。",
        line39: "（3）德适生物的科学研究",
        line40:
            "如果您同意参加我们的科学研究，我们的研究人员可以将您已进行数据脱敏处理的病例信息包含在大量用户数据中，以进行染色体自动分析的相关研究。",
        line41: "5、我们如何使用您的信息",
        line42: "我们仅以本隐私声明所述方式使用和共享您的信息。",
        line43: "（1）提供我们的服务",
        line44: "我们会通过多种方式利用您的信息来提供服务，这些方式包括：",
        line45:
            "Ⅰ.我们会利用您直接提供给我们的用户名、密码、手机号码、邮箱等信息确认您的身份，完成用户注册、用户登录的操作；",
        line46:
            "Ⅱ.我们会利用与您账号关联的邮箱和/或手机号码，与您沟通交流有关您的帐户、您的疑问以及我们服务的任何相关信息；",
        line47:
            "Ⅲ.我们会利用您的信息来执行我们的服务条款，例如订购服务、染色体核型分析服务；",
        line48: "（2）维护和改进我们的服务",
        line49:
            "我们会利用您的信息来确保人类遗传在线诊断咨询中心服务正常运行，同时也会利用您的信息改进人类遗传在线诊断咨询中心服务，这些方式包括：",
        line50:
            "Ⅰ.我们会持续监控我们的系统，以便及时发现问题。如果我们发现被禁止或非法的行为，我们将会采取措施以打击这种行为，维护用户和我们的权益；",
        line51:
            "Ⅱ.我们会利用已进行数据脱敏处理的原始染色体图片和染色体核型排列图进行数据训练分析以改善我们的染色体自动分析效率。",
        line52: "（3）德适生物的科学研究",
        line53:
            "您可以选择同意参加德适生物的科学研究。德适生物所述科学研究是指旨在在同行评审期刊上发表的研究，以及由德适进行的中国政府资助的其他研究。",
        line54:
            "仅当您通过填写同意文件同意时，才可以将您所上传的染色体图片信息用于科学研究。其中您所上传的病例将进行数据脱敏处理，即移除所有病例敏感识别信息其中可能包括病人的姓名、地址、城市、区、邮编、日期、电话、传真、电子邮箱、社会安全号、病历号等唯一标识号，通过系统生成唯一ID标识不同病例，并且我们采用匿名化处理，您所上传的病例信息不会链接到您的注册信息。",
        line55:
            "您可以随时通过联系我们更改同意状态来撤回同意参加德适的科学研究。但您先前提供的并且已同意在德适生物研究中使用的染色体图片信息无法从使用该信息已完成的研究中删除。您的数据将不会包含在帐户关闭后超过30 天开始的研究中（帐户关闭后最多可能需要 30 天才能撤消您的信息）。",
        line56:
            "对于位于EEA地区的用户：出于上述目的处理您的敏感信息的法律依据是您的同意。您可以随时撤回同意，但是，撤回同意不会影响在撤回同意之前基于同意进行处理的合法性。",
        line57: "注：病例信息的脱敏处理",
        line58:
            "移除所有病例敏感识别信息其中可能包括病人的姓名、地址、城市、区、邮编、日期、电话、传真、电子邮箱、社会安全号、病历号等唯一标识号，通过系统生成唯一ID 标识不同病例，",
        line59: "6、我们与第三方共享的信息",
        line60: "（1）您分享自己的信息",
        line61:
            "您在共享之前应当有所考虑，一旦您选择共享您所拥有的信息，与您共享此信息的个人也可能会使用或共享您信息，包括您选择共享的任何敏感信息。",
        line62: "（2）德适分享您的信息",
        line63:
            "对于所收集的关于您的任何信息，德适生物将采取安全保护措施，不会将您的信息与任何公司、组织和个人共享，下列情况除外：",
        line64: "Ⅰ.事先获得您明确的同意或授权；",
        line65:
            "Ⅱ.只有共享您的信息，才能实现我们的服务的核心功能或提供您需要的服务，如云储存服务商；",
        line66: "Ⅲ.在您的知情同意范围内基于学术研究而使用；",
        line67:
            "Ⅳ.根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据；",
        line68:
            "Ⅴ.在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意；",
        line69: "Ⅵ.本声明另有约定的。",
        line70: "7、我们如何储存您的信息",
        line71:
            "我们收集到的数据有不同的保留期限，具体取决于相应数据是什么、我们如何使用相应数据以及您的选择：",
        line72:
            "我们会将部分数据一直保留到您删除自己的帐号为止，例如用户登录相关的信息。",
        line73:
            "我们会出于合法业务的需要或法律目的延长某些数据的保留期限，例如为了保留财务记录。",
        line74:
            "我们会出于染色体自动分析相关科学研究的目的延长某些数据的保留期限，这些数据，包括您上传的病例信息和您的分析结果，我们将先进行数据脱敏处理（保护您的隐私），然后用于科学研究。",
        line75: "8、您的选择",
        line76: "（1）访问您的账号",
        line77:
            "您可以访问您的账号信息，并提出合理的请求，以更正、修改或删除不准确的信息。您可以在“用户管理”中更正、修改或删除子帐号信息，“角色管理”中更正、修改或删除角色信息，具体相关操作参考在线版《用户操作手册》，除此之外，如果您还想更正、修改或删除某些不准确信息，您可以联系我们的客服，我们将会尽全力来帮助您解决问题。",
        line78: "（2）数据可移植性和处理限制",
        line79:
            "您可以通过联系我们来行使您的权利以获得您的数据的可移植性或要求对处理您的数据进行限制。",
        line80: "（3）撤回同意",
        line81:
            "您可以联系我们随时撤回对我们处理您的数据的同意。因此，将来我们可能不会再根据此同意处理您的个人数据。撤回同意对撤回之前基于同意的处理的合法性没有影响。",
        line82: "（4）反对处理",
        line83:
            "无论何时德适生物依据其合法权益处理您的信息，您都可随时以与您特定情况相关的合法理由反对进行该等处理。",
        line84: "（5）删除账号",
        line85:
            "如果您不再希望参与我们的服务，或者不再希望处理您的信息，则可以选择注销您的账号。提交请求后，我们将向与您的人类遗传在线诊断咨询中心帐户链接的邮箱发送一封电子邮件，详细说明我们的帐户注销政策并要求您确认删除请求。在您确认删除帐户和数据的请求后，您将无法再访问您的帐户。确认请求后，此过程将无法取消或撤销。删除您的帐户后，所有关联的个人信息都会被删除，但受到以下限制：",
        line86:
            "Ⅰ.先前包含在德适生物研究中的信息。如任何适用的同意书所述，您先前提供的并且已同意在德适生物研究中使用的染色体图片信息无法从使用该信息的已完成研究中删除。您的数据将不会包含在帐户关闭后超过30 天开始的研究中（帐户关闭后最多可能需要 30 天才能撤消您的信息）。",
        line87:
            "Ⅱ.德适生物还将在法律规定的有限时间内保留与您的帐户和数据删除请求有关的有限信息，包括但不限于您的邮箱，帐户删除请求标识符，与查询或投诉有关的通信、为建立、行使或抗辩法律主张以及进行审核和合规目的所必需的。",
        line88: "（6）投诉权",
        line89:
            "如果您认为我们侵犯了您的权利，建议您与我们联系，以便我们可以尝试解决您的疑虑或非正式地提出争议（联系信息详见联系我们）。同时您有权向监管机构投诉。",
        line90: "9、数据保护措施",
        line91:
            "德适生物非常重视您对我们的信任，将采取措施保持数据准确性，防止未经授权访问或披露您的信息，确保信息的正确使用以及保护您的个人信息。我们的团队会定期审查并改善我们的安全做法，以确保我们的系统和您的信息的完整性。这些做法包括但不限于以下领域：",
        line92:
            "通过数据库、图片库的备份、集群等通用行业做法确保用户数据的准确性和完整性；",
        line93:
            "通过用户授权登陆、身份识别、有限获取和二次校验的方式确保用户数据的授权访问；我们采取各种合理的防范措施，保护您的个人信息免遭滥用、盗窃、丢失、未经授权的访问、泄露、篡改或损毁。",
        line94:
            "针对网络环境，采用安全套接层技术 (SSL)对信息传输进行加密确保连接的安全；通过对称和非对称加密等行业标准加密来提高数据传输的安全性；通过对称加密对存储在数据库中的数据进行密文保存；",
        line95:
            "落实各项等级保护政策，提供符合等保 2.0合规评测的在线系统。我们会通过特定实体、电子和管理程序确保您提供的数据安全无虞。",
        line96:
            "我们遵循普遍认可的行业标准，保护您提交给我们的个人信息，包括处于传输过程中的信息以及我们已收到的信息。然而，没有任何一种互联网传输方法或电子存储方法百分之百安全可靠。因此，我们将竭力运用商业上合理的手段并结合新的技术发展成果以持续修正安全措施保护您的个人信息，但无法保证绝对安全。我们建议您经常更改密码，使用包含大小写字母、数字和特殊符号组合的密码，并确保使用安全的浏览器。",
        line97: "10、儿童隐私",
        line98:
            "人类遗传在线诊断咨询中心致力于保护儿童的隐私。人类遗传在线诊断咨询中心是为遗传学专家进行染色体核型分析设计的，不是针对18岁以下儿童设计和提供服务的。用户也许可以获得18岁以下的儿童的染色体核型图片并通过本服务对其染色体核型图片进行分析，用户有告知其父母或监护人使用本服务所涉及的隐私权相关信息的义务并对其负全部责任。",
        line99: "11、国际数据传输",
        line100:
            "数据保护法律因国家/地区而异，不同国家/地区的数据保护力度也是有强有弱。我们将基于用户企业规则（具有内部约束力，除非另有说明，否则适用于全球的数据传输）进行数据传输。",
        line101:
            "对于欧洲经济区（EEA）地区的用户，我们从您收集的信息将根据欧盟第 2004/915 /EC号决定，按照欧盟委员会之与个人数据向第三国转移相关的标准合同（即标准合作条款）被转移到并保存在中国且在中国处理。您的个人数据还会被位于EEA境外的，为我们提供工作或为我们的供应商工作的工作人员所处理。此类工作人员参与（包括）处理您的付款信息。我们将采取所有合理所需的措施以确保您的信息按照本声明被安全的处理。",
        line102: "12、隐私声明变更",
        line103:
            "德适生物保有修改隐私声明的权利。我们建议您定期重新访问此页面，了解此隐私声明的任何更改。如果我们修改此隐私声明，我们将通过我们的网站提供最新版隐私声明。每当对此隐私声明进行重大更改时，我们将在更改生效之前向您提供通知，例如在我们的网站上发布通知或将消息发送到与您的帐户关联的电子邮件地址。",
        line104:
            "在本隐私声明的更改生效后继续访问或使用服务，即表示您同意受修订的隐私声明的约束。如果您无法接受任何更改，则可以随时停止使用我们的服务并删除您的帐户。",
        line105: "13、联系我们",
        line106:
            "如果您想要行使您的权利或您对该隐私声明有任何疑问或看法，请通过以下方式联系我们：",
        line107: "地址：杭州市余杭区仓前街道龙泉路 22 号",
        line108: "电话：+86 0571-89055921",
        line109: "传真：+86 0571-89055921",
        line110: "邮箱：support@diagens.com"
    },
    onlineManage: {
        login: {
            login_system: "系统登录",
            login: "登录",
            input_account: "请输入账号",
            input_pwd: "请输入密码",
            press_left: "请摁住鼠标左键，刮出两面盾牌",
            next_step: "恭喜您成功刮出盾牌<br/>继续下一步操作吧",
            loading: "加载中",
            disappear: "呀，盾牌不见了<br/>请",
            one_more: "再来一次",
            or: "或",
            feedback: "反馈问题",
            wait_longtime: "我等得太久啦<br/>请",
            not_cool: "网络实在不给力<br/>请",
            scrape_tooQuickly: "您刮得太快啦<br/>请",
            click_start: "点击按钮开始智能验证",
            verify_success: "验证成功",
            verify_failed: "验证失败，请在此点击按钮刷新",
            verifying: "智能检测中",
            man_machine_check: "请进行人机校验",
            check_input: "请检查输入",
            Chinese_online_consult_center: "中国人类遗传在线诊断咨询中心"
        },
        user: {
            personal_setup: "个人设置",
            quit_account: "退出账号",
            back: "返回"
        },
        accountInformationList: {
            filterBy_account: "按照账号筛选：",
            code: "编号",
            primary_account_name: "主账号名称",
            account_balance: "账号余额(元)",
            create_time: "创建时间",
            add_or_edit: "新增/编辑",
            package_amount: "套餐金额：",
            package_code: "套餐编号：",
            starttime: "开始时间：",
            endtime: "结束时间："
        },
        accountList: {
            package_code: "套餐编号",
            money: "金额",
            package_type: "套餐类型",
            package_describe: "套餐描述",
            case_quality: "病例质量",
            country: "国家",
            creat_time: "创建时间",
            edit_time: "修改时间",
            package_action: "套餐操作",
            amount: "金额：",
            package_mode: "套餐类型：",
            chargeBy_piece: "按张收费",
            caseQuality: "病例质量：",
            good: "好",
            bad: "差",
            countries: "国家：",
            China: "中国",
            overseas: "海外",
            packageDescribe: "套餐描述："
        },
        accountStatistics: {
            primaryAccount_sum: "主账号总数：",
            primaryAccount_name: "主账号名称",
            childAccount_num: "子账号个数",
            charge_amount: "充值金额(元)",
            account_balance: "账号余额(元)",
            current_account_charge: "本账号充值",
            charge_sum: "充值总额：",
            balance_before_charge: "充值前账号余额(元)",
            balance_after_charge: "充值后账号余额(元)",
            charge_type: "充值类型",
            package_charge: "套餐充值",
            normal_charge: "普通充值",
            charge_way: "充值方式",
            charge_time: "充值时间",
            child_account_charge: "子账号充值",
            childAccount_total_consumption: "子账号消费总额",
            tel_num: "手机号",
            user_name: "用户名",
            consumption_amount: "消费金额(元)",
            childAccount_consumption_detail: "子账号消费明细",
            patient: "病人",
            karyo_image: "核型图",
            charge_detail: "充值详情",
            consumption_detail: "消费明细"
        },
        auditResultEmail: {
            code: "编号",
            title: "标题",
            content: "内容",
            email_type: "邮件类型",
            send_message: "是否发送短信",
            isRead: "是否已读",
            create_time: "创建时间",
            email_detail: "邮件详情",
            register: "注册",
            report_submit: "报告提交",
            report_sendback: "报告退回",
            edit_audit_by_emailOrTel: "邮箱手机号修改审核",
            remind_expert: "发起专家会诊提醒专家",
            expert_consultion_endInform: "专家会诊结束通知",
            checkResult_inform: "病例审核结果通知",
            not_send: "不发",
            sended: "已发",
            no_read: "未读",
            read: "已读"
        },
        consumptionRecord: {
            searchBy_primaryAccount: "按照主账号查询：",
            searchBy_time: "按照时间查询：",
            code: "编号",
            primaryAccount_name: "主账号名称",
            consumption_account: "消费账号",
            consumption_amount: "消费金额(元)",
            amount_before_consuming: "消费前金额(元)",
            amount_after_consuming: "消费后金额(元)",
            case_name: "病例名称",
            slide_name: "玻片名称",
            karyoImage_name: "核型图名称",
            create_time: "创建时间"
        },
        customtemplate: {
            add_check_fence: "新增审核栏",
            create_immediately: "立即创建",
            Diagens_self_template: "德适生物自定义报告模板",
            result: "结果",
            check_result: "染色体检查结果",
            read_person: "阅片人:",
            karyo_check_person: "核型审定者:",
            signature_by_doc: "医师签名:",
            report_date: "报告日期:",
            name: "姓名",
            sex: "性别",
            age: "年龄",
            tel: "电话",
            organ_name: "机构名称",
            sample_type: "样本类型",
            check_way: "检查方法",
            consulting_standard: "诊断标准",
            describe_gist: "描述依据",
            report_type: "报告类型",
            add_new_module: "新增模块"
        },
        customtemplateList: {
            template_name: "模板名称",
            create_date: "创建日期"
        },
        dataStatistics: {
            self_defined_time_scope: "自定义时间范围：",
            account_compare_chart: "账号对比表",
            user_num: "用户数",
            primary_account: "主账号",
            child_account: "子账号",
            sum_dose: "总量",
            account_charge_amount: "账号充值金额",
            account_balance: "账号余额",
            consumption_amount: "消费金额",
            charge_amount: "充值金额",
            case_statistics: "病例统计",
            sum: "总数",
            checked: "已审核",
            case_good: "病例质量好",
            bad_num: "质量差数",
            AI_analyzed_num: "已AI分析数",
            AI_analyzed: "已AI分析",
            human_account_num: "人工计数数",
            human_array: "人工排列",
            need_recheck: "需要复查",
            report_statistics: "报告统计"
        },
        expertExamine: {
            account: "账号：",
            code: "编号",
            account_name: "账号姓名",
            check_result: "审核结果",
            image: "图片",
            remark: "备注信息"
        },
        graphStatistics: {
            primary_account: "主账号",
            child_account: "子账号",
            case_name: "病例名称",
            case_amount: "病例总数",
            belongs_case_amount: "所属病例总数",
            operating_case_amount: "操作病例总数",
            karyoImage_amount: "核型图总数",
            undisposed_karyoImage: "未处理核型图",
            confirm_num: "确认数量",
            confirm_array: "确认排序"
        },
        home: {
            welcome_to_center: "欢迎来到中国人类遗传在线诊断咨询中心",
            permission_manage: "权限管理"
        },
        karyotypeLog: {
            searchBy_operator_or_log: "按照操作人/日志类型查询：",
            searchBy_time: "按照时间查询：",
            operator: "操作人",
            create_time: "创建时间",
            log_type: "日志类型",
            user_action: "用户操作",
            operation_time: "操作时间",
            operation_entrance: "操作入口",
            operation_detail: "操作详情"
        },
        mechanismExamine: {
            account: "账号：",
            organ_code: "机构编号",
            organ_name: "机构名称",
            organ_tel: "机构电话号码",
            organ_site: "机构地址",
            connect_name: "联系人姓名",
            connect_tel: "联系人手机号码",
            connect_qq: "联系人QQ账号",
            company_email_code: "企业邮箱账号",
            organ_intro: "机构简介",
            organ_netsite: "机构网址",
            check_result: "审核结果"
        },
        modifyMail: {
            user_name: "用户名",
            old_tel: "旧手机号",
            new_tel: "新手机号",
            old_email: "旧邮件",
            new_email: "新邮件",
            image: "图片",
            remark: "备注",
            modify_detail: "修改详情",
            tel_before_modify: "修改前手机号",
            tel_after_modify: "修改后手机号",
            email_before_modify: "修改前邮件",
            email_after_modify: "修改后邮件",
            verify_image: "验证图片"
        },
        noticesthat: {
            add_new_information: "新增通知",
            operator: "操作人",
            type: "类型",
            content: "内容",
            add_or_edit: "新增/编辑",
            date_setup: "日期设置：",
            inform_conten: "通知内容：",
            vindicate_inform: "维护通知"
        },
        rechargeRecord: {
            filterBy_account: "按照账号筛选：",
            searchBy_time: "按照时间查询：",
            code: "编号",
            primary_account_name: "主账号名称",
            charge_account_information: "充值账号信息",
            charge_amount: "充值金额(元)",
            charge_mode: "充值方式",
            balance_before_charge: "充值前账号余额(元)",
            balance_after_charge: "充值后账号余额(元)",
            is_success: "是否成功",
            reason: "成功或失败原因",
            remark: "备注",
            create_time: "创建时间"
        },
        reviewConsultationResults: {
            user_name: "用户名",
            check_status: "审核状态",
            create_date: "创建日期"
        },
        roleManager: {
            new_role: "新增角色",
            create_date: "创建日期",
            role_name: "角色名称",
            role_type: "角色类型",
            role_action: "角色操作",
            role: "角色",
            allot_role: "分配角色",
            second_role: "二级角色",
            permission_manage: "权限管理",
            input_role_name: "请输入角色名",
            permission_choose: "选择权限项",
            choose_second_role: "请选择二级角色"
        },
        userSysUser: {
            new_user: "新增用户",
            create_date: "创建日期",
            user_name: "用户名",
            name: "姓名",
            tel: "手机",
            email: "邮箱",
            user_action: "用户操作",
            real_name: "真实姓名",
            role: "角色",
            tel_no: "手机号",
            verify_tel: "手机号验证码",
            verify_email: "邮箱验证码",
            password: "密码",
            confirm_pwd: "确认密码"
        },
        userUserInfo: {
            filterBy_account: "按照账号筛选：",
            account_type: "账号类型",
            manage: "管理员",
            primary_account: "主账号",
            child_account: "子账号",
            belong_primary_account: "所属主账号",
            isHave_organ: "是否有机构",
            yes: "是",
            no: "否",
            isDomestic_accounts: "是否是国内账号"
        },
        withdrawalAudit: {
            account: "账号:",
            check_status: "审核状态：",
            account_name: "账号名",
            widthdraw_amount: "提现金额(元)",
            serial_num: "流水号",
            widthdraw_type: "收款类型",
            account_num: "账号号",
            user_bank_name: "账号名称",
            bank_site: "开户行",
            check_is_passed: "审核是否通过",
            is_been_received: "是否已到账",
            waited_check: "待审核"
        },
        options: {
            operate: "操作",
            check: "审核",
            no_check: "未审核",
            is_passed: "是否通过：",
            passed: "通过",
            no_passed: "未通过",
            passed_cannot_empty: "通过选项不能为空",
            Alipay: "支付宝",
            Wechat: "微信",
            no_pay: "未支付",
            success: "成功",
            failed: "失败",
            normal: "正常",
            normal_variation: "正常变异",
            variation: "变异",
            abnormal: "异常",
            amniotic_fluid: "羊水",
            initialize: "初始化",
            not_get_money: "未到账",
            get_money: "已到账"
        },
        placeholder: {
            please_choose: "请选择",
            input_money: "请输入金额",
            package_code: "套餐编号",
            start_time: "开始时间",
            end_time: "结束时间",
            input_package_amount: "请输入套餐金额",
            choose_package_type: "请选择套餐类型",
            choose_case_quality: "请选择病例质量",
            choose_country: "请选择国家",
            input_package_describe: "请输入套餐描述",
            start_date: "开始日期",
            end_date: "结束日期",
            input_search_account: "请输入查找账号",
            account: "账号",
            password: "密码",
            input_content: "请输入内容",
            choose_type: "请选择类型",
            case_num: "病历号",
            primary_account: "主账号",
            remark: "备注",
            role_name: "角色名",
            input_verify_code: "请输入手机号验证码",
            input_email_code: "请输入邮箱验证码"
        },
        shortcuts: {
            lately_1day: "最近一天",
            lately_1week: "最近一周",
            lately_1month: "最近一个月",
            lately_7days: "最近7天",
            lately_30days: "最近30天"
        },
        message: {
            success: "成功",
            confirm_delete: "确定要删除?",
            remind: "提示",
            money_atMost: "最大金额不能超过99999999",
            selfDefined_12programa_atMost: "最多只能自义定12个栏目",
            only4_programa_selfDefined: "底部最能只能添加4个自定义栏目",
            save_success: "保存成功,请在模板列表查看!",
            is_continue: "此操作将永久删除该模板, 是否继续?",
            delete_success: "删除成功!",
            delete_cancel: "已取消删除",
            searchDays_90_atMost: "查询日期最多为90天",
            name_demand: "至少八个字符，至少一个字母和一个数字",
            input_pwd_again: "请再次输入密码",
            pwd_not_same: "两次输入密码不一致!",
            account_cannot_empty: "账号不能为空",
            reInput_username: "用户名已被注册了，请重新填一个吧",
            input_realName: "请输入真实姓名",
            tel_cannot_empty: "手机号码不能为空",
            input_correct_tel: "请填写正确的手机号码",
            verifyCode_cannot_empty: "验证码不能为空",
            verifyCode_demand: "验证码为4位纯数字",
            email_cannot_empty: "邮件不能为空",
            input_correct_email_format: "请填写正确的邮件格式",
            name_cannot_empty: "姓名不能为空",
            get_verifyCode: "获取验证码",
            input_username: "请输入用户名",
            choose_role: "请选择角色",
            input_tel: "请输入手机号",
            input_tel_verifyCode: "请输入手机号验证码",
            input_email: "请输入邮箱",
            input_email_verifyCode: "请输入邮箱验证码",
            input_pwd: "请输入密码",
            confirm_pwd: "请确认密码",
            areaCode_cannot_empty: "区号不能为空",
            retry: "s后重新发送"
        },
        btns: {
            edit: "编辑",
            cancel: "取 消",
            confirm: "确 定",
            delete: "删除",
            export: "导出",
            detail: "详情",
            check: "审核",
            showAll: "显示全部",
            addNew: "新 增",
            save: "保存",
            viewDetails: "查看详情"
        }
    }
};
export default zh;
