/**
 * 将染色体插入到某个组
 * @param {Object} current
 * @param {Object} target
 */
function insertChromosByBox(current,target){
	const keys = Object.keys(this.arrangeController.group);
	let current_chromo, target_group = 0;
	keys.forEach(g=>{
		const group = this.arrangeController.group[g];
		group.list.forEach((item, i)=>{
			if(item.shape === current){
				current_chromo = item.chromo;
			}
		})
		if(group.box === target){
			target_group = g;
		}
	});
	if(current_chromo.chromoId != target_group){
		current.yoffsetInGroup = 0;
		current_chromo.yoffsetInGroup = 0;
	}
	current_chromo.chromoId = parseInt(target_group);
}
/**
 * 将染色体插入到某个染色体的前面或者后面
 * @param {Object} current
 * @param {Object} target
 * @param {Object} isLeft
 */
function insertChromosByChromo(current,target,isLeft){
	// console.warn(current,target,isLeft);
	if(current === target){
		return;
	}
	const keys = Object.keys(this.arrangeController.group);
	let current_chromo,
		target_chromo;
	keys.forEach(g=>{
		const group = this.arrangeController.group[g];
		group.list.forEach((item, i)=>{
			if(item.shape === current){
				current_chromo = item.chromo;
			}
			if(item.shape === target){
				target_chromo = item.chromo;
			}
		})
	})
	if(current_chromo.chromoId != target_chromo.chromoId){
		current.yoffsetInGroup = 0;
		current_chromo.yoffsetInGroup = 0;
	}
	current_chromo.chromoId = parseInt(target_chromo.chromoId);
	const cindex = this.chromoList.indexOf(current_chromo);
	cindex > -1 && this.chromoList.splice(cindex, 1);
	const tindex = this.chromoList.indexOf(target_chromo) + (isLeft ? 0 : 1);
	const newChromoList = this.chromoList.slice(0,tindex).concat([current_chromo]).concat(this.chromoList.slice(tindex));
	this.chromoList = newChromoList;
	this.arrangeController.chromoList = newChromoList;
	this.karyoController.chromoList = newChromoList;
}

/**
 * 染色体相互替换
 * @param {Object} current
 * @param {Object} target
 */
function replaceChromos(current,target){
	// console.warn(current,target);
	if(current === target){
		return;
	}
	const keys = Object.keys(this.arrangeController.group);
	let current_chromo, 
		target_chromo;
	keys.forEach(g=>{
		const group = this.arrangeController.group[g];
		group.list.forEach((item, i)=>{
			if(item.shape === current){
				current_chromo = item.chromo;
			}
			if(item.shape === target){
				target_chromo = item.chromo;
			}
		})
	})
	const cid = current_chromo.chromoId;
	const tid = target_chromo.chromoId;
	current_chromo.chromoId = target_chromo.chromoId;
	target_chromo.chromoId = cid;
	if(cid != tid){
		current.yoffsetInGroup = 0;
		target.yoffsetInGroup = 0;
		current_chromo.yoffsetInGroup = 0;
		target_chromo.yoffsetInGroup = 0;
	}
	
	const cindex = this.chromoList.indexOf(current_chromo);
	const tindex = this.chromoList.indexOf(target_chromo);
	
	cindex > -1 && this.chromoList.splice(cindex, 1, target_chromo);
	cindex > -1 && this.chromoList.splice(tindex, 1, current_chromo);
	
}

/**
 * 染色体拖动
 * @param {Object} stage
 */
function chromoDrag(stage) {
	let currentShape = null;
	let sx = 0,
		sy = 0;
	let dx = 0,
		dy = 0;
	let ex = 0,
		ey = 0;
	let prevBox, prevChromo, isMove;
	let positionLine, prevTarget, type = 'join', isLeft = false;
	//拖拽释放回调
	const dragend = (e) => {
		clearPositionLine();
		if (currentShape && prevTarget && currentShape !== prevTarget) {
			window.startTime = +new Date();
			// console.warn(type,prevTarget.zindex);
			if(type === 'join' && prevTarget.zindex < 0){
				insertChromosByBox.call(this,currentShape,prevTarget)
				// this.arrangeController.reArrange();
			}
			if(type === 'join' && prevTarget.zindex >= 0){
				insertChromosByChromo.call(this,currentShape,prevTarget,isLeft);
				// this.arrangeController.reset(this.chromoList, this.karyoInfo, this.arrangeController.arrowList);
			}
			if(type !== 'join'){
				replaceChromos.call(this,currentShape,prevTarget);
				// this.arrangeController.reArrange();
			}
			currentShape.substitute.hide = true;
			currentShape.zindex = currentShape._zindex;
			delete currentShape._zindex;
			this.arrangeController.reArrange();
			this.karyoController.resetKaryo();
			this.cachePool.save();
			this.cachePool.record({
				type : 'dragChromo',
				message : '移动染色体',
				data : stage.controller.getChromoByShape(currentShape),
				result : this.chromoList
			})
			currentShape = null;
		}
		if(currentShape && prevTarget && currentShape === prevTarget){
			const box = stage.isPointInner(e,false,'rectangle');
			if(box){
				const y = currentShape.substitute.y;
				// console.log(currentShape, box);
				const chromo = stage.controller.getChromoByShape(currentShape);
				const min_y = box.y;
				const max_y = box.y + box.height - currentShape.height * currentShape.scale;
				currentShape.yoffsetInGroup = max_y - y;
				chromo && (chromo.yoffsetInGroup = currentShape.yoffsetInGroup);
				currentShape.y = Math.max(min_y, Math.min(max_y, y));
				currentShape.substitute.hide = true;
				currentShape.substitute.x = currentShape.x;
				currentShape.substitute.y = currentShape.y;
				currentShape.zindex = currentShape._zindex;
				delete currentShape._zindex;
			}else{
				currentShape.substitute.hide = true;
				currentShape.substitute.x = currentShape.x;
				currentShape.substitute.y = currentShape.y;
				stage.draw();
			}
			this.cachePool.save();
		}
		currentShape && (currentShape.color = "transparent");
		if(this.choosedChromo){
            const _shape = this.arrangeController.getShapeByChromo(this.choosedChromo);
            _shape && (_shape.color = "#f00");
        }
		currentShape = null;
		prevTarget = null;
	}
	
	//清除位置提示的红色竖线
	const clearPositionLine = () => {
		stage.removeChild(positionLine);
		positionLine = null;
	}

	//触发条件
	const condition = {
		getStatus : ()=>{
			return (this.toolsType === 'general' || this.toolsType === 'select' || this.toolsType === 'count') && this.shortKeyType === '';
		}
	}

	//拖动开始事件捕获
	stage.event.dragStart(condition,({
		e,
		shape
	}) => {
		// console.log(shape)
		if (shape && shape.realType === 'chromo') {
			currentShape = shape;
			sx = currentShape.substitute.x;
			sy = currentShape.substitute.y;
			const pos = stage.getPos(e);
			dx = pos.x;
			dy = pos.y;
			ex = e.offsetX;
			ey = e.offsetY;
		}else{
			currentShape = null;
		}
	})

	//拖动过程事件捕获
	stage.event.dragMove(condition,({
		e
	}) => {
		if (currentShape) {
			isMove = true;
			const pos = stage.getPos(e);
			currentShape.substitute.hide = false;
			currentShape.substitute.x = pos.x - dx + sx;
			currentShape.substitute.y = pos.y - dy + sy;
			currentShape._zindex = currentShape.zindex;
			currentShape.zindex = 99;
			currentShape.color = "#1890FF"

			const box = stage.isPointInner(e, true, 'rectangle').filter(item => item.id !== currentShape.id)[0];
			const chromo = stage.isPointInner(e, true)[0];
			//box 鼠标指针位置经过box效果
			if (!prevBox && box) {
				box.color = '#f0f0f0'
				prevBox = box;
			}
			if (prevBox && !box) {
				prevBox.color = 'transparent'
				prevBox = null;
			}
			if (prevBox && box && prevBox !== box) {
				prevBox.color = 'transparent'
				box.color = '#f0f0f0'
				prevBox = box;
			}
			//鼠标指针位置经过染色体效果
			if (!prevChromo && chromo) {
				chromo._color = chromo.color;
				chromo.color = '#f00'
				prevChromo = chromo;
			}
			if (prevChromo && !chromo) {
				prevChromo.color = prevChromo._color || ''
				delete prevChromo._color
				prevChromo = null;
			}
			if (prevChromo && chromo && prevChromo !== chromo) {
				prevChromo.color = prevChromo._color || ''
				delete prevChromo._color
				chromo._color = chromo.color;
				chromo.color = '#f00'
				prevChromo = chromo;
			}
			//如果此时 鼠标指针的位置在染色体上
			if (box && chromo) {
				clearPositionLine();
				prevTarget = chromo;
				type = 'replace'
			}
			if (box && !chromo) {
				const group = this.arrangeController.group;
				const groupNum = Object.keys(group).filter(key => group[key].box === box)[0];
				const groupItem = group[groupNum]
				if (!groupItem) {
					return;
				}

				let target,
					left = 0,
					height = box.height - 30;
				//如果是空组 则对应的位置应该在 组中间的位置
				if (!groupItem.list.length) {
					left = box.x + box.width / 2;
					target = box;
				} else {
					target = groupItem.list[0].shape;

					for (var i = 0; i < groupItem.list.length; i++) {
						let next = groupItem.list[i + 1];
						next && (next = next.shape);
						const shape = groupItem.list[i].shape;
						target = shape;
						const width = shape.width * shape.scale;
						const right = shape.x + width / 2;
						if (pos.x < shape.x) {
							isLeft = true;
							break
						}
						if (pos.x >= shape.x && pos.x < right) {
							isLeft = true;
							break
						}
						if (pos.x >= right && pos.x <= shape.x + width) {
							isLeft = false;
							break;
						}
						if (pos.x > shape.x + width && next && pos.x < next.x) {
							isLeft = false;
							break;
						}
						if (pos.x > shape.x + width && !next) {
							isLeft = false;
							// break;
						}
					}
					left = target.x + (isLeft ? -2 : (target.width * target.scale + 2));
				}
				const matrix = [
					[left, box.y + box.height - height],
					[left, box.y + box.height]
				]
				if (positionLine) {
					positionLine.matrix = matrix;
				} else {
					positionLine = stage.graphs.line({
						color: "#f00",
						lineWidth: 2,
						matrix: matrix,
						hold : false
					})
					stage.addChild(positionLine)
				}
				prevTarget = target;
				type = 'join'
			}

			stage.draw();
		}
	})

	//拖动事件结束捕获
	stage.event.dragEnd(condition,({
		e
	}) => {
		sx = 0;
		sy = 0;
		dx = 0;
		dy = 0;
		stage.isPointInner(e, true, 'rectangle').forEach(item=>{
			item.color = "transparent"
		})
		prevBox = null;
		prevChromo = null;
		if(currentShape && isMove && (Math.abs(e.offsetX - ex) >= 5 || Math.abs(e.offsetY - ey) >= 5)){
			dragend(e);
		}else{
			if(currentShape){
				currentShape.color = "transparent";
				currentShape.substitute.hide = true;
				currentShape.zindex = currentShape._zindex;
				delete currentShape._zindex;
			}
			currentShape = null;
			positionLine = null;
			prevTarget = null;
			type = 'join';
			isLeft = false;
		}
		stage.draw();
	})
}
export default chromoDrag
