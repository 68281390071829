<template>
    <div class="workSetupBox">
        <div class="work-setting">
            <h3>{{$t('teamSeting.automatic.title')}}</h3>
            <div class="case-price-list">
                <div class="case-price">
                    <label>{{$t('teamSeting.automatic.low.title')}}</label>
                    <!-- <span>{{$t('teamSeting.automatic.low.desc')}}</span> -->
                    <span>优质核型图占比{{adminConfig.lowProportion}}%以下</span>
                    <em>{{adminConfig.lowPrice}} {{$t('teamSeting.unit')}}/{{$t('teamSeting.case')}}</em>
                </div>
                <div class="case-price">
                    <label>{{$t('teamSeting.automatic.secondary.title')}}</label>
                    <!-- <span>{{$t('teamSeting.automatic.secondary.desc')}}</span> -->
                    <span>优质核型图占比{{adminConfig.mediumProportion}}%以上</span>
                    <em>{{adminConfig.mediumPrice}} {{$t('teamSeting.unit')}}/{{$t('teamSeting.case')}}</em>
                </div>
                <div class="case-price">
                    <label>{{$t('teamSeting.automatic.high.title')}}</label>
                    <!-- <span>{{$t('teamSeting.automatic.high.desc')}}</span> -->
                    <span>优质核型图占比{{adminConfig.heightProportion}}%以上</span>
                    <em>{{adminConfig.highPrice}} {{$t('teamSeting.unit')}}/{{$t('teamSeting.case')}}</em>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "WorkSetup",
  components: {},
  data() {
    return {
      adminConfig: {}, //病例分析金额与占比
    };
  },
  created() {
    this.getWorkConfig();
    // window.setApp = this;
  },
  methods: {
    /**
     * 获取工作设置信息
     */
    getWorkConfig() {
      this.$api.getWorkConfig({}).then((res) => {
        if (res.code != 200) {
          this.$message({
            type: "error",
            message: res.message,
          });
          return;
        }
        this.adminConfig = res.data.adminConfig;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.workSetupBox {
  padding: 20px 50px;
  overflow: hidden;
  .work-setting {
    padding-top: 18px;
    overflow: hidden;
    border-top: 1px solid #e3e3e3;
    &:first-child {
      border-top: none;
    }
    h3 {
      margin: 0;
      height: 28px;
      line-height: 28px;
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      display: flex;
      align-items: center;
    }
  }
  .case-price-list {
    overflow: hidden;
    padding: 10px 40px;
    .case-price {
      overflow: hidden;
      height: 60px;
      line-height: 60px;
      border-top: 1px solid #e3e3e3;
      &:first-child {
        border-top: none;
      }
      label {
        font-size: 17px;
        font-weight: 600;
        color: #333333;
        float: left;
      }
      span {
        float: left;
        margin-left: 16px;
        font-size: 14px;
        color: #666666;
      }
      em {
        float: right;
        color: #333333;
        font-size: 15px;
      }
    }
  }
}
</style>
