<template>
	<div class="primary-setup">
		<div class="pointer" @click="showSetup">
			<i class="el-icon-setting"></i>{{$t('uploadPdf.setup')}}
		</div>
		<DialogPopup :visible="isShow" :title="$t('primarySetup.infrastructure')" @visibleChange="visibleChange"
			:closeFlag="false">
			<div slot="content" class="pop-cnt">
				<el-tabs v-model="activeName" type="border-card" tab-position="left" @tab-click="handleClick">
					<el-tab-pane :label="$t('primarySetup.amplification_setting')" name="first">
						<div class="karyo-size">
							<p style="margin: 0">{{$t('primarySetup.Chromosome_size')}}</p>
							<el-slider class="karyo-slider" v-model="chromoDefault" :step="0.1" show-stops
								:marks="marks" :show-tooltip="false" :max="1.5" :min="0.5">
							</el-slider>
						</div>
						<div class="karyo-size">
							<p style="margin: 0">
								{{$t('primarySetup.Chromosome_Amplification')}}
								<el-switch v-model="isEnlarge" active-color="#3A83CE" inactive-color="#DCDFE6"
									:active-value="1" :inactive-value="0">
								</el-switch>
							</p>
							<el-slider class="karyo-slider" v-model="chromoEnlarge" :step="0.05" show-stops
								:marks="marks" :show-tooltip="false" :max="1.5" :min="1" :disabled="!isEnlarge">
							</el-slider>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="$t('primarySetup.Analysis_of_setting')" name="second">
						<div class="setup">
							<label>{{$t('primarySetup.Count_the_number')}}</label>
							<el-input type="number" v-model="karyoSetNum"
								:placeholder="$t('primarySetup.Please_enter_the')"></el-input><label
								style="margin-left: 6px">{{$t('primarySetup.zhang')}}</label>
						</div>
						<div class="setup">
							<label>{{$t('primarySetup.Arrange_the_number')}}</label>
							<el-input type="number" v-model="arraySetNum"
								:placeholder="$t('primarySetup.Please_enter_the')"></el-input><label
								style="margin-left: 6px">{{$t('primarySetup.zhang')}}</label>
						</div>
						<p class="intrd">
							{{$t('primarySetup.message')}}
						</p>
						<div class="setup">
							<label>{{$t('primarySetup.analysisModeDefault')}}</label>
							<el-radio v-model="analysisModeDefault" label="1" style="margin-right: 20px;">{{$t('primarySetup.counterMode')}}</el-radio>
							<el-radio v-model="analysisModeDefault" label="2">{{$t('primarySetup.arrangeMode')}}</el-radio>
						</div>
						<div class="setup">
							<label>{{$t('primarySetup.hasHideNumber')}}</label>
							<el-radio v-model="hasHideNumber" label="1" style="margin-right: 20px;">{{$t('primarySetup.hide')}}</el-radio>
							<el-radio v-model="hasHideNumber" label="0">{{$t('primarySetup.show')}}</el-radio>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div slot="footer" class="btns">
				<el-button class="default-btn" type="primary" @click="reset" v-show="activeName == 'first'">
					{{$t('primarySetup.Restore_the_default')}}</el-button>
				<el-button class="cancel-btn" type="primary" @click="cancel">{{$t('primarySetup.cancel')}}</el-button>
				<el-button class="confirm-btn" type="primary" @click="confirm">{{$t('primarySetup.save')}}</el-button>
			</div>
		</DialogPopup>
	</div>
</template>

<script>
	import DialogPopup from "../../../selfDefineTemplate/components/dialogPopup";
	export default {
		name: "primarySetup",
		created() {
			
		},
		components: {
			DialogPopup,
		},
		data() {
			return {
				// perntage:0,
				marks: {
					0.5: "0.5",
					0.6: "0.6",
					0.7: "0.7",
					0.8: "0.8",
					0.9: "0.9",
					1: {
						style: {
							color: "#1989FA",
						},
						label: "1.0",
					},
					1.1: "1.1",
					1.2: "1.2",
					1.3: "1.3",
					1.4: "1.4",
					1.5: "1.5",
				},
				karyoSetNum: 1,
				arraySetNum: 1,
				isShow: false,
				isEnlarge: 0,
				activeName: "first",
				chromoDefault: 1, //染色体初始值大小
				chromoEnlarge: 1, //染色体放大缩小值
				analysisModeDefault: '1',	//默认分析模式
				hasHideNumber: '0', //是否隐藏染色体编号
			};
		},
		props: {
			// visible: false,
			karyoNum: "",
			arrayNum: "",
		},
		methods: {
            handleClick(tab){
                if(tab.name == 'first'){
                    this.$api.analysisSetUp({
                        singleNum: this.karyoSetNum,
                        typeNum: this.arraySetNum,
                        analysisModeDefault: this.analysisModeDefault,
                        hasHideNumber: this.hasHideNumber
                    }).then(res => {
                        if (res.code == 200) {
                            let obj = {
                                singleNum: this.karyoSetNum,
                                typeNum: this.arraySetNum,
                                chromoDefault: this.$store.state.analysisInfo.chromoDefault,
                                chromoEnlarge: this.$store.state.analysisInfo.chromoEnlarge,
                                isEnlarge: this.$store.state.analysisInfo.isEnlarge,
                                analysisModeDefault: String(this.analysisModeDefault),
                                hasHideNumber: String(this.hasHideNumber)
                            }
                            this.$store.commit('analysisInfoStore', obj);
                           // this.$message.success(this.$t('primarySetup.Set_up_success'));
                           // this.isShow = false;
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                }
                if(tab.name == 'second'){
                    this.$api.analysisChromo({
                        chromoDefault: this.chromoDefault,
                        chromoEnlarge: this.chromoEnlarge,
                        isEnlarge: this.isEnlarge
                    }).then(res => {
                        if (res.code == 200) {
                            let obj = {
                                singleNum: this.$store.state.analysisInfo.singleNum,
                                typeNum: this.$store.state.analysisInfo.typeNum,
                                chromoDefault: this.chromoDefault,
                                chromoEnlarge: this.chromoEnlarge,
                                isEnlarge: this.isEnlarge,
                                analysisModeDefault: String(this.$store.state.analysisInfo.analysisModeDefault),
                                hasHideNumber: String(this.$store.state.analysisInfo.hasHideNumber)
                            }
                            this.$store.commit('analysisInfoStore', obj);
                            //this.$message.success(this.$t('primarySetup.Set_up_success'));
                           // this.isShow = false;
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                }
            },
			confirm() {
				if (this.activeName == 'second') {
					this.$api.analysisSetUp({
						singleNum: this.karyoSetNum,
						typeNum: this.arraySetNum,
						analysisModeDefault: this.analysisModeDefault,
						hasHideNumber: this.hasHideNumber
					}).then(res => {
						if (res.code == 200) {
							let obj = {
								singleNum: this.karyoSetNum,
								typeNum: this.arraySetNum,
								chromoDefault: this.$store.state.analysisInfo.chromoDefault,
								chromoEnlarge: this.$store.state.analysisInfo.chromoEnlarge,
								isEnlarge: this.$store.state.analysisInfo.isEnlarge,
								analysisModeDefault: String(this.analysisModeDefault),
								hasHideNumber: String(this.hasHideNumber)
							}
							this.$store.commit('analysisInfoStore', obj);
							this.$message.success(this.$t('primarySetup.Set_up_success'));
							this.isShow = false;
						} else {
							this.$message.error(res.message);
						}
					})
				} else if(this.activeName == 'first') {
					this.$api.analysisChromo({
						chromoDefault: this.chromoDefault,
						chromoEnlarge: this.chromoEnlarge,
						isEnlarge: this.isEnlarge
					}).then(res => {
						if (res.code == 200) {
							let obj = {
								singleNum: this.$store.state.analysisInfo.singleNum,
								typeNum: this.$store.state.analysisInfo.typeNum,
								chromoDefault: this.chromoDefault,
								chromoEnlarge: this.chromoEnlarge,
								isEnlarge: this.isEnlarge,
								analysisModeDefault: String(this.$store.state.analysisInfo.analysisModeDefault),
								hasHideNumber: String(this.$store.state.analysisInfo.hasHideNumber)
							}
							this.$store.commit('analysisInfoStore', obj);
							this.$message.success(this.$t('primarySetup.Set_up_success'));
							this.isShow = false;
						} else {
							this.$message.error(res.message);
						}
					})
				}
			},
			cancel() {
				this.isShow = false;
				this.karyoSetNum = this.$store.state.analysisInfo.singleNum;
				this.arraySetNum = this.$store.state.analysisInfo.typeNum;
				this.chromoDefault = this.$store.state.analysisInfo.chromoDefault;
				this.chromoEnlarge = this.$store.state.analysisInfo.chromoEnlarge;
				this.isEnlarge = this.$store.state.analysisInfo.isEnlarge || 0;
				this.analysisModeDefault= String(this.$store.state.analysisInfo.analysisModeDefault || 1),
				this.hasHideNumber= String(this.$store.state.analysisInfo.hasHideNumber || 0)
			},
			reset() {
				this.$api.karyoReset({}).then(res => {
					if (res.code == 200) {
						let obj = {
							singleNum: this.$store.state.analysisInfo.singleNum,
							typeNum: this.$store.state.analysisInfo.typeNum,
							chromoDefault: res.data.chromoDefault,
							chromoEnlarge: res.data.chromoEnlarge,
							isEnlarge: res.data.isEnlarge,
							analysisModeDefault: String(res.data.analysisModeDefault || 1),
							hasHideNumber: String(res.data.hasHideNumber || 0)
						}
						this.$store.commit('analysisInfoStore', obj);
						this.$message.success(this.$t('primarySetup.Set_up_success'));
					} else {
						this.$message.error(res.message);
					}
				})
			},
			visibleChange(val) {
				this.isShow = val;
			},
			showSetup() {
				this.isShow = true;
			},
		},
		computed: {},
		watch: {
			'$store.state.analysisInfo': function(newVal) {
				this.karyoSetNum = newVal.singleNum;
				this.arraySetNum = newVal.typeNum;
				this.chromoDefault = newVal.chromoDefault;
				this.chromoEnlarge = newVal.chromoEnlarge;
				this.isEnlarge = newVal.isEnlarge || 0;
				this.analysisModeDefault= String(newVal.analysisModeDefault || 1);
				this.hasHideNumber= String(newVal.hasHideNumber || 0);
			}
		}
	};
</script>

<style lang="scss">
	.primary-setup {

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		input[type="number"] {
			-moz-appearance: textfield;
		}

		box-sizing: border-box;

		.pointer {
			width: fit-content;
			font-size: 16px;
			cursor: pointer;

			.el-icon-setting {
				font-size: 18px;
				margin: 2px 3px 0 0;
				vertical-align: top;
			}
		}

		.el-dialog__wrapper {
			display: flex;

			.el-dialog {
				position: relative;
				width: 640px;
				height: fit-content;

				.pop-cnt {
					overflow: hidden;
				}

				.el-dialog__body {
					padding: 0;
				}

				.btns {
					position: absolute;
					right: 30px;
					bottom: 20px;
				}

				.cancel-btn {
					width: 68px;
					height: 30px;
					background: #fff !important;
					color: #000;
					border: 1px solid rgba(0, 0, 0, 0.15) !important;
					margin-right: 8px;
				}

				.default-btn {
					position: absolute;
					left: -256px;
					width: 68px;
					height: 30px;
					background: #fff !important;
					color: #000;
					border: 1px solid rgba(0, 0, 0, 0.15) !important;
				}

				.confirm-btn {
					width: 68px;
					height: 30px;
					background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
					color: #fff;
					border: none;
				}

				.el-dialog__footer {
					background: none;
					border: none;
					padding: 0;
				}
			}
		}

		.el-dialog__header {
			height: 36px;
			padding: 0 16px !important;
			box-sizing: border-box;
		}

		.el-dialog__headerbtn {
			top: 20px;
		}

		.el-tabs--border-card {
			height: 495px;
			border: 0;
		}

		.el-tabs__nav-wrap {
			background: #eff0f2;
		}

		.el-tabs__content {
			float: left;
			padding: 30px;
			width: calc(100% - 235px);
		}

		.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
			width: 160px;
			height: 34px;
			line-height: 34px;
			font-size: 15px;
			color: #333333;
			border: none;
			padding-left: 20px;
			margin-right: 0;
			text-align: left;
			border-left: 4px solid #eff0f2;

			&.is-active {
				position: relative;
				color: #4773b8;
				background: #fff;
				border-left: 4px solid #4773b8;
			}
		}

		.setup {
			width: fit-content;
			font-size: 15px;
			display: flex;
			margin: 0 auto 12px;

			label {
				width: fit-content;
				margin-right: 4px;
				line-height: 30px;
			}

			.el-input {
				width: fit-content;
				height: 30px;
			}

			.el-input__inner {
				height: 30px;
				padding: 0 12px;
				box-sizing: border-box;
			}
		}

		.intrd {
			width: fit-content;
			margin: 10px 0 10px 30px;
			font-size: 13px;
		}

		.karyo-size {
			width: 332px;
			margin-bottom: 30px;
		}

		.karyo-slider {
			width: 334px;
			margin-left: 43px;
		}

		// .el-slider__bar {
		//     // background: #4773b8;
		// }
		.el-slider__marks-text {
			width: 20px;
			font-size: 12px;
		}

		.el-slider__button {
			user-select: none;
			width: 8px;
			height: 20px;
			background: #ffffff;
			box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 9%);
			border-radius: 4px;
			border: 1px solid #4773b8;
		}

		.el-slider__runway {
			height: 4px;
			background: linear-gradient(180deg, #dedfe1 0%, #d8d9da 100%);
		}

		.el-slider__stop {
			top: -2px;
			width: 2px;
			height: 8px;
			background: #c7c8ca;
			border-radius: 1px;
		}

		.el-slider__button-wrapper {
			top: -16px;
		}
	}
</style>
