const Common = {
    cookie: {
        set: function(key, val, time) {
            //设置cookie方法
            var date = new Date(); //获取当前时间
            var expiresHours = time; //将date设置为n小时以后的时间
            date.setTime(date.getTime() + expiresHours * 3600 * 1000); //格式化为cookie识别的时间
            document.cookie =
                key + "=" + val + ";expires=" + date.toGMTString(); //设置cookie
        },
        get: function(key) {
            //获取cookie方法
            /*获取cookie参数*/
            var getCookie = document.cookie.replace(/[ ]/g, ""); //获取cookie，并且将获得的cookie格式化，去掉空格字符
            var arrCookie = getCookie.split(";"); //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
            var tips; //声明变量tips
            for (var i = 0; i < arrCookie.length; i++) {
                //使用for循环查找cookie中的tips变量
                var arr = arrCookie[i].split("="); //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
                if (key == arr[0]) {
                    //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
                    tips = arr[1]; //将cookie的值赋给变量tips
                    break; //终止for循环遍历
                }
            }
            return tips;
        },
        delete: function(key) {
            //删除cookie方法
            var date = new Date(); //获取当前时间
            date.setTime(date.getTime() - 10000); //将date设置为过去的时间
            document.cookie = key + "=v; expires =" + date.toGMTString(); //设置cookie
        }
    },
    resloveUrl() {
        var arr = location.search.substring(1).split("&");
        var obj = {};
        arr.map(function(a) {
            var sp = a.split("=");
            if (sp.length == 2) {
                obj[sp[0]] = sp[1];
            }
        });
        return obj;
    },
    timestampFormat(timestamp, format) {
        if (!timestamp || !Number(timestamp)) {
            return "";
        }
        timestamp =
            String(timestamp).length == 10 ? timestamp + "000" : timestamp;
        let date = new Date(Number(timestamp));
        var conf = {
            yyyy: date.getFullYear(),
            MM: this.addZero(date.getMonth() + 1),
            M: date.getMonth() + 1,
            dd: this.addZero(date.getDate()),
            d: date.getDate(),
            HH: this.addZero(date.getHours()),
            mm: this.addZero(date.getMinutes()),
            ss: this.addZero(date.getSeconds())
        };
        format = format || "yyyy-MM-dd HH:mm:ss";
        return format.replace(/[a-zA-Z]+/g, mat => {
            return conf[mat] || "";
        });
    },
    addZero(n) {
        n = Number(n) || 0;
        return n > 9 ? n : "0" + n;
    },
    timeTransition(time) {
        time = Number(time) || 0;
        var s = 1000,
            m = 60 * s,
            h = m * 60,
            d = h * 24;
        var dd = Math.floor(time / d),
            hh = Math.floor((time - d * dd) / h),
            mm = Math.floor((time - d * dd - h * hh) / m),
            ss = Math.floor((time - d * dd - h * hh - m * mm) / s);
        return {
            dd: dd,
            hh: this.addZero(hh),
            mm: this.addZero(mm),
            ss: this.addZero(ss),
            tt: this.getAfterDay(dd)
        };
    },
    getAfterDay(days) {
        if (days == 1) {
            return "明天";
        } else {
            var t = new Date().getTime() + days * 24 * 60 * 60 * 1000;
            var d = new Date(t).getDate();
            return d + "日";
        }
    },
    permissionsByPointList(key) {
        let powerMap = localStorage.getItem("powerMap")
            ? JSON.parse(localStorage.getItem("powerMap"))
            : {};
        let pointList = localStorage.getItem("pointList")
            ? JSON.parse(localStorage.getItem("pointList"))
            : [];
        return powerMap[key] || pointList.includes(powerMap[key]);
    },
    permissionsByPage(key) {
        const config = {
            personal_info: "/user/queryUserByToken",
            personal_identity: "/user/queryUserByToken",
            organ_validation: "/user/queryUserByToken",
            personal_set: "/user/getWorkConfig",
            personal_account: "/billtype/queryByToken",
            personal_detail: "/paymentSummary/profit",
            member: "/role/listById",
            team_work_setting: "/user/getWorkConfig"
        };
        return config[key] ? this.permissionsByPointList(config[key]) : false;
    },
    // TODO 下周更改权限，改回
    permissions(key = "") {
        return (
            this.permissionsByPointList(key) ||
            this.permissionsByPage(key)
        );
    },
    getPermissionFromPowerList(key) {
        let list = localStorage.getItem("powerList")
            ? JSON.parse(localStorage.getItem("powerList"))
            : [];
        const item = list.filter(
            a => a.powerType.indexOf(key) > -1 || key.indexOf(a.powerType) > -1
        )[0];
        return item && !!item.hasPower;
    }
};

export default Common;
