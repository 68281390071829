<template>
  <div class="information" :class="{'english': $store.state.language == 'en'}">
    <span class="information_title">
      <img :src="iconBingliNormal" />
      <span>{{$t("new_interaction.Case_information")}}</span>
      <img :src="editNormal" @click="editNormalBtn()" v-show="$store.state.caseId && $store.state.caseValue.patientType != 1 && $store.state.caseValue.patientType != 3" />
      <!-- <i class="el-icon-upload upload-pdf" @click="openExplorer"></i> -->
      <div style="display: none;">
        <input type="file" id="casesPdfInput" multiple accept=".pdf, .png, .jpg" ref="casesPdfInput" @change="filterFilesByType" />
      </div>
    </span>
    <div class="content">
      <ul class="information_center_top">
        <li>
          <p>{{$t("information.Case_number")}}</p>
          <a>：</a>
          <span :title="formList.name">{{formList.name}}</span>
        </li>
        <li>
          <p>{{$t("information.name")}}</p>
          <a>：</a>
          <span :title="formList.realName">{{formList.realName}}</span>
        </li>
        <li>
          <p>{{$t("information.age")}}</p>
          <a>：</a>
          <span :title="formList.age">{{formList.age}}</span>
        </li>
        <li>
          <p>{{$t("information.gender")}}</p>
          <a>：</a>
          <span :title="formList.gender">{{formList.gender}}</span>
        </li>
      </ul>
      <ul class="information_center_banner">
        <li>
          <p>{{$t("information.Sample_type")}}</p>
          <a>：</a>
          <span :title="sampleTypeName">{{sampleTypeName}}</span>
        </li>
        <li>
          <p>{{$t("information.Receiving_date")}}</p>
          <a>：</a>
          <span :title="getLocalTime(formList.receiveDate)">{{getLocalTime(formList.receiveDate)}}</span>
        </li>
        <li>
          <p>{{$t("information.Clinical_diagnosis")}}</p>
          <a>：</a>
          <span :title="formList.clinicalDiagnosis">{{formList.clinicalDiagnosis}}</span>
        </li>

        <li>
          <p>{{$t("information.files")}}</p>
          <a>：</a>
          <div>
            <span class="attachment-icon" :class="item.format" v-for="(item, index) in fileUrl" :key=index :title="item.name" @click="previewAttachment(item)">{{item.name}}</span>
          </div>
        </li>
      </ul>
      <ul class="information_center_footer">
        <li>
          <p>{{$t("information.note")}}</p>
          <a>：</a>
          <span :title="formList.remark">{{formList.remark}}</span>
        </li>
      </ul>
    </div>
    <el-dialog :title="$t('new_interaction.Case_information')" :visible.sync="dialogFormVisible" width="684px" style="padding: 27px 0px" class="caseInformation" :close-on-click-modal="false">
      <el-form :model="formInline" label-width="159px" class="demo-ruleForm" ref="formInline">
        <el-form-item :label="$t('information.Case_number')" prop="name">
          <el-input v-model="formInline.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('information.name')">
          <el-input v-model="formInline.realName" :placeholder="$t('information.Please_enter_name')" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item :label="$t('information.age')">
          <el-col :span="10">
            <el-input v-model="formInline.age" maxlength="3"></el-input>
            <!-- <el-select v-model="formInline.gender" :placeholder="$t('information.select_please')">
              <el-option :label="$t('information.day')" :value="$t('information.day')"></el-option>
              <el-option :label="$t('information.week')" :value="$t('information.week')"></el-option>
              <el-option :label="$t('information.month')" :value="$t('information.month')"></el-option>
              <el-option :label="$t('information.year')" :value="$t('information.year')"></el-option>
            </el-select> -->
          </el-col>
          <el-col class="line" :span="4" style="text-align: center">{{$t("information.gender")}}</el-col>
          <el-col :span="10">
            <el-select v-model="formInline.gender" :placeholder="$t('information.select_please')">
              <el-option :label="$t('information.male')" :value="$t('information.male')"></el-option>
              <el-option :label="$t('information.female')" :value="$t('information.female')"></el-option>
              <el-option :label="$t('information.other')" :value="$t('information.other')"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('information.Sample_type')">
          <el-select v-model="formInline.sampleType" :placeholder="$t('information.select_please')" style="display: block">
            <el-option :label="$t('popup.old_report.blood')" value="1"></el-option>
            <el-option :label="$t('popup.old_report.marrow')" value="2"></el-option>
            <el-option :label="$t('popup.old_report.villus')" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('information.Receiving_date')">
          <el-form-item>
            <el-date-picker type="date" v-model="formInline.receiveDate" value-format="timestamp" style="width: 100%;" format="yyyy 年 MM 月 dd 日"></el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-form-item :label="$t('information.Clinical_diagnosis')">
          <el-input v-model="formInline.clinicalDiagnosis" :placeholder="$t('information.Please_enter_your_diagnosis')" maxlength="225"></el-input>
        </el-form-item>
        <el-form-item :label="$t('information.note')">
          <el-input v-model="formInline.remark" :placeholder="$t('information.Please_enter_remarks')" maxlength="225"></el-input>
        </el-form-item>
        <el-form-item :label="$t('information.files')">
          <div class="attachment">
            <div class="attachment-top">
              <el-button icon="el-icon-upload2" @click="openExplorer">{{$t('information.upload_file')}}</el-button>
              <span style="line-height: 20px">{{$t('information.support_files')}}</span>
            </div>
            <ul class="attachment-upload">
              <li v-for="(item, index) in filesShowBack" :key="index">
                <!-- <li> -->
                <div class="attachment-item">
                  <div class="attachment-name attachment-icon">{{item.name}}</div>
                  <!-- <div class="attachment-name">附件.xls</div> -->
                  <i class="el-icon-close" @click="removeAttachment(index)"></i>
                </div>
              </li>
            </ul>
            <ul class="attachment-upload">
              <li v-for="(item, index) in manager.list" :key="index">
                <!-- <li> -->
                <div class="attachment-item">
                  <div class="attachment-name attachment-icon">{{item.file.upname}}</div>
                  <!-- <div class="attachment-name">附件.xls</div> -->
                  <i class="el-icon-close" @click="stopUpload(item)"></i>
                </div>
                <div class="attachment-progress">
                  <div class="attachment-progress-bar" :class="{success: item.status == 2 && item.percent >= 100, fail: item.status == 3}">
                    <em :style="{width: item.percent + '%'}"></em>
                  </div>
                  <div class="attachment-progress-num">{{item.percent}}%</div>
                </div>
              </li>
            </ul>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmitBtn" class="onSubmitBtn">{{$t("information.determine")}}</el-button>
          <el-button type="info" @click="onSubmitClose" class="onSubmitClose">{{$t("information.cancel")}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- <upload-pdf :visible.sync="uploadVisible" :filesList="filesList" @close="uploadVisible=false"></upload-pdf> -->
    <el-dialog :visible.sync="dialogPdfVisible" width="684px" class="pdf-dialog">
      <pdf ref="pdf" :src="previewPdfUrl" :page="currentPage" @num-pages="pageCount=$event" @page-loaded="currentPage=$event" @progress="loadedRatio = $event"></pdf>
      <i class="el-icon-arrow-up" @click="pdfPageUp" v-if="currentPage > 1"></i>
      <i class="el-icon-arrow-down" @click="pdfPageDown" v-if="currentPage < pageCount"></i>
    </el-dialog>
  </div>

</template>

<script>
// import uploadPdf from './uploadPdf.vue'
import pdf from 'vue-pdf'
import uploader from "../../assets/utils/fileUploader.js";
export default {
  name: "information",
  props: ["dataList"],
  components: {
    // uploadPdf
    pdf
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
      iconBingliNormal: require('@/assets/images/analysissystem/icon_bingli_normal.png'),
      editNormal: require('@/assets/images/analysissystem/Edit_normal.png'),
      dialogFormVisible: false,
      formInline: {
        name: '',
        realName: '',
        gender: '',
        age: '',
        sampleType: '',
        receiveDate: '',
        clinicalDiagnosis: '',
        remark: '',
      },
      sampleTypeName: '',
      formList: {
        name: '',
        realName: '',
        gender: '',
        age: '',
        sampleType: '',
        receiveDate: '',
        clinicalDiagnosis: '',
        remark: '',
      },
      filesList: [],
      filesShowBack: [],
      uploadVisible: false,
      manager: {},
      fileUrl: [],
      dialogPdfVisible: false,
      previewPdfUrl: '',
    }
  },
  created() {
    // this.language = localStorage.getItem('language');
  },
  methods: {
    pdfPageUp() {
      this.currentPage > 1 && this.currentPage--;

      console.log(this.currentPage, this.pageCount);
    },
    pdfPageDown() {
      this.currentPage < this.pageCount && this.currentPage++;
    },
    previewAttachment(item) {
      console.log(item.url);
      // this.doPreview(item) ;return;
      this.$api.patientInfo({ patientId: this.$store.state.caseId }).then(res => {
        if (res.code == 200) {
          const url = res.data.fileUrl.find(a => a.split("?")[0] === item.url.split("?")[0]);
          const target = {
            name: decodeURIComponent(url.split("/").pop().split("?")[0]),
            format: url.split(".").pop().split("?")[0],
            url: url
          }
          this.doPreview(target)
        } else {
          this.doPreview(item)
        }
      })

    },
    doPreview(item) {
      if (item.format === 'pdf') {
        this.dialogPdfVisible = true;
        this.pageCount = 0;
        this.currentPage = 1;
        this.previewPdfUrl = item.url;
      } else if (['jpg', 'png'].includes(item.format)) {
        window.open(item.url, "_blank")
      } else {
        this.previewFile(item.url);
      }
    },
    /**
     * 打开资源管理器
     */
    openExplorer() {
      this.$refs["casesPdfInput"].click();
    },
    previewFile(fileUrl) {
      let url = encodeURIComponent(fileUrl)
      let officeUrl = 'http://view.officeapps.live.com/op/view.aspx?src=' + url;
      console.log(officeUrl);
      window.open(officeUrl, '_target')
    },
    /**
     * input file选择文件后进行过滤
     */
    filterFilesByType(e) {
      if (e.target.files.length > 10 - this.fileUrl.length) {
        this.$message({
          type: 'warning',
          message: this.$t('caseAllocation.files_50_most')
        })
        return;
      }
      const overSize = 10 * 1024 * 1024;
      const bool = Array.prototype.slice.call(e.target.files).some(a => { return a.size > overSize });
      if (bool) {
        this.$message({
          type: 'warning',
          message: this.$t('caseAllocation.oversize')
        })
        return;
      }
      this.filesList = Array.prototype.slice.call(e.target.files).filter(file => {
        return /\.(png|jpg|pdf|doc|docx|xls|xlsx)$/g.test(file.name);
      });
      if (!this.filesList.length) {
        this.$message({
          type: 'warning',
          message: this.$t('caseAllocation.only_support_files')
        })
      } else {
        this.uploadVisible = true;
        this.$refs["casesPdfInput"].value = "";
        this.checkRename();
        this.init();
      }
    },
    getNameFormat(str) {
      let name = str.split(".");
      let format = name.pop();
      name = name.join(".");
      return { name, format }
    },
    checkRename() {
      console.log(this.filesList);
      console.log(this.filesShowBack);
      this.filesList.forEach(a => {
        const { name, format } = this.getNameFormat(a.name);
        let exist = this.filesShowBack.filter(b => {
          const obj = this.getNameFormat(b.name);
          return obj.name.indexOf(name) >= 0 && format === obj.format
        });
        if (exist && exist.length) {
          let nums = exist.map(b => {
            const obj = this.getNameFormat(b.name);
            const _name = obj.name.replace(name, "");
            let num = _name.match(/\d+$/g);
            num = num ? num[0] : 0;
            return num;
          })
          console.log(nums);
          let max = Math.max.apply(null, nums);
          max++;
          // a.name = a.name+"_"+max;
          a.upname = name + "_" + max + "." + format;
        } else {
          a.upname = a.name;
        }
      })
    },
    //关闭编辑页面
    onSubmitClose() {
      this.dialogFormVisible = false;
    },

    //编辑页面
    editNormalBtn() {
      this.dialogFormVisible = true;
      let formListVal = JSON.stringify(this.formList);
      let formInlineVal = JSON.parse(formListVal);
      for (let i in this.formInline) {
        this.formInline[i] = formInlineVal[i] ? formInlineVal[i] : ''
      }
      this.filesShowBack = [].concat(this.fileUrl);
      console.log(this);
    },

    //时间格式转换
    getLocalTime(time) {
      if (!!time && time != 0) {
        let d = new Date(time);
        return d.getFullYear() + this.$t('caseAllocation.year') + (d.getMonth() + 1) + this.$t('caseAllocation.month') + d.getDate() + this.$t('caseAllocation.day')
      } else {
        return ""
      }
    },

    //选择
    sampleTypeBtn() {
      if (this.formList.sampleType == 1) {
        this.sampleTypeName = this.$t('popup.old_report.blood');
      } else if (this.formList.sampleType == 2) {
        this.sampleTypeName = this.$t('popup.old_report.marrow');
      } else if (this.formList.sampleType == 3) {
        this.sampleTypeName = this.$t('popup.old_report.villus');
      } else {
        this.sampleTypeName = ""
      }
    },
    //提交编辑信息
    onSubmitBtn() {
      let post_data = this.formInline;
      post_data.patientId = this.$store.state.caseId;
      const fileUrl = this.manager.list && this.manager.list.map(a => a.ossPath);
      post_data.fileUrl = this.filesShowBack.map(a => a.url).concat(fileUrl).join(",");
      if (this.filesList.length && this.manager.list.length) {
        if (this.manager.status >= 2) {

          this.submit(post_data);
        } else {
          this.$confirm(this.$t("information.confirm")).then(res => {
            this.submit(post_data);
          })
        }
      } else {
        this.submit(post_data);
      }
    },
    submit(post_data) {
      this.$api.updatePatientInfo(post_data).then((res) => {
        if (res.code == 200) {
          for (let i in this.formList) {
            this.formList[i] = !!post_data[i] ? post_data[i] : '';
          }
          this.$store._mutations.updatePatientInfo(this.$store.state.caseId, this.formList);
          this.sampleTypeBtn();
          this.dialogFormVisible = false;
          this.patientInfo();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    patientInfo() {
      let post_data = {
        patientId: this.$store.state.caseId,
      };
      this.$api.patientInfo(post_data).then((res) => {
        if (res.code == 200) {
          for (let i in this.formList) {
            this.formList[i] = !!res.data.patientList[i] ? res.data.patientList[i] : '';
          }
          this.fileUrl = res.data.fileUrl.map(a => {
            return {
              name: decodeURIComponent(a.split("/").pop().split("?")[0]),
              format: a.split(".").pop().split("?")[0],
              url: a
            }
          });
          this.$store._mutations.updatePatientInfo(this.$store.state.caseId, this.formList);
          this.sampleTypeBtn();
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    removeAttachment(index) {
      this.filesShowBack.splice(index, 1)
      this.$forceUpdate();
    },
    /**
     * 初始化
     */
    init() {
      this.manager = {
        status: 1, //0=准备上传 1=上传中 2=全部上传完成 3=上传完成但有文件上传失败 4=全部上传失败
        percent: 0,
        totalSize: this.filesList.reduce((a, b) => a + b.size, 0),
        remaining: '--:--:--',
        success: 0,
        fail: 0,
        left: this.filesList.length,
        list: this.filesList.map(file => {
          return {
            lastUpdateTime: file.lastUpdateTime,    //最后更新时间
            file: file,    //文件
            percent: 0,    //上传百分比
            progress: 0,    //分析百分比
            uploaded: 0,    //已上传大小
            status: 0,    //状态 0=等待上传  1=上传中  2=上传成功 3=上传失败
            state: "",    //状态显示文字
            speed: "",    //显示的上传速度
            _speed: "",    //触发loadpress时的速度
            sizeFormat: this.sizeFormat(file.size),    //文件大小
            random: this.random_string(6)    //随机串
          }
        })
      }
      console.log(this.manager);
      this.doUpload();
    },
    /**
     * 文件大小格式
     * @param {Object} size
     */
    sizeFormat(size) {
      let kb = parseInt(size / 1024),
        mb = Number(size / 1024 / 1024).toFixed(1),
        gb = Number(size / 1024 / 1024 / 1024).toFixed(1);
      const base = 1000 / 1024;
      return gb >= base ? gb + "G" : mb >= base ? mb + "M" : kb + "Kb";
    },
    /**
     * 生成随机串
     */
    random_string(len) {
      len = len || 32;
      const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      const maxPos = chars.length;
      let pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    /**
     * 弹窗关闭
     */
    dialogClose() {
      this.$emit("close");
    },
    /**
     * 执行上传
     */
    doUpload() {
      let start = 4;
      let zipUploadLooping = false;
      const list = this.manager.list.filter(a => {
        return a.status < 2;
      });
      //每秒循环一次 更新各病例当前的状态
      const loop = () => {
        let totalPercent = 0,
          count = 0;
        let byte_s = 0;
        let byte_c = 0;
        let loaded = 0;
        let success_count = 0,
          fail_count = 0,
          left_count = 0;
        this.manager.list.forEach(item => {
          const percent = parseInt((item.uploaded / item.file.size) * 100);
          item.speed = item._speed;
          // item.remaining = item._remaining;
          item.percent = percent || 0;
          totalPercent += percent;
          count++;
          byte_s += item.byte_s || 0;
          byte_c += item.byte_s ? 1 : 0;
          loaded += item.uploaded || 0;
          item.status === 2 && success_count++;
          item.status === 3 && fail_count++;
          item.status !== 3 && item.status !== 2 && left_count++;
        });
        // console.warn('byte_s',byte_s,'totalPercent',totalPercent,'count',count);
        let speed = byte_s / byte_c;
        const remaining = speed ? (this.manager.totalSize - loaded) / speed : 0;
        this.manager.remaining = this.msToTimeFormat(remaining);
        const percent = totalPercent / count;
        // console.log(totalPercent, count, percent);
        this.manager.percent = Number(percent.toFixed(1));

        this.manager.success = success_count;
        this.manager.fail = fail_count;
        this.manager.left = left_count;

        const allFinished = this.manager.list.every(a => a.status >= 2);
        const allSuccess = this.manager.list.every(a => a.status == 2);
        console.log(allFinished, allSuccess, this.manager.percent);
        if (allFinished && allSuccess) {
          this.manager.status = 2;
          this.manager.percent = 100;
          this.manager.list.forEach(item => {
            item.percent = 100;
          });
        }
        if (allFinished && !allSuccess) {
          this.manager.status = 3;
          this.manager.percent = 100;
          this.manager.list.forEach(item => {
            item.percent = 100;
          });
        }
        if (!allFinished) {
          this.manager.status = 1;
        }
        if (this.manager.percent <= 100 && !allFinished) {
          this.manager.loopTimer = setTimeout(loop, 1000);
        }
        // this.$forceUpdate();
        // console.warn('全部完成',allFinished,'分析中：',analysising,'进度',this.zipUploadManager.percent,'状态：',this.zipUploadManager.status);
      };
      //文件上传的主要进程
      const main = item => {
        // console.log(item);
        //记录当前时间
        item.lasttime = +new Date();
        //更改文件状态 表示当前正在上传
        uploader({
          files: [item.file],
          path: ["attachment", this.$store.state.caseId, ""].join("/"),
          ossUploadAccess: this.$store.state.ossUploadAccess,
          processHandle: _file => {
            const size = _file.loaded - item.uploaded;
            const time = +new Date();
            const byte_s = size / (time - item.lasttime);
            const kb_s = (byte_s * 1000) / 1024;
            const mb_s = (byte_s * 1000) / 1024 / 1024;
            const gb_s = (byte_s * 1000) / 1024 / 1024 / 1024;
            const base = 1000 / 1024;
            const speed = gb_s >= base ? gb_s : (mb_s >= base ? mb_s : kb_s);
            const unit = gb_s >= base ? "G/s" : (mb_s >= base ? "M/s" : "Kb/s");
            item._speed = speed.toFixed(2) + unit;
            // console.log(time - item.lasttime,'ms上传了',size,'字节');
            item.byte_s = byte_s;
            item.uploaded = _file.loaded;
            item.status = 1;
            item.lasttime = time;
            if (!zipUploadLooping) {
              zipUploadLooping = true;
              loop();
            }
          }
        }).then(arr => {
          const target = arr[0];
          console.log(target);
          item.uploaded = item.file.size;
          if (start < list.length) {
            main(list[start]);
            start++;
          }
          if (target.status === "success") {
            item.status = 2;
            item.state = "success";
            this.manager.success++;
            this.manager.left--;
            item.ossPath = target.path;
            // this.uploadLocalPatient(item);
          } else {
            // item.progress = 1;
            item.status = 3;
            item.state = "exception";
            this.manager.fail++;
            this.manager.left--;
          }
        });
      };
      list.slice(0, start).forEach(item => {
        main(item);
      });
    },
    /**
     * 停止上传并移除
     */
    stopUpload(item) {
      item.file.loader && item.file.loader.stop();
      this.manager.list = this.manager.list.filter(target => target !== item);
    },
    /**
     * 毫秒转时间格式
     * @param {Object} ms
     */
    msToTimeFormat(ms) {
      if (ms < 0) {
        return "计算中";
      }
      const s = Math.ceil(ms / 1000);
      const hour = Math.floor(s / 3600);
      const minites = Math.floor((s - hour * 3600) / 60);
      const second = s - hour * 3600 - minites * 60;
      const hh = hour > 9 ? hour : "0" + hour;
      const mm = minites > 9 ? minites : "0" + minites;
      const ss = second > 9 ? second : "0" + second;
      const day = parseInt(hour / 24);
      return hour > 24 ? this.$t('case_manage.exceed') + day + this.$t('case_manage.days') : [hh, mm, ss].join(":");
    },
  },
  watch: {
    '$store.state.caseId': function (val) {
      if (!!val) {
        //病例信息
        this.patientInfo();
      } else {
        this.formList = {
          name: '',
          realName: '',
          gender: '',
          age: '',
          sampleType: '',
          receiveDate: '',
          clinicalDiagnosis: '',
          remark: '',
        }
      }
    },
    dialogFormVisible: function (v) {
      if (!v) {
        this.filesList = [];
        this.manager = {};
      }
    },
    dialogPdfVisible: function (v) {
      if (!v) {
        this.previewPdfUrl = '';
        this.pageCount = 0;
        this.currentPage = 1;
      }
    }
  }

}
</script>
<style lang="scss">
.pdf-dialog .el-icon-arrow-up,
.pdf-dialog .el-icon-arrow-down {
  position: absolute;
  left: 50%;
  font-size: 50px;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  &.disabled {
    display: none;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.pdf-dialog .el-icon-arrow-up {
  top: 60px;
}
.pdf-dialog .el-icon-arrow-bottom {
  bottom: 50px;
}
.caseInformation > .el-dialog > .el-dialog__body {
  padding: 30px 50px 30px 20px !important;
}
.attachment {
  overflow: hidden;
}
.attachment-top {
  display: flex;
  align-items: center;
}
.attachment-top .el-button {
  width: 130px;
  height: 40px !important;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-right: 10px;
  margin-left: 0 !important;
}
.attachment-upload {
  overflow: hidden;
  max-height: 120px;
  overflow-y: auto;
}
.attachment-upload li {
  overflow: hidden;
  margin-top: 12px;
}
.attachment-upload li:hover {
  background-color: #eeeeee;
}
.attachment-item {
  height: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.attachment-name {
  flex: 1;
  margin-right: 10px;
  padding-left: 26px;
}
.attachment-icon {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACWklEQVQ4T6WTT0gUYRjGn/ebaUZq3SW6FEjQrQ4pQUUiiCvbzmhQeOgQdehQlw6SO2vkJY0C+zOLKxpBFCEE/VGLamlnJiRP2b2iwLpEEBGJuYs64873xhpKpZuHvuv7PT94n/d5CP/5aC19S6fXEMrQJoidRHgHyHbHbplY0v0TYJ7xDnIoh0F0i6QcBeEEg1oRYrubNb+UIRUBRto5AMYoGLYbNc4dfjtMxc0RlTXxmQnnXdscrAgwOhwTgh6Bud+f0nv0Tf5TEE26tnHKsJxJEK65tpldFWCknQQzPSHi69HITNdMIfoQQK0QMi5D0QzCIIPqvIzxfgXASDlxADkG3QyimqUX/Xtg7F0QMq6Sso9CHmJQh5cxBlaYuL/TaxBSugQaqv70o72wNTbE4MayWGPaw5LukKCu+Yg2UFUMRhk84trm7UUTk2l3AzFPApSvr06efFn0bhBzsizWpbJLgu8C6NYKC9kguu4xGBv9cC4xnm2b/gWw3KMEvioCuY11pZuZj4VSbRIirAX4AQEXmShDzDkCYiyRcPvMqWUPTCt/gUG1bvWrtmShvqgq1MiytEWyGAHhsj+rX9HX+8/AiJU0tXmsN/H9Dw8My7EAHHEz5m7Dcj4AKH+oI3AfBdwrNZEHEBOBjOcHWr/9nt7FFRKnn+9QlPA1CIeY6SMRHScZvpmXVTldDRwwx0oltWmsP/H17+gvJ9FIe5eYOSVI9kMqLyRxDQFnAfgIuXkpuhUB5YGZyqeYqANADQizYNz3Qz01no1PVyrdql0on9WLTMyhp0eu1dafWHMNIFdvzM0AAAAASUVORK5CYII=)
    no-repeat left center;
  background-size: 16px;
}
.attachment-item > i {
  font-size: 16px;
  cursor: pointer;
}
.attachment-progress {
  height: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.attachment-progress-bar {
  flex: 1;
  height: 8px;
  overflow: hidden;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.04);
}
.attachment-progress-bar em {
  display: block;
  height: 100%;
  border-radius: 4px;
  background: #1890ff;
}
.attachment-progress-bar.success em {
  background: #07c160;
}
.attachment-progress-bar.fail em {
  background: #e72323;
}
.attachment-progress-num {
  width: 40px;
  text-align: right;
  color: #666;
}
</style>
<style lang="scss" scoped>
.information {
  width: 100%;
  height: 100%;
  background: #eaebec;
}
.content {
  height: 280px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #bdbebf;
    -webkit-border-radius: 7px;
    outline: 2px solid #eaebec;
    outline-offset: 2px;
    border: 2px solid #eaebec;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #eaebec;
    -webkit-border-radius: 3px;
  }
}
.information_title {
  width: 100%;
  height: 40px;
  background: #f8f8f8;
  position: relative;
  display: block;
}
.information_title img:nth-child(1) {
  display: inline-block;
  position: absolute;
  height: 15px;
  width: 14px;
  left: 20px;
  top: 13px;
}

.information_title span:nth-child(2) {
  display: inline-block;
  position: absolute;
  /* width: 70px; */
  height: 22px;
  left: 44px;
  top: 9px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}
.information_title img:nth-child(3) {
  display: inline-block;
  position: absolute;
  height: 24px;
  width: 24px;
  right: 12px;
  top: 8px;
  cursor: pointer;
}
.upload-pdf {
  position: absolute;
  height: 24px;
  width: 24px;
  font-size: 22px;
  right: 12px;
  top: 8px;
  cursor: pointer;
}
.information.english {
  .information_center_top {
    li {
      display: flex;
      p {
        width: auto;
        white-space: nowrap;
      }
      span {
        width: auto;
        white-space: nowrap;
      }
    }
  }
  .information_center_banner {
    li {
      display: flex;
      p {
        width: auto;
        white-space: nowrap;
      }
      span {
        width: auto;
        white-space: nowrap;
      }
    }
  }
  .information_center_footer {
    li {
      display: flex;
      p {
        width: auto;
        white-space: nowrap;
      }
      span {
        width: auto;
        white-space: nowrap;
      }
    }
  }
}
.information_center_top {
  display: block;
  height: 85px;
  padding: 12px 20px 10px;
}

.information_center_top li {
  height: 14px;
  margin-bottom: 10px;
}
.information_center_top li p {
  width: 60px;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  color: #333333;
  margin: 0;
  text-align: justify;
  text-align-last: justify;
  display: inline-block;
  // white-space: nowrap;
  float: left;
}
.information_center_top li p:after {
  display: inline-block;
  content: "";
  overflow: hidden;
  width: 100%;
  height: 0;
}
.information_center_top li a {
  width: 10px;
  height: 16px;
  float: left;
  line-height: 14px;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.information_center_top li span {
  width: calc(100% - 70px);
  height: 16px;
  float: left;
  line-height: 14px;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  // white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.information_center_banner {
  display: block;
  /* height: 80px; */
  padding: 12px 20px;
}
.information_center_banner li {
  /* height: 14px; */
  margin-bottom: 10px;
  display: flex;
}
.information_center_banner li > div {
  margin-top: -5px;
  height: auto !important;
}
.information_center_banner li > div span {
  height: 24px;
  line-height: 24px;
  display: block;
  padding-left: 16px;
  overflow: hidden;

  text-overflow: ellipsis;
  cursor: pointer;
}
.information_center_banner li > div span:hover {
  text-decoration: underline;
}
.information_center_banner li p {
  width: 60px;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  color: #333333;
  margin: 0;
  text-align: justify;
  text-align-last: justify;
  display: inline-block;
  float: left;
  // white-space: nowrap;
}
.information_center_banner li p:after {
  display: inline-block;
  content: "";
  overflow: hidden;
  width: 100%;
  height: 0;
}
.information_center_banner li a {
  width: 10px;
  height: 14px;
  float: left;
  line-height: 14px;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.information_center_banner li > span,
.information_center_banner li > div {
  width: calc(100% - 70px);
  height: 14px;
  float: left;
  line-height: 14px;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* cursor: pointer; */
}

.information_center_footer {
  display: block;
  height: 60px;
  padding: 0px 20px 12px;
}
.information_center_footer li {
  height: 14px;
  margin-bottom: 10px;
}
.information_center_footer li p {
  width: 60px;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  color: #333333;
  margin: 0;
  text-align: justify;
  text-align-last: justify;
  display: inline-block;
  // white-space: nowrap;
  float: left;
}
.information_center_footer li p:after {
  display: inline-block;
  content: "";
  overflow: hidden;
  width: 100%;
  height: 0;
}
.information_center_footer li a {
  width: 10px;
  height: 42px;
  float: left;
  line-height: 14px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /*超出3行部分显示省略号，去掉该属性 显示全部*/
  -webkit-box-orient: vertical;
}
.information_center_footer li span {
  width: calc(100% - 70px);
  height: 50px;
  float: left;
  line-height: 16px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /*超出3行部分显示省略号，去掉该属性 显示全部*/
  -webkit-box-orient: vertical;
}
.onSubmitClose {
  float: right;
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.65);
}
.onSubmitBtn {
  float: right;
  width: 80px;
  height: 30px;
  background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
  border-radius: 4px;
}
</style>