<template>
    <div :class="scrollbars ? 'loginFir' : 'login'">
        <!--内容部分start-->
        <div class="MaintenanceNotifier" v-show="MaintenanceNotifier == true">
            <marquee v-if="isContent==2" class="MaintenanceNotifier_marquee">{{content}}</marquee>
            <span v-if="isContent==1">{{content}}</span>
        </div>
        <div class="login_center">
            <!--login背景图start-->
            <div class="login_logo">
                <img src="../../assets/images/index/autovisionlogo.png" v-if="lang == 'zh'" />
                <img src="../../assets/images/index/logo_white.png" class="imgEn" v-if="lang == 'en'" />
            </div>
            <!--login背景图end-->

            <!--中间的内容部分start-->
            <div class="login_content">
                <!--左侧标题部分start-->
                <div class="login_content_title">
                    <div class="login_content_title_Center">人类染色体在线诊断咨询中心</div>
                    <div class="login_content_title_Center1">染色体核型辅助诊断软件(Al Online AutoVision DsS0102)</div>
                    <div class="login_content_title_Center_title">
						<span>
							通过智能分析、异地诊断报告、疑难病例远程MDT等方法
						</span>
                        <span>
							整合医疗资源，提升病患群体服务质量
						</span>
                    </div>
                </div>
                <!--左侧标题部分end-->

                <!--  注册登陆模块start-->
                <div class="login_content_title_login international">
                    <el-tabs v-model="activeName" @tab-click="handleClick" v-if="tabNum != 3">
                        <el-tab-pane :label="$t('login.login_width_num')" name="first" width="50%">
                        </el-tab-pane>
                        <el-tab-pane :label="$t('login.mesage_login')" name="second" width="50%">
                        </el-tab-pane>
                    </el-tabs>

                    <div class="autoVision_register_title" v-if="tabNum == 3">
                        <span>{{$t('login.new_register')}}</span>
                    </div>
                    <div class="errorMsg" v-if="errAccountType == 2">
                        <img src="../../assets/images/index/errconunt.png" /><span>{{ errAccount }}</span>
                    </div>
                    <el-form label-width="100px" class="demo-ruleForm" :model="accountRuleForm" ref="accountRuleForm">
                        <!--账号登录-->
                        <div class="phoneLogin" v-if="tabNum == 1">
                            <img src="../../assets/images/index/account.svg" />
                            <input class="phone-input pd" :placeholder="$t('login.input_tel')"
                                   v-model="accountRuleForm.loginName" @focus="accountRuleFormLoginName" maxlength="20" />
                            <input type="text" class="hidden">
                        </div>
                        <!--账号登录密码-->
                        <div class="phoneLogin" v-if="tabNum == 1">
                            <img src="../../assets/images/index/passworld.svg" />
                            <input type="password" class="hidden">
                            <input class="phone-input pd" :type="pwdType" :placeholder="$t('login.input_password')"
                                   v-model="accountRuleForm.password" @focus="accountRuleFormPassword" />
                            <img :src="seen?openeye:nopeneye" class="eye" alt="" @click="changeType()">
                        </div>

                        <!--手机号登录账号-->
                        <div class="phoneLogin" v-if="tabNum == 2">
                            <el-select v-model="phonePrefix" :popper-append-to-body="false" filterable>
                                <el-option v-for="(item, index) in phoneData" :key="index" :label="item.useOption"
                                           :value="item.keyVal">
                                </el-option>
                            </el-select>
                            <el-input type="number" v-model="accountRuleForm.iphoneLogin"
                                      :placeholder="$t('personal_info.input_tel')" @focus="accountRuleFormIphoneLogin"
                                      maxlength="20"></el-input>
                        </div>
                        <!--注册账号-->
                        <div class="phoneLogin" v-if="tabNum == 3">
                            <el-select v-model="regisPrefix" :popper-append-to-body="false" filterable>
                                <el-option v-for="(item, index) in phoneData" :key="index" :label="item.useOption"
                                           :value="item.keyVal">
                                </el-option>
                            </el-select>
                            <el-input type="number" v-model="accountRuleForm.iphone"
                                      :placeholder="$t('personal_info.input_tel')" @focus="accountRuleFormIphone"
                                      maxlength="20"></el-input>
                        </div>
                        <div>
                            <div id="sc"></div>
                        </div>
                        <!--注册号登录密码-->
                        <div class="phoneLogin" v-if="tabNum == 3">
                            <el-input v-model="accountRuleForm.code" :placeholder="$t('login.input_verifycode')"
                                      @focus="accountRuleFormCode" maxlength="4" autocomplete="off"></el-input>
                            <span class="verify_code" @click="sendRegisterGetCode"
                                  v-show="getRegisterGetCode == 1">{{ codeTextRegister }}</span>
                            <span class="verify_code" v-show="getRegisterGetCode == 2">{{ codeTextRegisterNum }}</span>
                        </div>
                        <div class="layui-form-item layui-form-checkbox-a" style="margin-top: 16px" v-if="tabNum == 3">
                            <input type="checkbox" name="check" lay-verify="check" lay-skin="primary" required=""
                                   id="confirmCheck" v-model="accountRuleForm.diss" @click="checkbox()" />
                            <span class="layui-agreement">
								<a style="display: inline-block;">{{$t('checkbox_button.agreeto_observe')}}</a>
								<span class="layui-deshi-agreement">
									<router-link class="layui-deshi-agreement" style="color: #23a3ff;" to="/statement"
                                                 target="_blank">{{$t('checkbox_button.privacy_statement')}}</router-link>
									{{$t('checkbox_button.and')}}
									<router-link class="layui-deshi-agreement" style="color: #23a3ff;" to="/agreement"
                                                 target="_blank">{{$t('checkbox_button.service_agreement')}}</router-link>
								</span>
							</span>
                        </div>
                        <!--手机号登录密码-->
                        <div class="phoneLogin" v-if="tabNum == 2">
                            <el-input type="text" v-model="accountRuleForm.codeLogin" id="codeLogin"
                                      :placeholder="$t('login.input_verifycode')" @focus="accountRuleFormCodeLogin"
                                      maxlength="4" autocomplete="off"></el-input>
                            <span class="verify_code" @click="sendcode" v-show="getCodeShow == 1">{{ codeText }}</span>
                            <span class="verify_code" v-show="getCodeShow == 2">{{ codeTextNum }}</span>
                        </div>
                        <el-button type="primary" class="registerBtn" @click="numberLogon()">{{ buttonTitle }}
                        </el-button>
                        <div class="phoneLogin account" v-if="tabNum != 3">
                            <span class="no_account">{{$t('login.no_account')}}</span><span class="no_account blue"
                                                                                            @click="accountBtnEegister()">{{$t('login.new_register')}}</span>
                        </div>
                        <div class="phoneLogin account" v-if="tabNum == 3">
                            <span class="no_account">{{$t('login.tologin')}}</span><span class="no_account blue"
                                                                                         @click="goLogin()">{{$t('login.login')}}</span>
                        </div>
                    </el-form>
                </div>
                <!--  注册登陆模块end-->
            </div>
            <!--中间的内容部分end-->
            <!--底部注册商标start-->
            <div class="autoVisionFooter1">
                <span>浙ICP备17045030号浙公网安备33011002014406号版本:V2.0.1.0</span>
            </div>
            <div class="autoVisionFooter">
                <span>版权所有Copyight(©)2013-2021 杭州德适生物科技有限公司</span>
            </div>
            <!--底部注册商标end-->
        </div>
        <!-- 内容部分end-->
    </div>
</template>

<script>
import phoneIntNatnData from "../../assets/loginJson/telLogin_intel_en.json"
import {
    cloneDeep
} from 'lodash';
export default {
    data() {
        return {
            scrollbars: false, //有没有滚动条
            activeName: "first", //默认选中短信登录 还是密码登录
            accountRuleForm: {
                loginName: "", //账号
                password: "", //密码
                phoneCode: 86, //国家码
                iphoneLogin: "", //手机号
                codeLogin: "", //手机号验证码
                iphone: "", //手机号
                code: "", //验证码
                diss: false,
            },
            getCodeShow: 1, //
            // codeText: "获取验证码",
            codeTextNum: "", //到计数时间
            time: 60,
            buttonTitle: this.$t("login.login"),
            tabNum: 1, //判断是在那一个维度 1账号、2手机，3注册
            errAccount: "", //错误提示
            errAccountType: 1, //错误提示类型 1不显示 2显示
            errAccountTitle: "", //账号错误提示
            errAccountTitleType: 1, //账号错误提示 1不显示2显示
            // codeTextRegister: "获取验证码", //验证码
            getRegisterGetCode: 1, //
            codeTextRegisterNum: "", //倒计时
            times: 60,
            MaintenanceNotifier: false, //公告显示还是隐藏
            content: "",
            isContent: 1,
            screenWidth: "",
            screenHeight: "",
            accountPrefix: '',
            phonePrefix: '',
            regisPrefix: '',
            seen: '',
            pwdType: 'password', // 密码类型
            openeye: require("@/assets/images/index/icon_hide_on.png"),
            nopeneye: require("@/assets/images/index/icon_hide_off.png"),
        };
    },
    created() {
        if (!this.$route.query.key) {
            this.isTokenExpired();
        } else if (this.$route.query.isReg) {
            this.tabNum = 3;
        }
        // this.quickLogin();
        // this.queryMsgApi();
        document.title = this.$t('login.Chinese_online_consult_center');
        // this.isTokenExpired();
        this.quickLogin();
        this.queryMsgApi();
        let lang = localStorage.getItem('language') || (navigator.language || navigator.userLanguage).substr(0, 2);
        localStorage.setItem('language', lang);
        this.$store.commit('changeLanguange', lang)
        if (lang === 'en') {
            this.accountPrefix = this.phoneData[0].keyVal;
            this.phonePrefix = this.phoneData[0].keyVal;
            this.regisPrefix = this.phoneData[0].keyVal;
        } else {
            this.accountPrefix = this.phoneData[1].keyVal;
            this.phonePrefix = this.phoneData[1].keyVal;
            this.regisPrefix = this.phoneData[1].keyVal;
        }
        console.log(1111, this.phoneData[0]);
    },
    computed: {
        codeText: {
            get() {
                return this.$t('login.get_verifycode')
            },
            set() {}
        },
        codeTextRegister: {
            get() {
                return this.$t('login.get_verifycode')
            },
            set() {}
        },
        lang() {
            return localStorage.getItem('language') || (navigator.language || navigator.userLanguage).substr(0, 2)
        },
        phoneData() {
            let telDataIntel = cloneDeep(phoneIntNatnData);
            if (this.lang === 'en') {
                telDataIntel = telDataIntel.map(a => a.en).sort().map(key => {
                    return telDataIntel.filter(a => a.en === key)[0]
                })
                telDataIntel.forEach((item) => {
                    item.useOption = item.en + '(+' + item.tel + ')';
                    item.keyVal = item.tel + ',' + item.short
                })
            } else {
                telDataIntel = telDataIntel.map(a => a.name).sort().map(key => {
                    return telDataIntel.filter(a => a.name === key)[0]
                })
                telDataIntel.forEach((item) => {
                    item.useOption = item.name + '(+' + item.tel + ')';
                    item.keyVal = item.tel + ',' + item.short
                })
            }
            return telDataIntel;
        }
    },
    methods: {

        changeType() {
            this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
            this.seen = !this.seen; //小眼睛的变化
        },
        queryMsgApi() {
            this.$api.queryMsg({}).then((res) => {
                if (res.code === "200") {
                    const date = res.data;
                    if (!!date) {
                        this.MaintenanceNotifier = true;
                        this.content = date.content;

                        this.screenWidth = document.body.clientWidth;
                        this.screenHeight = document.body.clientHeight;

                        if (this.content.length * 18 < this.screenWidth * 0.8) {
                            this.isContent = 1;
                        } else {
                            this.isContent = 2;
                        }
                    } else {
                        this.MaintenanceNotifier = false;
                    }
                } else {
                    this.MaintenanceNotifier = false;
                }
            });
        },

        //快捷登录
        quickLogin() {
            let that = this; //快捷登录
            let key;
            document.onkeydown = function(e) {
                if (!!window.event) {
                    key = window.event.keyCode;
                } else {
                    key = e.keyCode;
                }
                if (key == 13) {
                    if (that.$route.path == "/") {
                        that.numberLogon();
                    }
                }
            };
        },

        jumpAfterLogin() {
            if (this.$route.query.key) {
                this.$router.replace('/analysissystem');
            } else {
                this.$router.push({
                    path: "/analysissystem",
                });
            }
        },

        //判断token有没有过期
        isTokenExpired() {
            if (!!JSON.parse(localStorage.getItem("loginObj"))) {
                let param = {
                    token: JSON.parse(localStorage.getItem("loginObj")).token,
                };
                this.$api.checkToken(param).then((res) => {
                    if (res.code == 200) {
                        this.jumpAfterLogin();
                    }
                });
            }
        },

        //初始化背景图片样式
        monitorBrowser() {
            this.$nextTick(() => {
                //初始化判断有没有滚动条
                if (document.body.scrollHeight > document.body.clientHeight) {
                    this.scrollbars = true;
                } else {
                    this.scrollbars = false;
                }
            });
        },

        //短信登录 手机号码登录切换
        handleClick(tab) {
            this.buttonTitle = this.$t("login.login");
            this.errAccountType = 1;
            if (tab.index == 0) {
                this.tabNum = 1;
            }
            if (tab.index == 1) {
                this.tabNum = 2;
            }
            window.ic.reset();
        },

        //去注册页面
        accountBtnEegister() {
            this.tabNum = 3;
            this.buttonTitle = this.$t("login.free_register");
            this.errAccountType = 1;
            window.ic.reset();
        },

        //去登录页面
        goLogin() {
            this.activeName = "first";
            this.tabNum = 1;
            this.buttonTitle = this.$t("login.login");
            this.errAccountType = 1;
            window.ic.reset();
        },

        //密码登录
        PasswordLogin() {
            let machineVerification = JSON.parse(
                sessionStorage.getItem("machineVerification")
            );
            //账号校验
            if (this.accountRuleForm.loginName == "") {
                this.errAccountType = 2;
                this.errAccount = this.$t("login.input_login_num");
                return false;
            }
            //密码校验
            if (this.accountRuleForm.password == "") {
                this.errAccountType = 2;
                this.errAccount = this.$t("login.input_password_warn");
                return false;
            }

            this.errAccountType = 1;
            let param = {
                loginName: this.accountRuleForm.loginName,
                password: this.accountRuleForm.password,
                verification: 86,
            };
            this.$api.login(param).then((res) => {
                if (res.code == 200) {
                    sessionStorage.getItem("machineVerification") ? sessionStorage.removeItem(
                        "machineVerification") : "";
                    let loginObj = JSON.stringify(res.data);
                    localStorage.setItem("loginObj", loginObj);
                    localStorage.setItem("userName", res.data.userName);
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("roleStatus", res.data.roleStatus);
                    let caseValue, labValue;
                    if (res.data.accountId == "0") {
                        caseValue = {
                            mechanismId: res.data.userId,
                            mechanismName: this.$t('uploadPdf.personal_cases'),
                            patientType: "0", //病例类型 0 个人病例、1 会诊病例 2 团队病例 3 转入病例
                            accountType: "1", //1个人，专家   2机构  3管理员
                        };
                        labValue = {
                            mechanismId: res.data.userId,
                            mechanismName: this.$t('uploadPdf.personal_space'),
                            accountType: "1",
                            isExpert: false
                        }
                    } else {
                        var mechanismName;
                        res.data.mechanismList.forEach(item => {
                            if (item.mechanismId == res.data.accountId) {
                                mechanismName = item.mechanismName;
                            }
                        })
                        caseValue = {
                            mechanismId: res.data.accountId,
                            mechanismName: mechanismName,
                            patientType: "2", //病例类型 0 个人病例、1 会诊病例 2 团队病例 3 转入病例
                            accountType: "2", //1个人，专家   2机构  3管理员
                        };
                        labValue = {
                            mechanismId: res.data.accountId,
                            mechanismName: mechanismName,
                            accountType: "2",
                            isExpert: false
                        }
                    }
                    this.$store.commit("changeCaseValue", caseValue);
                    localStorage.setItem('caseValue', JSON.stringify(caseValue))
                    localStorage.setItem('currentLabValue', JSON.stringify(labValue));

                    this.beforeInvitation(res.token);
                } else {
                    window.ic.reset();
                    this.errAccountType = 2;
                    this.errAccount = res.message;
                }
            });
        },

        //短信登录
        textLogin() {
            // let loginNamePhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/); //手机号正则校验
            let smscode = new RegExp(/^[\S]{4,4}$/);
            //短信登录
            if (this.accountRuleForm.iphoneLogin == "") {
                this.errAccountType = 2;
                this.errAccount = this.$t("login.input_tel_warn");
                return false;
            }
            // if (!loginNamePhone.test(this.accountRuleForm.iphoneLogin)) {
            //   this.errAccountType = 2;
            //   this.errAccount = this.$t("login.verifycode_cannot_empty");
            //   return false;
            // }
            if (this.accountRuleForm.codeLogin == "") {
                this.errAccountType = 2;
                this.errAccount = this.$t("login.verifycode_notCorrect");
                return false;
            }
            if (!smscode.test(this.accountRuleForm.codeLogin)) {
                this.errAccountType = 2;
                this.errAccount = this.$t("login.verifycode_notCorrect");
                return false;
            }
            this.errAccountType = 1;
            this.$api.login({
                loginName: this.accountRuleForm.iphoneLogin, //手机号
                smscode: this.accountRuleForm.codeLogin, //验证码
                phoneCode: this.phonePrefix.split(',')[0], //国家码
            }).then((res) => {
                if (res.code == 200) {
                    let loginObj = JSON.stringify(res.data);
                    localStorage.setItem("loginObj", loginObj);
                    localStorage.setItem("userName", res.data.userName);
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("roleStatus", res.data.roleStatus);
                    this.$store.state.userName = res.data.userName;

                    let caseValue, labValue;
                    if (res.data.accountId == "0") {
                        caseValue = {
                            mechanismId: res.data.userId,
                            mechanismName: this.$t('uploadPdf.personal_cases'),
                            patientType: "0", //病例类型 0 个人病例、1 会诊病例 2 团队病例 3 转入病例
                            accountType: "1", //1个人，专家   2机构  3管理员
                        };
                        labValue = {
                            mechanismId: res.data.userId,
                            mechanismName: this.$t('uploadPdf.personal_space'),
                            accountType: "1",
                            isExpert: false
                        }
                    } else {
                        var mechanismName;
                        res.data.mechanismList.forEach(item => {
                            if (item.mechanismId == res.data.accountId) {
                                mechanismName = item.mechanismName;
                            }
                        })
                        caseValue = {
                            mechanismId: res.data.accountId,
                            mechanismName: mechanismName,
                            patientType: "2", //病例类型 0 个人病例、1 会诊病例 2 团队病例 3 转入病例
                            accountType: "2", //1个人，专家   2机构  3管理员
                        };
                        labValue = {
                            mechanismId: res.data.accountId,
                            mechanismName: mechanismName,
                            accountType: "2",
                            isExpert: false
                        }
                    }
                    this.$store.commit("changeCaseValue", caseValue);
                    localStorage.setItem('caseValue', JSON.stringify(caseValue))
                    localStorage.setItem('currentLabValue', JSON.stringify(labValue));

                    this.beforeInvitation(res.token);
                } else {
                    window.ic.reset();
                    this.errAccountType = 2;
                    this.errAccount = res.message;
                }
            });
        },

        //注册登录
        registerAndLogin() {
            // let loginNamePhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/); //手机号正则校验
            let smscode = new RegExp(/^[\S]{4,4}$/);

            //账号校验
            if (this.accountRuleForm.iphone == "") {
                this.errAccountType = 2;
                this.errAccount = this.$t("login.input_tel_warn");
                return false;
            }

            /*          //账号不能为空
                      if (!loginNamePhone.test(this.accountRuleForm.iphone)) {
                          this.errAccountType = 2;
                          this.errAccount = this.$t('login.verifycode_cannot_empty');
                          return false;
                      }*/

            //验证码
            if (this.accountRuleForm.code == "") {
                this.errAccountType = 2;
                this.errAccount = this.$t("login.verifycode_cannot_empty");
                return false;
            }

            //验证码格式问题
            if (!smscode.test(this.accountRuleForm.code)) {
                this.errAccountType = 2;
                this.errAccount = this.$t("login.verifycode_notCorrect");
                return false;
            }

            //协议选择
            if (!this.accountRuleForm.diss) {
                this.errAccountType = 2;
                this.errAccount = this.$t("login.agree_deal");
                return false;
            }

            let post_data = {
                phoneEmail: this.accountRuleForm.iphone, // 手机号或邮箱
                code: this.accountRuleForm.code, // 验证码
                phoneCode: this.regisPrefix.split(',')[0], // 区号
                isDomestic: 0,
            };
            this.$api.register(post_data).then((res) => {
                if (res.code == 200) {
                    let loginObj = JSON.stringify(res.data);
                    localStorage.setItem("userName", res.data.userName);
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("loginObj", loginObj);
                    localStorage.setItem("roleStatus", res.data.roleStatus);
                    this.$store.state.userName = res.data.userName;
                    let labValue = {
                        mechanismId: res.data.userId,
                        mechanismName: this.$t('uploadPdf.personal_space'),
                        accountType: "1",
                        isExpert: false
                    }
                    localStorage.setItem('currentLabValue', JSON.stringify(labValue));
                    let caseValue = {
                        mechanismId: res.data.userId,
                        mechanismName: this.$t('uploadPdf.personal_cases'),
                        patientType: "0", //病例类型 0 个人病例、1 会诊病例 2 团队病例 3 转入病例
                        accountType: "1", //1个人，专家   2机构  3管理员
                    };
                    this.$store.commit("changeCaseValue", caseValue);
                    localStorage.setItem('caseValue', JSON.stringify(caseValue))
                    this.beforeInvitation(res.token);

                } else {
                    this.$message.error(res.message);
                }
            });
        },

        //登录/注册
        numberLogon() {
            if (this.tabNum == 1) {
                this.PasswordLogin();
            } else if (this.tabNum == 2) {
                this.textLogin();
            } else if (this.tabNum == 3) {
                this.registerAndLogin();
            }
        },

        beforeInvitation(token) {
            if (!this.$route.query.key) {
                this.afterInvitation();
            } else {
                this.afterInvitation(this.$route.query.key);
            }
        },

        afterInvitation(key) {
            this.$api.queryList({
                type: 1,
            }).then((res) => {
                if (res.code == 200) {
                    let pointList = res.data.pointList;
                    let powerMap = res.data.powerMap;
                    localStorage.setItem("pointList", JSON.stringify(pointList));
                    localStorage.setItem("powerMap", JSON.stringify(powerMap));
                    //登录跳转
                    // this.jumpAfterLogin();
                    this.$router.push({
                        name: '分析系统',
                        params: {key}
                    });
                }
            });
        },

        //短信获取验证码
        sendcode() {
            this.time = 60;
            let machineVerification = JSON.parse(
                sessionStorage.getItem("machineVerification")
            ); //获取人机校验
            let loginNamePhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/); //手机号正则校验
            if (this.accountRuleForm.iphoneLogin == "") {
                this.errAccountType = 2;
                this.errAccount = this.$t("login.input_tel_warn");
                return false;
            }
            /*          if (!loginNamePhone.test(this.accountRuleForm.iphoneLogin)) {
                          this.errAccountType = 2;
                          this.errAccount = "手机号码格式不正确，请重新输入";
                          return false;
                      }*/
            let phone_data = {
                phoneEmail: this.accountRuleForm.iphoneLogin,
                smsType: 2, //1注册  2登录 3修改密码  4修改手机号  5修改邮箱
                phoneCode: this.phonePrefix.split(',')[0], //那个国家
            };
            this.$api.sms(phone_data).then((res) => {
                if (res.code == 200) {
                    this.timer();
                    sessionStorage.getItem("machineVerification") ?
                        sessionStorage.removeItem("machineVerification") :
                        "";
                } else {
                    window.ic.reset();
                    this.errAccountType = 2;
                    this.errAccount = res.message;
                }
            });
        },

        //短信倒计时
        timer() {
            if (this.time > 0) {
                this.getCodeShow = 2;
                this.time--;
                this.codeTextNum = this.time + this.$t("unit.seconds");
                setTimeout(this.timer, 1000);
            } else {
                this.time = 0;
                this.codeTextNum = 60;
                this.getCodeShow = 1;
            }
        },

        //协议选中还是未选中
        checkbox() {
            this.diss = event.target.checked;
            this.errAccountType = 1;
        },

        //注册验证码
        sendRegisterGetCode() {
            this.times = 60;
            let machineVerification = JSON.parse(
                sessionStorage.getItem("machineVerification")
            ); //获取人机校验
            let loginNamePhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/); //手机号正则校验

            //账号校验
            if (this.accountRuleForm.iphone == "") {
                this.errAccountType = 2;
                this.errAccount = this.$t("login.input_tel_warn");
                return false;
            }

            /*              //账号不能为空
                      if (!loginNamePhone.test(this.accountRuleForm.iphone)) {
                          this.errAccountType = 2;
                          this.errAccount = "手机号码格式不正确，请重新输入";
                          return false;
                      }*/

            let phone_data = {
                phoneEmail: this.accountRuleForm.iphone,
                smsType: 1, // 1注册  2登录 3修改密码  4修改手机号  5修改邮箱
                phoneCode: this.regisPrefix.split(',')[0], // 那个国家
            };
            this.$api.sms(phone_data).then((res) => {
                if (res.code == 200) {
                    this.timers();
                    sessionStorage.getItem("machineVerification") ?
                        sessionStorage.removeItem("machineVerification") :
                        "";
                } else {
                    window.ic.reset();
                    this.errAccountType = 2;
                    this.errAccount = res.message;
                }
            });
        },

        //注册验证码倒计时
        timers() {
            if (this.times > 0) {
                this.getRegisterGetCode = 2;
                this.times--;
                this.codeTextRegisterNum = this.times + this.$t("unit.seconds");
                setTimeout(this.timers, 1000);
            } else {
                this.times = 0;
                this.codeTextRegisterNum = 60;
                this.getRegisterGetCode = 1;
            }
        },

        //注册手机号获取焦点
        accountRuleFormIphone() {
            this.errAccountType = 1;
            this.accountRuleForm.iphone = this.accountRuleForm.iphone.replace(/\D/g, '')
        },

        //注册验证码获取焦点
        accountRuleFormCode() {
            this.errAccountType = 1;
        },

        //短信登录手机号
        accountRuleFormIphoneLogin() {
            this.errAccountType = 1;
            this.accountRuleForm.iphoneLogin = this.accountRuleForm.iphoneLogin.replace(/\D/g, '')
        },

        //短信登录验证码
        accountRuleFormCodeLogin() {
            this.errAccountType = 1;
        },

        //账号登录
        accountRuleFormLoginName() {
            this.errAccountType = 1;
        },

        //密码登录
        accountRuleFormPassword() {
            this.errAccountType = 1;
        },
    },

    mounted() {
        this.monitorBrowser();
        //监听浏览器有没有滚动条
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth;
                this.screenHeight = document.body.clientHeight;

                if (this.content.length * 18 < this.screenWidth * 0.8) {
                    this.isContent = 1;
                } else {
                    this.isContent = 2;
                }
                if (
                    document.body.scrollHeight >
                    (window.innerHeight || document.documentElement.clientHeight)
                ) {
                    this.scrollbars = true;
                } else {
                    this.scrollbars = false;
                }
            })();
        };

        let default_txtHtml = this.$t("smart_verify.hit_verify");
        let success_txtHtml = this.$t("smart_verify.verified_success");
        let fail_txtHtml = this.$t("smart_verify.verify_failed");
        let scaning_txtHtml = this.$t("smart_verify.smart_verifying");
        if (window.smartCaptcha) {
            init();
        } else {
            var timer = setInterval(() => {
                if (window.smartCaptcha) {
                    clearInterval(timer);
                    init();
                }
            }, 500);
        }
        let that = this;

        function init() {
            window.ic = new window.smartCaptcha({
                renderTo: "#sc",
                width: 300,
                height: 42,
                default_txt: default_txtHtml,
                success_txt: success_txtHtml,
                fail_txt: fail_txtHtml,
                scaning_txt: scaning_txtHtml,
                success: function(data) {
                    that.errAccountType = 1;
                    var machineVerification = {
                        afsSessionId: data.sessionId,
                        afsToken: window.NVC_Opt.token,
                        afsSig: data.sig,
                        afsScene: "ic_login",
                    };
                    sessionStorage.setItem(
                        "machineVerification",
                        JSON.stringify(machineVerification)
                    );
                },
                fail: function() {
                    //console.log("ic error");
                },
            });

            window.ic.init();
            sessionStorage.getItem("machineVerification") ?
                sessionStorage.removeItem("machineVerification") :
                "";
        }
    },
};
</script>
<style lang="scss">
html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.eye {
    position: absolute;
    left: 90% !important;
    top: 11px !important;
    z-index: 100 !important;
}

#rectMask,
#sm-btn-bg {
    width: 100% !important;
}

#SM_BTN_1 {
    width: calc(100% - 2px) !important;
}

#app {
    height: 100%;
    width: 100%;
}

.el-tabs__nav {
    width: 100%;
}

.el-tabs__item {
    width: 50%;
    text-align: center;
    font-size: 18px;
    color: #999;
    padding: 0;
}

#tab-first {
    padding-right: 30px;
}

.el-tabs__active-bar {
    background-color: #4773b8 !important;
}

.el-tabs__item.is-active {
    color: #4773b8 !important;
}

.el-tabs__item:hover {
    color: #4773b8 !important;
}

.login_content_title_login.international {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    .el-tabs__nav-wrap::after {
        background: none;
    }

    .phoneLogin {
        position: relative;

        img {
            position: absolute;
            left: 10px;
            top: 9px;
            z-index: 10;
        }

        .el-select__tags-text {
            display: inline-block;
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-select .el-tag__close.el-icon-close {
            top: -7px;
        }

        .el-input {
            .el-input__inner {
                height: 42px;
            }
        }

        .phone-input {
            -webkit-appearance: none;
            background-color: #fff;
            background-image: none;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;
            color: #606266;
            display: inline-block;
            font-size: 13px;
            height: 42px;
            line-height: 40px;
            outline: 0;
            padding: 0 15px;
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 100%;

            &:hover {
                border-color: #c0c4cc;
            }

            &.pd {
                padding-left: 45px;
            }

            &:focus {
                border-color: #409eff;
                outline: 0;
            }

            &::placeholder {
                color: #c0c4cc;
                font-size: 13px;
            }
        }

        .hidden {
            display: none;
        }

        .pd {
            .el-input__inner {
                padding-left: 45px;
                font-size: 13px;
            }
        }

        .el-select {
            position: relative;
            width: 300px;
            height: 42px;
            margin-right: 8px;

            .el-input__inner {
                height: 42px;
                padding: 0 5px;
                padding-right: 18px;
                text-overflow: ellipsis;
                text-align: center;
                font-size: 13px;
            }

            .el-input__suffix {
                right: 0;
            }

            .el-input__icon {
                width: 30px;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
#sc {
    margin-top: 16px !important;
}

.login {
    background-image: url("../../assets/images/index/bg.webp");
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
}

.loginFir {
    background-image: url("../../assets/images/index/bg.webp");
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    overflow: hidden;
}

/*屏幕大于1600排序（大屏幕电脑）*/
@media screen and (min-width: 1600px) {
    .login_center {
        width: 1500px;
        height: 900px;
        margin: 0 auto;
        position: relative;
    }

    .login_logo {
        padding-top: 60px;
        width: 204px;
    }

    .login_logo img {
        height: 86px;
        /* width: 244px; */
    }

    .login_logo img.imgEn {
        height: 79px;
        /* width: 244px; */
    }

    .login_content {
        height: 500px;
        width: 100%;
        margin-top: 110px;
    }

    .login_content_title {
        /* display: inline-block; */
        height: 267px;
        width: 650px;
        float: left;
        margin-top: 80px;
    }

    .login_content_title_Center {
        width: 955px;
        font-size: 45px;
        color: #ffffff;
        line-height: 54px;
        font-weight: 600;
    }

    .login_content_title_Center1 {
        width: 955px;
        font-size: 30px;
        color: #ffffff;
        line-height: 50px;
        font-weight: 600;
    }

    .login_content_title_Center em {
        font-size: 38px;
        font-weight: 400;
    }

    .login_content_title_Center_title {
        width: 950px;
        height: 102px;
        margin-top: 10px;
    }

    .login_content_title_Center_title span {
        display: block;
        width: 950px;
        font-size: 26px;
        color: #ffffff;
        line-height: 34px;
    }

    /*注册登陆模块*/
    .login_content_title_login {
        float: right;
        width: 340px;
        min-height: 310px;
        background: #ffffff;
        box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        z-index: 1;
        padding: 50px 30px;
    }

    .autoVisionFooter1 {
        position: absolute;
        width: 600px;
        height: 34px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        bottom: 0px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .autoVisionFooter1 span {
        text-align: center;
        display: block;
        height: 17px;
    }

    .autoVisionFooter {
        position: absolute;
        width: 550px;
        height: 34px;
        font-size: 12px;
        font-weight: 400;
        color: #FEFFFF;
        line-height: 17px;
        bottom: -20px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .autoVisionFooter span {
        text-align: center;
        display: block;
        height: 17px;
    }
}

/*屏幕在1600px到1366之间（中屏幕电脑）*/
@media screen and (min-width: 1366px) and (max-width: 1600px) {
    .login_center {
        width: 1066px;
        height: 768px;
        margin: 0 auto;
        position: relative;
    }

    .login_logo {
        padding-top: 43px;
        width: 173px;
    }

    .login_logo img {
        height: 61px;
        /* width: 173px; */
    }

    .login_logo img.imgEn {
        height: 56px;
        /* width: 173px; */
    }

    .login_content {
        height: 500px;
        width: 100%;
        margin-top: 110px;
        position: relative;
    }

    .login_content_title {
        height: 150px;
        width: 550px;
        float: left;
        margin-top: 45px;
    }

    .login_content_title_Center {
        width: 660px;
        font-size: 40px;
        font-weight: 800;
        color: #ffffff;
        line-height: 40px;
    }
    .login_content_title_Center1{
        width: 660px;
        font-size: 22px;
        font-weight: 800;
        color: #ffffff;
        line-height: 40px;
    }

    .login_content_title_Center em {
        font-size: 30px;
        font-weight: 400;
    }

    .login_content_title_Center_title {
        width: 660px;
        height: 73px;
        margin-top: 7px;
    }

    .login_content_title_Center_title span {
        width: 660px;
        height: 24px;
        font-size: 18px;
        color: #ffffff;
        line-height: 24px;
        display: block;
    }

    /*注册登陆模块*/
    .login_content_title_login {
        width: 300px;
        min-height: 310px;
        background: #ffffff;
        box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        float: right;
        z-index: 1;
        padding: 50px;
    }

    .autoVisionFooter {
        position: absolute;
        width: 550px;
        height: 34px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .autoVisionFooter span {
        text-align: center;
        display: block;
        height: 17px;
    }

    .autoVisionFooter1 {
        position: absolute;
        width: 550px;
        height: 34px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .autoVisionFooter1 span {
        text-align: center;
        display: block;
        height: 17px;
    }
}

/*屏幕在1366px到1250之间（中屏幕电脑）*/
@media screen and (min-width: 1250px) and (max-width: 1366px) {
    .login_center {
        width: 1000px;
        height: 750px;
        margin: 0 auto;
        position: relative;
    }

    .login_logo {
        padding-top: 43px;
        width: 162px;
    }

    .login_logo img {
        height: 57px;
        /* width: 162px; */
    }

    .login_logo img.imgEn {
        height: 52px;
        /* width: 173px; */
    }

    .login_content {
        height: 500px;
        width: 100%;
        margin-top: 98px;
        position: relative;
    }

    .login_content_title {
        height: 150px;
        width: 550px;
        float: left;
        margin-top: 81px;
    }

    .login_content_title_Center {
        width: 580px;
        /* height: 36px; */
        font-size: 36px;
        color: #ffffff;
        line-height: 30px;
        font-weight: 600;
    }

    .login_content_title_Center1 {
        width: 580px;
        /* height: 36px; */
        font-size: 18px;
        color: #ffffff;
        line-height: 40px;
        font-weight: 600;
    }

    .login_content_title_Center em {
        font-size: 24px;
        font-weight: 400;
    }

    .login_content_title_Center_title {
        width: 550px;
        height: 85px;
        margin-top: 19px;
    }

    .login_content_title_Center_title span {
        width: 560px;
        height: 23px;
        font-size: 17px;

        color: #ffffff;
        line-height: 23px;
        display: block;
    }

    /*注册登陆模块*/
    .login_content_title_login {
        width: 300px;
        min-height: 310px;
        background: #ffffff;
        box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        float: right;
        z-index: 1;
        padding: 50px;
    }

    .autoVisionFooter {
        position: absolute;
        width: 550px;
        height: 34px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .autoVisionFooter span {
        text-align: center;
        display: block;
        height: 17px;
    }

    .autoVisionFooter1 {
        position: absolute;
        width: 550px;
        height: 34px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .autoVisionFooter1 span {
        text-align: center;
        display: block;
        height: 17px;
    }
}

/*屏幕小于1200（主要是手机屏幕）*/
@media screen and (max-width: 1250px) {
    .login_center {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        position: relative;
    }

    .login_logo {
        padding-top: 60px;
        width: 174px;
        margin: 0px auto;
    }

    .login_logo img {
        height: 60px;
        width: 174px;
    }

    .login_content {
        height: 615px;
        width: 100%;
        position: relative;
    }

    .login_content_title {
        /* height: 140px; */
        width: 100%;
        margin: auto;
        margin-bottom: 10px;
    }

    .login_content_title_Center {
        width: 100%;
        /* height: 45px; */
        font-size: 30px;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
        font-weight: 600;
        margin-top: 50px;
    }

    .login_content_title_Center1 {
        width: 100%;
        /* height: 45px; */
        font-size: 20px;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        font-weight: 600;
    }

    .login_content_title_Center em {
        font-size: 18px;
        font-weight: 400;
    }

    .login_content_title_Center_title {
        width: 100%;
        /* height: 75px; */
        margin-top: 2px;
    }

    .login_content_title_Center_title span {
        width: 100%;
        /* height: 25px; */
        font-size: 18px;

        color: #ffffff;
        line-height: 20px;
        display: block;
        text-align: center;
    }

    /*注册登陆模块*/
    .login_content_title_login {
        width: 300px;
        min-height: 310px;
        background: #ffffff;
        box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        margin: 0 auto;
        z-index: 1;
        padding: 50px;
    }

    .autoVisionFooter {
        position: absolute;
        width: 100%;
        height: 34px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .autoVisionFooter span {
        text-align: center;
        display: block;
        height: 17px;
    }
    .autoVisionFooter1 {
        position: absolute;
        width: 100%;
        height: 34px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .autoVisionFooter1 span {
        text-align: center;
        display: block;
        height: 17px;
    }
}

.numberLogon {
    height: 24px;
    border-bottom: 1px solid #d8d8d8;
    padding: 16px 0;
}

.numberLogon>span {
    height: 24px;
    width: 100%;
    display: flex;
}

.numberLogon>span>img {
    height: 24px;
    width: 24px;
    float: left;
}

.numberLogon>span>input {
    height: 24px;
    width: 280px;
    float: left;
    text-indent: 15px;
    border: none;
    outline: none;
}

.phoneLogin {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .verify_code {
        display: flex;
        width: 100px;
        color: #4773b8;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        margin-left: 20px;
    }

    &.account {
        justify-content: flex-end;

        .no_account {
            font-size: 14px;
            color: #999;
            margin-right: 5px;

            &.blue {
                color: #4773b8;
                cursor: pointer;
            }
        }
    }
}

.accountBtn {
    width: 300px;
    height: 44px;
    background: linear-gradient(90deg, #64a7c9 0%, #416ab0 100%);
    border-radius: 4px;
    border: none;
    margin-top: 20px;
    cursor: pointer;
    font-size: 16px;

    color: #ffffff;
}

.accountBtnEegister {
    display: block;
    margin-top: 12px;
    width: 100%;
    height: 14px;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
}

.accountBtnEegister span {
    float: right;
    color: #999999;
}

.accountBtnEegister span:hover {
    color: #333333;
}

.messagesCode {
    height: 24px;
    border-bottom: 1px solid #d8d8d8;
    padding: 20px 0 16px;
}

.messagesCode input {
    height: 24px;
    border: none;
    vertical-align: sub;
    font-size: 14px;
    margin-left: 14px;
    width: 150px;
    outline: none;
}

.getCode {
    cursor: pointer;
    font-size: 14px;
    vertical-align: top;
    margin-top: 7px;
    color: #4773b8;
    /* display: inline-block; */
    height: 24px;
    width: unset;
    float: right;
}

.messagesCode img {
    vertical-align: middle;
}

.autoVision_register_title {
    width: 100%;
    height: 28px;
    font-size: 20px;
    // font-weight: 600;
    color: #4773b8;
    line-height: 28px;
    display: block;
    text-align: left;
    margin-bottom: 30px;

    span {
        border-bottom: 2px solid #4773b8;
        padding: 0 10px 8px;
    }
}

.registererrorMsg {
    font-size: 14px;
    display: none;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    width: 100%;
    background: #ffeeee;
    border-radius: 2px;
    border: 1px solid #f53500;
    padding: 10px 0;
}

.registererrorMsg img {
    vertical-align: top;
    margin-left: 10px;
    height: 16px;
    width: 16px;
    display: inline-block;
}

.registererrorMsg span {
    margin-left: 10px;
    display: inline-block;
    min-width: 30px;
    width: 250px;
}

.registerLog_ul_li {
    height: 24px;
    border-bottom: 1px solid #d8d8d8;
    padding: 16px 0;
    list-style-type: none;
}

.registerLog_ul_li span {
    height: 24px;
    width: 100%;
    display: block;
}

.registerLog_ul {
    margin: 0;
    padding: 0;
}

.registerLog_ul_li span img {
    vertical-align: middle;
}

.registerLog_ul_li span input {
    height: 24px;
    border: none;
    vertical-align: sub;
    font-size: 14px;
    margin-left: 14px;
    width: 200px;
    outline: none;
}

.registerCode {
    height: 20px;
    border-bottom: 1px solid #d8d8d8;
    padding: 20px 0 16px;
}

.registerCode img {
    vertical-align: middle;
}

.registerCode input {
    height: 24px;
    border: none;
    vertical-align: sub;
    font-size: 14px;
    margin-left: 14px;
    width: 150px;
    outline: none;
}

.registerGetCode {
    cursor: pointer;
    font-size: 14px;
    vertical-align: sub;
    color: #4773b8;
    display: inline-block;
    height: 24px;
    width: 100px;
    text-align: right;
}

.layui-form-item {
    clear: both;
    *zoom: 1;
}

.registerBtn {
    margin: 0 !important;
    width: 100%;
    height: 44px;
    background: linear-gradient(90deg, #64a7c9 0%, #416ab0 100%);
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 16px;

    color: #ffffff;
    margin-top: 20px !important;
    transition-duration: 0.5s;
}

.registerBtn:hover {
    /*background: #6ba9e2;*/

    background: linear-gradient(90deg, #64a7c9 0%, #416ab0 100%);
    opacity: 0.8;
    transition-duration: 1s;
}

.goLogin {
    width: 100%;
    height: 22px;
    font-size: 16px;

    color: #999999;
    line-height: 22px;
    display: block;
    text-align: center;
    margin-top: 16px;
    cursor: pointer;
}

.goLogin:hover {
    color: #333333;
}

.layui-agreement {
    font-size: 14px;
}

.layui-deshi-agreement {
    text-decoration: none;
}

.errorMsg {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    width: 100%;
    background: #ffeeee;
    border-radius: 2px;
    border: 1px solid #f53500;
    padding: 10px 0;
    margin: 10px 0;
}

.errorMsg img {
    vertical-align: top;
    margin-left: 10px;
    height: 16px;
    width: 16px;
    display: inline-block;
    margin-top: 2px;
}

.errorMsg span {
    flex: 1;
    margin-left: 10px;
    display: inline-block;
    min-width: 30px;
    width: 250px;
}

.MaintenanceNotifier {
    position: fixed;
    top: 0;
    height: 40px;
    background: #ffecb0;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 40px;
    text-align: center;
    width: 100%;
    color: #f54f00;
}

.MaintenanceNotifier_title {
    color: #faad14;
    margin-right: 8px;
}

.MaintenanceNotifier_marquee {
    width: 85%;
}
</style>
