/*
 * @Author: your name
 * @Date: 2021-02-25 11:36:40
 * @LastEditTime: 2021-03-03 19:32:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \autovision-vue\src\store\modules\rect\mutation-types.js
 */
export const ENABLE_ACTIVE = 'ENABLE_ACTIVE';
export const DISABLE_ACTIVE = 'DISABLE_ACTIVE';

export const ENABLE_DRAGGABLE = 'ENABLE_DRAGGABLE';
export const DISABLE_DRAGGABLE = 'DISABLE_DRAGGABLE';

export const ENABLE_RESIZABLE = 'ENABLE_RESIZABLE';
export const DISABLE_RESIZABLE = 'DISABLE_RESIZABLE';

export const ENABLE_PARENT_LIMITATION = 'ENABLE_PARENT_LIMITATION';
export const DISABLE_PARENT_LIMITATION = 'DISABLE_PARENT_LIMITATION';

export const ENABLE_SNAP_TO_GRID = 'ENABLE_SNAP_TO_GRID';
export const DISABLE_SNAP_TO_GRID = 'DISABLE_SNAP_TO_GRID';

export const ENABLE_ASPECT = 'ENABLE_ASPECT';
export const DISABLE_ASPECT = 'DISABLE_ASPECT';

export const ENABLE_X_AXIS = 'ENABLE_X_AXIS';
export const ENABLE_Y_AXIS = 'ENABLE_Y_AXIS';
export const ENABLE_BOTH_AXIS = 'ENABLE_BOTH_AXIS';
export const ENABLE_NONE_AXIS = 'ENABLE_NONE_AXIS';

export const CHANGE_ZINDEX = 'CHANGE_ZINDEX';

export const CHANGE_MINW = 'CHANGE_MINW';
export const CHANGE_MINH = 'CHANGE_MINH';

export const CHANGE_WIDTH = 'CHANGE_WIDTH';
export const CHANGE_HEIGHT = 'CHANGE_HEIGHT';
export const CHANGE_FONTSIZE = 'CHANGE_FONTSIZE';
export const CHANGE_TOP = 'CHANGE_TOP';
export const CHANGE_LEFT = 'CHANGE_LEFT';
export const CHANGE_PROP = 'CHANGE_PROP';
export const CHANGE_FONTWEIGHT = 'CHANGE_FONTWEIGHT';
export const CHANGE_FONTFAMILY = 'CHANGE_FONTFAMILY'
export const CHANGE_COLOR = 'CHANGE_COLOR'
export const SET_SHAPEPOSTYLE = 'SET_SHAPEPOSTYLE'
export const KEYUP_RELATIONSERIALNUMBER = 'KEYUP_RELATIONSERIALNUMBER'
export const SET_SHAPERESIZETYLE = 'SET_SHAPERESIZETYLE'
export const CHANGE_FORMAT = 'CHANGE_FORMAT'
export const ENABLE_SHOWLABELLINE = 'ENABLE_SHOWLABELLINE'
export const DISABLE_SHOWLABELLINE = 'DISABLE_SHOWLABELLINE'
export const ENABLE_SHOWDASHEDLINE = 'ENABLE_SHOWDASHEDLINE'
export const DISABLE_SHOWDASHEDLINE = 'DISABLE_SHOWDASHEDLINE'

export const ENABLE_REQUIRED = 'ENABLE_REQUIRED'
export const DISABLE_REQUIRED = 'DISABLE_REQUIRED'

export default {
    ENABLE_ACTIVE,
    DISABLE_ACTIVE,
    ENABLE_DRAGGABLE,
    DISABLE_DRAGGABLE,
    ENABLE_RESIZABLE,
    DISABLE_RESIZABLE,
    ENABLE_PARENT_LIMITATION,
    DISABLE_PARENT_LIMITATION,
    ENABLE_SNAP_TO_GRID,
    DISABLE_SNAP_TO_GRID,
    ENABLE_ASPECT,
    DISABLE_ASPECT,
    ENABLE_X_AXIS,
    ENABLE_Y_AXIS,
    ENABLE_NONE_AXIS,
    ENABLE_BOTH_AXIS,
    CHANGE_ZINDEX,
    CHANGE_MINW,
    CHANGE_MINH,
    CHANGE_WIDTH,
    CHANGE_HEIGHT,
    CHANGE_TOP,
    CHANGE_LEFT,
    CHANGE_FONTSIZE,
    CHANGE_PROP,
    CHANGE_FONTWEIGHT,
    CHANGE_COLOR,
    SET_SHAPEPOSTYLE,
    KEYUP_RELATIONSERIALNUMBER,
    SET_SHAPERESIZETYLE,
    CHANGE_FORMAT,
    ENABLE_SHOWLABELLINE,
    DISABLE_SHOWLABELLINE,
    ENABLE_SHOWDASHEDLINE,
    DISABLE_SHOWDASHEDLINE,
    CHANGE_FONTFAMILY,
    ENABLE_REQUIRED,
    DISABLE_REQUIRED
}