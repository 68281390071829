export const standard = {
	"type": 850,
	"padding": 40,
	"position": [{
			"name": "Chrom01ISCN09",
			"element": [{
				"lxly": [
					33,
					962
				]
			}]
		},
		{
			"name": "Chrom02ISCN09",
			"element": [{
				"lxly": [
					32,
					886
				]
			}]
		},
		{
			"name": "Chrom03ISCN09",
			"element": [{
				"lxly": [
					34,
					750
				]
			}]
		},
		{
			"name": "Chrom04ISCN09",
			"element": [{
				"lxly": [
					33,
					704
				]
			}]
		},
		{
			"name": "Chrom05ISCN09",
			"element": [{
				"lxly": [
					33,
					668
				]
			}]
		},
		{
			"name": "Chrom06ISCN09",
			"element": [{
				"lxly": [
					33,
					654
				]
			}]
		},
		{
			"name": "Chrom07ISCN09",
			"element": [{
				"lxly": [
					33,
					594
				]
			}]
		},
		{
			"name": "Chrom08ISCN09",
			"element": [{
				"lxly": [
					33,
					526
				]
			}]
		},
		{
			"name": "Chrom09ISCN09",
			"element": [{
				"lxly": [
					33,
					525
				]
			}]
		},
		{
			"name": "Chrom10ISCN09",
			"element": [{
				"lxly": [
					34,
					510
				]
			}]
		},
		{
			"name": "Chrom11ISCN09",
			"element": [{
				"lxly": [
					34,
					512
				]
			}]
		},
		{
			"name": "Chrom12ISCN09",
			"element": [{
				"lxly": [
					33,
					496
				]
			}]
		},
		{
			"name": "Chrom13ISCN09",
			"element": [{
				"lxly": [
					33,
					418
				]
			}]
		},
		{
			"name": "Chrom14ISCN09",
			"element": [{
				"lxly": [
					33,
					404
				]
			}]
		},
		{
			"name": "Chrom15ISCN09",
			"element": [{
				"lxly": [
					33,
					381
				]
			}]
		},
		{
			"name": "Chrom16ISCN09",
			"element": [{
				"lxly": [
					33,
					352
				]
			}]
		},
		{
			"name": "Chrom17ISCN09",
			"element": [{
				"lxly": [
					33,
					342
				]
			}]
		},
		{
			"name": "Chrom18ISCN09",
			"element": [{
				"lxly": [
					33,
					306
				]
			}]
		},
		{
			"name": "Chrom19ISCN09",
			"element": [{
				"lxly": [
					31,
					270
				]
			}]
		},
		{
			"name": "Chrom20ISCN09",
			"element": [{
				"lxly": [
					32,
					257
				]
			}]
		},
		{
			"name": "Chrom21ISCN09",
			"element": [{
				"lxly": [
					33,
					199
				]
			}]
		},
		{
			"name": "Chrom22ISCN09",
			"element": [{
				"lxly": [
					33,
					216
				]
			}]
		},
		{
			"name": "ChromXISCN09",
			"element": [{
				"lxly": [
					32,
					602
				]
			}]
		},
		{
			"name": "ChromYISCN09",
			"element": [{
				"lxly": [
					33,
					213
				]
			}]
		}
	],
	"banding": [{
			"name": "Chrom01ISCN09",
			"element": [{
					"name": "p36.33",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						3
					]
				},
				{
					"name": "p36.32",
					"lxly": [
						0,
						4
					],
					"rxry": [
						33,
						8
					]
				},
				{
					"name": "p36.31",
					"lxly": [
						0,
						9
					],
					"rxry": [
						33,
						19
					]
				},
				{
					"name": "p36.23",
					"lxly": [
						0,
						20
					],
					"rxry": [
						33,
						28
					]
				},
				{
					"name": "p36.22",
					"lxly": [
						0,
						29
					],
					"rxry": [
						33,
						39
					]
				},
				{
					"name": "p36.21",
					"lxly": [
						0,
						39
					],
					"rxry": [
						33,
						48
					]
				},
				{
					"name": "p36.13",
					"lxly": [
						0,
						49
					],
					"rxry": [
						33,
						69
					]
				},
				{
					"name": "p36.12",
					"lxly": [
						0,
						70
					],
					"rxry": [
						33,
						78
					]
				},
				{
					"name": "p36.11",
					"lxly": [
						0,
						79
					],
					"rxry": [
						33,
						84
					]
				},
				{
					"name": "p35.3",
					"lxly": [
						0,
						85
					],
					"rxry": [
						33,
						93
					]
				},
				{
					"name": "p35.2",
					"lxly": [
						0,
						94
					],
					"rxry": [
						33,
						103
					]
				},
				{
					"name": "p35.1",
					"lxly": [
						0,
						104
					],
					"rxry": [
						33,
						121
					]
				},
				{
					"name": "p34.3",
					"lxly": [
						0,
						122
					],
					"rxry": [
						33,
						135
					]
				},
				{
					"name": "p34.2",
					"lxly": [
						0,
						136
					],
					"rxry": [
						33,
						147
					]
				},
				{
					"name": "p34.1",
					"lxly": [
						0,
						148
					],
					"rxry": [
						33,
						164
					]
				},
				{
					"name": "p33",
					"lxly": [
						0,
						164
					],
					"rxry": [
						33,
						180
					]
				},
				{
					"name": "p32.3",
					"lxly": [
						0,
						180
					],
					"rxry": [
						33,
						192
					]
				},
				{
					"name": "p32.2",
					"lxly": [
						0,
						193
					],
					"rxry": [
						33,
						213
					]
				},
				{
					"name": "p32.1",
					"lxly": [
						0,
						214
					],
					"rxry": [
						33,
						231
					]
				},
				{
					"name": "p31.3",
					"lxly": [
						0,
						231
					],
					"rxry": [
						33,
						251
					]
				},
				{
					"name": "p31.2",
					"lxly": [
						0,
						251
					],
					"rxry": [
						33,
						258
					]
				},
				{
					"name": "p31.1",
					"lxly": [
						0,
						258
					],
					"rxry": [
						33,
						283
					]
				},
				{
					"name": "p22.3",
					"lxly": [
						0,
						283
					],
					"rxry": [
						33,
						301
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						302
					],
					"rxry": [
						33,
						313
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						314
					],
					"rxry": [
						33,
						342
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						343
					],
					"rxry": [
						33,
						356
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						358
					],
					"rxry": [
						33,
						364
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						364
					],
					"rxry": [
						33,
						379
					]
				},
				{
					"name": "p13.3",
					"lxly": [
						0,
						380
					],
					"rxry": [
						33,
						402
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						403
					],
					"rxry": [
						33,
						414
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						415
					],
					"rxry": [
						33,
						423
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						424
					],
					"rxry": [
						33,
						435
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						436
					],
					"rxry": [
						33,
						450
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						451
					],
					"rxry": [
						33,
						459
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						460
					],
					"rxry": [
						33,
						469
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						470
					],
					"rxry": [
						33,
						556
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						557
					],
					"rxry": [
						33,
						581
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						582
					],
					"rxry": [
						33,
						590
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						592
					],
					"rxry": [
						33,
						602
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						603
					],
					"rxry": [
						33,
						611
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						612
					],
					"rxry": [
						33,
						635
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						636
					],
					"rxry": [
						33,
						644
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						645
					],
					"rxry": [
						33,
						651
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						652
					],
					"rxry": [
						33,
						660
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						662
					],
					"rxry": [
						33,
						668
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						668
					],
					"rxry": [
						33,
						681
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						681
					],
					"rxry": [
						33,
						690
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						691
					],
					"rxry": [
						33,
						699
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						700
					],
					"rxry": [
						33,
						737
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						738
					],
					"rxry": [
						33,
						752
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						753
					],
					"rxry": [
						33,
						758
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						759
					],
					"rxry": [
						33,
						777
					]
				},
				{
					"name": "q32.11",
					"lxly": [
						0,
						777
					],
					"rxry": [
						33,
						789
					]
				},
				{
					"name": "q32.12",
					"lxly": [
						0,
						790
					],
					"rxry": [
						33,
						793
					]
				},
				{
					"name": "q32.13",
					"lxly": [
						0,
						795
					],
					"rxry": [
						33,
						809
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						810
					],
					"rxry": [
						33,
						821
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						822
					],
					"rxry": [
						33,
						828
					]
				},
				{
					"name": "q41",
					"lxly": [
						0,
						828
					],
					"rxry": [
						33,
						847
					]
				},
				{
					"name": "q42.11",
					"lxly": [
						0,
						847
					],
					"rxry": [
						33,
						855
					]
				},
				{
					"name": "q42.12",
					"lxly": [
						0,
						856
					],
					"rxry": [
						33,
						864
					]
				},
				{
					"name": "q42.13",
					"lxly": [
						0,
						865
					],
					"rxry": [
						33,
						907
					]
				},
				{
					"name": "q42.2",
					"lxly": [
						0,
						908
					],
					"rxry": [
						33,
						916
					]
				},
				{
					"name": "q42.3",
					"lxly": [
						0,
						917
					],
					"rxry": [
						33,
						924
					]
				},
				{
					"name": "q43",
					"lxly": [
						0,
						925
					],
					"rxry": [
						33,
						943
					]
				},
				{
					"name": "q44",
					"lxly": [
						0,
						943
					],
					"rxry": [
						33,
						959
					]
				}
			]
		},
		{
			"name": "Chrom02ISCN09",
			"element": [{
					"name": "p25.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						32,
						17
					]
				},
				{
					"name": "p25.2",
					"lxly": [
						0,
						17
					],
					"rxry": [
						32,
						25
					]
				},
				{
					"name": "p25.1",
					"lxly": [
						0,
						26
					],
					"rxry": [
						32,
						41
					]
				},
				{
					"name": "p24.3",
					"lxly": [
						0,
						41
					],
					"rxry": [
						32,
						57
					]
				},
				{
					"name": "p24.2",
					"lxly": [
						0,
						57
					],
					"rxry": [
						32,
						63
					]
				},
				{
					"name": "p24.1",
					"lxly": [
						0,
						63
					],
					"rxry": [
						32,
						73
					]
				},
				{
					"name": "p23.3",
					"lxly": [
						0,
						74
					],
					"rxry": [
						32,
						84
					]
				},
				{
					"name": "p23.2",
					"lxly": [
						0,
						85
					],
					"rxry": [
						32,
						89
					]
				},
				{
					"name": "p23.1",
					"lxly": [
						0,
						91
					],
					"rxry": [
						32,
						99
					]
				},
				{
					"name": "p22.3",
					"lxly": [
						0,
						99
					],
					"rxry": [
						32,
						114
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						114
					],
					"rxry": [
						32,
						119
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						119
					],
					"rxry": [
						32,
						130
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						131
					],
					"rxry": [
						32,
						140
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						140
					],
					"rxry": [
						32,
						143
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						144
					],
					"rxry": [
						32,
						166
					]
				},
				{
					"name": "p16.3",
					"lxly": [
						0,
						166
					],
					"rxry": [
						32,
						182
					]
				},
				{
					"name": "p16.2",
					"lxly": [
						0,
						182
					],
					"rxry": [
						32,
						190
					]
				},
				{
					"name": "p16.1",
					"lxly": [
						0,
						190
					],
					"rxry": [
						32,
						204
					]
				},
				{
					"name": "p15",
					"lxly": [
						0,
						204
					],
					"rxry": [
						32,
						229
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						230
					],
					"rxry": [
						32,
						238
					]
				},
				{
					"name": "p13.3",
					"lxly": [
						0,
						239
					],
					"rxry": [
						32,
						246
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						246
					],
					"rxry": [
						32,
						255
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						254
					],
					"rxry": [
						32,
						284
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						285
					],
					"rxry": [
						32,
						312
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						312
					],
					"rxry": [
						32,
						336
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						337
					],
					"rxry": [
						32,
						344
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						345
					],
					"rxry": [
						32,
						353
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						354
					],
					"rxry": [
						32,
						376
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						377
					],
					"rxry": [
						32,
						385
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						386
					],
					"rxry": [
						32,
						392
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						393
					],
					"rxry": [
						32,
						398
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						399
					],
					"rxry": [
						32,
						415
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						414
					],
					"rxry": [
						32,
						428
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						428
					],
					"rxry": [
						32,
						439
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						440
					],
					"rxry": [
						32,
						452
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						452
					],
					"rxry": [
						32,
						481
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						482
					],
					"rxry": [
						32,
						491
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						492
					],
					"rxry": [
						32,
						510
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						511
					],
					"rxry": [
						32,
						529
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						529
					],
					"rxry": [
						32,
						533
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						533
					],
					"rxry": [
						32,
						539
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						540
					],
					"rxry": [
						32,
						544
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						545
					],
					"rxry": [
						32,
						550
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						551
					],
					"rxry": [
						32,
						566
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						567
					],
					"rxry": [
						32,
						581
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						582
					],
					"rxry": [
						32,
						600
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						600
					],
					"rxry": [
						32,
						621
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						622
					],
					"rxry": [
						32,
						658
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						659
					],
					"rxry": [
						32,
						666
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						667
					],
					"rxry": [
						32,
						685
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						685
					],
					"rxry": [
						32,
						707
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						707
					],
					"rxry": [
						32,
						715
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						716
					],
					"rxry": [
						32,
						734
					]
				},
				{
					"name": "q33.1",
					"lxly": [
						0,
						734
					],
					"rxry": [
						32,
						754
					]
				},
				{
					"name": "q33.2",
					"lxly": [
						0,
						755
					],
					"rxry": [
						32,
						763
					]
				},
				{
					"name": "q33.3",
					"lxly": [
						0,
						764
					],
					"rxry": [
						32,
						781
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						781
					],
					"rxry": [
						32,
						798
					]
				},
				{
					"name": "q35",
					"lxly": [
						0,
						798
					],
					"rxry": [
						32,
						809
					]
				},
				{
					"name": "q36.1",
					"lxly": [
						0,
						809
					],
					"rxry": [
						32,
						817
					]
				},
				{
					"name": "q36.2",
					"lxly": [
						0,
						818
					],
					"rxry": [
						32,
						819
					]
				},
				{
					"name": "q36.3",
					"lxly": [
						0,
						821
					],
					"rxry": [
						32,
						837
					]
				},
				{
					"name": "q37.1",
					"lxly": [
						0,
						837
					],
					"rxry": [
						32,
						858
					]
				},
				{
					"name": "q37.2",
					"lxly": [
						0,
						858
					],
					"rxry": [
						32,
						867
					]
				},
				{
					"name": "q37.3",
					"lxly": [
						0,
						868
					],
					"rxry": [
						32,
						884
					]
				}
			]
		},
		{
			"name": "Chrom03ISCN09",
			"element": [{
					"name": "p26.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						34,
						11
					]
				},
				{
					"name": "p26.2",
					"lxly": [
						0,
						12
					],
					"rxry": [
						34,
						13
					]
				},
				{
					"name": "p26.1",
					"lxly": [
						0,
						15
					],
					"rxry": [
						34,
						25
					]
				},
				{
					"name": "p25.3",
					"lxly": [
						0,
						25
					],
					"rxry": [
						34,
						38
					]
				},
				{
					"name": "p25.2",
					"lxly": [
						0,
						39
					],
					"rxry": [
						34,
						44
					]
				},
				{
					"name": "p25.1",
					"lxly": [
						0,
						44
					],
					"rxry": [
						34,
						54
					]
				},
				{
					"name": "p24.3",
					"lxly": [
						0,
						55
					],
					"rxry": [
						34,
						72
					]
				},
				{
					"name": "p24.2",
					"lxly": [
						0,
						72
					],
					"rxry": [
						34,
						81
					]
				},
				{
					"name": "p24.1",
					"lxly": [
						0,
						81
					],
					"rxry": [
						34,
						93
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						93
					],
					"rxry": [
						34,
						99
					]
				},
				{
					"name": "p22.3",
					"lxly": [
						0,
						99
					],
					"rxry": [
						34,
						111
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						111
					],
					"rxry": [
						34,
						127
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						128
					],
					"rxry": [
						34,
						141
					]
				},
				{
					"name": "p21.33",
					"lxly": [
						0,
						142
					],
					"rxry": [
						34,
						153
					]
				},
				{
					"name": "p21.32",
					"lxly": [
						0,
						154
					],
					"rxry": [
						34,
						159
					]
				},
				{
					"name": "p21.31",
					"lxly": [
						0,
						161
					],
					"rxry": [
						34,
						207
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						207
					],
					"rxry": [
						34,
						216
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						217
					],
					"rxry": [
						34,
						228
					]
				},
				{
					"name": "p14.3",
					"lxly": [
						0,
						228
					],
					"rxry": [
						34,
						239
					]
				},
				{
					"name": "p14.2",
					"lxly": [
						0,
						239
					],
					"rxry": [
						34,
						247
					]
				},
				{
					"name": "p14.1",
					"lxly": [
						0,
						248
					],
					"rxry": [
						34,
						257
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						257
					],
					"rxry": [
						34,
						292
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						292
					],
					"rxry": [
						34,
						308
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						308
					],
					"rxry": [
						34,
						314
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						315
					],
					"rxry": [
						34,
						328
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						328
					],
					"rxry": [
						34,
						337
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						338
					],
					"rxry": [
						34,
						345
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						345
					],
					"rxry": [
						34,
						352
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						352
					],
					"rxry": [
						34,
						368
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						368
					],
					"rxry": [
						34,
						376
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						376
					],
					"rxry": [
						34,
						384
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						384
					],
					"rxry": [
						34,
						396
					]
				},
				{
					"name": "q13.11",
					"lxly": [
						0,
						396
					],
					"rxry": [
						34,
						410
					]
				},
				{
					"name": "q13.12",
					"lxly": [
						0,
						410
					],
					"rxry": [
						34,
						415
					]
				},
				{
					"name": "q13.13",
					"lxly": [
						0,
						416
					],
					"rxry": [
						34,
						426
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						426
					],
					"rxry": [
						34,
						433
					]
				},
				{
					"name": "q13.31",
					"lxly": [
						0,
						433
					],
					"rxry": [
						34,
						447
					]
				},
				{
					"name": "q13.32",
					"lxly": [
						0,
						447
					],
					"rxry": [
						34,
						450
					]
				},
				{
					"name": "q13.33",
					"lxly": [
						0,
						450
					],
					"rxry": [
						34,
						463
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						464
					],
					"rxry": [
						34,
						473
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						474
					],
					"rxry": [
						34,
						479
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						480
					],
					"rxry": [
						34,
						507
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						508
					],
					"rxry": [
						34,
						517
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						517
					],
					"rxry": [
						34,
						523
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						524
					],
					"rxry": [
						34,
						533
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						534
					],
					"rxry": [
						34,
						553
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						553
					],
					"rxry": [
						34,
						569
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						570
					],
					"rxry": [
						34,
						584
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						585
					],
					"rxry": [
						34,
						594
					]
				},
				{
					"name": "q25.31",
					"lxly": [
						0,
						595
					],
					"rxry": [
						34,
						600
					]
				},
				{
					"name": "q25.32",
					"lxly": [
						0,
						601
					],
					"rxry": [
						34,
						606
					]
				},
				{
					"name": "q25.33",
					"lxly": [
						0,
						607
					],
					"rxry": [
						34,
						616
					]
				},
				{
					"name": "q26.1",
					"lxly": [
						0,
						617
					],
					"rxry": [
						34,
						636
					]
				},
				{
					"name": "q26.2",
					"lxly": [
						0,
						636
					],
					"rxry": [
						34,
						651
					]
				},
				{
					"name": "q26.31",
					"lxly": [
						0,
						652
					],
					"rxry": [
						34,
						664
					]
				},
				{
					"name": "q26.32",
					"lxly": [
						0,
						664
					],
					"rxry": [
						34,
						672
					]
				},
				{
					"name": "q26.33",
					"lxly": [
						0,
						673
					],
					"rxry": [
						34,
						681
					]
				},
				{
					"name": "q27.1",
					"lxly": [
						0,
						682
					],
					"rxry": [
						34,
						696
					]
				},
				{
					"name": "q27.2",
					"lxly": [
						0,
						697
					],
					"rxry": [
						34,
						702
					]
				},
				{
					"name": "q27.3",
					"lxly": [
						0,
						703
					],
					"rxry": [
						34,
						711
					]
				},
				{
					"name": "q28",
					"lxly": [
						0,
						711
					],
					"rxry": [
						34,
						723
					]
				},
				{
					"name": "q29",
					"lxly": [
						0,
						724
					],
					"rxry": [
						34,
						747
					]
				}
			]
		},
		{
			"name": "Chrom04ISCN09",
			"element": [{
					"name": "p16.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						8
					]
				},
				{
					"name": "p16.2",
					"lxly": [
						0,
						9
					],
					"rxry": [
						33,
						15
					]
				},
				{
					"name": "p16.1",
					"lxly": [
						0,
						16
					],
					"rxry": [
						33,
						34
					]
				},
				{
					"name": "p15.33",
					"lxly": [
						0,
						34
					],
					"rxry": [
						33,
						49
					]
				},
				{
					"name": "p15.32",
					"lxly": [
						0,
						49
					],
					"rxry": [
						33,
						55
					]
				},
				{
					"name": "p15.31",
					"lxly": [
						0,
						56
					],
					"rxry": [
						33,
						70
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						70
					],
					"rxry": [
						33,
						93
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						93
					],
					"rxry": [
						33,
						121
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						121
					],
					"rxry": [
						33,
						154
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						154
					],
					"rxry": [
						33,
						172
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						173
					],
					"rxry": [
						33,
						180
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						181
					],
					"rxry": [
						33,
						187
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						188
					],
					"rxry": [
						33,
						199
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						200
					],
					"rxry": [
						33,
						232
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						233
					],
					"rxry": [
						33,
						257
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						257
					],
					"rxry": [
						33,
						264
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						264
					],
					"rxry": [
						33,
						275
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						275
					],
					"rxry": [
						33,
						299
					]
				},
				{
					"name": "q21.21",
					"lxly": [
						0,
						300
					],
					"rxry": [
						33,
						311
					]
				},
				{
					"name": "q21.22",
					"lxly": [
						0,
						312
					],
					"rxry": [
						33,
						316
					]
				},
				{
					"name": "q21.23",
					"lxly": [
						0,
						318
					],
					"rxry": [
						33,
						324
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						325
					],
					"rxry": [
						33,
						337
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						338
					],
					"rxry": [
						33,
						356
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						356
					],
					"rxry": [
						33,
						360
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						360
					],
					"rxry": [
						33,
						372
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						372
					],
					"rxry": [
						33,
						385
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						386
					],
					"rxry": [
						33,
						399
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						399
					],
					"rxry": [
						33,
						415
					]
				},
				{
					"name": "q26",
					"lxly": [
						0,
						416
					],
					"rxry": [
						33,
						441
					]
				},
				{
					"name": "q27",
					"lxly": [
						0,
						441
					],
					"rxry": [
						33,
						458
					]
				},
				{
					"name": "q28.1",
					"lxly": [
						0,
						459
					],
					"rxry": [
						33,
						468
					]
				},
				{
					"name": "q28.2",
					"lxly": [
						0,
						469
					],
					"rxry": [
						33,
						486
					]
				},
				{
					"name": "q28.3",
					"lxly": [
						0,
						486
					],
					"rxry": [
						33,
						514
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						515
					],
					"rxry": [
						33,
						535
					]
				},
				{
					"name": "q31.21",
					"lxly": [
						0,
						536
					],
					"rxry": [
						33,
						542
					]
				},
				{
					"name": "q31.22",
					"lxly": [
						0,
						543
					],
					"rxry": [
						33,
						553
					]
				},
				{
					"name": "q31.23",
					"lxly": [
						0,
						554
					],
					"rxry": [
						33,
						560
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						561
					],
					"rxry": [
						33,
						578
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						578
					],
					"rxry": [
						33,
						592
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						593
					],
					"rxry": [
						33,
						602
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						603
					],
					"rxry": [
						33,
						611
					]
				},
				{
					"name": "q33",
					"lxly": [
						0,
						611
					],
					"rxry": [
						33,
						629
					]
				},
				{
					"name": "q34.1",
					"lxly": [
						0,
						629
					],
					"rxry": [
						33,
						637
					]
				},
				{
					"name": "q34.2",
					"lxly": [
						0,
						637
					],
					"rxry": [
						33,
						641
					]
				},
				{
					"name": "q34.3",
					"lxly": [
						0,
						641
					],
					"rxry": [
						33,
						660
					]
				},
				{
					"name": "q35.1",
					"lxly": [
						0,
						660
					],
					"rxry": [
						33,
						694
					]
				},
				{
					"name": "q35.2",
					"lxly": [
						0,
						693
					],
					"rxry": [
						33,
						702
					]
				}
			]
		},
		{
			"name": "Chrom05ISCN09",
			"element": [{
					"name": "p15.33",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						20
					]
				},
				{
					"name": "p15.32",
					"lxly": [
						0,
						20
					],
					"rxry": [
						33,
						30
					]
				},
				{
					"name": "p15.31",
					"lxly": [
						0,
						31
					],
					"rxry": [
						33,
						46
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						47
					],
					"rxry": [
						33,
						55
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						56
					],
					"rxry": [
						33,
						84
					]
				},
				{
					"name": "p14.3",
					"lxly": [
						0,
						84
					],
					"rxry": [
						33,
						96
					]
				},
				{
					"name": "p14.2",
					"lxly": [
						0,
						97
					],
					"rxry": [
						33,
						98
					]
				},
				{
					"name": "p14.1",
					"lxly": [
						0,
						99
					],
					"rxry": [
						33,
						113
					]
				},
				{
					"name": "p13.3",
					"lxly": [
						0,
						114
					],
					"rxry": [
						33,
						129
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						129
					],
					"rxry": [
						33,
						133
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						135
					],
					"rxry": [
						33,
						153
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						154
					],
					"rxry": [
						33,
						166
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						167
					],
					"rxry": [
						33,
						174
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						175
					],
					"rxry": [
						33,
						184
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						185
					],
					"rxry": [
						33,
						209
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						210
					],
					"rxry": [
						33,
						223
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						224
					],
					"rxry": [
						33,
						229
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						230
					],
					"rxry": [
						33,
						243
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						243
					],
					"rxry": [
						33,
						258
					]
				},
				{
					"name": "q13.21",
					"lxly": [
						0,
						259
					],
					"rxry": [
						33,
						267
					]
				},
				{
					"name": "q13.22",
					"lxly": [
						0,
						268
					],
					"rxry": [
						33,
						278
					]
				},
				{
					"name": "q13.23",
					"lxly": [
						0,
						278
					],
					"rxry": [
						33,
						288
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						289
					],
					"rxry": [
						33,
						304
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						304
					],
					"rxry": [
						33,
						323
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						323
					],
					"rxry": [
						33,
						329
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						330
					],
					"rxry": [
						33,
						339
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						339
					],
					"rxry": [
						33,
						367
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						367
					],
					"rxry": [
						33,
						384
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						384
					],
					"rxry": [
						33,
						389
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						389
					],
					"rxry": [
						33,
						406
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						406
					],
					"rxry": [
						33,
						414
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						415
					],
					"rxry": [
						33,
						420
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						420
					],
					"rxry": [
						33,
						437
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						438
					],
					"rxry": [
						33,
						454
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						454
					],
					"rxry": [
						33,
						459
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						459
					],
					"rxry": [
						33,
						476
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						476
					],
					"rxry": [
						33,
						513
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						514
					],
					"rxry": [
						33,
						519
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						521
					],
					"rxry": [
						33,
						545
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						545
					],
					"rxry": [
						33,
						561
					]
				},
				{
					"name": "q33.1",
					"lxly": [
						0,
						561
					],
					"rxry": [
						33,
						577
					]
				},
				{
					"name": "q33.2",
					"lxly": [
						0,
						578
					],
					"rxry": [
						33,
						583
					]
				},
				{
					"name": "q33.3",
					"lxly": [
						0,
						584
					],
					"rxry": [
						33,
						609
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						610
					],
					"rxry": [
						33,
						628
					]
				},
				{
					"name": "q35.1",
					"lxly": [
						0,
						628
					],
					"rxry": [
						33,
						640
					]
				},
				{
					"name": "q35.2",
					"lxly": [
						0,
						641
					],
					"rxry": [
						33,
						647
					]
				},
				{
					"name": "q35.3",
					"lxly": [
						0,
						648
					],
					"rxry": [
						33,
						665
					]
				}
			]
		},
		{
			"name": "Chrom06ISCN09",
			"element": [{
					"name": "p25.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						7
					]
				},
				{
					"name": "p25.2",
					"lxly": [
						0,
						8
					],
					"rxry": [
						33,
						11
					]
				},
				{
					"name": "p25.1",
					"lxly": [
						0,
						13
					],
					"rxry": [
						33,
						25
					]
				},
				{
					"name": "p24",
					"lxly": [
						0,
						25
					],
					"rxry": [
						33,
						35
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						35
					],
					"rxry": [
						33,
						53
					]
				},
				{
					"name": "p22.33",
					"lxly": [
						0,
						54
					],
					"rxry": [
						33,
						57
					]
				},
				{
					"name": "p22.32",
					"lxly": [
						0,
						58
					],
					"rxry": [
						33,
						61
					]
				},
				{
					"name": "p22.31",
					"lxly": [
						0,
						63
					],
					"rxry": [
						33,
						76
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						76
					],
					"rxry": [
						33,
						80
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						81
					],
					"rxry": [
						33,
						86
					]
				},
				{
					"name": "p21.33",
					"lxly": [
						0,
						87
					],
					"rxry": [
						33,
						103
					]
				},
				{
					"name": "p21.32",
					"lxly": [
						0,
						103
					],
					"rxry": [
						33,
						105
					]
				},
				{
					"name": "p21.31",
					"lxly": [
						0,
						108
					],
					"rxry": [
						33,
						126
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						127
					],
					"rxry": [
						33,
						136
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						135
					],
					"rxry": [
						33,
						176
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						176
					],
					"rxry": [
						33,
						192
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						192
					],
					"rxry": [
						33,
						200
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						200
					],
					"rxry": [
						33,
						210
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						211
					],
					"rxry": [
						33,
						214
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						215
					],
					"rxry": [
						33,
						222
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						223
					],
					"rxry": [
						33,
						230
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						231
					],
					"rxry": [
						33,
						239
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						239
					],
					"rxry": [
						33,
						264
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						265
					],
					"rxry": [
						33,
						296
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						296
					],
					"rxry": [
						33,
						308
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						309
					],
					"rxry": [
						33,
						314
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						314
					],
					"rxry": [
						33,
						327
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						327
					],
					"rxry": [
						33,
						362
					]
				},
				{
					"name": "q16.1",
					"lxly": [
						0,
						362
					],
					"rxry": [
						33,
						378
					]
				},
				{
					"name": "q16.2",
					"lxly": [
						0,
						378
					],
					"rxry": [
						33,
						384
					]
				},
				{
					"name": "q16.3",
					"lxly": [
						0,
						384
					],
					"rxry": [
						33,
						400
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						400
					],
					"rxry": [
						33,
						413
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						414
					],
					"rxry": [
						33,
						418
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						419
					],
					"rxry": [
						33,
						441
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						441
					],
					"rxry": [
						33,
						450
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						451
					],
					"rxry": [
						33,
						460
					]
				},
				{
					"name": "q22.31",
					"lxly": [
						0,
						461
					],
					"rxry": [
						33,
						470
					]
				},
				{
					"name": "q22.32",
					"lxly": [
						0,
						470
					],
					"rxry": [
						33,
						485
					]
				},
				{
					"name": "q22.33",
					"lxly": [
						0,
						485
					],
					"rxry": [
						33,
						495
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						495
					],
					"rxry": [
						33,
						509
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						510
					],
					"rxry": [
						33,
						515
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						516
					],
					"rxry": [
						33,
						539
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						539
					],
					"rxry": [
						33,
						549
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						549
					],
					"rxry": [
						33,
						558
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						558
					],
					"rxry": [
						33,
						568
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						568
					],
					"rxry": [
						33,
						592
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						593
					],
					"rxry": [
						33,
						601
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						602
					],
					"rxry": [
						33,
						624
					]
				},
				{
					"name": "q26",
					"lxly": [
						0,
						624
					],
					"rxry": [
						33,
						633
					]
				},
				{
					"name": "q27",
					"lxly": [
						0,
						634
					],
					"rxry": [
						33,
						651
					]
				}
			]
		},
		{
			"name": "Chrom07ISCN09",
			"element": [{
					"name": "p22.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						10
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						11
					],
					"rxry": [
						33,
						14
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						16
					],
					"rxry": [
						33,
						29
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						29
					],
					"rxry": [
						33,
						44
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						44
					],
					"rxry": [
						33,
						48
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						48
					],
					"rxry": [
						33,
						56
					]
				},
				{
					"name": "p15.3",
					"lxly": [
						0,
						56
					],
					"rxry": [
						33,
						71
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						72
					],
					"rxry": [
						33,
						79
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						81
					],
					"rxry": [
						33,
						109
					]
				},
				{
					"name": "p14.3",
					"lxly": [
						0,
						110
					],
					"rxry": [
						33,
						118
					]
				},
				{
					"name": "p14.2",
					"lxly": [
						0,
						119
					],
					"rxry": [
						33,
						128
					]
				},
				{
					"name": "p14.1",
					"lxly": [
						0,
						130
					],
					"rxry": [
						33,
						140
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						140
					],
					"rxry": [
						33,
						159
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						159
					],
					"rxry": [
						33,
						170
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						170
					],
					"rxry": [
						33,
						175
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						175
					],
					"rxry": [
						33,
						186
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						187
					],
					"rxry": [
						33,
						200
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						201
					],
					"rxry": [
						33,
						206
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						207
					],
					"rxry": [
						33,
						215
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						216
					],
					"rxry": [
						33,
						239
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						240
					],
					"rxry": [
						33,
						254
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						255
					],
					"rxry": [
						33,
						291
					]
				},
				{
					"name": "q21.11",
					"lxly": [
						0,
						291
					],
					"rxry": [
						33,
						313
					]
				},
				{
					"name": "q21.12",
					"lxly": [
						0,
						314
					],
					"rxry": [
						33,
						315
					]
				},
				{
					"name": "q21.13",
					"lxly": [
						0,
						317
					],
					"rxry": [
						33,
						329
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						329
					],
					"rxry": [
						33,
						336
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						336
					],
					"rxry": [
						33,
						354
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						355
					],
					"rxry": [
						33,
						400
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						401
					],
					"rxry": [
						33,
						409
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						410
					],
					"rxry": [
						33,
						416
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						416
					],
					"rxry": [
						33,
						429
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						429
					],
					"rxry": [
						33,
						441
					]
				},
				{
					"name": "q31.31",
					"lxly": [
						0,
						441
					],
					"rxry": [
						33,
						452
					]
				},
				{
					"name": "q31.32",
					"lxly": [
						0,
						453
					],
					"rxry": [
						33,
						454
					]
				},
				{
					"name": "q31.33",
					"lxly": [
						0,
						457
					],
					"rxry": [
						33,
						470
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						470
					],
					"rxry": [
						33,
						480
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						481
					],
					"rxry": [
						33,
						485
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						486
					],
					"rxry": [
						33,
						504
					]
				},
				{
					"name": "q33",
					"lxly": [
						0,
						503
					],
					"rxry": [
						33,
						514
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						514
					],
					"rxry": [
						33,
						539
					]
				},
				{
					"name": "q35",
					"lxly": [
						0,
						539
					],
					"rxry": [
						33,
						555
					]
				},
				{
					"name": "q36.1",
					"lxly": [
						0,
						555
					],
					"rxry": [
						33,
						568
					]
				},
				{
					"name": "q36.2",
					"lxly": [
						0,
						569
					],
					"rxry": [
						33,
						575
					]
				},
				{
					"name": "q36.3",
					"lxly": [
						0,
						575
					],
					"rxry": [
						33,
						592
					]
				}
			]
		},
		{
			"name": "Chrom08ISCN09",
			"element": [{
					"name": "p23.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						5
					]
				},
				{
					"name": "p23.2",
					"lxly": [
						0,
						6
					],
					"rxry": [
						33,
						15
					]
				},
				{
					"name": "p23.1",
					"lxly": [
						0,
						15
					],
					"rxry": [
						33,
						38
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						39
					],
					"rxry": [
						33,
						57
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						57
					],
					"rxry": [
						33,
						89
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						90
					],
					"rxry": [
						33,
						98
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						99
					],
					"rxry": [
						33,
						113
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						113
					],
					"rxry": [
						33,
						126
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						125
					],
					"rxry": [
						33,
						131
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						131
					],
					"rxry": [
						33,
						138
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						138
					],
					"rxry": [
						33,
						154
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						155
					],
					"rxry": [
						33,
						160
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						161
					],
					"rxry": [
						33,
						167
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						168
					],
					"rxry": [
						33,
						184
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						185
					],
					"rxry": [
						33,
						203
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						203
					],
					"rxry": [
						33,
						229
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						230
					],
					"rxry": [
						33,
						235
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						236
					],
					"rxry": [
						33,
						242
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						242
					],
					"rxry": [
						33,
						250
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						251
					],
					"rxry": [
						33,
						261
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						262
					],
					"rxry": [
						33,
						267
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						268
					],
					"rxry": [
						33,
						277
					]
				},
				{
					"name": "q21.11",
					"lxly": [
						0,
						277
					],
					"rxry": [
						33,
						289
					]
				},
				{
					"name": "q21.12",
					"lxly": [
						0,
						289
					],
					"rxry": [
						33,
						298
					]
				},
				{
					"name": "q21.13",
					"lxly": [
						0,
						298
					],
					"rxry": [
						33,
						307
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						307
					],
					"rxry": [
						33,
						322
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						322
					],
					"rxry": [
						33,
						342
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						342
					],
					"rxry": [
						33,
						358
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						359
					],
					"rxry": [
						33,
						367
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						368
					],
					"rxry": [
						33,
						394
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						395
					],
					"rxry": [
						33,
						402
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						402
					],
					"rxry": [
						33,
						408
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						408
					],
					"rxry": [
						33,
						427
					]
				},
				{
					"name": "q24.11",
					"lxly": [
						0,
						427
					],
					"rxry": [
						33,
						438
					]
				},
				{
					"name": "q24.12",
					"lxly": [
						0,
						439
					],
					"rxry": [
						33,
						445
					]
				},
				{
					"name": "q24.13",
					"lxly": [
						0,
						445
					],
					"rxry": [
						33,
						471
					]
				},
				{
					"name": "q24.21",
					"lxly": [
						0,
						471
					],
					"rxry": [
						33,
						480
					]
				},
				{
					"name": "q24.22",
					"lxly": [
						0,
						480
					],
					"rxry": [
						33,
						492
					]
				},
				{
					"name": "q24.23",
					"lxly": [
						0,
						491
					],
					"rxry": [
						33,
						503
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						504
					],
					"rxry": [
						33,
						523
					]
				}
			]
		},
		{
			"name": "Chrom09ISCN09",
			"element": [{
					"name": "p24.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						12
					]
				},
				{
					"name": "p24.2",
					"lxly": [
						0,
						14
					],
					"rxry": [
						33,
						19
					]
				},
				{
					"name": "p24.1",
					"lxly": [
						0,
						20
					],
					"rxry": [
						33,
						27
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						28
					],
					"rxry": [
						33,
						46
					]
				},
				{
					"name": "p22.3",
					"lxly": [
						0,
						47
					],
					"rxry": [
						33,
						57
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						58
					],
					"rxry": [
						33,
						63
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						64
					],
					"rxry": [
						33,
						79
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						79
					],
					"rxry": [
						33,
						89
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						90
					],
					"rxry": [
						33,
						95
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						95
					],
					"rxry": [
						33,
						107
					]
				},
				{
					"name": "p13.3",
					"lxly": [
						0,
						107
					],
					"rxry": [
						33,
						130
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						131
					],
					"rxry": [
						33,
						137
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						138
					],
					"rxry": [
						33,
						151
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						151
					],
					"rxry": [
						33,
						161
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						161
					],
					"rxry": [
						33,
						163
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						164
					],
					"rxry": [
						33,
						170
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						171
					],
					"rxry": [
						33,
						179
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						180
					],
					"rxry": [
						33,
						233
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						235
					],
					"rxry": [
						33,
						246
					]
				},
				{
					"name": "q21.11",
					"lxly": [
						0,
						247
					],
					"rxry": [
						33,
						252
					]
				},
				{
					"name": "q21.12",
					"lxly": [
						0,
						253
					],
					"rxry": [
						33,
						263
					]
				},
				{
					"name": "q21.13",
					"lxly": [
						0,
						263
					],
					"rxry": [
						33,
						276
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						276
					],
					"rxry": [
						33,
						285
					]
				},
				{
					"name": "q21.31",
					"lxly": [
						0,
						286
					],
					"rxry": [
						33,
						298
					]
				},
				{
					"name": "q21.32",
					"lxly": [
						0,
						298
					],
					"rxry": [
						33,
						303
					]
				},
				{
					"name": "q21.33",
					"lxly": [
						0,
						303
					],
					"rxry": [
						33,
						323
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						323
					],
					"rxry": [
						33,
						335
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						336
					],
					"rxry": [
						33,
						341
					]
				},
				{
					"name": "q22.31",
					"lxly": [
						0,
						343
					],
					"rxry": [
						33,
						357
					]
				},
				{
					"name": "q22.32",
					"lxly": [
						0,
						358
					],
					"rxry": [
						33,
						363
					]
				},
				{
					"name": "q22.33",
					"lxly": [
						0,
						364
					],
					"rxry": [
						33,
						386
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						386
					],
					"rxry": [
						33,
						399
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						400
					],
					"rxry": [
						33,
						408
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						409
					],
					"rxry": [
						33,
						414
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						415
					],
					"rxry": [
						33,
						434
					]
				},
				{
					"name": "q33.1",
					"lxly": [
						0,
						434
					],
					"rxry": [
						33,
						447
					]
				},
				{
					"name": "q33.2",
					"lxly": [
						0,
						447
					],
					"rxry": [
						33,
						453
					]
				},
				{
					"name": "q33.3",
					"lxly": [
						0,
						454
					],
					"rxry": [
						33,
						459
					]
				},
				{
					"name": "q34.1",
					"lxly": [
						0,
						460
					],
					"rxry": [
						33,
						483
					]
				},
				{
					"name": "q34.2",
					"lxly": [
						0,
						485
					],
					"rxry": [
						33,
						489
					]
				},
				{
					"name": "q34.3",
					"lxly": [
						0,
						491
					],
					"rxry": [
						33,
						522
					]
				}
			]
		},
		{
			"name": "Chrom10ISCN09",
			"element": [{
					"name": "p15.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						34,
						6
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						8
					],
					"rxry": [
						34,
						13
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						14
					],
					"rxry": [
						34,
						34
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						34
					],
					"rxry": [
						34,
						43
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						44
					],
					"rxry": [
						34,
						63
					]
				},
				{
					"name": "p12.33",
					"lxly": [
						0,
						63
					],
					"rxry": [
						34,
						72
					]
				},
				{
					"name": "p12.32",
					"lxly": [
						0,
						73
					],
					"rxry": [
						34,
						75
					]
				},
				{
					"name": "p12.31",
					"lxly": [
						0,
						77
					],
					"rxry": [
						34,
						87
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						88
					],
					"rxry": [
						34,
						95
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						96
					],
					"rxry": [
						34,
						103
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						104
					],
					"rxry": [
						34,
						135
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						136
					],
					"rxry": [
						34,
						142
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						142
					],
					"rxry": [
						34,
						157
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						158
					],
					"rxry": [
						34,
						164
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						165
					],
					"rxry": [
						34,
						170
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						172
					],
					"rxry": [
						34,
						187
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						188
					],
					"rxry": [
						34,
						190
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						192
					],
					"rxry": [
						34,
						216
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						216
					],
					"rxry": [
						34,
						234
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						234
					],
					"rxry": [
						34,
						245
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						245
					],
					"rxry": [
						34,
						261
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						261
					],
					"rxry": [
						34,
						288
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						287
					],
					"rxry": [
						34,
						293
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						294
					],
					"rxry": [
						34,
						309
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						309
					],
					"rxry": [
						34,
						325
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						325
					],
					"rxry": [
						34,
						337
					]
				},
				{
					"name": "q23.31",
					"lxly": [
						0,
						338
					],
					"rxry": [
						34,
						348
					]
				},
				{
					"name": "q23.32",
					"lxly": [
						0,
						349
					],
					"rxry": [
						34,
						351
					]
				},
				{
					"name": "q23.33",
					"lxly": [
						0,
						352
					],
					"rxry": [
						34,
						362
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						363
					],
					"rxry": [
						34,
						370
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						371
					],
					"rxry": [
						34,
						376
					]
				},
				{
					"name": "q24.31",
					"lxly": [
						0,
						377
					],
					"rxry": [
						34,
						387
					]
				},
				{
					"name": "q24.32",
					"lxly": [
						0,
						388
					],
					"rxry": [
						34,
						391
					]
				},
				{
					"name": "q24.33",
					"lxly": [
						0,
						392
					],
					"rxry": [
						34,
						395
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						396
					],
					"rxry": [
						34,
						412
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						412
					],
					"rxry": [
						34,
						427
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						428
					],
					"rxry": [
						34,
						436
					]
				},
				{
					"name": "q26.11",
					"lxly": [
						0,
						436
					],
					"rxry": [
						34,
						447
					]
				},
				{
					"name": "q26.12",
					"lxly": [
						0,
						448
					],
					"rxry": [
						34,
						453
					]
				},
				{
					"name": "q26.13",
					"lxly": [
						0,
						454
					],
					"rxry": [
						34,
						483
					]
				},
				{
					"name": "q26.2",
					"lxly": [
						0,
						483
					],
					"rxry": [
						34,
						492
					]
				},
				{
					"name": "q26.3",
					"lxly": [
						0,
						493
					],
					"rxry": [
						34,
						508
					]
				}
			]
		},
		{
			"name": "Chrom11ISCN09",
			"element": [{
					"name": "p15.5",
					"lxly": [
						0,
						0
					],
					"rxry": [
						34,
						22
					]
				},
				{
					"name": "p15.4",
					"lxly": [
						0,
						22
					],
					"rxry": [
						34,
						32
					]
				},
				{
					"name": "p15.3",
					"lxly": [
						0,
						32
					],
					"rxry": [
						34,
						48
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						49
					],
					"rxry": [
						34,
						53
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						54
					],
					"rxry": [
						34,
						85
					]
				},
				{
					"name": "p14.3",
					"lxly": [
						0,
						85
					],
					"rxry": [
						34,
						106
					]
				},
				{
					"name": "p14.2",
					"lxly": [
						0,
						106
					],
					"rxry": [
						34,
						113
					]
				},
				{
					"name": "p14.1",
					"lxly": [
						0,
						113
					],
					"rxry": [
						34,
						122
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						122
					],
					"rxry": [
						34,
						139
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						139
					],
					"rxry": [
						34,
						153
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						154
					],
					"rxry": [
						34,
						186
					]
				},
				{
					"name": "p11.12",
					"lxly": [
						0,
						186
					],
					"rxry": [
						34,
						196
					]
				},
				{
					"name": "p11.11",
					"lxly": [
						0,
						196
					],
					"rxry": [
						34,
						202
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						203
					],
					"rxry": [
						34,
						211
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						212
					],
					"rxry": [
						34,
						228
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						228
					],
					"rxry": [
						34,
						236
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						237
					],
					"rxry": [
						34,
						242
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						242
					],
					"rxry": [
						34,
						277
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						278
					],
					"rxry": [
						34,
						283
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						284
					],
					"rxry": [
						34,
						301
					]
				},
				{
					"name": "q13.4",
					"lxly": [
						0,
						302
					],
					"rxry": [
						34,
						311
					]
				},
				{
					"name": "q13.5",
					"lxly": [
						0,
						312
					],
					"rxry": [
						34,
						331
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						331
					],
					"rxry": [
						34,
						350
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						350
					],
					"rxry": [
						34,
						355
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						355
					],
					"rxry": [
						34,
						371
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						372
					],
					"rxry": [
						34,
						385
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						385
					],
					"rxry": [
						34,
						400
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						400
					],
					"rxry": [
						34,
						405
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						405
					],
					"rxry": [
						34,
						420
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						420
					],
					"rxry": [
						34,
						433
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						434
					],
					"rxry": [
						34,
						439
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						440
					],
					"rxry": [
						34,
						469
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						468
					],
					"rxry": [
						34,
						477
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						478
					],
					"rxry": [
						34,
						489
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						490
					],
					"rxry": [
						34,
						498
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						498
					],
					"rxry": [
						34,
						510
					]
				}
			]
		},
		{
			"name": "Chrom12ISCN09",
			"element": [{
					"name": "p13.33",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						9
					]
				},
				{
					"name": "p13.32",
					"lxly": [
						0,
						10
					],
					"rxry": [
						33,
						15
					]
				},
				{
					"name": "p13.31",
					"lxly": [
						0,
						16
					],
					"rxry": [
						33,
						36
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						36
					],
					"rxry": [
						33,
						42
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						43
					],
					"rxry": [
						33,
						56
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						56
					],
					"rxry": [
						33,
						69
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						70
					],
					"rxry": [
						33,
						72
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						74
					],
					"rxry": [
						33,
						83
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						84
					],
					"rxry": [
						33,
						104
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						105
					],
					"rxry": [
						33,
						113
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						114
					],
					"rxry": [
						33,
						120
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						121
					],
					"rxry": [
						33,
						127
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						128
					],
					"rxry": [
						33,
						136
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						137
					],
					"rxry": [
						33,
						153
					]
				},
				{
					"name": "q13.11",
					"lxly": [
						0,
						153
					],
					"rxry": [
						33,
						171
					]
				},
				{
					"name": "q13.12",
					"lxly": [
						0,
						173
					],
					"rxry": [
						33,
						178
					]
				},
				{
					"name": "q13.13",
					"lxly": [
						0,
						179
					],
					"rxry": [
						33,
						196
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						197
					],
					"rxry": [
						33,
						202
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						203
					],
					"rxry": [
						33,
						221
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						221
					],
					"rxry": [
						33,
						234
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						234
					],
					"rxry": [
						33,
						242
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						244
					],
					"rxry": [
						33,
						249
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						250
					],
					"rxry": [
						33,
						267
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						267
					],
					"rxry": [
						33,
						280
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						280
					],
					"rxry": [
						33,
						292
					]
				},
				{
					"name": "q21.31",
					"lxly": [
						0,
						292
					],
					"rxry": [
						33,
						303
					]
				},
				{
					"name": "q21.32",
					"lxly": [
						0,
						304
					],
					"rxry": [
						33,
						306
					]
				},
				{
					"name": "q21.33",
					"lxly": [
						0,
						308
					],
					"rxry": [
						33,
						323
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						324
					],
					"rxry": [
						33,
						344
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						345
					],
					"rxry": [
						33,
						357
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						357
					],
					"rxry": [
						33,
						363
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						363
					],
					"rxry": [
						33,
						373
					]
				},
				{
					"name": "q24.11",
					"lxly": [
						0,
						373
					],
					"rxry": [
						33,
						399
					]
				},
				{
					"name": "q24.12",
					"lxly": [
						0,
						399
					],
					"rxry": [
						33,
						405
					]
				},
				{
					"name": "q24.13",
					"lxly": [
						0,
						406
					],
					"rxry": [
						33,
						417
					]
				},
				{
					"name": "q24.21",
					"lxly": [
						0,
						417
					],
					"rxry": [
						33,
						428
					]
				},
				{
					"name": "q24.22",
					"lxly": [
						0,
						428
					],
					"rxry": [
						33,
						432
					]
				},
				{
					"name": "q24.23",
					"lxly": [
						0,
						433
					],
					"rxry": [
						33,
						440
					]
				},
				{
					"name": "q24.31",
					"lxly": [
						0,
						440
					],
					"rxry": [
						33,
						468
					]
				},
				{
					"name": "q24.32",
					"lxly": [
						0,
						468
					],
					"rxry": [
						33,
						478
					]
				},
				{
					"name": "q24.33",
					"lxly": [
						0,
						478
					],
					"rxry": [
						33,
						493
					]
				}
			]
		},
		{
			"name": "Chrom13ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						20
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						20
					],
					"rxry": [
						33,
						30
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						31
					],
					"rxry": [
						33,
						53
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						53
					],
					"rxry": [
						33,
						62
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						63
					],
					"rxry": [
						33,
						69
					]
				},
				{
					"name": "q12.11",
					"lxly": [
						0,
						70
					],
					"rxry": [
						33,
						80
					]
				},
				{
					"name": "q12.12",
					"lxly": [
						0,
						81
					],
					"rxry": [
						33,
						86
					]
				},
				{
					"name": "q12.13",
					"lxly": [
						0,
						87
					],
					"rxry": [
						33,
						97
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						98
					],
					"rxry": [
						33,
						103
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						104
					],
					"rxry": [
						33,
						117
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						117
					],
					"rxry": [
						33,
						124
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						125
					],
					"rxry": [
						33,
						130
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						131
					],
					"rxry": [
						33,
						143
					]
				},
				{
					"name": "q14.11",
					"lxly": [
						0,
						143
					],
					"rxry": [
						33,
						161
					]
				},
				{
					"name": "q14.12",
					"lxly": [
						0,
						162
					],
					"rxry": [
						33,
						167
					]
				},
				{
					"name": "q14.13",
					"lxly": [
						0,
						168
					],
					"rxry": [
						33,
						174
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						175
					],
					"rxry": [
						33,
						185
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						186
					],
					"rxry": [
						33,
						208
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						208
					],
					"rxry": [
						33,
						221
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						221
					],
					"rxry": [
						33,
						229
					]
				},
				{
					"name": "q21.31",
					"lxly": [
						0,
						230
					],
					"rxry": [
						33,
						239
					]
				},
				{
					"name": "q21.32",
					"lxly": [
						0,
						238
					],
					"rxry": [
						33,
						242
					]
				},
				{
					"name": "q21.33",
					"lxly": [
						0,
						242
					],
					"rxry": [
						33,
						256
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						258
					],
					"rxry": [
						33,
						272
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						273
					],
					"rxry": [
						33,
						278
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						279
					],
					"rxry": [
						33,
						291
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						292
					],
					"rxry": [
						33,
						309
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						309
					],
					"rxry": [
						33,
						315
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						315
					],
					"rxry": [
						33,
						324
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						324
					],
					"rxry": [
						33,
						339
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						341
					],
					"rxry": [
						33,
						346
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						347
					],
					"rxry": [
						33,
						370
					]
				},
				{
					"name": "q33.1",
					"lxly": [
						0,
						370
					],
					"rxry": [
						33,
						379
					]
				},
				{
					"name": "q33.2",
					"lxly": [
						0,
						380
					],
					"rxry": [
						33,
						382
					]
				},
				{
					"name": "q33.3",
					"lxly": [
						0,
						384
					],
					"rxry": [
						33,
						397
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						398
					],
					"rxry": [
						33,
						415
					]
				}
			]
		},
		{
			"name": "Chrom14ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						20
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						22
					],
					"rxry": [
						33,
						31
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						32
					],
					"rxry": [
						33,
						53
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						54
					],
					"rxry": [
						33,
						60
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						61
					],
					"rxry": [
						33,
						69
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						70
					],
					"rxry": [
						33,
						94
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						94
					],
					"rxry": [
						33,
						113
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						113
					],
					"rxry": [
						33,
						128
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						129
					],
					"rxry": [
						33,
						133
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						135
					],
					"rxry": [
						33,
						142
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						142
					],
					"rxry": [
						33,
						156
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						157
					],
					"rxry": [
						33,
						159
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						160
					],
					"rxry": [
						33,
						175
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						176
					],
					"rxry": [
						33,
						197
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						198
					],
					"rxry": [
						33,
						202
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						203
					],
					"rxry": [
						33,
						213
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						214
					],
					"rxry": [
						33,
						226
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						226
					],
					"rxry": [
						33,
						234
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						235
					],
					"rxry": [
						33,
						242
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						243
					],
					"rxry": [
						33,
						261
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						262
					],
					"rxry": [
						33,
						268
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						269
					],
					"rxry": [
						33,
						303
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						303
					],
					"rxry": [
						33,
						316
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						317
					],
					"rxry": [
						33,
						319
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						320
					],
					"rxry": [
						33,
						334
					]
				},
				{
					"name": "q32.11",
					"lxly": [
						0,
						334
					],
					"rxry": [
						33,
						338
					]
				},
				{
					"name": "q32.12",
					"lxly": [
						0,
						339
					],
					"rxry": [
						33,
						343
					]
				},
				{
					"name": "q32.13",
					"lxly": [
						0,
						344
					],
					"rxry": [
						33,
						357
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						356
					],
					"rxry": [
						33,
						366
					]
				},
				{
					"name": "q32.31",
					"lxly": [
						0,
						367
					],
					"rxry": [
						33,
						377
					]
				},
				{
					"name": "q32.32",
					"lxly": [
						0,
						378
					],
					"rxry": [
						33,
						386
					]
				},
				{
					"name": "q32.33",
					"lxly": [
						0,
						386
					],
					"rxry": [
						33,
						401
					]
				}
			]
		},
		{
			"name": "Chrom15ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						21
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						22
					],
					"rxry": [
						33,
						31
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						32
					],
					"rxry": [
						33,
						52
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						53
					],
					"rxry": [
						33,
						60
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						61
					],
					"rxry": [
						33,
						71
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						72
					],
					"rxry": [
						33,
						88
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						89
					],
					"rxry": [
						33,
						95
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						95
					],
					"rxry": [
						33,
						104
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						104
					],
					"rxry": [
						33,
						109
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						109
					],
					"rxry": [
						33,
						134
					]
				},
				{
					"name": "q14",
					"lxly": [
						0,
						134
					],
					"rxry": [
						33,
						144
					]
				},
				{
					"name": "q15.1",
					"lxly": [
						0,
						144
					],
					"rxry": [
						33,
						167
					]
				},
				{
					"name": "q15.2",
					"lxly": [
						0,
						167
					],
					"rxry": [
						33,
						171
					]
				},
				{
					"name": "q15.3",
					"lxly": [
						0,
						170
					],
					"rxry": [
						33,
						177
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						177
					],
					"rxry": [
						33,
						191
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						191
					],
					"rxry": [
						33,
						197
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						197
					],
					"rxry": [
						33,
						211
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						211
					],
					"rxry": [
						33,
						220
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						221
					],
					"rxry": [
						33,
						227
					]
				},
				{
					"name": "q22.31",
					"lxly": [
						0,
						228
					],
					"rxry": [
						33,
						237
					]
				},
				{
					"name": "q22.32",
					"lxly": [
						0,
						236
					],
					"rxry": [
						33,
						241
					]
				},
				{
					"name": "q22.33",
					"lxly": [
						0,
						241
					],
					"rxry": [
						33,
						254
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						254
					],
					"rxry": [
						33,
						265
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						264
					],
					"rxry": [
						33,
						287
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						288
					],
					"rxry": [
						33,
						292
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						291
					],
					"rxry": [
						33,
						302
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						303
					],
					"rxry": [
						33,
						310
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						310
					],
					"rxry": [
						33,
						314
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						314
					],
					"rxry": [
						33,
						328
					]
				},
				{
					"name": "q26.1",
					"lxly": [
						0,
						328
					],
					"rxry": [
						33,
						350
					]
				},
				{
					"name": "q26.2",
					"lxly": [
						0,
						350
					],
					"rxry": [
						33,
						364
					]
				},
				{
					"name": "q26.3",
					"lxly": [
						0,
						364
					],
					"rxry": [
						33,
						378
					]
				}
			]
		},
		{
			"name": "Chrom16ISCN09",
			"element": [{
					"name": "p13.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						27
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						28
					],
					"rxry": [
						33,
						42
					]
				},
				{
					"name": "p13.13",
					"lxly": [
						0,
						43
					],
					"rxry": [
						33,
						54
					]
				},
				{
					"name": "p13.12",
					"lxly": [
						0,
						55
					],
					"rxry": [
						33,
						61
					]
				},
				{
					"name": "p13.11",
					"lxly": [
						0,
						62
					],
					"rxry": [
						33,
						76
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						77
					],
					"rxry": [
						33,
						85
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						86
					],
					"rxry": [
						33,
						94
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						95
					],
					"rxry": [
						33,
						103
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						104
					],
					"rxry": [
						33,
						139
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						140
					],
					"rxry": [
						33,
						145
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						146
					],
					"rxry": [
						33,
						151
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						152
					],
					"rxry": [
						33,
						191
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						192
					],
					"rxry": [
						33,
						214
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						215
					],
					"rxry": [
						33,
						222
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						223
					],
					"rxry": [
						33,
						247
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						247
					],
					"rxry": [
						33,
						266
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						266
					],
					"rxry": [
						33,
						274
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						275
					],
					"rxry": [
						33,
						279
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						280
					],
					"rxry": [
						33,
						289
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						290
					],
					"rxry": [
						33,
						302
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						302
					],
					"rxry": [
						33,
						306
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						308
					],
					"rxry": [
						33,
						315
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						316
					],
					"rxry": [
						33,
						335
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						336
					],
					"rxry": [
						33,
						340
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						341
					],
					"rxry": [
						33,
						350
					]
				}
			]
		},
		{
			"name": "Chrom17ISCN09",
			"element": [{
					"name": "p13.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						16
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						17
					],
					"rxry": [
						33,
						23
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						24
					],
					"rxry": [
						33,
						61
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						62
					],
					"rxry": [
						33,
						74
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						75
					],
					"rxry": [
						33,
						109
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						110
					],
					"rxry": [
						33,
						115
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						116
					],
					"rxry": [
						33,
						124
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						125
					],
					"rxry": [
						33,
						143
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						144
					],
					"rxry": [
						33,
						152
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						153
					],
					"rxry": [
						33,
						166
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						167
					],
					"rxry": [
						33,
						171
					]
				},
				{
					"name": "q21.31",
					"lxly": [
						0,
						173
					],
					"rxry": [
						33,
						208
					]
				},
				{
					"name": "q21.32",
					"lxly": [
						0,
						209
					],
					"rxry": [
						33,
						214
					]
				},
				{
					"name": "q21.33",
					"lxly": [
						0,
						215
					],
					"rxry": [
						33,
						235
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						236
					],
					"rxry": [
						33,
						247
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						247
					],
					"rxry": [
						33,
						258
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						259
					],
					"rxry": [
						33,
						267
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						268
					],
					"rxry": [
						33,
						278
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						279
					],
					"rxry": [
						33,
						282
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						284
					],
					"rxry": [
						33,
						290
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						290
					],
					"rxry": [
						33,
						304
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						304
					],
					"rxry": [
						33,
						314
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						315
					],
					"rxry": [
						33,
						319
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						320
					],
					"rxry": [
						33,
						340
					]
				}
			]
		},
		{
			"name": "Chrom18ISCN09",
			"element": [{
					"name": "p11.32",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						4
					]
				},
				{
					"name": "p11.31",
					"lxly": [
						0,
						6
					],
					"rxry": [
						33,
						23
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						23
					],
					"rxry": [
						33,
						28
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						29
					],
					"rxry": [
						33,
						34
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						35
					],
					"rxry": [
						33,
						73
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						74
					],
					"rxry": [
						33,
						80
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						81
					],
					"rxry": [
						33,
						89
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						90
					],
					"rxry": [
						33,
						112
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						112
					],
					"rxry": [
						33,
						134
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						134
					],
					"rxry": [
						33,
						145
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						146
					],
					"rxry": [
						33,
						163
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						163
					],
					"rxry": [
						33,
						211
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						212
					],
					"rxry": [
						33,
						225
					]
				},
				{
					"name": "q21.31",
					"lxly": [
						0,
						226
					],
					"rxry": [
						33,
						238
					]
				},
				{
					"name": "q21.32",
					"lxly": [
						0,
						239
					],
					"rxry": [
						33,
						245
					]
				},
				{
					"name": "q21.33",
					"lxly": [
						0,
						246
					],
					"rxry": [
						33,
						259
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						259
					],
					"rxry": [
						33,
						277
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						277
					],
					"rxry": [
						33,
						283
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						284
					],
					"rxry": [
						33,
						290
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						290
					],
					"rxry": [
						33,
						304
					]
				}
			]
		},
		{
			"name": "Chrom19ISCN09",
			"element": [{
					"name": "p13.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						31,
						42
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						43
					],
					"rxry": [
						31,
						54
					]
				},
				{
					"name": "p13.13",
					"lxly": [
						0,
						55
					],
					"rxry": [
						31,
						62
					]
				},
				{
					"name": "p13.12",
					"lxly": [
						0,
						63
					],
					"rxry": [
						31,
						72
					]
				},
				{
					"name": "p13.11",
					"lxly": [
						0,
						73
					],
					"rxry": [
						31,
						100
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						101
					],
					"rxry": [
						31,
						109
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						110
					],
					"rxry": [
						31,
						119
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						120
					],
					"rxry": [
						31,
						128
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						129
					],
					"rxry": [
						31,
						139
					]
				},
				{
					"name": "q13.11",
					"lxly": [
						0,
						140
					],
					"rxry": [
						31,
						148
					]
				},
				{
					"name": "q13.12",
					"lxly": [
						0,
						149
					],
					"rxry": [
						31,
						155
					]
				},
				{
					"name": "q13.13",
					"lxly": [
						0,
						156
					],
					"rxry": [
						31,
						174
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						175
					],
					"rxry": [
						31,
						184
					]
				},
				{
					"name": "q13.31",
					"lxly": [
						0,
						185
					],
					"rxry": [
						31,
						194
					]
				},
				{
					"name": "q13.32",
					"lxly": [
						0,
						195
					],
					"rxry": [
						31,
						204
					]
				},
				{
					"name": "q13.33",
					"lxly": [
						0,
						205
					],
					"rxry": [
						31,
						240
					]
				},
				{
					"name": "q13.41",
					"lxly": [
						0,
						241
					],
					"rxry": [
						31,
						250
					]
				},
				{
					"name": "q13.42",
					"lxly": [
						0,
						250
					],
					"rxry": [
						31,
						262
					]
				},
				{
					"name": "q13.43",
					"lxly": [
						0,
						263
					],
					"rxry": [
						31,
						267
					]
				}
			]
		},
		{
			"name": "Chrom20ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						32,
						20
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						20
					],
					"rxry": [
						32,
						30
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						30
					],
					"rxry": [
						32,
						36
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						37
					],
					"rxry": [
						32,
						49
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						49
					],
					"rxry": [
						32,
						74
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						75
					],
					"rxry": [
						32,
						82
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						83
					],
					"rxry": [
						32,
						103
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						104
					],
					"rxry": [
						32,
						112
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						113
					],
					"rxry": [
						32,
						119
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						120
					],
					"rxry": [
						32,
						135
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						136
					],
					"rxry": [
						32,
						141
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						143
					],
					"rxry": [
						32,
						160
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						160
					],
					"rxry": [
						32,
						173
					]
				},
				{
					"name": "q13.11",
					"lxly": [
						0,
						173
					],
					"rxry": [
						32,
						180
					]
				},
				{
					"name": "q13.12",
					"lxly": [
						0,
						181
					],
					"rxry": [
						32,
						189
					]
				},
				{
					"name": "q13.13",
					"lxly": [
						0,
						190
					],
					"rxry": [
						32,
						211
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						212
					],
					"rxry": [
						32,
						226
					]
				},
				{
					"name": "q13.31",
					"lxly": [
						0,
						226
					],
					"rxry": [
						32,
						234
					]
				},
				{
					"name": "q13.32",
					"lxly": [
						0,
						235
					],
					"rxry": [
						32,
						240
					]
				},
				{
					"name": "q13.33",
					"lxly": [
						0,
						241
					],
					"rxry": [
						32,
						256
					]
				}
			]
		},
		{
			"name": "Chrom21ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						20
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						22
					],
					"rxry": [
						33,
						31
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						32
					],
					"rxry": [
						33,
						56
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						57
					],
					"rxry": [
						33,
						63
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						64
					],
					"rxry": [
						33,
						72
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						73
					],
					"rxry": [
						33,
						88
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						88
					],
					"rxry": [
						33,
						110
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						112
					],
					"rxry": [
						33,
						119
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						119
					],
					"rxry": [
						33,
						126
					]
				},
				{
					"name": "q22.11",
					"lxly": [
						0,
						127
					],
					"rxry": [
						33,
						144
					]
				},
				{
					"name": "q22.12",
					"lxly": [
						0,
						143
					],
					"rxry": [
						33,
						151
					]
				},
				{
					"name": "q22.13",
					"lxly": [
						0,
						152
					],
					"rxry": [
						33,
						159
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						160
					],
					"rxry": [
						33,
						170
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						171
					],
					"rxry": [
						33,
						197
					]
				}
			]
		},
		{
			"name": "Chrom22ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						20
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						22
					],
					"rxry": [
						33,
						32
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						33
					],
					"rxry": [
						33,
						53
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						54
					],
					"rxry": [
						33,
						63
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						64
					],
					"rxry": [
						33,
						69
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						70
					],
					"rxry": [
						33,
						100
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						101
					],
					"rxry": [
						33,
						104
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						107
					],
					"rxry": [
						33,
						123
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						124
					],
					"rxry": [
						33,
						129
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						130
					],
					"rxry": [
						33,
						137
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						138
					],
					"rxry": [
						33,
						149
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						148
					],
					"rxry": [
						33,
						175
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						176
					],
					"rxry": [
						33,
						181
					]
				},
				{
					"name": "q13.31",
					"lxly": [
						0,
						182
					],
					"rxry": [
						33,
						205
					]
				},
				{
					"name": "q13.32",
					"lxly": [
						0,
						206
					],
					"rxry": [
						33,
						210
					]
				},
				{
					"name": "q13.33",
					"lxly": [
						0,
						211
					],
					"rxry": [
						33,
						213
					]
				}
			]
		},
		{
			"name": "ChromXISCN09",
			"element": [{
					"name": "p22.33",
					"lxly": [
						0,
						0
					],
					"rxry": [
						32,
						13
					]
				},
				{
					"name": "p22.32",
					"lxly": [
						0,
						13
					],
					"rxry": [
						32,
						25
					]
				},
				{
					"name": "p22.31",
					"lxly": [
						0,
						25
					],
					"rxry": [
						32,
						36
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						36
					],
					"rxry": [
						32,
						46
					]
				},
				{
					"name": "p22.13",
					"lxly": [
						0,
						46
					],
					"rxry": [
						32,
						72
					]
				},
				{
					"name": "p22.12",
					"lxly": [
						0,
						72
					],
					"rxry": [
						32,
						77
					]
				},
				{
					"name": "p22.11",
					"lxly": [
						0,
						78
					],
					"rxry": [
						32,
						86
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						86
					],
					"rxry": [
						32,
						107
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						107
					],
					"rxry": [
						32,
						113
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						113
					],
					"rxry": [
						32,
						134
					]
				},
				{
					"name": "p11.4",
					"lxly": [
						0,
						134
					],
					"rxry": [
						32,
						160
					]
				},
				{
					"name": "p11.3",
					"lxly": [
						0,
						161
					],
					"rxry": [
						32,
						168
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						169
					],
					"rxry": [
						32,
						194
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						194
					],
					"rxry": [
						32,
						204
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						205
					],
					"rxry": [
						32,
						207
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						208
					],
					"rxry": [
						32,
						215
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						216
					],
					"rxry": [
						32,
						220
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						223
					],
					"rxry": [
						32,
						228
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						229
					],
					"rxry": [
						32,
						241
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						241
					],
					"rxry": [
						32,
						283
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						284
					],
					"rxry": [
						32,
						295
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						296
					],
					"rxry": [
						32,
						307
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						307
					],
					"rxry": [
						32,
						325
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						325
					],
					"rxry": [
						32,
						332
					]
				},
				{
					"name": "q21.31",
					"lxly": [
						0,
						333
					],
					"rxry": [
						32,
						351
					]
				},
				{
					"name": "q21.32",
					"lxly": [
						0,
						351
					],
					"rxry": [
						32,
						360
					]
				},
				{
					"name": "q21.33",
					"lxly": [
						0,
						360
					],
					"rxry": [
						32,
						375
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						375
					],
					"rxry": [
						32,
						412
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						413
					],
					"rxry": [
						32,
						421
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						422
					],
					"rxry": [
						32,
						445
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						446
					],
					"rxry": [
						32,
						459
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						460
					],
					"rxry": [
						32,
						480
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						480
					],
					"rxry": [
						32,
						502
					]
				},
				{
					"name": "q26.1",
					"lxly": [
						0,
						502
					],
					"rxry": [
						32,
						519
					]
				},
				{
					"name": "q26.2",
					"lxly": [
						0,
						519
					],
					"rxry": [
						32,
						524
					]
				},
				{
					"name": "q26.3",
					"lxly": [
						0,
						525
					],
					"rxry": [
						32,
						547
					]
				},
				{
					"name": "q27.1",
					"lxly": [
						0,
						547
					],
					"rxry": [
						32,
						557
					]
				},
				{
					"name": "q27.2",
					"lxly": [
						0,
						557
					],
					"rxry": [
						32,
						565
					]
				},
				{
					"name": "q27.3",
					"lxly": [
						0,
						566
					],
					"rxry": [
						32,
						580
					]
				},
				{
					"name": "q28",
					"lxly": [
						0,
						581
					],
					"rxry": [
						32,
						600
					]
				}
			]
		},
		{
			"name": "ChromYISCN09",
			"element": [{
					"name": "p11.32",
					"lxly": [
						0,
						0
					],
					"rxry": [
						33,
						9
					]
				},
				{
					"name": "p11.31",
					"lxly": [
						0,
						9
					],
					"rxry": [
						33,
						23
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						23
					],
					"rxry": [
						33,
						60
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						61
					],
					"rxry": [
						33,
						68
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						69
					],
					"rxry": [
						33,
						76
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						77
					],
					"rxry": [
						33,
						86
					]
				},
				{
					"name": "q11.221",
					"lxly": [
						0,
						86
					],
					"rxry": [
						33,
						101
					]
				},
				{
					"name": "q11.222",
					"lxly": [
						0,
						101
					],
					"rxry": [
						33,
						107
					]
				},
				{
					"name": "q11.223",
					"lxly": [
						0,
						107
					],
					"rxry": [
						33,
						122
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						122
					],
					"rxry": [
						33,
						138
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						139
					],
					"rxry": [
						33,
						209
					]
				}
			]
		}
	]
}
