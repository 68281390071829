const tooltipConfig = {
    'count' : {
        title : "计数",
        tip : ['点击染色体进行计数点']
    },
    'general' : {
        title : "万能工具",
        tip : [
            '1. Alt+单击视图空白区切换左右视图',
            '2. 按下鼠标左键移动进行视图拖放',
            '3. 左右视图中鼠标滚轮滚动进行视图缩放',
            '4. 点击W键显示或隐藏染色体边缘和计数标识',
            '5. 按下D键,Delete,Backspace或鼠标滚轮键将选中染色体删除',
            '6. 按下鼠标右键划线进行画线切割',
            '7. 按下E+鼠标左键移动鼠标使用区域擦除',
            '8. 按下Ctrl+Z键撤销上一步操作',
            '9. 按下Ctrl+Y键恢复上一步操作',
            '10. 按下Ctrl+S键保存当前操作结果',
            '11. 按下Ctrl+鼠标左键旋转鼠标旋转染色体',
            '12. “左键点击”拖动染色体',
            '13. 按下Ctrl+鼠标滚轮缩放染色体',
            '14. 按下Ctrl+Shift+鼠标左键点击，染色体镜像旋转',
            '',
            '在中期分裂相中',
            '15. 鼠标左键点中染色体按住移动到相邻染色体进行连接',
            '16. 在染色体处按下鼠标右键进行推荐切割',
            '17. 按下Shift键加上鼠标左键进行涂抹操作',
            '18. 万能工具下按下X键加上鼠标右键进行十字切割'
        ]
    },
    'same' : {
        title : "同号染色体对比",
        tip : ['在【中期分裂相】中，鼠标左键点击染色体，弹出染色体同号对比界面']
    },
    'diff' : {
        title : "双号染色体对比",
        tip : ['在【中期分裂相】中，鼠标左键点击染色体，弹出染色体双号对比界面']
    },
    'arrow' : {
        title : "箭头标注",
        tip : [
            '在【中期分裂相】或者【排列图】中，按下鼠标左键，拖动鼠标，进行标注',
            '鼠标右键点击箭头标注，删除标注',
            '鼠标右键点击箭头，删除箭头'
        ]
    },
    'select' : {
        title : "选择",
        tip : ['染色体移动']
    },
    'delete' : {
        title : "删除工具",
        tip : [
            '在【中期分裂相】或者【排列图】中，鼠标左键按下，删除命中的染色体',
            '在【中期分裂相】或者【排列图】中，鼠标左键按下，删除命中的箭头'
        ]
    },
    'eraser' : {
        title : "橡皮擦工具",
        tip : ['在【排列图】中，圈出染色体旁边的噪点杂质，完成擦除']
    },
    'area' : {
        title : "区域擦除工具",
        tip : ['在【排列图】中，圈出染色体旁边的噪点杂质，完成擦除']
    },
    'paint' : {
        title : "涂抹添加工具",
        tip : ['在【中期分裂相】中，按下鼠标左键，移动鼠标，添加一条染色体']
    },
    'connect' : {
        title : "连接工具",
        tip : ['在【中期分裂相】中，鼠标移动到连接染色体上，将染色体连接为一体']
    },
    'cutting' : {
        title : "划线切割工具",
        tip : ['在【中期分裂相】或者【排列图】中，鼠标右键按下拖动鼠标，实现划线切割']
    },
    'cross' : {
        title : "十字切割",
        tip : ['在【中期分裂相】中，按下鼠标右键，命中染色体十字交叉中点，进行十字切割']
    },
    'edge' : {
        title : "边缘切割工具",
        tip : ['在【中期分裂相】或者【排列图】中，鼠标左键按下拖动鼠标，切割命中的染色体']
    },
    'rotate' : {
        title : "染色体旋转",
        tip : ['在【排列图】中，染色体旋转']
    }
}
export default tooltipConfig