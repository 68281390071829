<template>
  <div>
    <span class="searchDivider"></span>

    <!-- 玻片、进度筛选-->
    <span class="searchCheck" :class="{'english': $store.state.language == 'en'}">
      <!--玻片筛选-->
      <div class="glFilterGlass">

        <span class="glFilterGlass_span">{{$t("metadata.Glass")}}</span>
        <!-- <span class="glFilterGlass_span_ico">：</span> -->
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="glFilterGlassCheckAll">{{$t("metadata.All_slides")}}</el-checkbox>

        <el-checkbox-group v-model="checkedCities1" @change="handleCheckedCitiesChange" class="glFilterGlassCheckList">
          <el-checkbox v-for="(item,index) in cities1" :label="item.id" :key="index" :title="item.name">{{cutOutArr(item.name)}}</el-checkbox>
        </el-checkbox-group>

        <span class="glFilterGlassCheckListSpan" @click="moreImgBtn" v-if="moreImgBtnShow"><img :src="moreImg"></span>

        <!--玻片右键弹窗-->
        <div v-show="moreCheck" class="moreCheck" id="moreImgId" @mouseleave="moreCheckLeave">
          <el-checkbox-group v-model="checkedCities2" @change="handleCheckedCitiesChange1">
            <el-checkbox v-for="(item,index) in cities2" :label="item.id" :key="index">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>

      </div>

      <!--进度筛选-->
      <div class="dtmf_progress">
        <p class="dtmf_progress_span">{{$t("metadata.progress_of")}}</p>
        <!-- <span class="glFilterGlass_span_ico">：</span> -->
        <span class="dtmf_progress_list">
          <el-radio-group v-model="radio" @change="changeHandler">
            <span class="dtmf_progress_list_span">
              <el-radio :label="-1" value="-1">{{$t("metadata.All_images")}}</el-radio>
              <span></span>
            </span>
            <span class="dtmf_progress_list_span">
              <el-radio :label="0" value="0">{{$t("metadata.No_analysis")}}</el-radio>
              <span class="dtmf_progress_list_span_fir1"></span>
            </span>
            <!--<img src="../../assets/images/analysissystem/icon_jiantou.png" class="dtmf_progress_img">-->
            <span class="dtmf_progress_list_span">
              <el-radio :label="1" value="1">{{$t("metadata.Automatic_analysis")}}</el-radio>
              <span class="dtmf_progress_list_span_fir"></span>
            </span>
            <!--<img src="../../assets/images/analysissystem/icon_jiantou.png" class="dtmf_progress_img">-->
            <span class="dtmf_progress_list_span">
              <el-radio :label="2" value="2">{{$t("metadata.Manual_counting_confirmation")}}</el-radio>
              <span class="dtmf_progress_list_span_sen"></span>
            </span>

            <!--<img src="../../assets/images/analysissystem/icon_jiantou.png" class="dtmf_progress_img">-->
            <span class="dtmf_progress_list_span">
              <el-radio :label="3" value="3">{{$t("metadata.Manual_alignment_confirmation")}}</el-radio>
              <span class="dtmf_progress_list_span_Thr"></span>
            </span>
          </el-radio-group>
        </span>

      </div>
    </span>

    <!--分割线-->
    <span class="searchDivider"></span>

    <div class="searchDividerFooter">
      <!--玻片数量，图片数量-->
      <span class="slides_img_control" :class="{'english': $store.state.language == 'en'}">
        <!--玻片数量-->
        <span class="slides_control">
          <span>{{$t("metadata.Number_of_slides")}}</span>
          <span>{{ karyotypeNumberlist.glassNum || 0 }} {{$t("metadata.a_num")}}</span>
        </span>

        <!--图片数量-->
        <span class="img_control">
          <span>{{$t("metadata.graphics")}}</span>
          <span>{{ karyotypeNumberlist.cellNum || 0 }} {{$t("metadata.zhang")}}</span>
        </span>
      </span>

      <!--分割线-->
      <span class="searchDivider"></span>

      <!--确认计数，确认排列-->
      <span class="domain_count_confirm">

        <!--确认计数-->
        <span class="domain_count">
          <span class="domain_count_span">{{$t("metadata.Confirm_the_count")}} {{karyotypeNumberlist.singleSureNum || 0}} {{$t("metadata.zhang_Num")}}</span>
          <ul class="domain_count_ul">
            <li>
              {{$t("metadata.Article_45")}}<span class="domain_count_ul_45"> {{karyotypeNumberlist.artNumMap && karyotypeNumberlist.artNumMap[45] || 0}} </span>{{$t("metadata.zhang")}}
            </li>
            <li>
              {{$t("metadata.Article_46")}}<span class="domain_count_ul_46"> {{karyotypeNumberlist.artNumMap && karyotypeNumberlist.artNumMap[46] || 0}} </span>{{$t("metadata.zhang")}}
            </li>
            <li>
              {{$t("metadata.Article_47")}}<span class="domain_count_ul_47"> {{karyotypeNumberlist.artNumMap && karyotypeNumberlist.artNumMap[47] || 0}} </span>{{$t("metadata.zhang")}}
            </li>
          </ul>
        </span>

        <!--确认排列-->
        <span class="mail_confirm">
          <span class="mail_confirm_span">{{$t("metadata.Confirm_the_arrangement")}} {{karyotypeNumberlist.typeSureNum || 0}} {{$t("metadata.zhang_Num")}}</span>
          <ul class="mail_confirm_ul">
            <li v-for="(value, key, index) in this.karyotypeNumberlist.karyotypeExpressionList" :key="index" :title="`${key}${$t('metadata.karyotype')}${value}${$t('metadata.zhang')}`">
              {{key}}{{$t("metadata.karyotype")}}{{value}}{{$t("metadata.zhang")}}
            </li>
          </ul>
        </span>
      </span>
    </div>

  </div>
</template>

<script>
import "../../assets/scss/searchLink.scss";
export default {
  name: "metadate",
  props: ["dataList"],
  data() {
    return {
      karyotypeNumberlist: {
        artNumMap: {
          45: 0,
          46: 0,
          47: 0
        }
      }, //玻片数 细胞 人工确认排序 数据回显

      checkAll: false,
      checkAll1: true,
      checkAll2: true,
      checkedCities1: [], //第一个列表病例
      checkedCities2: [], //第二个列表病例
      checkedCitiesAll: this.$store.state.glassList,//全部病例
      cities: [],
      cities1: [],
      cities2: [],
      radio: this.$store.state.status,
      moreImg: require('@/assets/images/analysissystem/icon_omit_small.png'),
      moreCheck: false,
      moreImgBtnShow: false,
    }
  },
  created() {

  },
  methods: {
    cutOutArr(obj) {
      if (obj.length > 7) {
        return obj.slice(0, 7) + '...'
      } else {
        return obj;
      }
    },
    changeHandler(value) {
      this.$store.commit("firstLoadingType", 2); //操作的时候执行搜索
      this.$store.commit("clickSlideType", true); //单选框
      this.radio = value;
      this.$store.commit("statusType", value);
    },
    //点击显示更多选择
    moreImgBtn() {
      let moreImgId = document.querySelector('#moreImgId');
      moreImgId.style.display = "block";
      moreImgId.style.left = event.clientX - 10 + 'px';
      moreImgId.style.top = event.clientY - 10 + 'px';
      if (this.moreCheck) {
        this.moreCheck = false;
      } else {
        this.moreCheck = true;
      }
    },

    moreCheckLeave() {
      this.moreCheck = false;
    },

    handleCheckAllChange(val) {
      this.$store.commit("clickGlassType", true); //单选判断

      this.checkedCitiesAll = this.$store.state.glassList;

      if (this.checkedCitiesAll.length >= 3) {
        let cities1Arr = [];
        let cities2Arr = [];
        this.checkedCitiesAll.forEach((itm, index) => {
          if (index < 3) {
            cities1Arr.push(itm);
          } else {
            cities2Arr.push(itm);
          }
        });
        this.cities1 = cities1Arr;
        this.cities1.forEach((it) => {
          this.checkedCities1.push(it.id);
        });

        this.cities2 = cities2Arr;
        this.cities2.forEach((its) => {
          this.checkedCities2.push(its.id);
        });
        this.checkedCities1 = val ? this.checkedCities1 : [];
        this.checkedCities2 = val ? this.checkedCities2 : [];

      } else {
        this.checkedCities1 = [];
        this.cities1 = this.checkedCitiesAll;
        this.cities1.forEach((ite, index) => {
          //当全选被选中的时候，循环遍历源数据，把数据的每一项加入到默认选中的数组去
          if (index < 3) {
            this.checkedCities1.push(ite.id);
          } else {
            this.checkedCities2.push(ite.id);
          }
        });
        this.checkedCities1 = val ? this.checkedCities1 : [];
      }

      this.$store.commit("glassIdsType", []);
      this.$store.commit("firstLoadingType", 2); //操作的时候执行搜索

      if (val) {
        this.$store.commit("checkAllNameType", true); //单选发选
      } else {
        this.$store.commit("checkAllNameType", false); //单选反选
      }

    },

    //单选1
    handleCheckedCitiesChange(value) {
      this.$store.commit("clickGlassType", true); //单选判断
      let checkedCount = value.length;
      if (checkedCount === this.cities1.length) {
        this.checkAll1 = true;
        if (this.checkAll2) {
          this.checkAll = true;
          this.$store.commit("checkAllNameType", true); //单选发选
        } else {
          this.checkAll = false;
          this.$store.commit("checkAllNameType", false); //单选发选
        }
      } else {
        this.checkAll1 = false;
        this.checkAll = false;
        this.$store.commit("checkAllNameType", false); //单选发选
      }

      let checkedCitiesNum = this.checkedCities1.concat(this.checkedCities2);
      this.$store.commit("glassIdsType", checkedCitiesNum);
      this.$store.commit("firstLoadingType", 2); //操作的时候执行搜索*
      localStorage.setItem('checkedCities1', JSON.stringify(value));
      localStorage.setItem('checkedCities2', JSON.stringify(this.checkedCities2));
    },

    //单选2
    handleCheckedCitiesChange1(value) {
      this.$store.commit("clickGlassType", true); //单选判断
      let checkedCount1 = value.length;
      if (checkedCount1 === this.cities2.length) {
        this.checkAll2 = true;
        if (this.checkAll1) {
          this.checkAll = true;
          this.$store.commit("checkAllNameType", true); //单选发选
        } else {
          this.checkAll = false;
          this.$store.commit("checkAllNameType", false); //单选发选
        }
      } else {
        this.checkAll2 = false;
        this.checkAll = false;
        this.$store.commit("checkAllNameType", false); //单选发选
      }
      let checkedCitiesNum = this.checkedCities1.concat(this.checkedCities2)
      this.$store.commit("glassIdsType", checkedCitiesNum);
      this.$store.commit("firstLoadingType", 2); //操作的时候执行搜索*
      localStorage.setItem('checkedCities2', JSON.stringify(value));
      localStorage.setItem('checkedCities1', JSON.stringify(this.checkedCities1));
    }
  },

  watch: {

    '$store.state.status': function () {
      this.radio = this.$store.state.status;
    },
    //监听玻片数 上面的内容回显
    "$store.state.karyotypeNumberlist": function () {
      this.karyotypeNumberlist = this.$store.state.karyotypeNumberlist;

    },
    //监听病例数
    "$store.state.glassList": function () {
      this.checkedCitiesAll = this.$store.state.glassList;
      this.checkedCities1 = [];
      this.checkedCities2 = [];
      if (this.checkedCitiesAll.length > 3) {
        let cities1Arr = [];
        let cities2Arr = [];
        this.checkedCitiesAll.forEach((itm, index) => {
          if (index < 3) {
            cities1Arr.push(itm);
          } else {
            cities2Arr.push(itm);
          }
        });
        this.cities1 = cities1Arr;
        this.cities1.forEach((it) => {
          this.checkedCities1.push(it.id);
        });

        this.cities2 = cities2Arr;
        this.cities2.forEach((its) => {
          this.checkedCities2.push(its.id);
        });

        localStorage.setItem('checkedCities1', JSON.stringify(this.checkedCities1));
        localStorage.setItem('checkedCities2', JSON.stringify(this.checkedCities2));
        this.moreImgBtnShow = true;
        this.checkAll = true;

      } else {
        this.cities1 = this.checkedCitiesAll;

        this.cities1.forEach((item) => {
          //当全选被选中的时候，循环遍历源数据，把数据的每一项加入到默认选中的数组去
          this.checkedCities1.push(item.id);
        });
        localStorage.setItem('checkedCities1', JSON.stringify(this.checkedCities1));
        localStorage.setItem('checkedCities2', JSON.stringify(this.checkedCities2));
        this.moreImgBtnShow = false;
        this.checkAll = true;
      }
    },
  }
}
</script>

<style scoped>
.searchDividerFooter {
  width: calc(100% - 700px);
  height: 50px;
  float: left;
  overflow-y: hidden;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 0px;
}
/*滚动条样式*/
/*滚动条样式*/
.searchDividerFooter::-webkit-scrollbar {
  width: 1px;
  height: 4px;
}
.searchDividerFooter::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.searchDividerFooter::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>