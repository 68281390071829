<template>
	<div class="complexityData">
		<el-collapse accordion>
			<el-collapse-item v-for="(item, index) in complexityData" :key="index" :title="item.path" :name="index" v-if="item.list.length">
				<el-table :data="item.list" style="width: 100%">
					<el-table-column prop="type" label="类型" align="center"></el-table-column>
					<el-table-column prop="name" label="方法名" width="200" align="center"></el-table-column>
					<el-table-column prop="complexity" label="圈复杂度" align="center" sortable sort-by="complexity">
					</el-table-column>
					<el-table-column label="代码位置" align="center">
						<template slot-scope="scope">
							<span style="color: #FF2A2D;">{{scope.row.line}}</span>行，<span style="color: #FF2A2D;">{{scope.row.column}}</span>列
						</template>
					</el-table-column>
					<el-table-column prop="lines" label="代码大小(行)" width="140" align="center" sortable sort-by="lines"></el-table-column>
					<el-table-column label="代码状态" align="center">
						<template slot-scope="scope">
							<span style="color: #52C41A;" v-if="scope.row.complexity < 10">无需重构</span>
							<span style="color: #FAAD14;" v-if="scope.row.complexity >= 10 && scope.row.complexity <= 15">建议重构</span>
							<span style="color: #E72323;" v-if="scope.row.complexity > 15">强烈建议重构</span>
						</template>
					</el-table-column>
				</el-table>
			</el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
import complexityData from '../assets/js/complexity.js'
export default {
	data() {
		return {
			complexityData : complexityData
		}
	},
	created() {
		this.complexityData.forEach(item=>{
			item.list.forEach(a=>{
				a.lines = a.endLine - a.line;
			})
		})
	}
}
</script>

<style lang="scss" scoped>
.complexityData{
	width: 800px;
	margin: 0 auto;
}	
.filePath{
	height: 30px;
	line-height: 30px;
	font-size: 22px;
	font-weight: bold;
}
</style>
