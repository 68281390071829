function timeFormat(t) {
	return new Date(t).toISOString().replace(/T/g, ' ').replace(/\.\d+Z/g, '');
}

function sizeFormat(size) {
	let kb = parseInt(size / 1024),
		mb = Number(size / 1024 / 1024).toFixed(1),
		gb = Number(size / 1024 / 1024 / 1024).toFixed(1);
	const base = 1000 / 1024;
	return gb >= base ? (gb + 'G') : (mb >= base ? (mb + "M") : (kb + 'Kb'));
}

function FilesSelector({
	filelist,
	type,
	handles
}) {
	this.filelist = Array.prototype.slice.call(filelist);
	this.type = type || 'importFolder';
	this.handles = Object.assign({
		before: new Function(),
		done: new Function()
	}, handles)
	this.SLEEP = 2000;
	this.STEP = 10000;
	this.INDEX = 0;
	this.filesMap = {};
	this.cases = [];
	this.caseNames = [];
	this.handles.before();
	if (this.filelist.length) {
		this.start();
	} else {
		this.handles.done(this.filelist.length);
	}
	return this;
}
FilesSelector.prototype = {
	/**
	 * 分批次筛选文件
	 */
	start: function() {
		const list = this.filelist.slice(this.INDEX * this.STEP, (this.INDEX + 1) * this.STEP);
		this.filterFiles(list)
		if ((this.INDEX + 1) * this.STEP < this.filelist.length) {
			setTimeout(() => {
				this.INDEX++;
				this.start();
			}, this.SLEEP)
		} else {
			setTimeout(() => {
				this.createMap();
			}, this.SLEEP)
		}
	},

	/**
	 * 根据规则筛选文件
	 * @param files
	 */
	filterFiles(files) {
		//符合病例规则的图片格式
		let formats = ['bmp', 'jpg', 'tif', 'tiff'];
		//符合普通文件夹规则的图片格式
		let formats1 = ['bmp', 'png', 'jpg', 'jpe', 'jpeg', 'tif', 'tiff', 'raw', 'aipng', 'metasight'];
		for (let i = 0; i < files.length; i++) {
			let file = files[i],
				relativePath = file.webkitRelativePath,
				name = file.name.replace(/\.[0-9a-zA-Z]+$/, ''),
				format = file.name.split('.').pop().toLowerCase();
			if (relativePath && formats1.indexOf(format) > -1) {
				let path = relativePath.split("/");
				path = path.slice(0, path.length - 1);
				let _case = {
					name: name,
					format: format,
					path: path.join('/'),
					index: this.INDEX * this.STEP + i,
					lastModified: file.lastModified,
					size: file.size,
					status : 0,// 0=等待上传 1=正在上传 2=上传成功 3=上传失败
					loaded : 0
				}
				let gsc = name.split(".");
				if(gsc.length >= 3){
					let arr = [];
					arr.push(gsc.shift());
					arr.push(gsc.shift());
					arr.push(gsc.join("."));
					gsc = arr;
				}
				//导入文件夹
				if (this.type === 'importFolder' && path.length === 1 && formats1.indexOf(format) > -1) {
					this.cases.push(Object.assign(_case, {
						patient: path[0],
						slide: path[0],
						cell: name
					}))
				}
				//导入病例文件夹
				var bool = path.length === 3 && (format === 'aipng' || format === 'raw' || format === 'metasight');
				if ((this.type === 'importCasesFolder' && bool)) {
					let case1 = Object.assign(_case, {
						patient: path[0],
						slide: path[1],
						cell: path[2]
					})
					if (format === 'aipng') {
						let na = name.split('_').pop();
						let raw = this.cases.filter(item => {
							return item.path === path.join('/') && item.format === 'raw';
						})[0]
						if (!raw && na === 'met') {
							this.cases.push(case1);
						}
					} else if (format === 'raw') {
						let isSame = this.cases.some(item => {
							return item.path === path.join('/') && item.format === 'raw'
						})
						if (!isSame) {
							let index = -1;
							this.cases.forEach((item, j) => {
								if (item.path === path.join('/') && item.format === 'aipng') {
									index = j;
								}
							})
							if (index > -1) {
								this.cases.splice(index, 1);
							}
							this.cases.push(case1);
						}
					} else if (format === 'metasight' && name === 'image_target') {
						this.cases.push(case1)
					}
				}
				if (path.length === 1 && formats.indexOf(format) > -1 && gsc.length === 3 && this.type === 'importCasesFolder') {
					this.cases.push(Object.assign(_case, {
						patient: gsc[0],
						slide: gsc[1],
						cell: gsc[2]
					}))
				}
				//导入病例库
				if (this.type === 'importCasesLibrary') {
					if (path.length === 2 && formats.indexOf(format) > -1 && gsc.length === 3) {
						// console.warn('病例库2层',path,gsc)
						this.cases.push(Object.assign(_case, {
							patient: path[1],
							slide: gsc[1],
							cell: gsc[2]
						}))
					}
					if (path.length === 1 && format==='bmp' && gsc.length === 3) {
						// console.warn('病例库2层',path,gsc)
						this.cases.push(Object.assign(_case, {
							patient: gsc[0],
							slide: gsc[1],
							cell: gsc[2]
						}))
					}
					if (path.length === 4 && (format === 'aipng' || format === 'raw' || format === 'metasight')) {
						// console.warn('病例库4层',path)
						let case2 = Object.assign(_case, {
							patient: path[1],
							slide: path[2],
							cell: path[3]
						})
						if (format === 'aipng') {
							let na = name.split('_').pop();
							let raw = this.cases.filter(item => {
								return item.path === path.join('/') && item.format === 'raw';
							})[0]
							if (!raw && na === 'met') {
								this.cases.push(case2);
							}
						} else if (format === 'raw') {
							let isSame = this.cases.some(item => {
								return item.path === path.join('/') && item.format === 'raw'
							})
							if (!isSame) {
								let index = -1;
								this.cases.forEach((item, j) => {
									if (item.path === path.join('/') && item.format === 'aipng') {
										index = j;
									}
								})
								if (index > -1) {
									this.cases.splice(index, 1);
								}
								this.cases.push(case2);
							}
						} else if (format === 'metasight' && name === 'image_target') {
							this.cases.push(case2)
						}
					}
				}
			}
		}
		console.log(this.INDEX);
	},

	/**
	 * 根据病例名称 建立映射关系
	 */
	createMap() {
		let map = {};
		this.cases.forEach(item => {
			let patient = item.patient;
			if (map[patient]) {
				map[patient].list.push(item);
				map[patient].recentTime = Math.max(map[patient].recentTime, item.lastModified);
				map[patient].recentTimeFormat = timeFormat(map[patient].recentTime);
			} else {
				map[patient] = {
					patient: patient,
					list: [item],
					recentTime: item.lastModified,
					recentTimeFormat: timeFormat(item.lastModified),
					totalSize: item.size
				}
			}
		})
		Object.keys(map).forEach(key => {
			const guid = this.guid();
			map[key].totalSize = map[key].list.reduce((a, b) => {
				b.guid = guid;
				return a + b.size;
			}, 0);
			map[key].totalSizeFormat = sizeFormat(map[key].totalSize);
			map[key].guid = guid;
		})
		this.filesMap = Object.keys(map).map(key => {
			return Object.assign({
				patient: key,
				percent: 0,
				status: 0, // 0=等待上传 1=正在上传 2=上传成功 3=上传失败
			}, map[key])
		})

		this.caseNames = Object.keys(map);
		// console.log(this.filesMap);
		this.handles.done(this.cases.length);
	},
	guid() {
		return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random() * 16 | 0,
				v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	},
	/**
	 * 根据起始时间筛选病例
	 * @param {Object} startTime
	 */
	filterByTimeInterval(startTime, endTime) {
		const maxEndTime = new Date().getTime() - 1800000;
		startTime = startTime ? new Date(startTime).getTime() : 0;
		endTime = endTime ? new Date(endTime).getTime() : (new Date().getTime() - 1800000);
		endTime = Math.min(maxEndTime, endTime);
		Object.keys(this.filesMap).forEach(key => {
			this.filesMap[key].valid = (this.filesMap[key].recentTime >= startTime && this.filesMap[key].recentTime <=
				endTime);
		})
	}

}
export default FilesSelector
