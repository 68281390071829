<template>
    <div></div>
</template>

<script>
export default {
    name: "MessageBox",
    data() {
        return {
            newStatus: false
        };
    },
    props: ["updateType", "updateTips", "openStatus"],
    methods: {
        open() {
            this.$confirm(this.updateTips, this.$t('popup.warning.remind'), {
                confirmButtonText: this.$t('btns.confirm'),
                cancelButtonText: this.$t('btns.cancel'),
                type: "warning",
                closeOnClickModal: false
            })
                .then(() => {
                    this.$emit("childFun", this.updateType);
                })
                .catch(() => {
                    this.$emit("childFun", this.newStatus);
                });
        }
    },
    watch: {
        openStatus() {
            if (this.openStatus) {
                this.open();
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
