//事件集合
let eventsHandle = {};
//默认条件
const defaultCondition = {
	ctrlKey: false,
	shiftKey: false,
	altKey: false
}
class ShortKey {
	constructor(target) {
		this.disabled = false;
		this.init();
		this.bindAPP();
	}
	
	bindAPP(target){
		this.app = target || this;
	}
	
	/**
	 * 初始化-绑定事件
	 */
	init(){
		//白名单中的按键不禁止触发默认事件
		const whiteList = ['F5','F12','NumpadEnter'];
		document.addEventListener('keydown',(e)=>{
			// console.log(e);
			// console.warn("this.app.shortKeyType",this.app.shortKeyType);
			if(whiteList.indexOf(e.key) < 0 && !this.disabled){
				e.preventDefault();
				if(e.code){
					this.app.shortKeyType = e.code.replace(/^(Digit|Key|Numpad)/g,'').toLowerCase();
				}
				this.emit('keydown',e)
			}
		})
		document.addEventListener('keyup',(e)=>{
			if(this.disabled){
				return
			}
			e.preventDefault();
			this.emit('keyup',e)
			this.emit('keypress',e)
			this.resetHandles();
		})
		document.addEventListener('keypress',(e)=>{
			if(whiteList.indexOf(e.key) < 0 && !this.disabled){
				e.preventDefault();
				this.emit('keypress',e)
			}
		})
	}
	
	/**
	 * 添加快捷键绑定
	 * @param type
	 * @param condition
	 * @param handle
	 */
	on(type = 'keydown',condition = defaultCondition,handle = new Function(),loop = false){
		const args = Array.prototype.slice.call(arguments);
		type = 'keydown';
		condition = defaultCondition;
		args.forEach(arg=>{
			if(typeof arg === 'string'){
				type = arg;
			}
			if(typeof arg === 'object'){
				condition = Object.assign({},defaultCondition,arg);
			}
			if(typeof arg === 'function'){
				handle = arg;
			}
			if(typeof arg === 'number' || typeof arg === 'boolean'){
				loop = arg;
			}
		})
		if(!condition.key){
			console.error('触发条件必须传入key字段')
			return
		}
		!eventsHandle[type] && (eventsHandle[type] = []);
		eventsHandle[type].push({
			condition : condition,
			handle : handle,
			loop : loop,
			disabled : false
		})
		// console.log(eventsHandle);
	}
	
	/**
	 * 触发事件-调用对应回调
	 * @param type
	 * @param e
	 */
	emit(type, e){
		const condition = {
			altKey: e.altKey,
			ctrlKey: e.ctrlKey,
			shiftKey: e.shiftKey,
			key : e.code ? e.code.replace(/^(Digit|Key|Numpad)/g,'').toLowerCase() : ''
		}
		if(eventsHandle[type]){
			eventsHandle[type].forEach(item=>{
				const _condition = {
					key : item.condition.key,
					altKey : item.condition.altKey,
					ctrlKey : item.condition.ctrlKey,
					shiftKey : item.condition.shiftKey
				}
				const status = item.condition.getStatus ? item.condition.getStatus(e) : true;
				// !item.disabled && isSameCondition(condition, _condition) && status && item.handle(e);
				if(!item.disabled && isSameCondition(condition, _condition) && status){
					item.handle(e);
				}
				if(Number(item.loop) === 1 && !item.disabled){
					item.disabled = true;
				}
			})
		}
	}
	
	/**
	 * 重置回调disabled
	 */
	resetHandles(){
		Object.keys(eventsHandle).forEach(type=>{
			eventsHandle[type].forEach(item=>{
				item.disabled = false
			})
		})
		this.app.shortKeyType = ''
	}

	/**
	 * 清空绑定事件
	 */
	clear(){
		eventsHandle = {};
	}
}
/**
 * 判断条件是否相等
 * @param {*} condition1 
 * @param {*} condition2 
 */
export function isSameCondition(condition1 ,condition2 = defaultCondition){
	const bool = Object.keys(condition1).every(key=>{
		return condition1[key] === condition2[key];
	})
	return bool
}
export default new ShortKey()