import { instance as http } from "./http.js";
import Common from "./common.js";
// import { Message } from "element-ui";
// import Vue from "vue";
const API = {
    //
    stsToken(param) {
        return this.apiPackage(
            {
                url: "/oss/stsToken",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },
    //登录接口
    register(param) {
        return this.apiPackage(
            {
                url: "/login/register",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },
    //登录接口
    login(param) {
        return this.apiPackage(
            {
                url: "/login/login",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },
    //权限列表
    queryList(param) {
        return this.apiPackage(
            {
                url: "/power/queryList",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },
    queryOss(param) {
        return this.apiPackage(
            {
                url: "/patient/queryOss",
                needAuth: true
            },
            "post",
            param
        );
    },
    queryOssLocal(param) {
        return this.apiPackage(
            {
                url: "/patient/queryOssLocal",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 获取验证码
    sms(param) {
        return this.apiPackage(
            {
                url: "/login/sms",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },
    // 修改手机号、邮箱
    updatePhoneEmail(param) {
        return this.apiPackage(
            {
                url: "/user/updatePhoneEmail",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 修改密码
    updatePwd(param) {
        return this.apiPackage(
            {
                url: "/user/updatePwd",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 获取工作设置信息
    getWorkConfig(param) {
        return this.apiPackage(
            {
                url: "/user/getWorkConfig",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 更新工作设置信息
    updateWorkConfig(param) {
        return this.apiPackage(
            {
                url: "/user/updateWorkConfig",
                needAuth: true
            },
            "post",
            param
        );
    },
    //获取角色列表
    listById(param) {
        return this.apiPackage(
            {
                url: "/role/listById",
                needAuth: true
            },
            "post",
            param
        );
    },
    //成员列表
    memberList(param) {
        return this.apiPackage(
            {
                url: "/userMechanism/memberList",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },
    //修改成员颜色
    updateMemberColor(param) {
        return this.apiPackage(
            {
                url: "/userMechanism/updateColor",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },
    //修改成员角色
    updateRole(param) {
        return this.apiPackage(
            {
                url: "/userMechanism/updateRole",
                needAuth: true
            },
            "post",
            param
        );
    },
    //移除成员
    memberRemove(param) {
        return this.apiPackage(
            {
                url: "/userMechanism/remove",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },
    //复制邀请链接
    copyUrl(param) {
        return this.apiPackage(
            {
                url: "/userMechanism/copyUrl",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },
    //邀请绑定
    invitation(param) {
        return this.apiPackage(
            {
                url: "/userMechanism/invitation",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },
    checkInvitationCode(param){
        return this.apiPackage(
            {
                url: "/userMechanism/checkInvitationCode",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },

    // 获取账户余额
    queryByToken(param) {
        return this.apiPackage(
            {
                url: "/billtype/queryByToken",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 获取账户列表
    homeList(param) {
        return this.apiPackage(
            {
                url: "/userAccount/homeList",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 用户添加管理账户
    addUserAccount(param) {
        return this.apiPackage(
            {
                url: "/userAccount/add",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 收支明细列表
    paymentSummaryList(param) {
        return this.apiPackage(
            {
                url: "/paymentSummary/list",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 支付宝支付二维码
    aliPaymentFace(param) {
        return this.apiPackage(
            {
                url: "/ali/paymentFace",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 支付宝支付链接
    aliPayment(param) {
        return this.apiPackage(
            {
                url: "/ali/payment",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 微信支付二维码
    weixinPayment(param) {
        return this.apiPackage(
            {
                url: "/weixin/payment",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 用户申请提现
    withdrawalEdit(param) {
        return this.apiPackage(
            {
                url: "/withdrawal/edit",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 用户申请认证为机构、或者专家
    userAuth(param) {
        return this.apiPackage(
            {
                url: "/user/auth",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 完善个人信息
    userPerfectUser(param) {
        return this.apiPackage(
            {
                url: "/user/perfectUser",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 获取个人身份信息
    getQueryUserByToken(param) {
        return this.apiPackage(
            {
                url: "/user/queryUserByToken",
                needAuth: true
            },
            "post",
            param
        );
    },

    //高级搜索
    queryPatientBySeach(param) {
        return this.apiPackage(
            {
                url: "/patient/queryPatientBySeach",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 获取病例列表
    patientPListLabor(param) {
        return this.apiPackage(
            {
                url: "/patient/pListLabor",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 分配病例，获取用户信息
    userAnalysis(param) {
        return this.apiPackage(
            {
                url: "/user/analysis",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 分配病例，分配给用户
    patientDisPatient(param) {
        return this.apiPackage(
            {
                url: "/patient/disPatient",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 病例转交
    transPatient(param) {
        return this.apiPackage(
            {
                url: "/patient/transPatient",
                needAuth: true
            },
            "post",
            param
        );
    },
    //检查病例是否存在
    checkPatient(param) {
        return this.apiPackage(
            {
                url: "/patient/checkPatient",
                needAuth: false
            },
            "post",
            param
        );
    },
    // 上传local病例
    uploadLocalPatient(param) {
        return this.apiPackage(
            {
                url: "/patient/uploadLocalPatient",
                needAuth: true
            },
            "post",
            param
        );
    },
    //查询后端local病例上传处理进度
    querySegmentLocal(param) {
        return this.apiPackage(
            {
                url: "/patient/querySegmentLocal",
                needAuth: true
            },
            "post",
            param
        );
    },

    // 添加报告
    reportEdit(param) {
        return this.apiPackage(
            {
                url: "/report/edit",
                needAuth: true
            },
            "post",
            param
        );
    },

    //删除病例图片
    patientDelete(param) {
        return this.apiPackage(
            {
                url: "/patient/delete",
                needAuth: true
            },
            "post",
            param
        );
    },
    //列表
    queryKaryoList(param) {
        return this.apiPackage(
            {
                url: "/patient/queryKaryoList",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },

    //可分析病例分析
    autoSegment(param) {
        return this.apiPackage(
            {
                url: "/karyo/autoSegment",
                needAuth: true
            },
            "post",
            param
        );
    },

    //检测病例分析
    queryKaryoListByPatient(param) {
        return this.apiPackage(
            {
                url: "/patient/queryKaryoListByPatient",
                needAuth: true
            },
            "post",
            param
        );
    },

    //返回的数据/karyo/queryBuKaryoIds
    queryBuKaryoIds(param) {
        return this.apiPackage(
            {
                url: "/karyo/queryStatisByKaryoIds",
                needAuth: true
            },
            "post",
            param
        );
    },

    // 获取核型图选择列表
    patientQueryBySingleType(param) {
        return this.apiPackage(
            {
                url: "/patient/queryBySingleType",
                needAuth: true
            },
            "post",
            param
        );
    },

    // 获取报告列表
    reportListByPatientId(param) {
        return this.apiPackage(
            {
                url: "/report/listByPatientId",
                needAuth: true
            },
            "post",
            param
        );
    },

    // 删除报告
    reportDel(param) {
        return this.apiPackage(
            {
                url: "/report/del",
                needAuth: true
            },
            "post",
            param
        );
    },

    // 获取报告详情
    reportQuery(param) {
        return this.apiPackage(
            {
                url: "/report/query",
                needAuth: true
            },
            "post",
            param
        );
    },

    // 根据病例获取专家会诊意见
    patientQueryExpertByPatient(param) {
        return this.apiPackage(
            {
                url: "/patient/queryExpertByPatient",
                needAuth: true
            },
            "post",
            param
        );
    },

    // 机构发起专家会诊
    patientExpertDiagnosis(param) {
        return this.apiPackage(
            {
                url: "/patient/expertDiagnosis",
                needAuth: true
            },
            "post",
            param
        );
    },

    // 专家提交会诊意见
    patientExpertOpinion(param) {
        return this.apiPackage(
            {
                url: "/patient/expertOpinion",
                needAuth: true
            },
            "post",
            param
        );
    },

    // 官方机构审核
    patientOfficialExamine(param) {
        return this.apiPackage(
            {
                url: "/patient/officialExamine",
                needAuth: true
            },
            "post",
            param
        );
    },
    // 用户查询最后一个病例的上传时间
    patientQueryEndTime(param) {
        return this.apiPackage(
            {
                url: "/patient/queryEndTime",
                needAuth: false
            },
            "post",
            param
        );
    },
    //病例上传到oss
    patientOss(param) {
        return this.apiPackage(
            {
                url: "/patient/oss",
                needAuth: true
            },
            "post",
            param
        );
    },
    //病例状态更新
    updateStatus(param) {
        return this.apiPackage(
            {
                url: "/patient/updateStatus",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },

    //病例审核
    patientReviewer(param) {
        return this.apiPackage(
            {
                url: "/patient/reviewer",
                needAuth: true
            },
            "post",
            param
        );
    },
    //
    downloadPatient(param) {
        return this.apiPackage(
            {
                url: "/patient/downloadPatient",
                needAuth: true
            },
            "post",
            param
        );
    },
    querySegmentOnline(param) {
        return this.apiPackage(
            {
                url: "/patient/querySegmentOnline",
                needAuth: true
            },
            "post",
            param
        );
    },

    //删除查询 hasHandle
    hasHandle(param) {
        return this.apiPackage(
            {
                url: "/patient/hasHandle",
                needAuth: true
            },
            "post",
            param
        );
    },

    //查询分析进度
    querySegment(param) {
        return this.apiPackage(
            {
                url: "/patient/querySegment",
                needAuth: true
            },
            "post",
            param
        );
    },

    //判断是否可以进入分析界面
    patientCheckRandom(param) {
        return this.apiPackage(
            {
                url: "/patient/checkRandom",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },
    //获取染色体对比的列表
    withNumber(param) {
        return this.apiPackage(
            {
                url: "/karyo/withNumber",
                needAuth: true
            },
            "post",
            param
        );
    },

    //是否显示病例分配按钮
    userPatientDistribution(param) {
        return this.apiPackage(
            {
                url: "/user/patientDistribution",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },

    //确认计数
    confirmCount(param) {
        return this.apiPackage(
            {
                url: "/karyo/confirmCount",
                needAuth: true
            },
            "post",
            param
        );
    },
    //确认排列
    confirmArrangement(param) {
        return this.apiPackage(
            {
                url: "/karyo/confirmArrangement",
                needAuth: true
            },
            "post",
            param
        );
    },
    //核型图保存
    preservation(param) {
        return this.apiPackage(
            {
                url: "/karyo/preservation",
                needAuth: true
            },
            "post",
            param
        );
    },
    //更新核型图与排列图快照
    updateKaryoArrayPic(param) {
        return this.apiPackage(
            {
                url: "/karyo/updateKaryoArrayPic",
                needAuth: true
            },
            "post",
            param
        );
    },
    //自动排列染色体
    chromoRecog(param) {
        return this.apiPackage(
            {
                url: "/karyo/chromoRecog",
                needAuth: true
            },
            "post",
            param
        );
    },

    //退出登录
    loginOut(param) {
        return this.apiPackage(
            {
                url: "/login/out",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },

    //心跳
    heartbeat(param) {
        return this.apiPackage(
            {
                url: "/patient/heartbeat",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },
    //删除病例锁
    deleteLock(param) {
        return this.apiPackage(
            {
                url: "/patient/deleteLock",
                needAuth: true
            },
            "post",
            param
        );
    },

    //验证token是否过期
    checkToken(param) {
        return this.apiPackage(
            {
                url: "/user/checkToken",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },

    //自定义模板
    //获取自定义属性数据
    queryFiled(param) {
        return this.apiPackage(
            {
                url: "/reportTemplate/queryFiled",
                needAuth: true
            },
            "post",
            param
        );
    },
    //添加修改模板
    editTemplate(param) {
        return this.apiPackage(
            {
                url: "/reportTemplate/edit",
                needAuth: true
            },
            "post",
            param
        );
    },
    //用户获取报告模板
    templateList(param) {
        return this.apiPackage(
            {
                url: "/reportTemplate/list",
                needAuth: true
            },
            "post",
            param
        );
    },

    //用户删除报告模板
    deleteTemplate(param) {
        return this.apiPackage(
            {
                url: "/reportTemplate/delete",
                needAuth: true
            },
            "post",
            param
        );
    },

    //用户查询模板信息
    templateQueryById(param) {
        return this.apiPackage(
            {
                url: "/reportTemplate/queryById",
                needAuth: true
            },
            "post",
            param
        );
    },
    //推送消息
    queryMsg(param) {
        return this.apiPackage(
            {
                url: "/notice/queryMsg",
                needAuth: false,
                permission: true
            },
            "post",
            param
        );
    },
    //图像优化列表
    optimizeList(param) {
        return this.apiPackage(
            {
                url: "/imageOptimization/list",
                needAuth: true
            },
            "post",
            param
        );
    },
    //保存或（保存并使用）图像优化参数设置
    optimizePressvation(param) {
        return this.apiPackage(
            {
                url: "/imageOptimization/preservation",
                needAuth: true
            },
            "post",
            param
        );
    },
    //重置：根据id查询数据，根据type初始化参数设置
    optimizeReset(param) {
        return this.apiPackage(
            {
                url: "/imageOptimization/reset",
                needAuth: true
            },
            "post",
            param
        );
    },
    //切换模式
    optimizeQueryById(param) {
        return this.apiPackage(
            {
                url: "/imageOptimization/queryById",
                needAuth: true
            },
            "post",
            param
        );
    },
    optimizeUpload(param) {
        return this.apiPackage(
            {
                url: "/imageOptimization/upload",
                needAuth: true
            },
            "post",
            param
        );
    },
    uploadSegment(param) {
        return this.apiPackage(
            {
                url: "/imageOptimization/uploadSegment",
                needAuth: true
            },
            "post",
            param
        );
    },

    //颜色选择
    updateColor(param) {
        return this.apiPackage(
            {
                url: "/karyo/updateColor",
                needAuth: true
            },
            "post",
            param
        );
    },

    //置顶
    updateIsTop(param) {
        return this.apiPackage(
            {
                url: "/karyo/updateIsTop",
                needAuth: true
            },
            "post",
            param
        );
    },

    //获取分析设置信息
    queryAnalysis(param) {
        return this.apiPackage(
            {
                url: "/karyo/queryAnalysis",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },
    //分析设置
    analysisSetUp(param) {
        return this.apiPackage(
            {
                url: "/karyo/analysisSetUp",
                needAuth: true
            },
            "post",
            param
        );
    },
    //染色体放大缩小设置
    analysisChromo(param) {
        return this.apiPackage(
            {
                url: "/karyo/analysisChromo",
                needAuth: true
            },
            "post",
            param
        );
    },
    //染色体放大缩小重置
    karyoReset(param) {
        return this.apiPackage(
            {
                url: "/karyo/reset",
                needAuth: true
            },
            "post",
            param
        );
    },

    //批量删除
    delete(param) {
        return this.apiPackage(
            {
                url: "/karyo/delete",
                needAuth: true
            },
            "post",
            param
        );
    },

    //病例信息 patient/patientInfo
    patientInfo(param) {
        return this.apiPackage(
            {
                url: "/patient/patientInfo",
                needAuth: true,
            },
            "post",
            param
        );
    },

    //修改病例信息patient/updatePatientInfo
    updatePatientInfo(param) {
        return this.apiPackage(
            {
                url: "/patient/updatePatientInfo",
                needAuth: true
            },
            "post",
            param
        );
    },
    //取消确认计数+排列
    cancelArrange(param) {
        return this.apiPackage(
            {
                url: "/karyo/cancel",
                needAuth: true
            },
            "post",
            param
        );
    },
    //获取机构的转入机构列表
    getTransInOrgList(param) {
        return this.apiPackage(
            {
                url: "/patient/getTransInOrgList",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },

    //获取病例转交、分配查询信息
    getDisPatientUserList(param) {
        return this.apiPackage(
            {
                url: "/user/getDisPatientUserList",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },
    //修改头像
    modifyHeadUrl(param) {
        return this.apiPackage(
            {
                url: "/user/head",
                needAuth: true
                // permission: true
            },
            "post",
            param
        );
    },
    //机构信息
    mechanismQuery(param) {
        return this.apiPackage(
            {
                url: "/user/mechanismQuery",
                needAuth: true
            },
            "post",
            param
        );
    },
    //修改机构名称
    updateAccountName(param) {
        return this.apiPackage(
            {
                url: "/user/updateAccountName",
                needAuth: true
            },
            "post",
            param
        );
    },
    //收益记录
    profit(param) {
        return this.apiPackage(
            {
                url: "/paymentSummary/profit",
                needAuth: true
            },
            "post",
            param
        );
    },
    //消息列表
    emailList(param) {
        return this.apiPackage(
            {
                url: "/email/list",
                needAuth: true
            },
            "post",
            param
        );
    },
    //修改成员昵称
    updateNickName(param) {
        return this.apiPackage(
            {
                url: "/userMechanism/updateNickName",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },
    //发起专家会诊，获取合作的专家信息
    expertList(param) {
        return this.apiPackage(
            {
                url: "/mechanismExpert/expertList",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },

    //追加分析之后判断病例的是不是分数
    karyoAutoSegmen(param) {
        return this.apiPackage(
            {
                url: "/karyo/checkKaryoScore",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },

    //获取追加分析核型图个数和单病例核型图上限
    queryKaryoNum(param){
        return this.apiPackage(
            {
                url: "/patient/queryKaryoNum",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },


    //获取追加分析核型图个数和单病例核型图上限
    paymentQuery(param){
        return this.apiPackage(
            {
                url: "/payment/query",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },

     //添加前台页面，根据各个账户消息汇总接口
     discountSummary(param){
        return this.apiPackage(
            {
                url: "/discount/summary",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },
    //提交专家会诊
    expertDiagnosis(param){
        return this.apiPackage(
            {
                url: "/patient/expertDiagnosis",
                needAuth: true,
                permission: true
            },
            "post",
            param
        );
    },

	queryMechanismName(param){
		return this.apiPackage(
		    {
		        url: "/userMechanism/queryMechanismName",
		        needAuth: true,
		        permission: true
		    },
		    "post",
		    param
		);
	},
	
    apiPackage(api, type = "get", param) {
        if (Common.permissions(api.url) || api.permission) {
            (api.url.indexOf("login") < 0 || api.url == "/login/out") &&
                (param = this.checkParam(param, api.needAuth));
            /*console.log(api, type, param);*/
            if (type == "get") {
                return http.get(api.url, {
                    params: param
                });
            } else {
                return http.post(api.url, param);
            }
        } else {
            console.log(api);
            return new Promise((resolve) => {
                resolve({
                    code: 401,
                    message: "没有权限"
                });
            });
        }
    },
    checkParam(data = {}, needAuth) {
        let myToken;
        if (localStorage.getItem("loginObj")) {
            myToken = JSON.parse(localStorage.getItem("loginObj")).token;
        } else {
            myToken = localStorage.getItem("loginObj");
        }
        data.token = data.token || myToken;

        let labValue = localStorage.getItem("currentLabValue")
            ? JSON.parse(localStorage.getItem("currentLabValue"))
            : "";
        !data.accountType && (data.accountType = labValue.accountType);
        !data.accountId && (data.accountId = labValue.mechanismId);

        if (localStorage.getItem("language") == "en") {
            data.language = "en_US";
        } else {
            data.language = "zh_CN";
        }
        if (needAuth) {
            if (!data.token) {
                /*console.log(this.$message);*/
                // Message.warn()
                /*		Message({
                			message: '未登录',
                			type: 'warning'
                		});*/
            }
        }
        return data;
    }
};
export default API;
