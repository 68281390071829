<template>
  <div class="autovision-header-slot">
    <div style="width:88px;"></div>
    <div class="count-arrange-progress">
      <progress-setup :karyoNum="singleCount" :arrayNum="arrangeCount"></progress-setup>
    </div>
    <div class="btn-baseset">
      <!-- <label>分析进度：</label> -->
      <!-- <el-select v-model="status" placeholder="请选择" @change="statusChange" size="small" style="margin-right:10px;width:120px">
        <el-option label="待分析" value="0"></el-option>
        <el-option label="分析中" value="1"></el-option>
        <el-option label="已完成" value="2"></el-option>
      </el-select> -->
      <primarySetup></primarySetup>
    </div>
  </div>
</template>

<script>
import progressSetup from "../autovision/modules/components/progressSetup.vue";
import primarySetup from "../autovision/modules/components/primarySetup.vue";
export default {
  name: "AutovisionHeaderSlot",
  components: {
    progressSetup,
    primarySetup,
  },
  props: {
    singleCount: 0,
    arrangeCount: 0,
    dialogVisible: () => { },
    caseStatus: String
  },
  data() {
    return {
      // percent: 0,
      // singleMax: 25,
      // arrangeMax: 5,
      visible: false,
      status: this.caseStatus
    };
  },
  created() {
    // this.mathPercent();
  },
  methods: {
    statusChange(v) {
      console.log(v);
      this.$emit("statusChange", v)
    },
    showSetDialog() {
      this.$emit("dialogVisible", true)
    }
  },
  watch: {
    caseStatus(v) {
      this.status = v;
    },
    "$store.state.caseStatus": function (val) {
      this.status = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.autovision-header-slot {
  display: flex;
  flex: 1;
  justify-content: space-between;
  height: 100%;
  .btn-baseset {
    height: 22px;
    line-height: 22px;
    font-size: 16px;
    color: #333;
    // padding-left: 24px;
    margin-top: 19px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .count-arrange-progress {
    width: 327px;
    overflow: hidden;
    margin-top: 11px;
  }
  .progress-analys {
    height: 30px;
    display: flex;
    justify-content: space-between;
  }
}
</style>




