var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"contrast",attrs:{"id":"contrast","width":"1180px","title":_vm.title,"visible":_vm.visible,"before-close":_vm.contrastClose},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"contrast-header"},_vm._l((_vm.chromos),function(item,index){return _c('div',{key:item.id,staticClass:"contrast-number"},_vm._l((_vm.tabsValid),function(tab,i){return _c('div',{key:i,class:[
				'contrast-chromo-num',
				{
					current: item.chromoId == tab.id,
					mar: tab.id ===25,
					disabled: !tab.valid || (_vm.chromos[index==0 ? 1 : 0] && _vm.chromos[index==0 ? 1 : 0].chromoId == tab.id)
				}
			],on:{"click":function($event){return _vm.changeContrast(index,tab.id)}}},[(tab.id < 23)?_c('span',[_vm._v(_vm._s(tab.id))]):_vm._e(),(tab.id == 23)?_c('span',[_vm._v("X")]):_vm._e(),(tab.id == 24)?_c('span',[_vm._v("Y")]):_vm._e(),(tab.id == 25)?_c('span',[_vm._v("MAR")]):_vm._e()])}),0)}),0),_c('div',{class:['contrast-wrap',{diff:_vm.chromo_list.length===2}]},[_c('div',{staticClass:"contrast-container"},_vm._l((_vm.chromo_list),function(item){return _c('div',{key:item.key,staticClass:"contrast-main",class:['scale-'+_vm.scale]},_vm._l((item.list),function(chromos,index){return _c('ul',{key:index,staticClass:"contrast-row"},[_c('li',{staticClass:"contrast-item"},[_c('div',{class:['contrast-standard']},[(item.key<23)?_c('img',{attrs:{"src":("https://cdn.autovision.online/standard/320/Chrom" + (item.key > 9 ? item.key : ('0' + item.key)) + "ISCN09.jpg?v=20200410")}}):_vm._e(),(item.key==23)?_c('img',{attrs:{"src":"https://cdn.autovision.online/standard/320/ChromXISCN09.jpg?v=20200410"}}):_vm._e(),(item.key==24)?_c('img',{attrs:{"src":"https://cdn.autovision.online/standard/320/ChromYISCN09.jpg?v=20200410"}}):_vm._e()]),(item.key < 23)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.key)+_vm._s(_vm.$t('unit.num')))]):_vm._e(),(item.key == 23)?_c('div',{staticClass:"title"},[_vm._v("X"+_vm._s(_vm.$t('unit.num')))]):_vm._e(),(item.key == 24)?_c('div',{staticClass:"title"},[_vm._v("Y"+_vm._s(_vm.$t('unit.num')))]):_vm._e(),(item.key == 25)?_c('div',{staticClass:"title"},[_vm._v("MAR")]):_vm._e()]),_vm._l((chromos),function(chromo,$index){return _c('li',{key:$index,staticClass:"contrast-item",on:{"dblclick":function($event){return _vm.changeKaryo(chromo.karyoId)}}},[_c('div',{class:[
							'contrast-chromo',
							{currentKaryo: chromo.karyoId == _vm.karyoId,
							hasCheckPL: chromo.isTypeSure && chromo.karyoId != _vm.karyoId,
							hasCheckCL: !chromo.isTypeSure && chromo.karyoId != _vm.karyoId,
							noborder: !chromo.justUrl}
						]},[(chromo.justUrl)?_c('img',{class:chromo.classname,attrs:{"src":chromo.justUrl},on:{"load":function($event){return _vm.chromoImageLoaded(chromo)}}}):_vm._e()]),(chromo.index || chromo.index==0)?_c('div',{staticClass:"title",attrs:{"title":chromo.glassName + '/' + chromo.cellName}},[_vm._v(_vm._s(chromo.glassName)+"/"+_vm._s(chromo.cellName))]):_vm._e()])}),_vm._l(((_vm.scale - chromos.length)),function(n){return _c('li',{key:'n_' + n,staticClass:"contrast-item"})})],2)}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }