<template>
	<div class="autovision">
		<div style="display: none">
			<input type="file" id="selectfiles" style="display: none" />
		</div>
		<div class="autovision-left">
			<!-- 左上主操作区 -->
			<div class="autovision-left-top">
				<div class="karyo-stage-canvas stage-canvas">
					<canvas id="karyo-stage" :class="[
                            {
                                nopointer:
                                    toolsType === 'paint' ||
                                    (shortKeyType === 'shiftleft' &&
                                        (toolsType === 'general' ||
                                            toolsType === 'count')),
                            },
                        ]" style="width: 100%; height: 100%"></canvas>
				</div>
				<div :class="['karyo-counter',{singleSure:karyoInfo.isSingleSure},{typeSure:karyoInfo.isTypeSure}]">
					{{karyoInfo.singleNum}}</div>
				<div class="tools-btns">
					<div class="small_large_btn">
						<el-button @click="smallKaryoCanvas()" class="small-btn"><span></span></el-button>
						<el-button @click="enchargeKaryoCanvas()" class="enlarge-btn"><span></span></el-button>
					</div>
				</div>
				<div class="karyo-brush-thickness" v-if="
                        toolsType === 'eraser' || toolsType === 'paint' ||
                        ((shortKeyType === 'shiftleft' || shortKeyType === 'shiftright') && (toolsType === 'general' || toolsType === 'count'))
                    ">
					<label v-if="toolsType === 'eraser'">{{$t('case_analysis.brush_thickness1')}}：</label>
					<label v-else>{{$t('case_analysis.brush_thickness')}}：</label>
					<div class="brush-slider">
						<el-slider v-model="brushThickness" :min="5" :max="50" :show-tooltip="false"
							@change="brushThicknessChange"></el-slider>
					</div>
					<label style="margin-left: 10px">{{
                        brushThickness
                    }}</label>
				</div>
			</div>
			<!-- 全部染色体排列区 -->
			<div class="autovision-left-allchromo" v-show="globalMode==='counter'">
				<div class="allchromo-title">{{$t('uploadPdf.all_karyos')}}</div>
				<div class="allchromo-container">
					<allTheKaryos :imgData="chromoList" ref="allTheKaryos"></allTheKaryos>
					<!-- <allKaryos :imgData="chromoList" ref="allTheKaryos"></allKaryos> -->
				</div>
			</div>
		</div>
		<div class="autovision-middle">
			<!-- 主工具栏 -->
			<div class="autovision-main-tools" :class="{'countmodal': globalMode==='counter'}">
				<div class="autovision-tool flex">
					<div class="tool undo" @click="undo" ref="undoBtn">{{$t('tools_tip.undo')}}</div>
					<div class="tool redo" @click="redo" ref="redoBtn">{{$t('tools_tip.redo')}}</div>
				</div>
				<div :class="['autovision-tool','autovision-mode',globalMode]">
					<div class="modes">
						<div class="mode" @click="globalMode='counter'">{{$t('new_tools.Counting_mode')}}</div>
						<div class="mode" @click="globalMode='arrange'">{{$t('new_tools.pattern')}}</div>
					</div>
				</div>

				<template v-if="globalMode === 'arrange'">
					<div class="autovision-tool autovision-normal-tool switch-view" @click="switchStage">
						{{$t('new_tools.Karyotypes_interchangeably')}}</div>
					<div class="autovision-tool autovision-normal-tool arrange-wait" @click="arrange26">
						{{$t('new_tools.Arranged_to_arranged')}}</div>
					<div class="autovision-tool autovision-normal-tool arrange-all" @click="arrangeAll">
						{{$t('new_tools.Arrange_all')}}</div>
					<div class="autovision-tool autovision-normal-tool paint" v-if="!isArrangeOnLeft"
						:class="{ active: toolsType == 'paint' }" @click="toolsTypeChange('paint')">
						{{$t('new_tools.daub')}}</div>
					<div class="autovision-tool autovision-normal-tool rotate" v-if="isArrangeOnLeft"
						:class="{ active: toolsType == 'rotate' }" @click="toolsTypeChange('rotate')">
						{{$t('new_tools.rotating')}}</div>
				</template>
				<div class="autovision-tool autovision-normal-tool delete" :class="{ active: toolsType == 'delete' }"
					@click="toolsTypeChange('delete')">{{$t('new_tools.delete')}}</div>
				<!-- <template v-if="globalMode === 'counter'">
                    <div class="autovision-tool autovision-normal-tool delete" :class="{ active: toolsType == 'multi-delete' }" @click="toolsTypeChange('multi-delete')">{{$t('new_tools.multidelete')}}</div>
                </template> -->
				<template v-if="globalMode === 'arrange'">
					<div class="autovision-tool autovision-normal-tool magnify" @click="magnifyChromo">
						{{$t('new_tools.Chromosome_amplification')}}</div>
					<div class="autovision-tool autovision-normal-tool narrow" @click="narrowChromo">
						{{$t('new_tools.Chromosome_reduction')}}</div>
				</template>
				<div class="autovision-tool tools-switch">
					<div class="tool-name">{{$t('new_tools.Auxiliary_marker')}}</div>
					<div class="tool-switch" :class="{active: assistantMarker}" @click="hidePolygon"></div>
				</div>
				<!-- <div class="autovision-tool tools-switch">
					<div class="tool-name">优化图</div>
					<div class="tool-switch" :class="{active: isShowPrimaryImage}" @click="isShowPrimaryImage = !isShowPrimaryImage"></div>
				</div> -->
				<div
					:class="['autovision-tool','autovision-mode',{counter:!isShowPrimaryImage},{arrange:isShowPrimaryImage} ]">
					<div class="modes">
						<div class="mode" @click="switchPrimaryOrOpt(false)">{{$t('new_tools.optimize_the_figure')}}
						</div>
						<div class="mode" @click="switchPrimaryOrOpt(true)">{{$t('new_tools.original_figure')}}</div>
					</div>
				</div>
			</div>
			<div class="autovision-middle-count" v-if="globalMode==='counter'">{{$t('new_tools.current_count1')}}</div>
			<div class="autovision-middle-karyo-expression" v-if="globalMode==='counter'"
				:style="{border:'none',background:'none',color:karyoInfo.singleNum==46 ? '#52C41A' : '#F53500'}">
				{{karyoInfo.singleNum}}</div>
			<div class="autovision-middle-count" v-if="globalMode==='arrange'">
				{{$t('new_tools.current_count')}}{{karyoInfo.singleNum}}</div>

			<div class="autovision-middle-expression-title" v-if="globalMode==='arrange'">
				{{$t('new_tools.Karyotype_expression')}}</div>
			<!-- 核型表达式 -->
			<textarea v-if="globalMode==='arrange'" class="autovision-middle-karyo-expression"
				:class="[expressionStyle]" :rows="expressionRow" v-model="karyotypeExpression"
				@input="karyotypeExpressionChange" @focus="karyotypeExpressionFocus" @blur="karyotypeExpressionBlur">
            </textarea>
			<!-- <div class="autovision-middle-karyo-expression">{{karyoInfo.karyotypeExpression}}</div> -->
			<!-- 病例信息 -->
			<div class="autovision-patient-info" :class="{'english': $store.state.language == 'en'}">
				<el-scrollbar :native='false'>
					<dl class="autovision-patient-info-dl">
						<dt>{{$t('new_tools.Case_information')}}</dt>
						<dd><label>{{$t('new_tools.Case_number')}}</label><a>：</a><span
								:title="patientName">{{patientName}}</span></dd>
						<dd><label>{{$t('new_tools.name')}}</label><a>：</a><span
								:title="patientInfo.realName">{{patientInfo.realName}}</span></dd>
						<dd><label>{{$t('new_tools.Years_of_age')}}</label><a>：</a><span
								:title="patientInfo.age">{{patientInfo.age ? patientInfo.age : ''}}</span></dd>
						<dd><label>{{$t('new_tools.Sex_don')}}</label><a>：</a><span
								:title="patientInfo.gender">{{patientInfo.gender}}</span></dd><br>
						<dd><label>{{$t('new_tools.Sample_type')}}</label><a>：</a><span
								:title="sampleTypeName">{{sampleTypeName}}</span></dd>
						<dd><label>{{$t('new_tools.Receiving_date')}}</label><a>：</a><span
								:title="patientInfo.receiveDate">{{patientInfo.receiveDate}}</span></dd>
						<dd><label>{{$t('new_tools.Clinical_diagnosis')}}</label><a>：</a><span
								:title="patientInfo.clinicalDiagnosis">{{patientInfo.clinicalDiagnosis}}</span></dd>
                        <dd><label>{{$t('new_tools.note')}}</label><a>：</a><span :title="patientInfo.remark"  class="clinicalDiagnosis">{{patientInfo.remark}}</span></dd><br>
						<dd><label>{{$t('new_tools.Slide_number')}}</label><a>：</a><span
								:title="glassName">{{glassName}}</span></dd>
						<dd><label>{{$t('new_tools.Cell_number')}}</label><a>：</a><span
								:title="karyoInfo.cellName">{{karyoInfo.cellName}}</span></dd><br>
					</dl>
				</el-scrollbar>
			</div>
		</div>
		<div class="autovision-right">
			<!-- 小视图区 -->
			<div class="autovision-view-zone">
				<div class="arrange-stage-canvas stage-canvas">
					<canvas id="arrange-stage" :class="[{ nopointer: toolsType === 'eraser' }]"
						style="width: 100%; height: 100%"></canvas>
				</div>
				<div class="autovision-view-zone-cover show" v-if="globalMode==='counter'"></div>
			</div>
			<!-- 副工具栏 -->
			<div class="autovision-assistant-tools">
				<div class="assistant-tool tool-optmize" @click="optimizeVisible = true">
					<span>{{$t('new_tools.optimize_the_image')}}</span></div>
				<template v-if="globalMode==='arrange'">
					<div class="assistant-tool tool-chain" :class="{ active: toolsType == 'area' }"
						@click="toolsTypeChange('area')"><span>{{$t('new_tools.Chain_erase')}}</span></div>
					<div class="assistant-tool tool-erase" :class="{ active: toolsType == 'eraser' }"
						@click="toolsTypeChange('eraser')"><span>{{$t('new_tools.The_eraser')}}</span></div>
					<div class="assistant-tool tool-standard" :class="{ active: standardVisible }"
						@click="toggleStandard"><span>{{$t('new_tools.Standard_ribbon')}}</span></div>
					<div class="assistant-tool tool-fixed" :class="{ active: toolsType == 'banding' }"
						@click="toolsTypeChange('banding')"><span>{{$t('new_tools.Fixed_belt')}}</span></div>
					<div class="assistant-tool tool-single fz10" :class="{ active: toolsType == 'same' }"
						@click="toolsTypeChange('same')">
						<span>{{$t('new_tools.Homologous_order_number_comparison')}}</span></div>
					<div class="assistant-tool tool-double fz10" :class="{ active: toolsType == 'diff' }"
						@click="toolsTypeChange('diff')">
						<span>{{$t('new_tools.Homologous_double_sign_contrast')}}</span></div>
					<div class="assistant-tool tool-arrow" :class="{ active: toolsType == 'arrow' }"
						@click="toolsTypeChange('arrow')"><span>{{$t('new_tools.arrow_mark')}}</span></div>
					<div class="assistant-tool tool-mar" @click="showMark"><span>MAR</span></div>
					<div class="assistant-tool tool-mirror" :class="{ active: toolsType == 'mirror' }"
						@click="toolsTypeChange('mirror')"><span>{{$t('new_tools.The_mirror')}}</span></div>
				</template>
				<div class="assistant-tool tool-color"
					:class="{red:karyoInfo.color==1,yellow:karyoInfo.color==2,blue:karyoInfo.color==3}">
					<span>{{$t('new_tools.Color_tag')}}</span>
					<div class="color-pannel">
						<div class="red" @click="updateColor(1)"></div>
						<div class="yellow" @click="updateColor(2)"></div>
						<div class="blue" @click="updateColor(3)"></div>
						<div class="white" @click="updateColor(0)"></div>
					</div>
				</div>
				<div class="assistant-tool tool-top" @click="updateIsTop">
					<span>{{karyoInfo.isTop?this.$t('new_tools.cancel'):''}}{{$t('new_tools.Placed_at_the_top')}}</span>
				</div>
				<div class="assistant-tool tool-export" @click="exportImages">
					<span>{{$t('new_tools.Export_images')}}</span></div>
				<template v-if="globalMode==='arrange'">
					<div class="assistant-tool tool-resotre fz10" @click="return2OriginalCondition">
						<span>{{$t('new_tools.Reautomatic_analysis')}}</span></div>
				</template>
			</div>
			<!-- 批注 -->
			<div class="autovision-remark">
				<label>{{$t('new_tools.notation')}}</label>
				<textarea class="remark" v-model="remark" :placeholder="$t('new_tools.Please_enter_image')"></textarea>
			</div>
			<!-- 保存、切换等按钮 -->
			<div class="autovision-main-btns">
				<div class="autovision-main-btns-row">
					<div class="save-and-next" @click="confirmCount">{{$t('new_tools.Confirm_count')}}</div>
					<div class="save-both" @click="confirmArrange">
						<span :class="{'english': $store.state.language == 'en'}">{{$t('new_tools.Confirm_the_count_and')}}</span>
					</div>
				</div>
				<div class="autovision-main-btns-row">
					<div class="prev-karyo" @click="prevKaryo"><i></i>{{$t('new_tools.On_one_piece')}}</div>
					<div class="next-karyo" @click="nextKaryo"><i></i>{{$t('new_tools.The_next')}}</div>
					<div class="cancel-confirm" @click="cancelArrange">{{$t('new_tools.Cancel_the_confirmation')}}</div>
				</div>

				<!-- <div class="prev-karyo" @click="prevKaryo"><i></i>{{$t('new_tools.On_one_piece')}}</div>
				<div class="save-karyo">
					<div class="save-and-next" @click="confirmCount">{{$t('new_tools.Confirm_count')}}</div>
					<div class="save-flex">
						<div class="save-both" @click="confirmArrange"><span
								:class="{'english': $store.state.language == 'en'}">{{$t('new_tools.Confirm_the_count_and')}}</span>
						</div>
						<div class="cancel-confirm" @click="cancelArrange">{{$t('new_tools.Cancel_the_confirmation')}}
						</div>
					</div>
				</div>
				<div class="next-karyo" @click="nextKaryo"><i></i>{{$t('new_tools.The_next')}}</div> -->
			</div>
		</div>
		<div class="autovision-list">
			<label class="autovision-patient-label">{{$t('new_tools.List_of_case_karyotypes')}}</label>
			<div class="autovision-patient-title" v-if="karyoTopList.length"><i></i>{{$t('new_tools.Top_area')}}</div>
			<karyo-list v-if="karyoTopList.length"
				:style="{height: Math.min(karyoTopList.length*59,294) + 'px',flex:'none'}" :karyoList="karyoTopList"
				:karyoId="karyoId" :switchKaryoHandle="switchKaryoHandle"></karyo-list>
			<div class="autovision-patient-title" v-if="karyoTopList.length">{{$t('new_tools.The_top_section')}}</div>
			<karyo-list :karyoList="karyoNormalList" :karyoId="karyoId" :switchKaryoHandle="switchKaryoHandle"
				:infinite="true"></karyo-list>
		</div>
		<div id="div01" style="display: none"></div>
		<div id="div02" style="display: none"></div>
		<el-dialog :title="$t('new_tools.Enter_the_note')" width="470px" :visible.sync="arrowMarkVisible"
			class="arrow-mark-dialog">
			<el-input type="textarea" :rows="2" :maxlength="30" resize="none"
				:placeholder="$t('new_tools.Please_enter_the_contents')" class="arrowMark" v-model="arrowMarkValue">
			</el-input>
			<div class="arrow-mark-tip">
				<em>{{ arrowMarkValue.length }}</em>/30
			</div>
			<div slot="footer">
				<div class="btn btn-default" @click="arrowMarkVisible = false">
					{{$t('new_tools.cancel')}}
				</div>
				<div class="btn btn-primary" @click="arrowMarkConfirm">
					{{$t('new_tools.determine')}}
				</div>
			</div>
		</el-dialog>
		<contrast :visible.sync="contrastVisible" :chromos="contrastChromos" :chromoList="chromoList"
			:patientId="caseId" :karyoId="karyoId" :type="contrastType" @closeContrast="closeContrast"></contrast>
		<optimize-setting v-if="optimizeVisible" :visible.sync="optimizeVisible" :optimizeConfig="optimizeConfig"
			@optimizeSettingClose="optimizeSettingClose"></optimize-setting>
		<div class="banding-select" v-if="bandingSelectVisible" ref="banding">
			<div @click="bandingSelect(320)" :class="{current:bandingStandchromoModal==320}">320</div>
			<div @click="bandingSelect(400)" :class="{current:bandingStandchromoModal==400}">400</div>
			<div @click="bandingSelect(550)" :class="{current:bandingStandchromoModal==550}">550</div>
			<div @click="bandingSelect(700)" :class="{current:bandingStandchromoModal==700}">700</div>
			<div @click="bandingSelect(850)" :class="{current:bandingStandchromoModal==850}">850</div>
		</div>
	</div>
</template>

<script>
	import KaryoList from "./karyoList.vue";
	import contrast from "./contrast.vue";
	import "../../assets/scss/tools.scss";
	import shortKey from "../../assets/utils/shortKey.js";
	import {
		downloadZip
	} from "../../assets/utils/downloadZip.js";
	import karyoCanvasController from "./modules/karyoCanvasController.js";
	import arrangeCanvasController from "./modules/arrangeCanvasController.js";
	import Tools from "./modules/tools.js";
	import standard_320 from "../../assets/images/autovision/320.png";
	import standard_400 from "../../assets/images/autovision/400.png";
	import standard_550 from "../../assets/images/autovision/550.png";
	import standard_700 from "../../assets/images/autovision/700.png";
	import standard_850 from "../../assets/images/autovision/850.png";
	import CachePool from "./modules/tools/cachePool.js";
	import uploader from "../../assets/utils/fileUploader.js";
	import tooltipConfig from "../../struction/airstruction.js";
	import {
		getRotateObj
	} from "./modules/tools/rotate.js";
	import config from "../../assets/js/config.js";
	import heartbeatMixin from "./modules/heartbeat.js";
	import btnFun from "./modules/btnFun.js";
	import allTheKaryos from "./modules/components/allTheKaryos.vue";
	// import allKaryos from "./modules/components/allKaryos.vue";
	import OptimizeSetting from "./optimizeSetting.vue";
	const pako = window.pako;
	let TIMER;
	export default {
		name: 'Autovision',
		components: {
			KaryoList,
			contrast,
			allTheKaryos,
			// allKaryos,
			OptimizeSetting
		},
		props: {
			updateCounterArrange: () => {},
			updateAnalys: () => {},
			patientName: '',
			patientId: '',
			karyoID: '',
			RANDOM_STRING_RECIVE: '',
			patientInfo: {}
		},
		mixins: [heartbeatMixin, btnFun],
		data() {
			// const param = this.resolveQuery(this.$route.query);
			return {
				globalMode: "counter",
				hasHideNumber: Number(this.$store.state.analysisInfo.hasHideNumber || 0),
				caseName: this.patientName,
				caseId: this.patientId,
				karyoId: this.karyoID,
				viewStatus: 'karyo',
				isSingleSure: 0, //是否已确认计数
				isTypeSure: 0, //是否已确认排列
				isPageLoading: true, //页面正在加载
				isPageInit: false, //页面是否第一次加载
				glassName: "", //玻片名称
				karyoList: [], //核型图列表
				queryListParam: {
					status: 1
				},
				karyoTopList: [],
				karyoNormalList: [],
				chromoList: [], //染色体列表
				karyoInfo: {}, //当前核型图信息
				karyotypeExpression: "", //核型表达式
				remark: this.$t('new_tools.notation'), //批注
				SingleSureCount: 0, //已确认计数
				TypeSureArray: 0, //已确认排列
				selectStripNum: 320, //条带信息数
				standardInfo: {}, //条带信息
				standardPics: {
					//标准染色体图片集合
					320: standard_320,
					400: standard_400,
					550: standard_550,
					700: standard_700,
					850: standard_850,
				},
				standardImage: null, //标准染色体图片
				standardVisible: false, //标准染色体是否显示
				downloadQueue: [], //zip下载队列
				entries: {}, //zip解压图片后缓存
				whetherRepeatInspect: false, //是否需要复检
				isShowPrimaryImage: false, //是否显示原图
				assistantMarker: true,
				brushThickness: 15, //画笔粗细
				eraserBrushThickness: 15, //橡皮擦画笔粗细
				paintBrushThickness: 15, //涂抹工具画笔粗细
				isKaryoListShrink: false, //核型图列表是否收缩 false为展开 true为收起
				toolsType: "general", //工具类型
				shortKeyType: "", //快捷键类型
				arrowMarkVisible: false, //箭头备注弹窗
				arrowMarkValue: "", //箭头备注内容
				arrowMarkCallback: new Function(), //箭头备注弹窗回调
				shortKeyDisabled: false, //快捷键启用状态
				contrastType: "single", //染色体对比类型
				contrastChromos: [], //需要对比的染色体集合
				contrastVisible: false, //染色体对比弹窗界面
				optimizeVisible: false,
				cachePool: new CachePool({
					constructor: this
				}), //数据缓存池
				choosedChromo: null, //当前选中的染色体
				tooltipConfig: tooltipConfig, //工具提示配置
				randomString: "", //随机字符串
				version: 0, //心跳次数记录
				currentVersion: 0, //当前心跳
				isArrangeOnLeft: false,//排列图是否显示在左侧位置
				remoteVersion: 0, //服务端版本号
				isNotMoveOverFiveMinites: false, //是否已经超过5分钟未操作
				bandingSelectVisible: false,
				bandingStandchromoModal: 320,
				optimizeConfig: {},
				VectorJSMat: [],
				VectorJSMatFinished: false,
				expressionStyle: '',
				expressionRow: 1
			};
		},
		created() {
			console.log("页面 created", +new Date());
			this.isPageLoading = true;
			this.RANDOM_STRING = this.RANDOM_STRING_RECIVE;
			console.warn(this.RANDOM_STRING)
			this.globalMode = this.$store.state.analysisInfo.analysisModeDefault == 2 ? "arrange" : "counter";
			// this.isArrangeOnLeft = this.$store.state.analysisInfo.analysisModeDefault == 2
			
			this.queryOss(this.karyoId);
			this.queryKaryoList(false, {
				status: this.$store.state.status
			}, false);
			let count = 0;
			Object.keys(this.standardPics).forEach((key) => {
				const src = this.standardPics[key];
				const img = new Image();
				img.src = src;
				img.onload = () => {
					count++;
					this.standardPics[key] = img;
					count >= 5 && this.requireStandard(320);
				};
			});
			shortKey.clear();
			shortKey.bindAPP(this);
			shortKey.disabled = false;
			this.$parent.$parent.getOssSign();

			const fn = () => {
				clearTimeout(this.heartTimer);
				this.heartTimer = null;
				this.$store.state.heartbeating = false;
				localStorage.removeItem("patient_opened_" + this.caseId);
				window.removeEventListener("blur", visibility);
			};
			window.removeEventListener("popstate", fn);
			window.addEventListener("popstate", fn);
			const visibility = () => {
				this.shortKeyType = "";
			};
			window.removeEventListener("blur", visibility);
			window.addEventListener("blur", visibility);

			window.chromoMap = []; //删除染色体时存储地方

			// sessionStorage.setItem('deleteChrome', '');
		},
		mounted() {
			window.APP = this;
			console.log("页面 mounted", +new Date());
			window.startTime = +new Date();
			window.loadingInstance = this.$loading({
				background: "rgba(255,255,255,.3)",
				customClass: "autovision-loading",
				text: this.$t('new_tools.message'),
			});
			if (this.globalMode === 'arrange') {
				this.switchStage();
			}
			this.$store._mutations.updateKaryoStatus = (karyoid, key, value) => {
				if (this.karyoId === karyoid) {
					if (key && value) {
						this.karyoInfo[key] = value;
						this.karyoList.forEach(a => {
							if (a.id === this.karyoId) {
								a = Object.assign(a, this.karyoInfo)
							}
						})
						this.karyoTopList = this.karyoList.filter(a => a.isTop);
						this.karyoNormalList = this.karyoList.filter(a => !a.isTop);
					} else {
						this.reloadKaryoInfo();
					}
				}
			}
			this.$store._mutations.deleteKaryo = (karyoId) => {
				console.log('deleteKaryo', this.karyoId, karyoId);
				if (this.karyoId !== karyoId) {
					return;
				}
				if (this.karyoList.length === 1) {
					this.$store._mutations.closeTab(this.caseId)
					return;
				}
				this.karyoList = this.karyoList.filter(a => a.id !== karyoId);
				if (!this.karyoList.length) {
					this.$store._mutations.closeTab(this.caseId)
					return;
				}
				let index = this.karyoList.map((a) => a.id).indexOf(this.karyoId);
				index += index < this.karyoList.length - 1 ? 1 : -index;
				let id = this.karyoList[index].id;
				console.log('nextKaryo', id);
				clearTimeout(this.heartTimer);
				this.heartTimer = null;
				this.$store.state.heartbeating = false;
				// this.reloadKaryoInfo(id);
				this.uploadSureCount();
				this.switchKaryoCallback(id);
				return;
				this.uploadSureCount();
				this.queryOss(id, () => {
					this.karyoList.forEach(a => {
						if (a.id === id) {
							a = Object.assign(a, this.karyoInfo);
							const b = Object.assign(a, this.karyoInfo, {
								showLoading: 1,
								arrange: 1,
								breviary: 2
							});
							this.$store._mutations.updateAnalysKaryoInfo(b);
						}
					})
					this.karyoTopList = this.karyoList.filter(a => a.isTop);
					this.karyoNormalList = this.karyoList.filter(a => !a.isTop);

					this.karyoController.reset(this.chromoList, this.karyoInfo, !this.karyoInfo
						.originalArrowLines ? [] : JSON.parse(this.karyoInfo.originalArrowLines));
					this.arrangeController.reset(this.chromoList, this.karyoInfo, !this.karyoInfo
						.arrangeArrowLines ? [] : JSON.parse(this.karyoInfo.arrangeArrowLines));
					this.cachePool.reset();
					this.cachePool.save();
				});
			}
		},
		computed: {
			sampleTypeName() {
				switch (this.patientInfo.sampleType) {
					case "1":
						return this.$t('popup.old_report.blood');
					case "2":
						return this.$t('popup.old_report.marrow');
					case "3":
						return this.$t('popup.old_report.villus');
					default:
						return '';
				}
			}
		},
		destroyed() {
			console.log("destroyed");
			// RANDOM_STRING = "";
			this.destroyedStatus = true;
			shortKey.disabled = true;
			clearTimeout(this.heartTimer);
			this.heartTimer = null;
			this.$store.state.heartbeating = false;
			this.$store.state.autovision = {};
			delete this.entries;
			window.entiresPool = null;
			this.deleteLock();
			// localStorage.removeItem("patient_opened_"+ this.caseId);
		},
		methods: {

			/**
			 * 删除病例锁
			 */
			deleteLock(fn) {
				if (!this.randomString) return;
				const param = {
					randomString: this.randomString,
					patientId: this.caseId,
					karyoId: this.karyoId
				}
				this.$api.deleteLock(param).then(res => {
					if (typeof fn === "function") {
						fn();
					}
				})
			},
			optimizeSettingClose() {
				this.optimizeVisible = false;
				this.arrangeController.chromoList = this.chromoList;
				this.karyoController.resetKaryo();
				this.arrangeController.reArrange();
				// this.arrangeController.reset(this.chromoList,this.karyoInfo,!this.karyoInfo.arrangeArrowLines ? []: JSON.parse(this.karyoInfo.arrangeArrowLines))
			},
			//全屏
			fullscreen() {
				if (this.$store.state.fullScreen) {
					this.$store.commit("quitFullScreen");
				} else {
					this.$store.commit("openFullScreen");
				}
			},


			/**
			 * 选择条带信息
			 */
			reSelectStripNum(e) {
				this.selectStripNum = e;
				this.requireStandard(e);
			},

			/**
			 * 获取条带信息json
			 * @param {Object} v
			 */
			requireStandard(v) {
				this.standardInfo = require(`./modules/json/standard-${v}`).standard;
				this.standardImage = this.standardPics[v];
				if (this.arrangeController) {
					this.arrangeController.toggleStandard(
						this.standardInfo,
						this.standardImage,
						this.standardVisible
					);
				}
			},

			/**
			 * 切换原图与优化图
			 */
			switchPrimaryOrOpt(v) {
				this.isShowPrimaryImage = v;
				if (this.karyoController.primaryImage) {
					this.karyoController.primaryImageShape.hide = !v;
				} else {
					this.karyoController.loadPrimaryUrl();
				}
				if (this.karyoController.optImage) {
					this.karyoController.optImageShape.hide = v;
				}
				this.karyoController.stage.draw();
			},

			/**
			 * queryOss
			 */
			queryOss(karyoId, callback) {
				const param = {
					id: karyoId,
					type: 3,
				};
				!!this.RANDOM_STRING && (param.randomString = this.RANDOM_STRING);
				// RANDOM_STRING = "";
				this.$api.queryOss(param).then((res) => {
					if (res.code == 200) {
						if (!res.data) {
							this.returnToOriginal();
							return;
						}
						this.optimizeConfig = {};
						this.RANDOM_STRING = res.data.randomString;
						this.$store.state.autovision = res.data;
						this.version = 0;
						this.chromoList = res.data.chromoList.map((a) => {
							a.yoffsetInGroup = a.yoffsetInGroup || 0;
							return a;
						});
						this.karyoInfo = res.data.karyoList[0];
						if (/\.(tiff|TIFF|tif|TIF)/g.test(this.karyoInfo.primaryUrl)) {
							this.karyoInfo.primaryUrl = this.ossUrlWebFir(this.karyoInfo.primaryUrl, 6);
						}
						this.isSingleSure = this.karyoInfo.isSingleSure;
						this.isTypeSure = this.karyoInfo.isTypeSure;
						this.remoteVersion = this.karyoInfo.version;
						// this.isArrangeOnLeft = !!this.isTypeSure;
						//计数模式且 之前 排列图在主工作区
						if (this.globalMode === 'counter' && this.isArrangeOnLeft) {
							this.switchStage();
						}

						// if (this.karyoInfo.isTypeSure && !this.isArrangeOnLeft) {
						//     this.switchStage();
						// } else if (
						//     !this.karyoInfo.isTypeSure &&
						//     this.isArrangeOnLeft
						// ) {
						//     this.switchStage();
						// }
						// if (this.karyoList.length) {
						//     this.glassName = this.karyoList.filter(
						//         (item) => item.id === karyoId
						//     )[0].glassName;
						// }
						this.whetherRepeatInspect = this.karyoInfo.whetherRepeatInspect;
						this.karyotypeExpression = this.karyoInfo.karyotypeExpression;
						this.expressionManuallyModify = this.karyoInfo.isPreserSure === 1;
						this.remark = this.karyoInfo.remark;
						this.randomString = res.data.randomString;
						typeof callback === "function" && callback();
						if (!this.isPageInit) {
							this.isPageInit = true;
							this.downloadOptImages(this.karyoId);
						}
						this.$store.state.heartbeating = true;
						this.heartbeat();
						// this.reCreateJsMat();
					} else {
						this.$message.error(res.message);
						this.clearStorage();
						window.loadingInstance.close();
					}
					if (res.code == 248) {
						this.clearStorage();
					}
				});
			},

			/**
			 * 获取核型图列表
			 */
			queryKaryoList(notloadZip, param = {
				status: 4
			}, isNotUpdate) {
				param.status === -1 && (param.status = 4)
				this.loadingKaryoList = true;
				this.queryListParam = Object.assign(this.queryListParam, param);
				const $param = Object.assign({
					patientId: this.caseId,
					limit: 1000
				}, this.queryListParam);

				console.log(notloadZip, $param);
				this.$api
					.queryKaryoList($param)
					.then((res) => {
						if (res.code == 200) {
							this.$emit("updateAnalys", res.data);
							this.loadingKaryoList = false;
							this.pageUtil = res.data.pageUtil;
							this.karyoList = res.data.pageUtil.list;
							// this.glassName = this.karyoList.filter(
							//     (item) => item.id === this.karyoId
							// )[0].glassName;
							const info = this.karyoList.filter(a => a.id === this.karyoId)[0];
							this.glassName = info ? info.glassName : '';
							this.karyoTopList = this.karyoList.filter(a => a.isTop);
							this.karyoNormalList = this.karyoList.filter(a => !a.isTop);
							// this.downloadZip();
							this.SingleSureCount = res.data.statisInfo.singleSureNum;
							this.TypeSureArray = res.data.statisInfo.typeSureNum;
							!isNotUpdate && this.$emit("updateCounterArrange", this.SingleSureCount, this
								.TypeSureArray);
							this.karyoList.forEach((item) => {
								// item._optUrl = this.ossUrlWebFir(
								//     item.optUrl.split("?")[0],
								//     1,
								//     100,
								//     100
								// );
								if (item.zipJEOssUrl && !notloadZip) {
									this.addUrlToQueue(item.id, item.zipJEOssUrl);
								}
								if (item.zipOssUrl && !notloadZip) {
									this.addUrlToQueue(item.id, item.zipOssUrl);
								}
							});
							// !notloadZip && this.asyncLoadZip();
							// this.preloadPrimaryImage();
						} else {
							this.$message.error(res.message);
						}
					});
			},
			/**
			 * 加载核型图列表
			 */
			loadNextPageList() {
				if (this.loadingKaryoList) return;
				if (this.pageUtil.currPage === this.pageUtil.totalPage) {
					return
				}
				this.loadingKaryoList = true;
				const $param = {
					patientId: this.caseId,
					limit: 1000,
					page: this.pageUtil.currPage + 1,
				};
				this.$api.queryKaryoList(Object.assign($param, this.queryListParam)).then((res) => {
					if (res.code == 200) {
						this.$emit("updateAnalys", res.data);
						this.loadingKaryoList = false;
						this.pageUtil = res.data.pageUtil;
						this.karyoList = this.karyoList.concat(res.data.pageUtil.list);
						this.karyoTopList = this.karyoList.filter(a => a.isTop);
						this.karyoNormalList = this.karyoList.filter(a => !a.isTop);
						// this.downloadZip();
						this.SingleSureCount = res.data.statisInfo.singleSureNum;
						this.TypeSureArray = res.data.statisInfo.typeSureNum;
						res.data.pageUtil.list.forEach((item) => {
							if (item.zipJEOssUrl) {
								this.addUrlToQueue(item.id, item.zipJEOssUrl);
							}
							if (item.zipOssUrl) {
								this.addUrlToQueue(item.id, item.zipOssUrl);
							}
						});
						this.asyncLoadZip();
					} else {
						this.$message.error(res.message);
					}
				})
			},
			/**
			 * 预加载原图大图
			 */
			preloadPrimaryImage() {
				this.karyoList.forEach((item) => {
					const image = new Image();
					image.setAttribute("crossOrigin", "anonymous");
					image.onload = () => {
						item.primaryImage = image;
					};
					image.src = item.primaryUrl;

					const image1 = new Image();
					image1.setAttribute("crossOrigin", "anonymous");
					image1.onload = () => {
						item.optImage = image1;
					};
					image1.src = item.optUrl;
				});
			},
			/**
			 * 核型图列表收缩与展开
			 */
			haryListShrink(value) {
				this.isKaryoListShrink = value;
				this.$nextTick(() => {
					this.karyoController.resetStage();
					this.arrangeController.resetStage();
				});
			},

			/**
			 * 初始化中期分裂相与排列图
			 */
			initKaryoAndArrange() {
				console.warn('3333333333')
				this.isPageLoading = false;
				this.cachePool.save({
					optImage: this.karyoController.optImage
				});
				console.warn('4444444444')
				this.addEventListener();
				console.warn('55555555')
				this.addShortKeys();
				console.warn('666666666666')
				this.asyncLoadZip();
				console.warn('777777777777')
				// this.preloadPrimaryImage();
			},
			initKaryoStage() {
				console.log("页面 初始化中期分裂相", +new Date());
				this.karyoController = new karyoCanvasController({
					element: document.querySelector("#karyo-stage"),
					chromoList: this.chromoList,
					karyoInfo: this.karyoInfo,
					arrowList: !this.karyoInfo.originalArrowLines ?
						[] :
						JSON.parse(this.karyoInfo.originalArrowLines),
					app: this
				});
				this.karyoInfo.originalArrowLines = JSON.stringify(this.karyoController.arrowList.map(arrow => {
					return this.karyoController.arrowWrite(arrow);
				}))
				// this.karyoController.stage && this.addKaryoEventListener();
			},
			initArrangeStage() {
				this.arrangeController = new arrangeCanvasController({
					element: document.querySelector("#arrange-stage"),
					chromoList: this.chromoList,
					karyoInfo: this.karyoInfo,
					arrowList: !this.karyoInfo.arrangeArrowLines ?
						[] :
						JSON.parse(this.karyoInfo.arrangeArrowLines),
					banding: !this.karyoInfo.banding ? {
						standchromoInfo: [],
						standchromoModal: "320"
					} : JSON.parse(this.karyoInfo.banding),
					entries: this.entries,
					app: this
				});
				// this.arrangeController.app = this;
				this.arrangeController.toggleStandard(
					this.standardInfo,
					this.standardImage,
					this.standardVisible
				);
				// this.arrangeController.stage && this.addArrangeEventListener();
			},
			/**
			 * 渲染画布-用来调试绘制效率
			 */
			render() {
				const t = new Date().getTime();
				window.timer = window.requestAnimationFrame(() => {
					if (this.karyoController.stage) {
						this.karyoController.stage.draw();
					}
					if (this.arrangeController.stage) {
						this.arrangeController.stage.draw();
					}
					this.render();
					const _t = new Date().getTime() - t;
					if (_t > 0) {
						const fps = parseInt(1000 / _t);
						window.maxfps = Math.max(window.maxfps || fps, fps);
						window.minfps = Math.min(window.minfps || fps, fps);
						document.querySelector("#fps").innerHTML =
							this.$t('popup.tips.currentfps') +
							fps +
							this.$t('popup.tips.smallestfps') +
							window.minfps +
							this.$t('popup.tips.bigestfps') +
							window.maxfps;
					}
				});
			},

			/**
			 * 切换核型图
			 */
			switchKaryoHandle(karyoid, isInit) {
				// this.assistantMarker = true;
				window.chromoMap = [];
				if (this.cachePool.isInit() || isInit) {
					this.switchKaryoCallback(karyoid);
				} else {
					this.preservation(() => {
						this.switchKaryoCallback(karyoid);
					}, true);
					//   this.saveConfirm = this.$confirm(
					//     this.$t('popup.change_karyo.content'), this.$t('popup.change_karyo.title'),
					//     {
					//       type: "warning",
					//       distinguishCancelAndClose: true,
					//       customClass: "save-confirm-box",
					//     }
					//   )
					//     .then(() => {
					//       this.preservation(() => {
					//         this.switchKaryoCallback(karyoid);
					//       }, true);
					//       this.saveConfirm = null;
					//     })
					//     .catch((action) => {
					//       if (action === "cancel") {
					//         this.switchKaryoCallback(karyoid);
					//       } else {
					//         this.isPageLoading = false;
					//       }
					//       this.saveConfirm = null;
					//     });
				}
			},

			/**
			 * 切换核型图过程
			 */
			switchKaryoCallback(karyoid, cb) {
				if (this.ajaxLoading) {
					return;
				}
				clearTimeout(this.heartTimer);
				this.heartTimer = null;
				this.isPageLoading = true;
				this.karyoId = karyoid;
				window.startTime = +new Date();
				window.loadingInstance = this.$loading({
					background: "rgba(255,255,255,.3)",
					customClass: "autovision-loading-switch",
					text: this.$t('popup.change_karyo.changing'),
				});
				this.karyoController.stage.clearAllChild();
				this.arrangeController.stage.clearAllChild();
				this.karyoController.stage.draw();
				this.arrangeController.stage.draw();
				this.arrangeController.initFinished = false;
				this.queryOss(this.karyoId, () => {
					// console.log(this.karyoController);
					this.downloadOptImages(this.karyoId, () => {
						this.karyotypeExpression = this.karyoInfo.karyotypeExpression;
						this.remark = this.karyoInfo.remark;
						if (this.standardVisible) {
							this.toggleStandard();
						}

						this.karyoController.reset(
							this.chromoList,
							this.karyoInfo,
							!this.karyoInfo.originalArrowLines ?
							[] :
							JSON.parse(this.karyoInfo.originalArrowLines)
						);
						this.arrangeController.reset(
							this.chromoList,
							this.karyoInfo,
							!this.karyoInfo.arrangeArrowLines ?
							[] :
							JSON.parse(this.karyoInfo.arrangeArrowLines)
						);
						// this.isShowPrimaryImage = false;

						this.cachePool.reset();
						this.cachePool.save();
						this.toolsType = this.globalMode === 'arrange' ? "general" : 'count';
						typeof cb === 'function' && cb();
						// const str = window.btoa(
						//     [
						//         encodeURIComponent(this.caseName),
						//         this.caseId,
						//         this.karyoId,
						//     ].join(",")
						// );
						// this.$router.replace("?t=" + str);
						setTimeout(() => {
							this.isPageLoading = false;
						}, 1000);
					});
				});
			},

			/**
			 * 显示隐藏标准染色体
			 */
			toggleStandard() {
				this.standardVisible = !this.standardVisible;
				this.arrangeController.toggleStandard(
					this.standardInfo,
					this.standardImage,
					this.standardVisible
				);
				if (this.toolsType === 'banding' && this.standardVisible) {
					this.toolsType = this.globalMode === 'arrange' ? "general" : 'count';
				}
			},

			/**
			 * 下载当前优化小图
			 */
			downloadOptImages(id, callback) {
				this.toolsType = this.globalMode === 'counter' ? "count" : "general";
				if (typeof callback === 'function') {
					callback();
				} else {
					this.initKaryoStage();
					this.initArrangeStage();
					let timer = setInterval(() => {
						if (this.karyoController.initFinished && this.arrangeController.initFinished) {
							clearInterval(timer);
							timer = null;
							this.initKaryoAndArrange();
						}
					}, 20)
				}


				return;
				let count = 0;
				this.originOptUrl = {};
				this.chromoList.forEach((chromo) => {
					const image = new Image();
					image.setAttribute("crossOrigin", "Anonymous");
					image.onload = () => {
						this.originOptUrl[chromo.index].img = image;
						check();
					};
					image.onerror = () => {
						check();
					};
					const src = this.getBase64FromEntries(id, chromo.justUrl);
					image.src = src;

					this.originOptUrl[chromo.index] = {
						src: src,
						scale: 1,
					};
				});
				const check = () => {
					count++;
					if (count >= this.chromoList.length) {
						typeof callback === "function" ?
							callback() :
							this.initArrangeStage();
						// : this.initKaryoAndArrange();
					}
				};
			},

			/**
			 * 获取图片对应的base64
			 * @param {Object} src
			 */
			getBase64FromEntries(id, src) {
				if (src.indexOf("base64,") > -1 || !id || !this.entries) {
					return src;
				}
				const _name = src.split("?")[0].split("/").pop();
				const key = ["karyo", id, _name].join("_");
				return this.entries[key] || src;
			},

			/**
			 * 将zip包链接加入到下载队列
			 */
			addUrlToQueue(id, url, isLoaded = false) {
				const name = url.split("?")[0].split("/").pop();
				const exist = this.downloadQueue.some((item) => item.name === name);
				if (!exist) {
					this.downloadQueue.push({
						id,
						name,
						url,
						isLoaded,
					});
				}
			},

			/**
			 * 预加载下一个核型图的zip包
			 */
			asyncLoadZip() {
				const load = (n) => {
					// console.log(n, this.downloadQueue.length, !this.$store.state.heartbeating);
					if (n >= this.downloadQueue.length || this.destroyedStatus) {
						return;
					}
					const item = this.downloadQueue[n];
					// console.log(item, +new Date());
					if (!item.isLoaded) {
						downloadZip(item.url)
							.then((entries) => {
								entries.forEach((data) => {
									const key = [
										"karyo",
										item.id,
										data.filename,
									].join("_");
									this.entries[key] = data.base64;
								});
								// !this.entries[item.id] && (this.entries[item.id] = []);
								// this.entries[item.id] = this.entries[item.id].concat(entries);
								// console.log(entries.map(item => item.filename), +new Date());
								item.isLoaded = true;
								window.entiresPool = this.entries;
								load(n + 1);
							})
							.catch((status) => {
								if (status == 404) {
									item.isLoaded = true;
									item.status = status;
								}
								load(n + 1);
							});
					} else {
						load(n + 1);
					}
				};
				load(0);
			},

			/**
			 * 画笔粗细大小改变
			 */
			brushThicknessChange(e) {
				if (this.toolsType === "eraser") {
					this.eraserBrushThickness = e;
				} else {
					this.paintBrushThickness = e;
				}
			},

			/**
			 * 批注
			 */
			remarkChange(e) {
				this.remark = e.target.value;
			},

			/**
			 * 核型表达式修改
			 */
			karyotypeExpressionChange(e) {
				this.expressionManuallyModify = true;
				this.karyotypeExpression = e.target.value;
			},

			/**
			 * 核型表达式输入框获得焦点
			 */
			karyotypeExpressionFocus() {
				this._karyotypeExpression = this.karyotypeExpression;
			},

			/**
			 * 核型表达式输入框失去焦点
			 */
			karyotypeExpressionBlur() {
				if (
					this.karyotypeExpression !== this._karyotypeExpression &&
					!this.karyoInfo.isPreserSure
				) {
					this.karyoInfo.isPreserSure = 1;
				}
			},

			/**
			 * 加密
			 * @param {Object} str
			 */
			binaryzip(str) {
				var binaryString = pako.gzip(str, {
					to: "string",
				});
				return window.btoa(binaryString);
			},

			/**
			 * 解压
			 * @param {Object} key
			 */
			unzip(key) {
				// 将二进制字符串转换为字符数组
				if (key) {
					if (Object.prototype.toString.call(key) !== "[object String]") {
						return [];
					}
					let atobData =
						(window.atob(key) && window.atob(key).split("")) || [];
					var charData =
						atobData &&
						atobData.map(function(x) {
							return x.charCodeAt(0);
						});
					// 将数字数组转换成字节数组
					var binData = new Uint8Array(charData); // 解压
					var data = pako.inflate(binData);
					return String.fromCharCode.apply(null, new Uint16Array(data));
				} else {
					return [];
				}
			},

			/**
			 * 自动排列所有待排列染色体
			 */
			arrange26() {
				let list = this.chromoList
					.filter((a) => a.chromoId >= 26)
					.map((chromo) => {
						return {
							id: chromo.id,
							chromoId: chromo.chromoId,
							justUrl: chromo.invertedUrl,
							karyoId: chromo.karyoId,
							chromoScore: chromo.chromoScore ?
								chromo.chromoScore :
								0,
							index: chromo.index,
						};
					});
				list = this.cachePool.replaceBase64(list);
				this.arrangeByNewChromolist(list, 1);
			},

			/**
			 * 自动排列所有染色体
			 */
			arrangeAll() {
				let list = this.chromoList.map((chromo) => {
					return {
						id: chromo.id,
						chromoId: chromo.chromoId,
						justUrl: chromo.invertedUrl,
						karyoId: chromo.karyoId,
						chromoScore: chromo.chromoScore ? chromo.chromoScore : 0,
						index: chromo.index,
					};
				});
				list = this.cachePool.replaceBase64(list);
				this.arrangeByNewChromolist(list, 2);
			},

			/**
			 * 请求服务端进行自动排列 对返回的结果与本地染色体列表进行关联更改属性
			 */
			arrangeByNewChromolist(chromos, type) {
				console.log(chromos);
				const param = {
					karyoId: this.karyoId,
					chromos: this.binaryzip(JSON.stringify(chromos)),
					type,
				};
				let loading = this.$loading({
					background: "rgba(255,255,255,.3)",
					text: this.$t('popup.change_karyo.arraying'),
					customClass: "autovision-loading",
				});
				this.ajaxLoading = true;
				let count = 0,
					total;
				const check = () => {
					if (count >= total) {
						this.cachePool.save();
						this.cachePool.record({
							type: "chromoRecog",
							message: this.$t('popup.change_karyo.auto_array'),
							data: param,
							result: this.chromoList,
						});
						this.karyoController.resetKaryo();
						this.arrangeController.reArrange();
					}
				}
				const getTotal = (result) => {
					let n = 0;
					result.forEach(a => {
						let item = chromos.filter(b => a.id === b.id && a.index === b.index && a.chromoId !== b
							.chromoId);
						n += item.length;
					})
					return n;
				}
				this.$api.chromoRecog(param).then((res) => {
					if (res.code == 200) {
						const result = JSON.parse(this.unzip(res.data));
						console.warn(result);
						if (result.length) {
							total = type === 1 ? result.length : getTotal(result);
							console.warn('total', total)
							result.forEach((item) => {
								this.chromoList.forEach((chromo) => {
									if ((chromo.id === item.id || chromo.index === item.index) &&
										item.justUrl) {
										// item.invertedUrl = item.justUrl;
										delete item.justUrl;
										chromo = Object.assign(chromo, item);
										if (item.chromoUpright == 1) {
											getRotateObj(
												chromo.invertedUrl,
												180
											).then((res) => {
												var _img = new Image();
												_img.src = res;
												_img.onload = () => {
													chromo.justUrl = res;
													chromo.image = _img;
													chromo.imgHandledRotatedDegree =
														180;
													count++;
													check();
												};
											});
										} else {
											count++;
											check();
										}
									}
								});
							});

						}
					} else {
						this.$message({
							message: res.message,
							type: "warning",
						});
					}
					loading.close();
					this.ajaxLoading = false;
				});
			},

			/**
			 * 更新确认计数与确认排列的数量
			 */
			uploadSureCount(notloadZip) {
				this.$api
					.queryKaryoList({
						patientId: this.caseId,
						limit: 1000
					})
					.then((res) => {
						setTimeout(() => {
							this.saving = false;
						}, 1000)
						if (res.code == 200) {
							this.$emit("updateAnalys", res.data);
							// this.karyoList = res.data.pageUtil.list;
							// this.glassName = this.karyoList.filter(
							//     (item) => item.id === this.karyoId
							// )[0].glassName;
							// this.karyoTopList = this.karyoList.filter(a => a.isTop);
							// this.karyoNormalList = this.karyoList.filter(a => !a.isTop);
							// this.downloadZip();
							this.SingleSureCount = res.data.statisInfo.singleSureNum;
							this.TypeSureArray = res.data.statisInfo.typeSureNum;
							this.$emit("updateCounterArrange", this.SingleSureCount, this.TypeSureArray);
							// this.karyoList.forEach((item) => {
							//     // item._optUrl = this.ossUrlWebFir(
							//     //     item.optUrl.split("?")[0],
							//     //     1,
							//     //     100,
							//     //     100
							//     // );
							//     if (item.zipJEOssUrl && !notloadZip) {
							//         this.addUrlToQueue(item.id, item.zipJEOssUrl);
							//     }
							//     if (item.zipOssUrl && !notloadZip) {
							//         this.addUrlToQueue(item.id, item.zipOssUrl);
							//     }
							// });
							// !notloadZip && this.asyncLoadZip();
							// this.preloadPrimaryImage();
						} else {
							this.$message.error(res.message);
						}
					});
			},

			/**
			 * 确认计数
			 */
			confirmCount() {
				let loading = this.$loading({
					background: "rgba(255,255,255,.3)",
					text: this.$t('popup.warning.confirming'),
					customClass: "autovision-loading",
				});
				this.ajaxLoading = true;
				this.getParamAfterUploadArrayUrl((param) => {
					console.log(param);
					this.$api.confirmCount(param).then((res) => {
						loading.close();
						this.ajaxLoading = false;

						if (res.code == 200) {
							this.remoteVersion = res.data.karyoVersion;
							this.isSingleSure = 1;
							this.karyoInfo.isSingleSure = 1;
							this.cachePool.initData.karyoInfo = JSON.stringify(
								this.karyoInfo
							);
							this.karyoList.forEach(item => {
								if (item.id === this.karyoId) {
									item.isSingleSure = 1;
									item.singleNum = this.karyoInfo.singleNum;
									item.karyotypeExpression = this.karyoInfo.karyotypeExpression;
								}
							})
							this.$store._mutations.updateAnalysKaryoInfo(this.karyoInfo, 'isSingleSure');
							this.$store._mutations.updateAnalysKaryoInfo(this.karyoInfo, 'singleNum');
							this.$store._mutations.updateAnalysKaryoInfo({
								speedProgress: 2,
								patientId: this.caseId,
								id: this.karyoId
							}, 'speedProgress');
							this.$forceUpdate();
							this.uploadSureCount();
							this.cachePool.initData = this.cachePool.getData();
							this.changeKaryo(true, true)
						} else {
							this.$message({
								message: res.message,
								type: "warning",
							});
						}
					});
				}, true);
			},

			/**
			 * 确认排列
			 */
			confirmArrange() {
				let loading = this.$loading({
					background: "rgba(255,255,255,.3)",
					text: this.$t('popup.warning.confirming'),
					customClass: "autovision-loading",
				});
				this.ajaxLoading = true;
				this.getParamAfterUploadArrayUrl((param) => {
					this.$api.confirmArrangement(param).then((res) => {
						loading.close();
						this.ajaxLoading = false;
						if (res.code == 200) {
							this.remoteVersion = res.data.karyoVersion;
							this.isSingleSure = 1;
							this.karyoInfo.isSingleSure = 1;
							this.isTypeSure = 1;
							this.karyoInfo.isTypeSure = 1;
							this.cachePool.initData.karyoInfo = JSON.stringify(
								this.karyoInfo
							);
							this.karyoList.forEach(item => {
								if (item.id === this.karyoId) {
									item.isSingleSure = 1;
									item.isTypeSure = 1;
									item.singleNum = this.karyoInfo.singleNum;
									item.karyotypeExpression = this.karyoInfo.karyotypeExpression;
								}
							})
							this.$store._mutations.updateAnalysKaryoInfo(this.karyoInfo, 'isSingleSure');
							this.$store._mutations.updateAnalysKaryoInfo(this.karyoInfo, 'isTypeSure');
							this.$store._mutations.updateAnalysKaryoInfo(this.karyoInfo, 'singleNum');
							this.$store._mutations.updateAnalysKaryoInfo({
								speedProgress: 3,
								patientId: this.caseId,
								id: this.karyoId
							}, 'speedProgress');
							this.$store._mutations.updateAnalysKaryoInfo({
								arrayUrl: this.ossUrlWebFir(param.arrayUrl, 1, 500),
								patientId: this.caseId,
								id: this.karyoId
							}, 'arrayUrl');

							this.$forceUpdate();
							this.uploadSureCount();
							this.cachePool.initData = this.cachePool.getData();
							this.changeKaryo(true, true);
						} else {
							this.$message({
								message: res.message,
								type: "warning",
							});
						}
					});
				});
			},

			/**
			 * 取消确认
			 */
			cancelArrange() {
				let loading = this.$loading({
					background: "rgba(255,255,255,.3)",
					text: this.$t('new_tools.Is_cancelled'),
					customClass: "autovision-loading",
				});
				this.ajaxLoading = true;
				this.$api.cancelArrange({
					karyoId: this.karyoId
				}).then(res => {
					loading.close();
					this.ajaxLoading = false;
					if (res.code == 200) {
						this.$message({
							message: this.$t('new_tools.Cancelled_confirmation'),
							type: "success",
						});
						this.isSingleSure = 0;
						this.karyoInfo.isSingleSure = 0;
						this.isTypeSure = 0;
						this.karyoInfo.isTypeSure = 0;
						this.cachePool.initData.karyoInfo = JSON.stringify(
							this.karyoInfo
						);
						this.karyoList.forEach(item => {
							if (item.id === this.karyoId) {
								item.isSingleSure = 0;
								item.isTypeSure = 0;
							}
						})
						this.$store._mutations.updateAnalysKaryoInfo(this.karyoInfo, 'isSingleSure');
						this.$store._mutations.updateAnalysKaryoInfo(this.karyoInfo, 'isTypeSure');
						this.$store._mutations.updateAnalysKaryoInfo({
							speedProgress: 1,
							patientId: this.caseId,
							id: this.karyoId
						}, 'speedProgress');
						this.$store._mutations.updateAnalysKaryoInfo({
							arrayUrl: '',
							patientId: this.caseId,
							id: this.karyoId
						}, 'arrayUrl');
						this.uploadSureCount();
						this.cachePool.initData = this.cachePool.getData();
					} else {
						this.$message({
							message: res.message,
							type: "warning",
						});
					}
				})
			},

			/**
			 * 上一张
			 */
			prevKaryo() {
				if (this.karyoList.length <= 1) {
					return;
				}
				this.changeKaryo(false)
			},

			/**
			 * 下一张
			 */
			nextKaryo() {
				if (this.karyoList.length <= 1) {
					return;
				}
				this.changeKaryo(true)
			},


			/**
			 * 保存
			 */
			save() {
				this.preservation();
			},

			/**
			 * 保存并退出
			 */
			saveAndQuit() {
				this.preservation(() => {
					setTimeout(() => {
						this.clearStorage();
						this.$router.replace("/analysissystem");
					}, 300);
				});
			},

			/**
			 * ajax保存
			 */
			preservation(cb, isNotLoadNext) {
				const param = this.cachePool.getAjaxParams();
				let loading = this.$loading({
					background: "rgba(255,255,255,.3)",
					text: this.$t('case_manage.saving'),
					customClass: "autovision-loading",
				});
				this.ajaxLoading = true;
				this.getParamAfterUploadArrayUrl((param) => {
					this.$api.preservation(param).then((res) => {
						loading.close();
						this.ajaxLoading = false;
						this.$message({
							message: res.code == 200 ? this.$t(
								'selfDefined_template.save_success') : res.message,
							type: res.code == 200 ? "success" : "warning",
						});
						this.cachePool.initData = this.cachePool.getData();
						res.code == 200 &&
							(this.remoteVersion = res.data.karyoVersion);
						this.karyoList.forEach(a => {
							if (a.id === this.karyoId) {
								a.singleNum = this.karyoInfo.singleNum;
								a.karyotypeExpression = this.karyoInfo.karyotypeExpression;
							}
						})
						this.$store._mutations.updateAnalysKaryoInfo(this.karyoInfo, 'singleNum');
						if (this.karyoInfo.isTypeSure) {
							this.$store._mutations.updateAnalysKaryoInfo({
								arrayUrl: this.ossUrlWebFir(param.arrayUrl, 1, 500),
								patientId: this.caseId,
								id: this.karyoId
							}, 'arrayUrl');
						}
						this.$forceUpdate();
						!isNotLoadNext && this.uploadSureCount();
						!isNotLoadNext && this.changeKaryo(true, true)
						this.isPageLoading = false;
						typeof cb === "function" && res.code == 200 && cb();
					});
				});
			},

			clearStorage() {
				localStorage.removeItem("patient_opened_" + this.caseId);
				localStorage.removeItem("patient_randomString_" + this.caseId);
				clearTimeout(this.heartTimer);
				this.heartTimer = null;
				this.$store.state.heartbeating = false;
				console.log('clearStorage');
				try {
					// this.$parent.actionCloseConfirmHandle();
					this.$parent.patient = {};
					this.$parent.currentTabIndex = 0;
				} catch (e) {
					//TODO handle the exception
				}

			},
			prevCloseHandle(cb) {
				// this.returnAnalys();
				const callback = typeof cb === "function" ? cb : this.clearStorage;
				if (this.cachePool.isInit()) {
					callback();
				} else {
					this.preservation(callback, true);
				}
			},
			/**
			 * 返回分析页
			 */
			returnAnalys() {
				if (this.cachePool.isInit()) {
					this.clearStorage();
				} else if (!this.isNotMoveOverFiveMinites) {
					this.saveConfirm = this.$confirm(
							this.$t('popup.change_karyo.content'), this.$t('popup.change_karyo.title'), {
								type: "warning",
								distinguishCancelAndClose: true,
								customClass: "save-confirm-box",
                                closeOnClickModal: false
							}
						)
						.then(() => {
							this.clearStorage();
							this.preservation(() => {
								this.clearStorage();
							}, true);
							this.saveConfirm = null;
						})
						.catch((action) => {
							if (action === "cancel") {
								this.clearStorage();
							} else {
								this.isPageLoading = false;
							}
							this.saveConfirm = null;
						});
				} else {
					this.clearStorage();
				}
			},

			getParamAfterUploadArrayUrl(fn, isNotUpload) {
				const param = this.cachePool.getAjaxParams();
				param.chromos = this.binaryzip(param.chromos);
				if (isNotUpload) {
					typeof fn === "function" && fn(param);
					return;
				}
				const getName = (name, format) => {
					return [
						this.caseName,
						this.caseId,
						this.karyoId,
						[name, format].join("."),
					].join("_");
				};
				const sp = this.karyoInfo.primaryUrl.split("?")[0].split("/");
				sp.splice(-2, 2, "array", "");
				const path = sp.splice(3).join("/");
				const format = "jpeg";
				const name = getName("arrayUrl", format);
				const fullUrl = [config.ossUrl, path + name].join("/");
				param.arrayUrl = fullUrl;
				this.arrangeController
					.exportBase64({
						isHideXY: false,
						format: format,
						zoom: 0.5
					})
					.then((base64) => {
						// console.log(base64);
						// const format = base64.split(";")[0].split("/")[1];
						const file = this.cachePool.dataURLtoBlob(base64, name);
						this.doupload([file], path, (url) => {
							// !!url && (param.arrayUrl = url);
							if (url) {
								// this.$api.updateKaryoArrayPic({
								//     patientId: this.caseId,
								//     karyoId: this.karyoId,
								//     arrayUrl: url
								// })
								typeof fn === "function" && fn(param);
							}
						});
					});
			},

			/**
			 * 导出的base64上传至OSS
			 */
			doupload(files, path, cb) {
				uploader({
						files: files,
						path: path,
						ossUploadAccess: this.$store.state.ossUploadAccess,
					})
					.then((filelist) => {
						const target = filelist[0];
						cb(target.path);
					})
					.catch((filelist) => {
						cb();
					});
			},

			/**
			 * 获取下载文件的名称
			 */
			getDownLoadFileName(type) {
				let sparr = this.karyoInfo.optUrl
					.split("?")[0]
					.split("/")
					.pop()
					.split("_");
				let patientName = this.karyoInfo.patientName || sparr[0];
				let glassName = this.karyoInfo.glassName || sparr[1];
				let cellName = this.karyoInfo.cellName;
				return [patientName, glassName + cellName, type, "png"].join(".");
			},

			/**
			 * 下载图片
			 */
			downloadImage(controller, isHideXY, type) {
				controller.exportBase64({
					isHideXY
				}).then((base64) => {
					let event = new MouseEvent("click");
					let a = document.createElement("a");
					const name = this.getDownLoadFileName(type);
					a.download = name || "download";
					a.target = "_blank";
					a.href = base64;
					a.dispatchEvent(event);
				});
			},

			/**
			 * 导出图片
			 */
			exportImages() {
				this.$confirm(this.$t('popup.warning.hide_sexKaryo'), this.$t('popup.warning.remind'), {
						confirmButtonText: this.$t('popup.warning.need'),
						cancelButtonText: this.$t('popup.warning.no_need'),
						type: "info",
						iconClass: "el-icon-question",
						distinguishCancelAndClose: true,
                        closeOnClickModal: false
					})
					.then((res) => {
						this.downloadImage(this.karyoController, true, 'A');
						this.downloadImage(this.arrangeController, true, 'K');
					})
					.catch((action) => {
						if (action === "cancel") {
							this.downloadImage(this.karyoController, false, 'A');
							this.downloadImage(this.arrangeController, false, 'K');
						}
					});
			},

			/**
			 * 切换视图
			 */
			switchStage() {
				const leftParentNode = document.querySelector(
					".autovision-left-top"
				);
				const rightParentNode = document.querySelector(
					".autovision-view-zone"
				);
				const leftChild = leftParentNode.getElementsByClassName("stage-canvas")[0];
				const rightChild = rightParentNode.getElementsByClassName("stage-canvas")[0];
				// console.log(leftChild,rightChild);
				leftParentNode.appendChild(rightChild);
				rightParentNode.appendChild(leftChild);
				this.isArrangeOnLeft = !this.isArrangeOnLeft;
				if (this.toolsType === 'paint' || this.toolsType === 'rotate') {
					this.toolsType = 'general'
				}
				this.$nextTick(() => {
					if (this.karyoController) {
						this.karyoController.stage.reset();
						this.karyoController.resetStage();
						this.karyoController.resetKaryo();
						this.polygonChromo(); //辅助标记
					}
					if (this.arrangeController) {
						this.arrangeController.stage.reset();
						this.arrangeController.resetStage();
						this.arrangeController.reArrange();
					}
				})

			},

			/**
			 * 确认箭头标注
			 */
			arrowMarkConfirm() {
				this.arrowMarkVisible = false;
				this.arrowMarkCallback();
			},

			/**
			 * 关闭染色体对比弹窗
			 */
			closeContrast() {
				this.contrastChromos = [];
				this.contrastVisible = false;
			},

			/**
			 * 撤销
			 */
			undo() {
				console.log("撤销");
				this.cachePool.undo((item) => {
					this.resetControllerStage(item);
				});
			},

			/**
			 * 恢复
			 */
			redo() {
				console.log("恢复");
				this.cachePool.redo((item) => {
					this.resetControllerStage(item);
				});
			},

			/**
			 * 重置控制器的stage
			 */
			resetControllerStage({
				chromoList,
				karyoInfo,
				arrangeArrows,
				karyoArrows,
				countPoints,
				banding,
				optImage,
				optimizeConfig
			}) {
				window.startTime = +new Date();
				this.chromoList = chromoList;
				this.karyoInfo = karyoInfo;
				this.arrangeController.karyoInfo = karyoInfo;
				this.karyoController.karyoInfo = karyoInfo;
				this.arrangeController.chromoList = chromoList;
				this.karyoController.chromoList = chromoList;
				this.arrangeController.arrowList = arrangeArrows;
				this.karyoController.arrowList = karyoArrows;
				this.karyoController.countPoints = countPoints.map((point) => {
					return this.karyoController.stage.graphs.point({
						point: point,
						lineWidth: 2,
						hide: true,
						length: 10,
						zindex: 2,
						color: "#f00",
					});
				});
				this.arrangeController.banding = banding;
				optImage && (this.karyoController.optImage = optImage);
				this.optimizeConfig = optimizeConfig || {};
				this.arrangeController.reArrange();
				this.karyoController.resetKaryo();
				if (this.toolsType === "count") {
					this.karyoController.stage.objects.forEach((item) => {
						if (item.type && item.type === "point") {
							item.hide = false;
						}
					});
					this.karyoController.stage.draw();
				}
				// this.arrangeController.reset(this.chromoList, this.karyoInfo, item.arrangeArrows);
				// this.karyoController.reset(this.chromoList, this.karyoInfo, item.karyoArrows);
			},
			/**
			 * 切换工具类型
			 */
			toolsTypeChange(type) {
				if (this.toolsType === type) {
					this.toolsType = this.globalMode === 'counter' ? 'count' : 'general'
				} else {
					this.toolsType = type;
				}
				if (type === 'banding') {
					this.standardVisible = false;
					this.arrangeController.toggleStandard(
						this.standardInfo,
						this.standardImage,
						this.standardVisible
					);
				}
			},
			/**
			 * 显示MAR染色体
			 */
			showMark() {
				window.startTime = +new Date();
				this.arrangeController.hasMar = !this.arrangeController.hasMar;
				if(!this.arrangeController.hasMar){
					this.chromoList.forEach(chromo=>{
						if(chromo.chromoId === 25){
							chromo.chromoId = 26;
						}
					})
				}
				this.arrangeController.reArrange();
			},
			/**
			 * 设置颜色标记
			 */
			updateColor(color) {
				this.$api.updateColor({
					karyoId: this.karyoId,
					color
				}).then(res => {
					if (res.code == 200) {
						this.karyoInfo.color = color;
						this.$store._mutations.updateAnalysKaryoInfo(this.karyoInfo, 'color');
						this.karyoList.forEach(item => {
							item.id === this.karyoId && (item.color = color);
						})
						const info = JSON.parse(this.cachePool.initData.karyoInfo);
						info.color = color;
						this.cachePool.initData.karyoInfo = JSON.stringify(info);
					}
				})
			},

			/**
			 * 监听空格事件
			 */
			onShortKeySpace() {
				let isSingleSure = this.karyoInfo.isSingleSure;
				let isTypeSure = this.karyoInfo.isTypeSure;
				const confirm = (content, handle) => {
					this.saveConfirm = this.$confirm(content, this.$t('popup.change_karyo.title'), {
							type: "warning",
							distinguishCancelAndClose: true,
							customClass: "save-confirm-box",
                            closeOnClickModal: false
						})
						.then(() => {
							handle();
							this.saveConfirm = null;
						})
						.catch((action) => {
							this.saveConfirm = null;
						});
				}
				const check = () => {
					if (this.globalMode === 'counter') {
						confirm(this.$t('popup.change_karyo.confirm_counter'), this.confirmCount)
					} else {
						confirm(this.$t('popup.change_karyo.confirm_arrange'), this.confirmArrange)
					}

					// isSingleSure = this.karyoInfo.isSingleSure;
					// isTypeSure = this.karyoInfo.isTypeSure;
					// if (!isSingleSure) {
					//     this.confirmCount();
					// } else if (!isTypeSure) {
					//     this.confirmArrange();
					// } else {
					//     this.save();
					// }
				};

				//复检
				shortKey.on({
						key: "space",
					},
					() => {
						if (this.saveConfirm) {
							let nodes = document.getElementsByClassName(
								"save-confirm-box"
							)[0].childNodes[2].childNodes[1];
							nodes.click();
						} else {
							// this.whetherRepeatInspect = !this.whetherRepeatInspect;
							check();
						}
					},
					true
				);
				//复检
				shortKey.on({
						key: "enter",
					},
					() => {
						if (this.saveConfirm) {
							let nodes = document.getElementsByClassName(
								"save-confirm-box"
							)[0].childNodes[2].childNodes[1];
							nodes.click();
						}
					},
					true
				);
			},
			addKaryoEventListener() {
				console.warn("分裂相画布绑定事件");
				const stageK = this.karyoController.stage;
				// 展示推荐切割线
				Tools.listener.recommendCutting.call(
					this,
					stageK,
					this.karyoController
				);
				//染色体计数
				Tools.listener.count.call(this, stageK, this.karyoController);
				// 染色体连接
				Tools.listener.connect.call(this, stageK, this.karyoController);

				//划线切割
				Tools.listener.cutting.call(this, stageK, this.karyoController);
				//区域擦除切割
				Tools.listener.easerArea.call(this, stageK, this.karyoController);
				// 涂抹功能
				Tools.listener.paint.call(this, stageK, this.karyoController);
				//删除
				Tools.listener.deleteShape.call(this, stageK, this.karyoController);
				//点击空白切换窗口
				Tools.listener.switchStage.call(this, stageK);
				//优化图 缩放
				Tools.stageScale.call(this, stageK);
				//优化图 拖动
				Tools.stageDrag.call(this, stageK);
				//优化图 箭头的添加与移动
				Tools.listener.arrowKaryo.call(this, stageK, this.karyoController);
			},
			addArrangeEventListener() {
				console.warn("排列图画布绑定事件");
				const stageA = this.arrangeController.stage;
				//排列图 缩放
				Tools.stageScale.call(this, stageA);
				//排列图 拖动
				Tools.stageDrag.call(this, stageA);
				//排列图 染色体拖动
				Tools.listener.chromoDrag.call(this, stageA);
				//排列图 箭头的添加与移动
				Tools.listener.arrowArrange.call(this, stageA, this.arrangeController);
				//缩放染色体
				stageA.event.mousewheel({
					ctrlKey: true
				}, ({
					e
				}) => {
					window.startTime = +new Date();
					this.arrangeController.scaleChromos(
						this.arrangeController.chromoScale - 10 / e.deltaY
					);
					this.arrangeController.reCreateStandChromo();
				});
				//划线切割
				Tools.listener.cutting.call(this, stageA, this.arrangeController);
				//区域擦除切割
				Tools.listener.easerArea.call(this, stageA, this.arrangeController);
				// 旋转功能
				Tools.listener.rotate.call(this, stageA, this.arrangeController);
				//删除
				Tools.listener.deleteShape.call(
					this,
					stageA,
					this.arrangeController
				);
				//橡皮擦
				Tools.listener.easer.call(this, stageA, this.arrangeController);
				Tools.listener.switchStage.call(this, stageA);
				Tools.listener.scaleSingleChromo.call(
					this,
					stageA,
					this.arrangeController
				);
				//排列图 定带
				Tools.listener.banding.call(this, stageA, this.arrangeController);
			},
			smallKaryoCanvas() {
				let stage = this.globalModeIs();
				Tools.stageBtnScale.call(this, stage, 'small');
			},
			enchargeKaryoCanvas() {
				let stage = this.globalModeIs();
				Tools.stageBtnScale.call(this, stage, 'enlarge');
			},
			globalModeIs() {
				let stage;
				if (this.globalMode === 'arrange' && this.isArrangeOnLeft) {
					stage = this.arrangeController.stage;
				} else {
					stage = this.karyoController.stage;
				}
				return stage
			},
			/**
			 * 监听事件
			 */
			addEventListener() {
				let resizeTimer = null;
				window.addEventListener("resize", () => {
					this.karyoController.stage.clearAllChild();
					this.karyoController.resetStage();
					this.karyoController.resetKaryo();
					this.arrangeController.stage.clearAllChild();
					this.arrangeController.resetStage();
					this.arrangeController.reArrange();
				});
				const stageA = this.arrangeController.stage;
				const stageK = this.karyoController.stage;
				this.addKaryoEventListener();
				this.addArrangeEventListener();
				//选中排列图染色体 同时选中核型图染色体
				Tools.listener.chooseArrange.call(this, stageA, stageK);
				//选中核型图染色体 同时选中排列图染色体
				Tools.listener.chooseKaryo.call(this, stageK, stageA);

				//染色体对比
				Tools.listener.chromoContrast.call(this);
			},

			/**
			 * 绑定快捷键
			 */
			addShortKeys() {
				//向下切换核型图
				shortKey.on({
						key: "arrowdown",
					},
					() => {
						this.changeKaryo(true);
					},
					true
				);

				//向上切换核型图
				shortKey.on({
						key: "arrowup",
					},
					() => {
						this.changeKaryo(false);
					},
					true
				);

				this.onShortKeySpace();

				//切换原图与优化图
				shortKey.on({
						key: "o",
					},
					() => {
						this.switchPrimaryOrOpt(!this.isShowPrimaryImage);
					},
					true
				);

				//M 切换显示MAR
				shortKey.on(
					"keypress", {
						key: "m",
					},
					() => {
						this.showMark();
					}
				);

				//撤销
				shortKey.on(
					"keypress", {
						key: "z",
						ctrlKey: true,
					},
					() => {
						// this.$refs['undoBtn'].click();
						this.undo();
					}
				);

				//恢复
				shortKey.on(
					"keypress", {
						key: "y",
						ctrlKey: true,
					},
					() => {
						// this.$refs['redoBtn'].click();
						this.redo();
					}
				);

				//保存
				shortKey.on(
					"keypress", {
						key: "s",
						ctrlKey: true,
					},
					() => {
						// this.$refs["saveBtn"].click();
						this.save()
					}
				);

				//删除
				shortKey.on(
					"keypress", {
						key: "d",
					},
					this.deleteChromo
				);

				//删除
				shortKey.on(
					"keypress", {
						key: "delete",
					},
					this.deleteChromo
				);

				//删除
				shortKey.on(
					"keypress", {
						key: "backspace",
					},
					this.deleteChromo
				);

				let isHidePolygon = false;
				//万能工具模式下 M键绑定
				shortKey.on(
					"keypress", {
						key: "w",
					},
					this.hidePolygon
				);
				//TAB键 切换工具
				shortKey.on(
					"keypress", {
						key: "tab",
					},
					() => {
						// if (this.toolsType === "general") {
						//     this.toolsType = "count";
						// } else {
						//     this.toolsType = "general";
						// }
						this.globalMode = this.globalMode === "arrange" ? 'counter' : 'arrange'
					}
				);
			},
			resetExpressionStyle(nv) {
				this.expressionStyle = '';
				if (nv.length > 8) {
					this.expressionStyle = 'row1';
				}
				if (nv.length > 13) {
					this.expressionStyle = 'row2';
				}
				if (nv.length > 36) {
					this.expressionStyle = 'row3';
				}
				this.expressionRow = nv.length < 9 ? 1 : (nv.length > 16 ? 3 : 2)
			}
		},
		watch: {
			chromoList(newValue, oldValue) {
				// console.log(newValue);
				if (!this.chromoList.includes(this.choosedChromo)) {
					this.choosedChromo = null;
				}
				const unum = JSON.parse(
					this.karyoInfo.undetectedChromoPoints || "[]"
				).length;
				const list = this.chromoList
					.map((a) => {
						if (typeof a.countPoints === "string") {
							return JSON.parse(a.countPoints).length;
						}
						if (typeof a.countPoints === "object") {
							return a.countPoints.length || 1;
						}
						return 0;
					})
				this.karyoInfo.singleNum = list.length ? (list.reduce((a, b) => a + b) + unum) : 0;
				// console.warn(this.karyoInfo.singleNum, this.chromoList.length)
				if (this.arrangeController && this.arrangeController.initFinished) {
					this.arrangeController.chromoList = newValue;
					const result = this.arrangeController.createKaryotypeExpression();
				}
				if (this.karyoController) {
					this.karyoController.chromoList = newValue;
				}
				// if (
				//     Math.abs(newValue.length - oldValue.length) >= 2 &&
				//     oldValue.length > 0
				// ) {
				//     this.arrangeController.reArrange();
				//     this.karyoController.resetKaryo();

				// }
				this.reCreateJsMat();
			},
			//工具类型
			toolsType(newValue, oldValue) {
				if (newValue === "same") {
					this.contrastType = "signle";
				}
				if (newValue === "diff") {
					this.contrastType = "double";
				}
				if (newValue === "count") {
					this.karyoController.stage && this.karyoController.stage.objects.forEach((item) => {
						if (item.type && item.type === "point") {
							item.hide = false;
						}
					});
					this.karyoController.stage && this.karyoController.stage.draw();
				} else if (oldValue === "count") {
					this.karyoController.stage && this.karyoController.stage.objects.forEach((item) => {
						if (item.type && item.type === "point") {
							item.hide = true;
						}
					});
					this.karyoController.stage && this.karyoController.stage.draw();
				}
				if (newValue === "eraser") {
					this.brushThickness = this.eraserBrushThickness;
				}
				if (newValue === "paint") {
					this.brushThickness = this.paintBrushThickness;
				}
			},
			shortKeyType(newValue, oldValue) {
				if (newValue === "shiftleft" || newValue === "shiftright") {
					this.brushThickness = this.paintBrushThickness;
				}
			},
			//快捷键启用与禁用
			arrowMarkVisible(newValue, oldValue) {
				shortKey.disabled = newValue;
			},
			shortKeyDisabled(newValue, oldValue) {
				shortKey.disabled = newValue;
			},
			contrastVisible(newValue, oldValue) {
				if (!newValue) {
					this.contrastChromos = [];
				}
			},
			version(newValue, oldValue) {
				if (!window.isNetworkError) {
					return;
				}
				if (
					new Date().getTime() - this.heartbeatLastSuccessTime <= 30000 &&
					window.isNetworkError
				) {
					this.networkErrorMsg(this.$t('popup.warning.network_abnormal'));
				}
				if (
					new Date().getTime() - this.heartbeatLastSuccessTime > 30000 &&
					window.isNetworkError
				) {
					this.networkErrorMsg(this.$t('popup.warning.off_connect'));
				}
			},
			karyoId(nv, ov) {
				if (nv && !ov) {
					this.switchKaryoHandle(nv);
				}
				const info = this.karyoList.filter(a => a.id === nv)[0];
				this.glassName = info ? info.glassName : '';
			},
			globalMode(nv, ov) {
				console.log(nv === 'arrange' , this.isArrangeOnLeft,nv === 'arrange' && this.isArrangeOnLeft);
				clearTimeout(TIMER);
				const todo = () => {
					if (nv === 'counter' && this.isArrangeOnLeft) {
						this.switchStage();
					}
					if (nv === 'arrange') {
						this.switchStage();
					}
					this.toolsType = nv === 'counter' ? "count" : "general";
					this.$nextTick(() => {
						if (this.karyoController) {
							this.karyoController.resetStage();
							this.karyoController.resetKaryo();
							this.polygonChromo();
						}
						if (this.arrangeController) {
							this.arrangeController.resetStage();
							this.arrangeController.reArrange();
						}
					})
				}
				if ((this.karyoController && !this.karyoController.initFinished) || (this.arrangeController && !this.arrangeController.initFinished)) {
					TIMER = setTimeout(() => {
						todo();
					}, 200)
					return;
				} else {
					todo();
				}
				try{
					let stage = this.globalModeIs();
					Tools.stageBtnScale.call(this, stage, '');
				}catch(e){
					//TODO handle the exception
				}
				
			},
			karyotypeExpression(nv) {
				this.resetExpressionStyle(nv);
			},
			optimizeVisible(nv) {
				if (!nv) {
					this.VectorJSMat = null;
					this.VectorJSMatFinished = false;
				}
			},
			'$store.state.analysisInfo': function(newVal) {
				try{
					this.hasHideNumber= newVal.hasHideNumber || 0;
					this.karyoController.resetKaryo();
				}catch(e){
					//TODO handle the exception
				}
				
			}
		},
	};
</script>

<style lang="scss" scoped>
	.autovision-patient-info-dl dd label {
		font-size: 12px;
		text-align: justify;
		-moz-text-align-last: justify;
		text-align-last: justify;
	}

	.autovision-patient-info-dl dd span {
		font-size: 12px;
	}
    .clinicalDiagnosis{
        height: 50px;
        float: left;
        line-height: 16px;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        padding-top: 3px;
        word-break: break-all;
    }
	.el-scrollbar {
		width: 100%;
		height: 100%;
	}

	.el-scrollbar__wrap {
		height: 100%;
		overflow-x: hidden !important;
	}

	.el-scrollbar__thumb {
		background-color: rgba(144, 147, 153, 1);
	}

	.autovision {
		>.el-scrollbar {
			>.el-scrollbar__wrap {
				>.el-scrollbar__view {
					display: flex;
				}
			}
		}

		.allchromo-container {
			position: relative;
			// padding: 0 10px;
		}
	}
</style>
