<template>
  <div class="completeExpertCertificationBox">
    <div class="info-short" v-show="!detailShow && !editShow">
      <!-- <p class="title">{{$t('accountInfo.my_approve')}}</p> -->
      <div class="info-card" v-if="expertList" @click="detailInfo()">
        <span class="top-line" v-if="expertList.isExamine == 1">已认证</span>
        <ul class="info-list" v-if="expertList.isExamine == 1">
          <li class="li-line">
            <span>{{$t('accountInfo.name')}}</span>
            <label>{{expertList.realName}}</label>
          </li>
          <li class="li-line">
            <span>{{$t('accountInfo.affiliated_unit')}}</span>
            <label>{{expertList.hospital}}</label>
          </li>
          <li class="li-line">
            <span>{{$t('accountInfo.department')}}</span>
            <label>{{expertList.departmentName}}</label>
          </li>
        </ul>
        <div class="in_review" v-if="expertList.isExamine == 0">
          <h1>{{$t('accountInfo.audit')}}</h1>
          <p class="line">{{$t('accountInfo.audit_waiting')}}</p>
        </div>
        <div class="in_review" v-if="expertList.isExamine == 2">
          <h1 class="color_red">{{$t('accountInfo.audit_nopass')}}</h1>
          <button class="retry-btn" @click="retryToSubmit(expertList)">{{$t('accountInfo.re_auth')}}</button>
        </div>
        <img src="../../../assets/images/personalSettings/identyfy.png" class="bottom-img" alt="" />
      </div>
      <div class="card-add fl" @click="addNew()" v-if="!expertList">
        <img src="../../../assets/images/personalSettings/icon_add_normal.png" />
        <p>{{$t('accountInfo.add_identity_approve')}}</p>
      </div>
      <ul class="ul-list">
        <p class="p-tit">{{$t('accountInfo.auth_rights')}}</p>
        <li class="ul-li">{{$t('accountInfo.my_rights')}}</li>
        <li class="ul-li">{{$t('accountInfo.right_one')}}</li>
        <li class="ul-li">{{$t('accountInfo.right_two')}}</li>
      </ul>
    </div>
    <!-- 详情 -->
    <div class="info-detail" v-show="detailShow && !editShow">
      <a class="back-btn" @click="goback()"> 〈 {{$t('accountInfo.back')}}</a>
      <div class="completeImg">
        <img src="../../../assets/images/personalSettings/iconIdentity submitSuccessfully.svg" alt="" />
      </div>
      <div class="completeExpertCertificationText">{{$t('my_identity.finish_expert_identity_verify')}}</div>
      <div class="subjectContent">
        <div class="subjectContentLabel">
          <span>{{$t('expert_approve.name')}}</span>
          <span v-html="expertList.realName"></span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('expert_approve.the_unit')}}</span>
          <span v-html="expertList.hospital"></span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('expert_approve.the_office')}}</span>
          <span v-html="expertList.departmentName"></span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('expert_approve.office_tel')}}</span>
          <span v-html="expertList.departmentPhone">0232-24245454</span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('expert_approve.work_card')}}</span>
          <span @click="showLayerImage(0)">
            <img :src="expertList.breastplate" alt="" v-if="expertList.breastplate" />
          </span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('expert_approve.medical_practice_certificate')}}</span>
          <span @click="showLayerImage(1)">
            <img :src="expertList.practiceCertificate" alt="" v-if="expertList.practiceCertificate" />
          </span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('expert_approve.license_code')}}</span>
          <span v-html="expertList.licenseCode">152423422424</span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('expert_approve.doc_credentials')}}</span>
          <span @click="showLayerImage(2)">
            <img :src="expertList.qualification" alt="" v-if="expertList.qualification" />
          </span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('expert_approve.certification_code')}}</span>
          <span v-html="expertList.qualificationCode">152423422424</span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('expert_approve.front_back_IDcard')}}</span>
          <span v-for="(item, index) in expertList.idCard" :key="index" @click="showLayerImage(3+index)">
            <img :src="item" alt="" v-if="item" style="marginleft: 10px" />
          </span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('expert_approve.IDcode')}}</span>
          <span v-html="expertList.idCardNumber"></span>
        </div>
      </div>
      <!-- <div class="btns">
                <el-button type="primary" @click="edit()" class="save-btn btn">{{$t('btns.eidt')}}</el-button>
            </div> -->
    </div>
    <ExpertCertification v-show="editShow" :userId="userId" :detailInfo="detail" @childFun="popupUpdateFun($event)" @submitFun="editSubmit($event)"></ExpertCertification>
    <ImageLarge :isShowLayer="isShowLayer" :currentImageIndex="currentImageIndex" :currentImage="currentImage" :totalNum="totalNum" @changeCurrentImage="showCurImg" @showImage="showImg"></ImageLarge>
  </div>
</template>

<script>
import ExpertCertification from "./expertCertification";
import ImageLarge from "../components/imageLarge.vue"
export default {
  name: "CompleteExpertCertification",
  components: {
    ExpertCertification,
    ImageLarge
  },
  props: ["isSuccess"],
  data() {
    return {
      expertList: "",
      detailShow: false,
      editShow: false,
      userId: "",
      detail: "",
      isShowLayer: false,
      currentImageIndex: 0,
      currentImage: '',
      totalNum: 5,
      imgArr: []//图片集合
    };
  },
  created() {
    const { expert, user } = this.$store.state.queryUserInfo;
    if (!!expert) {
      if (!(expert.idCard instanceof Array)) {
        expert.idCard = !!expert.idCard ? expert.idCard.split(",") : [];
      }
      this.expertList = expert;
      this.userId = user.id;
      this.imgArr = [this.expertList.breastplate, this.expertList.practiceCertificate, this.expertList.qualification, ...this.expertList.idCard];
      this.totalNum = this.imgArr.length;
    }
  },
  methods: {
    detailInfo() {
      if (this.expertList.isExamine != 1) return;
      this.detailShow = true;
    },
    goback() {
      this.detailShow = false;
    },
    retryToSubmit(item) {
      this.detail = item;
      this.editShow = true;
    },
    addNew() {
      this.editShow = true;
    },
    popupUpdateFun(payload) {
      this.detailShow = payload;
      this.editShow = payload;
    },
    editSubmit(payload) {
      if (payload) {
        this.expertList = this.$store.state.queryUserInfo.expert;
      }
    },
    showLayerImage(index) {
      this.currentImageIndex = index;
      this.currentImage = this.imgArr[index];
      this.isShowLayer = true;
    },
    showCurImg(payload) {
      this.currentImage = this.imgArr[payload];
    },
    showImg(payload) {
      this.isShowLayer = payload;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.completeExpertCertificationBox {
  .info-short {
    padding: 20px;
    .title {
      margin-bottom: 14px;
      font-size: 18px;
      font-weight: bold;
    }
    .info-card {
      position: relative;
      width: 300px;
      height: 118px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
      }
      .top-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 56px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        font-weight: 600;
        background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
        border-radius: 8px 0px 8px 0px;
      }
      .info-list {
        padding: 0 5px;
        margin-top: 36px;
        font-size: 15px;
        .li-line {
          display: flex;
          // float: left;
          width: 100%;
          margin-top: 8px;
          font-size: 12px;
          span {
            width: 82px;
            text-align: right;
          }
          label {
            width: calc(100% - 90px);
            font-weight: bold;
            word-wrap: break-word;
          }
        }
      }
      .bottom-img {
        position: absolute;
        bottom: 4px;
        right: 5px;
      }
    }
    .ul-list {
      margin: 35px 0 0 0;
      .p-tit {
        font-size: 16px;
        font-weight: bold;
      }
      .ul-li {
        color: #666;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .card-add {
      width: 300px;
      height: 118px;
      margin-top: 2px;
      // border: 1px solid #ddd;
      text-align: center;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      img {
        width: 29px;
        margin-top: 34px;
      }
      p {
        margin-top: 4px;
        font-size: 12px;
      }
    }
  }
  .info-detail {
    position: relative;
    margin-top: 110px;
    .back-btn {
      position: absolute;
      top: -50px;
      left: 0;
      text-decoration: none;
      color: #333333;
      cursor: pointer;
    }
    .btns {
      // width: 115px;
      margin-top: 70px;
      margin-left: 215px;
    }
    .save-btn {
      width: 110px;
      height: 30px;
      padding: 0;
      margin: 0 auto 6px;
      border: 0;
      font-size: 14px;
      text-align: center;
      color: #fff;
      background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
      border-radius: 4px;
      &:hover {
        background: linear-gradient(90deg, #64a7c9 0%, #416ab0 100%);
        transition-duration: 0.5s;
      }
    }
  }
  .completeImg,
  .completeExpertCertificationText {
    text-align: center;
  }

  .completeExpertCertificationText {
    margin-top: 21px;
    font-size: 18px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;

    color: #4773b8;
  }

  .subjectContent {
    margin-top: 20px;
    .subjectContentLabel {
      margin-top: 18px;
      display: flex;

      & > :nth-child(1) {
        text-align: right;
        width: 200px;
        font-size: 16px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #666666;
      }

      & > :nth-child(2) {
        margin-left: 12px;
        font-size: 18px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #333333;
      }

      img {
        width: 58px;
        height: 58px;
      }
    }
  }
  .in_review {
    padding-top: 25px;
    text-align: center;
    h1 {
      font-size: 16px;
      &.color_red {
        color: #f53500;
      }
    }
    .line {
      margin-top: 0;
      color: #999;
      font-size: 14px;
    }
    .retry-btn {
      background: none;
      border: 0;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: #4773b8;
      }
    }
  }
}
</style>
