<template>
  <div class="accountManagementBox">
    <!-- 账户管理页面 -->
    <div class="accountManagementHome" v-if="isShow == 1">
      <div class="accountBalance">
        <div class="accountBalanceText">{{$t('account_manage.account_balance')}}</div>
        <!-- <div class="totalAmountText">{{$t('account_manage.all_money')}}</div> -->
        <div class="moneyText">
          <span>{{$t('unit.money')}}</span>
          <span v-html="moneyInteger"></span>
          <span v-html="moneyDecimal"></span>
        </div>
        <div class="moneyBtn">
          <div class="btn determineNomal" v-if="paymentType == 0" @click="rechargeFun(2)">
            {{$t('btns.recharge')}}
          </div>
        </div>
      </div>
      <div class="management" v-if="false">
        <div class="managementText">{{$t('account_manage.account_manage')}}</div>
        <ul class="accountInformation">
          <li>
            <span class="accountAlipay">{{$t('account_manage.Alipay')}}</span>
            <div class="accountText">
              <span v-html="alipayAccount"></span>
              <div class="btn accountAdd" @click="rechargeFun(4)" v-if="alipayAccount == ''">
                {{$t('account_manage.no_bind_Alipay')}}
              </div>
            </div>
            <div class="updateBtn" v-if="alipayAccount" @click="addAccountFun(4)" v-html="updateText"></div>
          </li>
          <li>
            <span class="accountWeChat">{{$t('account_manage.Wechat')}}</span>
            <div class="accountText">
              <span v-html="weChatAccount"></span>
              <div class="btn accountAdd" @click="rechargeFun(5)" v-if="weChatAccount == ''">
                {{$t('account_manage.no_bind_Wechat')}}
              </div>
            </div>
            <div class="updateBtn" v-if="weChatAccount" @click="addAccountFun(5)" v-html="updateText"></div>
          </li>
          <li>
            <span class="accountBankCard">{{$t('account_manage.bankcard')}}</span>
            <div class="accountText">
              <span v-html="bankCardAccount"></span>
              <div class="btn accountAdd" @click="rechargeFun(6)" v-if="bankCardAccount == ''">
                {{$t('account_manage.no_bind_bankcard')}}
              </div>
            </div>
            <div class="updateBtn" v-if="bankCardAccount" @click="addAccountFun(6)" v-html="updateText"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 充值 -->
    <div v-if="isShow == 2">
      <AccountRecharge @childFun="parentFun($event)"></AccountRecharge>
    </div>
    <!-- 提现 -->
    <div v-if="isShow == 3">
      <AccountWithdrawal @childFun="parentFun($event)"></AccountWithdrawal>
    </div>
    <!-- 添加支付宝 -->
    <div v-if="isShow == 4">
      <AddAlipayAccount @childFun="parentFun($event)"></AddAlipayAccount>
    </div>
    <!-- 添加微信 -->
    <div v-if="isShow == 5">
      <AddWeChatAccount @childFun="parentFun($event)"></AddWeChatAccount>
    </div>
    <!-- 添加银行卡 -->
    <div v-if="isShow == 6">
      <AddBankCardAccount @childFun="parentFun($event)"></AddBankCardAccount>
    </div>
    <!-- 提示弹窗 -->
    <MessageBox :updateType="updateType" :updateTips="updateTips" :openStatus="openStatus" @childFun="parentFun($event)"></MessageBox>
  </div>
</template>

<script>
import "../../../assets/scss/button.scss";
import "./../scss/accountManagement.scss";
import AccountRecharge from "./accountRecharge";
import AccountWithdrawal from "./accountWithdrawal";
import AddAlipayAccount from "./addAlipayAccount";
import AddWeChatAccount from "./addWeChatAccount";
import AddBankCardAccount from "./addBankCardAccount";
import MessageBox from "./../../messageBox";
import common from "./../common/common.js";
export default {
  name: "AccountManagement",
  data() {
    return {
      isShow: 1, // 默认显示第一个账户管理
      updateType: "", // 弹窗type 4-支付宝 5-微信 6-银行卡
      updateText: this.$t("btns.modify"), // 按钮文案
      updateTips: this.$t("popup.editAcct_remind.title"), // 提示文案
      moneyInteger: 0, // 显示金额
      moneyDecimal: 0,
      alipayAccount: "", // 支付宝账号
      weChatAccount: "", // 微信账号
      bankCardAccount: "", // 银行卡账号
      isIntoWithdrawal: false, // 是否可提现
      openStatus: false, // 弹窗显示状态
      paymentType: 0,
      fastPay:this.$store.state.fastPay
    };
  },
  methods: {
    // 点击提现方法
    rechargeFun(type) {
      if (type == 3) {
        this.isIntoWithdrawal
          ? (this.isShow = type)
          : this.$message.error(
            this.$t("account_manage.creat_withdrawAcount_first")
          );
      } else {
        this.isShow = type;
      }
    },
    // 添加账号弹窗
    addAccountFun(type) {
      this.openStatus = true;
      this.updateType = type;
    },
    parentFun(payload) {
      payload ? (this.isShow = payload) : (this.isShow = 1);
      this.openStatus = false;
    },
    // 获取金额信息
    getQueryByToken() {
      this.$api.queryByToken({}).then((res) => {
        if (res.code == 200) {
          const { billTypes, company, discount } = JSON.parse(res.data);
          this.moneyInteger = discount.priceAccount.toString().split(".")[0];
          this.paymentType = discount.paymentType;
          discount.priceAccount.toString().split(".")[1]
            ? (this.moneyDecimal =
              "." + discount.priceAccount.toString().split(".")[1])
            : (this.moneyDecimal = ".00");
        } else {
          // this.$router.push({
          //   path: "/",
          // });
          this.$message.error(res.message);
        }
      });
    },
    // 获取账户列表
    getHomeList() {
      this.$api.homeList({}).then((res) => {
        if (res.code == 200) {
          res.data.length > 0
            ? (this.isIntoWithdrawal = true)
            : (this.isIntoWithdrawal = false);
          res.data.forEach((item) => {
            if (item.type == 1) {
              this.alipayAccount =
                item.account + common.substrFun(item.accountName, true);
            } else if (item.type == 2) {
              this.weChatAccount =
                item.account + common.substrFun(item.accountName, true);
            } else if (item.type == 4) {
              this.bankCardAccount =
                item.account + common.substrFun(item.accountName, true);
            }
          });
        } else {
          // this.$router.push({
          //   path: "/",
          // });
          this.$message.error(res.message);
        }
      });
    },
  },
  created() {
    this.getQueryByToken();
    this.getHomeList();
    if(this.fastPay == 1 ){
      this.rechargeFun(2);
    }
  },
  watch:{
      isShow(newVal){
        if(newVal == 1){
            this.getQueryByToken();
        }
    }
  },
  components: {
    AccountRecharge,
    AccountWithdrawal,
    AddAlipayAccount,
    AddWeChatAccount,
    AddBankCardAccount,
    MessageBox,
  },
};
</script>

<style lang="scss" scoped></style>
