<template>
  <div>
    <span class="searchDivider" style="width:0px !important;"></span>

    <!-- 玻片、进度筛选-->
    <span class="searchCheck" :class="{'english': $store.state.language == 'en'}" style="margin-top: 8px;width: 520px">
      <!--玻片筛选-->
      <div class="glFilterGlass">

        <span class="glFilterGlass_span">{{$t("metadata.Glass")}}</span>
        <!-- <span class="glFilterGlass_span_ico">：</span> -->
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="glFilterGlassCheckAll">{{$t("metadata.All_slides")}}</el-checkbox>

        <el-checkbox-group v-model="checkedCities1" @change="handleCheckedCitiesChange" class="glFilterGlassCheckList">
          <el-checkbox v-for="(item,index) in cities1" :label="item.id" :key="index" :title="item.name">{{cutOutArr(item.name)}}</el-checkbox>
        </el-checkbox-group>

        <span class="glFilterGlassCheckListSpan" @click="moreImgBtn" v-if="moreImgBtnShow"><img :src="moreImg"></span>

        <!--玻片右键弹窗-->
        <div v-show="moreCheck" class="moreCheck" id="moreImgId" @mouseleave="moreCheckLeave">
          <el-checkbox-group v-model="checkedCities2" @change="handleCheckedCitiesChange1">
            <el-checkbox v-for="(item,index) in cities2" :label="item.id" :key="index">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>

      </div>

      <!--进度筛选-->
      <div class="dtmf_progress">
        <span class="dtmf_progress_span">{{$t("metadata.progress_of")}}</span>
        <!-- <span class="glFilterGlass_span_ico">：</span> -->
        <span class="dtmf_progress_list">
          <el-radio-group v-model="radioValues" @change="changeHandler">
            <span class="dtmf_progress_list_span">
              <el-radio :label="-1" value="-1">{{$t("metadata.All_images")}}</el-radio>
              <span></span>
            </span>

            <!--<img src="../../assets/images/analysissystem/icon_jiantou.png" class="dtmf_progress_img">-->
            <span class="dtmf_progress_list_span">
              <el-radio :label="1" value="1">{{$t("metadata.Automatic_analysis")}}</el-radio>
              <span class="dtmf_progress_list_span_fir"></span>
            </span>
            <!--<img src="../../assets/images/analysissystem/icon_jiantou.png" class="dtmf_progress_img">-->
            <span class="dtmf_progress_list_span">
              <el-radio :label="2" value="2">{{$t("metadata.Manual_counting_confirmation")}}</el-radio>
              <span class="dtmf_progress_list_span_sen"></span>
            </span>

            <!--<img src="../../assets/images/analysissystem/icon_jiantou.png" class="dtmf_progress_img">-->
            <span class="dtmf_progress_list_span">
              <el-radio :label="3" value="3">{{$t("metadata.Manual_alignment_confirmation")}}</el-radio>
              <span class="dtmf_progress_list_span_Thr"></span>
            </span>
          </el-radio-group>
        </span>

      </div>
    </span>

    <!--分割线-->
    <span class="searchDivider" style="margin-top: 13px;display: inline-block"></span>

    <div class="searchDividerFooter" style="margin-top: 6px;height: 44px;width: calc(100% - 554px)">
      <!--玻片数量，图片数量-->
      <span class="slides_img_control" :class="{'english': $store.state.language == 'en'}">
        <!--玻片数量-->
        <span class="slides_control">
          <span>{{$t("metadata.Number_of_slides")}}</span>
          <span>{{ karyotypeNumberlist.glassNum }} {{$t("metadata.a_num")}}</span>
        </span>

        <!--图片数量-->
        <span class="img_control">
          <span>{{$t("metadata.graphics")}}</span>
          <span>{{ karyotypeNumberlist.cellNum }} {{$t("metadata.zhang")}}</span>
        </span>
      </span>

      <!--分割线-->
      <span class="searchDivider"></span>

      <!--确认计数，确认排列-->
      <span class="domain_count_confirm">

        <!--确认计数-->
        <span class="domain_count">
          <span class="domain_count_span">{{$t("metadata.Confirm_the_count")}} {{karyotypeNumberlist.singleSureNum}} {{$t("metadata.zhang_Num")}}</span>
          <ul class="domain_count_ul">
            <li>
              {{$t("metadata.Article_45")}}<span class="domain_count_ul_45"> {{karyotypeNumberlist.artNumMap && karyotypeNumberlist.artNumMap[45]}} </span>{{$t("metadata.zhang")}}
            </li>
            <li>
              {{$t("metadata.Article_46")}}<span class="domain_count_ul_46"> {{karyotypeNumberlist.artNumMap &&karyotypeNumberlist.artNumMap[46]}} </span>{{$t("metadata.zhang")}}
            </li>
            <li>
              {{$t("metadata.Article_47")}}<span class="domain_count_ul_47"> {{karyotypeNumberlist.artNumMap &&karyotypeNumberlist.artNumMap[47]}} </span>{{$t("metadata.zhang")}}
            </li>
          </ul>
        </span>

        <!--确认排列-->
        <span class="mail_confirm">
          <span class="mail_confirm_span">{{$t("metadata.Confirm_the_arrangement")}} {{karyotypeNumberlist.typeSureNum}} {{$t("metadata.zhang_Num")}}</span>
          <ul class="mail_confirm_ul">
            <li v-for="(value, key, index) in this.karyotypeNumberlist.karyotypeExpressionList" :key="index" :title="`${key}${$t('metadata.karyotype')}${value}${$t('metadata.zhang')}`">
              {{key}}{{$t("metadata.karyotype")}}{{value}}{{$t("metadata.zhang")}}
            </li>
          </ul>
        </span>
      </span>
    </div>

  </div>
</template>

<script>
import "../../assets/scss/searchLink.scss";
export default {
  name: "metadata",
  props: ["dataList", "checkedCitiesAll", "radioValue", "karyotypeNumberlist", "moreImgBtnShow", 'checkedCities1Val', 'checkedCities2Val'],
  data() {
    return {
      checkAll: false,
      checkAll1: true,
      checkAll2: true,
      checkedCities1: this.checkedCities1Val, //第一个列表病例
      checkedCities2: this.checkedCities2Val, //第二个列表病例
      cities: [],
      cities1: [],
      cities2: [],
      moreImg: require('@/assets/images/analysissystem/icon_omit_small.png'),
      radioValues: this.radioValue,
      moreCheck: false,
    }
  },
  created() {

    // this.checkedCitiesAll = this.checkedCitiesAll;

    if (this.checkedCitiesAll.length > 3) {
      let cities1Arr = [];
      let cities2Arr = [];
      this.checkedCitiesAll.forEach((itm, index) => {
        if (index < 3) {
          cities1Arr.push(itm);
        } else {
          cities2Arr.push(itm);
        }
      });
      this.cities1 = cities1Arr;

      if (this.cities1.length == this.checkedCities1.length) {
        this.checkedCities1 = [];
        this.cities1.forEach((it) => {
          this.checkedCities1.push(it.id);
        });

      } else {
        // this.checkedCities1 = this.checkedCities1;
      }


      this.cities2 = cities2Arr;
      if (this.cities2.length == this.checkedCities2.length) {
        this.checkedCities2 = [];
        this.cities2.forEach((its) => {
          this.checkedCities2.push(its.id);
        });
      } else {
        // this.checkedCities2 = this.checkedCities2;
      }


      this.$emit("updateBtnStatus", true)

      if (this.cities2.length == this.checkedCities2.length && this.cities1.length == this.checkedCities1.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }


    } else {
      this.cities1 = this.checkedCitiesAll;

      if (this.cities1.length == this.checkedCities1.length) {
        this.checkedCities1 = [];
        this.cities1.forEach((item) => {
          //当全选被选中的时候，循环遍历源数据，把数据的每一项加入到默认选中的数组去
          this.checkedCities1.push(item.id);
          this.checkAll = true;
        });
      } else {
        // this.checkedCities1 = this.checkedCities1;
        this.checkAll = false;
      }

      // this.moreImgBtnShow = false;
      this.$emit("updateBtnStatus", false)

    }
  },
  methods: {
    parentCallback(param) {
      if (this.$parent.$refs.autovision) {
        this.$parent.$refs.autovision.queryKaryoList(true, param, true)
      }
    },

    cutOutArr(obj) {
      if (obj.length > 7) {
        return obj.slice(0, 7) + '...'
      } else {
        return obj;
      }
    },
    //获取进度的value
    changeHandler(value) {
      this.parentCallback({ status: value === -1 ? 4 : value })
      console.log(value)
    },
    //点击显示更多选择
    moreImgBtn() {
      let moreImgId = document.querySelector('#moreImgId');
      moreImgId.style.display = "block";
      moreImgId.style.left = event.clientX - 10 + 'px';
      moreImgId.style.top = event.clientY - 10 + 'px';
      if (this.moreCheck) {
        this.moreCheck = false;
      } else {
        this.moreCheck = true;
      }
    },

    moreCheckLeave() {
      this.moreCheck = false;
    },

    handleCheckAllChange(val) {
      this.$store.commit("clickGlassType", true); //单选判断

      // this.checkedCitiesAll = this.checkedCitiesAll;

      if (this.checkedCitiesAll.length >= 3) {
        let cities1Arr = [];
        let cities2Arr = [];
        this.checkedCitiesAll.forEach((itm, index) => {
          if (index < 3) {
            cities1Arr.push(itm);
          } else {
            cities2Arr.push(itm);
          }
        });
        this.cities1 = cities1Arr;
        this.cities1.forEach((it) => {
          this.checkedCities1.push(it.id);
        });

        this.cities2 = cities2Arr;
        this.cities2.forEach((its) => {
          this.checkedCities2.push(its.id);
        });
        this.checkedCities1 = val ? this.checkedCities1 : [];
        this.checkedCities2 = val ? this.checkedCities2 : [];

      } else {
        this.checkedCities1 = [];
        this.cities1 = this.checkedCitiesAll;
        this.cities1.forEach((ite, index) => {
          //当全选被选中的时候，循环遍历源数据，把数据的每一项加入到默认选中的数组去
          if (index < 3) {
            this.checkedCities1.push(ite.id);
          } else {
            this.checkedCities2.push(ite.id);
          }
        });
        this.checkedCities1 = val ? this.checkedCities1 : [];
      }


      if (val) {
        //获取选中的玻片号
        console.log('全选事件', val);
        this.parentCallback({ glassIds: null })
      } else {
        //获取选中的玻片号
        console.log('反选事件', val);
        this.parentCallback({ glassIds: 0 })
      }

    },

    //单选表格1
    handleCheckedCitiesChange(value) {
      this.$store.commit("clickGlassType", true); //单选判断
      let checkedCount = value.length;
      if (checkedCount === this.cities1.length) {
        this.checkAll1 = true;
        if (this.checkAll2) {
          this.checkAll = true;
          this.$store.commit("checkAllNameType", true); //单选发选
        } else {
          this.checkAll = false;
          this.$store.commit("checkAllNameType", false); //单选发选
        }
      } else {
        this.checkAll1 = false;
        this.checkAll = false;
        this.$store.commit("checkAllNameType", false); //单选发选
      }

      let checkedCitiesNum = this.checkedCities1.concat(this.checkedCities2);
      if (checkedCitiesNum.length <= 0) {
        this.parentCallback({ glassIds: 0 })
      } else {
        this.parentCallback({ glassIds: checkedCitiesNum.join(",") })
      }
      console.log(checkedCitiesNum, '获取选中的玻片号1');
    },

    //单选表格2
    handleCheckedCitiesChange1(value) {
      this.$store.commit("clickGlassType", true); //单选判断
      let checkedCount1 = value.length;
      if (checkedCount1 === this.cities2.length) {
        this.checkAll2 = true;
        if (this.checkAll1) {
          this.checkAll = true;
          /*this.$store.commit("checkAllNameType", true); //单选发选*/
        } else {
          this.checkAll = false;
          /*this.$store.commit("checkAllNameType", false); //单选发选*/
        }
      } else {
        this.checkAll2 = false;
        this.checkAll = false;
        /* this.$store.commit("checkAllNameType", false); //单选发选*/
      }
      let checkedCitiesNum = this.checkedCities1.concat(this.checkedCities2);
      if (checkedCitiesNum.length <= 0) {
        this.parentCallback({ glassIds: 0 })
      } else {
        this.parentCallback({ glassIds: checkedCitiesNum.join(",") })
      }

      //获取选中的玻片号
      console.log(checkedCitiesNum, '获取选中的玻片号2');
    }
  },

  watch: {
    "checkedCitiesAll": function () {
      console.log(this.checkedCitiesAll)
    },

  }
}
</script>

<style lang="scss" scoped>
.searchDividerFooter {
  width: calc(100% - 700px);
  height: 50px;
  float: left;
  overflow-y: hidden;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 0px;
}
/*滚动条样式*/
/*滚动条样式*/
.searchDividerFooter::-webkit-scrollbar {
  width: 1px;
  height: 4px;
}
.searchDividerFooter::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.searchDividerFooter::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.online-analysissystem-tab {
  .online-tabs-slot {
    padding-top: 4px;
  }
}
.glFilterGlass {
  margin-bottom: 6px;
}
</style>