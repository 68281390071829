<template>
  <div id="app">
    <keep-alive v-if="this.$route.path !== '/autovision'">
      <router-view class="child-view"></router-view>
    </keep-alive>
    <router-view class="child-view" v-else></router-view>

    <div class="peopleService" @click="dialogVisibleClose = true">
      <div class="peopleServiceImg"></div>
      <span>{{$t('connect_service')}}</span>
    </div>

    <span class="customerServiceClose" v-if="dialogVisibleClose == true">
      <span>{{$t('connect_service_popup.title')}}</span>
      <span @click="dialogVisibleClose = false"><i class="el-icon-close"></i></span>
    </span>
    <div class="customerService" v-if="dialogVisibleClose == true">
      <iframe :src="$t('srcEnOrZh')" width="100%" height="100%" frameborder="none" loading="lazy">
      </iframe>
    </div>
  </div>
</template>

<script>
let Base64 = require("js-base64").Base64;
import CryptoJS from "crypto-js";
import shortKey from "./assets/utils/shortKey.js";
window.shortKey = shortKey;
// import {ossUrlWebFir} from './assets/utils/ossUrlWeb.js'
// import uploader from './assets/utils/fileUploader.js'
import $ from "jquery";
export default {
  name: "App",
  data() {
    return {
      dialogVisibleClose: false,
    };
  },
  components: {},
  created() {

    window.addEventListener("resize", () => {
      setTimeout(() => {
        this.$store.commit("isFullscreenForNoScroll", this.$store.state.fullScreen);
      }, 100)
    });

    window.addEventListener("online", () => {
      window.isNetworkError = false;
      try {
        var nodes = document.getElementsByClassName("judgePopups")[0].childNodes[2].childNodes[1];
        nodes.click();
      } catch (error) {

      }
    })
    window.addEventListener("offline", () => {
      window.isNetworkError = true;
    })


    this.browserVersion();
    let info = new Browser();
    console.log(info, this.checkRouter());
    if (info.browser !== 'Chrome' && !this.checkRouter()) {
      // this.$router.push({
      //   path: "/checkhtml"
      // });
    }

    shortKey.on({ key: "f11" }, () => {
      if (this.$store.state.fullScreen) {
        this.$store.commit("quitFullScreen");
      } else {
        this.$store.commit("openFullScreen");
      }
    });
    $(document).on("focus", "input", () => {
      shortKey.disabled = true;
    });
    $(document).on("blur", "input", () => {
      shortKey.disabled = false;
    });
    $(document).on("focus", "textarea", () => {
      shortKey.disabled = true;
    });
    $(document).on("blur", "textarea", () => {
      shortKey.disabled = false;
    });

    setInterval(() => {
      this.getOssSign();
    }, 1800000);
  },
  methods: {
    checkRouter() {
      const path = this.$route.path;
      return ["/statement", "/agreement"].indexOf(path) > -1;
    },
    getOssSign() {
      this.$api.stsToken({ isUpdate: "false", }).then((res) => {
        if (res.code === "200") {
          const data = res.data;
          const accesskey = data.AccessKeySecret;
          const policyBase64 = this.policyBase64(data.Expiration);
          // const bytes = CryptoJS.HMAC(CryptoJS.SHA1, policyBase64, accesskey, {
          // 	asBytes: true
          // });
          const bytes = CryptoJS.HmacSHA1(
            policyBase64,
            accesskey
          );
          // console.warn(bytes,bytes);
          this.$store.state.ossUploadAccess = {
            accessid: data.AccessKey,
            accesskey: data.AccessKeySecret,
            SecurityToken: data.SecurityToken,
            policyBase64: policyBase64,
            // signature: CryptoJS.util.bytesToBase64(bytes)
            signature: CryptoJS.enc.Base64.stringify(bytes),
            expiration: data.Expiration
          };

          // ossUrlWebFir.call(this);
        }
      });
    },
    policyBase64(expiration) {
      return Base64.encode(
        JSON.stringify({
          expiration: expiration || this.getExpiration(), //设置该Policy的失效时间，超过这个失效时间之后，就没有办法通过这个policy上传文件了
          conditions: [
            ["content-length-range", 0, 1048576000], // 设置上传文件的大小限制
          ],
        })
      );
    },
    getExpiration() {
      let t = new Date().getTime() + 7 * 24 * 3600 * 1000;
      return JSON.stringify(new Date(t));
    },

  },
  watch: {
    $route: function (n, o) {
      console.log(n.path, o.path);
      if (this.$route.path === "/analysissystem") {  //statement
        // this.$store.commit("isFullscreenForNoScroll");
      }
      if (this.$route.path !== "/" && this.$route.path != '/checkhtml' && this.$route.path != '/statement' && this.$route.path != '/agreement' && this.$route.path != '/login') {
        setTimeout(() => {
          this.getOssSign();
        }, 300)
      }
      if (this.$route.path !== "/autovision") {
        shortKey.clear();
        delete window.entiresPool;
        window.loadingInstance && window.loadingInstance.close();
      }
      shortKey.disabled = this.$route.path !== "/autovision";
      this.$store.state.heartbeating = this.$route.path === "/autovision";
      if (n.path !== "/autovision" && o.path === "/autovision") {
        // this.deleteLock();
        window.onbeforeunload = null;
        window.onunload = null;
      }
      // console.warn("快捷键状态：", shortKey.disabled);
    },
  },
};
</script>

<style>
.peopleService {
  position: fixed;
  display: flex;
  bottom: 200px;
  width: 100%;
  right: 30px;
  transform: translateX(100%);
  height: 30px;
  line-height: 30px;
  width: auto;
  padding: 0 6px;
  background: #f0f6ff;
  border-radius: 100px 2px 2px 100px;
  cursor: pointer;
  color: #4773b8ff;
  transition: 0.1s;
}

.peopleServiceImg {
  height: 24px;
  width: 24px;
  display: block;
  margin: auto;
  background: url("assets/images/index/lianxikefu.png");
}

.peopleService span {
  display: block;
  height: 80px;
  font-size: 14px;
  margin: auto;
}
.peopleService:hover {
  /* color: #ffffff;
    background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%); */
  right: 0;
  transform: translateX(0);
}
/* .peopleService:hover .peopleServiceImg {
    background: url("assets/images/index/lianxikefuhover.png");
} */

.customerService {
  position: fixed;
  height: 676px;
  width: 400px;
  bottom: 0;
  right: 0;
  z-index: 666;
  overflow: hidden;
  box-shadow: 0px 10px 20px 0px rgba(75, 75, 75, 0.5);
  border-radius: 0 0 6px 6px;
}
.customerServiceClose {
  width: 400px;
  height: 36px;
  background: #4773b8;
  position: fixed;
  right: 0;
  bottom: 675px;
  cursor: pointer;
  display: block;
  border-radius: 6px 6px 0 0;
  z-index: 9999;
}
.customerServiceClose span:nth-child(1) {
  height: 36px;
  float: left;
  line-height: 36px;
  font-size: 16px;
  color: #ffffff;
  margin-left: 16px;
}

.customerServiceClose span:nth-child(2) {
  height: 36px;
  display: inline-block;
  width: 16px;
  float: right;
  line-height: 36px;
  font-size: 16px;
  color: #ffffff;
  margin-right: 16px;
}
.el-progress--circle .el-progress__text,
.el-progress--dashboard .el-progress__text {
  font-size: 30px !important;
  font-weight: bold;
  color: #acacb0;
}
.el-button.el-button--default {
  line-height: 0 !important;
  padding: 0 !important;
}
.el-menu-item.is-active {
  color: #303133 !important;
}
.el-progress-circle__track {
  stroke: #bababa;
}

.el-menu-item.is-active {
  color: #303133 !important;
}
</style>
