<template>
	<div class="dividerBox">
		<el-divider></el-divider>
	</div>
</template>

<script>
	import "../assets/scss/divider.scss"
	export default {
		name: "Divider",
		data() {
			return {}
		},
		methods: {},
	}
</script>

<style lang="scss">
	.dividerBox {
		.el-divider--horizontal {
			margin: 0;
			background: #e3e3e3;
			height: 1px;
			width: 750px;
		}
	}
</style>
