<template>
    <div class="risk">
        <!--   <p class="times">更新时间:2019年5月8日</p>-->
        <h3 class="riskH3">{{$t('agreement.line1')}}</h3>
        <p>{{$t('agreement.line1')}}</p>
        <p>{{$t('agreement.line2')}}</p>
        <p>{{$t('agreement.line3')}}</p>
        <p>{{$t('agreement.line4')}}</p>
        <p>{{$t('agreement.line5')}}</p>
        <p>{{$t('agreement.line6')}}</p>
        <p>{{$t('agreement.line7')}}</p>
        <p>{{$t('agreement.line8')}}</p>
        <p>{{$t('agreement.line9')}}</p>
        <p>{{$t('agreement.line10')}}</p>
        <p>{{$t('agreement.line11')}}</p>
        <p>{{$t('agreement.line12')}}</p>
        <p>{{$t('agreement.line13')}}</p>
        <p>{{$t('agreement.line14')}}</p>
        <p>{{$t('agreement.line15')}}</p>
        <p>{{$t('agreement.line16')}}</p>
        <p>{{$t('agreement.line17')}}</p>
        <p>{{$t('agreement.line18')}}</p>
        <p>{{$t('agreement.line19')}}</p>
        <p>{{$t('agreement.line20')}}</p>
        <p>{{$t('agreement.line21')}}</p>
        <p>{{$t('agreement.line22')}}</p>
        <p>{{$t('agreement.line23')}}</p>
        <p>{{$t('agreement.line24')}}</p>
        <p>{{$t('agreement.line25')}}</p>
        <p>{{$t('agreement.line26')}}</p>
        <p>{{$t('agreement.line27')}}</p>
        <p>{{$t('agreement.line28')}}</p>
        <p>{{$t('agreement.line29')}}</p>
        <p>{{$t('agreement.line30')}}</p>
        <p>{{$t('agreement.line31')}}</p>
        <p>{{$t('agreement.line32')}}</p>
        <p>{{$t('agreement.line33')}}</p>
        <p>{{$t('agreement.line34')}}</p>
        <p>{{$t('agreement.line35')}}</p>
        <p>{{$t('agreement.line36')}}</p>
        <p>{{$t('agreement.line37')}}</p>
        <p>{{$t('agreement.line38')}}</p>
        <p>{{$t('agreement.line39')}}</p>
        <p>{{$t('agreement.line40')}}</p>
        <p>{{$t('agreement.line41')}}</p>
        <p>{{$t('agreement.line42')}}</p>
        <p>{{$t('agreement.line43')}}</p>
        <p>{{$t('agreement.line44')}}</p>
        <p>{{$t('agreement.line45')}}</p>
        <p>{{$t('agreement.line46')}}</p>
        <p>{{$t('agreement.line47')}}</p>
        <p>{{$t('agreement.line48')}}</p>
        <p>{{$t('agreement.line49')}}</p>
        <p>{{$t('agreement.line50')}}</p>
        <p>{{$t('agreement.line51')}}</p>
        <p>{{$t('agreement.line52')}}</p>
        <p>{{$t('agreement.line53')}}</p>
        <p>{{$t('agreement.line54')}}</p>
        <p>{{$t('agreement.line55')}}</p>
        <p>{{$t('agreement.line56')}}</p>
        <p>{{$t('agreement.line57')}}</p>
        <p>{{$t('agreement.line58')}}</p>
        <p>{{$t('agreement.line59')}}</p>
        <p>{{$t('agreement.line60')}}</p>
        <p>{{$t('agreement.line61')}}</p>
        <p>{{$t('agreement.line62')}}</p>
        <p>{{$t('agreement.line63')}}</p>
        <p>{{$t('agreement.line64')}}</p>
        <p>{{$t('agreement.line65')}}</p>
        <p>{{$t('agreement.line66')}}</p>
    </div>
</template>

<script>
    export default {
        name: "Agreement",
        created() {
            document.title = this.$t('checkhtml.service_agreement');
        },
        data() {
            return {}
        },
        methods: {},
        watch: {
            "$store.state.language": function () {
                if (this.$route.path == '/agreement') {
                    document.title = this.$t('checkhtml.service_agreement');
                }
            },
            $route: function () {
                if (this.$route.path == '/agreement') {
                    document.title = this.$t('checkhtml.service_agreement');
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .risk {
        width: 80%;
        margin: 0 auto;
    }
    .riskH3 {
        width: 100%;
        height: 50px;
        margin: 30px 0;
        text-align: center;
        line-height: 50px;
        font-size: 25px;
        color: #000000;
    }
    .riskH4 {
        /*     height:30px; */
        font-size: 16px;
        line-height: 30px;
        color: #666;
    }
    .risk p {
        line-height: 30px;
        font-size: 16px;
        color: #666;
        margin-bottom: 10px;
        text-indent: 32px;
    }
    .commonP {
        text-align: center;
        color: #000000 !important;
        text-indent: 0 !important;
    }

    .changeColor {
        color: #e43f47;
    }
    .times {
        line-height: 30px;
        font-size: 16px;
        color: #666;
        margin-bottom: 10px;
        text-indent: 32px;
        text-align: right;
    }
</style>
