<template>
  <div class="casesListStyle">
    <div class="casesListStyleFir" ref="casesListStyleFir">
      <div v-infinite-scroll="loadMore" infinite-scroll-disabled="scrollDisabled" class="casesListClass" id="casesListClass" ref="casesListClass" :style="{height: myHeight + 'px'}">
        <div v-for="(item, index) in data" :data="data" :key="item.index" class="analyList" :class='[item.iconNewDelSelect ==2?"activeStyle":"",activeindex == index?"active":""]' @click="switchCase(item, index)" @mouseenter="changeMaskEnter(item, index)" @mouseleave="changeMaskLeave(item, index)">
          <span class="listActiveColor" v-if="activeindex == index"></span>
          <span class="belongColor" :style="{'background': item.belongUserColor}"></span>
          <img src="../../assets/images/analysissystem/icon_report_yellow.svg" class="icon_report_gray" v-if="item.reportNum" />
          <img src="../../assets/images/analysissystem/icon_report_gray.svg" class="icon_report_gray" v-if="!item.reportNum" />
          <span class="listActiveName" :title="`${item.name}`">{{item.name}}</span>

          <!-- expertStatus 0：初始化   1：待开始   2：待审核  3：审核通过", 4：审核未通过   patientType: 0 个人病例、1 会诊病例 2 团队病例 3 转入病例-->
          <el-tooltip :content="$t('new_interaction.waiting_start')" placement="bottom" effect="light" v-if="item.expertStatus == 1 && patientType != 0">
            <img src="../../assets/images/analysissystem/icon_notice_yellow.png" class="weizhenduan" @click.stop="examineFun(item.id, item.expertStatus)" />
          </el-tooltip>
          <el-tooltip :content="$t('new_interaction.waiting_audit')" placement="bottom" effect="light" v-if="item.expertStatus == 2 && patientType != 0">
            <img src="../../assets/images/analysissystem/icon_notice_blue.png" class="weizhenduan" @click.stop="examineFun(item.id, item.expertStatus)" />
          </el-tooltip>
          <el-tooltip :content="$t('new_interaction.audit_pass')" placement="bottom" effect="light" v-if="item.expertStatus == 3 && patientType != 0">
            <img src="../../assets/images/analysissystem/icon_notice_green.png" class="weizhenduan" @click.stop="examineFun(item.id, item.expertStatus)" />
          </el-tooltip>
          <el-tooltip :content="$t('new_interaction.audit_nopass')" placement="bottom" effect="light" v-if="item.expertStatus == 4 && patientType != 0">
            <img src="../../assets/images/analysissystem/icon_notice_black.png" class="weizhenduan" @click.stop="examineFun(item.id, item.expertStatus)" />
          </el-tooltip>

          <el-tooltip :content="$t('new_interaction.no_analysis')" placement="bottom" effect="light" v-if="item.isTransfer == 1 && item.transferStatus == 1 && (patientType == 2 || patientType == 3)">
            <img src="../../assets/images/analysissystem/icon_transfer_black.png" class="weizhenduan transfer" />
          </el-tooltip>
          <el-tooltip :content="$t('new_interaction.completely')" placement="bottom" effect="light" v-if="item.isTransfer == 1 && item.transferStatus == 2 && (patientType == 2 || patientType == 3)">
            <img src="../../assets/images/analysissystem/icon_transfer_green.png" class="weizhenduan transfer" />
          </el-tooltip>

          <!-- 查看会诊意见 -->
          <!-- <el-tooltip :content="$t('case_overview.check_consult_opinion')" placement="bottom" effect="light">
                        <img src="../../assets/images/analysissystem/yizhenduan.svg" class="weizhenduan" v-if="item.isExpert == 2" @click.stop="examineFun(item.id, item.expertStatus)" />
                    </el-tooltip> -->
          <img src="../../assets/images/analysissystem/icon_new_del_select1.svg" class="icon_new_del_select1" v-if="item.iconNewDelSelect == 2" @click.stop="changeType(item)" />
          <img src="../../assets/images/analysissystem/icon_new_check_green.svg" class="icon_new_check_green" v-if="item.isReviewer == 2" />
        </div>
        <Examine :dialogVisible="dialogVisibleStatus" :patientId="patientId" :expertStatus="expertStatus" @childFun="parentFun($event)" @examineChildFun="examineParentFun($event)"></Examine>

        <!--弹出框点击出现的审核 删除 提交审核 -->
        <div v-show="menuType" @mouseleave="mouseleavesEnterList">
          <el-menu id="menuList" @select="handleSelectList" text-color="#000" v-model="itemList" ref="menuListHeight">
            <el-menu-item index="1" class="menuItem menuItemList" ref="menuItemFir" v-show="menuItemFirType">
              <span slot="title">{{itemList.isReviewer == 0? $t('case_overview.recheck_pass'): $t('case_overview.recheck_cancel')}}</span>
            </el-menu-item>
            <!-- 病例提交专家会诊 menuItemSenType  病例分配与病例转交（机构下才会有） v-show="menuItemSenType == 0" -->
            <el-menu-item index="2" class="menuItem menuItemList" ref="menuItemSen" v-show="menuItemSenType">
              <span slot="title">{{$t("case_overview.submit_expert_consult")}}</span>
            </el-menu-item>
            <el-menu-item index="3" class="menuItem menuItemList" v-show="menuItemAllocType">
              <span slot="title">{{$t("new_interaction.case_allot")}}</span>
            </el-menu-item>
            <el-menu-item index="4" class="menuItem menuItemList" v-show="menuItemTranfType">
              <span slot="title">{{$t("new_interaction.case_transfer")}}</span>
            </el-menu-item>
            <el-menu-item index="5" class="menuItem menuItemList" ref="menuItemThr" v-show="menuItemThrType">
              <span slot="title">{{$t("case_overview.delete")}}</span>
            </el-menu-item>
          </el-menu>
        </div>
        <CaseAllocation :dialogVisible="dialogVisibleAlloc" :patientId="patientId" :patientName="itemList.name" @childFun="caseAllocFun($event)"></CaseAllocation>
        <CaseTransfer :dialogVisible="dialogVisibleTransfer" :patientId="patientId" :patientName="itemList.name" @childFun="caseTransferFun($event)"></CaseTransfer>
      </div>
    </div>
  </div>
</template>

<script>
import Examine from "./examine";
import CaseAllocation from "./caseAllocation";
import CaseTransfer from "./caseTransfer";
import "../../assets/scss/analysLeftList.scss";
import Common from "../../assets/js/common";
export default {
  name: "analysLeftList",
  components: {
    Examine,
    CaseAllocation,
    CaseTransfer,
  },
  props: ["dataList"],
  data() {
    return {
      loading: false,
      menuType: false,
      totalPage: "", //取后端返回内容的总页数
      data: [],
      itemsPerLoad: 20,
      page: 1,
      listActiveColor: 1,
      activeindex: 0,
      checkValue: this.$store.state.batchAnalysisList, //选中的id集合
      dialogVisibleStatus: false,
      dialogVisibleAlloc: false,
      dialogVisibleTransfer: false,
      patientId: 0, //病例id
      expertStatus: 0,
      isReviewer: "", //是显示复审通过还是显示复审取消 0是没有提交审核 2是已经审核通过
      isMenuExpert: "",
      itemList: "", //左侧列表点击 获取当前信息
      menuItemFirType: false, //病例复诊 
      menuItemSenType: false, //病例提交专家会诊 
      menuItemThrType: false, //病例 删除按钮 
      menuItemAllocType: false, //病例分配
      menuItemTranfType: false, //病例 转交
      isReturn: false,
      myHeight: "",
      isShowCaseAllocation: false,
      patientType: '',
      isClick: true
    };
  },
  created() {
    window.leftApp = this;
    this.$store.commit("firstLoadingType", 1);
  },

  computed: {
    noMore() {
      //当起始页数大于总页数时停止加载
      return this.page > this.totalPage - 1;
    },
    scrollDisabled() {
      return this.loading || this.noMore;
    },
    userType() {
      return JSON.parse(localStorage.getItem("loginObj")).userType;
    },
  },
  methods: {
    loadMore() {
      console.log("有没有触底");
      //滑到底部时进行加载
      this.loading = true;

      setTimeout(() => {
        this.page += 1; //页数+1
        this.getMessage(); //调用接口，此时页数+1，查询下一页数据
        this.loading = false;
      }, 200);
    },

    getMessage() {
      let self = this;
      let srcMechanismId = "";
      if (this.$store.state.caseValue.mechanismId !== this.$store.state.labValue.mechanismId) {
        srcMechanismId = this.$store.state.caseValue.mechanismId;
      }
      let param = {
        limit: self.itemsPerLoad, // 每页几条
        search: this.dataList.searchValue,
        page: self.page,
        sortType: this.$store.state.sortType, //0名称   1时间
        sort: this.$store.state.sort, //0:顺序   1倒序
        patientType: this.$store.state.caseValue.patientType,
        srcMechanismId: srcMechanismId, // 来源机构ID
      };
      this.$api.queryPatientBySeach(param).then((res) => {
        if (res.code == 200) {
          if (res.data.totalCount == 0) {
            //列表没有数据
            this.dataList.casesList = 2;
            this.$store.commit("caseIdType", '');
          } else {
            //列表有数据
            this.dataList.casesList = 1;
            this.data = this.data.concat(res.data.list);
            this.data.forEach((element) => {
              element.iconNewDelSelect = 1;
            });
            this.totalPage = res.data.totalPage;
            this.$store.commit("caseIdType", (this.data[0] && this.data[0].id) || '');
            console.log("====888", this.data[0] && this.data[0].id)
            this.data.forEach((ele, index) => {
              if (localStorage.getItem("id") == ele.id) {
                this.dataList.id = ele.id;
                this.activeindex = index;
                this.isReturn = true;
                this.$store.commit("caseIdType", ele.id);
                if (this.$store.state.isSearchUpdate) {
                  this.$store._mutations.receiveCaseStatus(ele.id, ele.status)
                  this.$store.commit("isSearchUpdateType", false);
                }
              }
            });

            if (!localStorage.getItem("id") && !!this.data[0]) {
              this.dataList.id = this.data[0].id || '';
              // this.$store.commit("caseIdType", this.data[0].id);
              this.$store._mutations.receiveCaseStatus(this.data[0].id, this.data[0].status)
              this.$store.commit("isSearchUpdateType", false);
            }
            if (!this.isReturn && !!this.data[0]) {
              this.dataList.id = this.data[0].id || '';
              this.$store._mutations.receiveCaseStatus(this.data[0].id, this.data[0].status)
              this.$store.commit("isSearchUpdateType", false);
            }

          }
          this.loading = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    //切换核型图
    switchCase: function (item, index) {
        if(this.$store.state.isLoading ==1){
           if (this.activeindex == index) return false;
           if (this.isClick == false) return false;
            this.isClick = false;
           this.activeindex = index;
           this.$store.commit("glassIdsType", []);
           this.$store.commit("statusType", -1);
           this.$store.commit("sortTypeList", 1);
           this.$store.commit("color", 4);
           this.$store.commit('beingAnalyzed', 0);
           /*this.$store.commit("radioCheckType", 1);*/
           this.dataList.id = item.id;
           localStorage.setItem("id", item.id);

           this.$store.commit("caseIdType", item.id);
           this.$store._mutations.receiveCaseStatus(this.data[0].id, this.data[0].status)
           this.$store.commit("clickSomeReviewType", false); //复选框
           this.$store.commit("checkAllNameType", true); //切换
           this.$store.commit("clickSlideType", false); //单选框
           this.$store.commit("clickGlassType", false); //单选判断
           this.dataList.automaticType = 1; //分析不可点击
           clearInterval(window.timestampName);
           clearInterval(window.timesCase);
           setTimeout(() => {
                this.isClick = true;
            }, 500)
        }
    },

    examineMenuFun() {
      this.dialogVisibleStatus = true;
      this.expertStatus = this.isMenuExpert;
    },

    // 审核
    examineFun(id, expertStatus) {
      if (!Common.permissions("/patient/queryExpertByPatient")) {
        return
      }
      this.dialogVisibleStatus = true;
      this.patientId = id;
      this.expertStatus = expertStatus;
    },

    parentFun(payload) {
      this.dialogVisibleStatus = payload;
    },
    caseAllocFun(payload) {
      this.dialogVisibleAlloc = payload;
    },
    caseTransferFun(payload) {
      this.dialogVisibleTransfer = payload;
    },
    //子组件添加专家会诊向父组件传递消息
    examineParentFun(payload) {
      this.data.forEach((item) => {
        if (item.id == this.patientId) {
          item.expertStatus = payload;
        }
      });
      this.$forceUpdate();
    },

    //移入病例
    changeMaskEnter(item, index) {
      this.data.forEach((element) => {
        if (element.id == item.id) {
          element.iconNewDelSelect = 2;
        } else {
          element.iconNewDelSelect = 1;
        }
      });
      this.$forceUpdate();
    },

    //移出病例
    changeMaskLeave(item, index) {
      this.data.forEach((element) => {
        if (element.id == item.id) {
          element.iconNewDelSelect = 1;
        }
      });
      this.$forceUpdate();
    },

    //点击移入的哪一个病例 提交专家会诊 复审通过 取消复审 删除
    changeType(item) {
      this.itemList = item; //列表的数据信息
      this.patientId = item.id;
      this.isReviewer = item.isReviewer;
      this.isMenuExpert = item.expertStatus;
      let pointList = localStorage.getItem("pointList");
      window.pointListObj = JSON.parse(pointList);
      //审核权限
      let param = {
        patientId: item.id,
      };
      this.$api.hasHandle(param).then((res) => {
        if (res.code == 200) {
          //审核权限 复诊按钮 个人中心与专家会诊不显示 patientType:0 个人病例、1 咨询病例 2 团队病例 3 转入病例
          if (this.patientType != 0 && this.patientType != 1 && Common.permissions("/patient/reviewer")) {
            this.menuItemFirType = true;
          } else {
            this.menuItemFirType = false;
          }

          //提交专家会诊按钮 机构下且状态为初始值显示
          if (!item.expertStatus && (this.patientType == 2 || this.patientType == 3) && (res.data == 1 || res.data == 2) && Common.permissions("/patient/queryExpertByPatient")) {
            this.menuItemSenType = true; //显示
          } else {
            this.menuItemSenType = false; //隐藏
          }

          //删除病例权限
          let userId = Number(JSON.parse(localStorage.getItem("loginObj")).userId);
          //删除按钮 会诊病例以及转交病例不能删除
          if ((item.owner == userId || item.ownerMainId == userId) && this.patientType != 1 && this.patientType != 3) {
            //判断是不是自己的病例 是的话就可以删除 不是的话就不能删除
            this.menuItemThrType = true;//显示
          } else {
            this.menuItemThrType = false;//隐藏
          }

          //分配按钮 
          if ((this.patientType == 2 || this.patientType == 3) && this.$store.state.isOpenDistribution && Common.permissions("/patient/pListLabor")) {
            this.menuItemAllocType = true;//显示
          } else {
            this.menuItemAllocType = false;//隐藏
          }

          //转交按钮 
          if (item.isTransfer == 0 && (this.patientType == 2 || this.patientType == 3) && !!this.$store.state.coMechanism.mechanismName && (res.data == 1 || res.data == 2) && Common.permissions("/patient/pListLabor")) {
            this.menuItemTranfType = true;//显示
          } else {
            this.menuItemTranfType = false;//隐藏
          }
        } else if (res.code == 136) {
          this.menuItemThrType = true;
          this.menuItemSenType = false;
          this.menuItemFirType = false;
          this.menuItemAllocType = false;
          this.menuItemTranfType = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });

      //显示弹出框 并显示位置
      this.$nextTick(function () {
        this.menuType = true;
        let menuList = document.querySelector("#menuList");
        let menuListHeight = document.documentElement.clientHeight;
        menuList.style.display = "block";
        menuList.style.left = event.clientX - 10 + "px";
        menuList.style.bottom = menuListHeight - event.clientY - 10 + "px";
      });
    },

    mouseleavesEnterList() {
      this.menuType = false;
    },

    //删除 审核  提交
    handleSelectList(index) {
      if (index == 5) {
        //删除按钮  删除病例
        this.deleteCases();
      } else if (index == 1) {
        //审核
        let isReviewerValue = "";
        if (this.itemList.isReviewer == 0) {
          isReviewerValue = 2;
        }
        if (this.itemList.isReviewer == 2) {
          isReviewerValue = 0;
        }
        let param = {
          patientId: this.itemList.id,
          isReviewer: isReviewerValue,
        };
        this.$api.patientReviewer(param).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.message);
            this.data.forEach((itm) => {
              if (itm.id == this.itemList.id) {
                itm.isReviewer = isReviewerValue;
              }
            });
            this.menuType = false;
          } else {
            this.$message.error(res.message);
            this.menuType = false;
          }
        });
      } else if (index == 2) {
        this.examineMenuFun();
      } else if (index == 3) {
        this.dialogVisibleAlloc = true;
      } else if (index == 4) {
        this.dialogVisibleTransfer = true;
      }
    },

    //删除
    deleteCases() {
      this.$confirm(this.$t("popup.warning.delete_cell"), this.$t("popup.warning.remind"), {
        confirmButtonText: this.$t("btns.confirm"),
        cancelButtonText: this.$t("btns.cancel"),
        type: "warning",
          closeOnClickModal: false,
      }).then(() => {
        let param = {
          id: this.itemList.id,
          type: 1,
        };
        this.$api.patientDelete(param).then((res) => {
          if (res.code == 200) {
            clearInterval(window.timestampName);
            clearInterval(window.timesCase);
            this.$store._mutations.closeTab(this.itemList.id);
            this.data.forEach((item, i) => {
              if (item.id == this.itemList.id) {
                if (this.activeindex == i) {
                  if (this.data.length > 1) {
                    if (i == 0) {
                      this.data.splice(i, 1);
                      this.activeindex = i;
                      this.$store.commit("firstLoadingType", 1);
                      this.dataList.id = this.data[i].id;
                      this.loadMoreHeight();
                    } else {
                      this.data.splice(i, 1);
                      this.activeindex = i - 1;
                      this.$store.commit("firstLoadingType", 1);
                      this.dataList.id = this.data[i - 1].id;
                      this.loadMoreHeight();
                    }
                  } else {
                    this.data.splice(i, 1);
                    this.dataList.casesList = 2;
                    this.dataList.beingAnalyzed = false;
                    clearInterval(window.timestampName);
                    clearInterval(window.timesCase);
                    this.loadMoreHeight();
                  }
                } else {
                  //列表很多 删除的不是当前选中项
                  this.data.splice(i, 1);
                  this.loadMoreHeight();
                }
                return true;
              }
            });
            this.menuType = false;
            this.$message({
              type: "success",
              message: res.message,
            });
          } else {
            this.menuType = false;
            this.$message.error(res.message);
          }
        });
      })
        .catch(() => { });
    },

    //监听
    loadMoreHeight() {
      let that = this;
      that.$nextTick(function () {
        let casesListStyleFirHeight = parseInt(
          window.getComputedStyle(this.$refs.casesListStyleFir).height
        ); //列表的固定高度
        let casesListClassHeight = parseInt(
          window.getComputedStyle(this.$refs.casesListClass).height
        ); //动态生成的高度
        console.log(casesListStyleFirHeight, casesListClassHeight);
        if (
          casesListStyleFirHeight > casesListClassHeight &&
          casesListStyleFirHeight - casesListClassHeight < 20
        ) {
          this.page = 1;
          this.data = [];
          this.dataList.searchValue = "";
          this.getMessage();
        }
      });
    },
    /**
     * 同步至病例列表中的状态
     */
    syncCaseStatus(patientId, status) {
      this.data.forEach((item) => {
        if (item.id === patientId) {
          item.status = status;
          // transferStatus 0 初始化 1 未分析 2 已完成
          if (!!item.transferStatus) {
            if (status == 2) {
              item.transferStatus = status;
              if (this.$store.state.caseValue.patientType == 3) {
                this.$store._mutations.closeToolTab();
                this.page = 1;
                this.data = [];
                this.dataList.searchValue = "";
                this.getMessage();
              }
            } else {
              item.transferStatus = 1;
            }
          }
        }
      })
    },
  },
  mounted() {
    this.$store._mutations.syncCaseStatus = this.syncCaseStatus;
    this.$store._mutations.getStatusByCaseId = (id) => {
      let status = 0;
      this.data.forEach(a => {
        a.id == id && (status = a.status);
      })
      return status;
    }
  },
  watch: {
    //监听搜索框查询
    "dataList.searchValue": {
      immediate: true,
      handler: function (value, oldVal) {
        if (oldVal == undefined) return false;
        this.$store.commit("firstLoadingType", 1);
        localStorage.removeItem("id");
        this.page = 1;
        this.data = [];
        this.activeindex = 0;
        this.getMessage();
      },
    },
    // "$store.state.sortType": function (val) {
    //   this.$store.commit("firstLoadingType", 1);
    //   localStorage.removeItem("id");
    //   this.page = 1;
    //   this.data = [];
    //   this.activeindex = 0;
    //   this.getMessage();
    // },
    "$store.state.sort": function () {
      this.$store.commit("firstLoadingType", 1);
      localStorage.removeItem("id");
      this.page = 1;
      this.data = [];
      this.activeindex = 0;
      this.getMessage();
    },
    "$store.state.belongUserColorItem": function (val) {
      this.data.forEach((item) => {
        if (item.belongUserId == val.belongUserId) {
          item.belongUserColor = val.belongUserColor;
          // this.$forceUpdate();
        }
      });
    },
    "$store.state.isUpdateList": function () {
      this.data.forEach((item) => {
        if (item.id == this.$store.state.caseId) {
          item.reportNum = 1;
          this.$forceUpdate();
        }
      });
      this.$store.commit("isUpdateListType", false);
    },

    "$store.state.caseImg": function () {
      this.data.forEach((item) => {
        if (item.id == this.$store.state.caseId) {
          item.reportNum = 0;
          this.$forceUpdate();
        }
      });
      this.$store.commit("caseImgType", false);
    },
    "$store.state.allocUserColor": function (val) {
      const { patientIdsList, belongUserColor } = val;
      this.data.forEach(item => {
        patientIdsList.forEach(it => {
          if (it === item.id) {
            item.belongUserColor = belongUserColor;
            this.$forceUpdate();
          }
        })
      })
    },
    "$store.state.patientIdStatus": function (val) {
      this.data.forEach(item => {
        if (item.id === val.patientId) {
          item.status = val.status;
          this.$forceUpdate();
        }
      })
    },

    // "$store.state.isDeleteCase": function () {
    // this.data.forEach((item, index) => {
    //   if (item.id == this.$store.state.caseId) {
    //     this.data.splice(index, 1);
    //     this.$forceUpdate();
    //   }
    // });
    // if (this.data.length > 0) {
    //   //列表有数据
    //   this.dataList.casesList = 1;
    //   this.dataList.id = this.data[0].id;
    //   this.activeindex = 0;
    // } else {
    //   //列表没有数据
    //   this.dataList.casesList = 2;
    //   }
    //   this.$store.commit("isDeleteCaseType", false);
    // },

    //上传之后监听
    "$store.state.changeListLeft": function () {
      this.$store.commit("firstLoadingType", 1);
      this.page = 1;
      this.data = [];
      this.dataList.searchValue = "";
      this.activeindex = 0;
      this.getMessage();
    },
    "$store.state.caseValue": function (newVal) {
      this.$store.commit("firstLoadingType", 1);
      this.$store.commit("glassListType", []);
      this.$store.commit("karyotypeNumberlistType", {});
      this.page = 1;
      this.data = [];
      this.dataList.searchValue = "";
      this.activeindex = 0;
      this.getMessage();
      this.patientType = newVal.patientType;
      this.$store.commit('statusType', -1);
    },
    "$store.state.transferIds": function (val) {
      val.forEach(item => {
        this.data.forEach(it => {
          if (item === it.id) {
            it.transferStatus = '1';//状态转为未分析
            it.isTransfer = 1;
          }
        })
      });
    },
  },
};
</script>

<style lang="scss">
.casesListStyle {
  .el-menu {
    width: unset !important;
  }
}
</style>
