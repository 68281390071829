
<template>
  <div class="hoverImage" :style="{height: containerHeight + 'px'}">
    <div class="all-karyo" id="allkaryo" @contextmenu.prevent>
      <div class="karyos" :style="{height: rowHeight1 + 'px'}">
        <div class="box" id="box" :style="{height: rowHeight1 + 'px'}" @mouseenter="hover('box', hoverflag=true)" @mouseleave="hover('box', hoverflag=false)">
        </div>
      </div>
      <div class="karyos" v-show="num" :style="{height: rowHeight2 + 12 + 'px'}">
        <div class="box" id="box2" :style="{height: rowHeight2 + 12 +'px'}" @mouseenter="hover('box2', hoverflag2=true)" @mouseleave="hover('box2', hoverflag2=false)">
        </div>
      </div>
    </div>
    <div class="btns">
      <div class="small_large_btn">
        <el-button @click="smallKaryo()" class="small-btn"><span></span></el-button>
        <el-button @click="enchargeKaryo()" class="enlarge-btn"><span></span></el-button>
      </div>
      <el-button class="undo-btn" @click="undo" ref="undoBtn"></el-button>
    </div>
  </div>
</template>
<script>
// import $ from 'jquery'
import { cloneDeep } from 'lodash';
export default {
  name: "allTheKaryos",
  created() {
    window.allAPP = this;
    this.selectd = ''
  },
  components: {},
  data() {
    return {
      num: 0,//染色体换行的个数
      data: [],
      hoverflag: true,
      hoverflag2: true,
      rowHeight1: 130,
      rowHeight2: 100,
      sumWidth: 0,
      defaultScale: 1,//外部绝对比例
      karyoScale: 1,//得出的最后比例
      countScale: 1.5,//自身调节的相对比例
      originalData: [],
      containerHeight: 280,
      colorGreen: false,
      selectd: '',
      allkaryoWidth: '',
      allkaryoHeight: '',
      // isEnlarge: false
      processing: true,
    };
  },
  props: {
    imgData: '',
  },
  mounted() {
    // this.init(this.imgData);
  },

  computed: {
    isEnlarge() {
      return this.$store.state.analysisInfo.isEnlarge;
    },
    chromoEnlarge() {
      return this.$store.state.analysisInfo.chromoEnlarge;
    }
  },

  methods: {
    smallKaryo() {
      this.defaultScale -= 0.1;
      if (this.defaultScale < 0.5) {
        this.defaultScale = 0.5
      }
      let obj = {
        singleNum: this.$store.state.analysisInfo.singleNum,
        typeNum: this.$store.state.analysisInfo.typeNum,
        chromoDefault: this.defaultScale,
        chromoEnlarge: this.$store.state.analysisInfo.chromoEnlarge,
        isEnlarge: this.$store.state.analysisInfo.isEnlarge
      }
      this.$store.commit('analysisInfoStore', obj);
      this.karyoScale = this.defaultScale;
      if (this.countScale < 1.5) {
        this.karyoScale = this.defaultScale * this.countScale;
      }
      this.changeKaryos(this.data, this.karyoScale);
    },
    enchargeKaryo() {
      this.defaultScale += 0.1;
      if (this.defaultScale > 1.5) {
        this.defaultScale = 1.5
      }
      let obj = {
        singleNum: this.$store.state.analysisInfo.singleNum,
        typeNum: this.$store.state.analysisInfo.typeNum,
        chromoDefault: this.defaultScale,
        chromoEnlarge: this.$store.state.analysisInfo.chromoEnlarge,
        isEnlarge: this.$store.state.analysisInfo.isEnlarge
      }
      this.$store.commit('analysisInfoStore', obj);
      this.karyoScale = this.defaultScale;
      if (this.countScale < 1.5) {
        this.karyoScale = this.defaultScale * this.countScale;
      }
      this.changeKaryos(this.data, this.karyoScale);
    },
    changeKaryos(data, scale) {
      let originalData = cloneDeep(this.originalData);
      for (let i = 0; i < data.length; i++) {
        if (!originalData[i]) {
          continue;
        }
        data[i].width = originalData[i].width * scale;
        data[i].height = originalData[i].height * scale;
      }
      this.initHandlerKaryos(data);
      let allkaryo = document.getElementById("allkaryo")
      if (!allkaryo) return
      let box = document.getElementById("box");
      if (box.childNodes) box.innerHTML = ''
      let fragment = document.createDocumentFragment();
      if (this.num) {
        let box2 = document.getElementById("box2");
        if (box2.childNodes) box2.innerHTML = ''
        data.slice(0, this.num - 1).forEach((item) => {
          this.creatImg(fragment, item, 'box')
        })
        box.appendChild(fragment)
        data.slice(this.num - 1, data.length).forEach((item) => {
          this.creatImg(box2, item, 'box2')
        })
        box2.appendChild(fragment)
      } else {
        data.forEach((item) => {
          this.creatImg(fragment, item, 'box')
        })
        box.appendChild(fragment)
      }
    },
    creatImg(fragment, item, id) {
      if (!item.img) return
      const imgkug = document.createElement('div');
      const child = document.createElement('div');
      child.className = "img-kug-child";
      child.id = item.id + "-" + item.index;
      child.appendChild(item.img);
      child.childNodes[0].style.width = item.width + 'px';
      child.childNodes[0].style.height = item.height + 'px';
      child.style.borderColor = item.borderColor;
      fragment.appendChild(imgkug);
      // box.appendChild(imgkug);
      imgkug.appendChild(child);
      imgkug.className = "img-kug";
      this.$forceUpdate();
      this.creatDot(imgkug.childNodes[0], item)
      imgkug.onmousedown = ((ev) => {
        const imgKug = document.getElementsByClassName('img-kug')
        imgKug.forEach((item) => {
          item.className = "img-kug";
        })
        this.choseAndCount(ev, item, imgkug)
        imgkug.className = "img-kug red-kug";
      });
      // if (id == 'box') {
      //     imgkug.onmouseenter = (() => {
      //         this.hover(id, this.hoverflag = true)
      //     })
      // } else {
      //     imgkug.parentNode.onmouseenter = (() => {
      //         this.hover(id, this.hoverflag2 = true)
      //     })
      // }
    },
    creatDot(ele, item) {
      let span = ele.getElementsByTagName('span');
      for (let i = span.length - 1; i >= 0; i--) {
        span[i].parentNode.removeChild(span[i]);
      }
      const count_dot = document.createElement('span')
      ele.appendChild(count_dot);
      count_dot.className = 'count-dot';
      for (let i = 1; i < item.countNum; i++) {
        const i_dot = document.createElement('i')
        count_dot.appendChild(i_dot);
        if (i == 1) {
          const i_dot = document.createElement('i')
          count_dot.appendChild(i_dot);
        }
      }
    },
    //计算初始化纵向最大比例
    initScale(val) {
      let data = cloneDeep(val);
      let row1 = [], row2 = [], len = data.length;
      const loop = (n) => {
        if (n >= len) {
          return
        }
        row1 = data.slice(0, len - n);
        row2 = data.slice(len - n, len);
        const maxH1 = row1.length ? Math.max.apply(null, row1.map(a => a.height)) : 0;
        const maxH2 = row2.length ? Math.max.apply(null, row2.map(a => a.height)) : 0;
        const totalW1 = row1.reduce((a, b) => a + b.width, 0);
        const totalW2 = row2.reduce((a, b) => a + b.width, 0);
        const scale = Math.min(1.5, this.allkaryoHeight / (maxH1 + maxH2 + 24));
        // console.warn(maxH1, maxH2, totalW1, totalW2, scale, n);
        this.countScale = scale / 1.5;
        if (totalW1 * scale <= this.allkaryoWidth && totalW2 * scale <= this.allkaryoWidth) {
          // return scale / 1.5;
          this.countScale = scale / 1.5;
        } else {
          loop(n + 1)
        }
      }
      loop(0);
    },
    //判断染色体宽度总和是否大于外部容器的宽度，如果超出，则比例减小0.1
    continueScale(val) {
      if (!this.allkaryoWidth) return
      let data = cloneDeep(val);
      let originalData = cloneDeep(this.originalData);
      if (!originalData.length) return;
      for (let i = 0; i < data.length; i++) {
        data[i].width = originalData[i].width * this.countScale * 1.5;
        data[i].height = originalData[i].height * this.countScale * 1.5;
      }
      this.initHandlerKaryos(data);
      let row2 = [];
      let len = data.length;
      row2 = data.slice(this.num - 1, len);
      const totalW2 = row2.reduce((a, b) => a + b.width * 1.5, 0);
      if ((totalW2 * this.countScale + 18 + 4 * (len - this.num)) > this.allkaryoWidth) {
        // return scale / 1.5;
        this.countScale -= 0.1;
        this.continueScale(data);
      }
    },
    //计算换行的num值
    initHandlerKaryos(data) {
      this.rowHeight1 = data.length && data[0].height;
      this.num = 0;
      let sumWidth = 0;
      let flag = false;
      data.forEach((item, index) => {
        sumWidth += Number(item.width) + 4;//4为每条染色体marginright和border值
        if (!flag && (sumWidth - 18) > this.allkaryoWidth) {//10为外边框padding值
          this.num = index;
          this.rowHeight2 = data[this.num - 1] && data[this.num - 1].height;
          flag = true;
        }
      })
    },
    // 获取外容器的宽高
    getWidthHeight() {
      let allkaryo = document.getElementById('allkaryo');
      if (!allkaryo) return
      let result = this.getSize('allkaryo');
      this.allkaryoWidth = result.width;
      this.allkaryoHeight = result.height;
    },
    //获取隐藏元素的宽高
    getSize(id) {
      var width,
        height,
        elem = document.getElementById(id),
        noneNodes = [],
        nodeStyle = [];
      this.getNoneNode(elem, noneNodes); //获取多层display：none;的元素
      this.setNodeStyle(noneNodes, nodeStyle);
      width = elem.clientWidth;
      height = elem.clientHeight;
      this.resumeNodeStyle(noneNodes, nodeStyle);
      return {
        width: width,
        height: height
      }
    },
    getNoneNode(node, noneNodes) {
      var display = this.getStyles(node).getPropertyValue('display'),
        tagName = node.nodeName.toLowerCase();
      if (display != 'none'
        && tagName != 'body') {
        this.getNoneNode(node.parentNode, noneNodes);
      } else {
        noneNodes.push(node);
        if (tagName != 'body')
          this.getNoneNode(node.parentNode, noneNodes);
      }
    },
    getStyles(elem) {
      var view = elem.ownerDocument.defaultView;
      if (!view || !view.opener) {
        view = window;
      }
      return view.getComputedStyle(elem);
    },
    setNodeStyle(noneNodes, nodeStyle) {
      var i = 0;
      for (; i < noneNodes.length; i++) {
        var visibility = noneNodes[i].style.visibility,
          display = noneNodes[i].style.display,
          style = noneNodes[i].getAttribute("style");
        //覆盖其他display样式
        noneNodes[i].setAttribute("style", "visibility:hidden;display:block !important;" + style);
        nodeStyle[i] = {
          visibility: visibility,
          display: display
        }
      }
    },
    //这方法才能获取最终是否有display属性设置，不能style.display。
    resumeNodeStyle(noneNodes, nodeStyle) {
      var i = 0;
      for (; i < noneNodes.length; i++) {
        noneNodes[i].style.visibility = nodeStyle[i].visibility;
        noneNodes[i].style.display = nodeStyle[i].display;
      }
    },
    getTop(obj) {
      //获取元素到body顶部的距离
      let iTop = 0;
      while (obj) {
        iTop += obj.offsetTop;
        obj = obj.offsetParent;
      }
      return iTop;
    },
    getLeft(obj) {
      //获取元素到body左边的距离
      let iLeft = 0;
      while (obj) {
        iLeft += obj.offsetLeft;
        obj = obj.offsetParent;
      }
      return iLeft;
    },
    // 选中与增加计数点
    choseAndCount(event, item, imgkug) {
      if (event.button == 0) {//左键
        if (item.index.toString() != sessionStorage.getItem('chromoIndex')) {
          //选中 
          sessionStorage.setItem('chromoIndex', item.index)
          this.$parent.chooseChromoByImage(item.index);
        } else {
          //计数
          this.$parent.addPointToChromo(item.index);
          this.creatDot(imgkug.childNodes[0], item)
        }
      } else if (event.button == 2) {//右键
        this.$parent.deletePointFromChromo(item.index, item);
        this.creatDot(imgkug.childNodes[0], item)
        this.$parent.cachePool.save();
      }
    },
    hover(id, hoverflag) {
      let oDiv = document.getElementById(id);
      let pDiv = oDiv.parentNode;
      if (!hoverflag || !this.isEnlarge) {
        pDiv.onmousemove = null;
        return
      }
      // let oDiv = document.getElementById(id);
      let aImg = oDiv.getElementsByClassName("img-kug-child");
      let scale = this.chromoEnlarge;
      if (scale == 1) return
      pDiv.onmousemove = (ev) => {
        let $ev = ev || event;
        let L = $ev.clientX;
        let T = $ev.clientY;
        for (let i = 0; i < aImg.length; i++) {

          let x = $(aImg[i]).offset().left + aImg[i].offsetWidth / 2;
          let y = $(aImg[i]).offset().top + aImg[i].offsetHeight / 2
          let a = Math.pow(L - x, 2); //鼠标的位置与对应图片的中心点之间水平距离的平方
          let b = Math.pow(y - T, 2); //鼠标位置与对应图片中心点之间垂直距离的平方
          let c = Math.sqrt(b + a) / 350; //鼠标到图片中心点的距离

          let j = 0;
          let zoom = Math.max(1, Math.min(scale - c + 0.2, this.chromoEnlarge + 0.2));

          if (id == "box2") {
            j = parseInt(this.num - 1) + i;
          } else {
            j = i;
          }
          aImg[i].getElementsByTagName("img")[0].style.width =
            this.data[j].width * zoom + "px";
          aImg[i].getElementsByTagName("img")[0].style.height =
            this.data[j].height * zoom + "px";
        }

      };
    },
    // 监控数据，进行排序
    async baseDataChange(newVal) {
      let data = cloneDeep(newVal);
      // this.whArr = [];
      this.originalData = [];
      let pointsSum = 0;
      let originalData = [];
      data.forEach((item, i) => {
        let img = this.$parent.getImageByChromo(newVal[i]);
        if (img) {
          item.img = img;
          item.width = img.naturalWidth;
          item.height = img.naturalHeight;
          originalData.push({
            width: img.naturalWidth,
            height: img.naturalHeight
          })
          let countPoints = typeof item.countPoints === 'string' ? JSON.parse(item.countPoints) : item.countPoints;
          item.countNum = countPoints.length;
          pointsSum += item.countNum;
        }
      })
      this.colorGreen = pointsSum == 46 ? true : false;
      data.sort(function (a, b) {
        return b['height'] - a['height']
      });
      originalData.sort(function (a, b) {
        return b['height'] - a['height']
      });
      this.data = data;
      this.originalData = originalData;
      this.getWidthHeight();//得出宽高
      this.initScale(this.data);
      this.continueScale(this.data);
      this.karyoScale = this.defaultScale
      if (this.countScale < 1.5) {
        this.karyoScale = this.defaultScale * this.countScale;
      }
      this.changeKaryos(this.data, this.karyoScale);
    },
    addDelPoints(index, countPoints) {
      let pointsSum = 0;
      let points = typeof countPoints === 'string' ? JSON.parse(countPoints) : countPoints;
      this.data.forEach((item) => {
        if (item.index == index) {
          item.countNum = points.length
          // this.$forceUpdate();
          var id = item.id + "-" + item.index;
          this.creatDot(document.getElementById(id), item)
        }
        pointsSum += item.countNum;
      })
      this.colorGreen = pointsSum == 46 ? true : false;

    },
    //画布中hover染色体，全部染色体区显示对应颜色
    getHoverColor(chromo, color) {
      this.data.forEach((item) => {
        if (item.index == chromo.index && item.id == chromo.id) {
          item.borderColor = color;
          var id = item.id + "-" + item.index;
          document.getElementById(id).style.borderColor = color;
          this.$forceUpdate();
        }
      })
    },
    undo() {
      this.$parent.cachePool.undo((item) => {
        this.$parent.resetControllerStage(item);
      });
    },
    init(newVal) {
      const check = () => {
        if (this.$parent.arrangeController && this.$parent.arrangeController.initFinished && !this.$parent.arrangeController.reArranging) {
          try {
            this.baseDataChange(newVal);
          } catch (error) {

          }
        } else {
          setTimeout(check, 100)
        }
      }
      check();
    }
  },
  watch: {
    hoverflag(newVal) {
      let oDiv = document.getElementById('box');
      let aImg = oDiv.getElementsByTagName("img");
      for (let i = 0; i < aImg.length; i++) {
        if (!newVal) {
          aImg[i].style.width = this.data[i].width + 'px';
          aImg[i].style.height = this.data[i].height + 'px';
          aImg[i].style.transition = "0.3s";
        } else {
          aImg[i].style.transition = "unset";
        }
      }
    },
    hoverflag2(newVal) {
      let oDiv = document.getElementById('box2');
      let aImg = oDiv.getElementsByTagName("img");
      for (let i = 0; i < aImg.length; i++) {
        let j = parseInt(this.num - 1) + i;
        if (!newVal) {
          aImg[i].style.width = this.data[j].width + 'px';
          aImg[i].style.height = this.data[j].height + 'px';
          aImg[i].style.transition = "0.3s";
        } else {
          aImg[i].style.transition = "unset";
        }
      }
    },
    imgData(newVal) {
      sessionStorage.setItem('chromoIndex', '');

    },
    '$store.state.analysisInfo.chromoDefault': {
      handler: function (newVal) {
        if (newVal >= 1.5) {
          newVal = 1.5;
        } else if (newVal <= 0.5) {
          newVal = 0.5;
        }
        this.defaultScale = newVal;
        // this.initScale(this.data);
        // this.continueScale(this.data);
        this.karyoScale = this.defaultScale
        if (this.countScale < 1.5) {
          this.karyoScale = this.defaultScale * this.countScale;
        }
        this.changeKaryos(this.data, this.karyoScale);
      },
      immediate: true
    },
    colorGreen(val) {
      const count_dot = document.getElementsByClassName('count-dot');
      count_dot.forEach((item) => {
        item.childNodes.forEach((it) => {
          if (val) {
            it.className = "green";
          } else {
            it.className = "";
          }
        })
      })
    }
  }
};
</script>

<style lang="scss">
.hoverImage {
  [v-cloak] {
    display: none !important;
  }
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // padding: 0 10px 0 20px;
  padding: 0 20px;
  box-sizing: border-box;
  .all-karyo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-top: 7px;
  }
  .karyos {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .box {
    width: auto;
    // height: auto;
    height: 100%;
    // position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    white-space: nowrap;
    display: flex;
    // transform: translateX(-50%);
    .img-kug {
      position: relative;
      // display: inline-block;
      display: flex;
      align-items: flex-end;
      width: auto;
      // border: 1px solid #ccc;
      margin-right: 2px;
      &.red-kug {
        .img-kug-child {
          border: 1px solid rgba(245, 53, 0, 0.8);
        }
      }
      .count-dot {
        position: absolute;
        top: -10px;
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: center;
        width: 100%;
        i {
          width: 6px;
          height: 6px;
          border-radius: 6px;
          background: rgba(245, 53, 0, 0.8);
          border: 1px solid #333;
          &.green {
            background: rgba(46, 198, 87, 0.8);
          }
        }
      }
      img {
        width: 100%;
        display: flex;
        transform-origin: center bottom;
      }
      .img-kug-child {
        display: flex;
        position: relative;
        border: 1px solid #ccc;
      }
    }
  }
  .inner {
    width: 100%;
  }

  // .box img {
  //     width: auto;
  //     display: flex;
  // }
  .btns {
    position: absolute;
    display: flex;
    bottom: 4px;
    right: 5px;
    opacity: 0.8;
    transform: scale(0.8);
    .small_large_btn {
      display: flex;
      width: 110px;
      height: 34px;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      box-sizing: border-box;
      background: url("../../../../assets/images/common/icon_Search_qianse.svg")
        no-repeat;
      .small-btn,
      .enlarge-btn {
        width: 28px;
        height: 28px;
        color: #fff;
        font-size: 20px;
        text-align: center;
        background: #c4c4c4;
        margin: 0;
        padding: 0;
        border-radius: 50%;
        border: none;
        outline: none;
      }
      .small-btn {
        span {
          display: block;
          width: 14px;
          height: 2px;
          background: #ffffff;
          border-radius: 1px;
          margin: auto;
        }
      }
      .enlarge-btn {
        span {
          position: relative;
          display: block;
          width: 14px;
          height: 2px;
          background: #ffffff;
          border-radius: 1px;
          margin: auto;
          &::before {
            content: "";
            position: absolute;
            top: -6px;
            left: 6px;
            width: 2px;
            height: 14px;
            background: #ffffff;
            border-radius: 1px;
          }
        }
      }
    }
    .undo-btn {
      width: 34px;
      height: 34px;
      border: none;
      background: url("../../../../assets/images/common/btn_tool_cexiao_normal.svg")
        no-repeat;
      padding: 0;
    }
  }
}
</style>
