<template>
  <div class="generateFormBox">
    <div style="
                position: absolute;
                top: -10000px;
                left: -10000px;
                display: none;
            " id="divContainer"></div>
    <div style="position: absolute; top: -10000px; left: -10000px" id="div01"></div>
    <input type="file" id="selectfiles" style="display: none" />
    <img src="" alt="" id="ossImg" style="
                position: absolute;
                display: none;
                top: -10000px;
                left: -10000px;
            " />
    <el-dialog :title="!!reportId ?  $t('popup.addnew_report.edit_title') : $t('popup.addnew_report.title')" :visible.sync="newDialogVisibleForm" :close-on-click-modal="false" width="830px" :before-close="closeFun">
      <div class="generateFormContent">
        <!-- <el-form ref="ruleForm" :model="ruleForm"> -->
        <div class="label-list">
          <label class="label-wrds">{{$t('popup.addnew_report.choose_report_template')}}：</label>
          <el-select v-model="ruleForm.templateName" :placeholder="$t('select_button.choose')" @change="selectTemplate" :disabled="!!reportId">
            <el-option v-for="item in templateList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <el-form ref="ruleForm" :model="ruleForm">
          <div class="template-info">
            <div class="center" v-if="rects.length" ref="template">
              <div class="inside" id="list">
                <VueDragResize v-for="(rect, index) in rects" :key="index" :preventActiveBehavior="true" :w="rect.styles.width" :h="rect.styles.height" :x="rect.styles.left" :y="rect.styles.top" :ftSize="rect.styles.fontSize" :ftWeight="rect.styles.fontWeight" :ftColor="rect.styles.color" :ftFamily="rect.styles.fontFamily" :isActive="false" :isDraggable="false" :isResizable="false">
                  <!-- 图片 -->
                  <div class="outer-wrap" v-if="rect.prop == LOGOIMG">
                    <img v-if="rect.imgUrl" :src="rect.imgUrl" class="avatar" />
                  </div>
                  <!-- label/input/select/textarea -->
                  <div class="outer-wrap" v-else-if="
                                            rect.prop == LABEL ||
                                            rect.prop == SELECT ||
                                            rect.prop == INPUT ||
                                            rect.prop == DATE ||
                                            rect.prop == LABELTEXTAREA
                                        ">
                    <label class="label-width" :class="{
                                                required:
                                                    rect.configObj.isRequired,
                                            }" v-if="
                                                rect.prop == LABEL ||
                                                rect.prop == SELECT
                                            ">{{ rect.title || $t('selfDefined_template.designation') }}：</label>
                    <label class="label-textarea" v-if="rect.prop == LABELTEXTAREA" :class="{
                                                required:
                                                    rect.configObj.isRequired,
                                            }" :style="{
                                                height:
                                                    utils.textWidth(
                                                        rect.title,
                                                        rect
                                                    ).height + 'px',
                                            }">{{ rect.title || $t('selfDefined_template.designation') }}：</label>
                    <label class="label-width" :class="{
                                                required:
                                                    rect.configObj.isRequired,
                                            }" v-if="rect.prop == DATE">{{ rect.title || $t('selfDefined_template.date') }}：</label>
                    <input class="nokug" v-if="rect.prop == INPUT" style="outline: none" readonly v-model="rect.value" :style="{
                                                fontSize:
                                                    rect.styles.fontSize + 'px',
                                                fontWeight:
                                                    rect.styles.fontWeight,
                                                fontFamily:
                                                    rect.styles.fontFamily,
                                                color: rect.styles.color,
                                            }" :class="{
                                                noline: !rect.configObj
                                                    .showLabelLine,
                                                'dashed-line':
                                                    rect.configObj
                                                        .showDashedLine,
                                            }" />
                    <el-form-item v-if="rect.prop == LABEL" :prop="rect.field" :rules="{
                                                required:
                                                    rect.configObj
                                                        .imitationRequired,
                                                message: rect.configObj
                                                    .imitationRequired
                                                    ? rect.title +  $t('report.cannot_empty')
                                                    : '',
                                            }">
                      <el-input v-model="rect.value" :placeholder="$t('report.input_content')" clearable :style="{
                                                    fontSize:
                                                        rect.styles.fontSize +
                                                        'px',
                                                    fontWeight:
                                                        rect.styles.fontWeight,
                                                    color: rect.styles.color,
                                                    fontFamily:
                                                        rect.styles.fontFamily,
                                                    height: rect.styles.height,
                                                }" @input="inputBlur($event, rect)"></el-input>
                    </el-form-item>
                    <el-form-item v-if="rect.prop == LABELTEXTAREA" :prop="rect.field" :rules="{
                                                required:
                                                    rect.configObj
                                                        .imitationRequired,
                                                message: rect.configObj
                                                    .imitationRequired
                                                    ? rect.title + $t('report.cannot_empty')
                                                    : '',
                                            }">
                      <el-input v-model="rect.value" type="textarea" :placeholder="$t('report.input_content')" clearable :style="{
                                                    fontSize:
                                                        rect.styles.fontSize +
                                                        'px',
                                                    fontWeight:
                                                        rect.styles.fontWeight,
                                                    fontFamily:
                                                        rect.styles.fontFamily,
                                                    color: rect.styles.color,
                                                }" @input="inputBlur($event, rect)"></el-input>
                    </el-form-item>
                    <el-form-item v-if="rect.prop == SELECT" :prop="rect.field" :rules="{
                                                required:
                                                    rect.configObj
                                                        .imitationRequired,
                                                message: rect.configObj
                                                    .imitationRequired
                                                    ? rect.title +$t('report.cannot_empty')
                                                    : '',
                                            }">
                      <el-select v-model="rect.value" :filterable="true" :allow-create="true" :style="{
                                                    fontSize:
                                                        rect.styles.fontSize +
                                                        'px',
                                                    fontWeight:
                                                        rect.styles.fontWeight,
                                                    fontFamily:
                                                        rect.styles.fontFamily,
                                                    color: rect.styles.color,
                                                }" :placeholder="$t('select_button.choose')" clearable @input="inputBlur($event, rect)">
                        <el-option v-for="(
                                                        item, index
                                                    ) in rect.options" :key="index" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-date-picker :prop="rect.field" :rules="{
                                                required:rect.configObj.imitationRequired,
                                                message: rect.configObj.imitationRequired? rect.title + $t('report.cannot_empty'): '',
                                            }" v-model="rect.value" v-if="rect.prop == DATE" :placeholder="$t('report.choose_date')" :type="rect.configObj.datatype" :style="{
                                                fontSize:rect.styles.fontSize + 'px',
                                                fontWeight:rect.styles.fontWeight,
                                                fontFamily:rect.styles.fontFamily,
                                                color: rect.styles.color,
                                            }" :format="rect.configObj.format" :value-format="
                                                rect.configObj.format
                                            ">
                    </el-date-picker>
                  </div>
                  <!-- 核型图排列图占位区 -->
                  <div class="outer-wrap img-position" v-else-if="rect.prop == IMGPOSITION" :class="{noline: !rect.configObj.showLabelLine,'dashed-line':rect.configObj.showDashedLine,}">
                    <div class="uploadImgBox" v-if="rect.field == KARYO &&rect.prop == IMGPOSITION" @click="karyotypeSelectionFun(rect)">
                      <i class="el-icon-plus avatar-uploader-icon" v-if="!rect.imgUrl &&!rect.isPercentage">{{$t('case_overview.karyoImage')}}</i>
                      <img v-if="rect.imgUrl &&!rect.isPercentage" :key="index" :src="rect.imgUrl" :style="{width: rect.imgWidth + 'px'}" class="avatar" :alt="$t('case_overview.karyoImage')" />
                      <el-progress v-if="rect.isPercentage" type="circle" :percentage="rect.percentage" :width="rect.percentageSize" :stroke-width="rect.strokeWidth"></el-progress>
                    </div>
                    <div class="uploadImgBox" v-if="rect.field == SEQUENCE &&rect.prop == IMGPOSITION" @click="karyotypeSelectionFun(rect)">
                      <i class="el-icon-plus avatar-uploader-icon" v-if="!rect.imgUrl &&!rect.isPercentage">{{$t('case_overview.array_image')}}</i>
                      <img v-if="rect.imgUrl &&!rect.isPercentage" :key="index" :src="rect.imgUrl" :style="{width: rect.imgWidth + 'px'}" class="avatar" :alt="$t('case_overview.array_image')" />
                      <el-progress v-if="rect.isPercentage" type="circle" :percentage="rect.percentage" :width="rect.percentageSize" :stroke-width="rect.strokeWidth"></el-progress>
                    </div>
                    <span v-if="rect.type == CUSTOM &&rect.prop == IMGPOSITION">
                      {{$t('report.image')}}
                    </span>
                  </div>
                  <div class="outer-wrap" v-else-if="rect.prop == ESIGNATURE" style="display: flex">
                    <label class="label-width">{{ rect.title || $t('selfDefined_template.designation') }}：</label>
                    <el-select v-model="rect.value" :placeholder="$t('select_button.choose')" clearable>
                      <el-option v-for="(item, index) in rect.options" :key="index" :label="item.name" :value="item.imgUrl">
                      </el-option>
                    </el-select>
                  </div>
                  <!-- 输入框 -->
                  <div v-else-if="rect.prop == BORDERKUG" class="outer-wrap borderkug">
                    <div class="border-kug" :class="{noline: !rect.configObj.showLabelLine,'dashed-line':rect.configObj.showDashedLine,}"></div>
                  </div>
                  <div v-else-if="rect.prop == LINE" class="outer-wrap controlline">
                    <p class="control-line" :class="{noline: !rect.configObj.showLabelLine,'dashed-line':rect.configObj.showDashedLine,}"></p>
                  </div>
                  <!-- 输入框 -->
                  <div v-else class="outer-wrap">
                    <textarea v-model="rect.value" readonly class="textarea" :style="{fontSize:rect.styles.fontSize + 'px',fontWeight:rect.styles.fontWeight,color: rect.styles.color, fontFamily:rect.styles.fontFamily,}" :class="{noline: !rect.configObj.showLabelLine,'dashed-line':rect.configObj.showDashedLine,}"></textarea>
                  </div>
                </VueDragResize>
              </div>
            </div>
          </div>
        </el-form>
        <div class="btnBox">
          <div class="btn updateBtn" @click="closeFun">{{$t('btns.cancel')}}</div>
          <!-- <div class="btn determineNomal" @click="onSubmit('ruleForm')">提交</div> -->
          <el-button class="btn determineNomal" @click="onSubmit('ruleForm')" :disabled="submitFlag">{{$t('btns.submit')}}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <KaryotypeSelection :karyotypeDialogVisible="karyotypeSelectionStatus" :kyreportId="reportId" :patientId="newPatientId" :karyoIds="karyotypeIdList" :control="control" @childFun="parentFun($event)" @karyoDataFun="karyoObjFun($event)"></KaryotypeSelection>
    <MessageBox :updateType="isConfirmation" :updateTips="confirmationTips" :openStatus="openStatus" @childFun="popupUpdateFun($event)"></MessageBox>
  </div>
</template>

<script>
import "./../../assets/scss/dialog.scss";
import "./../../assets/scss/button.scss";
import uploader from "../../assets/utils/fileUploader.js";
import KaryotypeSelection from "../../components/analysissystem/karyotypeSelectionSelf.vue";
import common from "../../assets/js/common.js";
import MessageBox from "./../messageBox";
import utils from "./config/utils.js";
import { cloneDeep } from 'lodash';

import {
  SELECT,
  INPUT,
  TEXTAREA,
  LABEL,
  DATE,
  LOGOIMG,
  IMGPOSITION, //图片占位区
  KARYO,
  SEQUENCE,
  OFFICIAL,
  CUSTOM,
  ESIGNATURE,
  BORDERKUG,
  LINE,
  LABELTEXTAREA,
} from "./config/props.js";
import VueDragResize from "./components/vue-drag-resize/vue-drag-resize.vue";
import karyoCanvasController from "./../autovision/modules/karyoCanvasController.js";
import arrangeCanvasController from "./../autovision/modules/arrangeCanvasController.js";

export default {
  name: "GenerateForm",
  props: ["isVisible", "patientId", "reportId", "karyoIdsGather", "templateList"],
  created() {
    this.getTemplateList();
    // this.getReportQuery(this.reportId);
  },
  activated() {
    // this.templateListFn();
  },
  components: {
    VueDragResize,
    KaryotypeSelection,
    MessageBox,
  },
  data() {
    return {
      newDialogVisibleForm: false,
      // templateList: [], //模板列表
      rects: [], //控件列表
      imageUrl: "",
      ruleForm: {},
      aloneReportInfo: "", //病例报告数据
      hideSex: "", //隐藏性染色体
      SELECT,
      INPUT,
      TEXTAREA,
      LABEL,
      DATE,
      LOGOIMG,
      IMGPOSITION,
      KARYO,
      SEQUENCE,
      OFFICIAL,
      CUSTOM,
      ESIGNATURE,
      BORDERKUG,
      LINE,
      LABELTEXTAREA,
      utils,
      karyotypeSelectionStatus: false, //核型图选择状态
      newPatientId: "", //病例ID
      karyotypeId: "", //核心图ID
      karyotypeIdList: [], //
      control: "", //控件ID
      isConfirmation: true, // 是否显示弹窗
      confirmationTips: "", // 弹窗提示文案
      openStatus: false, // 弹窗初始状态
      karyoExpresArr: [], //核型表达式数组
      karyoExpresArrLen: 0,
      karyoSumNum: 0,
      submitFlag: false,
    };
  },
  methods: {
    inputBlur(ev, rect) {
      if (!!rect.configObj.isRequired) {
        ev != ""
          ? (rect.configObj.imitationRequired = false)
          : (rect.configObj.imitationRequired = true);
      }
    },
    /**
     * @method: 获取模板列表
     * @Date: 2020-12-22 10:50:45
     */
    getTemplateList() {
      if (this.templateList && !!this.templateList.length) {
        this.ruleForm.templateName = this.templateList[0].name;
        this.ruleForm.reportTemplateId = this.templateList[0].id;
        this.templateQueryById(this.templateList[0].id);
      }
    },
    /**
     * @method: 选择模板
     * @Date: 2020-12-24 17:13:31
     */
    selectTemplate(id) {
      this.ruleForm.reportTemplateId = id;
      this.karyotypeIdList = [];
      this.templateQueryById(id);
      // this.getQueryOssLocal(this.karyotypeId, this.dname);
    },

    closeFun() {
      this.newDialogVisibleForm = false;
      this.karyotypeIdList = [];
      this.$emit("childFun", this.newDialogVisibleForm);
    },

    /**
     * @method: 根据id查询模板信息
     * @Date: 2020-12-23 18:31:41
     */
    templateQueryById(id) {
      this.$api.templateQueryById({ id: id }).then((res) => {
        if (res.code == 200) {
          let templateInfo = (this.templateInfo = res.data);
          let content =
            typeof templateInfo.content == "string"
              ? JSON.parse(templateInfo.content)
              : {};
          content.rects.forEach((item) => {
            !!item.imgUrl
              ? (item.imgUrl = this.ossUrlWebFir(
                item.imgUrl.split("?")[0],
                1,
                item.styles.width,
                item.styles.height
              ))
              : "";
            if (item.prop == ESIGNATURE) {
              let options = [];
              options = item.options;
              item.options = options;
            } else if (item.prop == DATE) {
              item.value = common.timestampFormat(
                new Date(),
                item.configObj.format
              );
            }
            item.configObj.imitationRequired =
              item.configObj.isRequired;
          });
          this.hideSex = content.hideSex;
          this.rects = content.rects;
          this.karyoExpresArr = [];
          this.karyoExpresArrLen = 0;
          let lenky = 0,
            lenseq = 0;
          content.rects.forEach((item) => {
            if (item.field == KARYO) {
              lenky++;
            } else if (item.field == SEQUENCE) {
              lenseq++;
            }
          });
          this.karyoExpresArrLen = Math.max(lenky, lenseq);
          this.karyoSumNum = lenky + lenseq;

          if (this.$refs["ruleForm"]) {
            this.$refs["ruleForm"].resetFields();
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @method: 查询报告单
     * @Date: 2020-12-29 09:13:37
     */
    getReportQuery(id) {
      this.$api.reportQuery({ id: id }).then((res) => {
        if (res.code == 200) {
          const { report, reportTemplate } = res.data;
          this.aloneReportInfo = report;
          this.ruleForm.templateName = reportTemplate.name;
          this.ruleForm.reportTemplateId = report.reportTemplateId;
          this.karyotypeId = report.karyoIds;
          this.mapData(reportTemplate);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @method: 修改时，将病例数据还原到模板中
     * @Date: 2020-12-28 13:25:27
     */
    mapData(reportTemplate) {
      const content =
        typeof reportTemplate.content == "string"
          ? JSON.parse(reportTemplate.content)
          : {};
      const KARYOArr =
        typeof this.aloneReportInfo[KARYO] == "string"
          ? JSON.parse(this.aloneReportInfo[KARYO])
          : [];
      const SEQUENCEArr =
        typeof this.aloneReportInfo[SEQUENCE] == "string"
          ? JSON.parse(this.aloneReportInfo[SEQUENCE])
          : [];
      content.rects.forEach((item) => {
        item.imgUrl = !!item.imgUrl ? this.ossUrlWebFir(
          item.imgUrl.split('?')[0],
          1,
          item.styles.width,
          item.styles.height
        ) : ''
        if (item.type == OFFICIAL) {
          if (item.field === KARYO) {
            KARYOArr.forEach((it) => {
              if (item.id === it.id) {
                // if (it.imgUrl.indexOf('.online') == -1) {
                item.imgUrl = this.ossUrlWebFir(
                  it.imgUrl,
                  1,
                  item.styles.width,
                  item.styles.height
                );
                // } else {
                //     let width;
                //     if (item.styles.width > item.styles.height) {
                //         width = parseInt((4 * item.styles.height) / 3);
                //     } else {
                //         width = item.style.width;
                //     }
                //     item.imgUrl = it.imgUrl;
                //     item.imgWidth = width;
                // }
              }
            });
          } else if (item.field === SEQUENCE) {
            SEQUENCEArr.forEach((it) => {
              if (item.id === it.id) {
                // if (it.imgUrl.indexOf('.online') == -1) {
                item.imgUrl = this.ossUrlWebFir(
                  it.imgUrl,
                  1,
                  item.styles.width,
                  item.styles.height
                );
                // } else {
                //     let width;
                //     if (item.styles.width > item.styles.height) {
                //         width = parseInt((4 * item.styles.height) / 3);
                //     } else {
                //         width = item.style.width;
                //     }
                //     item.imgUrl = it.imgUrl;
                //     item.imgWidth = width;
                // }
              }
            });
          } else if (item.prop === DATE) {
            item.value = common.timestampFormat(
              this.aloneReportInfo[item.field],
              item.configObj.format
            );
          } else {
            item.value = this.aloneReportInfo[item.field] || "";
          }
        } else {
          if (!!this.aloneReportInfo.extInfo) {
            let extInfo = JSON.parse(this.aloneReportInfo.extInfo);
            item.value = extInfo[item.field];
          }
        }
      });

      this.rects = content.rects;
    },

    // 提交模板表单
    postReportEdit() {
      let obj = {};
      let KARYOArr = [];
      let SEQUENCEArr = [];
      let KARYOFlag = 0;
      let SEQUENCEFlag = 0;
      let rectsCopy = [];
      rectsCopy = cloneDeep(this.rects);
      //提交时，将模板数据剥离出来保存在病例表中
      for (let i = 0; i < rectsCopy.length; i++) {
        let item = rectsCopy[i];
        if (item.field == LOGOIMG) {
          item.imgUrl = !!item.imgUrl ? item.imgUrl.split('?')[0] : "";
        }
        if (item.prop === DATE) {
          JSON.stringify(item.value) === "{}"
            ? (item.value = common.timestampFormat(
              new Date(),
              item.configObj.format
            ))
            : "";
        }
        if ((item.field === KARYO || item.field === SEQUENCE) && !item.imgUrl) {
          this.$message.error("您还没有上传核型图或者排列图");
          return
        }
        if (item.type === OFFICIAL) {
          if (item.field === KARYO && !!item.imgUrl) {
            KARYOFlag += 1;
            if (item.imgUrl.indexOf('.online') == -1) {
              item.imgUrl = !!item.imgUrl ? item.imgUrl.split('?')[0] : '';
            }
            KARYOArr.push({
              id: item.id,
              imgUrl: item.imgUrl,
            });
          } else if (item.field === SEQUENCE && !!item.imgUrl) {
            SEQUENCEFlag += 1;
            if (item.imgUrl.indexOf('.online') == -1) {
              item.imgUrl = !!item.imgUrl ? item.imgUrl.split('?')[0] : '';
            }
            SEQUENCEArr.push({
              id: item.id,
              imgUrl: item.imgUrl,
            });
          } else {
            this.ruleForm[item.field] = item.value || "";
          }
        } else {
          obj[item.field] = item.value;
          this.ruleForm.extInfo = JSON.stringify(obj);
        }
      }

      if (KARYOFlag != KARYOArr.length) {
        this.$message.error(this.$t('report.karyoImage_upload_fully'));
        return;
      }

      if (SEQUENCEFlag != SEQUENCEArr.length) {
        this.$message.error(this.$t('report.arrayImage_upload_fully'));
        return;
      }

      this.ruleForm[KARYO] = JSON.stringify(KARYOArr);
      this.ruleForm[SEQUENCE] = JSON.stringify(SEQUENCEArr);

      let karyoIds = [];
      this.karyotypeIdList.forEach((item) => {
        karyoIds.push(item.karyoId);
      });

      this.ruleForm.id = null;
      this.ruleForm.patientId = this.newPatientId;
      this.ruleForm.karyoIds = karyoIds.toString();
      let msgInfo = this.$t('report.add_success');

      if (!!this.reportId) {
        this.ruleForm.id = this.reportId;
        this.ruleForm.karyoIds = this.karyotypeId;
        this.ruleForm.templateArrayUrl = this.aloneReportInfo.templateArrayUrl;
        this.ruleForm.templateOptUrl = this.aloneReportInfo.templateOptUrl;
        msgInfo = this.$t('report.modify_success');
      }
      this.submitFlag = true;
      let timeout = null;
      this.$api.reportEdit(this.ruleForm).then((res) => {
        if (res.code == 200) {
          let string = this.ruleForm.karyoIds;
          let stringResult = string.split(",");
          for (let i = 0; i < stringResult.length; i++) {
            setTimeout(() => {
              this.$store.commit(
                "addReviseGlassIdStyle",
                stringResult[i]
              );
            }, 50);
          }
          this.openStatus = false;
          this.$emit("onsubmit", true);
          this.$store.commit("isUpdateListType", true);
          this.$message.success(msgInfo);
          this.$parent.getReportListByPatientId();
          this.closeFun();
          this.ruleForm.confirm = 0;
          this.karyotypeIdList = [];
          clearTimeout(timeout);
          //因为弹窗还没有关闭，此时又点击了弹窗，会发生错误
          timeout = setTimeout(() => {
            this.submitFlag = false;
          }, 600);
        } else if (res.code == 150) {
          this.confirmationTips = res.message;
          this.openStatus = true;
        } else {
          this.$message.error(res.message);
          this.submitFlag = false;
        }
      });
    },

    // 返回code=150时调用
    popupUpdateFun(payload) {
      payload
        ? ((this.ruleForm.confirm = 1), this.postReportEdit())
        : (this.openStatus = payload);
    },

    /**
     * @method:提交
     * @Date: 2020-12-24 17:13:50
     */
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.postReportEdit();
        } else {
          return false;
        }
      });
    },

    parentFun(payload) {
      this.karyotypeSelectionStatus = payload;
    },
    karyotypeSelectionFun(rect) {
      if (this.reportId != "") return;
      this.control = rect;
      this.karyotypeSelectionStatus = true;
      this.submitFlag = true;
    },
    karyoObjFun(payload) {
      //判断有没有重选
      if (
        !!this.karyotypeIdList.length &&
        this.karyotypeIdList.every((item) => {
          return item.karyoId == payload.karyotypeId;
        })
      )
        return;

      this.ruleForm.karyoName =
        payload.glassName + "/" + payload.cellName;
      payload.control.karyoId = payload.karyotypeId;
      //
      if (
        this.karyotypeIdList.every((item) => {
          return (
            item.controlId != payload.control.id &&
            item.karyoId != payload.karyotypeId
          );
        })
      ) {
        this.karyotypeIdList.push({
          controlId: payload.control.id,
          karyoId: payload.karyotypeId,
        });
      } else {
        this.karyotypeIdList.forEach((item) => {
          if (item.controlId == payload.control.id) {
            item.karyoId = payload.karyotypeId;
          }
        });
      }
      //自动填写核型表达式
      this.rects.forEach((item) => {
        if (item.field == "karyotypeExpression") {
          this.karyoExpresArr.push(payload.karyotypeExpression);
          if (this.karyoExpresArr.length > this.karyoExpresArrLen) {
            this.karyoExpresArr.shift();
          }
          this.$set(item, "value", this.karyoExpresArr.join("/"));
        }
      });

      this.dname = payload.dname;
      if (!this.hideSex) {
        //不隐藏染色体
        this.rects.forEach((item) => {
          if (
            item.configObj.relationNum ==
            payload.control.configObj.relationNum
          ) {
            if (item.field == KARYO) {
              this.getQueryOssLocal(
                payload.karyotypeId,
                payload.dname,
                item,
                payload
              );
            }
            if (item.field == SEQUENCE) {
              let width;
              if (item.styles.width > item.styles.height) {
                width = parseInt((4 * item.styles.height) / 3);
              } else {
                width = item.style.width;
              }
              item.imgUrl = payload.permutationUrl;
              item.imgWidth = width;
            }
          }
        });
      } else {
        this.rects.forEach((item) => {
          if (
            item.configObj.relationNum ==
            payload.control.configObj.relationNum
          ) {
            this.getQueryOssLocal(
              payload.karyotypeId,
              payload.dname,
              item,
              payload
            );
          }
        });
      }
    },
    getQueryOssLocal(karyoId, dname, control, payload) {
      control.isPercentage = true;
      control.percentageSize = 3 / 5;
      control.percentage = 10;
      const minValue =
        control.styles.width > control.styles.height
          ? control.styles.height
          : control.styles.width;
      control.percentageSize = minValue * control.percentageSize;
      switch (true) {
        case minValue > 100:
          control.strokeWidth = 6;
          break;
        case minValue >= 50 && minValue <= 100:
          control.strokeWidth = 4;
          break;
        case minValue < 50:
          control.strokeWidth = 2;
          break;

        default:
          break;
      }
      let setPercentage = setInterval(() => {
        control.percentage += 5;
        if (control.percentage >= 99) {
          control.percentage = 99;
        }
      }, 100);
      if (karyoId == "") return;
      this.$api
        .queryOssLocal({
          patientId: this.newPatientId,
          karyoId: karyoId,
        })
        .then((res) => {
          if (res.code == 200) {
            const { chromoList, karyo } = res.data;

            this.getBase64Fun(
              chromoList,
              karyo,
              dname,
              control,
              setPercentage,
              payload
            );
          } else {
            this.$message.error(res.message);
          }
        });
    },
    getBase64Fun(
      chromoList,
      karyoList,
      dname,
      control,
      setPercentage,
      payload
    ) {
      if (control.field === KARYO) {
        const canvasContainer = document.createElement("canvas");
        const canvaskaryo = document.createElement("canvas");
        canvaskaryo.width = 1000;
        canvaskaryo.height = 800;
        canvasContainer.appendChild(canvaskaryo);
        document.body.appendChild(canvasContainer);
        const karyoController = new karyoCanvasController({
          element: canvaskaryo,
          chromoList: chromoList,
          karyoInfo: {
            id: karyoList[0].id,
            optUrl: this.ossUrlWebFir(
              karyoList[0].optUrl,
              7
            ), //karyoList[0].optUrl
            optBackUrl: this.ossUrlWebFir(
              karyoList[0].optBackUrl,
              7
            ), //karyoList[0].optBackUrl,
            primaryUrl: this.ossUrlWebFir(
              karyoList[0].primaryUrl,
              7
            ), //karyoList[0].primaryUrl,
            undetectedChromoPoints:
              karyoList[0].undetectedChromoPoints,
          },
          arrowList: !karyoList[0].originalArrowLines
            ? []
            : JSON.parse(karyoList[0].originalArrowLines),
          simple: true,
          app: null
        });

        //当没有箭头且不隐藏性染色体时，不用重新画，直接取地址
        if (!karyoController.arrowList.length && !this.hideSex) {
          let width, height;
          if (control.styles.width > control.styles.height) {
            // height = item.styles.height;
            width = parseInt((4 * control.styles.width) / 3);
          } else {
            width = control.style.width;
            // height = parseInt((3 * width) / 4);
          }
          control.imgUrl = payload.karyotypeUrl;
          control.imgWidth = width;
          // control.imgHeight = height;
          control.isPercentage = false;
          this.$forceUpdate();
          clearInterval(setPercentage);
          setPercentage = null;
          canvasContainer.remove();
          this.submitFlag = false;
          return;
        }

        let timerkaryo = setInterval(() => {
          if (karyoController.initFinished) {
            clearInterval(timerkaryo);
            timerkaryo = null;
            karyoController
              .exportBase64({ isHideXY: this.hideSex })
              .then(async (base64) => {
                canvasContainer.remove();
                let options = {
                  scale: 1,
                  width: control.styles.width,
                  height: control.styles.height,
                };
                let newBase64 = await this.dealImage(
                  base64,
                  options
                );

                let blob = this.dataURLtoBlob(newBase64);
                dname = dname.split(".")[0] + ".jpeg";
                let _file = this.blobToFile(blob, dname);
                _file.upname =
                  new Date().getTime() +
                  "karyoUrl." +
                  _file.name.split(".").pop();
                this.uploadImg(_file, control, setPercentage);
              });
          }
        });
      } else {
        const canvasContainer = document.createElement("canvas");
        const canvasArrange = document.createElement("canvas");
        canvasArrange.width = 1000;
        canvasArrange.height = 800;
        canvasContainer.appendChild(canvasArrange);
        document.body.appendChild(canvasContainer);
        const arrangeController = new arrangeCanvasController({
          element: canvasArrange,
          chromoList: chromoList,
          karyoInfo: karyoList[0],
          arrowList: !karyoList[0].arrangeArrowLines
            ? []
            : JSON.parse(karyoList[0].arrangeArrowLines),
          simple: true
        });
        let timerArrange = setInterval(() => {
          if (arrangeController.initFinished) {
            clearInterval(timerArrange);
            timerArrange = null;
            arrangeController
              .exportBase64({ isHideXY: this.hideSex })
              .then(async (base64) => {
                canvasContainer.remove();
                let options = {
                  scale: 1,
                  width: control.styles.width,
                  height: control.styles.height,
                };
                let newBase64 = await this.dealImage(
                  base64,
                  options
                );
                let blob = this.dataURLtoBlob(newBase64);
                dname = dname.split(".")[0] + ".jpeg";
                let _file = this.blobToFile(blob, dname);
                _file.upname =
                  new Date().getTime() +
                  "arrayUrl." +
                  _file.name.split(".").pop();
                this.uploadImg(_file, control, setPercentage);
              });
          }
        });
      }
    },
    //将base64转换为blob
    dataURLtoBlob: function (dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //将blob转换为file
    blobToFile: function (theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    //压缩图片
    async dealImage(base64, options) {
      return new Promise((resolve) => {
        let newImage = new Image();
        newImage.src = base64;
        newImage.setAttribute("crossOrigin", "Anonymous"); //url为外域时需要
        newImage.onload = function () {
          let that = this;
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          let width, height;
          if (options.width > options.height) {
            height = options.height;
            width = parseInt((4 * height) / 3);
            // width = options.width * ctx.height / height;
          } else {
            width = options.width;
            height = parseInt((3 * width) / 4);
          }
          canvas.setAttribute("width", width);
          canvas.setAttribute("height", height);
          ctx.drawImage(that, 0, 0, width, height);
          let base64 = canvas.toDataURL("image/jpeg", options.scale); //压缩语句
          resolve(base64);
          // callback(base64); //必须通过回调函数返回，否则无法及时拿到该值
        };
      });
    },
    //图片上传
    uploadImg: function (file, control, setPercentage) {
      uploader({
        files: [file],
        path: "user/certificate/",
        ossUploadAccess: this.$store.state.ossUploadAccess,
      }).then((filelist) => {
        control.imgUrl = this.ossUrlWebFir(
          filelist[0].path,
          1,
          control.styles.width,
          control.styles.height
        );
        control.isPercentage = false;
        this.$forceUpdate();
        clearInterval(setPercentage);
        setPercentage = null;
        this.karyoSumNum--;
        if (this.karyoSumNum <= 0) {
          this.submitFlag = false;
        }
        // if (!!img1 && !!img2) {
        //     this.submitFlag = false;
        // }
      });
    },
  },
  watch: {
    isVisible() {
      this.newDialogVisibleForm = this.isVisible;
      this.flag = true; // 是否关闭日期
      this.newPatientId = this.patientId;
      !!this.reportId ? this.getReportQuery(this.reportId) : this.getTemplateList();
    },
  },
};
</script>

<style lang="scss" scoped>
.generateFormBox {
  .generateFormContent {
    position: relative;
    height: 750px;
  }
  .label-list {
    display: flex;
    height: 32px;
    margin-bottom: 10px;
    .label-wrds {
      width: unset;
      min-width: 98px;
      line-height: 32px;
      text-align: right;
    }
    .el-input {
      flex: 1;
      outline: none;
    }
    .el-select {
      flex: 1;
    }
  }

  .template-info {
    position: relative;
    height: 660px;
    overflow-y: auto;
    border: 1px solid #e2e6ed;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      background: linear-gradient(270deg, #b4b5b5 0%, #bdbebf 100%);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 5px;
      background: #fff;
    }
    .center {
      position: absolute;
      left: 0;
      top: 10px;
      width: 780px;
      padding-bottom: 10px;
      border-right: 0;
      background: #fff;
      border-radius: 4px 4px 0px 0px;
      box-sizing: border-box;
      .inside {
        position: relative;
        width: 700px;
        height: 994px;
        margin: 0 auto;
      }
      .outer-wrap {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        min-height: 26px;
        box-sizing: border-box;
        z-index: 1;
        .el-form-item {
          flex: 1;
          height: 100%;
          margin-bottom: 0;
        }

        .el-input {
          display: flex;
          align-items: center;
          flex: 1;
          outline: none;
          height: 100%;
          overflow: hidden;
        }
        .label-textarea {
          position: relative;
          display: flex;
          height: auto;
          min-height: 24px;
          align-items: center;
          &.required {
            &::before {
              content: "*";
              position: absolute;
              color: #f56c6c;
              left: -8px;
              font-size: 14px;
            }
          }
          &.input-label {
            border: 0;
          }
          &.input-label:focus {
            border: 1px dashed #6b6a6a;
          }
        }
        .label-width {
          position: relative;
          display: flex;
          height: 100%;
          align-items: center;
          &.required {
            &::before {
              content: "*";
              position: absolute;
              color: #f56c6c;
              left: -8px;
              font-size: 14px;
            }
          }
          &.input-label {
            border: 0;
          }
          &.input-label:focus {
            border: 1px dashed #6b6a6a;
          }
        }
        // .l .colon {
        //     display: flex;
        //     height: 100%;
        //     justify-content: center;
        //     align-items: center;
        // }

        .line {
          flex: 1;
          height: 22px;
          border: 0;
          border-bottom: 1px solid #6b6a6a;
          background: none;
          overflow: hidden;
          &.custom-mrg {
            margin: 0 0 0 -8px;
          }
        }
        &.controlline {
          justify-content: center;
          align-items: center;
          min-height: 1px;
        }
        .control-line {
          width: 100%;
          height: 1px;
          border-bottom: 1px solid #6b6a6a;
          margin: 0;
          &.dashed-line {
            border: 0;
            border-bottom: 1px dashed #6b6a6a;
          }
          &.noline {
            border: 0;
          }
        }
        .nokug {
          width: 100%;
          border: 1px solid #6b6a6a;
          text-align: center;
          padding: 0;
          border-radius: 4px;
          &.dashed-line {
            border: 1px dashed #6b6a6a;
          }
          &.noline {
            border: 0;
            background: none;
          }
        }
        &.img-position {
          width: 100%;
          height: 100%;
          display: flex;
          text-align: center;
          align-items: center;
          border: 1px solid #6b6a6a;
          border-radius: 4px;
          overflow: hidden;
          &.dashed-line {
            border: 1px dashed #6b6a6a;
          }
          &.noline {
            border: 0;
          }
          span {
            width: 100%;
          }
          .el-upload--picture-card {
            width: 100% !important;
            height: 100% !important;
          }
          .uploadImgBox {
            cursor: pointer;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .relationNum {
            width: 14px;
            height: 14px;
            background: red;
            border-radius: 50%;
            position: absolute;
            top: -7px;
            left: -7px;
            font-size: 12px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .textarea {
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 5px;
          line-height: 20px;
          border: 1px solid #6b6a6a;
          box-sizing: border-box;
          border-radius: 4px;
          outline: none;
          resize: none;
          overflow: hidden;
          &.dashed-line {
            border: 1px dashed #6b6a6a;
          }
          &.noline {
            border: 0;
          }
        }
        &.borderkug {
          z-index: 0;
        }
        .border-kug {
          border: 1px solid #6b6a6a;
          border-radius: 4px;
          width: 100%;
          height: 100%;
          z-index: 0;
          &.dashed-line {
            border: 1px dashed #6b6a6a;
          }
          &.noline {
            border: 0;
          }
        }
      }
      .esignature-img {
        position: absolute;
        top: 0px;
        left: 100%;
      }
    }
  }
  .btnBox {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    .updateBtn {
      width: 80px;
      height: 30px;
    }
    // .determineNomal {
    //     margin-left: 12px;
    //     width: 82px;
    //     height: 32px;
    // }
    .determineNomal {
      margin-left: 12px;
      width: 82px;
      height: 32px;
      color: #fff;
      border: 0;
      &.el-button--default {
        background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
      }
      &:hover {
        // opacity: 0.8;
        // background: #5092d4;
        background: linear-gradient(90deg, #3a83ce 0%, #5092d4 100%);
      }
      &:disabled {
        background: #8eaddc;
      }
    }
  }
  .avatar-uploader {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
  }
  .avatar {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    display: block;
  }
  .save-btn {
    position: absolute;
    bottom: 100px;
    left: 800px;
  }
  .vdr.active:before {
    outline: none;
  }
  .control-label {
    .control-title {
      height: 26px;
      padding-left: 5px;
      background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
      color: #fff;
      line-height: 26px;
    }
    .add-control-label {
      color: #606266;
      font-size: 14px;
      margin-left: 5px;
      .el-button {
        padding: 1px 2px;
      }
    }
  }
  .upload {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.generateFormBox {
  .el-dialog__wrapper {
    // display: unset;
    // overflow: hidden;
    // .el-dialog {
    //     height: 836px;
    // }
  }
  .el-form-item__content {
    height: 100%;
    .el-textarea {
      height: 100%;
      vertical-align: unset;
      // margin-top: -5px;
    }
    .el-textarea__inner {
      height: 100%;
      line-height: normal;
      resize: none;
      padding: 2px;
    }
  }

  .el-dialog__body {
    padding: 30px 20px 10px;
  }
  .el-form-item__error {
    width: 200%;
    top: unset;
    bottom: -12px;
  }
  .el-input__inner {
    height: 100%;
    line-height: normal;
    padding: 0 6px;
    background: none;
    vertical-align: top;
  }
  .el-input__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: inherit;
  }
  .el-icon-date,
  .el-icon-time {
    margin-top: 1px;
    line-height: inherit;
  }
  .el-input__suffix {
    right: 0;
  }
  .el-input .el-select__caret {
    vertical-align: top;
  }
  .el-select {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .el-input {
      height: 100%;
      font-size: inherit;
      font-weight: inherit;
    }
  }
  .el-input.is-disabled .el-input__inner {
    background-color: #f5f7fa;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    flex: 1;
    .el-input__inner {
      padding-left: 20px;
    }
    .el-input__prefix {
      left: 0;
    }
    .el-input__suffix {
      // top: -3px;
      right: -3px;
    }
  }
  .avatar-uploader .el-upload {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    z-index: 1 !important;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .el-progress {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-progress--circle .el-progress__text,
  .el-progress--dashboard .el-progress__text {
    font-size: 14px !important;
  }
}
</style>
