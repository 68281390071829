<template>
  <div class="online-main">
    <av-header :isHideUser="currentTabIndex > 0">
      <div class="btnBox" v-if="currentTabIndex===0">
        <uploadCase :finishHandle="uploadFinishHandle" v-show="isShowUploadBtn  && $store.state.caseValue.patientType != 1"></uploadCase>
        <div class="btn determineNomal" @click="caseAllocationFun" v-show="$store.state.labValue.accountType == 2 && isShowCaseAllocation && $store.state.isOpenDistribution">
          <img src="../assets/images/personalSettings/iconCaseAllocation.svg" alt="" />
          <span class="caseAllocationText">{{$t("case_overview.case_allot")}}</span>
        </div>
        <CaseAllocation :dialogVisible="dialogVisibleStatus" @childFun="parentFun($event)"></CaseAllocation>

        <div class="btn determineNomal" @click="caseTransferFun" v-show="$store.state.labValue.accountType == 2 && !!this.$store.state.coMechanism.mechanismName && isShowCaseTransfer">
          <img src="../assets/images/personalSettings/icon_transfer_normal.png" alt="" />
          <span class="caseAllocationText">{{$t('new_interaction.case_transfer')}}</span>
        </div>
        <CaseTransfer :dialogVisible="dialogVisibleTransfer" @childFun="transferFun($event)"></CaseTransfer>
      </div>
      <AutovisionHeaderSlot v-else :singleCount="singleCount" :arrangeCount="arrangeCount" :caseStatus="caseStatus" @dialogVisible="dialogClose" @statusChange="updateCaseStatus"></AutovisionHeaderSlot>
    </av-header>
    <div class="online-container">
      <div class="online-tabs online-analysissystem-tab">
        <div class="online-tab" :class="{current : currentTabIndex===0}" @click="currentTabIndex=0" v-show="accountType == '1' && !labInfo.isExpert"><span>{{$t('main.Cases_in_the_overview')}}</span></div>
        <div class="online-tab" :class="{current : currentTabIndex===0}" @click="currentTabIndex=0" v-show="accountType == '1' && labInfo.isExpert"><span>{{$t('new_interaction.consult_case')}}</span></div>

        <div class="online-tab" :class="{current : currentTabIndex===0}" @click="currentTabIndex=0" v-if="accountType == '2' && mechanismList.length === 1"><span>{{$t('new_interaction.case_all_check')}}</span></div>
        <div class="online-tab" :class="{current: currentTabIndex===0}" @click="currentTabIndex=0" v-if="accountType == '2' && mechanismList.length > 1">
          <el-select v-model="caseValue" :title="caseValue.mechanismName" value-key="mechanismId" @change="changeCaseVal" v-show="currentTabIndex===0">
            <el-option v-for="(item, index) in mechanismList" :key="index" :label="item.mechanismName" :value="item">
            </el-option>
          </el-select>
          <span v-show="currentTabIndex!==0">{{caseValue.mechanismName}}</span>
        </div>
        <div class="online-tab" :class="{current : currentTabIndex===1}" @click="currentTabIndex=1" v-if="patient && patient.patientId">
          <span>{{patient.patientName}}</span><i class="el-icon-close" @click.stop="closeTab"></i>
        </div>

        <div class="online-tabs-slot" v-if="currentTabIndex > 0">
          <metadata style="flex:1;" :checkedCitiesAll="checkedCitiesAll" :checkedCities1Val="checkedCities1Val" :checkedCities2Val="checkedCities2Val" :radioValue="radioValue" :karyotypeNumberlist='karyotypeNumberlist' :moreImgBtnShow="moreImgBtnShow" @updateBtnStatus="updateBtnStatus" v-if="checkedCitiesAll.length"></metadata>
          <div class="caseStatusBtn el-button" :class="{disabled: caseStatus==2}" style="width:unset; height:36px; margin:7px 10px 0 0; padding: 0 10px !important;" @click="updateCaseStatus(2)">{{caseStatus==2 ? $t('new_interaction.case_analysis_completely') : $t('new_interaction.complete_case_analysis')}}</div>
          <div class="addReport" v-if="checkedCitiesAll.length">
            <DiagnosisReport :patientId="patient.patientId"></DiagnosisReport>
          </div>
        </div>
      </div>
      <div class="online-content">
        <analysissystem v-show="currentTabIndex === 0" ref="analysissystem"></analysissystem>
        <autovision v-show="currentTabIndex > 0" v-if="patient && patient.patientId" @updateCounterArrange="updateCounterArrange" @updateAnalys="updateAnalys" :patientName="patient.patientName" :patientId="patient.patientId" :karyoID="patient.karyoId" :RANDOM_STRING_RECIVE="RANDOM_STRING" :patientInfo="patientInfo" ref="autovision"></autovision>
      </div>
    </div>
  </div>
</template>

<script>
import CaseAllocation from "./analysissystem/caseAllocation";
import CaseTransfer from "./analysissystem/caseTransfer";
import uploadCase from "./analysissystem/uploadCase.vue";
import AvHeader from "./common/header.vue";
import AutovisionHeaderSlot from "./common/autovisionHeaderSlot.vue";
import Autovision from "./autovision/tools.vue";
import analysissystem from "./analysissystem/analysissystem.vue";
import metadata from "./analysissystem/metadata";
import DiagnosisReport from "./analysissystem/diagnosisReport.vue";
import shortKey from "../assets/utils/shortKey";

import CAlgoModule from "../components/autovision/modules/tools/cAlgoModuleFun";
import { get } from 'lodash';
import Common from "../assets/js/common";
export default {
  components: {
    CaseAllocation,
    uploadCase,
    AvHeader,
    AutovisionHeaderSlot,
    Autovision,
    analysissystem,
    metadata,
    DiagnosisReport,
    CaseTransfer,
  },
  data() {
    return {
      pagePath: this.$route.path,
      checkedCitiesAll: [], //玻片列表
      radioValue: this.$store.state.status, //进度选中项目
      checkedCities1Val: JSON.parse(localStorage.getItem("checkedCities1")),
      checkedCities2Val: JSON.parse(localStorage.getItem("checkedCities2")),
      karyotypeNumberlist: {}, //玻片个数
      moreImgBtnShow: true,
      isShowCaseAllocation: false, //是否显示病例分配
      isShowCaseTransfer: false, //是否显示病例转交
      currentTabIndex: 0, //当前tab标签
      actionCloseConfirmHandle: () => { },
      onlineTabs: [
        {
          text: this.$t("main.Cases_in_the_overview"),
          alias: "analysissystem",
        },
      ],
      patient: {},
      actionClose: false, //是否要关闭工具页tab标签
      singleCount: 0,
      arrangeCount: 0,
      analysData: {},
      visible: false,
      randomStringMap: {},
      RANDOM_STRING: "",
      patientInfo: {},
      patientInfoMap: {},
      eventQueue: [],
      caseValue: "",
      userType: "0",
      mechanismList: [],
      // accountType: "1",
      labInfo: "",
      dialogVisibleStatus: false,
      dialogVisibleTransfer: false,
      caseStatus: '0',
      isShowUploadBtn: true,
    };
  },
  created() {
    console.warn(this.$route);
    if (this.$route.params.key) {
      this.beforeInvitation(this.$route.params.key);
    }
    if (!localStorage.getItem("loginObj") || localStorage.getItem("loginObj") == 'undefined') {
      window.location.href = "/";
      localStorage.clear();
      sessionStorage.clear();
    }
    this.userType = JSON.parse(localStorage.getItem("loginObj")).userType;
    const param = this.resolveQuery(this.$route.query);
    window.parent = this;
    //检查patient_randomString
    this.getRandomString();
    window.onbeforeunload = () => {
      this.setRandomString();
    };
    // this.isShowUploadBtn = this.userType === 0 || (this.userType & 8) === 8;

    let caseValue = localStorage.getItem('caseValue');
    if (!!caseValue && caseValue != 'undefined') {
      this.caseValue = JSON.parse(caseValue);
    } else {
      this.caseValue = this.$store.state.caseValue;
    }
    this.checkBtnVisible();
    this.queryAnalysis();
  },
  mounted() {
    this.$store._mutations.openPatient = this.openToolsPage;
    this.$store._mutations.updatePatientInfo = this.updatePatientInfo;
    this.$store._mutations.receiveCaseStatus = this.receiveCaseStatus;
    this.$store._mutations.closeTab = (patientId) => {
      if (this.patient.patientId === patientId) {
        this.patient = {};
        this.currentTabIndex = 0;
      }
    };
    this.$store._mutations.closeToolTab = this.closeTab;
    document.addEventListener("mousedown", () => {
      this.$refs.autovision && this.$refs.autovision.version++;
    });
    document.addEventListener("keydown", () => {
      this.$refs.autovision && this.$refs.autovision.version++;
    });
  },
  methods: {
    queryAnalysis() {
      this.$api.queryAnalysis({}).then(res => {
        if (res.code == 200) {
          this.$store.commit('analysisInfoStore', res.data);
        }
      })
    },
    checkBtnVisible() {
      this.isShowUploadBtn = Common.permissions("/patient/uploadLocalPatient");
      this.isShowCaseAllocation = Common.permissions("/patient/pListLabor");
      this.isShowCaseTransfer = Common.permissions("/patient/pListLabor");
    },
    updateCaseStatus(status = 2) {
      if (this.caseStatus == 2) { return }
      this.$confirm(this.$t('new_interaction.isComplete_all_work'), this.$t('popup.warning.remind'), {
        type: "warning",
        distinguishCancelAndClose: true,
        customClass: "save-confirm-box",
        closeOnClickModal: false
      }).then(res => {
        this.$api.updateStatus({
          patientId: this.patient.patientId,
          status
        }).then(res => {
          if (res.code != 200) {
            this.$message({
              type: 'error',
              message: res.message
            })
          } else {
            this.caseStatus = String(status);
            this.$store._mutations.syncCaseStatus(this.patient.patientId, status)
            this.$message.success(this.$t('new_interaction.operate_success'))
          }
        })
      }).catch(e => {

      })

    },
    /**
     * 接收病例列表中的同步状态
     */
    receiveCaseStatus(patientId, status) {
      if (this.patient.patientId == patientId) {
        this.caseStatus = String(status);
      }
    },
    updateBtnStatus(bool) {
      this.moreImgBtnShow = bool;
    },
    caseAllocationFun() {
      this.dialogVisibleStatus = true;
    },
    caseTransferFun() {
      if (!!this.$store.state.coMechanism.mechanismName) {
        this.dialogVisibleTransfer = true;
      } else {
        this.$message.warning(this.$t('new_interaction.cannot_transfer'))
      }
    },
    parentFun(payload) {
      this.dialogVisibleStatus = payload;
    },
    transferFun(payload) {
      this.dialogVisibleTransfer = payload;
    },
    // 将转入机构与自身机构合并
    async handleInOrgList() {
      await this.getTransInOrgList();
      let labValue = JSON.parse(localStorage.getItem('currentLabValue'))
      this.labInfo = labValue;
      let caseValue = localStorage.getItem('caseValue');
      if (!this.$store.state.caseValue && !!caseValue && caseValue != 'undefined') {
        this.caseValue = JSON.parse(caseValue);
      } else {
        if (labValue.accountType == "2") {
          this.caseValue = this.mechanismList[0];
        } else if (labValue.accountType == "1") {
          this.caseValue = {
            mechanismId: labValue.mechanismId,
            mechanismName: this.$t('uploadPdf.personal_cases'),
            patientType: "0", //病例类型 0 个人病例、1 会诊病例 2 团队病例 3 转入病例
            accountType: "1",
          };
          if (labValue.isExpert) {
            this.caseValue = {
              mechanismId: labValue.mechanismId,
              mechanismName: this.$t('new_interaction.consult_case'),
              patientType: "1",
              accountType: "1",
            };
          }
        }
      }
      this.$store.commit("changeCaseValue", this.caseValue);
      localStorage.setItem('caseValue', JSON.stringify(this.caseValue))
    },
    // 获取转入机构信息
    async getTransInOrgList() {
      return this.$api.getTransInOrgList({}).then((res) => {
        if (res.code == 200) {
          let labValue = JSON.parse(localStorage.getItem('currentLabValue'))
          this.mechanismList = [{
            mechanismId: labValue.mechanismId,
            // mechanismName: labValue.mechanismName,
            mechanismName: this.$t('new_interaction.case_all_check'),
            patientType: "2", //病例类型 0 个人病例、1 会诊病例 2 团队病例 3 转入病例
            accountType: "2", // 1个人，专家   2机构  3管理员
          }];
          res.data.mechanismList.forEach((item) => {
            item.patientType = "3";
            item.accountType = "2";
            item.mechanismName = item.mechanismName + this.$t('new_interaction.cases');
          });
          let mechanismList = res.data.mechanismList;
          !!mechanismList ? this.mechanismList = this.mechanismList.concat(mechanismList) : "";
          let coMechanism = {
            mechanismId: get(res.data.coMechanism, "mechanismId"),
            mechanismName: get(res.data.coMechanism, "mechanismName")
          }
          this.$store.commit("changeMechanismList", this.mechanismList);
          this.$store.commit("coMechanismSave", coMechanism);
          this.$store.commit("isAcceptMechanismSave", res.data.isAcceptMechanism);
          localStorage.setItem('isAcceptMechanismSave', res.data.isAcceptMechanism)
          this.$store.commit("openDistribution", res.data.isOpenDistribution);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * 页面关闭前 保存随机串
     */
    setRandomString() {
      if (!this.patient.patientId) {
        return;
      }
      const item = this.randomStringMap[this.patient.patientId];

      if (!item) {
        this.randomStringMap[this.patient.patientId] = {
          value: this.$refs.autovision.RANDOM_STRING,
          time: +new Date(),
        };
      } else if (+new Date() - item.time > 1000 * 300) {
        delete this.randomStringMap[this.patient.patientId];
      } else {
        this.randomStringMap[this.patient.patientId] = {
          value: this.$refs.autovision.RANDOM_STRING,
          time: +new Date(),
        };
      }
      localStorage.setItem("patientRandomStringMap", JSON.stringify(this.randomStringMap));
    },
    getRandomString() {
      this.RANDOM_STRING = "";
      const patient_randomString = localStorage.getItem(
        "patientRandomStringMap"
      );
      if (patient_randomString) {
        this.randomStringMap = JSON.parse(patient_randomString);
        const item = this.randomStringMap[this.patient.patientId];
        if (!item) {
          return;
        }
        const timeout = +new Date() - item.time >= 1000 * 300;
        console.log(item, timeout);
        if (!timeout) {
          this.RANDOM_STRING = item.value;
        } else {
          delete this.randomStringMap[this.patient.patientId];
          localStorage.setItem(
            "patientRandomStringMap",
            JSON.stringify(this.randomStringMap)
          );
        }
      }
    },
    /**
     * 解析路由hash信息
     */
    resolveQuery(query) {
      const hash = query.t;
      if (!hash) {
        return [];
      }
      const param = decodeURIComponent(window.atob(hash));
      return param.split(",");
    },
    /**
     * 上传完成回调
     */
    uploadFinishHandle(type, status) {
      this.$refs.analysissystem.uploadFinishHandle(type, status);
    },
    /**
     * 更新筛选条件与统计信息
     */
    updateAnalys(data) {
      this.checkedCitiesAll = data.glassList;
      this.karyotypeNumberlist = data.statisInfo;
    },
    /**
     * 更新计数与排列统计
     */
    updateCounterArrange(counter, arrange) {
      this.singleCount = counter;
      this.arrangeCount = arrange;
    },
    /**
     * 显示基础设置
     */
    dialogClose(bool) {
      this.visible = bool;
    },

    replaceRouter() {
      return;
      const str = window.btoa(
        [
          encodeURIComponent(this.patient.patientName),
          this.patient.patientId,
          this.patient.karyoId,
        ].join(",")
      );
      this.$router.replace("?t=" + str);
    },

    /**
     * steps
     * 1.调用 openToolsPage 判断当前是否有正在打开中的病例，如果有则先定位到对应tab标签：
     * 2.判断当前已打开的病例与需要打开的病例是否相同
     * 		a.如果相同: 判断当前核型图ID与需要打开的核型图是否相同
     * 			1).如果相同，则不做任何操作
     * 			2).如果不同，则
     * 				.判断当前核型图操作是否需要保存
     * 				.切换到对应核型图位置
     * 		b.如果不同：
     * 			.判断当前核型图操作是否需要保存
     * 			.重新打开tab页 或者 重新加载对应的病例与核型图数据。
     * 3.如果当前没有正在操作的病例，则打开对应的病例，且切换tab
     */

    /**
     * 打开分析工具页
     * 判断是否有已打开的tab页
     */
    async openToolsPage(patientId, karyoId, patientName) {
      // if(!typeof window.Module.getVectorJSchromo !== "function"){
      if (!window.CVLoaded || !window.ModuleLoaded) {
        this.$message({
          type: "error",
          message: this.$t("main.message_graphics"),
        });
        let loadCVAndAlgo = new CAlgoModule();
        return;
      }

      //如果当前并没有打开任何病例
      if (this.patient && !this.patient.patientId) {
        // this.addTab(patientName,patientId);
        this.currentTabIndex = 1;
        this.radioValue = this.$store.state.status;
        this.checkedCities1Val = JSON.parse(
          localStorage.getItem("checkedCities1")
        );
        this.checkedCities2Val = JSON.parse(
          localStorage.getItem("checkedCities2")
        );
        this.patient = {
          patientName,
          patientId,
          karyoId,
        };
        this.getRandomString();
        this.replaceRouter();
        this.patientInfo = this.patientInfoMap[patientId] || {};
      }
      //如果当前有打开中的病例 则判断打开中的病例与要打开的病例是否相同
      if (this.patient && this.patient.patientId) {
        //如果是相同病例且相同核型图
        if (
          this.patient.patientId === patientId &&
          this.patient.karyoId === karyoId
        ) {
          this.setMetaData();
        }
        //如果是相同病例且不同核型图
        if (
          this.patient.patientId === patientId &&
          this.patient.karyoId !== karyoId
        ) {
          this.setMetaData();
          this.$refs.autovision.prevCloseHandle(() => {
            this.patient.karyoId = karyoId;
            this.$refs.autovision.switchKaryoCallback(karyoId, () => {
              this.$refs.autovision.refreshKaryoList();
            });
          }, true);

          // this.prevCloseTab(() => {
          //     this.patient.karyoId = karyoId;
          //     this.currentTabIndex = 1;
          //     this.actionCloseConfirmHandle = this.defaultCloseHandle;
          //     this.$refs.autovision.switchKaryoHandle(karyoId);
          // });
          // this.replaceRouter();
          // this.patientInfo = this.patientInfoMap[patientId]
        }
        //如果不是相同病例
        if (this.patient.patientId !== patientId) {
          this.setMetaData();
          this.$refs.autovision.prevCloseHandle(() => {
            this.patientInfo = this.patientInfoMap[patientId] || {};
            this.patient = {};
            this.$nextTick(() => {
              this.patient = {
                patientName,
                patientId,
                karyoId,
              };
              this.getRandomString();
              this.actionCloseConfirmHandle = this.defaultCloseHandle;
            });
          }, true);
        }
      }
    },
    setMetaData() {
      this.currentTabIndex = 1;
      this.radioValue = this.$store.state.status;
      this.checkedCitiesAll = this.$store.state.glassList;
      this.checkedCities1Val = JSON.parse(
        localStorage.getItem("checkedCities1")
      );
      this.checkedCities2Val = JSON.parse(
        localStorage.getItem("checkedCities2")
      );
    },
    /**
     * 关闭tab操作之后的默认行为
     */
    defaultCloseHandle(cb) {
      window.loadingInstance && window.loadingInstance.close();
      this.patient = {};
      this.currentTabIndex = 0;
      console.log("defaultCloseHandle", cb);
      this.$nextTick(() => {
        console.log("$nextTick");
        typeof cb === "function" && cb();
        this.checkedCitiesAll = [];
        this.karyotypeNumberlist = {};
      });
    },
    /**
     * 页面关闭之间
     */
    prevCloseTab(callback) {
      this.actionCloseConfirmHandle =
        typeof callback === "function" ? callback() : this.defaultCloseHandle;
      //调用工具页的方法
      this.$refs.autovision.prevCloseHandle();
    },
    closeTab() {
      if (!this.$refs.autovision || !this.$refs.autovision.isPageInit) {
        this.defaultCloseHandle();
      } else {
        try {
          this.setRandomString();
          this.prevCloseTab();
        } catch (error) {
          this.defaultCloseHandle();
        }
      }
    },
    updatePatientInfo(patientId, info) {
      const data = Object.assign({}, info);
      if (info.receiveDate) {
        data.receiveDate = new Date(info.receiveDate).toLocaleDateString();
      }
      this.patientInfoMap[patientId] = data;
      if (patientId === this.patient.patientId) {
        this.patientInfo = data || {};
      }
    },

    changeCaseVal(val) {
      this.closeTab();
      this.$store.commit("changeCaseValue", val);
      localStorage.setItem('caseValue', JSON.stringify(val))
    },

    queryKaryoNum() {
      this.$api.queryKaryoNum().then(res => {
        if (res.code == 200) {
          Object.assign(this.$store.state, res.data);
        }
      })
    },

    beforeInvitation(key) {
      this.$api.queryMechanismName({
        key: key
      }).then(res => {
        if (res.code == 200) {
          this.invitationConfirm(res.data);
        } else {
          this.$message({
            type: 'error',
            message: res.message
          })
          // this.afterInvitation();
        }
      })
    },
    invitationConfirm(name) {
      const title = this.$t("login.invitation") + name;
      this.$confirm(title, '', {
        confirmButtonText: this.$t("btns.confirm"),
        cancelButtonText: this.$t("btns.cancel"),
        closeOnClickModal: false
      }).then(() => {
        this.invitation(name);
      }).catch(() => {
        // this.jumpAfterLogin();
      })
    },
    //绑定邀请
    invitation(name) {
      this.$api.invitation({
        key: this.$route.params.key
      }).then(res => {
        if (res.code == 200) {
          this.getQueryUserByToken(name);
        }
        this.$message({
          type: res.code == 200 ? 'success' : 'error',
          message: res.message
        })
      })
    },
    getQueryUserByToken(name) {
      return this.$api.getQueryUserByToken().then(res => {
        if (res.code == 200 && res.data.userMechanismList) {
          const loginObj = JSON.parse(localStorage.getItem("loginObj"));
          loginObj.mechanismList = res.data.userMechanismList;
          localStorage.setItem("loginObj", JSON.stringify(loginObj))
          const item = loginObj.mechanismList.find(a => a.mechanismName === name);
          if (name && item) {
            const data = Object.assign({
              accountType: "2",
              patientType: "2"
            }, item);
            localStorage.setItem("caseValue", JSON.stringify(data))
            localStorage.setItem("currentLabValue", JSON.stringify(Object.assign({
              accountType: "2",
              choosed: false,
              isExpert: false
            }, item)))
            console.log(localStorage.caseValue);
          }
          this.afterInvitation();
        }
      })
    },
    afterInvitation(key) {
      this.$api.queryList({
        type: 1,
      }).then((res) => {
        if (res.code == 200) {
          let pointList = res.data.pointList;
          let powerMap = res.data.powerMap;
          localStorage.setItem("pointList", JSON.stringify(pointList));
          localStorage.setItem("powerMap", JSON.stringify(powerMap));
          this.$store.commit("updateLabValue")
        }
      });
    },

  },
  computed: {
    accountType() {
      return this.$store.state.labValue.accountType;
    },
  },
  watch: {
    onlineTabs(nv, ov) {
      console.log(nv, ov);
    },
    patient(nv, ov) {
      if (!nv.patientId) {
        this.singleCount = 0;
        this.arrangeCount = 0;
      } else {
        const status = this.$store._mutations.getStatusByCaseId(nv.patientId);
        this.caseStatus = String(status);
      }
      this.eventQueue = [];
    },
    currentTabIndex(nv) {
      shortKey.disabled = nv === 0;
      this.$store.commit("changeCurrentTabIndex", nv);
      this.$store.state.currentTabIndex = nv;
      if (
        nv === 1 &&
        this.$refs.autovision &&
        this.$refs.autovision.arrangeController &&
        this.$refs.autovision.arrangeController.initFinished
      ) {
        setTimeout(() => {
          this.$refs.autovision.$refs.allTheKaryos.baseDataChange(
            this.$refs.autovision.chromoList
          );
        }, 50);
      }
      this.eventQueue = this.eventQueue.filter((item) => {
        if (item.tabindex === nv) {
          item.handle();
        } else {
          return true;
        }
      });
    },
    "$store.state.labValue": async function (val) {
      if (this.$route.path === "/personalSettings") return;
      this.closeTab();
      // await this.updatePowerMap();
      this.queryKaryoNum();
      this.handleInOrgList();
      this.checkBtnVisible();
    },
    "$store.state.language": function () {
      this.getTransInOrgList();
    }
  },
};
</script>

<style lang="scss" scoped>
.online-main {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.online-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.btnBox {
  display: flex;
  flex: 1;
}
.online-analysissystem-tab {
  height: 50px;
  overflow: hidden;
  display: flex;
  background-color: #e4e5e6;

  .online-tab {
    width: 200px;
    height: 34px;
    line-height: 34px;
    background: linear-gradient(180deg, #ffffff 0%, #ebebeb 100%);
    border-radius: 6px 6px 0px 0px;
    font-size: 16px;
    color: #999999;
    font-weight: 400;
    box-sizing: border-box;
    /*text-align: center;*/
    // padding: 0 12px;
    display: flex;
    // text-align: center;
    margin-right: 1px;
    margin-top: 16px;
    justify-content: center;
    cursor: pointer;
    transition: all ease 0.2s;
    span,
    i {
      display: block;
    }
    span {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
    i {
      width: 20px;
      height: 20px;
      position: relative;
      top: 50%;
      right: 6px;
      transform: translateY(-50%) scale(0.8);
      cursor: pointer;
    }
    &.current {
      background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
      color: #ffffff;
      height: 38px;
      line-height: 38px;
      margin-top: 12px;
    }
  }
  .online-tabs-slot {
    flex: 1;
    display: flex;
    /* padding-top: 8px;*/
  }
  .addReport {
    width: 120px;
    height: 36px;
    // line-height: 36px;
    text-align: center;
    background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
    border-radius: 4px;
    color: #f5f5f5;
    font-size: 16px;
    cursor: pointer;
    margin-top: 7px;
    margin-right: 15px;
    &:hover {
      background: rgb(45, 134, 239);
    }
    &:active {
      background: rgb(38, 103, 181);
    }
  }
}
.online-content {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  // max-height: 857px;
}
</style>
<style lang="scss">
.online-main {
  .online-tab {
    .el-input--suffix .el-input__inner {
      padding-right: 0;
      background: none;
      color: #fff;
      border: 0;
      font-size: 16px;
      height: 38px;
      line-height: 39px;
      // width: calc(100% - 10px);
    }
    .el-select {
      width: 100%;
    }
    .el-input__suffix {
      right: 15px !important;
    }
    .el-input {
      height: 38px;
      line-height: 38px;
    }
    .el-input__icon {
      width: 16px;
      display: flex;
      align-items: center;
    }
    .el-input--suffix .el-input__inner {
      padding: 0 26px 0 15px !important;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .el-select .el-input .el-select__caret {
      // display: flex;
      // align-items: center;
    }
    .el-icon-arrow-up:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      background: url("../assets/images/autovision/icon_bottom.png");
    }
  }
  .determineNomal {
    width: auto;
    min-width: 82px;
    height: 34px;
    padding: 0 6px;
    margin-right: 10px;
    line-height: 16px;
    .caseAllocationText {
      margin-left: 8px;
    }
  }
}
.caseStatusBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 36px;
  line-height: 36px;
  background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
  color: #ffffff;
  border: none;
  &:hover {
    color: #ffffff;
  }
  &.disabled {
    background: #a0cfff !important;
    cursor: not-allowed;
  }
}
</style>