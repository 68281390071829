<template>
	<div class="diagens-switch" :class="{on:!!value, off:!value, disabled: disabled}" @click="active">
		<span class="active-text">{{activeText}}</span>
		<span class="inactive-text">{{inactiveText}}</span>
		<div class="diagens-switch-btn"></div>
	</div>
</template>

<script>
export default {
	name: 'dsSwitch',
	props:{
		value: {
			type: [Boolean,Number],
			default: false
		},
		activeText: {
			type: String,
			default: 'ON',
		},
		inactiveText: {
			type: String,
			default: 'OFF',
		},
		disabled: Boolean
	},
	data(){
		return {
			
		}
	},
	created() {
		console.warn(this.disabled)
	},
	methods:{
		active(){
			if(!this.disabled){
				// this.value = !this.value;
				this.$emit("change",!this.value)
			}
		}
	},
	watch:{
		
	}
}
</script>

<style scoped>
.diagens-switch{
	height: 20px;
	overflow: hidden;
	position: relative;
	display: inline-block;
	border-radius: 10px;
	cursor: pointer;
}
.diagens-switch.disabled{
	cursor: not-allowed;
}
.diagens-switch.disabled:after{
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(255,255,255,.3);
	top: 0;
	left: 0;
}
.diagens-switch.on .active-text{
	display: block;
}
.diagens-switch.off .inactive-text{
	display: block;
}
.diagens-switch .active-text,
.diagens-switch .inactive-text{
	overflow: hidden;
	height: 100%;
	line-height: 20px;
	border-radius: 10px;
	font-size: 12px;
	font-weight: 400;
	display: none;
	user-select: none;
}
.diagens-switch .active-text{
	color: #FFFFFF;
	padding: 0 22px 0 10px;
	background-color: #4773B8;
}
.diagens-switch .inactive-text{
	color: #666666;
	padding: 0 10px 0 22px;
	background-color: #D1D1D6;
}
.diagens-switch .diagens-switch-btn{
	width: 18px;
	height: 18px;
	background: #FFFFFF;
	border-radius: 9px;
	position: absolute;
	top: 1px;
	left: 19px;
	transform: translateX(-100%);
	transition: all ease .2s;
}
.diagens-switch.on .diagens-switch-btn{
	left: 100%;
	margin-left: -1px;
}
/* .diagens-switch .diagens-switch-btn{
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	overflow: hidden;
	display: flex;
	box-sizing: border-box;
	padding: 0 1px;
	transition: all ease .2s;
}
.diagens-switch.on .diagens-switch-btn{
	justify-content: flex-end;
}
.diagens-switch.off .diagens-switch-btn{
	justify-content: flex-start;
}
.diagens-switch .diagens-switch-btn:after{
	content: '';
	display: block;
	width: 18px;
	height: 18px;
	background: #FFFFFF;
	border-radius: 9px;
	margin-top: 1px;
} */

</style>
