<template>
  <div class="expertCertificationBox">
    <a class="back-btn" @click="goback()"> 〈 {{$t('accountInfo.back')}}</a>
    <input type="file" id="selectfiles" style="display:none" />
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" @submit.native.prevent>
      <el-form-item :label="$t('expert_approve.name')" prop="realName">
        <el-input v-model="ruleForm.realName" :placeholder="$t('expert_approve.input_name')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('expert_approve.the_unit')">
        <el-input v-model="ruleForm.hospital" :placeholder="$t('organize_approve.input_organName')"></el-input>
        <div class="tipsText">
          {{$t('organize_approve.organName_demand')}}
        </div>
      </el-form-item>
      <el-form-item :label="$t('expert_approve.the_office')">
        <el-input v-model="ruleForm.departmentName" :placeholder="$t('expert_approve.input_office_name')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('expert_approve.office_tel')">
        <el-input v-model="ruleForm.departmentPhone" :placeholder="$t('expert_approve.input_office_tel')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('expert_approve.work_card')">
        <div class="uploadImg" @click="clickUpload('breastplate')">
          <img v-if="ruleForm.breastplate && !isPercentageBre" :src="ruleForm.breastplate" class="avatar" />
          <img src="../../../assets/images/personalSettings/iconUploading.svg" alt="" v-if="!ruleForm.breastplate && !isPercentageBre && $store.state.language == 'zh'" />
          <img src="../../../assets/images/personalSettings/iconUploadingEn.png" alt="" v-if="!ruleForm.breastplate && !isPercentageBre && $store.state.language == 'en'" />
          <input type="file" id="breastplate" @change="uploadImg($event, 'breastplate')" style="display:none" />
          <el-progress v-if="isPercentageBre" type="circle" :percentage="percentageBre"></el-progress>
        </div>
        <div class="tipsText">
          {{$t('expert_approve.card_demand')}}
        </div>
      </el-form-item>
      <el-form-item :label="$t('expert_approve.medical_practice_certificate')">
        <div class="uploadImg" @click="clickUpload('practiceCertificate')">
          <img v-if="ruleForm.practiceCertificate && !isPercentagePra" :src="ruleForm.practiceCertificate" class="avatar" />
          <img src="../../../assets/images/personalSettings/iconUploading.svg" alt="" v-if="!ruleForm.practiceCertificate && !isPercentagePra && $store.state.language == 'zh'" />
          <img src="../../../assets/images/personalSettings/iconUploadingEn.png" alt="" v-if="!ruleForm.practiceCertificate && !isPercentagePra && $store.state.language == 'en'" />
          <input type="file" id="practiceCertificate" @change="uploadImg($event,'practiceCertificate')" style="display:none" />
          <el-progress v-if="isPercentagePra" type="circle" :percentage="percentagePra"></el-progress>
        </div>
        <div class="tipsText">
          {{$t('expert_approve.photo_demand')}}
        </div>
      </el-form-item>
      <el-form-item :label="$t('expert_approve.license_code')">
        <el-input v-model="ruleForm.licenseCode" :placeholder="$t('expert_approve.input_license_code')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('expert_approve.doc_credentials')">
        <div class="uploadImg" @click="clickUpload('qualification')">
          <img v-if="ruleForm.qualification && !isPercentageQua" :src="ruleForm.qualification" class="avatar" />
          <img src="../../../assets/images/personalSettings/iconUploading.svg" alt="" v-if="!ruleForm.qualification && !isPercentageQua && $store.state.language == 'zh'" />
          <img src="../../../assets/images/personalSettings/iconUploadingEn.png" alt="" v-if="!ruleForm.qualification && !isPercentageQua && $store.state.language == 'en'" />
          <input type="file" id="qualification" @change="uploadImg($event,'qualification')" style="display:none" />
          <el-progress v-if="isPercentageQua" type="circle" :percentage="percentageQua"></el-progress>
        </div>
        <div class="tipsText">
          {{$t('expert_approve.person_photo_demand')}}
        </div>
      </el-form-item>
      <el-form-item :label="$t('expert_approve.certification_code')">
        <el-input v-model="ruleForm.qualificationCode" :placeholder="$t('expert_approve.input_license_code')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('expert_approve.front_back_IDcard')" class="idCardBox">
        <div class="uploadImg" @click="clickUpload('idCardCopy1')">
          <img v-if="ruleForm.idCardCopy1 && !isPercentageIdc1" :src="ruleForm.idCardCopy1" class="avatar" />
          <img src="../../../assets/images/personalSettings/iconUploading.svg" alt="" v-if="!ruleForm.idCardCopy1 && !isPercentageIdc1 && $store.state.language == 'zh'" />
          <img src="../../../assets/images/personalSettings/iconUploadingEn.png" alt="" v-if="!ruleForm.idCardCopy1 && !isPercentageIdc1 && $store.state.language == 'en'" />
          <input type="file" id="idCardCopy1" @change="uploadImg($event, 'idCardCopy1')" style="display:none" />
          <el-progress v-if="isPercentageIdc1" type="circle" :percentage="percentageIdc1"></el-progress>
        </div>
        <div class="uploadImg" @click="clickUpload('idCardCopy2')" v-if="ruleForm.idCardCopy1">
          <img v-if="ruleForm.idCardCopy2 && !isPercentageIdc2" :src="ruleForm.idCardCopy2" class="avatar" />
          <img src="../../../assets/images/personalSettings/iconUploading.svg" alt="" v-if="!ruleForm.idCardCopy2 && !isPercentageIdc2 && $store.state.language == 'zh'" />
          <img src="../../../assets/images/personalSettings/iconUploadingEn.png" alt="" v-if="!ruleForm.idCardCopy2 && !isPercentageIdc2 && $store.state.language == 'en'" />
          <input type="file" id="idCardCopy2" @change="uploadImg($event, 'idCardCopy2')" style="display:none" />
          <el-progress v-if="isPercentageIdc2" type="circle" :percentage="percentageIdc2"></el-progress>
        </div>

      </el-form-item>
      <el-form-item :label="$t('expert_approve.IDcode')">
        <el-input v-model="ruleForm.idCardNumber" :placeholder="$t('expert_approve.input_IDcode')"></el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="ruleForm.checked"></el-checkbox>
        <span class="securityProtocol">
          {{$t('checkbox_button.agreeto_observe')}}
          <router-link :to="{ path: '/statement' }" target="_blank">
            {{$t('checkbox_button.privacy_statement')}}
          </router-link>
          {{$t('checkbox_button.and')}}
          <router-link :to="{ path: '/agreement' }" target="_blank">
            {{$t('checkbox_button.service_agreement')}}
          </router-link>
        </span>
      </el-form-item>
      <el-form-item>
        <div class="btn determineNomal" @click="submitForm('ruleForm')">
          {{$t('btns.submit')}}
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { reg } from "../../../assets/js/reg.js";
import uploader from "../../../assets/utils/fileUploader.js";
import $ from "jquery";
import { cloneDeep } from "lodash";
export default {
  name: "ExpertCertification",
  props: ["userId", "detailInfo"],
  data() {
    return {
      id: "",
      breastplateURl: "",
      practiceCertificateURl: "",
      qualificationURl: "",
      idCardURl: "",
      idCardCopyURl: "",
      idCardList: [],
      ruleForm: {
        realName: "", // 姓名
        hospital: "", // 单位
        departmentName: "", // 所在科室/部门
        departmentPhone: "", // 科室/部门电话
        breastplate: "", // 工作胸牌
        practiceCertificate: "", // 医师执业证书
        licenseCode: "", // 执业证编码
        qualification: "", // 医师资格证书
        qualificationCode: "", // 资格证编码
        idCard: "", // 身份证
        idCardNumber: "", // 身份证号码
        checked: false, // 勾选协议
      },
      rules: {
        realName: [
          {
            required: true,
            message: this.$t("my_identity.input_name"),
            trigger: "blur",
          },
          {
            min: 2,
            max: 30,
            message: this.$t("organize_approve.organName_length_demand"),
            trigger: "blur",
          },
        ],
      },
      isPercentageBre: false,
      percentageBre: 0,
      isPercentagePra: false,
      percentagePra: 0,
      isPercentageQua: false,
      percentageQua: 0,
      isPercentageIdc1: false,
      percentageIdc1: 0,
      isPercentageIdc2: false,
      percentageIdc2: 0,
    };
  },
  created() { },
  methods: {
    goback() {
      this.$emit("childFun", false);
    },
    getQueryUserByToken() {
      this.$api.getQueryUserByToken({}).then((res) => {
        if (res.code == 200) {
          const { mechanism, user, expert } = res.data;
          this.$store.commit("changeQueryUserInfo", res.data);
          this.$emit("submitFun", true);
        } else {
          // this.$router.push({
          //   path: "/",
          // });
          this.$message.error(res.message);
        }
      });
    },
    // 点击提交
    submitForm(formName) {
      if (this.isPercentageBre || this.isPercentagePra || this.isPercentageQua || this.isPercentageIdc1 || this.isPercentageIdc2) {
        this.$message.error(this.$t('accountInfo.file_uploading'));
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.checked) {
            let ruleForm = cloneDeep(this.ruleForm);
            ruleForm.type = 1;
            ruleForm.breastplate = !!ruleForm.breastplate ? ruleForm.breastplate.split("?")[0] : "";
            ruleForm.practiceCertificate = !!ruleForm.practiceCertificate ? ruleForm.practiceCertificate.split("?")[0] : "";
            ruleForm.qualification = !!ruleForm.qualification ? ruleForm.qualification.split("?")[0] : "";
            let idCard = [!!ruleForm.idCardCopy1 ? ruleForm.idCardCopy1.split("?")[0] : "", !!ruleForm.idCardCopy2 ? ruleForm.idCardCopy2.split("?")[0] : ""]
            ruleForm.idCard = idCard.toString();
            ruleForm.idCardCopy1 = null;
            ruleForm.idCardCopy2 = null;
            this.$api.userAuth(ruleForm).then((res) => {
              if (res.code == 200) {
                this.$emit("childFun", false);
                this.getQueryUserByToken();
                this.$message.success(res.message);
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            this.$message.error(this.$t("organize_approve.check_deal"));
          }
        } else {
          return false;
        }
      });
    },
    uploadImg(e, urlName) {
      let _file = e.target.files[0];
      _file.israndom = true;
      if (!_file) return;
      let name = _file.name.split(".");
      !!name.length && name.pop();
      if (!reg.specialImgCharacters.test(name)) {
        this.$message.error(this.$t('accountInfo.imgName_cannot_include_special'));
        return;
      }
      const isLt10M = _file.size / 1024 / 1024 < 10;
      let formats = ["bmp", "png", "jpg", "jpe", "jpeg"];
      let format = _file.name.split(".").pop().toLowerCase();
      if (isLt10M && formats.indexOf(format) > -1) {
        let setPercentage;
        switch (urlName) {
          case "breastplate":
            this.isPercentageBre = true;
            this.percentageBre = 0;
            setPercentage = setInterval(() => {
              this.percentageBre += 5;
              if (this.percentageBre >= 99) {
                this.percentageBre = 99;
              }
            }, 200);
            break;
          case "practiceCertificate":
            this.isPercentagePra = true;
            this.percentagePra = 0;
            setPercentage = setInterval(() => {
              this.percentagePra += 5;
              if (this.percentagePra >= 99) {
                this.percentagePra = 99;
              }
            }, 200);
            break;
          case "qualification":
            this.isPercentageQua = true;
            this.percentageQua = 0;
            setPercentage = setInterval(() => {
              this.percentageQua += 5;
              if (this.percentageQua >= 99) {
                this.percentageQua = 99;
              }
            }, 200);
            break;
          case "idCardCopy1":
            this.isPercentageIdc1 = true;
            this.percentageIdc1 = 0;
            setPercentage = setInterval(() => {
              this.percentageIdc1 += 5;
              if (this.percentageIdc1 >= 99) {
                this.percentageIdc1 = 99;
              }
            }, 200);
            break;
          case "idCardCopy2":
            this.isPercentageIdc2 = true;
            this.percentageIdc2 = 0;
            setPercentage = setInterval(() => {
              this.percentageIdc2 += 5;
              if (this.percentageIdc2 >= 99) {
                this.percentageIdc2 = 99;
              }
            }, 200);
            break;
        }
        uploader({
          files: [_file],
          path: "user/certificate/",
          processHandle: (file) => { },
          ossUploadAccess: this.$store.state.ossUploadAccess,
        }).then((filelist) => {
          const imgUrl = filelist[0].path;
          if (urlName === "breastplate") {
            this.ruleForm.breastplate = this.ossUrlWebFir(imgUrl, 1, 130, 130);
            this.isPercentageBre = false;
          } else if (urlName === "practiceCertificate") {
            this.ruleForm.practiceCertificate = this.ossUrlWebFir(imgUrl, 1, 130, 130);
            this.isPercentagePra = false;
          } else if (urlName === "qualification") {
            this.ruleForm.qualification = this.ossUrlWebFir(imgUrl, 1, 130, 130);
            this.isPercentageQua = false;
          } else if (urlName === "idCardCopy1") {
            this.ruleForm.idCardCopy1 = this.ossUrlWebFir(imgUrl, 1, 130, 130);
            this.isPercentageIdc1 = false;
          } else if (urlName === "idCardCopy2") {
            this.ruleForm.idCardCopy2 = this.ossUrlWebFir(imgUrl, 1, 130, 130);
            this.isPercentageIdc2 = false;
          }
          clearInterval(setPercentage);
          setPercentage = null;
        });
      } else {
        this.$message.error(this.$t("selfDefined_template.image_size_demand"));
      }
    },
    // 点击上传
    clickUpload(id) {
      $('#' + id).trigger("click");
    },
  },
  watch: {
    detailInfo(val) {
      if (val) {
        let newVal = cloneDeep(val);
        if (!(newVal.idCard instanceof Array)) {
          newVal.idCard = !!newVal.idCard ? newVal.idCard.split(",") : [];
        }
        this.ruleForm = newVal;
        this.ruleForm.idCardCopy1 = newVal.idCard[0];
        this.ruleForm.idCardCopy2 = newVal.idCard[1];


        // this.ruleForm.mechanismType = !!val.mechanismType
        //   ? val.mechanismType.toString()
        //   : "";
        // this.ruleForm.mechanismCode = !!val.mechanismCod
        //   ? val.mechanismCode.toString()
        //   : "";
        // this.ruleForm.mechanismCodeType = !!val.mechanismCodeType
        //   ? val.mechanismCodeType.toString()
        //   : "";
        // this.ruleForm.checkedArea = [val.province, val.city, val.area];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.expertCertificationBox {
  .demo-ruleForm {
    margin-top: 20px;
  }
  .idCardBox {
    display: flex;
  }

  .uploadImg {
    cursor: pointer;
    width: 58px;
    height: 58px;
    background: #fbfbfb;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .tipsText {
    margin-top: 8px;
    height: 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    display: flex;
    align-items: center;
    line-height: 14px;
    width: 94%;
  }

  .securityProtocol {
    a {
      text-decoration: none;
      color: #4773b8;
    }
  }

  .determineNomal {
    width: 130px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
<style lang="scss">
.expertCertificationBox {
  .el-textarea__inner {
    font-family: Microsoft YaHei;
  }
  .el-input__inner {
    width: 480px;
    height: 40px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .el-form-item__error {
    padding-top: 0;
  }
  .el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 19px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .avatar {
    width: 58px;
    height: 58px;
    display: block;
  }

  .idCardBox {
    .el-form-item__content {
      margin: 0 !important;
      display: flex;
      & > :nth-child(2) {
        margin-left: 20px;
      }
    }
  }
  .el-form-item {
    margin-bottom: 14px;
  }
}

.avatar-uploader {
  height: 58px;
}
</style>
