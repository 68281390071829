<!--
 * @Author: your name
 * @Date: 2020-12-22 20:11:16
 * @LastEditTime: 2021-01-28 15:22:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \autovision-vue\src\components\selfDefineTemplate\preview.vue
-->
<template>
    <div class="templatePreview">
        <el-dialog
            :title="$t('report.template_review')"
            :visible.sync="newDialogVisible"
            width="614px"
            :before-close="closeFun"
        >
            <div class="previewContent">
                <img :src="templateUrl" alt="" srcset="" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import "./../../assets/scss/dialog.scss";
export default {
    name: "Preview",
    props: ["dialogVisible", "templateUrl"],
    data() {
        return {
            newDialogVisible: false,
        };
    },
    methods: {
        closeFun() {
            this.newDialogVisible = false;
            this.$emit("childFun", this.newDialogVisible);
        },
    },
    watch: {
        dialogVisible() {
            this.newDialogVisible = this.dialogVisible;
        },
    },
};
</script>

<style lang="scss">
.templatePreview {
    .previewContent {
        display: flex;
        justify-content: center;
        img {
            width: 100%;
            // height: 804px;
        }
    }
    .el-dialog__body {
        padding: 0;
    }
}
</style>
