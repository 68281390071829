<template>
    <div class="invitation">{{message}}</div>
</template>
<script>
export default {
    name: 'invitation',
    data(){
        return {
            message : ''
        }
    },
    created(){
        // this.getQueryUserByToken();
        // window.app = this;
        this.checkKeyValid();
        console.log(this.$route);
    },
    methods:{
        async checkKeyValid(){
            const res = await this.$api.checkInvitationCode({key: this.$route.query.key});
            if(res.code == 200){
                const query = this.$route.query;
                const param = Object.keys(query).map(key=>{
                    return key + "=" + query[key]
                }).join("&")
                this.$router.replace('/?'+param);
            }else{
                this.message = res.message;
            }
        },
        getQueryUserByToken(){
            this.$api.getQueryUserByToken().then(res=>{
                if(res.code == 200){
                    this.invitation();
                }else{
                    this.$router.push({
                        path: "/",
                        query : {
                            key : this.$route.query.key
                        }
                    });
                    this.$message.error(res.message);
                }
            })
        },
        invitation(){
            this.$api.invitation({
                key: this.$route.query.key
            }).then(res=>{
                if(res.code == 200){
                    this.$router.replace('/analysissystem');
                }
                this.$message({
                    type : res.code == 200 ? 'success' : 'error',
                    message : res.message
                })
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.invitation{
    text-align: center;
    line-height: 100px;
    font-size: 16px;
    color: #f54f00;
}
</style>