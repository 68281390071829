<template>
	<div class="optimizeDialog" v-if="visible" :style="moveStyle" ref="optimizeDialog">
		<div class="optimizeDialog-title">
			<span @mousedown="titleMousedown">{{$t('new_interaction.ptimize_the_image')}}</span>
			<i class="el-icon-close" @click="optimizeSettingClose"></i>
		</div>
		<div class="optimizeDialog-content">
			<div id="optimizeSetting" class="optimizeSetting">
			    <div class="optimize-container">
			
			        <div class="optimize-content">
			            <div class="optimize-row">
			                <div class="optimize-col">
			                    <div class="optimize-top">
			                        <label>{{$t('optimize_setting.denoising')}}</label>
			                        <input type="number" v-model="input_denoiseRadius" min="0" max="15" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" maxlength="3" />
			                    </div>
			                    <div class="optimize-bar">
			                        <el-slider v-model="denoiseRadius" :min="0" :max="15" :show-tooltip="false"></el-slider>
			                    </div>
			                </div>
			            </div>
			            <div class="optimize-row">
			                <div class="optimize-col">
			                    <div class="optimize-top">
			                        <label>{{$t('optimize_setting.brightness')}}</label>
			                        <input type="number" v-model="input_brightness" min="0" max="300" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" maxlength="3" />
			                    </div>
			                    <div class="optimize-bar">
			                        <el-slider v-model="brightness" :min="0" :max="300" :show-tooltip="false"></el-slider>
			                    </div>
			                </div>
			            </div>
			            <div class="optimize-row" v-if="model==='CSIE'">
			                <div class="optimize-col">
			                    <div class="optimize-top">
			                        <label>{{$t('optimize_setting.acuity')}}</label>
			                        <input type="number" v-model="input_singleUsmAmount" :min="0" :max="500" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" maxlength="3" />
			                    </div>
			                    <div class="optimize-bar">
			                        <el-slider v-model="singleUsmAmount" :min="0" :max="500" :show-tooltip="false"></el-slider>
			                    </div>
			                </div>
			                <div class="optimize-col">
			                    <div class="optimize-top">
			                        <label>{{$t('optimize_setting.radius')}}</label>
			                        <input type="number" v-model="input_singleUsmRadius" :min="0" :max="30" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" maxlength="3" />
			                    </div>
			                    <div class="optimize-bar">
			                        <el-slider v-model="singleUsmRadius" :min="0" :max="30" :show-tooltip="false"></el-slider>
			                    </div>
			                </div>
			            </div>
			            <div class="optimize-row" v-if="model==='LKIE'">
			                <div class="optimize-col">
			                    <div class="optimize-top">
			                        <label>{{$t('optimize_setting.contrast')}}</label>
			                        <input type="number" v-model="input_singleUsmAmount" :min="0" :max="500" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" maxlength="3"/>
			                    </div>
			                    <div class="optimize-bar">
			                        <el-slider v-model="singleUsmAmount" :min="0" :max="500" :show-tooltip="false"></el-slider>
			                    </div>
			                </div>
			                <div class="optimize-col">
			                    <div class="optimize-top">
			                        <label>{{$t('optimize_setting.levels')}}</label>
			                        <input type="number" v-model="input_singleUsmRadius" :min="0" :max="30" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" maxlength="3"/>
			                    </div>
			                    <div class="optimize-bar">
			                        <el-slider v-model="singleUsmRadius" :min="0" :max="30" :show-tooltip="false"></el-slider>
			                    </div>
			                </div>
			            </div>
			            <div class="optimize-row">
			                <div class="optimize-col">
			                    <div class="optimize-top">
			                        <label>{{$t('optimize_setting.acuity')}}</label>
			                        <input type="number" v-model="input_multiUsmAmount" :min="0" :max="100" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" maxlength="3" />
			                    </div>
			                    <div class="optimize-bar">
			                        <el-slider v-model="multiUsmAmount" :min="0" :max="100" :show-tooltip="false"></el-slider>
			                    </div>
			                </div>
			                <div class="optimize-col">
			                    <div class="optimize-top">
			                        <label>{{$t('optimize_setting.radius')}}</label>
			                        <input type="number" v-model="input_multiUsmRadius" :min="0" :max="30" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" maxlength="3" />
			                    </div>
			                    <div class="optimize-bar">
			                        <el-slider v-model="multiUsmRadius" :min="0" :max="30" :show-tooltip="false"></el-slider>
			                    </div>
			                </div>
			            </div>
			        </div>
			        <div class="optimize-btns" slot="footer">
			            <el-button type="primary" @click="save">{{$t('information.determine')}}</el-button>
			            <el-button @click="optimizeSettingClose">{{$t('information.cancel')}}</el-button>
			        </div>
			    </div>
			</div>
			<div class="optimizeDialog-loading" v-if="loading">
				<i class="el-icon-loading" style="font-size: 26px;"></i>
			</div>
		</div>
	</div>
</template>
<script>
import cvCommon from './modules/tools/opencv-common.js'
import {getRotateObj} from "./modules/tools/rotate"
export default {
    name: 'OptimizeSetting',
    props: {
        visible: Boolean,
        optimizeConfig: Object
    },
    data() {
        return {
            model: 'CSIE',
            type: 0,
            interface: {
                'CSIE': 0,
                'LKIE': 1,
                'MCIE': 2,
                'TAIE': 3,
                'BAIE': 4
            },
            value: 50,
            optimizeList: [],
            paramData: {},
            paramDataInput: {},
            optimizeData: {},
            originKaryoImage: "",
            originArrangeImage: "",
            karyoImage: "",
            arrangeImage: "",
            originPicJS: null,
            chromoPicJS: null,
            JSloaded: false,
            chromoPicList: [],
            brightness: 0,
            denoiseRadius: 0,
            multiUsmAmount: 0,
            multiUsmRadius: 0,
            singleUsmAmount: 0,
            singleUsmRadius: 0,
            input_brightness: 0,
            input_denoiseRadius: 0,
            input_multiUsmAmount: 0,
            input_multiUsmRadius: 0,
            input_singleUsmAmount: 0,
            input_singleUsmRadius: 0,
            loading: false,
            currentImage: '',
            currentImageIndex: 1,
            rotateAngle: 0,
            scale: 100,
            uploading: false,
            uploadPercent: 0,
            isInit: true,
			isMouseDown: false,
			start_x: 0,
			start_y: 0,
			move_x:0,
			move_y:0,
			moveStyle: ''
        }
    },
    created() {
        console.log("ready reCreateJsMat")
        this.$parent.reCreateJsMat();
        console.log("done reCreateJsMat")
        let data = this.$parent.cachePool.getData();
        this.karyoInfo = JSON.parse(data.karyoInfo);
        this.chromoList = JSON.parse(data.chromoList);
        this.$parent.karyoController.$optImage = this.$parent.karyoController.optImage;
        console.log("ready getOptimizeList")
        this.getOptimizeList();
        window.app = this;
		
		let mx = 0, my = 0;
		document.onmousemove = (e)=>{
			if(this.isMouseDown){
				mx = e.pageX - this.start_x + this.move_x;
				my = e.pageY - this.start_y + this.move_y;
				this.moveStyle = `margin: ${my}px 0 0 ${mx}px`;
			}
		}
		document.onmouseup = ()=>{
			this.isMouseDown = false;
			this.move_x = mx;
			this.move_y = my;
		}
    },
	mounted() {
		// document.body.appendChild(this.$refs.optimizeDialog);
	},
    methods: {
		titleMousedown(e){
			this.isMouseDown = true;
			this.start_x = e.pageX;
			this.start_y = e.pageY;
		},
        /**
         * 获取图像优化列表
         */
        getOptimizeList() {
            this.$api.optimizeList({}).then(res => {
                if (res.code == 200) {
                    this.optimizeList = res.data;
                    const isUse = this.optimizeList.filter(a => a.isUse)[0];
                    this.type = isUse ? isUse.id : this.optimizeList[0].id;
                    this.model = isUse ? isUse.name : this.optimizeList[0].name;
                    if (this.model === this.optimizeConfig.model) {
                        this.processData(this.optimizeConfig)
                    } else {
                        this.queryById(this.type);
                    }

                } else {
                    this.$message({
                        type: 'warning',
                        message: res.message
                    })
                }
            })
        },
        /**
         * 根据ID，切换模式
         */
        queryById(id) {
            this.$api.optimizeQueryById({ id: id }).then(res => {
                if (res.code == 200) {
                    this.processData(res.data.imageOptimization)
                } else {
                    this.$message({
                        type: 'warning',
                        message: res.message
                    })
                }
            })
        },
        /**
         * 处理初始接口数据
         */
        processData(data) {
            // this.optimizeData = data;
            this.setData(data);
            this.$parent.optimizeConfig = this.getData();
            // console.log(data);
            this.optUrl = this.karyoInfo.optUrl;
            this.primaryUrl = this.karyoInfo.primaryUrl;
            this.chromoPicList = this.chromoList.map(a => a.externalUrl);
            const t = +new Date();
            this.originPicJS = this.$parent.karyoController.originPicJS;
            this.optPicJS = this.$parent.karyoController.optPicJS;

            this._getVectorJSMat = this.$parent.VectorJSMat;
            console.log(this._getVectorJSMat);
            this.imageRender();
        },
        /**
         * 保存数值
         */
        setData(data) {
            data = Object.assign(data, this.optimizeConfig);
            this.brightness = parseInt(data.brightness)
            this.denoiseRadius = parseInt(data.denoiseRadius)
            this.multiUsmAmount = parseInt(data.multiUsmAmount)
            this.multiUsmRadius = parseInt(data.multiUsmRadius)
            this.singleUsmAmount = parseInt(data.singleUsmAmount)
            this.singleUsmRadius = parseInt(data.singleUsmRadius)
        },
        getData() {
            const param = {
                id: this.type,
                model: this.model,
                denoiseRadius: this.denoiseRadius,
                brightness: this.brightness,
                multiUsmAmount: this.multiUsmAmount,
                multiUsmRadius: this.multiUsmRadius,
            }
            if (this.model === 'CSIE'||this.model === 'LKIE') {
                param.singleUsmAmount = this.singleUsmAmount;
                param.singleUsmRadius = this.singleUsmRadius;
            }
            return param;
        },
        /**
         * 保存
         */
        save() {
            this.$parent.karyoInfo.optUrl = this.$parent.karyoController.optImage.src
            this.$parent.chromoList = this.chromoList;
            this.$parent.cachePool.save();
            this.$emit("optimizeSettingClose")
        },

        /**
         * 处理图片效果
         */
        imageRender() {
            // const picJS = this.isShowPrimaryImage ? this.originPictureJS : this.optPicJS;
            // if(!this.JSloaded && !this.originPictureJS){
            //     return;
            // }
            clearTimeout(this.timer);
            // this.loading = true;
            this.loadingLayer = this.$loading({
                background: "rgba(255,255,255,.3)",
                // customClass: "autovision-loading",
                text: this.$t('new_interaction.optimize_the_image_on'),
            });
            this.timer = setTimeout(() => {
                // this.loadingLayer = this.$loading({
                //     background: "rgba(255,255,255,.3)",
                //     // customClass: "autovision-loading",
                //     text: this.$t('new_interaction.optimize_the_image_on'),
                // });
                this.doRender();
            }, 100)
        },
        optimizeSettingClose() {
            if (this.$parent.karyoController.$optImage) {
                this.$parent.karyoController.optImage = this.$parent.karyoController.$optImage;
            }
            // this.$parent.karyoController.resetKaryo();
            // console.log(this.chromoList[0].justUrl);
            // this.$parent.optimizeConfig = this.getData();
            // this.$parent.chromoList = this.chromoList;
            // this.$parent.arrangeController.chromoMap = [];
            // this.$parent.arrangeController.reArrange();
            this.$parent.$refs.allTheKaryos && this.$parent.$refs.allTheKaryos.baseDataChange(this.$parent.chromoList);

            this.$emit("optimizeSettingClose")
        },
        /**
         * 调用c++方法 对图片进行处理
         */
        doRender() {
            console.warn("调用c++方法");

            try {
                const originPictureJS = this.originPicJS;
                const model = this.interface[this.model];
                const blur_radius = this.denoiseRadius;
                const light = this.brightness;
                const org_usm_amount = model < 2 ? this.singleUsmAmount : 0;
                const org_usm_radius = model < 2 ? this.singleUsmRadius : 0;
                const arr_usm_amount = this.multiUsmAmount;
                const arr_usm_radius = this.multiUsmRadius;

                // console.log(originPictureJS);
                // console.log(this._getVectorJSMat.get(0));
                // console.log(model,blur_radius,light,arr_usm_amount,arr_usm_radius,org_usm_amount,org_usm_radius);
                console.warn(+new Date())

                let manualSegmentFun = window.Module.ChromEnhanceFun(
                    originPictureJS,
                    this._getVectorJSMat,
                    model,
                    blur_radius,
                    light,
                    arr_usm_amount,
                    arr_usm_radius,
                    org_usm_amount,
                    org_usm_radius,
                )
                console.warn("执行c++方法完毕", +new Date())
                this.loading = false;
                // console.log(manualSegmentFun);
                if (manualSegmentFun.result == -1) {
                    console.warn("manualSegmentFun.result == -1")
                    return false;
                } else {
                    let chrArr = manualSegmentFun.chrArr;
                    let mat = manualSegmentFun.mat;
                    // console.log(chrArr);
                    console.warn("调用成功，正在生成新图片", +new Date())
                    this.createImage(mat, chrArr)
                }
            } catch (e) {
                this.loading = false;
                this.loadingLayer.close()
                console.error(e)
            }
        },
        /**
         * 创建优化图与排列图
         */
        createImage(mat, chrArr) {
            if (mat) {
                console.warn("有优化大图");
                let src = cvCommon._getImageByCpp(mat);
                const image = new Image();
                image.onload = () => {
                    // this.$parent.karyoController.$optImage = this.$parent.karyoController.optImage;
                    this.$parent.karyoController.optImage = image;
                    this.$parent.karyoController.resetKaryo();
                }
                image.src = src;
            }
            try {
                if (chrArr) {
                    console.warn("有优化小图");
                    this.count = 0;
                    for (var i = 0; i < this.chromoPicList.length; i++) {
                        let item = chrArr.get(i);
                        this.chromoList.forEach(chromo => {
                            if (chromo.index === item.index && chromo.chromoId === item.chromoId) {
                                const invertedUrl = cvCommon._getImageByCpp(item.cImgRotated);
                                let src = cvCommon._getImageByCpp(item.cImg);
                                // const _chromo = JSON.parse(JSON.stringify(chromo))
                                // console.log(chromo.justUrl == src,chromo.justUrl,src,)
                                chromo.invertedUrl = invertedUrl;
                                chromo.primaryUrl = src;
                                let degree = chromo.imgHandledRotatedDegree % 360;
                                if(degree > 0){
                                    getRotateObj(invertedUrl, degree).then(res=>{
                                        chromo.justUrl = res;
                                        this.afterCreateImage();
                                    })
                                }else{
                                    chromo.justUrl = invertedUrl;
                                    this.afterCreateImage();
                                }
                                // arr.push(_chromo);
                                // console.log(item);
                                // map[chromo.index] = cvCommon._getImageByCpp(item.cImgRotated)
                            }
                        })
                    }
                    
                    // this.$parent.arrangeController.reset(this.$parent.chromoList,this.$parent.karyoInfo,!this.karyoInfo.arrangeArrowLines ? []: JSON.parse(this.karyoInfo.arrangeArrowLines))
                    
                    // this.$parent.arrangeController.reCreateChromoImages();
                    
                }
            } catch (error) {

            }
            this.loadingLayer.close()
        },
        afterCreateImage(){
            this.count++;
            if(this.count === this.chromoPicList.length){
                this.$parent.arrangeController.chromoList = this.chromoList;
                this.$parent.arrangeController.reArrange();
                this.$parent.$refs.allTheKaryos && this.$parent.$refs.allTheKaryos.baseDataChange(this.$parent.chromoList);
            }
        },
        /**
         * 染色体列表添加JSmat对象
         */
        getExternalUrlJS() {
            if (this.$parent.VectorJSMat) {
                this._getVectorJSMat = this.$parent.VectorJSMat;
                this.JSloaded = true;
            }
            return;
            if (typeof window.Module.getVectorJSchromo !== "function") {
                this.$notify({
                    title: this.$t('optimize_setting.attention'),
                    message: this.$t('optimize_setting.upload_module_failed'),
                    type: 'warning',
                    duration: 10000
                });
                return;
            }
            let count = 0, total = this.chromoPicList.length;
            this._getVectorJSMat = window.Module.getVectorJSchromo();
            this.chromoList.forEach((chromo, index) => {
                // console.log(chromo);
                // chromo.cimgType = 1;
                cvCommon.getJSDataPromiseAsync(chromo).then(chromoDataJS => {
                    // chromo.chromoDataJS = chromoDataJS;
                    this._getVectorJSMat.push_back(chromoDataJS);
                    count++;
                    if (count >= total) {
                        this.JSloaded = true;
                        const picJS = this.isShowPrimaryImage ? this.originPictureJS : this.optPicJS;
                        if (picJS) {
                            this.isInit = false;
                            // !this.isInit && this.imageRender();
                            // this.isInit = false;
                        }
                    }
                });
            })
        },
    },
    watch: {

        brightness(nv, ov) {

            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.input_brightness = this.brightness;
            this.imageRender();
        },
        input_brightness(nv, ov) {
            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.brightness = parseInt(this.input_brightness);
            this.imageRender();
        },
        denoiseRadius(nv, ov) {
            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.input_denoiseRadius = this.denoiseRadius;
            this.imageRender();
        },
        input_denoiseRadius(nv, ov) {
            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.denoiseRadius = parseInt(this.input_denoiseRadius);
            this.imageRender();
        },
        singleUsmAmount(nv, ov) {
            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.input_singleUsmAmount = this.singleUsmAmount;
            this.imageRender();
        },
        input_singleUsmAmount(nv, ov) {
            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.singleUsmAmount = parseInt(this.input_singleUsmAmount);
            this.imageRender();
        },
        singleUsmRadius(nv, ov) {
            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.input_singleUsmRadius = this.singleUsmRadius;
            this.imageRender();
        },
        input_singleUsmRadius(nv, ov) {
            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.singleUsmRadius = parseInt(this.input_singleUsmRadius);
            this.imageRender();
        },
        multiUsmAmount(nv, ov) {
            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.input_multiUsmAmount = this.multiUsmAmount;
            this.imageRender();
        },
        input_multiUsmAmount(nv, ov) {
            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.multiUsmAmount = parseInt(this.input_multiUsmAmount);
            this.imageRender();
        },
        multiUsmRadius(nv, ov) {
            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.input_multiUsmRadius = this.multiUsmRadius;
            this.imageRender();
        },
        input_multiUsmRadius(nv, ov) {
            if (ov != 0) {
                this.$store.commit('metatypeValue', 2);
            }
            this.multiUsmRadius = parseInt(this.input_multiUsmRadius);
            this.imageRender();
        }
    }
}
</script>
<style lang="scss" scoped>
	.optimizeDialog{
		position: fixed;
		width: 500px;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%,-50%,0);
		overflow: hidden;
		border-radius: 6px;
		box-shadow: 0px 10px 20px 0px rgba(75, 75, 75, 0.5);
		background-color: #FFFFFF;
		z-index: 999;
	}
	.optimizeDialog-title{
		padding: 0 20px;
		height: 40px;
		line-height: 40px;
		background: linear-gradient(90deg, #3A83CE 0%, #416AB0 100%);
		color: #FFFFFF;
		position: relative;
		span{
			display: block;
			width: 100%;
			height: 100%;
			cursor: move;
		}
		i{
			position: absolute;
			right: 12px;
			top: 50%;
			transform: translate3d(0,-50%,0);
			cursor: pointer;
		}
	}
	.optimizeDialog-content{
		overflow: hidden;
		padding: 20px 30px;
		position: relative;
	}
	.optimizeDialog-loading{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(255,255,255,.4);
		display: flex;
		align-items: center;
		text-align: center;
		z-index: 9999;
		i{
			margin: 0 auto;
		}
	}
.zoom-image {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    .zoom-image-bg {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .zoom-image-close {
        position: absolute;
        z-index: 2;
        width: 48px;
        height: 48px;
        top: 50px;
        right: 50px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACqElEQVRoQ92aO6gTQRSGv1UEHyjBztgJio2VjY2IlRaicIuAjUlEEbFSQbGzEwW1ElHEJIIIKQTFQiuxs7GyEQU7YydB8QGikT/syLpsdmd2N7kct7s3Z87835yZOWdmN6KepwHsALYDm4EmsA5YE7v/DnwFRsAH4A3wGhhX7T6q4GA9sAfYC2wFQn1NgHfAc+AF8KWMltBO1cdGYAnYB6wu02lGmx/AM+Ah8CnEZwjASuAQcLhG4WmtAnkAPAJ++YD4AmhOnwe2+DitweY9cDleM7nufAB2AaeBtTUIC3HxDbgOvMxrVASwHzgJrAjpuUbb38BN4Oksn3kAEn+qRjFVXN2YBTELQNPmwjKOfBpWkbiUNZ2yALRgNfcWPeeLIqQ1obWoZPj3SQNoq7y2wN2mSHT6d+1OZ5JbbBpACaob6nXB9r044U27TQIow96aY5Kqi1PJ7oTL2EmAY3GmraujefpRpr6TjIAKs7sGRt8NiqJwVAWgi8CBOCzBo9ZoNBiPy1XFVdrG0/2JA7gKbAtVLwGj0YjhcEi322UyUYVc/ERRRK/Xo9Vq0Ww2yw7AW+CsAHQYuVeinp8q7ff7tNttBoOBF4QT79p0Op1i4mwLjdYRAewGzpX1khaUF4kQW089VwRwHDjo2SDTzEeYj00JDY8FcBHYWaLxP03yBM5JvPp/JYDbwKaqANM9OV6cyTWh/2vBhqyTAC0fBXAf2BDQKNc0DSHjOYmX688C0EF6VV0A6Ujob98dqoSGn/8FgPkpZH4Rm99GzScy86WE+WJO26/pcloA5g805o+UioLpQ70AzF+rCML0xZYAzF8tCsL05a4ryU1frzsI0y84khBmXzElp5PZl3wOwvRrVgdh+kV38sLA7KcG6VsPsx97ZF3fLNvnNn8AJ1h+fk1clm4AAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        cursor: pointer;
        &:hover {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADQUlEQVRoQ92aTWgTURDH/5NgbPADERG9eGr1LIrNpQWP3jyI1bK7HlKiqIXi19GDR+tHoSoakoPZJX7gwZMehfaSitCzticviopItSRWNiMTdku6Jt2PbFpfc0vyZub/25ndN++9JcTw0XV9NxEdZeYMER1g5j4AO4hom7hn5p8AfhDRHDO/J6IKM78xTfNLp+EpqoNsNrtzaWnpNACdmY8QUShfzMxE9BaAmUqlnhSLxe9RtIQKKgE0TdubSCSuMPNZAFuiBG1hs0hEj+r1+i3Lsj6F8RkYIJfLbarVamP1ev06EW0NEyToWGb+lUgkbvT09Ezk8/k/QewCAWia1ktEzwEcDOI0hjGzzHzSsqx5P1++AJqmHSeixwC2+zmL+f8FZj5jWdbL1fyuCqDreg7AAwDJmMUFdWcDOG+aZr6dQVsAR/xDAL5ZCqom4jgGcK4dREtxTtm8WMcr72W1mflEq3L6B2B4eLgvmUy+W4ea90vQgm3bh8vl8lzzwBUA8qisVqsza/i08RPt/X82nU73Nz9iVwAYhnGVmW+G9bqW44noWqlUGndjLgPIDAvgQ7cmqbggZbIDsN+dsZcBDMO4zcyX4grUTT9EdKdUKl2WGA0ApzH7GGNv00394nsxlUrtkwawAaDr+gUA96JETafTqFarUUzRiS2Ai6Zp3ncBKgD6w6oQAZOTk6hUKigWi9L3B3IhnXc2m0Umk8Ho6GjUCzBjmmaGZDHCzJ/D9vOu0pGREQwODmJ6ehqFQsEXQsSLzcDAAKampho2UT7OemIPGYYxxMxPozhp3ERNgvwgwowNooeITkkG7gIYC2LQtqEKABG3eEfLhGTgFTMf6wTALxNdEi/Zfy0ZkN6it1OAdhDyu1vzfiUWQcO8AHwFsCuCcUsT79WWQXLDdkG8uP4mJVRj5s1xAXgzId+7JF5K6Lf6AMqXkPI38UZ4jCo/kSnfSqjdzDnrAXXb6Q2xoFF+SSlZUHpRLwDKb6s4WVB3Y0sAlN9aFIj/eXOXmQ95T2023va6u7BR+oDDA6HmEZMLofQhnwvh3NjP1vDgY9a27SHvaUyrdXvgAzylD7qbyZV91cCbPmVf9mhVh+v5us1fYxKag3s3/o0AAAAASUVORK5CYII=);
        }
    }
    .image-box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        transform-origin: 50% 50%;
    }
    .layer-tools {
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 4px;
        position: absolute;
        left: 50%;
        bottom: 65px;
        z-index: 9;
        transform: translateX(-50%);
        display: flex;
        padding: 12px 0;
        .layer-tools-page {
            padding: 0 28px 0 38px;
            display: flex;
            border-right: 1px solid rgba(216, 216, 216, 0.4);
            .prev,
            .next {
                width: 24px;
                height: 24px;
                border-radius: 4px;
                background-repeat: no-repeat;
                cursor: pointer;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAA0UlEQVRIS+3WQQoCMQyF4T8n0BPpShG9kV5IBHXtHdTbKO6eDHQhIkzaBGczXaf9mgcpNQZaNpDLCP8teVfUkibAHtiZ2T3jdl54DlyAB7DIwF1w16GkDXDMwt1wNl4FZ+LVcMHXwCkSexOcgTfDP/Clmd28oxaCI3gY/sKfZc57O8+CV8AZeBX42hd5GJZUjXaXCsGtaAiOoM1wFG2CM9BqOAutgjNRNyxpVj4C7jlNmWNJU+AAbM2s93HoQ90dew6qrQk9ILXYZ/0IR9Kr2vsG33V2H1ROdPQAAAAASUVORK5CYII=);
                &:hover {
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAABFUlEQVRIS8XXMQ6CMBQG4P+NMJh4CZkIqwfwBO5wARLCROIJDByDGT2BB/AMLnADIyMLpkSNg4TX9iEdm6ZfX5v38koAEEXRpu/7I4AdgJWam2G0AC5EdCjL8kYv9ApgPQP2a8s7EW0pDMMTgP2f0DdzVvBjxusdi6dVcP/naAeOBTuOgziOUVUVmqYROScL9jwPWZah6zrkeS6Cs2AVYhAESJJEDGfD0rgWLIlrw1K4ESyBG8O2uBVsg1vDprgI7Ps+0jQdcrwoCtR1PVndrGETlF2rx45vilrBNqgxbIsawRKoNiyFasGSKBv+bgS4eTqVyKw8dl330/pwisMUyo6Ys5HumkXb22Ua+sW+MOptlvi0PQFmkQgqM0gXlgAAAABJRU5ErkJggg==);
                }
                &.disabled {
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAA0UlEQVRIS+3WQQoCMQyF4T8n0BPpShG9kV5IBHXtHdTbKO6eDHQhIkzaBGczXaf9mgcpNQZaNpDLCP8teVfUkibAHtiZ2T3jdl54DlyAB7DIwF1w16GkDXDMwt1wNl4FZ+LVcMHXwCkSexOcgTfDP/Clmd28oxaCI3gY/sKfZc57O8+CV8AZeBX42hd5GJZUjXaXCsGtaAiOoM1wFG2CM9BqOAutgjNRNyxpVj4C7jlNmWNJU+AAbM2s93HoQ90dew6qrQk9ILXYZ/0IR9Kr2vsG33V2H1ROdPQAAAAASUVORK5CYII=);
                    cursor: not-allowed;
                }
                background-size: 100%;
            }
            .prev {
                transform: rotate(180deg);
            }
            .page-num {
                width: 69px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                color: #ffffff;
                font-weight: 400;
                font-size: 16px;
            }
        }
        .layer-tools-zoom {
            padding: 0 29px;
            display: flex;
            border-right: 1px solid rgba(216, 216, 216, 0.4);
            .zoom-minus,
            .zoom-plus {
                width: 24px;
                height: 24px;
                border-radius: 4px;
                background-repeat: no-repeat;
                background-position: center center;
                cursor: pointer;
                &:hover {
                    background-color: #5d5d5d;
                }
                &.disabled {
                    background-color: transparent !important;
                    cursor: not-allowed;
                }
                background-size: 80%;
            }
            .zoom-minus {
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACBUlEQVRIS7WVy4vOYRTHPx/KQrksJMtxKdcs3JKRUoiSJBZKxE4pKYkxWVhgpyymSSF/gNuUWJGFjWRWiHIZC0nKZS1Hp57RO+/M7/f+1Dtn9fb+nud8zvme7/M8MsnhJOenFhAR04FtwCpgLvADeAPcV782KW5CQERMA04Cp4CZwEfgCzAbWAhMBW4CZ9X8vzLGASJiDnC3VH0FGFA/jWaIiFnAfuAc8AfYpb6oIowBlMofAfOBnepw1cZSyB1gMbBOzS7HRTugD+gHeuuSt3XzDBhRc1bVgDLQz8CgerrJAHNNRGwHHgAb1aft+/51EBG7gdtAT6vmTUAR8Q4YUk/UAc4DB9QFLRKkg6ZUQH6pOeTs4jqwSN1UBxgEVqobyqaDxYpVTdxQj5S1F4A96pI6wKViuWVl0wxgc00Hw+pIWTuQtlbX1wEO54DzxKo/m2jfImU66bV6qA6QV0G66JiaoEYREUuBl8A+9VYloLR6DdhRZvGtEyEi0oVp0R5ghfq7E2Ae8Bz4UE5ypVQl+eXsGNiqPq49aC165s35EPgOHFfz95gosmTyLeXi61MvNgIUqbLlq1kZ8B54Um7TvOjWAGuBt8BRIJ2TNp0Q0uk96AX2Aqvb3oMh4N6o5hFxpgrStRetBbJcfTUqV9cARdo8pHkeYlIAjYfcyf//872rEk0E/gvfh78ZkwqqIQAAAABJRU5ErkJggg==);
            }
            .zoom-plus {
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACN0lEQVRIS7WVS4iOYRTHfz/KQrksJMtxKdcs3JKRUoiSWyxExE4pKYkhxQI7ZTFNcsnCkmHKZUUWNpJZIcplLCQpt60cnXq+6ZtvvvfzpZmzenvf53l+55z//zmvjHI4yufTEhAR44F1wCJgKvAdeA3cVb+0k1xTQESMA44AR4GJwAfgMzAZmAmMBa4DJ9R8XxnDABExBbhdsr4IdKsfaydExCRgJ3AK+ANsUp9XEYYASuYPgenARrW/amNJpBeYDSxTs8ph0QjoAk4CnY2HR8QeoFf91VDNU2BATa2qAUXQT0CPeqx+ZUSkDj+AzWpfw7f1wH1gpfqkkTBYQURsAW4BHfU9zw0RkeJ+A7aqqc+QiIi3QJ96uBXgDLBbnVHXgsx8THHSALALuFe+/1RT5EzgKjBLXdUK0AMsVFeUTdnztGJVXFP3l7VngW3qnFaA88Vy88qmCcDqUkFeuBvAOSBFzehXs6qsoDttrS5vBdiXAueNVVPQwWhDg4S+Uve2AuQoSBcdVBPUFiAi5gIvgB3qzUpAKfUKsKFo8bVO7BT6MnC61payPl2YFu0AFqi//wWYBjwD3pebPKRVDVXl4ReyYmCt+qiZG5rNopycD4rvD6n5PCRKW/LwNWXwdalpgGFRNU2z5EuZGfAOeFymaQ66JcBS4A1wAEjnpE2bQv71P+gEtgOLG/4HOS7u1HoeEcerICP2R6uDzFdf1no1YoDiqrykeR9iVABti9xs4f++G9EWNUviL5Bc4RmQtObrAAAAAElFTkSuQmCC);
            }
            .zoom-percent {
                width: 72px;
                text-align: center;
                font-size: 16px;
                color: #ffffff;
                font-weight: 400;
            }
        }
        .layer-tools-rotate {
            width: 24px;
            height: 24px;
            cursor: pointer;
            margin: 0 40px 0 30px;
            border-radius: 4px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABVUlEQVRIS9WUsStFURzHP98sisFIBqNnUAbZ7GajlyJ5T8lABhQDIzLKy2yQEoNMYsc/IIvB4q029dWt++p6ue+e+7jFqbP9vudzvr/v7xxR8FLB5/N3ALaHgV1JE3lcZzqw3QdsA7NAh6RMTfICqcW2u4DVeHcnRPvAK3AHPEpyK0ftAE6AAWAMeAY2JZ2lQTLtxi3aAWaSLbLdAywCa8ApUJX00QzKBDQEaSHbHgKugBtJc20DWvXZdgm4B8qSLoJCzjOKUa3tLWBS0kgqwHYnsA70BgDegT1J0URFgH7gBShJemrov2RguwwcAOcBgHHgWtJKIqcIsJRsUzOgAlQkjWYBbB9FNZKqCcADcCjpOM3BTwFR0DVJtX8LiMa1LqleiIPvcvvVkEMA88AyMJ01RcAG8CZpIfg3tT0I3OZ4aFOSLoMBAbfOXRL8m+Y+ORYUDvgEHXqZGXga6fEAAAAASUVORK5CYII=)
                no-repeat;
            &:hover {
                background-color: #5d5d5d;
            }
        }
    }
}
.optimizeSetting-tips {
    height: 34px;
    line-height: 34px;
    background-color: #fff8bf;
    color: #f53500;
    font-size: 14px;
    text-align: center;
}
.hr {
    margin: 20px 0 0 0;
    border-top: 1px solid #e3e3e3;
}
.current {
    // color: #F53500 !important;
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        background-color: #f53500;
        top: 100%;
        left: 0;
        transform: translateY(6px);
    }
}
.buttonCurrent {
    border: none !important;
    margin: 0;
}
.buttonCurrentImg {
    margin-left: 4px;
    transform: translateY(3px);
}
.optimize-container {
    overflow: hidden;
    // padding: 15px 90px 100px;
    .optimize-title {
        margin-top: 6px;
        height: 42px;
        line-height: 42px;
        position: relative;
        font-size: 16px;
        color: #333333;

        font-family: PingFangSC-Medium, PingFang SC;
        > label {
            position: absolute;
            width: 80px;
            height: 26px;
            left: 76px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            border-radius: 3px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            text-align: center;
            line-height: 26px;
            color: #333333;
            font-family: SourceHanSansSC-Medium, SourceHanSansSC;

        }
    }
    .priview {
        width: 748px;
        height: 292px;
        display: flex;
        border: 1px solid #d4d4d4;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 8px 0;
        background-color: #fbfbfb;
        cursor: pointer;
        .karyo,
        .arrange {
            flex: 1;
            background-color: #ffffff;
            overflow: hidden;
            vertical-align: middle;
            img {
                width: 100%;
            }
        }
        .arrange {
            margin-left: 0px;
        }
    }
    .optimize-content {
        position: relative;
        // padding: 0 20px;
    }
    .upload-page {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #b2b2b2;
        left: 0;
        top: 0;
        z-index: 99999;
        .upload-pannel {
            width: 300px;
            height: 100px;
            border-radius: 8px;
            background-color: #ffffff;
            left: 50%;
            top: 50%;
            position: absolute;
            transform: translate3d(-50%, -50%, 0);
            overflow: hidden;
            > label {
                display: block;
                text-align: center;
                line-height: 22px;
                margin-top: 30px;
                font-size: 16px;
                color: #333333;
                font-family: PingFangSC-Medium, PingFang SC;

            }
            .upload-progress {
                width: 230px;
                height: 4px;
                border-radius: 2px;
                margin: 14px auto 0;
                overflow: hidden;
                background-color: #d9e7f5;
            }
            .upload-percent {
                height: 4px;
                background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
                border-radius: 2px;
            }
        }
    }
    .optimize-btns {
        overflow: hidden;
        margin-top: 20px;
        text-align: right;
        .el-button {
            width: 110px;
            height: 40px;
            line-height: 40px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .optimize-desc {
        margin-top: 30px;
        font-size: 12px;
        color: #333333;
        line-height: 18px;
    }
}
.optimize-row {
    overflow: hidden;
    // width: 530px;
    padding-right: 20px;
    display: flex;
    padding-left: 20px;
    // margin-left: -20px;
}
.optimize-col {
    flex: 1;
    &:nth-child(2) {
        margin-left: 20px;
    }
    .optimize-top {
        height: 24px;
        display: flex;
        justify-content: space-between;
        label {
            line-height: 34px;
            font-size: 14px;
            color: #444444;
        }
        input {
            width: 60px;
            height: 24px;
            border-radius: 4px;
            text-indent: 10px;
            font-size: 14px;
            color: #444444;
            outline: none;
            border: 1px solid #d9d9d9;
        }
    }
}
</style>