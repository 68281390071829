export const standard = {
	"type": 700,
	"padding": 35,
	"position": [{
			"name": "Chrom01ISCN09",
			"element": [{
				"lxly": [
					39,
					962
				]
			}]
		},
		{
			"name": "Chrom02ISCN09",
			"element": [{
				"lxly": [
					40,
					886
				]
			}]
		},
		{
			"name": "Chrom03ISCN09",
			"element": [{
				"lxly": [
					39,
					751
				]
			}]
		},
		{
			"name": "Chrom04ISCN09",
			"element": [{
				"lxly": [
					40,
					705
				]
			}]
		},
		{
			"name": "Chrom05ISCN09",
			"element": [{
				"lxly": [
					41,
					668
				]
			}]
		},
		{
			"name": "Chrom06ISCN09",
			"element": [{
				"lxly": [
					39,
					653
				]
			}]
		},
		{
			"name": "Chrom07ISCN09",
			"element": [{
				"lxly": [
					39,
					594
				]
			}]
		},
		{
			"name": "Chrom08ISCN09",
			"element": [{
				"lxly": [
					39,
					526
				]
			}]
		},
		{
			"name": "Chrom09ISCN09",
			"element": [{
				"lxly": [
					40,
					524
				]
			}]
		},
		{
			"name": "Chrom10ISCN09",
			"element": [{
				"lxly": [
					40,
					511
				]
			}]
		},
		{
			"name": "Chrom11ISCN09",
			"element": [{
				"lxly": [
					40,
					512
				]
			}]
		},
		{
			"name": "Chrom12ISCN09",
			"element": [{
				"lxly": [
					40,
					495
				]
			}]
		},
		{
			"name": "Chrom13ISCN09",
			"element": [{
				"lxly": [
					39,
					417
				]
			}]
		},
		{
			"name": "Chrom14ISCN09",
			"element": [{
				"lxly": [
					40,
					403
				]
			}]
		},
		{
			"name": "Chrom15ISCN09",
			"element": [{
				"lxly": [
					41,
					381
				]
			}]
		},
		{
			"name": "Chrom16ISCN09",
			"element": [{
				"lxly": [
					40,
					353
				]
			}]
		},
		{
			"name": "Chrom17ISCN09",
			"element": [{
				"lxly": [
					40,
					343
				]
			}]
		},
		{
			"name": "Chrom18ISCN09",
			"element": [{
				"lxly": [
					41,
					307
				]
			}]
		},
		{
			"name": "Chrom19ISCN09",
			"element": [{
				"lxly": [
					38,
					271
				]
			}]
		},
		{
			"name": "Chrom20ISCN09",
			"element": [{
				"lxly": [
					39,
					258
				]
			}]
		},
		{
			"name": "Chrom21ISCN09",
			"element": [{
				"lxly": [
					41,
					200
				]
			}]
		},
		{
			"name": "Chrom22ISCN09",
			"element": [{
				"lxly": [
					39,
					217
				]
			}]
		},
		{
			"name": "ChromXISCN09",
			"element": [{
				"lxly": [
					39,
					602
				]
			}]
		},
		{
			"name": "ChromYISCN09",
			"element": [{
				"lxly": [
					46,
					213
				]
			}]
		}
	],
	"banding": [{
			"name": "Chrom01ISCN09",
			"element": [{
					"name": "p36.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						39,
						19
					]
				},
				{
					"name": "p36.23",
					"lxly": [
						0,
						20
					],
					"rxry": [
						39,
						28
					]
				},
				{
					"name": "p36.22",
					"lxly": [
						0,
						29
					],
					"rxry": [
						39,
						39
					]
				},
				{
					"name": "p36.21",
					"lxly": [
						0,
						40
					],
					"rxry": [
						39,
						48
					]
				},
				{
					"name": "p36.13",
					"lxly": [
						0,
						49
					],
					"rxry": [
						39,
						69
					]
				},
				{
					"name": "p36.12",
					"lxly": [
						0,
						70
					],
					"rxry": [
						39,
						78
					]
				},
				{
					"name": "p36.11",
					"lxly": [
						0,
						79
					],
					"rxry": [
						39,
						84
					]
				},
				{
					"name": "p35.3",
					"lxly": [
						0,
						85
					],
					"rxry": [
						39,
						93
					]
				},
				{
					"name": "p35.2",
					"lxly": [
						0,
						94
					],
					"rxry": [
						39,
						103
					]
				},
				{
					"name": "p35.1",
					"lxly": [
						0,
						104
					],
					"rxry": [
						39,
						121
					]
				},
				{
					"name": "p34.3",
					"lxly": [
						0,
						122
					],
					"rxry": [
						39,
						135
					]
				},
				{
					"name": "p34.2",
					"lxly": [
						0,
						136
					],
					"rxry": [
						39,
						147
					]
				},
				{
					"name": "p34.1",
					"lxly": [
						0,
						148
					],
					"rxry": [
						39,
						165
					]
				},
				{
					"name": "p33",
					"lxly": [
						0,
						165
					],
					"rxry": [
						39,
						180
					]
				},
				{
					"name": "p32.3",
					"lxly": [
						0,
						181
					],
					"rxry": [
						39,
						193
					]
				},
				{
					"name": "p32.2",
					"lxly": [
						0,
						193
					],
					"rxry": [
						39,
						213
					]
				},
				{
					"name": "p32.1",
					"lxly": [
						0,
						214
					],
					"rxry": [
						39,
						231
					]
				},
				{
					"name": "p31.3",
					"lxly": [
						0,
						232
					],
					"rxry": [
						39,
						251
					]
				},
				{
					"name": "p31.2",
					"lxly": [
						0,
						251
					],
					"rxry": [
						39,
						258
					]
				},
				{
					"name": "p31.1",
					"lxly": [
						0,
						258
					],
					"rxry": [
						39,
						283
					]
				},
				{
					"name": "p22.3",
					"lxly": [
						0,
						283
					],
					"rxry": [
						39,
						301
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						302
					],
					"rxry": [
						39,
						313
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						314
					],
					"rxry": [
						39,
						342
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						343
					],
					"rxry": [
						39,
						357
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						358
					],
					"rxry": [
						39,
						364
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						364
					],
					"rxry": [
						39,
						379
					]
				},
				{
					"name": "p13.3",
					"lxly": [
						0,
						380
					],
					"rxry": [
						39,
						402
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						403
					],
					"rxry": [
						39,
						414
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						415
					],
					"rxry": [
						39,
						423
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						424
					],
					"rxry": [
						39,
						435
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						436
					],
					"rxry": [
						39,
						452
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						453
					],
					"rxry": [
						39,
						461
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						462
					],
					"rxry": [
						39,
						469
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						470
					],
					"rxry": [
						39,
						556
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						557
					],
					"rxry": [
						39,
						587
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						589
					],
					"rxry": [
						39,
						596
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						598
					],
					"rxry": [
						39,
						604
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						605
					],
					"rxry": [
						39,
						613
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						614
					],
					"rxry": [
						39,
						635
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						636
					],
					"rxry": [
						39,
						644
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						646
					],
					"rxry": [
						39,
						653
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						654
					],
					"rxry": [
						39,
						662
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						663
					],
					"rxry": [
						39,
						670
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						671
					],
					"rxry": [
						39,
						682
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						683
					],
					"rxry": [
						39,
						691
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						692
					],
					"rxry": [
						39,
						700
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						702
					],
					"rxry": [
						39,
						737
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						738
					],
					"rxry": [
						39,
						753
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						753
					],
					"rxry": [
						39,
						758
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						759
					],
					"rxry": [
						39,
						777
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						777
					],
					"rxry": [
						39,
						810
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						811
					],
					"rxry": [
						39,
						822
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						823
					],
					"rxry": [
						39,
						828
					]
				},
				{
					"name": "q41",
					"lxly": [
						0,
						828
					],
					"rxry": [
						39,
						847
					]
				},
				{
					"name": "q42.11",
					"lxly": [
						0,
						847
					],
					"rxry": [
						39,
						854
					]
				},
				{
					"name": "q42.12",
					"lxly": [
						0,
						855
					],
					"rxry": [
						39,
						863
					]
				},
				{
					"name": "q42.13",
					"lxly": [
						0,
						864
					],
					"rxry": [
						39,
						908
					]
				},
				{
					"name": "q42.2",
					"lxly": [
						0,
						908
					],
					"rxry": [
						39,
						916
					]
				},
				{
					"name": "q42.3",
					"lxly": [
						0,
						917
					],
					"rxry": [
						39,
						924
					]
				},
				{
					"name": "q43",
					"lxly": [
						0,
						925
					],
					"rxry": [
						39,
						943
					]
				},
				{
					"name": "q44",
					"lxly": [
						0,
						943
					],
					"rxry": [
						39,
						959
					]
				}
			]
		},
		{
			"name": "Chrom02ISCN09",
			"element": [{
					"name": "p25.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						40,
						17
					]
				},
				{
					"name": "p25.2",
					"lxly": [
						0,
						17
					],
					"rxry": [
						40,
						24
					]
				},
				{
					"name": "p25.1",
					"lxly": [
						0,
						24
					],
					"rxry": [
						40,
						41
					]
				},
				{
					"name": "p24.3",
					"lxly": [
						0,
						41
					],
					"rxry": [
						40,
						57
					]
				},
				{
					"name": "p24.2",
					"lxly": [
						0,
						57
					],
					"rxry": [
						40,
						62
					]
				},
				{
					"name": "p24.1",
					"lxly": [
						0,
						62
					],
					"rxry": [
						40,
						68
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						69
					],
					"rxry": [
						40,
						99
					]
				},
				{
					"name": "p22.3",
					"lxly": [
						0,
						99
					],
					"rxry": [
						40,
						114
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						115
					],
					"rxry": [
						40,
						116
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						117
					],
					"rxry": [
						40,
						130
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						130
					],
					"rxry": [
						40,
						165
					]
				},
				{
					"name": "p16.3",
					"lxly": [
						0,
						165
					],
					"rxry": [
						40,
						180
					]
				},
				{
					"name": "p16.2",
					"lxly": [
						0,
						181
					],
					"rxry": [
						40,
						189
					]
				},
				{
					"name": "p16.1",
					"lxly": [
						0,
						189
					],
					"rxry": [
						40,
						204
					]
				},
				{
					"name": "p15",
					"lxly": [
						0,
						204
					],
					"rxry": [
						40,
						220
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						220
					],
					"rxry": [
						40,
						231
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						232
					],
					"rxry": [
						40,
						284
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						284
					],
					"rxry": [
						40,
						311
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						311
					],
					"rxry": [
						40,
						337
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						338
					],
					"rxry": [
						40,
						344
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						345
					],
					"rxry": [
						40,
						354
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						355
					],
					"rxry": [
						40,
						375
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						376
					],
					"rxry": [
						40,
						393
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						394
					],
					"rxry": [
						40,
						414
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						415
					],
					"rxry": [
						40,
						426
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						427
					],
					"rxry": [
						40,
						438
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						439
					],
					"rxry": [
						40,
						451
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						451
					],
					"rxry": [
						40,
						481
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						481
					],
					"rxry": [
						40,
						490
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						491
					],
					"rxry": [
						40,
						511
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						512
					],
					"rxry": [
						40,
						542
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						543
					],
					"rxry": [
						40,
						564
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						564
					],
					"rxry": [
						40,
						580
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						580
					],
					"rxry": [
						40,
						599
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						599
					],
					"rxry": [
						40,
						621
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						621
					],
					"rxry": [
						40,
						651
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						652
					],
					"rxry": [
						40,
						666
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						667
					],
					"rxry": [
						40,
						684
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						685
					],
					"rxry": [
						40,
						706
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						706
					],
					"rxry": [
						40,
						714
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						714
					],
					"rxry": [
						40,
						734
					]
				},
				{
					"name": "q33.1",
					"lxly": [
						0,
						734
					],
					"rxry": [
						40,
						754
					]
				},
				{
					"name": "q33.2",
					"lxly": [
						0,
						755
					],
					"rxry": [
						40,
						763
					]
				},
				{
					"name": "q33.3",
					"lxly": [
						0,
						764
					],
					"rxry": [
						40,
						781
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						781
					],
					"rxry": [
						40,
						797
					]
				},
				{
					"name": "q35",
					"lxly": [
						0,
						798
					],
					"rxry": [
						40,
						820
					]
				},
				{
					"name": "q36",
					"lxly": [
						0,
						821
					],
					"rxry": [
						40,
						839
					]
				},
				{
					"name": "q37.1",
					"lxly": [
						0,
						840
					],
					"rxry": [
						40,
						861
					]
				},
				{
					"name": "q37.2",
					"lxly": [
						0,
						862
					],
					"rxry": [
						40,
						870
					]
				},
				{
					"name": "q37.3",
					"lxly": [
						0,
						871
					],
					"rxry": [
						40,
						883
					]
				}
			]
		},
		{
			"name": "Chrom03ISCN09",
			"element": [{
					"name": "p26.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						39,
						12
					]
				},
				{
					"name": "p26.2",
					"lxly": [
						0,
						13
					],
					"rxry": [
						39,
						14
					]
				},
				{
					"name": "p26.1",
					"lxly": [
						0,
						15
					],
					"rxry": [
						39,
						25
					]
				},
				{
					"name": "p25",
					"lxly": [
						0,
						25
					],
					"rxry": [
						39,
						56
					]
				},
				{
					"name": "p24.3",
					"lxly": [
						0,
						56
					],
					"rxry": [
						39,
						73
					]
				},
				{
					"name": "p24.2",
					"lxly": [
						0,
						73
					],
					"rxry": [
						39,
						81
					]
				},
				{
					"name": "p24.1",
					"lxly": [
						0,
						81
					],
					"rxry": [
						39,
						95
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						94
					],
					"rxry": [
						39,
						100
					]
				},
				{
					"name": "p22.3",
					"lxly": [
						0,
						100
					],
					"rxry": [
						39,
						112
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						113
					],
					"rxry": [
						39,
						131
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						132
					],
					"rxry": [
						39,
						142
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						143
					],
					"rxry": [
						39,
						207
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						207
					],
					"rxry": [
						39,
						216
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						217
					],
					"rxry": [
						39,
						229
					]
				},
				{
					"name": "p14.3",
					"lxly": [
						0,
						230
					],
					"rxry": [
						39,
						238
					]
				},
				{
					"name": "p14.2",
					"lxly": [
						0,
						239
					],
					"rxry": [
						39,
						247
					]
				},
				{
					"name": "p14.1",
					"lxly": [
						0,
						247
					],
					"rxry": [
						39,
						258
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						258
					],
					"rxry": [
						39,
						292
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						292
					],
					"rxry": [
						39,
						307
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						309
					],
					"rxry": [
						39,
						314
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						314
					],
					"rxry": [
						39,
						327
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						328
					],
					"rxry": [
						39,
						337
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						338
					],
					"rxry": [
						39,
						345
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						346
					],
					"rxry": [
						39,
						354
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						355
					],
					"rxry": [
						39,
						369
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						370
					],
					"rxry": [
						39,
						374
					]
				},
				{
					"name": "q13.11",
					"lxly": [
						0,
						375
					],
					"rxry": [
						39,
						386
					]
				},
				{
					"name": "q13.12",
					"lxly": [
						0,
						387
					],
					"rxry": [
						39,
						393
					]
				},
				{
					"name": "q13.13",
					"lxly": [
						0,
						394
					],
					"rxry": [
						39,
						406
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						406
					],
					"rxry": [
						39,
						421
					]
				},
				{
					"name": "q13.31",
					"lxly": [
						0,
						421
					],
					"rxry": [
						39,
						437
					]
				},
				{
					"name": "q13.32",
					"lxly": [
						0,
						438
					],
					"rxry": [
						39,
						442
					]
				},
				{
					"name": "q13.33",
					"lxly": [
						0,
						443
					],
					"rxry": [
						39,
						455
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						456
					],
					"rxry": [
						39,
						507
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						508
					],
					"rxry": [
						39,
						517
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						518
					],
					"rxry": [
						39,
						522
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						523
					],
					"rxry": [
						39,
						531
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						532
					],
					"rxry": [
						39,
						553
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						554
					],
					"rxry": [
						39,
						569
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						570
					],
					"rxry": [
						39,
						579
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						580
					],
					"rxry": [
						39,
						588
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						589
					],
					"rxry": [
						39,
						617
					]
				},
				{
					"name": "q26.1",
					"lxly": [
						0,
						618
					],
					"rxry": [
						39,
						636
					]
				},
				{
					"name": "q26.2",
					"lxly": [
						0,
						637
					],
					"rxry": [
						39,
						654
					]
				},
				{
					"name": "q26.31",
					"lxly": [
						0,
						654
					],
					"rxry": [
						39,
						668
					]
				},
				{
					"name": "q26.32",
					"lxly": [
						0,
						668
					],
					"rxry": [
						39,
						673
					]
				},
				{
					"name": "q26.33",
					"lxly": [
						0,
						674
					],
					"rxry": [
						39,
						684
					]
				},
				{
					"name": "q27",
					"lxly": [
						0,
						683
					],
					"rxry": [
						39,
						711
					]
				},
				{
					"name": "q28",
					"lxly": [
						0,
						711
					],
					"rxry": [
						39,
						725
					]
				},
				{
					"name": "q29",
					"lxly": [
						0,
						725
					],
					"rxry": [
						39,
						748
					]
				}
			]
		},
		{
			"name": "Chrom04ISCN09",
			"element": [{
					"name": "p16.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						40,
						8
					]
				},
				{
					"name": "p16.2",
					"lxly": [
						0,
						9
					],
					"rxry": [
						40,
						15
					]
				},
				{
					"name": "p16.1",
					"lxly": [
						0,
						16
					],
					"rxry": [
						40,
						34
					]
				},
				{
					"name": "p15.33",
					"lxly": [
						0,
						35
					],
					"rxry": [
						40,
						48
					]
				},
				{
					"name": "p15.32",
					"lxly": [
						0,
						49
					],
					"rxry": [
						40,
						55
					]
				},
				{
					"name": "p15.31",
					"lxly": [
						0,
						56
					],
					"rxry": [
						40,
						69
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						70
					],
					"rxry": [
						40,
						92
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						92
					],
					"rxry": [
						40,
						121
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						121
					],
					"rxry": [
						40,
						154
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						154
					],
					"rxry": [
						40,
						173
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						173
					],
					"rxry": [
						40,
						179
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						180
					],
					"rxry": [
						40,
						188
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						189
					],
					"rxry": [
						40,
						199
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						200
					],
					"rxry": [
						40,
						232
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						232
					],
					"rxry": [
						40,
						257
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						257
					],
					"rxry": [
						40,
						263
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						263
					],
					"rxry": [
						40,
						275
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						275
					],
					"rxry": [
						40,
						299
					]
				},
				{
					"name": "q21.21",
					"lxly": [
						0,
						299
					],
					"rxry": [
						40,
						310
					]
				},
				{
					"name": "q21.22",
					"lxly": [
						0,
						311
					],
					"rxry": [
						40,
						316
					]
				},
				{
					"name": "q21.23",
					"lxly": [
						0,
						317
					],
					"rxry": [
						40,
						323
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						324
					],
					"rxry": [
						40,
						337
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						338
					],
					"rxry": [
						40,
						363
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						363
					],
					"rxry": [
						40,
						380
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						381
					],
					"rxry": [
						40,
						399
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						398
					],
					"rxry": [
						40,
						415
					]
				},
				{
					"name": "q26",
					"lxly": [
						0,
						415
					],
					"rxry": [
						40,
						441
					]
				},
				{
					"name": "q27",
					"lxly": [
						0,
						441
					],
					"rxry": [
						40,
						458
					]
				},
				{
					"name": "q28.1",
					"lxly": [
						0,
						459
					],
					"rxry": [
						40,
						468
					]
				},
				{
					"name": "q28.2",
					"lxly": [
						0,
						469
					],
					"rxry": [
						40,
						486
					]
				},
				{
					"name": "q28.3",
					"lxly": [
						0,
						486
					],
					"rxry": [
						40,
						515
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						515
					],
					"rxry": [
						40,
						535
					]
				},
				{
					"name": "q31.21",
					"lxly": [
						0,
						536
					],
					"rxry": [
						40,
						542
					]
				},
				{
					"name": "q31.22",
					"lxly": [
						0,
						543
					],
					"rxry": [
						40,
						555
					]
				},
				{
					"name": "q31.23",
					"lxly": [
						0,
						556
					],
					"rxry": [
						40,
						562
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						563
					],
					"rxry": [
						40,
						578
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						578
					],
					"rxry": [
						40,
						592
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						592
					],
					"rxry": [
						40,
						596
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						596
					],
					"rxry": [
						40,
						605
					]
				},
				{
					"name": "q33",
					"lxly": [
						0,
						605
					],
					"rxry": [
						40,
						629
					]
				},
				{
					"name": "q34.1",
					"lxly": [
						0,
						629
					],
					"rxry": [
						40,
						636
					]
				},
				{
					"name": "q34.2",
					"lxly": [
						0,
						636
					],
					"rxry": [
						40,
						641
					]
				},
				{
					"name": "q34.3",
					"lxly": [
						0,
						641
					],
					"rxry": [
						40,
						660
					]
				},
				{
					"name": "q35.1",
					"lxly": [
						0,
						660
					],
					"rxry": [
						40,
						693
					]
				},
				{
					"name": "q35.2",
					"lxly": [
						0,
						693
					],
					"rxry": [
						40,
						702
					]
				}
			]
		},
		{
			"name": "Chrom05ISCN09",
			"element": [{
					"name": "p15.33",
					"lxly": [
						0,
						0
					],
					"rxry": [
						41,
						20
					]
				},
				{
					"name": "p15.32",
					"lxly": [
						0,
						21
					],
					"rxry": [
						41,
						30
					]
				},
				{
					"name": "p15.31",
					"lxly": [
						0,
						31
					],
					"rxry": [
						41,
						46
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						47
					],
					"rxry": [
						41,
						55
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						56
					],
					"rxry": [
						41,
						84
					]
				},
				{
					"name": "p14.3",
					"lxly": [
						0,
						84
					],
					"rxry": [
						41,
						98
					]
				},
				{
					"name": "p14.2",
					"lxly": [
						0,
						99
					],
					"rxry": [
						41,
						100
					]
				},
				{
					"name": "p14.1",
					"lxly": [
						0,
						101
					],
					"rxry": [
						41,
						113
					]
				},
				{
					"name": "p13.3",
					"lxly": [
						0,
						114
					],
					"rxry": [
						41,
						138
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						139
					],
					"rxry": [
						41,
						143
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						144
					],
					"rxry": [
						41,
						153
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						154
					],
					"rxry": [
						41,
						166
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						167
					],
					"rxry": [
						41,
						174
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						175
					],
					"rxry": [
						41,
						184
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						185
					],
					"rxry": [
						41,
						224
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						224
					],
					"rxry": [
						41,
						237
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						238
					],
					"rxry": [
						41,
						243
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						244
					],
					"rxry": [
						41,
						260
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						260
					],
					"rxry": [
						41,
						271
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						273
					],
					"rxry": [
						41,
						281
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						282
					],
					"rxry": [
						41,
						304
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						304
					],
					"rxry": [
						41,
						322
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						323
					],
					"rxry": [
						41,
						329
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						330
					],
					"rxry": [
						41,
						339
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						339
					],
					"rxry": [
						41,
						367
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						367
					],
					"rxry": [
						41,
						384
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						384
					],
					"rxry": [
						41,
						389
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						389
					],
					"rxry": [
						41,
						406
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						406
					],
					"rxry": [
						41,
						434
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						434
					],
					"rxry": [
						41,
						454
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						454
					],
					"rxry": [
						41,
						459
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						459
					],
					"rxry": [
						41,
						478
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						479
					],
					"rxry": [
						41,
						513
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						514
					],
					"rxry": [
						41,
						519
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						521
					],
					"rxry": [
						41,
						545
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						545
					],
					"rxry": [
						41,
						565
					]
				},
				{
					"name": "q33.1",
					"lxly": [
						0,
						565
					],
					"rxry": [
						41,
						577
					]
				},
				{
					"name": "q33.2",
					"lxly": [
						0,
						578
					],
					"rxry": [
						41,
						583
					]
				},
				{
					"name": "q33.3",
					"lxly": [
						0,
						584
					],
					"rxry": [
						41,
						609
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						610
					],
					"rxry": [
						41,
						628
					]
				},
				{
					"name": "q35.1",
					"lxly": [
						0,
						628
					],
					"rxry": [
						41,
						640
					]
				},
				{
					"name": "q35.2",
					"lxly": [
						0,
						641
					],
					"rxry": [
						41,
						647
					]
				},
				{
					"name": "q35.3",
					"lxly": [
						0,
						648
					],
					"rxry": [
						41,
						665
					]
				}
			]
		},
		{
			"name": "Chrom06ISCN09",
			"element": [{
					"name": "p25",
					"lxly": [
						0,
						0
					],
					"rxry": [
						39,
						27
					]
				},
				{
					"name": "p24",
					"lxly": [
						0,
						27
					],
					"rxry": [
						39,
						37
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						37
					],
					"rxry": [
						39,
						53
					]
				},
				{
					"name": "p22.3",
					"lxly": [
						0,
						53
					],
					"rxry": [
						39,
						68
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						68
					],
					"rxry": [
						39,
						72
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						73
					],
					"rxry": [
						39,
						79
					]
				},
				{
					"name": "p21.33",
					"lxly": [
						0,
						80
					],
					"rxry": [
						39,
						99
					]
				},
				{
					"name": "p21.32",
					"lxly": [
						0,
						100
					],
					"rxry": [
						39,
						105
					]
				},
				{
					"name": "p21.31",
					"lxly": [
						0,
						106
					],
					"rxry": [
						39,
						130
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						131
					],
					"rxry": [
						39,
						142
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						142
					],
					"rxry": [
						39,
						175
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						175
					],
					"rxry": [
						39,
						192
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						192
					],
					"rxry": [
						39,
						200
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						200
					],
					"rxry": [
						39,
						212
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						213,
						231
					],
					"rxry": [
						39,
						215
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						216
					],
					"rxry": [
						39,
						221
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						222
					],
					"rxry": [
						39,
						230
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						231
					],
					"rxry": [
						39,
						238
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						238
					],
					"rxry": [
						39,
						270
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						270
					],
					"rxry": [
						39,
						295
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						295
					],
					"rxry": [
						39,
						306
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						307
					],
					"rxry": [
						39,
						309
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						310
					],
					"rxry": [
						39,
						324
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						324
					],
					"rxry": [
						39,
						361
					]
				},
				{
					"name": "q16.1",
					"lxly": [
						0,
						362
					],
					"rxry": [
						39,
						378
					]
				},
				{
					"name": "q16.2",
					"lxly": [
						0,
						378
					],
					"rxry": [
						39,
						383
					]
				},
				{
					"name": "q16.3",
					"lxly": [
						0,
						384
					],
					"rxry": [
						39,
						400
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						400
					],
					"rxry": [
						39,
						440
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						441
					],
					"rxry": [
						39,
						451
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						451
					],
					"rxry": [
						39,
						459
					]
				},
				{
					"name": "q22.31",
					"lxly": [
						0,
						459
					],
					"rxry": [
						39,
						469
					]
				},
				{
					"name": "q22.32",
					"lxly": [
						0,
						470
					],
					"rxry": [
						39,
						484
					]
				},
				{
					"name": "q22.33",
					"lxly": [
						0,
						485
					],
					"rxry": [
						39,
						495
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						495
					],
					"rxry": [
						39,
						503
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						504
					],
					"rxry": [
						39,
						510
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						511
					],
					"rxry": [
						39,
						538
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						538
					],
					"rxry": [
						39,
						548
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						549
					],
					"rxry": [
						39,
						557
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						558
					],
					"rxry": [
						39,
						567
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						567
					],
					"rxry": [
						39,
						592
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						593
					],
					"rxry": [
						39,
						598
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						599
					],
					"rxry": [
						39,
						623
					]
				},
				{
					"name": "q26",
					"lxly": [
						0,
						623
					],
					"rxry": [
						39,
						634
					]
				},
				{
					"name": "q27",
					"lxly": [
						0,
						634
					],
					"rxry": [
						39,
						651
					]
				}
			]
		},
		{
			"name": "Chrom07ISCN09",
			"element": [{
					"name": "p22",
					"lxly": [
						0,
						0
					],
					"rxry": [
						39,
						19
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						19
					],
					"rxry": [
						39,
						44
					]
				},
				{
					"name": "p15.3",
					"lxly": [
						0,
						44
					],
					"rxry": [
						39,
						64
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						65
					],
					"rxry": [
						39,
						79
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						81
					],
					"rxry": [
						39,
						109
					]
				},
				{
					"name": "p14.3",
					"lxly": [
						0,
						110
					],
					"rxry": [
						39,
						118
					]
				},
				{
					"name": "p14.2",
					"lxly": [
						0,
						119
					],
					"rxry": [
						39,
						130
					]
				},
				{
					"name": "p14.1",
					"lxly": [
						0,
						131
					],
					"rxry": [
						39,
						140
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						140
					],
					"rxry": [
						39,
						175
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						176
					],
					"rxry": [
						39,
						191
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						191
					],
					"rxry": [
						39,
						200
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						201
					],
					"rxry": [
						39,
						207
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						207
					],
					"rxry": [
						39,
						215
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						216
					],
					"rxry": [
						39,
						239
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						240
					],
					"rxry": [
						39,
						254
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						255
					],
					"rxry": [
						39,
						291
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						291
					],
					"rxry": [
						39,
						323
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						323
					],
					"rxry": [
						39,
						331
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						332
					],
					"rxry": [
						39,
						351
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						350
					],
					"rxry": [
						39,
						406
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						407
					],
					"rxry": [
						39,
						415
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						416
					],
					"rxry": [
						39,
						421
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						421
					],
					"rxry": [
						39,
						434
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						434
					],
					"rxry": [
						39,
						441
					]
				},
				{
					"name": "q31.31",
					"lxly": [
						0,
						441
					],
					"rxry": [
						39,
						451
					]
				},
				{
					"name": "q31.32",
					"lxly": [
						0,
						452
					],
					"rxry": [
						39,
						455
					]
				},
				{
					"name": "q31.33",
					"lxly": [
						0,
						456
					],
					"rxry": [
						39,
						470
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						470
					],
					"rxry": [
						39,
						504
					]
				},
				{
					"name": "q33",
					"lxly": [
						0,
						503
					],
					"rxry": [
						39,
						514
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						514
					],
					"rxry": [
						39,
						539
					]
				},
				{
					"name": "q35",
					"lxly": [
						0,
						539
					],
					"rxry": [
						39,
						555
					]
				},
				{
					"name": "q36.1",
					"lxly": [
						0,
						555
					],
					"rxry": [
						39,
						568
					]
				},
				{
					"name": "q36.2",
					"lxly": [
						0,
						569
					],
					"rxry": [
						39,
						575
					]
				},
				{
					"name": "q36.3",
					"lxly": [
						0,
						575
					],
					"rxry": [
						39,
						592
					]
				}
			]
		},
		{
			"name": "Chrom08ISCN09",
			"element": [{
					"name": "p23.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						39,
						5
					]
				},
				{
					"name": "p23.2",
					"lxly": [
						0,
						6
					],
					"rxry": [
						39,
						14
					]
				},
				{
					"name": "p23.1",
					"lxly": [
						0,
						15
					],
					"rxry": [
						39,
						38
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						39
					],
					"rxry": [
						39,
						57
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						57
					],
					"rxry": [
						39,
						89
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						90
					],
					"rxry": [
						39,
						98
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						99
					],
					"rxry": [
						39,
						113
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						113
					],
					"rxry": [
						39,
						122
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						123
					],
					"rxry": [
						39,
						128
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						129
					],
					"rxry": [
						39,
						135
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						135
					],
					"rxry": [
						39,
						153
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						154
					],
					"rxry": [
						39,
						159
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						160
					],
					"rxry": [
						39,
						167
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						168
					],
					"rxry": [
						39,
						184
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						185
					],
					"rxry": [
						39,
						203
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						203
					],
					"rxry": [
						39,
						225
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						224
					],
					"rxry": [
						39,
						233
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						234
					],
					"rxry": [
						39,
						244
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						245
					],
					"rxry": [
						39,
						250
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						251
					],
					"rxry": [
						39,
						261
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						262
					],
					"rxry": [
						39,
						267
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						268
					],
					"rxry": [
						39,
						277
					]
				},
				{
					"name": "q21.11",
					"lxly": [
						0,
						277
					],
					"rxry": [
						39,
						289
					]
				},
				{
					"name": "q21.12",
					"lxly": [
						0,
						289
					],
					"rxry": [
						39,
						299
					]
				},
				{
					"name": "q21.13",
					"lxly": [
						0,
						299
					],
					"rxry": [
						39,
						308
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						308
					],
					"rxry": [
						39,
						322
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						322
					],
					"rxry": [
						39,
						337
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						337
					],
					"rxry": [
						39,
						354
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						355
					],
					"rxry": [
						39,
						363
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						364
					],
					"rxry": [
						39,
						396
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						396
					],
					"rxry": [
						39,
						403
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						403
					],
					"rxry": [
						39,
						408
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						408
					],
					"rxry": [
						39,
						427
					]
				},
				{
					"name": "q24.11",
					"lxly": [
						0,
						427
					],
					"rxry": [
						39,
						440
					]
				},
				{
					"name": "q24.12",
					"lxly": [
						0,
						441
					],
					"rxry": [
						39,
						447
					]
				},
				{
					"name": "q24.13",
					"lxly": [
						0,
						447
					],
					"rxry": [
						39,
						473
					]
				},
				{
					"name": "q24.21",
					"lxly": [
						0,
						474
					],
					"rxry": [
						39,
						481
					]
				},
				{
					"name": "q24.22",
					"lxly": [
						0,
						482
					],
					"rxry": [
						39,
						492
					]
				},
				{
					"name": "q24.23",
					"lxly": [
						0,
						491
					],
					"rxry": [
						39,
						503
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						504
					],
					"rxry": [
						39,
						523
					]
				}
			]
		},
		{
			"name": "Chrom09ISCN09",
			"element": [{
					"name": "p24.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						40,
						12
					]
				},
				{
					"name": "p24.2",
					"lxly": [
						0,
						13
					],
					"rxry": [
						40,
						18
					]
				},
				{
					"name": "p24.1",
					"lxly": [
						0,
						19
					],
					"rxry": [
						40,
						27
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						28
					],
					"rxry": [
						40,
						46
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						46
					],
					"rxry": [
						40,
						79
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						80
					],
					"rxry": [
						40,
						91
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						91
					],
					"rxry": [
						40,
						94
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						95
					],
					"rxry": [
						40,
						114
					]
				},
				{
					"name": "p13.3",
					"lxly": [
						0,
						114
					],
					"rxry": [
						40,
						132
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						133
					],
					"rxry": [
						40,
						137
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						138
					],
					"rxry": [
						40,
						150
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						151
					],
					"rxry": [
						40,
						168
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						169
					],
					"rxry": [
						40,
						164
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						165
					],
					"rxry": [
						40,
						171
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						172
					],
					"rxry": [
						40,
						178
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						179
					],
					"rxry": [
						40,
						233
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						234
					],
					"rxry": [
						40,
						246
					]
				},
				{
					"name": "q21.11",
					"lxly": [
						0,
						246
					],
					"rxry": [
						40,
						251
					]
				},
				{
					"name": "q21.12",
					"lxly": [
						0,
						252
					],
					"rxry": [
						40,
						263
					]
				},
				{
					"name": "q21.13",
					"lxly": [
						0,
						263
					],
					"rxry": [
						40,
						275
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						276
					],
					"rxry": [
						40,
						284
					]
				},
				{
					"name": "q21.31",
					"lxly": [
						0,
						285
					],
					"rxry": [
						40,
						298
					]
				},
				{
					"name": "q21.32",
					"lxly": [
						0,
						298
					],
					"rxry": [
						40,
						303
					]
				},
				{
					"name": "q21.33",
					"lxly": [
						0,
						303
					],
					"rxry": [
						40,
						316
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						317
					],
					"rxry": [
						40,
						335
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						336
					],
					"rxry": [
						40,
						341
					]
				},
				{
					"name": "q22.31",
					"lxly": [
						0,
						342
					],
					"rxry": [
						40,
						356
					]
				},
				{
					"name": "q22.32",
					"lxly": [
						0,
						357
					],
					"rxry": [
						40,
						363
					]
				},
				{
					"name": "q22.33",
					"lxly": [
						0,
						364
					],
					"rxry": [
						40,
						387
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						387
					],
					"rxry": [
						40,
						400
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						400
					],
					"rxry": [
						40,
						408
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						409
					],
					"rxry": [
						40,
						414
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						415
					],
					"rxry": [
						40,
						434
					]
				},
				{
					"name": "q33.1",
					"lxly": [
						0,
						434
					],
					"rxry": [
						40,
						447
					]
				},
				{
					"name": "q33.2",
					"lxly": [
						0,
						447
					],
					"rxry": [
						40,
						452
					]
				},
				{
					"name": "q33.3",
					"lxly": [
						0,
						452
					],
					"rxry": [
						40,
						459
					]
				},
				{
					"name": "q34.1",
					"lxly": [
						0,
						459
					],
					"rxry": [
						40,
						485
					]
				},
				{
					"name": "q34.2",
					"lxly": [
						0,
						485
					],
					"rxry": [
						40,
						490
					]
				},
				{
					"name": "q34.3",
					"lxly": [
						0,
						491
					],
					"rxry": [
						40,
						521
					]
				}
			]
		},
		{
			"name": "Chrom10ISCN09",
			"element": [{
					"name": "p15.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						40,
						6
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						7
					],
					"rxry": [
						40,
						13
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						14
					],
					"rxry": [
						40,
						35
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						34
					],
					"rxry": [
						40,
						40
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						41
					],
					"rxry": [
						40,
						75
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						75
					],
					"rxry": [
						40,
						88
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						88
					],
					"rxry": [
						40,
						94
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						94
					],
					"rxry": [
						40,
						103
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						103
					],
					"rxry": [
						40,
						136
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						137
					],
					"rxry": [
						40,
						142
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						143
					],
					"rxry": [
						40,
						158
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						159
					],
					"rxry": [
						40,
						164
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						165
					],
					"rxry": [
						40,
						171
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						172
					],
					"rxry": [
						40,
						187
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						188
					],
					"rxry": [
						40,
						190
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						191
					],
					"rxry": [
						40,
						216
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						216
					],
					"rxry": [
						40,
						234
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						234
					],
					"rxry": [
						40,
						245
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						245
					],
					"rxry": [
						40,
						261
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						262
					],
					"rxry": [
						40,
						288
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						289
					],
					"rxry": [
						40,
						294
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						295
					],
					"rxry": [
						40,
						310
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						310
					],
					"rxry": [
						40,
						325
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						325
					],
					"rxry": [
						40,
						336
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						337
					],
					"rxry": [
						40,
						352
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						352
					],
					"rxry": [
						40,
						361
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						362
					],
					"rxry": [
						40,
						367
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						368
					],
					"rxry": [
						40,
						397
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						397
					],
					"rxry": [
						40,
						411
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						412
					],
					"rxry": [
						40,
						427
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						427
					],
					"rxry": [
						40,
						437
					]
				},
				{
					"name": "q26.1",
					"lxly": [
						0,
						437
					],
					"rxry": [
						40,
						484
					]
				},
				{
					"name": "q26.2",
					"lxly": [
						0,
						485
					],
					"rxry": [
						40,
						494
					]
				},
				{
					"name": "q26.3",
					"lxly": [
						0,
						493
					],
					"rxry": [
						40,
						508
					]
				}
			]
		},
		{
			"name": "Chrom11ISCN09",
			"element": [{
					"name": "p15.5",
					"lxly": [
						0,
						0
					],
					"rxry": [
						40,
						22
					]
				},
				{
					"name": "p15.4",
					"lxly": [
						0,
						22
					],
					"rxry": [
						40,
						31
					]
				},
				{
					"name": "p15.3",
					"lxly": [
						0,
						32
					],
					"rxry": [
						40,
						48
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						49
					],
					"rxry": [
						40,
						53
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						54
					],
					"rxry": [
						40,
						85
					]
				},
				{
					"name": "p14.3",
					"lxly": [
						0,
						85
					],
					"rxry": [
						40,
						108
					]
				},
				{
					"name": "p14.2",
					"lxly": [
						0,
						108
					],
					"rxry": [
						40,
						113
					]
				},
				{
					"name": "p14.1",
					"lxly": [
						0,
						113
					],
					"rxry": [
						40,
						119
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						120
					],
					"rxry": [
						40,
						139
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						139
					],
					"rxry": [
						40,
						153
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						154
					],
					"rxry": [
						40,
						186
					]
				},
				{
					"name": "p11.12",
					"lxly": [
						0,
						186
					],
					"rxry": [
						40,
						196
					]
				},
				{
					"name": "p11.11",
					"lxly": [
						0,
						197
					],
					"rxry": [
						40,
						203
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						203
					],
					"rxry": [
						40,
						211
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						212
					],
					"rxry": [
						40,
						228
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						228
					],
					"rxry": [
						40,
						269
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						270
					],
					"rxry": [
						40,
						275
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						275
					],
					"rxry": [
						40,
						306
					]
				},
				{
					"name": "q13.4",
					"lxly": [
						0,
						307
					],
					"rxry": [
						40,
						311
					]
				},
				{
					"name": "q13.5",
					"lxly": [
						0,
						312
					],
					"rxry": [
						40,
						331
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						331
					],
					"rxry": [
						40,
						352
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						352
					],
					"rxry": [
						40,
						357
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						357
					],
					"rxry": [
						40,
						371
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						372
					],
					"rxry": [
						40,
						385
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						385
					],
					"rxry": [
						40,
						400
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						400
					],
					"rxry": [
						40,
						405
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						405
					],
					"rxry": [
						40,
						420
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						420
					],
					"rxry": [
						40,
						433
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						434
					],
					"rxry": [
						40,
						439
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						440
					],
					"rxry": [
						40,
						469
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						468
					],
					"rxry": [
						40,
						477
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						478
					],
					"rxry": [
						40,
						489
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						490
					],
					"rxry": [
						40,
						498
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						498
					],
					"rxry": [
						40,
						510
					]
				}
			]
		},
		{
			"name": "Chrom12ISCN09",
			"element": [{
					"name": "p13.33",
					"lxly": [
						0,
						0
					],
					"rxry": [
						40,
						5
					]
				},
				{
					"name": "p13.32",
					"lxly": [
						0,
						6
					],
					"rxry": [
						40,
						11
					]
				},
				{
					"name": "p13.31",
					"lxly": [
						0,
						12
					],
					"rxry": [
						40,
						36
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						37
					],
					"rxry": [
						40,
						42
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						43
					],
					"rxry": [
						40,
						58
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						58
					],
					"rxry": [
						40,
						70
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						71
					],
					"rxry": [
						40,
						73
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						74
					],
					"rxry": [
						40,
						84
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						84
					],
					"rxry": [
						40,
						98
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						99
					],
					"rxry": [
						40,
						107
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						108
					],
					"rxry": [
						40,
						121
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						122
					],
					"rxry": [
						40,
						128
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						129
					],
					"rxry": [
						40,
						136
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						137
					],
					"rxry": [
						40,
						152
					]
				},
				{
					"name": "q13.11",
					"lxly": [
						0,
						153
					],
					"rxry": [
						40,
						168
					]
				},
				{
					"name": "q13.12",
					"lxly": [
						0,
						169
					],
					"rxry": [
						40,
						174
					]
				},
				{
					"name": "q13.13",
					"lxly": [
						0,
						175
					],
					"rxry": [
						40,
						196
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						197
					],
					"rxry": [
						40,
						203
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						203
					],
					"rxry": [
						40,
						221
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						221
					],
					"rxry": [
						40,
						234
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						234
					],
					"rxry": [
						40,
						243
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						244
					],
					"rxry": [
						40,
						249
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						250
					],
					"rxry": [
						40,
						267
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						267
					],
					"rxry": [
						40,
						280
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						281
					],
					"rxry": [
						40,
						292
					]
				},
				{
					"name": "q21.31",
					"lxly": [
						0,
						292
					],
					"rxry": [
						40,
						305
					]
				},
				{
					"name": "q21.32",
					"lxly": [
						0,
						306
					],
					"rxry": [
						40,
						307
					]
				},
				{
					"name": "q21.33",
					"lxly": [
						0,
						308
					],
					"rxry": [
						40,
						324
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						324
					],
					"rxry": [
						40,
						345
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						345
					],
					"rxry": [
						40,
						358
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						358
					],
					"rxry": [
						40,
						363
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						363
					],
					"rxry": [
						40,
						373
					]
				},
				{
					"name": "q24.11",
					"lxly": [
						0,
						373
					],
					"rxry": [
						40,
						400
					]
				},
				{
					"name": "q24.12",
					"lxly": [
						0,
						401
					],
					"rxry": [
						40,
						406
					]
				},
				{
					"name": "q24.13",
					"lxly": [
						0,
						407
					],
					"rxry": [
						40,
						428
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						429
					],
					"rxry": [
						40,
						440
					]
				},
				{
					"name": "q24.31",
					"lxly": [
						0,
						441
					],
					"rxry": [
						40,
						468
					]
				},
				{
					"name": "q24.32",
					"lxly": [
						0,
						468
					],
					"rxry": [
						40,
						478
					]
				},
				{
					"name": "q24.33",
					"lxly": [
						0,
						478
					],
					"rxry": [
						40,
						493
					]
				}
			]
		},
		{
			"name": "Chrom13ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						39,
						18
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						19
					],
					"rxry": [
						39,
						30
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						31
					],
					"rxry": [
						39,
						52
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						53
					],
					"rxry": [
						39,
						60
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						61
					],
					"rxry": [
						39,
						69
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						70
					],
					"rxry": [
						39,
						92
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						93
					],
					"rxry": [
						39,
						99
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						99
					],
					"rxry": [
						39,
						123
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						125
					],
					"rxry": [
						39,
						137
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						137
					],
					"rxry": [
						39,
						161
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						161
					],
					"rxry": [
						39,
						167
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						168
					],
					"rxry": [
						39,
						192
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						192
					],
					"rxry": [
						39,
						205
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						205
					],
					"rxry": [
						39,
						211
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						211
					],
					"rxry": [
						39,
						236
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						236
					],
					"rxry": [
						39,
						260
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						261
					],
					"rxry": [
						39,
						266
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						267
					],
					"rxry": [
						39,
						283
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						284
					],
					"rxry": [
						39,
						309
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						309
					],
					"rxry": [
						39,
						321
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						322
					],
					"rxry": [
						39,
						328
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						329
					],
					"rxry": [
						39,
						365
					]
				},
				{
					"name": "q33",
					"lxly": [
						0,
						365
					],
					"rxry": [
						39,
						391
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						392
					],
					"rxry": [
						39,
						415
					]
				}
			]
		},
		{
			"name": "Chrom14ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						40,
						20
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						21
					],
					"rxry": [
						40,
						31
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						32
					],
					"rxry": [
						40,
						53
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						54
					],
					"rxry": [
						40,
						60
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						61
					],
					"rxry": [
						40,
						69
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						70
					],
					"rxry": [
						40,
						104
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						106
					],
					"rxry": [
						40,
						124
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						124
					],
					"rxry": [
						40,
						143
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						143
					],
					"rxry": [
						40,
						172
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						172
					],
					"rxry": [
						40,
						183
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						184
					],
					"rxry": [
						40,
						187
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						188
					],
					"rxry": [
						40,
						209
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						210
					],
					"rxry": [
						40,
						217
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						218
					],
					"rxry": [
						40,
						221
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						222
					],
					"rxry": [
						40,
						234
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						233
					],
					"rxry": [
						40,
						253
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						254
					],
					"rxry": [
						40,
						264
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						263
					],
					"rxry": [
						40,
						300
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						301
					],
					"rxry": [
						40,
						326
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						326
					],
					"rxry": [
						40,
						348
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						349
					],
					"rxry": [
						40,
						357
					]
				},
				{
					"name": "q32.31",
					"lxly": [
						0,
						358
					],
					"rxry": [
						40,
						367
					]
				},
				{
					"name": "q32.32",
					"lxly": [
						0,
						368
					],
					"rxry": [
						40,
						375
					]
				},
				{
					"name": "q32.33",
					"lxly": [
						0,
						377
					],
					"rxry": [
						40,
						400
					]
				}
			]
		},
		{
			"name": "Chrom15ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						41,
						32
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						21
					],
					"rxry": [
						41,
						32
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						33
					],
					"rxry": [
						41,
						50
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						51
					],
					"rxry": [
						41,
						61
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						62
					],
					"rxry": [
						41,
						71
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						72
					],
					"rxry": [
						41,
						100
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						100
					],
					"rxry": [
						41,
						105
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						105
					],
					"rxry": [
						41,
						134
					]
				},
				{
					"name": "q14",
					"lxly": [
						0,
						134
					],
					"rxry": [
						41,
						144
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						145
					],
					"rxry": [
						41,
						178
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						178
					],
					"rxry": [
						41,
						191
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						192
					],
					"rxry": [
						41,
						197
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						198
					],
					"rxry": [
						41,
						210
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						211
					],
					"rxry": [
						41,
						221
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						221
					],
					"rxry": [
						41,
						227
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						228
					],
					"rxry": [
						41,
						254
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						254
					],
					"rxry": [
						41,
						265
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						265
					],
					"rxry": [
						41,
						297
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						298
					],
					"rxry": [
						41,
						304
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						305
					],
					"rxry": [
						41,
						314
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						314
					],
					"rxry": [
						41,
						328
					]
				},
				{
					"name": "q26.1",
					"lxly": [
						0,
						329
					],
					"rxry": [
						41,
						350
					]
				},
				{
					"name": "q26.2",
					"lxly": [
						0,
						351
					],
					"rxry": [
						41,
						364
					]
				},
				{
					"name": "q26.3",
					"lxly": [
						0,
						364
					],
					"rxry": [
						41,
						379
					]
				}
			]
		},
		{
			"name": "Chrom16ISCN09",
			"element": [{
					"name": "p13.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						40,
						27
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						28
					],
					"rxry": [
						40,
						42
					]
				},
				{
					"name": "p13.13",
					"lxly": [
						0,
						43
					],
					"rxry": [
						40,
						53
					]
				},
				{
					"name": "p13.12",
					"lxly": [
						0,
						54
					],
					"rxry": [
						40,
						57
					]
				},
				{
					"name": "p13.11",
					"lxly": [
						0,
						58
					],
					"rxry": [
						40,
						76
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						77
					],
					"rxry": [
						40,
						85
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						86
					],
					"rxry": [
						40,
						94
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						95
					],
					"rxry": [
						40,
						103
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						104
					],
					"rxry": [
						40,
						138
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						139
					],
					"rxry": [
						40,
						145
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						146
					],
					"rxry": [
						40,
						152
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						153
					],
					"rxry": [
						40,
						191
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						192
					],
					"rxry": [
						40,
						216
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						217
					],
					"rxry": [
						40,
						222
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						223
					],
					"rxry": [
						40,
						247
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						247
					],
					"rxry": [
						40,
						266
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						266
					],
					"rxry": [
						40,
						289
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						290
					],
					"rxry": [
						40,
						301
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						302
					],
					"rxry": [
						40,
						304
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						304
					],
					"rxry": [
						40,
						317
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						318
					],
					"rxry": [
						40,
						350
					]
				}
			]
		},
		{
			"name": "Chrom17ISCN09",
			"element": [{
					"name": "p13.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						40,
						18
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						19
					],
					"rxry": [
						40,
						24
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						25
					],
					"rxry": [
						40,
						61
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						61
					],
					"rxry": [
						40,
						74
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						75
					],
					"rxry": [
						40,
						109
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						110
					],
					"rxry": [
						40,
						115
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						115
					],
					"rxry": [
						40,
						123
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						124
					],
					"rxry": [
						40,
						143
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						144
					],
					"rxry": [
						40,
						153
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						154
					],
					"rxry": [
						40,
						166
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						167
					],
					"rxry": [
						40,
						172
					]
				},
				{
					"name": "q21.31",
					"lxly": [
						0,
						173
					],
					"rxry": [
						40,
						210
					]
				},
				{
					"name": "q21.32",
					"lxly": [
						0,
						211
					],
					"rxry": [
						40,
						216
					]
				},
				{
					"name": "q21.33",
					"lxly": [
						0,
						217
					],
					"rxry": [
						40,
						238
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						238
					],
					"rxry": [
						40,
						254
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						254
					],
					"rxry": [
						40,
						263
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						263
					],
					"rxry": [
						40,
						270
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						270
					],
					"rxry": [
						40,
						278
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						279
					],
					"rxry": [
						40,
						291
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						292
					],
					"rxry": [
						40,
						316
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						317
					],
					"rxry": [
						40,
						321
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						322
					],
					"rxry": [
						40,
						340
					]
				}
			]
		},
		{
			"name": "Chrom18ISCN09",
			"element": [{
					"name": "p11.32",
					"lxly": [
						0,
						0
					],
					"rxry": [
						41,
						4
					]
				},
				{
					"name": "p11.31",
					"lxly": [
						0,
						5
					],
					"rxry": [
						41,
						24
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						24
					],
					"rxry": [
						41,
						73
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						74
					],
					"rxry": [
						41,
						81
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						82
					],
					"rxry": [
						41,
						89
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						90
					],
					"rxry": [
						41,
						116
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						116
					],
					"rxry": [
						41,
						134
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						134
					],
					"rxry": [
						41,
						145
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						145
					],
					"rxry": [
						41,
						163
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						163
					],
					"rxry": [
						41,
						212
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						211
					],
					"rxry": [
						41,
						225
					]
				},
				{
					"name": "q21.31",
					"lxly": [
						0,
						226
					],
					"rxry": [
						41,
						239
					]
				},
				{
					"name": "q21.32",
					"lxly": [
						0,
						240
					],
					"rxry": [
						41,
						245
					]
				},
				{
					"name": "q21.33",
					"lxly": [
						0,
						246
					],
					"rxry": [
						41,
						259
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						259
					],
					"rxry": [
						41,
						277
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						277
					],
					"rxry": [
						41,
						304
					]
				}
			]
		},
		{
			"name": "Chrom19ISCN09",
			"element": [{
					"name": "p13.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						38,
						44
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						45
					],
					"rxry": [
						38,
						54
					]
				},
				{
					"name": "p13.13",
					"lxly": [
						0,
						55
					],
					"rxry": [
						38,
						61
					]
				},
				{
					"name": "p13.12",
					"lxly": [
						0,
						61
					],
					"rxry": [
						38,
						71
					]
				},
				{
					"name": "p13.11",
					"lxly": [
						0,
						71
					],
					"rxry": [
						38,
						101
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						102
					],
					"rxry": [
						38,
						110
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						111
					],
					"rxry": [
						38,
						118
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						119
					],
					"rxry": [
						38,
						127
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						128
					],
					"rxry": [
						38,
						139
					]
				},
				{
					"name": "q13.11",
					"lxly": [
						0,
						140
					],
					"rxry": [
						38,
						149
					]
				},
				{
					"name": "q13.12",
					"lxly": [
						0,
						150
					],
					"rxry": [
						38,
						155
					]
				},
				{
					"name": "q13.13",
					"lxly": [
						0,
						156
					],
					"rxry": [
						38,
						175
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						176
					],
					"rxry": [
						38,
						185
					]
				},
				{
					"name": "q13.31",
					"lxly": [
						0,
						186
					],
					"rxry": [
						38,
						195
					]
				},
				{
					"name": "q13.32",
					"lxly": [
						0,
						195
					],
					"rxry": [
						38,
						204
					]
				},
				{
					"name": "q13.33",
					"lxly": [
						0,
						205
					],
					"rxry": [
						38,
						240
					]
				},
				{
					"name": "q13.41",
					"lxly": [
						0,
						241
					],
					"rxry": [
						38,
						250
					]
				},
				{
					"name": "q13.42",
					"lxly": [
						0,
						251
					],
					"rxry": [
						38,
						263
					]
				},
				{
					"name": "q13.43",
					"lxly": [
						0,
						264
					],
					"rxry": [
						38,
						268
					]
				}
			]
		},
		{
			"name": "Chrom20ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						39,
						20
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						21
					],
					"rxry": [
						39,
						28
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						29
					],
					"rxry": [
						39,
						32
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						33
					],
					"rxry": [
						39,
						43
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						43
					],
					"rxry": [
						39,
						77
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						78
					],
					"rxry": [
						39,
						83
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						84
					],
					"rxry": [
						39,
						106
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						107
					],
					"rxry": [
						39,
						112
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						113
					],
					"rxry": [
						39,
						120
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						121
					],
					"rxry": [
						39,
						147
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						148
					],
					"rxry": [
						39,
						150
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						151
					],
					"rxry": [
						39,
						167
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						167
					],
					"rxry": [
						39,
						173
					]
				},
				{
					"name": "q13.11",
					"lxly": [
						0,
						174
					],
					"rxry": [
						39,
						178
					]
				},
				{
					"name": "q13.12",
					"lxly": [
						0,
						179
					],
					"rxry": [
						39,
						187
					]
				},
				{
					"name": "q13.13",
					"lxly": [
						0,
						188
					],
					"rxry": [
						39,
						214
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						215
					],
					"rxry": [
						39,
						234
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						234
					],
					"rxry": [
						39,
						256
					]
				}
			]
		},
		{
			"name": "Chrom21ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						41,
						20
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						22
					],
					"rxry": [
						41,
						31
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						32
					],
					"rxry": [
						41,
						52
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						53
					],
					"rxry": [
						41,
						63
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						64
					],
					"rxry": [
						41,
						72
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						73
					],
					"rxry": [
						41,
						88
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						88
					],
					"rxry": [
						41,
						110
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						112
					],
					"rxry": [
						41,
						117
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						118
					],
					"rxry": [
						41,
						125
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						125
					],
					"rxry": [
						41,
						156
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						155
					],
					"rxry": [
						41,
						170
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						170
					],
					"rxry": [
						41,
						197
					]
				}
			]
		},
		{
			"name": "Chrom22ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						39,
						20
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						21
					],
					"rxry": [
						39,
						32
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						33
					],
					"rxry": [
						39,
						53
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						54
					],
					"rxry": [
						39,
						61
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						62
					],
					"rxry": [
						39,
						69
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						70
					],
					"rxry": [
						39,
						107
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						108
					],
					"rxry": [
						39,
						110
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						113
					],
					"rxry": [
						39,
						123
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						124
					],
					"rxry": [
						39,
						129
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						130
					],
					"rxry": [
						39,
						137
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						138
					],
					"rxry": [
						39,
						147
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						147
					],
					"rxry": [
						39,
						175
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						176
					],
					"rxry": [
						39,
						181
					]
				},
				{
					"name": "q13.31",
					"lxly": [
						0,
						182
					],
					"rxry": [
						39,
						205
					]
				},
				{
					"name": "q13.32",
					"lxly": [
						0,
						206
					],
					"rxry": [
						39,
						208
					]
				},
				{
					"name": "q13.33",
					"lxly": [
						0,
						209
					],
					"rxry": [
						39,
						212
					]
				}
			]
		},
		{
			"name": "ChromXISCN09",
			"element": [{
					"name": "p22.33",
					"lxly": [
						0,
						0
					],
					"rxry": [
						39,
						13
					]
				},
				{
					"name": "p22.32",
					"lxly": [
						0,
						13
					],
					"rxry": [
						39,
						25
					]
				},
				{
					"name": "p22.31",
					"lxly": [
						0,
						25
					],
					"rxry": [
						39,
						36
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						36
					],
					"rxry": [
						39,
						46
					]
				},
				{
					"name": "p22.13",
					"lxly": [
						0,
						46
					],
					"rxry": [
						39,
						72
					]
				},
				{
					"name": "p22.12",
					"lxly": [
						0,
						72
					],
					"rxry": [
						39,
						77
					]
				},
				{
					"name": "p22.11",
					"lxly": [
						0,
						78
					],
					"rxry": [
						39,
						86
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						86
					],
					"rxry": [
						39,
						107
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						107
					],
					"rxry": [
						39,
						113
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						113
					],
					"rxry": [
						39,
						134
					]
				},
				{
					"name": "p11.4",
					"lxly": [
						0,
						134
					],
					"rxry": [
						39,
						160
					]
				},
				{
					"name": "p11.3",
					"lxly": [
						0,
						161
					],
					"rxry": [
						39,
						168
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						169
					],
					"rxry": [
						39,
						193
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						194
					],
					"rxry": [
						39,
						202
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						203
					],
					"rxry": [
						39,
						209
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						210
					],
					"rxry": [
						39,
						215
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						216
					],
					"rxry": [
						39,
						222
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						223
					],
					"rxry": [
						39,
						228
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						228
					],
					"rxry": [
						39,
						240
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						240
					],
					"rxry": [
						39,
						283
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						284
					],
					"rxry": [
						39,
						295
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						296
					],
					"rxry": [
						39,
						307
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						307
					],
					"rxry": [
						39,
						325
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						325
					],
					"rxry": [
						39,
						336
					]
				},
				{
					"name": "q21.31",
					"lxly": [
						0,
						336
					],
					"rxry": [
						39,
						351
					]
				},
				{
					"name": "q21.32",
					"lxly": [
						0,
						351
					],
					"rxry": [
						39,
						357
					]
				},
				{
					"name": "q21.33",
					"lxly": [
						0,
						357
					],
					"rxry": [
						39,
						375
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						375
					],
					"rxry": [
						39,
						412
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						413
					],
					"rxry": [
						39,
						421
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						422
					],
					"rxry": [
						39,
						445
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						446
					],
					"rxry": [
						39,
						459
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						460
					],
					"rxry": [
						39,
						480
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						480
					],
					"rxry": [
						39,
						495
					]
				},
				{
					"name": "q26.1",
					"lxly": [
						0,
						495
					],
					"rxry": [
						39,
						530
					]
				},
				{
					"name": "q26.2",
					"lxly": [
						0,
						531
					],
					"rxry": [
						39,
						536
					]
				},
				{
					"name": "q26.3",
					"lxly": [
						0,
						537
					],
					"rxry": [
						39,
						565
					]
				},
				{
					"name": "q27",
					"lxly": [
						0,
						566
					],
					"rxry": [
						39,
						580
					]
				},
				{
					"name": "q28",
					"lxly": [
						0,
						581
					],
					"rxry": [
						39,
						600
					]
				}
			]
		},
		{
			"name": "ChromYISCN09",
			"element": [{
					"name": "p11.32",
					"lxly": [
						0,
						0
					],
					"rxry": [
						46,
						8
					]
				},
				{
					"name": "p11.31",
					"lxly": [
						0,
						9
					],
					"rxry": [
						46,
						23
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						24
					],
					"rxry": [
						46,
						60
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						61
					],
					"rxry": [
						46,
						68
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						69
					],
					"rxry": [
						46,
						77
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						78
					],
					"rxry": [
						46,
						87
					]
				},
				{
					"name": "q11.221",
					"lxly": [
						0,
						87
					],
					"rxry": [
						46,
						101
					]
				},
				{
					"name": "q11.222",
					"lxly": [
						0,
						101
					],
					"rxry": [
						46,
						108
					]
				},
				{
					"name": "q11.223",
					"lxly": [
						0,
						108
					],
					"rxry": [
						46,
						122
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						122
					],
					"rxry": [
						46,
						139
					]
				},

				{
					"name": "q12",
					"lxly": [
						0,
						140
					],
					"rxry": [
						46,
						208
					]
				}
			]
		}
	]
}
