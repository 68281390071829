<template>
    <div class="organManage">
        <CompleteAgencyCertification></CompleteAgencyCertification>
    </div>
</template>

<script>
import CompleteAgencyCertification from "./completeAgencyCertification";
export default {
  name: "organManage",
  data() {
    return {
      activeName: "first",
      isSuccess: null,
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    parentFun(payload) {
      this.isSuccess = payload;
    },
  },
  created() {
    // this.$api.getQueryUserByToken({}).then((res) => {
    //   if (res.code == 200) {
    //     const { user } = res.data;
    //     //0：初始化  1：通过  2：不通过  3：审核中
    //     if (user.isExamine == 0 || user.isExamine == 2) {
    //       this.isSuccess = 0;
    //     } else if (user.isExamine == 1 && user.isExpert == 1) {
    //       this.isSuccess = 2;
    //     } else if (user.isExamine == 1 && user.isMechanism == 1) {
    //       this.isSuccess = 3;
    //     } else if (user.isExamine == 3) {
    //       this.isSuccess = 1;
    //     }
    //   } else {
    //     this.$router.push({
    //       path: "/",
    //     });
    //     this.$message.error(res.message);
    //   }
    // });
  },
  components: {
    CompleteAgencyCertification,
  },
};
</script>

<style lang="scss" scoped>
.organManage {
  margin-top: 19px;
  margin-left: 30px;
}
</style>
