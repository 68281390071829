/**
 * 选择
 */
function select(sameContrast){
	const condition = {
		getStatus : ()=>{
			return this.toolsType === 'select'
		}
	}
}
export default select