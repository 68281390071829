<template>
  <div class="examineBox">
    <el-dialog :title="$t('popup.examine.consult_ques_desc')" :visible.sync="newDialogVisible" width="670px" :before-close="closeFun" :close-on-click-modal="false" :modal = false>
      <el-form ref="ruleForm" :model="ruleForm">
        <!-- 提交专家会诊 -->
        <div class="submit_expert" v-if="!readonlyProblem">
          <p class="choose_wrds" v-if="expertList.length != 1">{{$t("popup.examine.choose_expert")}}</p>
          <el-radio-group class="chose_expert" v-model="expertId" v-if="expertList.length != 1">
            <el-radio v-for="(item, index) in expertList" :key="index" :label="item.expertId"><span class="info">{{item.expertName}}</span>{{item.hospital}}</el-radio>
          </el-radio-group>
          <div class="kug">
            <el-upload class="upload-demo" action="#" :http-request="uploadImg" :limit="10" accept=".pdf, .png, .jpg" :on-remove="handleRemove" :multiple="true" :file-list="fileList">
              <el-button type="text" class="add-btn"> + {{$t("main.adjunct")}}</el-button>
            </el-upload>
            <p class="choose_wrds">{{$t('popup.examine.ques_desc')}}</p>
            <div class="input-kug">
              <el-input type="textarea" v-model="problem" :readonly="readonlyProblem" maxlength="200" :placeholder="$t('popup.examine.ques_desc_words')"></el-input>
              <div class="files">
                <!-- 进度条 -->
                <el-progress :percentage="percentage" v-if="isPercentage"></el-progress>
              </div>
            </div>
          </div>
          <el-form-item :label="$t('popup.examine.show_case')" prop="problem">
            <el-radio-group v-model="showPatient">
              <el-radio :label="1">{{$t('popup.examine.yes')}}</el-radio>
              <el-radio :label="0">{{$t('popup.examine.no')}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <!-- 查看问题描述 -->
        <div class="check_problem" v-if="readonlyProblem">
          <p class="choose_wrds">{{$t('popup.examine.problem_describe')}}</p>
          <ul class="files-list">
            <li class="file-li" v-for="(item, index) in problemData" :key="index">
              <p class="wrds" v-if="item.fileType === 0">{{item.fileUrl}}</p>
              <AudioPlay :src="item.fileUrl" v-if="item.fileType === 3"></AudioPlay>
              <span class="attachment-icon" v-if="item.fileType === 2 || item.fileType === 1" :key="index" :title="item.name" @click="previewAttachment(item)">{{item.name}}</span>
            </li>
          </ul>
        </div>

        <!-- 会诊解答 -->
        <el-form-item :label="$t('popup.examine.consult_answer')" prop="content" v-if="isShowContent && !readonlyContent">
          <el-input type="textarea" v-model="content" :readonly="readonlyContent" maxlength="500" :placeholder="$t('popup.examine.expert_answer_words')"></el-input>
        </el-form-item>
        <el-form-item label="">
          <div class="btnBox">
            <div class="btn updateBtn cancel" @click="cancel" v-if="isCancel">{{$t("btns.cancel")}}</div>
            <div class="btn" :class="[btnStyle ? 'determineNomal' : 'disableAddReport']" @click="onSubmit('ruleForm')" v-if="isOnSubmit">{{$t("popup.examine.submit")}}</div>
          </div>
        </el-form-item>
      </el-form>

      <!--  会诊解答查看 -->
      <div class="check_problem" v-if="isShowContent && readonlyContent">
        <p class="choose_wrds">{{$t('popup.examine.expert_reply')}}</p>
        <ul class="files-list reply">
          <li class="file-li" v-for="(item, index) in contentData" :key="index">
            <p class="wrds" v-if="item.fileType === 0">{{item.fileUrl}}</p>
            <AudioPlay :src="item.fileUrl" v-if="item.fileType === 3"></AudioPlay>
            <span v-if="item.fileType === 2 || item.fileType === 1" @click="previewAttachment(item)">{{item.fileUrl}}</span>
          </li>
        </ul>
      </div>
    </el-dialog>
    <!-- 查看pdf -->
    <!-- <el-dialog :visible.sync="dialogPdfVisible" width="684px">
      <pdf ref="pdf" :src="previewPdfUrl"></pdf>
    </el-dialog> -->
    <el-dialog :visible.sync="dialogPdfVisible" width="684px" class="pdf-dialog">
      <pdf ref="pdf" :src="previewPdfUrl" :page="currentPage" @num-pages="pageCount=$event" @page-loaded="currentPage=$event" @progress="loadedRatio = $event"></pdf>
      <i class="el-icon-arrow-up" @click="pdfPageUp" v-if="currentPage > 1"></i>
      <i class="el-icon-arrow-down" @click="pdfPageDown" v-if="currentPage < pageCount"></i>
    </el-dialog>
  </div>
</template>

<script>
import "../../assets/scss/button.scss";
import "../../assets/scss/dialog.scss";
import AudioPlay from "./audioPlay.vue"
import uploader from "../../assets/utils/fileUploader.js";
import pdf from 'vue-pdf'
export default {
  name: "Examine",
  props: ["dialogVisible", "patientId", "expertStatus"],
  components: {
    AudioPlay,
    pdf
  },
  data() {
    // var validateProblem = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error(this.$t("popup.examine.input_ques_desc_words")));
    //   } else if (value.length < 10) {
    //     callback(new Error(this.$t("popup.examine.input_words_demand")));
    //   } else {
    //     callback();
    //   }
    // };
    // var validateContent = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error(this.$t("popup.examine.input_expert_answer_words")));
    //   } else if (value.length < 10) {
    //     callback(new Error(this.$t("popup.examine.input_words_demand")));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      newDialogVisible: false, // 弹窗显示状态
      // isNewExpert: "", // 1 是未验证 2 是已验证
      isOfficial: "", // 1：官方审核 0:不是官方
      readonlyProblem: false, // 会诊问题是否可编辑
      readonlyContent: false, // 专家建议是否可编辑
      isShowContent: false, // 是否显示专家输入框
      // submitText: this.$t("popup.examine.submit"),
      isReject: false, // 是否显示退回按钮
      isCancel: false, // 是否显示取消按钮
      isOnSubmit: false, // 是否显示提交按钮
      submitType: "", // 提交按钮文案
      btnStyle: true, // 提交按钮样式
      ruleForm: {
        patientId: "", // 病例id
        problem: "", // 问题描述
        content: "", // 会诊解答
      },
      // rules: {
      //   content: [
      //     {
      //       validator: validateContent,
      //       trigger: "change",
      //     },
      //   ],
      // },
      audioSrc: require("@/assets/music/new_Boy.mp3"),
      expertId: "",//专家
      expertList: [],
      showPatient: 1,
      // filesList: [],
      // manager: {},
      fileList: [],
      problem: "",
      content: "",
      dataList: [],
      problemData: [],
      contentData: [],
      dialogPdfVisible: false,
      previewPdfUrl: '',
      isPercentage: false,
      percentage: 0,
      currentPage: 1,
      pageCount: 0,
    };
  },
  created() {

  },
  methods: {
    // 获取专家
    expertInfoList() {
      this.$api.expertList({}).then((res) => {
        if (res.code == 200) {
          this.expertList = res.data;
          this.expertId = this.expertList[0].expertId;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    pdfPageUp() {
      this.currentPage > 1 && this.currentPage--;

      console.log(this.currentPage, this.pageCount);
    },
    pdfPageDown() {
      this.currentPage < this.pageCount && this.currentPage++;
    },
    // 查看文档
    previewAttachment(item) {
      if (item.format === 'pdf') {
        this.dialogPdfVisible = true;
        this.pageCount = 0;
        this.currentPage = 1;
        this.previewPdfUrl = item.fileUrl;
      } else if (['jpg', 'png'].includes(item.format)) {
        window.open(item.fileUrl, "_blank")
      } else {
        this.previewFile(item.fileUrl);
      }
    },
    previewFile(fileUrl) {
      let url = encodeURIComponent(fileUrl)
      let officeUrl = 'http://view.officeapps.live.com/op/view.aspx?src=' + url
      window.open(officeUrl, '_target')
    },
    /**
   * @method: 上传文件
   * @Date: 2020-12-22 10:11:46
   */
    uploadImg(e) {
      const _file = e.file;
      _file.israndom = true;
      if (!_file) return;
      let name = _file.name.split(".");
      !!name.length && name.pop();
      const isLt10M = _file.size / 1024 / 1024 < 10;
      let formats = ["png", "jpg", "jpe", "jpeg", "pdf"];
      let format = _file.name.split(".").pop().toLowerCase();
      if (isLt10M && formats.indexOf(format) > -1) {
        let setPercentage;
        this.isPercentage = true;
        setPercentage = setInterval(() => {
          this.percentage += 5;
          if (this.percentage >= 99) {
            this.percentage = 99;
          }
        }, 200);
        this.$forceUpdate();
        uploader({
          files: [_file],
          path: "attachment/",
          processHandle: () => { },
          ossUploadAccess: this.$store.state.ossUploadAccess,
        }).then((file) => {
          const fileUrl = file[0].path;
          this.fileList.push({ name: file[0].file.name, url: fileUrl });
          // let index = this.fileList.map(item => item.name).indexOf(file[0].file.name);
          // index > -1 ? this.fileList.push({ name: file[0].file.name + "_1", url: fileUrl }) : this.fileList.push({ name: file[0].file.name, url: fileUrl });
          let obj = {
            type: 2,
            content: fileUrl
          }
          if (fileUrl.indexOf(".png") > -1 || fileUrl.indexOf(".bmp") > -1 || fileUrl.indexOf(".jpg") > -1 || fileUrl.indexOf(".jpe") > -1 || fileUrl.indexOf(".jpeg") > -1) {
            obj.type = 1
          }
          this.dataList.push(obj);
          this.isPercentage = false;
          this.percentage = 0;
          clearInterval(setPercentage);
          setPercentage = null;
        });
      } else {
        this.$message.error(
          this.$t('popup.examine.file_type_error')
        );
      }
    },
    handleRemove(file) {
      let index = this.fileList.indexOf(file);
      this.fileList.splice(index, 1);
      this.dataList.splice(index, 1);
    },
    // 根据病例获取提问以及专家会诊意见
    getPatientQueryExpertByPatient() {
      if (this.patientId != "") {
        this.$api.patientQueryExpertByPatient({ patientId: this.patientId, }).then((res) => {
          if (res.code == 200) {
            this.getExpertOrMechanism();
            if (res.data.length <= 0) return;
            this.problemData = res.data[0].problemData;
            this.problemData.forEach(item => {
              if (item.fileType) {
                let name = decodeURIComponent(item.fileUrl.split("/").pop().split("?")[0]);
                item.name = name.split('_')[1] ? name.split('_')[1] : name;
                item.format = item.fileUrl.split(".").pop().split("?")[0];
              }
            });
            this.contentData = res.data[0].contentData;
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 机构发起专家会诊
    expertDiagnosis() {
      if (!this.problem) {
        this.$message.error(this.$t('popup.examine.problem_cannot_empty'));
        return
      }
      let data = [{
        type: 0,
        content: this.problem
      }];
      data.push(...this.dataList)

      // return;

      let param = {
        expertId: this.expertId,
        patientId: this.patientId,
        data: JSON.stringify(data),
        showPatient: this.showPatient
      }
      this.$api.expertDiagnosis(param).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.$emit("examineChildFun", 1); // 返回父组件数据，1机构 2 专家
          this.cancel();
        } else {
          this.$message.error(res.message);

        }
      });
    },
    // 专家提交会诊意见
    patientExpertOpinion() {
      if (!this.content) {
        this.$message.error(this.$t('popup.examine.content_cannot_empty'));
        return
      }
      let data = [{
        type: 0,
        content: this.content
      }];
      let param = {
        expertId: this.expertId,
        patientId: this.patientId,
        data: JSON.stringify(data)
      }
      this.$api.patientExpertOpinion(param).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          // 是否更新左侧病例列表
          // this.$store.commit("isDeleteCaseType", true);
          this.$emit("examineChildFun", 2);
          this.cancel();
        } else {
          this.$message.error(res.message);

        }
      });
    },
    // 获取是专家还是机构
    getExpertOrMechanism() {
      //patientType病例类型 0 个人病例、1 会诊病例 2 团队病例 3 转入病例
      if (this.patientType == 1) {
        this.submitType = 'expert'
      } else if (this.patientType == 2 || this.patientType == 3) {
        this.submitType = 'mechanism';
      }

      //是否显示专家输入框 
      if (this.patientType == 1 || this.expertStatus == 3) {
        this.isShowContent = true;
      } else {
        this.isShowContent = false;
      }

      //expertStatus 0：初始化   1：待回复   2：待审核  3：审核通过", 4：审核未通过
      if (((this.patientType == 2 || this.patientType == 3) && this.expertStatus == 0) || (this.patientType == 1 && (this.expertStatus == 1 || this.expertStatus == 4))) {
        this.isOnSubmit = true;
        this.isCancel = true;
      } else {
        this.isOnSubmit = false;
        this.isCancel = false;
      }

      if (this.patientType == 1 && (this.expertStatus == 1 || this.expertStatus == 4)) {
        this.readonlyContent = false;
      } else {
        this.readonlyContent = true;
      }

      if ((this.patientType == 2 || this.patientType == 3) && this.expertStatus == 0) {
        this.readonlyProblem = false;
      } else {
        this.readonlyProblem = true;
      }
    },
    clearRuleForm() {
      this.ruleForm = {
        patientId: this.patientId, // 病例id
        problem: "", // 问题描述
        content: "", // 会诊解答
      };
    },
    // reject() {
    //   this.cancel();
    // this.postPatientOfficialExamine(2);
    // },
    closeFun() {
      this.cancel();
    },
    cancel() {
      this.newDialogVisible = false;
      this.$emit("childFun", this.newDialogVisible);
    },
    onSubmit() {
      // if (this.isShowContent && !this.ruleForm.content) {
      //   this.$message.error(this.$t('caseAllocation.opinion_cannot_empty'))
      //   return
      // }
      // this.$refs[formName].validate((valid) => {
      // if (valid) {
      // 调用接口的类型 mechanism机构提交 expert专家提交 official官方机构提交
      if (this.submitType == "mechanism") {
        if (this.btnStyle == false) return;

        this.expertDiagnosis();
      } else if (this.submitType == "expert") {

        this.patientExpertOpinion();
      }
      //  else if (this.submitType == "official") {
      //   this.cancel();
      //   this.postPatientOfficialExamine(1);
      // }
      // } else {
      //   return false;
      // }
      // });
    },
  },
  computed: {
    patientType() {
      return this.$store.state.caseValue.patientType;
    }
  },
  watch: {
    dialogVisible(val) {
      this.problemData = [];
      this.contentData = [];
      this.fileList = [];
      this.dataList = [];
      this.problem = "";
      this.content = "";
      this.newDialogVisible = this.dialogVisible;
      this.getPatientQueryExpertByPatient();
      if (val && this.$store.state.accountType == 2) {
        this.expertInfoList();
      }
      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].resetFields();
      }
      this.clearRuleForm();
    },
    dialogPdfVisible: function (v) {
      if (!v) {
        this.previewPdfUrl = '';
        this.pageCount = 0;
        this.currentPage = 1;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.examineBox {
  .choose_wrds {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #1c1c1c;
    font-weight: bold;
    margin: 5px 0 12px;
  }
  .kug {
    position: relative;
  }
  .info {
    display: inline-block;
    min-width: 112px;
  }
  .btnBox {
    display: flex;
    justify-content: flex-end;
    .cancel {
      margin-left: 12px;
    }
    .determineNomal {
      margin-left: 12px;
      width: 82px;
      height: 32px;
      margin-right: 0;
    }
  }
  .disableAddReport {
    margin-left: 12px;
    width: 80px;
    height: 30px;
  }
  .add-btn {
    position: absolute;
    right: 3px;
    top: 0px;
    width: unset;
    color: #4773b8;
    border: 1px solid #4773b8;
    padding: 0 4px !important;
    box-sizing: content-box;
    cursor: pointer;
    z-index: 99;
  }
  .attachment-upload {
    padding: 0 10px;
    overflow: hidden;
    max-height: 120px;
    overflow-y: auto;
  }
  .attachment-upload li {
    float: left;
    overflow: hidden;
    margin-top: 12px;
  }
  .attachment-upload li:hover {
    background-color: #eeeeee;
  }
  .attachment-item {
    height: 24px;
    line-height: 24px;
    // display: flex;
    // align-items: center;
  }
  .attachment-name {
    // flex: 1;
    margin-right: 10px;
    padding-left: 26px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACWklEQVQ4T6WTT0gUYRjGn/ebaUZq3SW6FEjQrQ4pQUUiiCvbzmhQeOgQdehQlw6SO2vkJY0C+zOLKxpBFCEE/VGLamlnJiRP2b2iwLpEEBGJuYs64873xhpKpZuHvuv7PT94n/d5CP/5aC19S6fXEMrQJoidRHgHyHbHbplY0v0TYJ7xDnIoh0F0i6QcBeEEg1oRYrubNb+UIRUBRto5AMYoGLYbNc4dfjtMxc0RlTXxmQnnXdscrAgwOhwTgh6Bud+f0nv0Tf5TEE26tnHKsJxJEK65tpldFWCknQQzPSHi69HITNdMIfoQQK0QMi5D0QzCIIPqvIzxfgXASDlxADkG3QyimqUX/Xtg7F0QMq6Sso9CHmJQh5cxBlaYuL/TaxBSugQaqv70o72wNTbE4MayWGPaw5LukKCu+Yg2UFUMRhk84trm7UUTk2l3AzFPApSvr06efFn0bhBzsizWpbJLgu8C6NYKC9kguu4xGBv9cC4xnm2b/gWw3KMEvioCuY11pZuZj4VSbRIirAX4AQEXmShDzDkCYiyRcPvMqWUPTCt/gUG1bvWrtmShvqgq1MiytEWyGAHhsj+rX9HX+8/AiJU0tXmsN/H9Dw8My7EAHHEz5m7Dcj4AKH+oI3AfBdwrNZEHEBOBjOcHWr/9nt7FFRKnn+9QlPA1CIeY6SMRHScZvpmXVTldDRwwx0oltWmsP/H17+gvJ9FIe5eYOSVI9kMqLyRxDQFnAfgIuXkpuhUB5YGZyqeYqANADQizYNz3Qz01no1PVyrdql0on9WLTMyhp0eu1dafWHMNIFdvzM0AAAAASUVORK5CYII=)
      no-repeat left center;
    background-size: 16px;
  }
  .attachment-item > i {
    font-size: 16px;
    cursor: pointer;
  }
  .attachment-progress {
    height: 22px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
  .attachment-progress-bar {
    flex: 1;
    height: 8px;
    overflow: hidden;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.04);
  }
  .attachment-progress-bar em {
    display: block;
    height: 100%;
    border-radius: 4px;
    background: #1890ff;
  }
  .attachment-progress-bar.success em {
    background: #07c160;
  }
  .attachment-progress-bar.fail em {
    background: #e72323;
  }
  .attachment-progress-num {
    width: 40px;
    text-align: right;
    color: #666;
  }
}
</style>

<style lang="scss">
.pdf-dialog .el-icon-arrow-up,
.pdf-dialog .el-icon-arrow-down {
  position: absolute;
  left: 50%;
  font-size: 50px;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  &.disabled {
    display: none;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.pdf-dialog .el-icon-arrow-up {
  top: 60px;
}
.pdf-dialog .el-icon-arrow-bottom {
  bottom: 50px;
}
.examineBox {
  .el-upload-list__item.is-ready,
  .el-upload-list__item.is-uploading {
    display: none !important;
  }
  .el-form-item__label {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #1c1c1c;
    font-weight: bold;
  }
  .el-form-item {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .input-kug {
    // height: 90px;
    // border: 1px solid #b8b8b8;
    // border-radius: 6px;
  }
  .files {
    margin-top: 10px;
    height: 84px;
    background: #f9f9f9;
    border-radius: 6px;
  }
  .files-list {
    margin-bottom: 30px;
    .wrds {
      margin: 0;
      margin-bottom: 5px;
    }
    .self__audio {
      margin-bottom: 5px;
    }
    &.reply {
      padding: 10px;
      background: #f9f9f9;
    }
  }
  .attachment-icon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACWklEQVQ4T6WTT0gUYRjGn/ebaUZq3SW6FEjQrQ4pQUUiiCvbzmhQeOgQdehQlw6SO2vkJY0C+zOLKxpBFCEE/VGLamlnJiRP2b2iwLpEEBGJuYs64873xhpKpZuHvuv7PT94n/d5CP/5aC19S6fXEMrQJoidRHgHyHbHbplY0v0TYJ7xDnIoh0F0i6QcBeEEg1oRYrubNb+UIRUBRto5AMYoGLYbNc4dfjtMxc0RlTXxmQnnXdscrAgwOhwTgh6Bud+f0nv0Tf5TEE26tnHKsJxJEK65tpldFWCknQQzPSHi69HITNdMIfoQQK0QMi5D0QzCIIPqvIzxfgXASDlxADkG3QyimqUX/Xtg7F0QMq6Sso9CHmJQh5cxBlaYuL/TaxBSugQaqv70o72wNTbE4MayWGPaw5LukKCu+Yg2UFUMRhk84trm7UUTk2l3AzFPApSvr06efFn0bhBzsizWpbJLgu8C6NYKC9kguu4xGBv9cC4xnm2b/gWw3KMEvioCuY11pZuZj4VSbRIirAX4AQEXmShDzDkCYiyRcPvMqWUPTCt/gUG1bvWrtmShvqgq1MiytEWyGAHhsj+rX9HX+8/AiJU0tXmsN/H9Dw8My7EAHHEz5m7Dcj4AKH+oI3AfBdwrNZEHEBOBjOcHWr/9nt7FFRKnn+9QlPA1CIeY6SMRHScZvpmXVTldDRwwx0oltWmsP/H17+gvJ9FIe5eYOSVI9kMqLyRxDQFnAfgIuXkpuhUB5YGZyqeYqANADQizYNz3Qz01no1PVyrdql0on9WLTMyhp0eu1dafWHMNIFdvzM0AAAAASUVORK5CYII=)
      no-repeat left center;
    background-size: 16px;
    padding-left: 20px;
    color: #4773b8;
    cursor: pointer;
  }
  .el-textarea {
    height: 90px;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    // margin-bottom: 20px;
    // background: #f8f8f8;
    // border: none;
  }
  .upload-demo {
    position: absolute;
    right: 0;
    top: -2px;
    width: 100%;
  }
  .el-upload-list__item {
    margin-top: -7px;
  }
  .el-upload-list__item-name {
    margin-right: 25px;
  }
  .el-upload-list {
    position: absolute;
    top: 138px;
    left: 6px;
    height: 64px;
    padding: 10px 0;
    overflow: auto;
  }
  .el-textarea__inner {
    background: #fff;
    // border-radius: 6px;
    border: none;
    resize: none;
    height: 90px;
    // border-bottom: 1px solid #ddd;
    border-radius: 6px;
    // border-top-left-radius: 6px;
    // border-top-right-radius: 6px;
    // box-shadow: 0 2px 4px #ddd;
  }
  .el-upload-list__item {
    display: inline-block;
    width: auto;
    margin-right: 20px;
  }
  .el-dialog__body {
    padding: 10px 20px;
  }
  .chose_expert {
    .el-radio-button__inner,
    .el-radio-group {
      display: block;
    }
    .el-radio {
      display: block;
      margin-bottom: 12px;
    }
  }
  .el-progress {
    position: absolute;
    width: 100%;
    bottom: 3px;
    line-height: 1;
    left: 10px;
  }
}
</style>
