<template>
    <div class="karyotypeSelectionBox">
        <el-dialog :title="$t('popup.old_report.karyo_image_choose')" :visible.sync="karyotypeDialogVisible" width="900px" :before-close="closeFun">
            <div class="karyotypeSelectionContent">
                <div class="karyotypeSelectionContentImg">
                    <span class="karyotyImg">
                        <img :src="
                                karyoAndPermutation
                                    ? permutationUrl
                                    : karyotypeUrl
                            " alt="" width="586px" height="471px" />
                    </span>
                    <span class="karyotypeSelectionContentIcon" @mouseover="changeActive" @mouseout="changeMask">
                        <img src="../../assets/images/analysissystem/iconSwitchPermutationChart.svg" alt="" />
                    </span>
                </div>
                <div class="karyotypeSelectionContentList">
                    <ul>
                        <li v-for="item in karyotypeList" :key="item.id" :class="{
                                current: item.id == karyotypeId,
                                disabled: item.isDisable,
                            }" @click="karyotypeFun(item)">
                            <div class="abbOneBox">
                                <div class="abbOneNum">
                                    <div>{{ item.singleNum }}</div>
                                    <div class="abbOneColor"></div>
                                </div>
                                <img :src="item.abbOne" alt="" srcset="" width="58px" height="58px" />
                                <div class="reexamination" v-if="item.whetherRepeatInspect"></div>
                            </div>
                            <div class="abbOneTxt">
                                <el-tooltip :content="
                                        item.glassName + '/' + item.cellName
                                    " placement="right-end" effect="light" :enterable="false">
                                    <div class="abbOneTxtTooltip">
                                        {{ item.glassName }}/{{ item.cellName }}
                                    </div>
                                </el-tooltip>
                                <el-tooltip :content="item.karyotypeExpression" placement="right-end" effect="light" :enterable="false">
                                    <div class="abbOneTxtTooltip">
                                        {{ item.karyotypeExpression }}
                                    </div>
                                </el-tooltip>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <div class="btnBox">
                    <div class="btn updateBtn" @click="cancel">{{$t("information.cancel")}}</div>
                    <div class="btn determineNomal" @click="onSubmit">{{$t("information.determine")}}</div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>
 
<script>
export default {
    name: "KaryotypeSelection",
    props: [
        "karyotypeDialogVisible",
        "patientId",
        "kyreportId",
        "karyoIds",
        "control",
    ],
    data() {
        return {
            newKaryotypeDialogVisible: false,
            karyoAndPermutation: true, // 显示核型图或排列图
            karyotypeId: "", // 核型图id
            dname: "", // 图片name
            karyotypeUrl: "", // 核型图url
            permutationUrl: "", // 排列图url
            glassName: "", // 玻片名称
            cellName: "", // 细胞名称
            karyotypeExpression: "",
            karyotypeList: [],
            clicked: false,
            resData: [],
            currentObj: "",
        };
    },
    methods: {
        // 获取核型图选择列表
        getPatientQueryBySingleType() {
            this.$api
                .patientQueryBySingleType({ patientId: this.patientId })
                .then((res) => {
                    if (res.code == 200) {
                        if (res.data.length <= 0) return;
                        this.karyoIds.forEach((item) => {
                            res.data.forEach((it) => {
                                if (item.karyoId == it.id) {
                                    it.isDisable = true;
                                }
                            });
                        });

                        if (!!this.control.karyoId) {
                            this.karyotypeId = this.control.karyoId;
                            res.data.forEach((item) => {
                                if (item.id == this.control.karyoId) {
                                    this.dname = item.dname;
                                    this.karyotypeUrl = item.optUrl;
                                    this.permutationUrl = item.arrayUrl;
                                    this.glassName = item.glassName;
                                    this.cellName = item.cellName;
                                    this.karyotypeExpression =
                                        item.karyotypeExpression;
                                }
                            });
                        } else {
                            this.resData = res.data;
                            this.karyotypeId = res.data[0].id;
                            this.dname = res.data[0].dname;
                            this.karyotypeUrl = res.data[0].optUrl;
                            this.permutationUrl = res.data[0].arrayUrl;
                            this.glassName = res.data[0].glassName;
                            this.cellName = res.data[0].cellName;
                            this.karyotypeExpression =
                                res.data[0].karyotypeExpression;
                        }
                        this.karyotypeList = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        // 经过核型图按钮时
        changeActive() {
            this.karyoAndPermutation = false;
        },
        // 离开核型图按钮时
        changeMask() {
            this.karyoAndPermutation = true;
        },
        closeFun() {
            this.newKaryotypeDialogVisible = false;
            this.$emit("childFun", this.newKaryotypeDialogVisible);
        },
        cancel() {
            this.newKaryotypeDialogVisible = false;
            this.$emit("childFun", this.newKaryotypeDialogVisible);
        },
        // 传给父组件的数据
        karyotypeFun(obj) {
            if (obj.isDisable) return;
            this.karyotypeId = obj.id;
            this.dname = obj.dname;
            this.karyotypeUrl = obj.optUrl;
            this.permutationUrl = obj.arrayUrl;
            this.glassName = obj.glassName;
            this.cellName = obj.cellName;
            this.karyotypeExpression = obj.karyotypeExpression;
        },
        // 提交按钮
        onSubmit() {
            this.newKaryotypeDialogVisible = false;
            let karyoData = {
                karyotypeId: this.karyotypeId,
                dname: this.dname,
                glassName: this.glassName,
                cellName: this.cellName,
                karyotypeUrl: this.karyotypeUrl,
                permutationUrl: this.permutationUrl,
                karyotypeExpression: this.karyotypeExpression,
                control: this.control,
            };
            this.$emit("childFun", this.newKaryotypeDialogVisible);
            this.$emit("karyoDataFun", karyoData);
        },
    },
    watch: {
        // 监听是否打开弹窗
        karyotypeDialogVisible() {
            this.newKaryotypeDialogVisible = this.karyotypeDialogVisible;
            this.getPatientQueryBySingleType();
        },
    },
};
</script>
 
<style lang="scss" scoped>
.karyotypeSelectionBox {
    .karyotypeSelectionContent {
        display: flex;
        height: 526px;
        box-shadow: 0px 1px 0px 0px #dddddd;
        .karyotypeSelectionContentImg {
            width: 740px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .karyotypeSelectionContentIcon {
                cursor: pointer;
                position: absolute;
                top: 20px;
                right: 20px;
            }
        }
        .karyotypeSelectionContentList {
            width: 160px;
            height: 100%;
            background: #d2d4d6;
            box-shadow: -1px 0px 0px 0px #999999;
            overflow: auto;
            overflow-x: hidden;
            ul {
                margin: 0;
                padding: 0;
                li {
                    cursor: pointer;
                    margin-top: 1px;
                    list-style-type: none;
                    height: 58px;
                    background: #e7e9eb;
                    display: flex;
                    &.current {
                        border-right: 5px solid #416ab0;
                    }
                    &.disabled {
                        opacity: 0.6;
                        cursor: not-allowed;
                    }
                    .abbOneBox {
                        position: relative;
                        .abbOneNum {
                            position: absolute;
                            top: 4px;
                            left: 5px;
                            display: flex;
                            flex-direction: column;
                            .abbOneColor {
                                display: block;
                                width: 18px;
                                height: 3px;
                                background: #1a9448;
                            }
                        }
                        .reexamination {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: 5px;
                            background-color: #f53500;
                        }
                    }
                    .abbOneTxt {
                        margin-left: 6px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #1a1a1a;
                        .abbOneTxtTooltip {
                            margin: 8px 0;
                            width: 70px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
    .btnBox {
        width: 860px;
        display: flex;
        justify-content: flex-end;
        .updateBtn {
            width: 80px;
            height: 30px;
        }
        .determineNomal {
            margin-left: 10px;
            width: 82px;
            height: 32px;
        }
    }
}
</style>
 
<style lang="scss">
.karyotypeSelectionBox {
    .el-dialog__body {
        padding: 0;
    }
    .el-dialog__wrapper {
        .el-dialog {
            height: auto !important;
        }
        .el-dialog__footer {
            display: block !important;
        }
    }
}
</style>