const getGuid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0,
			v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}
const getDefaultParam = (config) => {
	return {
		x: config.x || 0,
		y: config.y || 0,
		zindex: config.zindex || 0,
		width: config.width || 0,
		height: config.height || 0,
		matrix: config.matrix || [],
		scale: config.scale || 1,
		rotate: config.rotate || 0,
		hide: config.hide || false,
		lineWidth: config.lineWidth || 1,
		color: config.color || "transparent",
		id: config.id || getGuid(),
		type: config.type || 'rectangle',
		realType: config.realType || 'rectangle',
		drag: config.drag || true,
		isCustom: config.isCustom || false,
		_: config._
	}
}
const isRectPointInner = function(x, y) {
	const xRight = x >= this.x;
	const xLeft = x <= this.x + this.width;
	const yTop = y >= this.y;
	const yBottom = y <= this.y + this.height;
	return xRight && xLeft && yTop && yBottom;
}
const insidePolygon = function(x, y, points) {
	// console.log(points);
	let result = false;
	if (!points) {
		return false;
	}
	for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
		if (points[i][1] > y != points[j][1] > y && x < (points[j][0] - points[i][0]) * (y - points[i][1]) / (points[j][1] -
				points[i][1]) + points[i][0]) {
			result = !result;
		}
	}
	return result;
};

const insideLine = function(x0, y0, x1, y1, lineWidth, x, y) {
	if (lineWidth === 0) {
		return false;
	}
	var _l = lineWidth;
	var _a = 0;
	var _b = x0;
	// Quick reject
	if (y > y0 + _l && y > y1 + _l || y < y0 - _l && y < y1 - _l || x > x0 + _l && x > x1 + _l || x < x0 - _l && x < x1 -
		_l) {
		return false;
	}

	if (x0 !== x1) {
		_a = (y0 - y1) / (x0 - x1);
		_b = (x0 * y1 - x1 * y0) / (x0 - x1);
	} else {
		return Math.abs(x - x0) <= _l / 2;
	}
	var tmp = _a * x - y + _b;
	var _s = tmp * tmp / (_a * _a + 1);
	return _s <= _l / 2 * _l / 2;
};
const insideArc = (function (x, y, r, sa, ea) {
	var pi = Math.PI;
	var dis = void 0,
		isIn = void 0;
	// Sector
	if (!isNaN(sa) && !isNaN(ea)) {
		var angle = 0;
		// 4th quadrant
		if (x >= 0 && y >= 0) {
			if (x === 0) {
				angle = pi / 2;
			} else {
				angle = Math.atan(y / x);
			}
		}
		// 3th quadrant
		else if (x <= 0 && y >= 0) {
			if (x === 0) {
				angle = pi;
			} else {
				angle = pi - Math.atan(y / Math.abs(x));
			}
		}
		// secend quadrant
		else if (x <= 0 && y <= 0) {
			if (x === 0) {
				angle = pi;
			} else {
				angle = Math.atan(Math.abs(y) / Math.abs(x)) + pi;
			}
		}
		// first quadrant
		else if (x >= 0 && y <= 0) {
			if (x === 0) {
				angle = pi * 3 / 2;
			} else {
				angle = 2 * pi - Math.atan(Math.abs(y) / x);
			}
		}
		dis = Math.sqrt(x * x + y * y);
		if (sa < ea) {
			isIn = !!(angle >= sa && angle <= ea && dis <= r);
		} else {
			isIn = !!((angle >= 0 && angle <= ea || angle >= sa && angle <= 2 * pi) && dis <= r);
		}
	}
	// normal arc
	else {
		isIn = !!(Math.sqrt(x * x + y * y) <= r);
	}
	return isIn;
});
class Graphs {
	constructor(_this) {
		this._ = _this;
	}

	image(config) {
		const param = getDefaultParam(Object.assign(config, {
			width: config.image.width,
			height: config.image.height,
			type: 'image',
			realType: config.realType,
			_: this._,
		}))
		return Object.assign(param, {
			draw: function() {
				if (!this.hide && Object.prototype.toString.call(config.image) === "[object HTMLImageElement]") {
					const canvas = this._.canvas;
					const transX = this._.transX;
					const transY = this._.transY;
					const tx = this._.offset.TRANS_X;
					const ty = this._.offset.TRANS_Y;
					const width = this.width;
					const height = this.height;
					canvas.save();
					canvas.translate(this.x + transX + tx, this.y + transY + ty);
					canvas.drawImage(config.image, 0, 0, width, height);
					canvas.restore();
				}
			},
			isPointInner: function(x, y) {
				const tx = this._.offset.TRANS_X;
				const ty = this._.offset.TRANS_Y;
				return isRectPointInner.call(this, x - tx, y - ty)
			}
		})
	}

	standard(config) {
		const param = getDefaultParam(Object.assign(config, {
			width: config.width,
			height: config.height,
			type: 'image',
			realType: config.realType,
			_: this._,
		}))
		const getMatrix = ()=>{

		}
		return Object.assign(param, {
			dx: config.dx,
			dy: config.dy,
			dw: config.dw,
			dh: config.dh,
			maxScale : config.maxScale,
			no: config.no
		}, {
			draw: function() {
				if (!this.hide && Object.prototype.toString.call(config.image) === "[object HTMLImageElement]") {
					const canvas = this._.canvas;
					const transX = this._.transX;
					const transY = this._.transY;
					const width = this.width;
					// const height = this.height / this.maxScale * this.scale;
					const height = this.height * this.scale;
					const y = this.y - height;
					// console.log(height);
					canvas.save();
					canvas.translate(this.x + transX, y + transY);
					canvas.drawImage(config.image, this.dx, this.dy, this.dw, this.dh, 0, 0, width, height)
					canvas.restore();
				}
			},
			isPointInner: function(x, y) {
				x = parseInt(x);
				y = parseInt(y);
				const width = this.width;
				// const height = this.height / this.maxScale * this.scale;
				const height = this.height * this.scale;
				const tx = this.x;
				const ty = this.y - height;
				const xRight = x >= tx;
				const xLeft = x <= tx + width;
				const yTop = y >= ty;
				const yBottom = y <= this.y;
				return xRight && xLeft && yTop && yBottom;
			},
			getPosRelative(y){
				// const height = this.height / this.maxScale * this.scale;
				const height = this.height * this.scale;
				const ty = this.y - height;
				return (y - ty) / height * this.dh;
			}
		})
	}

	banding(config){
		const param = getDefaultParam(Object.assign(config, {
			width: config.width,
			height: config.height,
			type: 'image',
			realType: config.realType,
			_: this._,
		}))
		return Object.assign(param, {
			dx: config.dx,
			dy: config.dy,
			dw: config.dw,
			dh: config.dh,
			keys : config.keys,
			no: config.no,
			image : config.image,
			isRight : config.isRight
		}, {
			draw: function() {
				if (!this.hide && Object.prototype.toString.call(this.image) === "[object HTMLImageElement]") {
					const canvas = this._.canvas;
					const transX = this._.transX;
					const transY = this._.transY;
					const width = this.width;
					const height = this.height * this.scale;
					const y = this.y - height;
					canvas.save();
					canvas.translate(this.x + transX, y + transY);
					canvas.drawImage(this.image, this.dx, this.dy, this.dw, this.dh, 0, 0, width, height)
					
					canvas.restore();
					this.drawKeys();
				}
			},
			drawKeys: function(){
				const canvas = this._.canvas;
				const transX = this._.transX;
				const transY = this._.transY;
				const height = this.height * this.scale;
				const scale = height / this.dh;
				canvas.font = `normal normal 500 36px Arial`;
				canvas.fillStyle = "#000";
				this.keys.forEach(key=>{
					let text = '';
					let left = 0;
					if(this.isRight){
						text = "— " + key.name;
						left = this.width + 4;
					}else{
						text = key.name + " —";
						const width = canvas.measureText(text).width;
						left = - width - 4;
					}
					// console.log(left, text, this);
					canvas.save();
					canvas.translate(this.x + transX + left, this.y + transY - height + key.y * scale);
					
					canvas.fillText(text, 0, 10);
					canvas.restore();
				})
			},
			isPointInner: function(x, y) {
				x = parseInt(x);
				y = parseInt(y);
				const width = this.width;
				const height = this.height * this.scale;
				const tx = this.x;
				const ty = this.y - height;
				const xRight = x >= tx;
				const xLeft = x <= tx + width;
				const yTop = y >= ty;
				const yBottom = y <= this.y;
				return xRight && xLeft && yTop && yBottom;
			},
			getPosRelative(y){
				const height = this.height * this.scale;
				const ty = this.y - height;
				return (y - ty) / height * this.dh;
			}
		})
	}

	rectangle(config) {
		const param = getDefaultParam(Object.assign(config, {
			type: 'rectangle',
			realType: config.realType,
			_: this._,
		}))
		param.matrix = [
			[param.x, param.y],
			[param.x + param.width * param.scale, param.y],
			[param.x + param.width * param.scale, param.y + param.height * param.scale],
			[param.x, param.y + param.height * param.scale]
		]
		return Object.assign(param,{
			group : config.group || 0
		}, {
			draw: function() {
				if (this.hide) {
					return
				}
				const canvas = this._.canvas;
				const transX = this._.transX;
				const transY = this._.transY;
				const width = this.width;
				const height = this.height;
				this.matrix = [
					[this.x + transX, this.y + transY],
					[this.x + width + transX, this.y + transY],
					[this.x + width + transX, this.y + height + transY],
					[this.x + transX, this.y + height + transY],
				];
				canvas.save();
				canvas.beginPath()
				this.matrix.forEach((pos, i) => {
					i === 0 ? canvas.moveTo(pos[0], pos[1]) : canvas.lineTo(pos[0], pos[1])
				})
				canvas.lineTo(this.matrix[0][0], this.matrix[0][1]);
				canvas.fillStyle = this.color;
				canvas.fill();
				canvas.closePath()
				canvas.restore();
			},
			isPointInner: function(x, y) {
				return isRectPointInner.call(this, x, y)
			}
		})
	}
	
	groupLine(config) {
		const shape = this.rectangle(config);
		shape.type = "groupLine";
		return shape;
	}

	polygon(config) {
		const param = getDefaultParam(Object.assign(config, {
			type: 'polygon',
			realType: config.realType,
			_: this._,
		}));
		const draw = (canvas, matrix, color)=>{
			const transX = this._.transX;
			const transY = this._.transY;
			const tx = this._.offset.TRANS_X;
			const ty = this._.offset.TRANS_Y;
			canvas.save();
			canvas.beginPath()
			matrix.forEach((pos, i) => {
				i === 0 ? canvas.moveTo(pos[0] + transX + tx, pos[1] + transY + ty) : canvas.lineTo(pos[0] + transX + tx, pos[1] + transY + ty)
			})
			canvas.lineTo(matrix[0][0] + transX + tx, matrix[0][1] + transY + ty);
			canvas.lineWidth = 1 / this._.offset.scale / this._.scale;
			canvas.strokeStyle = color;
			canvas.stroke();
			canvas.closePath()
			canvas.restore();
		}
		return Object.assign(param, {
			draw: function() {
				if (this.hide) {
					return
				}
				const canvas = this._.canvas;
				if (config.debug) {
					// console.log(this);
				}
				draw.call(param, canvas, this.matrix, this.color);
				if(this.divisionLine && this.divisionLine.length){
					this.divisionLine.forEach(matrix=>{
						draw.call(this, canvas, matrix, this.color);
					})
				}
			},
			isPointInner: function(x, y) {
				const tx = this._.offset.TRANS_X;
				const ty = this._.offset.TRANS_Y;
				return insidePolygon.call(this, x - tx, y - ty, this.matrix)
			}
		})
	}
	
	easerArea(config) {
		const param = getDefaultParam(Object.assign(config, {
			type: 'easerArea',
			realType: config.realType,
			color : config.color || "rgba(255,255,255,.5)",
			_: this._,
		}));
		return Object.assign(param,{
			dash : config.dash || [2,2],
			borderColor : config.borderColor || '#52C41A'
		}, {
			draw: function() {
				if (this.hide) {
					return
				}
				const canvas = this._.canvas;
				const transX = this._.transX;
				const transY = this._.transY;
				const matrix = this.matrix;
				canvas.save();
				canvas.beginPath()
				if (this.dash && Array.isArray(this.dash)) {
					canvas.setLineDash(this.dash);
				}
				matrix.forEach((pos, i) => {
					i === 0 ? canvas.moveTo(pos[0] + transX, pos[1] + transY) : canvas.lineTo(pos[0] + transX, pos[1] + transY)
				})
				canvas.lineTo(matrix[0][0] + transX, matrix[0][1] + transY);
				canvas.lineWidth = this.lineWidth / this._.offset.scale / this._.scale;
				canvas.fillStyle = this.color;
				canvas.fill();
				canvas.strokeStyle = this.borderColor;
				canvas.stroke();
				canvas.closePath()
				canvas.restore();
			},
			isPointInner: function(x, y) {
				return insidePolygon.call(this, x, y, this.matrix)
			}
		})
	}

	text(config) {
		const param = getDefaultParam(Object.assign(config, {
			type: 'text',
			_: this._,
		}));
		return Object.assign(param, {
			font: config.font,
			text: config.text,
			holdFont : config.holdFont || false,
			TRANS_X : config.TRANS_X !== undefined ? config.TRANS_X : this._.offset.TRANS_X,
			TRANS_Y : config.TRANS_Y !== undefined ? config.TRANS_Y : this._.offset.TRANS_Y
		}, {
			draw: function() {
				if (this.hide) {
					return
				}
				const canvas = this._.canvas;
				const transX = this._.transX;
				const transY = this._.transY;
				const width = canvas.measureText(this.text).width;
				this.textWidth = width || this.textWidth || 0;
				const left = ((this.width || 0) - this.textWidth) / 2;
				const tx = this.TRANS_X;
				const ty = this.TRANS_Y;
				canvas.save();
				canvas.translate(transX + this.x + tx, transY + this.y + ty);
				canvas.font = this.font;
				if(this.holdFont){
					canvas.font = this.font.replace(/\d+/g,(res)=>{
						const fz = parseInt(res) * this._.offset.zoom / this._.scale
						// console.log(res, fz);
						// return Math.max(20,fz);
						return Math.ceil(fz);
					})
				}
				canvas.fillStyle = this.color;
				if(this.width){
					canvas.fillText(this.text, left, 0, this.width);
				}else{
					canvas.textAlign = 'center';
					canvas.fillText(this.text, 0, 0);
				}
				canvas.restore();
			},
			isPointInner: function(x, y) {
				const tx = this._.offset.TRANS_X;
				const ty = this._.offset.TRANS_Y;
				return isRectPointInner.call(this, x - tx, y - ty)
			}
		})
	}

	standardText(config){
		const param = getDefaultParam(Object.assign(config, {
			type: 'text',
			realType : 'standardText',
			_: this._,
		}));
		return Object.assign(param, {
			font: config.font,
			text: config.text,
		}, {
			draw: function() {
				if (this.hide) {
					return
				}
				const canvas = this._.canvas;
				const transX = this._.transX;
				const transY = this._.transY;
				canvas.font = this.font;
				const width = canvas.measureText(this.text).width;
				// console.log(width);
				let left = this.x;
				if(left + width >= this._.width){
					// left = this._.width - 30 - width;
					left = this.x - width - 20
				}
				const tx = this._.offset.TRANS_X;
				const ty = this._.offset.TRANS_Y;
				canvas.save();
				canvas.translate(transX + left, transY + this.y);
				canvas.font = this.font;
				canvas.fillStyle = this.color;
				if(this.width){
					canvas.fillText(this.text, 0, 0, this.width);
				}else{
					canvas.fillText(this.text, 0, 0);
				}
				canvas.restore();
			},
			isPointInner: function(x, y) {
				const tx = this._.offset.TRANS_X;
				const ty = this._.offset.TRANS_Y;
				return isRectPointInner.call(this, x, y)
			}
		})
	}

	line(config) {
		const param = getDefaultParam(Object.assign(config, {
			type: 'line',
			realType: config.realType,
			_: this._,
		}));
		return Object.assign(param, {
			dash: config.dash,
			lineCap: config.lineCap,
			lineJoin: config.lineJoin,
			hold : (config.hold === undefined ? true : config.hold)
		}, {
			draw: function() {
				if (this.hide) {
					return
				}
				const canvas = this._.canvas;
				const transX = this._.transX;
				const transY = this._.transY;
				canvas.save();
				canvas.translate(transX + this.x, transY + this.y);
				canvas.beginPath();
				if (this.dash && Array.isArray(this.dash)) {
					canvas.setLineDash(this.dash);
				}
				if (this.lineCap) {
					canvas.lineCap = this.lineCap;
				}
				if (this.lineJoin) {
					canvas.lineJoin = this.lineJoin;
				}
				canvas.lineWidth = this.lineWidth
				if(this.hold){
					canvas.lineWidth = this.lineWidth / this._.offset.scale / this._.scale;
				}
				canvas.strokeStyle = this.color;
				const isArray = Array.isArray(this.matrix[0]);
				this.matrix.forEach((point, i) => {
					isArray && i === 0 ? canvas.moveTo(point[0], point[1]) : canvas.lineTo(point[0], point[1]);
					!isArray && i === 0 ? canvas.moveTo(point.x, point.y) : canvas.lineTo(point.x, point.y);
				});
				canvas.stroke();
				canvas.closePath();
				canvas.restore();
			},
			isPointInner: function(x, y) {
				const isArray = Array.isArray(this.matrix[0]);
				const firstPoint = this.matrix[0];
				const lastPoint = this.matrix[this.matrix.length - 1]
				const [x0, y0] = isArray ? firstPoint : [firstPoint.x, firstPoint.y];
				const [x1, y1] = isArray ? lastPoint : [lastPoint.x, lastPoint.y];
				return insideLine.call(this, x0, y0, x1, y1, this.lineWidth, x, y)
			}
		})
	}

	arrow(config) {
		const param = getDefaultParam(Object.assign(config, {
			type: 'arrow',
			_: this._,
		}));
		const getPoint = function(beginPoint, stopPoint) {
			const fromX = beginPoint.x;
			const fromY = beginPoint.y;
			const toX = stopPoint.x;
			const toY = stopPoint.y;
			const headlen = this._.controller.name === "karyo" ? 12 : 20;
			const theta = this._.controller.name === "karyo" ? 30 : 35;
			let arrowX1, arrowY1; //箭头线终点坐标
			let arrowX2, arrowY2; //箭头线终点坐标
			// 计算各角度和对应的箭头终点坐标
			const angle = Math.atan2(fromY - toY, fromX - toX) * 180 / Math.PI;
			const angle1 = (angle + theta) * Math.PI / 180;
			const angle2 = (angle - theta) * Math.PI / 180;
			const topX = headlen * Math.cos(angle1);
			const topY = headlen * Math.sin(angle1);
			const botX = headlen * Math.cos(angle2);
			const botY = headlen * Math.sin(angle2);
			arrowX1 = toX + topX;
			arrowY1 = toY + topY;
			arrowX2 = toX + botX;
			arrowY2 = toY + botY;
			return {
				arrowPoint1: [arrowX1, arrowY1],
				arrowPoint2: [arrowX2, arrowY2]
			}
		}
		const data = Object.assign(param, {
			beginPoint: config.beginPoint,
			stopPoint: config.stopPoint,
			dash: config.dash,
			txt: config.note || config.txt || '',
			lineCap: config.lineCap || "round",
			lineJoin: config.lineJoin || "round"
		});
		const arrowLine = getPoint.call(data, data.beginPoint, data.stopPoint);
		const drawLine = function(canvas, point1, point2) {
			canvas.beginPath();
			if (this.dash && Array.isArray(this.dash)) {
				canvas.setLineDash(this.dash);
			}
			if (this.lineCap) {
				canvas.lineCap = this.lineCap;
			}
			if (this.lineJoin) {
				canvas.lineJoin = this.lineJoin;
			}
			canvas.lineWidth = this.lineWidth;
			// canvas.lineWidth = this.lineWidth / this._.offset.scale / this._.scale;
			canvas.strokeStyle = this.color;
			canvas.moveTo(point1[0], point1[1]);
			canvas.lineTo(point2[0], point2[1])
			canvas.stroke();
			canvas.closePath();
		}
		const drawText = function(canvas, point1, point2){
			canvas.fillStyle = this.color;
			canvas.font = this.font || (this._.controller.name === "arrange" ? '45px Arial' :  '30px Arial');
			const top = point1[1] > point2[1] ? (point1[1] + 30) : (point1[1] - 15);
			canvas.fillText(this.txt, point1[0], top);
		}
		return Object.assign(data, {
			draw: function() {
				const canvas = this._.canvas;
				const transX = this._.transX;
				const transY = this._.transY;
				// const tx = this._.offset.TRANS_X;
				// const ty = this._.offset.TRANS_Y;
				const tx = 0;
				const ty = 0;
				canvas.save();
				canvas.translate(transX + this.x + tx, transY + this.y + ty);
				const beginPoint = [this.beginPoint.x, this.beginPoint.y];
				const stopPoint = [this.stopPoint.x, this.stopPoint.y];
				const {
					arrowPoint1,
					arrowPoint2
				} = getPoint.call(this, this.beginPoint, this.stopPoint)
				drawLine.call(this, canvas, beginPoint, stopPoint);
				
				canvas.beginPath();
				if (this.dash && Array.isArray(this.dash)) {
					canvas.setLineDash(this.dash);
				}
				if (this.lineCap) {
					canvas.lineCap = this.lineCap;
				}
				if (this.lineJoin) {
					canvas.lineJoin = this.lineJoin;
				}
				canvas.lineWidth = this.lineWidth;
				canvas.fillStyle = this.color;
				canvas.moveTo(stopPoint[0], stopPoint[1]);
				canvas.lineTo(arrowPoint1[0], arrowPoint1[1]);
				canvas.lineTo(arrowPoint2[0], arrowPoint2[1]);
				canvas.fill();
				canvas.closePath();
				drawLine.call(this, canvas, stopPoint, arrowPoint1);
				drawLine.call(this, canvas, stopPoint, arrowPoint2);
				drawLine.call(this, canvas, arrowPoint1, arrowPoint2);
				if(this.txt){
					drawText.call(this, canvas, beginPoint, stopPoint)
				}
				canvas.restore();
			},
			isPointInner: function(x, y) {
				let spx = this.beginPoint.x + this.x,
					spy = this.beginPoint.y + this.y,
					epx = this.stopPoint.x + this.x,
					epy = this.stopPoint.y + this.y;
				const {
					arrowPoint1,
					arrowPoint2
				} = getPoint.call(this, this.beginPoint, this.stopPoint)
				const [lpx, lpy] = arrowPoint1;
				const [rpx, rpy] = arrowPoint2;
				//this.lineWidth * this._.offset.zoom + 1
				// const tx = this._.offset.TRANS_X;
				// const ty = this._.offset.TRANS_Y;
				const tx = 0;
				const ty = 0;
				const bool1 = insideLine.call(this, spx, spy, epx, epy, this.lineWidth * (this._.offset.zoom + 1), x - tx, y - ty);
				const bool2 = insideLine.call(this, epx, epy, lpx, lpy, this.lineWidth * (this._.offset.zoom + 1), x - tx, y - ty);
				const bool3 = insideLine.call(this, epx, epy, rpx, rpy, this.lineWidth * (this._.offset.zoom + 1), x - tx, y - ty);
				const bool4 = this.isPointerOnText(x, y);
				return bool1 || bool2 || bool3 || bool4;
			},
			isPointOnStartPoint: function(x, y) {
				// const tx = this._.offset.TRANS_X;
				// const ty = this._.offset.TRANS_Y;
				const tx = 0;
				const ty = 0;
				let cx = this.beginPoint.x + this.x + tx,
					cy = this.beginPoint.y + this.y + ty;
				const sa = Math.PI / 180 * 0;
				const ea = Math.PI / 180 *360;
				const r = 10 / this._.scale / this._.offset.scale;
                return insideArc(x-cx,y-cy,r,sa,ea);
				// return Math.abs(x-cx) < 5 && Math.abs(y-cy) < 5;
			},
			isPointOnEndPoint: function(x, y) {
				// const tx = this._.offset.TRANS_X;
				// const ty = this._.offset.TRANS_Y;
				const tx = 0;
				const ty = 0;
				let cx = this.stopPoint.x + this.x + tx,
					cy = this.stopPoint.y + this.y + ty;
				const sa = Math.PI / 180 * 0;
				const ea = Math.PI / 180 *360;
				const r = 10 / this._.scale / this._.offset.scale;
				return insideArc(x-cx,y-cy,r,sa,ea);
				// return Math.abs(x-cx) < 5 && Math.abs(y-cy) < 5;
			},
			isPointerOnText: function(x, y){
				const canvas = this._.canvas;
				canvas.font = this.font || '30px Arial';
				const width = canvas.measureText(this.text).width;
				// const tx = this._.offset.TRANS_X;
				// const ty = this._.offset.TRANS_Y;
				const tx = 0;
				const ty = 0;
				let sx = this.beginPoint.x + this.x + tx,
					sy = this.stopPoint.y + this.y + ty;
				let by = this.beginPoint.y + this.y + ty;
				let cx = sx,
					cy = by > sy ? (by + 5) : (by - 45);
				// console.log(sx,sy,ey,cx,cy);
				const xRight = x >= cx;
				const xLeft = x <= cx + width;
				const yTop = y >= cy;
				const yBottom = y <= cy + 30;
				// console.log(xRight,xLeft,yTop,yBottom);
				return xRight && xLeft && yTop && yBottom;
			},
			updatePoint: function(beginPoint, stopPoint) {
				this.beginPoint = beginPoint;
				this.stopPoint = stopPoint;
				const obj = getPoint.call(data, beginPoint, stopPoint);
				Object.assign(this, obj);
			}
		})
	}

	chromo(config) {
		// console.log(config);
		const getMatrix = function() {
			const w = this.width * this.scale;
			const h = this.height * this.scale;
			const x = this.x;
			const y = this.y;
			return [
				[x, y],
				[x + w, y],
				[x + w, y + h],
				[x, y + h]
			]
		}
		const param = getDefaultParam(Object.assign(config, {
			width: config.image.width,
			height: config.image.height,
			type: 'polygon',
			realType: 'chromo',
			_: this._,
		}))
		param.matrix = getMatrix.call(param)
		return Object.assign(param, {
			image: config.image,
			yoffsetInGroup: config.yoffsetInGroup || 0,
			substitute : {
				x : param.x,
				y : param.y,
				rotate : param.rotate,
				scale : param.scale,
				width : param.width,
				height: param.height,
				hide : true
			},
			chromoId : config.chromoId,
			chromo_id : config.chromo_id
		}, {
			draw: function() {
				// console.log(this.image);
				if (this.hide) {
					return
				}
				const canvas = this._.canvas;
				const transX = this._.transX;
				const transY = this._.transY;
				this.matrix = getMatrix.call(this);
				const [x, y] = this.matrix[0]
				
				canvas.save();
				
				canvas.translate(transX + x, transY + y);
				const angle = this.rotate * Math.PI / 180;
				canvas.rotate(angle)
				canvas.drawImage(this.image, 0, 0, this.width * this.scale, this.height * this.scale);
				canvas.restore();
				
				if(this.color){
					canvas.beginPath()
					this.matrix.forEach((p,i)=>{
						i === 0 ? canvas.moveTo(transX + p[0], transY + p[1]) : canvas.lineTo(transX + p[0], transY + p[1])
					})
					canvas.lineTo(transX + x, transY + y)
					canvas.strokeStyle = this.color;
					canvas.lineWidth = this.lineWidth / this._.offset.scale / this._.scale;
					canvas.stroke();
					canvas.closePath();
				}
				//绘制替身
				if(!this.substitute.hide){
					const sub = this.substitute;
					const cw = sub.width * sub.scale / 2;
					const ch = sub.height * sub.scale / 2;
					const cx = transX + sub.x + cw;
					const cy = transY + sub.y + ch;
					canvas.save();
					canvas.translate(cx, cy);
					const angle = sub.rotate * Math.PI / 180;
					canvas.rotate(angle);
					canvas.globalAlpha = 0.6;
					canvas.drawImage(this.image, -cw, -ch, sub.width * sub.scale, sub.height * sub.scale);
					canvas.restore();
				}
			},
			isPointInner: function(x, y) {
				const matrix = getMatrix.call(this.substitute);
				const bool1 = insidePolygon.call(this, x, y, this.matrix);
				const bool2 = insidePolygon.call(this.substitute, this.substitute.x, this.substitute.y, matrix);
				return bool1 && bool2;
			}
		})
	}
	
	point(config){
		const param = getDefaultParam(Object.assign(config, {
			type: 'point',
			realType: config.realType,
			_: this._,
		}));
		return Object.assign(param,{
			point : config.point,
			length : config.length || 20,
			lineWidth : config.lineWidth
		}, {
			draw: function() {
				if (this.hide) {
					return
				}
				const canvas = this._.canvas;
				const transX = this._.transX;
				const transY = this._.transY;
				const point = this.point;
				const length = this.length;
				const lineWidth = this.lineWidth || 2;
				const r = length || 8;
				const bg = this._.$parent.karyoInfo.singleNum == 46 ? 'rgba(46,198,87,.8)' : 'rgba(245,53,0,.8)';
				canvas.save();
				canvas.beginPath();
				
				if(!this.color || this.color === "transparent"){
					canvas.fillStyle = bg;
				}else{
					canvas.fillStyle = this.color;
				}
				canvas.translate(transX + this.x, transY + this.y);
				canvas.arc(point.x, point.y, r, 0, Math.PI * 2, false);
				canvas.fill();
				if(lineWidth){
					canvas.strokeStyle = 'rgba(0,0,0,1)';
					canvas.stroke();
				}
				
				canvas.closePath();
				canvas.restore();
			},
			isPointInner: function(x, y) {
				const point = this.point;
				const transX = this._.transX;
				const transY = this._.transY;
				const cx = this.x + point.x;
				const cy = this.y + point.y;
				// console.log(x,y,cx,cy,point,this);
				const sa = Math.PI / 180 * 0;
				const ea = Math.PI / 180 *360;
				return insideArc(x - cx, y- cy,8,sa,ea);
			}
		})
	}

	point1(config){
		const param = getDefaultParam(Object.assign(config, {
			type: 'point',
			realType: config.realType,
			_: this._,
		}));
		const drawLine = (canvas,sp,ep,lineWidth,color)=>{
			canvas.beginPath();
			canvas.lineCap = "round";
			canvas.lineJoin = "round";
			canvas.lineWidth = lineWidth / this._.offset.scale / this._.scale;
			canvas.strokeStyle = color;
			canvas.moveTo(sp[0], sp[1]);
			canvas.lineTo(ep[0], ep[1]);
			canvas.stroke();
			canvas.closePath();
		}
		return Object.assign(param,{
			point : config.point,
			length : config.length || 20,
		}, {
			draw: function() {
				if (this.hide) {
					return
				}
				const canvas = this._.canvas;
				const transX = this._.transX;
				const transY = this._.transY;
				const point = this.point;
				const length = this.length;
				const sx = point.x - length / 2;
				const ex = point.x + length / 2;
				const sy = point.y - length / 2;
				const ey = point.y + length / 2;
				const lineWidth = this.lineWidth || 2;
				canvas.save();
				canvas.translate(transX + this.x, transY + this.y);
				drawLine.call(this,canvas, [sx, point.y],[ex, point.y],lineWidth+4,'rgba(255,255,255,.8)');
				drawLine.call(this,canvas, [point.x, sy],[point.x, ey],lineWidth+4,'rgba(255,255,255,.8)');
				drawLine.call(this,canvas, [sx, point.y],[ex, point.y],lineWidth,this.color || '#f00');
				drawLine.call(this,canvas, [point.x, sy],[point.x, ey],lineWidth,this.color || '#f00');
				
				canvas.restore();
			},
			isPointInner: function(x, y) {
				const point = this.point;
				const length = this.length;
				const sx = point.x - length / 2;
				const ex = point.x + length / 2;
				const sy = point.y - length / 2;
				const ey = point.y + length / 2;
				
				const bool1 = x >= sx;
				const bool2 = x <= ex;
				const bool3 = y >= sy;
				const bool4 = y <= ey;
				return bool1 && bool2 && bool3 && bool4;
			}
		})
	}
	
	brush(config){
		const param = getDefaultParam(Object.assign(config, {
			type: 'circle',
			realType: config.realType || 'brush',
			_: this._,
		}));
		return Object.assign(param,{
			brushThickness : config.brushThickness
		}, {
			draw: function() {
				if (this.hide) {
					return
				}
				const canvas = this._.canvas;
				// const radius = this.brushThickness / 2 * stage.offset.zoom / stage.scale;
				const radius = this.brushThickness / 2 * this._.offset.zoom;
				canvas.save();
				canvas.translate(this._.transX,this._.transY);
				canvas.beginPath();
				canvas.arc(this.x, this.y, radius, 0, 2* Math.PI);
				canvas.fillStyle = 'rgb(255,255,255,.6)';
				canvas.fill();
				canvas.lineWidth = 1 * this._.offset.zoom / this._.scale;
				canvas.strokeStyle = "#4773B8";
				canvas.stroke();
				canvas.closePath();
				canvas.restore();
			},
			isPointInner: function(x, y) {
				return false
			}
		})
	}

}
export default Graphs;
