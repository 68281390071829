<template>
	<ul class="karyo-list" :class="{shrink:shrink}">
		<el-scrollbar :native='false' ref="scrollbar">
			<li v-for="item in karyoList" :key="item.id" :class="['karyo-item',{current: item.id == karyoId}]" @click="switchKaryo(item)">
				<div :class="['karyo-thumb',{red:item.color==1,yellow:item.color==2,blue:item.color==3}]">
					<img :src="item._optUrl || item.optUrl" />
				</div>
				<div class="karyo-info">
					<div class="name">{{item.glassName}}/{{item.cellName}}</div>
					<div class="expression" :title="item.karyotypeExpression">{{item.karyotypeExpression || " "}}</div>
				</div>
				<div :class="['karyo-count',{singleSure:item.isSingleSure},{typeSure:item.isTypeSure}]">{{item.singleNum}}</div>
			</li>
		</el-scrollbar>
	</ul>

</template>

<script>
	export default {
		name: "KaryoList",
		props: {
			karyoList: Array,
			shrink: Boolean,
			karyoId: Number || String,
			switchKaryoHandle: Function,
			infinite: false
		},
		data() {
			return {

			}
		},
		mounted() {
			// console.log(this.karyoList);
			// console.log(this.shrink);
			this.scrollbar = this.$refs.scrollbar.$el.getElementsByClassName("el-scrollbar__wrap")[0];
			this.viewDom = this.scrollbar.childNodes[0];
			this.scrollbar.addEventListener("scroll",(e)=>{
				// console.log(this.scrollbar.scrollTop, this.viewDom.clientHeight,this.scrollbar.clientHeight);
				if(this.viewDom.clientHeight <= this.scrollbar.scrollTop + this.scrollbar.clientHeight + 50 && this.infinite){
					this.$parent.loadNextPageList();
				}
			})
		},
		methods: {
			switchKaryo(item) {
				if(item.id !== this.karyoId){
					this.switchKaryoHandle(item.id);
				}
			}
		}
	}
</script>

<style lang="scss">
	.karyo-list {
		flex: 1;
		overflow: hidden;
		padding: 0;
		margin: 0;

		.el-scrollbar {
			height: 100%;
		}

		.el-scrollbar__wrap {
			height: 100%;
			overflow-x: hidden;
		}

		.el-scrollbar__thumb {
			background-color: rgba(144, 147, 153, 1);
		}

		.karyo-item {
			overflow: hidden;
			height: 58px;
			border-bottom: 1px solid #7C8087;
			border-top: 1px solid #E5E7EA;
			position: relative;
			display: flex;

			&:first-child {
				border-top: none;
			}
			&.current{
				
				.karyo-info{
					background-color: #51A749;
					color: #ffffff;
				}
			}
			// &.current:after {
			// 	content: '';
			// 	display: block;
			// 	position: absolute;
			// 	right: 0;
			// 	top: 0;
			// 	height: 100%;
			// 	width: 8px;
			// 	background-color: #51A749;
			// }

			.karyo-count {
				position: absolute;
				left: 4px;
				height: 4px;
				font-size: 14px;
				padding: 0 2px;
				height: 18px;
				line-height: 18px;
				color: #1a1a1a;
				border-bottom: 3px solid #faca09;
			}

			.karyo-count.singleSure {
				border-bottom: 3px solid rgba(0, 161, 233, 1);
			}
			.karyo-count.typeSure {
				border-bottom: 3px solid rgba(81, 167, 73, 1) !important;
			}

			.karyo-thumb {
				width: 58px;
				overflow: hidden;
				position: relative;

				img {
					// width: 100%;
					height: 100%;
					left: 50%;
					transform: translateX(-50%);
					position: relative;
				}

				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 4px;
					position: absolute;
					left: 0;
					bottom: 0;
					// background-color: #f23232;
				}
				&.red:after{
					background-color: #F84E4E;
				}
				&.yellow:after{
					background-color: #EFDA57;
				}
				&.blue:after{
					background-color: #5AB9FF;
				}
			}

			.karyo-info {
				flex: 1;
				overflow: hidden;
				padding: 0 8px 0 4px;
				font-size: 13px;
				text-align: left;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.name,
				.expression {
					height: 22px;
					line-height: 22px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}

		&.shrink {
			.karyo-item.current {
				&:after {
					width: 8px;
				}
			}

			.karyo-info {
				padding: 0 8px 0 0px;
			}
		}
	}
</style>
