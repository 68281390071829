import axios from "axios";
import config from "./config.js";
import qs from "qs";
import { json } from "graphlib";
import jsrsasign from "jsrsasign";
import CryptoJS from "crypto-js";
import md5 from "js-md5";
import JSEncrypt from "jsencrypt";
import RELEASE_ENV from "./release.js";

axios.defaults.withCredentials = true;
import Vue from "vue";
import Router from "../../router";
import zh from "../../locales/zh";
import en from "../../locales/en";



// 客户端公钥
let pk =
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCSSrIvlYZr7OqZxuG7uI+IYsPFi677n7TSzWLQ47Q7EjAtclleSULneW5JJ6/3z2Rdfo02K9E3LCzKiLce8Qdc1gyzqJwJRtzcYZeNGFxdmOiIFmSVneMQfkMo8WttWTstLmuJYbhdTW5OYngYwk3zWGN//CLcWBvzJkTODvF3MwIDAQAB\n" +
    "-----END PUBLIC KEY-----";
// 客户端私钥
let priK =
    "-----BEGIN PRIVATE KEY-----\n" +
    "MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAJJKsi+Vhmvs6pnG4bu4j4hiw8WLrvuftNLNYtDjtDsSMC1yWV5JQud5bkknr/fPZF1+jTYr0TcsLMqItx7xB1zWDLOonAlG3Nxhl40YXF2Y6IgWZJWd4xB+Qyjxa21ZOy0ua4lhuF1Nbk5ieBjCTfNYY3/8ItxYG/MmRM4O8XczAgMBAAECgYAHH3nzlS05XgzCNgvRrxfWBTEREk8P02Pji7qO/zdMVQNhgxrU225VhPUYvMT1pPR3FO1dQN7r98uqhOOdrxCzooBv/zjBCjg70luQ9qmkHCcezukk8PaxVSQEhS9lzhEjX2uwEg1DMbaZEehlqOH4sJSRZimpjCQGJoA+30El+QJBANp/OHjVeMeEqvPcx/X/onhdBG+AiUR2I6J9DsDlTCylFe95/Bm10cbx2F1sUTCzTe/IGpJRIyVVEYRKbU+0HL0CQQCrZsW+W0gi0oiqq4BczFfTB044Gl90VJfVKtALd15t5P9fwEEB8j1OqnKcEss8mDGEvtuG76u8enu0PNrj+zqvAkBRssox3Fg5+jBV2NLn97sPWHCFJMGu2KHAaxRuKg7OjrfiAHG0R1j2E438eLjvBcx5gQ/2b+I9Xk6bcZzn9YfdAkAGa7AmpFa8bzSNaCVD1KfJkPF+Koa+3dheNN9cPpl79mMWnCYTQ5bGVp46eH3ChFT+iwPAN0QwqEnwA4OTnLqjAkAWjrlztE0mPeQrN9p30n5wSzGdchjMf9Gt8dGCH/+y2omsg9USfkJ0W32zDjidEgKczDS9v6iegosMy3UCDds4\n" +
    "-----END PRIVATE KEY-----";

// 服务端公钥
let server_public_key =
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCuXB849oVL7uukcgiU79P + yKIol77zFN8oVymHv7hQ8g8dKhVmZARNnUgPqkEWoFJ5Ks50egKBf + Ev2LfH1NCDgWfyAziEntaMhFKwkz8 / dddPOhZSk3DZ + VpC10UMpzuV3jMXEFp2aDsh5nWKn6iHwhvIPs1Or3EAX8CkHBGr2wIDAQAB\n" +
    "-----END PUBLIC KEY-----";

// 随机生成的16位字符串 todo
const randomStr = "1234123412ABCDEF";

// RSA加密AES的key
function RSAEncrypt(word) {
    var crypt = new JSEncrypt();
    crypt.setPublicKey(server_public_key);
    var encryptedData = crypt.encrypt(word);
    return encryptedData;
}

// RSA解密AES的key
function RSADecrypt(word) {
    var crypt = new JSEncrypt();
    //console.log('crypt签名方法', crypt)
    crypt.setPrivateKey(priK);
    var decryptData = crypt.decrypt(word);
    return decryptData;
}

// AES加密
function AESEncrypt(word, key) {
    let key1 = CryptoJS.enc.Utf8.parse(key);
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key1, {
        iv: key1,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString().toUpperCase();
}

// AES解密
function AESDecrypt(word, key) {
    let key1 = CryptoJS.enc.Utf8.parse(key);
    let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(srcs, key1, {
        iv: key1,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}

function strToUtf8Bytes(str) {
    return new TextEncoder().encode(str);
}

function bytesToHex(bytes) {
    return Array.from(bytes).map(byte => byte.toString(16).padStart(2, '0')).join('');
}

// 加密方法
function encryption(dataObj, isEncryption) {
    if (isEncryption) {
        // 传入的对象转字符串
        let bodyDataStr = JSON.stringify(dataObj);

        // rsa加密的秘钥
        let encryptedData = RSAEncrypt(randomStr);

        // AES加密密文
        let requestData = AESEncrypt(bodyDataStr, randomStr);
        //console.log('EncryptData', requestData)

        // 使用jsrsasign库加载私钥
        const key = jsrsasign.KEYUTIL.getKey(priK);
        // 创建签名对象并初始化
        const sig = new jsrsasign.KJUR.crypto.Signature({ 'alg': 'SHA256withRSA' });
        sig.init(key);
        // 将内容转换为UTF-8编码的字节数组并更新签名内容
        const utf8Bytes = strToUtf8Bytes(requestData);
        const hexContent = bytesToHex(utf8Bytes);
        sig.updateHex(hexContent);
        // 生成签名并返回Base64编码的签名结果
        const signed = sig.sign();

        var data = {
            encrypted: encryptedData, // rsa加密的秘钥，服务端rsa公钥加密
            requestData: requestData, // 对请求体AES加密
            sign: jsrsasign.hextob64(signed) // 对requestData签名,rsa私钥签名
        };

        return data;
    } else {
        return dataObj;
    }
}

//实时监听语言切换
function languageChange(res){
    let lang = navigator.language || navigator.userLanguage;
    lang = lang.substr(0, 2);

    let languageType = localStorage.getItem("language") || lang;
    if(languageType =='zh'){
        languageType = zh;
    }
    if(languageType =='en'){
        languageType = en;
    }
    //充值提醒
    Vue.prototype.$confirm(res.message, languageType.accountInfo.prompt, {
        confirmButtonText: languageType.accountInfo.phone_immediately,
        cancelButtonText: languageType.accountInfo.Close_remind,
        type: 'warning',
        closeOnClickModal: false
    }).then(() => {
        Router.app.$router.push({
            name: "个人设置页",
            params: { tabType: "personal_account" },
        });
    }).catch(() => {

    });
}
// 解密方法
function decrypt(res, isDecrypt) {
    if (isDecrypt) {
        // RSA解密encrypted，得出解密key
        let decryptKey = RSADecrypt(res.encrypted);

        // AES解密requestData
        let decryptData = AESDecrypt(res.requestData, decryptKey);

        // 字符串转数组
        let decryptDataObj = JSON.parse(decryptData);

        return decryptDataObj;
    } else {
        return res;
    }
}

let instance = axios.create({
    // 前置
    // baseURL: "http://10.15.11.96:8085",
    baseURL: "https://aiapi.autovision.online",
    // 设置headers
    headers: {
        // 'Content-Type': 'application/json'
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    // 固定的URL 参数
    params: {},
    // 超时设置 60s
    timeout: 60000
    // transformRequest: (data) => {
    // 	return qs.stringify(data)
    // }
});

let encryptionDecrypt = false;
if (
    RELEASE_ENV.includes("dev") ||
    RELEASE_ENV.includes("test")
) {
    encryptionDecrypt = false;
} else {
    encryptionDecrypt = true;
}

instance.interceptors.request.use(
    _config => {
        let myToken;
        if (!!localStorage.getItem("loginObj")) {
            myToken = JSON.parse(localStorage.getItem("loginObj")).token;
        } else {
            myToken = localStorage.getItem("loginObj");
        }
        _config.headers["token"] = myToken;
        _config.data = encryption(
            _config.data || _config.params,
            encryptionDecrypt
        );
        // _config.data.clientType = "";
        // _config.data.env = "";
        _config.data = qs.stringify(_config.data);
        return _config;
    },
    error => {
        //console.log(error)
        return Promise.reject(error);
    }
);

function queryURLParameter(url) {
    const obj = {};
    if (url.indexOf("/#/") < 0) return obj;
    url = url.split("/#/")[1];
    let urlArr = url.split("&");
    urlArr.forEach(function(item) {
        let newArr = item.split("=");
        obj[newArr[0]] = newArr[1];
    });
    return obj;
}
instance.interceptors.response.use(response => {

    let res = response.data;
    if (typeof res === "string") {
        res = JSON.parse(res);
    }
    if (res.code == 1000) {
        let url = window.location.href;
        let arrUrl = url.split("/#/");
        if (arrUrl[1] != "") {
            window.location.href = "/";
            localStorage.clear();
            sessionStorage.clear();
        }
    } else if (res.code == 122) {
        languageChange(res);
    } else {
        // 解密
        if (res.data) {
            res.data = decrypt(res.data, encryptionDecrypt);
        }
    }
    // console.log(res);
    return res;
});
export { instance };
