export const standard = {
	"type": 320,
	"padding": 15,
	"position": [{
			"name": "Chrom01ISCN09",
			"element": [{
				"lxly": [
					60,
					964
				]
			}]
		},
		{
			"name": "Chrom02ISCN09",
			"element": [{
				"lxly": [
					63,
					886
				]
			}]
		},
		{
			"name": "Chrom03ISCN09",
			"element": [{
				"lxly": [
					64,
					747
				]
			}]
		},
		{
			"name": "Chrom04ISCN09",
			"element": [{
				"lxly": [
					64,
					705
				]
			}]
		},
		{
			"name": "Chrom05ISCN09",
			"element": [{
				"lxly": [
					64,
					666
				]
			}]
		},
		{
			"name": "Chrom06ISCN09",
			"element": [{
				"lxly": [
					64,
					656
				]
			}]
		},
		{
			"name": "Chrom07ISCN09",
			"element": [{
				"lxly": [
					65,
					593
				]
			}]
		},
		{
			"name": "Chrom08ISCN09",
			"element": [{
				"lxly": [
					62,
					528
				]
			}]
		},
		{
			"name": "Chrom09ISCN09",
			"element": [{
				"lxly": [
					65,
					525
				]
			}]
		},
		{
			"name": "Chrom10ISCN09",
			"element": [{
				"lxly": [
					66,
					509
				]
			}]
		},
		{
			"name": "Chrom11ISCN09",
			"element": [{
				"lxly": [
					64,
					508
				]
			}]
		},
		{
			"name": "Chrom12ISCN09",
			"element": [{
				"lxly": [
					64,
					493
				]
			}]
		},
		{
			"name": "Chrom13ISCN09",
			"element": [{
				"lxly": [
					64,
					417
				]
			}]
		},
		{
			"name": "Chrom14ISCN09",
			"element": [{
				"lxly": [
					66,
					405
				]
			}]
		},
		{
			"name": "Chrom15ISCN09",
			"element": [{
				"lxly": [
					65,
					383
				]
			}]
		},
		{
			"name": "Chrom16ISCN09",
			"element": [{
				"lxly": [
					64,
					351
				]
			}]
		},
		{
			"name": "Chrom17ISCN09",
			"element": [{
				"lxly": [
					64,
					342
				]
			}]
		},
		{
			"name": "Chrom18ISCN09",
			"element": [{
				"lxly": [
					65,
					307
				]
			}]
		},
		{
			"name": "Chrom19ISCN09",
			"element": [{
				"lxly": [
					63,
					271
				]
			}]
		},
		{
			"name": "Chrom20ISCN09",
			"element": [{
				"lxly": [
					63,
					259
				]
			}]
		},
		{
			"name": "Chrom21ISCN09",
			"element": [{
				"lxly": [
					65,
					200
				]
			}]
		},
		{
			"name": "Chrom22ISCN09",
			"element": [{
				"lxly": [
					65,
					216
				]
			}]
		},
		{
			"name": "ChromXISCN09",
			"element": [{
				"lxly": [
					63,
					602
				]
			}]
		},
		{
			"name": "ChromYISCN09",
			"element": [{
				"lxly": [
					65,
					212
				]
			}]
		}
	],
	"banding": [{
			"name": "Chrom13ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						64,
						21
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						22
					],
					"rxry": [
						64,
						32
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						33
					],
					"rxry": [
						64,
						53
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						54
					],
					"rxry": [
						64,
						61
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						62
					],
					"rxry": [
						64,
						72
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						73
					],
					"rxry": [
						64,
						134
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						135
					],
					"rxry": [
						64,
						156
					]
				},
				{
					"name": "q14",
					"lxly": [
						0,
						157
					],
					"rxry": [
						64,
						223
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						224
					],
					"rxry": [
						64,
						271
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						272
					],
					"rxry": [
						64,
						301
					]
				},
				{
					"name": "q31-33",
					"lxly": [
						0,
						301
					],
					"rxry": [
						64,
						352
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						352
					],
					"rxry": [
						64,
						414
					]
				}
			]
		},
		{
			"name": "Chrom21ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						65,
						21
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						22
					],
					"rxry": [
						65,
						31
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						32
					],
					"rxry": [
						65,
						54
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						54
					],
					"rxry": [
						65,
						63
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						63
					],
					"rxry": [
						65,
						72
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						73
					],
					"rxry": [
						65,
						84
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						85
					],
					"rxry": [
						65,
						123
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						123
					],
					"rxry": [
						65,
						196
					]
				}
			]
		},
		{
			"name": "Chrom09ISCN09",
			"element": [{
					"name": "p24",
					"lxly": [
						0,
						0
					],
					"rxry": [
						65,
						22
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						23
					],
					"rxry": [
						65,
						51
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						51
					],
					"rxry": [
						65,
						79
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						79
					],
					"rxry": [
						65,
						114
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						114
					],
					"rxry": [
						65,
						157
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						158
					],
					"rxry": [
						65,
						165
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						166
					],
					"rxry": [
						65,
						170
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						171
					],
					"rxry": [
						65,
						181
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						182
					],
					"rxry": [
						65,
						233
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						234
					],
					"rxry": [
						65,
						291
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						291
					],
					"rxry": [
						65,
						355
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						356
					],
					"rxry": [
						65,
						387
					]
				},
				{
					"name": "q31-33",
					"lxly": [
						0,
						387
					],
					"rxry": [
						65,
						445
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						445
					],
					"rxry": [
						65,
						521
					]
				}
			]
		},
		{
			"name": "Chrom18ISCN09",
			"element": [{
					"name": "p11.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						65,
						15
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						16
					],
					"rxry": [
						65,
						72
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						73
					],
					"rxry": [
						65,
						80
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						80
					],
					"rxry": [
						65,
						90
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						91
					],
					"rxry": [
						65,
						106
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						106
					],
					"rxry": [
						65,
						147
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						147
					],
					"rxry": [
						65,
						236
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						237
					],
					"rxry": [
						65,
						287
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						288
					],
					"rxry": [
						65,
						303
					]
				}
			]
		},
		{
			"name": "ChromYISCN09",
			"element": [{
					"name": "p11.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						65,
						11
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						12
					],
					"rxry": [
						65,
						61
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						62
					],
					"rxry": [
						65,
						69
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						70
					],
					"rxry": [
						65,
						80
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						81
					],
					"rxry": [
						65,
						88
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						89
					],
					"rxry": [
						65,
						102
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						102
					],
					"rxry": [
						65,
						147
					]
				},
				{
					"name": "q11.24",
					"lxly": [
						0,
						148
					],
					"rxry": [
						65,
						209
					]
				}
			]
		},
		{
			"name": "Chrom14ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						66,
						20
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						21
					],
					"rxry": [
						66,
						29
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						30
					],
					"rxry": [
						66,
						53
					]
				},

				{
					"name": "p11.1",
					"lxly": [
						0,
						54
					],
					"rxry": [
						66,
						60
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						61
					],
					"rxry": [
						66,
						73
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						74
					],
					"rxry": [
						66,
						115
					]
				},
				{
					"name": "q12-21",
					"lxly": [
						0,
						116
					],
					"rxry": [
						66,
						156
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						156
					],
					"rxry": [
						66,
						163
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						164
					],
					"rxry": [
						66,
						178
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						179
					],
					"rxry": [
						66,
						308
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						308
					],
					"rxry": [
						66,
						340
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						340
					],
					"rxry": [
						66,
						401
					]
				}
			]
		},
		{
			"name": "Chrom16ISCN09",
			"element": [{
					"name": "p13.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						64,
						25
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						25
					],
					"rxry": [
						64,
						42
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						43
					],
					"rxry": [
						64,
						74
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						75
					],
					"rxry": [
						64,
						97
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						98
					],
					"rxry": [
						64,
						136
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						98
					],
					"rxry": [
						64,
						143
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						144
					],
					"rxry": [
						64,
						154
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						155
					],
					"rxry": [
						64,
						186
					]
				},
				{
					"name": "q12-13",
					"lxly": [
						0,
						187
					],
					"rxry": [
						64,
						226
					]
				},
				{
					"name": "q21-23",
					"lxly": [
						0,
						227
					],
					"rxry": [
						64,
						275
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						276
					],
					"rxry": [
						64,
						347
					]
				}
			]
		},
		{
			"name": "Chrom01ISCN09",
			"element": [{
					"name": "p36.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						60,
						21
					]
				},
				{
					"name": "p36.2",
					"lxly": [
						0,
						22
					],
					"rxry": [
						60,
						37
					]
				},
				{
					"name": "p36.1",
					"lxly": [
						0,
						38
					],
					"rxry": [
						60,
						112
					]
				},
				{
					"name": "p35",
					"lxly": [
						0,
						112
					],
					"rxry": [
						60,
						126
					]
				},
				{
					"name": "p34",
					"lxly": [
						0,
						126
					],
					"rxry": [
						60,
						182
					]
				},
				{
					"name": "p33",
					"lxly": [
						0,
						183
					],
					"rxry": [
						60,
						197
					]
				},
				{
					"name": "p32",
					"lxly": [
						0,
						197
					],
					"rxry": [
						60,
						225
					]
				},
				{
					"name": "p31",
					"lxly": [
						0,
						225
					],
					"rxry": [
						60,
						296
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						296
					],
					"rxry": [
						60,
						326
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						326
					],
					"rxry": [
						60,
						376
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						376
					],
					"rxry": [
						60,
						444
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						445
					],
					"rxry": [
						60,
						452
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						453
					],
					"rxry": [
						60,
						461
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						462
					],
					"rxry": [
						60,
						474
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						475
					],
					"rxry": [
						60,
						561
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						562
					],
					"rxry": [
						60,
						667
					]
				},
				{
					"name": "q22-24",
					"lxly": [
						0,
						668
					],
					"rxry": [
						60,
						692
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						693
					],
					"rxry": [
						60,
						715
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						715
					],
					"rxry": [
						60,
						765
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						765
					],
					"rxry": [
						60,
						814
					]
				},
				{
					"name": "q41",
					"lxly": [
						0,
						814
					],
					"rxry": [
						60,
						843
					]
				},
				{
					"name": "q42",
					"lxly": [
						0,
						843
					],
					"rxry": [
						60,
						941
					]
				},
				{
					"name": "q43-44",
					"lxly": [
						0,
						942
					],
					"rxry": [
						60,
						961
					]
				}
			]
		},
		{
			"name": "ChromXISCN09",
			"element": [{
					"name": "p22.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						63,
						10
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						11
					],
					"rxry": [
						63,
						27
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						27
					],
					"rxry": [
						63,
						81
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						82
					],
					"rxry": [
						63,
						130
					]
				},
				{
					"name": "p11.2-11.4",
					"lxly": [
						0,
						130
					],
					"rxry": [
						63,
						209
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						210
					],
					"rxry": [
						63,
						214
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						215
					],
					"rxry": [
						63,
						226
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						227
					],
					"rxry": [
						63,
						296
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						296
					],
					"rxry": [
						63,
						360
					]
				},
				{
					"name": "q22-24",
					"lxly": [
						0,
						361
					],
					"rxry": [
						63,
						490
					]
				},
				{
					"name": "q25-27",
					"lxly": [
						0,
						490
					],
					"rxry": [
						63,
						553
					]
				},
				{
					"name": "q28",
					"lxly": [
						0,
						553
					],
					"rxry": [
						63,
						598
					]
				}
			]
		},
		{
			"name": "Chrom02ISCN09",
			"element": [{
					"name": "p25",
					"lxly": [
						0,
						0
					],
					"rxry": [
						63,
						16
					]
				},
				{
					"name": "p24",
					"lxly": [
						0,
						16
					],
					"rxry": [
						63,
						43
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						44
					],
					"rxry": [
						63,
						128
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						129
					],
					"rxry": [
						63,
						148
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						149
					],
					"rxry": [
						63,
						179
					]
				},
				{
					"name": "p14-16",
					"lxly": [
						0,
						179
					],
					"rxry": [
						63,
						220
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						220
					],
					"rxry": [
						63,
						287
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						288
					],
					"rxry": [
						63,
						314
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						315
					],
					"rxry": [
						63,
						332
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						333
					],
					"rxry": [
						63,
						340
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						341
					],
					"rxry": [
						63,
						352
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						353
					],
					"rxry": [
						63,
						419
					]
				},
				{
					"name": "q12-14",
					"lxly": [
						0,
						421
					],
					"rxry": [
						63,
						467
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						468
					],
					"rxry": [
						63,
						525
					]
				},
				{
					"name": "q22-24",
					"lxly": [
						0,
						526
					],
					"rxry": [
						63,
						600
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						600
					],
					"rxry": [
						63,
						654
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						654
					],
					"rxry": [
						63,
						715
					]
				},
				{
					"name": "q33",
					"lxly": [
						0,
						715
					],
					"rxry": [
						63,
						742
					]
				},
				{
					"name": "q34-36",
					"lxly": [
						0,
						743
					],
					"rxry": [
						63,
						783
					]
				},
				{
					"name": "q37",
					"lxly": [
						0,
						783
					],
					"rxry": [
						63,
						883
					]
				}
			]
		},
		{
			"name": "Chrom15ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						65,
						20
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						21
					],
					"rxry": [
						65,
						33
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						34
					],
					"rxry": [
						65,
						55
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						56
					],
					"rxry": [
						65,
						62
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						63
					],
					"rxry": [
						65,
						75
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						76
					],
					"rxry": [
						65,
						114
					]
				},
				{
					"name": "q12-14",
					"lxly": [
						0,
						115
					],
					"rxry": [
						65,
						126
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						125
					],
					"rxry": [
						65,
						153
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						154
					],
					"rxry": [
						65,
						197
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						197
					],
					"rxry": [
						65,
						298
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						299
					],
					"rxry": [
						65,
						306
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						306
					],
					"rxry": [
						65,
						338
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						339
					],
					"rxry": [
						65,
						359
					]
				},
				{
					"name": "q26",
					"lxly": [
						0,
						360
					],
					"rxry": [
						65,
						379
					]
				}
			]
		},
		{
			"name": "Chrom05ISCN09",
			"element": [{
					"name": "p15",
					"lxly": [
						0,
						0
					],
					"rxry": [
						64,
						71
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						71
					],
					"rxry": [
						64,
						120
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						120
					],
					"rxry": [
						64,
						154
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						155
					],
					"rxry": [
						64,
						163
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						164
					],
					"rxry": [
						64,
						173
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						174
					],
					"rxry": [
						64,
						185
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						186
					],
					"rxry": [
						64,
						202
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						203
					],
					"rxry": [
						64,
						229
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						230
					],
					"rxry": [
						64,
						305
					]
				},
				{
					"name": "qi-21",
					"lxly": [
						0,
						306
					],
					"rxry": [
						64,
						368
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						368
					],
					"rxry": [
						64,
						395
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						395
					],
					"rxry": [
						64,
						445
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						444
					],
					"rxry": [
						64,
						561
					]
				},
				{
					"name": "q32-34",
					"lxly": [
						0,
						561
					],
					"rxry": [
						64,
						602
					]
				},
				{
					"name": "q35",
					"lxly": [
						0,
						603
					],
					"rxry": [
						64,
						663
					]
				}
			]
		},
		{
			"name": "Chrom11ISCN09",
			"element": [{
					"name": "p15",
					"lxly": [
						0,
						0
					],
					"rxry": [
						64,
						94
					]
				},
				{
					"name": "p12-14",
					"lxly": [
						0,
						95
					],
					"rxry": [
						64,
						144
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						144
					],
					"rxry": [
						64,
						193
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						194
					],
					"rxry": [
						64,
						202
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						203
					],
					"rxry": [
						64,
						217
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						217
					],
					"rxry": [
						64,
						228
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						229
					],
					"rxry": [
						64,
						317
					]
				},
				{
					"name": "q14-22",
					"lxly": [
						0,
						317
					],
					"rxry": [
						64,
						400
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						400
					],
					"rxry": [
						64,
						471
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						472
					],
					"rxry": [
						64,
						485
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						486
					],
					"rxry": [
						64,
						504
					]
				}
			]
		},
		{
			"name": "Chrom22ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0

					],
					"rxry": [
						65,
						20
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						21
					],
					"rxry": [
						65,
						30
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						33
					],
					"rxry": [
						65,
						53
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						54
					],
					"rxry": [
						65,
						62
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						63

					],
					"rxry": [
						65,
						71
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						72
					],
					"rxry": [
						65,
						115
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						116
					],
					"rxry": [
						65,
						137
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						138
					],
					"rxry": [
						65,
						212
					]
				}
			]
		},
		{
			"name": "Chrom17ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						64,
						58
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						59
					],
					"rxry": [
						64,
						85
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						86
					],
					"rxry": [
						64,
						107
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						108
					],
					"rxry": [
						64,
						115
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						116
					],
					"rxry": [
						64,
						125
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						126
					],
					"rxry": [
						64,
						145
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						146
					],
					"rxry": [
						64,
						163
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						164
					],
					"rxry": [
						64,
						246
					]
				},
				{
					"name": "q22-24",
					"lxly": [
						0,
						246
					],
					"rxry": [
						64,
						301
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						301
					],
					"rxry": [
						64,
						338
					]
				}
			]
		},
		{
			"name": "Chrom08ISCN09",
			"element": [{
					"name": "p23",
					"lxly": [
						0,
						0
					],
					"rxry": [
						62,
						33
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						34
					],
					"rxry": [
						62,
						50
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						51
					],
					"rxry": [
						62,
						120
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						121
					],
					"rxry": [
						62,
						134
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						134
					],
					"rxry": [
						62,
						154
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						155
					],
					"rxry": [
						62,
						161
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						162
					],
					"rxry": [
						62,
						171
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						172
					],
					"rxry": [
						62,
						184
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						185
					],
					"rxry": [
						62,
						206
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						207
					],
					"rxry": [
						62,
						238
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						237
					],
					"rxry": [
						62,
						287
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						286
					],
					"rxry": [
						62,
						355
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						355
					],
					"rxry": [
						62,
						417
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						418
					],
					"rxry": [
						62,
						525
					]
				}
			]
		},
		{
			"name": "Chrom03ISCN09",
			"element": [{
					"name": "p26",
					"lxly": [
						0,
						0
					],
					"rxry": [
						64,
						13
					]
				},
				{
					"name": "p25",
					"lxly": [
						0,
						13
					],
					"rxry": [
						64,
						37
					]
				},
				{
					"name": "p22-24",
					"lxly": [
						0,
						38
					],
					"rxry": [
						64,
						88
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						88
					],
					"rxry": [
						64,
						243
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						244
					],
					"rxry": [
						64,
						261
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						262
					],
					"rxry": [
						64,
						304
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						304
					],
					"rxry": [
						64,
						329
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						330
					],
					"rxry": [
						64,
						341
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						342
					],
					"rxry": [
						64,
						352
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						353
					],
					"rxry": [
						64,
						369
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						370
					],
					"rxry": [
						64,
						403
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						403
					],
					"rxry": [
						64,
						436
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						436
					],
					"rxry": [
						64,
						531
					]
				},
				{
					"name": "q22-24",
					"lxly": [
						0,
						531
					],
					"rxry": [
						64,
						567
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						567
					],
					"rxry": [
						64,
						591
					]
				},
				{
					"name": "q26",
					"lxly": [
						0,
						591
					],
					"rxry": [
						64,
						648
					]
				},
				{
					"name": "q27",
					"lxly": [
						0,
						649
					],
					"rxry": [
						64,
						683
					]
				},
				{
					"name": "q28",
					"lxly": [
						0,
						684
					],
					"rxry": [
						64,
						697
					]
				},
				{
					"name": "q29",
					"lxly": [
						0,
						698
					],
					"rxry": [
						64,
						744
					]
				}
			]
		},
		{
			"name": "Chrom07ISCN09",
			"element": [{
					"name": "p22",
					"lxly": [
						0,
						0
					],
					"rxry": [
						65,
						15
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						16
					],
					"rxry": [
						65,
						61
					]
				},
				{
					"name": "p15",
					"lxly": [
						0,
						61
					],
					"rxry": [
						65,
						123
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						124
					],
					"rxry": [
						65,
						137
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						138
					],
					"rxry": [
						65,
						184
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						185
					],
					"rxry": [
						65,
						195
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						196
					],
					"rxry": [
						65,
						205
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						206
					],
					"rxry": [
						65,
						222
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						223
					],
					"rxry": [
						65,
						285
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						285
					],
					"rxry": [
						65,
						342
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						342
					],
					"rxry": [
						65,
						404
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						404
					],
					"rxry": [
						65,
						454
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						454
					],
					"rxry": [
						65,
						484
					]
				},
				{
					"name": "q33-35",
					"lxly": [
						0,
						486
					],
					"rxry": [
						65,
						505
					]
				},
				{
					"name": "q36",
					"lxly": [
						0,
						506
					],
					"rxry": [
						65,
						590
					]
				}
			]
		},
		{
			"name": "Chrom12ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						64,
						43
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						43
					],
					"rxry": [
						64,
						87
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						87
					],
					"rxry": [
						64,
						121
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						122
					],
					"rxry": [
						64,
						128
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						129
					],
					"rxry": [
						64,
						141
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						142
					],
					"rxry": [
						64,
						150
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						150
					],
					"rxry": [
						64,
						211
					]
				},
				{
					"name": "q14",
					"lxly": [
						0,
						211
					],
					"rxry": [
						64,
						258
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						259
					],
					"rxry": [
						64,
						265
					]
				},
				{
					"name": "q21-23",
					"lxly": [
						0,
						266
					],
					"rxry": [
						64,
						330
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						330
					],
					"rxry": [
						64,
						413
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						414
					],
					"rxry": [
						64,
						431
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						432
					],
					"rxry": [
						64,
						490
					]
				}
			]
		},
		{
			"name": "Chrom19ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						63,
						100
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						101
					],
					"rxry": [
						63,
						111
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						112
					],
					"rxry": [
						63,
						121
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						122
					],
					"rxry": [
						63,
						130
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						131
					],
					"rxry": [
						63,
						146
					]
				},
				{
					"name": "q13.1-13.3",
					"lxly": [
						0,
						147
					],
					"rxry": [
						63,
						252
					]
				},
				{
					"name": "q13.4",
					"lxly": [
						0,
						252
					],
					"rxry": [
						63,
						267
					]
				}
			]
		},
		{
			"name": "Chrom20ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						63,
						26
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						27
					],
					"rxry": [
						63,
						61
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						62
					],
					"rxry": [
						63,
						105
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						106
					],
					"rxry": [
						63,
						113
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						114
					],
					"rxry": [
						63,
						123
					]
				},
				{
					"name": "q11.2-13.1",
					"lxly": [
						0,
						124
					],
					"rxry": [
						63,
						197
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						197
					],
					"rxry": [
						63,
						227
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						227
					],
					"rxry": [
						63,
						255
					]
				}
			]
		},
		{
			"name": "Chrom04ISCN09",
			"element": [{
					"name": "p16",
					"lxly": [
						0,
						0
					],
					"rxry": [
						64,
						86
					]
				},
				{
					"name": "p15",
					"lxly": [
						0,
						86
					],
					"rxry": [
						64,
						142
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						143
					],
					"rxry": [
						64,
						180
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						181
					],
					"rxry": [
						64,
						188
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						189
					],
					"rxry": [
						64,
						200
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						201
					],
					"rxry": [
						64,
						220
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						221
					],
					"rxry": [
						64,
						255
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						256
					],
					"rxry": [
						64,
						339
					]
				},
				{
					"name": "q22-24",
					"lxly": [
						0,
						339
					],
					"rxry": [
						64,
						399
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						399
					],
					"rxry": [
						64,
						437
					]
				},
				{
					"name": "q26-28",
					"lxly": [
						0,
						438
					],
					"rxry": [
						64,
						507
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						508
					],
					"rxry": [
						64,
						613
					]
				},
				{
					"name": "q32-34",
					"lxly": [
						0,
						613
					],
					"rxry": [
						64,
						663
					]
				},
				{
					"name": "q35",
					"lxly": [
						0,
						663
					],
					"rxry": [
						64,
						701
					]
				}
			]
		},
		{
			"name": "Chrom06ISCN09",
			"element": [{
					"name": "p25",
					"lxly": [
						0,
						0
					],
					"rxry": [
						64,
						20
					]
				},
				{
					"name": "p24",
					"lxly": [
						0,
						21
					],
					"rxry": [
						64,
						38
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						39
					],
					"rxry": [
						64,
						65
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						65
					],
					"rxry": [
						64,
						94
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						94
					],
					"rxry": [
						64,
						177
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						177
					],
					"rxry": [
						64,
						211
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						212
					],
					"rxry": [
						64,
						222
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						223
					],
					"rxry": [
						64,
						237
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						238
					],
					"rxry": [
						64,
						265
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						265
					],
					"rxry": [
						64,
						326
					]
				},
				{
					"name": "q14-16",
					"lxly": [
						0,
						326
					],
					"rxry": [
						64,
						361
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						361
					],
					"rxry": [
						64,
						413
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						413
					],
					"rxry": [
						64,
						456
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						456
					],
					"rxry": [
						64,
						480
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						480
					],
					"rxry": [
						64,
						519
					]
				},
				{
					"name": "q25-27",
					"lxly": [
						0,
						520
					],
					"rxry": [
						64,
						652
					]
				}
			]
		},
		{
			"name": "Chrom10ISCN09",
			"element": [{
					"name": "p15",
					"lxly": [
						0,
						0
					],
					"rxry": [
						66,
						16
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						17
					],
					"rxry": [
						66,
						30
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						31
					],
					"rxry": [
						66,
						75
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						76
					],
					"rxry": [
						66,
						98
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						97
					],
					"rxry": [
						66,
						158
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						159
					],
					"rxry": [
						66,
						163
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						164
					],
					"rxry": [
						66,
						173
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						174
					],
					"rxry": [
						66,
						198
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						200
					],
					"rxry": [
						66,
						247
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						248
					],
					"rxry": [
						66,
						311
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						312
					],
					"rxry": [
						66,
						353
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						354
					],
					"rxry": [
						66,
						395
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						396
					],
					"rxry": [
						66,
						437
					]
				},
				{
					"name": "q26",
					"lxly": [
						0,
						438
					],
					"rxry": [
						66,
						505
					]
				}
			]
		}
	]
}
