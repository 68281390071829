<template>
    <div class="uploadCase">
        <div class="btn determineNomal" @mouseover="patientMenuVisible = true" @mouseout="patientMenuVisible = false">
            <img src="../../assets/images/analysissystem/upload.svg" />
            <span class="caseAllocationText">{{$t('case_overview.case_manage')}}</span>
            <ul class="patients-manage-dropmenu" v-show="patientMenuVisible">
                <li>
                    <span>{{$t('case_manage.case_import')}}</span><i class="el-icon-caret-right"></i>
                    <ul class="patients-manage-submenu" style="width: 170px">
                        <li @click="handleCommand('importCasesFolder')">
                            <span>{{$t('case_manage.import_single_case')}}</span>
                        </li>
                        <li @click="handleCommand('importCasesLibrary')">
                            <span>{{$t('case_manage.import_case_data')}}</span>
                        </li>
                        <li @click="handleCommand('importZipFile')">
                            <span>{{$t('case_manage.import_autovision_case')}}</span>
                        </li>
                    </ul>
                </li>
                <li>
                    <span>{{$t('case_manage.defined_import')}}</span><i class="el-icon-caret-right"></i>
                    <ul class="patients-manage-submenu">
                        <li @click="handleCommand('importFolder')">
                            <span>{{$t('case_manage.import_file')}}</span>
                        </li>
                    </ul>
                </li>
                <li @click="exportPatients"><span>{{$t('case_manage.case_output')}}</span></li>
            </ul>
            <div style="display: none;">
                <input type="file" id="casesFilesInput" webkitdirectory="" ref="casesFilesInput" @change="filterCaseByType" />
                <input type="file" id="importZipFile" accept=".autovision" multiple="multiple" ref="importZipFile" @change="filterAutoVision" />
            </div>
        </div>
        <!-- autovision病例上传界面 -->
        <el-dialog :title="$t('case_manage.autovision_upload')" width="684px" class="upload-dialog" :visible.sync="zipUploadManager.visible" :before-close="stopZipUpload" :close-on-click-modal="false">
            <div class="upload-total">
                {{$t('case_manage.total')}} {{ zipUploadManager.totalCase }} {{$t('case_manage.upload_progress')}}
            </div>
            <div class="upload-progress">
                <el-progress :percentage="zipUploadManager.percent" v-if="zipUploadManager.status < 2"></el-progress>
                <el-progress :percentage="zipUploadManager.percent" v-if="zipUploadManager.status === 2" status="success"></el-progress>
                <el-progress :percentage="zipUploadManager.percent" v-if="zipUploadManager.status === 3" status="exception"></el-progress>
                <div class="upload-status" v-if="zipUploadManager.status === 0 && zipUploadManager.totalSize > 0">{{$t('case_manage.uploading')}}</div>
                <div class="upload-status" v-if="zipUploadManager.status === 1">{{$t('case_manage.analysising')}}</div>
                <div class="upload-status success" v-if="zipUploadManager.status === 2">{{$t('case_manage.analysis_complete')}}</div>
                <div class="upload-status retry" v-if="zipUploadManager.status === 3" @click="retryZipUpload">{{$t('case_manage.retry')}}</div>
            </div>
            <div class="upload-remaining" v-if="zipUploadManager.status < 1 &&zipUploadManager.totalSize > 0">
                {{$t('case_manage.upload_time')}}：<em>{{ zipUploadManager.remaining }}</em>
            </div>
            <div class="upload-remaining error" v-if="zipUploadManager.status === 3">
                {{$t('case_manage.upload_failed')}}
            </div>
            <div class="upload-files">
                <el-scrollbar :native="false" id="zipList" ref="zipList">
                    <ul class="zip-list" :style="{
                        height: zipUploadManager.files.length * 36 + 'px'
                    }">
                        <li class="zip-list-item" v-for="(file, index) in zipUploadManager.files" :key="index">
                            <div class="zip-item-name">{{ file.patient }}</div>
                            <div class="zip-item-exist" v-if="file.isExist &&file.hasReplace === undefined">
                                <span>{{$t('case_manage.cases_exist')}}</span>
                            </div>
                            <div class="zip-item-progress" v-if="!file.isExist || file.hasReplace >= 0">
                                <el-progress :percentage="file.percent" :status="file.state" v-if="file.state"></el-progress>
                                <el-progress :percentage="file.percent" v-else></el-progress>
                            </div>
                            <div class="zip-item-status" v-if="(!file.isExist || file.hasReplace >= 0) && file.status === 0">
                                {{$t('case_manage.waiting_upload')}}
                            </div>
                            <div class="zip-item-status" v-if="(!file.isExist || file.hasReplace >= 0) && file.status === 1">
                                {{ file.speed }}
                            </div>
                            <div class="zip-item-status" v-if="(!file.isExist || file.hasReplace >= 0) && file.status === 2">
                                {{$t('case_manage.upload_completely')}}
                            </div>
                            <div class="zip-item-status error" v-if="(!file.isExist || file.hasReplace >= 0) && file.status === 3">
                                {{$t('case_manage.upload_failed')}}
                            </div>
                            <div class="zip-item-status" v-if="(!file.isExist || file.hasReplace >= 0) && file.status === 4">
                                {{$t('case_manage.analysising')}}
                            </div>
                            <div class="zip-item-status success" v-if="(!file.isExist || file.hasReplace >= 0) && file.status === 5">
                                {{$t('case_manage.analysis_complete')}}
                            </div>
                            <div class="zip-item-size" v-if="!file.isExist || file.hasReplace >= 0">
                                {{ file.sizeFormat }}
                            </div>
                        </li>
                    </ul>
                </el-scrollbar>
            </div>
            <span slot="footer" class="dialog-footer">
                <div class="footer-tips">
                    {{$t('case_manage.upload_successfully')}} {{ zipUploadManager.success }} {{$t('case_manage.last')}} {{zipUploadManager.left}} {{$t('case_manage.upload_failed_nums')}} {{ zipUploadManager.fail }} {{$t('case_manage.pieces')}}
                </div>
                <div class="btn btn-default" @click="stopZipUpload" v-if="zipUploadManager.status < 2 && zipUploadManager.totalSize > 0">
                    {{$t('case_manage.stop')}}
                </div>
                <button class="btn btn-primary" :disabled="zipUploadManager.status < 2 && zipUploadManager.totalSize > 0" @click="zipUploadFinished">
                    {{$t('case_manage.confirm')}}
                </button>
            </span>
        </el-dialog>
        <!-- 重复病例提示 -->
        <el-dialog title="" :visible.sync="existTipVisible" width="450px" custom-class="existTip" :close-on-click-modal="false">
            <div class="existTip-title">{{$t('case_manage.upload_remind')}}</div>
            <div class="existTip-content">
                <i class="el-icon-warning"></i>
                <span v-html="existTipContent"></span>
            </div>
            <span slot="footer" class="dialog-footer">
                <div class="is-apply-all">
                    <el-checkbox v-model="isApplyAll">{{$t('case_manage.use_all')}}</el-checkbox>
                </div>
                <div class="btn btn-default" @click="existKepBoth">
                    {{$t('case_manage.keep_both')}}
                </div>
                <div class="btn btn-default" @click="existCancel">{{$t('case_manage.cancel')}}</div>
                <button class="btn btn-primary" @click="existReplace">
                    {{$t('case_manage.change')}}
                </button>
            </span>
        </el-dialog>

        <!-- 文件夹病例上传界面 -->
        <el-dialog :title="$t('case_manage.cases_upload')" :visible.sync="caseUploadManager.visible" width="684px" class="upload-dialog" :before-close="beforeCaseUploadClose" :close-on-click-modal="false">
            <div class="patients-none" v-if="caseUploadManager.phase === -1">
                <span>{{$t('case_manage.unfind_rechoose')}}</span>
                <div class="btn btn-primary" @click="reChooseWhenCaseEmpty">
                    {{$t('case_manage.rechoose')}}
                </div>
            </div>
            <div class="patients-searching" v-if="caseUploadManager.phase === 0">
                <span>{{$t('case_manage.searching_case')}}</span>
                <i class="el-icon-loading"></i>
            </div>
            <div class="patients-screening" v-if="caseUploadManager.phase === 1">
                <el-row>
                    <label>{{$t('case_manage.only_show_choosed_cases')}}</label>
                    <el-date-picker v-model="caseUploadManager.filterDate" type="daterange" :firstDayOfWeek="7" range-separator="~" unlink-panels @change="onDataRangePick" :picker-options="pickerOptions" :start-placeholder="$t('select_button.startDate')" :end-placeholder="$t('select_button.endDate')">
                    </el-date-picker>
                </el-row>
                <el-table ref="multipleTable" :data="caseUploadManager.showlist" tooltip-effect="dark" stripe max-height="300" style="width: 100%; border: 1px solid #ebeef5; border-width: 1px 1px 0 1px; margin-top: 10px;" @selection-change="handleSelectionChange" :empty-text="$t('checkhtml.no_data')">
                    <el-table-column type="selection" width="55" :label="$t('checkbox_button.all')"></el-table-column>
                    <el-table-column :label="$t('popup.case_allot.case_code')" prop="patient"></el-table-column>
                    <el-table-column prop="recentTimeFormat" :label="$t('case_manage.end_time')" width="180"></el-table-column>
                    <el-table-column prop="totalSizeFormat" width="100" :label="$t('case_manage.size')"></el-table-column>
                </el-table>
                <div class="patients-screening-footer">
                    <div class="patients-selection">
                        {{$t('case_manage.choosed')}}<em>{{ caseUploadManager.uplist.length }}</em>{{$t('case_manage.all_cases')}}<span>{{caseUploadManager.upTotalSize || "0M"}}</span>
                    </div>
                    <div class="patients-screening-btns">
                        <button class="btn btn-default" @click="caseUploadManager = getDefaultCaseUploadManager()">{{$t('case_manage.cancel')}}</button>
                        <button class="btn btn-primary" :disabled="!caseUploadManager.uplist.length" @click="doCaseUpload">{{$t('case_manage.confirm')}}</button>
                    </div>
                </div>
            </div>
            <div class="patients-upload" v-if="caseUploadManager.phase >= 2">
                <div class="patients-title">
                    {{$t('case_manage.total')}}{{ caseUploadManager.uplist.length }}{{$t('case_manage.cases')}},{{caseUploadManager.totalImages}}{{$t('case_manage.images_upload_progress')}}
                </div>
                <div class="patients-upload-progress">
                    <el-progress :percentage="caseUploadManager.percent" v-if="caseUploadManager.phase < 3"></el-progress>
                    <el-progress :percentage="caseUploadManager.percent" v-if="caseUploadManager.phase === 3" status="success"></el-progress>
                    <el-progress :percentage="caseUploadManager.percent" v-if="caseUploadManager.phase === 4" status="exception"></el-progress>
                    <div class="upload-status" v-if="caseUploadManager.phase === 2">{{$t('case_manage.uploading')}}</div>
                    <div class="upload-status success" v-if="caseUploadManager.phase === 3">{{$t('case_manage.upload_completely')}}</div>
                    <div class="upload-status retry" v-if="caseUploadManager.phase === 4" @click="retryCasesUpload">{{$t('case_manage.retry')}}</div>
                </div>
                <div class="patients-upload-remaining" v-if="caseUploadManager.phase === 2">
                    {{$t('case_manage.time_remaining')}}<em>{{ caseUploadManager.remaining }}</em>
                </div>
                <div class="patients-upload-fail" v-if="caseUploadManager.phase === 4">
                    {{$t('case_manage.upload_failed')}}
                </div>
                <div class="patients-upload-remaining" v-if="caseUploadManager.phase === 3">
                    &nbsp;
                </div>
                <ul class="patients-upload-list">
                    <li v-for="(item, index) in caseUploadManager.uplist" :key="index">
                        <div class="patient-name">{{ item.patient }}</div>
                        <div class="patient-progress">
                            <el-progress :percentage="item.percent" v-if="item.status < 2 || item.saveing"></el-progress>
                            <el-progress :percentage="item.percent" v-if="item.status === 2 && !item.saveing" status="success"></el-progress>
                            <el-progress :percentage="item.percent" v-if="item.status === 3" status="exception"></el-progress>
                        </div>
                        <div class="patient-speed" v-if="item.status === 0 && !item.overLimit">
                            {{$t('case_manage.waiting')}}
                        </div>
                        <el-tooltip class="item" effect="dark" :content="item.overLimitTip" placement="top-start" v-if="item.status === 0 && item.overLimit">
                            <div class="patient-speed fail">
                                {{$t('case_manage.overlimit')}}
                            </div>
                        </el-tooltip>
                        <div class="patient-speed" v-if="item.status === 1 && !item.saveing">
                            {{ item.speed }}
                        </div>
                        <div class="patient-speed" v-if="item.status === 1 && item.saveing">
                            {{$t('case_manage.saving')}}
                        </div>
                        <div class="patient-speed success" v-if="item.status === 2">
                            {{$t('case_manage.upload_success')}}
                        </div>
                        <div class="patient-speed fail" v-if="item.status === 3">
                            {{ item.saveTryCount ? $t('case_manage.save_failed'):$t('case_manage.upload_failed') }}
                        </div>
                        <div class="patient-size">
                            {{ item.totalSizeFormat }}
                        </div>
                    </li>
                </ul>
            </div>

            <span slot="footer" class="dialog-footer" v-if="caseUploadManager.phase >= 2">
                <div class="footer-tips">
                    {{$t('case_manage.upload_successfully')}}{{ caseUploadManager.successCount }}{{$t('case_manage.last')}}{{caseUploadManager.leftCount}}{{$t('case_manage.upload_failed_nums')}}{{ caseUploadManager.failCount }}{{$t('case_manage.pieces')}}
                </div>
                <div class="btn btn-default" @click="stopCaseUpload" v-if="caseUploadManager.phase == 2">
                    {{$t('case_manage.stop')}}
                </div>
                <button class="btn btn-primary" :disabled="caseUploadManager.phase == 2" @click="caseUploadFinish">
                    {{$t('case_manage.confirm')}}
                </button>
            </span>
        </el-dialog>

        <!-- 导出病例界面 -->
        <el-dialog :title="$t('case_manage.case_output')" :visible.sync="exportManager.visible" width="684px" class="upload-dialog" :before-close="beforeExportClose" :close-on-click-modal="false">
            <div class="export-screening" v-if="exportManager.phase === 1">
                <div class="export-search">
                    <input type="text" v-model="exportManager.keyword" :placeholder="$t('case_manage.search_by_casecode')" />
                    <button class="btn btn-primary" @click="searchCases">
                        {{$t('case_manage.searching')}}
                    </button>
                </div>
                <div class="export-choosed-cases">
                    {{$t('case_manage.choosed')}}<em> {{ exportManager.downloadList.length }} </em>{{$t('case_manage.cases')}}
                </div>
                <el-table ref="exportTable" :data="exportManager.showlist" tooltip-effect="dark" stripe max-height="300" style="width: 100%; border: 1px solid #ebeef5; border-width: 1px 1px 0 1px; margin-top: 10px;" @selection-change="exportSelectionChange" :empty-text="$t('checkhtml.no_data')">
                    <el-table-column type="selection" width="55" :label="$t('checkbox_button.all')"></el-table-column>
                    <el-table-column :label="$t('popup.case_allot.case_code')" prop="name"></el-table-column>
                    <el-table-column prop="recentTimeFormat" :label="$t('case_manage.case_edit_time')" width="300"></el-table-column>
                </el-table>
                <div class="export-switch-type">
                    {{$t('case_manage.only_export_choosed_images')}}
                </div>
                <div class="export-switch-radio">
                    <el-radio v-model="exportManager.exportType" :label="2">{{$t('case_manage.confirmed_images')}}</el-radio>
                    <el-radio v-model="exportManager.exportType" :label="1">{{$t('case_manage.export_all_images')}}</el-radio>
                </div>
                <div class="export-switch-radio">
                    <el-radio v-model="exportManager.exportType" :label="3">{{$t('case_manage.only_confimed_array_images')}}</el-radio>
                </div>
                <div class="export-screening-btns">
                    <button class="btn btn-default" @click="beforeExportClose">{{$t('case_manage.cancel')}}</button>
                    <button class="btn btn-primary" :disabled="!exportManager.downloadList.length" @click="confirmBeformExport">{{$t('case_manage.export')}}</button>
                </div>
            </div>
            <div class="export-download" v-if="exportManager.phase >= 2">
                <div class="patients-title">
                    {{$t('case_manage.total')}}{{exportManager.downloadList.length}}{{$t('case_manage.export_progress')}}
                </div>
                <div class="export-total-progress">
                    <el-progress :percentage="exportManager.percent" v-if="exportManager.phase < 3"></el-progress>
                    <el-progress :percentage="exportManager.percent" v-if="exportManager.phase === 3" status="success"></el-progress>
                    <el-progress :percentage="exportManager.percent" v-if="exportManager.phase === 4" status="exception"></el-progress>
                    <div class="export-status" v-if="exportManager.phase === 2">
                        {{$t('case_manage.exporting')}}
                    </div>
                    <div class="export-status success" v-if="exportManager.phase === 3">
                        {{$t('case_manage.export_completely')}}
                    </div>
                    <div class="export-status fail" v-if="exportManager.phase === 4">
                        {{$t('case_manage.export_failed')}}
                    </div>
                </div>
                <div class="export-case-list">
                    <div class="export-case-item" v-for="item in exportManager.downloadList" :key="item.id">
                        <div class="case-name">{{ item.name }}</div>
                        <div class="case-progress">
                            <el-progress :percentage="item.percent" v-if="item.status < 2"></el-progress>
                            <el-progress :percentage="item.percent" v-if="item.status === 2" status="success"></el-progress>
                            <el-progress :percentage="item.percent" v-if="item.status === 3" status="exception"></el-progress>
                        </div>
                        <div class="case-status">
                            <span v-if="item.status === 0">{{$t('case_manage.waiting')}}</span>
                            <span v-if="item.status === 1">{{$t('case_manage.exporting')}}</span>
                            <span v-if="item.status === 2" class="success">{{$t('case_manage.export_successfully')}}</span>
                            <span v-if="item.status === 3" class="fail">{{$t('case_manage.export_failed')}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="export-footer" v-if="exportManager.phase >= 2">
                <div class="btn btn-default" @click="beforeExportClose" v-if="exportManager.phase == 2">
                    {{$t('case_manage.stop')}}
                </div>
                <button class="btn btn-primary" :disabled="exportManager.phase == 2" @click="beforeExportClose">
                    {{$t('case_manage.confirm')}}
                </button>
            </span>
        </el-dialog>

        <!-- 上传文件流所必需的隐藏组件 -->
        <div style="display: none;">
            <input type="file" id="selectfiles" style="display:none" />
        </div>
    </div>
</template>
 
<script>
import "../../assets/scss/button.scss";
import "../../assets/scss/uploadPatients.scss";
import uploader from "../../assets/utils/fileUploader.js";
import FilesSelector from "../../assets/utils/fileSelector.js";
import config from "../../assets/js/config.js";
export default {
    name: "uploadCase",
    props: {
        finishHandle: {
            type: Function,
            value: new Function()
        }
    },
    data() {
        return {
            patientMenuVisible: false,    //病例管理菜单显示状态
            uploadType: "",    //上传类型
            zipUploadManager: this.getDefaultZipUploadManager(),    //autovision病例上传管理器
            caseUploadManager: this.getDefaultCaseUploadManager(),    //文件夹病例上传管理器
            exportManager: this.getDefaultExportManager(),    //病例导出管理器
            caseUploader: null,    //文件夹病例上传组件
            existTipVisible: false,    //病例已存在提示显示状态
            existTipContent: "",    //病例已存在提示内容
            isApplyAll: false,    //是否为应用全部
            existTipIndex: 0,    //已存在病例循环提示index
            pickerOptions: {    //日期选择框配置
                shortcuts: [{
                    text: this.$t('case_manage.lately_1week'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(
                            start.getTime() - 3600 * 1000 * 24 * 7
                        );
                        picker.$emit("pick", [start, end]);
                    }
                },
                {
                    text: this.$t('case_manage.lately_1month'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(
                            start.getTime() - 3600 * 1000 * 24 * 30
                        );
                        picker.$emit("pick", [start, end]);
                    }
                },
                {
                    text: this.$t('case_manage.lately_3month'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(
                            start.getTime() - 3600 * 1000 * 24 * 90
                        );
                        picker.$emit("pick", [start, end]);
                    }
                },
                {
                    text: this.$t('case_manage.lately_6month'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(
                            start.getTime() - 3600 * 1000 * 24 * 180
                        );
                        picker.$emit("pick", [start, end]);
                    }
                },
                {
                    text: this.$t('case_manage.lately_1year'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(
                            start.getTime() - 3600 * 1000 * 24 * 365
                        );
                        picker.$emit("pick", [start, end]);
                    }
                }
                ]
            }
        };
    },
    created() {
        // window.APP = this;
        // window.App = this;
        window.upapp = this;
    },
    mounted() {
        // console.warn(this.successHandle)
    },
    methods: {
        /**
         * 获取autovision病例上传控制器默认属性
         */
        getDefaultZipUploadManager() {
            return {
                files: [],    //文件列表
                patientNames: "",    //病例名称
                visible: false,
                percent: 0,    //百分比
                uploaded: 0,    //已上传大小
                totalSize: 0,    //总大小
                status: 0,    //状态 0=上传中  1=分析中 2=分析完成 3=上传失败
                remaining: this.$t('case_manage.counting'),    //剩余时间提示
                loopTimer: null, //定时器
                success: 0,    //上传成功数量
                left: 0,    //剩余数量
                fail: 0        //失败数量
            };
        },

        /**
         * 获取文件夹病例上传控制器默认属性
         */
        getDefaultCaseUploadManager() {
            return {
                filterDate: [], //时间段
                showlist: [], //显示时间段内的病例
                uplist: [], //需要上传的病例
                upTotalSize: "", //需要上传的病例总大小
                totalImages: 0, //上传的图片总数
                percent: 0, //总上传进度
                remaining: "",
                successCount: 0, //上传成功的病例数
                leftCount: 0, //剩余的病例个数
                failCount: 0, //上传失的败病例数
                visible: false, //弹窗是否可见
                phase: 0, //-1=未检索到病例 0=检索中 1=完成检索 2=上传阶段 3=全部上传完成 4=部分上传失败
                looptimer: null //循环检测进度和时间定时器
            };
        },

        /**
         * 获取病例导出控制器默认属性
         */
        getDefaultExportManager() {
            return {
                visible: false,
                showlist: [],    //展示的病例列表
                keyword: "",    //input框中的关键字
                search: 0,        //当前病例列表对应的搜索关键字
                totalImages: 0,    //图片总量
                downloadList: [],    //下载列表
                exportType: 2, //1=所有图片 2=经人工计数、人工排列确认的图片 3=仅人工排列确认的图片
                phase: 1, //1=筛选中 2=打包下载 3=导出成功 4=导出失败
                percent: 0,    //百分比
                queryTimer: null,    //打包请求定时器
                downTimer: null,    //下载定时器
                queue: [],        //下载队列
                downloading: false    //下载中
            };
        },

        /**
         * 下拉菜单 指令触发
         * @param {Object} type
         */
        handleCommand(type) {
            this.uploadType = type;
            if (this.uploadType === "importZipFile") {
                this.$refs["importZipFile"].click();
            } else {
                this.$refs["casesFilesInput"].click();
            }
        },

        /**
         * 筛选autovision病例
         * @param {Object} e
         */
        filterAutoVision(e) {
            this.zipUploadManager.files = Array.prototype.slice.call(e.target.files).filter(file => {
                return file.size <= 800 * 1024 * 1024 && /\.autovision$/g.test(file.name);
            });
            //清空input file的值
            this.$refs["importZipFile"].value = "";
            //筛选出所选文件的病例名称集合
            this.zipUploadManager.patientNames = this.zipUploadManager.files.map(file => {
                return file.name.replace(/\.autovision$/g, "");
            }).join(",");

            //当病例名称发生重复时 将对应病例添加标识
            const whenPatientExist = res => {
                res.data.forEach(a => {
                    this.zipUploadManager.files.forEach(item => {
                        const name = item.name.replace(/\.autovision$/g, "");
                        if (a.patientName === name) {
                            item.isExist = true;
                            item.lastUpdateTime = +new Date(a.lastUpdateTime);
                        }
                    });
                });
                this.importZipReady(res.data);
            };

            //异步请求 检查病例是否重复
            this.checkPatient(this.zipUploadManager.patientNames)
                .then(res => {
                    if (res.result === true || res.result === "true") {
                        this.importZipReady();
                    } else {
                        //如果重复则
                        whenPatientExist(res);
                    }
                })
                .catch(res => {
                    whenPatientExist(res);
                });
        },

        /**
         * 循环提示重复
         * @param {Object} n
         */
        showConfirmLoop() {
            const n = this.existTipIndex;
            const item = this.zipUploadManager.files.filter(a => a.isExist)[n];
            if (!item) {
                this.existTipVisible = false;
                this.existTipContent = "";
                this.isApplyAll = false;
                this.existTipIndex = 0;
                this.doZipUpload();
            } else {
                const name = item.patient;
                const isNew =
                    item.lastUpdateTime > item.file.lastModified ? this.$t('case_manage.new') : this.$t('case_manage.old');
                const checkbox = '<label><input type="checkbox"></label>';
                const text = this.$t('case_manage.exist_name') + `<strong>${name}</strong>` + this.$t('case_manage.more') + isNew + this.$t('case_manage.de_cases') + `.<br>` + this.$t('case_manage.want_change');
                this.existTipVisible = true;
                this.existTipContent = text;
            }
        },

        /**
         * 单次或者批量处理重复病例时的操作
         * @param {Object} type
         */
        existHandle(type) {
            if (this.isApplyAll) {
                this.existTipVisible = false;
                this.existTipContent = "";
                this.isApplyAll = false;
                this.zipUploadManager.files
                    .filter(a => a.isExist)
                    .forEach((item, i) => {
                        if (i >= this.existTipIndex && (type === "replace" || type === "keepboth")) {
                            item.hasReplace = type === "replace" ? 1 : 0;
                        }
                    });
                this.existTipIndex = 0;
                this.doZipUpload();
            } else {
                const item = this.zipUploadManager.files.filter(a => a.isExist)[
                    this.existTipIndex
                ];
                if (type === "replace" || type === "keepboth") {
                    item.hasReplace = type === "replace" ? 1 : 0;
                }
                this.existTipIndex++;
                this.showConfirmLoop();
            }
        },

        /**
         * 保留两者
         */
        existKepBoth() {
            this.existHandle("keepboth");
        },

        /**
         * 取消
         */
        existCancel() {
            this.existHandle("cancel");
        },

        /**
         * 替换
         */
        existReplace() {
            this.existHandle("replace");
        },

        /**
         * 文件大小格式
         * @param {Object} size
         */
        sizeFormat(size) {
            let kb = parseInt(size / 1024),
                mb = Number(size / 1024 / 1024).toFixed(1),
                gb = Number(size / 1024 / 1024 / 1024).toFixed(1);
            const base = 1000 / 1024;
            return gb >= base ? gb + "G" : mb >= base ? mb + "M" : kb + "Kb";
        },

        /**
         * autovision病例已准备就绪
         */
        importZipReady(data) {
            this.zipUploadManager.files = this.zipUploadManager.files.map(file => {
                return {
                    isExist: !!file.isExist,    //是否已存在
                    lastUpdateTime: file.lastUpdateTime,    //最后更新时间
                    file: file,    //文件
                    patient: file.name.replace(/\.autovision$/g, ""),    //病例名称
                    percent: 0,    //上传百分比
                    progress: 0,    //分析百分比
                    uploaded: 0,    //已上传大小
                    status: 0,    //状态 0=等待上传  1=上传中  2=上传成功 3=上传失败
                    state: "",    //状态显示文字
                    speed: "",    //显示的上传速度
                    _speed: "",    //触发loadpress时的速度
                    sizeFormat: this.sizeFormat(file.size),    //文件大小
                    random: this.random_string(6)    //随机串
                };
            });
            this.zipUploadManager.visible = true;

            if (data) {
                this.showConfirmLoop();
            } else {
                this.$nextTick(() => {
                    this.doZipUpload();
                });
            }
        },

        /**
         * 生成随机串
         */
        random_string(len) {
            len = len || 32;
            const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
            const maxPos = chars.length;
            let pwd = "";
            for (let i = 0; i < len; i++) {
                pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        },

        /**
         * 毫秒转时间格式
         * @param {Object} ms
         */
        msToTimeFormat(ms) {
            if (ms < 0) {
                return "计算中";
            }
            const s = Math.ceil(ms / 1000);
            const hour = Math.floor(s / 3600);
            const minites = Math.floor((s - hour * 3600) / 60);
            const second = s - hour * 3600 - minites * 60;
            const hh = hour > 9 ? hour : "0" + hour;
            const mm = minites > 9 ? minites : "0" + minites;
            const ss = second > 9 ? second : "0" + second;
            const day = parseInt(hour / 24);
            return hour > 24 ? this.$t('case_manage.exceed') + day + this.$t('case_manage.days') : [hh, mm, ss].join(":");
        },

        /**
         * autovision病例上传进程
         */
        doZipUpload() {
            //筛选未重复、重复却保留或替换的病例
            const cases = this.zipUploadManager.files.filter(
                a => !a.isExist || a.hasReplace >= 0
            );
            this.zipUploadManager.totalCase = cases.length;
            this.zipUploadManager.totalSize = this.zipUploadManager.files.reduce((a, b) => {
                return a + (b.isExist && b.hasReplace === undefined ? 0 : b.file.size)
            }, 0);
            this.zipUploadManager.left = this.zipUploadManager.totalCase;
            if (this.zipUploadManager.totalSize <= 0) {
                return;
            }
            let zipUploadLooping = false;
            let start = 4;
            const list = this.zipUploadManager.files.filter(a => {
                return (!a.isExist || a.hasReplace >= 0) && a.status < 2;
            });
            //每秒循环一次 更新各病例当前的状态
            const loop = () => {
                let totalPercent = 0,
                    count = 0;
                let byte_s = 0;
                let byte_c = 0;
                let loaded = 0;
                let success_count = 0,
                    fail_count = 0,
                    left_count = 0;
                this.zipUploadManager.files.forEach(item => {
                    if (!item.isExist || item.hasReplace >= 0) {
                        // console.log(item);
                        const progress = item.progress * 50;
                        const percent =
                            parseInt((item.uploaded / item.file.size) * 50) +
                            progress;
                        item.speed = item._speed;
                        // item.remaining = item._remaining;
                        item.percent = percent || 0;
                        totalPercent += percent;
                        count++;
                        byte_s += item.byte_s || 0;
                        byte_c += item.byte_s ? 1 : 0;
                        loaded += item.uploaded || 0;
                        item.status === 5 && success_count++;
                        item.status === 3 && fail_count++;
                        item.status !== 3 && item.status !== 5 && left_count++;
                    }
                });
                // console.warn('byte_s',byte_s,'totalPercent',totalPercent,'count',count);
                let speed = byte_s / byte_c;
                const remaining = speed ? (this.zipUploadManager.totalSize - loaded) / speed : 0;
                this.zipUploadManager.remaining = this.msToTimeFormat(
                    remaining
                );
                const percent = totalPercent / count;
                // console.log(totalPercent, count, percent);
                this.zipUploadManager.percent = Number(percent.toFixed(1));

                this.zipUploadManager.success = success_count;
                this.zipUploadManager.fail = fail_count;
                this.zipUploadManager.left = left_count;

                const analysising = cases.every(a => a.percent >= 50);
                const allFinished = cases.filter(a => a.status == 3 || a.status == 5).length === cases.length;

                if (allFinished) {
                    this.zipUploadManager.status = 2;
                    this.zipUploadManager.percent = 100;
                    this.zipUploadManager.files
                        .filter(item => !item.isExist || item.hasReplace >= 0)
                        .forEach(item => {
                            if (item.status !== 3 && item.status !== 5) {
                                item.percent = 100;
                            }
                        });
                }
                if (!allFinished && analysising) {
                    this.zipUploadManager.status = 1;
                }
                if (this.zipUploadManager.percent < 100 && !allFinished) {
                    this.zipUploadManager.loopTimer = setTimeout(loop, 1000);
                }
                // this.$forceUpdate();
                // console.warn('全部完成',allFinished,'分析中：',analysising,'进度',this.zipUploadManager.percent,'状态：',this.zipUploadManager.status);
            };
            //文件上传的主要进程
            const main = item => {
                // console.log(item);
                //记录当前时间
                item.lasttime = +new Date();
                //更改文件状态 表示当前正在上传
                uploader({
                    files: [item.file],
                    path: ["zip-local-output", item.random, ""].join("/"),
                    ossUploadAccess: this.$store.state.ossUploadAccess,
                    processHandle: _file => {
                        const size = _file.loaded - item.uploaded;
                        const time = +new Date();
                        const byte_s = size / (time - item.lasttime);
                        const kb_s = (byte_s * 1000) / 1024;
                        const mb_s = (byte_s * 1000) / 1024 / 1024;
                        const gb_s = (byte_s * 1000) / 1024 / 1024 / 1024;
                        const base = 1000 / 1024;
                        const speed = gb_s >= base ? gb_s : (mb_s >= base ? mb_s : kb_s);
                        const unit = gb_s >= base ? "G/s" : (mb_s >= base ? "M/s" : "Kb/s");
                        item._speed = speed.toFixed(2) + unit;
                        // console.log(time - item.lasttime,'ms上传了',size,'字节');
                        item.byte_s = byte_s;
                        item.uploaded = _file.loaded;
                        item.status = 1;
                        item.lasttime = time;
                        if (!zipUploadLooping) {
                            zipUploadLooping = true;
                            loop();
                        }
                    }
                }).then(arr => {
                    const target = arr[0];
                    item.uploaded = item.file.size;
                    if (start < list.length) {
                        main(list[start]);
                        start++;
                    }
                    if (target.status === "success") {
                        item.status = 2;
                        this.zipUploadManager.success++;
                        this.zipUploadManager.left--;
                        this.uploadLocalPatient(item);
                    } else {
                        // item.progress = 1;
                        item.status = 3;
                        item.state = "exception";
                        this.zipUploadManager.fail++;
                        this.zipUploadManager.left--;
                    }
                });
            };
            list.slice(0, start).forEach(item => {
                main(item);
            });
        },

        /**
         * 停止上传auto vision病例
         */
        stopZipUpload() {
            const next = (status) => {
                clearTimeout(this.zipUploadManager.loopTimer);
                this.zipUploadManager = this.getDefaultZipUploadManager();
                this.$forceUpdate();
                this.finishHandle("autovision", status);
            }
            if (this.zipUploadManager.status < 2 && this.zipUploadManager.totalSize > 0) {
                const loaded = this.zipUploadManager.files.filter(a => !a.isExist && (a.status === 3 || a.status === 5)).length;
                const loading = this.zipUploadManager.files.filter(a => !a.isExist && a.status !== 3 && a.status !== 5).length;
                const title = this.$t('case_manage.completely') + loaded + this.$t('case_manage.no_complete') + loading + this.$t('case_manage.confirm_stop');
                this.$confirm(title, "", {
                    confirmButtonText: this.$t('btns.confirm'),
                    cancelButtonText: this.$t('case_manage.cancel'),
                    type: "warning",
                    customClass: "download-confirm-message",
                    closeOnClickModal: false,
                })
                    .then(res => {
                        this.zipUploadManager.files.forEach(item => {
                            item.file.loader && item.file.loader.stop();
                        });
                        next('cancel')
                    })
                    .catch(() => {
                        //TODO
                    });
            } else {
                next('success')
            }
        },

        /**
         * 上传失败时 重新上传
         */
        retryZipUpload() {
            //重置属性
            this.zipUploadManager.status = 0;
            this.zipUploadManager.files.forEach(item => {
                if (item.status === 3) {
                    item.status = 0;
                    item.percent = 0;
                    item.uploaded = 0;
                    item.process = 0;
                    item.speed = "";
                    item._speed = "";
                    item.state = "";
                }
            });
            let percent = 0,
                count = 0;
            this.zipUploadManager.files.forEach(item => {
                if (!item.isExist) {
                    percent += (item.uploaded / item.file.size) * 50 + item.process * 50;
                    count++;
                }
            });
            if (count) {
                this.zipUploadManager.percent = percent / count;
            }
            this.doZipUpload();
        },

        /**
         * 上传autovision文件后，通知后台
         * @param {Object} file
         */
        uploadLocalPatient(item) {
            // console.log('uploadLocalPatient',item, item.status);
            const param = {
                patientName: [item.random, item.file.name].join("/"),
                fileSize: item.file.size
            };
            if (item.hasReplace >= 0) {
                param.hasReplace = item.hasReplace;
            }
            this.$api.uploadLocalPatient(param).then(res => {
                item.status = 4;
                item.id = res.data;
                if (!this.zipUploadManager.loopChecked) {
                    this.zipUploadManager.loopChecked = true;
                    this.loopCheckProgress();
                }
            });
        },

        /**
         * 循环查询后端处理local病例进度
         */
        loopCheckProgress() {
            const list = this.zipUploadManager.files.filter(a => a.id);
            const ids = list.map(a => a.id).join(",");
            this.$api.querySegmentLocal({
                onlys: ids
            }).then(res => {
                if (res.code == 200) {
                    const data = res.data;
                    // console.log(res);
                    Object.keys(data).forEach(id => {
                        const progress = Number(data[id]);
                        list.forEach(item => {
                            if (item.id === id) {
                                try {
                                    const _progress = Math.min(1, Math.max(item.progress, progress));
                                    // console.log('_progress',_progress,item);
                                    item.progress = _progress;
                                    if (progress === 10 || progress === 11) {
                                        item.status = 3;
                                        item.state = "exception";
                                    } else if (item.progress >= 1) {
                                        item.status = 5;
                                        item.percent = 100;
                                        item.state = "success";
                                    }
                                } catch (e) {
                                    //TODO handle the exception
                                }
                            }
                        });
                    });
                    const isAllFinished = this.zipUploadManager.files.every(a => {
                        return (a.hasReplace === undefined && a.isExist) || (a.progress >= 1 && a.id);
                    });
                    if (!isAllFinished) {
                        setTimeout(this.loopCheckProgress, 1000);
                    }
                } else {
                    setTimeout(this.loopCheckProgress, 1000);
                }
            });
        },

        /**
         * 完成上传回调
         */
        zipUploadFinished() {
            this.zipUploadManager = this.getDefaultZipUploadManager();
            this.finishHandle("autovision", "success");
        },

        /**
         * 检查病例是否已存在
         */
        checkPatient(patientNames) {
            return new Promise((resolve, reject) => {
                this.$api.checkPatient({
                    patientNames: patientNames
                }).then(res => {
                    if (res.code === 200 || res.code === "200") {
                        if (res.result === true || res.result === "true") {
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    } else {
                        reject(res);
                    }
                });
            });
        },

        /**
         * 根据上传类型对所选文件进行筛选
         */
        filterCaseByType(e) {
            // console.log(e);
            this.caseUploadManager.visible = true;
            this.caseUploadManager.phase = 0;
            this.caseUploader = new FilesSelector({
                filelist: e.target.files,
                type: this.uploadType,
                handles: {
                    before: () => { },
                    done: length => {
                        console.log(length);
                        if (length) {
                            this.whileCheckPatient();
                        } else {
                            this.caseUploadManager.phase = -1;
                        }
                    }
                }
            });
            this.$refs["casesFilesInput"].value = "";
            console.log(this.caseUploader);
        },

        /**
         * 查询病例名称是否有重复 有重复名称后面加_1,_2
         */
        whileCheckPatient() {
            const patientNames = this.caseUploader.filesMap.map(item => {
                return {
                    patient: item.patient,
                    name: item.patient,
                    current: 0
                };
            });
            const docheck = () => {
                const patient_names = patientNames.map(item => {
                    return item.patient + (item.current ? "_" + item.current : "");
                }).join(",");
                this.checkPatient(patient_names).then(res => {
                    console.warn("checkPatient", "没有重复");
                    if (this.uploadType === "importCasesLibrary") {
                        this.caseUploadManager.phase = 1;
                        this.getPatientQueryEndTime();
                    } else {
                        this.caseUploader.filesMap.forEach(item => {
                            const _item = patientNames.filter(target => {
                                return target.patient === item.patient;
                            })[0];
                            if (_item) {
                                const newName = _item.patient + (_item.current ? "_" + _item.current : "");
                                item.patient = newName;
                                item.list.forEach(child => {
                                    child.patient = newName;
                                });
                            }
                        });
                        this.caseUploadManager.uplist = this.caseUploader.filesMap;
                        this.caseUploadManager.totalImages = this.caseUploadManager.uplist.reduce((a, b) => {
                            return a + b.list.length;
                        }, 0);

                        //TODO 调用上传进程
                        this.doCaseUpload();
                    }
                }).catch(res => {
                    const list = res.data.map(a => a.patientName);
                    if (this.uploadType === "importCasesLibrary") {
                        console.warn("checkPatient", "重复了");
                        this.caseUploader.filesMap.forEach(item => {
                            if (list.indexOf(item.patient) > -1) {
                                item.valid = false;
                            }
                        });
                        this.caseUploader.filesMap = this.caseUploader.filesMap.filter(
                            item => item.valid !== false
                        );
                        if (this.caseUploader.filesMap.length) {
                            this.caseUploadManager.phase = 1;
                            this.getPatientQueryEndTime();
                        } else {
                            this.caseUploadManager.phase = -1;
                        }
                    } else {
                        patientNames.forEach(item => {
                            if (list.indexOf(item.name) > -1) {
                                item.current++;
                                item.name = item.patient + (item.current ? "_" + item.current : "");
                            }
                        });
                        docheck();
                    }
                });
            };
            docheck();
        },

        /**
         * 未检索到病例时重新选择
         */
        reChooseWhenCaseEmpty() {
            this.$refs["casesFilesInput"].click();
        },

        /**
         * 服务端返回最后病例更新时间
         */
        getPatientQueryEndTime() {
            this.$api.patientQueryEndTime({}).then(res => {
                const time = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
                const startTime =
                    res.code == 200 && res.data.createTime ?
                        new Date(res.data.createTime) :
                        new Date(time);
                const endTime = new Date();
                this.caseUploadManager.filterDate = [startTime, endTime];
                this.onDataRangePick(this.caseUploadManager.filterDate);
            });
        },

        /**
         * 选择日期
         */
        onDataRangePick([startTime, endTime]) {
            console.log(this.caseUploadManager);
            this.caseUploader.filterByTimeInterval(startTime, endTime);
            console.log(this.caseUploader);
            this.caseUploadManager.showlist = this.caseUploader.filesMap.filter(
                item => item.valid
            );
            // console.warn(this.$refs.multipleTable);
            this.$refs.multipleTable.clearSelection();
            if (this.caseUploadManager.showlist.length) {
                this.$refs.multipleTable.toggleAllSelection();
            }
        },

        /**
         * 病例列表选中与取消
         */
        handleSelectionChange(list) {
            this.caseUploadManager.uplist = list;
            const totalSize = list.reduce((a, b) => {
                return a + b.totalSize;
            }, 0);
            this.caseUploadManager.upTotalSize = this.sizeFormat(totalSize);
            this.caseUploadManager.totalImages = this.caseUploadManager.uplist.reduce((a, b) => {
                return a + b.list.length;
            }, 0);
        },

        /**
         * 当前上传速度转换
         * @param {Object} byte_s 当前速度 字节/毫秒
         */
        getSpeed(byte_s) {
            // console.warn(byte_s)
            const kb_s = (byte_s * 1000) / 1024;
            const mb_s = (byte_s * 1000) / 1024 / 1024;
            const gb_s = (byte_s * 1000) / 1024 / 1024 / 1024;
            const base = 1000 / 1024;
            const speed = gb_s >= base ? gb_s : (mb_s >= base ? mb_s : kb_s);
            const unit = gb_s >= base ? "G/s" : (mb_s >= base ? "M/s" : "Kb/s");
            return speed.toFixed(2) + unit;
        },

        /**
         * 普通病例上传
         */
        doCaseUpload() {
            const limit = this.$store.state.patientUpLimit || 200;
            this.caseUploadManager.uplist.forEach(a=>{
                if(a.list.length > limit){
                    a.valid = false;
                    a.overLimit = true;
                    a.overLimitTip = this.$t("case_manage.overlimit_c").replace('${0}',limit);
                }
            })
            const caseUplist = this.caseUploadManager.uplist.filter(a=>!a.overLimit);
            const over_count = this.caseUploadManager.uplist.length - caseUplist.length;
            this.caseUploadManager.successCount = caseUplist.filter(item => item.status === 2).length;
            this.caseUploadManager.failCount = caseUplist.filter(item => item.status === 3).length + over_count;
            this.caseUploadManager.leftCount = this.caseUploadManager.uplist.length - this.caseUploadManager.successCount - this.caseUploadManager.failCount;
            this.caseUploadManager.phase = 2;
            //需要上传的病例的总大小
            const totalSize = caseUplist.reduce((a, b) => {
                return a + (b.overLimit ? 0 : b.totalSize);
            }, 0);
            //已上传的总大小
            let totalLoaded = 0;
            let uploadLooping = false;
            let start = 4;
            const list = [];
            caseUplist.forEach(item => {
                if (item.count === undefined) {
                    item.count = -1;
                }
                if ((item.status === 0 || item.status === 3)) {
                    item.list.forEach(target => {
                        if (target.status === 0 || target.status === 3) {
                            list.push({
                                item: target,
                                parent: item
                            });
                        }
                    });
                }
                totalLoaded += item.list.reduce((a, b) => {
                    return a + (b.loaded || 0);
                }, 0);
            });

            const loop = () => {

                let totalLoaded = 0;
                let t = +new Date();
                let t_byte_s = 0;
                caseUplist.forEach(item => {
                    let byte_s = 0;
                    let loaded = 0;
                    item.list.forEach(target => {
                        loaded += target.loaded;
                        byte_s += target.status === 1 ? target.speed : 0;
                        // console.warn(target.name,target.status,target.speed);
                    });
                    t_byte_s += byte_s;
                    totalLoaded += loaded;
                    if (item.count === -1) {
                        item.status = 0;
                        item.percent = 0;
                    } else if (item.count >= 0 && item.count < item.list.length) {
                        item.status = 1;
                        item.speed = this.getSpeed(Math.max(byte_s, 0));
                        item.percent = parseInt(
                            (loaded / item.totalSize) * 100
                        );
                    } else if (item.count === item.list.length) {
                        const issuccess = item.list.every(a => a.status === 2);
                        if (issuccess) {
                            item.percent = 100;
                            if (!item.saveing && !item.saved) {
                                console.warn("病例{" + item.patient + "}已上传完成，准备发送数据给后端。。。");
                                this.patientOss(item);
                            } else if (item.saved) {
                                item.status = 2;
                            }
                        } else {
                            console.warn(issuccess, item);
                            item.status = 3;
                        }
                    }
                });
                this.caseUploadManager.percent = parseInt(
                    (totalLoaded / totalSize) * 100
                );
                const remaining = (totalSize - totalLoaded) / (t_byte_s || 100);
                // console.warn(t_byte_s,totalSize,totalLoaded,remaining)
                this.caseUploadManager.remaining = this.msToTimeFormat(
                    remaining
                );
                //整体正在上传
                let isUploading = caseUplist.some(
                    item => item.status < 2
                );
                if (isUploading) {
                    this.caseUploadManager.phase = 2;
                    this.caseUploadManager.looptimer = setTimeout(() => {
                        loop();
                    }, 1000);
                } else {
                    //全部成功
                    let isAllSuccess = caseUplist.every(
                        item => item.status === 2
                    );
                    if (isAllSuccess) {
                        this.caseUploadManager.phase = 3;
                    } else {
                        this.caseUploadManager.phase = 4;
                    }
                }
                this.caseUploadManager.successCount = caseUplist.filter(item => item.status === 2).length;
                this.caseUploadManager.failCount = caseUplist.filter(item => item.status === 3).length;// + f_count;
                this.caseUploadManager.leftCount = this.caseUploadManager.uplist.length - this.caseUploadManager.successCount - this.caseUploadManager.failCount;
                this.$forceUpdate();
            };

            const main = (item, parent) => {
                if (parent.count === -1) {
                    parent.count++;
                }
                //记录当前时间
                item.lasttime = +new Date();
                const file = this.caseUploader.filelist[item.index];
                const random = Math.random().toString(16).split(".")[1].substring(0, 6);
                file.upname = [item.patient, item.slide, item.cell, random, file.name].join("_");
                uploader({
                    files: [file],
                    path: [item.guid, "patient/glass/karyo/"].join("/"),
                    ossUploadAccess: this.$store.state.ossUploadAccess,
                    processHandle: (_file, real) => {
                        item.status = 1;
                        const size = _file.loaded - item.loaded;
                        const time = +new Date();
                        const byte_s = size / (time - item.lasttime);
                        item.lasttime = time;
                        item.speed = byte_s;
                        item.loaded = _file.loaded;
                        item.loader = real.loader;
                        if (!uploadLooping) {
                            uploadLooping = true;
                            loop();
                        }
                    }
                }).then(arr => {
                    const target = arr[0];
                    item.loaded = file.size;
                    parent.count++;
                    if (start < list.length) {
                        // main(list[start]);
                        main(list[start].item, list[start].parent);
                        start++;
                    }
                    if (target.status === "success") {
                        item.status = 2;
                    } else {
                        console.warn("fail", arr);
                        item.status = 3;
                    }
                });
            };
            if(list.length){
                list.slice(0, start).forEach(({
                    parent,
                    item
                }) => {
                    main(item, parent);
                });
            }else{
                this.caseUploadManager.phase = 5
            }
        },

        /**
         * 停止普通病例上传
         */
        stopCaseUpload() {
            const s_count = this.caseUploadManager.successCount;
            const f_count =
                this.caseUploadManager.leftCount +
                this.caseUploadManager.failCount;
            const title = this.$t('case_manage.completely') + ' ' + s_count + ' ' + this.$t('case_manage.no_complete') + ' ' + f_count + ' ' + this.$t('case_manage.confirm_stop');
            this.$confirm(title, "", {
                type: "warning",
                confirmButtonText: this.$t('btns.confirm'),
                cancelButtonText: this.$t('case_manage.cancel'),
                customClass: "download-confirm-message",
                closeOnClickModal: false,
            })
                .then(() => {
                    clearTimeout(this.caseUploadManager.looptimer);
                    this.caseUploadManager.uplist.forEach(item => {
                        item.list.forEach(target => {
                            target.loader && target.loader.stop();
                        });
                    });
                    this.caseUploadManager = this.getDefaultCaseUploadManager();
                    this.finishHandle("folder", "cancel");
                })
                .catch(() => {
                    //TODO
                });
        },

        /**
         * 重试普通病例上传
         */
        retryCasesUpload() {
            this.caseUploadManager.uplist.forEach(item => {
                if (item.status === 3) {
                    item.list.forEach(target => {
                        if (target.status === 3) {
                            target.status = 0;
                        }
                    });
                    item.status = 0;
                }
            });
            this.doCaseUpload();
        },

        /**
         * 关闭普通病例上传弹窗是询问
         */
        beforeCaseUploadClose() {
            if (
                this.caseUploadManager.phase > 2 ||
                this.caseUploadManager.phase === 1
            ) {
                this.caseUploadManager = this.getDefaultCaseUploadManager();
                this.finishHandle("folder", "success");
            } else if (this.caseUploadManager.phase === -1) {
                this.caseUploadManager = this.getDefaultCaseUploadManager();
                this.finishHandle("folder", "cancel");
            } else {
                this.stopCaseUpload();
            }
        },

        /**
         * 普通病例上传成功后通知后台
         */
        patientOss(item) {
            let slideList = [...new Set(item.list.map(a => a.slide))];
            let ossJson = {
                patient: item.patient,
                createTime: item.recentTimeFormat,
                glass: slideList.map(slide => {
                    let cellList = [
                        ...new Set(
                            item.list
                                .filter(a => a.slide === slide)
                                .map(a => a.cell)
                        )
                    ];
                    return {
                        glassName: slide,
                        cells: cellList.map(cell => {
                            let target = item.list.filter(
                                a => a.slide === slide && a.cell === cell
                            )[0];
                            const file = this.caseUploader.filelist[
                                target.index
                            ];
                            return {
                                cellName: cell,
                                karyos: [{
                                    picScore: 0,
                                    karyoName: file.name,
                                    url: config.ossUrl + "/" + item.guid + "/patient/glass/karyo/" + (file.upname || file.name),
                                    singleNum: "0"
                                }]
                            };
                        })
                    };
                })
            };
            item.saveing = true;
            this.$api.patientOss({
                isBranch: "0",
                ossJson: JSON.stringify(ossJson)
            }).then(res => {
                item.saveing = false;
                item.saved = res.code == 200;
            }).catch(() => {
                item.saveing = false;
                item.saved = false;
            });
        },

        /**
         * 普通病例上传结束时回调
         */
        caseUploadFinish() {
            this.caseUploadManager.uplist.forEach(item => {
                item.list.forEach(target => {
                    target.loader && target.loader.stop();
                });
            });
            this.caseUploadManager = this.getDefaultCaseUploadManager();
            this.finishHandle("folder", "success");
        },

        /**
         * 选择导出病例 显示病例导出界面
         */
        exportPatients() {
            this.exportManager.visible = true;
            this.searchCases();
        },

        /**
         * 关闭病例导出界面时 判断状态 弹出确认框
         */
        beforeExportClose() {
            if (this.exportManager.phase !== 2) {
                this.exportManager = this.getDefaultExportManager();
            } else {
                clearTimeout(this.exportManager.queryTimer);
                clearInterval(this.exportManager.downTimer);
                this.exportManager.downloading = false;
                const count = this.exportManager.downloadList.filter(a => a.status === 2).length;
                const num = this.exportManager.downloadList.length - count;
                const title = this.$t('case_manage.completely') + ' ' + count + ' ' + this.$t('case_manage.no_complete') + ' ' + num + ' ' + this.$t('case_manage.confirm_stop');
                this.$confirm(title, "", {
                    type: "warning",
                    confirmButtonText: this.$t('btns.confirm'),
                    cancelButtonText: this.$t('case_manage.cancel'),
                    customClass: "download-confirm-message",
                    closeOnClickModal: false,
                }).then(res => {
                    this.exportManager = this.getDefaultExportManager();
                }).catch(() => {
                    //TODO
                    this.requestPackage();
                    this.downloadLoopProcess();
                });
            }
        },

        /**
         * 搜索病例
         */
        searchCases() {
            if (this.exportManager.search === this.exportManager.keyword) {
                return;
            }
            this.exportManager.search = this.exportManager.keyword;
            const patientType = parseInt(this.$store.state.caseValue.patientType);
            this.$api.queryPatientBySeach({
                page: 1,
                limit: 10000,
                search: this.exportManager.keyword,
                patientType: Math.min(patientType,2)
            }).then(res => {
                if (res.code == 200) {
                    this.exportManager.showlist = res.data.list.map(item => {
                        item.recentTimeFormat = new Date()
                            .toISOString(item.updateTime)
                            .replace(/T/g, " ")
                            .replace(/\.\d+Z/g, "");
                        return item;
                    });
                } else {
                    this.$message({
                        message: res.message
                    });
                }
            });
        },

        /**
         * 选中或取消待导出的病例
         */
        exportSelectionChange(list) {
            this.exportManager.downloadList = list;
        },

        /**
         * 导出前确认
         */
        confirmBeformExport() {
            this.$api.downloadPatient({
                patientIds: this.exportManager.downloadList
                    .map(a => a.id)
                    .join(","),
                type: this.exportManager.exportType
            }).then(res => {
                if (res.code == 200) {
                    const enabled = res.data.filter(a => a.code == 200);
                    if (enabled.length) {
                        const tip = this.$t('case_manage.all') + ' ' + enabled.length + ' ' + this.$t('case_manage.continue');
                        this.$confirm(tip, "", {
                            type: "warning",
                            customClass: "download-confirm-message",
                            confirmButtonText: this.$t("btns.confirm"),
                            cancelButtonText: this.$t("btns.cancel"),
                            closeOnClickModal: false,

                        }).then(res => {
                            const ids = enabled.map(a => a.patientId);
                            this.exportManager.downloadList = this.exportManager.downloadList.filter(a => {
                                return ids.indexOf(String(a.id)) > -1;
                            });
                            this.exportManager.downloadList.forEach(item => {
                                item.status = 1; //0=等待中 1=正在导出 2=导出成功 3=导出失败
                            });
                            this.exportManager.phase = 2;
                            this.requestPackage();
                        }).catch(() => {
                            //TODO
                        });
                    } else {
                        this.$message({
                            message: this.$t('case_manage.no_image_rechoose'),
                            type: "warning"
                        });
                    }
                } else {
                    console.log(res);
                    this.$message({
                        message: res.message,
                        type: "warning"
                    });
                }
            });
        },

        /**
         * 请求服务端打包
         */
        requestPackage() {
            const ids = this.exportManager.downloadList.map(a => a.id).join(",");
            this.$api.querySegmentOnline({
                patientIds: ids
            })
                .then(res => {
                    let isAllPackaged = false;
                    if (res.code == 200) {
                        let percent = 0;
                        res.data.forEach(_res => {
                            Object.keys(_res)
                                .filter(key => /^\d+$/g.test(key))
                                .forEach(id => {
                                    const _process = Number(_res[id]);
                                    const item = this.exportManager.downloadList.filter(a => a.id == id)[0];
                                    if (_process <= 1) {
                                        item.percent = parseInt(_process * 100);
                                        // item.status = 1;
                                        if (_process == 1) {
                                            this.addToDownloadQueue(id, _res.zipUrl);
                                        }
                                    } else {
                                        item.status = 3;
                                    }
                                    percent += _process;
                                });
                        });
                        isAllPackaged = this.exportManager.downloadList.every(a => a.status === 2 || a.status === 3);
                        this.exportManager.percent = Number(Number(percent / this.exportManager.downloadList.length * 100).toFixed(1));
                    }
                    if (!isAllPackaged) {
                        this.exportManager.queryTimer = setTimeout(() => {
                            console.warn("queryTimer");
                            this.requestPackage();
                        }, 2000);
                    }
                    // const somefail = this.exportManager.downloadList.some(a=>a.state===3);
                    const everysuccess = this.exportManager.downloadList.every(a => a.status === 2);
                    this.exportManager.phase = isAllPackaged ? (everysuccess ? 3 : 4) : 2;
                    this.$forceUpdate();
                });
        },

        /**
         * 添加至下载队列
         */
        addToDownloadQueue(id, url) {
            const isExist = this.exportManager.queue.some(
                item => item.id === id
            );
            if (!isExist) {
                this.exportManager.queue.push({
                    id,
                    url,
                    done: false
                });
            }
            this.downloadLoopProcess();
        },

        /**
         * 循环下载
         */
        downloadLoopProcess() {
            if (!this.exportManager.downloading && this.exportManager.queue.length) {
                this.exportManager.downloading = true;

                this.exportManager.downTimer = setInterval(() => {
                    const target = this.exportManager.queue.filter(
                        a => !a.done
                    )[0];
                    //有未下载的
                    if (target) {
                        let event = new MouseEvent("click");
                        let a = document.createElement("a");
                        a.download = "download";
                        a.target = "_blank";
                        a.href = target.url;
                        a.dispatchEvent(event);
                        target.done = true;
                        this.exportManager.downloadList.forEach(item => {
                            console.log(item.id, target.id);
                            if (parseInt(item.id) === parseInt(target.id)) {
                                item.status = 2;
                            }
                        });
                    } else if (this.exportManager.phase > 2) {
                        //如果没有未下载 且全部打包完毕 清空定时器
                        clearInterval(this.exportManager.downTimer);
                    }
                }, 2000);
            }
        }
    },
    watch: {}
};
</script>
 
<style lang="scss"></style>
