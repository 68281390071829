<template>
    <div class="no-permission">{{$t("noPermission.tip")}}<a href="#/analysissystem">{{$t("noPermission.home")}}</a></div>
</template>
<script>
export default {
    name: 'noPermission',
    data(){
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.no-permission{
    text-align: center;
    padding-top: 100px;
    margin-top: 20px;
    a{
        color: #4773B8;
    }
}
</style>