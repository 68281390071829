<template>
  <div class="templateInfo">
    <input type="file" id="selectfiles" style="display: none" />
    <p class="p-title">{{$t('report_template.report_template')}}</p>
    <ul class="template-ul">
      <li class="template-list" v-for="(item, index) in templateList" :key="index">
        <img :src="item.templateImgUrl" alt="" />
        <p class="p-wrd">{{ item.name }}</p>
        <div class="tem-btns">
          <el-button type="cancel" @click="preview(item)" class="preview-btn btn">{{$t('btns.preview')}}</el-button>
          <el-button type="primary" @click="editTemplate(item)" class="save-btn btn">{{$t('btns.eidt')}}</el-button>
          <el-button type="primary" @click="deleteTemplate(item)" class="delete-btn btn">{{$t('btns.delete')}}</el-button>
        </div>
      </li>
      <li class="template-list cursor" @click="linkto">
        <i class="el-icon-plus add-icon"></i>
        <p>{{$t('report_template.addnew_report')}}</p>
      </li>
    </ul>
    <p class="p-title">{{$t('report_template.recommend_template')}}</p>
    <ul class="template-ul">
      <li class="template-list" v-for="(item, index) in offcialTemplate" :key="index">
        <img :src="item.templateImgUrl" alt="" />
        <p class="p-wrd">{{ item.name }}</p>
        <div class="tem-btns offical">
          <el-button type="cancel" @click="preview(item)" class="preview-btn btn">{{$t('btns.preview')}}</el-button>
          <el-button type="primary" @click="editTemplate(item)" class="save-btn btn">{{$t('report_template.use_this_template')}}</el-button>
        </div>
      </li>
    </ul>
    <DialogPopup :visible="isShow" title="" @visibleChange="visibleChange" :closeFlag="true">
      <div slot="content" class="pop-cnt">
        <img :src="templateImgUrl" :alt="$t('report_template.preview_image')" />
      </div>
    </DialogPopup>
  </div>
</template>

<script>
import DialogPopup from "../../selfDefineTemplate/components/dialogPopup";
export default {
  name: "TemplateInfo",
  created() {
    this.templateListFn();
  },
  /**
   * @method: 页面跳转过来时，触发函数
   * @Date: 2020-12-25 19:41:49
   */
  activated() {
    this.templateListFn();
  },
  components: {
    DialogPopup,
  },
  data() {
    return {
      templateList: [],
      offcialTemplate: [],
      templateImgUrl: "",
      isShow: false,
    };
  },
  props: [],
  methods: {
    /**
     * @method: 获取模板列表
     * @Date: 2020-12-22 10:50:45
     */
    templateListFn() {
      this.$api.templateList({}).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            const { template, offcialTemplate } = res.data;
            this.templateList = template;
            this.offcialTemplate = offcialTemplate;
            !!template &&
              template.forEach((item) => {
                item.templateImgUrl = this.ossUrlWebFir(
                  item.templateImgUrl,
                  1,
                  614,
                  877
                );
              });
            !!offcialTemplate &&
              offcialTemplate.forEach((item) => {
                item.templateImgUrl = this.ossUrlWebFir(
                  item.templateImgUrl,
                  1,
                  614,
                  877
                );
              });
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    editTemplate(item) {
      if (item.hasReport) {
        this.$message.error(this.$t('popup.warning.cannot_modify'));
        return;
      }
      this.$router.push({
        path: "../selfDefineTemplate",
        query: { templateId: item.id },
      });
    },
    deleteTemplate(item) {
      if (item.hasReport) {
        this.$message.error(this.$t('popup.warning.cannot_delete'));
        return;
      }
      this.$confirm(this.$t('popup.warning.continue'), this.$t('popup.warning.remind'), {
        confirmButtonText: this.$t('btns.confirm'),
        cancelButtonText: this.$t('btns.cancel'),
        type: "warning",
        closeOnClickModal: false
      })
        .then(() => {
          let obj = {
            ids: item.id,
          };
          this.$api.deleteTemplate(obj).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              this.templateListFn();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => { });
    },
    linkto() {
      this.$router.push({
        path: "../selfDefineTemplate",
        query: { templateId: null },
      });
    },
    preview(item) {
      this.isShow = true;
      this.templateImgUrl = item.templateImgUrl;
    },
    visibleChange(val) {
      this.isShow = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.templateInfo {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  // overflow-y: auto;
  padding: 30px 20px;
  box-sizing: border-box;

  .p-title {
    font-size: 18px;
    color: #3d3d3d;
    line-height: 25px;
    font-weight: bold;
    margin: 0;
  }
  .p-wrd {
    margin: 10px 0;
    font-size: 14px;
    line-height: 15px;
  }
  .template-ul {
    display: flex;
    margin: 16px 0 60px;
    flex-wrap: wrap;
    .template-list {
      position: relative;
      width: 168px;
      height: 210px;
      margin: 0 10px 38px 0;
      text-align: center;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dadee5;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
      p {
        margin: 0;
      }
      .add-icon {
        font-size: 35px;
        margin: 73px 0 16px;
        font-weight: bold;
      }
      &.cursor {
        cursor: pointer;
      }
      &:nth-of-type(5n) {
        margin-right: 0;
      }
    }
  }
  .pop-cnt {
    img {
      width: 100%;
    }
  }
  .tem-btns {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    opacity: 0;
    &:hover {
      animation: test 0.3s;
      opacity: 1;
    }
    .btn {
      width: 115px;
      height: 30px;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      padding: 0;
      border-radius: 2px;
    }
    .preview-btn {
      color: #4773b8;
      background: #f2f2f2;
      border: 1px solid #c6c6c6;
      margin: 60px auto 6px;
      &:hover {
        background: #d3d2d2;
        transition-duration: 0.5s;
      }
    }
    &.offical {
      .preview-btn {
        margin-top: 72px;
      }
    }
    .save-btn {
      color: #fff;
      background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
      border: 0;
      margin: 0 auto 6px;
      padding: 0;
      &:hover {
        background: linear-gradient(90deg, #64a7c9 0%, #416ab0 100%);
        transition-duration: 0.5s;
      }
    }
    .delete-btn {
      font-size: 14px;
      background: #e3e3e3 !important;
      color: #1a1a1a;
      margin-left: 0 !important;
      &:hover {
        background: #b1afaf;
        transition-duration: 0.5s;
      }
    }
  }
  @keyframes test {
    from {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    40% {
      opacity: 0.4;
    }
    60% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.8;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
<style lang="scss">
.templateInfo {
  .el-dialog {
    width: 614px;
    // width: 780px;
    // padding: 0;
  }
  .el-dialog--center .el-dialog__body {
    height: 877px;
    // height: 1020px;
    padding: 0;
  }
  .el-dialog__wrapper .el-dialog .el-dialog__footer {
    display: none;
  }
  .el-dialog__wrapper .el-dialog .el-dialog__header {
    height: 0;
  }
  .el-dialog__wrapper .el-dialog .el-dialog__header .el-dialog__headerbtn {
    right: -24px !important;
    top: -12px !important;
  }
  .el-dialog__wrapper {
    top: 15px;
  }
}
</style>
