<template>
    <div class="accountRechargeBox">
        <div class="accountRechargeTitle">
            <div class="accountRechargeTitleText">
                <span>{{ $t('account_manage.account_manage') }} / </span>
                <span>{{ $t('account_manage.account_recharge') }}</span>
            </div>
            <div class="accountRechargeTitleBtn" @click="backFun" v-show="pay_loading == 0">
                <span>{{ $t('account_manage.back') }}</span>
                <img src="../../../assets/images/personalSettings/iconReturnRight.svg"/>
            </div>
        </div>
        <div class="dividingLine"></div>
        <div class="accountRechargeContent">

            <!--        支付失败-->
            <div v-show="isState ==5">
            <span class="successful_img">
                <img src="../../../assets/images/common/icon_fail.svg">
            </span>
                <span class="successful_title">{{ $t('account_manage.failure_payment') }}</span>
                <button class="successful_back" @click="successfulBack">{{ $t('account_manage.Retry') }}</button>

                <span class="successful_num">{{ toTiming }}{{ $t('account_manage.Return_to_the_system')}}</span>
            </div>

            <!--        支付成功-->
            <div v-show="isState == 1">
            <span class="successful_img">
                <img src="../../../assets/images/common/Check-Circle-Fill.svg">
            </span>
                <span class="successful_title">{{ $t('account_manage.Payment_success')}}</span>
                <span class="successful_num_style">{{ $t('account_manage.Payment_success_title')}}:0571 8955921</span>
                <button class="successful_back" @click="successfulBack">{{ $t('account_manage.Return')}}</button>
                <span class="successful_num">{{ toTiming }}{{ $t('account_manage.Return_to_the_system')}}</span>
            </div>

            <!--        支付中-->
            <div v-show="isState == 3">
                <span class="analysis_loading">{{ $t('account_manage.complete_payment')}}</span>
                <span class="analysis_in">
                 <div class="alipayPaymentBox" v-show="dialogVisible">
                     <div id="qrcode"></div>
                     <div class="alipayPaymentBoxDio" v-if="isOverdue == 1">
                         <span class="alipayPaymentBoxDioBtn" @click="alipayPaymentBoxDioBtn">刷新</span>
                     </div>
                 </div>
            </span>
                <button class="cancel_pay" @click="cancelPay">{{ $t('account_manage.Cancel_payment')}}</button>
            </div>


            <el-form ref="form" :model="form" label-width="180px" v-show="isState == 0">
                <el-form-item :label="$t('account_manage.account_last')" class="moneyText">
                    <span class="showMoney">{{ $t('unit.money') }}<span v-html="moneyNum">2900</span></span>
                </el-form-item>

                <el-form-item :label="$t('account_manage.money_choose')">
                    <div class="moneySelect">
                        <div :class="{ addAmountAcctive: amount == 100 }" @click="amountFun(100)">
              <span>
                <span>{{ $t('unit.money') }}</span>
                <span>100</span>
                <span>{{ $t('unit.yuan') }}</span>
              </span>
                            <span class="amountIconSelect" v-if="amount == 100">
                <img src="../../../assets/images/personalSettings/iconSelect.svg" alt=""/>
              </span>
                        </div>
                        <div :class="{ addAmountAcctive: amount == 200 }" @click="amountFun(200)">
              <span>
                <span>{{ $t('unit.money') }}</span>
                <span>200</span>
                <span>{{ $t('unit.yuan') }}</span>
              </span>
                            <span class="amountIconSelect" v-if="amount == 200">
                <img src="../../../assets/images/personalSettings/iconSelect.svg" alt=""/>
              </span>
                        </div>
                        <div :class="{ addAmountAcctive: amount == 500 }" @click="amountFun(500)">
              <span>
                <span>{{ $t('unit.money') }}</span>
                <span>500</span>
                <span>{{ $t('unit.yuan') }}</span>
              </span>
                            <span class="amountIconSelect" v-if="amount == 500">
                <img src="../../../assets/images/personalSettings/iconSelect.svg" alt=""/>
              </span>
                        </div>
                        <div :class="{ addAmountAcctive: amount == 0 }"
                             @click="isShowOtherMoney ? '' : amountOtherFun(0)">
                            <el-input type="text" v-model="amount" v-if="isShowOtherMoney"
                                      @input="inputMoney(amount)"></el-input>
                            <span v-if="!isShowOtherMoney">{{ $t('account_manage.other_amount') }}</span>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item :label="$t('account_manage.pay_amount')">
                    <div class="paymentAmount">
                        <span>{{ $t('unit.money') }}</span>
                        <span v-html="amount"></span>
                        <span>{{ $t('unit.yuan') }}</span>
                    </div>
                </el-form-item>

                <el-form-item :label="$t('account_manage.paying_mode')">
                    <div class="accountBox">
            <span class="accountAlipay" :class="{ addAccpuntAcctive: billType == 1 }" @click="billTypeFun(1)">
              <img src="../../../assets/images/personalSettings/iconAlipay.svg" alt=""/>
              <span class="iconSelect" v-if="billType == 1">
                <img src="../../../assets/images/personalSettings/iconSelect.svg" alt=""/>
              </span>
            </span>
                        <span class="accountWeChat" :class="{ addAccpuntAcctive: billType == 2 }"
                              @click="billTypeFun(2)">
              <img src="../../../assets/images/personalSettings/iconWeChat.svg" alt=""/>
              <span class="iconSelect" v-if="billType == 2">
                <img src="../../../assets/images/personalSettings/iconSelect.svg" alt=""/>
              </span>
            </span>
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <div class="btn determineNomal" @click="onSubmit" v-show="rechargeHidden == 0">
                        {{ $t('account_manage.recharge_in') }}
                    </div>
                    <div class="btn determineNomal_disabler" v-show="rechargeHidden == 1">
                        {{ $t('account_manage.recharge_in') }}
                    </div>
                </el-form-item>

            </el-form>
        </div>

    </div>
</template>

<script>
import "../../../assets/scss/button.scss";
import QRCode from "qrcodejs2";
import "../../../assets/scss/dialog.scss";
import {reg} from "../../../assets/js/reg.js";

window.timeCountDown = null;
window.timeCountDownName = null;
window.CountDown = null;
window.CountDownName = null;
export default {
    name: "AccountRecharge",
    data() {
        return {
            dialogVisible: false,
            title: "", // 二维码弹窗title
            isShowJump: true, // 是否显示跳转链接
            form: {},
            moneyNum: 0, // 金额
            isShowDate: 1,
            isShowOtherMoney: false,
            amount: 100,
            billType: 1, // 支付类型 1、支付宝 2、微信 3、PayPal
            isState: 0,
            time: 300,
            orderNo: 0,
            toTiming: 10,
            isOverdue:0,
            pay_loading:0,
            rechargeHidden:0
        };
    },
    methods: {
        backFun() {
            this.$emit("childFun", this.isShowDate);
            clearInterval(window.timeCountDown);
            this.pay_loading = 0
        },
        billTypeFun(billType) {
            this.billType = billType;
            this.orderNo = 0;
        },


        //支付成功
        successfulBack() {
            this.isState = 0;
            clearInterval(window.CountDown);
            clearInterval(window.timeCountDown);
            this.backFun();
        },

        // 选择充值金额方法
        amountFun(amount) {
            this.amount = amount;
            this.isShowOtherMoney = false;
        },
        // 其他金额
        amountOtherFun(amount) {
            this.rechargeHidden = 1;
            this.amount = amount;
            this.isShowOtherMoney = true;
        },
        //取消支付
        cancelPay() {
            clearInterval(window.timeCountDown);
            this.backFun();
        },

        CountDownMin: function () {
            let CountDownName = 1000;
            clearInterval(window.CountDown);
            let CountDownMinNum= 10;
            let showCountDownMin = () => {
                console.log(CountDownMinNum)
                let timeaNum = CountDownMinNum--;
                this.toTiming = timeaNum;
                if (timeaNum < 0) {
                    clearInterval(window.CountDown);
                    this.isState = 0
                    this.backFun();
                }
            }
            showCountDownMin();
            window.CountDown = setInterval(showCountDownMin, CountDownName);
        },
        //5分钟倒计时
        CountDown: function () {
            let timeCountDownName = 1000;
            clearInterval(window.timeCountDown);
            let data = {
                orderNo: this.orderNo, // 备注/内容
                payType: this.billType,
            };
            let time = 180;
            let showCountDown = () => {
                const countDown = (second) => {
                    const s = second % 60;
                    const m = Math.floor(second / 60);
                    return `${`00${m}`.slice(-2)} : ${`00${s}`.slice(-2)}`;

                };

                const show = countDown(time--);
                console.log(show)
                this.$api.paymentQuery(data).then(res => {
                    if (res.code == 200) {
                        //0 初始化  1是成功 5失败
                        if (res.data == 1) {
                            this.isState = 1
                            this.pay_loading = 1
                            clearInterval(window.timeCountDown);
                            this.CountDownMin();
                        }
                        if (res.data == 5) {
                            this.isState = 5;
                            this.pay_loading = 1
                            clearInterval(window.timeCountDown);
                            this.CountDownMin()
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                });
                if (time < 0) {
                    clearInterval(window.timeCountDown);
                    this.isOverdue = 1;
                }

            }
            showCountDown();
            window.timeCountDown = setInterval(showCountDown, timeCountDownName);
        },

        alipayPaymentBoxDioBtn(){
            this.isOverdue = 0;
            this.onSubmit();
        },
        // 校验输入金额
        inputMoney(val) {

            // 判断是否为空
            if (val == ""||val <0 ) {
                this.amount = 0;
                this.rechargeHidden = 1;
            } else {
                // 判断输入为整数，或者是小数 大于1为小数 否则为整数
                if (val.split(".").length > 1) {
                    this.amount = this.amount.split(".")[0] + "." + val.split(".")[1].substr(0, 2);
                    if (val.split(".")[1].substr(0, 1) != "") {
                        if (reg.checkMoney.test(val.split(".")[1].substr(0, 1))) {
                            if (!reg.checkMoney.test(val.split(".")[1].substr(1, 2))) {
                                this.amount = 0;
                                this.rechargeHidden = 1;
                            }
                        } else {
                            this.amount = 0;
                            this.rechargeHidden = 1;
                        }
                    }
                    this.rechargeHidden = 0;
                } else {
                    if (val != 0) {
                        this.amount = val.replace(/\b(0+)/gi, "");
                        this.rechargeHidden = 0;
                    } else {
                        this.amount = 0;
                        this.rechargeHidden = 1;
                    }
                    if (reg.checkMoney.test(val)) {
                        if (val.length > 20) {
                            this.$message.error(this.$t('popup.editAcct_remind.no_more_than_20'));
                            this.amount = val.substr(0, 20);
                            this.rechargeHidden = 0;
                        }
                    } else {
                        this.amount = 0;
                        this.rechargeHidden = 1;
                    }
                }
                //this.onSubmit();
            }
        },
        // 点击充值
        submitAliPayment() {
            let postData = {
                subject: this.$t('account_manage.recharge_in'), // 备注/内容
                amount: this.amount, // 充值金额
                channelType: "2", // 渠道类型 1、套餐充值 2、普通充值
                billType: this.billType, // 支付类型 1、支付宝 2、微信 3、PayPal
                type: 1,
                billChannel: "2" // 支付渠道标识 1：钱包扣款 2：扫码扣款 3：红包抵扣 （当channel_type=1才有值)
            };
            this.$api.aliPaymentFace(postData).then(res => {
                if (res.code == 200) {
                    this.dialogVisible = true;
                    this.title = this.$t('account_manage.pay_width_Alipay');
                    this.isShowJump = true;
                    this.qrcodeScan(res.data.url);
                    this.orderNo = res.data.orderNo;
                    this.getAliPayment();
                    this.CountDown();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 微信充值
        submitWeixinPayment() {
            let postData = {
                subject: this.$t('account_manage.recharge_in'), // 备注/内容
                amount: this.amount, // 充值金额
                channelType: "2", // 渠道类型 1、套餐充值 2、普通充值
                billType: this.billType, // 支付类型 1、支付宝 2、微信 3、PayPal
                billChannel: "2" // 支付渠道标识 1：钱包扣款 2：扫码扣款 3：红包抵扣 （当channel_type=1才有值)
            };
            this.$api.weixinPayment(postData).then(res => {
                if (res.code == 200) {
                    this.dialogVisible = true;
                    this.title = this.$t('account_manage.pay_width_Wechat');
                    this.isShowJump = false;
                    let data = res.data;
                    this.qrcodeScan(data.code_url);
                    this.orderNo = data.orderNo;
                    this.CountDown();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 点击提交按钮
        onSubmit() {
            this.billType == 1
                ? this.submitAliPayment()
                : this.submitWeixinPayment();

            this.isState = 3;
        },
        // 二维码弹窗
        qrcodeScan(text) {
            let setQrcode = setInterval(() => {
                let qrcodeHtml = document.getElementById("qrcode");
                // let image = document.getElementById('#id');
                if (qrcodeHtml) {
                    qrcodeHtml.innerHTML = "";
                    //生成二维码
                    let qrcode = new QRCode("qrcode", {
                        width: 137, // 二维码宽度
                        height: 140, // 二维码高度
                        text: text
                        // image 添加图片元素
                        // src 添加图片地址
                    });
                    clearInterval(setQrcode);
                }
            });
        },
        // 获取二维码弹窗信息
        getAliPayment() {
            let postData = {
                subject: this.$t('account_manage.recharge_in'), // 备注/内容
                amount: this.amount, // 充值金额
                channelType: "2", // 渠道类型 1、套餐充值 2、普通充值
                billType: this.billType, // 支付类型 1、支付宝 2、微信 3、PayPal
                type: "2",
                billChannel: "2" // 支付渠道标识 1：钱包扣款 2：扫码扣款 3：红包抵扣 （当channel_type=1才有值)
            };
            this.$api.aliPayment(postData).then(res => {
                localStorage.setItem("divFormHtml", res.data);
            });
        },

        Initialize(){
            this.$api.queryByToken({}).then(res => {
                if (res.code == 200) {
                    const {billTypes, company, discount} = JSON.parse(res.data);
                    discount.priceAccount.toString().split(".")[1]
                        ? (this.moneyNum = discount.priceAccount)
                        : (this.moneyNum = discount.priceAccount + ".00");

                    //this.onSubmit()

                } else {
                    this.$message.error(res.message);
                }
            });
        }

    },
    created() {
       this.Initialize();
       clearInterval(window.timeCountDown);
    },
};
</script>

<style lang="scss" scoped>
.accountRechargeBox {
    .accountRechargeTitle {
        position: relative;
        height: 24px;
        display: flex;

        .accountRechargeTitleText {
            display: flex;
            & > :nth-child(1) {
                font-size: 16px;
                font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                font-weight: 400;
                color: #666666;
            }

            & > :nth-child(2) {
                font-size: 16px;
                font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                font-weight: 400;
                color: #333333;
            }
        }

        .accountRechargeTitleBtn {
            position: absolute;
            top: 0;
            right: 18px;
            margin-left: 683px;
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
                font-size: 18px;
                font-family: SourceHanSansSC-Normal, SourceHanSansSC;
                font-weight: 400;
                color: #333333;
            }

            img {
                padding-left: 10px;
            }
        }
    }

    .dividingLine {
        margin-top: 13px;

        width: 890px;
        height: 1px;
        background: #d8d8d8;
    }

    .analysis_in {
        width: 137px;
        height: 140px;
        display: block;
        margin: 30px auto 12px;
    }

    .analysis_loading {
        width: 100%;
        height: 33px;
        font-size: 24px;
        color: #333333;
        line-height: 33px;
        display: block;
        text-align: center;
        font-weight: 600;
        margin-top: 40px;
    }

    .cancel_pay {
        width: 200px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        border: 0;
        background: #ffffff;
        display: block;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
    }
    .cancel_pay:hover{
        color: #4773b8;
    }
    .alipayPaymentBoxDio{
        position: absolute;
        width: 137px;
        height: 140px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto !important;
        background: rgba(0,0,0,0.5);
    }
    .alipayPaymentBoxDioBtn{
        width: 68px;
        height: 28px;
        background: linear-gradient(90deg, #3A83CE 0%, #416AB0 100%);
        box-shadow: 0px 2px 5px 0px rgb(68 111 178 / 30%);
        border-radius: 4px;
        display: block;
        line-height: 28px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        cursor: pointer;
    }
    .successful_img {
        width: 112px;
        height: 112px;
        display: block;
        margin: 80px auto 16px;
        border-radius: 112px;
    }

    .successful_img img {
        width: 112px;
        height: 112px;
    }

    .successful_title {
        width: 100%;
        height: 32px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 32px;
        text-align: center;
        display: block;
    }

    .successful_back {
        width: 92px;
        height: 36px;
        background: linear-gradient(90deg, #3A83CE 0%, #416AB0 100%);
        border-radius: 4px;
        color: #ffffff;
        border: none;
        cursor: pointer;
        display: block;
        margin: 16px auto;
    }

    .successful_num {
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        text-align: center;
        display: block;
    }
    .successful_num_style{
        width: 100%;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        text-align: center;
        display: block;
    }

    .accountRechargeContent {
        margin-top: 52px;


        .determineNomal {
            width: 146px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            font-family: SourceHanSansSC-Medium, SourceHanSansSC;
            color: #ffffff;
        }
        .determineNomal_disabler{
            width: 146px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            font-family: SourceHanSansSC-Medium, SourceHanSansSC;
            color: #ffffff;
            background: #cccccc;
        }
        form {
            width: 326px;
            margin-top: 20px;

            .moneyText {
                height: 29px;
            }

            .showMoney {
                font-size: 20px;
                font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                font-weight: bold;
                color: #333333;
            }

            .accountBox {
                display: flex;

                .accountAlipay {
                    display: block;
                    cursor: pointer;
                    width: 190px !important;
                    height: 60px !important;
                    background: #ffffff;
                    border-radius: 4px;
                    border: 1px solid #dcdcdc;
                    position: relative;

                    img {
                        padding-top: 14px;
                        padding-left: 51px;
                    }

                    .iconSelect {
                        height: 24px;
                        position: absolute;
                        right: 0;
                        bottom: 0;

                        img {
                            padding: 0px;
                        }
                    }
                }

                .accountWeChat {
                    display: block;
                    cursor: pointer;
                    width: 190px !important;
                    height: 60px !important;
                    background: #ffffff;
                    border-radius: 4px;
                    border: 1px solid #dcdcdc;
                    margin-left: 20px;
                    position: relative;

                    img {
                        padding-top: 14px;
                        padding-left: 34px;
                    }

                    .iconSelect {
                        height: 24px;
                        position: absolute;
                        right: 0;
                        bottom: 0;

                        img {
                            padding: 0px;
                        }
                    }
                }

                .addAccpuntAcctive {
                    border: 1px solid #4773b8;
                }
            }

            .moneySelect {
                display: flex;

                div {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 140px;
                    height: 60px;
                    background: #ffffff;
                    border-radius: 4px;
                    border: 1px solid #dcdcdc;

                    & > :nth-child(1),
                    & > :nth-child(3) {
                        font-size: 14px;
                        font-family: SourceHanSansSC-Medium, SourceHanSansSC;

                        color: #333333;
                        border: none;
                        line-height: 18px;
                        text-align: center;
                    }

                    & > :nth-child(2) {
                        font-size: 24px;
                        font-family: SourceHanSansSC-Medium, SourceHanSansSC;

                        color: #333333;
                    }

                    & > :nth-child(2) {
                        font-size: 16px;
                        font-family: SourceHanSansSC-Medium, SourceHanSansSC;

                        color: #333333;
                    }
                }

                & > :nth-child(2),
                & > :nth-child(3),
                & > :nth-child(4) {
                    margin-left: 20px;
                }

                & > :nth-child(1),
                & > :nth-child(2),
                & > :nth-child(3) {
                    position: relative;

                    .amountIconSelect {
                        height: 25px;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }

                .addAmountAcctive {
                    border: 1px solid #4773b8;
                }
            }

            .paymentAmount {
                span {
                    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
                }

                & > :nth-child(1) {
                    font-size: 14px;
                    color: #f53500;
                }

                & > :nth-child(2) {
                    font-size: 24px;
                    color: #f53500;
                }

                & > :nth-child(3) {
                    font-size: 14px;
                    color: #333333;
                }
            }
        }
    }

    .alipayPaymentBox {
        flex-direction: column;
        align-items: center;
        position: relative;
        & > :nth-child(2) {
            margin-top: 10px;
        }

        .jumpPaymentPage {
            a {
                text-decoration: none;
                color: #4773b8;
            }
        }
    }
}
</style>
<style lang="scss">
.accountRechargeBox {

    .el-form-item {
        width: 620px;
    }

    .el-form-item__label {
        font-size: 18px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #666666;
    }
}
</style>
