import Stage from "../../../assets/utils/stage.js";
import cvCommon from "./tools/opencv-common.js";
// import ossUrlWeb from '../../../assets/utils/ossUrlWeb.js'

class karyoCanvasController {
    constructor({ element, chromoList, karyoInfo, arrowList, app = {} }) {
        this.name = "karyo";
        this.element = element;
        this.canvas = this.element.getContext("2d");
        this.parentNode = this.element.parentNode;
        this.optBounding = {
            width: 1600,
            height: 1200
        };
        this.primaryBounding = {
            width: 1600,
            height: 1200
        };
        this.app = app;
        this.optPicJS = null;
        this.originPicJS = null;
        this.optBackPicJS = null;
        this.freeJsMat();
        this.reset(chromoList, karyoInfo, arrowList);
    }
    /**
     * 初始化
     * 加载优化图 原图
     * 初始化画布父元素宽高
     */
    init() {
        this.unzipChromoList();
        if (this.app) {
            const item = this.app.karyoList.filter(
                a => a.id === this.karyoInfo.id
            )[0];
            if (item && item.optImage && item.primaryImage) {
                this.optImage = item.optImage;
                this.primaryImage = item.primaryImage;
                this.initStage();
            } else {
                this.loadOptUrl();
                this.loadPrimaryUrl();
                this.loadOptBackUrl();
            }
        } else {
            this.loadOptUrl();
            this.loadPrimaryUrl();
            this.loadOptBackUrl();
        }
    }
    /**
     * 重置
     */
    reset(chromoList, karyoInfo, arrowList) {
        this.chromoList = chromoList;
        this.karyoInfo = karyoInfo;
        this.arrowList = arrowList || [];
        this.primaryImage = null;
        this.primaryImageShape = null;
        this.optImage = null;
        this.fpslist = [];
        this.countPoints = [];
        this.initFinished = false;
        this.freeJsMat();
        this.originPicJS = null;
        this.optPicJS = null;
        this.optBackPicJS = null;
        // window.cancelRequestAnimationFrame && window.cancelRequestAnimationFrame(window.timer)
        this.init();
    }
    /**
     * 释放大图的jsmat申请的内存
     */
    freeJsMat() {
        if (this.originPicJS) {
            window.Module._free(this.originPicJS.data_ptr);
            delete this.originPicJS;
        }
        if (this.optPicJS) {
            window.Module._free(this.optPicJS.data_ptr);
            delete this.optPicJS;
        }
        if (this.optBackPicJS) {
            window.Module._free(this.optBackPicJS.data_ptr);
            delete this.optBackPicJS;
        }
    }
    /**
     * 加载优化图
     */
    loadOptUrl() {
        const begin = () => {
            this.initStage();
            if (window.loadingInstance && window.startTime) {
                window.loadingInstance.close();
                const time = +new Date() - window.startTime;
                console.warn("数据加载并分裂相渲染完成，用时" + time + "毫秒");
                // window.APP.$message.success('数据加载并分裂相渲染完成，用时' + (time) + "毫秒");
            }
            window.APP.initK = true;
            // this.render(time);
            // this.loadPrimaryUrl();
        };
        let image = new Image();
        image.setAttribute("crossOrigin", "anonymous");
        image.id = "imgOpti" + Math.random();
        console.warn("开始加载优化图", +new Date());
        image.onload = () => {
            console.warn("优化图加载完毕", +new Date());
            this.optImage = image;
            this.optBounding.width = image.width;
            this.optBounding.height = image.height;
            !this.optPicJS &&
                cvCommon.getJSMatData(image.src).then(jsMat => {
                    this.optPicJS = jsMat;
                    console.warn("优化图JSMAT生成完毕", +new Date());
                });
            begin();
        };
        image.onerror = () => {
            begin();
        };
        image.src = this.karyoInfo.optUrl;
        // image.src = ossUrlWeb(this.karyoInfo.optUrl, 1, 100);
    }

    /**
     * 加载原图
     */
    loadPrimaryUrl(callback) {
        let image = new Image();
        image.setAttribute("crossOrigin", "anonymous");
        image.id = "imgOriginal" + Math.random();
        image.onload = () => {
            this.primaryImage = image;
            this.primaryBounding.width = image.width;
            this.primaryBounding.height = image.height;
            !this.originPicJS &&
                cvCommon.getJSMatData(this.karyoInfo.primaryUrl).then(jsMat => {
                    this.originPicJS = jsMat;
                });

            // for (let index = 0; index < 100; index++) {
            //     cvCommon.getJSMatData(this.karyoInfo.optBackUrl).then(jsMat => {
            //         window.Module._free(this.originPicJS.data_ptr)
            //         delete this.originPicJS
            //         console.log(new Date().getTime(), " index:", index, "Module.buffer.byteLength:", window.Module.buffer.byteLength)
            //         this.originPicJS = jsMat
            //     });
            //     console.log("getJSMatData: ", 1)
            // }

            if (typeof callback === "function") {
                callback();
            } else if (this.stage) {
                const primaryImage = this.stage.graphs.image({
                    image: this.primaryImage,
                    x: 0,
                    y: 0,
                    zindex: -1,
                    hide: this.app && !this.app.isShowPrimaryImage
                });
                this.primaryImageShape = primaryImage;
                if (this.stage.objects.indexOf(primaryImage) < 0) {
                    this.stage.addChild(primaryImage);
                    this.stage.draw();
                }
            }
        };
        image.src = this.karyoInfo.primaryUrl;
    }
    /**
     * 加载optBack图片
     */
    loadOptBackUrl() {
        console.warn(+new Date());
        let image = new Image();
        image.setAttribute("crossOrigin", "anonymous");
        image.id = "imgOpti" + Math.random();
        image.onload = () => {
            this.optBackImage = image;
            !this.optBackPicJS &&
                cvCommon.getJSMatData(image.src).then(jsMat => {
                    this.optBackPicJS = jsMat;
                    console.warn(+new Date());
                });
        };
        image.src = this.karyoInfo.optBackUrl;
    }

    /**
     * 初始化舞台
     */
    initStage() {
        this.setCanvasParentSize(
            this.optBounding.width,
            this.optBounding.height
        );
        if (this.stage) {
            this.stage.clearAllChild();
            this.stage.reset();
        } else {
            this.stage = new Stage({
                element: this.element,
                offset: this.offset,
                scale: 1
            });
            this.stage.$parent = this.app;
            this.stage.controller = this;
        }
        window.stage2 = this.stage;
        this.resetKaryo(true);
        //console.log("页面 初始化中期分裂相完成", +new Date());
    }

    /**
     * 重置分裂相画布
     * @param {Boolean} isNotAddPrimaryImage 是否不需要添加原图 true为不添加
     */
    resetKaryo(isNotAddPrimaryImage) {
        this.resetStage();
        this.karyoInfo.singleNum = this.chromoList.reduce((a, chromo) => {
            let posarr =
                typeof chromo.countPoints === "string"
                    ? JSON.parse(chromo.countPoints)
                    : chromo.countPoints;
            return a + posarr.length;
        }, 0);
        // console.warn("1",+new Date())
        this.initFinished = false;
        this.countPoints = [];
        this.stage.clearAllChild();
        // const arrowList = JSON.parse(this.app.karyoInfo.originalArrowLines)
        // this.arrowList = this.arrowList.map(a=>this.arrowRead(a));
        //创建优化图对象
        if (this.optImage) {
            const optImage = this.stage.graphs.image({
                image: this.optImage,
                x: 0,
                y: 0,
                zindex: -1,
                hide: this.app && this.app.isShowPrimaryImage
            });
            this.optImageShape = optImage;
            this.stage.addChild([optImage]);
        }

        if (this.primaryImage) {
            this.primaryImageShape = this.stage.graphs.image({
                image: this.primaryImage,
                x: 0,
                y: 0,
                zindex: -1,
                hide: this.app && !this.app.isShowPrimaryImage
            });
            this.stage.addChild(this.primaryImageShape);
        }
        //染色体与图形映射关系
        this.chromoMap = [];
        this.arrowMap = {};
        //创建染色体集合与染色体编号
        const polygons = [];
        const textarr = [];
        const countPoints = [];
        const getTextOfId = id => {
            if (id < 23) {
                return id;
            } else if (id === 23) {
                return "X";
            } else if (id === 24) {
                return "Y";
            } else if (id === 25) {
                return "mar";
            } else {
                return "";
            }
        };
        // console.warn("2",+new Date())
        this.chromoList.forEach((chromo, i) => {
            const matrix = chromo._cimgBoundaryPointsList.map(pos => {
                return [
                    pos.x + chromo._cimgBoundingbox.x,
                    pos.y + chromo._cimgBoundingbox.y
                ];
            });
            const polygon = this.stage.graphs.polygon({
                matrix: matrix,
                color: "#51A749",
                rotate: 0,
                lineWidth: this.offset.zoom,
                debug: i === 0,
                zindex: 1,
                drag: false,
                realType: "chromo"
            });
            polygons.push(polygon);
            const text = this.stage.graphs.text({
                x:
                    chromo._cimgBoundingbox.x +
                    (chromo.cimgOrientation > 0
                        ? chromo._cimgBoundingbox.w
                        : 0),
                y: chromo._cimgBoundingbox.y,
                text: getTextOfId(chromo.chromoId),
                color: "#f00",
                font: "28px Arial",
                holdFont: false,
                zindex: 3,
                drag: false
            });
            if (this.app && this.app.globalMode === "arrange" && this.app.hasHideNumber==0) {
                textarr.push(text);
            }
            let posarr =
                typeof chromo.countPoints === "string"
                    ? JSON.parse(chromo.countPoints)
                    : chromo.countPoints;
            let points = [];
            posarr.forEach(pos => {
                const _pos = {
                    x: parseInt(pos.x) + this.offset.TRANS_X,
                    y: parseInt(pos.y) + this.offset.TRANS_Y
                };
                const point = this.stage.graphs.point({
                    point: _pos,
                    lineWidth: 2,
                    hide: this.app ? this.app.toolsType !== "count" : true,
                    length: 10,
                    zindex: 2,
                    color:
                        this.karyoInfo.singleNum == 46
                            ? "rgba(46,198,87,.8)"
                            : "rgba(245,53,0,.8)"
                });
                countPoints.push(point);
                points.push(point);
            });

            this.chromoMap.push({
                chromo: chromo,
                shape: polygon,
                text: text,
                points: points
            });
        });
        // console.warn("3",+new Date())
        if (
            typeof this.karyoInfo.undetectedChromoPoints === "string" &&
            !!this.karyoInfo.undetectedChromoPoints
        ) {
            const undetectedChromoPoints = JSON.parse(
                this.karyoInfo.undetectedChromoPoints
            );
            undetectedChromoPoints.forEach(pos => {
                const _pos = {
                    x: parseInt(pos.x) + this.offset.TRANS_X,
                    y: parseInt(pos.y) + this.offset.TRANS_Y
                };
                const point = this.stage.graphs.point({
                    point: _pos,
                    lineWidth: 2,
                    hide: this.app ? this.app.toolsType !== "count" : true,
                    length: 10,
                    zindex: 2
                });
                this.countPoints.push(point);
            });
        }
        // console.warn("4",+new Date())
        let arrows = [];
        if (this.app && this.app.globalMode === "arrange") {
            arrows = this.arrowList.map(arrow => {
                // console.log(arrow);
                !arrow.guid && (arrow.guid = this.getGuid());
                const shape = this.stage.graphs.arrow({
                    beginPoint: {
                        x: arrow.start_x + this.offset.TRANS_X,
                        y: arrow.start_y + this.offset.TRANS_Y
                    },
                    stopPoint: {
                        x: arrow.target_x + this.offset.TRANS_X,
                        y: arrow.target_y + this.offset.TRANS_Y
                    },
                    color: "#f00",
                    lineWidth: 3,
                    note: arrow.note || "",
                    id: arrow.guid
                });
                this.arrowMap[arrow.guid] = {
                    shape,
                    arrow
                };
                return shape;
            });
        }
        // console.log('arrows',arrows);
        // console.warn("5",+new Date())

        if (!isNotAddPrimaryImage) {
            if (this.stage.objects.indexOf(this.primaryImageShape) < 0) {
                this.stage.addChild(this.primaryImageShape);
            }
        }
        this.stage.addChild(
            polygons
                .concat(textarr)
                .concat(countPoints)
                .concat(this.countPoints)
                .concat(arrows)
        );
        if (this.app && this.app.choosedChromo) {
            const shape = this.getShapeByChromo(this.app.choosedChromo);
            shape && (shape.color = "#f00");
        }
        // console.warn("6",+new Date())

        setTimeout(() => {
            this.stage.draw();
			this.app.polygonChromo();
        }, 10);
        // console.warn("7",+new Date())
        this.initFinished = true;
    }

    getGuid() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
            c
        ) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    /**
     * 将箭头属性转换成与local对应一致的格式
     * @param {*} arrow
     */
    arrowLocal(arrow) {
        if (arrow.beginPoint) {
            return {
                beginPoint: arrow.beginPoint,
                stopPoint: arrow.stopPoint,
                text: arrow.text || arrow.txt,
                guid: arrow.guid || arrow.id || this.getGuid()
            };
        } else {
            return {
                beginPoint: {
                    x: arrow.start_x / this.offset.localWidth,
                    y: arrow.start_y / this.offset.localHeight
                },
                stopPoint: {
                    x: arrow.target_x / this.offset.localWidth,
                    y: arrow.target_y / this.offset.localHeight
                },
                text: arrow.note,
                guid: arrow.guid || this.getGuid()
            };
        }
    }
    arrowOldFormat(point) {
        return {
            x: point.x * this.offset.w,
            y: point.y * this.offset.h
        };
    }
    arrowRead(arrow) {
        // console.log(arrow);
        if (arrow.beginPoint) {
            return {
                beginPoint: this.arrowOldFormat(arrow.beginPoint),
                stopPoint: this.arrowOldFormat(arrow.stopPoint),
                note: arrow.text || arrow.txt || "",
                guid: arrow.guid || arrow.id || this.getGuid()
            };
        } else {
            return Object.assign(arrow, {
                note: arrow.note || "",
                guid: arrow.guid || this.getGuid()
            });
        }
    }

    arrowWrite(arrow) {
        let obj = JSON.parse(JSON.stringify(arrow));
        delete obj.guid;
        return obj;
    }

    shapeToArrow(shape) {
        return {
            start_x: shape.beginPoint.x - this.offset.TRANS_X,
            start_y: shape.beginPoint.y - this.offset.TRANS_Y,
            target_x: shape.stopPoint.x - this.offset.TRANS_X,
            target_y: shape.stopPoint.y - this.offset.TRANS_Y,
            note: shape.txt,
            guid: shape.id || shape.guid || getGuid()
        };
    }

    /**
     * 设置画布父元素宽高
     */
    setCanvasParentSize(w, h) {
        //2030 1600
        // console.log(w,h);
        const win_width = this.parentNode.clientWidth; //1208
        const win_height = this.parentNode.clientHeight; // 553
        if (!win_width) {
            return this.offset;
        }
        let realWidth = win_width,
            realHeight = win_height;
        const localWidth = 2030;
        const localHeight = 2030;
        let drawWidth = localWidth;
        let drawHeight = (win_height / win_width) * drawWidth;
        let TRANS_X = 0,
            TRANS_Y = 0;
        const rate = drawWidth / drawHeight;
        if (w / h >= drawWidth / drawHeight) {
            //图像宽度与绘制区域宽度相同时，图像高度小于等于绘制区域高度
            //显示结果应为 图片宽度与容器宽度相等 图片垂直居中
            drawWidth = w;
            drawHeight = w / rate;
            TRANS_X = 0;
            TRANS_Y = (drawHeight - h) / 2;
        } else {
            //图像宽度与绘制区域宽度相同时，图像高度大于绘制区域高度
            //显示结果应为 图片高度与容器高度相等 图片水平居中
            drawWidth = h * rate;
            drawHeight = h;
            TRANS_X = (drawWidth - w) / 2;
            TRANS_Y = 0;
        }

        const scale = realWidth / drawWidth;
        const l_height = (h / w) * localWidth;
        const l_width = (w / h) * localHeight;
        let LocalWidth = 0,
            LocalHeight = 0;
        if (l_height <= localHeight) {
            LocalWidth = localWidth;
            LocalHeight = l_height;
        } else if (l_width <= localWidth) {
            LocalWidth = l_width;
            LocalHeight = localHeight;
        }

        this.offset = {
            realWidth,
            realHeight,
            parentWidth: win_width,
            parentHeight: win_height,
            width: drawWidth,
            height: drawHeight,
            scale,
            zoom: drawWidth / realWidth,
            localWidth: LocalWidth,
            localHeight: LocalHeight,
            w,
            h,
            TRANS_X,
            TRANS_Y
        };
    }
    setCanvasParentSize1(w, h) {
        const win_width = this.parentNode.clientWidth;
        const win_height = this.parentNode.clientHeight;
        // console.log(this.windowNode.clientHeight,win_height);
        const scaleHeight = (h / w) * win_width;
        const scaleWidth = (w / h) * win_height;
        let realWidth = 0,
            realHeight = 0;
        if (scaleHeight <= win_height) {
            realWidth = win_width;
            realHeight = scaleHeight;
        } else if (scaleWidth <= win_width) {
            realWidth = scaleWidth;
            realHeight = win_height;
        }
        const scale = realWidth / w;

        const localWidth = 2030;
        const localHeight = 2030;
        const l_height = (h / w) * localWidth;
        const l_width = (w / h) * localHeight;
        let LocalWidth = 0,
            LocalHeight = 0;
        if (l_height <= localHeight) {
            LocalWidth = localWidth;
            LocalHeight = l_height;
        } else if (l_width <= localWidth) {
            LocalWidth = l_width;
            LocalHeight = localHeight;
        }

        this.offset = {
            realWidth,
            realHeight,
            parentWidth: win_width,
            parentHeight: win_height,
            width: w,
            height: h,
            scale,
            zoom: w / realWidth,
            localWidth: LocalWidth,
            localHeight: LocalHeight
        };
    }

    /**
     * 渲染画布 - 调试渲染速率
     */
    render(time) {
        const t = new Date().getTime();
        window.timer = window.requestAnimationFrame(() => {
            this.stage.draw();
            this.render();
            const _t = new Date().getTime() - t;
            const fps = parseInt(1000 / _t);
            this.maxfps = Math.max(this.maxfps || fps, fps);
            this.minfps = Math.min(this.minfps || fps, fps);
            this.fpslist.length > 1000 && (this.fpslist = []);
            this.fpslist.push(fps);
            const total =
                this.fpslist.reduce((a, b) => a + b) / this.fpslist.length;
            document.querySelector("#fps").innerHTML =
                this.$t("popup.tips.currentfps") +
                fps +
                this.$t("popup.tips.smallestfps") +
                this.minfps +
                this.$t("popup.tips.bigestfps") +
                this.maxfps +
                this.$t("popup.tips.averagefps") +
                parseInt(total);
        });
    }

    /**
     * 解压染色体列表中的点坐标信息
     */
    unzipChromoList() {
        this.chromoList.forEach(chromo => {
            if (!(chromo.cimgBoundingbox instanceof Object)) {
                const [y, x, h, w] = chromo.cimgBoundingbox
                    .split(",")
                    .map(a => Number(a));
                chromo._cimgBoundingbox = {
                    y,
                    x,
                    w,
                    h
                };
            }

            if (!(chromo.cimgBoundaryPointsList instanceof Object)) {
                const pointList = chromo.cimgBoundaryPointsList
                    .split(",")
                    .filter(a => a)
                    .map(pos => {
                        const [x, y] = pos.split(".").map(a => Number(a));
                        return {
                            x: x || 0,
                            y: y || 0
                        };
                    });
                chromo._cimgBoundaryPointsList = pointList;
            }
        });
    }

    /**
     * 窗口发生变化时 重置舞台大小
     */
    resetStage() {
        this.setCanvasParentSize(
            this.optBounding.width,
            this.optBounding.height
        );
        // console.log('karyocontroller', this.offset);
        if (this.stage) {
            this.stage.offset = Object.assign({}, this.offset);
            this.stage.realWidth = this.offset.realWidth;
            this.stage.realHeight = this.offset.realHeight;
            this.stage.setCanvasSize(this.stage.scale);
        }
    }

    /**
     * 导出base64图片
     */
    exportBase64({ isHideXY }) {
        const canvas = document.createElement("canvas");
        canvas.width = this.stage.offset.w;
        canvas.height = this.stage.offset.h;
        const context = canvas.getContext("2d");
        context.fillStyle = "#FFFFFF";
        context.fillRect(0, 0, this.stage.offset.w, this.stage.offset.h);

        const clearWhite = img => {
            var _canvas = document.createElement("canvas"),
                _ctx = _canvas.getContext("2d");
            _canvas.width = img.width;
            _canvas.height = img.height;
            _ctx.drawImage(img, 0, 0, img.width, img.height);
            var imgdata = _ctx.getImageData(0, 0, img.width, img.height);
            for (var i = 0; i < imgdata.data.length; i += 4) {
                if (
                    imgdata.data[i] == 255 &&
                    imgdata.data[i + 1] == 255 &&
                    imgdata.data[i + 2] == 255
                ) {
                    imgdata.data[i + 3] = 0;
                }
            }
            _ctx.clearRect(img, 0, 0, img.width, img.height);
            _ctx.putImageData(imgdata, 0, 0, 0, 0, img.width, img.height);
            return _canvas.toDataURL("image/png", 1.0);
        };
        return new Promise((resolve, reject) => {
            let timer = setInterval(() => {
                if (this.initFinished) {
                    clearInterval(timer);
                    timer = null;
                    let count = 0;
                    const list = this.chromoList.map(item => {
                        return Object.assign({}, item);
                    });

                    const check = () => {
                        count++;
                        if (count === list.length) {
                            const stage = {
                                canvas: context,
                                element: canvas,
                                width: this.stage.offset.w,
                                height: this.stage.offset.h,
                                $parent: this.app,
                                controller: this,
                                scale: 1,
                                transX: 0,
                                transY: 0,
                                offset: {
                                    scale: 0.5
                                }
                            };
                            let loadedcount = 0,
                                total = 0;
                            list.forEach(item => {
                                if (
                                    !(
                                        item.chromoId > 22 &&
                                        item.chromoId < 25 &&
                                        isHideXY
                                    )
                                ) {
                                    total++;
                                    const img = item.img;
                                    const $img = new Image();
                                    $img.crossOrigin = "anonymous";
                                    $img.onload = function() {
                                        loadedcount++;
                                        const y = item._cimgBoundingbox.y;
                                        const x = item._cimgBoundingbox.x;
                                        const h = item._cimgBoundingbox.h;
                                        const w = item._cimgBoundingbox.w;
                                        const rotate =
                                            item.cimgOrientation > 0
                                                ? 90 - item.cimgOrientation
                                                : -90 - item.cimgOrientation;
                                        const _tx =
                                            item.imgHandledRotatedDegree > 0
                                                ? w
                                                : 0;
                                        const angle =
                                            -item.imgHandledRotatedDegree +
                                            rotate;
                                        const cx = x + w / 2;
                                        const cy = y + h / 2;
                                        const radius = (angle * Math.PI) / 180;
                                        const px = cx - img.width / 2;
                                        const py = cy - img.height / 2;
                                        const tx =
                                            Math.cos(radius) * (px - cx) -
                                            Math.sin(radius) * (py - cy) +
                                            cx;
                                        const ty =
                                            Math.sin(radius) * (px - cx) +
                                            Math.cos(radius) * (py - cy) +
                                            cy;
                                        context.save();
                                        context.translate(tx, ty);
                                        context.rotate(radius);
                                        context.drawImage(
                                            $img,
                                            0,
                                            0,
                                            $img.width,
                                            $img.height
                                        );
                                        context.restore();
                                        checkShouldDrawArrow();
                                    };
                                    $img.onerror = () => {
                                        loadedcount++;
                                        checkShouldDrawArrow();
                                    };
                                    $img.src = clearWhite(item.img);
                                }
                            });
                            const checkShouldDrawArrow = () => {
                                if (loadedcount < total) {
                                    return;
                                }
                                this.stage.objects
                                    .filter(a => a.type === "arrow")
                                    .map(arrow => {
                                        const obj = Object.assign(
                                            {
                                                _: stage
                                            },
                                            arrow
                                        );
                                        obj._ = stage;
                                        obj.beginPoint = {
                                            x:
                                                (arrow.beginPoint.x *
                                                    this.stage.width -
                                                    this.stage.offset.TRANS_X) /
                                                this.stage.offset.w,
                                            y:
                                                (arrow.beginPoint.y *
                                                    this.stage.height -
                                                    this.stage.offset.TRANS_Y) /
                                                this.stage.offset.h
                                        };
                                        obj.stopPoint = {
                                            x:
                                                (arrow.stopPoint.x *
                                                    this.stage.width -
                                                    this.stage.offset.TRANS_X) /
                                                this.stage.offset.w,
                                            y:
                                                (arrow.stopPoint.y *
                                                    this.stage.height -
                                                    this.stage.offset.TRANS_Y) /
                                                this.stage.offset.h
                                        };
                                        console.warn(obj);
                                        return obj;
                                    })
                                    .forEach(arrow => {
                                        arrow.draw();
                                    });
                                setTimeout(() => {
                                    const base64 = canvas.toDataURL(
                                        "image/png",
                                        1.0
                                    );
                                    resolve(base64);
                                }, 100);
                            };
                        }
                    };
                    list.forEach(chromo => {
                        const img = new Image();
                        img.setAttribute("crossOrigin", "anonymous");
                        img.onload = () => {
                            check();
                        };
                        img.onerror = () => {
                            check();
                        };
                        img.src = chromo.justUrl;
                        chromo.img = img;
                    });
                }
            });
        });
    }

    /**
     * 根据图形获取源数据
     */
    getChromoByShape(shape) {
        const filter = this.chromoMap.filter(item => item.shape === shape);
        return filter && filter.length > 0 ? filter[0].chromo : null;
    }

    /**
     * 根据chromo源数据获取图形
     */
    getShapeByChromo(chromo) {
        const filter = this.chromoMap.filter(item => item.chromo === chromo);
        return filter && filter.length > 0 ? filter[0].shape : null;
    }
}
export default karyoCanvasController;
