<template>
  <div class="searchLink">
    <!-- 搜索框-->
    <div class="searchBtn">
      <span class="search-btn">
        <img src="../../assets/images/analysissystem/search.svg" />
        <input :placeholder="$t('searchLink.name_search')" v-model="searchVal" @input="inputFunc" />
      </span>
      <span class="nameSorting_center" @click="timeName()">
        <span>{{titleName}}</span>
        <img src="../../assets/images/analysissystem/icon_bottom.png" />
      </span>
      <span class="activity_sorting" @click="sortingModeBtn()">
        <span>{{sorName}}</span>
        <img :src="studyDataPic" />
      </span>
      <!--筛选弹出框-->
      <div v-show="menuBox" @mouseleave="mouseleavesEnterBox">
        <el-menu id="menu" mode="vertical" @select="handleSelectIndex">
          <el-menu-item index="0" :class="activeColor==0?'activeSelect':''" class="menuItem menuItemList">
            <span slot="title">{{$t('nameSorting.Sort_by_name')}}</span>
          </el-menu-item>
          <el-menu-item index="1" :class="activeColor==1?'activeSelect':''" class="menuItem menuItemList">
            <span slot="title">{{$t('nameSorting.Chronological_order')}}</span>
          </el-menu-item>
        </el-menu>
      </div>
    </div>

    <!--筛选条件-->
    <span class="searchBtnTab">
      <div style="width: 100%;height: 100%">
        <metadate :dataList.sync="dataList"></metadate>

      </div>
    </span>

    <!--添加报告 自动分析-->

    <div class="add_report">
      <DiagnosisReport :patientId="patientId"> </DiagnosisReport>
    </div>

    <button :class="additionalDisabled==0?'additional':'additionalDisabled'" @click="additionalBtnSValBtn">
      {{$t('searchLink.Additional_analysis')}}
    </button>

  </div>
</template>
 
 
<script>
import "../../assets/scss/searchLink.scss";
import metadata from "./metadata";
import metadate from "./metadate";
import DiagnosisReport from "./diagnosisReport";
export default {
  name: "searchLink",
  props: ["dataList", 'patientId'],
  components: {
    metadata,
    DiagnosisReport,
    metadate
  },
  data() {
    return {
      moreImg: require('@/assets/images/analysissystem/icon_omit_small.png'),
      searchVal: '',
      isAddReport: true,
      checkedCitiesAll: this.$store.state.glassList,
      radioValue: this.$store.state.status,
      karyotypeNumberlist: {
        artNumMap: {
          45: 0,
          46: 0,
          47: 0
        }
      },
      moreImgBtnShow: this.$store.state.glassList.length > 3 ? true : false,
      menuBox: false,
      studyDataPic: require('@/assets/images/analysissystem/icon_Descendingorder.png'),
      // titleName: this.$t('nameSorting.time'),
      activeColor: 1,
      // sorName: this.$t('nameSorting.Descending_order'),
      sort: this.$store.state.sort,//排序筛选
      additionalDisabled:0
    }
  },
  created() {
  },
  computed: {
    titleName() {
      if (this.$store.state.sortType == 0) {
        return this.$t('nameSorting.name_of_the');
      } else {
        return this.$t('nameSorting.time');
      }
    },
    sorName() {
      if (this.$store.state.sort == 0) {
        return this.$t('nameSorting.ascending');
      } else {
        return this.$t('nameSorting.Descending_order');
      }
    }
  },
  methods: {
    handleSelectIndex(index) {
      if (index == 0) {
        this.activeColor = 0;
        this.studyDataPic = require('@/assets/images/analysissystem/icon_Ascendingorder.png');
        this.$store.commit('sortType', index);
        this.$store.commit('sort', 0);
      } else {
        this.activeColor = 1;
        this.studyDataPic = require('@/assets/images/analysissystem/icon_Descendingorder.png');
        this.$store.commit('sortType', index);
        this.$store.commit('sort', 1);
      }
      this.menuBox = false;
    },
    //监听搜索 防抖
    inputFunc() {
      this.clearTimer();
      this.$store.commit("isSearchUpdateType", true);
      if (this.searchVal && this.searchVal.length > 0) {
        this.lengths = true;
        this.timer = setTimeout(() => {
          this.dataList.searchValue = this.searchVal;
        }, 500);
      } else {
        if (this.lengths) {
          this.dataList.searchValue = this.searchVal;
        }
        if (this.searchVal === "") {
          this.lengths = false;
          return;
        }
      }
    },
    //input 搜索防抖
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
    },
    //追加分析
    additionalBtnSValBtn() {
      if(this.$store.state.beingAnalyzed == 0){
          this.$emit('update:additionalBtnSVal', false);
      };

      // let param = {
      //     patientId: this.dataList.id
      // };
      // this.$api.karyoAutoSegmen(param).then((res) => {
      //      if(res.code == 200){
      //          if(res.data){
      //              this.$emit('update:additionalBtnSVal', false);
      //          }else{
      //              this.$confirm("核型图质量过低，不适合继续分析，是否继续？", this.$t("popup.add_analysis.title"), {
      //                  confirmButtonText: this.$t("btns.confirm"),
      //                  cancelButtonText: this.$t("btns.cancel"),
      //                  type: "warning",
      //              }).then(() => {
      //                  this.$emit('update:additionalBtnSVal', false);
      //              }).catch(() => {
      //                  this.$message.success(res.message);
      //              });
      //          }
      //          //
      //      }else{
      //          this.$message.error(res.message);
      //      }
      // })

    },
    //右侧弹框移出事件
    mouseleavesEnterBox() {
      this.menuBox = false //让菜单隐藏
    },
    //鼠标右键核型图
    timeName() {
      let menu = document.querySelector('#menu');
      menu.style.display = "block";
      menu.style.left = event.clientX - 50 + 'px';
      menu.style.top = event.clientY + 15 + 'px';
      this.menuBox = true;
    },
    //排序筛选
    sortingModeBtn() {
      console.log("有没有改变");
      if (this.sort == 0) {
        this.sort = 1;
        this.$store.commit('sort', 1);
        this.studyDataPic = require('@/assets/images/analysissystem/icon_Descendingorder.png');
        this.sorName = this.$t('nameSorting.Descending_order');
      } else {
        this.sort = 0;
        this.$store.commit('sort', 0);
        this.studyDataPic = require('@/assets/images/analysissystem/icon_Ascendingorder.png');
        this.sorName = this.$t('nameSorting.ascending');
      }
    }
  },

  watch: {
    //监听玻片数 上面的内容回显
    "$store.state.karyotypeNumberlist": function () {
      this.karyotypeNumberlist = this.$store.state.karyotypeNumberlist;
    },
    '$store.state.status': function () {
      this.radioValue = this.$store.state.status;
    },

    "$store.state.glassList": function () {
      this.checkedCitiesAll = this.$store.state.glassList;
      this.moreImgBtnShow = this.$store.state.glassList.length > 3 ? true : false
    },

    //监听玻片有没有正在分析中的
    '$store.state.beingAnalyzed':function (val) {
        if(val == 0){
            this.additionalDisabled = 0
        }
        if(val == 1){
            this.additionalDisabled = 1
        }
    },
  }
}
</script>
 
<style scoped>
</style>
