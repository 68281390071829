/*
 * @Author: your name
 * @Date: 2021-01-04 14:12:56
 * @LastEditTime: 2021-03-12 14:48:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \autovision-vue\src\store\modules\rect\state.js
 */
export default {
    rects: [],
    snapshotData: [], // 编辑器快照数据
    snapshotIndex: -1, // 快照索引
};