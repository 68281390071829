import {
    isSameCondition
} from "./shortKey";
import Graphs from './graphs.js'
//画布最小缩放比
const MIN_SCALE = 1;
//画布最大缩放比
const MAX_SCALE = 4;
//与按键相关的事件名称集合
const EVENTS_WITH_BUTTON = [
    'mousedown', 'mousemove', 'mouseup', 'click', 'dblclick', 'dragStart', 'dragMove', 'dragEnd', 'dragCustomStart',
    'dragCustomMove', 'dragCustomEnd'
]
//与按键不相关的事件名称集合
const EVENTS_WITHOUT_BUTTON = [
    'mouseenter', 'mouseout', 'contextmenu', 'mousewheel'
]
class Stage {
    static MIN_SCALE = MIN_SCALE;
    static MAX_SCALE = MAX_SCALE;
    constructor(config) {
        this.element = typeof config.element === 'string' ? document.querySelector(config.element) : config.element;
        this.width = config.width || config.offset.width || 1600;
        this.height = config.height || config.offset.height || 1200;
        this.offset = config.offset;
        this.realWidth = this.offset.realWidth;
        this.realHeight = this.offset.realHeight;
        this.canvas = this.element.getContext('2d');
        this.MIN_SCALE = MIN_SCALE;
        this.MAX_SCALE = MAX_SCALE;
        this.STATUS = {
            custom: 'custom',
            normal: 'normal'
        }
        this.CUSTOMTYPE = {
            line: 'line',
            polygon: 'polygon'
        }
        this.status = this.STATUS.normal;
        this.customType = this.CUSTOMTYPE.line;
        this.SWITCH = {
            stageDragEnable: true,
            stageScaleEnable: true,
            shapeDragEnable: true,
            shapeScaleEnable: true
        }
        this.event = {};
        this.eventsHandle = {};
        this.objects = [];
        this.graphs = new Graphs(this);
        this.init();
    }

    /**
     * 初始化
     */
    init() {
        this.reset();
        this.addEventsListener();
        this.addCondition();
    }

    /**
     * 重置
     */
    reset() {
        this.objects = [];
        this.transX = 0;
        this.transY = 0;
        this.scale = MIN_SCALE;
        this.setCanvasSize(MIN_SCALE);
    }

    /**
     * 设置画布尺寸
     */
    setCanvasSize(scale) {
        const offset = this.offset;
        // console.log(offset);
        this.width = offset.width || 1600;
        this.height = offset.height || 1200;
        this.element.width = offset.width;
        this.element.height = offset.height;
        this.element.style.width = this.realWidth + "px";
        this.element.style.height = this.realHeight + "px";
        this.canvas.scale(scale, scale);
        this.scale = scale;
        // this.transX = 0;
        // this.transY = 0;
        this.draw();
    }

    /**
     * 设置缩放比例 以(x,y)为中心点进行缩放
     * @param {Number} scale
     * @param {Number} x
     * @param {Number} y
     */
    setScale(scale, x = 0, y = 0) {
        scale = Math.min(MAX_SCALE, Math.max(MIN_SCALE, scale));
        // if (this.scale === scale) {
        // 	return;
        // }
        const tx = this.transX;
        const ty = this.transY;
        const mx = x * this.offset.zoom / scale - x * this.offset.zoom / this.scale;
        const my = y * this.offset.zoom / scale - y * this.offset.zoom / this.scale;
        this.setCanvasSize(scale);
        const max_x = 0;
        const min_x = (1 / scale - 1) * this.offset.width;
        const move_x = tx + mx;

        const max_y = 0;
        const min_y = (1 / scale - 1) * this.offset.height;
        const move_y = ty + my;

        // console.log(mx, my, move_x, move_y);
        this.transX = Math.min(max_x, Math.max(min_x, move_x));
        this.transY = Math.min(max_y, Math.max(min_y, move_y));
        this.draw();
    }

    /**
     * 根据中心点来缩放画布
     * 放大画布到最大比例,并将x,y坐标定位到视图窗口正中间的位置
     * @param {*} x 
     * @param {*} y 
     */
    scaleStageByCenterPoint(x, y) {
        const width = this.offset.width;
        const height = this.offset.height;
        const scale = this.MAX_SCALE;
        this.setCanvasSize(scale);
        const cx = width / 2;
        const cy = height / 2;
        const tx = (1 / scale - 1) * cx;
        const ty = (1 / scale - 1) * cy;
        const dx = (x - cx);
        const dy = (y - cy);

        const mx = tx - dx - this.offset.TRANS_X;
        const my = ty - dy - this.offset.TRANS_Y;

        const min_x = (1 / this.MAX_SCALE - 1) * width;
        const min_y = (1 / this.MAX_SCALE - 1) * height;

        this.transX = Math.min(0, Math.max(min_x, mx));
        this.transY = Math.min(0, Math.max(min_y, my));
        this.draw();
    }
    /**
     * 将图形绘制舞台上
     */
    draw() {
		typeof this.beforeDraw === 'function' && this.beforeDraw();
        this.canvas.clearRect(0, 0, this.offset.width / this.scale, this.offset.height / this.scale);
        this.canvas.fillStyle = "#ffffff";
        this.canvas.fillRect(0, 0, this.offset.width / this.scale, this.offset.height / this.scale)
        this.objects.sort((a, b) => {
            return (a ? a.zindex : 0) - (b ? b.zindex : 0);
        }).forEach(item => {
            item && item.draw();
        })
		this.beforeDraw = null;
    }

    /**
     * 添加图形到舞台
     * @param {Object} shape
     */
    addChild(shape) {
        const shapes = Array.isArray(shape) ? shape : [shape];
        this.objects = this.objects.concat(shapes);
    }

    /**
     * 从舞台上移除图形
     * @param {Object} shape
     */
    removeChild(shape) {
        const shapes = Array.isArray(shape) ? shape : [shape];
        shapes.forEach(item => {
            const index = this.objects.indexOf(item);
            if (index > -1) {
                this.objects.splice(index, 1)
            }
        })
    }

    /**
     * 清空所有图形
     */
    clearAllChild() {
        this.objects = [];
    }

    /**
     * 将事件对象中的offsetX offsetY坐标转换成舞台上对应的坐标位置
     * @param {Object} e
     */
    getPos(e) {
        return {
            x: e.offsetX * this.offset.zoom / this.scale - this.transX,
            y: e.offsetY * this.offset.zoom / this.scale - this.transY,
        }
    }

    getPosOnPrimaryImage(e) {
        return {
            x: e.offsetX * this.offset.zoom / this.scale - this.transX - this.offset.TRANS_X,
            y: e.offsetY * this.offset.zoom / this.scale - this.transY - this.offset.TRANS_Y,
        }
    }

    /**
     * 获取鼠标在整个舞台上对应的坐标点
     * @param {Object} e
     */
    getPosOnFullScreen(e) {
        return {
            x: e.offsetX * this.offset.zoom / this.scale,
            y: e.offsetY * this.offset.zoom / this.scale,
        }
    }

    /**
     * 根据鼠标事件对象中的位置信息筛选选中的图形
     * @param {Object} e
     */
    isPointInner(e, isAll, type = 'polygon') {
        const pos = this.getPos(e);
        const types = ["arrow"];
        types.push(type);
        const filter = this.objects.filter(item => {
            return item.type === type && item.isPointInner(pos.x, pos.y) && !item.hide;
            // return types.indexOf(item.type) > -1 && item.isPointInner(pos.x, pos.y);
        });
        return isAll ? filter : filter.sort((a, b) => {
            return a.zindex - b.zindex
        }).pop() || null;
    }

    /**
     * 移动舞台
     * @param {Object} x
     * @param {Object} y
     */
    translateStage(x, y) {
        // console.log(x, y);
        const max_x = 0;
        const min_x = (1 / this.scale - 1) * this.offset.width;
        const max_y = 0;
        const min_y = (1 / this.scale - 1) * this.offset.height;
        this.transX = Math.min(max_x, Math.max(min_x, x));
        this.transY = Math.min(max_y, Math.max(min_y, y));
        // console.log('translateStage',x,min_x,this.transX);
    }

    /**
     * 为画布添加事件处理
     */
    addEventsListener() {
        let currentTarget = null;
        let isLeftMousedown = false,
            isRightMousedown = false;
        let mousedownTimer = null;
        let customPoints = [];
        let customPos = [];
        let customPosReal = [];
        let mousedownTime = +new Date();
        let clickTimer = null;
        let prevTarget = null;
        let buttons = 0;
        let mousemoveTimer;
        let mousedownPos;
        const resetData = () => {
            this.isMousedown = false;
            this.isMousemove = false;
            isLeftMousedown = false;
            isRightMousedown = false;
            mousedownTimer = null;
            mousemoveTimer = null;
            mousedownPos = {};
            customPoints = [];
            customPos = [];
            customPosReal = [];
            clearTimeout(clickTimer);
            clickTimer = null;
            prevTarget = null;
            buttons = 0;
        }
        resetData();
        this.element.addEventListener('mousedown', (e) => {
            mousedownPos = {
                x: e.offsetX,
                y: e.offsetY
            }
            e.stopPropagation();
            const isNormal = this.status === this.STATUS.normal;
            const isCustom = this.status === this.STATUS.custom;
            this.isMousedown = true;
            this.isMousemove = false;
            buttons = e.buttons;
            clearTimeout(clickTimer);
            clickTimer = null;
            mousedownTime = +new Date();
            currentTarget = this.isPointInner(e, false);
            isLeftMousedown = e.button === 0;
            isRightMousedown = e.button === 2;
            mousedownTimer = setTimeout(() => {
                this.triggerCondition('mousedown', {
                    e,
                    shape: currentTarget
                });
            }, 300)
            if (isNormal) {
                customPoints.push({
                    x: e.offsetX,
                    y: e.offsetY
                });
                customPos.push(this.getPos(e))
                customPosReal.push(this.getPosOnPrimaryImage(e));
                this.triggerCondition('dragStart', {
                    e,
                    shape: currentTarget,
                    points: customPoints,
                    posList: customPos,
                    posReal: customPosReal
                });
                if (currentTarget) {
                    this.triggerCondition('mouseout', {
                        e,
                        shape: currentTarget
                    })
                }
            }
            if (isCustom) {
                customPoints.push({
                    x: e.offsetX,
                    y: e.offsetY
                });
                customPos.push(this.getPos(e))
                customPosReal.push(this.getPosOnPrimaryImage(e));
                this.triggerCondition('dragCustomStart', {
                    e,
                    shape: currentTarget,
                    points: customPoints,
                    posList: customPos,
                    posReal: customPosReal
                });
            }
        })
        this.element.addEventListener('mousemove', (e) => {
            // console.log('mousemove');
            // e.stopPropagation();
            const isNormal = this.status === this.STATUS.normal;
            const isCustom = this.status === this.STATUS.custom;
            this.isMousemove = this.isMousedown && e.buttons === buttons;
            if (this.isMousedown) {
                clearTimeout(mousedownTimer)
            }
            if (isNormal && !this.isMousedown) {
                clearTimeout(mousemoveTimer)
                const shape = this.isPointInner(e, false);
                mousemoveTimer = setTimeout(() => {
                    this.triggerCondition('mousemove', {
                        e,
                        shape: shape
                    });
                }, 50)
                if (!prevTarget && shape) {
                    prevTarget = shape;
                    this.triggerCondition('mouseenter', {
                        e,
                        shape: shape
                    })
                }
                if (prevTarget && !shape) {
                    this.triggerCondition('mouseout', {
                        e,
                        shape: prevTarget
                    })
                    prevTarget = null;
                }
                if (prevTarget && shape && prevTarget !== shape) {
                    this.triggerCondition('mouseout', {
                        e,
                        shape: prevTarget
                    });
                    this.triggerCondition('mouseenter', {
                        e,
                        shape: shape
                    })
                    prevTarget = shape;
                }
            }
            if (isNormal && this.isMousedown) {
                customPoints.push({
                    x: e.offsetX,
                    y: e.offsetY
                });
                customPos.push(this.getPos(e))
                customPosReal.push(this.getPosOnPrimaryImage(e));
                this.triggerCondition('dragMove', {
                    e,
                    shape: currentTarget,
                    points: customPoints,
                    posList: customPos,
                    posReal: customPosReal
                });
            }
            if (isCustom && this.isMousedown) {
                customPoints.push({
                    x: e.offsetX,
                    y: e.offsetY
                });
                customPos.push(this.getPos(e));
                customPosReal.push(this.getPosOnPrimaryImage(e));
                let points = [],
                    poslist = [];
                if (this.customType === this.CUSTOMTYPE.line) {
                    const endpoint = customPoints.pop();
                    points = [customPoints[0], endpoint];
                    const _endpoint = customPos.pop();
                    poslist = [customPos[0], _endpoint]
                } else {
                    points = customPoints;
                    poslist = customPos;
                }
                this.triggerCondition('dragCustomMove', {
                    e,
                    shape: currentTarget,
                    points: points,
                    posList: poslist,
                    posReal: customPosReal
                });
            }
        })
        this.element.addEventListener('mouseup', (e) => {
            // console.log(e.offsetX, e.offsetY)
            e.stopPropagation();
            const isNormal = this.status === this.STATUS.normal;
            const isCustom = this.status === this.STATUS.custom;
            const isMousemove = mousedownPos.x !== e.offsetX || mousedownPos.y !== e.offsetY
            if (isNormal && this.isMousedown && this.isMousemove) {
                this.triggerCondition('dragEnd', {
                    e,
                    shape: currentTarget,
                    points: customPoints,
                    posList: customPos,
                    posReal: customPosReal
                });
            }
            if (isCustom && this.isMousedown && this.isMousemove) {
                this.triggerCondition('dragCustomEnd', {
                    e,
                    shape: currentTarget,
                    points: customPoints,
                    posList: customPos,
                    posReal: customPosReal
                });
            }
            this.triggerCondition('mouseup', {
                e
            });
            // console.log(mousedownPos.x === e.offsetX && mousedownPos.y === e.offsetY, !this.isMousemove, e.button === 0);
            if (!isMousemove) {
                this.triggerCondition('click', {
                    e,
                    shape: currentTarget
                });
            }
            this.isMousedown = false;
            resetData();
        })
        this.element.addEventListener('click', (e) => {
            e.preventDefault();
        })
        this.element.addEventListener('dblclick', (e) => {
            e.preventDefault();
            clearTimeout(clickTimer)
            clickTimer = null;
            if (!this.isMousemove) {
                this.triggerCondition('dblclick', {
                    e,
                    shape: currentTarget
                });
            }
            resetData();
        })
        this.element.addEventListener('contextmenu', (e) => {
            e.preventDefault();
            const shape = this.isPointInner(e, false);
            this.triggerCondition('contextmenu', {
                e,
                shape: shape
            });
            resetData();
        })
        this.element.addEventListener('mouseenter', (e) => {
            e.stopPropagation();
        })
        this.element.addEventListener('mouseout', (e) => {
            e.stopPropagation();
            if (this.isMousedown) {
                this.triggerCondition('dragCustomEnd', {
                    e,
                    shape: currentTarget,
                    points: customPoints,
                    posList: customPos,
                    posReal: customPosReal
                });
                this.triggerCondition('dragEnd', {
                    e,
                    shape: currentTarget,
                    points: customPoints,
                    posList: customPos,
                    posReal: customPosReal
                });
            } else if (prevTarget) {
                this.triggerCondition('mouseout', {
                    e,
                    shape: prevTarget
                });
            }
            resetData('mouseout');
        })

        this.element.addEventListener("wheel", (e) => {
            if (e.ctrlKey) {
                e.preventDefault();
            }
            this.triggerCondition("mousewheel", {
                e
            });
            this.draw();
        }, {
            passive: false
        })

        window.addEventListener('mousewheel', (e) => {
            if (e.ctrlKey) {
                e.preventDefault();
            }
        }, {
            passive: false
        })

        window.addEventListener('DOMMouseScroll', (e) => {
            if (e.ctrlKey) {
                e.preventDefault();
            }
        }, {
            passive: false
        })
    }

    /**
     * 物理碰撞检测
     * @return {Array} 图形集合
     */
    collisionDetection(shape, types = ['rectangle', 'polygon']) {
        if (Object.prototype.toString.call(shape) === '[object Object]') {
            const typeArr = Array.isArray(types) ? types : (typeof types === 'string' ? types : ['rectangle', 'polygon']);
            return this.objects.filter(item => {
                if (item.id === shape.id || typeArr.indexOf(item.type) < 0) {
                    return false;
                }
                if (typeArr.indexOf(item.type) >= 0) {
                    const bool1 = item.matrix.some(pos => {
                        return shape.isPointInner(pos[0], pos[1]);
                    })
                    const bool2 = shape.matrix && shape.matrix.some(pos => {
                        return item.isPointInner(pos[0], pos[1]);
                    })
                    return bool1 || bool2
                }
            })
        }
    }

    /**
     * 判断 点集合组成的线条经过的图形列表
     * @return {Array} 图形集合
     */
    getObjectsByPointList(pointList, types = ['rectangle', 'polygon']) {
        if (!Array.isArray(pointList) || pointList.length < 2 || !pointList[0]) {
            return false;
        }
        const typeArr = Array.isArray(types) ? types : (typeof types === 'string' ? types : ['rectangle', 'polygon']);
        const shapeArr = [];
        const objects = this.objects.filter(a => typeArr.indexOf(a.type) >= 0);
        if (pointList.length < 5) {
            const points = [];
            for (let i = 0; i < pointList - 1; i++) {
                points.push(...this.splitStraightLine(pointList[i], pointList[i + 1]))
            }
            pointList.push(...points);
        }
        const last = pointList.pop();
        const endpoint = pointList.slice(-1)[0];
        const spoints = this.splitStraightLine(endpoint, last);
        pointList.push(...spoints)
        // console.warn(pointList)
        // pointList.push(...this.splitStraightLine(pointList.slice(-1)[0],pointList[0]))
        pointList.forEach(point => {
            objects.forEach(obj => {
                if (shapeArr.indexOf(obj) < 0 && obj.isPointInner(point.x, point.y)) {
                    shapeArr.push(obj);
                }
            })
        })
        return shapeArr;
    }

    /**
     * 获取在某个区域对象内的特定图形集合
     * @param {*} areaShape 
     * @param {*} type 
     * @returns 
     */
    getObjectsInsideShape(areaShape, type = 'polygon'){
        let arr = [];
        let objects = this.objects.filter(a=>a.type===type)
        const TRANS_X = this.offset.TRANS_X;
        const TRANS_Y = this.offset.TRANS_Y;
        objects.forEach(item=>{
            for (let i = 0; i < item.matrix.length; i++) {
                const [x,y] = item.matrix[i];
                if(areaShape.isPointInner && areaShape.isPointInner(x+TRANS_X, y+TRANS_Y)){
                    arr.push(item);
                    break;
                }
            }
        })
        return arr;
    }

    /**
     * 将一条直线拆分成若干个直线
     * @param {*} point1 
     * @param {*} point2 
     */
    splitStraightLine(point1, point2) {
        const points = [];

        const absX = parseInt(Math.abs(point2.x - point1.x));
        const absY = parseInt(Math.abs(point2.y - point1.y));
        const gcd = (a, b) => {
            let temp;
            while (b != 0) {
                temp = a % b;
                a = b;
                b = temp;
            }
            return a;
        }
        const scm = (a, b) => {
            return (a * b) / gcd(a, b);
        }
        const count = scm(absX, absY);
        const step_x = (point2.x - point1.x) / count;
        const step_y = (point2.y - point1.y) / count;
        for (let i = 1; i < count; i += 5) {
            points.push({
                x: point1.x + i * step_x,
                y: point1.y + i * step_y
            })
        }
        return points;
    }

    /**
     * 添加事件触发条件
     */
    addCondition() {
        const cond = {
            altKey: false,
            ctrlKey: false,
            shiftKey: false
        }
        EVENTS_WITH_BUTTON.forEach(type => {
            this.eventsHandle[type] = [];
            this.event[type] = (condition, handle) => {
                const _condition = typeof condition === 'object' ? condition : cond;
                handle = typeof handle === 'function' ? handle : (condition || new Function())
                this.eventsHandle[type].push({
                    condition: Object.assign({
                        button: 0
                    }, cond, _condition),
                    handle: handle
                })
            }
        })
        EVENTS_WITHOUT_BUTTON.forEach(type => {
            this.eventsHandle[type] = [];
            this.event[type] = (condition, handle) => {
                const _condition = typeof condition === 'object' ? condition : cond;
                handle = typeof handle === 'function' ? handle : (condition || new Function())
                this.eventsHandle[type].push({
                    condition: Object.assign({}, cond, _condition),
                    handle: handle
                })
            }
        })
    }

    /**
     * 事件触发时检测是否符合前置条件
     * @param {Object} type
     * @param {Object} e
     */
    triggerCondition(type, arg) {
        const e = arg.e;
        const conditionBase = {
            altKey: e.altKey,
            ctrlKey: e.ctrlKey,
            shiftKey: e.shiftKey
        }
        const button = e.buttons === 1 ? 0 : (e.buttons === 2 ? 2 : (e.buttons || e.button))
        const condition = Object.assign({
            button: button
        }, conditionBase);
        const args = Array.prototype.slice.call(arguments);
        args.splice(0, 1);
        this.eventsHandle[type].forEach(item => {
            const _condition = {
                button: item.condition.button,
                altKey: item.condition.altKey,
                ctrlKey: item.condition.ctrlKey,
                shiftKey: item.condition.shiftKey
            }
            const status = item.condition.getStatus ? item.condition.getStatus(e) : true;
            if (EVENTS_WITH_BUTTON.indexOf(type) >= 0 && isSameCondition(condition, _condition) && status) {
                item.handle.apply(this.event, args);
            }
            if (EVENTS_WITHOUT_BUTTON.indexOf(type) >= 0 && isSameCondition(conditionBase, _condition) && status) {
                item.handle.apply(this.event, args);
            }
        })
    }

}

export default Stage