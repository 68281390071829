<template>
  <header class="av-header">
    <div class="av-header-wrap" :style="{ width: width }">
      <div class="logo">
        <img src="../../assets/images/common/commonLogo.svg" @click="goIndex" v-show="langUsing == '中文'" />
        <img src="../../assets/images/index/logo_normal.png" @click="goIndex" v-show="langUsing == 'English'" />
      </div>
      <div class="av-header-slot">
        <slot></slot>
      </div>
      <div class="av-nav-links">
        <div v-for="(item, index) in links" :key="index" @click="analysissystem">
          <router-link :to="item.url" v-if="item.url !== pagePath">
            <div class="backAnalysissystem">
              <img src="../../assets/images/common/iconExit.svg" alt="" />
              {{ item.name }}
            </div>
          </router-link>
        </div>
      </div>
      <!-- 个人空间 -->
      <el-button v-if="!mechanismLen && (userType&1) !=1 &&  pagePath === '/analysissystem' && $store.state.currentTabIndex===0" class="open-account" @click="openAccount"><img src="../../assets/images/common/add_icon.png" alt="" />{{$t('uploadPdf.add_organ')}}</el-button>
      <div class="tab-change" v-if="(mechanismLen || (userType&1) ==1) && pagePath === '/analysissystem' && $store.state.currentTabIndex===0">
        <span @click="showChoose($event)">{{labValue.mechanismName}}<img src="../../assets/images/common/icon_bottom.png" alt="" /></span>
        <ul class="change-ul" v-show="openChoose">
          <li v-for="(item, index) in labOptions" :key="index" :title="item.mechanismName" @click="changeLabVal(item)" :class="{'choosed': item.choosed}">{{item.mechanismName}}</li>
          <li class="last-li" :class="{'english': $store.state.language == 'en'}" @click="openAccount()"><img src="../../assets/images/common/add_icon.png" alt="" />{{$t('uploadPdf.add_organ')}}</li>
        </ul>
      </div>

      <!-- 消息通知 -->
      <div class="tab-change mesg" v-show="$store.state.currentTabIndex===0">
        <span @click="showMsgChoose($event)"><i class="dot" v-if="messageList.length"></i><img src="../../assets/images/common/Noticnormal.png" alt="" />{{ $t("checkhtml.inform") }}</span>
        <ul class="change-ul" v-show="openMsg">
          <li v-for="(item, index) in messageList" :key="index" :title="item.name" @click="changeMsgVal(item)" :class="{'choosed': item.choosed}">
            <a class="wrds" :href="msgUrl">{{item.name}}</a>
            <span class="num">{{item.count}}</span>
          </li>
        </ul>
      </div>

      <div @click="fullScreen" v-if="pagePath === '/analysissystem'" :class="[isFullscreen?'fullscreenNext':'fullscreen']">
        <img src="../../assets/images/autovision/fullScreen.svg" v-show="!isFullscreen" alt="" /><img src="../../assets/images/autovision/fullscreenNext.svg" v-show="isFullscreen" alt="" />{{ isFullscreen ? $t("quit_screen") : $t("full_screen") }}
      </div>

      <!-- 中英文切换 -->
      <div class="language-Choose" v-show="pagePath === '/analysissystem' && $store.state.currentTabIndex===0">
        <img src="../../assets/images/common/language.svg" class="img_language">
        <el-select v-model="langUsing" @change="changeLang" @visible-change="showHide()">
          <el-option v-for="item in languages" :key="item.value" :label="item.label" :value="item.value">
            <img style="vertical-align: middle; margin: 0 8px 0 0;" :src="item.imgSrc" />{{ item.label }}
          </el-option>
        </el-select>
      </div>

      <div class="rechargeMoney" @click.stop="rechargeMoney" v-show="pagePath === '/analysissystem' && paymentType == 0">
        <img src="../../assets/images/autovision/rechargeMoney.svg" />
        <span>{{ $t("checkhtml.top_up") }}</span>
      </div>
      <div class="av-header-item">
        <div class="av-user" :class="{ open: userMenuOpened }" @click.stop="userClick">
          <div class="av-user-icon" ref="userName">
            <img :src="headUrl" alt="headImg" v-if="!!headUrl" />
          </div>
          <!-- <div class="av-user-name" ref="userName">
            {{ userName }}
          </div> -->
          <!-- <i></i> -->
          <ul class="av-user-menu">
            <li v-if="(userType&4) == 4 && $store.state.caseValue.patientType == 0">
              <a class="icon-system" :href="systemUrl" @click="adminSystem()">{{$t('uploadPdf.system_backstage')}}</a>
            </li>
            <li>
              <a class="icon-set" @click="personalSettings">{{$t('uploadPdf.account_setup')}}</a>
            </li>
            <li>
              <a class="icon-database" :href="systemUrl" @click="normalSystem()">{{$t("account_num.data_manage")}}</a>
            </li>
            <li>
              <a class="icon-quit" @click="quitLogin">{{$t("account_num.exit_system")}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// import { FLAG_UNRESTRICTED } from 'html2canvas/dist/types/css/syntax/tokenizer';
import Common from "../../assets/js/common";
export default {
  name: "AvHeader",
  props: {
    width: {
      type: String,
      value: "100%",
    },
  },
  data() {
    return {
      pagePath: this.$route.path,
      userName: this.$store.state.userName,
      userMenuOpened: false,
      isFullscreen: this.$store.state.fullScreen,
      MaintenanceNotifier: false,
      content: "",
      langUsing: "",
      // languages: [
      //   {
      //     imgSrc: require("@/assets/images/common/CHINA.svg"),
      //     value: "zh",
      //     label: this.$t('uploadPdf.Chinese'),
      //     choosed: false
      //   },
      //   {
      //     imgSrc: require("@/assets/images/common/USA.svg"),
      //     value: "en",
      //     label: "English",
      //     choosed: false
      //   },
      // ],
      labValue: "", //实验室切换值
      labOptions: [], //个人空间、专家空间、...机构实验室列表
      headUrl: "", //用户头像
      normalUrl: require("@/assets/images/common/icon-avatar.svg"),
      userType: "", //用户类型（0 个人用户 1 专家 2 机构 4 管理员，多重角色支持累加）
      systemUrl: "", //后台跳转链接
      openChoose: false,
      normalHeader: require("@/assets/images/common/head-photo.png"),
      mechanismLen: 0,
      openMsg: false,
      messageList: [],
      msgUrl: "",
      paymentType: 0,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    document.addEventListener("click", () => {
      this.userMenuOpened = false;
      this.openChoose = false;
      this.openMsg = false;
    });
  },
  computed: {
    links() {
      return [
        {
          url: "/analysissystem",
          name: this.$t("backto_case_overview"),
        },
      ];
    },
    languages() {
      return [
        {
          imgSrc: require("@/assets/images/common/CHINA.svg"),
          value: "zh",
          label: this.$t('uploadPdf.Chinese'),
          choosed: false
        },
        {
          imgSrc: require("@/assets/images/common/USA.svg"),
          value: "en",
          label: "English",
          choosed: false
        },
      ];
    },
  },
  methods: {
    async init() {
      this.userName = JSON.parse(localStorage.getItem("loginObj")).userName;
      let loginObj = JSON.parse(localStorage.getItem("loginObj"));
      this.userType = loginObj.userType;
      this.queryMsgApiNew();
      this.getLabOptions();
      let currentLabValue = localStorage.getItem('currentLabValue');
      this.labValue = !!currentLabValue && currentLabValue != "undefined" ? JSON.parse(currentLabValue) : this.labOptions[0];
      this.$store.commit("changeLabValue", this.labValue);
      this.$store.commit("changeAccountType", this.labValue.accountType);
      localStorage.setItem('currentLabValue', JSON.stringify(this.labValue));
      await this.updatePowerMap();

      let lang = localStorage.getItem("language") || (navigator.language || navigator.userLanguage).substr(0, 2);
      if (lang == "en") {
        this.langUsing = "English";
      } else {
        this.langUsing = this.$t('uploadPdf.Chinese');
      }
      localStorage.setItem("language", lang);
      this.$store.commit('changeLanguange', lang)
      this.$store.state.headUrl = loginObj.headUrl;
      this.discountSummary();

      if (this.labValue.mechanismName == '个人空间' || this.labValue.mechanismName == 'Personal space') {
        this.labValue.mechanismName = this.$t('uploadPdf.personal_space');
      } else if (this.labValue.mechanismName == '专家空间' || this.labValue.mechanismName == 'Expert space') {
        this.labValue.mechanismName = this.$t('uploadPdf.expert_space');
      }
      this.isPrepaid();
    },
    //判断是预付费还是后付费
    isPrepaid() {
      this.$api.queryByToken({}).then((res) => {
        if (res.code == 200) {
          const { billTypes, company, discount } = JSON.parse(res.data);
          this.paymentType = discount.paymentType;
          console.log(this.paymentType, '=======')
        } else {
          this.$message.error(res.message);
        }
      });
    },


    getLabOptions() {
      /*
        逻辑说明：userType用户类型（0 个人用户 1 专家 2 机构 4 管理员，多重角色支持累加）
        每个用户都拥有个人中心，专家拥有专家空间。该用户拥有专家角色时，那他同时拥有个人空间与专家空间
        当拥有机构角色时，则将登录接口返回的机构信息与处理好的数组进行合并
      */
      let loginObj = JSON.parse(localStorage.getItem("loginObj"));
      if ((loginObj.userType & 1) == 1) {
        this.labOptions = [{
          mechanismId: loginObj.userId,
          mechanismName: this.$t('uploadPdf.personal_space'),
          accountType: "1",
          isExpert: false,
          choosed: false
        }, {
          mechanismId: loginObj.userId,
          mechanismName: this.$t('uploadPdf.expert_space'),
          accountType: "1",
          isExpert: true,
          choosed: false
        }];
      } else {
        this.labOptions = [{
          mechanismId: loginObj.userId,
          mechanismName: this.$t('uploadPdf.personal_space'),
          accountType: "1",
          isExpert: false,
          choosed: false
        }];
      }
      if (!!loginObj.mechanismList && loginObj.mechanismList.length) {
        loginObj.mechanismList.forEach(item => {
          item.accountType = "2";
          item.isExpert = false;
          item.choosed = false;
        });
        this.labOptions = this.labOptions.concat(loginObj.mechanismList);//列表中添加机构
        this.mechanismLen = loginObj.mechanismList;
      }

    },
    showChoose(e) {
      e.stopPropagation();
      this.openChoose = !this.openChoose;
      this.userMenuOpened = false;
      this.openMsg = false;
    },
    showMsgChoose(e) {
      e.stopPropagation();
      this.openMsg = !this.openMsg;
      this.userMenuOpened = false;
      this.openChoose = false;
    },
    openAccount() {
      clearInterval(window.timeCountDown);
      this.$store.commit("fastPay", 0);
      if (this.$store.state.labValue.accountType == 1 && !Common.permissions("/user/queryUserByToken")) {
        this.$message.error(this.$t('uploadPdf.no_right_check'))
        return
      } else if (this.$store.state.labValue.accountType == 2 && !Common.permissions("/user/mechanismQuery")) {
        this.$message.error(this.$t('uploadPdf.no_right_check'))
        return
      }
      this.$router.push({
        name: "个人设置页",
        params: { tabType: "organ_validation" },
      });
    },

    rechargeMoney() {
      this.$router.push({
        name: "个人设置页",
        params: { tabType: "personal_account" },
      });
      this.$store.commit("fastPay", 1);
    },
    adminSystem() {
      clearInterval(window.timeCountDown);
      this.$store.commit("fastPay", 0);
      let loginObj = JSON.parse(localStorage.getItem("loginObj"));
      let obj = {
        mechanismId: loginObj.userId,
        accountType: "3", // 1个人，专家   2机构  3管理员
      };
      localStorage.setItem("systemValue", JSON.stringify(obj));
      this.systemUrl = "/dist/#/home";
    },
    normalSystem() {
      clearInterval(window.timeCountDown);
      this.$store.commit("fastPay", 0);
      let labValue = JSON.parse(localStorage.getItem("currentLabValue"));
      let obj = {
        mechanismId: labValue.mechanismId,
        accountType: labValue.accountType, // 1个人，专家   2机构  3管理员
      };
      localStorage.setItem("systemValue", JSON.stringify(obj));
      this.systemUrl = "/dist/#/home";
    },
    changeMsgVal(item) {

      let obj = {
        mechanismId: item.accountId,//账户id    跳转到后台使用
        accountType: item.accountType,//账户类型      跳转到后台使用
        isRead: 0
      };
      localStorage.setItem("systemValue", JSON.stringify(obj));
      this.msgUrl = "/dist/#/registeredMail";
    },
    discountSummary() {
      this.$api.discountSummary({}).then((res) => {
        if (res.code === "200") {
          this.messageList = res.data;
        } else {
          this.MaintenanceNotifier = false;
        }
      });
    },
    queryMsgApiNew() {
      this.$api.queryMsg({}).then((res) => {
        if (res.code === "200") {
          const date = res.data;
          if (date) {
            this.content = date.content;

            this.MaintenanceNotifier = true;
          } else {
            this.MaintenanceNotifier = false;
          }
        } else {
          this.MaintenanceNotifier = false;
        }
      });
    },

    userClick() {
      this.userMenuOpened = !this.userMenuOpened;
      this.openChoose = false;
      this.openMsg = false;
    },
    goIndex() {
      if (this.pagePath !== "/analysissystem") {
        clearInterval(window.timeCountDown);
        this.$store.commit("fastPay", 0);
        this.$router.push({
          path: "/analysissystem",
        });
      }
    },

    analysissystem() {
      clearInterval(window.timeCountDown);
      this.$store.commit("fastPay", 0);
      this.$store.commit("isUpdateSettingType", false);
    },

    // 进入个人设置
    personalSettings() {
      clearInterval(window.timeCountDown);
      this.$store.commit("fastPay", 0);
      if (this.$store.state.labValue.accountType == 1 && !Common.permissions("/user/queryUserByToken")) {
        this.$message.error(this.$t('uploadPdf.no_right_check'))
        return
      } else if (this.$store.state.labValue.accountType == 2 && !Common.permissions("/user/mechanismQuery")) {
        this.$message.error(this.$t('uploadPdf.no_right_check'))
        return
      }
      this.$store.commit("isUpdateSettingType", true);
      this.$store.commit("metatypeValue", 1);
      this.$store.commit("metatype", "personal_info");
      this.$router.push({
        path: "/personalSettings",
      });
    },

    //退出登录
    async quitLogin() {
      clearInterval(window.timeCountDown);
      this.$store.commit("fastPay", 0);
      await this.$api.loginOut({}).then((res) => {
        if (res.code == 200) {
          // localStorage.clear();
          // sessionStorage.clear();
          // window.location.href = window.location.href.split("#")[0];
          // this.$router.replace("/");
        }
      });
      localStorage.clear();
      sessionStorage.clear();
      // this.$router.replace("/");
      window.location.href = window.location.href.split("#")[0];
    },

    fullScreen() {
      if (this.$store.state.fullScreen) {
        this.$store.commit("quitFullScreen");
      } else {
        this.$store.commit("openFullScreen");
      }
    },

    addEvents() {
      // document.addEventListener("click",()=>{
      //   this.userMenuOpened = false;
      // })
    },
    showHide() {
      this.openMsg = false;
      this.openChoose = false;
      this.userMenuOpened = false;
    },
    changeLang(val) {
      // 通过切换locale的值来实现语言切换
      this.$i18n.locale = val;
      localStorage.setItem("language", val);
      this.$store.commit('changeLanguange', val)
      this.$store.commit("changeLanguange", val);
      this.$api.queryList({ type: 1 }).then((res) => {
        if (res.code == 200) {
          let pointList = res.data.pointList;
          localStorage.setItem("pointList", JSON.stringify(pointList));
          this.$api.queryList({ type: 0 }).then((res) => {
            let powerList = res.data.powerList;
            localStorage.setItem("powerList", JSON.stringify(powerList));
          });
        }
      });
    },
    async updatePowerMap() {
      return this.$api.queryList({ type: 1 }).then(res => {
        if (res.code == 200) {
          let pointList = res.data.pointList;
          let powerMap = res.data.powerMap;
          localStorage.setItem("pointList", JSON.stringify(pointList));
          localStorage.setItem("powerMap", JSON.stringify(powerMap));
        }
      })
    },
    async changeLabVal(val) {
      clearInterval(window.timeCountDown);
      this.$store.commit("fastPay", 0);
      this.openChoose = false;
      this.openMsg = false;
      localStorage.setItem("currentLabValue", JSON.stringify(val))
      await this.updatePowerMap();
      let obj = {
        mechanismId: val.mechanismId,
        mechanismName: val.mechanismName,
        accountType: val.accountType,
        patientType: 0
      }
      if (val.isExpert) {
        obj.patientType = 1;
      } else if (val.accountType == 2) {
        obj.patientType = 2;
      }
      this.$store.commit("changeLabValue", val);
      this.$store.commit("changeAccountType", val.accountType);
      this.isPrepaid();
    },
  },
  watch: {
    "$store.state.labValueUpdatedTime": function () {
      this.init();
    },
    "$store.state.ids": function () {
      this.userName = JSON.parse(localStorage.getItem("loginObj")).userName;
    },

    "$store.state.fullScreen": function () {
      this.isFullscreen = this.$store.state.fullScreen;
    },

    "$store.state.userName": function () {
      this.userName = JSON.parse(localStorage.getItem("loginObj")).userName;
    },
    "$i18n.locale": function () {
      let lang = localStorage.getItem("language") || (navigator.language || navigator.userLanguage).substr(0, 2);
      if (lang === "en") {
        this.langUsing = "English";
      } else {
        this.langUsing = this.$t('uploadPdf.Chinese');
      }
      localStorage.setItem("language", lang);
      this.$store.commit('changeLanguange', lang);
      this.getLabOptions();

      if (this.labValue.mechanismName == '个人空间' || this.labValue.mechanismName == 'Personal space') {
        this.labValue.mechanismName = this.$t('uploadPdf.personal_space');
      } else if (this.labValue.mechanismName == '专家空间' || this.labValue.mechanismName == 'Expert space') {
        this.labValue.mechanismName = this.$t('uploadPdf.expert_space');
      }
    },
    "$store.state.headUrl": function (val) {
      this.headUrl = val ? this.ossUrlWebFir(val.split('?')[0], 1, 30, 30) : this.normalHeader;
    },
    "$store.state.ossUploadAccess": function () {
      this.headUrl = this.$store.state.headUrl ? this.ossUrlWebFir(this.$store.state.headUrl.split('?')[0], 1, 30, 30) : this.normalHeader;
    },
    "$store.state.labValue": function (val) {
      this.labOptions.forEach(item => {
        if (val.mechanismId === item.mechanismId && item.accountType == '2') {
          item.mechanismName = val.mechanismName;
        }
        if (val.mechanismId === item.mechanismId && val.isExpert === item.isExpert) {
          item.choosed = true;
        } else {
          item.choosed = false;
        }
      })
      this.labValue = val;
    },
  },
};
</script>

<style lang="scss">
$link-color: "#333333";

.av-header {
  height: 60px;
  line-height: 60px;
  background-color: #ffffff;
  padding-left: calc(100vw - 100%);
  .av-header-wrap {
    margin: 0 auto;
    height: 100%;
    display: flex;
    padding: 0 20px 0 30px;
    box-sizing: border-box;
  }

  .logo {
    width: 135px;
    overflow: hidden;
    padding: 6px 0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    img {
      display: block;
      cursor: pointer;
      width: 135px;
    }
  }

  .sologan {
    margin-left: -25px;
    font-size: 18px;
    // font-family: PingFangSC-Medium, PingFang SC;

    color: #4773b8;
    line-height: 80px;
    margin-right: 5px;
  }
  .lab-select {
    width: 150px;
    height: 40px;
    margin-right: 8px;
    margin-top: 10px;
    .el-input {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .el-input__inner {
      height: 40px;
      border: 0;
      text-align: center;
      color: #4773b8;
      font-size: 14px;
      //padding: 0 10px;
    }
    .el-select__caret {
      color: #4773b8;
    }
    .el-input__icon {
      height: unset;
    }
  }
  .el-button {
    &.open-account {
      width: auto;
      height: 34px;
      padding: 0 10px !important;
      font-size: 16px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #4773b8;
      color: #4773b8;
      margin-top: 13px;
      &:hover {
        background: #f0f7ff;
      }
      span {
        line-height: 32px;
      }
      img {
        vertical-align: top;
        margin: 8px 5px 0 0;
      }
    }
  }
}
.lab-select {
  .el-select-dropdown__item {
    font-size: 14px;
  }
  .el-select-dropdown__item.selected {
    font-size: 14px;
  }
  .el-input__prefix,
  .el-input__suffix {
    display: flex;
    align-items: center;
  }
}
.el-popper[x-placement^="bottom"] .popper__arrow {
  left: 50% !important;
}
.av-header-slot {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
}

.av-nav-links {
  overflow: hidden;

  .backAnalysissystem {
    display: flex;

    img {
      padding-right: 7px;
    }
  }

  a {
    text-decoration: none;
    color: #333333 !important;
    font-size: 16px;

    &:visited {
      color: #333333 !important;
    }

    &:hover {
      color: #000000 !important;
    }

    &:active {
      text-decoration: underline;
    }
  }

  .icon-exit {
    padding-left: 23px;
    background: url(../../assets/images/common/icon-exit.png) no-repeat left
      center;
  }
}
.el_button_title_icon {
  display: inline-block;
  height: 16px;
  width: 16px;
  background: #ffffff;
  vertical-align: text-top;
  margin-right: 12px;
}
.el_button_title_msg {
  font-size: 16px;
  vertical-align: bottom;
  display: inline-block;
  height: 18px;
  // width: 35px;
  line-height: 18px;
}
.el_button_title {
  width: unset !important;
  height: 30px !important;
  margin: 14px 0 0 20px !important;
  line-height: 30px;
}
.el_button_title span:nth-child(1) {
  width: 100%;
  height: 18px;
  display: block;
}
.el_button_title i {
  color: #ff9e1f;
  font-size: 18px;
}
.el_button_title_item {
  border: none !important;
}
.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fullscreen {
  // background: url(../../assets/images/autovision/fullScreen.svg) no-repeat left
  //   center;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  margin-top: 15px;
  cursor: pointer;
  margin-left: 6px;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  img {
    vertical-align: top;
    margin: 8px 6px 0 0;
    transition: 0.1s;
  }
}
.el-select {
  min-width: 50px !important;
}
.fullscreenNext {
  // background: url(../../assets/images/autovision/fullscreenNext.svg) no-repeat
  //   left center;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  margin-top: 15px;
  margin-left: 20px;
  cursor: pointer;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  img {
    vertical-align: top;
    margin: 8px 6px 0 0;
    transition: 0.1s;
  }
}
.rechargeMoney {
  position: relative;
  margin-top: 19px;
  cursor: pointer;
  margin-left: 20px;
}
.rechargeMoney img {
  height: 16px;
  width: 16px;
  display: inline-block;
  float: left;
  margin-top: 3px;
}
.rechargeMoney span {
  width: 60px;
  height: 20px;
  display: inline-block;
  float: left;
  line-height: normal;
  margin-left: 5px;
}
.rechargeMoney span:hover {
  color: #4773b8;
}
.language-Choose {
  //display: flex;
  //position: relative;
  //margin-left: 10px;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  margin-top: 15px;
  cursor: pointer;
  margin-left: 6px;
  .img_language {
    height: 16px;
    width: 16px;
    margin: 7px;
    float: left;
    display: inline-block;
  }
  .el-select {
    // display: flex;
    //width: 100px;
    align-items: center;
    // background: url(../../assets/images/common/language.svg) 14px center no-repeat;
    background-size: 18px;
    // text-indent: 38px;
    font-size: 16px;
    color: #000;
    width: 55px;
  }
  .el-input--suffix .el-input__inner {
    height: 30px;
    line-height: 30px;
    border: 0;
    padding: 0;
    font-size: 16px;
    color: #000;
  }
  .el-input__suffix-inner {
    display: none;
    color: #000;
    font-size: 16px;
  }
}
.tab-change {
  position: relative;
  margin-top: 13px;
  span {
    display: block;
    width: auto;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    color: #4773b8;
    padding: 0 14px;
    border-radius: 4px;
    border: 1px solid #4773b8;
    cursor: pointer;
    &:hover {
      background: #f0f7ff;
    }
    img {
      vertical-align: top;
      margin: 9px 0 0 6px;
    }
  }
  .change-ul {
    position: absolute;
    min-width: 178px;
    width: 100%;
    top: 45px;
    left: 0;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 30%);
    border-radius: 4px;
    transition: 0.1s;
    z-index: 99;
    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 27%;
      width: 8px;
      height: 8px;
      background-color: #ffffff;
      box-shadow: -2px -2px 6px 0px rgb(0 0 0 / 10%);
      transform: rotate(45deg);
      top: -4px;
    }
    li {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      cursor: pointer;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.choosed {
        color: #409eff;
      }
      &:hover {
        background: #f5f7fa;
      }
    }
    .last-li {
      display: flex;
      // justify-content: center;
      border-top: 1px solid #e6e6e6;
      &.english {
        font-size: 12px;
      }
      img {
        width: 16px;
        height: 16px;
        margin-top: 12px;
        margin-right: 6px;
      }
    }
  }
}
.tab-change.mesg {
  span {
    position: relative;
    border: none;
    color: #333;
    .dot {
      position: absolute;
      top: 6px;
      left: 14px;
      width: 6px;
      height: 6px;
      background: red;
      border-radius: 6px;
    }
    &:hover {
      background: none;
      color: #4773b8;
    }
    img {
      margin: 7px 7px 0px 6px;
    }
  }
  .change-ul {
    width: 304px;
    left: -82px;
    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 45%;
      width: 8px;
      height: 8px;
      background-color: #ffffff;
      box-shadow: -2px -2px 6px 0px rgb(0 0 0 / 10%);
      transform: rotate(45deg);
      top: -4px;
    }
    li {
      position: relative;
      background: url("../../assets/images/common/yellow-inform.png") 12px
        center no-repeat;
      padding: 0 45px 0 20px;
      .wrds {
        display: block;
        text-indent: 30px;
        cursor: pointer;
        text-decoration: none;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover,
        &:active,
        &:visited {
          color: #333;
        }
      }
      &:hover {
        background: #f5f7fa url("../../assets/images/common/yellow-inform.png")
          12px center no-repeat;
      }
      &:first-child {
        background: url("../../assets/images/common/org-inform.png") 12px center
          no-repeat;
        &:hover {
          background: #f5f7fa url("../../assets/images/common/org-inform.png")
            12px center no-repeat;
        }
      }
      .num {
        position: absolute;
        top: 12px;
        right: 16px;
        min-width: 16px;
        height: 18px;
        line-height: 18px;
        padding: 0 7px;
        text-align: center;
        font-size: 14px;
        border-radius: 9px;
        background: #000000;
        color: #fff;
        opacity: 0.5;
      }
    }
  }
}
.av-user {
  height: 35px;
  line-height: 30px;
  margin-top: 14px;
  margin-left: 10px;
  // padding-right: 20px;
  position: relative;
  display: flex;

  .av-user-icon {
    width: 35px;
    height: 35px;
    // background: url(../../assets/images/common/icon-avatar.svg) no-repeat left
    //   center;
    cursor: pointer;
    border-radius: 35px;
    overflow: hidden;
    transition: 0.1s;
    &:hover {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .av-user-name {
    margin-left: 12px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  > i {
    display: block;
    position: absolute;
    width: 14px;
    height: 10px;
    right: 0;
    top: 50%;
    transition: all ease-in-out 0.3s;
    transform: translateY(-50%) rotate(0deg);
    background: url(../../assets/images/common/icon-arrow-down.svg) no-repeat;
    cursor: pointer;
  }
  .av-user-menu {
    // padding: 0 20px 10px;
    // width: 100%;
    // min-width: 150px;
    margin: 0;
    list-style: none;
    position: absolute;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    // min-width: 100%;
    right: -8px;
    top: 110%;
    margin-top: 6px;
    text-align: left;
    // z-index: -1;
    // opacity: 0;
    display: none;
    // transform: scale(0.5);
    // transition: all ease-in-out 0.3s;
    &:before {
      content: "";
      position: absolute;
      display: block;
      right: 20px;
      width: 8px;
      height: 8px;
      background-color: #ffffff;
      box-shadow: -2px -2px 6px 0px rgba(0, 0, 0, 0.1);
      transform: rotate(45deg);
      top: -4px;
    }

    a {
      text-decoration: none;
      color: #333333 !important;
      font-size: 16px;
      white-space: nowrap;
      &:visited {
        color: #333333 !important;
      }

      &:hover {
        color: #000000 !important;
      }

      &:active {
        text-decoration: underline;
      }
    }

    li {
      // overflow: hidden;
      // margin-top: 10px;
      padding: 8px 20px;
      &:first-child {
        margin-top: 5px;
      }
      &:hover {
        background-color: #e2edffff;
      }
      a {
        padding-left: 26px;
        display: block;
        line-height: 24px;
        // width: 65px;
        cursor: pointer;
        background-position: left center;
        background-repeat: no-repeat;
      }
      .icon-database {
        background-image: url(../../assets/images/common/icon-database.svg);
      }

      .icon-set {
        background-image: url(../../assets/images/common/icon-set.svg);
      }
      .icon-system {
        background-image: url(../../assets/images/common/icon_xitong_normal.png);
      }
      .icon-quit {
        background-image: url(../../assets/images/common/icon-quit.svg);
      }
    }
  }

  &.open {
    > i {
      transform: translateY(-50%) rotate(180deg);
    }

    .av-user-menu {
      z-index: 10;
      // opacity: 1;
      display: block;
      // transform: scale(1);
    }
  }
}
</style>
