import cvCommon from './opencv-common.js'
import { cloneDeep } from 'lodash';
import shortKey from '../../../../assets/utils/shortKey.js'
/**
 * 涂抹添加
 * @param {Object} stage
 * @param {Object} controller
 */
function paint(stage, controller) {
	const app = this;
    let lineShape;
    let realLinePos = [];
    let originPictureJS = this.isShowPrimaryImage ? controller.optBackPicJS : controller.optPicJS
    const arrange = this.arrangeController;
    
	let isDragMove = false;
    const condition = {
        // 涂抹状态或者万能工具下按住shift进行涂抹
        getStatus: () => {
            return this.toolsType === 'paint' || isDragMove;
        }
    }
    const condition1 = {
        shiftKey : true,
        getStatus : ()=>{
            return this.toolsType === 'general' || this.toolsType === 'count'
        }
    }

    const drawCircle = (stage,x,y)=>{
		const canvas = stage.canvas;
		// const radius = this.brushThickness / 2 * stage.offset.zoom / stage.scale;
		const radius = this.brushThickness / 2 * stage.offset.zoom;
		canvas.save();
		canvas.translate(stage.transX,stage.transY);
		canvas.beginPath();
		canvas.arc(x, y, radius, 0, 2* Math.PI);
		canvas.fillStyle = 'rgb(255,255,255,.6)';
		canvas.fill();
		canvas.lineWidth = 1 * stage.offset.zoom / stage.scale;
		canvas.strokeStyle = "#4773B8";
		canvas.stroke();
		canvas.closePath();
		canvas.restore();
	}

    /**
     * 涂抹过程中划线
     * @param {涂抹经过的画布上的点坐标} posList 
     */
    const drawMove = (posList)=>{
        isDragMove = true;
        let matrix = posList.map((pos) => {
            return [pos.x, pos.y];
        })
        if (lineShape) {
            lineShape.matrix = matrix;
        } else {
            lineShape = stage.graphs.line({
                x: 0,
                y: 0,
                color: "rgba(255, 0, 0, 0.5)",
                borderColor: 'f00',
                // lineWidth: this.brushThickness,
                lineWidth: this.brushThickness * stage.offset.zoom,
                hold: false,
                zindex: 999,
                matrix: matrix,
                lineCap: "round",
                lineJoin: "round"
            })
            stage.addChild(lineShape);
        }
        stage.draw();
    }
    // move过程划线
    stage.event.dragMove(condition, ({ posList, posReal}) => {
        drawMove(posList)
        // let po = posReal[posReal.length -1]
        // initRealLinePos(po)
    })
    stage.event.dragMove(condition1, ({ posList, posReal }) => {
        drawMove(posList)
        // let po = posReal[posReal.length -1]
        // initRealLinePos(po)
    })

    const initRealLinePos = (point) => {
        if (!realLinePos) {
            realLinePos = []
        }
        for (let xIndex = -2; xIndex < 3; xIndex++) {
            for (let yIndex = -2; yIndex < 3; yIndex++) {
                let po = {x: point.x + xIndex, y: point.y + yIndex}
                if (po.x < 0 || po.y < 0 || po.x > stage.width || po.y > stage.height) {
                    continue;
                }
                let imageData = stage.canvas.getImageData(po.x, po.y, 1, 1).data;
                // console.log(imageData)
                if (imageData[0] < 250 || imageData[1] < 250 || imageData[2] < 250) {
                    realLinePos.push(po)
                    // console.log(realLinePos.length)
                }
            }
        }
    }
    
    let brushShapeK;
    let prevTargetChromo = null;
    /**
     * 涂抹结束，执行生成染色体操作
     * @param {涂抹经过的画布上的点坐标} posList 
     */
    const dragEnd = (posList,e,posReal) =>{
        isDragMove = false;
        originPictureJS = this.isShowPrimaryImage ? controller.optBackPicJS : controller.optPicJS
        // 删除线条图形
        stage.removeChild(lineShape);
        lineShape = null;       
        stage.draw();

        if (posList.length < 5) {
            return;
        }
        const shapeArr = stage.getObjectsByPointList(posList, 'polygon').filter(obj => { return obj.realType === 'chromo' });
        let targetChromos = []
        if (shapeArr && shapeArr.length > 0) {
            shapeArr.forEach(shape => {targetChromos.push(controller.getChromoByShape(shape))})
        }
        if(prevTargetChromo === targetChromos[0]){
            return;
        }
        prevTargetChromo = targetChromos[0];
        // TODO 算法传空涂抹不出来
        if(targetChromos.length == 0) {
            targetChromos.push(controller.chromoList[0])
        }
        doPaintChromo(targetChromos, posList,e,posReal)
        prevTargetChromo = null;
        realLinePos = null
        stage.draw();
    }
    
    // 涂抹结束
    stage.event.dragEnd(condition, ({ posList,e,posReal }) => {
        dragEnd(posList,e,posReal);
    })
    stage.event.dragEnd(condition1, ({ posList,e,posReal }) => {
        dragEnd(posList,e,posReal);
    })

    const createBrushShape = (pos)=>{
        if(stage.objects.includes(brushShapeK)){
            Object.assign(brushShapeK,{
                brushThickness : this.brushThickness,
                x : pos.x,
                y : pos.y
            })
        }else{
            brushShapeK = stage.graphs.brush({
                brushThickness : this.brushThickness,
                x : pos.x,
                y : pos.y,
                zindex: 1000
            })
            stage.addChild(brushShapeK);
        }
    }
    let isMouseOver = false, mousePos, movePos;
    // this.cachePool.afterReset = ()=>{
    //     if(isMouseOver){
    //         createBrushShape(mousePos);
    //         stage.draw();
    //     }
    // }
	stage.element.addEventListener("mouseout",(e)=>{
		if(this.toolsType === 'paint' || (e.shiftKey && (this.toolsType === 'general' || this.toolsType === 'count'))){
			stage.removeChild(brushShapeK);
			brushShapeK = null;
            stage.draw();
            isMouseOver = false
		}
	})
	stage.element.addEventListener("mousemove",(e)=>{
        movePos = stage.getPos(e);
		if(this.toolsType === 'paint' || (e.shiftKey && (this.toolsType === 'general' || this.toolsType === 'count'))){
            const pos = stage.getPos(e);
            mousePos = pos;
			createBrushShape(pos);
            stage.draw();
            isMouseOver = true
		}
	})
    shortKey.on("keyup",{
        key:'shiftleft'
    },()=>{
        this.shortKeyType = ''
        stage.removeChild(brushShapeK);
        stage.draw();
    })
    shortKey.on("keydown",{
        key:'shiftleft'
    },()=>{
        this.shortKeyType = 'shiftleft'
        createBrushShape(movePos)
        stage.draw();
    })



    /**
     * 生成涂抹后的染色体
     * @param {涂抹线经过的染色体列表} chromoList 
     * @param {涂抹线的点坐标} posList 
     */
    const doPaintChromo = (chromoList, posList, e,posReal) => {
        let maskJS, chromoRect;
        let chromoJsList = window.Module.getVectorJSchromo();
        const toolsType = this.toolsType;
        function checkData() {
            if (originPictureJS && maskJS && chromoJsList && chromoRect) {
                getSmearChromo(originPictureJS, maskJS, chromoJsList, chromoRect, chromoList).then((newChromos) => {
                    if (!newChromos || newChromos.length <= 0) {
                        console.warn("涂抹染色体生成失败")
                        return;
                    }
                    // 删除原有染色体
                    chromoList && chromoList.forEach(srcChromo => {
                        let desChromo = newChromos.filter(nc => {nc.index === srcChromo.index})
                        if (desChromo.length <= 0) {
                            let eChromoIndex = controller.chromoList.indexOf(srcChromo);
                            eChromoIndex >= 0 ? controller.chromoList.splice(eChromoIndex, 1) : "";
                        }
                    })
                    newChromos.forEach(ch => {
                        if (controller.chromoList.filter(srcChromo => {srcChromo.index === ch.index}).length <= 0) {controller.chromoList.push(ch);}
                    })
                    
                    controller.resetKaryo();
                    arrange.reArrange()
                    if(toolsType === 'paint'){
                        createBrushShape(stage.getPos(e));
                        stage.draw();
                    }
                    app.cachePool.save();
                    app.cachePool.record({
                        type : 'paint',
                        message : '涂抹',
                        data : chromoList,
                        result : newChromos
                    })
                });
            } else {
                console.warn("doPaintChromo checkData: require some data")
            }
        }
        checkData();

        // 原始画布大小
        if (!originPictureJS) {
            let url = this.isShowPrimaryImage ? this.karyoInfo.optBackUrl : this.karyoInfo.optUrl;
            cvCommon.getJSMatData(url).then(jsMat => {
                this.isShowPrimaryImage ? controller.optBackPicJS = jsMat : controller.optPicJS = jsMat;
                originPictureJS = jsMat;
                checkData()
            }).catch((err) => {
                console.error(err)
                return;
            });
        }

        // 涂抹起源染色体的位置信息
        if (chromoList && chromoList.length > 0) {
            chromoList.forEach(chrmo => {
                if (!chrmo.chromoDataJS) {
                    let startTime = new Date().getTime()
                    cvCommon.getJSDataPromiseAsync(chrmo).then(chromoDataJS => {
                        console.log("paint getJSDataPromiseAsync use time 2:", new Date().getTime() - startTime)
                        chrmo.chromoDataJS = chromoDataJS;
                        chromoJsList.push_back(chrmo.chromoDataJS)
                        if(chromoJsList.length >= chromoList.length) {
                            checkData()
                        }
                    }).catch((err) => {
                        console.error(err)
                        return;
                    });
                } else {
                    chromoJsList.push_back(chrmo.chromoDataJS)
                }
                if(chromoJsList.length >= chromoList.length) {
                    checkData()
                }
            })
        } else {
            chromoJsList = []
            checkData()
        }

        // 涂抹线的mask数据
        if (!maskJS) {
            getLineMaskJSData(posReal).then(([jsMat, jsRect]) => {
                maskJS = jsMat;
                chromoRect = jsRect;
                checkData()
            }).catch((err) => {
                console.error(err)
                return;
            });
        }
    }

    /**
     * 获取涂抹的mask jsdata
     * @param {手动涂抹经过的点坐标} paintMatrix 
     */
    const getLineMaskJSData = (paintMatrix) => {
        return new Promise((resolve, reject) => {
            let xlist = paintMatrix.map((item) => { return item.x });
            let ylist = paintMatrix.map((item) => { return item.y });
            let xsort = xlist.sort(function (a, b) {
                return a - b;
            });
            let ysort = ylist.sort(function (a, b) {
                return a - b;
            });
            let max_x = xsort[xlist.length - 1];
            let min_x = xsort[0];
            let max_y = ysort[ylist.length - 1];
            let min_y = ysort[0];
            let width = max_x - min_x + this.brushThickness * stage.offset.zoom;
            let height = max_y - min_y + this.brushThickness * stage.offset.zoom;
            // let jsRect = Module.getJSRect(ltPtX, ltPtY, width, height);
            let jsRect = { x: min_x -this.brushThickness/2 * stage.offset.zoom, y: min_y-this.brushThickness/2 * stage.offset.zoom, width: width, height: height }

            // 创建划线画布
            let _canvas = document.createElement('canvas');
            _canvas.width = width;
            _canvas.height = height;
            let _ctx = _canvas.getContext('2d');
            _ctx.lineCap = "round";
            _ctx.lineJoin = "round";
            _ctx.fillRect(0, 0, width, height);
            _ctx.lineWidth = this.brushThickness*stage.offset.zoom; //线条的粗细
            _ctx.beginPath();
            paintMatrix.forEach((point, i) => {
                i === 0 ? _ctx.moveTo(point.x - min_x + this.brushThickness/2 * stage.offset.zoom, point.y - min_y + this.brushThickness/2 * stage.offset.zoom)
                 : _ctx.lineTo(point.x - min_x + this.brushThickness/2 * stage.offset.zoom, point.y - min_y + this.brushThickness/2 * stage.offset.zoom);
            });
            _ctx.strokeStyle = "white";
            _ctx.stroke();
            
            let maskCanvas = cvCommon.drawMaskImg(_canvas)
            cvCommon.getJSMatData(maskCanvas).then(jsMat => {
                maskCanvas = null
                resolve([jsMat, jsRect])
            }).catch((err) => {
                console.error(err)
                reject(err)
            });
        })
    }

    function getPaintChromo(originPictureJS, maskJS, hitPosJS, jsRect, srcChromo) {
        return new Promise((resolve) => {
            try {
                let moduleAddAndLinkFun = window.Module.moduleAddAndLinkFunRect(originPictureJS, maskJS, hitPosJS, 1, jsRect);
                if (moduleAddAndLinkFun.result != -1) {
                    let chr = moduleAddAndLinkFun.chr
                    cvCommon.getPaintChromoData(chr).then((newChromo) => {
                        if (newChromo) {
                            newChromo.chromoId = 26;
                            newChromo.chromoDataJS = cvCommon._getChromoDataJS(chr, srcChromo)
                            resolve(newChromo);
                        }
                    })
                } else {
                    console.warn("getPaintChromo error:", moduleAddAndLinkFun)
                    resolve(false)
                }
            } catch (error) {
                console.error(error)
                resolve(false)
            }
        })
    }

    
    /****************************************************************************
    *函数功能描述 ：		                            涂抹生成新染色体
    *入参：
    *   JSMat src_img_js								原始优化图
    *   JSMat mask_js								    新涂抹染色体对应的mask
    *   JSRect rect
    *   int new_index								    新生成染色体对象的index
    *   vector<JSchromo> chroms_js                      与涂抹染色体具有交集的一系列染色体对象
    *函数返回值 ：										JSResult jsResult.result等于0成功，其他失败
    *****************************************************************************/
    // JSResult SmearFun(JSMat src_img_js, JSMat mask_js, JSRect rect, int new_index, vector<JSchromo> chroms_js); 
    
    function getSmearChromo(originPictureJS, maskJS, chromoJsList, jsRect, srcChromoList) {
        return new Promise((resolve) => {
            try {
                let maxIndex = Math.max.apply(Math, controller.chromoList.map(item => { return item.index })) + 1;
                let moduleAddAndLinkFun = window.Module.SmearFun(originPictureJS, maskJS, jsRect, maxIndex, chromoJsList);
                if (moduleAddAndLinkFun.result != -1) {
                    let chrArr = moduleAddAndLinkFun.chrArr
                    moduleAddAndLinkFun = null

                    const paintChromos = [];
                    for (let index = 0; index < chrArr.size(); index++) {
                        const chromoData = chrArr.get(index);
                        // 如果涂抹结果中包含输入的染色体，则不需要再次生成图片，加快速度
                        let hasContain = false;
                        srcChromoList && srcChromoList.forEach(srcChromo => {
                            if (srcChromo.index === chromoData.index) {
                                hasContain = true
                            }
                        })
                        // let newChromo = {}
                        // if (hasContain) {
                        //     newChromo.index = chromoData.index
                        // } else {
                            let newChromo = cloneDeep(cvCommon.getCuttingData(chromoData, srcChromoList[0]))
                            newChromo.chromoId = 26;
                            newChromo.chromoDataJS = cvCommon._getChromoDataJS(chromoData, srcChromoList[0])
                        // }
                        paintChromos.push(newChromo);
                    }
                    resolve(paintChromos)
                } else {
                    console.warn("getPaintChromo error:", moduleAddAndLinkFun)
                    resolve(false)
                }
            } catch (error) {
                console.error(error)
                resolve(false)
            }
        })
    }

}
    
export default paint