<template>
  <div class="progress-setup">
    <div class="pointer" @click="showSetup">
      <div class="progress">
        <div class="count-num">
          <span>{{$t('progressSetup.Already_count')}}</span><b :class="{green: karyoNum >= confmKaryoNum}">{{karyoNum}}</b><span>{{$t('progressSetup.Zhang_karyotype')}}</span>
          <!-- <span class="num">{{ karyoNum }}</span> -->
        </div>
        <div class="count-num">
          <span>{{$t('progressSetup.Has_been_arranged')}}</span><b :class="{green: arrayNum >= confmArrayNum}">{{arrayNum}}</b><span>{{$t('progressSetup.Zhang_karyotype')}}</span>
          <!-- 已排列<span class="num">{{ arrayNum }}</span>张核型 -->
        </div>
      </div>
      <div class="progress-line">
        <el-progress :percentage="perntage" status="success"></el-progress>
      </div>
    </div>
    <DialogPopup :visible="isShow" :title="$t('progressSetup.Analysis_of_setting')" @visibleChange="visibleChange" :closeFlag="false">
      <div slot="content" class="pop-cnt">
        <p class="intrd">
          {{$t('progressSetup.message')}}
        </p>
        <div class="setup">
          <label>{{$t('progressSetup.Count_the_number_of')}}</label>
          <el-input type="number" v-model="karyoSetNum" :placeholder="$t('progressSetup.Please_enter_the')"></el-input>
        </div>
        <div class="setup">
          <label>{{$t('progressSetup.Arrange_the_number')}}</label>
          <el-input type="number" v-model="arraySetNum" :placeholder="$t('progressSetup.Please_enter_the')"></el-input>
        </div>
        <div slot="footer" class="btns">
          <el-button class="cancel-btn" type="primary" @click="cancel">{{$t('progressSetup.cancel')}}</el-button>
          <el-button class="confirm-btn" type="primary" @click="confirm">{{$t('progressSetup.save')}}</el-button>
        </div>
      </div>
    </DialogPopup>
  </div>
</template>

<script>
import DialogPopup from "../../../selfDefineTemplate/components/dialogPopup";
export default {
  name: "progressSetup",
  created() {
    this.queryAnalysis();
  },
  components: {
    DialogPopup,
  },
  data() {
    return {
      karyoSetNum: 1,
      arraySetNum: 1,
      confmKaryoNum: 1,
      confmArrayNum: 1,
      isShow: false,
      chromoDefault: '',//染色体初始值大小
      chromoEnlarge: ''//染色体放大缩小值
    };
  },
  props: {
    karyoNum: Number,
    arrayNum: Number,
  },
  methods: {
    confirm() {
      this.$api.analysisSetUp({
        singleNum: this.karyoSetNum,
        typeNum: this.arraySetNum
      }).then(res => {
        if (res.code == 200) {
          let obj = {
            singleNum: this.karyoSetNum,
            typeNum: this.arraySetNum,
            chromoDefault: this.$store.state.analysisInfo.chromoDefault,
            chromoEnlarge: this.$store.state.analysisInfo.chromoEnlarge,
            isEnlarge: this.$store.state.analysisInfo.isEnlarge
          }
          this.$store.commit('analysisInfoStore', obj);
          this.$message.success(this.$t('progressSetup.Set_up_the_success'));
          this.isShow = false;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    cancel() {
      this.isShow = false;
      this.karyoSetNum = this.$store.state.analysisInfo.singleNum;
      this.arraySetNum = this.$store.state.analysisInfo.typeNum;
      this.confmKaryoNum = this.$store.state.analysisInfo.singleNum;
      this.confmArrayNum = this.$store.state.analysisInfo.typeNum;
    },
    visibleChange(val) {
      this.isShow = val;
    },
    showSetup() {
      this.isShow = true;
    },
    queryAnalysis() {
      this.$api.queryAnalysis({}).then(res => {
        if (res.code == 200) {
          this.$store.commit('analysisInfoStore', res.data);
        }
      })
    },
  },
  computed: {
    perntage() {
      let sumNum = parseInt(this.confmKaryoNum) + parseInt(4 * this.confmArrayNum),
        karyoPentag =
          parseInt(this.karyoNum) / parseInt(this.confmKaryoNum) > 1
            ? 1 : parseInt(this.karyoNum) / parseInt(this.confmKaryoNum),
        arrayPentag =
          parseInt(this.arrayNum) / parseInt(this.confmArrayNum) > 1
            ? 1 : parseInt(this.arrayNum) / parseInt(this.confmArrayNum),
        progressPentag =
          (karyoPentag * parseInt(this.confmKaryoNum)) / sumNum +
          (arrayPentag * parseInt(this.confmArrayNum) * 4) / sumNum;
      progressPentag = progressPentag > 1 ? 100 : progressPentag * 100;
      return progressPentag || 0;
    },
  },
  watch: {
    '$store.state.analysisInfo': function (newVal) {
      this.karyoSetNum = newVal.singleNum;
      this.arraySetNum = newVal.typeNum;
      this.confmKaryoNum = newVal.singleNum;
      this.confmArrayNum = newVal.typeNum;
    }
  }
};
</script>

<style lang="scss">
.progress-setup {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  width: fit-content;
  margin: 0 auto;
  .pointer {
    cursor: pointer;
  }
  .progress {
    display: flex;
    width: fit-content;
    margin: 0 auto;
    .count-num {
      display: flex;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      > * {
        display: block;
        height: 30px;
      }
      > b {
        width: 48px;
        line-height: 30px;
        text-align: center;
        background: linear-gradient(180deg, #ffffff 0%, #e3e5e8 100%);
        box-shadow: 0px -1px 0px 0px rgba(192, 192, 192, 0.5);
        border-radius: 4px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #f53500;
        margin: 0 6px;
        &.green {
          color: #1bc931;
        }
      }
      > span {
        line-height: 38px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .intrd {
    margin: 12px 0;
    font-size: 13px;
    text-align: center;
    line-height: 20px;
    padding: 0 20px;
  }
  .setup {
    width: fit-content;
    font-size: 15px;
    display: flex;
    margin: 0 auto 5px;
    label {
      width: fit-content;
      margin-right: 4px;
      line-height: 30px;
    }
    .el-input {
      width: 90px;
      height: 30px;
    }
    .el-input__inner {
      height: 30px;
      padding: 0 12px;
      box-sizing: border-box;
      vertical-align: top;
    }
  }
  .el-dialog__wrapper {
    display: flex;
    .el-dialog {
      width: 440px;
      background: #ffffff;
      box-shadow: 0px 3px 10px 0px rgb(113 113 113 / 20%);
      border-radius: 6px;
      margin: auto !important;
      .pop-cnt {
        overflow: hidden;
      }
      .el-dialog__body {
        padding: 0;
      }
      .btns {
        float: right;
        margin: 15px 30px;
      }
      .cancel-btn {
        width: 68px;
        height: 30px;
        background: #fff !important;
        color: #000;
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
        margin-right: 8px;
      }
      .confirm-btn {
        width: 68px;
        height: 30px;
        background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
        color: #fff;
        border: none;
      }
      .el-dialog__footer {
        background: none;
        border: none;
        padding: 0;
      }
    }
  }

  .el-dialog__header {
    height: 36px;
    padding: 0 16px !important;
    box-sizing: border-box;
  }
  .el-dialog__headerbtn {
    top: 20px;
  }
  .el-progress {
    z-index: 100;
  }
  .progress-line {
    width: 100%;
    margin: 0 auto;
  }
  .el-progress-bar {
    padding: 0;
  }
  .el-progress__text {
    display: none;
  }
}
</style>
