import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "./store";
import API from "./assets/js/api.js";
import "./assets/scss/base/base.scss";
import infiniteScroll from "vue-infinite-scroll";
import Print from "vue-print-nb";
import visibility from "vue-visibility-change";
// import vcolorpicker from "vcolorpicker";
import elTableInfiniteScroll from "el-table-infinite-scroll";

import RELEASE_ENV from "./assets/js/release.js";

import ossUrlWebFir from "./assets/utils/ossUrlWeb";

import ElementResizeDetectorMaker from "element-resize-detector";

import browserVersion from "./assets/utils/browserVersion";

import zh from "../src/locales/zh";
import en from "../src/locales/en";
import ElementLocale from "element-ui/lib/locale";
import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";

import VueI18n from "vue-i18n";

import audio from "vue-mobile-audio";

const BrowserLogger = require("alife-logger");

const environment = {
    dev: "local",
    test: "daily",
    pre: "pre",
    pro: "prod"
};
if (RELEASE_ENV !== "") {
    const __bl = BrowserLogger.singleton({
        pid: "dtb85t3tzr@c024d246f6bd9ad",
        imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
        enableSPA: true,
        behavior: true,
        environment: environment[RELEASE_ENV],
        enableLinkTrace: true
    });
    __bl.setConfig({
        ignore: {
            ignoreApis: ["/patient/heartbeat"]
        }
    });
    Vue.prototype.__bl = __bl;
}

// Vue.use(vcolorpicker); //颜色吸取

Vue.prototype.ossUrlWebFir = ossUrlWebFir;
Vue.prototype.browserVersion = browserVersion;
Vue.use(ElementUI, infiniteScroll);
Vue.use(Print);
Vue.use(visibility);
Vue.use(VueI18n);
Vue.use(elTableInfiniteScroll); //引入table无限滚动加载
Vue.use(audio);

Vue.prototype.$erd = ElementResizeDetectorMaker();
Vue.config.productionTip = false;

Vue.prototype.$api = API;

let lang = navigator.language || navigator.userLanguage;
lang = lang.substr(0, 2);

const i18n = new VueI18n({
    locale: localStorage.getItem("language") || lang, // 语言标识
    messages: {
        zh: {
            ...zh,
            ...zhLocale
        },
        en: {
            ...en,
            ...enLocale
        }
    },
    silentTranslationWarn: true
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

window.i18n = i18n;

new Vue({
    render: h => h(App),
    router,
    store,
    i18n
}).$mount("#app");
