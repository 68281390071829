import uploader from "../../../../assets/utils/fileUploader.js";
class CachePool{
    constructor({constructor, maxStep}) {
        //页面APP实例指向
        this.app = constructor || this;
        //最大缓存操作步数
        this.maxStep = maxStep || 22;
        //当前处于第几操作步数
        this.step = 0;
        //当前操作步数之前的缓存
        this.cache = [];
        //当前操作步数之后的缓存
        this.fetchCache = [];
        //撤销定时器
        this.undoTimer = null;
        //恢复定时器
        this.redoTimer = null;
        //操作名称集合  目前不涉及具体功能 
        //收集当前用户的所有操作 用来研究类似Photoshop的撤销与恢复
        this.operatingRecord = [];
        //base64图片的映射关系
        this.base64Map = {};
        this.initData = {};
    }
    
    /**
     * 重置缓存池
     */
    reset(){
        this.initData = {};
        this.step = 0;
        this.cache = [];
        this.fetchCache = [];
        this.base64Map = {};
        clearTimeout(this.undoTimer);
        clearTimeout(this.redoTimer);
        this.undoTimer = null;
        this.redoTimer = null;
    }
    
    /**
     * 具体操作记录
     * @param {Object} args
     */
    record(args){
        this.operatingRecord.push(JSON.stringify(args));
    }
    
    /**
     * 保存数据
     */
    save(obj = {}){
        const start = Math.max(0,this.cache.length - this.maxStep + 1)
        const arr = this.cache.slice(start, this.step);
        const data = this.getData();
        // data.optImage = this.app.karyoController.optImage;
        arr.push(Object.assign(data,obj));
        if(arr.length === 1){
            this.initData = data;
        }
        this.cache = arr;
        this.fetchCache = [];
        this.step = Math.min(this.step+1, this.maxStep);
        this.checkBase64(this.app.chromoList);
        this.app.version++;
    }
    
    /**
     * 获取页面当前数据转为存储格式的数据
     */
    getData(){
        const chromoList = this.app.chromoList.map(chromo=>{
            const item = JSON.parse(JSON.stringify(chromo));
            delete item.chromoDataJS;
            return item;
        });
        const karyoInfo = this.app.karyoInfo;
        const banding = this.app.arrangeController.banding || this.app.karyoInfo.banding;
        const offset = this.app.karyoController.offset;
        const countPoints = this.app.karyoController.countPoints.map(a=>{
            return {
                x : a.point.x - offset.TRANS_X,
                y : a.point.y - offset.TRANS_Y
            }
        });
        // const arrangeArrows = this.app.arrangeController.arrowList.map(a=>{
        //     return {
        //         beginPoint : a.beginPoint,
        //         stopPoint : a.stopPoint,
        //         text : a.text
        //     }
        // })
        const arrangeArrows = this.app.arrangeController.arrowList.map(a=>{
            const copy = JSON.parse(JSON.stringify(a));
            delete copy.guid;
            return copy;
        })
        const karyoArrows = this.app.karyoController.arrowList.map(a=>{
            return this.app.karyoController.arrowWrite(a);
        })
        const _chromoList = JSON.stringify(chromoList);
        const _karyoInfo = JSON.stringify(karyoInfo);
        const _arrangeArrows = JSON.stringify(arrangeArrows);
        const _karyoArrows = JSON.stringify(karyoArrows);
        const _countPoints = JSON.stringify(countPoints);
        const _banding = JSON.stringify(banding)
        return {
            chromoList : _chromoList, 
            karyoInfo : _karyoInfo, 
            arrangeArrows : _arrangeArrows, 
            karyoArrows : _karyoArrows,
            countPoints : _countPoints,
            banding : _banding
        }
    }
    
    /**
     * 判断当前页面的数据是否为页面刚进入时的数据
     */
    isInit(){
        const data = this.getData();
        console.warn("-----------")
        const isSameData = Object.keys(data).every(key=>{
            // console.warn(key,data[key].replace(/\s/g,"") === this.initData[key].replace(/\s/g,""));
            return data[key] && this.initData[key] && data[key].replace(/\s/g,"") === this.initData[key].replace(/\s/g,"");
            // 
        })
        const karyoInfo = this.initData.karyoInfo ? JSON.parse(this.initData.karyoInfo) : this.app.karyoInfo;
        const expression1 = this.app.karyoInfo.karyotypeExpression && this.app.karyoInfo.karyotypeExpression.replace(/\s/g,"");
        const expression2 = karyoInfo.karyotypeExpression && karyoInfo.karyotypeExpression.replace(/\s/g,"");
        const isSameExpression = (expression1 === expression2 || !expression2);
        const isSameRemark = this.app.remark === karyoInfo.remark;
        console.warn(isSameData,expression1,expression2,isSameExpression, isSameRemark);
        return isSameData && isSameExpression && isSameRemark;
    }
    
    /**
     * 读取数据
     */
    fetch(){
        const item = this.cache.slice(-1)[0];
        return {
            chromoList : item ? JSON.parse(item.chromoList) : [],
            countPoints : item ? JSON.parse(item.countPoints) : {}, 
            arrangeArrows : item ? JSON.parse(item.arrangeArrows) : [], 
            karyoArrows : item ? JSON.parse(item.karyoArrows) : [],
            karyoInfo : item ? JSON.parse(item.karyoInfo) : {},
            banding : item ? JSON.parse(item.banding) : {},
            optImage : item.optImage,
            optimizeConfig : item.optimizeConfig
        }
    }
    
    /**
     * 撤销功能
     * @param {Function} cb
     */
    undo(cb){
        clearTimeout(this.undoTimer)
        this.step--;
        this.undoTimer = setTimeout(()=>{
            const start = Math.max(this.step,1);
            const arr = this.cache.splice(start,this.cache.length);
            if(arr && arr.length){
                this.fetchCache = arr.concat(this.fetchCache);
                const item = this.fetch();
                typeof cb === 'function' && cb(item)
                typeof this.afterReset === "function" && this.afterReset();
                this.app.version++;
            }else{
                if(!this.warning){
                    this.warning = this.app.$message({
                        message : this.app.$t("popup.warning.cannot_undo"),
                        type : 'warning',
                        onClose : ()=>{
                            delete this.warning;
                        }
                    })
                }
                this.step = 1;
            }
        },200)
    }
    
    /**
     * 恢复功能
     * @param {Function} cb
     */
    redo(cb){
        clearTimeout(this.redoTimer)
        this.step++;
        if(this.fetchCache.length){
            this.redoTimer = setTimeout(()=>{
                const length = this.step - this.cache.length;
                const arr = this.fetchCache.splice(0,length);
                this.cache.push(...arr);
                const item = this.fetch();
                typeof cb === 'function' && cb(item)
                typeof this.afterReset === "function" && this.afterReset();
                this.app.version++;
            },200)
        }else{
            if(!this.warning){
                this.warning = this.app.$message({
                    message : this.app.$t("popup.warning.the_newest"),
                    type : 'warning',
                    onClose : ()=>{
                        delete this.warning;
                    }
                })
            }
            this.step = this.cache.length;
        }
    }

    /**
     * 获取唯一ID
     */
    getGuid(){
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    }
    /**
     * 检查染色体列表中的图片是否为base64
     * 如果为base64则上传至oss 将oss图片路径与base64对应起来
     * @param {Array} chromoList
     */
    checkBase64(chromoList, cb){
        // console.log(chromoList);
        chromoList.forEach(chromo=>{
            Object.keys(chromo).filter(key=>{
                return /Url$/g.test(key)
            }).forEach(key=>{
                const url = chromo[key];
                // console.log(key, url);
                if(url && url.indexOf(";base64,") > -1){
                    const isExist = Object.keys(this.base64Map).filter(key=>{
                        return this.base64Map[key].data === url && this.base64Map[key].status > 0;
                    }).length > 0;
                    // console.log(isExist);
                    if(!isExist){
                        const random = this.getGuid();
                        const format = url.split(";")[0].split("/")[1];
                        let name = [this.app.caseName, this.app.glassName, this.app.karyoInfo.cellName,chromo.chromoId,random].join("_");
                        // const file = this.blobToFile(this.dataURLtoBlob(url),name);
                        name = [name, format].join(".");
                        const file = this.dataURLtoBlob(url,name);
                        const path = this.app.karyoInfo.primaryUrl.split("?")[0].split("/");
                        path.splice(-2,2,'chromo','')
                        
                        this.base64Map[random] = {
                            data : url,
                            status : 0,
                            url : ''
                        }
                        
                        uploader({
                            files : [file],
                            path : path.splice(3).join("/"),
                            ossUploadAccess : this.app.$store.state.ossUploadAccess,
                            processHandle : ()=>{
                                this.base64Map[random].status = 1;
                            }
                        }).then(filelist=>{
                            const target = filelist[0];
                            if(target.status === 'success'){
                                this.base64Map[random].url = target.path;
                                this.base64Map[random].status = 2;
                            }else{
                                this.base64Map[random].status = 0;
                            }
                            typeof cb === 'function' && cb(target)
                        })
                    }
                }
            })
        })
    }
    
    /**
     * 将base64转换为blob
     * @param {String} dataurl
     */
    dataURLtoBlob(dataurl, filename){
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }
    
    /**
     * 将染色体列表复制一份
     * 然后将base64替换成http连接
     * 返回新的染色体列表
     * @param {Object} chromoList
     */
    replaceBase64(chromoList){
        chromoList = JSON.parse(JSON.stringify(chromoList))
        chromoList.forEach(chromo=>{
            Object.keys(chromo).filter(key=>{
                return /Url$/g.test(key)
            }).forEach(key=>{
                const url = chromo[key];
                if(url.indexOf(";base64,") > -1){
                    const keyName = Object.keys(this.base64Map).filter(key=>{
                        return this.base64Map[key].data === url && this.base64Map[key].status == 2;
                    })[0];
                    if(keyName){
                        chromo[key] = this.base64Map[keyName].url;
                    }
                }else{
                    chromo[key] = url.split("?")[0]
                }
            })
            delete chromo._cimgBoundaryPointsList;
            delete chromo._cimgBoundingbox;
            delete chromo.chromoDataJS;
        })
        return chromoList;
    }

    /**
     * 获取确认计数、确认排列、保存相关的参数
     */
    getAjaxParams(){
        const data = this.getData();
        const chromos = this.replaceBase64(this.app.chromoList);
        const arrows = JSON.parse(data.arrangeArrows);
        arrows.forEach(a=>{
            Object.keys(a).forEach(key=>{
                if(typeof a[key] === 'number'){
                    a[key] = Math.round(a[key])
                }
            })
        })
        const offset = this.app.karyoController.offset;
        return {
            chromoArrangeScale : this.app.arrangeController.chromoScale,
            remark : this.app.remark,
            patientId : this.app.caseId,
            karyoId : this.app.karyoId,
            chromos : JSON.stringify(chromos),
            chromoInReport : '',
            singleNum : this.app.karyoInfo.singleNum,
            arrangeArrowLines : JSON.stringify(arrows),
            originalArrowLines : data.karyoArrows,
            banding : data.banding,
            pointKaryo : JSON.stringify(this.app.karyoController.countPoints.map(a=>{
                return {
                    x : a.point.x - offset.TRANS_X,
                    y : a.point.y - offset.TRANS_Y
                }
            })),
            // pointKaryo: JSON.stringify(this.app.karyoController.countPoints.map(a=>a.point)),
            isPreserSure : Number(!!this.app.karyoInfo.isPreserSure),
            karyotypeExpression : this.app.karyoInfo.isPreserSure ? this.app.karyotypeExpression : this.app.karyoInfo.karyotypeExpression,
            whetherRepeatInspect : Number(this.app.whetherRepeatInspect) || 0,
            randomString : this.app.randomString || '',
            arrayUrl : '',
            isUpdate : Number(!this.isInit())
        }
    }

    /**
     * 箭头数据转换
     */
    transArrowData(arrowsJSON){
        const arrows = JSON.parse(arrowsJSON);
        const offset = this.app.karyoController.offset;
        const karyoArrows = arrows.map(a=>{
            return {
                start_x : this.roundNumber(a.beginPoint.x , offset.localWidth),
                start_y : this.roundNumber(a.beginPoint.y , offset.localHeight),
                target_x : this.roundNumber(a.stopPoint.x , offset.localWidth),
                target_y : this.roundNumber(a.stopPoint.y , offset.localHeight),
                note : a.text
            }
        })
        return JSON.stringify(karyoArrows)
    }

    roundNumber(n, m){
        const r = Math.round(n * m);
        return Math.max(0, Math.min(m, r));
    }

}
export default CachePool