<template>
  <div class="accountWithdrawalBox">
    <div class="accountWithdrawalTitle">
      <div class="accountWithdrawalTitleText">
        <span>{{$t('account_manage.account_manage')}} / </span> <span>{{$t('account_manage.account_widthdraw')}}</span>
      </div>
      <div class="accountWithdrawalTitleBtn" @click="backFun">
        <span>{{$t('account_manage.back')}}</span>
        <img src="../../../assets/images/personalSettings/iconReturnRight.svg" />
      </div>
    </div>
    <div class="dividingLine"></div>
    <div class="accountWithdrawalContent">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm" label-width="90px">
        <el-form-item :label="$t('account_manage.account_last')" class="moneyText">
          <span class="showMoney">{{$t('unit.money')}}<span v-html="moneyNum"></span></span>
        </el-form-item>
        <el-form-item :label="$t('account_manage.gathering_mode')">
          <el-select v-model="ruleForm.id" :placeholder="$t('select_button.choose')">
            <el-option v-for="item in homeList" :key="item.id" :label="item.account" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('account_manage.withdrawAmount')" prop="money">
          <el-input v-model="ruleForm.money" :placeholder="$t('account_manage.input_withdrawAmount')"></el-input>
        </el-form-item>
        <el-form-item label="">
          <div class="btn determineNomal" @click="onSubmit('ruleForm')">
            {{$t('btns.widthdraw')}}
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import "../../../assets/scss/button.scss";
import common from "./../common/common.js";
export default {
  name: "AccountWithdrawal",
  data() {
    var validateMoney = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('account_manage.input_withdrawAmount')));
      } else {
        if (Number(value) < 1) {
          callback(new Error(this.$t('account_manage.withdrawAmount_moreThan_one')));
        } else {
          callback();
        }
      }
    };
    return {
      isShowDate: 1,
      moneyNum: 0,
      homeList: [], // 存放列表数据
      ruleForm: {
        id: "",
        money: ""
      },
      rules: {
        money: [
          {
            validator: validateMoney,
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    backFun() {
      this.$emit("childFun", this.isShowDate);
    },
    // 点击提交按钮
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let postData = {
            money: this.ruleForm.money,
            userAccountId: this.homeList[0].id
          };
          this.$api.withdrawalEdit(postData).then(res => {
            if (res.code == 200) {
              this.ruleForm.money = "";
              this.$parent.getQueryByToken();
              this.getQueryByToken();
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 获取金额信息
    getQueryByToken() {
      this.$api.queryByToken({}).then(res => {
        if (res.code == 200) {
          const { billTypes, company, discount } = JSON.parse(
            res.data
          );
          discount.priceAccount.toString().split(".")[1]
            ? (this.moneyNum = discount.priceAccount)
            : (this.moneyNum = discount.priceAccount + ".00");
        } else {
          // this.$router.push({
          //     path: "/"
          // });
          this.$message.error(res.message);
        }
      });
    },
    // 获取账户列表
    getHomeList() {
      this.$api.homeList({}).then(res => {
        if (res.code == 200) {
          res.data.forEach(item => {
            if (item.type == 1) {
              item.type = "(" + this.$t('account_manage.Alipay') + ")";
            }
            if (item.type == 2) {
              item.type = "(" + this.$t('account_manage.Wechat') + ")";
            }
            if (item.type == 4) {
              item.type = "(" + this.$t('account_manage.bankcard') + ")";
            }
            item.account =
              item.account +
              common.substrFun(item.accountName, true) +
              item.type;
          });
          this.homeList = res.data;
          this.ruleForm.id = res.data[0].id;
        } else {
          //   this.$router.push({
          //     path: "/"
          //   });
          this.$message.error(res.message);
        }
      });
    }
  },
  created() {
    this.getQueryByToken();
    this.getHomeList();
  }
};
</script>

<style lang="scss" scoped>
.accountWithdrawalBox {
  .accountWithdrawalTitle {
    margin-left: 20px;
    height: 24px;
    display: flex;

    .accountWithdrawalTitleText {
      & > :nth-child(1) {
        font-size: 16px;
        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
        font-weight: 400;
        color: #666666;
      }

      & > :nth-child(2) {
        font-size: 16px;
        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
        font-weight: 400;
        color: #333333;
      }
    }

    .accountWithdrawalTitleBtn {
      margin-left: 683px;
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-size: 18px;
        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
        font-weight: 400;
        color: #333333;
      }

      img {
        padding-left: 10px;
      }
    }
  }

  .dividingLine {
    margin-top: 13px;
    margin-left: 20px;
    width: 890px;
    height: 1px;
    background: #d8d8d8;
  }

  .accountWithdrawalContent {
    margin-top: 52px;
    padding-left: 50px;

    .moneyText {
      height: 29px;
    }

    .determineNomal {
      width: 146px;
      height: 40px;
      line-height: 40px;

      font-size: 16px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;

      color: #ffffff;
    }

    form {
      width: 326px;
      margin-top: 20px;

      .showMoney {
        font-size: 20px;
        font-family: SourceHanSansSC-Bold, SourceHanSansSC;
        font-weight: bold;
        color: #333333;
      }
    }
  }
}
</style>
<style lang="scss">
.accountWithdrawalBox {
  .el-form-item__label {
    font-size: 18px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #666666;
  }

  .el-select .el-input__inner {
    width: 224px;
    height: 40px;
    background: #ffffff;
    border-radius: 2px;
  }

  .el-input {
    width: 224px;
    height: 40px;
    background: #ffffff;
    border-radius: 2px;
  }
}
</style>
