<template>
  <div class="team-work-setting">
    <div class="work-setting" v-if="false">
      <h3>{{$t('teamSeting.automatic.title')}}</h3>
      <div class="case-price-list">
        <div class="case-price">
          <label>{{$t('teamSeting.automatic.low.title')}}</label>
          <!-- <span>{{$t('teamSeting.automatic.low.desc')}}</span> -->
          <span>优质核型图占比{{adminConfig.lowProportion}}%以下</span>
          <em>{{adminConfig.lowPrice}} {{$t('teamSeting.unit')}}/{{$t('teamSeting.case')}}</em>
        </div>
        <div class="case-price">
          <label>{{$t('teamSeting.automatic.secondary.title')}}</label>
          <!-- <span>{{$t('teamSeting.automatic.secondary.desc')}}</span> -->
          <span>优质核型图占比{{adminConfig.mediumProportion}}%以上</span>
          <em>{{adminConfig.mediumPrice}} {{$t('teamSeting.unit')}}/{{$t('teamSeting.case')}}</em>
        </div>
        <div class="case-price">
          <label>{{$t('teamSeting.automatic.high.title')}}</label>
          <!-- <span>{{$t('teamSeting.automatic.high.desc')}}</span> -->
          <span>优质核型图占比{{adminConfig.heightProportion}}%以上</span>
          <em>{{adminConfig.highPrice}} {{$t('teamSeting.unit')}}/{{$t('teamSeting.case')}}</em>
        </div>
      </div>
    </div>
    <div class="work-setting" v-if="userWorkConfig.coMechanism">
      <h3>
        <span>{{$t('teamSeting.transfer.title')}}</span>
        <!-- <ds-switch class="transfer-switch" v-model="transferSwitch" :active-text="$t('teamSeting.transfer.on')" :inactive-text="$t('teamSeting.transfer.off')" @change="transferSwitchChange"></ds-switch> -->
      </h3>
      <div class="transfer-setting">
        <div class="transfer-row">
          <label>{{$t('teamSeting.transfer.method')}}</label>
          <div class="transfer-row-flex">
            <div class="transfer-radio">
              <el-radio v-model="cooperationMethod" label="1" @change="changeCooperation">{{$t('teamSeting.transfer.method_1')}}</el-radio>
            </div>
            <div class="transfer-radio">
              <el-radio v-model="cooperationMethod" label="2" @change="changeCooperation">{{$t('teamSeting.transfer.method_2')}}</el-radio>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="work-setting">
      <h3>{{$t('teamSeting.analysis.title')}}</h3>
      <div class="analysis-setting">
        <div class="analysis-setting-label">
          <label>{{$t('teamSeting.analysis.title1')}}</label>
          <label>{{$t('teamSeting.analysis.title2')}}</label>
        </div>
        <div class="analysis-setting-flex">
          <div class="analysis-setting-row">
            <ds-switch v-model="distributable" :active-text="$t('teamSeting.transfer.on')" :inactive-text="$t('teamSeting.transfer.off')" @change="analysisSwitchChange"></ds-switch>
          </div>
          <div class="analysis-setting-row">
            <ds-switch v-model="autoDistributable" :active-text="$t('teamSeting.transfer.on')" :inactive-text="$t('teamSeting.transfer.off')" @change="autoAnalysisSwitchChange"></ds-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="work-setting">
      <h3>{{$t('teamSeting.service.title')}}</h3>
      <div class="service-setting-tips">{{$t('teamSeting.service.tip')}}</div>
    </div>
    <!-- <el-button :type="btnType" class="submit-btn" @click="updateWorkConfig">{{$t(btnText)}}</el-button> -->
    <!-- <div class="work-setting">
            <h3>{{$t('teamSeting.service.title')}}</h3>
            <div class="service-setting">
                <div class="service-setting-row">
                    <label>{{$t('teamSeting.service.cost')}}</label>
                    <el-input-number v-model="userWorkConfig.patientPrice" controls-position="right" :min="1" size="small" disabled></el-input-number>
                    <label style="margin-left: 5px;">{{$t('teamSeting.unit')}}</label>
                </div>
                
            </div>
        </div> -->
  </div>
</template>

<script>
import dsSwitch from "./ds-switch.vue";
export default {
  name: "TeamWorkSetting",
  components: {
    dsSwitch,
  },
  data() {
    return {
      adminConfig: {}, //病例分析金额与占比
      transferSwitch: false, //转交病例设置
      selectDisabled: true, //下拉框禁用状态
      transferAgency: "", //合作机构
      mechanismList: [], //机构列表
      userWorkConfig: {
        // sendStatus: 0, //病例转交设置 0：关闭   1：开启
        // coMechanism: {
        //   coMechanismId: 1,
        //   patientPrice: 200, //单病例人工分析费用
        //   cooperationType: 2, //合作方式  1：上传后自动发送给合作机构  2：手动选择病例发送给合作机构
        // },
        // autoDistributeInAgency: 1, //是否启用病例分配：0 关闭 1 开启
        // autoDistributePatient: 1, //是否自动分配病例：0 关闭 1 开启
        // receiveStatus: 0, //病例转交分析服务状态0：关闭  1：审核中   2：开启
        // patientPrice: 200, //单病例人工分析费用(病例转交分析服务)
      },

      cooperationMethod: "2", //合作方式
      distributable: false, //病例分配
      autoDistributable: false, //自动分配病例
      cost: 100, //单病例人工分析费用

      btnType: "primary", //按钮类型
      btnText: "teamSeting.service.btnSubmit",
      btnTypeMap: {
        type: ["primary", "success", "primary"],
        text: [
          "teamSeting.service.btnSubmit",
          "teamSeting.service.btnSuccess",
          "teamSeting.service.btnSubmit",
        ],
      },
    };
  },
  created() {
    this.getWorkConfig();
    window.setApp = this;
  },
  methods: {
    /**
     * 获取工作设置信息
     */
    getWorkConfig() {
      this.$api
        .getWorkConfig({
          acountId: "",
          accountType: "2",
        })
        .then((res) => {
          if (res.code != 200) {
            this.$message({
              type: "error",
              message: res.message,
            });
            return;
          }
          this.adminConfig = res.data.adminConfig;
          this.mechanismList = res.data.mechanismList || [];
          this.userWorkConfig = res.data.userWorkConfig || this.userWorkConfig;
          this.transferSwitch = !!this.userWorkConfig.sendStatus;
          this.cooperationMethod = String((this.userWorkConfig.coMechanism && this.userWorkConfig.coMechanism.cooperationType) || this.cooperationMethod);
          this.distributable = !!this.userWorkConfig.autoDistributeInAgency;
          this.autoDistributable = !!this.userWorkConfig.autoDistributePatient;
          this.btnType =
            this.btnTypeMap.type[this.userWorkConfig.receiveStatus];
          this.btnText =
            this.btnTypeMap.text[this.userWorkConfig.receiveStatus];
        });
    },
    /**
     * 保存设置
     */
    updateWorkConfig() {
      const param = {
        // sendStatus: Number(this.transferSwitch),
        // mechanismId: this.transferAgency,
        // cooperationType: this.cooperationMethod,
        autoDistributeInAgency: Number(!!this.distributable),
        autoDistributePatient: Number(!!this.autoDistributable),
      };
      if (this.userWorkConfig.coMechanism) {
        param.cooperationType = this.cooperationMethod
      }
      console.log(param);
      this.$api.updateWorkConfig(param).then(res => {
        this.$message({
          type: res.code == 200 ? "success" : "error",
          message: res.message
        })
      })
    },
    transferSwitchChange(v) {
      console.log(v);
      this.transferSwitch = v;
      this.selectDisabled = !v;
    },
    analysisSwitchChange(v) {
      this.distributable = v;
      this.$store.commit("openDistribution", 1);
      if (!v) {
        this.autoDistributable = false
        this.$store.commit("openDistribution", 0);
      }
      this.updateWorkConfig();
    },
    autoAnalysisSwitchChange(v) {
      this.autoDistributable = v;
      this.updateWorkConfig();
    },
    changeCooperation() {
      this.updateWorkConfig();
    }
  },
  watch: {
    distributable(val) {
      if (!val) {
        this.autoDistributable = false;
      }
    },
  }
};
</script>

<style scoped lang="scss">
.team-work-setting {
  padding: 20px 50px;
  overflow: hidden;
  .work-setting {
    padding-top: 18px;
    overflow: hidden;
    border-top: 1px solid #e3e3e3;
    &:first-child {
      border-top: none;
    }
    h3 {
      margin: 0;
      height: 28px;
      line-height: 28px;
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      display: flex;
      align-items: center;
    }
  }
  .transfer-switch {
    transform: translate3d(20px, 1px, 0);
  }
  .case-price-list {
    overflow: hidden;
    padding: 10px 40px;
    .case-price {
      overflow: hidden;
      height: 60px;
      line-height: 60px;
      border-top: 1px solid #e3e3e3;
      &:first-child {
        border-top: none;
      }
      label {
        font-size: 17px;
        font-weight: 600;
        color: #333333;
        float: left;
      }
      span {
        float: left;
        margin-left: 16px;
        font-size: 14px;
        color: #666666;
      }
      em {
        float: right;
        color: #333333;
        font-size: 15px;
      }
    }
  }

  .transfer-setting {
    overflow: hidden;
    padding: 0 40px;
    .transfer-row {
      margin: 12px 0 8px;
      overflow: hidden;
      display: flex;
      > label {
        display: block;
        height: 36px;
        line-height: 36px;
      }
      .transfer-row-flex {
        flex: 1;
      }
      .transfer-desc {
        height: 26px;
        line-height: 26px;
        font-size: 12px;
      }
    }
    .transfer-radio {
      height: 36px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .analysis-setting {
    overflow: hidden;
    padding: 10px 40px 28px;
    display: flex;
    .analysis-setting-label {
      text-align: right;
      font-size: 16px;
      color: #666666;
      label {
        height: 38px;
        line-height: 38px;
        display: block;
      }
    }
    .analysis-setting-flex {
      flex: 1;
    }
    .analysis-setting-row {
      height: 38px;
      display: flex;
      align-items: center;
    }
  }

  .service-setting {
    padding: 20px 0;
    overflow: hidden;
    .service-setting-row {
      display: flex;
      align-items: center;
      label {
        font-size: 14px;
      }
    }
  }
  .submit-btn {
    width: 146px;
    height: 40px;
    margin-top: 20px;
    margin-left: 0;
  }
  .service-setting-tips {
    font-size: 14px;
    margin-top: 12px;
  }
}
</style>
