/**
 * 添加箭头与箭头操作
 * @param {Object} stage
 */
function arrow(stage, controller) {
	let arrowCustom, 	//添加箭头时的箭头
		dataCustom,
		arrowShape, 	//已经存在的箭头
		arrowStarPoint, 	//起始坐标
		arrowDx = 0,	//已存在的箭头的x坐标位置
		arrowDy = 0;	//已存在的箭头的y坐标位置
	let isPointOnStartPoint = false,	//鼠标是否在箭头起始点位置附近
		isPointOnEndPoint = false;	//鼠标是否在箭头终点位置附近
	let isDragMove = false;		//是否为拖动状态
	let arrowInController;	//控制器中与被拖动的箭头对应的数据
	let moveBeginPoint;
	let moveStopPoint;
	//触发条件
	const condition = {
		getStatus : ()=>{
			return this.toolsType === "arrow" && this.shortKeyType === '';
		}
	}
	//获取controller中对应的箭头数据
	const getArrowInController = (shape)=>{
		if(controller.arrowMap[shape.id]){
			return controller.arrowMap[shape.id].arrow;
		}
		return null;
	}
	//保存当前数据
	const save = (shape) =>{
		this.cachePool.save();
		this.cachePool.record({
			type : 'arrowMark',
			message : '箭头标注',
			data : (()=>{
				const arrow = Object.assign({},shape);
				delete arrow._;
				return arrow;
			})(),
			result : null
		})
	}

	const dragStartHandle = ({e})=>{
		const shape = stage.isPointInner(e, false, 'arrow');
		if (shape) {
			stage.SWITCH.stageDragEnable = false;
			arrowShape = shape;
			arrowDx = arrowShape.x;
			arrowDy = arrowShape.y;
			arrowStarPoint = stage.getPos(e);
			const pos = stage.getPos(e);
			isPointOnStartPoint = arrowShape.isPointOnStartPoint(pos.x, pos.y);
			isPointOnEndPoint = arrowShape.isPointOnEndPoint(pos.x, pos.y);
			arrowInController = getArrowInController(shape);
			moveBeginPoint = {
				x : arrowShape.beginPoint.x,
				y : arrowShape.beginPoint.y
			};
			moveStopPoint = {
				x : arrowShape.stopPoint.x,
				y : arrowShape.stopPoint.y
			};
		}
	}
	
	const dragMoveHandle = ({e,points})=>{
		const TRANS_X = controller.name === 'karyo' ? 0 : stage.offset.TRANS_X;
		isDragMove = true;
		const startP = points[0];
		const endP = points[points.length-1];
		// console.log(startP,endP);
		const isValid = Math.abs(endP.x - startP.x) >= 5 || Math.abs(endP.y - startP.y) >= 5
		//drag start 点中的为非箭头 且未添加当前箭头 且起始与终点为有效距离
		if (!arrowShape && !arrowCustom && isValid) {
			const sp = stage.getPos({
				offsetX: startP.x,
				offsetY: startP.y
			});
			const ep = stage.getPos({
				offsetX: endP.x,
				offsetY: endP.y
			});
			arrowCustom = stage.graphs.arrow({
				beginPoint: {
					x: sp.x,
					y: sp.y
				},
				stopPoint: {
					x: ep.x,
					y: ep.y
				},
				color: "#f00",
				lineWidth: controller.name === "karyo" ? 3 : 6,
				zindex :600,
				id : controller.getGuid()
			});
			// dataCustom = {
			// 	start_x : sp.x,
			// 	start_y : sp.y,
			// 	target_x : ep.x,
			// 	target_y : ep.y,
			// 	note : '',
			// 	guid : arrowCustom.id
			// }
			// const dataCustom = controller.shapeToArrow(arrowCustom);
			stage.addChild(arrowCustom);
			// controller.arrowList.push(dataCustom);
			stage.draw()
		}
		//dragstart 未点中箭头 且 已经添加了自定义箭头 === 正在添加新的箭头并且在移动中,尚未松开鼠标按键
		if (!arrowShape && arrowCustom) {
			const ep = stage.getPos({
				offsetX: endP.x,
				offsetY: endP.y
			});
			arrowCustom.stopPoint = {
				x: ep.x,
				y: ep.y
			}
			stage.draw()
		}
		//点中箭头图形拖动中 箭头非两端部位
		if (arrowShape && !arrowCustom && !isPointOnStartPoint && !isPointOnEndPoint) {
			const point = stage.getPos(e);
			const x = (point.x - arrowStarPoint.x);
			const y = (point.y - arrowStarPoint.y);
			// console.log(moveBeginPoint,moveStopPoint,x,y);
			arrowShape.beginPoint.x = moveBeginPoint.x + x;
			arrowShape.beginPoint.y = moveBeginPoint.y + y;
			arrowShape.stopPoint.x = moveStopPoint.x + x;
			arrowShape.stopPoint.y = moveStopPoint.y + y;
			// arrowShape.x = arrowDx + point.x - arrowStarPoint.x;
			// arrowShape.y = arrowDy + point.y - arrowStarPoint.y;
			stage.draw();
		}
		//点中箭头图形拖动中 箭头 起点附近
		if (arrowShape && !arrowCustom && isPointOnStartPoint && !isPointOnEndPoint) {
			const point = stage.getPos(e);
			arrowShape.beginPoint = {
				x: (point.x - arrowDx),
				y: (point.y - arrowDy)
			}
			stage.draw();
		}
		//点中箭头图形拖动中 箭头 终点附近
		if (arrowShape && !arrowCustom && !isPointOnStartPoint && isPointOnEndPoint) {
			const point = stage.getPos(e);
			arrowShape.stopPoint = {
				x: (point.x - arrowDx),
				y: (point.y - arrowDy)
			}
			stage.draw();
		}
		//点中箭头图形拖动中 箭头 终点附近
		if (arrowShape && !arrowCustom && isPointOnStartPoint && isPointOnEndPoint) {
			const point = stage.getPos(e);
			arrowShape.stopPoint = {
				x: (point.x - arrowDx),
				y: (point.y - arrowDy)
			}
			stage.draw();
		}
	}

	//拖动开始
	stage.event.dragStart(condition,({
		e,
		points
	}) => {
		dragStartHandle({e,points})
	})
	//拖动中
	stage.event.dragMove(condition,dragMoveHandle)

	/**
	 * 重置数据
	 */
	const resetData = () => {
		arrowCustom = null;
		dataCustom = null;
		arrowShape = null;
		arrowStarPoint = null;
		arrowDx = 0;
		arrowDy = 0;
		isPointOnStartPoint = false;
		isPointOnEndPoint = false;
		isDragMove = false;
	}


	const dragEndHandle = ({e, shape})=>{
		stage.SWITCH.stageDragEnable = true;
		if (arrowCustom) {
			const sx = arrowCustom.beginPoint.x;
			const sy = arrowCustom.beginPoint.y;
			const ex = arrowCustom.stopPoint.x;
			const ey = arrowCustom.stopPoint.y;
			const isValid = Math.abs(sx - ex) < 5 && Math.abs(sy - ey) < 5;
			
			const index = controller.arrowList.indexOf(dataCustom);
			if (isValid) {
				stage.removeChild(arrowCustom);
				stage.draw();
				controller.arrowList.splice(index, 1);
			}else{
				// controller.name === "arrange" && controller.reArrange();
				const arrow = controller.shapeToArrow(arrowCustom);
				// const arrow = getArrowByShape(arrowCustom);
				controller.arrowList.push(arrow)
				controller.arrowMap[arrowCustom.id] = {
					shape : arrowCustom,
					arrow
				}
				save(arrowCustom)
			}
		}else if(arrowShape){
			const arrow = arrowInController;
			// console.warn("arrow",arrow,arrowShape,controller.name);
			if(!arrow) return;

			if(arrow.beginPoint){
				arrow.beginPoint = arrowShape.beginPoint;
				arrow.stopPoint = arrowShape.stopPoint;
			}else{
				const index = controller.arrowList.indexOf(arrow);
				// const newArrow = getArrowByShape(arrowShape);
				const newArrow = controller.shapeToArrow(arrowShape);
				controller.arrowList.splice(index,1, Object.assign(arrow,newArrow))
			}
			save(arrowShape)
		}
		resetData();
	}

	//拖动结束
	stage.event.dragEnd(condition,dragEndHandle)

	const conditionSelect = {
		getStatus : ()=>{
			return this.toolsType === "select" && this.shortKeyType === '';
		}
	}
	stage.event.dragStart(conditionSelect,({
		e,
		points
	}) => {
		dragStartHandle({e,points})
	})
	stage.event.dragMove(conditionSelect,({
		e,
		points
	}) => {
		if(arrowShape && !arrowCustom){
			dragMoveHandle({e,points})
		}
	})
	stage.event.dragEnd(conditionSelect,dragEndHandle)


	//箭头双击 打开箭头标注弹出框
	stage.event.dblclick(condition,({
		e,
		points
	}) => {
		arrowShape = null;
		arrowCustom = null;
		const shape = stage.isPointInner(e, false, 'arrow');
		resetData();
		if (shape) {
			this.arrowMarkVisible = true;
			this.arrowMarkValue = shape.txt;
			const arrow = getArrowInController(shape);
			console.log(shape,arrow);
			this.arrowMarkCallback = (v) => {
				shape.txt = this.arrowMarkValue;
				
				if(arrow){
					arrow.note = this.arrowMarkValue;
				}
				stage.draw();
				save(shape)
			}
		}
	})
	const deleteArrow = (e)=>{
		const shape = stage.isPointInner(e, false, 'arrow');
		console.log(shape)
		if(!shape) return;
		const pos = stage.getPos(e);
		const arrow = getArrowInController(shape);
		console.warn(shape.isPointInner(pos.x, pos.y))
		if(shape.isPointerOnText(pos.x, pos.y)){
			shape.txt = "";
			arrow.text = "";
			stage.draw();
			save(shape)
		}else if(shape.isPointInner(pos.x, pos.y)){
			stage.removeChild(shape);
			const index = controller.arrowList.indexOf(arrow);
			controller.arrowList.splice(index,1);
			stage.draw();
			save(shape)
		}
	}
	stage.event.click(Object.assign({button:2}, condition),({e})=>{
		deleteArrow(e);
	})
	// stage.event.click(Object.assign({button:1}, condition),({e})=>{
	// 	deleteArrow(e);
	// })
	stage.element.addEventListener("click",(e)=>{
		if(e.button === 1 && this.toolsType === "arrow" && this.shortKeyType === ''){
			deleteArrow(e);
		}
	})
}
export default arrow
