function banding(stage, controller){
    console.log(this);
    const condition = {
        getStatus : ()=>{
            return this.toolsType === 'banding';
        }
    }
    const conditionAdd = Object.assign({button:0},condition)
    const conditionMenu = Object.assign({button:2},condition)
    const conditionDel = Object.assign({button:1},condition)
    const getDataByShape = (shape) => {
        return controller.bandingMap[shape.id].item;
    }
    let TEXT = '', text = '', no = '', target;
    const resetData = ()=>{
        TEXT = '';
        text = '';
        no = '';
        target = null;
    }

    const addBandingKey = (standard, e) => {
        const banding = getDataByShape(standard);
        console.warn(banding);
        const pos = stage.getPos(e);
        const y = standard.getPosRelative(pos.y);
        const item = controller.standardInfo.banding.filter(item=>{
            return item.name === `Chrom${standard.no}ISCN09`
        })[0];
        this.bandingSelectVisible = false;
        if(item){
            const _item = item.element.filter(a=>{
                return a.lxly[1] <= y && y <= a.rxry[1]
            })[0];
            if(_item){
                const obj = banding.key.filter(a=>a.Name===_item.name)[0];
                const index = banding.key.indexOf(obj)
                // console.warn(obj,index,_item.name);
                if(index < 0){
                    banding.key.push({Name:_item.name})
                }else{
                    banding.key.splice(index,1)
                }
                resetData();
                // console.warn(banding.key.length);
                controller.reCreateStandChromo();
                this.cachePool.save();
            }
        }
    }

    //左键点击
    stage.event.click(conditionAdd,({e})=>{
        this.bandingSelectVisible = false;
        !controller.banding.standchromoInfo && (controller.banding.standchromoInfo = []);
        !controller.banding.standchromoModal && (controller.banding.standchromoModal = 320)
        const standard = stage.isPointInner(e,false,'image');
        const groupbox = stage.isPointInner(e,false,'rectangle');
        if(standard){
            addBandingKey(standard, e);
        }else if(groupbox){
            const cx = groupbox.x + groupbox.width / 2;
            const pos = stage.getPos(e);
            const isRight = pos.x > cx;
            const isExist = controller.banding.standchromoInfo.some(a=>{
                return a.ChromoID == groupbox.group && a.IsRight == isRight;
            })
            if(isExist){
                return;
            }
            // console.log(groupbox,isRight);
            controller.banding.standchromoInfo.push({
                ChromoID : groupbox.group,
                IsRight : isRight,
                key : []
            })
            controller.reCreateStandChromo();
            this.cachePool.save();
        }
    })
    const showMenu = ({e}) => {
        e.stopPropagation();
        const standard = stage.isPointInner(e,false,'image');
        console.log(standard);
        if(!standard || standard.realType !== 'banding' && standard.realType !== 'standard'){
            return;
        }
        this.bandingSelectVisible = true;
        this.bandingStandchromoModal = controller.banding.standchromoModal;
        this.$nextTick(()=>{
            this.$refs.banding.style.left = e.clientX + 5 + "px";
            this.$refs.banding.style.top = e.clientY + "px";
        })
    }
    //右键点击
    stage.event.click(conditionMenu,showMenu)
    stage.event.click({
        button: 2,
        getStatus: ()=>{
            return this.toolsType !== 'banding' && this.standardVisible;
        }
    },showMenu)
    document.addEventListener("click",(e)=>{
        this.bandingSelectVisible = false;
    })
    //删除定带
    const delBanding = ({e}) => {
        if(!controller.banding.standchromoInfo || !controller.banding.standchromoModal){
            return;
        }
        const standard = stage.isPointInner(e,false,'image');
        if(!standard || standard.realType !== "banding"){
            return;
        }
        this.bandingSelectVisible = false;
        const data = getDataByShape(standard);
        const index = controller.banding.standchromoInfo.indexOf(data);
        index > -1 && (controller.banding.standchromoInfo.splice(index,1))
        controller.reCreateStandChromo();
        this.cachePool.save();
    }
    //中键点击
    stage.event.click(conditionDel,delBanding);
    //删除工具左键点击
    stage.event.click({
        button : 0,
        getStatus : ()=>{
            return this.toolsType === 'delete'
        }
    },delBanding)
    
    //hover
    stage.element.addEventListener("mousemove",(e)=>{
		if(this.toolsType !== 'banding' || this.standardVisible){
			return;
		}
        const standard = stage.isPointInner(e,false,'image');

        // console.log(standard);
        if(standard){
            target = standard;
            const pos = stage.getPos(e);
            const y = standard.getPosRelative(pos.y);
            const item = controller.standardInfo.banding.filter(item=>{
				return item.name === `Chrom${standard.no}ISCN09`
            })[0];
            if(item){
				const _item = item.element.filter(a=>{
					return a.lxly[1] <= y && y <= a.rxry[1]
                })[0];
                if(_item){
                    
                    if(TEXT !== _item.name){
                        standard.keys = standard.keys.filter(a=>a.name!==TEXT);
                        const isExist = standard.keys.some(a=>a.name===_item.name);
                        if(!isExist){
                            TEXT = _item.name;
                            const sy = _item.lxly[1]
                            const ey = _item.rxry[1]
                            const _y = sy + (ey - sy) / 2;
                            // console.log(`sy:${sy}, ey:${ey}, y: ${_y}`);
                            standard.keys.push({name:TEXT, y:_y});
                        }
                    }
                }
			}
        }else{
            if(target && TEXT){
                target.keys = target.keys.filter(a=>a.name!==TEXT);
                // controller.reCreateStandChromo();
            }
            resetData();
        }
        stage.draw();
	})
}
export default banding