<template>
  <div class="incomeExpenditureDetailsBox" :class="{'english': $store.state.language == 'en'}">
    <div class="incomeExpenditureDetailsScreen">
      <span class="startEndTime">{{$t('report.choose_date')}}：</span>
      <el-date-picker v-model="dateArr" type="daterange" align="right" unlink-panels :range-separator="$t('accountInfo.to')" :start-placeholder="$t('select_button.startDate')" :end-placeholder="$t('select_button.endDate')">
      </el-date-picker>
      <div class="radio" v-if="$store.state.accountType == '1'">
        <span class="sp-radio">{{$t('accountInfo.status')}}</span>
        <el-radio-group v-model="status" @change="accountStatusChange">
          <el-radio :label="1">{{$t('accountInfo.get_money')}}</el-radio>
          <el-radio :label="0">{{$t('accountInfo.not_get_money')}}</el-radio>
        </el-radio-group>
        <!-- <el-radio v-model="status" label="1">已到账</el-radio>
        <el-radio v-model="status" label="0">未到账</el-radio> -->
      </div>
    </div>
    <div class="incomeExpenditureDetailsCenter" v-if="$store.state.accountType == '1'">
      <el-table :data="tableData" stripe style="width: 100%">
        <template slot="empty">
          <img src="../../../assets/images/personalSettings/iconNotGenerated.svg" alt="" />
          <span>{{$t('income_expenses_details.no_record')}}</span>
        </template>
        <el-table-column prop="orderNo" :label="$t('new_interaction.code')" align="center">
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('income_expenses_details.createTime')" align="center"></el-table-column>
        <el-table-column prop="typeName" :label="$t('income_expenses_details.income_expense_mode')" align="center"></el-table-column>
        <el-table-column prop="patientName" :label="$t('accountInfo.case_no')" width="140" align="center">
        </el-table-column>
        <el-table-column prop="money" :label="$t('income_expenses_details.income_expense_amount')" align="center">
        </el-table-column>
        <el-table-column prop="statusName" :label="$t('accountInfo.status')" align="center"></el-table-column>

      </el-table>
    </div>
    <div class="incomeExpenditureDetailsCenter" v-if="$store.state.accountType == '2'">
      <el-table :data="tableData" stripe style="width: 100%">
        <template slot="empty">
          <img src="../../../assets/images/personalSettings/iconNotGenerated.svg" alt="" />
          <span>{{$t('income_expenses_details.no_record')}}</span>
        </template>
        <el-table-column prop="orderNo" :label="$t('new_interaction.code')" align="center">
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('income_expenses_details.createTime')" align="center"></el-table-column>
        <el-table-column prop="account" :label="$t('accountInfo.analysis_account')" align="center"></el-table-column>
        <el-table-column prop="typeName" :label="$t('income_expenses_details.income_expense_mode')" align="center"></el-table-column>
        <el-table-column prop="patientName" :label="$t('accountInfo.case_name')" width="140" align="center"></el-table-column>
        <el-table-column prop="mechanismName" :label="$t('accountInfo.case_source')" width="140" align="center"></el-table-column>
        <el-table-column prop="money" :label="$t('income_expenses_details.income_expense_amount')" align="center" width="120">
        </el-table-column>
        <el-table-column prop="statusName" :label="$t('accountInfo.statusNo')" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="incomeExpenditureDetailsPaging">
      <div class="totalBox">
        <span>{{$t('pages.all')}}</span>
        <span v-html="total" style="margin: 0 4px"></span>
        <span>{{$t('pages.counts')}}</span>
      </div>
      <div class="currentPageBox">
        <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next" :total="total" background>
        </el-pagination>
        <div class="jumperPage">
          <span>{{$t('pages.to')}}</span>
          <el-input v-model="jumperPageValue" @input="jumperPageValueFun($event)"></el-input>
          <span>{{$t('pages.page')}}</span>
        </div>
        <div class="btn determineNomal" @click="jumperPageFun">
          {{$t('btns.confirm')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./../scss/incomeExpenditureDetails.scss";
import { reg } from "./../../../assets/js/reg.js";
import common from "./../../../assets/js/common.js";
export default {
  name: "IncomeExpenditureDetails",
  data() {
    return {
      tableData: [],
      // dateType: "1",
      // options: [
      //   {
      //     dateType: "1",
      //     label: this.$t("select_button.all"),
      //   },
      //   {
      //     dateType: "2",
      //     label: this.$t("select_button.lately_7days"),
      //   },
      //   {
      //     dateType: "3",
      //     label: this.$t("select_button.lately_30days"),
      //   },
      //   {
      //     dateType: "4",
      //     label: this.$t("select_button.lately_1year"),
      //   },
      // ],
      // standardData: [
      //   this.$t("select_button.all"),
      //   this.$t("select_button.income"),
      //   this.$t("select_button.expenditure"),
      // ],
      dateArr: [],
      selectIndex: 0,
      currentPage: 1,
      total: 0,
      pageSize: 0,
      jumperPageValue: "1",
      totalPage: 0,
      status: 1,
      common
    };
  },
  methods: {
    // 选择不同类型
    setSwitchState(index) {
      this.selectIndex = index;
      this.currentPage = 1;
      this.getPaymentSummaryList();
    },
    // 选择分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPaymentSummaryList();
    },
    // 获取列表数据
    getPaymentSummaryList() {
      let postData = {};
      const [startTime, endTime] = this.dateArr;
      if (this.$store.state.accountType == "1") {
        postData = {
          startTime: startTime && startTime.toLocaleDateString(),
          endTime: endTime && endTime.toLocaleDateString(),
          status: this.status, //0：未到账   1：已到账
          type: 4,//收益记录
          page: this.currentPage,
          limit: 10,
        };
      } else {
        postData = {
          startTime: startTime && startTime.toLocaleDateString(),
          endTime: endTime && endTime.toLocaleDateString(),
          type: 4,//收益记录
          page: this.currentPage,
          limit: 10,
        };
      }
      this.$api.profit(postData).then((res) => {
        if (res.code == 200) {
          const { currPage, list, pageSize, totalCount, totalPage } = res.data;
          res.data.list.forEach((item) => {
            if (item.type == 1 || item.type == 2 || item.type == 3) {
              item.typeName = this.$t('accountInfo.charge');
              item.money = "+ " + item.money;
            } else if (item.type == 4) {
              item.typeName = this.$t('accountInfo.expert_reply');
              item.money = "+ " + item.money;
            } else if (
              item.type == 5 ||
              item.type == 6 ||
              item.type == 7 ||
              item.type == 8
            ) {
              item.typeName = this.$t('accountInfo.widthdraw');
              item.money = "- " + item.money;
            } else if (item.type == 9) {
              item.typeName = this.$t('accountInfo.auto_snalysis');
              item.money = "- " + item.money;
            } else if (item.type == 10) {
              item.typeName = this.$t('accountInfo.transfer_service');
              item.money = "+ " + item.money;
            } else if (item.type == 11) {
              item.typeName = this.$t('accountInfo.expert_consult');
              item.money = "- " + item.money;
            } else if (item.type == 12) {
              item.typeName = this.$t('accountInfo.transfer_cass');
              item.money = "- " + item.money;
            } else if (item.type == 13) {
              item.typeName = this.$t('accountInfo.sys_charge');
              item.money = "+ " + item.money;
            } else if (item.type == 14) {
              item.typeName = this.$t('accountInfo.sys_widthdraw');
              item.money = "- " + item.money;
            } else if (item.type == 15) {
              item.typeName = this.$t('accountInfo.case_transfer_back_money');
              item.money = "+ " + item.money;
            } else if (item.type == 16) {
              item.typeName = this.$t('accountInfo.expert_consult_back_money');
              item.money = "+ " + item.money;
            }


            if (!item.status) {
              item.statusName = this.$t('accountInfo.not_get_money');
            } else {
              item.statusName = this.$t('accountInfo.get_money');
            }

            item.createTime = common.timestampFormat(item.createTime, "yyyy-MM-dd");
          });
          this.currentPage = currPage;
          this.tableData = list;
          this.total = totalCount;
          this.pageSize = pageSize;
          this.jumperPageValue = currPage;
          this.totalPage = totalPage;
        } else {
          // this.$router.push({
          //   path: "/",
          // });
          this.$message.error(res.message);
        }
      });
    },
    accountStatusChange(val) {
      // this.status = val;
      this.currentPage = 1;
      this.getPaymentSummaryList();
    },
    // 点击确定分页数方法
    jumperPageFun() {
      this.currentPage = this.jumperPageValue;
      this.getPaymentSummaryList();
    },
    // 输入分页数
    jumperPageValueFun(value) {
      if (reg.pureNumbers.test(value)) {
        this.jumperPageValue = value.replace(reg.pureNumbers, "");
      } else {
        if (value === "0") {
          this.jumperPageValue = 1;
        }
      }
    },
  },
  mounted() {
    this.getPaymentSummaryList();
  },
  watch: {
    dateArr(val) {
      if (val == null) {
        this.dateArr = [];
      }
      this.getPaymentSummaryList();
    },
  }
};
</script>

<style lang="scss">
.incomeExpenditureDetailsBox {
  .el-range-editor.el-input__inner {
    height: 34px;
  }
  .el-date-editor .el-range__icon {
    line-height: 27px;
  }
  .el-date-editor .el-range-separator {
    line-height: 24px;
  }
  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner {
    width: 280px;
  }
  .el-date-editor .el-range__close-icon {
    line-height: 26px;
  }
  .radio {
    display: flex;
    align-items: center;
    .sp-radio {
      margin-left: 30px;
    }
    .el-radio {
      margin-top: 4px;
      margin-right: 16px;
    }
  }
  .el-table__header-wrapper {
    position: relative !important;
  }
  .el-table__body-wrapper {
    margin-top: 0;
  }
  /*滚动条样式*/
  .is-scrolling-none::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  .is-scrolling-none::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #bdbebf;
    -webkit-border-radius: 7px;
    outline: 2px solid #eaebec;
    outline-offset: 2px;
    border: 2px solid #eaebec;
  }

  .is-scrolling-none::-webkit-scrollbar-track-piece {
    background-color: #eaebec;
    -webkit-border-radius: 3px;
  }
}
</style>
