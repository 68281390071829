import store from "../../store";
import config from '../js/config.js';
//图片oss签名处理 、
// url,
// type =1 //s缩放
//      =2 //裁剪
//      =3 //旋转
//      =4 //钝化
//      =5 //水印
//      =6 //格式转换
// size是根绝type类型进行填充
const handle = async (fn) => {
    await fn();
}
export default function ossUrlWebFir(url, type, size, sizeH) {
    if (!url) {
        return '';
    }
    // 替换CDN地址为Oss地址
    url = url.replace(config.cdnUrl, config.ossUrl)
    let that = this;
    let OSS = require('ali-oss');
    try {

        if(url.indexOf('.online')==-1){

            let arr = url.split('?')[0];
            let picName = arr.split('.com/')[1]; //获取图片的oss路径;
            let bucketName = url.split('?')[0].split('//')[1].split('.')[0]; //获取bucketName  例如：ds-test
            let region = url.split('?')[0].split('//')[1].split('.')[1].split('.')[0]; //获取bucketName  例如：ds-test
            const {
                accessid,
                accesskey,
                SecurityToken,
                expiration,
            } = that.$store.state.ossUploadAccess;
            if (arr != '' && picName != "" && bucketName != "" && region != '' && accessid != '' && accesskey != '' && SecurityToken != '') {
                let client = new OSS({
                    region: region,
                    accessKeyId: accessid,
                    accessKeySecret: accesskey,
                    stsToken: SecurityToken,
                    bucket: bucketName,
                    /*cname:true,*/
                    secure: true
                });
                let signUrl = '';
                // 修改本地时间后，自适应修改过期时间
                let expireSecs = new Date(expiration).getTime() > new Date().getTime() ?
                    Number(((new Date(expiration).getTime() - new Date().getTime() + 1800000) / 1000).toFixed(0)) : 1800;
                if (type == 1) { //s缩放
                    try {
                        handle(() => {
                            if (sizeH) {
                                signUrl = client.signatureUrl(decodeURI(picName), {
                                    expires: expireSecs,
                                    'process': 'image/resize,m_pad,h_' + sizeH + ',w_' + size + ',color_FFFFFF'
                                });
                            } else {
                                signUrl = client.signatureUrl(decodeURI(picName), {
                                    expires: expireSecs,
                                    'process': 'image/resize,m_pad,h_' + size + ',w_' + size + ',color_FFFFFF'
                                });
                            }
                        })
                    } catch (e) {
                        //console.log(e);
                    }
                } else if (type == 2) { //裁剪
                    try {
                        handle(() => {
                            signUrl = client.signatureUrl(decodeURI(picName), {
                                expires: expireSecs,
                                process: 'image/crop,w_' + size + ',h_' + size + ',x_' + size + ',y_' + size + ',r_1'
                            })
                        })
                    } catch (e) {
                        //console.log(e);
                    }
                } else if (type == 3) { //旋转
                    try {
                        handle(() => {
                            signUrl = client.signatureUrl(decodeURI(picName), {
                                expires: expireSecs,
                                process: 'image/rotate,' + size + ''
                            })
                        })
                    } catch (e) {
                        //console.log(e);
                    }
                } else if (type == 4) { //钝化
                    try {
                        handle(() => {
                            signUrl = client.signatureUrl(decodeURI(picName), {
                                expires: expireSecs,
                                process: 'image/sharpen,' + size + ''
                            })
                        })
                    } catch (e) {
                        //console.log(e);
                    }
                } else if (type == 5) { //水印
                    try {
                        handle(() => {
                            signUrl = client.signatureUrl(decodeURI(picName), {
                                expires: expireSecs,
                                process: 'image/watermark,text_SGVsbG8g5Zu-54mH5pyN5YqhIQ'
                            })
                        })
                    } catch (e) {
                        console.log(e);
                    }
                } else if (type == 6) { //格式转换

                    try {
                        handle(() => {
                            signUrl = client.signatureUrl(decodeURI(picName), {
                                expires: expireSecs,
                                process: 'image/format,webp'
                            })
                        })
                    } catch (e) {
                        console.error(e);
                    }
                } else if (type == 7) { //格式转换
                    try {
                        handle(() => {
                            signUrl = client.signatureUrl(decodeURI(picName))
                        })
                    } catch (e) {
                        //console.log(e);
                    }
                } else if (type == 8) { //格式转换
                    try {
                        handle(() => {
                            signUrl = client.signatureUrl(decodeURI(picName), {
                                expires: expireSecs,
                                process: 'image/format,png'
                            })
                        })
                    } catch (e) {
                        //console.log(e);
                    }
                }
                return signUrl
            }
        }else{
            return url;
        }


    } catch (e) {
        //console.log(e);
    }

}