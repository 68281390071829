/**
 * 染色体十字切割
 */
function crossCutting(stage, controller){
	const condition = {
		getStatus : () => {
			return this.toolsType === 'cross'
		}
	}
}
export default crossCutting