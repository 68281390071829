import cvCommon from './opencv-common.js'
import {cloneDeep} from 'lodash';

/**
 * 染色体连接
 */
function connect(stage, controller) {
    let startChromos = [], endChromos = [], positionLine, startConnect = false;
    const arrange = this.arrangeController;
    const app = this;
    //拖拽释放进行连接回调
    const dragend = (sChromo, eChromo) => {
        let originPictureJS = this.isShowPrimaryImage ? controller.optBackPicJS : controller.optPicJS
        function checkData() {
            if (originPictureJS && sChromo.chromoDataJS && eChromo.chromoDataJS) {
                let newChromo = getMergeChromo(originPictureJS, sChromo.chromoDataJS, eChromo.chromoDataJS, sChromo);
                if (newChromo) {
                    let maxIndex = Math.max.apply(Math, controller.chromoList.map(item => { return item.index })) + 1;
                    // 更新掉当前的数据
                    let sChromoIndex = controller.chromoList.indexOf(sChromo);
                    sChromoIndex > -1 && controller.chromoList.splice(sChromoIndex, 1);
                    let eChromoIndex = controller.chromoList.indexOf(eChromo);
                    eChromoIndex > -1 && controller.chromoList.splice(eChromoIndex, 1);
                    newChromo.index = maxIndex++;
                    controller.chromoList.push(newChromo)
                    clear();
                    controller.resetKaryo();
                    arrange.reArrange()
                    app.cachePool.save();
                    app.cachePool.record({
                        type : 'connectChromo',
                        message : '连接染色体',
                        data : [sChromo, eChromo],
                        result : newChromo
                    })
                } else{
                    clear();
                }
            } else {
                console.warn("checkData: require some data")
            }
            clear();
        }
        checkData();

        if (!originPictureJS) {
            let startTime = new Date().getTime()
            let url = this.isShowPrimaryImage ? this.karyoInfo.optBackUrl : this.karyoInfo.optUrl;
            cvCommon.getJSMatData(url).then(jsMat => {
                console.log("Connect getJSMatData use time 2:", new Date().getTime() - startTime)
                this.isShowPrimaryImage ? controller.optBackPicJS = jsMat : controller.optPicJS = jsMat;
                originPictureJS = jsMat;
                checkData()
            }).catch((err) => {
                console.error(err)
                clear();
            });
        }
        if (!sChromo.chromoDataJS) {
            let startTime = new Date().getTime()
            cvCommon.getJSDataPromiseAsync(sChromo).then(chromoDataJS => {
                console.log("Connect getJSDataPromiseAsync use time 2:", new Date().getTime() - startTime)
                sChromo.chromoDataJS = chromoDataJS;
                checkData()
            }).catch((err) => {
                console.error(err)
                clear();
            });
        }
        if (!eChromo.chromoDataJS) {
            let startTime = new Date().getTime()
            cvCommon.getJSDataPromiseAsync(eChromo).then(chromoDataJS => {
                console.log("Connect getJSDataPromiseAsync use time 2:", new Date().getTime() - startTime)
                eChromo.chromoDataJS = chromoDataJS;
                checkData()
            }).catch((err) => {
                console.error(err)
                clear();
            });
        }

        function getMergeChromo(originPictureJS, c1Str, c2Str, sChromo) {
            try {
                let mergeChromoFun = window.Module.mergeChromoFun(originPictureJS, c1Str, c2Str, 1);
                if (mergeChromoFun.result == 1) {
                    let chr = mergeChromoFun.chr
                    let newChromo = cloneDeep(cvCommon.getCuttingData(chr, sChromo))
                    newChromo.chromoDataJS = cvCommon._getChromoDataJS(chr)
                    newChromo.chromoId = 26;
                    return newChromo;
                } else {
                    console.warn("mergeChromoFun error:", mergeChromoFun)
                    return false;
                }
            } catch (error) {
                console.error(error)
            }
            return false;
        }
    }
    
    let prevTarget = null;
    let startTarget = null;
    let isStartTargetColorRed = false;
    // 清除连接记录
    const clear = ()=>{
        const choosedShape = controller.getShapeByChromo(this.choosedChromo);
        if(prevTarget && prevTarget !== choosedShape){
            prevTarget.color = prevTarget._color || "#51A749";
            delete prevTarget._color;
        }
        prevTarget = null;
        if(startTarget && startTarget !== choosedShape){
            startTarget.color = startTarget._color || "#51A749";
            delete startTarget._color;
        }
        startTarget = null;
        isStartTargetColorRed = false;
        startConnect = false;
        startChromos = []
        endChromos = []
        stage.removeChild(positionLine);
        positionLine = null;
        // console.log(choosedShape, choosedShape.color);
        stage.objects.filter(a=>a.color==="blue" && a !== choosedShape).forEach(a=>{
            a.color = a.type==="polygon" ? "#51A749" : "#f00";
            delete a._color;
        })
        // stage.draw();
    }
    
    const condition = {
        getStatus : ()=>{
            return (this.shortKeyType === '' && ['general','count','connect'].indexOf(this.toolsType) > -1);
        }
    }
    stage.event.click(condition,()=>{
        clear();
        stage.draw();
    })
    // 连接开始
    stage.event.dragStart(condition,({e, shape}) => {
        startTarget = shape;
        stage.isPointInner(e, true).forEach(item =>{
            item.realType === 'chromo' && startChromos.push(controller.getChromoByShape(item))
        });
        // startConnect = startChromos && startChromos.length > 0;
    })
    
    // 连接过程中
    stage.event.dragMove(condition,({posList,e}) => {
        if (startTarget) {
            let length = posList.length
            const matrix = [[posList[0].x, posList[0].y], [posList[length-1].x, posList[length-1].y]]
            if (positionLine) {
                positionLine.matrix = matrix;
            } else {
                positionLine = stage.graphs.line({
                    color: "red",
                    lineWidth: 2,
                    matrix: matrix,
                    dash: [2, 2]
                })
                stage.addChild(positionLine)
            }
            const shape = stage.isPointInner(e, true, "polygon").filter(a=>a!==startTarget)[0];
            if(shape && shape !== prevTarget && !prevTarget && shape !== startTarget){
                prevTarget = shape;
                prevTarget._color = prevTarget.color;
                prevTarget.color = "blue";
            }
            if(shape && shape !== prevTarget && prevTarget && shape !== startTarget){
                prevTarget.color = prevTarget._color || "#51A749";
                prevTarget = shape;
                prevTarget.color = "blue";
            }
            if(!shape && prevTarget){
                prevTarget.color = prevTarget._color || "#51A749";
                prevTarget = shape;
            }
            if(!isStartTargetColorRed){
                isStartTargetColorRed = true;
                startTarget._color = startTarget.color;
                startTarget.color = "red";
            }
            stage.draw();
        }
    })
    
    const dragEnd = ({e})=>{
        if (!startTarget) {
            clear()
            return;
        }
        stage.isPointInner(e, true).forEach(item =>{
            item.realType === 'chromo' && endChromos.push(controller.getChromoByShape(item))
        });
        let chromotmp = getConnectChromoList(startChromos, endChromos)
        if (chromotmp.length > 1) {
            let startTime = new Date().getTime()
            dragend(chromotmp[0], chromotmp[1])
            console.log("ConnectChromo use time :", new Date().getTime() - startTime)
        } else {
            clear()
        }
        stage.draw();
    }

    // 连接结束
    stage.event.dragEnd(condition,dragEnd)
    
    stage.element.addEventListener("mouseup",(e)=>{
        dragEnd({e})
    })
    stage.element.addEventListener("mouseout",(e)=>{
        clear()
        stage.draw();
    })

    /**
     * 返回可用于连接的第一对染色体
     * @param {*} array1 开始点击中的染色体列表
     * @param {*} array2 结束点击中的染色体列表
     */
    function getConnectChromoList(array1, array2) {
        var resultList = [];
        for (var i = 0; i < array1.length; i++) {
            if (!("index" in array1[i]) || array1[i].index < 0) {
                continue
            }
            resultList.splice(0)
            resultList.push(array1[i])
            for (var j = 0; j < array2.length; j++) {
                if (!("index" in array2[j]) || array2[j].index < 0) {
                    continue
                }
                if (resultList[0].index != array2[j].index) {
                    resultList.push(array2[j])
                    return resultList
                }
            }
        }
        return resultList;
    }

}
export default connect