import chooseArrange from './tools/chooseArrange.js'
import chooseKaryo from './tools/chooseKaryo.js'
import chromoDrag from './tools/chromoDrag.js'
import chromoContrast from './tools/chromoContrast.js'
import count from './tools/count.js'
import arrowKaryo from './tools/arrowKaryo.js'
import arrowArrange from './tools/arrowArrange.js'
import select from './tools/select.js'
import deleteShape from './tools/deleteShape.js'
import easer from './tools/easer.js'
import easerArea from './tools/easerArea.js'
import paint from './tools/paint.js'
import connect from './tools/connect.js'
import {
    cutting,
    recommendCutting
} from './tools/cutting.js'
import crossCutting from './tools/crossCutting.js'
import edgeCutting from './tools/edgeCutting.js'
import rotate from './tools/rotate.js'
import switchStage from './tools/switchStage.js'
import scaleSingleChromo from './tools/scaleSingleChromo.js'
import banding from './tools/banding.js'
const Tools = {
    listener: {
        /**
         * 选中排列图染色体
         */
        chooseArrange,
        /**
         * 选中核型图染色体
         */
        chooseKaryo,
        /**
         * 染色体拖动
         */
        chromoDrag,
        /**
         * 染色体对比
         */
        chromoContrast,
        /**
         * 染色体计数
         */
        count,
        /**
         * 添加箭头与箭头操作
         */
        arrowKaryo,
        arrowArrange,
        /**
         * 选择
         */
        select,
        /**
         * 删除
         */
        deleteShape,
        /**
         * 橡皮擦效果
         */
        easer,
        /**
         * 区域擦除
         */
        easerArea,
        /**
         * 涂抹
         */
        paint,
        /**
         * 染色体连接
         */
        connect,
        /**
         * 染色体切割
         */
        cutting,
        /**
         * 推荐切割线
         */
        recommendCutting,
        /**
         * 十字切割
         */
        crossCutting,
        /**
         * 边缘切割
         */
        edgeCutting,
        /**
         * 旋转
         */
        rotate,
        /**
         * 切换视图
         */
        switchStage,
        /**
         * 缩放单个染色体
         */
        scaleSingleChromo,
        /**
         * 定带
         */
        banding
    },
    /**
     * 舞台拖动
     * @param {Object} stage
     */
    stageDrag(stage) {
        let isMouseOnBlank = false;
        let tx = 0,
            ty = 0,
            startPos;
        const condition = {
            getStatus: () => {
                const bool1 = ['arrow', 'eraser', 'area', 'paint', 'delete'].indexOf(this.toolsType) < 0;
                const bool2 = ['e'].indexOf(this.shortKeyType) < 0;
                return bool1 && bool2
            }
        }
        stage.event.dragStart(condition, ({
            e
        }) => {
            const shape = stage.isPointInner(e, false);
            if (!shape && stage.scale !== 1) {
                isMouseOnBlank = true;
                tx = stage.transX;
                ty = stage.transY;
                // startPos = {x: e.offsetX, y: e.offsetY};
                startPos = stage.getPosOnFullScreen(e);

                stage.element.className = "draging"
            }
        })
        stage.event.dragMove(condition, ({
            e
        }) => {
            if (isMouseOnBlank && stage.scale !== 1) {
                // const pos = {x: e.offsetX, y: e.offsetY};
                const pos = stage.getPosOnFullScreen(e);
                const mx = pos.x - startPos.x + tx;
                const my = pos.y - startPos.y + ty;
                stage.translateStage(mx, my);
                stage.draw();
            }
        })
        stage.event.dragEnd(condition, ({
            e
        }) => {
            tx = 0;
            ty = 0;
            startPos = null;
            isMouseOnBlank = false;
            stage.element.className === "draging" && (stage.element.className = "")
        })
        stage.element.addEventListener("mouseup", (e) => {
            tx = 0;
            ty = 0;
            startPos = null;
            isMouseOnBlank = false;
            stage.element.className === "draging" && (stage.element.className = "")
            stage.draw();
        })
    },
    /**
     * 舞台缩放
     * @param {Object} stage
     */
    stageScale(stage) {
        stage.event.mousewheel(({
            e,
            shapes
        }) => {
            stage.setScale(stage.scale - 10 / e.deltaY * stage.offset.zoom, e.offsetX, e.offsetY)
        })
    },
    /**
     * 舞台缩放(按钮操作)
     * @param {Object} stage
     */
    stageBtnScale(stage, operation) {
        if (operation === 'small') {
            stage.setScale(stage.scale - 0.5, stage.offset.realWidth / 2, stage.offset.realHeight / 2)
        } else if (operation === 'enlarge') {
            stage.setScale(stage.scale + 0.5, stage.offset.realWidth / 2, stage.offset.realHeight / 2)
        } else {
            stage.setScale(1, stage.offset.realWidth / 2, stage.offset.realHeight / 2)
        }
    }
}
export default Tools;