import Vue from 'vue'
import Router from 'vue-router'

// const HelloWorld = resolve => require(['./../components/HelloWorld.vue'], resolve)
const Index = resolve => require(['./../components/index/index.vue'], resolve)
const Login = resolve => require(['./../components/index/login.vue'], resolve)
const Autovision = resolve => require(['./../components/autovision/autovision.vue'], resolve)
const PersonalSettings = resolve => require(['../components/personalSettings/personalSettings.vue'], resolve)
const Analysissystem = resolve => require(['./../components/analysissystem/analysissystem.vue'], resolve)
const Statement = resolve => require(['./../securityProtocol/statement.vue'], resolve)
const Agreement = resolve => require(['./../securityProtocol/agreement.vue'], resolve)
const PaymentPage = resolve => require(['./../components/personalSettings/modules/paymentPage.vue'], resolve)
const SelfDefineTemplate = resolve => require(['./../components/selfDefineTemplate/selfDefineTemplate.vue'], resolve)

const checkhtml = resolve => require(['./../components/checkhtml.vue'], resolve)
const main = resolve => require(['./../components/main.vue'], resolve)
const invitation = resolve => require(['./../components/invitation.vue'], resolve)
const complexity = resolve => require(['./../components/complexity.vue'], resolve)
Vue.use(Router)
Router.prototype.go = function () {
    this.isBack = true
    window.history.go(-1)
}
const routes = [{
        path: '/',
        name: '中国人类遗传在线诊断咨询中心',
        component: Index
    },
    {
    	path: '/login',
    	name: '中国人类遗传在线诊断咨询中心',
    	component: Login
    },
    {
        path: '/autovision',
        name: 'online autovision 分析工具',
        component: Autovision,
        meta:{
            keepAlive: false //不需要被缓存的组件
        }
    },
    {
        path: '/checkhtml',
        name: 'checkhtml',
        component: checkhtml
    },
    {
        path: '/personalSettings/',
        name: '个人设置页',
        component: PersonalSettings
    },
    {
        path: '/analysissystem',
        name: '分析系统',
        component: Analysissystem,
        component: main
    },
    {
        path: '/statement',
        name: '隐私声明',
        component: Statement
    },
    {
        path: '/agreement',
        name: '服务协议',
        component: Agreement
    },
    {
        path: '/paymentPage',
        name: '支付页面',
        component: PaymentPage
    },
    {
        path: '/selfDefineTemplate',
        name: '自定义报告模板',
        component: SelfDefineTemplate
    },
    {
        path: '/invitation',
        name: '团队邀请',
        component: invitation
    },
	{
	    path: '/complexity',
	    name: '',
	    component: complexity
	},
]
// 跳回自己路由不会报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default new Router({
    // mode: 'history',
    routes: routes.map(item => {
        item.beforeEnter = (to, from, next) => {
            //修改 浏览器  默认 的头部内容
            document.title = to.name;
            next();
        }
        return item;
    }),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            if (from.meta.keepAlive) {
                from.meta.savedPosition = document.body.scrollTop
            }
            return {
                x: 0,
                y: to.meta.savedPosition || 0
            }
        }
    }
})