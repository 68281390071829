import cvCommon from "./tools/opencv-common.js";
const btnFun = {
    methods: {
        //辅助标记开关
        hidePolygon() {
            this.assistantMarker = !this.assistantMarker;
            this.polygonChromo();
			this.polygonChromo();
        },
        polygonChromo() {
            this.karyoController.stage.objects.forEach(shape => {
                if (
                    shape &&
                    (shape.realType === "chromo" || shape.type === "text")
                ) {
                    shape.hide = !this.assistantMarker;
                    if (!this.assistantMarker) {
                        shape._color = shape.color;
                        shape.color = "transparent";
                        // shape.isHidePolygon = this.assistantMarker;
                    } else {
                        shape.color =
                            shape._color ||
                            (shape.type === "text" ? "#f00" : "#51A749");
                        delete shape._color;
                        // delete shape.isHidePolygon;
                    }
                }
				if(shape && shape.type === "point" && this.globalMode === 'counter'){
					shape.hide = !this.assistantMarker;
				}
            });
            this.karyoController.chromoMap.forEach(item => {
                if (item.chromo === this.choosedChromo) {
                    item.shape && (item.shape.color = "#f00");
                }
            });
            this.karyoController.stage.draw();
        },
        //删除染色体
        deleteChromo() {
            if (this.choosedChromo) {
                const index = this.chromoList.indexOf(this.choosedChromo);
                const chromo =
                    index > -1 ? this.chromoList.splice(index, 1) : null;
                this.karyoController.resetKaryo();
                this.arrangeController.reArrange();
                this.cachePool.save();
                this.cachePool.record({
                    type: "deleteChromo",
                    message: this.$t("new_interaction.Deletion_of_chromosome"),
                    data: chromo,
                    result: null
                });
                if (index > -1) {
                    const chromoMap = this.karyoController.chromoMap.filter(
                        a => a.chromo === chromo
                    )[0];
                    let delArr = window.chromoMap;
                    const cindex = delArr
                        .map(item => item.chromo)
                        .indexOf(this.choosedChromo);
                    cindex === -1 ? delArr.push(chromoMap) : "";
                    window.chromoMap = delArr;
                }
            }
        },
        /**
         * 切换核型图
         */
        changeKaryo(isNext, isInit) {
            if (
                this.isPageLoading ||
                this.ajaxLoading ||
                this.karyoList.length === 1
            ) {
                return;
            }
            this.isPageLoading = true;
            let index = this.karyoList.map(a => a.id).indexOf(this.karyoId);
            if (isNext) {
                index += index < this.karyoList.length - 1 ? 1 : -index;
            } else {
                index -= index > 0 ? 1 : 1 - this.karyoList.length;
            }
            clearTimeout(this.heartTimer);
            this.heartTimer = null;
            let id = this.karyoList[index].id;
            this.switchKaryoHandle(id, isInit);
        },
        /**
         * 置顶与取消置顶
         */
        updateIsTop() {
            var isTop = Number(!this.karyoInfo.isTop);
            this.$api
                .updateIsTop({
                    karyoId: this.karyoId,
                    isTop
                })
                .then(res => {
                    if (res.code == 200) {
                        this.karyoInfo.isTop = isTop;
                        this.$store._mutations.updateAnalysKaryoInfo(
                            this.karyoInfo,
                            "isTop"
                        );
                        this.karyoList.forEach(item => {
                            item.id === this.karyoId && (item.isTop = isTop);
                        });
                        this.karyoTopList = this.karyoList.filter(a => a.isTop);
                        this.karyoNormalList = this.karyoList.filter(
                            a => !a.isTop
                        );
                        const info = JSON.parse(
                            this.cachePool.initData.karyoInfo
                        );
                        info.isTop = isTop;
                        this.cachePool.initData.karyoInfo = JSON.stringify(
                            info
                        );
                        this.$forceUpdate();
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.msg
                        });
                    }
                });
        },
        /**
         * 染色体放大
         */
        magnifyChromo() {
            if (this.isArrangeOnLeft) {
                if (this.choosedChromo) {
                    this.scaleChromoImage(0.1);
                } else {
                    this.arrangeController.scaleChromos(
                        this.arrangeController.chromoScale + 0.1
                    );
                }
                this.arrangeController.reCreateStandChromo();
            } else {
                const stage = this.karyoController.stage;
                stage.setScale(
                    stage.scale + 0.5,
                    stage.offset.realWidth / 2,
                    stage.offset.realHeight / 2
                );
            }
        },
        /**
         * 染色体缩小
         */
        narrowChromo() {
            if (this.isArrangeOnLeft) {
                if (this.choosedChromo) {
                    this.scaleChromoImage(-0.1);
                } else {
                    this.arrangeController.scaleChromos(
                        this.arrangeController.chromoScale - 0.1
                    );
                }
                this.arrangeController.reCreateStandChromo();
            } else {
                const stage = this.karyoController.stage;
                stage.setScale(
                    stage.scale - 0.5,
                    stage.offset.realWidth / 2,
                    stage.offset.realHeight / 2
                );
            }
        },
        /**
         * 获取每行的最大高度
         * @param {*} chromo
         */
        getMaxHeight(chromo, controller) {
            let groupNum = 0;
            let target;
            Object.keys(controller.group).forEach(key => {
                const group = controller.group[key];
                group.list.forEach(item => {
                    if (item.chromo === chromo) {
                        groupNum = parseInt(key);
                        target = item;
                    }
                });
            });
            let row = 0;
            Object.keys(controller.groupRowRange).forEach(key => {
                const item = controller.groupRowRange[key];
                if (item.min <= groupNum && groupNum <= item.max) {
                    row = parseInt(key) - 1;
                }
            });
            return {
                target,
                maxHeight: controller.rowsHeight[row]
            };
        },
        scaleChromoImage(delta) {
            const controller = this.arrangeController;
            const chromo = this.choosedChromo;
            const item = this.originOptUrl[chromo.index];
            if (!item) return;
            const { maxHeight, target } = this.getMaxHeight(chromo, controller);
            const min = controller.minChromoScale / controller.chromoScale;
            const max = maxHeight / controller.maxChromoScale / item.img.height;
            const scale = Math.max(min, Math.min(max, item.scale + delta));
            console.warn(item.scale, scale, min, max);
            if (scale === item.scale) {
                return;
            }
            item.scale = scale;
            const element = document.createElement("canvas");
            const canvas = element.getContext("2d");
            const width = item.img.width * item.scale;
            const height = item.img.height * item.scale;
            element.width = width;
            element.height = height;
            canvas.drawImage(item.img, 0, 0, width, height);
            const base64 = element.toDataURL("image/png", 1.0);
            const image = new Image();
            image.width = width;
            image.height = height;
            image.src = base64;
            image.onload = () => {
                chromo.justUrl = base64;
                target.image = image;
                this.cachePool.checkBase64(this.chromoList);
                controller.reCreateChromoImages();
                controller.reCreateStandChromo();
            };
        },
        return2OriginalCondition() {
            this.$confirm(
                this.$t("new_interaction.message"),
                this.$t("popup.warning.remind"),
                {
                    type: "warning",
                    distinguishCancelAndClose: true,
                    customClass: "save-confirm-box",
                    closeOnClickModal: false
                }
            )
                .then(() => {
                    this.returnToOriginal();
                })
                .catch(action => {});
        },
        returnToOriginal() {
            this.loading = this.$loading({
                background: "rgba(255,255,255,.3)",
                customClass: "autovision-loading",
                text: this.$t("new_interaction.message_title")
            });
            this.$api
                .autoSegment({
                    patientId: this.caseId,
                    ids: this.karyoId,
                    type: 2
                })
                .then(res => {
                    if (res.code == 200) {
                        this.beginSegmentTime = +new Date();
                        this.checkSegmentProcess();
                    } else if(res.code != 122){
                        this.loading.close();
                        this.$message({
                            type: "warning",
                            message: res.message
                        });
                    }
                });
        },
        checkSegmentProcess() {
            this.$api
                .queryKaryoListByPatient({
                    patientId: this.caseId
                })
                .then(res => {
                    if (res.code == 200 && !res.data.length) {
                        this.queryStatisByKaryoIds();
                    } else {
                        if (+new Date() - this.beginSegmentTime < 30000) {
                            setTimeout(() => {
                                this.checkSegmentProcess();
                            }, 2000);
                        } else {
                            this.loading.close();
                            this.$message({
                                type: "warning",
                                message: this.$t("new_interaction.message_err")
                            });
                        }
                    }
                });
        },
        queryStatisByKaryoIds() {
            this.$api
                .queryBuKaryoIds({
                    karyoIds: this.karyoId
                })
                .then(res => {
                    this.loading.close();
                    if (res.code == 200) {
                        this.karyoList.forEach(a => {
                            if (a.id === this.karyoId) {
                                a = res.data[0];
                            }
                        });
                        this.reloadKaryoInfo();
                    } else {
                        this.$message({
                            type: "warning",
                            message: res.message
                        });
                    }
                });
        },
        reloadKaryoInfo(id) {
            this.uploadSureCount();
            this.queryOss(id || this.karyoId, () => {
                this.refreshKaryoList();

                this.karyoController.reset(
                    this.chromoList,
                    this.karyoInfo,
                    !this.karyoInfo.originalArrowLines
                        ? []
                        : JSON.parse(this.karyoInfo.originalArrowLines)
                );
                this.arrangeController.reset(
                    this.chromoList,
                    this.karyoInfo,
                    !this.karyoInfo.arrangeArrowLines
                        ? []
                        : JSON.parse(this.karyoInfo.arrangeArrowLines)
                );

                // this.karyoController.chromoList = this.chromoList;
                // this.karyoController.unzipChromoList();
                // this.karyoController.resetKaryo();
                // this.arrangeController.chromoList = this.chromoList;
                // this.arrangeController.reArrange()
                this.cachePool.reset();
                this.cachePool.save();
                this.$store._mutations.updateAnalysKaryoInfo(
                    this.karyoInfo,
                    "color"
                );
                this.$store._mutations.updateAnalysKaryoInfo(
                    this.karyoInfo,
                    "isTop"
                );
                this.$store._mutations.updateAnalysKaryoInfo(
                    this.karyoInfo,
                    "isTypeSure"
                );
                this.$store._mutations.updateAnalysKaryoInfo(
                    this.karyoInfo,
                    "isSingleSure"
                );
                this.$store._mutations.updateAnalysKaryoInfo(
                    this.karyoInfo,
                    "arrayUrl"
                );
                this.$store._mutations.updateAnalysKaryoInfo(
                    this.karyoInfo,
                    "singleNum"
                );
                this.$store._mutations.updateAnalysKaryoInfo(
                    Object.assign(
                        {
                            breviary: 1
                        },
                        this.karyoInfo
                    ),
                    "breviary"
                );
                this.$store._mutations.updateAnalysKaryoInfo(
                    Object.assign(
                        {
                            arrange: 2
                        },
                        this.karyoInfo
                    ),
                    "arrange"
                );
            });
        },
        refreshKaryoList() {
            this.karyoList.forEach(a => {
                if (a.id === this.karyoId) {
                    a = Object.assign(a, this.karyoInfo);
                    const b = Object.assign(a, this.karyoInfo, {
                        showLoading: 1,
                        arrange: 1,
                        breviary: 2
                    });
                    this.$store._mutations.updateAnalysKaryoInfo(b);
                }
            });
            this.karyoTopList = this.karyoList.filter(a => a.isTop);
            this.karyoNormalList = this.karyoList.filter(a => !a.isTop);
        },
        /**
         * 选中染色体
         * @param {*} id
         */
        chooseChromoByImage(index) {
            const chromo = this.chromoList.filter(a => {
                return a.index === index;
            })[0];
            if (chromo) {
                this.choosedChromo = chromo;
                this.chromoScreenCenter(chromo);
            }
        },
        /**
         * 在染色体中添加计数点
         * @param {*} id
         */
        addPointToChromo(index) {
            const chromo = this.chromoList.filter(a => {
                return a.index === index;
            })[0];
            if (chromo) {
                const countPoints =
                    typeof chromo.countPoints === "string"
                        ? JSON.parse(chromo.countPoints)
                        : chromo.countPoints;
                let points =
                    typeof countPoints == "string"
                        ? JSON.parse(countPoints)
                        : countPoints;
                if (points.length >= 6) return;
                const { x, y } = countPoints[0];
                const point = {
                    x: parseInt(x),
                    y: parseInt(y)
                };
                if (point) {
                    const x = parseInt(Math.random() * 10 + point.x - 5);
                    const y = parseInt(Math.random() * 10 + point.y - 5);
                    const p = {
                        x,
                        y
                    };
                    countPoints.push(p);
                    chromo.countPoints = JSON.stringify(countPoints);
                }
                this.$refs.allTheKaryos.addDelPoints(index, countPoints);
                this.karyoController.karyoInfo.singleNum =
                    parseInt(this.karyoController.karyoInfo.singleNum) + 1;
                this.karyoController.resetKaryo();
                this.chromoScreenCenter(chromo);

                this.cachePool.save();
            }
        },
        deletePointFromChromo(index, item) {
            const chromo = this.chromoList.filter(a => {
                return a.index === index;
            })[0];
            if (chromo) {
                const countPoints =
                    typeof chromo.countPoints === "string"
                        ? JSON.parse(chromo.countPoints)
                        : chromo.countPoints;
                let point = chromo.countPoints;
                countPoints.pop();
                let length = countPoints.length;
                chromo.countPoints = JSON.stringify(countPoints);
                if (length) {
                    this.cachePool.save();
                    this.$refs.allTheKaryos.addDelPoints(index, countPoints);
                    this.karyoController.karyoInfo.singleNum =
                        parseInt(this.karyoController.karyoInfo.singleNum) - 1;
                } else {
                    const _index = this.chromoList.indexOf(chromo);
                    _index > -1 && this.chromoList.splice(_index, 1);
                    this.$refs.allTheKaryos.baseDataChange(this.chromoList);

                    const chromoMap = this.karyoController.chromoMap.filter(
                        a => a.chromo === chromo
                    )[0];
                    chromoMap.chromo.countPoints = point;
                    let delArr = window.chromoMap;
                    const cindex = delArr
                        .map(item => item.chromo)
                        .indexOf(chromo);
                    cindex === -1 ? delArr.push(chromoMap) : "";
                    window.chromoMap = delArr;
                }
                this.karyoController.resetKaryo();
                this.chromoScreenCenter(chromo);
            }
        },
        /**
         * 染色体放大并在屏幕中居中显示
         * @param {*} chromo
         */
        chromoScreenCenter(chromo) {
            let pos;
            if (chromo._cimgBoundaryPointsList) {
                pos = chromo._cimgBoundaryPointsList;
            } else {
                pos = chromo.cimgBoundaryPointsList.split(",").map(p => {
                    const [x, y] = p.split(".");
                    return {
                        x: parseInt(x),
                        y: parseInt(y)
                    };
                });
            }
            const minx = Math.min.apply(
                null,
                pos.map(a => a.x)
            );
            const maxx = Math.max.apply(
                null,
                pos.map(a => a.x)
            );
            const miny = Math.min.apply(
                null,
                pos.map(a => a.y)
            );
            const maxy = Math.max.apply(
                null,
                pos.map(a => a.y)
            );
            let box;
            if (chromo._cimgBoundingbox) {
                box = chromo._cimgBoundingbox;
            } else {
                const [y, x, h, w] = chromo.cimgBoundingbox
                    .split(",")
                    .map(a => parseInt(a));
                box = {
                    y,
                    x,
                    w,
                    h
                };
            }
            const cx = box.x + (maxx - minx) / 2;
            const cy = box.y + (maxy - miny) / 2;
            // console.log(cx,cy,box.x,minx,maxx, box.y,miny,maxy,chromo.countPoints);
            this.karyoController.stage.objects
                .filter(a => a.type === "polygon")
                .filter(a => {
                    a.color = !this.assistantMarker ? "transparent" : "#51A749";
                });
            const shape = this.karyoController.getShapeByChromo(chromo);
            console.log(shape);
            if (shape && shape.color) {
                shape._color = shape.color;
                shape.color = "#f00";
            }
            this.karyoController.stage.scaleStageByCenterPoint(cx, cy);
        },
        setKaryoColor(index, color) {
            const chromo = this.chromoList.filter(a => {
                return a.index === index;
            })[0];
            const shape = this.karyoController.getShapeByChromo(chromo);
            if (shape) {
                if (color) {
                    shape._color = shape.color;
                    shape.color = color;
                } else {
                    shape.color = shape._color;
                }
                this.karyoController.stage.draw();
            }
        },
        bandingSelect(v) {
            // this.standardInfo = require(`./json/standard-${v}`).standard;
            //当前为标准染色体
            if (this.standardVisible) {
                this.requireStandard(v);
                this.bandingStandchromoModal = v;
            } else if (this.arrangeController.banding.standchromoModal !== v) {
                //定带
                this.arrangeController.banding.standchromoModal = v;
                this.arrangeController.banding.standchromoInfo = this.arrangeController.banding.standchromoInfo.map(
                    item => {
                        item.key = [];
                        return item;
                    }
                );
                this.arrangeController.reCreateStandChromo();
                this.cachePool.save();
                this.bandingSelectVisible = false;
            }

            console.warn("???", v, "!!!");
        },
        /**
         * 当染色体数量发生变化时重新生成染色体jsmat对象列表
         */
        reCreateJsMat() {
            try {
                console.log("reCreateJsMat", new Date().getTime());
                // this.VectorJSMat = window.Module.getVectorJSchromo();
                let VectorJSMat = window.Module.getVectorJSchromo();
                this.VectorJSMatFinished = !this.chromoList.length;
                let count = 0,
                    total = this.chromoList.length;
                this.chromoList.forEach((chromo, index) => {
                    if (chromo.chromoDataJS) {
                        VectorJSMat.push_back(chromo.chromoDataJS);
                        count++;
                        if (count >= total) {
                            this.VectorJSMatFinished = true;
                            this.VectorJSMat = VectorJSMat;
							console.log("reCreateJsMat", new Date().getTime());
                        }
                    } else {
                        cvCommon
                            .getJSDataPromiseAsync(chromo)
                            .then(chromoDataJS => {
                                VectorJSMat.push_back(chromoDataJS);
                                chromo.chromoDataJS = chromoDataJS;
                                count++;
                                if (count >= total) {
                                    this.VectorJSMatFinished = true;
                                    this.VectorJSMat = VectorJSMat;
									console.log("reCreateJsMat", new Date().getTime());
                                }
                            });
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        getImageByChromo(chromo) {
            if (!this.arrangeController.initFinished || !chromo) {
                return false;
            }
            const group = this.arrangeController.group;
            let target = null;
            Object.keys(group).forEach(key => {
                group[key].list.forEach(item => {
                    if (item.chromo === chromo) {
                        target = item.image;
                    }
                });
            });
            return target;

            const list = this.arrangeController.group[
                chromo.chromoId
            ].list.flat(Infinity);
            if (!list.length) {
                return null;
            }
            const item = list.filter(
                a =>
                    a.chromo.index === chromo.index && a.chromo.id === chromo.id
            )[0];
            return item && item.image;
        }
    }
};
export default btnFun;
