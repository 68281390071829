import { genLineMaskAndCut4Arrange, genLineMaskAndCut, getLineCutChromos, getTargetChromoByCut } from "./cutting.js"
import {getRotateObj} from "./rotate"
/**
 * 区域擦除
 */
function easerArea(stage, controller) {
	const app = this;
    const arrange = this.arrangeController;
    const karyo = this.karyoController;
    // 标记当前切割在排列图还是中期分裂相上
    const isArrange = controller.name === 'arrange'
    let aeraMatrix = []
	let isDragMove = false;
    const condition = {
        getStatus: () => {
            return this.toolsType === 'area' || ((this.toolsType === 'general' || this.toolsType === 'count') && (this.shortKeyType === 'e' || isDragMove))
        }
    }

    let lineShape;

    // move过程划线
    stage.event.dragMove(condition, ({ posList }) => {
		isDragMove = true;
        let matrix = posList.map((pos) => {
            return [pos.x, pos.y];
        })
        if (lineShape) {
            lineShape.matrix = matrix;
        } else {
            lineShape = stage.graphs.easerArea({
                x: 0,
                y: 0,
                borderColor: "rgba(34, 177, 76)",
                color: "rgba(255, 255, 255, .7)",
                dash: [3, 3],
                lineWidth: 2,
                zindex: 999,
                matrix: matrix
            })
            stage.addChild(lineShape);
        }
        stage.draw();
    })
    let prevTargetChromo
    // end执行切割
    stage.event.dragEnd(condition, ({ posList, posReal }) => {
        // console.log("右键 dragEnd");
		isDragMove = false;
        // 删除线条图形
        stage.removeChild(lineShape);
        lineShape = null;
        stage.draw();

        posList.push(posList[0])
        aeraMatrix = posList
        const shapeArr = stage.getObjectsByPointList(posList, 'polygon').filter(obj => { return obj.realType === 'chromo' });
        let targetChromo = getTargetChromoByCut(controller, shapeArr, this.choosedChromo)
        if(!targetChromo || prevTargetChromo === targetChromo){
            return;
        }
        prevTargetChromo = targetChromo
        if (isArrange) {
            genLineMaskAndCut4Arrange(targetChromo, shapeArr[shapeArr.length - 1], posList).then(([chromoDataJS, allLinesJS, matrix]) => {
                aeraMatrix = matrix
                doLineCut(chromoDataJS, allLinesJS, targetChromo)
                prevTargetChromo = null;
            }).catch((err) => {
                console.error(err)
            });
        } else {
            genLineMaskAndCut(targetChromo, posReal).then(([chromoDataJS, allLinesJS]) => {
                aeraMatrix = posReal;
                doLineCut(chromoDataJS, allLinesJS, targetChromo)
                prevTargetChromo = null;
            }).catch((err) => {
                console.error(err)
            });
        }

        function doLineCut(chromoDataJS, allLinesJS, srcChromo) {
            let cuttingChromos = getLineCutChromos(chromoDataJS, allLinesJS, srcChromo)
            if (!cuttingChromos || cuttingChromos.length <= 0 || cuttingChromos.length > 2) {
                console.warn("划线切割失败")
                return;
            }

            let newChromo = null
            if (cuttingChromos.length == 1) {
                newChromo = cuttingChromos[0]
            } else if (cuttingChromos.length == 2) {
                let chromo1Center = [cuttingChromos[0].countPoints[0].x, cuttingChromos[0].countPoints[0].y]
                let chromo2Center = [cuttingChromos[1].countPoints[0].x, cuttingChromos[1].countPoints[0].y]
                let isInChromo1 = isPointInPolygon(chromo1Center, aeraMatrix)
                let isInChromo2 = isPointInPolygon(chromo2Center, aeraMatrix)

                if (!isInChromo1 && isInChromo2) {
                    newChromo = cuttingChromos[0]
                } else if (isInChromo1 && !isInChromo2) {
                    newChromo = cuttingChromos[1]
                }
            }
            console.warn(newChromo);
            if (!newChromo) {
                return;
            }
            let sChromoIndex = controller.chromoList.indexOf(srcChromo);

            newChromo.chromoId = srcChromo.chromoId;
            newChromo.id = srcChromo.id;
            if (newChromo.index !== srcChromo.index) {
                newChromo.index = srcChromo.index;
            }

            let angle_old = srcChromo.cimgOrientation > 0
                ? (90 - srcChromo.cimgOrientation)
                : (270 - srcChromo.cimgOrientation)
            let angle_new = newChromo.cimgOrientation > 0 ? (90 - newChromo.cimgOrientation)
                : (270 - newChromo.cimgOrientation)
            // 记录变化角度还原，以免切割后发生角度轻微旋转
            console.warn(angle_old, angle_new)
            let changeAngle = angle_new - angle_old
            let degree = srcChromo.imgHandledRotatedDegree + changeAngle;
            newChromo.imgHandledRotatedDegree = degree % 360
            getRotateObj(newChromo.invertedUrl, degree).then(res => {
                // if (newChromo.imgHandledFlip) {
                    // TODO 经过镜像旋转后，区域擦除，会引起第二次擦除路径计算有问题
                    // cvCommon.onRotaterMirror(res).then(rotateBase64 => {
                    //     doExecEndAndSave(rotateBase64)
                    // })
                // } else {
                    doExecEndAndSave(res)
                // }

                function doExecEndAndSave(rotateBase64) {
                    var _img = new Image();
                    _img.src = rotateBase64;
                    _img.onload = function () {
                        newChromo.justUrl = rotateBase64;
                        newChromo.image = _img;
                        // 如果区域擦除后，不做镜像旋转归位，则需要修改默认没有旋转
                        newChromo.imgHandledFlip = 0;
                        sChromoIndex > -1 && controller.chromoList.splice(sChromoIndex, 1, newChromo);
                        srcChromo = null

                        karyo.resetKaryo();
                        arrange.reArrange()
                        app.cachePool.save();
                        app.cachePool.record({
                            type : 'area',
                            message : '区域擦除',
                            data : cuttingChromos,
                            result : newChromo
                        })
                    }
                }
            })
        }
    })
}

/**
 * 判断点是否在多边形内部
 * @param {点} checkPoint 
 * @param {多边形边缘点} polygonPoints 
 */
function isPointInPolygon(checkPoint, polygonPoints) {
    var counter = 0;
    var i;
    var xinters;
    var p1, p2;
    var pointCount = polygonPoints.length;
    p1 = polygonPoints[0];
    if (!p1.x) {
        p1 = point(p1[0], p1[1])
    }

    for (i = 1; i <= pointCount; i++) {
        p2 = polygonPoints[i % pointCount];
        if (!p2.x) {
            p2 = point(p2[0], p2[1])
        }
        if (checkPoint[0] > Math.min(p1.x, p2.x) && checkPoint[0] <= Math.max(p1.x, p2.x)) {
            if (checkPoint[1] <= Math.max(p1.y, p2.y)) {
                if (p1.x != p2.x) {
                    xinters = (checkPoint[0] - p1.x) * (p2.y - p1.y) / (p2.x - p1.x) + p1.y;
                    if (p1.y == p2.y || checkPoint[1] <= xinters) {
                        counter++;
                    }
                }
            }
        }
        p1 = p2;
    }
    if (counter % 2 == 0) {
        return false;
    } else {
        return true;
    }
}
function point(x, y) {
    return { x: x, y: y };
}


export default easerArea