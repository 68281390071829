<template>
  <div class="audio__wrap">
    <audio controls :src="src" ref="audioPlayer" style="display:none" @ended="overAudio"></audio>
    <div class="self__audio">
      <div class="audio__trigger" @click="playAudioHandler" :style="{width: duration*10 +'px'}">
        <div :class="{'wifi-symbol':true,'wifi-symbol--avtive':isPlaying}">
          <div class="wifi-circle first"></div>
          <div class="wifi-circle second"></div>
          <div class="wifi-circle third"></div>
        </div>
      </div>
      <div class="audio__duration">{{duration}}"</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AudioPlay",
  data() {
    return {
      isPlaying: false,
      duration: ""
    };
  },
  props: {
    src: {
      type: String,
      required: true
    }
  },
  created() {
  },
  methods: {
    playAudioHandler() {
      this.isPlaying = !this.isPlaying;
      const player = this.$refs.audioPlayer;
      if (this.isPlaying) {
        player.load();
        player.play();
      } else {
        player.pause();
      }
    },
    overAudio() {
      this.isPlaying = false;
    }
  },
  mounted() {
    const player = this.$refs.audioPlayer;
    player.load();
    const vm = this;
    player.oncanplay = function () {
      vm.duration = Math.ceil(player.duration);
    };
  }
};
</script>
<style lang="scss" scoped>
.audio__wrap {
  .self__audio {
    .audio__duration {
      display: inline-block;
      line-height: 32px;
      height: 32px;
      padding-left: 6px;
      color: #888888;
    }
    .audio__trigger {
      cursor: pointer;
      vertical-align: top;
      display: inline-block;
      line-height: 32px;
      height: 32px;
      min-width: 40px;
      max-width: 400px;
      background-color: #fff;
      border-radius: 4px;
      position: relative;
      transform: rotate(180deg);
      border: 1px solid #ddd;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
      .wifi-symbol {
        position: absolute;
        right: 4px;
        top: -8px;
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        overflow: hidden;
        transform: rotate(-45deg) scale(0.5);
        .wifi-circle {
          border: 5px solid #999999;
          border-radius: 50%;
          position: absolute;
        }

        .first {
          width: 5px;
          height: 5px;
          background: #cccccc;
          top: 45px;
          left: 45px;
        }
        .second {
          width: 25px;
          height: 25px;
          top: 35px;
          left: 35px;
        }
        .third {
          width: 40px;
          height: 40px;
          top: 25px;
          left: 25px;
        }
      }
      .wifi-symbol--avtive {
        .second {
          animation: bounce 1s infinite 0.2s;
        }
        .third {
          animation: bounce 1s infinite 0.4s;
        }
      }
    }
    @keyframes bounce {
      0% {
        opacity: 0; /*初始状态 透明度为0*/
      }
      100% {
        opacity: 1; /*结尾状态 透明度为1*/
      }
    }
  }
}
</style>
