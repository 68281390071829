<template>
    <div class="originalTemplateBox">
        <div v-for="item in aloneReportInfo" :key="item.reportId">
            <div class="diagnosisReporCenter" v-if="item.patientStatus == 0 || item.patientStatus == 1">
                <div class="hospitalName">
                    {{ item.hospitalName }}
                </div>
                <div class="reportText">染色体检查报告单</div>
                <div class="reportInformation">
                    <!-- 1 -->
                    <div>
                        <div>编号：{{ item.patientName }}</div>
                        <div>姓名：{{ item.name }}</div>
                    </div>
                    <!-- 2 -->
                    <div>
                        <div>性别：{{ item.gender }}</div>
                        <div>年龄：{{ item.age }}</div>
                    </div>
                    <!-- 3 -->
                    <div>
                        <div>
                            <div>样本类型：{{ item.sampleType }}</div>
                            <div>送检医生：{{ item.sendName }}</div>
                            <div>标本采集日期：{{ item.collectDateStr }}</div>
                            <div>标本接收日期：{{ item.receiveDateStr }}</div>
                            <div>临床描述：{{ item.descBase }}</div>
                        </div>
                        <div>
                            <img :src="item.optUrl" alt="" width="200px" height="150px" />
                        </div>
                    </div>
                    <!-- 4 -->
                    <div>
                        <img :src="item.arrayUrl" alt="" width="400px" height="350px" />
                    </div>
                    <!-- 5 -->
                    <div>染色体检查结果：{{ item.result }}</div>
                    <!-- 6 -->
                    <div>
                        <div>阅片人：</div>
                        <div>核型审定者：</div>
                        <div>医师签名：</div>
                        <div>报告日期：</div>
                    </div>
                    <!-- 7 -->
                    <div></div>
                    <!-- 8 -->
                    <div>此结果仅对送检样本负责</div>
                    <!-- 9 -->
                    <div>
                        该检查为 G 显带 400-500
                        条带，不能排除微小染色体异常或基因突变所致疾病，结果仅供医生参考。
                    </div>
                </div>
            </div>
            <div class="rabnormalReporCente" v-if="item.patientStatus == 2 || item.patientStatus == 3">
                <div class="hospitalName">
                    {{ item.hospitalName }}
                </div>
                <div class="reportText">染色体检查报告单</div>
                <div class="rabnormalReportInformation">
                    <!-- 1 -->
                    <div>
                        <div>编号：{{ item.patientName }}</div>
                        <div>姓名：{{ item.name }}</div>
                        <div>性别：{{ item.gender }}</div>
                        <div>年龄：{{ item.age }}</div>
                    </div>
                    <!-- 2 -->
                    <div>
                        <img :src="item.optUrl" alt="" width="200px" height="150px" />
                    </div>
                    <!-- 3 -->
                    <div>
                        <img :src="item.arrayUrl" alt="" width="500px" height="400px" />
                    </div>
                    <!-- 4 -->
                    <div>
                        <div>Case：{{ item.patientName }}</div>
                        <div>Slide：{{ item.glassName }}</div>
                        <div>Cell：{{ item.cellName }}</div>
                    </div>
                    <!-- 5 -->
                    <div>
                        <div>核型：</div>
                        <div></div>
                    </div>
                    <!-- 6 -->
                    <div :class="[item.patientStatus == 2 ? 'current' : '']">
                        <div>最终校核者：</div>
                        <div></div>
                    </div>
                    <!-- 7 -->
                    <div v-if="item.patientStatus == 3">
                        根据国家规定禁止告知胎儿性别，此报告图像均不显示性染色体。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OriginalTemplate",
    props: ["aloneReportInfo"],
    data() {
        return {};
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.originalTemplateBox {
    width: 100%;
    height: 100%;
    & > :nth-child(1) {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .diagnosisReporCenter {
            width: 486px;
            position: absolute;
            transform: scale(1.33);
            .hospitalName {
                margin-top: 39px;
                font-size: 22px;
                font-family: SourceHanSerifSC-Medium, SourceHanSerifSC;

                color: #000000;
                text-align: center;
            }
            .reportText {
                margin-top: 8px;
                font-size: 16px;
                font-family: SourceHanSerifSC-Medium, SourceHanSerifSC;

                color: #000000;
                text-align: center;
            }
            .reportInformation {
                margin-top: 9px;
                font-size: 14px;
                font-family: SimSun;
                color: #000000;
                & > :nth-child(1) {
                    display: flex;
                    margin-left: 42px;
                    & > :nth-child(1) {
                        width: 200px;
                    }
                }
                & > :nth-child(2) {
                    margin-top: 15px;
                    display: flex;
                    margin-left: 42px;
                    & > :nth-child(1) {
                        width: 200px;
                    }
                }
                & > :nth-child(3) {
                    display: flex;
                    margin-left: 42px;
                    & > :nth-child(1) {
                        div {
                            margin-top: 15px;
                            width: 200px;
                        }
                    }
                }
                & > :nth-child(4) {
                    display: flex;
                    justify-content: center;
                }
                & > :nth-child(6) {
                    margin-top: 10px;
                    display: flex;
                    & > :nth-child(2) {
                        margin-left: 43px;
                    }
                    & > :nth-child(3) {
                        margin-left: 57px;
                    }
                    & > :nth-child(4) {
                        margin-left: 50px;
                    }
                }
                & > :nth-child(7) {
                    margin-top: 10px;
                    width: 100%;
                    height: 1px;
                    border-bottom: 1px solid #000000;
                }
                & > :nth-child(8) {
                    margin-top: 5px;
                    font-size: 9px;
                }
                & > :nth-child(9) {
                    margin-top: 5px;
                    font-size: 9px;
                    margin-bottom: 40px;
                }
            }
        }
        .rabnormalReporCente {
            width: 486px;
            position: absolute;
            transform: scale(1.3);
            .hospitalName {
                margin-top: 39px;
                font-size: 22px;
                font-family: SourceHanSerifSC-Medium, SourceHanSerifSC;

                color: #000000;
                text-align: center;
            }
            .reportText {
                margin-top: 8px;
                font-size: 16px;
                font-family: SourceHanSerifSC-Medium, SourceHanSerifSC;

                color: #000000;
                text-align: center;
            }
            .rabnormalReportInformation {
                margin-top: 29px;
                font-size: 14px;
                font-family: SimSun;
                color: #000000;
                & > :nth-child(1) {
                    display: flex;
                    & > :nth-child(1) {
                        display: block;
                        width: 147px;
                    }
                    & > :nth-child(2) {
                        display: block;
                        width: 126px;
                    }
                    & > :nth-child(3) {
                        display: block;
                        width: 126px;
                    }
                }
                & > :nth-child(2) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                & > :nth-child(3) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                & > :nth-child(4) {
                    display: flex;
                    & > :nth-child(1) {
                        margin-left: 42px;
                        width: 147px;
                    }
                    & > :nth-child(2) {
                        width: 147px;
                    }
                }
                & > :nth-child(5) {
                    margin-top: 10px;
                    display: flex;
                    align-items: flex-end;
                    & > :nth-child(2) {
                        width: 434px;
                        height: 1px;
                        border-bottom: 1px solid #000000;
                    }
                }
                & > :nth-child(6) {
                    margin-top: 15px;
                    display: flex;
                    align-items: flex-end;
                    & > :nth-child(2) {
                        width: 392px;
                        height: 1px;
                        border-bottom: 1px solid #000000;
                    }
                }
                & > :nth-child(7) {
                    margin-top: 10px;
                    font-size: 10px;
                    margin-bottom: 44px;
                }
            }

            .current {
                margin-bottom: 44px;
            }
        }
    }
}
</style>
