<template>
  <div class="selfDefineTemplate">
    <input type="file" id="selectfiles" style="display: none" />
    <img src="" alt="" id="ossImg" style="position: absolute; top：-10000px;left: -10000px;" />
    <div class="header">
      <span @click="goback"><img src="../../assets/images/selfDefineTemplate/back-icon.svg" alt="" />{{$t('selfDefined_template.selfdefined_template')}}</span>
      <!-- <div class="btns">
                <button @click="undo">撤销</button>
                <button @click="redo">恢复</button>
            </div> -->
    </div>
    <div class="wrap">
      <div class="left">
        <div class="control-label">
          <p class="control-title">
            {{$t('selfDefined_template.official_control')}}<span class="more" @click="showMore()"><img src="../../assets/images/selfDefineTemplate/unfold-icon.svg" v-show="showmore" alt="" />
              <img src="../../assets/images/selfDefineTemplate/fold-icon.svg" v-show="!showmore" alt="" />
            </span>
          </p>
          <ul class="control-ul" v-show="showmore">
            <li class="control-list" v-for="(item, index) in fieldsList" :key="index" @click="onchange(item, index)" :class="{ hover: item.configObj.checked }">
              <img class="add-img" v-if="
                                    item.field == KARYO ||
                                    item.field == SEQUENCE
                                " src="../../assets/images/selfDefineTemplate/add-icon.svg" /><span>{{ item.title }}</span>
            </li>
          </ul>
        </div>
        <div class="control-label">
          <p class="control-title">
            {{$t('selfDefined_template.add_selfcontrol')}}<span class="more" @click="showMoreSelf()"><img src="../../assets/images/selfDefineTemplate/unfold-icon.svg" v-show="showmoreself" alt="" />
              <img src="../../assets/images/selfDefineTemplate/fold-icon.svg" v-show="!showmoreself" alt="" /></span>
          </p>
          <ul class="control-ul" v-show="showmoreself">
            <li class="control-list" @click="addControl(LABEL)">
              <img class="add-img" src="../../assets/images/selfDefineTemplate/add-icon.svg" />
              <span>{{$t('selfDefined_template.label')}}</span>
            </li>
            <li class="control-list" @click="addControl(DATE)">
              <img class="add-img" src="../../assets/images/selfDefineTemplate/add-icon.svg" />
              <span>{{$t('selfDefined_template.date')}}</span>
            </li>
            <li class="control-list" @click="addControl(SELECT)">
              <img class="add-img" src="../../assets/images/selfDefineTemplate/add-icon.svg" />
              <span>{{$t('selfDefined_template.select_box')}}</span>
            </li>
            <li class="control-list" @click="addControl(LABELTEXTAREA)">
              <img class="add-img" src="../../assets/images/selfDefineTemplate/add-icon.svg" />
              <span>{{$t('selfDefined_template.lable_words_box')}}</span>
            </li>
            <li class="control-list" @click="addControl(INPUT)">
              <img class="add-img" src="../../assets/images/selfDefineTemplate/add-icon.svg" />
              <span>{{$t('selfDefined_template.title_box')}}</span>
            </li>
            <li class="control-list" @click="addControl(TEXTAREA)">
              <img class="add-img" src="../../assets/images/selfDefineTemplate/add-icon.svg" />
              <span>{{$t('selfDefined_template.words_box')}}</span>
            </li>
            <li class="control-list" @click="addControl(LOGOIMG)">
              <img class="add-img" src="../../assets/images/selfDefineTemplate/add-icon.svg" />
              <span>{{$t('selfDefined_template.image')}}</span>
            </li>
            <li class="control-list" @click="addControl(ESIGNATURE)">
              <img class="add-img" src="../../assets/images/selfDefineTemplate/add-icon.svg" />
              <span>{{$t('selfDefined_template.e-signature')}}</span>
            </li>
            <li class="control-list" @click="addControl(BORDERKUG)">
              <img class="add-img" src="../../assets/images/selfDefineTemplate/add-icon.svg" />
              <span>{{$t('selfDefined_template.border')}}</span>
            </li>
            <li class="control-list" @click="addControl(LINE)">
              <img class="add-img" src="../../assets/images/selfDefineTemplate/add-icon.svg" />
              <span>{{$t('selfDefined_template.line')}}</span>
            </li>

            <!-- <li class="control-list" @click="addControl(DIV)">
                            <img
                                class="add-img"
                                src="../../assets/images/selfDefineTemplate/add-icon.svg"
                            />
                            <span>文本域</span>
                        </li> -->
          </ul>
        </div>
      </div>
      <div class="center" ref="template">
        <div class="inside" id="list">
          <VueDragResize ref="vuedrag" v-for="(rect, index) in rects" :key="index" :w="rect.styles.width" :h="rect.styles.height" :x="rect.styles.left" :y="rect.styles.top" :ftSize="rect.styles.fontSize" :ftWeight="rect.styles.fontWeight" :ftFamily="rect.styles.fontFamily" :minw="rect.styles.minWidth" :minh="rect.styles.minHeight" :ftColor="rect.styles.color" :rsNumber="rect.configObj.rsNumber" :axis="rect.configObj.axis" :isActive="rect.configObj.active" :isDraggable="rect.configObj.draggable" :isResizable="rect.configObj.resizable" :parentLimitation="true" :snapToGrid="rect.configObj.snapToGrid" :aspectRatio="rect.configObj.aspectRatio" :z="rect.configObj.zIndex" :controlProp="rect.prop" :preventActiveBehavior="preventActive" :contentClass="rect.class" v-on:activated="activateEv(index)" v-on:deactivated="deactivateEv(index)" v-on:dragging="changePosition(arguments, index)" v-on:resizing="changeSize(arguments, index)">
            <!-- 图片上传 -->
            <div class="outer-wrap img" v-if="rect.prop == LOGOIMG" ref="img">
              <!-- 删除按钮 -->
              <el-button type="danger" icon="el-icon-close" v-show="rect.configObj.active" circle @click="deleteControl(rect, index)"></el-button>
              <el-upload class="avatar-uploader" :class="[rect.imgUrl ? '' : 'current']" action="#" :http-request="uploadImg.bind(null, rect)" :show-file-list="false" accept="image/*">
                <img v-if="rect.imgUrl && !rect.isPercentage" :src="rect.imgUrl" class="avatar" crossorigin="anonymous" />
                <i v-else-if="!rect.imgUrl && !rect.isPercentage" class="el-icon-plus avatar-uploader-icon"></i>
                <el-progress v-if="rect.isPercentage" type="circle" :percentage="rect.percentage"></el-progress>
              </el-upload>
            </div>
            <!-- label/input/select/date -->
            <div class="outer-wrap" v-else-if="
                                rect.prop == LABEL ||
                                rect.prop == SELECT ||
                                rect.prop == INPUT ||
                                rect.prop == DATE ||
                                rect.prop == ESIGNATURE ||
                                rect.prop == LABELTEXTAREA
                            ">
              <el-button type="danger" icon="el-icon-close" v-show="rect.configObj.active" circle @click="deleteControl(rect, index)"></el-button>
              <label class="label-width" :class="{ required: rect.configObj.isRequired }" v-if="
                                    rect.type == OFFICIAL && rect.prop != INPUT
                                ">{{ rect.title }}：</label>
              <span class="sp-required" :class="{ required: rect.configObj.isRequired }" v-if="
                                    rect.type == CUSTOM &&
                                    (rect.prop == LABEL ||
                                        rect.prop == SELECT ||
                                        rect.prop == ESIGNATURE ||
                                        rect.prop == DATE)
                                "></span>
              <span class="sp-required sp-textarea" :class="{ required: rect.configObj.isRequired }" v-if="
                                    rect.type == CUSTOM &&
                                    rect.prop == LABELTEXTAREA
                                "></span>
              <input type="text" class="label-width input-label" v-model="rect.title" v-if="
                                    rect.type == CUSTOM &&
                                    (rect.prop == LABEL ||
                                        rect.prop == SELECT ||
                                        rect.prop == ESIGNATURE)
                                " :style="{
                                    fontSize: rect.styles.fontSize + 'px',
                                    fontWeight: rect.styles.fontWeight,
                                    fontFamily: rect.styles.fontFamily,
                                    color: rect.styles.color,
                                    width:
                                        utils.textWidth(rect.title, rect)
                                            .width + 'px',
                                }" :placeholder="$t('selfDefined_template.label')" />
              <input type="text" class="label-width input-label" :class="{ required: rect.configObj.isRequired }" v-model="rect.title" v-if="rect.type == CUSTOM && rect.prop == DATE" :style="{
                                    fontSize: rect.styles.fontSize + 'px',
                                    fontWeight: rect.styles.fontWeight,
                                    fontFamily: rect.styles.fontFamily,
                                    color: rect.styles.color,
                                    width:
                                        utils.textWidth(rect.title, rect)
                                            .width + 'px',
                                }" :placeholder="$t('selfDefined_template.date')" />
              <input type="text" class="label-width label-textarea" :class="{ required: rect.configObj.isRequired }" v-model="rect.title" v-if="
                                    rect.type == CUSTOM &&
                                    rect.prop == LABELTEXTAREA
                                " :style="{
                                    fontSize: rect.styles.fontSize + 'px',
                                    fontWeight: rect.styles.fontWeight,
                                    fontFamily: rect.styles.fontFamily,
                                    color: rect.styles.color,
                                    width:
                                        utils.textWidth(rect.title, rect)
                                            .width + 'px',
                                    height:
                                        utils.textWidth(rect.title, rect)
                                            .height + 'px',
                                }" :placeholder="$t('selfDefined_template.designation')" />
              <span v-if="
                                    rect.type == CUSTOM &&
                                    rect.prop == LABELTEXTAREA
                                " class="colon" :style="{
                                    height:
                                        utils.textWidth(rect.title, rect)
                                            .height + 'px',
                                }">：</span>
              <span class="colon" v-if="
                                    rect.type == CUSTOM &&
                                    rect.prop != INPUT &&
                                    rect.prop != LABELTEXTAREA
                                ">：</span>
              <label ref="dashedBorderLine" v-if="
                                    rect.prop != INPUT &&
                                    rect.prop != ESIGNATURE &&
                                    rect.prop != LABELTEXTAREA
                                " class="line" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }"></label>
              <label ref="dashedBorderLine" v-if="rect.prop == LABELTEXTAREA" class="label-textarea" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }"></label>
              <span class="esig-span" v-if="
                                    rect.type == CUSTOM &&
                                    rect.prop == ESIGNATURE
                                ">
                {{$t('selfDefined_template.e-signature')}}
              </span>
              <input ref="dashedBorderLine" v-if="rect.prop == INPUT" type="text" v-model="rect.value" class="nokug" :placeholder="$t('selfDefined_template.input_content')" :style="{
                                    fontSize: rect.styles.fontSize + 'px',
                                    fontWeight: rect.styles.fontWeight,
                                    fontFamily: rect.styles.fontFamily,
                                    color: rect.styles.color,
                                }" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }" @focus="onFocus" @blur="onBlur" />
            </div>
            <!-- 核型图排列图占位区 -->
            <div ref="dashedBorderLine" class="outer-wrap img-position" v-else-if="rect.prop == IMGPOSITION" :class="{
                                noline: !rect.configObj.showLabelLine,
                                'dashed-line': rect.configObj.showDashedLine,
                            }">
              <el-button type="danger" icon="el-icon-close" v-show="rect.configObj.active" circle @click="deleteControl(rect, index)"></el-button>
              <div class="img-pos">
                <div v-if="
                                        rect.prop == IMGPOSITION &&
                                        rect.field == KARYO
                                    ">
                  <p>{{$t('selfDefined_template.metaphase_image')}}</p>
                  <div ng-if="rect.configObj.relationNum">
                    <span class="relationNumSpan">{{$t('selfDefined_template.relation')}}</span>
                    <input type="text" @keyup="
                                                relationNumFun(rect, $event)
                                            " :value="rect.configObj.relationNum" class="relationNum" />
                    <span class="relationNumSpan">{{$t('selfDefined_template.num_arrayImage')}}</span>
                  </div>
                </div>
                <div v-if="
                                        rect.prop == IMGPOSITION &&
                                        rect.field == SEQUENCE
                                    ">
                  <p>{{$t('case_analysis.array_image')}}</p>
                  <span class="relationNumSpan">{{$t('selfDefined_template.relation')}}</span>
                  <input type="text" @keyup="relationNumFun(rect, $event)" :value="rect.configObj.relationNum" class="relationNum" />
                  <span class="relationNumSpan">{{$t('selfDefined_template.num_karyoImage')}}</span>
                </div>
              </div>
            </div>
            <!-- 输入框 -->
            <div v-else-if="rect.prop == TEXTAREA" class="outer-wrap">
              <el-button type="danger" icon="el-icon-close" v-show="rect.configObj.active" circle @click="deleteControl(rect, index)"></el-button>
              <textarea ref="dashedBorderLine" v-model="rect.value" :placeholder="$t('selfDefined_template.input_words')" class="textarea" :style="{
                                    fontSize: rect.styles.fontSize + 'px',
                                    fontWeight: rect.styles.fontWeight,
                                    fontFamily: rect.styles.fontFamily,
                                    color: rect.styles.color,
                                }" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }" @focus="onFocus" @blur="onBlur"></textarea>
              <div class="div-text" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }" v-html="rect.value"></div>
            </div>

            <!-- 边框 -->
            <div v-else-if="rect.prop == BORDERKUG" class="outer-wrap">
              <el-button type="danger" icon="el-icon-close" v-show="rect.configObj.active" circle @click="deleteControl(rect, index)"></el-button>
              <div ref="dashedBorderLine" class="border-kug" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }"></div>
            </div>
            <!-- 文本域new -->
            <div v-else-if="rect.prop == DIV">
              <div contentEditable="true" id="trainPhase" @click="trainPhase(rect)" @blur="onBlur" @mouseup="mouseupFun($event)"></div>
            </div>
            <!-- 横线 -->
            <div v-else class="outer-wrap controlline">
              <el-button type="danger" icon="el-icon-close" v-show="rect.configObj.active" circle @click="deleteControl(rect, index)"></el-button>
              <p ref="dashedBorderLine" class="control-line" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }"></p>
            </div>
          </VueDragResize>
          <MarkLine @setShowLine="showLine($event)" @setResize="resizeLine($event)" />
        </div>
      </div>
      <div class="right">
        <toolbar @fontSizeFun="fontSizeParentFun($event)" @fontWeightFun="fontWeightParentFun($event)" @colorFun="colorParentFun($event)"></toolbar>
        <div class="toolbar-wh-row">
          <p class="toolbar-row-title">{{$t('selfDefined_template.special_choose')}}</p>
          <!-- <label class="toolbar-check"
                        ><input
                            type="checkbox"
                            @change="togglePDF"
                            :checked="loadPdf"
                        />
                        自动生成PDF报告</label
                    > -->
          <label class="toolbar-check"><input type="checkbox" @change="toggleHideSex" :checked="hideSex" />
            {{$t('checkbox_button.hide_karyos')}}</label>
        </div>
        <div class="btnBox">
          <el-button type="cancel" @click="previewFun()" class="preview-btn" :disabled="!rects.length">{{$t('btns.preview')}}</el-button>
          <el-button type="primary" @click="saveBefore()" class="save-btn" :disabled="!rects.length">{{$t('btns.save')}}</el-button>
        </div>
      </div>
    </div>

    <Preview :dialogVisible="dialogVisible" :templateUrl="templateImgUrlbf" @childFun="parentFun($event)"></Preview>

    <DialogPopup class="dialog-choose-merchants" :visible="nameShow" title="" @visibleChange="nameVisChange">
      <div slot="content" class="pop-cnt">
        <p class="p-titl">{{$t('selfDefined_template.create_template')}}</p>
        <input type="text" v-model="templateInfo.name" class="input-text" :placeholder="$t('selfDefined_template.input_template_name')" />
      </div>
      <div slot="footer">
        <el-button class="preview-btn btns" type="success" @click="nameVisChange(false)" :disabled="isSaved">{{$t('btns.cancel')}}</el-button>
        <el-button class="save-btn btns" type="success" @click="save" :disabled="isSaved" v-html="isSaved ? $t('selfDefined_template.saving'):$t('btns.confirm')"></el-button>
      </div>
    </DialogPopup>

    <DialogPopup class="dialog-choose-merchants" :visible="isShow" :showClose="false" title="" @visibleChange="visibleChange">
      <div slot="content" class="pop-cnt">
        <img src="../../assets/images/selfDefineTemplate/save-icon.svg" />
        <p class="word">{{$t('selfDefined_template.save_success')}}</p>
      </div>
      <div slot="footer">
        <el-button class="preview-btn btns" type="success" @click="visibleSaveChange(false)">{{$t('btns.continue_add')}}</el-button>
        <el-button class="save-btn btns" type="success" @click="saveChange">{{$t('btns.check_template')}}</el-button>
      </div>
    </DialogPopup>
  </div>
</template>

<script>
import {
  SELECT,
  INPUT,
  TEXTAREA,
  LABEL,
  DATE,
  LOGOIMG,
  IMGPOSITION, //图片占位区
  KARYO,
  SEQUENCE,
  OFFICIAL,
  CUSTOM,
  ESIGNATURE,
  BORDERKUG,
  LINE,
  DIV,
  LABELTEXTAREA,
} from "./config/props.js";
import VueDragResize from "./components/vue-drag-resize/vue-drag-resize.vue";
import toolbar from "./components/toolbar/toolbar.vue";
import shortKey from "../../assets/utils/shortKey";
import html2canvas from "html2canvas";
import Preview from "./preview";
import uploader from "../../assets/utils/fileUploader.js";
import DialogPopup from "./components/dialogPopup.vue";
import "../../assets/scss/dialog.scss";
import MarkLine from "./components/MarkLine.vue";
import eventBus from "./config/eventBus";
import utils from "./config/utils.js";
import { reg } from "../../assets/js/reg";
import { cloneDeep } from 'lodash';

export default {
  name: "SelfDefineTemplate",
  created() {
    // 用保存的数据恢复画布
    // if (localStorage.getItem("templateRects")) {
    //     this.$store.commit(
    //         "rect/setComponentData",
    //         this.resetID(JSON.parse(localStorage.getItem("templateRects")))
    //     );
    // }
    // this.addShortKey();
    this.templateListFn();
    this.ossAccess = this.$store.state.ossUploadAccess;
    sessionStorage.setItem(
      "ossAccess",
      JSON.stringify(this.$store.state.ossUploadAccess)
    );
    this.templateInfo.id = null;
    this.templateName = "";
    if (this.$route.query.templateId) {
      //禁止回退
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function () {
        history.pushState(null, null, document.URL);
      });
      this.templateId = this.$route.query.templateId;
      this.getSelect(this.templateId);
      return;
    }
    this.getSelect();
  },
  components: {
    VueDragResize,
    toolbar,
    Preview,
    DialogPopup,
    MarkLine,
  },
  data() {
    return {
      dialogVisible: false,
      templateInfo: {},
      templateImgUrlbf: "", //上传之前展示的图片地址
      templateImgUrl: "",
      templateNameList: [],
      templateName: "",
      fieldsList: [],
      showmore: true, //左边控件收缩展示
      showmoreself: true,
      listWidth: "", //父级宽度
      listHeight: "", //父级高度
      defaultHeight: 5, //默认高度
      isShow: false, //保存弹窗
      nameShow: false, //添加模板名字的弹窗
      loadPdf: false,
      hideSex: false,
      preventActive: false,
      controlIdx: "", //控件id
      isSaved: false,
      rectChanged: false, //判断模板是否修改
      SELECT,
      INPUT,
      TEXTAREA,
      LABEL,
      DATE,
      LOGOIMG,
      IMGPOSITION,
      KARYO,
      SEQUENCE,
      OFFICIAL,
      CUSTOM,
      ESIGNATURE,
      BORDERKUG,
      LINE,
      DIV,
      LABELTEXTAREA,
      childNodes: null, //文本域
      range: null, //文本域
      startOffset: null, //文本域
      endOffset: null, //文本域
      utils,
      temporaryNum: 1,
    };
  },
  mounted() {
    let listEl = document.getElementById("list");
    this.listWidth = listEl.clientWidth;
    this.listHeight = listEl.clientHeight;
    // 禁用浏览器返回
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      history.pushState(null, null, document.URL);
    });
  },
  computed: {
    rects: {
      get() {
        return this.$store.state.rect.rects;
      },
      set(newVal) {
        this.$store.commit("handleVal", newVal);
      },
    },
    ossAccess: {
      get() {
        // if (!!this.$store.state.ossUploadAccess.accessid)
        //     return this.$store.state.ossUploadAccess;
      },
      set() {
        if (!this.$store.state.ossUploadAccess.accessid) {
          this.$store.state.ossUploadAccess = JSON.parse(
            sessionStorage.getItem("ossAccess")
          );
          !!this.rects &&
            this.rects.forEach((item) => {
              if (item.imgUrl) {
                item.imgUrl = this.ossUrlWebFir(
                  item.imgUrl.split("?")[0],
                  1,
                  item.styles.width,
                  item.styles.height
                );
              }
            });
        }
      },
    },
  },

  methods: {
    resetID(data) {
      data.forEach((item) => {
        item.id = generateID();
      });

      return data;
    },
    undo() {
      this.$store.commit("rect/undo", 1);
    },

    redo() {
      this.$store.commit("rect/redo");
    },

    onFocus() {
      this.preventActive = true;
    },
    onBlur() {
      this.preventActive = false;
    },
    // 文本域
    trainPhase(rect) {
      rect.configObj.editActive = true;
      this.preventActive = true;
    },

    /**
     * @method: 文本域
     * @Date: 2021-01-26 14:44:34
     * @param {*} ev
     */
    mouseupFun(ev) {
      let selection_text = window.getSelection().toString();
      if (selection_text) {
        this.childNodes = $(ev.currentTarget)[0].childNodes;
        this.range = window.getSelection().getRangeAt(0);
        this.startOffset = this.range.startOffset;
        this.endOffset = this.range.endOffset;
      }
    },
    fontSizeParentFun(payload) {
      this.newFontSize = payload;
      utils.updateControlStyle(
        this.childNodes,
        this.range,
        this.startOffset,
        this.endOffset,
        payload
      );
    },
    fontWeightParentFun(payload) {
      this.newFontWeight = payload;
      utils.updateControlStyle(
        this.childNodes,
        this.range,
        this.startOffset,
        this.endOffset,
        this.newFontSize,
        payload
      );
    },
    colorParentFun(payload) {
      this.newcolor = payload;
      utils.updateControlStyle(
        this.childNodes,
        this.range,
        this.startOffset,
        this.endOffset,
        this.newFontSize,
        this.newFontWeight,
        payload
      );
    },
    /**
     * @method: 添加吸附
     * @Date: 2021-01-12 10:25:25
     */
    showLine(line) {
      if (this.controlIdx) {
        this.$store.dispatch("rect/setShapePosStyle", {
          id: this.controlIdx,
          newLine: line,
        });
      }
    },
    /**
     * @method: 添加吸附
     * @Date: 2021-01-12 10:25:25
     */
    resizeLine(line) {
      if (this.controlIdx) {
        this.$store.dispatch("rect/setShapeResizeStyle", {
          id: this.controlIdx,
          newLine: line,
        });
      }
    },
    /**
     * @method: 官方控件收缩展开
     * @Date: 2020-12-22 10:20:47
     */
    showMore() {
      this.showmore = !this.showmore;
    },

    /**
     * @method: 自定义控件收缩展开
     * @Date: 2020-12-22 10:21:16
     */
    showMoreSelf() {
      this.showmoreself = !this.showmoreself;
    },

    /**
     * @method: 自定生成并下载pdf
     * @Date: 2020-12-24 10:49:58
     */
    togglePDF() {
      this.loadPdf = !this.loadPdf;
    },

    /**
     * @method: 隐藏性染色体
     * @Date: 2020-12-24 10:49:58
     */
    toggleHideSex() {
      this.hideSex = !this.hideSex;
    },
    /**
     * @method: 控件点击触发函数
     * @Date: 2020-12-22 10:21:29
     */
    onchange(val) {
      this.rectChanged = true;
      this.controlIdx = "";
      val.configObj.checked = !val.configObj.checked;

      if (val.field == KARYO || val.field == SEQUENCE) {
        val.configObj.checked = false;
        this.addControl(val.field, val);
      } else if (val.configObj.checked) {
        this.rects.push(val);
        //生成新控件位置坐标
        this.setPosition();
      } else {
        let idx;
        for (let i = 0; i < this.rects.length; i++) {
          if (val.title === this.rects[i].title) {
            idx = i;
            break;
          }
        }
        this.rects.splice(idx, 1);
      }
      this.$store.commit("rect/recordSnapshot");
    },
    /**
     * @method: 获取初始控件
     * @Date: 2020-12-22 10:50:45
     */
    async queryFiled() {
      return this.$api.queryFiled({}).then((res) => {
        if (res.code == 200) {
          this.fieldsList = res.data.official;
          this.fieldsList.forEach((item) => {
            const { configObj, styles } = utils.paramter();
            configObj.checked = false;

            this.$set(item, "configObj", configObj);
            this.$set(item, "styles", styles);
            if (item.prop == LOGOIMG) {
              item.styles.width = 58;
              item.styles.height = 58;
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @method: 获取模板列表
     * @Date: 2020-12-22 10:50:45
     */
    async templateListFn() {
      return this.$api
        .templateList({ patientId: this.patientId })
        .then((res) => {
          if (res.code == 200) {
            const { template } = res.data;
            this.templateNameList = template;
            console.log(8888, template)
          } else {
            this.$message.error(res.message);
          }
        });
    },

    /**
     * @method: 根据id查询模板信息
     * @Date: 2020-12-23 18:31:41
     */
    async templateQueryById(id) {
      let obj = {
        id: id,
      };
      return this.$api.templateQueryById(obj).then((res) => {
        if (res.code == 200) {
          let templateInfo = (this.templateInfo = res.data);
          let content =
            typeof templateInfo.content == "string"
              ? JSON.parse(templateInfo.content)
              : {};
          this.rects = content.rects;
          this.templateName = templateInfo.name;
          this.rects.forEach((item) => {
            if (item.imgUrl) {
              item.imgUrl = this.ossUrlWebFir(
                item.imgUrl.split("?")[0],
                1,
                item.styles.width,
                item.styles.height
              );
            }

            if (item.configObj.showLabelLine == null) {
              this.$set(item.configObj, "showLabelLine", true);
              this.$set(item.configObj, "showDashedLine", false);
            }
            if (item.configObj.isRequired == null) {
              this.$set(item.configObj, "isRequired", false);
            }
            if (item.configObj.format == null) {
              this.$set(item.configObj, "format", "yyyy-MM-dd");
              this.$set(item.configObj, "format", "date");
            }
          });
          this.loadPdf = content.loadPdf;
          this.hideSex = content.hideSex;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @method: 编辑时获取控件数据和模板数据后进行对比
     * @Date: 2020-12-24 09:10:08
     */
    async getSelect(id) {
      this.rects = [];
      if (id) {
        let queryFiled = this.queryFiled();
        let templateQueryById = this.templateQueryById(id);
        await queryFiled;
        await templateQueryById;
        this.mapData();
        this.$store.commit("rect/recordSnapshot");
      } else {
        this.queryFiled();
        this.$store.commit("rect/recordSnapshot", true);
      }
    },
    /**
     * @method: 比对数据
     * @Date: 2020-12-25 20:17:35
     */
    mapData() {
      this.fieldsList.forEach((item) => {
        item.configObj.checked = false;
        this.rects.forEach((it) => {
          if (it.id === item.id) {
            item.configObj.checked = true;
          }
        });
      });
    },
    /**
     * @method: 设置控件添加位置
     * @param {*}
     * @Date: 2020-12-22 10:11:46
     */
    setPosition() {
      const length = this.rects.length;
      if (length >= 2) {
        const top = this.rects[length - 2].styles.top;
        const left = this.rects[length - 2].styles.left;
        const height = this.rects[length - 2].styles.height;
        const width = this.rects[length - 2].styles.width;
        const curHeight = this.rects[length - 2].styles.height;
        const curWidth = this.rects[length - 1].styles.width;
        if (
          top + height + this.defaultHeight + curHeight >
          this.listHeight
        ) {
          this.rects[length - 1].styles.top =
            this.listHeight - this.rects[length - 1].styles.height;
        } else {
          this.rects[length - 1].styles.top =
            top + height + this.defaultHeight;
        }
        if (left + width + curWidth > this.listWidth) {
          this.rects[length - 1].styles.left =
            this.listWidth - this.rects[length - 1].styles.width;
        } else {
          this.rects[length - 1].styles.left = left;
        }
      }
    },

    /**
     * @method: 删除控件
     * @Date: 2020-12-22 10:11:46
     */
    deleteControl(rect, index) {
      rect.configObj.checked = false;
      sessionStorage.setItem("deleteJudge", "true");
      this.rects.splice(index, 1);
      let rectObj = this.getArray();
      if (
        rectObj.KARYOArray.indexOf(rect.configObj.relationNum) < 0 &&
        rectObj.SEQUENCEArray.indexOf(rect.configObj.relationNum) < 0
      ) {
        this.rects.forEach((item) => {
          item.configObj.relationNum > rect.configObj.relationNum
            ? (item.configObj.relationNum -= 1)
            : "";
        });
      }
    },

    /**
     * @method: 更改关联编号
     * @Date: 2021-01-13 13:00:00
     */
    relationNumFun(rect, ev) {
      let relationNum = parseInt(ev.target.value);
      let rectObj = this.getArray();
      if (
        typeof relationNum !== "number" ||
        isNaN(relationNum) ||
        relationNum == 0
      ) {
        setTimeout(() => {
          if (typeof ev.target.value !== "number" || isNaN(parseInt(ev.target.value)) || ev.target.value == 0) {
            ev.target.value = rect.configObj.relationNum;
          }
        }, 1500);
      } else {
        if (rect.field == KARYO) {
          if (rectObj.KARYOArray.indexOf(relationNum) < 0) {
            rect.configObj.relationNum = relationNum;
          } else {
            if (rect.configObj.relationNum != relationNum) {
              this.$message.error(this.$t('selfDefined_template.already_exit_code'));
            } else {
              ev.target.value = relationNum;
            }
          }
        } else if (rect.field == SEQUENCE) {
          if (rectObj.SEQUENCEArray.indexOf(relationNum) < 0) {
            rect.configObj.relationNum = relationNum;
          } else {
            if (rect.configObj.relationNum != relationNum) {
              ev.target.value = rect.configObj.relationNum;
              this.$message.error(this.$t('selfDefined_template.already_exit_code'));
            } else {
              ev.target.value = relationNum;
            }
          }
        }
      }
    },

    /**
     * @method: 添加增加核型图或排列图编号
     * @Date: 2021-01-13 13:00:00
     */
    generateNumFun(rect) {
      let sameNum = 0;
      let rectObj = this.getArray();
      switch (true) {
        case rect.field == KARYO:
          let KARYOMax = utils.getMax(rectObj.KARYOArray);
          sameNum = this.getSameNum(1, rectObj.KARYOArray);
          if (sameNum != 1) {
            rect.configObj.relationNum = KARYOMax + 1;
          }
          break;

        case rect.field == SEQUENCE:
          let SEQUENCEMax = utils.getMax(rectObj.SEQUENCEArray);
          sameNum = this.getSameNum(1, rectObj.SEQUENCEArray);
          if (sameNum != 1) {
            rect.configObj.relationNum = SEQUENCEMax + 1;
          }
          break;

        default:
          break;
      }
    },

    /**
     * @method: 返回查询数组
     * @Date: 2021-01-13 13:00:00
     */
    getArray() {
      let rectObj = {
        KARYOArray: [],
        SEQUENCEArray: [],
      };
      this.rects.forEach((item) => {
        switch (true) {
          case item.field == KARYO:
            rectObj.KARYOArray.push(item.configObj.relationNum);
            break;

          case item.field == SEQUENCE:
            rectObj.SEQUENCEArray.push(item.configObj.relationNum);
            break;

          default:
            break;
        }
      });
      return rectObj;
    },

    /**
     * 获取数组中相同元素的个数
     * @param val 相同的元素
     * @param arr 传入数组
     */
    getSameNum(val, arr) {
      let processArr = arr.filter(function (value) {
        return value == val;
      });

      return processArr.length;
    },
    /**
     * @method: 添加自定义控件
     * @Date: 2020-12-22 10:11:46
     */
    addControl(prop, rect) {
      this.controlIdx = "";
      let controlObj = {
        id: utils.GenNonDuplicateID(),
        type: "custom", //类型 官方组件=official 或 自定义=custom
        prop: "", //用来判断为哪种控件类型
        title: "", //标题
        field: "field" + utils.GenNonDuplicateID(), //对应数据库中的gender字段
        value: "", //数据库中的gender字段的值 //模板生成时可为空，展示报告时需要将数据库的值赋值给value
        options: [],
      };
      const { configObj, styles } = utils.paramter();
      switch (prop) {
        case SELECT:
          controlObj.prop = SELECT;
          break;
        case INPUT:
          controlObj.prop = INPUT;
          break;
        case TEXTAREA:
          controlObj.prop = TEXTAREA;
          styles.height = 30;
          break;
        case DATE:
          controlObj.prop = DATE;
          break;
        case LOGOIMG:
          controlObj.prop = LOGOIMG;
          controlObj.percentage = 10;
          styles.width = 100;
          styles.height = 100;
          break;
        case IMGPOSITION:
          controlObj.prop = IMGPOSITION;
          styles.width = 85;
          styles.height = 85;
          break;
        case ESIGNATURE: //电子签名
          controlObj.prop = ESIGNATURE;
          break;
        case KARYO: //核型图 此处入参为field
          controlObj.prop = IMGPOSITION;
          controlObj.field = KARYO;
          controlObj.percentage = 10;
          controlObj.percentageSize = 3 / 5;
          controlObj.strokeWidth = 6;
          configObj.relationNum = 1;
          styles.width = 145;
          styles.height = 122;
          styles.minWidth = 145;
          styles.minHeight = 122;
          break;
        case SEQUENCE: //排列图 此处入参为field
          controlObj.prop = IMGPOSITION;
          controlObj.field = SEQUENCE;
          controlObj.percentage = 10;
          controlObj.percentageSize = 3 / 5;
          controlObj.strokeWidth = 6;
          configObj.relationNum = 1;
          styles.width = 145;
          styles.height = 122;
          styles.minWidth = 145;
          styles.minHeight = 122;
          break;
        case BORDERKUG: //边框
          controlObj.prop = BORDERKUG;
          configObj.zIndex = 0;
          styles.width = 100;
          styles.height = 100;
          break;
        case LINE: //横线
          controlObj.prop = LINE;
          styles.width = 200;
          styles.height = 10;
          break;
        case DIV: //
          controlObj.prop = DIV;
          break;
        case LABELTEXTAREA: //标签+文本域
          controlObj.prop = LABELTEXTAREA;
          break;
        default:
          controlObj.prop = LABEL;
      }

      if (rect) {
        controlObj.type = OFFICIAL;
      }

      this.$set(controlObj, "configObj", configObj);
      this.$set(controlObj, "styles", styles);
      this.rects.push(controlObj);
      this.generateNumFun(controlObj);
      this.setPosition();
    },

    /**
     * @method: 上传图片
     * @Date: 2020-12-22 10:11:46
     */
    async uploadImg(rect, e) {
      const _file = e.file;
      // _file.israndom = true;
      if (!_file) return;
      let name = _file.name.split(".");
      !!name.length && name.pop();
      if (!reg.specialImgCharacters.test(name)) {
        this.$message.error(
          this.$t('selfDefined_template.image_upload_demand')
        );
        return;
      }
      const isLt10M = _file.size / 1024 / 1024 < 10;
      let formats = ["bmp", "png", "jpg", "jpe", "jpeg"];
      let format = _file.name.split(".").pop().toLowerCase();
      if (isLt10M && formats.indexOf(format) > -1) {
        let setPercentage;
        if (rect) {
          rect.isPercentage = true;
          setPercentage = setInterval(() => {
            rect.percentage += 5;
            if (rect.percentage >= 99) {
              rect.percentage = 99;
            }
          }, 200);
          this.$forceUpdate();
        }
        return uploader({
          files: [_file],
          path: "custom/template/",
          processHandle: () => { },
          ossUploadAccess: this.$store.state.ossUploadAccess,
        }).then((filelist) => {
          const imgUrl = filelist[0].path;
          if (rect) {
            const imgList = this.$refs["img"];
            this.$nextTick(() => {
              rect.styles.width = imgList[0].clientWidth;
              rect.styles.height = imgList[0].clientHeight;
            });
            let ossImg = document.getElementById("ossImg");
            ossImg.src = URL.createObjectURL(_file);
            ossImg.onload = () => {
              rect.imgUrl = this.ossUrlWebFir(
                imgUrl,
                1,
                rect.styles.width,
                rect.styles.height
              );
              rect.isPercentage = false;
              this.$forceUpdate();
              ossImg.src = "";
              ossImg.width = 0;
              ossImg.height = 0;
            };
            clearInterval(setPercentage);
            setPercentage = null;
            rect.configObj.active = true;
          } else {
            this.templateImgUrl = imgUrl;
            clearInterval(setPercentage);
            setPercentage = null;
          }
        });
      } else {
        this.$message.error(
          this.$t('selfDefined_template.image_size_demand')
        );
      }
    },

    /**
     * @method: 获得焦点
     * @Date: 2020-12-22 10:17:00
     */
    activateEv(index) {
      this.controlIdx = index;
      this.$store.dispatch("rect/setActive", { id: index });
    },

    /**
     * @method: 失去焦点
     * @Date: 2020-12-22 10:17:13
     */
    deactivateEv(index) {
      this.$store.dispatch("rect/unsetActive", { id: index });
    },

    /**
     * @method: 更改位置
     * @Date: 2020-12-22 10:17:28
     */
    changePosition(e, index) {
      this.rectChanged = true;
      this.controlIdx = index;
      this.$store.dispatch("rect/setTop", {
        id: index,
        top: e[0].top,
      });
      this.$store.dispatch("rect/setLeft", {
        id: index,
        left: e[0].left,
      });
      this.$store.dispatch("rect/setWidth", {
        id: index,
        width: e[0].width,
      });
      this.$store.dispatch("rect/setHeight", {
        id: index,
        height: e[0].height,
      });

      this.$nextTick(() => {
        // 触发元素移动事件，用于显示标线、吸附功能
        // 后面两个参数代表鼠标移动方向
        // true 表示向下移动 false 表示向上移动
        // true 表示向右移动 false 表示向左移动
        eventBus.$emit("move", e[1], e[2], e[3]);
      });
    },

    /**
     * @method: 更改尺寸
     * @Date: 2020-12-22 10:17:40
     */
    changeSize(e, index) {
      this.rectChanged = true;
      this.$store.dispatch("rect/setTop", {
        id: index,
        top: e[0].top,
      });
      this.$store.dispatch("rect/setLeft", {
        id: index,
        left: e[0].left,
      });
      this.$store.dispatch("rect/setWidth", {
        id: index,
        width: e[0].width,
      });
      this.$store.dispatch("rect/setHeight", {
        id: index,
        height: e[0].height,
      });
      this.$nextTick(() => {
        eventBus.$emit("resize", e[1], e[2], e[3]);
      });
    },
    /**
     * @method: 将html转为图片
     * @Date: 2020-12-22 10:18:03
     */
    async htmlToImage(flag) {
      const template = this.$refs["template"];
      !!this.$refs["dashedBorderLine"] &&
        this.$refs["dashedBorderLine"].forEach(function (item) {
          if ($(item).hasClass("dashed-line")) {
            $(item).addClass("noline");
          }
        });
      $(".textarea").hide();
      $(".div-text").show();

      return html2canvas(template, {
        allowTaint: true,
        useCORS: true,
      }).then(async (canvas) => {
        let dataURL = canvas.toDataURL("image/webp");
        let dataURL1 = await this.dashedImage(dataURL, canvas);
        !!this.$refs["dashedBorderLine"] &&
          this.$refs["dashedBorderLine"].forEach(function (item) {
            if ($(item).hasClass("dashed-line")) {
              $(item).removeClass("noline");
            }
          });
        $(".textarea").show();
        $(".div-text").hide();

        let newUrl = await this.dealImage(dataURL1, 614, 877);
        this.templateImgUrlbf = newUrl;

        if (flag === "preview") {
          this.dialogVisible = true;
          return;
        }
        const userId = JSON.parse(localStorage.getItem("loginObj")).userId;
        const mainId = this.templateInfo.mainId ? 1 : 0;
        const objFile = {
          file: utils.dataURLtoFile(
            newUrl,
            this.templateInfo.name + mainId + userId + ".jpg"
          ),
        };
        return objFile;
      });
    },
    dealImage(base64, w, h) {
      return new Promise((resolve) => {
        let newImage = new Image();
        newImage.src = base64;
        newImage.setAttribute("crossOrigin", "Anonymous"); //url为外域时需要
        newImage.onload = function () {
          let canvas = document.createElement("canvas");
          canvas.width = w;
          canvas.height = h;
          let ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
          let base641 = canvas.toDataURL("image/webp"); //压缩语句
          resolve(base641);
          newImage.remove();
          canvas.remove();
        };
      });
    },
    dashedImage(dataURL, canvas) {
      return new Promise((resolve) => {
        let ossImg = document.getElementById("ossImg");
        ossImg.src = dataURL;
        ossImg.onload = () => {
          let canvas1 = document.createElement("canvas");
          canvas1.width = canvas.width;
          canvas1.height = canvas.height;
          canvas1.style.width = canvas.width + "px";
          canvas1.style.height = canvas.height + "px";
          let ctx = canvas1.getContext("2d");
          ctx.setLineDash([4, 2]);
          ctx.strokeStyle = "#6b6a6a";
          ctx.lineWidth = 1;
          ctx.globalAlpha = 1;
          ctx.drawImage(ossImg, 0, 0, canvas.width, canvas.height);

          this.rects.forEach((item) => {
            if (item.configObj.showDashedLine) {
              let top = item.styles.top + 32;
              let left = item.styles.left + 40;
              let width = item.styles.width - 4;
              let height = item.styles.height;
              if (item.prop == LABEL || item.prop == DATE || item.prop == SELECT) {
                let labelWidth = utils.textWidth(item.title, item).width + 14;
                // bottom
                ctx.beginPath();
                ctx.moveTo(left + labelWidth, top + height);
                ctx.lineTo(left + width, top + height);
                ctx.stroke();
                return;
              } else if (item.prop == LINE) {
                ctx.strokeStyle = "#333";
                // bottom
                ctx.beginPath();
                ctx.moveTo(left, top + height);
                ctx.lineTo(left + width, top + height);
                ctx.stroke();
                return;
              }

              ctx.strokeStyle = "#6b6a6a";
              // top
              ctx.beginPath();
              ctx.moveTo(left, top);
              ctx.lineTo(left + width, top);
              ctx.stroke();

              // left
              ctx.beginPath();
              ctx.moveTo(left, top);
              ctx.lineTo(left, top + height);
              ctx.stroke();

              // right
              ctx.beginPath();
              ctx.moveTo(left + width, top);
              ctx.lineTo(left + width, top + height);
              ctx.stroke();

              // bottom
              ctx.beginPath();
              ctx.moveTo(left, top + height);
              ctx.lineTo(left + width, top + height);
              ctx.stroke();
            }
          });

          let dataURL1 = canvas1.toDataURL("image/webp");
          resolve(dataURL1);
          canvas1.remove();
          ossImg.style.width = 0;
          ossImg.style.height = 0;
        };
      });
    },
    saveBefore() {
      this.nameShow = true;
      //保存
      // shortKey.on(
      //     {
      //         key: "enter",
      //     },
      //     () => {
      //         // this.$refs['redoBtn'].click();
      //         this.save();
      //     }
      // );
    },
    /**
     * @method: 保存
     * @Date: 2020-12-22 10:18:12
     */
    async save() {
      if (!this.templateInfo.name) {
        this.$message.error(this.$t('selfDefined_template.input_template_name'));
        return;
      }
      if (!reg.specialImgCharacters.test(this.templateInfo.name)) {
        this.$message.error(
          this.$t('selfDefined_template.template_name_demand')
        );
        return;
      }
      let nameList = [];
      this.templateNameList.forEach((it) => {
        if (!this.templateName || it.name != this.templateName) {
          nameList.push(it.name);
        }
      });
      let name = this.templateInfo.name;
      if (nameList.indexOf(name) != -1) {
        this.$message.error(this.$t('selfDefined_template.templateName_repeat'));
        return;
      }

      this.isSaved = true;
      const file = await this.htmlToImage();
      await this.uploadImg("", file);
      await this.editTemplate();
    },

    /**
     * @method: 添加修改模板
     * @Date: 2020-12-22 14:36:41
     */
    async editTemplate() {
      let customJson = [];

      let rectsCopy = [];
      rectsCopy = cloneDeep(this.rects);
      rectsCopy.forEach((item) => {
        item.imgUrl ? (item.imgUrl = item.imgUrl.split("?")[0]) : "";
        if (item.type === CUSTOM) {
          customJson.push(item);

        }
        if (JSON.stringify(item.value) === '{}') {
          delete item.value
        }
      });

      const content = {
        loadPdf: this.loadPdf,
        hideSex: this.hideSex,
        rects: rectsCopy,
      };

      let obj = {
        name: this.templateInfo.name,
        content: JSON.stringify(content),
        customJson: JSON.stringify(customJson),
        templateImgUrl: this.templateImgUrl,
      };
      // mainId为0表示为官方模板
      if (!!this.templateInfo.id && !!this.templateInfo.mainId) {
        obj.id = this.templateInfo.id;
      }
      console.log(obj);
      // alert(JSON.stringify(content));
      return this.$api.editTemplate(obj).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.isShow = true;
          this.isSaved = false;
        } else {
          this.isSaved = false;
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @method: 保存弹窗返回编辑
     * @Date: 2020-12-22 10:18:32
     */
    nameVisChange(val) {
      this.templateInfo.name = this.templateName;
      this.nameShow = val;
    },
    visibleChange() {
      this.rects = [];
      this.templateInfo = {};
      this.queryFiled();
    },

    async visibleSaveChange(val) {
      await this.templateListFn();
      this.isShow = val;
      this.nameShow = val;
      this.$store.state.rect.snapshotIndex = -1;
      this.$store.state.rect.snapshotData = [];
      this.templateInfo.id = null;
      this.templateName = "";
    },

    /**
     * @method: 保存弹窗跳转
     * @Date: 2020-12-22 10:18:45
     */
    saveChange() {
      this.isShow = false;
      this.nameShow = false;
      this.$router.push({
        name: "个人设置页",
        params: { tabType: "template_info" },
      });
    },
    goback() {
      if (!this.rects.length || !this.rectChanged) {
        this.$router.push({
          name: "个人设置页",
          params: { tabType: "template_info" },
        });
        return;
      }

      this.$confirm(this.$t('selfDefined_template.need_toSave'), this.$t('popup.warning.remind'), {
        confirmButtonText: this.$t('btns.save'),
        cancelButtonText: this.$t('btns.not_save'),
        closeOnClickModal: false,
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        this.saveBefore();
        this.rectChanged = false;
      }).catch((action) => {
        if (action === "cancel") {
          this.$router.push({
            name: "个人设置页",
            params: { tabType: "template_info" },
          });
          //   this.$store.commit("templateType", true);
          this.rectChanged = false;
        }
      });
    },
    /**
     * @method: 预览
     * @Date: 2020-12-22 10:19:07
     */
    previewFun() {
      this.htmlToImage("preview");
    },

    /**
     * @method: 预览弹窗关闭按钮
     * @Date: 2020-12-22 10:19:15
     */
    parentFun(payload) {
      this.dialogVisible = payload;
    },
    addShortKey() {
      shortKey.disabled = false;
      // 撤销
      shortKey.on(
        "keypress",
        {
          key: "z",
          ctrlKey: true,
        },
        () => {
          // this.$refs['undoBtn'].click();
          this.undo();
        }
      );
      //恢复
      shortKey.on(
        "keypress",
        {
          key: "y",
          ctrlKey: true,
        },
        () => {
          // this.$refs['redoBtn'].click();
          this.redo();
        }
      );
      //保存
      shortKey.on(
        "keypress",
        {
          key: "s",
          ctrlKey: true,
        },
        () => {
          // this.$refs['redoBtn'].click();
          this.saveBefore();
        }
      );
    },
  },
  watch: {
    rects: function () {
      this.mapData();
    },
    "$route.query.templateId": function (val) {
      this.templateListFn();
      this.templateInfo.id = null;
      this.templateName = "";
      this.$store.state.rect.snapshotIndex = -1;
      this.$store.state.rect.snapshotData = [];
      if (val) {
        //禁止回退
        history.pushState(null, null, document.URL);
        window.addEventListener("popstate", function () {
          history.pushState(null, null, document.URL);
        });
        this.templateId = this.$route.query.templateId;
        this.getSelect(this.templateId);
        this.addShortKey();
        return;
      }
      this.getSelect();
    },
    isShow(val) {
      shortKey.disabled = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.selfDefineTemplate {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background: #f5f5f5;
  ul,
  li {
    list-style: none;
  }
  input:focus,
  input:active,
  input:visited {
    outline: none;
    // border: 1px solid #6b6a6a;
  }
  .wrap {
    display: flex;
    position: relative;
    width: 1248px;
    margin: 10px auto;
    box-sizing: border-box;
    overflow: hidden;
  }
  .header {
    height: 50px;
    font-size: 16px;
    color: #3d3d3d;
    line-height: 50px;
    padding: 0 24px;
    background: #fff;
    span {
      cursor: pointer;
    }
    img {
      margin: 18px 5px 0 0;
      vertical-align: top;
    }
  }
  .title {
    height: 40px;
    background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    padding: 0 10px;
    margin-bottom: 0;
  }
  .left {
    width: 210px;
    height: 1114px;
    background: #ffffff;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #e2e6ed;
    margin: 0 10px 0 0;
    box-sizing: border-box;
    .control-label {
      width: 208px;
      .control-title {
        position: relative;
        height: 56px;
        padding: 0 12px;
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        color: #333;
        line-height: 56px;
        box-sizing: border-box;
        .more {
          position: absolute;
          top: 23px;
          right: 10px;
          height: 14px;
          width: 14px;
          cursor: pointer;
          img {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      .control-ul {
        display: flex;
        width: 208px;
        padding: 0 10px;
        flex-wrap: wrap;
        justify-content: space-between;
        justify-items: center;
        text-align: justify;
        box-sizing: border-box;
        .control-list {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 92px;
          // height: 28px;
          padding: 4px 0;
          font-size: 12px;
          color: #333;
          text-align: center;
          // line-height: 28px;
          margin-bottom: 10px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid #e2e6ed;
          box-sizing: border-box;
          &:hover {
            border: 1px solid #4773b8;
            cursor: pointer;
          }
          &.hover {
            color: #4773b8;
            border: 1px solid #4773b8;
            cursor: pointer;
          }
          .add-img {
            margin: 2px 3px 0 0;
            vertical-align: top;
          }
        }
      }
      .add-control-label {
        color: #606266;
        font-size: 14px;
        margin-left: 5px;
        .el-button {
          padding: 1px 2px;
        }
      }
    }
  }
  .center {
    position: absolute;
    left: 218px;
    top: 0;
    width: 780px;
    height: 1114px;
    border: 1px solid #e2e6ed;
    background: #fff;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
    .inside {
      position: relative;
      width: 700px;
      height: 1052px;
      margin: 30px auto;
    }
    .outer-wrap {
      position: relative;
      display: flex;
      height: 100%;
      min-height: 26px;
      box-sizing: border-box;
      // padding-left: 10px;
      &.img {
        width: 100%;
      }
      input[type="text" i] {
        padding: 0;
      }
      .label-width {
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
        &.required {
          &::before {
            content: "*";
            position: absolute;
            color: #f56c6c;
            left: -8px;
            font-size: 14px;
          }
        }
        &.label-textarea {
          height: auto;
          min-height: 24px;
          border: none;
          background: none;
          outline: none;
          align-items: normal;
        }
        &.input-label {
          border: none;
          background: none;
          outline: none;
          font-family: SimSun;
        }
        &.input-label:focus {
          border: 1px dashed #6b6a6a;
        }
      }
      .sp-required {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        &.sp-textarea {
          height: auto;
          align-items: unset;
        }
        &.required {
          &::before {
            content: "*";
            position: absolute;
            color: #f56c6c;
            left: -8px;
            font-size: 14px;
          }
        }
      }
      .colon {
        display: flex;
        height: 100%;
        min-height: 24px;
        align-items: center;
      }

      .line {
        flex: 1;
        // height: 22px;
        border: 0;
        border-bottom: 1px solid #6b6a6a;
        background: none;
        overflow: hidden;
        &.custom-mrg {
          margin: 0 0 0 -8px;
        }
        &.dashed-line {
          border-bottom: 1px dashed #6b6a6a;
        }
        &.noline {
          border: 0;
        }
      }
      .label-textarea {
        width: 100%;
        border: 1px solid #6b6a6a;
        border-radius: 4px;
        &.dashed-line {
          border: 1px dashed #6b6a6a;
        }
        &.noline {
          border: 0;
        }
        &:focus {
          border: 1px dashed #4773b8;
        }
      }
      .esig-span {
        width: 80px;
        line-height: 26px;
        text-align: center;
        border: 1px solid #6b6a6a;
        font-size: 14px;
      }
      &.controlline {
        justify-content: center;
        align-items: center;
        min-height: 1px;
      }
      .control-line {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #6b6a6a;
        margin: 0;
        &.dashed-line {
          border-bottom: 1px dashed #6b6a6a;
        }
        &.noline {
          border: 0;
        }
      }
      .nokug {
        width: 100%;
        min-width: 20px;
        line-height: 26px;
        border: 1px solid #6b6a6a;
        text-align: center;
        background: none;
        white-space: pre-wrap;
        border-radius: 4px;
        &.dashed-line {
          border: 1px dashed #6b6a6a;
        }
        &.noline {
          border: 0;
        }
      }
      &.img-position {
        width: 100%;
        height: 100%;
        min-width: 85px;
        min-height: 85px;
        display: flex;
        text-align: center;
        align-items: center;
        border: 1px solid #6b6a6a;
        border-radius: 4px;
        &.dashed-line {
          border: 1px dashed #6b6a6a;
        }
        &.noline {
          border: 0;
        }
        .img-pos {
          margin: auto;
        }
        .relationNumSpan {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
        .relationNum {
          width: 36px;
          height: 20px;
          text-align: center;
          font-size: 14px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #e3e3e3;
          color: #4773b8;
          font-weight: 400;
        }
        p {
          text-align: center;
          margin: 5px 0;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
        }
      }
      .textarea {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 5px;
        min-height: 30px;
        line-height: 20px;
        border: 1px solid #6b6a6a;
        box-sizing: border-box;
        border-radius: 4px;
        outline: none;
        resize: none;
        overflow: hidden;
        &.dashed-line {
          border: 1px dashed #6b6a6a;
        }
        &.noline {
          border: 0;
        }
      }
      .div-text {
        display: none;
        width: 100%;
        height: 100%;
        line-height: 20px;
        border: 1px solid #6b6a6a;
        padding: 5px;
        border-radius: 4px;
        white-space: pre-wrap;
        box-sizing: border-box;
        &.dashed-line {
          border: 1px dashed #6b6a6a;
        }
        &.noline {
          border: 0;
        }
      }
    }
    #trainPhase {
      border: 1px solid #6b6a6a;
      padding: 10px;
    }
    .vdr.active:before {
      outline: 1px dashed #4773b8;
    }
    .border-kug {
      width: 100%;
      height: 100%;
      border: 1px solid #6b6a6a;
      border-radius: 4px;
      z-index: 0;
      &.dashed-line {
        border: 1px dashed #6b6a6a;
      }
      &.noline {
        border: 0;
      }
    }
  }

  .right {
    position: relative;
    width: 240px;
    height: 1114px;
    padding: 20px 16px;
    margin-left: 786px;
    border: 1px solid #e2e6ed;
    background: #fff;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
    .btnBox {
      display: flex;
      justify-content: center;
      & > button {
        margin-top: 20px;
        padding: 0;
      }
    }
  }
  .avatar-uploader {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .el-upload.el-upload--picture-card {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: 0;
  }

  .el-upload--picture-card i {
    font-size: 16px;
  }
  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: auto;
    height: auto;
    max-width: 100%;
  }

  .el-button.is-circle {
    padding: 0;
  }
  .el-button--danger {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    font-size: 12px;
    z-index: 10;
  }

  .preview-btn {
    width: 100px;
    height: 34px;
    font-size: 13px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #c6c6c6;
    text-align: center;
    color: #333;
    line-height: 34px;
    &:hover {
      background: #eff0f1;
      transition-duration: 0.5s;
    }
    &:disabled {
      // background: #8eaddc;
      background: #eee;
      color: #999;
    }
  }
  .save-btn {
    width: 100px;
    height: 34px;
    background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
    border-radius: 2px;
    text-align: center;
    line-height: 34px;
    font-size: 13px;
    border: 0;
    &:hover {
      background: linear-gradient(90deg, #64a7c9 0%, #416ab0 100%);
      transition-duration: 0.5s;
    }
    &:disabled {
      background: #8eaddc;
    }
  }

  a {
    font-size: 14px;
  }
}
</style>
<style lang="scss">
.selfDefineTemplate {
  .current {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
  }
  .avatar-uploader .el-upload {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .vdr-stick {
    border: 1px solid #4773b8;
    border-radius: 50%;
  }
  .outer-wrap {
    .el-icon-close {
      position: absolute;
      top: 0;
      left: 0;
    }
    &.img {
      .avatar-uploader .el-upload {
        z-index: -1;
      }
    }
    .el-progress {
      width: 100%;
      height: 100%;
    }
    .el-progress-circle {
      width: 100% !important;
      height: 100% !important;
    }
    .el-progress--circle .el-progress__text,
    .el-progress--dashboard .el-progress__text {
      font-size: 14px !important;
    }
  }

  .dialog-choose-merchants {
    .el-dialog {
      width: 480px;
      height: 210px;
      background: #ffffff;
      box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03),
        0px 9px 28px 0px rgba(0, 0, 0, 0.05),
        0px 6px 16px -8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
    .el-dialog--center .el-dialog__body {
      padding: 0px 25px 8px;
      .pop-cnt {
        text-align: center;
        .word {
          font-size: 15px;
        }
        .p-titl {
          font-size: 16px;
          margin: 0 0 16px;
          font-weight: bold;
          color: #333;
        }
        .input-text {
          width: 400px;
          height: 34px;
          border: 1px solid #4773b8;
          margin-bottom: 20px;
          padding: 0 16px;
          box-sizing: border-box;
        }
      }
    }

    .el-dialog__wrapper .el-dialog .el-dialog__header {
      background: none;
    }
    .el-dialog__footer {
      background: none;
      border-top: 0;
      padding: 0;
      .btns {
        width: unset;
        height: 32px;
        padding: 0 10px !important;
      }
      .btns:first-child {
        margin-right: 6px;
      }
    }
    .el-dialog__wrapper
      .el-dialog
      .el-dialog__header
      .el-dialog__headerbtn
      .el-dialog__close {
      color: #333;
      font-size: 24px;
    }
  }
  .el-dialog__wrapper .el-dialog .el-dialog__header .el-dialog__headerbtn {
    top: 20px;
    right: 14px;
  }
  .el-upload-list--picture-card .el-upload-list__item,
  .el-upload--picture-card {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: 0;
    justify-content: center;
    align-items: center;
  }
}
</style>
