<template>
  <div class="caseAllocationBox">
    <el-dialog :title="$t('popup.case_allot.title')" :visible.sync="newDialogVisible" width="1000px" height="760px" :before-close="closeFun" :close-on-click-modal="false">
      <div class="contentBox">
        <div class="screenBox" :class="{'firstline': $store.state.language == 'en'}">
          <span class="statusFiltering">{{$t('caseAllocation.filter_by_caseno')}}</span>
          <span>
            <el-input type="text" v-model="search" :placeholder="$t('caseAllocation.enter_caseno')" clearable></el-input>
          </span>
          <span class="statusFiltering">{{$t('caseAllocation.filter_by_source')}}</span>
          <span>
            <el-select v-model="mechanismId">
              <el-option v-for="(item, index) in mechanismList" :key="index" :label="item.mechanismName" :value="item.mechanismId">
              </el-option>
            </el-select>
          </span>
          <span class="statusFiltering">{{$t("popup.case_allot.filterby_uploadTime")}}：</span>
          <span>
            <el-date-picker v-model="timeValue" type="daterange" :start-placeholder="$t('select_button.startDate')" :end-placeholder="$t('select_button.endDate')" value-format="yyyy-MM-dd" :clearable="true">
            </el-date-picker>
          </span>
        </div>
        <div class="screenBox">
          <span class="statusFiltering">{{$t("popup.case_allot.filterby_status")}}：</span>
          <span>
            <el-select v-model="transferStatus" :clearable="true">
              <el-option v-for="item in transferStatusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </span>
          <span class="statusFiltering">{{$t('caseAllocation.filter_by_progress')}}</span>
          <span>
            <el-select v-model="progressId" :clearable="true">
              <el-option v-for="item in progressList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </span>
          <span class="statusFiltering">{{$t('caseAllocation.filter_by_person')}}</span>
          <span>
            <el-select v-model="userId" :clearable="true">
              <el-option v-for="(item, index) in belongUserList" :key="index" :label="item.userName" :value="item.userId">
              </el-option>
            </el-select>
          </span>
          <div class="btn determineNomal" @click="searchFun">
            {{$t("popup.case_allot.search")}}
          </div>
          <div class="btn determineNomal senior-btn" @click="seniorSetup">
            {{$t('caseAllocation.advanced_setup')}}
          </div>
        </div>
        <div class="caseAllocationContent">
          <el-table :data="tableData" stripe v-el-table-infinite-scroll="load" ref="multipleTable" :empty-text="$t('checkhtml.no_data')">
            <el-table-column width="40">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.checked" @change="selectionChange(scope.row)"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('popup.case_allot.case_code')" align="center">
            </el-table-column>
            <el-table-column :label="$t('popup.case_allot.uploadDate')" align="center">
              <template slot-scope="scope">
                <!-- <el-popover trigger="hover" placement="top"> -->
                <p>{{ scope.row.createTime }}</p>
                <!-- <div slot="reference">
                    {{ scope.row.createTime }}
                  </div>
                </el-popover> -->
              </template>
            </el-table-column>
            <el-table-column prop="belongUserName" :label="$t('caseAllocation.belonger')" align="center">
              <template slot-scope="scope">
                <span class="belong-name"><i :style="{background:scope.row.belongUserColor}"></i>{{ scope.row.belongUserName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="statusName" :label="$t('caseAllocation.progress')" align="center"></el-table-column>
            <el-table-column prop="isTransferName" :label="$t('caseAllocation.transfer_status')" align="center"> </el-table-column>
          </el-table>
          <!-- <div class="last-line">
            <el-checkbox v-model="checkAll" @change="selAll()" class="check-box">{{$t('caseAllocation.all_checked')}}</el-checkbox>
            <span class="last-sp">{{$t('caseAllocation.checked')}}<i>{{patientIdsList.length}}</i>{{$t('unit.ge')}}</span>
            <span class="last-sp">{{$t('caseAllocation.all_cases')}}<i>{{total}}</i>{{$t('unit.ge')}}</span>
            <div class="allocation">
              <span>{{$t('caseAllocation.assign_to')}}</span>
              <el-select v-model="allocUserId">
                <el-option v-for="(item, index) in analysisUserList" :key="index" :label="item.userName" :value="item.userId">
                </el-option>
              </el-select>
            </div>
          </div> -->
        </div>
        <div class="caseAllocationPaging">
          <!-- <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next" :total="total" background>
          </el-pagination>
          <div class="jumperPage">
            <span>{{$t('pages.to')}}</span>
            <el-input v-model="jumperPageValue" @input="jumperPageValueFun($event)"></el-input>
            <span>{{$t('pages.page')}}</span>
          </div>
          <div class="btn determineNomal" @click="jumperPageFun">
            {{$t('btns.confirm')}}
          </div> -->
          <div class="last-line">
            <el-checkbox v-model="checkAll" @change="selAll()" class="check-box">{{$t('caseAllocation.all_checked')}}</el-checkbox>
            <span class="last-sp">{{$t('caseAllocation.checked')}}<i>{{patientIdsList.length}}</i>{{$t('unit.ge')}}</span>
            <span class="last-sp">{{$t('caseAllocation.all_cases')}}<i>{{total}}</i>{{$t('unit.ge')}}</span>
            <div class="allocation">
              <span>{{$t('caseAllocation.assign_to')}}</span>
              <el-select v-model="allocUserVal" class="alloc_belonger">
                <el-option v-for="(item, index) in analysisUserList" :key="index" :label="item.userName" :value="item.userId">
                </el-option>
              </el-select>
            </div>
            <el-button class="btn determineNomal" type="primary" @click="sunbmit">{{$t('caseAllocation.confirm_assigned')}}</el-button>
          </div>
          <!-- <div class="btns-right">
            <el-button class="btn updateBtn" @click="cancel">{{$t('btns.cancel')}}</el-button>
            <el-button class="btn determineNomal" type="primary" @click="sunbmit">{{$t('caseAllocation.confirm_assigned')}}</el-button>
          </div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>
 
<script>
import "../../assets/scss/button.scss";
import "../../assets/scss/dialog.scss";
import { reg } from "./../../assets/js/reg.js";
import common from "../../assets/js/common.js";
export default {
  name: "CaseAllocation",
  props: ["dialogVisible", "patientName", "patientId"],
  data() {
    return {
      isIndeterminate: false, //对el-checkbox控制不完整的全选状态
      checkAll: false, //对el-checkbox控制全选状态
      newDialogVisible: false,
      tableData: [],
      limit: 10,
      timeValue: [],
      currentPage: 1,
      pageSize: 0,
      totalPage: 0,
      total: 0,
      jumperPageValue: "1",
      singlePatientId: "",
      patientIds: "",
      patientIdsList: [],
      userDistributionList: [],
      isDisable: false,
      mechanismList: [], //来源
      mechanismId: "",
      belongUserList: [], //归属用户
      userId: "",
      analysisUserList: [], //分配人
      allocUserVal: "",
      progressId: "",
      transferStatus: "",
      chosedCasesNum: 0,
      search: "",
      loading: false,
    };
  },
  created() {
    // this.getTransInOrgList(); //来源
    const setID = () => {
      this.mechanismList = this.$store.state.mechanismList;
      this.mechanismId = this.$store.state.mechanismList[0] && this.$store.state.mechanismList[0].mechanismId;
    }
    try {
      setID();
    } catch (error) {
      setTimeout(() => {
        setID();
      }, 500)
    }
  },
  computed: {
    transferStatusList() {
      return [
        {
          value: "0",
          label: this.$t('caseAllocation.no_transfer'),
        },
        {
          value: "1",
          label: this.$t('caseAllocation.transfered'),
        },
      ];
    },
    progressList() {
      return [
        {
          value: "1",
          label: this.$t('caseAllocation.auto_completetly_analysis'),
        },
        {
          value: "2",
          label: this.$t('caseAllocation.manu_completetly_analysis'),
        },
      ];
    },
  },
  methods: {
    seniorSetup() {
      this.$router.push({
        name: "个人设置页",
        params: { tabType: "team_work_setting" },
      });
      this.newDialogVisible = false;
      this.$emit("childFun", this.newDialogVisible);
    },

    getDisPatientUserList() {
      this.$api.getDisPatientUserList({}).then((res) => {
        if (res.code == 200) {
          this.belongUserList = res.data.belongUserList;
          this.analysisUserList = res.data.analysisUserList;
          this.allocUserVal = this.analysisUserList.length
            ? this.analysisUserList[0].userId
            : "";
        } else {
          this.$message.error(res.message);
        }
      });
    },
    load() {
      this.currentPage += 1; //页数+1
      if (this.currentPage > this.totalPage) {
        this.currentPage = this.totalPage;
        return;
      }
      this.getPatientPListLabor(); //调用接口，此时页数+1，查询下一页数据
    },
    getPatientPListLabor() {
      let srcMechanismId = this.mechanismId != this.$store.state.labValue.mechanismId ? this.mechanismId : "";
      let postData = {
        limit: this.limit,
        page: this.currentPage,
        srcMechanismId: srcMechanismId, // 来源机构ID
        belongUserId: this.userId, // 归属用户ID
        progressStatus: this.progressId, // 进度： 0：未分析； 1：已自动分析； 2：已手动分析
        transferStatus: this.transferStatus, // 转交状态：0：初始化   1：未分析     2：已完成'
        startTime: this.timeValue[0],
        endTime: this.timeValue[1],
        search: this.search,
        patientId: this.singlePatientId,
        operationType: 1//病例分配
      };
      this.$api.patientPListLabor(postData).then((res) => {
        if (res.code == 200) {
          const { currPage, list, pageSize, totalCount, totalPage } = res.data;
          this.currentPage = currPage;
          this.pageSize = pageSize;
          list.forEach((item) => {
            switch (item.status) {
              case 0:
                item.statusName = this.$t('caseAllocation.no_analysis');
                break;
              case 1:
                item.statusName = this.$t('caseAllocation.auto_completetly_analysis');
                break;
              case 2:
                item.statusName = this.$t('caseAllocation.manu_completetly_analysis');
                break;
            }
            switch (item.isTransfer) {
              case 0:
                item.isTransferName = this.$t('caseAllocation.no_transfer');
                break;
              case 1:
                item.isTransferName = this.$t('caseAllocation.transfered');
                break;
            }
            item.createTime = common.timestampFormat(item.createTime, "yyyy-MM-dd");
            item.checked = false;
          });
          this.tableData = this.tableData.concat(list);
          this.total = totalCount;
          this.jumperPageValue = currPage;
          this.totalPage = totalPage;
          this.checkAll = false;
          // this.patientIdsList = [];
        } else {
          // this.$message.error(res.message);
        }
      });
    },
    selAll() {
      if (this.checkAll) {
        this.patientIdsList = [];
        this.tableData.forEach(item => {
          item.checked = true
          this.patientIdsList.push(item.id);
        })
      } else {
        this.tableData.forEach(item => {
          item.checked = false;
        })
        this.patientIdsList = [];
      }
      console.log(this.checkAll, this.tableData);
    },
    selectionChange() {
      this.checkAll = this.tableData.every(a => a.checked);
      this.patientIdsList = [];
      this.tableData.forEach(item => {
        item.checked && this.patientIdsList.push(item.id);
      })
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPatientPListLabor();
    },
    jumperPageFun() {
      this.currentPage = this.jumperPageValue;
      this.getPatientPListLabor();
    },
    searchFun() {
      this.tableData = [];
      this.currentPage = 1;
      this.patientIdsList = [];
      this.getPatientPListLabor();
    },
    closeFun() {
      this.newDialogVisible = false;
      this.$emit("childFun", this.newDialogVisible);
    },
    cancel() {
      this.newDialogVisible = false;
      this.$emit("childFun", this.newDialogVisible);
    },
    jumperPageValueFun(value) {
      if (reg.pureNumbers.test(value)) {
        this.jumperPageValue = value.replace(reg.pureNumbers, "");
      } else {
        if (value === "0") {
          this.jumperPageValue = 1;
        }
      }
    },
    sunbmit() {
      if (!this.patientIdsList.length) {
        this.$message.error(this.$t('caseAllocation.choose_allot_cases'));
        return;
      } else if (!this.allocUserVal) {
        this.$message.error(this.$t('caseAllocation.choose_assigner'));
        return;
      }
      this.patientIds = this.patientIdsList.toString();

      let postData = {
        userId: this.allocUserVal, //转交用户
        patientIds: this.patientIds,
        // isOfficial: 0,
        // isWhole: 0, // 是否全选 1：全选  0：不全选
      };
      this.$api.patientDisPatient(postData).then((res) => {
        if (res.code == 200) {
          let color;
          this.analysisUserList.forEach(item => {
            if (item.userId === this.allocUserVal) {
              color = item.color;
            }
          })
          let obj = {
            patientIdsList: this.patientIdsList,
            belongUserColor: color
          }
          this.$store.commit('allocUserColorSave', obj)
          this.tableData = [];
          this.currentPage = 1;
          this.patientIdsList = [];
          this.getPatientPListLabor();
          this.$message.success(res.message);
          this.newDialogVisible = false;
          this.$emit("childFun", this.newDialogVisible);
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  watch: {
    dialogVisible(val) {
      this.newDialogVisible = val;
      this.tableData = [];
      this.patientIdsList = [];
      if (val) {
        this.currentPage = 1;
        this.jumperPageValue = "1";
        this.search = this.patientName;
        this.singlePatientId = this.patientId;
        this.getDisPatientUserList(); //归属用户，分配人
        this.getPatientPListLabor();
      }
    },
    search(val) {
      if (!val) {
        this.singlePatientId = "";
      }
    },
    "$store.state.mechanismList": function (val) {
      this.mechanismList = val || [];
      this.mechanismId = val[0].mechanismId;
    },
    timeValue(val) {
      if (!val) {
        this.timeValue = [];
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.caseAllocationBox {
  .belong-name {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      width: 6px;
      height: 6px;
      margin-right: 5px;
      border-radius: 6px;
      // background: chartreuse;
    }
  }
  .caseAllocationContent {
    width: 960px;
    height: 475px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
  }
  .caseAllocationPaging {
    position: relative;
    margin: 10px 0;
    display: flex;
    height: 35px;
    .jumperPage {
      display: flex;
      align-items: center;
      & > :nth-child(1),
      & > :nth-child(3) {
        font-size: 16px;
        font-family: AlibabaPuHuiTiR;
        color: #666666;
      }

      & > :nth-child(2) {
        margin: 0 12px;
      }
    }

    .determineNomal {
      position: absolute;
      right: 0;
      padding: 0;
      width: 84px;
      height: 30px;
    }
  }
  .screenBox {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    // .box {
    //   display: flex;
    // }
    .statusFiltering {
      display: flex;
      align-items: center;
      margin-left: 20px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      text-align: right;
      word-break: keep-all;
      &:first-child {
        margin-left: 0;
      }
    }
    .determineNomal {
      width: unset;
      height: 40px;
      font-size: 16px;
      padding: 0;
      margin-left: 20px;
      margin-right: 0;
      &.senior-btn {
        background: #f7f7f8;
        border-radius: 4px;
        border: 1px solid #999999;
        color: #333333;
        margin-left: 10px;
      }
    }
    & > :nth-child(2) {
      display: flex;

      .disableAddReport {
        line-height: 16px;
        margin-left: 20px;
        width: 98px;
        height: 38px;
        font-size: 16px;
      }
      .everbright {
        margin-left: 20px;
      }
    }
  }

  .uploadTimeScreening {
    & > :nth-child(1) {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    & > :nth-child(2) {
      margin-left: 8px;
    }
  }
  .btnBox {
    display: flex;
    justify-content: flex-end;
    .updateBtn {
      width: 80px;
      height: 30px;
    }
    .determineNomal {
      min-width: 70px !important;
      height: 32px;
      margin: 0 6px 0 12px;
    }
  }
  .userPopup {
    .el-select {
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
.caseAllocationBox {
  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 288px;
  }
  .el-select-dropdown__item.selected {
    color: #4773b8;
  }
  .el-select .el-input .el-select__caret {
    height: 36px;
  }
  .allocation .el-select .el-input .el-select__caret {
    height: 30px;
  }
  .el-select-dropdown__item {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .screenBox {
    &.firstline {
      .el-input__inner {
        width: 206px;
      }
    }
    .el-input__suffix {
      top: 5px;
    }
    .el-select .el-input .el-select__caret.is-reverse {
      transform: rotateZ(0) translateY(-5px);
    }
  }
  .el-input__suffix {
    right: 2px;
    top: 10px;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    color: #4773b8;
  }
  .el-select .el-input__inner:focus {
    border: 1px solid #4773b8;
  }

  .el-range-editor.is-active,
  .el-range-editor.is-active:hover {
    border: 1px solid #4773b8;
  }
  .el-select.alloc_belonger {
    .el-input .el-select__caret.is-reverse {
      transform: rotateZ(0) translateY(-10px);
    }
  }

  .caseAllocationPaging {
    // .el-input {
    //   width: 50px;
    //   height: 30px;
    //   border: none;
    //   display: flex;
    // }

    .el-input__inner {
      width: 50px;
      height: 30px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #e3e3e3;
      padding: 0 5px;
    }
  }

  .userPopup {
    .el-dialog__footer {
      display: flex;
      justify-content: flex-end;
    }
  }
  .el-table {
    height: 476px;
    overflow: auto;
  }
  .el-table th > .cell {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    padding: 0 14px;
    word-break: keep-all;
  }

  .el-table__body-wrapper {
    margin-top: 61px;
  }

  .el-table td,
  .el-table th {
    padding: 0;
  }

  .el-table th,
  .el-table tr {
    height: 46px;
  }

  .el-table td div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }

  .el-checkbox__inner:hover {
    border-color: #4773b8;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #4773b8;
  }

  .el-button--text {
    width: unset;
    color: #4773b8 !important;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #4773b8;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background: #ffffff;
    border: 1px solid #e3e3e3;
  }
  .el-input--suffix .el-input__inner {
    width: 136px;
    padding-right: 20px;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 0;
  }
  .last-line {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
    font-size: 14px;
    background: #e4efff;
    border-radius: 5px;
    border: 1px solid #9cb9e6;
    .check-box {
      margin: 0 20px 0 14px;
    }
    .last-sp {
      margin-right: 10px;
      i {
        color: #4773b8;
        font-style: normal;
        margin: 0 3px;
      }
    }
    .allocation {
      position: absolute;
      right: 108px;
      height: 40px;
      line-height: 40px;
      .el-select {
        width: 130px;
        height: 30px;
        .el-input__inner {
          width: 130px;
          height: 30px;
          border: 0;
        }
      }
    }
  }
  .btns-right {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    .updateBtn {
      min-width: 70px !important;
      height: 40px;
    }
    .determineNomal {
      min-width: 70px !important;
      height: 40px !important;
      margin: 0 6px 0 12px;
    }
  }
}
</style>
