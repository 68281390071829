<template>
  <div class="risk">
    <!--<p class="times">更新时间:2019年5月8日</p>-->
    <h3 class="riskH3">{{$t('statement.line1')}}</h3>
    <p>{{$t('statement.line2')}}</p>
    <p>{{$t('statement.line3')}}</p>
    <p>{{$t('statement.line4')}}</p>
    <p>{{$t('statement.line5')}}</p>
    <p>{{$t('statement.line6')}}</p>
    <p>{{$t('statement.line7')}}</p>
    <!-- <p>{{$t('statement.line8')}}</p> -->
    <p>{{$t('statement.line9')}}</p>
    <p>{{$t('statement.line10')}}</p>
    <p>{{$t('statement.line11')}}</p>
    <p>{{$t('statement.line12')}}</p>
    <p>{{$t('statement.line13')}}</p>
    <p>{{$t('statement.line14')}}</p>
    <p>{{$t('statement.line15')}}</p>
    <p>{{$t('statement.line16')}}</p>
    <p>{{$t('statement.line17')}}</p>
    <p>{{$t('statement.line18')}}</p>
    <p>{{$t('statement.line19')}}</p>
    <p>{{$t('statement.line20')}}</p>
    <p>{{$t('statement.line21')}}</p>
    <p>{{$t('statement.line22')}}</p>
    <p>{{$t('statement.line23')}}</p>
    <p>{{$t('statement.line24')}}</p>
    <p>{{$t('statement.line25')}}</p>
    <p>{{$t('statement.line26')}}</p>
    <p>{{$t('statement.line27')}}</p>
    <p>{{$t('statement.line28')}}</p>
    <p>{{$t('statement.line29')}}</p>
    <p>{{$t('statement.line30')}}</p>
    <p>{{$t('statement.line31')}}</p>
    <p>{{$t('statement.line32')}}</p>
    <p>{{$t('statement.line33')}}</p>
    <p>{{$t('statement.line34')}}</p>
    <p>{{$t('statement.line35')}}</p>
    <p>{{$t('statement.line36')}}</p>
    <p>{{$t('statement.line37')}}</p>
    <p>{{$t('statement.line38')}}</p>
    <p>{{$t('statement.line39')}}</p>
    <p>{{$t('statement.line40')}}</p>
    <p>{{$t('statement.line41')}}</p>
    <p>{{$t('statement.line42')}}</p>
    <p>{{$t('statement.line43')}}</p>
    <p>{{$t('statement.line44')}}</p>
    <p>{{$t('statement.line45')}}</p>
    <p>{{$t('statement.line46')}}</p>
    <p>{{$t('statement.line47')}}</p>
    <p>{{$t('statement.line48')}}</p>
    <p>{{$t('statement.line49')}}</p>
    <p>{{$t('statement.line50')}}</p>
    <p>{{$t('statement.line51')}}</p>
    <p>{{$t('statement.line52')}}</p>
    <p>{{$t('statement.line53')}}</p>
    <p>{{$t('statement.line54')}}</p>
    <p>{{$t('statement.line55')}}</p>
    <p>{{$t('statement.line56')}}</p>
    <p>{{$t('statement.line57')}}</p>
    <p>{{$t('statement.line58')}}</p>
    <p>{{$t('statement.line59')}}</p>
    <p>{{$t('statement.line60')}}</p>
    <p>{{$t('statement.line61')}}</p>
    <p>{{$t('statement.line62')}}</p>
    <p>{{$t('statement.line63')}}</p>
    <p>{{$t('statement.line64')}}</p>
    <p>{{$t('statement.line65')}}</p>
    <p>{{$t('statement.line66')}}</p>
    <p>{{$t('statement.line67')}}</p>
    <p>{{$t('statement.line68')}}</p>
    <p>{{$t('statement.line69')}}</p>
    <p>{{$t('statement.line70')}}</p>
    <p>{{$t('statement.line71')}}</p>
    <p>{{$t('statement.line72')}}</p>
    <p>{{$t('statement.line73')}}</p>
    <p>{{$t('statement.line74')}}</p>
    <p>{{$t('statement.line75')}}</p>
    <p>{{$t('statement.line76')}}</p>
    <p>{{$t('statement.line77')}}</p>
    <p>{{$t('statement.line78')}}</p>
    <p>{{$t('statement.line79')}}</p>
    <p>{{$t('statement.line80')}}</p>
    <p>{{$t('statement.line81')}}</p>
    <p>{{$t('statement.line82')}}</p>
    <p>{{$t('statement.line83')}}</p>
    <p>{{$t('statement.line84')}}</p>
    <p>{{$t('statement.line85')}}</p>
    <p>{{$t('statement.line86')}}</p>
    <p>{{$t('statement.line87')}}</p>
    <p>{{$t('statement.line88')}}</p>
    <p>{{$t('statement.line89')}}</p>
    <p>{{$t('statement.line90')}}</p>
    <p>{{$t('statement.line91')}}</p>
    <p>{{$t('statement.line92')}}</p>
    <p>{{$t('statement.line93')}}</p>
    <p>{{$t('statement.line94')}}</p>
    <p>{{$t('statement.line95')}}</p>
    <p>{{$t('statement.line96')}}</p>
    <p>{{$t('statement.line97')}}</p>
    <p>{{$t('statement.line98')}}</p>
    <p>{{$t('statement.line99')}}</p>
    <p>{{$t('statement.line100')}}</p>
    <p>{{$t('statement.line101')}}</p>
    <p>{{$t('statement.line102')}}</p>
    <p>{{$t('statement.line103')}}</p>
    <p>{{$t('statement.line104')}}</p>
    <p>{{$t('statement.line105')}}</p>
    <p>{{$t('statement.line106')}}</p>
    <p>{{$t('statement.line107')}}</p>
    <p>{{$t('statement.line108')}}</p>
    <p>{{$t('statement.line109')}}</p>
    <p>{{$t('statement.line110')}}</p>
  </div>
</template>

<script>
export default {
  name: "Statement",
  created() {
    document.title = this.$t('checkhtml.privacy_statement');
  },
  data() {
    return {}
  },
  methods: {},
  watch: {
    "$store.state.language": function () {
      if (this.$route.path == '/statement') {
        document.title = this.$t('checkhtml.privacy_statement');
      }
    },
    $route: function () {
      if (this.$route.path == '/statement') {
        document.title = this.$t('checkhtml.privacy_statement');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.risk {
  width: 80%;
  margin: 0 auto;
}
.riskH3 {
  width: 100%;
  height: 50px;
  margin: 30px 0;
  text-align: center;
  line-height: 50px;
  font-size: 25px;
  color: #000000;
}
.riskH4 {
  /*     height:30px; */
  font-size: 16px;
  line-height: 30px;
  color: #666;
}
.risk p {
  line-height: 30px;
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  text-indent: 32px;
}
.commonP {
  text-align: center;
  color: #000000 !important;
  text-indent: 0 !important;
}

.changeColor {
  color: #e43f47;
}
.times {
  line-height: 30px;
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  text-indent: 32px;
  text-align: right;
}
</style>
