import {
    SELECT,
    ESIGNATURE,
    KARYO,
    SEQUENCE,
    DIV,
    DATE,
    IMGPOSITION
} from "../../config/props.js";
import uploader from "../../../../assets/utils/fileUploader.js";
import colorPicker from "../../../personalSettings/components/color-picker.vue";
export default {
    created() {},
    data() {
        return {
            ftzShowing: false,
            ftFmShowing: false,
            ftwShowing: false,
            fmatShowing: false,
            ftFmOptions: [
                {
                    fontFamily: "SimSun",
                    fontFamilyText: "template_choices.SimSun"
                },
                {
                    fontFamily: "SimHei",
                    fontFamilyText: "template_choices.SimHei"
                },
                {
                    fontFamily: "Microsoft YaHei",
                    fontFamilyText: "template_choices.Microsoft_YaHei"
                }
            ],
            options: [
                {
                    fontSize: 34,
                    fontText: "template_choices.font_one"
                },
                {
                    fontSize: 32,
                    fontText: "template_choices.font_smallone"
                },
                {
                    fontSize: 29,
                    fontText: "template_choices.font_two"
                },
                {
                    fontSize: 24,
                    fontText: "template_choices.font_smalltwo"
                },
                {
                    fontSize: 21,
                    fontText: "template_choices.font_three"
                },
                {
                    fontSize: 20,
                    fontText: "template_choices.font_smallthree"
                },
                {
                    fontSize: 18,
                    fontText: "template_choices.font_four"
                },
                {
                    fontSize: 16,
                    fontText: "template_choices.font_smallfour"
                },
                {
                    fontSize: 14,
                    fontText: "template_choices.font_five"
                },
                {
                    fontSize: 12,
                    fontText: "template_choices.font_smallfive"
                }
            ],
            fWeightOptions: [
                {
                    fontWeight: 200,
                    fontWord: "template_choices.font_slender"
                },
                {
                    fontWeight: 400,
                    fontWord: "template_choices.font_normal"
                },
                {
                    fontWeight: 700,
                    fontWord: "template_choices.font_bold"
                }
            ],
            formatOptions: [
                {
                    format: "yyyy-MM-dd",
                    formatText: "2020-01-01",
                    datetype: "date"
                },
                {
                    format: "yyyy年M月d日",
                    formatText: "2020年1月1日",
                    datetype: "date"
                },
                {
                    format: "yyyy/MM/dd",
                    formatText: "2020/01/01",
                    datetype: "date"
                },
                {
                    format: "yyyy/M/d",
                    formatText: "2020/1/1",
                    datetype: "date"
                },
                {
                    format: "yy/M/d",
                    formatText: "20/1/1",
                    datetype: "date"
                },
                {
                    format: "M/d/yy",
                    formatText: "1/1/20",
                    datetype: "date"
                },
                {
                    format: "MM/dd/yy",
                    formatText: "01/01/20",
                    datetype: "date"
                },
                {
                    format: "yyyy-MM-dd HH:mm:ss",
                    formatText: "2020-01-01 23:24:25",
                    datetype: "datetime"
                }
            ],
            fontText: "",
            formatText: "",
            fontWord: "",
            fontFamilyText: "",
            selectable: false,
            details: null,
            SELECT,
            ESIGNATURE,
            KARYO,
            SEQUENCE,
            DATE
        };
    },
    components: {
        colorPicker
    },
    computed: {
        activeRect() {
            return this.$store.getters["rect/getActive"];
        },
        imgOptions() {
            if (
                !!this.activeRect &&
                this.$store.state.rect.rects[this.activeRect].prop ===
                    ESIGNATURE
            ) {
                return this.$store.state.rect.rects[this.activeRect].options;
            } else {
                return [];
            }
        },
        inputList() {
            return this.activeRect === null
                ? ""
                : this.$store.state.rect.rects[this.activeRect].options;
        },

        width() {
            return this.activeRect === null
                ? ""
                : this.$store.state.rect.rects[this.activeRect].styles.width;
        },

        height() {
            return this.activeRect === null
                ? ""
                : Math.round(
                      this.$store.state.rect.rects[this.activeRect].styles
                          .height
                  );
        },

        fontSize() {
            return this.activeRect === null
                ? ""
                : this.$store.state.rect.rects[this.activeRect].styles.fontSize;
        },
        fontFamily() {
            return this.activeRect === null
                ? ""
                : this.$store.state.rect.rects[this.activeRect].styles
                      .fontFamily;
        },

        relationNum() {
            return this.activeRect === null
                ? ""
                : this.$store.state.rect.rects[this.activeRect].configObj
                      .relationNum;
        },

        color: {
            get() {
                return this.activeRect === null
                    ? ""
                    : this.$store.state.rect.rects[this.activeRect].styles
                          .color;
            },
            set(newVal) {
                let color = newVal;

                if (this.$store.state.rect.rects[this.activeRect].prop == DIV) {
                    this.$emit("colorFun", color);
                    return;
                }

                this.$store.dispatch("rect/setColor", {
                    id: this.activeRect,
                    color: color
                });
            }
        },

        fontWeight() {
            return this.activeRect === null
                ? ""
                : this.$store.state.rect.rects[this.activeRect].styles
                      .fontWeight;
        },

        format() {
            return this.activeRect === null
                ? ""
                : this.$store.state.rect.rects[this.activeRect].configObj
                      .format;
        },

        top() {
            return this.activeRect === null
                ? ""
                : this.$store.state.rect.rects[this.activeRect].styles.top;
        },

        left() {
            return this.activeRect === null
                ? ""
                : Math.round(
                      this.$store.state.rect.rects[this.activeRect].styles.left
                  );
        },

        aspectRatio() {
            return this.activeRect === null
                ? false
                : this.$store.state.rect.rects[this.activeRect].configObj
                      .aspectRatio;
        },

        parentLim() {
            return this.activeRect === null
                ? false
                : this.$store.state.rect.rects[this.activeRect].configObj
                      .parentLim;
        },

        draggable() {
            return this.activeRect === null
                ? false
                : this.$store.state.rect.rects[this.activeRect].configObj
                      .draggable;
        },

        showLabelLine() {
            return this.activeRect === null
                ? false
                : this.$store.state.rect.rects[this.activeRect].configObj
                      .showLabelLine;
        },

        showDashedLine() {
            return this.activeRect === null
                ? false
                : this.$store.state.rect.rects[this.activeRect].configObj
                      .showDashedLine;
        },
        isRequired() {
            return this.activeRect === null
                ? false
                : this.$store.state.rect.rects[this.activeRect].configObj
                      .isRequired;
        },

        resizable() {
            return this.activeRect === null
                ? false
                : this.$store.state.rect.rects[this.activeRect].configObj
                      .resizable;
        },

        snapToGrid() {
            return this.activeRect === null
                ? false
                : this.$store.state.rect.rects[this.activeRect].configObj
                      .snapToGrid;
        },

        topIsLocked() {
            if (this.activeRect === null) {
                return false;
            }
            return (
                this.$store.state.rect.rects[this.activeRect].configObj.axis ===
                    "x" ||
                this.$store.state.rect.rects[this.activeRect].configObj.axis ===
                    "none"
            );
        },

        leftIsLocked() {
            if (this.activeRect === null) {
                return false;
            }
            return (
                this.$store.state.rect.rects[this.activeRect].configObj.axis ===
                    "y" ||
                this.$store.state.rect.rects[this.activeRect].configObj.axis ===
                    "none"
            );
        },

        zIndex() {
            if (this.activeRect === null) {
                return null;
            }

            return this.$store.state.rect.rects[this.activeRect].configObj
                .zIndex === 1
                ? "isFirst"
                : this.$store.state.rect.rects[this.activeRect].configObj
                      .zIndex === this.$store.state.rect.rects.length
                ? "isLast"
                : "normal";
        }
    },
    mounted() {
        document.addEventListener("click", this.closePop);
    },
    methods: {
        beforeUpload(file) {
            if (
                this.imgOptions.some(item => {
                    return item.name == file.name;
                })
            )
                return false;
        },
        /**
         * @method: 上传图片
         * @Date: 2020-12-22 10:11:46
         */
        uploadImg(rect, e) {
            const _file = e.file;
            if (!_file) return;

            const isLt10M = _file.size / 1024 / 1024 < 10;
            this.$forceUpdate();
            if (isLt10M) {
                uploader({
                    files: [_file],
                    path: "custom/template/",
                    processHandle: file => {},
                    ossUploadAccess: this.$store.state.ossUploadAccess
                }).then(filelist => {
                    let imgUrl = filelist[0].path;
                    const name = filelist[0].file.name;
                    if (!!rect) {
                        this.imgOptions.push({
                            name: name,
                            imgUrl: imgUrl
                        });
                        rect.options = this.imgOptions;
                        console.log(rect.options);
                    }
                });
            } else {
                this.$message.error(
                    window.i18n.t("organize_approve.upload_image_demand")
                );
            }
        },
        deleteImg(file) {
            this.imgOptions.forEach((item, index) => {
                if (item.name === file.name) {
                    this.imgOptions.splice(index, 1);
                }
            });
        },
        closePop() {
            this.ftzShowing = false;
            this.ftwShowing = false;
            this.fmatShowing = false;
            this.ftFmShowing = false;
        },
        deleteChoice(index) {
            this.inputList.splice(index, 1);
        },
        addSelections() {
            this.inputList.push(this.details);
            this.details = "";
        },
        toggleYLock() {
            if (this.activeRect === null) {
                return;
            }

            this.$store.dispatch("rect/changeYLock", {
                id: this.activeRect
            });
        },
        toggleXLock() {
            if (this.activeRect === null) {
                return;
            }

            this.$store.dispatch("rect/changeXLock", {
                id: this.activeRect
            });
        },

        toggleAspect() {
            if (this.activeRect === null) {
                return;
            }
            if (!this.$store.state.rect.rects[this.activeRect].aspectRatio) {
                this.$store.dispatch("rect/setAspect", {
                    id: this.activeRect
                });
            } else {
                this.$store.dispatch("rect/unsetAspect", {
                    id: this.activeRect
                });
            }
        },

        toggleParentLimitation() {
            this.$store.dispatch("rect/toggleParentLimitation", {
                id: this.activeRect
            });
        },

        toggleResizable() {
            this.$store.dispatch("rect/toggleResizable", {
                id: this.activeRect
            });
        },

        toggleDraggable() {
            this.$store.dispatch("rect/toggleDraggable", {
                id: this.activeRect
            });
        },

        toggleSnapToGrid() {
            this.$store.dispatch("rect/toggleSnapToGrid", {
                id: this.activeRect
            });
        },

        changeLabelCss() {
            this.$store.dispatch("rect/changeLabelCss", {
                id: this.activeRect
            });
        },
        changeBorderCss() {
            this.$store.dispatch("rect/changeBorderCss", {
                id: this.activeRect
            });
        },
        toggleRequired() {
            this.$store.dispatch("rect/toggleRequired", {
                id: this.activeRect
            });
        },

        toTop() {
            this.$store.dispatch("rect/changeZToTop", {
                id: this.activeRect
            });
        },

        toBottom() {
            this.$store.dispatch("rect/changeZToBottom", {
                id: this.activeRect
            });
        },

        changeFontsize(val) {
            this.ftzShowing = false;

            let fontSize = parseInt(val.fontSize);

            if (this.$store.state.rect.rects[this.activeRect].prop == DIV) {
                this.$emit("fontSizeFun", fontSize);
                return;
            }

            if (typeof fontSize !== "number" || isNaN(fontSize)) {
                fontSize = this.$store.state.rect.rects[this.activeRect].styles
                    .fontSize;
                val = fontSize;
                return;
            }

            this.$store.dispatch("rect/setFontsize", {
                id: this.activeRect,
                fontSize: fontSize
            });
        },
        changeFontFamily(val) {
            this.ftFmShowing = false;

            let fontFamily = val.fontFamily;
            this.$store.dispatch("rect/setfontFamily", {
                id: this.activeRect,
                fontFamily: fontFamily
            });
        },

        changeFontweight(val) {
            this.ftwShowing = false;

            let fontWeight = val.fontWeight;

            if (this.$store.state.rect.rects[this.activeRect].prop == DIV) {
                this.$emit("fontWeightFun", fontWeight);
                return;
            }

            this.$store.dispatch("rect/setFontweight", {
                id: this.activeRect,
                fontWeight: fontWeight
            });
        },
        changeFormat(val) {
            this.fmatShowing = false;
            let format = val.format;
            let datatype = val.datetype;
            this.$store.dispatch("rect/setFormat", {
                id: this.activeRect,
                format: format,
                datatype: datatype
            });
        },

        changeTop(ev) {
            let top = parseInt(ev.target.value);

            if (typeof top !== "number" || isNaN(top)) {
                top = this.$store.state.rect.rects[this.activeRect].styles.top;
                ev.target.value = top;
                return;
            }

            this.$store.dispatch("rect/setTop", {
                id: this.activeRect,
                top: top
            });
        },

        changeLeft(ev) {
            let left = parseInt(ev.target.value);

            if (typeof left !== "number" || isNaN(left)) {
                left = this.$store.state.rect.rects[this.activeRect].styles
                    .left;
                ev.target.value = left;
            }

            this.$store.dispatch("rect/setLeft", {
                id: this.activeRect,
                left: left
            });
        },

        changeWidth(ev) {
            let width = parseInt(ev.target.value);

            if (typeof width !== "number" || isNaN(width)) {
                width = this.$store.state.rect.rects[this.activeRect].styles
                    .width;
                ev.target.value = width;
            }
            if (
                this.$store.state.rect.rects[this.activeRect].prop ==
                    IMGPOSITION &&
                width <
                    this.$store.state.rect.rects[this.activeRect].styles
                        .minWidth
            ) {
                width = parseInt(
                    this.$store.state.rect.rects[this.activeRect].styles
                        .minWidth
                );
            }

            this.$store.dispatch("rect/setWidth", {
                id: this.activeRect,
                width: width
            });
        },

        changeHeight(ev) {
            let height = parseInt(ev.target.value);

            if (typeof height !== "number" || isNaN(height)) {
                height = this.$store.state.rect.rects[this.activeRect].styles
                    .height;
                ev.target.value = height;
            }
            if (
                this.$store.state.rect.rects[this.activeRect].prop ==
                    IMGPOSITION &&
                height <
                    this.$store.state.rect.rects[this.activeRect].styles
                        .minHeight
            ) {
                height = parseInt(
                    this.$store.state.rect.rects[this.activeRect].styles
                        .minHeight
                );
            }

            this.$store.dispatch("rect/setHeight", {
                id: this.activeRect,
                height: height
            });
        }
    },
    watch: {
        fontFamily(val) {
            if (!val) {
                return (this.fontFamilyText = "");
            }
            let obj = {
                SimSun: "template_choices.SimSun",
                SimHei: "template_choices.SimHei",
                "Microsoft YaHei": "template_choices.Microsoft_YaHei"
            };
            Object.keys(obj).forEach(item => {
                if (item == val) {
                    return (this.fontFamilyText = obj[item]);
                }
            });
        },
        fontSize(val) {
            switch (val) {
                case 34:
                    return (this.fontText = "template_choices.font_one");
                case 32:
                    return (this.fontText = "template_choices.font_smallone");
                case 29:
                    return (this.fontText = "template_choices.font_two");
                case 24:
                    return (this.fontText = "template_choices.font_smalltwo");
                case 21:
                    return (this.fontText = "template_choices.font_three");
                case 20:
                    return (this.fontText = "template_choices.font_smallthree");
                case 18:
                    return (this.fontText = "template_choices.font_four");
                case 16:
                    return (this.fontText = "template_choices.font_smallfour");
                case 14:
                    return (this.fontText = "template_choices.font_five");
                case 12:
                    return (this.fontText = "template_choices.font_smallfive");
                default:
                    return (this.fontText = "template_choices.font_smallfour");
            }
        },
        fontWeight(val) {
            if (!val) {
                return (this.fontWord = "");
            }
            let obj = {
                200: "template_choices.font_slender",
                400: "template_choices.font_normal",
                700: "template_choices.font_bold"
            };
            Object.keys(obj).forEach(item => {
                if (item == val) {
                    return (this.fontWord = obj[item]);
                }
            });
        },
        format(val) {
            if (!val) {
                return (this.formatText = "");
            }
            let obj = {
                "yyyy/M/d": "2020/1/1",
                yyyy年M月d日: "2020年1月1日",
                "yy/M/d": "20/1/1",
                "M/d/yy": "1/1/20",
                "MM/dd/yy": "01/01/20",
                "yyyy/MM/dd": "2020/01/01",
                "yyyy-MM-dd": "2020-01-01",
                "yyyy-MM-dd HH:mm:ss": "2020-01-01 23:24:25"
            };
            Object.keys(obj).forEach(item => {
                if (item == val) {
                    return (this.formatText = obj[item]);
                }
            });
        }
    }
};
