import {
    getBase64FromEntriesPool
} from "../../../../assets/utils/downloadZip";
import CAlgoModule from "./cAlgoModuleFun.js";
import {
    cloneDeep
} from 'lodash';

class OpenCVCommon {
    constructor() {
        this.init()
    }

    async init() {
        if (!window.cv || !window.Module) {
            console.warn("OpenCVCommon need init")
            let loadCVAndAlgo = new CAlgoModule();
            loadCVAndAlgo = await loadCVAndAlgo
        }
    }

    async getJSDataPromiseAsync(obj) {
        // 单条染色体
        let chromoDataJS = window.Module.getJSchromo();
        chromoDataJS.relatedIndex = obj.relatedIndex ? obj.relatedIndex : -1;
        chromoDataJS.id = obj.id
        chromoDataJS.index = obj.index
        chromoDataJS.chromoUpright = obj.chromoUpright;
        if (obj.chromoUpright == null) {
            chromoDataJS.chromoUpright = 0;
        }
        chromoDataJS.chromoId = obj.chromoId;
        let chromoCategoryinfo = obj.chromoCategoryinfo
        let chromoCategoryinfoArr = obj.chromoCategoryinfo ? chromoCategoryinfo.split(',') : []
        let chromoDataJS_chromoCategoryInfo = chromoDataJS.chromoCategoryInfo;
        chromoCategoryinfoArr.forEach(element => {
            chromoDataJS_chromoCategoryInfo.push_back(Number(element))
        })
        chromoDataJS.chromoCategoryInfo = chromoDataJS_chromoCategoryInfo;
        chromoDataJS.cImgType = obj.cimgType;
        let div01 = document.getElementById('div01');
        let that = this;
        async function handle() {
            let count = 0;
            return new Promise((resolve, reject) => {
                const check = () => {
                    count++;
                    count === 3 && resolve(chromoDataJS);
                }
                // 读取页面上的旋转后的染色体数据
                let justUrl = obj.invertedUrl || obj.justUrl
                let imgJustUrl = new Image()
                imgJustUrl.crossOrigin = "anonymous"
                imgJustUrl.id = "imgRotated" + Math.random()
                imgJustUrl.onload = () => {
                    chromoDataJS.cImgRotated = that.getJSMatDataByCV(imgJustUrl, chromoDataJS.cImgRotated)
                    document.getElementById(imgJustUrl.id) && document.getElementById(imgJustUrl.id).remove();
                    check();
                }
                imgJustUrl.onerror = (e) => {
                    console.warn(e)
                    reject(e)
                }
                imgJustUrl.src = justUrl.indexOf("base64,") > -1 ? justUrl : getBase64FromEntriesPool(justUrl)
                div01.append(imgJustUrl);
                chromoDataJS.cImgPosition.cImgOrientation = obj.cimgOrientation
                // 读取页面上的掩码图的数据
                let externalUrl = obj.externalUrl
                let imgExternalUrl = new Image()
                imgExternalUrl.crossOrigin = "anonymous"
                imgExternalUrl.id = "imgMaskMap" + Math.random()
                imgExternalUrl.onload = () => {
                    chromoDataJS.cImgPosition.cImgMask = that.getJSMatDataByCV(imgExternalUrl, chromoDataJS.cImgPosition.cImgMask)
                    document.getElementById(imgExternalUrl.id) && document.getElementById(imgExternalUrl.id).remove();
                    check();
                }
                imgExternalUrl.onerror = (e) => {
                    console.warn(e)
                    reject(e)
                }
                imgExternalUrl.src = externalUrl.indexOf("base64,") > -1 ? externalUrl : getBase64FromEntriesPool(externalUrl)
                div01.append(imgExternalUrl)
                let cimgBoundingboxOffset = obj.cimgBoundingboxOffset
                let cimgBoundingboxOffsetArr = cimgBoundingboxOffset.split(',')
                var chromoDataJS_cImgPosition_cImgBoundingBoxOffset = chromoDataJS.cImgPosition.cImgBoundingBoxOffset
                cimgBoundingboxOffsetArr.forEach(element => {
                    chromoDataJS.cImgPosition.cImgBoundingBoxOffset.push_back(Number(element))
                });
                chromoDataJS.cImgPosition.cImgBoundingBoxOffset = chromoDataJS_cImgPosition_cImgBoundingBoxOffset;
                let cimgBoundingbox = obj._cimgBoundingbox || that.formatBoundingbox(obj.cimgBoundingbox)
                var chromoDataJS_cImgPosition_cImgBoundingBox = chromoDataJS.cImgPosition.cImgBoundingBox;
                chromoDataJS_cImgPosition_cImgBoundingBox.push_back(Number(cimgBoundingbox.y))
                chromoDataJS_cImgPosition_cImgBoundingBox.push_back(Number(cimgBoundingbox.x))
                chromoDataJS_cImgPosition_cImgBoundingBox.push_back(Number(cimgBoundingbox.h))
                chromoDataJS_cImgPosition_cImgBoundingBox.push_back(Number(cimgBoundingbox.w))
                chromoDataJS.cImgPosition.cImgBoundingBox = chromoDataJS_cImgPosition_cImgBoundingBox;
                // 读取页面上未旋转的染色体数据
                let primaryUrl = obj.primaryUrl
                let imgPrimaryUrl = new Image()
                imgPrimaryUrl.crossOrigin = "anonymous"
                imgPrimaryUrl.id = "imgNotRotated" + Math.random()
                imgPrimaryUrl.onload = () => {
                    chromoDataJS.cImg = that.getJSMatDataByCV(imgPrimaryUrl, chromoDataJS.cImg)
                    document.getElementById(imgPrimaryUrl.id) && document.getElementById(imgPrimaryUrl.id).remove()
                    check();
                }
                imgPrimaryUrl.onerror = (e) => {
                    console.warn(e)
                    reject(e)
                }
                imgPrimaryUrl.src = primaryUrl.indexOf("base64,") > -1 ? primaryUrl : getBase64FromEntriesPool(primaryUrl)
                div01.append(imgPrimaryUrl)
            })

        }

        await handle();

        return chromoDataJS;
    }

    formatBoundingbox(cimgBoundingbox) {
        const [y, x, h, w] = cimgBoundingbox.split(",").map(a => Number(a));
        return { y, x, w, h };
    }

    async getJSDataPromiseAsyncExternalUrl(obj) {
        // 单条染色体
        let chromoDataJS = window.Module.getJSchromo();
        chromoDataJS.relatedIndex = obj.relatedIndex ? obj.relatedIndex : -1;
        chromoDataJS.id = obj.id
        chromoDataJS.index = obj.index
        chromoDataJS.chromoUpright = obj.chromoUpright;
        if (obj.chromoUpright == null) {
            chromoDataJS.chromoUpright = 0;
        }
        chromoDataJS.chromoId = obj.chromoId;
        let chromoCategoryinfo = obj.chromoCategoryinfo
        let chromoCategoryinfoArr = obj.chromoCategoryinfo ? chromoCategoryinfo.split(',') : []
        let chromoDataJS_chromoCategoryInfo = chromoDataJS.chromoCategoryInfo;
        chromoCategoryinfoArr.forEach(element => {
            chromoDataJS_chromoCategoryInfo.push_back(Number(element))
        })
        chromoDataJS.chromoCategoryInfo = chromoDataJS_chromoCategoryInfo;
        chromoDataJS.cImgType = obj.cimgType;
        let div01 = document.getElementById('div01');
        let that = this;
        async function handle() {
            let count = 0;
            return new Promise((resolve, reject) => {
                chromoDataJS.cImgPosition.cImgOrientation = obj.cimgOrientation
                // 读取页面上的掩码图的数据
                let externalUrl = obj.externalUrl
                let imgExternalUrl = new Image()
                imgExternalUrl.crossOrigin = "anonymous"
                imgExternalUrl.onload = () => {
                    chromoDataJS.cImgPosition.cImgMask = that.getJSMatDataByCV(imgExternalUrl, chromoDataJS.cImgPosition.cImgMask)
                    document.getElementById(imgExternalUrl.id).remove();
                    resolve(chromoDataJS)
                }
                imgExternalUrl.onerror = (e) => {
                    console.warn(e)
                    reject(e)
                }
                imgExternalUrl.src = externalUrl.indexOf("base64,") > -1 ? externalUrl : getBase64FromEntriesPool(externalUrl)
                imgExternalUrl.id = "imgMaskMap" + Math.random()
                div01.append(imgExternalUrl)
                
                let cimgBoundingboxOffset = obj.cimgBoundingboxOffset
                let cimgBoundingboxOffsetArr = cimgBoundingboxOffset.split(',')
                var chromoDataJS_cImgPosition_cImgBoundingBoxOffset = chromoDataJS.cImgPosition.cImgBoundingBoxOffset
                cimgBoundingboxOffsetArr.forEach(element => {
                    chromoDataJS.cImgPosition.cImgBoundingBoxOffset.push_back(Number(element))
                });
                chromoDataJS.cImgPosition.cImgBoundingBoxOffset = chromoDataJS_cImgPosition_cImgBoundingBoxOffset;
                let cimgBoundingbox = obj.cimgBoundingbox
                // let cimgBoundingboxArr = cimgBoundingbox.split(',')
                var chromoDataJS_cImgPosition_cImgBoundingBox = chromoDataJS.cImgPosition.cImgBoundingBox;
                // cimgBoundingboxArr.forEach(element => {
                chromoDataJS_cImgPosition_cImgBoundingBox.push_back(Number(cimgBoundingbox.y))
                chromoDataJS_cImgPosition_cImgBoundingBox.push_back(Number(cimgBoundingbox.x))
                chromoDataJS_cImgPosition_cImgBoundingBox.push_back(Number(cimgBoundingbox.h))
                chromoDataJS_cImgPosition_cImgBoundingBox.push_back(Number(cimgBoundingbox.w))
                // });
                chromoDataJS.cImgPosition.cImgBoundingBox = chromoDataJS_cImgPosition_cImgBoundingBox;
               
                
            })

        }

        await handle();
        return chromoDataJS;
    }
    /**
     * 获取图片的JSMat对象
     * @param {*} parameter 图片
     */
    getJSMatData(parameter) {
        return new Promise((resolve, reject) => {
            if(!window.ModuleLoaded){
                reject({msg:'wasm模块加载失败'})
                return;
            }
            if (!(parameter instanceof Object)) {
                let div01 = document.getElementById('div01');
                let imgOriginal = new Image()
                imgOriginal.crossOrigin = "anonymous"
                imgOriginal.src = getBase64FromEntriesPool(parameter)
                imgOriginal.id = "imgOriginal" + Math.random()
                div01.append(imgOriginal)
                imgOriginal.onload = () => {
                    let optiJS = this.getJSMatDataByCV(imgOriginal);
                    document.getElementById(imgOriginal.id).remove()
                    resolve(optiJS)
                }
                imgOriginal.onerror = (e) => {
                    reject(e)
                }
            } else {
                let optiJS = this.getJSMatDataByCV(parameter);
                resolve(optiJS)
            }
        })
    }

    /**
     * 通过opencv 获取组装JSMat对象
     * @param {*} optiJS cols 宽；rows 高 data 数据
     * @param {*} parameter 图片、canvas对象
     */
    getJSMatDataByCV(parameter, srcJS) {
        let startTime = new Date().getTime()
        let original = window.cv.imread(parameter, 0)
        let optiStr = new window.cv.Mat();
        
        if (parameter instanceof Image && (parameter.width != parameter.naturalWidth || parameter.height != parameter.naturalHeight)) {
            let dst = new window.cv.Mat();
            let dsize = new window.cv.Size(parameter.naturalWidth, parameter.naturalHeight);
            window.cv.resize(original, dst, dsize, 0, 0, window.cv.INTER_NEAREST);
            window.cv.cvtColor(dst, optiStr, window.cv.COLOR_RGBA2GRAY, 0);
            dst.delete()
            dst = null
        } else {
            window.cv.cvtColor(original, optiStr, window.cv.COLOR_RGBA2GRAY, 0);
        }
        let matDataPtr = window.Module._malloc(optiStr.rows * optiStr.cols);
        window.Module.HEAPU8.set(optiStr.data, matDataPtr);
        let optiJS = window.Module.getJSMatCap(optiStr.cols, optiStr.rows, 1, matDataPtr);
        optiJS.cols = optiStr.cols
        optiJS.rows = optiStr.rows
        // window.Module._free(matDataPtr);
        // for (var i = 0; i < optiStr.rows * optiStr.cols; i++) {
        //     optiJS.data.push_back(optiStr.data[i])
        // }
        original.delete()
        original = null
        optiStr.delete()
        optiStr = null
        if ((new Date().getTime() - startTime) > 100) {
           console.warn("getJSMatDataByCV use time:", parameter.src, new Date().getTime() - startTime)
        }

        return optiJS
    }

    // JSposition方法
    getJSpositionData(obj) {
        return new Promise((resolve, reject) => {
            let clickPosiJS = window.Module.getJSposition()

            let cimgBoundingbox = obj.cimgBoundingbox
            let cimgBoundingboxArr = cimgBoundingbox.split(',')
            cimgBoundingboxArr.forEach(element => {
                clickPosiJS.cImgBoundingBox.push_back(Number(element))
            });

            let cimgBoundingboxOffset = obj.cimgBoundingboxOffset
            let cimgBoundingboxOffsetArr = cimgBoundingboxOffset.split(',')
            cimgBoundingboxOffsetArr.forEach(element => {
                clickPosiJS.cImgBoundingBoxOffset.push_back(Number(element))
            });

            clickPosiJS.cImgOrientation = obj.cimgOrientation

            const url = obj.externalUrl
            this.getJSMatData(url).then(jsMat => {
                clickPosiJS.cImgMask = jsMat
                resolve(clickPosiJS)
            }).catch((err) => {
                console.error(err)
                reject(err)
            });
        })
    }

    // JSPoint方法 坐标点
    getJSPointData(x, y) {
        let clickPosiJS = window.Module.getJSPoint()
        clickPosiJS.x = x
        clickPosiJS.y = y
        return clickPosiJS
    }

    /**
     * 压缩染色体数组中的边缘计数点
     * @param {染色体数组} chromoList
     */
    zipCimgBoundaryPointsList(cimgBoundaryPointsList) {
        let cimgBoundaryPointsListStr = "";
        if (cimgBoundaryPointsList instanceof Array) {
            cimgBoundaryPointsList.forEach(element => {
                cimgBoundaryPointsListStr = cimgBoundaryPointsListStr + element.x + "."
                cimgBoundaryPointsListStr = cimgBoundaryPointsListStr + element.y + ","
            });
            cimgBoundaryPointsList = cimgBoundaryPointsListStr.substring(0, cimgBoundaryPointsListStr.length - 1);
        }
        return cimgBoundaryPointsListStr;
    }

    /**
     * 解压还原染色体的边缘计数点
     * @param {染色体的边缘计数点压缩字符串} cimgBoundaryPointsListStr
     */
    unZipCimgBoundaryPointsList(cimgBoundaryPointsListStr) {
        let pointList = []
        try {
            pointList = JSON.parse(cimgBoundaryPointsListStr)
        } catch (e) {
            if (cimgBoundaryPointsListStr instanceof Array) {
                pointList = cimgBoundaryPointsListStr;
            } else {
                pointList = cimgBoundaryPointsListStr.split(",").map(pos => {
                    const [x, y] = pos.split(".").map(a => Number(a));
                    return {
                        x,
                        y
                    };
                })
            }
        }
        return pointList;
    }

    unzipBoundBox(cimgBoundingbox) {
        const [y, x, h, w] = cimgBoundingbox.split(",").map(a => Number(a));
        return {
            x,
            y,
            w,
            h
        };
    }

    // 返回新的数据
    getCuttingData(chromoData, newChromoList) {
        //掩码图
        var externalUrl_base64Img = this._getImageByCpp(chromoData.cImgPosition.cImgMask);

        //原图
        var primaryUrl_base64Img = this._getImageByCpp(chromoData.cImg);

        //旋转图
        var rotatedUrl_base64Img = this._getImageByCpp(chromoData.cImgRotated);
        var justUrl_base64Img = cloneDeep(rotatedUrl_base64Img);

        let cImgBoundingBoxList = []
        let cImgBoundingBox = chromoData.cImgPosition.cImgBoundingBox
        for (let i = 0; i < cImgBoundingBox.size(); i++) {
            const element = cImgBoundingBox.get(i);
            cImgBoundingBoxList.push(element)
        }
        let cImgBoundingBoxStr = cImgBoundingBoxList.toString()

        let cImgBoundingBoxOffsetList = []
        let cImgBoundingBoxOffset = chromoData.cImgPosition.cImgBoundingBoxOffset
        for (let i = 0; i < cImgBoundingBoxOffset.size(); i++) {
            const element = cImgBoundingBoxOffset.get(i);
            cImgBoundingBoxOffsetList.push(element)
        }
        let cImgBoundingBoxOffsetStr = cImgBoundingBoxOffsetList.toString()

        let chromoCategoryInfoList = []
        let chromoCategoryInfo = chromoData.chromoCategoryInfo
        for (let i = 0; i < chromoCategoryInfo.size(); i++) {
            const element = chromoCategoryInfo.get(i);
            chromoCategoryInfoList.push(element)
        }
        let chromoCategoryInfoStr = chromoCategoryInfoList.toString()
        let center = chromoData.center
        let countPoints = [];
        countPoints.push(center)

        let cimgBoundaryPointsList = []
        let cImgBoundaryPoints = chromoData.cImgPosition.cImgBoundaryPoints
        for (let i = 0; i < cImgBoundaryPoints.size(); i++) {
            const element = cImgBoundaryPoints.get(i);
            cimgBoundaryPointsList.push(element)
        }

        let newChromo = cloneDeep(newChromoList)
        newChromo.primaryUrl = primaryUrl_base64Img
        newChromo.cimgBoundingbox = cImgBoundingBoxStr
        newChromo._cimgBoundingbox = this.unzipBoundBox(cImgBoundingBoxStr)
        newChromo.cimgBoundingboxOffset = cImgBoundingBoxOffsetStr
        newChromo.externalUrl = externalUrl_base64Img
        newChromo.cimgOrientation = chromoData.cImgPosition.cImgOrientation
        newChromo.invertedUrl = rotatedUrl_base64Img
        newChromo.justUrl = justUrl_base64Img
        newChromo.cimgType = chromoData.cImgType
        newChromo.chromoCategoryinfo = chromoCategoryInfoStr
        newChromo.chromoId = chromoData.chromoId
        newChromo.chromoUpright = chromoData.chromoUpright
        newChromo.index = chromoData.index
        newChromo.relatedIndex = chromoData.relatedIndex ? chromoData.relatedIndex : -1;
        newChromo.countPoints = countPoints
        newChromo.cimgBoundaryPointsList = this.zipCimgBoundaryPointsList(cimgBoundaryPointsList)
        newChromo._cimgBoundaryPointsList = cimgBoundaryPointsList
        newChromo.imgHandledRotatedDegree = 0;
        newChromo.id = null;
        //阈值调整的图片
        newChromo._justUrlByYZ = null;

        return newChromo
    }

    _getChromoDataJS(chromoData, newChromoList) {
        // 组装chromoDataJS 信息，下次处理时，不用再调用算法获取
        let chromoDataJS = window.Module.getJSchromo()
        chromoDataJS.relatedIndex = newChromoList && newChromoList.relatedIndex ? newChromoList.relatedIndex : chromoData.relatedIndex;
        chromoDataJS.id = newChromoList ? newChromoList.id : 0
        chromoDataJS.index = newChromoList ? newChromoList.index : chromoData.index
        chromoDataJS.chromoId = newChromoList ? newChromoList.chromoId : chromoData.chromoId
        chromoDataJS.chromoUpright = newChromoList && newChromoList.chromoUpright ? newChromoList.chromoUpright : chromoData.chromoUpright

        chromoDataJS.chromoCategoryInfo = chromoData.chromoCategoryInfo;
        chromoDataJS.cImgType = chromoData.cImgType
        chromoDataJS.center = chromoData.center
        chromoDataJS.cImg = chromoData.cImg
        chromoDataJS.cImgRotated = chromoData.cImgRotated
        chromoDataJS.cImgPosition = chromoData.cImgPosition
        return chromoDataJS;
    }

    // 生成base64编码
    _getImageByCpp(cImgRotated) {
        //cImgRotated
        let cImgRotatedDataList = []
        let cImgRotatedCols = cImgRotated.cols
        let cImgRotatedRows = cImgRotated.rows
        let cImgRotatedData = cImgRotated.data
        for (let i = 0; i < cImgRotatedData.size(); i++) {
            const element = cImgRotatedData.get(i);
            cImgRotatedDataList.push(element)
        }
        let mat1 = window.cv.matFromArray(cImgRotatedRows, cImgRotatedCols, window.cv.CV_8U, cImgRotatedDataList);
        var div02 = document.getElementById('div02');
        let _canvas = document.createElement('canvas')
        _canvas.id = "canvasOutId" + Math.random()
        div02.append(_canvas)
        window.cv.imshow(_canvas.id, mat1)
        let img = _canvas.toDataURL('image/png', 1.0);
        document.getElementById(_canvas.id).remove();
        _canvas = null;
        return img;
    }

    // 生成base64编码在阈值调整中用到
    _getImageByCppInZFT(cImgRotated) {
        //cImgRotated
        let cImgRotatedDataList = []
        let cImgRotatedCols = cImgRotated.cols
        let cImgRotatedRows = cImgRotated.rows
        let cImgRotatedData = cImgRotated.data
        for (let i = 0; i < cImgRotatedData.size(); i++) {
            const element = cImgRotatedData.get(i);
            cImgRotatedDataList.push(element)
        }
        let mat1 = window.cv.matFromArray(cImgRotatedRows, cImgRotatedCols, window.cv.CV_8UC3, cImgRotatedDataList);
        var div01 = document.getElementById('div01');
        let _canvas = document.createElement('canvas')
        _canvas.id = "canvasOutId" + Math.random()
        div01.append(_canvas)
        window.cv.imshow(_canvas.id, mat1)
        let img = _canvas.toDataURL('image/png', 1.0);
        document.getElementById(_canvas.id).remove();
        _canvas = null;
        return img;
    }

    /**
     * 获取涂抹生成的染色体
     * 判断是否为全白
     * @param {算法切出返回的数据} chromoData 
     */
    getPaintChromoData(chromoData) {
        return new Promise((resolve) => {
            var primaryUrl_base64Img = this._getImageByCpp(chromoData.cImg);
            this.judgeImgIsWhite(primaryUrl_base64Img).then((isJudgeIsWhite) => {
                if (isJudgeIsWhite) {
                    let newChromoList = this.getCuttingData(chromoData, {});
                    resolve(newChromoList)
                } else {
                    resolve(false)
                }
            });
        })
    }

    /**
     * 裁剪白边
     * @param {*} imgData 原图
     */
    tailorImg(imgData) {
        var base64Img;
        async function handle() {
            await new Promise((resolve, reject) => {
                try {
                    let original = window.cv.imread(imgData, 0)
                    let optiStr = new window.cv.Mat();
                    window.cv.cvtColor(original, optiStr, window.cv.COLOR_RGBA2GRAY, 0);
                    window.cv.threshold(optiStr, optiStr, 253, 255, window.cv.THRESH_BINARY_INV);

                    let contours = new window.cv.MatVector();
                    let hierarchy = new window.cv.Mat();
                    window.cv.findContours(optiStr, contours, hierarchy, window.cv.RETR_EXTERNAL, window.cv.CHAIN_APPROX_SIMPLE);
                    optiStr.delete()
                    hierarchy.delete()
                    if (contours.size() > 0) {
                        let rect = window.cv.boundingRect(contours.get(0));
                        if(contours.size() > 1 && rect){
                            let xArr = [rect.x], 
                                yArr = [rect.y], 
                                rArr = [rect.x + rect.width], 
                                bArr = [rect.y + rect.height];
                            for (let i = 1; i < contours.size(); ++i) {
                                const _rect = window.cv.boundingRect(contours.get(i));
                                xArr.push(_rect.x);
                                yArr.push(_rect.y);
                                rArr.push(_rect.x + _rect.width);
                                bArr.push(_rect.y + _rect.height);
                                // rect = rect | window.cv.boundingRect(contours.get(i));
                            }
                            const x = Math.min.apply(null,xArr);
                            const y = Math.min.apply(null,yArr);
                            const width = Math.max.apply(null,rArr) - x;
                            const height = Math.max.apply(null,bArr) - y;
                            rect = {x, y, width, height}
                        }
                        contours.delete()
                        if (rect.width > 2 && rect.height > 2) {
                            let dst = original.roi(rect).clone()
                            let _canvas = document.createElement('canvas');
                            window.cv.imshow(_canvas, dst);

                            original.delete()
                            dst.delete()
                            resolve();
                            base64Img = _canvas.toDataURL('image/png', 1.0);
                        }
                    }
                } catch (e) {
                    reject(e)
                }
            })
        }
        handle();
        return base64Img;
    }

    /**
     * 图片镜像旋转
     * @param {图片url地址} imageUrl 
     */
    onRotaterMirror(imageUrl) {
        return new Promise((resolve, reject) => {
            let div01 = document.getElementById('div01');
            let imgOriginal = new Image()
            imgOriginal.crossOrigin = "anonymous"
            imgOriginal.src = imageUrl;
            imgOriginal.id = "imgOriginal" + Math.random()
            div01.append(imgOriginal)
            imgOriginal.onload = () => {
                let original = window.cv.imread(imgOriginal.id, 0)
                // let optiStr = new cv.Mat();
                // cv.cvtColor(original, optiStr, cv.COLOR_RGBA2GRAY, 0);

                let mirrorImage = new window.cv.Mat();
                window.cv.flip(original, mirrorImage, 1);
                let _canvas = document.createElement('canvas');
                window.cv.imshow(_canvas, mirrorImage);
                let base64Img = _canvas.toDataURL('image/png', 1.0);
                original.delete()
                original = null
                mirrorImage.delete()
                mirrorImage = null
                imgOriginal = null;
                resolve(base64Img)
            }
            imgOriginal.onerror = (e) => {
                console.warn(e)
                reject(e)
            }
        })
    }

    /**
     * 涂抹获取mask，包含闭合区域填充
     * @param {*} imgData 原图canvas对象
     */
    drawMaskImg(imgData) {
        let original = window.cv.imread(imgData, 0)
        let optiStr = new window.cv.Mat();
        window.cv.cvtColor(original, optiStr, window.cv.COLOR_RGBA2GRAY, 0);
        window.cv.threshold(optiStr, optiStr, 253, 255, window.cv.THRESH_BINARY);

        let contours = new window.cv.MatVector();
        let hierarchy = new window.cv.Mat();
        window.cv.findContours(optiStr, contours, hierarchy, window.cv.RETR_EXTERNAL, window.cv.CHAIN_APPROX_NONE);
        optiStr.delete()

        if (contours.size() > 0) {
            let color0 = new window.cv.Scalar(255, 255, 255);
            let dst = window.cv.Mat.zeros(original.rows, original.cols, window.cv.CV_8UC3);
            window.cv.drawContours(dst, contours, 0, color0, -1, 8, hierarchy, 100);
            contours.delete()
            let _canvas = document.createElement('canvas');
            window.cv.imshow(_canvas, dst);
            original.delete()
            hierarchy.delete()
            dst.delete()
            // var base64Img = _canvas.toDataURL('image/png', 1.0);
            return _canvas;
        } else {
            contours.delete()
            original.delete()
            hierarchy.delete()
        }

        return false;
    }

    /**
     * 判断图片是否为全白
     * @param {图片地址或base64} img_data 
     */
    judgeImgIsWhite(img_data) {
        return new Promise((resolve) => {
            let div02 = document.getElementById('div02');
            let img = new Image();
            div02.append(img)
            img.src = img_data
            img.onload = () => {
                let imgMat = window.cv.imread(img, 0)
                let meanValue = window.cv.mean(imgMat);
                let meanStartValue = meanValue[0];
                let meanEndValue = meanValue[meanValue.length - 1];
                if (Math.round(meanStartValue) == 255 && Math.round(meanEndValue) == 255) {
                    resolve(false)
                } else {
                    resolve(true)
                }
            }
            img.onerror = (e) => {
                console.warn(e)
                resolve(false)
            }
        })
    }

}

export default new OpenCVCommon()