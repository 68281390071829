<template>
  <div class="analysCenterClass" style="overflow:visible" ref="analysCenterClass">
    <div v-infinite-scroll="load" infinite-scroll-disabled="disabled" infinite-scroll-immediate="true" infinite-scroll-distance="150">
      <!-- 缩略图-->
      <ul class="analysCenterList" v-show="showImage !=0 && dataList.karyotypeSwitchVal==1" ref="analysCenterList">
        <li v-for="(item,index) in data" :key="item.index" class="analysCenterListLi" :style="{width: dataList.rowList.myWidth,paddingBottom:dataList.rowList.myWidth}" @contextmenu.prevent="rightClick(item)" @mouseleave="analysCenterListLiLeave(item)" @mouseenter="analysCenterListLiEnter(item)">

          <span class="centerListLi_Fir" v-if="item.showLoading==1" :class="`whetherRepeatInspect${item.whetherRepeatInspect}`" @click='goToTools(item)'>

            <!--顔色選擇-->
            <div class="change_color" ref="changeColor" v-if="item.changeColor == 1">
              <div class="change_color_box">
                <div @click.stop="change_color(item,1)">
                  <!--<img :src="press_red">-->
                </div>
                <div @click.stop="change_color(item,2)">
                  <!--<img :src="press_yellow">-->
                </div>
                <div @click.stop="change_color(item,0)">
                  <!--<img :src="press_white">-->
                </div>
                <div @click.stop="change_color(item,3)">
                  <!--<img :src="press_blue">-->
                </div>
              </div>
            </div>

            <!--置顶-->
            <div class="change_top" v-if="item.changeTop == 1||item.isTop ==1" @click.stop="changeTopBtn(item)">
              <img :src="changeTop_Select" v-if="item.isTop == 1" />
              <img :src="changeTop_hover" v-if="item.isTop != 1" />

            </div>
            <!--&lt;!&ndash;状态判断S&ndash;&gt;-->
            <div :class="`speedProgress${item.speedProgress}`" class="singleNumName">{{item.singleNum}}</div>
            <!--&lt;!&ndash;状态判断E&ndash;&gt;-->

            <!--   &lt;!&ndash;确认排列S&ndash;&gt;-->
            <template>
              <span v-if="item.isTypeSure ==1">
                <img :src="item.arrayUrl == ''||item.arrayUrl == null?item.newPic:item.arrayUrl" class="centerListLi_Fir_img_F" v-if="item.arrange == 1" ref="arrayUrl">
                <img :src="item.optUrl == ''||item.optUrl == null?item.primaryUrl:item.optUrl" class="centerListLi_Fir_img_F_s" v-if="item.breviary == 2" ref="abbThree">
                <span class="newchromosome_gray" @mouseover="mouseoverEnterTrue(index,item)" @mouseleave="mouseleaveEnterTrue(index,item)">
                  <span></span>
                </span>
              </span>
            </template>
            <!-- &lt;!&ndash;确认排列e&ndash;&gt;-->

            <!--&lt;!&ndash;无确认排列s&ndash;&gt;-->
            <template>
              <span v-if="item.isTypeSure == 0">

                <img :src="(item.isDeal==0||item.optUrl == ''||item.optUrl == null)?item.primaryUrl:item.optUrl" class="centerListLi_Fir_img_F" v-if="item.breviary == 1" ref="abbThree">
                <img :src="item.arrayUrl == ''||item.arrayUrl == null?item.newPic:item.arrayUrl" class="centerListLi_Fir_img_F_s" v-if="item.arrange ==2" ref="arrayUrl">

                <span class="newchromosome_gray" @mouseover="mouseoverEnterTrue(index,item)" @mouseleave="mouseleaveEnterTrue(index,item)">
                  <span></span>
                </span>
              </span>
            </template>
            <!--&lt;!&ndash;无确认排列e&ndash;&gt;-->

          </span>
          <span class="centerListLi_Fir_loading" v-if="item.showLoading==2">
            <img src="../../assets/images/analysissystem/loadinggig.gif">
            <span>自动分析中…</span>
          </span>
          <span class="centerListLi_Sen" v-if="item.showLoading==2" :class="`color${item.color}`">

            <!-- &lt;!&ndash; 确认排列s&ndash;&gt;-->
            <template>
              <span :disabled="item.showLoading==2?true:false" @change="handleCheckChange(item.id)" class="handleCheckChangeName" :title="`${item.glassName}/${item.cellName}`">{{item.glassName}}/{{item.cellName}}</span>
            </template>
            <!-- &lt;!&ndash; 确认排列e&ndash;&gt;-->
          </span>
          <span class="centerListLi_Sen" v-if="item.showLoading==1" :class="`color${item.color}`">

            <!--&lt;!&ndash; 未确认排列s&ndash;&gt;-->
            <template>
              <el-checkbox v-if="item.isTypeSure ==1" :checked="item.checkeds == 1" v-model="item.checkeds==1" :ref="`checked${item.id}`" :disabled="item.showLoading==2?true:false" @change="handleCheckChange(item.id)" :title="`${item.glassName}/${item.cellName}/${item.karyotypeExpression}`" class="handleCheckChangeName">{{item.glassName}}/{{item.cellName}}/{{item.karyotypeExpression}}</el-checkbox>
            </template>
            <!-- &lt;!&ndash; 未确认排列e&ndash;&gt;-->

            <!-- &lt;!&ndash; 确认排列s&ndash;&gt;-->
            <template>
              <el-checkbox v-if="item.isTypeSure ==0" :checked="item.checkeds == 1" v-model="item.checkeds==1" :ref="`checked${item.id}`" :disabled="item.showLoading==2?true:false" @change="handleCheckChange(item.id)" class="handleCheckChangeName" :title="`${item.glassName}/${item.cellName}`">{{item.glassName}}/{{item.cellName}}</el-checkbox>
            </template>
            <!-- &lt;!&ndash; 确认排列e&ndash;&gt;-->
          </span>
        </li>
        <div class="list" v-for="item in (dataList.rowList.row-len%dataList.rowList.row)" :key="item.index" :style="{width: dataList.rowList.myWidth}"></div>
      </ul>

      <!--没有细胞的情况-->
      <div v-show="showImage == 0" class="casesPicturesImg">
        <img src="../../assets/images/analysissystem/casesPictures.svg" width="256" height="210" />
        <span>{{ $t("case_overview.nocase_tocheck") }}</span>
      </div>

      <!--排列图-->
      <div v-show="showImage !=0 && dataList.karyotypeSwitchVal==2" ref="scrollTable" id="scrollTable">
        <ul class="scrollTableTop" id="scrollTableTop">
          <li style="width: 15%">{{ $t("case_overview.metaphase_image") }}</li>
          <li style="width: 15%">{{ $t("case_overview.array_image") }}</li>
          <li style="width: 10%">{{ $t("case_overview.slide") }}</li>
          <li style="width: 10%">{{ $t("case_overview.cell") }}</li>
          <li style="width: 10%">{{ $t("case_overview.count_num") }}</li>
          <li style="width: 10%">{{ $t("case_overview.count_confirm") }}</li>
          <li style="width: 10%">{{ $t("case_overview.array_confirm") }}</li>
          <li style="width: 20%">{{ $t("case_overview.karyotype_express") }}</li>
        </ul>
        <ul class="scrollTableFooter">
          <li v-for="(item,index) in data" :data="data" :key="index" @click="goToTools(item)" :class="`colorList${item.color}`" @contextmenu.prevent="rightClick(item)">
            <!--中期分裂相图S-->
            <span>
              <template>
                <img v-if="item.optUrl=='' || item.optUrl == null" :src="item.primaryUrl" width="115" height="83" :class="`whetherRepeatInspect${item.whetherRepeatInspect}`">
                <img v-else :src="item.optUrl" width="115" height="83" :class="`whetherRepeatInspect${item.whetherRepeatInspect}`">
              </template>
            </span>
            <!--中期分裂相图E-->

            <!--排列图S-->
            <span>
              <template>
                <a v-if="item.arrayUrl=='' || item.arrayUrl == null">/</a>
                <img v-else :src="item.arrayUrl" width="115" height="83">
              </template>
            </span>
            <!--排列图E-->

            <!--玻片S-->
            <span>{{item.glassName}}</span>
            <!--玻片E-->

            <!--细胞S-->
            <span :title="item.cellName">{{item.cellName}}</span>
            <!--细胞E-->

            <!--计数数量S-->
            <span>{{item.singleNum}}</span>
            <!--计数数量E-->

            <!--计数确认S-->
            <span>
              <template>
                <a v-if="item.isSingleSure == 0"><img :src="icon_line"></a>
                <a v-else style="color: #1A9448"><img :src="icon_Right_number"></a>
              </template>
            </span>
            <!--计数确认E-->

            <!--排列确认S-->
            <span>
              <template>
                <a v-if="item.isTypeSure == 0"><img :src="icon_line"></a>
                <a v-else style="color: #1A9448"><img :src="icon_Right_number"></a>
              </template>
            </span>
            <!--排列确认E-->

            <!--核型表达式S-->
            <span>
              <a>{{item.karyotypeExpression}}</a>
            </span>
            <!--核型表达式E-->
          </li>
        </ul>
      </div>
    </div>

    <!--玻片右键弹窗-->
    <div v-show="menus" @mouseleave="mouseleavesEnter">
      <el-menu id="menus" @select="handleSelect" text-color="#000">
        <el-menu-item index="2" class="menuItem menuItemList">
          <span slot="title">{{$t('accountInfo.auto_snalysis')}}</span>
        </el-menu-item>
        <el-menu-item index="3" class="menuItem menuItemList">
          <span slot="title">{{$t('new_tools.delete')}}</span>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
window.timestampName = null; //批量分析
window.timesCase = null; //病例分析中
import "../../assets/scss/analysCenter.scss";
import Common from '../../assets/js/common'
export default {
  props: ["dataList"],
  data() {
    return {
      press_red: require("@/assets/images/analysissystem/press_red.png"),
      press_yellow: require("@/assets/images/analysissystem/press_yellow.png"),
      press_blue: require("@/assets/images/analysissystem/press_blue.png"),
      press_white: require("@/assets/images/analysissystem/press_white.png"),
      icon_Right_number: require("@/assets/images/analysissystem/icon_Right number.png"), //已确认
      icon_line: require("@/assets/images/analysissystem/icon_line.png"),
      data: [],
      dataNew: [],
      itemsPerLoad: 20,
      page: 1,
      iconNewDelSelect: 1,
      totalPage: 1,
      listActiveColor: 1,
      len: "",
      showImage: "", //有没有病例图片
      menus: false, //默认隐藏
      dataMain: "", //选中的当个信息
      arr1: [], //分析中的id
      checkedValues: [],
      checkedValuesType: false,
      batchAnalysisList: [],
      analysisEnd: [], //分析完成数据回显
      glassIds: this.$store.state.glassIds,
      status: this.$store.state.status,
      firstLoading: this.$store.state.firstLoading,
      loading: true,
      changeTop_Select: require("@/assets/images/analysissystem/top_press.png"),
      changeTop_hover: require("@/assets/images/analysissystem/top_hover.png"),
      sortType: this.$store.state.sortTypeList,
      color: this.$store.state.color,
      titleMsg:""
    };
  },
  computed: {
    noMore() {
      //当起始页数大于总页数时停止加载
      return this.page > this.totalPage - 1;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created() {
    window.centerApp = this;
    this.$store._mutations.updateAnalysKaryoInfo = (karyoInfo, key) => {
      if (this.dataList.id === karyoInfo.patientId) {
        this.data = this.data.map((item) => {
          if (item.id === karyoInfo.id && key) {
            item[key] = karyoInfo[key];
          }
          if (item.id === karyoInfo.id && !key) {
            return JSON.parse(JSON.stringify(karyoInfo));
          }
          return item;
        });
        this.$forceUpdate();
      }
    };
  },
  methods: {
    //监听滚动条的高度
    handleScrollx() {
      if (this.dataList.karyotypeSwitchVal == 2) {
        let scrollTopHeight =
          document.getElementById("orderFullScreenName").scrollTop + "px";
        $("#scrollTableTop").css("top", scrollTopHeight);
      }
    },

    //移入列表显示颜色模块
    analysCenterListLiEnter(item) {
      this.data.forEach((itm, index) => {
        if (itm.id == item.id) {
          this.data[index].changeColor = 1;
          this.data[index].changeTop = 1;
        }
      });
      this.$forceUpdate();
    },

    //移出颜色模块消失
    analysCenterListLiLeave(item) {
      this.data.forEach((itm, index) => {
        if (itm.id == item.id) {
          this.data[index].changeColor = 0;
          this.data[index].changeTop = 0;
        }
      });
      this.$forceUpdate();
    },

    //颜色选择
    change_color(item, val) {
      this.data.forEach((itm, index) => {
        if (itm.id == item.id) {
          this.data[index].color = val;
          let param = {
            karyoId: item.id,
            color: val,
          };
          this.$api.updateColor(param).then((res) => {
            if (res.code == 200) {
              this.$store.state.autovision.randomString && this.$store._mutations.updateKaryoStatus(item.id, "color", val);
            }
          });
        }
      });
      this.$forceUpdate();
    },

    //置顶
    changeTopBtn(item) {
      this.data.forEach((itm, index) => {
        if (itm.id == item.id) {
          const isTop = Number(!itm.isTop);
          let param1 = {
            karyoId: item.id,
            isTop: isTop,
          };
          this.$api.updateIsTop(param1).then((res) => {
            if (res.code == 200) {
              this.data[index].isTop = isTop;
              this.$store.state.autovision.randomString && this.$store._mutations.updateKaryoStatus(item.id, "isTop", isTop);
            }
          });
        }
      });
    },

    //滚动加载
    load() {

        if (this.$store.state.firstLoading == 2) {
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.page += 1; //页数+1
            this.getMessageList(); //调用接口，此时页数+1，查询下一页数据
        }
    },

    //滚动加载
    getMessageList() {
      this.$store.commit("isLoading", 0);
      let i =1;
      console.log(i++);
      if (!this.dataList.id) return;
      let param = {
        limit: this.itemsPerLoad, // 每页几条
        patientId: this.dataList.id, //12590
        page: this.page,
        glassIds: this.glassIds.toString(),
        status: this.status,
        sortType: this.$store.state.sortTypeList,
        color: this.color,
      };
      this.$api.queryKaryoList(param).then((res) => {
        if (res.code == 200) {
          let dataArr = res.data.pageUtil.list;
          const handle = async (fn) => {
            await fn();
          };
          handle(() => {
            dataArr.forEach((element, index) => {
              element.showLoading = 1;
              element.checkeds = 2;
              element.arrange = 1; //排列图
              element.breviary = 1; //缩略图
              element.optUrl =
                this.ossUrlWebFir(element.optUrl, 6) || element.optUrl;
              element.arrayUrl =
                this.ossUrlWebFir(element.arrayUrl, 6) || element.arrayUrl;
              element.primaryUrl = this.ossUrlWebFir(element.primaryUrl, 6)||element.primaryUrl;
              element.newPic = "https://cdn.autovision.online/standard/standard_karyo.webp";
            });
          });

          this.dataNew = this.data.concat(dataArr); //因为每次后端返回的都是数组，所以这边把数组拼接到一起
          this.totalPage = res.data.pageUtil.totalPage;

          //病例列表 首次进入遍历列表数据
          if (this.$store.state.firstLoading == 1) {
            this.$store.commit("glassListType", res.data.glassList);
          }
          this.dataList.beingAnalyzed = false;
          if (res.data.hasAdditional == 0) {
            this.dataList.hasAdditional = 1;
          } else {
            this.dataList.hasAdditional = 2;
          }
          this.$emit("formData", this.dataList);
          //顶部病例树展示
          this.$store.commit("karyotypeNumberlistType", res.data.statisInfo);

          this.$store.commit("firstLoadingType", 2);
          if (res.data.pageUtil.totalPage == 0) {
            this.showImage = 0;
            this.$store.commit("changeShowImage", this.showImage);
          } else {
            this.showImage = 1;
            this.$store.commit("changeShowImage", this.showImage);
          }

          this.checkedValues = [];
          this.batchAnalysisList = [];
          this.$store.commit("batchAnalysisListType", this.checkedValues);

          //图片签名

          this.queryKaryoListByPatients();
         this.$store.commit("isLoading", 1);

        } else if (res.code == 136) {
          //正在分析中
          this.dataList.beingAnalyzed = true;
          this.queryProcessing();
            this.$store.commit("isLoading", 1);
        }else{
            this.$message.error(res.message);
        }
      });
    },

    //移入事件已确认
    mouseoverEnterTrue(index, item) {
      //isDeal 0未进行分析 1进行智能分析
      if (item.isDeal == 1) {
        this.data.forEach((ite, i) => {
          if (index == i) {
            this.data[i].arrange = 2;
            this.data[i].breviary = 2;
          }
        });
        this.$forceUpdate();
      }
    },

    //移出事件已确认
    mouseleaveEnterTrue(index, item) {
      //isDeal 0未进行分析 1进行智能分析
      if (item.isDeal == 1) {
        this.data.forEach((ite, i) => {
          if (index == i) {
            this.data[i].arrange = 1;
            this.data[i].breviary = 1;
          }
        });
        this.$forceUpdate();
      }
    },

    //鼠标右键核型图
    rightClick(item) {
      let menus = document.querySelector("#menus");
      menus.style.display = "block";
      menus.style.left = event.clientX - 10 + "px";
      menus.style.top = event.clientY - 10 + "px";
      //item.hasReport有没有出过报告 0没有 1出过报告
      if (item.hasReport == 0 && item.showLoading == 1) {
        this.menus = true; //让菜单显示
        this.dataMain = item;
      } else {
        this.menus = false; //让菜单显示
        this.dataMain = item;
      }
    },

    //选择删除还是分析
    handleSelect(index) {
      //3的时候是删除
      if (index == 3) {
        this.$confirm(
          this.$t("popup.warning.delete_cell"),
          this.$t("popup.warning.remind"),
          {
            confirmButtonText: this.$t("btns.confirm"),
            cancelButtonText: this.$t("btns.cancel"),
            type: "warning",
              closeOnClickModal: false
          }
        )
          .then(() => {
            let param = {
              id: this.dataMain.id,
              type: index,
            };

            this.$api.patientDelete(param).then((res) => {
              if (res.code == 200) {
                this.page = 1;
                this.data = [];
                this.getMessageList();
                this.$message({ type: "success", message: res.message });
                this.menus = false; //让菜单隐藏
                this.$store.commit("firstLoadingType", 1);
                this.$store._mutations.deleteKaryo(this.dataMain.id);
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => { });
      }

      //分析
      if (index == 2) {

          this.$api.karyoAutoSegmen({
              patientId: this.dataMain.patientId,
              karyoIds: this.dataMain.id,
              type:2
          }).then((res) => {
              if(res.code == 200){
                  if(res.data){  //真
                      this.AnalysisList();
                  }else{ //假

                      this.titleMsg = this.$t("popup.add_analysis.contentAnalysis");
                      this.$confirm(this.titleMsg, this.$t("popup.add_analysis.title"), {
                          confirmButtonText: this.$t("btns.confirm"),
                          cancelButtonText: this.$t("btns.cancel"),
                          type: "warning",
                          closeOnClickModal: false
                      }).then(() => {
                          this.AnalysisList();
                      }).catch(() => {
                          this.$message({
                              type: "info",
                              message: this.$t("popup.add_analysis.cancel_add"),
                          });
                      });
                  }
              }else{
                  this.$message.error(res.message);
              }
          });



      }
    },

    //单个分析
    AnalysisList:function(){
        let post_data = {
          patientId: this.dataMain.patientId,
          ids: this.dataMain.id,
          type: 2,
        };
        this.$api.autoSegment(post_data).then((res) => {
          if (res.code == 200) {
            let obj = {
              patientId: this.dataList.id,
              status: 1
            }
            this.$store.commit('patientIdStatusSave', obj);
            this.queryKaryoListByPatients();
            this.menus = false; //让菜单隐藏
          } else if(res.code != 122){

            this.$message.error(res.message);
          }
        });
    },


    //分析进度
    queryProcessing: function () {
      let timesCaseNum = 3000;
      clearInterval(window.timesCase);
      let post_data = {
        patientId: this.dataList.id,
      };
      let showCase = () => {
        this.$api.querySegment(post_data).then((res) => {
          if (res.code == 200) {
            if (res.data.percent == 1) {
              this.dataList.beingAnalyzed = false;
              clearInterval(window.timesCase);
              this.$store.commit("firstLoadingType", 1);
              this.glassIds = [];
              this.status = -1;
              this.page = 1;
              this.data = [];
              this.getMessageList();
            } else {
              this.dataList.percentage = parseFloat(
                (res.data.percent * 100).toFixed(2)
              );
            }
          } else {
            this.$message.error(res.message);
          }
        });
      };
      showCase();
      window.timesCase = setInterval(showCase, timesCaseNum);
    },

    //分析
    queryKaryoListByPatients: function () {
      let commonTime = 3000;
      clearInterval(window.timestampName);
      let post_data = {
        patientId: this.dataList.id,
      };
      let arr1 = [];
      let show = () => {
        this.$api.queryKaryoListByPatient(post_data).then((res) => {
          if (res.code == 200) {
            this.data = this.dataNew;
            this.len = this.data.length;
            this.loading = false;

            this.loadMoreHeightCenter();
            if (res.data.length == 0) {
                this.$store.commit('beingAnalyzed', 0);
              clearInterval(window.timestampName);
              //为了解决监听延迟的问题
              this.analysisEnd = arr1;
              this.dataAnalysis();
            } else {
                this.$store.commit('beingAnalyzed', 1);
              let arr2 = res.data;
              this.data.forEach((element) => {
                for (let j = 0; j < arr2.length; j++) {
                  if (element.id == arr2[j]) {
                    element.showLoading = 2;
                  }
                }
              });
              this.$forceUpdate();

              //提取两个数组不一样的部分
              const getArrDifference = (arr1, arr2) => {
                return arr1.concat(arr2).filter(function (v, i, arr) {
                  return arr.indexOf(v) === arr.lastIndexOf(v);
                });
              };

              let newLength = getArrDifference(arr1, arr2);

              if (newLength.length != 0 && arr1.length != 0) {
                this.analysisEnd = newLength;
                this.dataAnalysis();
              }
              arr1 = res.data;
            }
          } else {
            //TODO
            this.$message.error(res.message);
          }
        });
      };
      show();

      window.timestampName = setInterval(show, commonTime);
    },

    //数据更新完成之后
    dataAnalysis: function () {
      this.analysisEnd.forEach((items) => {
        let post_dataList = {
          karyoIds: items,
        };
        this.$api.queryBuKaryoIds(post_dataList).then((res) => {
          if (res.code == 200) {
            this.data.forEach((element, index) => {
              if (element.id == items) {
                this.data[index] = res.data.list[0];
                this.data[index].showLoading = 1;
                this.data[index].arrange = 1; //排列图
                this.data[index].breviary = 1; //缩略图
                this.data[index].checkeds = 2; //
                this.$store.state.autovision.randomString && this.$store._mutations.updateKaryoStatus(element.id);
              }
            });
            this.checkedValues.forEach((itm, index) => {
              if (items == itm) {
                this.checkedValues.splice(index, 1);
              }
            });
            this.$store.commit("karyotypeNumberlistType", res.data.statisInfo);
            this.$forceUpdate();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },

    //右侧弹框移出事件
    mouseleavesEnter() {
      this.menus = false; //让菜单隐藏
    },

    //选中核型图
    handleCheckChange: function (value) {
      let showType = false;
      if (this.checkedValues.length == 0) {
        this.checkedValues.push(value);
      } else {
        this.checkedValues.forEach((items, index) => {
          if (items == value) {
            showType = true;
            this.checkedValues.splice(index, 1);
          }
        });
        if (!showType) {
          this.checkedValues.push(value);
        }
      }

      this.$store.commit("batchAnalysisListType", this.checkedValues);

      if (this.checkedValues.length > 0) {
        this.dataList.automaticType = 2;
        this.$emit("formData", this.dataList);
        let arr = [];
        this.data.forEach((item, index) => {
          for (let i = 0; i < this.checkedValues.length; i++) {
            if (item.id == this.checkedValues[i]) {
              arr.push(index);
            }
          }
        });
        this.data.forEach((item, index) => {
          item.checkeds = 2;
        });
        for (let j = 0; j < arr.length; j++) {
          this.data[arr[j]].checkeds = 1;
        }
        this.$forceUpdate();
      } else {
        this.data.forEach((item, index) => {
          item.checkeds = 2;
        });
        this.$forceUpdate();
        this.dataList.automaticType = 1;
        this.$emit("formData", this.dataList);
      }
    },

    //顶部进入分析页面方法
    IntoAnalysis: function () {
      if (this.data.length >= 0) {
        let value = this.data[0];
        this.goToTools(value);
      }
    },

    //去工具页面
    goToTools(item) {
      // const userType = JSON.parse(localStorage.getItem("loginObj")).userType;
      // console.log(userType, userType & 8, (userType & 8) !== 8);
      // if ((userType & 8) !== 8) {
      //   this.$message({
      //     type: 'warning',
      //     message: '身份认证通过后开通个人空间病例分析功能。请先完成身份认证！'
      //   })
      //   return;
      // }
      let pointList = localStorage.getItem("pointList");
      window.pointListObj = JSON.parse(pointList);
      let patientId = this.dataList.id;
      let id = this.dataList.id;
      const openPatient = this.$store._mutations.openPatient;

      // if (window.pointListObj.indexOf("/karyo/autoSegment") > -1) {
      if (Common.permissions("/karyo/autoSegment")) {
        //分析权限
        if (item.isDeal == 1) {
          if (this.checkRandomPending) {
            return;
          }
          this.checkRandomPending = true;
          localStorage.setItem("opened_patient_karyo:" + patientId, item.id);
          let post_data = {
            patientId: this.dataList.id,
          };
          this.$api.patientCheckRandom(post_data).then((res) => {
            if (res.code == 200) {
              if (item.isDeal == 1 && res.data.result == "true") {
                openPatient(patientId, item.id, item.patientName);
                /* this.$store._mutations.updatePatientInfo(id,info)*/
                // return;
                // const isOpened = localStorage.getItem("patient_opened_"+ patientId);
                // if(!isOpened){
                //     let getIntoAnalysis =[];
                //     let userId = JSON.parse(localStorage.getItem("loginObj")).userId;
                //     let casename = item.patientName;
                //     let getIntoAnalysisList = JSON.parse(localStorage.getItem('getIntoAnalysis' + userId));
                //     if (getIntoAnalysisList) {getIntoAnalysis = getIntoAnalysisList;}
                //     const param = window.btoa([encodeURIComponent(casename), patientId,item.id].join(','));
                //     localStorage.setItem("id", patientId);  //保留id返回的时候使用
                //     clearInterval(window.timestampName);//跳转的时候清空分析定时事件
                //     this.$store.commit("statusType", '-1');
                //     this.$router.push("/autovision?t="+param);
                // }else{
                //     this.$message.error("当前病例正在分析，请切换到已经打开的工具页窗口");
                // }
              } else if (res.data.result == "false") {
                this.$message.error(res.data.msg);
              }
            }
            this.checkRandomPending = false;
          });
        } else {
          this.$confirm(
            this.$t("popup.warning.karyo_no_alysis_warning"),
            this.$t("popup.warning.karyo_alysis"), {
            confirmButtonText: this.$t("btns.confirm"),
            cancelButtonText: this.$t("btns.cancel"),
            type: "warning",
                  closeOnClickModal: false
          }).then(() => {
            let post_data = {
              patientId: this.dataList.id,
              ids: item.id,
              type: 2,
            };
            this.$api.autoSegment(post_data).then((res) => {
              if (res.code == 200) {
                this.queryKaryoListByPatients();
                this.menus = false; //让菜单隐藏
              } else if(res.code != 122){
                this.$message.error(res.message);
              }
            });
          })
            .catch(() => { });
        }
      } else {
        this.$message.error(this.$t("popup.warning.no_right_warning"));
      }
    },

    //监听
    loadMoreHeightCenter() {
      let that = this;
      that.$nextTick(function () {
        let analysCenterClassHeight = parseInt(
          window.getComputedStyle(this.$refs.analysCenterClass).height
        ); //列表的固定高度

        //动态获取固定宽度
        let analysCenterClassWidth = parseInt(
          window.getComputedStyle(this.$refs.analysCenterClass).width
        );
        this.$store.commit("analysCenterClassWidth", analysCenterClassWidth);
        console.log(analysCenterClassWidth, '我是宽度');
        let analysCenterListHeight = parseInt(
          window.getComputedStyle(this.$refs.analysCenterList).height
        ); //动态生成的高度
        if (analysCenterClassHeight > analysCenterListHeight) {
          if (this.page == 1 && this.totalPage > 1) {
            this.page++;
            this.getMessageList();
          }
        }
      });
    },
  },

  mounted() {
    document.getElementById("orderFullScreenName").addEventListener("scroll", this.handleScrollx, true); //监听滚动条的高度
      window.onresize=()=>{
          return(()=>{
              this.loadMoreHeightCenter()
          })()
      }
  },
  watch: {
    //监听放大缩小按钮 之后图片有没有全部加载
    "dataList.rowList.row": {
      immediate: true,
      handler: function (value, oldVal) {
        if (oldVal == undefined) return false;
        this.loadMoreHeightCenter();
      },
    },

    "$store.state.caseId": function (value, oldVal) {
      if (!isNaN(Number(oldVal))) {
        this.dataList.id = value;
        this.$store.commit("firstLoadingType", 1);
        this.$store.commit("glassListType", []);
        this.$store.commit("karyotypeNumberlistType", {});
        this.data = [];
        this.dataNew = [];
        this.page = 1;
        this.glassIds = [];
        this.status = -1;
        this.checkedValues = []; //切换的时候清空选中的值
        this.getMessageList();
      } else {
        this.dataList.id = localStorage.getItem('id')
      }
    },
    //选中的核型图
    "$store.state.batchAnalysisList": function () {
      this.batchAnalysisList = this.$store.state.batchAnalysisList;
    },

    //监听报告删除核型图
    "$store.state.reviseGlassId": function () {
      this.data.forEach((item) => {
        if (item.id == this.$store.state.reviseGlassId) {
          item.hasReport = 0;
        }
      });
    },
    //监听报告添加核型图
    "$store.state.addReviseGlassId": function () {
      this.data.forEach((item) => {
        if (item.id == this.$store.state.addReviseGlassId) {
          item.hasReport = 1;
        }
      });
    },

    //批量删除 checkChangeDelete
    "$store.state.checkChangeDelete": function () {
      if (this.$store.state.checkAllName) {
        this.glassIds = [];
      } else {
        if (this.$store.state.glassIds.length > 0) {
          //测试
          this.glassIds = this.$store.state.glassIds;
        } else {
          this.glassIds = [0];
        }
      }
      this.sortType = this.$store.state.sortTypeList;
      if (this.$store.state.firstLoading != 1) {
        this.page = 1;
        this.data = [];
        this.getMessageList();
      }
    },
    "$store.state.sortTypeList": function () {
      if (this.$store.state.checkAllName) {
        this.glassIds = [];
      } else {
        if (this.$store.state.glassIds.length > 0) {
          //测试
          this.glassIds = this.$store.state.glassIds;
        } else {
          this.glassIds = [0];
        }
      }
      this.sortType = this.$store.state.sortTypeList;
      if (this.$store.state.firstLoading != 1) {
        this.page = 1;
        this.data = [];
        this.getMessageList();
      }
    },

    "$store.state.color": function () {
      if (this.$store.state.checkAllName) {
        this.glassIds = [];
      } else {
        if (this.$store.state.glassIds.length > 0) {
          //测试
          this.glassIds = this.$store.state.glassIds;
        } else {
          this.glassIds = [0];
        }
      }
      this.color = this.$store.state.color;
      if (this.$store.state.firstLoading != 1) {
        this.page = 1;
        this.data = [];
        this.getMessageList();
      }
    },
    //监听拨片选中 glassIdsType
    "$store.state.glassIds": function () {
      if (this.$store.state.clickGlass) {
        //先判断全选是否选中
        if (this.$store.state.checkAllName) {
          this.glassIds = [];
        } else {
          if (this.$store.state.glassIds.length > 0) {
            //测试
            this.glassIds = this.$store.state.glassIds;
          } else {
            this.glassIds = [0];
          }
        }

        this.status = this.$store.state.status;

        if (this.$store.state.firstLoading != 1) {
          this.page = 1;
          this.data = [];
          this.getMessageList();
        }
      }
    },

    //状态选择
    "$store.state.status": function () {
      if (this.$store.state.clickSlide || this.$store.state.clickSomeReview) {
        //先判断全选是否选中
        if (this.$store.state.checkAllName) {
          this.glassIds = [];
        } else {
          if (this.$store.state.glassIds.length > 0) {
            //测试
            this.glassIds = this.$store.state.glassIds;
          } else {
            this.glassIds = [0];
          }
        }
        this.status = this.$store.state.status;

        if (this.$store.state.firstLoading != 1) {
          this.page = 1;
          this.data = [];
          this.getMessageList();
        }
      }
    },
  },
};
</script>

<style scoped>
.change_color {
  position: absolute;
  left: 7px;
  bottom: 7px;
  height: 50px;
  width: 50px;
  z-index: 9;
}

.change_color_box {
  height: 100%;
  width: 100%;
  position: relative;
}
.change_color_box div:nth-child(1) {
  height: 24px;
  width: 24px;
  background: #e72323;
  position: absolute;
  bottom: 26px;
  right: 25px;
  border-radius: 3px;
  border: 1px solid #6c6c6c;
  cursor: pointer;
}
.change_color_box div:nth-child(1):hover {
  width: 30px;
  height: 30px;
  transition: width 0.1s, height 0.1s;
  background: #fc5050;
}
.change_color_box div:nth-child(2) {
  height: 24px;
  width: 24px;
  background: #fff25c;
  border-radius: 3px;
  border: 1px solid #6c6c6c;
  position: absolute;
  bottom: 26px;
  left: 26px;
  cursor: pointer;
}
.change_color_box div:nth-child(2):hover {
  width: 30px;
  height: 30px;
  transition: width 0.1s, height 0.1s;
  background: #fff57e;
}
.change_color_box div:nth-child(3) {
  height: 24px;
  width: 24px;
  background: #f3f3f3;
  border-radius: 3px;
  border: 1px solid #6c6c6c;
  position: absolute;
  top: 25px;
  left: 26px;
  cursor: pointer;
}
.change_color_box div:nth-child(3):hover {
  width: 30px;
  height: 30px;
  transition: width 0.1s, height 0.1s;
  background: #ffffff;
}
.change_color_box div:nth-child(4) {
  height: 24px;
  width: 24px;
  background: #429dff;
  border-radius: 3px;
  border: 1px solid #6c6c6c;
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
.change_color_box div:nth-child(4):hover {
  width: 30px;
  height: 30px;
  transition: width 0.1s, height 0.1s;
  background: #65afff;
}
.change_color_box div img {
  width: 100%;
  height: 100%;
  display: inline-block;
  cursor: pointer;
}

.change_top {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50px;
  right: 10px;
  z-index: 9;
  cursor: pointer;
}
.change_top > img {
  height: 30px;
  width: 30px;
}
</style>

