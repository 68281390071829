/**
 * openCV 和 wasm算法加载使用类
 */
class CAlgoModule {
    constructor() {
        this.OPENCV_URL = 'https://cdn.autovision.online/web/static/wasm/opencv.js';
        this.ALGORITHMC_URL = 'js/algorithmC.js?v=345';
        this.init()
    }

    async init() {
        if (!this.cv || !this.module) {
            console.warn("CAlgoModule need init")
            let loadCVAndAlgo = this.loadCVAndAlgo()
            loadCVAndAlgo = await loadCVAndAlgo
            console.log(new Date().getTime(), "loadCV:", window.cv, "loadModule:", window.Module)
        }
    }

    // 判断OpenCV和wasmModule是否加载
    async loadCVAndAlgo() {
        var loadCV = false,
            loadModule = false;
        window.Module = {};
        window.Module.onRuntimeInitialized = function() {
            let fromCv = (new Error()).stack.split("\n").pop().trim().indexOf("opencv.js") > -1;
            let fromAlg = (new Error()).stack.split("\n").pop().trim().indexOf("algorithmC.js") > -1;
            if (fromCv) {
                console.log(new Date().getTime(), "openCV onRuntimeInitialized finish fromCv:", fromCv)
                window.CVLoaded = true;
            }
            if (fromAlg) {
                console.log(new Date().getTime(), "WASM onRuntimeInitialized finish fromAlg:{}", fromAlg)
                window.ModuleLoaded = true;
                // console.log(new Date().getTime(), window.Module.getJSPoint())
            }
        }
        if (!window.CVLoaded) {
            loadCV = this.loadJsAsync(this.OPENCV_URL)
        }
        if (!window.ModuleLoaded) {
            loadModule = this.loadJsAsync(this.ALGORITHMC_URL)
        }
        loadCV = await loadCV
        loadModule = await loadModule
        console.log(new Date().getTime(), "loadCV:", window.CVLoaded, "loadModule:", window.ModuleLoaded)
    }

    onCvModuleRuntimeInitialized() {
        return new Promise((resolve, reject) => {
            let fromCv = (new Error()).stack.split("\n").pop().trim().indexOf("opencv.js") > -1;
            let fromAlg = (new Error()).stack.split("\n").pop().trim().indexOf("algorithmC.js") > -1;
            console.log(new Date().getTime(), "window.Module onRuntimeInitialized finish fromCv:{} fromAlg:{}", fromCv, fromAlg)
            if (fromCv) {
                window.CVLoaded = true;
            }
            if (fromAlg) {
                window.ModuleLoaded = true;
            }
            if (window.CVLoaded && window.ModuleLoaded) {
                resolve(true);
            }
        })
    }

    /**
     * 异步加载网络资源
     * @param {*} jsUrl 
     */
    loadJsAsync(jsUrl) {
        return new Promise((resolve, reject) => {
            let script = document.createElement('script');
            script.setAttribute('defer', '');
            // script.setAttribute('type', 'text/javascript');
            script.addEventListener('load', () => {
                console.log('success to load ' + jsUrl);
                resolve();
            });
            script.addEventListener('error', () => {
                console.error('Failed to load ' + jsUrl);
                reject()
            });
            script.src = jsUrl;
            document.body.appendChild(script)
        })
    }
}
export default CAlgoModule