<template>
  <div class="personalIinformationBox" :class="{'english': $store.state.language == 'en'}">
    <input type="file" id="selectfiles" style="display:none" />
    <div class="personalIinformationUser">
      <div class="personalIinformationContent">
        <div>{{$t('personal_info.user_name')}}</div>
        <div class="existence">{{userName}}</div>
      </div>
      <div class="dividerBottom">
        <Divider></Divider>
      </div>
    </div>
    <div class="personalIinformationUser" :class="{'currentPersonalIinformationUser':showPhoto}">
      <div class="personalIinformationContent">
        <div>{{$t('accountInfo.head')}}</div>
        <div class="notSet" v-show="!showPhoto">
          <img :src="headImgUrl" alt="" v-if="!!headImgUrl" />
        </div>
        <div class="personalIinformationEdit" @click="editHeadPhoto" v-show="!showPhoto">{{$t("personal_info.edit")}}</div>
        <div class="personalIinformationEdit" @click="editHeadPhoto" v-show="showPhoto">{{$t('personal_info.foldup')}}</div>
      </div>
      <div class="change-photo" v-show="showPhoto">
        <div class="avatar-uploader">
          <img v-if="headImgUrl && !isPercentage" :src="headImgUrl" class="avatar" />
          <el-progress v-if="isPercentage" type="circle" :percentage="percentage"></el-progress>
        </div>
        <input type="file" id="changePhoto" @change="uploadImg($event)" style="display:none" />
        <el-button class="change-btn" @click="changHead()">{{$t('accountInfo.change_head')}}</el-button>
      </div>
      <div class="dividerBottom">
        <Divider></Divider>
      </div>
    </div>
    <div class="personalIinformationUser" :class="[realNameText ==  $t('personal_info.foldup') ? 'currentPersonalIinformationUser' : '']">
      <div class="personalIinformationContent">
        <div>{{$t('personal_info.real_name')}}</div>
        <div class="notSet" v-html="realName"></div>
        <!-- <div class="personalIinformationEdit" v-html="realNameText" @click="realNameFun" v-show="isShowEdit"></div> -->
      </div>
      <div class="formBox" v-show="isRealName">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-form-item prop="realName">
            <el-input v-model="ruleForm.realName" :placeholder="$t('account_manage.input_real_name')"></el-input>
          </el-form-item>
          <el-form-item label="">
            <div class="btn determineNomal" @click="onSubmit('ruleForm')">
              {{$t('btns.confirm')}}
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="dividerBottom">
        <Divider></Divider>
      </div>
    </div>
    <div class="personalIinformationUser" :class="[passwordText == $t('personal_info.foldup') ? 'currentPersonalIinformationUser' : '']">
      <div class="personalIinformationContent">
        <div>{{$t('personal_info.password')}}</div>
        <div class="notSet">{{$t('personal_info.setted_up')}}</div>
        <div class="personalIinformationEdit" v-html="passwordText" @click="passwordFun"></div>
      </div>
      <div class="formBox" v-show="isPassword">
        <el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" class="demo-ruleForm">
          <el-form-item prop="newPwd" class="updatePassword">
            <el-input :type="passwordNewPwd ? 'password' : 'text'" v-model="ruleForm1.newPwd" :placeholder="$t('personal_info.input_newpsw')" autocomplete="new-password">
              <img :src="passwordNewPwd ? closeUrl : openUrl" alt="" slot="suffix" class="passwordEyeClick" @click="passwordClick()" />
            </el-input>

          </el-form-item>
          <el-form-item prop="smsCode" class="inputVerificationCode">
            <el-input type="text" v-model="ruleForm1.smsCode" :placeholder="$t('login.input_verifycode')" maxlength="4"></el-input>
            <div class="getCaptcha" @click="sendcode()">{{getCaptchaPswText}}</div>
          </el-form-item>
          <el-form-item label="">
            <div class="btn determineNomal" @click="onSubmit1('ruleForm1')">
              {{$t('btns.submit')}}
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="dividerBottom">
        <Divider></Divider>
      </div>
    </div>
    <div class="personalIinformationUser" :class="[phoneText == $t('personal_info.foldup') ? 'currentPersonalIinformationUser' : '']">
      <div class="personalIinformationContent">
        <div>{{$t('personal_info.bind_tel')}}</div>
        <div class="notSet" v-html="phone"></div>
        <div class="personalIinformationEdit" v-html="phoneText" @click="phoneFun"></div>
      </div>
      <div class="formBox" v-show="isPhone">
        <el-form :model="ruleForm2" :rules="isRulesPhone ? rules2 : rulesVerification2" ref="ruleForm2" class="demo-ruleForm">
          <el-form-item prop="phone" class="phone-number">
            <el-select v-model="ruleForm2.phoneCode" :popper-append-to-body="false" filterable>
              <el-option v-for="(item, index) in phoneData" :key="index" :label="item.useOption" :value="item.keyVal">
              </el-option>
            </el-select>－
            <el-input v-model="ruleForm2.phone" :placeholder="$t('personal_info.input_tel')" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item prop="phoneSms" class="inputVerificationCode sp">
            <el-input v-model="ruleForm2.phoneSms" :placeholder="$t('login.input_verifycode')" maxlength="4"></el-input>
            <div class="getCaptcha" @click="phoneSmsFun()" v-html="getCaptchaPhoneText"></div>
          </el-form-item>
          <el-form-item label="">
            <div class="btn determineNomal sp" @click="phone != $t('personal_info.no_bind')? onSubmitUpdate2('ruleForm2'): onSubmit2('ruleForm2')">
              {{$t('btns.submit')}}
            </div>
            <div class="phoneTips">
              {{$t('personal_info.remind')}}
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="dividerBottom">
        <Divider></Divider>
      </div>
    </div>
    <div class="personalIinformationUser" :class="[emailText == $t('personal_info.foldup') ? 'currentPersonalIinformationUser' : '']">
      <div class="personalIinformationContent">
        <div>{{$t('personal_info.bind_email')}}</div>
        <div class="notSet" v-html="email"></div>
        <div class="personalIinformationEdit" v-html="emailText" @click="emailFun"></div>
      </div>
      <div class="formBox" v-show="isEmail">
        <el-form :model="ruleForm3" :rules="isRulesEmail ? rules3 : rulesVerification3" ref="ruleForm3" class="demo-ruleForm">
          <el-form-item prop="email">
            <el-input v-model="ruleForm3.email" :placeholder="$t('personal_info.input_emial')"></el-input>
          </el-form-item>
          <el-form-item prop="emailSms" class="inputVerificationCode">
            <el-input v-model="ruleForm3.emailSms" :placeholder="$t('login.input_verifycode')" maxlength="4"></el-input>
            <div class="getCaptcha" @click="emailSmsFun('ruleForm3')" v-html="getCaptchaEmailText"></div>
          </el-form-item>
          <el-form-item label="">
            <div class="btn determineNomal" @click="email != $t('personal_info.no_bind')? onSubmitUpdate3('ruleForm3'): onSubmit3('ruleForm3')">
              {{$t('btns.bind')}}
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="dividerBottom">
        <Divider></Divider>
      </div>
    </div>
    <div class="personalIinformationUser" v-if="0">
      <div class="personalIinformationContent">
        <div>{{$t('accountInfo.third_apply')}}</div>
        <div class="notSet" v-show="!showPhoto">
          <img src="../../../assets/images/personalSettings/vx.png" alt="" />
        </div>
        <div class="personalIinformationEdit" @click="editHeadPhoto">{{$t('accountInfo.bind')}}</div>
      </div>
      <div class="dividerBottom">
        <Divider></Divider>
      </div>
    </div>
  </div>
</template>

<script>
import "../../../assets/scss/button.scss";
import "./../scss/personalIinformation.scss";
import Divider from "./../../divider";
import { reg } from "../../../assets/js/reg.js";
import uploader from "../../../assets/utils/fileUploader.js";
import { cloneDeep } from 'lodash';
import phoneIntNatnData from "../../../assets/loginJson/telLogin_intel_en.json";
var clickNum = null;
var clockPhone = null;
export default {
  name: "PersonalIinformation",
  data() {
    var validateNewPwd = (rule, value, callback) => {
      if (!reg.password.test(value)) {
        callback(
          new Error(this.$t('accountInfo.need_include'))
        );
      } else {
        callback();
      }
    };
    // var validateConfirmPwd = (rule, value, callback) => {
    //   if (!reg.password.test(value)) {
    //     callback(
    //       new Error(this.$t('accountInfo.need_include'))
    //     );
    //   } else {
    //     callback();
    //   }
    // };
    var validatePhone = (rule, value, callback) => {
      if (value == "") {
        callback(new Error(this.$t("login.input_tel")));
      } else {
        if (reg.pureNumbers.test(value)) {
          this.ruleForm2.phone = value.replace(reg.pureNumbers, "");
        } else {
          this.isSubmitPhone = true;
          callback();
          /*                    if (!reg.phone.test(value)) {
                                            this.isSubmitPhone = false;
                                            callback(new Error("请输入正确的手机号码"));
                                        } else {
                                            this.isSubmitPhone = true;
                                            callback();
                                        }*/
        }
      }
    };
    var validateEmail = (rule, value, callback) => {
      if (value === "") {
        this.isSubmitEmail = false;
        callback(new Error(this.$t("personal_info.input_emial")));
      } else {
        if (!reg.email.test(value)) {
          this.isSubmitEmail = false;
          callback(new Error(this.$t("personal_info.input_correct_email")));
        } else {
          this.isSubmitEmail = true;
          callback();
        }
      }
    };
    return {
      userName: "", // 用户名
      realName: "", // 真实姓名
      realNameText: this.$t("personal_info.edit"), // 收起、编辑按钮
      isShowEdit: false,
      isRealName: false, // 是否显示真实姓名判断
      password: "", // 密码
      passwordText: this.$t("personal_info.edit"),
      isPassword: false, // 是否显示密码判断
      phone: "",
      phoneText: this.$t("personal_info.edit"),
      isPhone: false, // 是否显示手机号码判断
      email: "",
      emailText: this.$t("personal_info.edit"),
      isEmail: false, // 是否显示邮箱判断
      phoneWaitTime: "60", // 倒计时时间
      getCaptchaPhoneText: this.$t("login.get_verifycode"), // 获取验证码文案
      phoneClick: true,
      pswWaitTime: "60",
      getCaptchaPswText: this.$t("login.get_verifycode"), // 获取验证码文案
      pswClick: true,
      emailWaitTime: "60",
      getCaptchaEmailText: this.$t("login.get_verifycode"),
      emailClick: true,
      realNameUpdateSuccess: this.$t("personal_info.name_updated"),
      passwordUpdateSuccess: this.$t("personal_info.password_updated"),
      phoneUpdateSuccess: this.$t("personal_info.tel_updated"),
      emailUpdateSuccess: this.$t("personal_info.email_updated"),
      isRulesPhone: true, // 不同的校验状态手机号
      isRulesEmail: true, // 不同的校验状态邮箱
      isSubmitPhone: false,
      isSubmitEmail: false,
      passwordNewPwd: true,
      closeUrl: require("../../../assets/images/personalSettings/iconEyeClose.svg"),
      openUrl: require("../../../assets/images/personalSettings/iconEyeOpen.svg"),
      ruleForm: {
        realName: "",
      },
      rules: {
        realName: [
          {
            required: true,
            message: this.$t("account_manage.input_real_name"),
            trigger: "blur",
          },
          {
            min: 2,
            max: 30,
            message: this.$t("organize_approve.organName_length_demand"),
            trigger: "blur",
          },
        ],
      },
      ruleForm1: {
        newPwd: "",
        smsCode: "",
        phone: "",
        phoneCode: ""
      },
      rules1: {
        oldPwd: [
          {
            required: true,
            message: this.$t("personal_info.input_oldpsw"),
            trigger: "blur",
          },
        ],
        newPwd: [
          {
            required: true,
            message: this.$t("personal_info.input_newpsw"),
            trigger: "blur",
          },
          {
            validator: validateNewPwd,
            trigger: "blur",
          },
        ],
        smsCode: [
          {
            required: true,
            message: this.$t("login.input_verifycode"),
            trigger: "blur",
          }
        ],
      },
      ruleForm2: {
        phone: "",
        phoneSms: "",
        phoneCode: ""
      },
      rules2: {
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        phoneSms: [
          {
            required: true,
            message: this.$t("login.input_verifycode"),
            trigger: "blur",
          },
        ],
      },
      rulesVerification2: {
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
      },
      ruleForm3: {
        email: "",
        emailSms: "",
      },
      rules3: {
        email: [
          {
            validator: validateEmail,
            trigger: "blur",
          },
        ],
        emailSms: [
          {
            required: true,
            message: this.$t("login.input_verifycode"),
            trigger: "blur",
          },
        ],
      },
      rulesVerification3: {
        email: [
          {
            validator: validateEmail,
            trigger: "blur",
          },
        ],
      },
      accountType: "1",
      labName: "",
      labEidt: false,
      showPhoto: false,
      normalUrl: require("@/assets/images/common/head-photo.png"),
      headImgUrl: "",
      isPercentage: false,
      percentage: 0,
      user: '',
      getCodeShow: 1, //
      codeTextNum: "", //到计数时间
      clock: ''
    };
  },
  created() {
    this.accountType = this.$store.state.accountType;
    this.getQueryUserByToken();
  },
  methods: {
    editHeadPhoto() {
      this.showPhoto = !this.showPhoto;
    },

    // 点击上传
    changHead() {
      $("#changePhoto").trigger("click");
    },
    uploadImg(e) {
      let _file = e.target.files[0];
      _file.israndom = true;
      if (!_file) return;
      let name = _file.name.split(".");
      !!name.length && name.pop();
      if (!reg.specialImgCharacters.test(name)) {
        this.$message.error(this.$t('accountInfo.imgName_cannot_include_special'));
        return;
      }
      const isLt10M = _file.size / 1024 / 1024 < 10;
      let formats = ["bmp", "png", "jpg", "jpe", "jpeg"];
      let format = _file.name.split(".").pop().toLowerCase();
      if (isLt10M && formats.indexOf(format) > -1) {
        this.isPercentage = true;
        this.percentage = 0;
        let setPercentage = setInterval(() => {
          this.percentage += 5;
          if (this.percentage >= 99) {
            this.percentage = 99;
          }
        }, 200);
        this.$forceUpdate();
        // _file.name = _file.name + new Date().getTime();
        uploader({
          files: [_file],
          path: "head/",
          processHandle: (file) => { },
          ossUploadAccess: this.$store.state.ossUploadAccess,
        }).then((filelist) => {
          const imgUrl = filelist[0].path;
          this.headImgUrl = imgUrl ? this.ossUrlWebFir(imgUrl, 1, 130, 130) : this.normalUrl;
          this.isPercentage = false;
          clearInterval(setPercentage);
          setPercentage = null;
          this.$api.modifyHeadUrl({ headUrl: imgUrl }).then((res) => {
            if (res.code == 200) {
              this.$message.success(this.$t('accountInfo.edit_success'));

              let loginObj = JSON.parse(localStorage.getItem("loginObj"));
              loginObj.userName = this.userName;
              loginObj.headUrl = imgUrl;
              localStorage.setItem("loginObj", JSON.stringify(loginObj));

              this.$store.commit("changeHeadUrl", imgUrl);
            } else {
              this.$message.error(res.message);
            }
          });
        });
      } else {
        this.$message.error(this.$t("selfDefined_template.image_size_demand"));
      }
    },
    // 初始化文案
    clearStatus() {
      this.isRealName = false;
      this.realNameText = this.$t("personal_info.edit");
      this.isPassword = false;
      this.passwordText = this.$t("personal_info.edit");
      this.isPhone = false;
      this.phoneText = this.$t("personal_info.edit");
      this.isEmail = false;
      this.emailText = this.$t("personal_info.edit");
    },
    // 初始化真实姓名表单
    clearRealName() {
      this.ruleForm = {
        realName: "",
      };
    },
    // 初始化密码表单
    clearPassword() {
      this.ruleForm1 = {
        oldPwd: "",
        newPwd: "",
        smsCode: "",
      };
    },
    // 初始化手机号表单
    clearPhone() {
      this.ruleForm2 = {
        phone: "",
        phoneSms: "",
        phoneCode: ""
      };
    },
    // 初始化邮箱表单
    clearEmail() {
      this.ruleForm3 = {
        email: "",
        emailSms: "",
      };
    },
    // 点击获取密码
    passwordClick() {
      this.passwordNewPwd = !this.passwordNewPwd;
    },

    // 点击真实姓名提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.userPerfectUser(this.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message.success(this.realNameUpdateSuccess);
              this.clearRealName();
              this.getQueryUserByToken();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },

    //短信倒计时
    timer() {
      if (this.time > 0) {
        this.getCodeShow = 2;
        this.time--;
        this.codeTextNum = this.time + this.$t("unit.seconds");
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.codeTextNum = 60;
        this.getCodeShow = 1;
      }
    },
    // 点击密码提交
    onSubmit1(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm1.phone = this.user.phone;
          this.ruleForm1.phoneCode = this.user.phoneCode;
          this.$api.updatePwd(this.ruleForm1).then((res) => {
            if (res.code == 200) {
              this.$message.success(this.passwordUpdateSuccess);
              this.clearPassword();
              this.getQueryUserByToken();
              window.clearInterval(this.clock);
              this.getCaptchaPswText = this.$t("login.get_verifycode");
              this.pswWaitTime = 60;
              this.pswClick = true;
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    onSubmit2(formName) {
      this.isRulesPhone = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.userPerfectUser(this.ruleForm2).then((res) => {
            if (res.code == 200) {
              this.phoneFun();
              this.$message.success(this.phoneUpdateSuccess);
              this.clearPhone();
              this.getQueryUserByToken();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 点击手机号提交
    onSubmitUpdate2(formName) {
      this.isRulesPhone = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let postData = {
            newPhone: this.ruleForm2.phone,
            smsCode: this.ruleForm2.phoneSms,
            phoneCode: this.ruleForm2.phoneCode,
          };
          this.$api.updatePhoneEmail(postData).then((res) => {
            if (res.code == 200) {
              this.phoneFun();
              this.$message.success(this.phoneUpdateSuccess);
              this.clearPhone();
              this.getQueryUserByToken();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 点击邮箱提交
    onSubmit3(formName) {

      this.isRulesEmail = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.userPerfectUser(this.ruleForm3).then((res) => {
            if (res.code == 200) {
                this.emailFun();

              this.$message.success(this.emailUpdateSuccess);
              this.clearEmail();
              this.getQueryUserByToken();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    onSubmitUpdate3(formName) {

      this.isRulesEmail = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let postData = {
            newEmail: this.ruleForm3.email,
            smsCode: this.ruleForm3.emailSms,
          };
          this.$api.updatePhoneEmail(postData).then((res) => {
            if (res.code == 200) {
                this.emailFun();

              this.$message.success(this.emailUpdateSuccess);
              this.clearEmail();
              this.getQueryUserByToken();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 真实姓名点击收起、编辑方法
    realNameFun() {
      this.isRealName = !this.isRealName;
      this.isRealName == true
        ? (this.realNameText = this.$t("personal_info.foldup"))
        : (this.realNameText = this.$t("personal_info.edit"));
      this.clearRealName();
    },
    // 手机号点击收起、编辑方法
    phoneFun() {
      this.isPhone = !this.isPhone;
      this.isPhone == true
        ? (this.phoneText = this.$t("personal_info.foldup"))
        : (this.phoneText = this.$t("personal_info.edit"));
        window.clearInterval(clockPhone);
        this.getCaptchaPhoneText = this.$t("login.get_verifycode");
        this.phoneWaitTime = 60;
        this.phoneClick = true;
      this.clearPhone();
      // let lang = localStorage.getItem('language');
      // if (this.$store.state.language == 'en') {
      //   this.ruleForm2.phoneCode = this.phoneData[0].keyVal;
      // } else {
      //   this.ruleForm2.phoneCode = this.phoneData[1].keyVal;
      // }
      const la = navigator.language.split("-")[1];

      let curr = this.phoneData.find(a => a.short === la);
      this.ruleForm2.phoneCode = curr.tel + ',' + curr.short;
    },
    // 邮箱点击收起、编辑方法
    emailFun() {

      this.isEmail = !this.isEmail;
      this.isEmail == true
        ? (this.emailText = this.$t("personal_info.foldup"))
        : (this.emailText = this.$t("personal_info.edit"));
        this.clearEmail();
        window.clearInterval(clickNum);
        this.getCaptchaEmailText = this.$t("login.get_verifycode");
        this.emailWaitTime = 60;
        this.emailClick = true;
    },
    // 密码点击收起、编辑方法
    passwordFun() {
      this.isPassword = !this.isPassword;
      this.isPassword == true
        ? (this.passwordText = this.$t("personal_info.foldup"))
        : (this.passwordText = this.$t("personal_info.edit"));
      this.clearPassword();
    },
    //密码修改短信获取验证码
    sendcode() {
      let myToken, language;
      if (localStorage.getItem("loginObj")) {
        myToken = JSON.parse(localStorage.getItem("loginObj")).token;
      } else {
        myToken = localStorage.getItem("loginObj");
      }
      if (localStorage.getItem("language") == "en") {
        language = "en_US";
      } else {
        language = "zh_CN";
      }
      this.time = 60;
      let phone_data = {
        phoneEmail: this.user.phone,
        smsType: 3,
        isFinal: 1,
        phoneCode: this.user.phoneCode,
        token: myToken,
        language: language
      };
      this.$api.sms(phone_data).then((res) => {
        if (res.code == 200) {
          // this.timer();
          this.pswCountDownFun();
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 手机号获取验证码
    phoneSmsFun() {
      this.isRulesPhone = false;
      let myToken, language;
      if (localStorage.getItem("loginObj")) {
        myToken = JSON.parse(localStorage.getItem("loginObj")).token;
      } else {
        myToken = localStorage.getItem("loginObj");
      }
      if (localStorage.getItem("language") == "en") {
        language = "en_US";
      } else {
        language = "zh_CN";
      }
      if (!this.ruleForm2.phone || !this.isSubmitPhone) return;
      let postData = {
        phoneEmail: this.ruleForm2.phone,
        smsType: 4,
        isFinal: 1,
        phoneCode: "86",
        token: myToken,
        language: language
      };
      this.$api.sms(postData).then((res) => {
        if (res.code == 200) {
          this.phoneCountDownFun();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 手机号码重新发送倒计时
    phoneCountDownFun() {
      if (!this.phoneClick) return;
      this.phoneClick = false;
      this.getCaptchaPhoneText = this.phoneWaitTime + this.$t("personal_info.retry");
      clockPhone = window.setInterval(() => {
        this.phoneWaitTime--;
        this.getCaptchaPhoneText = this.phoneWaitTime + this.$t("personal_info.retry");
        if (this.phoneWaitTime < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(clockPhone);
          this.getCaptchaPhoneText = this.$t("login.get_verifycode");
          this.phoneWaitTime = 60;
          this.phoneClick = true;
        }
      }, 1000);
    },
    pswCountDownFun() {
      if (!this.pswClick) return;
      this.pswClick = false;
      this.getCaptchaPswText = this.pswWaitTime + this.$t("personal_info.retry");
      this.clock = window.setInterval(() => {
        this.pswWaitTime--;
        this.getCaptchaPswText = this.pswWaitTime + this.$t("personal_info.retry");
        if (this.pswWaitTime < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.clock);
          this.getCaptchaPswText = this.$t("login.get_verifycode");
          this.pswWaitTime = 60;
          this.pswClick = true;
        }
      }, 1000);
    },
    // 邮箱获取验证码
    emailSmsFun() {
      this.isRulesEmail = false;
      let myToken, language;
      if (localStorage.getItem("loginObj")) {
        myToken = JSON.parse(localStorage.getItem("loginObj")).token;
      } else {
        myToken = localStorage.getItem("loginObj");
      }
      if (localStorage.getItem("language") == "en") {
        language = "en_US";
      } else {
        language = "zh_CN";
      }
      if (!this.ruleForm3.email || !this.isSubmitEmail) return;
      let postData = {
        phoneEmail: this.ruleForm3.email,
        smsType: 5,
        isFinal: 1,
        token: myToken,
        language: language
      };
      this.$api.sms(postData).then((res) => {
        if (res.code == 200) {
          this.emailCountDownFun();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 邮箱重新发送倒计时
    emailCountDownFun() {
      if (!this.emailClick) return;
      this.emailClick = false;
      this.getCaptchaEmailText =
        this.emailWaitTime + this.$t("personal_info.retry");
      clickNum= window.setInterval(() => {
        this.emailWaitTime--;
        this.getCaptchaEmailText =
          this.emailWaitTime + this.$t("personal_info.retry");
        if (this.emailWaitTime < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(clickNum);
          this.getCaptchaEmailText = this.$t("login.get_verifycode");
          this.emailWaitTime = 60;
          this.emailClick = true;
        }
      }, 1000);
    },
    // 获取个人信息
    getQueryUserByToken() {
      this.$api.getQueryUserByToken({}).then((res) => {
        if (res.code == 200) {
          const { expert, user } = res.data;
          this.user = user;
          this.userName = user.userName;
          this.$store.commit("userName", this.userName);
          let loginObj = JSON.parse(localStorage.getItem("loginObj"));
          loginObj.userName = this.userName;

          this.headImgUrl = user.headUrl ? this.ossUrlWebFir(user.headUrl.split('?')[0], 1, 130, 130) : this.normalUrl;
          loginObj.headUrl = user.headUrl;
          localStorage.setItem("loginObj", JSON.stringify(loginObj));

          this.$store.commit("changeHeadUrl", user.headUrl);

          user.realName
            ? ((this.realName = user.realName),
              (this.isShowEdit = false),
              (this.isRealName = false))
            : ((this.realName = this.$t("personal_info.not_setup")),
              (this.isShowEdit = true));
          user.password
            ? this.$t("personal_info.setted_up")
            : this.$t("personal_info.not_setup");
          user.phone
            ? (this.phone = user.phone.replace(reg.asteriskReplace, "$1****$2"))
            : (this.phone = this.$t("personal_info.no_bind"));
          user.email
            ? (this.email =
              user.email.split("@")[0].substring(0, 3) +
              "****@" +
              user.email.split("@")[1])
            : (this.email = this.$t("personal_info.no_bind"));
        } else {
          // this.$router.push({
          //   path: "/",
          // });
          this.$message.error(res.message);
        }
      });
    },
  },
  computed: {
    codeText: {
      get() {
        return this.$t('login.get_verifycode')
      },
      set() { }
    },
    phoneData() {
      let telDataIntel = cloneDeep(phoneIntNatnData);
      if (this.$store.state.language === 'en') {
        telDataIntel = telDataIntel.map(a => a.en).sort().map(key => { return telDataIntel.filter(a => a.en === key)[0] })
        telDataIntel.forEach((item) => {
          item.useOption = item.en + '(+' + item.tel + ')';
          item.keyVal = item.tel + ',' + item.short
        })
      } else {
        telDataIntel = telDataIntel.map(a => a.name).sort().map(key => { return telDataIntel.filter(a => a.name === key)[0] })
        telDataIntel.forEach((item) => {
          item.useOption = item.name + '(+' + item.tel + ')';
          item.keyVal = item.tel + ',' + item.short
        })
      }
      return telDataIntel;
    }
  },

  watch: {
    "$store.state.isUpdateSetting": function () {
      this.clearStatus();
    },
    "$store.state.accountType": function (val) {
      this.accountType = val;
    },
    "$store.state.ossUploadAccess": function () {
      this.headImgUrl = this.user.headUrl ? this.ossUrlWebFir(this.user.headUrl, 1, 130, 130) : this.normalUrl;
    },
  },
  components: {
    Divider,
  },
};
</script>

<style lang="scss">
.personalIinformationBox {
  .avatar-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 130px;
    margin-top: -46px;
    margin-bottom: 12px;
    box-shadow: 0px 5px 5px #ccc;
    img {
      width: auto;
      max-width: 130px;
    }
  }
  .change-photo {
    display: flex;
    margin-left: 118px;
  }
  .el-button.change-btn {
    width: unset;
    height: 36px;
    margin: 48px 0 0 20px;
    padding: 0 20px !important;
    font-size: 16px;
    background: #e3e3e3;
    border-radius: 4px;
    border: 0;
  }
  .verify_code {
    display: flex;
    width: 270px;
    .updatePassword {
      width: 144px;
    }
    .el-input__inner {
      width: 144px;
    }
    .get_code {
      flex: 1;
      width: 112px;
      height: 40px;
      color: #4773b8;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #c7c7c7;
      &:hover {
        background: #f0f7ff;
      }
    }
  }
}
</style>
