import cvCommon from './opencv-common.js'

function Count(stage, controller) {
    const arrangeController = this.arrangeController;
    const showCount = () => {
        stage.objects.forEach(item => {
            if (item.type === 'point') {
                item.hide = false;
            }
            if(['polygon','text'].includes(item.type)){
                item.hide = !this.assistantMarker
            }
        })
        stage.draw();
    }
    /**
     * 添加计数点
     */
    const addPoint = (e) => {
        let pos = stage.getPos(e);
        pos = {
            x: parseInt(pos.x),
            y: parseInt(pos.y)
        }
        const shape = stage.isPointInner(e, false);
        const _pos = stage.getPosOnPrimaryImage(e);
        console.warn(pos, _pos);
        if (shape) {
            const chromo = controller.getChromoByShape(shape);
            const pointsPos = typeof chromo.countPoints === 'string' ? JSON.parse(chromo.countPoints) : chromo.countPoints;
            const len = pointsPos.length;
            if (len >= 6) return;
            // console.log(chromo);
            if (typeof chromo.countPoints === 'string') {
                let points = JSON.parse(chromo.countPoints);
                points.push(_pos);
                chromo.countPoints = JSON.stringify(points);
            } else {
                chromo.countPoints.push(_pos)
            }
            console.warn(chromo.countPoints);
            controller.resetKaryo();
            showCount();
            this.$refs.allTheKaryos.addDelPoints(chromo.index, chromo.countPoints);
        } else {
            const point = stage.graphs.point({
                point: pos,
                lineWidth: 2,
                hide: false,
                length: 10,
                zindex: 2,
            });
            controller.countPoints.push(point);
            const list = controller.countPoints.map(a => {
                return {
                    x: a.point.x - stage.offset.TRANS_X,
                    y: a.point.y - stage.offset.TRANS_Y
                }
            })
            this.karyoInfo.undetectedChromoPoints = JSON.stringify(list)
            stage.addChild(point);
            // stage.draw();
            controller.resetKaryo();
        }

        // this.$refs.allTheKaryos.baseDataChange(this.chromoList);
        // controller.karyoInfo.singleNum = parseInt(controller.karyoInfo.singleNum) + 1;
        this.arrangeController.createKaryotypeExpression();
        this.cachePool.save();
        this.cachePool.record({
            type: 'addPoint',
            message: '添加计数点',
            data: _pos,
            result: null
        })
    }

    /**
     * 删除计数点
     */
    const deletePoint = (shape) => {
        const indexOfController = controller.countPoints.indexOf(shape);
        const indexOfObjects = stage.objects.indexOf(shape);
        console.log(indexOfController, indexOfObjects);
        if (indexOfController > -1) {
            controller.countPoints.splice(indexOfController, 1);
            this.karyoInfo.undetectedChromoPoints = JSON.stringify(controller.countPoints.map(a => {
                return {
                    x: a.point.x - stage.offset.TRANS_X,
                    y: a.point.y - stage.offset.TRANS_Y
                }
            }))
        } else {
            controller.chromoMap.forEach((item, i) => {
                const index = item.points.indexOf(shape)
                if (index > -1) {
                    item.points.splice(index, 1);
                    if (item.points.length) {
                        const points = item.points.map(a => {
                            return {
                                x: a.point.x - stage.offset.TRANS_X,
                                y: a.point.y - stage.offset.TRANS_Y
                            }
                        });
                        console.log(points);
                        item.chromo.countPoints = typeof item.chromo.countPoints === 'string' ? JSON.stringify(points) : points;
                        this.$refs.allTheKaryos.addDelPoints(item.chromo.index, item.chromo.countPoints);
                    } else {
                        const _index = controller.chromoList.indexOf(item.chromo);
                        _index > -1 && controller.chromoList.splice(_index, 1);
                        arrangeController.reArrange();

                        const chromoMap = controller.chromoMap.filter(a => a.chromo === item.chromo)[0];
                        let delArr = window.chromoMap
                        const cindex = delArr.map(it => it.chromo).indexOf(item.chromo);
                        cindex === -1 ? delArr.push(chromoMap) : '';
                        window.chromoMap = delArr;
                    }

                }
            })
        }
        controller.karyoInfo.singleNum = parseInt(controller.karyoInfo.singleNum) - 1;
        this.arrangeController.createKaryotypeExpression();
        controller.resetKaryo();
        showCount();
        // this.$refs.allTheKaryos.baseDataChange(this.chromoList);
        this.cachePool.save();
        this.cachePool.record({
            type: 'deletePoint',
            message: '删除计数点',
            data: shape.point,
            result: null
        })
    }

    /**
     * 点击非染色体区域内 根据真实坐标点结合算法自动识别出染色体
     */
    const addChromo = (e) => {
        //如果chromoList的jsmat尚未生成完毕 则提示或返回
        if (!this.VectorJSMatFinished) {
            return;
        }
        const pos = stage.getPosOnPrimaryImage(e);
        let clickPosiJS = cvCommon.getJSPointData(pos.x, pos.y)
        const originPicJS = this.karyoController.originPicJS;
        const optBackPicJS = this.isShowPrimaryImage ? this.karyoController.optBackPicJS : this.karyoController.optPicJS;
        const VectorJSMat = this.VectorJSMat;
        console.log(pos, originPicJS, optBackPicJS, VectorJSMat);

        /**********************************************************************************
            input :
            JSMat originPictureJS				Origin image
            JSMat optimalImageJS                Optimal image without background removal
            vector<JSchromo> chromoArr			All chroms
            JSPoint clickPosiJS					Point of mouse click
            int index						    New chromosome index
        **************************************************************************************/
        // JSResult MouseClickGetChromObj(JSMat originPictureJS, JSMat optimalImageJS, vector<JSchromo> chromoArr, JSPoint clickPosiJS, int index);
        getMouseClickChromo(originPicJS, optBackPicJS, VectorJSMat, clickPosiJS).then((newChromo) => {
            if (newChromo) {
                console.warn(newChromo)
                let maxIndex = Math.max.apply(Math, controller.chromoList.map(item => {
                    return item.index
                })) + 1;
                newChromo.index = maxIndex;
                controller.chromoList.push(newChromo)
                reInit(controller.chromoList)
            }
        })

    }

    function getMouseClickChromo(originPicJS, optBackPicJS, VectorJSMat, clickPosiJS, srcChromo) {
        return new Promise((resolve) => {
            try {
                let manualSegmentFun = window.Module.MouseClickGetChromObj(originPicJS, optBackPicJS, VectorJSMat, clickPosiJS, 0)
                 /***************内存泄露测试代码，测试结果，不存在泄露 */
                // for (let index = 0; index < 500; index++) {
                //     let manualSegmentFun1 = window.Module.MouseClickGetChromObj(originPicJS, optBackPicJS, VectorJSMat, clickPosiJS, 0)
                //     let nowAddr = window.Module._malloc(1)
                //     console.log(new Date().getTime(), "MouseClickGetChromObj: ", index, (nowAddr-window.ModuleStart)/(1024 * 1024))
                // }
                if (manualSegmentFun.result == 0) {
                    let chr = manualSegmentFun.chrArr.get(0)
                    cvCommon.getPaintChromoData(chr).then((newChromo) => {
                        if (newChromo) {
                            newChromo.chromoId = 26;

                            newChromo.chromoDataJS = cvCommon._getChromoDataJS(chr, srcChromo)
                            resolve(newChromo);
                        }
                    })
                } else {
                    console.warn("getMouseClickChromo error:", manualSegmentFun)
                    resolve(false)
                }
            } catch (error) {
                console.error(error)
                resolve(false)
            }
        })
    }

    const reInit = (chromoList) => {
        this.chromoList = chromoList;
        this.cachePool.save();
        this.karyoController.resetKaryo();
        this.arrangeController.reArrange();
    }
	
	//左键添加计数
	stage.event.click({
		button:0,
		getStatus: () => {
		    return this.toolsType === 'count';
		}
	},({e})=>{
		let pos = stage.getPos(e);
		pos = {
		    x: parseInt(pos.x),
		    y: parseInt(pos.y)
		}
		const shape = stage.isPointInner(e, false);
		const _pos = stage.getPosOnPrimaryImage(e);
		if (shape) {
		    const chromo = controller.getChromoByShape(shape);
		    const pointsPos = typeof chromo.countPoints === 'string' ? JSON.parse(chromo.countPoints) : chromo.countPoints;
		    const len = pointsPos.length;
		    if (len >= 6) return;
		    // console.log(chromo);
		    if (typeof chromo.countPoints === 'string') {
		        let points = JSON.parse(chromo.countPoints);
		        points.push(_pos);
		        chromo.countPoints = JSON.stringify(points);
		    } else {
		        chromo.countPoints.push(_pos)
		    }
		    console.warn(chromo.countPoints);
		    controller.resetKaryo();
		    showCount();
		    this.$refs.allTheKaryos.addDelPoints(chromo.index, chromo.countPoints);
			this.cachePool.save();
		}else{
			const pos = stage.getPos(e);
			// let delArr = !!sessionStorage.getItem('deleteChrome') ? JSON.parse(sessionStorage.getItem('deleteChrome')) : [];
			let delArr = window.chromoMap;
			const item = delArr.filter(item => item.shape.isPointInner(pos.x, pos.y))[0];
			if (item) {
			    console.log(1)
			    controller.chromoList.push(item.chromo)
			    reInit(controller.chromoList)
			} else {
			    console.log(2)
			    addChromo(e)
			}
		}
	})
	//右键删除计数 
	stage.event.click({
		button:2,
		getStatus: () => {
		    return this.toolsType === 'count';
		}
	},({e})=>{
		const point = stage.isPointInner(e, false, 'point');
		if(point){
			deletePoint(point);
		}
	})
	
	
    stage.event.click({
        getStatus: () => {
            return this.toolsType === 'count' && 0;
        }
    }, ({
        e
    }) => {
        const point = stage.isPointInner(e, false, 'point');
        const chromo = stage.isPointInner(e, false);
        console.log(point);
        if (point) {
            deletePoint(point);
        } else if (chromo) {
            addPoint(e);
        } else {
            const pos = stage.getPos(e);
            // let delArr = !!sessionStorage.getItem('deleteChrome') ? JSON.parse(sessionStorage.getItem('deleteChrome')) : [];
            let delArr = window.chromoMap;
            const item = delArr.filter(item => item.shape.isPointInner(pos.x, pos.y))[0];
            if (item) {
                console.log(1)
                controller.chromoList.push(item.chromo)
                reInit(controller.chromoList)
            } else {
                console.log(2)
                addChromo(e)
            }
        }
    })
}
export default Count;