window.isNetworkError = false;
const heartbeatMixin = {
    methods: {
        heartbeatApi(isReConnect){
            clearTimeout(this.heartTimer);
            this.heartTimer = null;
            const param = {
                patientId : this.caseId,
                karyoId : this.karyoId,
                randomString : this.randomString,
                version : this.version
            }
            if(isReConnect){
                param.reconnect = true;
                param.remoteVersion = this.remoteVersion
            }
            this.$api.heartbeat(param).then(res=>{
                // if(!this.heartTimer) return;
                window.isNetworkError = false;
                this.checkPatientLock(res);
                this.version++;
            }).catch(()=>{
                window.isNetworkError = true;
                this.heartTimer = setTimeout(()=>{
                    this.heartbeat(isReConnect);
                },3000)
            })
        },
        /**
         * 心跳
         */
        heartbeat(isReConnect){
            if(!this.$store.state.heartbeating){
                clearTimeout(this.heartTimer);
                this.heartTimer = null;
                return;
            }
            this.heartbeatApi(isReConnect);
        },

        /**
         * 检查病例锁
         */
        checkPatientLock(res){
            let date = res.data.date; // true:没有超过5分钟 false:超过5分钟
            let hasLock = res.data.hasLock; // 1:自己持有锁 2:别人持有锁 3:没有人持有这个病例的锁
            let result = res.data.result; // true:randomString有效 false:randomString失效
            let hasDelete = res.data.hasDelete; // true:hasDelete表示该核型图已被删除 false:hasDelete表示该核型图没有删除
            if(!this.$store.state.heartbeating){
                return;
            }
            const bool1 = result == "false";
            const bool2 = date == "false" && (hasLock == 1 || hasLock == 3);
            
            if (hasDelete == "true") {
                this.whenKaryoDeleted();
            } else {
                if(bool1 || bool2){
                    // this.whenNotMoveOverFiveMinites();
					this.retryConnectLock();
                }else if(hasLock == 2){
                    this.whenPatientOpened();
                }else{
                    this.currentVersion = this.version;
                    this.heartbeatLastSuccessTime = +new Date();
                    if(this.$store.state.heartbeating){
                        this.heartTimer = setTimeout(()=>{
                            this.heartbeat();
                        },5000)
                    }
                }
            }
            //自己有锁 继续心跳

            //别人有锁 返回上一页面

            //超时5分钟 弹出提示，点击确定尝试重连
        },

        /**
         * 核型图已被删除
         */
        whenKaryoDeleted(){
            this.$alert(this.$t('new_interaction.message_karyotype'),'',{
                type: 'warning',
                closeOnClickModal: false,
                closeOnPressEscape: false,
                showClose: false
            }).then(res=>{
                this.autoChangeKaryo();
            }).catch(res=>{
                this.autoChangeKaryo();
            })
        },

        /**
         * 当前核型图被删除，切换到下一个核型图
         * 如果当前病例没有核型图了，则返回分析系统页
         */
        autoChangeKaryo(){
            const item = this.karyoList.filter(a=>a.id===this.karyoId)[0];
            let index = this.karyoList.indexOf(item);
            this.karyoList.splice(index,1);
            const karyo = this.karyoList[index] || this.karyoList[0];
            if(!karyo){
                this.returnAnalys();
            }else{
                this.karyoId = karyo.id;
                this.reloadKaryo();
            }
        },

        /**
         * 重新加载核型图
         */
        reloadKaryo(){
            window.loadingInstance = this.$loading({
                background: 'rgba(255,255,255,.3)',
                customClass : 'autovision-loading',
                text: this.$t('new_interaction.message_num')
            });
            this.$store.state.heartbeating = false;
            clearTimeout(this.heartTimer);
            this.heartTimer = null;
            this.$parent.deleteLock(()=>{
                this.isPageLoading = true;
                this.queryOss(this.karyoId);
                this.queryKaryoList(true);
                setTimeout(()=>{
                    window.loadingInstance.close()
                },300)
            })
        },

        /**
         * 超时后点击确定，尝试重新连接
         */
        retryConnectLock(){
            this.heartbeatApi(true);
        },

        /**
         * 超过5分钟未操作
         */
        whenNotMoveOverFiveMinites(){
            if(this.$parent.currentTabIndex === 1){
                this.showConfirmOverTime();
            }else{
                this.$parent.eventQueue.push({
                    tabindex : 1,
                    handle : this.showConfirmOverTime
                })
            }
        },
        showConfirmOverTime(){
            this.isNotMoveOverFiveMinites = true;
            this.$confirm(this.$t('popup.warning.longtime_remind'),'',{
                type: 'warning',
                closeOnClickModal: false,
                closeOnPressEscape: false,
                showClose: false
            }).then(res=>{
                this.returnAnalys();
            }).catch(res=>{
                this.isNotMoveOverFiveMinites = false;
                this.retryConnectLock();
            })
        },

        /**
         * 当前病例正在分析
         */
        whenPatientOpened(){
            this.$alert(this.$t('popup.warning.analyzing_try_later'),'',{
                type: 'warning',
                closeOnClickModal: false,
                closeOnPressEscape: false,
                showClose: false
            }).then(res=>{
                this.returnAnalys();
            }).catch(res=>{
                this.returnAnalys();
            })
        },

        /**
         * 网络异常消息提示
         */
        networkErrorMsg(msg){
            clearTimeout(this.heartTimer);
            this.heartTimer = null;
            this.$alert(msg,'',{
                type: 'warning',
                closeOnClickModal: false,
                closeOnPressEscape: false,
                showClose: false,
                customClass: "judgePopups"
            }).then(res=>{
                this.retryConnectLock();
            })
        },

    }
}
export default heartbeatMixin