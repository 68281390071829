<template>
    <el-dialog
        id="edge-cutting"
        class="edge-cutting"
        width="800px"
        :title="title"
        :visible.sync="visible"
        :before-close="beforeClose"
    >
        <div class="edge-cutting-container">
            
            <div class="edge-stage" ref="edgeStage"></div>
            <div class="edge-view">
                <div
                    class="edge-view-item"
                    v-for="(item, index) in 6"
                    :key="index"
                    @click="removeItem(index)"
                >
                    <span v-if="!viewList[index]">{{$t('popup.edge_cutting.generate_image')}}</span>
                    <img v-else :src="viewList[index]" />
                </div>
            </div>
            <div class="edge-cutting-left">
                <div class="edge-reload" @click="reload">
                    <i class="el-icon-refresh"></i><span>{{$t('popup.edge_cutting.reload')}}</span>
                </div>
                <div class="edge-undo" @click="undo">
                    <i class="el-icon-refresh-left"></i><span>{{$t('popup.edge_cutting.undo')}}</span>
                </div>
                <div class="edge-tips">
                    <label>{{$t('popup.edge_cutting.operation_tip')}}</label>
                    <span>{{$t('popup.edge_cutting.press_toMove')}}</span>
                    <span>{{$t('popup.edge_cutting.mouseWheel_toZoom')}}</span>
                    <span>{{$t('popup.edge_cutting.rightClick_toCut')}}</span>
                    <span>{{$t('popup.edge_cutting.leftClick_toChoose')}}</span>
                </div>
                <span class="dialog-footer">
                    <el-button @click="beforeClose">{{$t('btns.cancel')}}</el-button>
                    <el-button type="primary" @click="save">{{$t('btns.save')}}</el-button>
                </span>
            </div>
        </div>
        
    </el-dialog>
</template>

<script>
import Stage from "../../assets/utils/stage.js";
import cvCommon from "./modules/tools/opencv-common";
import { cloneDeep } from 'lodash';

export default {
    name: "edgeCutting",
    props: {
        visible: Boolean,
        chromo: Object,
    },
    data() {
        return {
            title: this.$t('tools_tip.karyos_cut'),
            lineList: [],
            viewList: [],
            edgeChromoList:[],
            stage: null,
            parentNode: null,
            img_width: null,
            img_height: null
        };
    },
    mounted() {},
    methods: {
        /**
         * 创建舞台
         */
        createStage() {
            this.parentNode =
                this.$refs.edgeStage ||
                document.getElementsByClassName("edge-stage")[0];
            if (!this.parentNode) {
                setTimeout(() => {
                    this.createStage();
                }, 50);
            } else {
                let canvas = document.createElement("canvas");
                // this.canvas = canvas;
                this.parentNode.appendChild(canvas);
                this.stage = new Stage({
                    element: canvas,
                    offset: {
                        width: 200,
                        height: 380,
                        realWidth: 200,
                        realHeight: 380,
                        scale: 1,
                        zoom: 1,
                    },
                    scale: 1,
                });
                window.stage3 = this.stage;
                this.drawChromo();
            }
        },

        clearStage() {
            this.parentNode =
                this.$refs.edgeStage ||
                document.getElementsByClassName("edge-stage")[0];
            this.parentNode.innerHTML = "";
            this.stage = null;
            this.edgeChromoList = [];
            this.viewList = [];
            this.lineList = [];
        },

        /**
         * 绘制染色体
         */
        drawChromo() {
            const image = new Image();
            image.onload = () => {
                this.img_width = image.width
                this.img_height = image.height
                let scale = 1;
                if(image.width > 200 || image.height > 380){
                    const w = image.width / image.height * 380;
                    const h = image.height / image.width * 200;
                    if(w > 200){
                        scale = 200 / image.width;
                    }else if(h > 380){
                        scale = 380 / image.height;
                    }
                }
                const shape = this.stage.graphs.chromo({
                    image: image,
                    x: (200 - image.width * scale) / 2,
                    y: (380 - image.height * scale) / 2,
                    src: this.chromo.primaryUrl,
                    scale: scale,
                    drag: false,
                });
                this.stage.addChild(shape);
                this.stage.draw();
                this.bindEvent();
                
                if (!this.chromo.chromoDataJS) {
                    let startTime = new Date().getTime()
                    cvCommon.getJSDataPromiseAsync(this.chromo).then(chromoDataJS => {
                        console.log("edge cutting getJSDataPromiseAsync use time 2:", new Date().getTime() - startTime)
                        this.chromo.chromoDataJS = chromoDataJS;
                    }).catch((err) => {
                        console.error(err)
                    });
                }
            };
            image.onerror = (e) => {
                console.error("edge cutting init img error:", e);
            }
            image.src = this.$parent.getBase64FromEntries(
                this.$parent.karyoId,
                this.chromo.primaryUrl
            );
        },

        /**
         * 添加画布事件
         */
        bindEvent() {
            let isDragMove, current;
            let allLinesJS;

            const stage = this.stage;
            
            //右键划线
            this.stage.event.dragMove({ button: 2 }, ({ posList }) => {
                if (isDragMove) {
                    current.matrix = posList;
                } else {
                    let lineShape = this.stage.graphs.line({
                        x: 0,
                        y: 0,
                        color: "#f00",
                        lineWidth: 1,
                        zindex: 999,
                        matrix: posList,
                    });
                    this.stage.addChild(lineShape);
                    current = lineShape;
                }
                this.stage.draw();
            });
            
            //划线结束
            this.stage.event.dragEnd({ button: 2 }, ({ posList }) => {
                console.log("!!!", this.stage);
                this.lineList.push(posList);
                isDragMove = false;
                let canvasWidth = this.stage.width, canvasHeight = this.stage.height
                genLineMask((canvasWidth - this.img_width) / 2, (canvasHeight - this.img_height) / 2, this.img_width, this.img_height, this.lineList, canvasWidth, canvasHeight)
            });
            
            //缩放
            this.stage.event.mousewheel(({ e }) => {
                this.stage.setScale(
                    this.stage.scale - 5 / e.deltaY,
                    e.offsetX,
                    e.offsetY
                );
            });
           
            //点击 生成染色体
            this.stage.event.click(({ e, shape }) => {
                if(!this.lineList || this.lineList.length < 1) {
                    this.$message({
                        message: this.$t('popup.edge_cutting.cut_first'),
                        type : 'warning'
                    })
                    return;
                }
                if(!shape) {
                    this.$message({
                        message: this.$t('popup.edge_cutting.cut_first'),
                        type : 'warning'
                    })
                    return;
                }
                const pos = stage.getPos(e);
                let canvasWidth = stage.width, canvasHeight = stage.height
                let clickPointData = cvCommon.getJSPointData(pos.x - (canvasWidth - this.img_width) / 2, pos.y - (canvasHeight - this.img_height) / 2)
                if(current){
                    this.stage.removeChild(current);
                    current = null;
                }
                if (this.chromo.chromoDataJS && allLinesJS) {
                    let newChromo = doCMethod(this.chromo, allLinesJS, clickPointData)
                    //把生成的base64放进 this.viewList 里
                    if (newChromo && this.viewList.length < 6) {
                        this.viewList.push(newChromo.primaryUrl)
                        this.edgeChromoList.push(newChromo)
                    } else if (newChromo && this.viewList.length >= 6) {
                        this.$message({
                            essage: this.$t('popup.edge_cutting.add6_atMost'),
							type : 'warning'
						})
                    } else if (!newChromo) {
                        // this.$message({
						// 	message: "切割失败，请重试",
						// 	type : 'warning'
						// })
                    }
                } else {
                    this.$message({
                        message: this.$t('popup.edge_cutting.cut_failed'),
                        type : 'warning'
                    })
                }
            });

            /**
             * 裁剪黑底白线掩码图,返回base64Img掩码图
             * */
            function genLineMask(x, y, width, height, lineList, canvasWidth, canvasHeight) {
                //裁剪路线图
                let _canvas1 = document.createElement('canvas');
                _canvas1.width = canvasWidth;
                _canvas1.height = canvasHeight;
                let _ctx1 = _canvas1.getContext('2d');
                _ctx1.fillRect(0, 0, canvasWidth, canvasHeight);
                _ctx1.lineWidth = 0.5;
                lineList.forEach(function (item) {
                    _ctx1.beginPath();
                    _ctx1.strokeStyle = 'white';
                    item.forEach(function (point, i) {
                        i === 0 ? _ctx1.moveTo(point.x, point.y) : _ctx1.lineTo(point.x, point.y);
                    });
                    _ctx1.stroke();
                })
                let imageData = _ctx1.getImageData(x, y, width, height);
                let _canvas2 = document.createElement('canvas');
                _canvas2.width = width;
                _canvas2.height = height;
                let _ctx2 = _canvas2.getContext('2d');
                _ctx2.putImageData(imageData, 0, 0);
                
                // 黑底白线图片 传入id
                let startTime = new Date().getTime()
                cvCommon.getJSMatData(_canvas2).then(jsMat => {
                    console.log("edge cutting getJSMatData use time 2:", new Date().getTime() - startTime)
                    allLinesJS = jsMat;
                }).catch((err) => {
                    console.error(err)
                    return;
                });
            }

            // 调用c++方法
            function doCMethod(sChromo, allLinesJS, _getJSPointData) {
                try {
                    let chromoDataJS = sChromo.chromoDataJS
                    let _getVectorJSMat = window.Module.getVectorJSMat()
                    _getVectorJSMat.push_back(allLinesJS)
                    let manualSegmentFun = window.Module.ManualSegmentFun(chromoDataJS, _getVectorJSMat, _getJSPointData, 0, 1, 0, 0)
                    if (manualSegmentFun.result == -1) {
                        return false;
                    } else {
                        let chrArr = manualSegmentFun.chrArr
                        const chromoData = chrArr.get(0);
                        if (chromoData) {
                            let newChromo = cloneDeep(cvCommon.getCuttingData(chromoData, sChromo))
                            newChromo.chromoId = 26;
                            newChromo.chromoDataJS = cvCommon._getChromoDataJS(chromoData, sChromo)
                            return newChromo
                        }
                    }
                } catch (error) {
                    console.warn("doCutChromo", error)
                }
                return false;
            }

            //拖动画布
            let isMouseOnBlank = false;
            let tx = 0,
                ty = 0,
                startPos;
            stage.event.dragStart(({ e }) => {
                if (stage.scale !== 1) {
                    isMouseOnBlank = true;
                    tx = stage.transX;
                    ty = stage.transY;
                    // startPos = {x: e.offsetX, y: e.offsetY};
                    startPos = stage.getPosOnFullScreen(e);
                }
            });
            stage.event.dragMove(({ e }) => {
                if (isMouseOnBlank && stage.scale !== 1) {
                    // const pos = {x: e.offsetX, y: e.offsetY};
                    const pos = stage.getPosOnFullScreen(e);
                    const mx = pos.x - startPos.x + tx;
                    const my = pos.y - startPos.y + ty;
                    stage.translateStage(mx, my);
                    stage.draw();
                }
            });
            stage.event.dragEnd(({ e }) => {
                tx = 0;
                ty = 0;
                startPos = null;
                isMouseOnBlank = false;
            });
        },

        /**
         * 重载继续
         */
        reload() {
            this.stage.objects = this.stage.objects.filter(
                (a) => a.type === "polygon"
            );
            this.lineList = [];
            this.stage.draw();
        },

        /**
         * 撤销
         */
        undo() {
            this.viewList.splice(-1);
            this.edgeChromoList.splice(-1);
        },

        /**
         * 删除
         */
        removeItem(index) {
            index > -1 && this.viewList.splice(index, 1);
            index > -1 && this.edgeChromoList.splice(index, 1);
        },

        /**
         * 关闭弹窗
         */
        beforeClose() {
            this.$emit("comfirm");
        },

        /**
         * 保存
         */
        save() {
            //生成的新的染色体集合
            if (!this.edgeChromoList || this.edgeChromoList.length <= 0) {
                this.$emit("comfirm");
            } else {
                this.$emit("comfirm", this.edgeChromoList);
            }
        },
    },
    watch: {
        visible(nv, ov) {
            if (nv) {
                this.createStage();
            } else {
                this.clearStage();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.el-button {
    &.el-button--default,
    &.el-button--primary {
        height: 30px;
        width: 80px;
    }
}
.edge-cutting{
    .el-dialog__footer{
        display: flex;
        justify-content: flex-end;
    }
}
.edge-cutting-container {
    overflow: hidden;
    display: flex;
    // justify-content: space-around;
    .edge-cutting-left {
        overflow: hidden;
        width: 210px;
        display: flex;
        position: relative;
        margin-left: 30px;
        .edge-reload,
        .edge-undo {
            width: 70px;
            height: 70px;
            border: 1px solid rgba(203, 206, 212, 1);
            border-radius: 4px;
            cursor: pointer;
            margin-right: 30px;
            margin-bottom: 20px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            i {
                font-size: 40px;
                color: #4773b8;
            }
            span {
                display: block;
            }
            &:hover {
                background-color: #4773b8;
                i,span {
                    color: #ffffff;
                }
            }
        }
    }
    .edge-stage {
        width: 200px;
        height: 380px;
        border: 1px solid rgba(203, 206, 212, 1);
        overflow: hidden;
        border-radius: 4px;
    }
    .edge-view {
        overflow: hidden;
        margin-top: -20px;
        width: 301px;
        .edge-view-item {
            float: left;
            width: 80px;
            height: 180px;
            margin-top: 20px;
            margin-left: 20px;
            border: 1px solid rgba(203, 206, 212, 1);
            border-radius: 4px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 60px;
                max-height: 170px;
            }
        }
    }
    .edge-tips{
        position: absolute;
        left: 0px;
        top: 100px;
        font-size: 12px;
        line-height: 20px;
        label{
            color: #333333;
            font-size: 14px;
        }
        span{
            display: block;
            padding-left: 7px;
            position: relative;
            &:before{
                content: '';
                display: block;
                width: 3px;
                height: 3px;
                border-radius: 2px;
                background-color: #4773B8;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .dialog-footer{
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
    }
}
</style>
