import cvCommon from './opencv-common.js'
import { cloneDeep } from 'lodash';

/**
 * 染色体划线切割
 * 染色体区域擦除
 * @param {*} stage 
 * @param {*} controller 
 */
export function cutting(stage, controller) {
    const arrange = this.arrangeController;
    const karyo = this.karyoController;
    // 标记当前切割在排列图还是中期分裂相上
    const isArrange = controller.name === 'arrange'
	const app = this;
    const condition = {
        button: 2,
        getStatus: () => {
            return (['count', 'general'].indexOf(this.toolsType) >= 0 && this.shortKeyType === '') || this.toolsType === 'cutting'
        }
    }
    let lineShape;

    // move过程划线
    stage.event.dragMove(condition, ({ posList }) => {
        if (lineShape) {
            lineShape.matrix = posList;
        } else {
            lineShape = stage.graphs.line({
                x: 0,
                y: 0,
                color: '#f00',
                lineWidth: 1,
                zindex: 999,
                matrix: posList
            })
            stage.addChild(lineShape);
        }
        stage.draw();
    })

    let prevTargetChromo;

    // end执行切割
    stage.event.dragEnd(condition, ({posReal,posList }) => {
        console.log("右键 dragEnd");
        // 删除线条图形
        stage.removeChild(lineShape);
        lineShape = null;
        stage.draw();

        const shapeArr = stage.getObjectsByPointList(posList, 'polygon').filter(obj => { return obj.realType === 'chromo' });
        // console.log(shapeArr);
        let targetChromo = getTargetChromoByCut(controller, shapeArr, this.choosedChromo);
        // console.log(targetChromo);
        if(!targetChromo || prevTargetChromo === targetChromo) return;
        let startTime = new Date().getTime()
        prevTargetChromo = targetChromo;
        if (isArrange) {
            genLineMaskAndCut4Arrange(targetChromo, shapeArr[shapeArr.length - 1], posList).then(([chromoDataJS, allLinesJS]) => {
                doLineCut(chromoDataJS, allLinesJS, targetChromo)
                prevTargetChromo = null;
                console.log("doLineCut use time:", new Date().getTime() - startTime)
            }).catch((err) => {
                console.error(err)
            });
        } else {
            // const list = posList.map(pos=>{
            //     pos.x -= stage.offset.TRANS_X;
            //     pos.y -= stage.offset.TRANS_Y;
            //     return pos;
            // })
            genLineMaskAndCut(targetChromo, posReal).then(([chromoDataJS, allLinesJS]) => {
                doLineCut(chromoDataJS, allLinesJS, targetChromo)
                prevTargetChromo = null;
                console.log("doLineCut use time:", new Date().getTime() - startTime)
            }).catch((err) => {
                console.error(err)
            });
        }

        function doLineCut(chromoDataJS, allLinesJS, srcChromo) {
            let cuttingChromos = getLineCutChromos(chromoDataJS, allLinesJS, srcChromo)
            if (cuttingChromos && cuttingChromos.length > 0) {
                let sChromoIndex = controller.chromoList.indexOf(srcChromo);
                sChromoIndex > -1 && controller.chromoList.splice(sChromoIndex, 1);
                let maxIndex = Math.max.apply(Math, controller.chromoList.map(item => { return item.index })) + 1;

                for (let index = 0; index < cuttingChromos.length; index++) {
                    let newChromo = cuttingChromos[index]
                    newChromo.index = maxIndex++;
                    controller.chromoList.push(newChromo);
                }
                setTimeout(()=>{
                    karyo.resetKaryo();
                    arrange.reArrange()
                    app.cachePool.save();
                    app.cachePool.record({
                        type : 'cutting',
                        message : '染色体切割',
                        data : cuttingChromos,
                        result : controller.chromoList
                    })
                },10)
            }
        }
    })

    stage.element.addEventListener("mouseup",(e)=>{
        setTimeout(()=>{
            stage.removeChild(lineShape);
            lineShape = null;
            stage.draw();
        },100)
    })
}


/**
 * 获取待切割染色体
 * @param {画布控制器} controller 
 * @param {图形数组} shapeArr 
 * @param {选中的染色体} choosedChromo 
 */
export function getTargetChromoByCut(controller, shapeArr, choosedChromo) {
    if (!shapeArr || shapeArr.length == 0) {
        return;
    }
    let targetShape = shapeArr[shapeArr.length - 1]
    if (choosedChromo) {
        let choosedShape = controller.getShapeByChromo(choosedChromo)
        targetShape = shapeArr.indexOf(choosedShape) > -1 ? choosedShape : shapeArr[shapeArr.length - 1]
    }
    return controller.getChromoByShape(targetShape)
}


/**
 * 生成切割线mask，并获取待切割染色体jsData
 * @param {待切割染色体} divisionChromo 
 * @param {划线的点坐标列表，基于原图坐标系尺寸} divisionMatrix 
 */
export function genLineMaskAndCut(divisionChromo, divisionMatrix) {
    return new Promise((resolve, reject) => {
        let allLinesJS;
        const checkData = () => {
            if (divisionChromo.chromoDataJS && allLinesJS) {
                resolve([divisionChromo.chromoDataJS, allLinesJS])
            }
        }

        // 单条染色体数据
        if (!divisionChromo.chromoDataJS) {
            cvCommon.getJSDataPromiseAsync(divisionChromo).then(chromoDataJS => {
                divisionChromo.chromoDataJS = chromoDataJS;
                checkData()
            }).catch((err) => {
                console.error(err)
                reject(err)
            });
        }
        // 获取划线mask的jsmat
        getLineMaskJSData(divisionChromo, divisionMatrix).then((jsmat) => {
            allLinesJS = jsmat;
            checkData()
        }).catch((err) => {
            console.error(err)
            reject(err)
        });
    })
}

/**
 * 排列图上切割，生成切割线mask，并获取待切割染色体jsData
 * @param {待切割染色体} divisionChromo 
 * @param {划线的点坐标列表，基于原图坐标系尺寸} divisionMatrix 
 */
export function genLineMaskAndCut4Arrange(divisionChromo, shape, divisionMatrix, lineWidth) {
    return new Promise((resolve, reject) => {
        let allLinesJS, karyoLinelist;
        lineWidth = lineWidth ? lineWidth : 1;
        const checkData = () => {
            if (divisionChromo.chromoDataJS && allLinesJS) {
                resolve([divisionChromo.chromoDataJS, allLinesJS, karyoLinelist])
            }
        }

        // 单条染色体数据
        if (divisionChromo.chromoDataJS) {
            karyoLinelist = genLineInKaryo(divisionChromo, shape, divisionMatrix);
            getLineMaskJSData(divisionChromo, karyoLinelist, lineWidth).then((jsmat) => {
                allLinesJS = jsmat;
                checkData()
            }).catch((err) => {
                console.error(err)
                reject(err)
            });
        } else {
            cvCommon.getJSDataPromiseAsync(divisionChromo).then(chromoDataJS => {
                divisionChromo.chromoDataJS = chromoDataJS;
                karyoLinelist = genLineInKaryo(divisionChromo, shape, divisionMatrix);
                getLineMaskJSData(divisionChromo, karyoLinelist, lineWidth).then((jsmat) => {
                    allLinesJS = jsmat;
                    checkData()
                }).catch((err) => {
                    console.error(err)
                    reject(err)
                });
            }).catch((err) => {
                console.error(err)
                reject(err)
            });
        }

    })
}

export function getLineMaskJSData(divisionChromo, karyoLinelist, lineWidth) {
    return new Promise((resolve, reject) => {
        let boundingbox = divisionChromo._cimgBoundingbox;
        var _canvas = document.createElement('canvas');
        _canvas.width = boundingbox.w;
        _canvas.height = boundingbox.h;
        var _ctx = _canvas.getContext('2d');
        _ctx.fillRect(0, 0, boundingbox.w, boundingbox.h);
        _ctx.lineWidth = lineWidth ? lineWidth : 1; //线条的粗细
        _ctx.beginPath();
        // 画布上划线
        karyoLinelist.forEach(function (point, i) {
            i === 0 ? _ctx.moveTo(point.x - boundingbox.x, point.y - boundingbox.y) : _ctx.lineTo(point.x - boundingbox.x, point.y - boundingbox.y);
        });
        
		_ctx.lineJoin = "round";
		_ctx.lineCap = "round";
		_ctx.strokeStyle = "#fff";
        // _ctx.strokeStyle = 'white'; //线条的颜色

        _ctx.stroke(); //画线
		_ctx.closePath();
        _ctx = null
        // 获取划线的mask的jsmat
        cvCommon.getJSMatData(_canvas).then(jsMat => {
            _canvas = null
            resolve(jsMat)
        }).catch((err) => {
            console.error(err)
            reject(err)
        });
    })
}

/**
 * 生成基于核型图的切割线
 * @param {待切割染色体数据对象} targetChromo 
 * @param {待切割染色体排列图画布对象} shape
 * @param {基于画布原始大小的线的点坐标} divisionMatrix
 */
function genLineInKaryo(targetChromo, shape, divisionMatrix) {
    let dataObj = targetChromo;
    let canvasObj = shape;

    let logicPointChain = [];
    // 1.生成基于染色体左上角点为基准点的位置
    divisionMatrix.forEach(function (po) {
        logicPointChain.push(point(po.x - canvasObj.x, po.y - canvasObj.y));
    });

    // 2.转换坐标，宽高为原始比例下的坐标
    logicPointChain.forEach(po => {
        po.x = parseInt(po.x / canvasObj.scale);
        po.y = parseInt(po.y / canvasObj.scale);
    })

    // 2.以img_handled左上角为（0，0）基准的img_handled中心点坐标
    let imgHandledCenterPt = point((canvasObj.width) / 2, (canvasObj.height) / 2);
    // 算出cImgRotated，左顶点相对于以img_handled中心为基准点的坐标
    let rotatedleftTopPt = point((-1) * dataObj.chromoDataJS.cImgRotated.cols / 2, (-1) * dataObj.chromoDataJS.cImgRotated.rows / 2);

    //3.判断是否人为的将染色体旋转了
    //img_handled_rotated_degree旋转90度后，对点反旋转时，会存在（0，0）基准点的偏移
    let logicPointChainRotated = [];
    // 判断染色体是够否转正
    let imgHandledRotatedDegree = dataObj.imgHandledRotatedDegree
    // chromoUpright仅用于保存标识，下次切割时用于计算角度
    // if (dataObj.chromoUpright == 1) {
    //     imgHandledRotatedDegree = imgHandledRotatedDegree + 180
    // }
    logicPointChain.forEach(itPt => {
        //切换点坐标为：基于以img_handled中心点为（0，0）基准
        itPt.x = itPt.x - imgHandledCenterPt.x;
        itPt.y = itPt.y - imgHandledCenterPt.y;

        //以img_handled中心点（0，0）为基准，绕img_handled中心逆旋转
        itPt = rotatePoint(point(0, 0), itPt, imgHandledRotatedDegree * (-1));
        // 水平镜像
        if (dataObj.imgHandledFlip) {
            itPt.x *= (-1);
        }

        //将旋转完成后的点转换为，以cImgRotated左顶点为基准点
        itPt.x = itPt.x - rotatedleftTopPt.x;
        itPt.y = itPt.y - rotatedleftTopPt.y;
        logicPointChainRotated.push(itPt);
    });

    //4.将原始图的四个顶点进行旋转，从而得到包裹住排序图的矩形左顶点
    //得到原始图旋转成排序图的角度，传过来的角度经过了加工，小于0加90，大于0用90减，将其转化为真实角度
    //rotation为正数，则排序图是由原始图逆时针旋转得到的，我们需知道原始图的外接矩形的左右顶点，
    //为负数需要左顶点和左下点
    let rotation = dataObj.cimgOrientation
    if (rotation < 0) {
        rotation = (-1) * rotation - 90;
    } else {
        rotation = 90 - rotation;
    }
    // 算法切割后原染色体基于原图的坐标 [y,x,height,width]
    let imgBoundingBox = dataObj.cimgBoundingbox.split(',');
    // 基于原图，原染色体的中心点坐标
    let centerPoint = point(Number(imgBoundingBox[1]) + Number(imgBoundingBox[3] / 2),
        Number(imgBoundingBox[0]) + Number(imgBoundingBox[2] / 2));
    //将图形的四个角进行旋转后，得到外接大矩形的左顶点,
    let bigLeftTopPoint = getRectangleLeftTop(centerPoint, (-1) * rotation, point(imgBoundingBox[1], imgBoundingBox[0]), imgBoundingBox[2], imgBoundingBox[3]);
    //需要将绘制在排序图上的所有点的坐标，变为基于原始图的中心点，得到排序图点需要转化的偏移量
    let baseOriginalCenterPtOffset = point(bigLeftTopPoint[0] - centerPoint.x + Number(dataObj.cimgBoundingboxOffset.split(',')[1]),
        bigLeftTopPoint[1] - centerPoint.y + Number(dataObj.cimgBoundingboxOffset.split(',')[0]));
    let linePoints = [];
    logicPointChainRotated.forEach(itPt => {
        ////将点转换为基于旋转中心后，进行旋转，并且最后转换为基于外接边框左顶点上,RotateThenGetBaseRectLtPt(旋转后得到基于矩形左上顶点的点)
        let rPoint = rotateThenGetBaseRectLtPt(point(0, 0), point(itPt.x, itPt.y),
            rotation, baseOriginalCenterPtOffset, point(imgBoundingBox[3] / 2, imgBoundingBox[2] / 2));
        let despoint = point(Number(rPoint.x) + Number(imgBoundingBox[1]),
            Number(rPoint.y) + Number(imgBoundingBox[0]))
        linePoints.push(despoint);
    });
    return linePoints;
}


/**
 * 将点转换为基于旋转中心后，进行旋转，并且最后转换为基于外接边框左顶点上,RotateThenGetBaseRectLtPt(旋转后得到基于矩形左上顶点的点)
 * @param {*} baseCenterPt 基于某点旋转
 * @param {*} srcPt 需要转换的点
 * @param {*} nRotateDegree 旋转的角度
 * @param {*} offsetPoint 需要加上的偏移点
 * @param {*} basePoint 最终以basePoint为基准
 */
function rotateThenGetBaseRectLtPt(baseCenterPt, srcPt, nRotateDegree, offsetPoint, basePoint) {
    srcPt.x = Number(offsetPoint.x) + srcPt.x;
    srcPt.y = Number(offsetPoint.y) + srcPt.y;

    let desPt = rotatePoint(baseCenterPt, srcPt, nRotateDegree);
    desPt.x = Number(basePoint.x) + desPt.x;
    desPt.y = Number(basePoint.y) + desPt.y;
    return desPt;
}

/**
 * 旋转图形得到四个点,并获得图形的外接矩形的左上角点
 * @param {旋转中心} baseCenterPt 
 * @param {旋转度数} nRotateDegree 
 * @param {以什么点为基准点} criterionPt 
 * @param {宽} width 
 * @param {高} height 
 * @returns {返回旋转后的顶点} 
 */
function getRectangleLeftTop(baseCenterPt, nRotateDegree, criterionPt, width, height) {

    //以srcGraph左上角为（0，0）基准,srcGraph的四个角
    let leftTopPt = point(criterionPt.x, criterionPt.y);
    let rightBottomPt = point(Number(criterionPt.x) + Number(height), Number(criterionPt.y) + Number(width));
    let leftBottomPt = point(leftTopPt.x, rightBottomPt.y);
    let rightTopPt = point(rightBottomPt.x, leftTopPt.y);

    //以img_handled左上角为（0，0）基准,绕img_handled中心逆旋转
    leftTopPt = rotatePoint(baseCenterPt, leftTopPt, nRotateDegree);
    leftBottomPt = rotatePoint(baseCenterPt, leftBottomPt, nRotateDegree);
    rightTopPt = rotatePoint(baseCenterPt, rightTopPt, nRotateDegree);
    rightBottomPt = rotatePoint(baseCenterPt, rightBottomPt, nRotateDegree);

    let xlist = [leftTopPt.x, leftBottomPt.x, rightTopPt.x, rightBottomPt.x];
    let ylist = [leftTopPt.y, leftBottomPt.y, rightTopPt.y, rightBottomPt.y];
    let xsort = xlist.sort(function (a, b) {
        return a - b;
    });
    let ysort = ylist.sort(function (a, b) {
        return a - b;
    });
    let max_x = xsort[xlist.length - 1];
    let min_x = xsort[0];
    let max_y = ysort[ylist.length - 1];
    let min_y = ysort[0];
    return [min_x, min_y, max_x - min_x, max_y - min_y]
}

/**
 * 根据坐标点旋转得到新的坐标
 * @param {旋转中心点} pCenter 
 * @param {待旋转点} p 
 * @param {旋转角度} angle 
 */
function rotatePoint(pCenter, p, angle) {
    let l = (angle * Math.PI) / 180;
    let cosv = Math.cos(l);
    let sinv = Math.sin(l);

    // calc new point
    let newX = (p.x - pCenter.x) * cosv - (p.y - pCenter.y) * sinv + pCenter.x;
    let newY = (p.x - pCenter.x) * sinv + (p.y - pCenter.y) * cosv + pCenter.y;
    return {
        x: newX,
        y: newY
    };
}

function point(x, y) {
    return { x: x, y: y };
}


/**
 * 调用C++算法，获取染色体切割后列表
 * @param {*} chromoDataJS 
 * @param {*} allLinesJS 
 * @param {*} targetChromo 
 */
export function getLineCutChromos(chromoDataJS, allLinesJS, targetChromo) {
    try {
        let manualSegmentFun = window.Module.line_segFun(chromoDataJS, allLinesJS, 1);
        if (manualSegmentFun.result == 0) {
            let chrArr = manualSegmentFun.chrArr
            manualSegmentFun = null

            const cuttingChromos = [];
            for (let index = 0; index < chrArr.size(); index++) {
                const chromoData = chrArr.get(index);
                let newChromo = cloneDeep(cvCommon.getCuttingData(chromoData, targetChromo))
                newChromo.chromoId = 26;
                newChromo.chromoDataJS = cvCommon._getChromoDataJS(chromoData, targetChromo)
                cuttingChromos.push(newChromo);
            }
            return cuttingChromos;
        }
    } catch (error) {
        console.warn("doCutChromo", error)
    }
    return false;
}

/**
 * 推荐切割的推荐线
 * 执行推荐切割
 * @param {*} stage 
 * @param {*} controller 
 */
export function recommendCutting(stage, controller) {
    let recommendPoint, nowPoint, isRecommending = false;
    const arrange = this.arrangeController;
	const app = this;

    if (isRecommending) {
        console.log("isRecommending:", isRecommending)
        return;
    }
    const condition = {
        getStatus: () => {
            return ['general'].indexOf(this.toolsType) > -1 && this.shortKeyType === '';
        }
    }

    const croseCondition = {
        getStatus: () => {
            return (['general'].indexOf(this.toolsType) > -1 && this.shortKeyType === 'x') || this.toolsType === 'cross';
        }
    }

    const mouseoutCondition = {
        getStatus: () => {
            return ['general', 'cross'].indexOf(this.toolsType) > -1;
        }
    }
    
    // 移开后删除推荐切割线
    stage.event.mouseout(mouseoutCondition, ({
        shape
    }) => {
        delete shape.divisionLine;
        isRecommending = false;
        controller.stage.draw();
    })

    // 移动过程中获取推荐切割线
    stage.event.mousemove(condition, ({
        e,
        shape
    }) => {
        nowPoint = stage.getPosOnPrimaryImage(e);
        // console.log(nowPoint);
        // nowPoint.x -= stage.offset.TRANS_X;
        // nowPoint.y -= stage.offset.TRANS_Y;
        if (isRecommending || !shape || shape.realType !== 'chromo') {
            return;
        }
        if (shape.divisionLine && calcuPointDis(nowPoint, recommendPoint)) {
            delete shape.divisionLine;
            controller.stage.draw();
        }
        
        let item = controller.getChromoByShape(shape)
        if(!item) {
            return
        }
        isRecommending = true;
        recommendPoint = stage.getPosOnPrimaryImage(e);
        // recommendPoint.x -= stage.offset.TRANS_X;
        // recommendPoint.y -= stage.offset.TRANS_Y;
        let startTime = new Date().getTime()
        genDataForRecommendCut(recommendPoint, item).then(([chromoDataJS, clickPosiJS]) => {
            doDivisionLine(shape, chromoDataJS, clickPosiJS, false)
            console.info("CutData use time 2:", new Date().getTime() - startTime)
        }).catch((err) => {
            isRecommending = false;
            console.warn(err)
        })

    })

    // 右键进行推荐切割
    stage.event.contextmenu(condition, ({
        e,
        shape
    }) => {
        if (!shape || shape.realType !== 'chromo') {
            return;
        }
        let item = controller.getChromoByShape(shape)
        if(!item) {
            return
        }
        const pos = stage.getPos(e);
        pos.x -= stage.offset.TRANS_X;
        pos.y -= stage.offset.TRANS_Y;
        let startTime = new Date().getTime()
        genDataForRecommendCut(pos, item).then(([chromoDataJS, clickPosiJS, srcChromo]) => {
            doRecommendDivision(chromoDataJS, clickPosiJS, srcChromo, false)
            console.info("CutData use time 2:", new Date().getTime() - startTime)
        }).catch((e) => {
            console.warn(e)
        })
    })

    // 移动过程中获取推荐切割线
    stage.event.mousemove(croseCondition, ({
        e,
        shape
    }) => {
        nowPoint = stage.getPos(e);
        if (isRecommending || !shape || shape.realType !== 'chromo') {
            return;
        }
        if (shape.divisionLine && calcuPointDis(nowPoint, recommendPoint)) {
            delete shape.divisionLine;
            controller.stage.draw();
        }
        let item = controller.getChromoByShape(shape)
        if(!item) {
            return
        }
        isRecommending = true;
        recommendPoint = stage.getPos(e);
        let startTime = new Date().getTime()

        genDataForRecommendCut(recommendPoint, item).then(([chromoDataJS, clickPosiJS]) => {
            doDivisionLine(shape, chromoDataJS, clickPosiJS, true)
            console.info("CutData use time 2:", new Date().getTime() - startTime)
        }).catch((err) => {
            isRecommending = false;
            console.warn(err)
        })

    })
    
    // 右键进行推荐切割
    stage.event.contextmenu(croseCondition, ({
        e,
        shape
    }) => {
        if (!shape || shape.realType !== 'chromo') {
            return;
        }
        let item = controller.getChromoByShape(shape)
        if(!item) {
            return
        }
        const pos = stage.getPos(e);
        let startTime = new Date().getTime()
        genDataForRecommendCut(pos, item).then(([chromoDataJS, clickPosiJS, srcChromo]) => {
            doRecommendDivision(chromoDataJS, clickPosiJS, srcChromo, true)
            console.info("CutData use time 2:", new Date().getTime() - startTime)
        }).catch((e) => {
            console.warn(e)
        })

    })

    /**
     * 获取推荐切割后的染色体边缘点坐标
     * @param {染色体数据对象} chromoDataJS 
     * @param {推荐点坐标对象} clickPosiJS 
     * @param {是否十字切割} isTenCut 
     */
    function doRecommendDivision(chromoDataJS, clickPosiJS, srcChromo, isTenCut) {
        let cuttingChromos = getRecommendChromos(chromoDataJS, clickPosiJS, srcChromo, isTenCut)
        if (cuttingChromos && cuttingChromos.length > 0) {
            let sChromoIndex = controller.chromoList.indexOf(srcChromo);
            sChromoIndex > -1 && controller.chromoList.splice(sChromoIndex, 1);
            let maxIndex = Math.max.apply(Math, controller.chromoList.map(item => { return item.index })) + 1;

            for (let index = 0; index < cuttingChromos.length; index++) {
                let newChromo = cuttingChromos[index]
                newChromo.index = maxIndex++;
                controller.chromoList.push(newChromo);
            }
            controller.resetKaryo();
            arrange.reArrange()
            app.cachePool.save();
            app.cachePool.record({
                type : 'division',
                message : '染色体推荐切割',
                data : cuttingChromos,
                result : controller.chromoList
            })
        }
    }

    /**
     * 获取推荐切割后的染色体边缘点坐标
     * @param {染色体数据对象} chromoDataJS 
     * @param {推荐点坐标对象} clickPosiJS 
     * @param {是否十字切割} isTenCut 
     */
    function doDivisionLine(shape, chromoDataJS, clickPosiJS, isTenCut) {
        if (calcuPointDis(nowPoint, recommendPoint)) {
            isRecommending = false;
            return;
        }
        let cuttingLine = getRecommendLine(chromoDataJS, clickPosiJS, isTenCut);
        if (cuttingLine && cuttingLine.length > 0) {
            shape.divisionLine = cuttingLine;
            controller.stage.draw();
        }
        isRecommending = false;
    }

    /**
     * 判断两个点的坐标是否已经错开
     * @param {*} sPoint 
     * @param {*} ePoint 
     */
    function calcuPointDis(sPoint, ePoint) {
        if (!sPoint || !ePoint) {
            return false;
        }
        return ((sPoint.x - ePoint.x) * (sPoint.x - ePoint.x) + (sPoint.y - ePoint.y) * (sPoint.y - ePoint.y)) > 10
    }

    /**
     * 准备用于推荐切割的数据
     * @param {鼠标点坐标} pos 
     * @param {染色体数据对象} item 
     */
    function genDataForRecommendCut(pos, item) {
        return new Promise((resolve, reject) => {
            let clickPosiJS = cvCommon.getJSPointData(pos.x - item._cimgBoundingbox.x, pos.y - item._cimgBoundingbox.y)
            if (item.chromoDataJS) {
                resolve([item.chromoDataJS, clickPosiJS, item])
            } else {
                cvCommon.getJSDataPromiseAsync(item).then((chromoDataJS) => {
                    item.chromoDataJS = chromoDataJS;
    
                    resolve([chromoDataJS, clickPosiJS, item])
                }).catch((err) => {
                    console.error(err)
                    reject(err)
                    isRecommending = false;
                });
            }
        })
    }

}

/**
 * 获取染色体边缘点坐标
 * @param {C算法生成的染色体对象} chromoData 
 */
function genPointLineByCArrChromo(chromoData) {
    let cimgBoundaryPointsList = []
    let cImgBoundaryPoints = chromoData.cImgPosition.cImgBoundaryPoints
    for (let i = 0; i < cImgBoundaryPoints.size(); i++) {
        const element = cImgBoundaryPoints.get(i);
        cimgBoundaryPointsList.push(element)
    }

    let cImgBoundingBoxList = []
    let cImgBoundingBox = chromoData.cImgPosition.cImgBoundingBox
    for (let i = 0; i < cImgBoundingBox.size(); i++) {
        const element = cImgBoundingBox.get(i);
        cImgBoundingBoxList.push(Number(element))
    }

    const matrix = cimgBoundaryPointsList.map((pos) => {
        return [pos.x + cImgBoundingBoxList[1], pos.y + cImgBoundingBoxList[0]];
    })
    return matrix;
}

/**
 * 获取推荐切割后的染色体边缘点坐标
 * @param {染色体数据对象} chromoDataJS 
 * @param {推荐点坐标对象} clickPosiJS 
 * @param {是否十字切割} isTenCut 
 */
function getRecommendLine(chromoDataJS, clickPosiJS, isTenCut) {
    try {
        let manualSegmentFun = isTenCut ? 
            window.Module.getCutTenShapeResultFun(chromoDataJS, 1, clickPosiJS) :
            window.Module.ManualCutRecommendResultFun(chromoDataJS, 1, clickPosiJS);
        if (manualSegmentFun.result != -1) {
            let chrArr = manualSegmentFun.chrArr

            const cuttingLine = [];
            for (let index = 0; index < chrArr.size(); index++) {
                const chromoData = chrArr.get(index);
                let line = genPointLineByCArrChromo(chromoData);
                cuttingLine.push(line);
            }
            return cuttingLine;
        }
    } catch (error) {
        console.error("getRecommendLine error:", error)
    }
    return false;
}

/**
 * 获取推荐切割后的染色体列表
 * @param {染色体数据对象} chromoDataJS 
 * @param {推荐点坐标对象} clickPosiJS 
 * @param {原始染色体} srcChromo 
 * @param {是否十字切割} isTenCut 
 */
function getRecommendChromos(chromoDataJS, clickPosiJS, srcChromo, isTenCut) {
    try {
        let manualSegmentFun = isTenCut ? 
            window.Module.getCutTenShapeResultFun(chromoDataJS, 1, clickPosiJS) :
            window.Module.ManualCutRecommendResultFun(chromoDataJS, 1, clickPosiJS);
        if (manualSegmentFun.result != -1) {
            let chrArr = manualSegmentFun.chrArr

            const cuttingChromos = [];
            for (let index = 0; index < chrArr.size(); index++) {
                const chromoData = chrArr.get(index);
                let newChromo = cloneDeep(cvCommon.getCuttingData(chromoData, srcChromo))
                newChromo.chromoId = 26;
                newChromo.chromoDataJS = cvCommon._getChromoDataJS(chromoData, srcChromo)
                cuttingChromos.push(newChromo);
            }

            return cuttingChromos;
        } else {
            return false;
        }
    } catch (error) {
        console.error("getRecommendChromos error:", error)
    }
    return false;
}

