/**
 * 染色体对比
 */
function chromoContrast() {
	const stageA = this.arrangeController.stage;
	const stageK = this.karyoController.stage;
	let chromos = [];
	
	/**
	 * 对比染色体
	 * @param {*} chromo 
	 */
	const contrast = (chromo)=>{
		const exist = chromos.some(a=>a.chromoId === chromo.chromoId);
		if(exist){
			return;
		}
		const maxlength = this.toolsType === 'same' ? 1 : 2;
		chromos.push(chromo);
		if(chromos.length === maxlength){
			this.contrastChromos = chromos;
			this.contrastVisible = true;
			chromos = [];
		}
	}
	
	/**
	 * 排列图点击事件
	 */
	stageA.event.click({
		getStatus : ()=>{
			return this.toolsType === 'same' || this.toolsType === 'diff'
		}
	}, ({
		e,
		shape
	}) => {
		if(shape){
			const chromo = this.arrangeController.getChromoByShape(shape);
			if(chromo && chromo.chromoId >=1 && chromo.chromoId < 26){
				contrast(chromo);
			}
			if(chromo && chromo.chromoId >= 26){
				this.$message.warning("待排列染色体不能进行对比");
				chromos = [];
			}
		}
	})
	
	//分裂相点击事件
	stageK.event.click({
		getStatus : ()=>{
			return this.toolsType === 'same' || this.toolsType === 'diff'
		}
	}, ({
		e,
		shape
	}) => {
		if(shape){
			const chromo = this.karyoController.getChromoByShape(shape);
			if(chromo && chromo.chromoId >=1 && chromo.chromoId < 26){
				contrast(chromo);
			}
			if(chromo && chromo.chromoId >= 26){
				this.$message.warning("待排列染色体不能进行对比");
				chromos = [];
			}
		}
	})
}
export default chromoContrast
