/**
 * 删除
 * @param {Object} stage
 * @param {Object} controller
 */
function deleteShape(stage, controller) {
    let lineShape, chromoArr = [];
    const condition = {
        getStatus: () => {
            return this.toolsType === 'delete'
        }
    }
    //获取controller中对应的箭头数据
    const getArrowInController = (shape) => {
        return controller.arrowList.filter(item => {
            return item.guid === shape.id;
            // if(item.beginPoint){
            // 	return item.beginPoint.x === shape.beginPoint.x && item.beginPoint.y === shape.beginPoint.y &&
            // 	item.stopPoint.x === shape.stopPoint.x && item.stopPoint.y === shape.stopPoint.y
            // }else{
            // 	return item.start_x === shape.beginPoint.x * stage.width && item.start_y === shape.beginPoint.y * stage.height &&
            // 	item.target_x === shape.stopPoint.x * stage.width && item.target_y === shape.stopPoint.y * stage.height
            // }
        })[0]
    }

    const delShape = ({
        e,
        shape
    }) => {
        const chromo = controller.getChromoByShape(shape);
        if (chromo) {
            const index = this.chromoList.indexOf(chromo);
            index > -1 && this.chromoList.splice(index, 1);
            this.reCreateJsMat();
            this.karyoController.resetKaryo();
            this.arrangeController.reArrange();
            this.cachePool.save();
            this.cachePool.record({
                type: 'deleteShape',
                message: '删除染色体',
                data: chromo,
                result: controller.chromoList
            })

            // let delArr = !!sessionStorage.getItem('deleteChrome') ? JSON.parse(sessionStorage.getItem('deleteChrome')) : [];
            let delArr = window.chromoMap;
            const cindex = delArr.map(item => item.chromo).indexOf(chromo);
            cindex === -1 ? delArr.push({
                chromo: chromo,
                shape: shape
            }) : '';
            // sessionStorage.setItem('deleteChrome', JSON.stringify(delArr));
            window.chromoMap = delArr;
        } else {
            const arrow = stage.isPointInner(e, false, 'arrow');
            if (!arrow) return;
            const pos = stage.getPos(e);
            const _arrow = getArrowInController(arrow);
            // console.warn(shape.isPointInner(pos.x, pos.y))
            if (arrow.isPointerOnText(pos.x, pos.y)) {
                arrow.txt = "";
                if (controller.name === "karyo") {
                    _arrow.text = "";
                } else {
                    _arrow.note = "";
                }

                stage.draw();
                save(arrow)
            } else if (arrow.isPointInner(pos.x, pos.y)) {
                stage.removeChild(arrow);
                const index = controller.arrowList.indexOf(_arrow);
                controller.arrowList.splice(index, 1);
                stage.draw();
                save(arrow)
            }
        }
    }

    //保存当前数据
    const save = (shape) => {
        this.cachePool.save();
        this.cachePool.record({
            type: 'arrowMark',
            message: '箭头标注',
            data: (() => {
                const arrow = Object.assign({}, shape);
                delete arrow._;
                return arrow;
            })(),
            result: null
        })
    }

    let conditionMulti = {
        getStatus: () => {
            return this.toolsType === 'multi-delete'
        }
    }

    controller.name === 'karyo' && stage.event.dragMove(condition, ({
        posList
    }) => {
        // isDragMove = true;
        let matrix = posList.map((pos) => {
            return [pos.x, pos.y];
        })
        if (lineShape) {
            lineShape.matrix = matrix;
        } else {
            lineShape = stage.graphs.easerArea({
                x: 0,
                y: 0,
                borderColor: "rgba(34, 177, 76)",
                color: "rgba(255, 255, 255, .7)",
                dash: [3, 3],
                lineWidth: 2,
                zindex: 999,
                matrix: matrix
            })
            stage.addChild(lineShape);
        }
        stage.draw();
    })
    controller.name === 'karyo' && stage.event.dragEnd(condition, ({
        posList,
        posReal
    }) => {
        posList.push(posList[0])
        // aeraMatrix = posList
        const shapeArr = stage.getObjectsInsideShape(lineShape, 'polygon').filter(obj => {
            return obj.realType === 'chromo'
        });
        // console.log(shapeArr);
        stage.removeChild(lineShape);
        lineShape = null;
        if (shapeArr.length) {
            shapeArr.forEach(shape => {
                chromoArr.push(controller.getChromoByShape(shape));
            })
            this.chromoList = this.chromoList.filter(chromo => {
                return !chromoArr.includes(chromo)
            });

            // let delArr = !!sessionStorage.getItem('deleteChrome') ? JSON.parse(sessionStorage.getItem('deleteChrome')) : [];
            let delArr = window.chromoMap;
            let arr = []
            chromoArr.forEach((cItem) => {
                if (delArr.some(dItem => dItem.chromo.index != cItem.index)) {
                    let item = controller.chromoMap.filter(it => it.chromo.index == cItem.index)[0]
                    arr.push(item);
                }
            })
            delArr = delArr.concat(arr)
            window.chromoMap = delArr;
            // sessionStorage.setItem('deleteChrome', JSON.stringify(delArr));

            chromoArr = [];
            this.karyoController.chromoList = this.chromoList;
            this.arrangeController.chromoList = this.chromoList;
            this.karyoController.resetKaryo();
            this.arrangeController.reArrange();
            this.cachePool.save();
        } else {
            stage.draw();
        }
    })



    stage.event.click(condition, delShape)

    stage.element.addEventListener("mousedown", (e) => {
        if (e.button === 1 && !chromoArr.length) {
            const shape = stage.isPointInner(e, false);
            delShape({
                e,
                shape
            })
        }
    })

}
export default deleteShape