/**
 * 选中核型图染色体
 */
function chooseKaryo(stage, targetStage) {
    let currentTarget, currentText, arrangeShape;
    /**
     * 根据图形获取映射对象
     * @param {*} shape 
     */
    const getItemByShape = (shape) => {
        let theItem;
        this.karyoController.chromoMap.forEach(item => {
            if (item.shape === shape) {
                theItem = item;
            }
        })
        return theItem;
    }
    //触发条件
    const condition = {
        getStatus: () => {
            const typeArr = ['count', 'general', 'same', 'diff', 'select', 'delete', 'cutting', 'cross', 'edge']
            return typeArr.indexOf(this.toolsType) > -1
        }
    }

    //鼠标移入图形效果
    stage.event.mouseenter(condition, ({
        e,
        shape
    }) => {
        currentTarget = shape;
        shape._color = shape.color;
        shape.color = 'blue';
        let theItem = getItemByShape(shape);
        this.$refs.allTheKaryos && this.$refs.allTheKaryos.getHoverColor(theItem.chromo, 'blue');
        currentText = theItem.text;
        currentText._color = currentText.color;
        currentText.color = 'blue';
        // console.log(shape);
        if (shape.isHidePolygon) {
            // shape.hide = false;
            // currentText.hide = false;
        }
        this.karyoController.chromoMap.forEach(item => {
            if (item.shape === shape) {
                item.points.forEach(point => {
                    point.color = 'rgba(250,202,9,.8)'
                })
            }
        })
        const arrangeShape = this.arrangeController.getShapeByChromo(theItem.chromo);
        arrangeShape && (arrangeShape.color = "blue");
        if (this.choosedChromo && this.choosedChromo !== theItem.chromo) {
            const _shape = this.arrangeController.getShapeByChromo(this.choosedChromo)
            _shape && (_shape.color = "#f00");
        }
        this.arrangeController.stage.draw();
        stage.draw();
    })

    /**
     * 移出效果
     * @param {*} shape 
     */
    const mouseout = (shape) => {
        if (!shape) {
            return;
        }
        if (shape._color) {
            shape.color = !this.assistantMarker ? 'transparent' : shape._color;
            let theItem = getItemByShape(shape);
            this.$refs.allTheKaryos && this.$refs.allTheKaryos.getHoverColor(theItem.chromo, '');
        }
        delete shape._color;
        if (currentText) {
            if (currentText._color) {
                currentText.color = !this.assistantMarker ? 'transparent' : currentText._color;
            }
            delete currentText._color;
        }
        if (shape.isHidePolygon) {
            // shape.hide = true;
            // currentText && (currentText.hide = true);
        }
        this.karyoController.chromoMap.forEach(item => {
            if (item.shape === shape) {
                item.points.forEach(point => {
                    delete point.color;
                })
            }
        })
        currentText = null;
        currentTarget = null;
        let theItem = getItemByShape(shape);
        if (theItem) {
            const arrangeShape = this.arrangeController.getShapeByChromo(theItem.chromo);
            arrangeShape && (arrangeShape.color = arrangeShape.chromoId < 26 ? "transparent" : "#aaa");
        }
        if (this.choosedChromo) {
            const _shape = this.arrangeController.getShapeByChromo(this.choosedChromo)
            _shape && (_shape.color = "#f00");
            const $shape = this.karyoController.getShapeByChromo(this.choosedChromo)
            $shape && ($shape.color = "#f00");
        }
        this.arrangeController.stage.draw();
        stage.draw();
    }

    //鼠标移出图形
    stage.event.mouseout(condition, ({
        e,
        shape
    }) => {
        mouseout(shape);
    })

    //当前条件下 捕获到dragEnd动作则执行鼠标移出图形效果
    stage.event.dragEnd(condition, () => {
        if (currentTarget) {
            mouseout(currentTarget);
        }
    })

    //点击事件触发条件
    const conditionClick = {
        getStatus: () => {
            const typeArr = ['count', 'arrow', 'delete']
            return typeArr.indexOf(this.toolsType) < 0
        }
    }

    //选中染色体 同时选中排列图中对应的染色体
    stage.event.click(conditionClick, ({
        e,
        shape
    }) => {
        if (shape) {
            currentTarget = shape;
            let chromo;
            this.karyoController.chromoMap.forEach(item => {
                if (item.shape === shape) {
                    item.shape.color = "#f00";
                    item.text.color = "#f00";
                    chromo = item.chromo;
                } else {
                    item.shape.color = "#51A749";
                }
            })
            this.choosedChromo = chromo;
            stage.draw();
            Object.keys(this.arrangeController.group).forEach(g => {
                const group = this.arrangeController.group[g];
                group.list.forEach(item => {
                    if (item.chromo === chromo) {
                        item.shape.color = "#f00";
                        arrangeShape = item.shape;
                    } else if (item.shape) {
                        item.shape.color = item.shape.chromoId < 26 ? "transparent" : "#aaa";
                    }
                })
            })
            this.arrangeController.stage.draw()
        }
    })

    stage.event.click({
		getStatus : ()=>{
			return this.isArrangeOnLeft && this.globalMode==='arrange'
		}
	},({e,shape})=>{
		if(!shape){
			this.switchStage();
		}
	})

    const isPointInner = (e)=>{
        const pos = stage.getPos(e);
        const target = stage.objects.find(item => {
            return item.type === 'polygon' && item.isPointInner(pos.x, pos.y);
        });
        return target
    }
    const showText = (shape)=>{
        const item = getItemByShape(shape);
        if(!item || !item.text) return
        const text = item.text;
        text.hide = false;
        text.color = "blue";
    }
    const hideText = (shape)=>{
        const item = getItemByShape(shape);
        if(!item || !item.text) return
        const text = item.text;
        text.hide = true;
        text.color = "transparent";
    }
    let prevTarget = null;
    stage.element.addEventListener("mousemove",(e)=>{
        if(this.assistantMarker){
            return
        }
        const target = isPointInner(e);
        if(target && !prevTarget){
            target.hide = false;
            showText(target)
            prevTarget = target;
        }
        if(target && prevTarget && prevTarget !== target){
            prevTarget.hide = true;
            hideText(prevTarget);
            target.hide = false;
            showText(target)
            prevTarget = target;
        }
        if(!target && prevTarget){
            prevTarget.hide = true;
            hideText(prevTarget);
            prevTarget = null;
        }
    })

    // stage.element.addEventListener("click",()=>{
    // 	this.$refs["standardSelect"].blur();
    // })
}
export default chooseKaryo