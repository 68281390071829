<template>
    <!-- 身份申请成功后显示页面 -->
    <div class="waitingReviewBox">
        <div>
            <img
                src="../../../assets/images/personalSettings/iconIdentity submitSuccessfully.svg"
                alt=""
            />
        </div>
        <div>{{$t('popup.warning.submit_success')}}</div>
        <div>{{$t('popup.warning.thanks_words')}}</div>
    </div>
</template>

<script>
export default {
    name: "WaitingReview",
    data() {
        return {};
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.waitingReviewBox {
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > :nth-child(2) {
        margin-top: 20px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;

        color: #4773b8;
    }

    & > :nth-child(3) {
        margin-top: 12px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
    }
}
</style>
