import Vue from 'vue'
import {
    ENABLE_ACTIVE,
    DISABLE_ACTIVE,
    ENABLE_ASPECT,
    DISABLE_ASPECT,
    ENABLE_DRAGGABLE,
    DISABLE_DRAGGABLE,
    ENABLE_RESIZABLE,
    DISABLE_RESIZABLE,
    ENABLE_PARENT_LIMITATION,
    DISABLE_PARENT_LIMITATION,
    ENABLE_SNAP_TO_GRID,
    DISABLE_SNAP_TO_GRID,
    CHANGE_ZINDEX,
    ENABLE_BOTH_AXIS,
    ENABLE_X_AXIS,
    ENABLE_Y_AXIS,
    ENABLE_NONE_AXIS,
    CHANGE_HEIGHT,
    CHANGE_LEFT,
    CHANGE_MINH,
    CHANGE_MINW,
    CHANGE_TOP,
    CHANGE_WIDTH,
    CHANGE_FONTSIZE,
    CHANGE_PROP,
    CHANGE_FONTWEIGHT,
    CHANGE_COLOR,
    SET_SHAPEPOSTYLE,
    SET_SHAPERESIZETYLE,
    CHANGE_FORMAT,
    ENABLE_SHOWLABELLINE,
    DISABLE_SHOWLABELLINE,
    ENABLE_SHOWDASHEDLINE,
    DISABLE_SHOWDASHEDLINE,
    CHANGE_FONTFAMILY,
    ENABLE_REQUIRED,
    DISABLE_REQUIRED
} from './mutation-types';
import utils from "../../../components/selfDefineTemplate/config/utils";
import store from "../../index";

export default {
    [ENABLE_ACTIVE](state, id) {
        state.rects[id].configObj.active = true;
    },
    [DISABLE_ACTIVE](state, id) {
        state.rects[id].configObj.active = false;
    },

    [ENABLE_ASPECT](state, id) {
        state.rects[id].configObj.aspectRatio = true;
    },
    [DISABLE_ASPECT](state, id) {
        state.rects[id].configObj.aspectRatio = false;
    },

    [ENABLE_DRAGGABLE](state, id) {
        state.rects[id].configObj.draggable = true;
    },
    [DISABLE_DRAGGABLE](state, id) {
        state.rects[id].configObj.draggable = false;
    },

    [ENABLE_RESIZABLE](state, id) {
        state.rects[id].configObj.resizable = true;
    },
    [DISABLE_RESIZABLE](state, id) {
        state.rects[id].configObj.resizable = false;
    },

    [ENABLE_SNAP_TO_GRID](state, id) {
        state.rects[id].configObj.snapToGrid = true;
    },
    [DISABLE_SNAP_TO_GRID](state, id) {
        state.rects[id].configObj.snapToGrid = false;
    },

    [ENABLE_SHOWLABELLINE](state, id) {
        state.rects[id].configObj.showLabelLine = true;
    },
    [DISABLE_SHOWLABELLINE](state, id) {
        state.rects[id].configObj.showLabelLine = false;
    },
    [ENABLE_SHOWDASHEDLINE](state, id) {
        state.rects[id].configObj.showDashedLine = true;
    },
    [DISABLE_SHOWDASHEDLINE](state, id) {
        state.rects[id].configObj.showDashedLine = false;
    },
    [ENABLE_REQUIRED](state, id) {
        state.rects[id].configObj.isRequired = true;
    },
    [DISABLE_REQUIRED](state, id) {
        state.rects[id].configObj.isRequired = false;
    },

    [ENABLE_BOTH_AXIS](state, id) {
        state.rects[id].configObj.axis = 'both';
    },
    [ENABLE_NONE_AXIS](state, id) {
        state.rects[id].configObj.axis = 'none';
    },
    [ENABLE_X_AXIS](state, id) {
        state.rects[id].configObj.axis = 'x';
    },
    [ENABLE_Y_AXIS](state, id) {
        state.rects[id].configObj.axis = 'y';
    },

    [ENABLE_PARENT_LIMITATION](state, id) {
        state.rects[id].configObj.parentLim = true;
    },
    [DISABLE_PARENT_LIMITATION](state, id) {
        state.rects[id].configObj.parentLim = false;
    },

    [CHANGE_ZINDEX](state, payload) {
        state.rects[payload.id].configObj.zIndex = payload.zIndex;
    },

    [CHANGE_HEIGHT](state, payload) {
        state.rects[payload.id].styles.height = payload.height;
    },

    [CHANGE_WIDTH](state, payload) {
        state.rects[payload.id].styles.width = payload.width;
    },

    [CHANGE_FONTSIZE](state, payload) {
        state.rects[payload.id].styles.fontSize = payload.fontSize;
    },
    [CHANGE_FONTFAMILY](state, payload) {
        state.rects[payload.id].styles.fontFamily = payload.fontFamily;
    },
    [CHANGE_FORMAT](state, payload) {
        state.rects[payload.id].configObj.format = payload.format;
        state.rects[payload.id].configObj.datatype = payload.datatype;
    },
    [CHANGE_FONTWEIGHT](state, payload) {
        state.rects[payload.id].styles.fontWeight = payload.fontWeight;
    },
    [CHANGE_PROP](state, payload) {
        state.rects[payload.id].prop = payload.prop;
    },
    [CHANGE_COLOR](state, payload) {
        state.rects[payload.id].styles.color = payload.color;
    },
    [CHANGE_TOP](state, payload) {
        state.rects[payload.id].styles.top = payload.top;
    },

    [CHANGE_LEFT](state, payload) {
        state.rects[payload.id].styles.left = payload.left;
    },

    [CHANGE_MINH](state, payload) {
        state.rects[payload.id].configObj.minh = payload.minh;
    },

    [CHANGE_MINW](state, payload) {
        state.rects[payload.id].configObj.minw = payload.minw;
    },

    [SET_SHAPEPOSTYLE](state, payload) {
        state.rects[payload.id].styles[payload.newLine.key] = payload.newLine.value
    },
    [SET_SHAPERESIZETYLE](state, payload) {
        if (payload.newLine.key == 'top') {
            state.rects[payload.id].styles.top = payload.newLine.value.k;
            state.rects[payload.id].styles.height = payload.newLine.value.v;
        } else if (payload.newLine.key == 'left') {
            state.rects[payload.id].styles.left = payload.newLine.value.k;
            state.rects[payload.id].styles.width = payload.newLine.value.v;
        }
    },
    undo(state, val) {
        if (state.snapshotIndex >= val) {
            state.snapshotIndex--
            store.commit('rect/setComponentData', utils.deepCopy(state.snapshotData[state.snapshotIndex]))
        }
    },
    redo(state) {
        if (state.snapshotIndex < state.snapshotData.length - 1) {
            state.snapshotIndex++
            store.commit('rect/setComponentData', utils.deepCopy(state.snapshotData[state.snapshotIndex]))
        }
    },
    setComponentData(state, rects = []) {
        Vue.set(state, 'rects', rects)
    },
    recordSnapshot(state, flag) {
        if (flag) {
            state.snapshotIndex = -1
        }
        // 添加新的快照
        state.snapshotData[++state.snapshotIndex] = utils.deepCopy(state.rects)
        // 在 undo 过程中，添加新的快照时，要将它后面的快照清理掉
        if (state.snapshotIndex < state.snapshotData.length - 1) {
            state.snapshotData = state.snapshotData.slice(0, state.snapshotIndex + 1)
        }
    },
};