<template>
  <div class="personalIinformationBox" :class="{'english': $store.state.language == 'en'}">
    <div class="account-manage">
      <div class="area">
        <p class="p-line"><label>{{$t('accountInfo.account')}}</label><span>{{mechanismInfo.account}}</span></p>
        <!-- <p class="p-line">
          <label>空间名称：</label>
          <span v-show="!labEidt">{{mechanismName}}</span>
          <el-input v-model="mechanismName" v-show="labEidt" maxlength="100"></el-input>
          <button class="edit-btn" @click="labNameEdit()" v-show="!labEidt"><img src="../../../assets/images/personalSettings/icon_edit_normal.png"></button>
          <el-button class="btn-conifrm" @click="nameConfirm()" v-show="labEidt">确认</el-button>
          <el-button class="btn-conifrm" @click="nameCancel()" v-show="labEidt">取消</el-button>
        </p> -->
        <p class="p-line"><label>{{$t('accountInfo.organ_shortname')}}</label><span>{{mechanismInfo.shortName}}</span></p>
        <p class="p-line"><label>{{$t('accountInfo.organ_name')}}</label><span>{{mechanismInfo.mechanismName}}</span></p>
      </div>
      <div class="area">
        <label class="title">{{$t('accountInfo.team_info')}}</label>
        <ul class="ul-list">
          <li>
            <h1>{{mechanismInfo.memberCount}}</h1>
            <span class="sp">{{$t('accountInfo.member')}}</span>
          </li>
          <li>
            <h1>{{mechanismInfo.patientCount}}</h1>
            <span class="sp">{{$t('accountInfo.cases_num')}}</span>
          </li>
          <li>
            <h1>{{mechanismInfo.patientCountAll}}</h1>
            <span class="sp">{{$t('accountInfo.upload_cases_num')}}</span>
          </li>
        </ul>
      </div>
      <div class="area">
        <label class="title">{{$t('accountInfo.super_manager')}}</label>
        <ul class="user-list">
          <li><img :src="mechanismInfo.headUrl" alt="" />{{mechanismInfo.userName}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../assets/scss/button.scss";
import "./../scss/personalIinformation.scss";
import Divider from "./../../divider";
export default {
  name: "AccountInfo",
  components: {
    Divider,
  },
  created() { },
  data() {
    return {
      mechanismInfo: {},
      labEidt: false,
      mechanismName: "",
      mechanismNameBefore: "",
    };
  },
  methods: {
    labNameEdit() {
      this.labEidt = true;
    },
    nameConfirm() {
      if (!this.mechanismName) {
        this.$message.error(this.$t('accountInfo.organ_name_cannot_empty'))
        this.mechanismName = this.mechanismNameBefore;
        return
      }
      this.updateAccountName();
    },
    nameCancel() {
      this.mechanismName = this.mechanismNameBefore;
      this.labEidt = false;
    },
    // 获取个人信息
    mechanismQuery() {
      this.$api.mechanismQuery({}).then((res) => {
        if (res.code == 200) {
          this.mechanismInfo = res.data;
          this.mechanismName = res.data.mechanismName;
          this.mechanismNameBefore = res.data.mechanismName;
          let imgUrl = res.data.headUrl;
          this.mechanismInfo.headUrl = this.ossUrlWebFir(imgUrl, 1, 58, 58);
        } else {
          // this.$router.push({
          //   path: "/",
          // });
          this.$message.error(res.message);
        }
      });
    },
    updateAccountName() {
      let param = {
        accountName: this.mechanismName,
      };
      this.$api.updateAccountName(param).then((res) => {
        if (res.code == 200) {
          let labValue = JSON.parse(localStorage.getItem('currentLabValue'));
          this.$message.success(res.message);
          let obj = {
            mechanismId: labValue.mechanismId,
            mechanismName: this.mechanismName,
            accountType: labValue.accountType,
            isExpert: labValue.isExpert,
          };
          this.$store.commit("changeLabValue", obj);
          localStorage.setItem('currentLabValue', JSON.stringify(obj));
          this.labEidt = false;
        } else {
          this.$message.error(res.message);
          this.labEidt = false;
        }
      });
    },
  },
  watch: {
    // "$store.state.labValue": function () {
    //   if (this.$store.state.accountType == '2' && this.$route.path === '/personalSettings') {
    //     this.mechanismQuery();
    //   }
    // },
    "$route.path": function (val) {
      if (val == '/personalSettings' && this.$store.state.accountType == '2') {
        this.mechanismQuery();
      }
    }
  }
};
</script>
<style lang="scss">
.personalIinformationBox {
}
</style>