<template>
    <div class="karyotypeSelectionBox">
        <el-dialog
            :title="$t('popup.old_report.karyo_image_choose')"
            :visible.sync="karyotypeDialogVisible"
            width="900px"
            :before-close="closeFun"
        >
            <div class="karyotypeSelectionContent">
                <div class="karyotypeSelectionContentImg">
                    <span class="karyotyImg">
                        <img
                            :src="
                                karyoAndPermutation
                                    ? karyotypeUrl
                                    : permutationUrl
                            "
                            alt=""
                            width="586px"
                            height="471px"
                        />
                    </span>

                    <span
                        class="karyotypeSelectionContentIcon"
                        @mouseover="changeActive"
                        @mouseout="changeMask"
                    >
                        <img
                            src="../../assets/images/analysissystem/iconSwitchPermutationChart.svg"
                            alt=""
                        />
                    </span>
                </div>

                <div class="karyotypeSelectionContentList">
                    <ul>
                        <li
                            v-for="item in karyotypeList"
                            :key="item.id"
                            :class="{ current: item.id == karyotypeId }"
                            @click="karyotypeFun(item)"
                        >
                            <div class="abbOneBox">
                                <div class="abbOneNum">
                                    <div>{{ item.singleNum }}</div>

                                    <div class="abbOneColor"></div>
                                </div>

                                <img
                                    :src="item.abbOne"
                                    alt=""
                                    srcset=""
                                    width="58px"
                                    height="58px"
                                />

                                <div
                                    class="reexamination"
                                    v-if="item.whetherRepeatInspect"
                                ></div>
                            </div>

                            <div class="abbOneTxt">
                                <el-tooltip
                                    :content="
                                        item.glassName + '/' + item.cellName
                                    "
                                    placement="right"
                                    effect="light"
                                >
                                    <div class="abbOneTxtTooltip">
                                        {{ item.glassName }}/{{ item.cellName }}
                                    </div>
                                </el-tooltip>

                                <el-tooltip
                                    :content="item.karyotypeExpression"
                                    placement="right"
                                    effect="light"
                                >
                                    <div class="abbOneTxtTooltip">
                                        {{ item.karyotypeExpression }}
                                    </div>
                                </el-tooltip>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <div class="btnBox">
                    <div class="btn updateBtn" @click="cancel">{{$t("information.cancel")}}</div>

                    <div class="btn determineNomal" @click="onSubmit">{{$t("information.determine")}}</div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>
 
 
 
<script>
export default {
    name: "KaryotypeSelection",

    props: ["karyotypeDialogVisible", "patientId", "reportId", "karyoId"],

    data() {
        return {
            newKaryotypeDialogVisible: false,

            karyotypeId: "",

            dname: "",

            karyoAndPermutation: true,

            karyotypeUrl: "",

            permutationUrl: "",

            glassName: "",

            cellName: "",

            karyotypeList: [],
        };
    },

    methods: {
        getPatientQueryBySingleType() {
            this.$api

                .patientQueryBySingleType({ patientId: this.patientId })

                .then((res) => {
                    if (res.code == 200) {
                        if (res.data.length <= 0) return;

                        this.karyoId != ""
                            ? (this.karyotypeId = this.karyoId)
                            : (this.karyotypeId = res.data[0].id);

                        this.dname = res.data[0].dname;

                        this.karyotypeUrl = res.data[0].optUrl;

                        this.permutationUrl = res.data[0].arrayUrl;

                        this.glassName = res.data[0].glassName;

                        this.cellName = res.data[0].cellName;

                        this.karyotypeList = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },

        changeActive() {
            this.karyoAndPermutation = false;
        },

        changeMask() {
            this.karyoAndPermutation = true;
        },

        closeFun() {
            this.newKaryotypeDialogVisible = false;

            this.$emit("childFun", this.newKaryotypeDialogVisible);
        },

        cancel() {
            this.newKaryotypeDialogVisible = false;

            this.$emit("childFun", this.newKaryotypeDialogVisible);
        },

        karyotypeFun(obj) {
            this.karyotypeId = obj.id;

            this.dname = obj.dname;

            this.karyotypeUrl = obj.optUrl;

            this.permutationUrl = obj.arrayUrl;

            this.glassName = obj.glassName;

            this.cellName = obj.cellName;
        },

        onSubmit() {
            this.newKaryotypeDialogVisible = false;

            let karyoData = {
                karyotypeId: this.karyotypeId,
                dname: this.dname,
                glassName: this.glassName,
                cellName: this.cellName,
                karyotypeUrl: this.karyotypeUrl,
                permutationUrl: this.permutationUrl,
            };

            this.$emit("childFun", this.newKaryotypeDialogVisible);

            this.$emit("karyoDataFun", karyoData);
        },
    },

    watch: {
        karyotypeDialogVisible() {
            this.newKaryotypeDialogVisible = this.karyotypeDialogVisible;

            this.getPatientQueryBySingleType();
        },
    },

    created() {},
};
</script>
 
 
 
<style lang="scss" scoped>
.karyotypeSelectionBox {
    .karyotypeSelectionContent {
        display: flex;

        height: 526px;

        box-shadow: 0px 1px 0px 0px #dddddd;

        .karyotypeSelectionContentImg {
            width: 740px;

            height: 100%;

            display: flex;

            justify-content: center;

            align-items: center;

            position: relative;

            .karyotypeSelectionContentIcon {
                cursor: pointer;

                position: absolute;

                top: 20px;

                right: 20px;
            }
        }

        .karyotypeSelectionContentList {
            width: 160px;

            height: 100%;

            background: #d2d4d6;

            box-shadow: -1px 0px 0px 0px #999999;

            overflow: auto;

            overflow-x: hidden;

            ul {
                margin: 0;

                padding: 0;

                li {
                    cursor: pointer;

                    margin-top: 1px;

                    list-style-type: none;

                    height: 58px;

                    background: #e7e9eb;

                    display: flex;

                    &.current {
                        border-right: 5px solid #416ab0;
                    }

                    .abbOneBox {
                        position: relative;

                        .abbOneNum {
                            position: absolute;

                            top: 4px;

                            left: 5px;

                            display: flex;

                            flex-direction: column;

                            .abbOneColor {
                                display: block;

                                width: 18px;

                                height: 3px;

                                background: #1a9448;
                            }
                        }

                        .reexamination {
                            position: absolute;

                            bottom: 0;

                            width: 100%;

                            height: 5px;

                            background-color: #f53500;
                        }
                    }

                    .abbOneTxt {
                        margin-left: 6px;

                        font-size: 12px;

                        font-family: PingFangSC-Regular, PingFang SC;

                        font-weight: 400;

                        color: #1a1a1a;

                        .abbOneTxtTooltip {
                            margin-top: 5px;

                            width: 70px;

                            text-overflow: ellipsis;

                            white-space: nowrap;

                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }

    .btnBox {
        width: 860px;

        display: flex;

        justify-content: flex-end;

        .updateBtn {
            width: 80px;

            height: 30px;
        }

        .determineNomal {
            margin-left: 10px;

            width: 82px;

            height: 32px;
        }
    }
}
</style>
 
 
 
<style lang="scss">
.karyotypeSelectionBox {
    .el-dialog__body {
        padding: 0;
    }
}
</style>