<template>
  <div class="check-count-box">
    <div>
      <span>{{$t('checkhtml.surface_line1')}}</span>
      <span>{{$t('checkhtml.remind')}}<a href="https://cdn.autovision.online/web/static/download/ChromeSetup.exe">{{$t('checkhtml.install_chrome')}}</a></span>
    </div>
  </div>
</template>

<script>
export default {
    name: "checkhtml"
}
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  min-width: 1280px;
  background-color: rgb(242, 245, 247);
  width: 100%;
  height: 100%;
}
.check-count-box {
  height: 20%;
  width: 80%;
  padding-bottom: 10%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 50px auto;
}

.check-count-box-img {
  display: block;
  width: 100%;
  height: 56px;
  margin: 0 auto 20px;
}
.check-count-box-img > img {
  display: block;
  margin: auto;
}
.check-count-box div span:nth-child(1) {
  display: block;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  margin-top: 50px;
}
.check-count-box div span:nth-child(2) {
  display: block;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-top: 30px;
}
</style>