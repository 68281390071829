const en = {
    backto_case_overview: "Return",
    connect_service: "Contact Customer Service",
    full_screen: "Full Screen",
    quit_screen: "Quit full screen",
    srcEnOrZh:
        "https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97d37257972615ccbf4ae7b8601730e6bf9fa62c96a295dc66f2b022cee0c61ab3d74b458364d4d7216792edbf66ba07cb",
    Pagnation: {
        Each_page: "each page",
        article: "item",
        determine: "confirm",
        Article_total: "items",
        page: "page",
        message: " Item number cannot be null or 0",
        title: "tips"
    },
    main: {
        Cases_in_the_overview: "My case",
        message_graphics:
            " Failed to load, please refresh the page and try again!!",
        adjunct: "attachment",
        account_info: "Account information",
        default_color: "Default color",
        theme_color: "Theme color",
        standard_color: "Standard color",
        more_color: "More color..."
    },
    new_interaction: {
        sort: "sort：",
        mark: "mark",
        progress_bar: "progress",
        color_filter: "color：",
        batch_analysis: "automatic analysis ",
        batch_delete: "delete",
        enter_karyotype_analysis: "Analysis Page",
        glass: "slide",
        age_check: "Age cannot be greater than 200 years old.",
        Select_report: "select a Template",
        patient_ame: "Please enter the patient name",
        Sex_selection: "choosing gender",
        Select_karyotype: "select an image",
        addnew_report: "New Report",
        Karyotype_diagram_statistics:
            "Images not currently confirm arrayed，please at least confirm array an image",
        inspection_report: "Chromosome examination report",
        Case_information: "Case information",
        Deletion_of_chromosome: "delete chromosomes",
        message: "Restore to the original state?",
        message_title: "restoring",
        message_err: "Request time out. Please try again.",
        message_karyotype:
            "lmage has been deleted，please select other images!",
        message_num: "loading data",
        ptimize_the_image: "optimize image",
        optimize_the_image_on: "optimizing",
        code: "Code",
        autovision_account: "account",
        result: "results",
        team_setup: "Organization Settings",
        waiting_start: "waiting",
        waiting_audit: "pending approval",
        audit_pass: "approved",
        audit_nopass: "not approved",
        no_analysis: "without analysis",
        completely: "completed",
        case_allot: "Case assigned",
        case_transfer: "Transfer Cases",

        cases: "Cases",
        consult_case: "Consultation case",
        case_all_check: "Cases",
        case_analysis_completely: "Case analysis has been completed",
        complete_case_analysis: "Complete Case Analysis",
        isComplete_all_work: "Case analysis has been completed ?",
        operate_success: "Success",
        cannot_transfer: "您目前没有合作机构，暂时不能进行病例转交"
    },
    caseAllocation: {
        filter_by_source: "Source：",
        filter_by_progress: "Progress：",
        advanced_setup: "Settings",
        filter_by_caseno: "Code：",
        filter_by_person: "Belongs to：",
        enter_caseno: "Enter case code",
        belonger: "Belonger",
        progress: "Progress",
        transfer_status: "Transfer status",
        all_checked: "Select all",
        checked: "Selected",
        all_cases: "All case",
        assign_to: "assign to：",
        confirm_assigned: "Confirm",
        no_transfer: "untransfered",
        transfered: "transfered",
        no_analysis: "without analysis",
        auto_completetly_analysis: "processing",
        manu_completetly_analysis: "completed",
        choose_allot_cases: "choose cases",
        choose_assigner: "choose assigner",
        cannot_transfer:
            "tip：Without analysis、finished and transfered case can't be transfer",
        co_agency: "Cooperation：",
        confirm_transfer: " Confirm",
        choose_transfer_cases: "Choose case",
        opinion_cannot_empty: "Cannot be empty",
        files_50_most: "A maximum of 10 files can be upload,",
        only_support_files: "Support file type include pdf,doc,docx,xls,xlsx",
        year: "year",
        month: "month",
        day: "day"
    },
    uploadPdf: {
        case_info_upload: "upload",
        intotal: "total",
        uopload_progress: " files have been uploaded",
        setup: "Settings",
        userful_core_tool: "Universal Tools",
        deep_analysis_tool: "Analysis tools,",
        all_karyos: "Chromosomes",
        add_organ: "Create a orgnazation",
        system_backstage: "backstage",
        account_setup: "Account Settings",
        Chinese: "Chinese",
        personal_space: "Personal space",
        expert_space: "Expert space",
        no_right_check: "You don’t have permission",
        personal_cases: "My case"
    },
    accountInfo: {
        prompt:'prompt',
        phone_immediately:"Top-Up",
        Close_remind:"I know",
        account: "Account：",
        organ_shortname: "Organization shortname：",
        enter_organ_shortname: "Enter Organization shortname",
        organ_name: "Organization name：",
        team_info: "Information",
        member: "Members",
        cases_num: "Cases",
        upload_cases_num: "Total cases",
        super_manager: "Super manager",
        organ_name_cannot_empty: "Organization name can't empty",
        organ_manage: "Organization management",
        organ_approve: "Create organization",
        department: "Department：",
        audit: "Pending approval",
        audit_waiting: "The audit will take about two days ",
        audit_nopass: "Not Approved",
        re_auth: "Re-authenticate",
        add_organ: "Create a orgnazation",
        auth_rights: "Right and Interests：",
        auth_pass_rights: "Rights after authentication：",
        auth_right_one: "1、You can invite members to analysis together",
        auth_right_two: "2、You can use expert consult。",
        auth_right_three:
            "3、You can use transfer case analysis service（affer sign cooperation，you can transfer cases to cooperative organization to analysis）。",
        back: "Return",
        my_approve: "My approve",
        my_identity: "My identity",
        name: "Name：",
        affiliated_unit: "Unit：",
        add_identity_approve: "Add Individual Authentication",
        my_rights:
            "After the identification is passed, the intelligent analysis function of personal space is opened：",
        right_one: "1、You can use karyotype lmage analysis.",
        right_two: "2、You can analysis tool to adjust the image.",
        file_uploading: "uploading",
        imgName_cannot_include_special:
            "The image name cannot contain characters other than Chinese characters, numbers, letters and ()()_-,",
        to: "to",
        startDate: "started",
        endDate: "ended",
        case_no: "case code",
        status: "status：",
        statusNo: "Status",
        analysis_account: "Analysis account",
        case_name: "Case name",
        case_source: "Source",
        get_money: "completed",
        not_get_money: "processing",
        charge: "top-up",
        expert_reply: "reply",
        widthdraw: "withdraw",
        auto_snalysis: "Automatic Analysis",
        transfer_service: "transfer caseanalysis service",
        expert_consult: "expert consult",
        transfer_cass: "Transfer Case",
        sys_charge: "System top-up",
        sys_widthdraw: "System withdrawal",
        case_transfer_back_money: "case transfer return",
        head: "image：",
        change_head: "upload",
        third_apply: "Bind social platform account：",
        bind: "Bind",
        need_include:
            "Must and only contain at least two between alphabets, numbers, and underscore. Should be between 6 and 25 characters",
        edit_success: "success",
        certified: "certified"
    },
    new_tools: {
        Counting_mode: "Count",
        pattern: "Arrange",
        Karyotypes_interchangeably: "Switch View",
        Arrange_all: "Rearrange All",
        Arranged_to_arranged: "Rearrange Part",
        daub: " add",
        rotating: "Rotate",
        delete: "Delete",
        multidelete: "Multidelete",
        Chromosome_amplification: "Enlarge",
        Chromosome_reduction: "Chorten",
        Auxiliary_marker: "Sign",
        optimize_the_figure: "Optimal",
        original_figure: "Original",
        current_count: "Current Count：",
        current_count1: "Chromosome Number",
        Karyotype_expression: "Karyotype expression",
        Case_information: "Case Information",
        Case_number: "Case code",
        name: "Name",
        Years_of_age: "Age",
        Sex_don: "Gender",
        Sample_type: "Sample type",
        Receiving_date: "Received date",
        Clinical_diagnosis: "Clinical diagnosis",
        note: "Note",
        Slide_number: "Slide code",
        Cell_number: "Cell code",
        optimize_the_image: "Optimize",
        Chain_erase: "Chain ",
        Standard_ribbon: "Standard",
        Fixed_belt: "Band Stage",
        Homologous_double_sign_contrast: "Double chr",
        Homologous_order_number_comparison: "Single chr",
        Color_tag: "Color",
        arrow_mark: "Arrow",
        Placed_at_the_top: "Top",
        The_mirror: "Mirror",
        Export_images: "Export",
        The_eraser: "Eraser",
        Reautomatic_analysis: "Reanalysis",
        notation: "Notes",
        Please_enter_image: "Enter marks",
        On_one_piece: "Back",
        Confirm_count: "Counted and Next",
        Confirm_the_count_and: "Counted and Arranged",
        Cancel_the_confirmation: "Cancel Confirm ",
        The_next: "Next",
        List_of_case_karyotypes: "lmage list",
        Top_area: "Top area",
        The_top_section: "Common area",
        Enter_the_note: "Enter notes",
        Please_enter_the_contents: "Enter contents",
        cancel: "Cancel",
        determine: "Confirm",
        message: "Loading",
        Is_cancelled: "Cancelling",
        Cancelled_confirmation: "Canceled Confirm"
    },
    information: {
        Case_number: "Case code",
        name: "Name",
        age: "Age",
        gender: "Gender",
        Sample_type: "Sample type",
        Receiving_date: "Received date",
        Clinical_diagnosis: "Clinical diagnosis",
        note: "Note",
        Case_information: "Case information",
        Please_enter_name: "Entername",
        male: "male",
        female: "female",
        other: "others",
        Please_enter_your_diagnosis: "Enter your diagnosis",
        Please_enter_remarks: "Enter remarks",
        determine: "Confirm",
        cancel: "Cancel",
        confirm: "A file is being uploaded,to continue？",
        files: "Attachment",
        upload_file: "Upload",
        support_files: "Supported file types include：pdf,jpg,png",
        select_please: "Select",
        day: "day",
        week: "week",
        month: "month",
        year: "years old"
    },
    metadata: {
        Glass: "Slide：",
        All_slides: "All slides",
        progress_of: "Progress：",
        progress_of_on: "Progress",
        All_images: "All Images",
        Automatic_analysis: "Automatic Analysis",
        Manual_counting_confirmation: "Manual Counted ",
        Manual_alignment_confirmation: "Manual Arranged",
        Number_of_slides: "Sildes：",
        a_num: "",
        graphics: "Images：",
        zhang: "",
        Confirm_the_count: "Counted",
        Article_45: "45 bars",
        zhang_Num: "：",
        Article_46: "46 bars",
        Article_47: "47 bars",
        Confirm_the_arrangement: "Arranged",
        karyotype: "karyotype",
        No_analysis: "Without analysis"
    },
    nameSorting: {
        My_case: "My Case",
        Sort_by_name: "Name",
        Chronological_order: "Date",
        time: " Date",
        Descending_order: "Desc",
        name_of_the: "Name",
        ascending: "Up"
    },
    searchLink: {
        name_search: "",
        Additional_analysis: "Additional Analysis"
    },
    primarySetup: {
        infrastructure: " Settings",
        amplification_setting: "Enlarging settings",
        Chromosome_size: "Eise：",
        Chromosome_Amplification: "Enable：",
        Analysis_of_setting: "Analysis settings",
        Count_the_number: "Counted number：",
        Please_enter_the: "Enter",
        zhang: "",
        Arrange_the_number: "Arranged number：",
        message:
            "Tip：Analysis accounted and arranged pictures have arrived the expected value ，they are considered as analysis completion",
        Restore_the_default: "Restore",
		analysisModeDefault: "Default analysis mode：",
		hasHideNumber: "Whether to hide the chromosome number：",
		arrangeMode: "Arrange mode",
		counterMode: "Counting mode",
		hide: "Hidden",
		show: "Visible",
        cancel: "Cancel",
        save: "Save",
        Set_up_success: "Success"
    },
    progressSetup: {
        Already_count: "Counted",
        Has_been_arranged: "Arranged",
        Zhang_karyotype: "",
        Analysis_of_setting: "Analysis settings",
        message:
            "Tip：Analysis accounted and arranged pictures have arrived the expected value ，they are considered as analysis completion",
        Count_the_number_of: "Counted：",
        Arrange_the_number: "Arranged：",
        Please_enter_the: "Enter",
        cancel: "Cancel",
        save: "Save",
        Set_up_the_success: "Success"
    },
    teamSeting: {
        case: "Case",
        unit: "CNY",
        automatic: {
            title: "Automatic Analysis",
            low: {
                title: "low quality case",
                desc: "low quality is 60% or less"
            },
            secondary: {
                title: "medium quality case",
                desc: "medium quality image is 60% or less"
            },
            high: {
                title: "high quality case",
                desc: "high quality image is 60% or less "
            }
        },
        transfer: {
            title: "Transfer case settings",
            on: "on",
            off: "off",
            agencyLabel: "Cooperative organization：",
            placeholder: "Select",
            desc: "Case analysis cost：",
            method: "Method：",
            method_1:
                "Automatic send to cooperative organization after uploaded",
            method_2: "Manual send to cooperative organization"
        },
        analysis: {
            title: "Assignment settings",
            title1: "Enable assigne：",
            title2: "Enable automatic assigne："
        },
        service: {
            title: "Transfer service",
            cost: "Transfer service cost：",
            btnSubmit: "Submint",
            btnSuccess: "Waiting",
            tip:
                "If you need to open the case transfer service, please contact the staff at 0571-89055921 to sign a cooperation agreement"
        }
    },
    members: {
        addMembers: "Invite members",
        name: "Name",
        color: "Color",
        role: "Role",
        username: "User name",
        phone: "Phone number",
        operation: "Operation",
        remove: "Remove",
        invite: "Invite members",
        administrator: " administrator",
        seniorAnalyst: "senior analyst",
        analysts: "analyst",
        inspector: "reader",
        copy: "copy link",
        setting: "account settings",
        member: "members management",
        patient: "Case Management",
        analyse: "case analysis",
        recheck: "recheck",
        report: "Report management",
        view: "view the case",
        copied: "copied",
        tip: "tip",
        success: "success",
        fail: "failure",
        del: "confirm to delete this member？",
        remark: "note",
        nickname: "Nickname",
        mesg_invite: "Message invite",
        link_invite: "Link invite",
        color_success: "Color changed successfully",
        name_success: "Nickname modified successfully",
        role_success: "Role modification succeeded",
        want_copy_content: "Here is what you want to copy."
    },
    noPermission: {
        tip: "You do not have permission，please return",
        home: "homepage"
    },

    checkhtml: {
        top_up:"top-up",
        inform: "Notification",
        human_online_diagnose_center:
            "Online Diagnostic and Consultant Center for Human Genetics",
        preview_version: "(Preview version)",
        surface_line1:
            "Some functions of China Human Genetics Online Diagnosis and Consultation Center can have compatibility issues on non-chrome browser",
        surface_line2:
            "Through intelligent analysis, remote diagnosis report, remote MDT of difficult cases, etc.",
        surface_line3:
            "Integrate medical resources and improve the quality of service for patient groups",
        surface_line4:
            "Zhejiang ICP No. 17045030 Zhejiang Public Network Security No. 33011002014406",
        remind:
            "Google Chrome browser is recommended for the best product experience.",
        install_chrome: "Click to download Chrome and install",
        analysis_tool: "online autovision profiler",
        analysis_system: "Analysis System",
        privacy_statement: "Privacy Statement",
        service_agreement: "Service Agreement",
        pay_page: "payment page",
        custom_template: "Custom Report Template",
        no_data: "No Data"
    },
    login: {
        login_width_num: "User Name",
        input_num: "Please enter the account/phone number",
        input_password: "Password",
        input_password_warn: "Please enter the Password",
        mesage_login: "SMS Verification",
        input_tel: "User Name",
        input_tel_warn: "Please enter the phone number",
        input_verifycode: "SMS code",
        get_verifycode: "Get code",
        login: "Log in",
        new_register: "Sign up",
        free_register: "Sign up",
        tologin: "Already have an account, ",
        input_login_num: "Please enter the account number",
        telnum_notCorrect:
            "The phone number format is incorrect. Please re-enter.",
        verifycode_cannot_empty: "Verification code cannot be empty",
        verifycode_notCorrect:
            "The verification code is incorrect. Please re-enter",
        agree_deal:
            "Please agree to the privacy statement and service agreement first",
        complete_smart_verify: "Please complete smart verification first",
        Chinese_online_consult_center:
            "Chinese Human Genetics Online Diagnostic Consultation Center",
        no_account: "Dont have an account yet?",
		invitation: "Do you agree to join"
    },
    smart_verify: {
        hit_verify: "Click for smart verification",
        smart_verifying: "Smart verification...",
        verified_success: "Verified successfully",
        complete_smart_verify: "Please complete smart verification",
        verify_failed: "Verification failed, please click here to refresh"
    },
    case_overview: {
        my_case: "My Case",
        case_manage: "Case Management",
        case_allot: "Case Assigned",
        input_caseNumAndName: "Enter case number or name",
        karyoImage: "Image",
        report: "Report",
        auto_analysis: "Automatic Analysis",
        add_analysis: "Additional Analysis",
        slide_num: "Slides",
        karyoImage_num: "Images",
        confirm_count: "Confirm Count",
        confirm_array: "Confirm Array",
        manual_count: "Manual Count",
        small_image: "Thumbnail View",
        array_image: "List View",
        metaphase_image: "Metaphase image",
        slide: "slide",
        cell: "Cell",
        count_num: "Count Number",
        count_confirm: "Count Confirmation",
        array_confirm: "Array Confirmation",
        karyotype_express: "Karyotype Expression",
        no_confirm: "Unconfirmed",
        confirmed: "Confirmed",
        nocase_tocheck: "There are no cases to check currently",
        delete: "Delete",
        goto_case_analysis_page: "Go to the analysis tool page",
        analyzing: "Analysis in progress",
        auto_analyzing: "Automatic analysis",
        add_consult_opinion: "Add consultation opinion",
        check_consult_opinion: "Check consultation opinion",
        recheck_pass: "Recheck",
        recheck_cancel: "Cancel",
        submit_expert_consult: "Submit an expert consultation"
    },
    case_manage: {
        case_import: "Import case",
        defined_import: "Custom Import",
        case_output: "Export case",
        import_single_case: "Import single case",
        import_case_data: "Import case database",
        import_autovision_case: "Import AutoVision case",
        import_file: "Import folder",
        autovision_upload: "Cases upload",
        cases_upload: "Cases upload",
        total: "In total ",
        upload_progress: " cases, upload progress bar:",
        uploading: "uploading",
        analysising: "is analyzing",
        analysis_complete: "analysis complete",
        retry: "click to retry",
        upload_time: "Time remaining",
        upload_failed: "fail to upload",
        cases_exist: "Cases already exist",
        waiting_upload: "waiting",
        upload_completely: "Completed ",
        upload_successfully: "Successed ",
        last: ", the remaining ",
        upload_failed_nums: "upload failed",
        pieces: "",
        stop: "stop",
        confirm: "Yes",
        upload_remind: "upload prompt",
        use_all: "Apply to all",
        keep_both: "Keep both",
        cancel: "No",
        change: "replace",
        unfind_rechoose: "No case retrieved, please reselect!",
        rechoose: "Reselect",
        searching_case: "On retrieval progress",
        only_show_choosed_cases:
            "Only cases in a specified time period are displayed:",
        end_time: "Last Modified Time",
        size: "size",
        choosed: "selected",
        all_cases: "cases, in total",
        images_upload_progress: " images, upload progress bar:",
        time_remaining: "Time remaining: ",
        waiting: "waiting",
        saving: "saving",
        upload_success: "success",
        save_failed: "failed",
        searching: "searching",
        cases: "cases",
        case_edit_time: "Modified time",
        only_export_choosed_images:
            "Only specific images from selected cases are exported:",
        confirmed_images:
            "Images with counting confirmation and arrangement confirmation",
        export_all_images: "Export all images",
        only_confimed_array_images: "Images only with arrangement confirmation",
        export: "export",
        export_progress: "cases, export progress bar:",
        exporting: "exporting",
        export_completely: "complete",
        export_failed: "failed",
        export_successfully: "success",
        counting: "Counting",
        new: "new",
        old: "old",
        exist_name: "The existing name is",
        more: "more",
        de_cases: "case",
        want_change:
            "Would you like to replace it with the case you are uploading?",
        exceed: "exceed",
        days: "days",
        is_counting: "counting",
        completely: "complete",
        no_complete: "cases, unfinished",
        confirm_stop: "cases, confirm if you want to stop?",
        continue: "cases can be exported, do you want to continue?",
        all: "In total ",
        no_image_rechoose: "No matching image, please reselect!",
        search_by_casecode: "Please enter case number to search",
        lately_1week: "The last one week",
        lately_1month: "The last one month",
        lately_3month: "The last three weeks",
        lately_6month: "The last six weeks",
        lately_1year: "The last one year",
        overlimit: "More than limit",
        overlimit_c:
            "Single cases karyotype figure number more than ${0} pieces, can't upload"
    },
    account_num: {
        data_manage: "Data Management",
        personal_setup: "Setting",
        exit_system: "Exit"
    },
    case_analysis: {
        confirmed_count: "Confirmed Count:",
        confirmed_array: "Confirmed Array:",
        image_num: "Image",
        array_image: "Array Image",
        tool_kit: "Tool Box",
        mesophase: "Metaphase",
        original_image: "Original Image",
        optimization_image: "Optimization image",
        karyoImage_list: "Image List",
        choose_band_info: "Band Stage",
        powerful_tools: "Universal Tools",
        quondam_image: "Original Image",
        karyoArray_change: "View Switch",
        powerful_core_tools: "Core Tool",
        depth_analysis_tools: "Karyotyping Tools",
        current_count: "Current Count",
        karyotype_express: "Karyotype expression",
        annotation: "Annotation",
        input_analys_content: "Please provide the analysis content",
        need_recheck: "Please check the image",
        confirm_count: "Confirm Count",
        confirm_array: "Confirm Array",
        save: "Save (Ctrl+S)",
        save_exit: "Save and Exit",
        load_karyoImage: "Download Metaphase image",
        load_arrayImage: "Download Karyogram image",
        single: "Single",
        double: "Double",
        num_karyo_compare: "Chromosome comparison",
        num_karyo: "Chromosome number",
        waited_array: "To be arranged",
        brush_thickness: "Brush thickness",
        case: "The Case",
        close_case: "is in operation,this page is about to close",
        loading_data: "Loading data"
    },

    tools_tip: {
        undo: "Undo",
        redo: "Restore",
        karyo_array_change: "Change karyotype/Arrangement Chart",
        count: "Count",
        powerful_tool: "Universal Tool",
        autoArray_pendings: "Automatically arrange pending chromosomes",
        autoArray_all: "Automatically arrange all chromosomes",
        sameKaryos_contrast: "Single chromosome comparison",
        twoKaryos_contrast: "Double chromosomes comparison",
        standard_karyos: "Standard chromosomes",
        arrows_mark: "Arrow mark",
        choose: "Select",
        delete: "Delete",
        eraser: "Eraser",
        area_wipe: "Clear Area",
        smeartoAdd: "Edit to Add",
        karyos_connect: "Chromosome link",
        karyos_cut: "Chromosome Cut",
        karyos_cross_cut: "Chromosome Cross Cut",
        eade_cut: "Trim",
        rotate: "Rotate"
    },

    powerful_tools: {
        line1:
            "1.Alt+click the blank area of the view to switch left and right views",
        line2:
            "2. Press the left mouse button to move and drag and drop the view",
        line3:
            "3. In the left and right view, scroll the mouse wheel to zoom the view",
        line4:
            "4. Click the W button to show or hide the chromosome edge and counting indicator",
        line5:
            "5. Press the D key, Delete, Backspace or the mouse wheel button to delete the selected chromosome",
        line6:
            "6. Press the right mouse button to draw a line to draw a line and cut",
        line7:
            "7. Press E + the left mouse button to move the mouse to erase the area",
        line8: "8. Press Ctrl+Z to cancel the previous operation",
        line9: "9. Press Ctrl+Y to resume the previous operation",
        line10: "10. Press Ctrl+S to save the current operation result",
        line11:
            "11. Press Ctrl+left mouse button to rotate the mouse to rotate the chromosome",
        line12: "12. Left click the mouse to drag chromosome",
        line13: "13. Press Ctrl+mouse wheel to zoom chromosomes",
        line14:
            "14. Press Ctrl+Shift+left mouse click, for mirror rotation of chromosome",
        title: "In the metaphase",
        line15:
            "15. Press and hold the chromosome with the left mouse button to move to the adjacent chromosome for connection",
        line16:
            "16. Press the right mouse button on the chromosome to perform recommended cut",
        line17:
            "17. Press the Shift key and the left mouse button to edit operation",
        line18:
            "18. Press the X key and the right mouse button under the universal tool to make a cross cut",
        same_karyo_compare:
            "In the metaphase image，press the left mouse button on the chromosome, then the chromosomal same number comparison interface will pop up",
        double_karyo_compare:
            "In the metaphase image，press the left mouse button on the chromosome, then the chromosomal comparison of different number interface will pop up",
        mark_karyo:
            "In the metaphase image or in the array image, press the left mouse button and move the mouse to mark the chromosome",
        delete_mark:
            "Press the right mouse button on “arrow marker”, then it will be delete",
        delete_arrow:
            "Press the right mouse button on the arrow, then it will be delete",
        choose: "choose",
        karyo_moving: "chromosome mobilization",
        delete_tools: "Delete tool",
        area_wipe: "Zone erasure tool",
        delete_choosed_karyo:
            "In the metaphase image or in the array image，press the left mouse button to delete the chosen chromosome",
        delete_choosed_arrow:
            "In the metaphase image or in the array image，press the left mouse button to delete the chosen arrow",
        complete_wipe:
            "In the array image, circle the objects includes stain debris and other “noise” near the chromosome to erase them",
        add_tool: "Paint addition tool",
        add_one_karyo:
            "In the metaphase image, press the left mouse button and move the mouse to add an extra chromosome",
        connect_tool: "Armlinker",
        connect_to_one_karyo:
            "In the metaphase image, press the left mouse button from one chromosome to another to link the neighboring chromosomes together",
        cutting_tool: "scribing cutting tool",
        to_cut_karyo:
            "In the metaphase image or in the array image，press the right mouse button and move the mouse to cut the chromosome.",
        cross_cutting: "Cross cutting.",
        cross_cut_karyo:
            "In the metaphase image，choose the chromosome, when the cross line appear, then press the right mouse button to cut.",
        edge_cutting_tool: "Edge cutting tool",
        cut_choosed_karyo:
            "In the metaphase image or in the array image，press the left mouse button and move the mouse to cut the chosen chromosome.",
        rotating_karyo: "Chromosome rotation",
        rotate_karyos: "In the array image，chromosome rotation",
        click_to_count: "Click the chromosome to add the count number"
    },

    report: {
        print_report: "Print Report",
        edit_report: "Edit Report",
        delete_report: "Delete report",
        addnew_report: "Add new report",
        cannot_empty: "Cannot be empty",
        input_content: "Please input content",
        choose_date: "Select date",
        image: "Image",
        karyoImage_upload_fully:
            "Please upload the chromosome image completely",
        arrayImage_upload_fully: "Please upload the array image completely",
        add_success: "Uploaded successfully",
        modify_success: "Modified successfully",
        template_review: "Template preview"
    },

    personal_setup: {
        title: "Personal Settings",
        personal_info: "Personal Information",
        account_info: "Account information",
        my_identity: "Verifivation",
        work_setting: "Work Settings",
        account_manage: "Wallet",
        income_expenses_details: "Gain recording",
        report_template: "Report Template",
        optimize_setup: "Optimization setting",
        member_manage: "Member management"
    },

    personal_info: {
        user_name: "User ID:",
        real_name: "Name:",
        password: "Password:",
        not_setup: "Not set up yet",
        setted_up: "Set",
        input_oldpsw: "Please enter the old password",
        input_newpsw: "Please enter a new password",
        reinput_newpsw: "Please re-enter the new password",
        bind_tel: "Phone number:",
        input_tel: "Phone number",
        remind:
            "Note：Once the phone is registered and if the user modifies the phone number the login username will also be update which cannot be modified again within half a year. Please enter the number carefully!",
        bind_email: "Email:",
        input_emial: "Please enter your email",
        edit: "Edit",
        foldup: "Fold up",
        no_bind: "Not registered",
        bind: "Registered",
        name_demand:
            "Must and only contain at least two between alphabets, numbers, and underscore. Should be between 6 and 25 characters",
        input_correct_tel: "Please enter the correct phone number",
        input_correct_email: "Please enter the correct email",
        name_updated: "Name has been updated",
        password_updated: "Password has been updated",
        tel_updated: "Phone number has been updated",
        email_updated: "Email has been updated",
        retry: "s to resend"
    },

    my_identity: {
        expert_approve: "Expert Verification",
        organ_approve: "Organization Verification",
        finish_expert_identity_verify: "Identity verification completed",
        input_name: "Please enter your name",
        input_organName: "Please enter the organization name"
    },

    expert_approve: {
        name: "Name:",
        input_name: "Please enter your name",
        the_unit: "Organization:",
        the_office: "Department:",
        input_office_name: "Please enter the name of your department",
        office_tel: "Telephone:",
        input_office_tel: "Please enter the phone number of your department",
        work_card: "Work badge:",
        card_demand:
            "Please make sure that the profile picture, name, hospital name and other information are clearly visible",
        medical_practice_certificate: "Physician Practicing Certificate:",
        person_photo_demand:
            "Please take a clear photo including your name, personal photo and stamp",
        photo_demand:
            "Please take a clear photo of the certificate, including your name, personal photo and stamp, certificate code, and home contract",
        license_code: "License Code:",
        input_license_code: "Please enter the physician's license code",
        doc_credentials: "Physician Credentials:",
        certification_code: "Certification Code:",
        front_back_IDcard: "Front and back of ID card:",
        input_IDcode: "Please enter your ID code",
        IDcode: "ID number:"
    },

    organize_approve: {
        organName: "Organization Name:",
        input_organName: "Please enter the full name of your organization",
        organName_demand:
            "Please enter the name of the organization as per the organization approval certificate, limited to Chinese characters, numbers or letters",
        organ_intrd: "What is an organization code certificate? ",
        officeName: "Department/Department Name:",
        input_officeName: "Please enter your office/department",
        organ_type: "Organization Type:",
        public_hospital: "Public Hospital",
        private_hospital: "Private hospital",
        theother_lab: "Third Party Medical Laboratory",
        organ_site: "Organization Address:",
        detail_site: "Detailed address",
        organ_site_demand:
            "Please fill in the address according to the organization approval certificate",
        organ_code: "Organization Code:",
        organize_code: "Organization Code",
        unify_cridet_code: "Social Credit Code",
        organName_length_demand: "From 1 to 16 characters",
        check_deal: "Please check and accept the agreement",
        upload_image_demand: "Image size cannot exceed 10MB!",
        input_certification_code:
            "Please enter the physician qualification certificate number",
        organ_code_jpg: "Example：Organization Code.jpg",
        organ_code_demand:
            "Please enter a 9-digit organization code, such as 123456789; or an 18-digit social credit number",
        organ_code_certificate: "Organization code certificate:",
        organ_code_certificate_demand:
            "Please upload the organization code or unified social credit certification documents, the organization code certificate or the original scanned copy of the three-in-one certificate (the copy needs to be stamped with the official seal). Supported file types include pdf, jpg, png, jpeg. You can upload multiple times, and the last one will be saved (the file size can not exceed 1M) ",
        medc_inst_licensNum:
            "Medical institution practice license registration number:",
        medc_inst_licens_nums:
            "Medical institution practice license registration number",
        medc_inst_licens_jpg:
            "Example: Medical institution practice license.jpg",
        input_licensNum:
            "Please enter the 22-digit medical institution practice license registration number, limited to numbers or alphabets",
        medc_inst_licens_colon: "Medical Institution Practicing License:",
        medc_inst_licens: "Medical Institution Practicing License",
        medc_inst_licens_demand:
            "Please upload the original scanned copy of the medical institution's practice license (the copy needs to be stamped with the official seal). Support file type include pdf, jpg, png, jpeg. You can upload multiple times, and the last file be saved (the file size can not exceed 1M)",
        complete_inst_licens: "Complete Institutional Identity Verification",
        office_name: "Department Name:",
        medc_inst_licens_document:
            "Medical institution practice license certificate:",
        no_organ_code_licens:
            "If there is no 'Organization Code Certificate', you can use 'Institution legal Person Certificate' with three certificates in one or five certificates in one. You can upload the document besed on the information from the certificate."
    },

    work_setting: {
        analysis_case_num: "Analysis Case Number",
        analysis_case_cycle: "Analyzed cases",
        enable_cases_allot: "Whether to enable case allocation",
        auto_cases_allot: "Whether to automatically allocate cases",
        opened: "Opened",
        closed: "Closed"
    },

    account_manage: {
        failure_payment:"failure_payment",
        Retry:"Retry",
        Return_to_the_system:"seconds after will automatically return",
        Payment_success:"Payment success",
        Payment_success_title:"Expected to 2 hours to the account. you can contact customer service if overtime ",
        Return:"Return",
        Cancel_payment:"Cancel payment",
        complete_payment:"Please scan QR code to complete payment",
        account_balance: "Balance",
        all_money: "Total Amount (Yuan)",
        account_manage: "Account Management",
        Alipay: "Alipay",
        Wechat: "WeChat",
        bankcard: "Bank Card",
        back: "Return",
        account_recharge: "Account recharge",
        pay_width_Alipay: "Alipay payment",
        pay_width_Wechat: "Wechat Pay",
        recharge_in: "Top-Up",
        real_name: "Real name:",
        input_real_name: "Please enter your real name",
        Alipay_code: "Alipay account number:",
        AlipayCode_tel: "Alipay Account/Phone Number",
        input_Alipay_code: "Please enter your Alipay account number",
        input_correct_AlipayCode:
            "Please enter the correct Alipay account number",
        name_cannot_empty: "Name cannot be empty",
        add_bank_code: "Add Bank Card",
        bank_code: "Bank Card Number:",
        input_bank_code: "Please enter the bank card number",
        bank_open_site: "Bank:",
        input_bank_open_site: "Please enter the name of the issuing bank",
        input_bankCode: "Please fill in the bank card account number",
        input_correct_bankCode:
            "Please fill in the correct bank card account number",
        bank_openSite_cannot_empty: "The issuing bank cannot be empty",
        add_Wechat: "Add Wechat",
        telNum: "Phone number:",
        input_Wechat_tel: "Please fill in the phone number connected to Wechat",
        Wechat_pay_site:
            "Please go to 'Me'>'Payment'>'...' 'to enable Wechat Pay' to receive payment via WeChat”",
        as_show: "As shown in the picture：",
        agree_payIn_searchTel: "Allow to transfer money via phone number",
        input_telNum: "Please enter the phone number",
        input_correct_telNum: "Please enter the correct phone number",
        name: "Name:",
        account_last: "Account Balance:",
        paying_mode: "Payment Mode:",
        money_choose: "Choose Amount:",
        other_amount: "Other Amount",
        pay_amount: "Payment Amount:",
        account_widthdraw: "Account Withdrawal",
        gathering_mode: "Withdrawal Method:",
        withdrawAmount: "Withdraw cash amount:",
        input_withdrawAmount: "Please enter the withdrawal amount",
        creat_withdrawAcount_first: "Please create a withdrawal account first",
        withdrawAmount_moreThan_one:
            "The withdrawal amount cannot be less than 1",
        no_bind_Alipay:
            "The account is not bound to Alipay, please click to add",
        no_bind_Wechat:
            "The account is not bound to Wechat, please click to add",
        no_bind_bankcard:
            "The account was not bound to bank card, please click to add",
        goto_pay_page: "Go to the payment page",
        add_Alipay: "Add account of Alipay"
    },

    optimize_setting: {
        remind_words:
            "Reminder: The image optimization settings affect the display of all chromosome pictures, please be careful when modifying.",
        optimize_choices: "Optimization Options",
        current_setup: "This option is the current setting",
        parm_setup: "Parameter setting",
        denoising: "Noise removal",
        brightness: "Brightness",
        acuity: "Sharpness",
        radius: "Radius",
        contrast: "Contrast",
        levels: "Levels",
        effect_preview: "Effect preview",
        uploadImage: "Upload Image",
        image_alayzing: "Image analysis...",
        uploading: "Uploading..",
        save_use: "Save and use",
        reset_parm: "Reset parameters",
        introduce: "Description：",
        intrd_line1:
            "1.CSIE: Highly compatible adaptation and optimization of different types of images.",
        intrd_line2:
            "2.LKIE: On the basis of general optimization settings, this option is better to optimize the image taken by the 100x objective lens and retains the highest degree of detail.",
        intrd_line3:
            "3.MCIE: Suitable for the optimization of low-definition images, and at the same time better supports the images taken by the 100x objective lens.",
        intrd_line4:
            "4.TAIE: Suitable for karyotype images with poor specimen quality and excessive chromosome dissolution.",
        intrd_line5: "5.BAIE: Suitable for all samples.",
        reset: "Reset",
        attention: "Attention",
        upload_module_failed:
            "Image optimization module failed to load. Preview of image optimization results is not available. Please try again after refreshing!"
    },

    income_expenses_details: {
        begin_end_time: "Start and end time",
        createTime: "Create Time",
        income_expense_amount: "Income and Expense Amount (Yuan)",
        record_code: "Record Number",
        income_expense_mode: "Income and Expense Type",
        no_record: "No record is currently available"
    },

    report_template: {
        report_template: "Report Template",
        addnew_report: "New Template",
        recommend_template: "Recommended template",
        use_this_template: "Use the template",
        preview_image: "preview image",
        no_cases: "There is currently no case , do you want to add a case?"
    },

    selfDefined_template: {
        official_control: "Official Control",
        add_selfcontrol: "Add custom control",
        designation: "Name",
        label: "Label",
        date: "Date",
        select_box: "Select Box",
        lable_words_box: "Label + Text Field",
        title_box: "Title Class Box",
        words_box: "Text Field",
        image: "Image",
        "e-signature": "E-signature",
        border: "Border",
        line: "Horizontal line",
        words: "Text",
        font: "Font",
        size: "Size",
        thickness: "Thickness",
        color: "Color",
        site: "Location",
        up: "Up",
        left: "Left",
        width: "Width",
        height: "Height",
        condition_choose: "Select Condition",
        change_size: "Adjust size",
        move: "Can be moved",
        show_border: "Show border",
        set_required: "Set as required",
        date_mode: "Date Format",
        select_content: "drop-down box content",
        input_content: "Please input content",
        add: "Add",
        special_choose: "Special options",
        metaphase_image: "Metaphase image",
        relation: "Relation",
        num_arrayImage: "Number array image",
        num_karyoImage: "Number Karyotype image",
        input_words: "Please enter text",
        saving: "Saving...",
        already_exit_code: "This code already exists",
        image_upload_demand:
            "The image name cannot contain characters other than Chinese characters, numbers, letters and ()()_-",
        image_size_demand:
            "The format of the uploaded image is incorrect or the size of the uploaded image exceeds 10MB!",
        template_name_demand:
            "The template name cannot contain characters other than Chinese characters, numbers, letters and ()()_-",
        input_template_name: "Please enter the template name",
        personal_setup: "Personal Settings Page",
        create_template: "Create template",
        save_success: "Saved successfully",
        templateName_repeat:
            "The template name already exists, please re-enter",
        need_toSave: "Do you need to save the file?",
        dotted_line: "Set dotted line",
        upload_esignature: "Upload E-signature",
        selfdefined_template: "Custom Template"
    },
    template_choices: {
        SimSun: "SimSun",
        SimHei: "SimHei",
        Microsoft_YaHei: "YaHei",
        font_one: "26",
        font_smallone: "24",
        font_two: "22",
        font_smalltwo: "18",
        font_three: "16",
        font_smallthree: "15",
        font_four: "14",
        font_smallfour: "12",
        font_five: "10.5",
        font_smallfive: "9",
        font_slender: "slim",
        font_normal: "normal",
        font_bold: "bold"
    },
    unit: {
        piece: "",
        ge: "",
        money: "$",
        yuan: "",
        num: "",
        seconds: "s"
    },

    radio_button: {
        all: "All",
        no_analysis: "Without analysis",
        auto_analysis: "Automatic analysis",
        manual_confirm_count: "Manual count confirmation",
        manual_confirm_array: "Manual array confirmation",
        one_day: "One day",
        aWeek: "One week"
    },

    checkbox_button: {
        all: "Select all",
        need_recheck: "Need to recheck",
        agreeto_observe: "I have read and accept the ",
        privacy_statement: " Privacy Policy",
        service_agreement: "Terms of Use",
        and: " and ",
        hide_karyos: "Hidden sex chromosomes"
    },

    select_button: {
        all: "All",
        choose: "Please select",
        choose_site: "Select Province/City/District",
        startDate: "Start Date",
        endDate: "End Date",
        alloted: "Allocated",
        no_allot: "Unallocated",
        lately_7days: "Last 7days",
        lately_30days: "Last 30days",
        lately_1year: "Last Year",
        income: "Income",
        expenditure: "Expenditure",
        Alipay: "Alipay",
        Wechat: "WeChat",
        income_consultion: "Consultation income",
        widthdraw_toAlipay: "Withdraw cash to Alipay",
        widthdraw_toWechat: "Withdraw cash to Wechat",
        widthdraw_toPaypal: "Withdraw cash to paypa",
        widthdraw_toBankCard: "Withdraw cash to bank card"
    },

    popup: {
        add_analysis: {
            title: "Additional Analysis",
            content: "pictures, do you want to continue?",
            cancel_add: "Cancel",
            contentNext:
                "pictures（the quality of karyotype image is too low for further analysis），do you want to continue？",
            contentAnalysis:
                "the quality of karyotype image is too low for further analysis,do you want to continue？"
        },

        case_allot: {
            title: "Case Assignment",
            filterby_uploadPerson: "Filter by up-loader",
            filterby_status: "Filter by status",
            filterby_uploadTime: "Filter by upload time",
            search: "Search",
            manual_assign: "Manual Assignment",
            case_code: "Case Code",
            operator: "Operator",
            uploadDate: "Upload Date",
            assigner: "Assigner",
            status: "Status",
            alloted: "Assigned",
            allocation_date: "Assigned date",
            operate: "Operation",
            allot_case: "Assign case"
        },

        allot_case: {
            title: "Please select a user",
            choose_user: "New report"
        },

        addnew_report: {
            title: "New report",
            choose_report_template: "Report template selection",
            edit_title: "Edit report"
        },

        editAcct_remind: {
            title:
                "You are about to modify the account, do you wish to continue?",
            no_more_than_20: "The amount entered cannot exceed 20 digits"
        },

        edit_setting: {
            title: "Work Settings",
            analysis_case_num:
                "The number of cases that can be analyzed per day by a single person:",
            input_case_num:
                "Please enter the number of cases that can be analyzed",
            analysis_case_cycle: "Analysis case cycle:",
            enable_cases_allot: "Do you wish to enable manual case assignment:",
            auto_cases_allot: "Do you wish to automatically assign cases:"
        },

        examine: {
            consult_ques_desc: "Consultation problem description",
            ques_desc: "Problem description:",
            ques_desc_words: "Please enter the problem description text",
            consult_answer: "expert reply：",
            expert_answer_words: "Expert consultation answer",
            input_ques_desc_words: "Please enter the problem description",
            input_words_demand: "Please enter 10 to 140 characters",
            input_expert_answer_words:
                "Please enter the expert consultation answer",
            pass: "Pass",
            submit: "Submit",
            submit_evaluate: "Submit Evaluation",

            choose_expert: "choose Expert",
            show_case: "Display case information：",
            yes: "yes",
            no: "no",
            problem_describe: "Describe the problem：",
            expert_reply: "expert reply：",
            problem_cannot_empty: "Content can't be empty",
            content_cannot_empty: "Content can't be empty",
            file_type_error:
                "The file format is incorrect or exceeds 10 MB. Please upload the file again!"
        },

        edge_cutting: {
            title: "Chromosome Cutting",
            generate_image: "Generate Image",
            reload: "Reload",
            undo: "Undo",
            operation_tip: "Operation tip：",
            press_toMove:
                "Press the left mouse button to move or drag and drop the view",
            mouseWheel_toZoom: "Scroll theMouse wheel to zoom view",
            rightClick_toCut:
                "Press the right mouse button to draw a line to cut the Chromosome",
            leftClick_toChoose:
                "Select the picture after cutting the chromosome by left mouse click",
            cut_first: "Please mark first for cutting",
            choose_karyo: "Please click on selected chromosome",
            add6_atMost: "A maximum of 6 can be added",
            cut_failed: "Cutting failed, please reload to continue"
        },

        old_report: {
            addnew_report: "Add new report",
            organ_name: "Organization Name:",
            input_organ_name: "Please enter the organization name",
            templateName_choose: "Report template selection:",
            name: "Name:",
            input_name: "Please enter patient name",
            age: "age:",
            input_age: "Please input age",
            sex: "Sex:",
            man: "Male",
            female: "Female",
            sample_type: "Sample Type:",
            karyo_checked_result: "Chromosome analysis result:",
            input_checked_result: "Please enter the chromosome analysis result",
            sample_collect_date: "Specimen collection date:",
            collect_date_choose:
                "Please select the date of specimen collection",
            sample_receive_date: "Specimen receiving date:",
            receive_date_choose: "Please select the specimen receipt date",
            inspect_doc: "Inspecting doctor:",
            input_inspect_doc: "Please enter the inspection doctor",
            clinic_desc: "Clinical description:",
            input_clinic_desc: "Please enter a clinical description",
            karyo_image_choose: "Karyotype image selection:",
            choose_karyo_image: "Select karyotype image",
            image_uploading: "Uploading Image",
            please_choose_karyoImage: "Please select a karyotype image",
            blood: "Peripheral blood",
            amniotic_fluid: "Amniotic fluid",
            villus: "Fluff",
            marrow: "Bone Marrow"
        },

        change_karyo: {
            title: "Switch karyotype image",
            content: "The page has been modified, do you want to save it?",
            changing: "Switching",
            arraying: "Arranging",
            auto_array: "Automatic arrangement of chromosomes",
            confirm_counter:
                "Are you sure you want to confirm the chromosome count?",
            confirm_arrange:
                "Are you sure you want to confirm the chromosome count + arrangement?"
        },

        warning: {
            remind: "Remind",
            waitedKaryos_cannotTo_beCompared:
                "The chromosomes to be arranged cannot be compared",
            longtime_remind:
                "Since you haven't operated for a long time, to ensure safety, please login again!",
            analyzing_try_later:
                "An existing user is analyzing the current case, please try again later!",
            move_karyo: "Move chromosome",
            connect_karyo: "Connect chromosomes",
            add_count_dot: "Add count dot",
            delete_count_dot: "Delete count dot",
            karyo_cutting: "Chromosome Cutting",
            karyo_recmd_cutting: "Recommended chromosome cutting",
            delete_karyo: "Delete Chromosome",
            arrow_mark: "Arrow mark",
            eraser_tool: "Eraser",
            area_wipe: "Wipe Area",
            painting: "Painting",
            rotate_karyo: "Chromosome rotation",
            rotate_level_karyo: "Chromosome horizontal flip",
            rotate_vertical_karyo: "Chromosome vertical flip",
            reloading_data: "Reloading data",
            upload_failed: "Upload failed, please upload again",
            no_right_warning:
                "You do not have permission. Please try again after the administrator gives permission",
            karyo_alysising_error:
                "The current case is being analyzed, please switch to the opened tool page window",
            cannot_modify:
                "This template has been added to the report and cannot be modified",
            cannot_delete:
                "This template has already been added to the report and cannot be deleted",
            continue:
                "This operation will permanently delete the template, do you want to continue?",
            isDelete_report: "Do you want to delete this report?",
            add_report_remind:
                "There is currently no case template, do you want to add a template?",
            delete_cell:
                "Cannot be restored after deletion, are you sure you want to delete it?",
            karyo_alysis: "karyotype analyzed",
            karyo_no_alysis_warning:
                "This karyotype has not yet been intelligently analyzed yet. Manual analysis can only be performed after intelligent analysis. Are you sure to analyze the karyotype?",
            submit_success: "Submitted successfully",
            thanks_words:
                "Thank you for joining. Please allow us one working day to contact you",
            confirm_count: "Do you want to confirm the chromosome count?",
            confirming: "Confirming",
            confirm_success: "Confirmation successful",
            confirm_array: "Do you want to confirm chromosomal alignment?",
            saving: "Saving",
            save_success: "Save successfully",
            hide_sexKaryo: "Do you need to hide your sex chromosomes?",
            load: "load",
            need: "need",
            no_need: "No need",
            network_abnormal: "Network abnormal, please try again later!",
            off_connect:
                "You have disconnected, please check the network and try again!",
            not_save_toleave:
                "The current Settings have not been saved, are you sure to leave？",
            cannot_undo: "It can't be undone.",
            the_newest: "It's up to date."
        },

        tips: {
            currentfps: "Current fps:",
            smallestfps: "，Minimum fps:",
            bigestfps: "，Maximum fps:",
            averagefps: "，Average fps:",
            input_confirm: "Enter remarks and confirm"
        }
    },

    btns: {
        cancel: "No",
        confirm: "Yes",
        submit: "Submit",
        bind: "Saved",
        edit_setting: "Edit settings",
        save_setting: "Save Settings",
        recharge: "Top-Up",
        widthdraw: "Withdraw",
        modify: "Modify",
        preview: "Preview",
        eidt: "Edit",
        delete: "Delete",
        continue_add: "Continue to add",
        check_template: "Check existing templates",
        add_template: "Add template",
        goback: "Return",
        save: "Save",
        not_save: "No",
        confirmed_count: "Confirmed count",
        confirmed_array: "Confirmed array"
    },

    pages: {
        all: "A total of",
        counts: "  Records",
        to: "to ",
        page: "page"
    },

    connect_service_popup: {
        title: "Online Customer Service",
        welcome_words:
            "Hello, welcome to Human Genetics Online Diagnostic Consultation Center of China"
    },
    agreement: {
        line1: "Service agreement",
        line2:
            "Thank you for choosing Hangzhou Deshi Biological Technology Co., Ltd. The service agreement of the Human Genetics Online Diagnostic Consultation Center (hereinafter referred to as 'This Agreement') is signed by Hangzhou Deshi Biotechnology Co., Ltd. (hereinafter referred to as 'we') and the user (hereinafter referred to as 'you').",
        line3:
            "This agreement applies to the account registration, use and payment related matters of the Human Genetics Online Diagnostic Consultation Center, and constitutes a legal document which constraints both parties. ",
        line4: "1.Service agreement confirmation and acceptance",
        line5:
            "1.1 Before accepting our services, please read carefully and fully understand all terms of this agreement, especially the terms of exemption or limitation of liability. If you use the Human Genetics Online Diagnostic Consultation Center (including but not limited to account registration, login, use and payment of all the functions and services of Human Genetics Online Diagnostic Consultation Center, etc.), your use behavior will be considered as acknowledgement, approval and agreement to abide by the entire content of this service agreement.",
        line6:
            "1.2 If we modify this agreement, the changes will be announced through the website where the software is located, and the modified agreement will become effective 30 days after the date of announcement. If you do not agree to the content of the changed agreement, you have the right to stop using this service. If you continue to use it, it is deemed to be an agreement to the modification.",
        line7:
            "1.3 If you are a person with no capacity for civil conduct or a person with limited capacity for civil conduct, please inform your guardian, then read this agreement and use our services under the guidance of your guardian. If you are not a user in the People’s Republic of China, you also need to abide by the laws of your country or region at the same time, and you confirm that entering into and fulfilling this agreement does not violate the laws and regulations of the country or region where you belong and live. If you accept this agreement without verifying if you violate the laws and regulations of the country or region where you belong and live, you will be fully responsible for related legal liabilities. ",
        line8: "2.Definition",
        line9:
            "2.1 User: Individuals or organizations that register, log in, and use the Human Genetics Online Diagnostic Consultation Center",
        line10:
            "2.2 Account: The account obtained by duly completing the registration on the Human Genetics Online Diagnostic Consultation Center",
        line11:
            "2.3 Identity information: Elements that we use for identification purpose, including but not limited to user name, password, phone number, email, official name, user from PRC or not, organization number, organization name, organization phone number, organization address, contact name, phone number of contact person, QQ account of contact person, corporate email account.",
        line12:
            "2.4 User information: Any file, image, documentation, or similar document submitted or uploaded by an individual/organization, as well as user-specific output generated by using the product based on the user's own original data or information.",
        line13:
            "3.Services provided by Human Genetics Online Diagnostic Consultation Center",
        line14:
            "The Human Genetics Online Diagnostic Consultation Center service provides you the automatic analysis of chromosome pictures. Specifically, it refers to the manual operation of the chromosome by genetic experts in accordance with the requirements of the Human Cell Genomics International Nomenclature System (ISCN2016). The pictures of metaphase cleavage will undergo preliminary automatic analysis first, and then further manual auxiliary operations by using the corresponding tools provided by this service, to perform cutting, moving and arranging operations on the chromosomes. Through this series of human-computer interaction operations, the chromosome pictures are finally arranged into a chromosome karyotype arrangement diagram that meets the requirements of the above-mentioned international naming system ISCN2016. Kindly note that the final arrangement of chromosomes needs to be evaluated by related professionals, and this service does not automatically issue a diagnosis report. ",
        line15: "4.Account registration, use and cancellation",
        line16:
            "4.1 Registration: When you register an account, you must provide authentic, accurate, up-to-date and complete personal information according to the requirements on the page, including but not limited to your user name, password, phone number, email, official name, user from PRC or not, organization number, organization name, organization phone number, organization address, contact name, phone number of contact person, QQ account of contact person, corporate email account, organization profile, website. Any issue caused by untrue registration information (including but not limited to registration failure, the inability to retrieve the password, and the suspension or termination of any other services) shall be borne by you. If any registration information you provide is untrue, inaccurate, not up-to-date or incomplete, or we have reasonable grounds to suspect that such information is untrue, incorrect, not up-to-date or incomplete, we will have the right to suspend or terminate your account. ",
        line17:
            "4.2 Use: Identity information is essential for identification purpose, please make sure to keep it properly. Operations and instructions using your identity information are deemed your own wishes. If the account, password, and other information is fraudulently or illegally used or stolen due to your own reasons, the risks and losses caused by this shall be borne by you.",
        line18:
            "4.3 Cancellation: When you need to terminate the use of this service, you can apply for cancellation of your account if you meet the following conditions. ",
        line19:
            "ⅰ.You can only apply for cancellation of your own/organization's account and follow our cancelling procedures.",
        line20:
            "ⅱ.The account which you are applying for cancellation is under a normal state, which means, that your account does not have unsettled rights and obligations or other situations that may cause disputes due to cancellation, and that there is no unsettled transaction. ",
        line21:
            "ⅲ.After the account is cancelled, you will not be able to use our Human Genetics Online Diagnostic Consultation Center, the rights and obligations of both parties will be terminated, and the following results may also occur: ",
        line22:
            "（1）All rights and interests associated with the account will be void；",
        line23:
            "（2）If you have breached the contract, infringed, or there are other improper behaviors before canceling your account, you should still bear the corresponding responsibility.",
        line24:
            "（3）Once the cancellation is successful, account records, functions, etc. cannot be restored or provided. Kindly refer to 'Privacy Statement' for more detailed information regarding the handling of user cases, chromosome karyotype images, analysis results and other records involved in the account.",
        line25: "5.Account rights and interests",
        line26:
            "Your account is designed to provide you with a location where you can access and manage your account information and use the Human Genetics Online Diagnostic Consultation Center services.",
        line27: "6.Privacy",
        line28:
            "We are committed to protecting your privacy and ensuring that you are informed about how we handle user information. Our privacy statement sets out how we collect, use, store and process your information, information concerning you, and how you can request deletion of your information. Please confirm that you have carefully read and understood this privacy statement.",
        line29:
            "7.Instructions for using the Human Genetics Online Diagnostic Consultation Center",
        line30:
            "7.1 Kindly be informed that the purpose of the Human Genetics Online Diagnostic Consultation Center is to provide the public with a service for the automatic analysis of chromosome images based on the International Nomenclature System of Human Cell Genomics (ISCN2016). The automatic analysis results do not represent the final chromosome arrangement and need to be professionally evaluated; the diagnosis report should be issued by professionals too. Please note that the Human Genetics Online Diagnostic Consultation Center is only a tool, its purpose is only to provide you with assistance when you perform activities such as segmentation, cutting, and arrangement of chromosomes, and cannot be used to replace your professional judgment.",
        line31:
            "7.2 Kindly be informed that all user information (including but not limited to user name, password, phone number, email, official name, user from PRC or not, organization number, organization name, organization phone number, organization address, payment information, manually imported cases, original chromosome karyotype picture, chromosome karyotype arrangement diagram, system-derived case report) will be stored in the database of Hangzhou Deshi Biotechnology Co., Ltd. and will be processed in accordance with the 'Privacy Statement'",
        line32: "8.Ordering",
        line33:
            "At this stage, the sales channels of the Human Genetics Online Diagnostic Consultation Center include the company's official website (https://www.autovision.online) and authorized sales channels. If the purchase is through unauthorized channels, we cannot guarantee the provision of this service. If invalid products are purchased through other channels, we have the right to refuse to provide services and not participate in product transaction.",
        line34:
            "9.User commitment of legitimate use of Human Genetics Online Diagnostic Consultation Center",
        line35:
            "You should only access and use (and allow access and use) the Human Genetics Online Diagnostic Consultation Center in accordance with all applicable laws. Except for expressly authorized by this agreement or expressly allowed by Hangzhou Deshi Biotechnology Co., Ltd. otherwise in writing, you must not:",
        line36:
            "ⅰ.Sell, rent, lend or otherwise provide all or any part of the online Human Genetics Online Diagnostic Consultation Center (including any functions and services of the product) to a third party;",
        line37:
            "ⅱ.Through the Internet (except those provided by Hangzhou Deshi Biotechnology Co., Ltd. through the network), any wide area network (WAN) or any other non-local network; any virtual private network (VPN); or any application virtual technology, remote virtual technology, virtual host, Time-sharing, software as a service(SaaS), platform as a service(PaaS), infrastructure as a service(Iaas), cloud or other network-based, virtual host-based or similar services use the Human Genetic Online Diagnostic Consultation Center.",
        line38: "In addition, you must not",
        line39:
            "ⅰ.Notice of removal of any copyright, trademark, confidentiality, or other proprietary rights from the Human Genetics Online Diagnostic Consultation Center",
        line40:
            "ⅱ.Crack our systems and programs by using reverse engineering methods, copy, modify, compile, integrate or tamper with the above systems and programs, destroy our systems and programs through cyber-attacks or other hacking techniques, modify, increase or decrease the functions of the system.",
        line41:
            "ⅲ.Provide any of the following information or material in any form (including but not limited to uploading, sending emails, and others) or other methods",
        line42:
            "(1)False, defamatory, fraudulent, or otherwise illegal or infringing.",
        line43:
            "(2)Threatening, harassing, insulting, abominable or intimidating, or otherwise disrespecting the rights and dignity of others.",
        line44: "(3)Obscene, indecent, pornographic, or otherwise revolting.",
        line45:
            "(4)Infringe any content protected by copyright, trademark, design rights, trade secret rights, public or proprietary rights, or any other proprietary rights of Hangzhou Deshi Biotechnology Co., Ltd. or any other related party.",
        line46:
            "(5)Security code, countersignature, encryption currency, password, or other similar information.",
        line47:
            "(6)Advertising, spam, offers to buy or sell any goods or services, 'chain letters' or any other form of solicitation.",
        line48:
            "(7)Any malicious software (such as viruses, worms, Trojan horses, Easter eggs, time bombs, or spyware) which is or may be harmful or offensive, or designed to disrupt or attack any hardware, software or operation of equipment, to restrict its use or to monitor other computer codes, documentations or programs used where it is run.",
        line49:
            "ⅰ.Use the Human Genetic Online Diagnostic Consultation Center in any way that is fraudulent, infringing, or illegal or has similar purpose or effect.",
        line50:
            "ⅱ.Interfering, attacking, or destroying any part of the product, the server, or the network used for the operation of the product.",
        line51:
            "ⅲ.Attempt to detect, scan or test the weakness of the product or violate or circumvent any safety or certification measures used in the product.",
        line52:
            "ⅳ.Impersonate any other individual or entity, misrepresent or otherwise falsely claim that you are associated to any subject or entity.",
        line53:
            "ⅴ.Use automated means (any robot, web crawler, website search/retrieval application or other retrieval, indexing, 'crawling' or 'data mining' device) to collect content or information from or with products.",
        line54: "10.Liability statement",
        line55:
            "10.1 The ownership of scientific research results (including but not limited to papers, monographs, new products, new technologies, patents) based on the chromosome picture information provided by the users informed and consent belongs to Hangzhou Deshi Biotechnology Co., Ltd. and has nothing to do with the user.",
        line56:
            "10.2 If you violate the terms of this agreement, we will also have the right to refuse to provide you with any form of service at the current stage or in the future, and you will also be responsible for the labor and expenses that we have put, or any loss caused.",
        line57:
            "10.3 You are obliged to keep the information registered on this website confidential. If you intentionally leak your account information and it causes losses to you or us, we do not bear any responsibility and have the right to request corresponding compensation.",
        line58:
            "10.4 If we are unable to provide our services properly due to any of the following reasons, we are exempt from responsibility:",
        line59: "(1)Our system is down for maintenance or upgrade.",
        line60:
            "(2)Due to force majeure such as typhoon, earthquake, flood, lightning, or terrorist attack.",
        line61:
            "(3)Your computer's software or hardware, communication wires, and power supply lines are malfunctioning.",
        line62:
            "(4)Due to your improper operation or using our services in a manner which is not authorized or approved by us.",
        line63:
            "(5)Due to viruses, Trojan horses, malicious program attacks, network congestion, system instability, system or equipment failures, communication failures, power failures, bank reasons, third-party service defects or government actions.",
        line64:
            "Despite the agreement in the preceding paragraph, we will take reasonable actions to actively promote the services back to normal.",
        line65: "11.Applicable law and permission",
        line66:
            "The validity, interpretation, modification, execution, and dispute resolution of this agreement shall be governed by the laws of the People's Republic of China. Disputes arising from this agreement shall be handled in accordance with the laws of the People's Republic of China and shall be under the jurisdiction of the people's court where Hangzhou Deshi Biotechnology Co., Ltd. is located."
    },
    statement: {
        line1: "Privacy statement",
        line2:
            "This statement applies to the use of the Human Genetic Online Diagnostic Consultation Center service ('the service')",
        line3:
            "This statement clarifies how we collect, use, and process your user information. Kindly read this statement carefully and understand it thoroughly. Only use this service after confirming your full understanding and consent. Once you use this service, it means that you agree to our collection, use and processing of your relevant information in accordance with this statement. If you do not agree with all or any part of this statement, you should not use this service.",
        line4: "This statement will help you understand the following content.",
        line5: "1.Definition",
        line6: "2.Our relationship with you",
        line7: "3.The information that we collect",
        line9: "4.Why we collect information",
        line10: "5.How we use your information",
        line11: "6.Information we share with third parties",
        line12: "7.How we store your information",
        line13: "8.Your choices",
        line14: "9.Data protection measures",
        line15: "10.Children's privacy",
        line16: "11.International data transmission",
        line17: "12.Privacy statement changes",
        line18: "13.Contact us",
        line19: "1.Definition",
        line20:
            "Original chromosome karyotype picture: the picture of the metaphase chromosome uploaded by the user.",
        line21:
            "Chromosome arrangement diagram: the chromosome picture that has been manually operated and confirmed by the user and has been arranged to meet the requirements of the International Nomenclature System of Human Cell Genomics (ISCN2016).",
        line22: "2.Our relationship with you",
        line23:
            "Hangzhou Deshi Biotechnology Co., Ltd. is the controller of your information described in this privacy statement.",
        line24: "3.Information we collect",
        line25:
            "When you use our services, such as creating an account, ordering or using cell karyotype analysis services, we will collect the following information:",
        line26: "(1)Information you provide directly to us",
        line27:
            "When you create an account or purchase our services, we will collect personal information, which may include your user name, password, phone number, email, official name, user from PRC or not, organization code certificate, medical institution practice license , institution legal person certificate, contact name, the phone number of the contact, the QQ account of the contact, the corporate email account, the introduction of the institution, the website address, and the payment information.",
        line28:
            "(2)Information related to our cell chromosome karyotype analysis service",
        line29:
            "To use our cell chromosome karyotype analysis service, you must first import the chromosome picture to be analyzed, complete the preliminary automatic analysis through our service, and then perform the final confirmation work based on your professional knowledge to obtain the final chromosome karyotype analysis result. Relevant information generated by this service includes but is not limited to imported cases, original karyotype pictures, karyotype permutations, and system-derived case reports.",
        line30:
            "(3)Web behavior information collected through tracking technologies (such as cookies and similar technologies), including but not limited to unique identifiers, user preferences, personal configuration information, and general and bulk statistical information.",
        line31: "(4)Other types of information",
        line32:
            "We will constantly improve our services with new features, which may result in the collection of new and different types of information. We will update the privacy statement and/or obtain your prior consent for the new types of processing as needed.",
        line33: "4.Why we collect information",
        line34:
            "We will collect and use your information for the following purposes.",
        line35: "(1)Provide our services",
        line36:
            "We process information to provide our services, such as user registration, user login, payment processing, automatic chromosome analysis services, etc.",
        line37: "(2)Maintain and improve our services",
        line38:
            "We continue to improve and provide better services while maintaining the normal provision of services. For example, we have been working hard to study chromosome automatic analysis algorithms and other related content, and to maximize the efficiency of chromosome automatic analysis. We will also improve the user experience based on user's usage and feedback of the website.",
        line39: "(3)Scientific research by Deshi Biology",
        line40:
            "If you agree to participate in our scientific research, our researchers can include your case information which have undergone data desensitization in a large amount of user data to conduct related research on automatic chromosome analysis.",
        line41: "5.How we use your information",
        line42:
            "We only use and share your information in the ways described in this privacy statement.",
        line43: "(1)Provision of our services",
        line44:
            "We will use your information to provide services in various ways, including: ",
        line45:
            "Ⅰ.We will use the username, password, phone number, email and other information you directly provide us to confirm your identity and complete the user registration and user login operations.",
        line46:
            "Ⅱ.We will use the email and/or phone number associated with your account to communicate with you about your account, your questions, and any relevant information regarding our services.",
        line47:
            "Ⅲ.We will use your information to implement our terms of service, such as ordering services, karyotype analysis services.",
        line48: "(2)Maintain and improve our services",
        line49:
            "We will use your information to ensure the proper operation of the Human Genetic Online Diagnostic Consultation Center services, and at the same time, we will also use your information to improve our services. These methods include:",
        line50:
            "Ⅰ.We will continue to monitor our system to find problems in time. If we find prohibited or illegal behavior, we will take measures to fight against such behavior, in order to protect the rights of users and ours.",
        line51:
            "Ⅱ.We will use the original chromosome pictures and chromosome karyotypes that have been desensitized for data training and analysis to improve the efficiency of our automatic chromosome analysis.",
        line52: "(3)Scientific research by Deshi Biology",
        line53:
            "You can choose to agree to participate in the scientific research of Deshi Biology. The scientific research described by Deshi Biology refers to research aimed at publishing in peer-reviewed journals and other research funded by the Chinese government conducted by Deshi.",
        line54:
            "Only when you agree by filling in the consent document, can you use the chromosome picture information you uploaded for scientific research. The case you uploaded will undergo data desensitization, that is, remove all sensitive unique identification information of the case, which may include the patient’s name, address, city, district, zip code, date, telephone, fax, email, social security number, and medical history number, and other unique identification numbers, then unique IDs are generated by the system to identify different cases. We use anonymization processing, therefore, the case information you upload will not be linked to your registration information.",
        line55:
            "You can withdraw your consent to participate in Deshi scientific research at any time by contacting us to change your consent status. However, the chromosome picture information that you previously provided and agreed to be used in the research of Deshi Biology cannot be deleted from the completed research using this information. Your data will not be included in studies that start more than 30 days after the account is closed (it may take up to 30 days after the account is closed to withdraw your information).",
        line56:
            "For users located in the EEA region: The legal basis for processing your sensitive information for the above-mentioned purposes is your consent. You can withdraw your consent at any time, but the withdrawal of consent will not affect the legality of processing based on consent before the withdrawal.",
        line57: "Note: Desensitization of case information",
        line58:
            "Remove all sensitive identification information of the case, which may include the patient's name, address, city, district, zip code, date, telephone, fax, email, social security number, medical record number and other unique identification numbers, then generate unique IDs by the system to identify different cases.",
        line59: "6.Information we share with third parties",
        line60: "(1)You share your information",
        line61:
            "Kindly think about it before sharing, once you choose to share the information you own, other individuals with whom you share this information may also use or share your information, including any sensitive information you choose to share.",
        line62: "(2)Deshi shares your information",
        line63:
            "For any information collected about you, Deshi Biotech will take security measures and will not share your information with any company, organization or individual, except in the following cases:",
        line64: "Ⅰ.Obtain your explicit consent or authorization in advance.",
        line65:
            "Ⅱ.Only by sharing your information can we realize the core functions of our services or provide the services you need, such as cloud storage service providers.",
        line66:
            "Ⅲ.Use based on academic research within the scope of your informed consent.",
        line67:
            "Ⅳ.In cases where your personal information must be provided in accordance with the requirements of laws, regulations, compulsory administrative law enforcement or judicial requirements, we may publicly disclose your personal information based on the type of personal information required and the method of disclosure. Under the premise of complying with laws and regulations, when we receive the above-mentioned request for information disclosure, we will require the corresponding legal documents to be issued, such as subpoenas or investigation letters. We firmly believe that the information requested from us should be as transparent as possible to the extent permitted by law. We have carefully reviewed all requests to ensure that they have legal basis and are limited to data obtained by law enforcement agencies for specific investigation purposes and with legal rights to obtain the requested information. ",
        line68:
            "Ⅴ.When it comes to mergers, acquisitions, asset transfers or similar transactions, if it involves the transfer of personal information, we will require the new company or organization that holds your personal information to continue to be bound by this privacy policy, otherwise, we will require the company or organization to ask for your authorization again.",
        line69: "Ⅵ.Otherwise agreed in this statement.",
        line70: "7.How we store your information",
        line71:
            "The data we collect has different retention periods, depending on what the data is, how we use the data, and your choices:",
        line72:
            "We will keep some data until you delete your account, such as information related to user login.",
        line73:
            "We will extend the retention period of certain data for legitimate business needs or legal purposes, such as to retain financial records.",
        line74:
            "We will extend the retention period of certain data for the purpose of scientific research related to automatic chromosome analysis. These data, including the case information uploaded by you and your analysis results, will first be desensitized (to protect your privacy) before being used for scientific research.",
        line75: "8.Your choices",
        line76: "(1)Access your account",
        line77:
            "You can access your account information and make reasonable requests to correct, modify or delete inaccurate information. You can correct, modify, or delete your sub-account information in 'User Management', and in 'Role Management' to correct, modify or delete your role information. Please refer to the online version of the 'User Operation Manual' for corresponding practical operations. In addition, if you still If you want to correct, modify or delete some inaccurate information, you can contact our customer service and we will do our very best to help you solve the problem.",
        line78: "(2)Data portability and processing limitations",
        line79:
            "You can effectuate your rights by contacting us to obtain your data portability or to request restrictions on processing of your data.",
        line80: "(3)Withdrawal of consent",
        line81:
            "You can contact us at any time to withdraw your consent to our processing of your data. Therefore, in the future, we may no longer agree to deal with your person based on this agreement. The withdrawal of consent has no effect on the legality of the processing based on the consent before the withdrawal.",
        line82: "(4)Objection to processing",
        line83:
            "Whenever Deshi Bio processes your information in accordance with legal rights and interests, you may always object to such processing for legal reasons related to the specific situation and context.",
        line84: "(5)Deletion of account",
        line85:
            "If you no longer wish to participate in our services, or no longer wish to have your information processed, you can choose to delete your account. After submitting the request, we will send an email to the mailbox linked to your Human Genetic Online Diagnostic Consultation Center account, detailing our account cancellation policy, and asking you to confirm the deletion request. After you confirm your request to delete your account and data, you will no longer be able to access your account. Once the request is confirmed, this process cannot be cancelled or reversed. After deleting your account, all associated personal information will be deleted, but subject to the following restrictions:",
        line86:
            "Ⅰ.Information previously contained in Deshi Biological Research. As stated in any applicable consent form, the chromosome picture information that you have previously provided and agreed to be used in Deshi Biological Research cannot be deleted from the completed research using the information. Your data will not be included in studies that start more than 30 days after the account is closed (it may take up to 30 days after the account is closed to withdraw your information).",
        line87:
            "Ⅱ.Deshi Bio will also retain limited information related to your account and data deletion request for a limited time frame as required by law, including but not limited to your email, account deletion request identifier, communications related to queries or complaints, necessary exercises for establishing, performing and defending legal claims, and information for audit and compliance purposes. ",
        line88: "(6)Right of complaint",
        line89:
            "If you deem that we have violated your rights, we suggest you contact us so that we can try to resolve your doubts and concerns, or raise an informal dispute (see Contact Us for contact information). At the same time, you have the right to lodge a complaint with the supervisory authority.",
        line90: "9.Data protection measures",
        line91:
            "Deshi Biology attaches great importance to your trust in us, and will take measures to maintain data accuracy, prevent unauthorized access or disclosure of your information, ensure the correct use of information, and protect your personal information. Our team will regularly review and improve our security practices to ensure the integrity of our systems and your information. These practices include but are not limited to the following fields:",
        line92:
            "Ensure the accuracy and integrity of user data through general industrial practices such as database, picture library backup, clustering, etc.",
        line93:
            "Ensure the authorized access of user data through user authorization login, identification, limited access, and secondary verification; we take various reasonable precautions to protect your personal information from abuse, theft, loss, unauthorized access, disclosure, tampering or damage.",
        line94:
            "For the network environment, the secure socket layer technology (SSL) is used to encrypt information transmission to ensure the security of the connection; for improving the security of data transmission, industry standard encryption such as symmetric and asymmetric encryption is used; for ciphertext preservation of the data stored in the database, symmetric encryption is used. ",
        line95:
            "The preliminary level protection policy provides an online system that complies with the Equal Guarantee 2.0 compliance assessment. We will ensure that the data you provide is safe and secured through specific physical, electronic and management procedures.",
        line96:
            "We follow generally recognized industry standards to protect the personal information you submit to us, including information in the process of transmission and information we have received. However, no Internet transmission method or electronic storage method is 100% secured and reliable. Therefore, we will try our best to use commercially reasonable means combined with development result of new technology to continuously modify security measures to protect your personal information, but we cannot guarantee absolute security. We recommend that you change your password frequently, use a password that contains a combination of uppercase and lowercase letters, numbers, and special symbols, and ensure that you use a secured browser.",
        line97: "10.Children’s privacy",
        line98:
            "Human Genetics Online Diagnostic Consultation Center is devoted to protecting children’s privacy, it is designed for genetic experts to perform chromosome karyotype analysis and not aimed at children under 18 years old. Users may obtain karyotype pictures of children under the age of 18 and analyze their karyotype pictures through this service. Users have the obligation to inform their parents or guardians of the privacy-related information involved in using this service, and users take full responsibility.",
        line99: "11.International data transmission",
        line100:
            "Data protection laws vary from country to country, consequently the control intensity differs. We will perform data transmission based on user corporate rules (internally binding, unless otherwise specified, applicable to global data transmission).",
        line101:
            "For users in the European Economic Area (EEA), the information we collect from you will be in accordance with EU Decision No. 2004/915/EC, in accordance with the European Commission’s standard contract related to the transfer of personal data to third countries (i.e. standard cooperation clauses). Your personal data processed by staff located outside of the EEA who work for us or our suppliers. Such staff are involved in (including) processing your payment information. We will take all reasonably necessary measures to ensure that your information is handled safely in accordance with this statement.",
        line102: "12.Privacy statement changes",
        line103:
            "Deshi Bio reserves the right to modify the privacy statement. We recommend that you revisit this page regularly to learn about any changes to this privacy statement. If we modify this privacy statement, we will provide the latest version of the privacy statement through our website. Whenever a major change is made to this privacy statement, we will provide you with notice before the change becomes effective, such as posting a notification on our website or sending a message to the email associated with your account.",
        line104:
            "If you continue to visit or use our services after the changes to this privacy statement take effect, that means that you agree to be bound by the revised privacy statement. If you cannot accept any change, you can stop using our services and delete your account at any time. ",
        line105: "13. Contact us",
        line106:
            "If you want to exercise your rights or if you have any questions or comments about this privacy statement, please contact us via the following methods:",
        line107:
            "Address: No. 22, Longquan Road, Cangqian Street, Yuhang District, Hangzhou",
        line108: "Phone: +86 0571-89055921",
        line109: "Fax: +86 0571-89055921",
        line110: "Email: support@diagens.com"
    },
    onlineManage: {
        login: {
            login_system: "System Login",
            login: "Login",
            input_account: "Please enter the account number",
            input_pwd: "Please enter the password",
            press_left:
                "Please press and hold the left mouse button to remove the shields",
            next_step:
                "Congratulations on successfully removing the shield. Continue to the next step",
            loading: "Loading",
            disappear: "Ah, the shield is gone",
            one_more: "One more time",
            or: "or",
            feedback: "Feedback Questions",
            wait_longtime: "I waited too long",
            not_cool: "The network is really not strong",
            scrape_tooQuickly: "You shaved too fast",
            click_start: "Click the button to start smart verification",
            verify_success: "verified successfully",
            verify_failed: "Verification failed, please click here to refresh",
            verifying: "Smart Detection In Progress",
            man_machine_check: "Please perform man-machine check",
            check_input: "Please check input",
            Chinese_online_consult_center:
                "Chinese Human Genetics Online Diagnostic Consultation Center"
        },
        user: {
            personal_setup: "Personal Settings",
            quit_account: "Logout",
            back: "Return"
        },
        accountInformationList: {
            filterBy_account: "Filter by account:",
            code: "Code",
            primary_account_name: "Primary Account Name",
            account_balance: "Account balance (RMB)",
            create_time: "Create Time",
            add_or_edit: "Add/Edit",
            package_amount: "Package amount:",
            package_code: "Package code:",
            starttime: "Start time:",
            endtime: "End time:"
        },
        accountList: {
            package_code: "package number",
            money: "Amount",
            package_type: "Package Type",
            package_describe: "package description",
            case_quality: "case quality",
            country: "Country",
            creat_time: "Creation Time",
            edit_time: "Edit time",
            package_action: "Package Action",
            amount: "Amount:",
            package_mode: "Package type:",
            chargeBy_piece: "Charge by piece",
            caseQuality: "Case Quality:",
            good: "Good",
            bad: "Poor",
            countries: "Country:",
            China: "China",
            overseas: "Overseas",
            packageDescribe: "Package description:"
        },
        accountStatistics: {
            primaryAccount_sum: "Total number of primary accounts:",
            primaryAccount_name: "Primary Account Name",
            childAccount_num: "Number of Sub Accounts",
            charge_amount: "Recharge amount (yuan)",
            account_balance: "Account balance (yuan)",
            current_account_charge: "Recharge this account",
            charge_sum: "Total recharge:",
            balance_before_charge: "Account balance before recharge (yuan)",
            balance_after_charge: "Account balance after recharge (yuan)",
            charge_type: "charge type",
            package_charge: "Package recharge",
            normal_charge: "normal recharge",
            charge_way: "Charge Way",
            charge_time: "charge time",
            child_account_charge: "Sub-account recharge",
            childAccount_total_consumption: "Total Sub-Account Consumption",
            tel_num: "Phone Number",
            user_name: "User Name",
            consumption_amount: "Consumption amount (yuan)",
            childAccount_consumption_detail: "Sub-account consumption details",
            patient: "Patient",
            karyo_image: "Karyotype image",
            charge_detail: "charge details",
            consumption_detail: "Consumption Details"
        },
        auditResultEmail: {
            code: "Code",
            title: "title",
            content: "Content",
            email_type: "Mail Type",
            send_message: "Whether to send SMS",
            isRead: "Is it read",
            create_time: "Create Time",
            email_detail: "Email Details",
            register: "Register",
            report_submit: "Report Submission",
            report_sendback: "report back",
            edit_audit_by_emailOrTel:
                "Email and phone number modification audit",
            remind_expert: "Remind experts to initiate consultation",
            expert_consultion_endInform: "Expert Consultation End Notice",
            checkResult_inform: "Notification of case review result",
            not_send: "Not send",
            sended: "Sent",
            no_read: "Unread",
            read: "Already read"
        },
        consumptionRecord: {
            searchBy_primaryAccount: "Search by primary account:",
            searchBy_time: "Search by time:",
            code: "Code",
            primaryAccount_name: "Primary Account Name",
            consumption_account: "Consumption Account",
            consumption_amount: "Consumption amount (yuan)",
            amount_before_consuming: "Amount before consumption (yuan)",
            amount_after_consuming: "Amount after consumption (yuan)",
            case_name: "Case name",
            slide_name: "Slide name",
            karyoImage_name: "Karyotype image name",
            create_time: "Create time"
        },
        customtemplate: {
            add_check_fence: "Add a review column",
            create_immediately: "Create immediately",
            Diagens_self_template: "Diagens Custom Report Template",
            result: "Result",
            check_result: "Chromosome Check Results",
            read_person: "Reader:",
            karyo_check_person: "Karyotype examiner:",
            signature_by_doc: "Doctor's signature:",
            report_date: "Report date:",
            name: "Name",
            sex: "Sex",
            age: "Age",
            tel: "Phone",
            organ_name: "Organization Name",
            sample_type: "Sample Type",
            check_way: "Check Way",
            consulting_standard: "Diagnostic Standard",
            describe_gist: "Description basis",
            report_type: "Report Type",
            add_new_module: "Add new module"
        },
        customtemplateList: {
            template_name: "Template name",
            create_date: "Create date"
        },
        dataStatistics: {
            self_defined_time_scope: "Self-defined time scope:",
            account_compare_chart: "Account Comparison Chart",
            user_num: "Number of Users",
            primary_account: "Primary Account",
            child_account: "Sub Account",
            sum_dose: "Total amount",
            account_charge_amount: "Recharge amount",
            account_balance: "Account Balance",
            consumption_amount: "Consumption Amount",
            charge_amount: "Amount charged",
            case_statistics: "case statistics",
            sum: "Total",
            checked: "Checked",
            case_good: "Case quality is good",
            bad_num: "Number of bad quality cases",
            AI_analyzed_num: "AI-analyzed number",
            AI_analyzed: "AI analyzed",
            human_account_num: "Manual count number",
            human_array: "Manual array",
            need_recheck: "Recheck required",
            report_statistics: "Report Statistics"
        },
        expertExamine: {
            account: "Account:",
            code: "Code",
            account_name: "Account Name",
            check_result: "Check result",
            image: "Image",
            remark: "Remarks"
        },
        graphStatistics: {
            primary_account: "Primary Account",
            child_account: "Sub account",
            case_name: "Case name",
            case_amount: "Total number of cases",
            belongs_case_amount: "Total number of cases assigned",
            operating_case_amount: "Total number of cases being analyzed",
            karyoImage_amount: "Total number of karyotype images",
            undisposed_karyoImage: "Unprocessed karyotype image",
            confirm_num: "Confirmed Quantity",
            confirm_array: "Confirmed array"
        },
        home: {
            welcome_to_center:
                "Welcome to China Human Genetics Online Diagnostic Consultation Center",
            permission_manage: "Permission Management"
        },
        karyotypeLog: {
            searchBy_operator_or_log: "Search by operator/log type:",
            searchBy_time: "Search by time:",
            operator: "Operator",
            create_time: "Create Time",
            log_type: "Log Type",
            user_action: "User Action",
            operation_time: "Operation time",
            operation_entrance: "Operation entry",
            operation_detail: "Operation details"
        },
        mechanismExamine: {
            account: "Account:",
            organ_code: "Organization Number",
            organ_name: "Organization Name",
            organ_tel: "Organization Phone Number",
            organ_site: "Organization Address",
            connect_name: "Contact Name",
            connect_tel: "Contact phone number",
            connect_qq: "Contact QQ account",
            company_email_code: "Enterprise Email Account",
            organ_intro: "Organization Introduction",
            organ_netsite: "Organization URL",
            check_result: "Check result"
        },
        modifyMail: {
            user_name: "User Name",
            old_tel: "Old phone number",
            new_tel: "New phone number",
            old_email: "Old Email",
            new_email: "New Email",
            image: "Image",
            remark: "Remarks",
            modify_detail: "Update Details",
            tel_before_modify: "Old phone number",
            tel_after_modify: "Updated phone number",
            email_before_modify: "Old email",
            email_after_modify: "Updated Email",
            verify_image: "Verify Image"
        },
        noticesthat: {
            add_new_information: "Add new notification",
            operator: "Operator",
            type: "Type",
            content: "Content",
            add_or_edit: "Add/Edit",
            date_setup: "Date setting:",
            inform_conten: "Notification content:",
            vindicate_inform: "Maintenance Notice"
        },

        rechargeRecord: {
            filterBy_account: "Filter by account:",
            searchBy_time: "Search by time:",
            code: "Code",
            primary_account_name: "Primary Account Name",
            charge_account_information: "Charged account information",
            charge_amount: "Charge amount (yuan)",
            charge_mode: "Charge mode",
            balance_before_charge: "Account balance before recharge (yuan)",
            balance_after_charge: "Account balance after recharge (yuan)",
            is_success: "Is it successful?",
            reason: "Reason for success or failure",
            remark: "Remarks",
            create_time: "Create time"
        },
        reviewConsultationResults: {
            user_name: "User Name",
            check_status: "Check Status",
            create_date: "Create date"
        },
        roleManager: {
            new_role: "New Role",
            create_date: "Create Date",
            role_name: "Role name",
            role_type: "Role Type",
            role_action: "Role Action",
            role: "Role",
            allot_role: "Assign Role",
            second_role: "Secondary Role",
            permission_manage: "Permission Management",
            input_role_name: "Please enter the role name",
            permission_choose: "Select permission",
            choose_second_role: "Please select a secondary role"
        },
        userSysUser: {
            new_user: "New User",
            create_date: "Create Date",
            user_name: "User Name",
            name: "Name",
            tel: "Phone",
            email: "Email",
            user_action: "User Action",
            real_name: "Real Name",
            role: "Role",
            tel_no: "Phone Number",
            verify_tel: "Phone number verification code",
            verify_email: "Email verification code",
            password: "Password",
            confirm_pwd: "Confirm password"
        },

        userUserInfo: {
            filterBy_account: "Filter by account:",
            account_type: "Account Type",
            manage: "Administrator",
            primary_account: "Primary Account",
            child_account: "Sub account",
            belong_primary_account: "Related to primary account",
            isHave_organ: "Is there an organization?",
            yes: "Yes",
            no: "No",
            isDomestic_accounts: "Is it a domestic account?"
        },
        withdrawalAudit: {
            account: "Account:",
            check_status: "Check status:",
            account_name: "Account Name",
            widthdraw_amount: "Withdrawal amount (RMB)",
            serial_num: "Serial Number",
            widthdraw_type: "Payment Type",
            account_num: "Account Number",
            user_bank_name: "Account Name",
            bank_site: "Account Opening Bank",
            check_is_passed: "Verification completed",
            is_been_received: "Has been received",
            waited_check: "Pending review"
        },
        options: {
            operate: "Analyze",
            check: "Review",
            no_check: "Not checked",
            is_passed: "Passed or not:",
            passed: "Passed",
            no_passed: "Not Passed",
            passed_cannot_empty: "Pass status cannot be empty",
            Alipay: "Alipay",
            Wechat: "WeChat",
            no_pay: "Not paid",
            success: "Success",
            failed: "Failed",
            normal: "Normal",
            normal_variation: "Normal Variation",
            variation: "Variation",
            abnormal: "Abnormal",
            amniotic_fluid: "Amniotic fluid",
            initialize: "Initialize",
            not_get_money: "Not received",
            get_money: "Accounted"
        },

        placeholder: {
            please_choose: "Please select",
            input_money: "Please enter the amount",
            package_code: "Package number",
            start_time: "Start Time",
            end_time: "End Time",
            input_package_amount: "Please enter the package amount",
            choose_package_type: "Please select package type",
            choose_case_quality: "Please select case quality",
            choose_country: "Please select a country",
            input_package_describe: "Please enter a package description",
            start_date: "Start Date",
            end_date: "End Date",
            input_search_account: "Please enter search account",
            account: "Account",
            password: "Password",
            input_content: "Please enter content",
            choose_type: "Please select the type",
            case_num: "Medical Record Number",
            primary_account: "Primary Account",
            remark: "Remarks",
            role_name: "Role name",
            input_verify_code:
                "Please enter your phone number verification code",
            input_email_code: "Please enter the email verification code"
        },
        shortcuts: {
            lately_1day: "Last 1 Day",
            lately_1week: "Last 1 week",
            lately_1month: "Last 1 month",
            lately_7days: "Last 7 days",
            lately_30days: "Last 30 days"
        },
        message: {
            success: "Success",
            confirm_delete: "Are you sure you want to delete?",
            remind: "Reminder",
            money_atMost: "The maximum amount cannot exceed 99999999",
            selfDefined_12programa_atMost:
                "You can only self-define 12 columns at most",
            only4_programa_selfDefined:
                "Only 4 custom columns can be added at the bottom",
            save_success: "Saved successfully. Please check the template list!",
            is_continue:
                "This operation will permanently delete the template, do you want to continue?",
            delete_success: "Deleted successfully!",
            delete_cancel: "Delete cancelled",
            searchDays_90_atMost: "The query date should be up to 90 days",
            name_demand:
                "Name should have minimum eight characters with at least one alphabet and one number",
            input_pwd_again: "Please enter the password again",
            pwd_not_same: "The two passwords do not match!",
            account_cannot_empty: "Account cannot be empty",
            reInput_username:
                "This username is already registered, please provide a different one",
            input_realName: "Please enter your name",
            tel_cannot_empty: "Phone number cannot be empty",
            input_correct_tel: "Please enter the correct phone number",
            verifyCode_cannot_empty: "The verification code cannot be empty",
            verifyCode_demand:
                "The verification code should be a 4-digit number",
            email_cannot_empty: "Email cannot be empty",
            input_correct_email_format:
                "Please enter the email in correct format",
            name_cannot_empty: "Name cannot be empty",
            get_verifyCode: "Get Verification Code",
            input_username: "Please enter your username",
            choose_role: "Please select a role",
            input_tel: "Please select your phone number",
            input_tel_verifyCode:
                "Please select the phone number verification code",
            input_email: "Please enter email",
            input_email_verifyCode: "Please enter the email verification code",
            input_pwd: "Please enter the password",
            confirm_pwd: "Please confirm your password",
            areaCode_cannot_empty: "Area Code cannot be empty",
            retry: "Please retry after s"
        },
        btns: {
            edit: "Edit",
            cancel: "No",
            confirm: "Yes",
            delete: "Delete",
            export: "Export",
            detail: "Detail",
            check: "Review",
            showAll: "Show All",
            addNew: "Add new",
            save: "Save",
            viewDetails: "View Details"
        }
    }
};
export default en;
