<template>
  <div class="agencyCertificationBox">
    <a class="back-btn" @click="goback()">〈 {{$t('accountInfo.back')}}</a>
    <input type="file" id="selectfiles" style="display: none" />
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm" @submit.native.prevent>
      <el-form-item :label="$t('accountInfo.organ_shortname')" prop="shortName">
        <el-input v-model="ruleForm.shortName" :placeholder="$t('accountInfo.enter_organ_shortname')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('organize_approve.organName')" prop="mechanismName">
        <el-input v-model="ruleForm.mechanismName" :placeholder="$t('organize_approve.input_organName')"></el-input>
        <div class="tipsText">
          {{$t('organize_approve.organName_demand')}}
          <el-tooltip class="item" effect="dark" placement="bottom">
            <div slot="content">{{$t('organize_approve.no_organ_code_licens')}}</div>
            <span class="organizationCodeTips">
              {{$t('organize_approve.organ_intrd')}}
            </span>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item :label="$t('organize_approve.officeName')">
        <el-input v-model="ruleForm.departmentName" :placeholder="$t('organize_approve.input_officeName')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('organize_approve.organ_type')">
        <el-select v-model="ruleForm.mechanismType" :popper-append-to-body="false" :placeholder="$t('select_button.choose')">
          <el-option :label="$t('organize_approve.public_hospital')" value="1"></el-option>
          <el-option :label="$t('organize_approve.private_hospital')" value="2"></el-option>
          <el-option :label="$t('organize_approve.theother_lab')" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('organize_approve.organ_site')">
        <el-cascader v-model="ruleForm.checkedArea" :options="provinceList" :props="optionProps" :append-to-body="false" :placeholder="$t('select_button.choose_site')"></el-cascader>
      </el-form-item>
      <el-form-item label="" prop="mechanismAddress">
        <el-input type="textarea" v-model="ruleForm.mechanismAddress" :placeholder="$t('organize_approve.detail_site')"></el-input>
        <div class="tipsText">{{$t('organize_approve.organ_site_demand')}}</div>
      </el-form-item>
      <el-form-item :label="$t('organize_approve.organ_code')" class="organizationCodeBox">
        <!-- <div class="organizationCodeBoxDiv"> -->
        <div class="organizationCodeSelectBox">
          <div class="organizationCodeSelect">
            <el-select v-model="ruleForm.mechanismCodeType" :popper-append-to-body="false" :placeholder="$t('organize_approve.organize_code')">
              <el-option :label="$t('organize_approve.organize_code')" value="1"></el-option>
              <el-option :label="$t('organize_approve.unify_cridet_code')" value="2"></el-option>
            </el-select>
          </div>
          <div class="doctorQualificationCertificate">
            <el-input v-model="ruleForm.mechanismCode" :placeholder="$t('organize_approve.input_certification_code')" maxlength="18"></el-input>
          </div>
        </div>
        <div class="tipsText">
          <div class="organizationCodeJpgTips organizationCodeJpgTipsBottom" @click="mechanismCodeTypeOpen">
            {{$t('organize_approve.organ_code_jpg')}}
          </div>
          {{$t('organize_approve.organ_code_demand')}}
        </div>
        <!-- </div> -->
      </el-form-item>
      <el-form-item :label="$t('organize_approve.organ_code_certificate')">
        <div class="uploadImgBox uploadImgBox1">
          <div class="uploadImg" @click="mechanismCodeFileFun">
            <img v-if="ruleForm.mechanismCodeFile && !isPercentageMec" :src="ruleForm.mechanismCodeFile" class="avatar" />
            <img src="../../../assets/images/personalSettings/iconUploading.svg" alt="" v-if="!ruleForm.mechanismCodeFile && !isPercentageMec && $store.state.language == 'zh'" />
            <img src="../../../assets/images/personalSettings/iconUploadingEn.png" alt="" v-if="!ruleForm.mechanismCodeFile && !isPercentageMec && $store.state.language == 'en'" />
            <el-progress v-if="isPercentageMec" type="circle" :percentage="percentageMec"></el-progress>
            <input type="file" id="mechanismCodeFile" @change="uploadImg($event, 'mechanismCodeFile')" style="display: none" />
          </div>
          <div class="organizationCodeJpgTips organizationCodeJpgHeight" @click="mechanismCodeFileOpen">
            {{$t('organize_approve.organ_code_jpg')}}
          </div>
        </div>
        <div class="tipsText">
          {{$t('organize_approve.organ_code_certificate_demand')}}
        </div>
        <!--                <div class="tipsText">
                    {{$t('organize_approve.organName')}}
                </div>
                <div class="tipsText">
                    {{$t('organize_approve.organName')}}
                </div>-->
      </el-form-item>
      <el-form-item :label="$t('organize_approve.medc_inst_licensNum')" class="practiceLicenseCodeBox">
        <div class="practiceLicenseCodeInput">
          <el-input v-model="ruleForm.practiceLicenseCode" :placeholder="$t('organize_approve.medc_inst_licens_nums')" maxlength="22" onKeyUp="value=value.replace(/[\W]/g,'')"></el-input>
          <div class="organizationCodeJpgTips organizationCodeJpgTipsLeft" @click="practiceLicenseCodeOpen">
            {{$t('organize_approve.medc_inst_licens_jpg')}}
          </div>
        </div>
        <div class="tipsText">
          {{$t('organize_approve.input_licensNum')}}
        </div>
      </el-form-item>
      <el-form-item :label="$t('organize_approve.medc_inst_licens_colon')">
        <div class="uploadImgBox">
          <div class="uploadImg" @click="practiceLicenseFun">
            <!-- <img v-if="practiceLicenseURL" :src="practiceLicenseURL" class="avatar" /> -->
            <img v-if="ruleForm.practiceLicense && !isPercentagePra" :src="ruleForm.practiceLicense" class="avatar" />
            <img src="../../../assets/images/personalSettings/iconUploading.svg" alt="" v-if="!ruleForm.practiceLicense && !isPercentagePra && $store.state.language == 'zh'" />
            <img src="../../../assets/images/personalSettings/iconUploadingEn.png" alt="" v-if="!ruleForm.practiceLicense && !isPercentagePra && $store.state.language == 'en'" />
            <el-progress v-if="isPercentagePra" type="circle" :percentage="percentagePra"></el-progress>
            <input type="file" id="practiceLicense" @change="uploadImg($event, 'practiceLicense')" style="display: none" />
          </div>
          <div class="organizationCodeJpgTips organizationCodeJpgHeight" @click="practiceLicenseOpen">
            {{$t('organize_approve.medc_inst_licens_jpg')}}
          </div>
        </div>
        <div class="tipsText">
          {{$t('organize_approve.medc_inst_licens_demand')}}
        </div>
        <!--            <div class="tipsText">
                    {$t('organize_approve.organName')}}
                </div>
                <div class="tipsText">超过1M）</div>-->
      </el-form-item>
      <el-form-item prop="checked">
        <el-checkbox v-model="ruleForm.checked"></el-checkbox>
        <span class="securityProtocol">
          {{$t('checkbox_button.agreeto_observe')}}
          <router-link :to="{ path: '/statement' }" target="_blank">
            {{$t('checkbox_button.privacy_statement')}}
          </router-link>
          {{$t('checkbox_button.and')}}
          <router-link :to="{ path: '/agreement' }" target="_blank">
            {{$t('checkbox_button.service_agreement')}}
          </router-link>
        </span>
      </el-form-item>
      <el-form-item>
        <button class="btn determineNomal" @click="submitForm('ruleForm')">{{$t('btns.submit')}}</button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { reg } from "../../../assets/js/reg.js";
import uploader from "../../../assets/utils/fileUploader.js";
import { provinceList } from "../../../assets/js/area.js";
import $ from "jquery";
import { cloneDeep } from "lodash";
export default {
  name: "AgencyCertification",
  props: ["userId", "detailInfo"],
  data() {
    return {
      id: "",
      mechanismCodeFileURL: "", // 医师资格证书
      practiceLicenseURL: "", // 机构执业许可证
      // contentTips:
      //   "若没有《组织机构代码证》可以使用三证合一或者五证合一后的《事业单位法人证书》证件，根据此证件填写上传即可。",
      provinceList: [],
      optionProps: {
        // 区域选择
        //配置节点
        value: "name",
        label: "name",
        children: "sub",
      },
      ruleForm: {
        shortName: "",//机构简称
        mechanismName: "", // 机构名称
        departmentName: "", // 科室/部门名称
        mechanismType: "", // 机构类型
        province: "", // 机构地址
        mechanismAddress: "", // 详细地址
        mechanismCodeType: "", // 组织机构代码
        mechanismCodeFile: "", // 组织机构代码证明证件
        mechanismCode: "", // 请输入医师资格证书编码
        practiceLicenseCode: "", // 医疗机构执业许可证登记号
        practiceLicense: "", // 医疗机构执业许可证
        checked: false, // 勾选协议
        checkedArea: []
      },
      isPercentageMec: false,
      percentageMec: 0,
      isPercentagePra: false,
      percentagePra: 0,
      rules: {
        shortName: [
          {
            required: true,
            message: this.$t("accountInfo.enter_organ_shortname"),
            trigger: "blur",
          },
          {
            min: 2,
            max: 30,
            message: this.$t("organize_approve.organName_length_demand"),
            trigger: "blur",
          },
        ],
        mechanismName: [
          {
            required: true,
            message: this.$t("my_identity.input_organName"),
            trigger: "blur",
          },
          {
            min: 2,
            max: 30,
            message: this.$t("organize_approve.organName_length_demand"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    // 初始化获取信息
    this.provinceList = provinceList;
  },
  methods: {
    goback() {
      this.$emit("childFun", false);
    },
    submitForm(formName) {
      if (this.isPercentageMec || this.isPercentagePra) {
        this.$message.error(this.$t('accountInfo.file_uploading'));
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.checked) {
            let ruleForm = cloneDeep(this.ruleForm);
            ruleForm.type = 0;
            ruleForm.area = ruleForm.checkedArea[2];
            ruleForm.city = ruleForm.checkedArea[1];
            ruleForm.province = ruleForm.checkedArea[0];
            ruleForm.mechanismCodeFile = ruleForm.mechanismCodeFile.split("?")[0];
            ruleForm.practiceLicense = ruleForm.practiceLicense.split("?")[0];
            this.$api.userAuth(ruleForm).then((res) => {
              if (res.code == 200) {
                // this.$emit("childFun", 1);
                this.$emit("childFun", false);
                this.ruleForm = {
                  shortName: "",
                  mechanismName: "", // 机构名称
                  departmentName: "", // 科室/部门名称
                  mechanismType: "", // 机构类型
                  province: "", // 机构地址
                  mechanismAddress: "", // 详细地址
                  mechanismCodeType: "", // 组织机构代码
                  mechanismCodeFile: "", // 组织机构代码证明证件
                  mechanismCode: "", // 请输入医师资格证书编码
                  practiceLicenseCode: "", // 医疗机构执业许可证登记号
                  practiceLicense: "", // 医疗机构执业许可证
                  checked: false, // 勾选协议
                  checkedArea: []
                };
                this.getQueryUserByToken();
                this.$message.success("添加成功");
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            this.$message.error(this.$t("organize_approve.check_deal"));
          }
        } else {
          return false;
        }
      });
    },
    getQueryUserByToken() {
      this.$api.getQueryUserByToken({}).then((res) => {
        if (res.code == 200) {
          const { mechanism, user, expert } = res.data;
          this.$store.commit("changeQueryUserInfo", res.data);
          this.$emit("submitFun", true);
        } else {
          // this.$router.push({
          //   path: "/",
          // });
          this.$message.error(res.message);
        }
      });
    },
    // 点击上传
    mechanismCodeFileFun() {
      $("#mechanismCodeFile").trigger("click");
    },
    // 点击上传
    practiceLicenseFun() {
      $("#practiceLicense").trigger("click");
    },
    uploadImg(e, urlName) {
      let _file = e.target.files[0];
      _file.israndom = true;
      if (!_file) return;
      let name = _file.name.split(".");
      !!name.length && name.pop();
      if (!reg.specialImgCharacters.test(name)) {
        this.$message.error(this.$t('accountInfo.imgName_cannot_include_special'));
        return;
      }
      const isLt10M = _file.size / 1024 / 1024 < 10;
      let formats = ["bmp", "png", "jpg", "jpe", "jpeg"];
      let format = _file.name.split(".").pop().toLowerCase();
      if (isLt10M && formats.indexOf(format) > -1) {
        let setPercentage;
        switch (urlName) {
          case "mechanismCodeFile":
            this.isPercentageMec = true;
            this.percentageMec = 0;
            setPercentage = setInterval(() => {
              this.percentageMec += 5;
              if (this.percentageMec >= 99) {
                this.percentageMec = 99;
              }
            }, 200);
            break;
          case "practiceLicense":
            this.isPercentagePra = true;
            this.percentagePra = 0;
            setPercentage = setInterval(() => {
              this.percentagePra += 5;
              if (this.percentagePra >= 99) {
                this.percentagePra = 99;
              }
            }, 200);
            break;
        }
        this.$forceUpdate();
        uploader({
          files: [_file],
          path: "user/certificate/",
          processHandle: (file) => { },
          ossUploadAccess: this.$store.state.ossUploadAccess,
        }).then((filelist) => {
          const imgUrl = filelist[0].path;
          if (urlName === "mechanismCodeFile") {
            this.ruleForm.mechanismCodeFile = this.ossUrlWebFir(imgUrl, 1, 130, 130);
            this.isPercentageMec = false;
          } else if (urlName === "practiceLicense") {
            this.ruleForm.practiceLicense = this.ossUrlWebFir(imgUrl, 1, 130, 130);
            this.isPercentagePra = false;
          }
          clearInterval(setPercentage);
          setPercentage = null;
        });
      } else {
        this.$message.error(this.$t("selfDefined_template.image_size_demand"));
      }
    },
    // 弹窗展示图片
    mechanismCodeTypeOpen() {
      const imageUrl = require("../../../assets/images/personalSettings/mechanismCodeType.png");
      const imageUrl1 = require("../../../assets/images/personalSettings/corporateCertificate.png");
      this.$alert(
        `<div style='display: flex;flex-direction: column;height:450px;overflow: auto;'>
                <img src="${imageUrl}" width=370px>
                <img src="${imageUrl1}">
                </div>`,
        {
          dangerouslyUseHTMLString: true,
        }
      );
    },
    // 弹窗展示图片
    mechanismCodeFileOpen() {
      const imageUrl = require("../../../assets/images/personalSettings/mechanismCodeType.png");
      const imageUrl1 = require("../../../assets/images/personalSettings/corporateCertificate.png");
      this.$alert(
        `<div style='display: flex;flex-direction: column;height:450px;overflow: auto;'>
                <img src="${imageUrl}" width=370px>
                <img src="${imageUrl1}">
                </div>`,
        {
          dangerouslyUseHTMLString: true,
        }
      );
    },
    practiceLicenseCodeOpen() {
      const imageUrl = require("../../../assets/images/personalSettings/practiceLicense.png");
      this.$alert(`<img src="${imageUrl}" width=390px>`, {
        dangerouslyUseHTMLString: true,
      });
    },
    practiceLicenseOpen() {
      const imageUrl = require("../../../assets/images/personalSettings/practiceLicense.png");
      this.$alert(`<img src="${imageUrl}" width=390px>`, {
        dangerouslyUseHTMLString: true,
      });
    },
  },
  watch: {
    detailInfo(val) {
      if (val) {
        this.ruleForm = this.detailInfo;
        this.ruleForm.mechanismType = !!val.mechanismType
          ? val.mechanismType.toString()
          : "";
        this.ruleForm.mechanismCode = !!val.mechanismCode
          ? val.mechanismCode.toString()
          : "";
        this.ruleForm.mechanismCodeType = !!val.mechanismCodeType
          ? val.mechanismCodeType.toString()
          : "";
        this.ruleForm.checkedArea = [val.province, val.city, val.area];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.agencyCertificationBox {
  .demo-ruleForm {
    margin-top: 20px;
  }
  .uploadImgBox {
    display: flex;
    .uploadImg {
      cursor: pointer;
      width: 58px;
      height: 58px;
      background: #fbfbfb;
      border-radius: 2px;
      border: 1px solid #e8e8e8;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .avatar-uploader {
        height: 58px;
      }
    }
  }

  .uploadImgBox1 {
    // margin-top: 14px;
  }

  .practiceLicenseCodeInput {
    display: flex;
    .organizationCodeJpgTipsLeft {
      margin-left: 16px;
    }
  }

  .organizationCodeTips {
    margin-left: 12px;
    font-size: 14px;
    font-family: AlibabaPuHuiTiR;
    color: #4773b8;
    // float: right;
    word-break: keep-all;
    line-height: 20px;
    width: 208px;
  }

  .organizationCodeJpgTips {
    cursor: pointer;
    height: 12px;
    font-size: 14px;
    font-family: AlibabaPuHuiTiR;
    color: #4773b8;
    margin-top: 5px;
  }

  .organizationCodeJpgTipsBottom {
    margin-bottom: 5px;
    width: unset !important;
  }

  .organizationCodeJpgHeight {
    margin-left: 16px;
    height: 60px;
    display: flex;
    align-items: flex-end;
  }

  .tipsText {
    margin-top: 5px;
    height: unset !important;
    line-height: 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }

  .securityProtocol {
    a {
      text-decoration: none;
      color: #4773b8;
    }
  }

  .determineNomal {
    width: 130px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
<style lang="scss">
.agencyCertificationBox {
  .el-input {
    width: 480px;
  }
  .el-form-item__error {
    padding-top: 0;
  }
  .el-input__inner {
    width: 480px;
    height: 40px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 19px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .el-textarea__inner {
    width: 480px;
    height: 64px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-family: Microsoft YaHei;
  }

  .organizationCodeBox {
    /* .organizationCodeBoxDiv {
                display: flex;
                flex-direction: column;
            } */
    .organizationCodeSelectBox {
      display: flex !important;
      .organizationCodeSelect {
        height: 40px;
        .el-select {
          width: 154px;
        }

        .el-input {
          width: 154px !important;
        }

        .el-form-item__content {
          display: flex;
        }

        .el-input__inner {
          width: 154px !important;
          height: 40px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
  }

  .doctorQualificationCertificate {
    margin-left: 12px;

    .el-input__inner {
      width: 314px;
      height: 40px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
  }

  .avatar {
    width: 58px;
    height: 58px;
    display: block;
  }

  .practiceLicenseCodeBox {
    .el-input {
      width: 324px;
    }
    .el-input__inner {
      width: 324px;
    }
  }

  .el-form-item {
    margin-bottom: 14px;
  }
}

.avatar-uploader {
  height: 58px;
}

.el-popup-parent--hidden {
  padding: 0px !important;
}
</style>
