import plupload from "plupload";
import config from "../js/config.js";

function uploader({
    files,
    path,
    processHandle,
    ossUploadAccess
}) {
    const _files = Array.isArray(files) ? files : (files.constructor === File ? [files] : []);
    path = path || 'patient/glass/array/';
    const osshost = config.ossUrl;
    const _processHandle = typeof processHandle === "function" ? processHandle : new Function();
    // console.log(_files);

    function getLoader(file, successCallback, errorCallback) {
        let uploader = new plupload.Uploader({
            runtimes: 'html5,flash,silverlight,html4',
            browse_button: 'selectfiles',
            //multi_selection: false,
            container: document.getElementById('app'),
            flash_swf_url: 'lib/plupload-2.1.2/js/Moxie.swf',
            silverlight_xap_url: 'lib/plupload-2.1.2/js/Moxie.xap',
            url: osshost,
            multipart_params: {
                'key': path + file.upname,
                'policy': ossUploadAccess.policyBase64,
                'OSSAccessKeyId': ossUploadAccess.accessid,
                'success_action_status': '200', //让服务端返回200,不然，默认会返回204
                'signature': ossUploadAccess.signature,
                'x-oss-security-token': ossUploadAccess.SecurityToken
            },
            init: {
                PostInit: function () {
                    uploader.addFile(file, file.upname);
                    uploader.start();
                },
                FilesAdded: function (up, files) {

                },
                BeforeUpload: function (up, file) {

                },
                UploadProgress: function (up, _file) {
                    typeof _processHandle === 'function' && _processHandle(_file, file);
                },
                FileUploaded: function (up, file, info) {
                    if (info.status == 200) {
                        if (typeof successCallback === 'function') {
                            successCallback(file, info)
                        }
                    }
                },
                Error: function (up, err) {
                    if (typeof errorCallback === 'function') {
                        errorCallback(file, err)
                    }
                }
            }
        });
        uploader.init();
        return uploader;
    }

    return new Promise((resolve, reject) => {
        let uploadTotal = files.length;
        let loadedCount = 0;
        let errorCount = 0;

        let fileList = [];

        function checkResult() {
            if (loadedCount + errorCount === uploadTotal) {
                resolve(fileList);
            }
            if (errorCount > 0) {
                reject(fileList);
            }
        }
        // console.log('files,86,', files);
        files.forEach((file, i) => {
            let random = Math.random().toString(16).split(".")[1];
            let name = file.israndom ? (random + '_' + file.name) : file.name;
            file.upname = file.upname || name;
            let _path = [path, file.upname].join("/");
            _path = _path.replace(/\/\//g, '/')
            _path = osshost + '/' + _path
            file.loader = getLoader(file, (_file, info) => {
                const target = {
                    file: file,
                    status: 'success',
                    info: info,
                    path: _path
                }
                fileList[i] = target;
                loadedCount++;
                checkResult();
            }, (_file, info) => {
                const target = {
                    file: file,
                    status: 'fail',
                    info: info
                };
                fileList[i] = target;
                errorCount++;
                checkResult();
            })
        })
    })
}

export default uploader