<template>
  <div id="personalSettings" class="personalSettings">
    <av-header width="1180px"></av-header>
    <div class="personalSettingsBox">
      <div class="personalSettingsCenter">
        <div class="personal-menu">
          <h2 v-show="$store.state.accountType == '1' || tabType == 'organ_validation'">{{$t('personal_setup.title')}}</h2>
          <h2 v-show="$store.state.accountType == '2' && tabType != 'organ_validation'">{{$t('new_interaction.team_setup')}}</h2>
          <ul class="menu-list" v-if="$store.state.accountType == '1' || tabType == 'organ_validation'">
            <li v-for="item in menuList" :key="item.alias" :class="{ current: item.alias == tabType }" @click="showName(item.alias)">
              {{ item.title }}
            </li>
          </ul>
          <ul class="menu-list" v-if="$store.state.accountType == '2' && tabType != 'organ_validation'">
            <li v-for="item in menuListTeam" :key="item.alias" :class="{ current: item.alias == tabType }" @click="showName(item.alias)">
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="personal-wrap">
          <!-- 个人信息 -->
          <PersonalIinformation v-if="'personal_info' == tabType"></PersonalIinformation>
          <!-- 我的身份 -->
          <MyIdentity v-if="'personal_identity' == tabType"></MyIdentity>
          <!-- 机构管理 -->
          <OrganManage v-if="'organ_validation' == tabType"></OrganManage>
          <!-- 工作设置 -->
          <WorkSetup v-if="'personal_set' == tabType"></WorkSetup>
          <!-- 账户管理 -->
          <AccountManagement v-if="'personal_account' == tabType"></AccountManagement>
          <!-- 收益记录 -->
          <IncomeExpenditureDetails v-if="'personal_detail' == tabType && (((userType & 1) == 1 && $store.state.accountType == 1) || $store.state.isAcceptMechanism == 1 )"></IncomeExpenditureDetails>
          <!-- 报告模板 -->
          <TemplateInfo v-if="'template_info' == tabType"></TemplateInfo>
          <!-- 优化设置 -->
          <OptimizeSetting v-if="'optimize_setting' == tabType"></OptimizeSetting>

          <!-- 账户信息 -->
          <AccountInfo v-if="'account_info' == tabType"></AccountInfo>
          <!-- 成员管理 -->
          <Member v-if="'member' == tabType"></Member>
          <!-- 工作设置 -->
          <TeamWorkSetting v-if="'team_work_setting' == tabType"></TeamWorkSetting>
          <!-- 没有权限 -->
          <noPermission v-if="'no_permission' == tabType"></noPermission>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../assets/scss/base/base.scss";
import AvHeader from "../common/header.vue";
import PersonalIinformation from "./modules/personalIinformation";
import AccountInfo from "./modules/accountInfo";
import MyIdentity from "./modules/myIdentity";
import OrganManage from "./modules/organManage";
import WorkSetup from "./modules/workSetup";
import AccountManagement from "./modules/accountManagement";
import IncomeExpenditureDetails from "./modules/incomeExpenditureDetails";
import TemplateInfo from "./modules/templateInfo";
import OptimizeSetting from "./modules/optimizeSetting";
import Member from "../teamSettings/member";
import TeamWorkSetting from "../teamSettings/worksetting";
import noPermission from "./modules/noPermission";
import "./scss/personalSettings.scss";
import Common from "../../assets/js/common";
import { cloneDeep } from 'lodash';
export default {
  name: "PersonalSettings",
  data() {
    return {
      tabPosition: "left",
      menuList: [],
      menuListTeam: [],
      tabType: "personal_info",
      userType: '',
      permissionConfig: {
        personal_info: "/user/queryUserByToken",
        personal_identity: "/user/queryUserByToken",
        organ_validation: "/user/queryUserByToken",
        personal_set: "/user/getWorkConfig",
        personal_account: "/billtype/queryByToken",
        personal_detail: "/paymentSummary/profit",
        template_info: "/reportTemplate/list",
        optimize_setting: "/imageOptimization/list",
        account_info: "/user/mechanismQuery",
        member: "/role/listById",
        team_work_setting: "/user/getWorkConfig",
      },
    };
  },
  created() {
    this.menuList = cloneDeep(this.menuListBf);
    this.menuListTeam = cloneDeep(this.menuListTeamBf);
    // 获取缓存登录数据
    let loginObj = JSON.parse(localStorage.getItem("loginObj"));
    this.userType = loginObj.userType;

    const pointList = JSON.parse(localStorage.getItem("pointList"));
    pointList.indexOf("/imageOptimization/list") < 0 && this.setMenuList(["optimize_setting"]);

    this.menuList = this.menuList.filter((a) => {
      const key = this.permissionConfig[a.alias];
      return Common.permissions(key);
    });

    this.getQueryUserByToken();
    if (this.$route.params.tabType) {
      this.tabType = this.$route.params.tabType;
    }

    this.handleMenuList();
    if (window.location.href.indexOf('recharge') != -1) {
      this.$router.push({
        name: "个人设置页",
        params: { tabType: "personal_account" },
      });
    }
  },
  methods: {
    // 隐藏列表方法
    setMenuList(hideList) {
      this.menuList = this.menuList.filter((item) => {
        return hideList.indexOf(item.alias) < 0;
      });
    },
    handleMenuList() {
      let obj = {
        alias: "personal_detail",
        title: this.$t("personal_setup.income_expenses_details"),
      };
      let caseValue = JSON.parse(localStorage.getItem("caseValue"));
      let isAcceptMechanism = localStorage.getItem("isAcceptMechanismSave");
      if (caseValue.accountType == 1 && (this.userType & 1) != 1) {
        let index = this.menuList.map(item => item.alias).indexOf(obj.alias);
        index > -1 ? this.menuList.splice(index, 1) : "";
      } else if (caseValue.accountType == 2 && isAcceptMechanism != 1) {
        let index = this.menuListTeam.map(item => item.alias).indexOf(obj.alias);
        index > -1 ? this.menuListTeam.splice(index, 1) : "";
      }
    },
    showName(e) {
      console.log(e)
      this.tabType = e;
      this.$store.commit("metatype", e);
    },
    // 获取个人信息与机构信息
    getQueryUserByToken() {
      this.$api.getQueryUserByToken({}).then((res) => {
        if (res.code == 200) {
          const { mechanism, user, expert } = res.data;
          // if (!!mechanism) {
          // this.mechanismList = mechanism;
          // this.userId = user.id;
          // }
          this.$store.commit("changeQueryUserInfo", res.data);
        } else {
          // this.$router.push({
          //   path: "/",
          // });
          this.$message.error(res.message);
        }
      });
    },

  },
  computed: {
    menuListBf() {
      return [
        {
          alias: "personal_info",
          title: this.$t("personal_setup.personal_info"),
        },
        {
          alias: "personal_identity",
          title: this.$t("personal_setup.my_identity"),
        },
        {
          alias: "organ_validation",
          title: this.$t('accountInfo.organ_manage'),
        },
        // {
        //   alias: "personal_set",
        //   title: this.$t("personal_setup.work_setting"),
        // },
        {
          alias: "personal_account",
          title: this.$t("personal_setup.account_manage"),
        },
        {
          alias: "personal_detail",
          title: this.$t("personal_setup.income_expenses_details"),
        },
        {
          alias: "template_info",
          title: this.$t("personal_setup.report_template"),
        },
        {
          alias: "optimize_setting",
          title: this.$t("personal_setup.optimize_setup"),
        },
      ];
    },
    menuListTeamBf() {
      return [
        {
          alias: "account_info",
          title: this.$t("main.account_info"),
        },
        {
          alias: "member",
          title: this.$t("personal_setup.member_manage"),
        },
        {
          alias: "team_work_setting",
          title: this.$t("personal_setup.work_setting"),
        },
        {
          alias: "personal_account",
          title: this.$t("personal_setup.account_manage"),
        },
        {
          alias: "personal_detail",
          title: this.$t("personal_setup.income_expenses_details"),
        },
        {
          alias: "template_info",
          title: this.$t("personal_setup.report_template"),
        },
        {
          alias: "optimize_setting",
          title: this.$t("personal_setup.optimize_setup"),
        },
      ];
    },
  },
  watch: {
    tabType(nv, ov) {
      if (nv) {
      }
    },
    //监听列表切换
    "$store.state.metatype": function () {
      clearInterval(window.timeCountDown);
      this.$store.commit("fastPay", 0);
      if (this.$store.state.metatypeValue == 2 && this.$store.state.metatype != "optimize_setting") {
        this.$confirm(this.$t("popup.warning.not_save_toleave"), this.$t("popup.warning.remind"), {
          confirmButtonText: this.$t("btns.confirm"),
          cancelButtonText: this.$t("btns.cancel"),
          type: "warning",
          closeOnClickModal: false
        }).then(() => {
          //离开
          this.$store.commit("metatypeValue", 1);
          this.tabType = this.$store.state.metatype;
        }).catch(() => {
          //取消
          this.$store.commit("metatype", "optimize_setting");
          this.tabType = "optimize_setting";
        });
      } else {
        this.tabType = this.$store.state.metatype;
      }
    },
    // "$store.state.template": function () {
    //   this.tabType = "template_info";
    // },
    "$store.state.isUpdateSetting": function () {
      this.menuListTeam = [];
      this.menuList = [];
      this.menuList = cloneDeep(this.menuListBf);
      this.menuListTeam = cloneDeep(this.menuListTeamBf);
      if (this.$store.state.accountType == 1) {
        this.tabType = "personal_info";
      } else {
        this.tabType = "account_info";
      }
      this.handleMenuList();
    },
    "$route.params.tabType": function () {
      this.tabType = this.$route.params.tabType;
      this.menuListTeam = [];
      this.menuList = [];
      this.menuList = cloneDeep(this.menuListBf);
      this.menuListTeam = cloneDeep(this.menuListTeamBf);
    },
    "$store.state.labValue": async function (val) {
      this.menuListTeam = [];
      this.menuList = [];
      this.menuList = cloneDeep(this.menuListBf);
      this.menuListTeam = cloneDeep(this.menuListTeamBf);
      if (this.$route.params.tabType) {
        this.tabType = this.$route.params.tabType;
      }

      if (this.$route.params.tabType) {
        this.tabType = this.$route.params.tabType;
      } else if (val.accountType == 1) {
        this.tabType = "personal_info";
      } else if (val.accountType == 2) {
        this.tabType = "account_info";
      }
      this.handleMenuList();
    },
    "$store.state.language": function () {
      this.handleMenuList();
    }
  },
  components: {
    AvHeader,
    PersonalIinformation,
    MyIdentity,
    OrganManage,
    WorkSetup,
    AccountManagement,
    IncomeExpenditureDetails,
    TemplateInfo,
    OptimizeSetting,
    Member,
    TeamWorkSetting,
    AccountInfo,
    noPermission,
  },
};
</script>

<style lang="scss" scoped>
</style>
