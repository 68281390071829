<template>
  <div class="addWeChatAccountBox">
    <div class="addWeChatAccountTitle">
      <div class="addWeChatAccountTitleText">
        <span>{{$t('account_manage.account_manage')}}/ </span> <span>{{$t('account_manage.add_Wechat')}}</span>
      </div>
      <div class="addWeChatAccountTitleBtn" @click="backFun">
        <span>{{$t('account_manage.back')}}</span>
        <img src="../../../assets/images/personalSettings/iconReturnRight.svg" />
      </div>
    </div>
    <div class="dividingLine"></div>
    <div class="addWeChatAccountContent">
      <el-form ref="form" :rules="rules" :model="form" label-width="108px">
        <el-form-item :label="$t('account_manage.real_name')" prop="accountName">
          <el-input v-model="form.accountName" maxlength="50" :placeholder="$t('account_manage.input_real_name')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('account_manage.telNum')" prop="account">
          <el-input v-model="form.account" :placeholder="$t('account_manage.input_Wechat_tel')"></el-input>
          <div class="tipsText">
            {{$t('account_manage.Wechat_pay_site')}}
          </div>
          <!--                   <div class="tipsTextBottom">
                        <span>如图所示：</span
                        ><span>允许通过手机号像我转账</span>
                        <span>
                            <img
                                src="../../../assets/images/personalSettings/iconWeChatTips.svg"
                                alt=""
                            />
                        </span>
                    </div>-->
        </el-form-item>
        <el-form-item label="">
          <div class="btn determineNomal" @click="onSubmit('form')">
            {{$t('btns.confirm')}}
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import "../../../assets/scss/button.scss";
import { reg } from "../../../assets/js/reg.js";
import common from "./../common/common.js";
export default {
  name: "AddWeChatAccount",
  data() {
    var validateAccount = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请填写手机号码"));
      } else {
        if (!reg.phone.test(value)) {
          callback(new Error("请填写正确的手机号码"));
        } else {
          callback();
        }
      }
    };
    return {
      isShowDate: 1,
      form: {
        id: null,
        accountName: "",
        account: "",
        type: 2
      },
      rules: {
        accountName: [
          {
            required: true,
            message: "姓名不能为空",
            trigger: "blur"
          }
        ],
        account: [
          {
            validator: validateAccount,
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    backFun() {
      this.$emit("childFun", this.isShowDate);
    },
    // 初始化表单数据
    clearForm() {
      this.form = {
        accountName: "",
        account: "",
        type: 2
      };
    },
    // 点击提交按钮方法
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$api.addUserAccount(this.form).then(res => {
            if (res.code == 200) {
              this.$parent.getHomeList();
              this.clearForm();
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    }
  },
  created() {
    // 获取账户列表数据
    this.$api.homeList({}).then(res => {
      if (res.code == 200) {
        res.data.forEach(item => {
          if (item.type == 2) {
            this.form.id = item.id;
            this.form.accountName = common.substrFun(
              item.accountName,
              false
            );
            this.form.account = item.account;
          }
        });
      } else {
        // this.$router.push({
        //     path: "/"
        // });
        this.$message.error(res.message);
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.addWeChatAccountBox {
  .addWeChatAccountTitle {
    margin-top: 13px;
    margin-left: 20px;
    height: 24px;
    display: flex;
    justify-content: space-between;

    .addWeChatAccountTitleText {
      & > :nth-child(1) {
        font-size: 16px;
        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
        font-weight: 400;
        color: #666666;
      }

      & > :nth-child(2) {
        font-size: 16px;
        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
        font-weight: 400;
        color: #333333;
      }
    }

    .addWeChatAccountTitleBtn {
      margin-right: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-size: 18px;
        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
        font-weight: 400;
        color: #333333;
      }

      img {
        padding-left: 10px;
      }
    }
  }

  .dividingLine {
    margin-top: 13px;
    margin-left: 20px;
    width: 890px;
    height: 1px;
    background: #d8d8d8;
  }

  .addWeChatAccountContent {
    margin-top: 52px;
    padding-left: 50px;

    .determineNomal {
      margin-top: 40px;
      width: 146px;
      height: 40px;
      line-height: 40px;

      font-size: 16px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;

      color: #ffffff;
    }

    form {
      width: 326px;
      margin-top: 20px;

      .tipsText {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .tipsTextBottom {
        height: 17px;
        display: flex;
        align-items: center;

        & > :nth-child(1) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }

        & > :nth-child(2) {
          margin-left: 8px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }

        & > :nth-child(3) {
          margin-left: 20px;
          display: flex;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.addWeChatAccountBox {
  .el-form-item {
    width: 620px;
  }

  .el-form-item__label {
    font-size: 18px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #666666;
  }
}
</style>
