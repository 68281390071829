export const standard = {
    "type": 400,
    "padding": 25,
    "position": [
        {
            "name": "Chrom01ISCN09",
            "element": [
                {
                    "lxly": [
                        50,
                        963
                    ]
                }
            ]
        },
        {
            "name": "Chrom02ISCN09",
            "element": [
                {
                    "lxly": [
                        51,
                        889
                    ]
                }
            ]
        },
        {
            "name": "Chrom03ISCN09",
            "element": [
                {
                    "lxly": [
                        50,
                        750
                    ]
                }
            ]
        },
        {
            "name": "Chrom04ISCN09",
            "element": [
                {
                    "lxly": [
                        53,
                        704
                    ]
                }
            ]
        },
        {
            "name": "Chrom05ISCN09",
            "element": [
                {
                    "lxly": [
                        53,
                        667
                    ]
                }
            ]
        },
        {
            "name": "Chrom06ISCN09",
            "element": [
                {
                    "lxly": [
                        50,
                        656
                    ]
                }
            ]
        },
        {
            "name": "Chrom07ISCN09",
            "element": [
                {
                    "lxly": [
                        52,
                        594
                    ]
                }
            ]
        },
        {
            "name": "Chrom08ISCN09",
            "element": [
                {
                    "lxly": [
                        51,
                        526
                    ]
                }
            ]
        },
        {
            "name": "Chrom09ISCN09",
            "element": [
                {
                    "lxly": [
                        53,
                        525
                    ]
                }
            ]
        },
        {
            "name": "Chrom10ISCN09",
            "element": [
                {
                    "lxly": [
                        52,
                        510
                    ]
                }
            ]
        },
        {
            "name": "Chrom11ISCN09",
            "element": [
                {
                    "lxly": [
                        53,
                        508
                    ]
                }
            ]
        },
        {
            "name": "Chrom12ISCN09",
            "element": [
                {
                    "lxly": [
                        53,
                        493
                    ]
                }
            ]
        },
        {
            "name": "Chrom13ISCN09",
            "element": [
                {
                    "lxly": [
                        55,
                        418
                    ]
                }
            ]
        },
        {
            "name": "Chrom14ISCN09",
            "element": [
                {
                    "lxly": [
                        53,
                        404
                    ]
                }
            ]
        },
        {
            "name": "Chrom15ISCN09",
            "element": [
                {
                    "lxly": [
                        51,
                        382
                    ]
                }
            ]
        },
        {
            "name": "Chrom16ISCN09",
            "element": [
                {
                    "lxly": [
                        52,
                        352
                    ]
                }
            ]
        },
        {
            "name": "Chrom17ISCN09",
            "element": [
                {
                    "lxly": [
                        65,
                        342
                    ]
                }
            ]
        },
        {
            "name": "Chrom18ISCN09",
            "element": [
                {
                    "lxly": [
                        52,
                        308
                    ]
                }
            ]
        },
        {
            "name": "Chrom19ISCN09",
            "element": [
                {
                    "lxly": [
                        52,
                        270
                    ]
                }
            ]
        },
        {
            "name": "Chrom20ISCN09",
            "element": [
                {
                    "lxly": [
                        51,
                        259
                    ]
                }
            ]
        },
        {
            "name": "Chrom21ISCN09",
            "element": [
                {
                    "lxly": [
                        53,
                        201
                    ]
                }
            ]
        },
        {
            "name": "Chrom22ISCN09",
            "element": [
                {
                    "lxly": [
                        56,
                        218
                    ]
                }
            ]
        },
        {
            "name": "ChromXISCN09",
            "element": [
                {
                    "lxly": [
                        53,
                        604
                    ]
                }
            ]
        },
        {
            "name": "ChromYISCN09",
            "element": [
                {
                    "lxly": [
                        54,
                        213
                    ]
                }
            ]
        }
    ],
    "banding": [
        {
            "name": "Chrom01ISCN09",
            "element": [
                {
                    "name": "p36.3",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        50,
                        22
                    ]
                },
                {
                    "name": "p36.2",
                    "lxly": [
                        0,
                        23
                    ],
                    "rxry": [
                        50,
                        37
                    ]
                },
                {
                    "name": "p36.1",
                    "lxly": [
                        0,
                        38
                    ],
                    "rxry": [
                        50,
                        112
                    ]
                },
                {
                    "name": "p35",
                    "lxly": [
                        0,
                        113
                    ],
                    "rxry": [
                        50,
                        126
                    ]
                },
                {
                    "name": "p34.3",
                    "lxly": [
                        0,
                        126
                    ],
                    "rxry": [
                        50,
                        140
                    ]
                },
                {
                    "name": "p34.2",
                    "lxly": [
                        0,
                        141
                    ],
                    "rxry": [
                        50,
                        154
                    ]
                },
                {
                    "name": "p34.1",
                    "lxly": [
                        0,
                        155
                    ],
                    "rxry": [
                        50,
                        176
                    ]
                },
                {
                    "name": "p33",
                    "lxly": [
                        0,
                        175
                    ],
                    "rxry": [
                        50,
                        191
                    ]
                },
                {
                    "name": "p32",
                    "lxly": [
                        0,
                        190
                    ],
                    "rxry": [
                        50,
                        225
                    ]
                },
                {
                    "name": "p31",
                    "lxly": [
                        0,
                        225
                    ],
                    "rxry": [
                        50,
                        296
                    ]
                },
                {
                    "name": "p22",
                    "lxly": [
                        0,
                        296
                    ],
                    "rxry": [
                        50,
                        325
                    ]
                },
                {
                    "name": "p21",
                    "lxly": [
                        0,
                        326
                    ],
                    "rxry": [
                        50,
                        375
                    ]
                },
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        375
                    ],
                    "rxry": [
                        50,
                        443
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        444
                    ],
                    "rxry": [
                        50,
                        450
                    ]
                },
                {
                    "name": "p11",
                    "lxly": [
                        0,
                        451
                    ],
                    "rxry": [
                        50,
                        464
                    ]
                },
                {
                    "name": "q11",
                    "lxly": [
                        0,
                        465
                    ],
                    "rxry": [
                        50,
                        474
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        475
                    ],
                    "rxry": [
                        50,
                        559
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        560
                    ],
                    "rxry": [
                        50,
                        628
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        629
                    ],
                    "rxry": [
                        50,
                        647
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        648
                    ],
                    "rxry": [
                        50,
                        659
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        660
                    ],
                    "rxry": [
                        50,
                        688
                    ]
                },
                {
                    "name": "q25",
                    "lxly": [
                        0,
                        689
                    ],
                    "rxry": [
                        50,
                        712
                    ]
                },
                {
                    "name": "q31",
                    "lxly": [
                        0,
                        712
                    ],
                    "rxry": [
                        50,
                        761
                    ]
                },
                {
                    "name": "q32",
                    "lxly": [
                        0,
                        762
                    ],
                    "rxry": [
                        50,
                        830
                    ]
                },
                {
                    "name": "q41",
                    "lxly": [
                        0,
                        830
                    ],
                    "rxry": [
                        50,
                        858
                    ]
                },
                {
                    "name": "q42",
                    "lxly": [
                        0,
                        859
                    ],
                    "rxry": [
                        50,
                        928
                    ]
                },
                {
                    "name": "q43",
                    "lxly": [
                        0,
                        929
                    ],
                    "rxry": [
                        50,
                        947
                    ]
                },
                {
                    "name": "q44",
                    "lxly": [
                        0,
                        947
                    ],
                    "rxry": [
                        50,
                        960
                    ]
                }
            ]
        },
        {
            "name": "Chrom02ISCN09",
            "element": [
                {
                    "name": "p25",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        51,
                        34
                    ]
                },
                {
                    "name": "p24",
                    "lxly": [
                        0,
                        33
                    ],
                    "rxry": [
                        51,
                        62
                    ]
                },
                {
                    "name": "p23",
                    "lxly": [
                        0,
                        61
                    ],
                    "rxry": [
                        51,
                        111
                    ]
                },
                {
                    "name": "p22",
                    "lxly": [
                        0,
                        112
                    ],
                    "rxry": [
                        51,
                        140
                    ]
                },
                {
                    "name": "p21",
                    "lxly": [
                        0,
                        140
                    ],
                    "rxry": [
                        51,
                        180
                    ]
                },
                {
                    "name": "p16",
                    "lxly": [
                        0,
                        180
                    ],
                    "rxry": [
                        51,
                        216
                    ]
                },
                {
                    "name": "p15",
                    "lxly": [
                        0,
                        217
                    ],
                    "rxry": [
                        51,
                        219
                    ]
                },
                {
                    "name": "p14",
                    "lxly": [
                        0,
                        220
                    ],
                    "rxry": [
                        51,
                        233
                    ]
                },
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        234
                    ],
                    "rxry": [
                        51,
                        288
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        289
                    ],
                    "rxry": [
                        51,
                        317
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        316
                    ],
                    "rxry": [
                        51,
                        333
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        334
                    ],
                    "rxry": [
                        51,
                        342
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        343
                    ],
                    "rxry": [
                        51,
                        355
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        356
                    ],
                    "rxry": [
                        51,
                        363
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        364
                    ],
                    "rxry": [
                        51,
                        394
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        395
                    ],
                    "rxry": [
                        51,
                        419
                    ]
                },
                {
                    "name": "q14.1",
                    "lxly": [
                        0,
                        420
                    ],
                    "rxry": [
                        51,
                        434
                    ]
                },
                {
                    "name": "q14.2",
                    "lxly": [
                        0,
                        435
                    ],
                    "rxry": [
                        51,
                        439
                    ]
                },
                {
                    "name": "q14.3",
                    "lxly": [
                        0,
                        440
                    ],
                    "rxry": [
                        51,
                        457
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        458
                    ],
                    "rxry": [
                        51,
                        527
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        527
                    ],
                    "rxry": [
                        51,
                        564
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        565
                    ],
                    "rxry": [
                        51,
                        580
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        580
                    ],
                    "rxry": [
                        51,
                        618
                    ]
                },
                {
                    "name": "q31",
                    "lxly": [
                        0,
                        618
                    ],
                    "rxry": [
                        51,
                        656
                    ]
                },
                {
                    "name": "q32",
                    "lxly": [
                        0,
                        656
                    ],
                    "rxry": [
                        51,
                        718
                    ]
                },
                {
                    "name": "q33",
                    "lxly": [
                        0,
                        718
                    ],
                    "rxry": [
                        51,
                        744
                    ]
                },
                {
                    "name": "q34",
                    "lxly": [
                        0,
                        744
                    ],
                    "rxry": [
                        51,
                        772
                    ]
                },
                {
                    "name": "q35",
                    "lxly": [
                        0,
                        772
                    ],
                    "rxry": [
                        51,
                        793
                    ]
                },
                {
                    "name": "q36",
                    "lxly": [
                        0,
                        793
                    ],
                    "rxry": [
                        51,
                        820
                    ]
                },
                {
                    "name": "q37",
                    "lxly": [
                        0,
                        820
                    ],
                    "rxry": [
                        51,
                        886
                    ]
                }
            ]
        },
        {
            "name": "Chrom03ISCN09",
            "element": [
                {
                    "name": "p26",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        50,
                        13
                    ]
                },
                {
                    "name": "p25",
                    "lxly": [
                        0,
                        14
                    ],
                    "rxry": [
                        50,
                        39
                    ]
                },
                {
                    "name": "p24",
                    "lxly": [
                        0,
                        39
                    ],
                    "rxry": [
                        50,
                        74
                    ]
                },
                {
                    "name": "p23",
                    "lxly": [
                        0,
                        74
                    ],
                    "rxry": [
                        50,
                        80
                    ]
                },
                {
                    "name": "p22",
                    "lxly": [
                        0,
                        82
                    ],
                    "rxry": [
                        50,
                        113
                    ]
                },
                {
                    "name": "p21",
                    "lxly": [
                        0,
                        113
                    ],
                    "rxry": [
                        50,
                        239
                    ]
                },
                {
                    "name": "p14",
                    "lxly": [
                        0,
                        240
                    ],
                    "rxry": [
                        50,
                        272
                    ]
                },
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        273
                    ],
                    "rxry": [
                        50,
                        306
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        307
                    ],
                    "rxry": [
                        50,
                        333
                    ]
                },
                {
                    "name": "p11",
                    "lxly": [
                        0,
                        334
                    ],
                    "rxry": [
                        50,
                        346
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        347
                    ],
                    "rxry": [
                        50,
                        355
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        356
                    ],
                    "rxry": [
                        50,
                        371
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        372
                    ],
                    "rxry": [
                        50,
                        383
                    ]
                },
                {
                    "name": "q13.1",
                    "lxly": [
                        0,
                        384
                    ],
                    "rxry": [
                        50,
                        412
                    ]
                },
                {
                    "name": "q13.2",
                    "lxly": [
                        0,
                        412
                    ],
                    "rxry": [
                        50,
                        425
                    ]
                },
                {
                    "name": "q13.3",
                    "lxly": [
                        0,
                        426
                    ],
                    "rxry": [
                        50,
                        453
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        454
                    ],
                    "rxry": [
                        50,
                        503
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        504
                    ],
                    "rxry": [
                        50,
                        517
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        519
                    ],
                    "rxry": [
                        50,
                        534
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        533
                    ],
                    "rxry": [
                        50,
                        570
                    ]
                },
                {
                    "name": "q25",
                    "lxly": [
                        0,
                        570
                    ],
                    "rxry": [
                        50,
                        597
                    ]
                },
                {
                    "name": "q26.1",
                    "lxly": [
                        0,
                        597
                    ],
                    "rxry": [
                        50,
                        636
                    ]
                },
                {
                    "name": "q26.2",
                    "lxly": [
                        0,
                        636
                    ],
                    "rxry": [
                        50,
                        646
                    ]
                },
                {
                    "name": "q26.3",
                    "lxly": [
                        0,
                        646
                    ],
                    "rxry": [
                        50,
                        683
                    ]
                },
                {
                    "name": "q27",
                    "lxly": [
                        0,
                        683
                    ],
                    "rxry": [
                        50,
                        711
                    ]
                },
                {
                    "name": "q28",
                    "lxly": [
                        0,
                        712
                    ],
                    "rxry": [
                        50,
                        725
                    ]
                },
                {
                    "name": "q29",
                    "lxly": [
                        0,
                        726
                    ],
                    "rxry": [
                        50,
                        746
                    ]
                }
            ]
        },
        {
            "name": "Chrom04ISCN09",
            "element": [
                {
                    "name": "p16",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        53,
                        65
                    ]
                },
                {
                    "name": "p15.3",
                    "lxly": [
                        0,
                        65
                    ],
                    "rxry": [
                        53,
                        90
                    ]
                },
                {
                    "name": "p15.2",
                    "lxly": [
                        0,
                        90
                    ],
                    "rxry": [
                        53,
                        96
                    ]
                },
                {
                    "name": "p15.1",
                    "lxly": [
                        0,
                        97
                    ],
                    "rxry": [
                        53,
                        119
                    ]
                },
                {
                    "name": "p14",
                    "lxly": [
                        0,
                        118
                    ],
                    "rxry": [
                        53,
                        158
                    ]
                },
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        158
                    ],
                    "rxry": [
                        53,
                        175
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        175
                    ],
                    "rxry": [
                        53,
                        180
                    ]
                },
                {
                    "name": "p11",
                    "lxly": [
                        0,
                        181
                    ],
                    "rxry": [
                        53,
                        190
                    ]
                },
                {
                    "name": "q11",
                    "lxly": [
                        0,
                        190
                    ],
                    "rxry": [
                        53,
                        201
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        201
                    ],
                    "rxry": [
                        53,
                        219
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        219
                    ],
                    "rxry": [
                        53,
                        255
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        255
                    ],
                    "rxry": [
                        53,
                        338
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        339
                    ],
                    "rxry": [
                        53,
                        365
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        365
                    ],
                    "rxry": [
                        53,
                        372
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        373
                    ],
                    "rxry": [
                        53,
                        398
                    ]
                },
                {
                    "name": "q25",
                    "lxly": [
                        0,
                        399
                    ],
                    "rxry": [
                        53,
                        413
                    ]
                },
                {
                    "name": "q26",
                    "lxly": [
                        0,
                        413
                    ],
                    "rxry": [
                        53,
                        450
                    ]
                },
                {
                    "name": "q27",
                    "lxly": [
                        0,
                        451
                    ],
                    "rxry": [
                        53,
                        463
                    ]
                },
                {
                    "name": "q28",
                    "lxly": [
                        0,
                        464
                    ],
                    "rxry": [
                        53,
                        513
                    ]
                },
                {
                    "name": "q31.1",
                    "lxly": [
                        0,
                        513
                    ],
                    "rxry": [
                        53,
                        542
                    ]
                },
                {
                    "name": "q31.2",
                    "lxly": [
                        0,
                        544
                    ],
                    "rxry": [
                        53,
                        556
                    ]
                },
                {
                    "name": "q31.3",
                    "lxly": [
                        0,
                        557
                    ],
                    "rxry": [
                        53,
                        603
                    ]
                },
                {
                    "name": "q32",
                    "lxly": [
                        0,
                        603
                    ],
                    "rxry": [
                        53,
                        632
                    ]
                },
                {
                    "name": "q33",
                    "lxly": [
                        0,
                        632
                    ],
                    "rxry": [
                        53,
                        652
                    ]
                },
                {
                    "name": "q34",
                    "lxly": [
                        0,
                        652
                    ],
                    "rxry": [
                        53,
                        675
                    ]
                },
                {
                    "name": "q35",
                    "lxly": [
                        0,
                        675
                    ],
                    "rxry": [
                        53,
                        701
                    ]
                }
            ]
        },
        {
            "name": "Chrom05ISCN09",
            "element": [
                {
                    "name": "p15.3",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        53,
                        34
                    ]
                },
                {
                    "name": "p15.2",
                    "lxly": [
                        0,
                        35
                    ],
                    "rxry": [
                        53,
                        54
                    ]
                },
                {
                    "name": "p15.1",
                    "lxly": [
                        0,
                        54
                    ],
                    "rxry": [
                        53,
                        74
                    ]
                },
                {
                    "name": "p14",
                    "lxly": [
                        0,
                        74
                    ],
                    "rxry": [
                        53,
                        115
                    ]
                },
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        115
                    ],
                    "rxry": [
                        53,
                        154
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        155
                    ],
                    "rxry": [
                        53,
                        164
                    ]
                },
                {
                    "name": "p11",
                    "lxly": [
                        0,
                        165
                    ],
                    "rxry": [
                        53,
                        174
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        175
                    ],
                    "rxry": [
                        53,
                        185
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        186
                    ],
                    "rxry": [
                        53,
                        213
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        214
                    ],
                    "rxry": [
                        53,
                        241
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        241
                    ],
                    "rxry": [
                        53,
                        281
                    ]
                },
                {
                    "name": "q14",
                    "lxly": [
                        0,
                        281
                    ],
                    "rxry": [
                        53,
                        327
                    ]
                },
                {
                    "name": "q15",
                    "lxly": [
                        0,
                        328
                    ],
                    "rxry": [
                        53,
                        344
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        344
                    ],
                    "rxry": [
                        53,
                        397
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        397
                    ],
                    "rxry": [
                        53,
                        418
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        419
                    ],
                    "rxry": [
                        53,
                        467
                    ]
                },
                {
                    "name": "q31",
                    "lxly": [
                        0,
                        467
                    ],
                    "rxry": [
                        53,
                        513
                    ]
                },
                {
                    "name": "q32",
                    "lxly": [
                        0,
                        513
                    ],
                    "rxry": [
                        53,
                        533
                    ]
                },
                {
                    "name": "q33",
                    "lxly": [
                        0,
                        534
                    ],
                    "rxry": [
                        53,
                        562
                    ]
                },
                {
                    "name": "q34",
                    "lxly": [
                        0,
                        562
                    ],
                    "rxry": [
                        53,
                        603
                    ]
                },
                {
                    "name": "q35",
                    "lxly": [
                        0,
                        604
                    ],
                    "rxry": [
                        53,
                        663
                    ]
                }
            ]
        },
        {
            "name": "Chrom06ISCN09",
            "element": [
                {
                    "name": "p25",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        49,
                        20
                    ]
                },
                {
                    "name": "p24",
                    "lxly": [
                        0,
                        20
                    ],
                    "rxry": [
                        49,
                        38
                    ]
                },
                {
                    "name": "p23",
                    "lxly": [
                        0,
                        38
                    ],
                    "rxry": [
                        49,
                        55
                    ]
                },
                {
                    "name": "p22",
                    "lxly": [
                        0,
                        55
                    ],
                    "rxry": [
                        49,
                        85
                    ]
                },
                {
                    "name": "p21.3",
                    "lxly": [
                        0,
                        85
                    ],
                    "rxry": [
                        49,
                        135
                    ]
                },
                {
                    "name": "p21.2",
                    "lxly": [
                        0,
                        135
                    ],
                    "rxry": [
                        49,
                        144
                    ]
                },
                {
                    "name": "p21.1",
                    "lxly": [
                        0,
                        144
                    ],
                    "rxry": [
                        49,
                        178
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        178
                    ],
                    "rxry": [
                        49,
                        210
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        210
                    ],
                    "rxry": [
                        49,
                        214
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        214
                    ],
                    "rxry": [
                        49,
                        224
                    ]
                },
                {
                    "name": "q11",
                    "lxly": [
                        0,
                        224
                    ],
                    "rxry": [
                        49,
                        237
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        237
                    ],
                    "rxry": [
                        49,
                        265
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        265
                    ],
                    "rxry": [
                        49,
                        285
                    ]
                },
                {
                    "name": "q14",
                    "lxly": [
                        0,
                        285
                    ],
                    "rxry": [
                        49,
                        309
                    ]
                },
                {
                    "name": "q15",
                    "lxly": [
                        0,
                        309
                    ],
                    "rxry": [
                        49,
                        326
                    ]
                },
                {
                    "name": "q16",
                    "lxly": [
                        0,
                        326
                    ],
                    "rxry": [
                        49,
                        361
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        361
                    ],
                    "rxry": [
                        49,
                        426
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        426
                    ],
                    "rxry": [
                        49,
                        474
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        474
                    ],
                    "rxry": [
                        49,
                        501
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        501
                    ],
                    "rxry": [
                        49,
                        538
                    ]
                },
                {
                    "name": "q25",
                    "lxly": [
                        0,
                        538
                    ],
                    "rxry": [
                        49,
                        595
                    ]
                },
                {
                    "name": "q26",
                    "lxly": [
                        0,
                        595
                    ],
                    "rxry": [
                        49,
                        618
                    ]
                },
                {
                    "name": "q27",
                    "lxly": [
                        0,
                        618
                    ],
                    "rxry": [
                        49,
                        655
                    ]
                }
            ]
        },
        {
            "name": "Chrom07ISCN09",
            "element": [
                {
                    "name": "p22",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        52,
                        23
                    ]
                },
                {
                    "name": "p21",
                    "lxly": [
                        0,
                        23
                    ],
                    "rxry": [
                        52,
                        61
                    ]
                },
                {
                    "name": "p15",
                    "lxly": [
                        0,
                        61
                    ],
                    "rxry": [
                        52,
                        114
                    ]
                },
                {
                    "name": "p14",
                    "lxly": [
                        0,
                        115
                    ],
                    "rxry": [
                        52,
                        138
                    ]
                },
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        139
                    ],
                    "rxry": [
                        52,
                        175
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        175
                    ],
                    "rxry": [
                        52,
                        190
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        190
                    ],
                    "rxry": [
                        52,
                        198
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        199
                    ],
                    "rxry": [
                        52,
                        207
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        208
                    ],
                    "rxry": [
                        52,
                        221
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        222
                    ],
                    "rxry": [
                        52,
                        285
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        286
                    ],
                    "rxry": [
                        52,
                        342
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        342
                    ],
                    "rxry": [
                        52,
                        391
                    ]
                },
                {
                    "name": "q31",
                    "lxly": [
                        0,
                        391
                    ],
                    "rxry": [
                        52,
                        453
                    ]
                },
                {
                    "name": "q32",
                    "lxly": [
                        0,
                        454
                    ],
                    "rxry": [
                        52,
                        504
                    ]
                },
                {
                    "name": "q33",
                    "lxly": [
                        0,
                        505
                    ],
                    "rxry": [
                        52,
                        519
                    ]
                },
                {
                    "name": "q34",
                    "lxly": [
                        0,
                        520
                    ],
                    "rxry": [
                        52,
                        524
                    ]
                },
                {
                    "name": "q35",
                    "lxly": [
                        0,
                        525
                    ],
                    "rxry": [
                        52,
                        545
                    ]
                },
                {
                    "name": "q36",
                    "lxly": [
                        0,
                        546
                    ],
                    "rxry": [
                        52,
                        590
                    ]
                }
            ]
        },
        {
            "name": "Chrom08ISCN09",
            "element": [
                {
                    "name": "p23",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        51,
                        32
                    ]
                },
                {
                    "name": "p22",
                    "lxly": [
                        0,
                        33
                    ],
                    "rxry": [
                        51,
                        54
                    ]
                },
                {
                    "name": "p21",
                    "lxly": [
                        0,
                        55
                    ],
                    "rxry": [
                        51,
                        107
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        108
                    ],
                    "rxry": [
                        51,
                        132
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        133
                    ],
                    "rxry": [
                        51,
                        153
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        154
                    ],
                    "rxry": [
                        51,
                        159
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        160
                    ],
                    "rxry": [
                        51,
                        169
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        170
                    ],
                    "rxry": [
                        51,
                        184
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        185
                    ],
                    "rxry": [
                        51,
                        206
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        206
                    ],
                    "rxry": [
                        51,
                        248
                    ]
                },
                {
                    "name": "q21.1",
                    "lxly": [
                        0,
                        249
                    ],
                    "rxry": [
                        51,
                        288
                    ]
                },
                {
                    "name": "q21.2",
                    "lxly": [
                        0,
                        288
                    ],
                    "rxry": [
                        51,
                        301
                    ]
                },
                {
                    "name": "q21.3",
                    "lxly": [
                        0,
                        301
                    ],
                    "rxry": [
                        51,
                        340
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        340
                    ],
                    "rxry": [
                        51,
                        391
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        391
                    ],
                    "rxry": [
                        51,
                        423
                    ]
                },
                {
                    "name": "q24.1",
                    "lxly": [
                        0,
                        423
                    ],
                    "rxry": [
                        51,
                        462
                    ]
                },
                {
                    "name": "q24.2",
                    "lxly": [
                        0,
                        463
                    ],
                    "rxry": [
                        51,
                        493
                    ]
                },
                {
                    "name": "q24.3",
                    "lxly": [
                        0,
                        492
                    ],
                    "rxry": [
                        51,
                        522
                    ]
                }
            ]
        },
        {
            "name": "Chrom09ISCN09",
            "element": [
                {
                    "name": "p24",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        53,
                        19
                    ]
                },
                {
                    "name": "p23",
                    "lxly": [
                        0,
                        20
                    ],
                    "rxry": [
                        53,
                        44
                    ]
                },
                {
                    "name": "p22",
                    "lxly": [
                        0,
                        45
                    ],
                    "rxry": [
                        53,
                        75
                    ]
                },
                {
                    "name": "p21",
                    "lxly": [
                        0,
                        76
                    ],
                    "rxry": [
                        53,
                        109
                    ]
                },
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        109
                    ],
                    "rxry": [
                        53,
                        153
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        154
                    ],
                    "rxry": [
                        53,
                        162
                    ]
                },
                {
                    "name": "p11",
                    "lxly": [
                        0,
                        163
                    ],
                    "rxry": [
                        53,
                        172
                    ]
                },
                {
                    "name": "q11",
                    "lxly": [
                        0,
                        173
                    ],
                    "rxry": [
                        53,
                        180
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        181
                    ],
                    "rxry": [
                        53,
                        233
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        234
                    ],
                    "rxry": [
                        53,
                        287
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        287
                    ],
                    "rxry": [
                        53,
                        341
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        341
                    ],
                    "rxry": [
                        53,
                        373
                    ]
                },
                {
                    "name": "q31",
                    "lxly": [
                        0,
                        373
                    ],
                    "rxry": [
                        53,
                        413
                    ]
                },
                {
                    "name": "q32",
                    "lxly": [
                        0,
                        414
                    ],
                    "rxry": [
                        53,
                        419
                    ]
                },
                {
                    "name": "q33",
                    "lxly": [
                        0,
                        420
                    ],
                    "rxry": [
                        53,
                        440
                    ]
                },
                {
                    "name": "q34",
                    "lxly": [
                        0,
                        440
                    ],
                    "rxry": [
                        53,
                        521
                    ]
                }
            ]
        },
        {
            "name": "Chrom10ISCN09",
            "element": [
                {
                    "name": "p15",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        52,
                        20
                    ]
                },
                {
                    "name": "p14",
                    "lxly": [
                        0,
                        20
                    ],
                    "rxry": [
                        52,
                        34
                    ]
                },
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        34
                    ],
                    "rxry": [
                        52,
                        76
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        77
                    ],
                    "rxry": [
                        52,
                        97
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        98
                    ],
                    "rxry": [
                        52,
                        158
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        159
                    ],
                    "rxry": [
                        52,
                        165
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        166
                    ],
                    "rxry": [
                        52,
                        173
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        174
                    ],
                    "rxry": [
                        52,
                        199
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        200
                    ],
                    "rxry": [
                        52,
                        257
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        257
                    ],
                    "rxry": [
                        52,
                        311
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        312
                    ],
                    "rxry": [
                        52,
                        354
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        354
                    ],
                    "rxry": [
                        52,
                        396
                    ]
                },
                {
                    "name": "q25",
                    "lxly": [
                        0,
                        395
                    ],
                    "rxry": [
                        52,
                        438
                    ]
                },
                {
                    "name": "q26",
                    "lxly": [
                        0,
                        438
                    ],
                    "rxry": [
                        52,
                        506
                    ]
                }
            ]
        },
        {
            "name": "Chrom11ISCN09",
            "element": [
                {
                    "name": "p15",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        53,
                        94
                    ]
                },
                {
                    "name": "p14",
                    "lxly": [
                        0,
                        94
                    ],
                    "rxry": [
                        53,
                        128
                    ]
                },
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        128
                    ],
                    "rxry": [
                        53,
                        138
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        138
                    ],
                    "rxry": [
                        53,
                        160
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        161
                    ],
                    "rxry": [
                        53,
                        193
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        194
                    ],
                    "rxry": [
                        53,
                        203
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        203
                    ],
                    "rxry": [
                        53,
                        216
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        217
                    ],
                    "rxry": [
                        53,
                        243
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        244
                    ],
                    "rxry": [
                        53,
                        302
                    ]
                },
                {
                    "name": "q14",
                    "lxly": [
                        0,
                        302
                    ],
                    "rxry": [
                        53,
                        345
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        345
                    ],
                    "rxry": [
                        53,
                        349
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        350
                    ],
                    "rxry": [
                        53,
                        392
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        393
                    ],
                    "rxry": [
                        53,
                        471
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        472
                    ],
                    "rxry": [
                        53,
                        485
                    ]
                },
                {
                    "name": "q25",
                    "lxly": [
                        0,
                        486
                    ],
                    "rxry": [
                        53,
                        505
                    ]
                }
            ]
        },
        {
            "name": "Chrom12ISCN09",
            "element": [
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        53,
                        54
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        54
                    ],
                    "rxry": [
                        53,
                        98
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        98
                    ],
                    "rxry": [
                        53,
                        120
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        121
                    ],
                    "rxry": [
                        53,
                        128
                    ]
                },
                {
                    "name": "q11",
                    "lxly": [
                        0,
                        129
                    ],
                    "rxry": [
                        53,
                        140
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        141
                    ],
                    "rxry": [
                        53,
                        160
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        160
                    ],
                    "rxry": [
                        53,
                        218
                    ]
                },
                {
                    "name": "q14",
                    "lxly": [
                        0,
                        219
                    ],
                    "rxry": [
                        53,
                        252
                    ]
                },
                {
                    "name": "q15",
                    "lxly": [
                        0,
                        252
                    ],
                    "rxry": [
                        53,
                        269
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        270
                    ],
                    "rxry": [
                        53,
                        324
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        324
                    ],
                    "rxry": [
                        53,
                        339
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        340
                    ],
                    "rxry": [
                        53,
                        359
                    ]
                },
                {
                    "name": "q24.1",
                    "lxly": [
                        0,
                        360
                    ],
                    "rxry": [
                        53,
                        432
                    ]
                },
                {
                    "name": "q24.2",
                    "lxly": [
                        0,
                        433
                    ],
                    "rxry": [
                        53,
                        450
                    ]
                },
                {
                    "name": "q24.3",
                    "lxly": [
                        0,
                        451
                    ],
                    "rxry": [
                        53,
                        489
                    ]
                }
            ]
        },
        {
            "name": "Chrom13ISCN09",
            "element": [
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        55,
                        21
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        22
                    ],
                    "rxry": [
                        55,
                        32
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        33
                    ],
                    "rxry": [
                        55,
                        53
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        54
                    ],
                    "rxry": [
                        55,
                        63
                    ]
                },
                {
                    "name": "q11",
                    "lxly": [
                        0,
                        64
                    ],
                    "rxry": [
                        55,
                        73
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        74
                    ],
                    "rxry": [
                        55,
                        135
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        136
                    ],
                    "rxry": [
                        55,
                        158
                    ]
                },
                {
                    "name": "q14",
                    "lxly": [
                        0,
                        157
                    ],
                    "rxry": [
                        55,
                        207
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        207
                    ],
                    "rxry": [
                        55,
                        257
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        257
                    ],
                    "rxry": [
                        55,
                        281
                    ]
                },
                {
                    "name": "q31",
                    "lxly": [
                        0,
                        281
                    ],
                    "rxry": [
                        55,
                        328
                    ]
                },
                {
                    "name": "q32",
                    "lxly": [
                        0,
                        328
                    ],
                    "rxry": [
                        55,
                        369
                    ]
                },
                {
                    "name": "q33",
                    "lxly": [
                        0,
                        370
                    ],
                    "rxry": [
                        55,
                        391
                    ]
                },
                {
                    "name": "q34",
                    "lxly": [
                        0,
                        391
                    ],
                    "rxry": [
                        55,
                        414
                    ]
                }
            ]
        },
        {
            "name": "Chrom14ISCN09",
            "element": [
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        53,
                        21
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        22
                    ],
                    "rxry": [
                        53,
                        33
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        34
                    ],
                    "rxry": [
                        53,
                        53
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        54
                    ],
                    "rxry": [
                        53,
                        64
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        65
                    ],
                    "rxry": [
                        53,
                        73
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        74
                    ],
                    "rxry": [
                        53,
                        104
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        104
                    ],
                    "rxry": [
                        53,
                        119
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        120
                    ],
                    "rxry": [
                        53,
                        127
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        128
                    ],
                    "rxry": [
                        53,
                        168
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        169
                    ],
                    "rxry": [
                        53,
                        194
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        195
                    ],
                    "rxry": [
                        53,
                        209
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        210
                    ],
                    "rxry": [
                        53,
                        308
                    ]
                },
                {
                    "name": "q31",
                    "lxly": [
                        0,
                        308
                    ],
                    "rxry": [
                        53,
                        340
                    ]
                },
                {
                    "name": "q32",
                    "lxly": [
                        0,
                        340
                    ],
                    "rxry": [
                        53,
                        401
                    ]
                }
            ]
        },
        {
            "name": "Chrom15ISCN09",
            "element": [
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        51,
                        21
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        22
                    ],
                    "rxry": [
                        51,
                        32
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        33
                    ],
                    "rxry": [
                        51,
                        56
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        57
                    ],
                    "rxry": [
                        51,
                        64
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        65
                    ],
                    "rxry": [
                        51,
                        72
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        73
                    ],
                    "rxry": [
                        51,
                        109
                    ]
                },
                {
                    "name": "q12-14",
                    "lxly": [
                        0,
                        109
                    ],
                    "rxry": [
                        51,
                        132
                    ]
                },
                {
                    "name": "q15",
                    "lxly": [
                        0,
                        133
                    ],
                    "rxry": [
                        51,
                        155
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        155
                    ],
                    "rxry": [
                        51,
                        199
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        199
                    ],
                    "rxry": [
                        51,
                        243
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        244
                    ],
                    "rxry": [
                        51,
                        250
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        251
                    ],
                    "rxry": [
                        51,
                        306
                    ]
                },
                {
                    "name": "q25",
                    "lxly": [
                        0,
                        307
                    ],
                    "rxry": [
                        51,
                        329
                    ]
                },
                {
                    "name": "q26.1",
                    "lxly": [
                        0,
                        329
                    ],
                    "rxry": [
                        51,
                        357
                    ]
                },
                {
                    "name": "q26.2",
                    "lxly": [
                        0,
                        358
                    ],
                    "rxry": [
                        51,
                        367
                    ]
                },
                {
                    "name": "q26.3",
                    "lxly": [
                        0,
                        368
                    ],
                    "rxry": [
                        51,
                        378
                    ]
                }
            ]
        },
        {
            "name": "Chrom16ISCN09",
            "element": [
                {
                    "name": "p13.3",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        52,
                        35
                    ]
                },
                {
                    "name": "p13.2",
                    "lxly": [
                        0,
                        36
                    ],
                    "rxry": [
                        52,
                        53
                    ]
                },
                {
                    "name": "p13.1",
                    "lxly": [
                        0,
                        54
                    ],
                    "rxry": [
                        52,
                        79
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        80
                    ],
                    "rxry": [
                        52,
                        102
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        102
                    ],
                    "rxry": [
                        52,
                        137
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        138
                    ],
                    "rxry": [
                        52,
                        145
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        146
                    ],
                    "rxry": [
                        52,
                        155
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        155
                    ],
                    "rxry": [
                        52,
                        186
                    ]
                },
                {
                    "name": "q12-13",
                    "lxly": [
                        0,
                        187
                    ],
                    "rxry": [
                        52,
                        226
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        226
                    ],
                    "rxry": [
                        52,
                        256
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        256
                    ],
                    "rxry": [
                        52,
                        276
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        276
                    ],
                    "rxry": [
                        52,
                        307
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        307
                    ],
                    "rxry": [
                        52,
                        348
                    ]
                }
            ]
        },
        {
            "name": "Chrom17ISCN09",
            "element": [
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        65,
                        58
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        59
                    ],
                    "rxry": [
                        65,
                        79
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        80
                    ],
                    "rxry": [
                        65,
                        107
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        108
                    ],
                    "rxry": [
                        65,
                        115
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        115
                    ],
                    "rxry": [
                        65,
                        125
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        126
                    ],
                    "rxry": [
                        65,
                        145
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        146
                    ],
                    "rxry": [
                        65,
                        163
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        164
                    ],
                    "rxry": [
                        65,
                        236
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        237
                    ],
                    "rxry": [
                        65,
                        269
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        269
                    ],
                    "rxry": [
                        65,
                        274
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        274
                    ],
                    "rxry": [
                        65,
                        306
                    ]
                },
                {
                    "name": "q25",
                    "lxly": [
                        0,
                        307
                    ],
                    "rxry": [
                        65,
                        338
                    ]
                }
            ]
        },
        {
            "name": "Chrom18ISCN09",
            "element": [
                {
                    "name": "p11.3",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        52,
                        17
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        17
                    ],
                    "rxry": [
                        52,
                        73
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        74
                    ],
                    "rxry": [
                        52,
                        81
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        82
                    ],
                    "rxry": [
                        52,
                        91
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        92
                    ],
                    "rxry": [
                        52,
                        107
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        108
                    ],
                    "rxry": [
                        52,
                        147
                    ]
                },
                {
                    "name": "q21.1",
                    "lxly": [
                        0,
                        148
                    ],
                    "rxry": [
                        52,
                        225
                    ]
                },
                {
                    "name": "q21.2",
                    "lxly": [
                        0,
                        226
                    ],
                    "rxry": [
                        52,
                        239
                    ]
                },
                {
                    "name": "q21.3",
                    "lxly": [
                        0,
                        240
                    ],
                    "rxry": [
                        52,
                        242
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        243
                    ],
                    "rxry": [
                        52,
                        277
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        277
                    ],
                    "rxry": [
                        52,
                        304
                    ]
                }
            ]
        },
        {
            "name": "Chrom19ISCN09",
            "element": [
                {
                    "name": "p13.3",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        52,
                        48
                    ]
                },
                {
                    "name": "p13.2",
                    "lxly": [
                        0,
                        49
                    ],
                    "rxry": [
                        52,
                        63
                    ]
                },
                {
                    "name": "p13.1",
                    "lxly": [
                        0,
                        64
                    ],
                    "rxry": [
                        52,
                        100
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        101
                    ],
                    "rxry": [
                        52,
                        112
                    ]
                },
                {
                    "name": "p11",
                    "lxly": [
                        0,
                        113
                    ],
                    "rxry": [
                        52,
                        121
                    ]
                },
                {
                    "name": "q11",
                    "lxly": [
                        0,
                        122
                    ],
                    "rxry": [
                        52,
                        131
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        132
                    ],
                    "rxry": [
                        52,
                        147
                    ]
                },
                {
                    "name": "q13.1",
                    "lxly": [
                        0,
                        148
                    ],
                    "rxry": [
                        52,
                        186
                    ]
                },
                {
                    "name": "q13.2",
                    "lxly": [
                        0,
                        188
                    ],
                    "rxry": [
                        52,
                        201
                    ]
                },
                {
                    "name": "q13.3",
                    "lxly": [
                        0,
                        202
                    ],
                    "rxry": [
                        52,
                        252
                    ]
                },
                {
                    "name": "q13.4",
                    "lxly": [
                        0,
                        252
                    ],
                    "rxry": [
                        52,
                        266
                    ]
                }
            ]
        },
        {
            "name": "Chrom20ISCN09",
            "element": [
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        51,
                        26
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        26
                    ],
                    "rxry": [
                        51,
                        61
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        62
                    ],
                    "rxry": [
                        51,
                        106
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        107
                    ],
                    "rxry": [
                        51,
                        112
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        113
                    ],
                    "rxry": [
                        51,
                        123
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        124
                    ],
                    "rxry": [
                        51,
                        169
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        170
                    ],
                    "rxry": [
                        51,
                        179
                    ]
                },
                {
                    "name": "q13.1",
                    "lxly": [
                        0,
                        180
                    ],
                    "rxry": [
                        51,
                        206
                    ]
                },
                {
                    "name": "q13.2",
                    "lxly": [
                        0,
                        207
                    ],
                    "rxry": [
                        51,
                        228
                    ]
                },
                {
                    "name": "q13.3",
                    "lxly": [
                        0,
                        229
                    ],
                    "rxry": [
                        51,
                        255
                    ]
                }
            ]
        },
        {
            "name": "Chrom21ISCN09",
            "element": [
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        53,
                        21
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        22
                    ],
                    "rxry": [
                        53,
                        31
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        32
                    ],
                    "rxry": [
                        53,
                        56
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        57
                    ],
                    "rxry": [
                        53,
                        63
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        64
                    ],
                    "rxry": [
                        53,
                        72
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        73
                    ],
                    "rxry": [
                        53,
                        84
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        85
                    ],
                    "rxry": [
                        53,
                        123
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        123
                    ],
                    "rxry": [
                        53,
                        197
                    ]
                }
            ]
        },
        {
            "name": "Chrom22ISCN09",
            "element": [
                {
                    "name": "p13",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        56,
                        20
                    ]
                },
                {
                    "name": "p12",
                    "lxly": [
                        0,
                        21
                    ],
                    "rxry": [
                        56,
                        32
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        33
                    ],
                    "rxry": [
                        56,
                        57
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        58
                    ],
                    "rxry": [
                        56,
                        63
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        64
                    ],
                    "rxry": [
                        56,
                        73
                    ]
                },
                {
                    "name": "q11.2",
                    "lxly": [
                        0,
                        74
                    ],
                    "rxry": [
                        56,
                        117
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        118
                    ],
                    "rxry": [
                        56,
                        139
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        140
                    ],
                    "rxry": [
                        56,
                        214
                    ]
                }
            ]
        },
        {
            "name": "ChromXISCN09",
            "element": [
                {
                    "name": "p22.3",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        53,
                        16
                    ]
                },
                {
                    "name": "p22.2",
                    "lxly": [
                        0,
                        16
                    ],
                    "rxry": [
                        53,
                        32
                    ]
                },
                {
                    "name": "p22.1",
                    "lxly": [
                        0,
                        32
                    ],
                    "rxry": [
                        53,
                        81
                    ]
                },
                {
                    "name": "p21",
                    "lxly": [
                        0,
                        81
                    ],
                    "rxry": [
                        53,
                        129
                    ]
                },
                {
                    "name": "p11.4",
                    "lxly": [
                        0,
                        130
                    ],
                    "rxry": [
                        53,
                        146
                    ]
                },
                {
                    "name": "p11.3",
                    "lxly": [
                        0,
                        147
                    ],
                    "rxry": [
                        53,
                        162
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        163
                    ],
                    "rxry": [
                        53,
                        208
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        209
                    ],
                    "rxry": [
                        53,
                        215
                    ]
                },
                {
                    "name": "q11",
                    "lxly": [
                        0,
                        216
                    ],
                    "rxry": [
                        53,
                        225
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        226
                    ],
                    "rxry": [
                        53,
                        236
                    ]
                },
                {
                    "name": "q13",
                    "lxly": [
                        0,
                        237
                    ],
                    "rxry": [
                        53,
                        296
                    ]
                },
                {
                    "name": "q21",
                    "lxly": [
                        0,
                        296
                    ],
                    "rxry": [
                        53,
                        350
                    ]
                },
                {
                    "name": "q22",
                    "lxly": [
                        0,
                        350
                    ],
                    "rxry": [
                        53,
                        409
                    ]
                },
                {
                    "name": "q23",
                    "lxly": [
                        0,
                        410
                    ],
                    "rxry": [
                        53,
                        424
                    ]
                },
                {
                    "name": "q24",
                    "lxly": [
                        0,
                        425
                    ],
                    "rxry": [
                        53,
                        483
                    ]
                },
                {
                    "name": "q25",
                    "lxly": [
                        0,
                        483
                    ],
                    "rxry": [
                        53,
                        506
                    ]
                },
                {
                    "name": "q26",
                    "lxly": [
                        0,
                        506
                    ],
                    "rxry": [
                        53,
                        546
                    ]
                },
                {
                    "name": "q27",
                    "lxly": [
                        0,
                        546
                    ],
                    "rxry": [
                        53,
                        574
                    ]
                },
                {
                    "name": "q28",
                    "lxly": [
                        0,
                        575
                    ],
                    "rxry": [
                        53,
                        600
                    ]
                }
            ]
        },
        {
            "name": "ChromYISCN09",
            "element": [
                {
                    "name": "p11.3",
                    "lxly": [
                        0,
                        0
                    ],
                    "rxry": [
                        54,
                        13
                    ]
                },
                {
                    "name": "p11.2",
                    "lxly": [
                        0,
                        13
                    ],
                    "rxry": [
                        54,
                        62
                    ]
                },
                {
                    "name": "p11.1",
                    "lxly": [
                        0,
                        63
                    ],
                    "rxry": [
                        54,
                        69
                    ]
                },
                {
                    "name": "q11.1",
                    "lxly": [
                        0,
                        70
                    ],
                    "rxry": [
                        54,
                        79
                    ]
                },
                {
                    "name": "q11.21",
                    "lxly": [
                        0,
                        80
                    ],
                    "rxry": [
                        54,
                        87
                    ]
                },
                {
                    "name": "q11.22",
                    "lxly": [
                        0,
                        87
                    ],
                    "rxry": [
                        54,
                        103
                    ]
                },
                {
                    "name": "q11.23",
                    "lxly": [
                        0,
                        103
                    ],
                    "rxry": [
                        54,
                        148
                    ]
                },
                {
                    "name": "q12",
                    "lxly": [
                        0,
                        149
                    ],
                    "rxry": [
                        54,
                        210
                    ]
                }
            ]
        }
    ]
}
