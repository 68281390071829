export const standard = {
	"type": 550,
	"padding": 35,
	"position": [{
			"name": "Chrom01ISCN09",
			"element": [{
				"lxly": [
					44,
					963
				]
			}]
		},
		{
			"name": "Chrom02ISCN09",
			"element": [{
				"lxly": [
					44,
					887
				]
			}]
		},
		{
			"name": "Chrom03ISCN09",
			"element": [{
				"lxly": [
					45,
					751
				]
			}]
		},
		{
			"name": "Chrom04ISCN09",
			"element": [{
				"lxly": [
					44,
					704
				]
			}]
		},
		{
			"name": "Chrom05ISCN09",
			"element": [{
				"lxly": [
					44,
					668
				]
			}]
		},
		{
			"name": "Chrom06ISCN09",
			"element": [{
				"lxly": [
					44,
					654
				]
			}]
		},
		{
			"name": "Chrom07ISCN09",
			"element": [{
				"lxly": [
					44,
					594
				]
			}]
		},
		{
			"name": "Chrom08ISCN09",
			"element": [{
				"lxly": [
					45,
					526
				]
			}]
		},
		{
			"name": "Chrom09ISCN09",
			"element": [{
				"lxly": [
					46,
					524
				]
			}]
		},
		{
			"name": "Chrom10ISCN09",
			"element": [{
				"lxly": [
					45,
					511
				]
			}]
		},
		{
			"name": "Chrom11ISCN09",
			"element": [{
				"lxly": [
					46,
					512
				]
			}]
		},
		{
			"name": "Chrom12ISCN09",
			"element": [{
				"lxly": [
					43,
					496
				]
			}]
		},
		{
			"name": "Chrom13ISCN09",
			"element": [{
				"lxly": [
					44,
					417
				]
			}]
		},
		{
			"name": "Chrom14ISCN09",
			"element": [{
				"lxly": [
					45,
					403
				]
			}]
		},
		{
			"name": "Chrom15ISCN09",
			"element": [{
				"lxly": [
					45,
					381
				]
			}]
		},
		{
			"name": "Chrom16ISCN09",
			"element": [{
				"lxly": [
					45,
					352
				]
			}]
		},
		{
			"name": "Chrom17ISCN09",
			"element": [{
				"lxly": [
					39,
					342
				]
			}]
		},
		{
			"name": "Chrom18ISCN09",
			"element": [{
				"lxly": [
					43,
					306
				]
			}]
		},
		{
			"name": "Chrom19ISCN09",
			"element": [{
				"lxly": [
					44,
					270
				]
			}]
		},
		{
			"name": "Chrom20ISCN09",
			"element": [{
				"lxly": [
					43,
					258
				]
			}]
		},
		{
			"name": "Chrom21ISCN09",
			"element": [{
				"lxly": [
					47,
					199
				]
			}]
		},
		{
			"name": "Chrom22ISCN09",
			"element": [{
				"lxly": [
					46,
					217
				]
			}]
		},
		{
			"name": "ChromXISCN09",
			"element": [{
				"lxly": [
					43,
					601
				]
			}]
		},
		{
			"name": "ChromYISCN09",
			"element": [{
				"lxly": [
					46,
					213
				]
			}]
		}
	],
	"banding": [{
			"name": "Chrom01ISCN09",
			"element": [{
					"name": "p36.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						44,
						15
					]
				},
				{
					"name": "p36.2",
					"lxly": [
						0,
						16
					],
					"rxry": [
						44,
						31
					]
				},
				{
					"name": "p36.1",
					"lxly": [
						0,
						33
					],
					"rxry": [
						44,
						65
					]
				},
				{
					"name": "p35",
					"lxly": [
						0,
						66
					],
					"rxry": [
						44,
						80
					]
				},
				{
					"name": "p34.3",
					"lxly": [
						0,
						81
					],
					"rxry": [
						44,
						101
					]
				},
				{
					"name": "p34.2",
					"lxly": [
						0,
						102
					],
					"rxry": [
						44,
						116
					]
				},
				{
					"name": "p34.1",
					"lxly": [
						0,
						117
					],
					"rxry": [
						44,
						147
					]
				},
				{
					"name": "p33",
					"lxly": [
						0,
						147
					],
					"rxry": [
						44,
						164
					]
				},
				{
					"name": "p32.3",
					"lxly": [
						0,
						164
					],
					"rxry": [
						44,
						180
					]
				},
				{
					"name": "p32.2",
					"lxly": [
						0,
						181
					],
					"rxry": [
						44,
						196
					]
				},
				{
					"name": "p32.1",
					"lxly": [
						0,
						197
					],
					"rxry": [
						44,
						219
					]
				},
				{
					"name": "p31.3",
					"lxly": [
						0,
						220
					],
					"rxry": [
						44,
						247
					]
				},
				{
					"name": "p31.2",
					"lxly": [
						0,
						247
					],
					"rxry": [
						44,
						254
					]
				},
				{
					"name": "p31.1",
					"lxly": [
						0,
						254
					],
					"rxry": [
						44,
						283
					]
				},
				{
					"name": "p22.3",
					"lxly": [
						0,
						283
					],
					"rxry": [
						44,
						304
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						305
					],
					"rxry": [
						44,
						313
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						314
					],
					"rxry": [
						44,
						346
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						347
					],
					"rxry": [
						44,
						384
					]
				},
				{
					"name": "p13.3",
					"lxly": [
						0,
						384
					],
					"rxry": [
						44,
						412
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						411
					],
					"rxry": [
						44,
						424
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						425
					],
					"rxry": [
						44,
						438
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						439
					],
					"rxry": [
						44,
						450
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						451
					],
					"rxry": [
						44,
						462
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						463
					],
					"rxry": [
						44,
						473
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						474
					],
					"rxry": [
						44,
						558
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						559
					],
					"rxry": [
						44,
						593
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						594
					],
					"rxry": [
						44,
						608
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						609
					],
					"rxry": [
						44,
						641
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						641
					],
					"rxry": [
						44,
						653
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						654
					],
					"rxry": [
						44,
						659
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						660
					],
					"rxry": [
						44,
						679
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						679
					],
					"rxry": [
						44,
						684
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						685
					],
					"rxry": [
						44,
						695
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						696
					],
					"rxry": [
						44,
						740
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						741
					],
					"rxry": [
						44,
						784
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						784
					],
					"rxry": [
						44,
						812
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						813
					],
					"rxry": [
						44,
						824
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						825
					],
					"rxry": [
						44,
						831
					]
				},
				{
					"name": "q41",
					"lxly": [
						0,
						831
					],
					"rxry": [
						44,
						864
					]
				},
				{
					"name": "q42.1",
					"lxly": [
						0,
						865
					],
					"rxry": [
						44,
						910
					]
				},
				{
					"name": "q42.2",
					"lxly": [
						0,
						911
					],
					"rxry": [
						44,
						919
					]
				},
				{
					"name": "q42.3",
					"lxly": [
						0,
						920
					],
					"rxry": [
						44,
						927
					]
				},
				{
					"name": "q43",
					"lxly": [
						0,
						928
					],
					"rxry": [
						44,
						946
					]
				},
				{
					"name": "q44",
					"lxly": [
						0,
						946
					],
					"rxry": [
						44,
						960
					]
				}
			]
		},
		{
			"name": "Chrom02ISCN09",
			"element": [{
					"name": "p25.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						44,
						10
					]
				},
				{
					"name": "p25.2",
					"lxly": [
						0,
						10
					],
					"rxry": [
						44,
						16
					]
				},
				{
					"name": "p25.1",
					"lxly": [
						0,
						17
					],
					"rxry": [
						44,
						42
					]
				},
				{
					"name": "p24",
					"lxly": [
						0,
						42
					],
					"rxry": [
						44,
						66
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						67
					],
					"rxry": [
						44,
						104
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						105
					],
					"rxry": [
						44,
						135
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						135
					],
					"rxry": [
						44,
						166
					]
				},
				{
					"name": "p16",
					"lxly": [
						0,
						167
					],
					"rxry": [
						44,
						199
					]
				},
				{
					"name": "p15",
					"lxly": [
						0,
						200
					],
					"rxry": [
						44,
						216
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						217
					],
					"rxry": [
						44,
						232
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						232
					],
					"rxry": [
						44,
						284
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						285
					],
					"rxry": [
						44,
						312
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						313
					],
					"rxry": [
						44,
						336
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						337
					],
					"rxry": [
						44,
						345
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						346
					],
					"rxry": [
						44,
						353
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						354
					],
					"rxry": [
						44,
						376
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						377
					],
					"rxry": [
						44,
						394
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						395
					],
					"rxry": [
						44,
						415
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						416
					],
					"rxry": [
						44,
						427
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						428
					],
					"rxry": [
						44,
						439
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						440
					],
					"rxry": [
						44,
						452
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						452
					],
					"rxry": [
						44,
						485
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						486
					],
					"rxry": [
						44,
						491
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						492
					],
					"rxry": [
						44,
						512
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						512
					],
					"rxry": [
						44,
						543
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						543
					],
					"rxry": [
						44,
						563
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						563
					],
					"rxry": [
						44,
						584
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						584
					],
					"rxry": [
						44,
						591
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						592
					],
					"rxry": [
						44,
						614
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						614
					],
					"rxry": [
						44,
						673
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						673
					],
					"rxry": [
						44,
						707
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						708
					],
					"rxry": [
						44,
						712
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						713
					],
					"rxry": [
						44,
						731
					]
				},
				{
					"name": "q33",
					"lxly": [
						0,
						732
					],
					"rxry": [
						44,
						782
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						782
					],
					"rxry": [
						44,
						798
					]
				},
				{
					"name": "q35",
					"lxly": [
						0,
						799
					],
					"rxry": [
						44,
						821
					]
				},
				{
					"name": "q36",
					"lxly": [
						0,
						822
					],
					"rxry": [
						44,
						840
					]
				},
				{
					"name": "q37.1",
					"lxly": [
						0,
						841
					],
					"rxry": [
						44,
						862
					]
				},
				{
					"name": "q37.2",
					"lxly": [
						0,
						863
					],
					"rxry": [
						44,
						868
					]
				},
				{
					"name": "q37.3",
					"lxly": [
						0,
						870
					],
					"rxry": [
						44,
						884
					]
				}
			]
		},
		{
			"name": "Chrom03ISCN09",
			"element": [{
					"name": "p26",
					"lxly": [
						0,
						0
					],
					"rxry": [
						45,
						24
					]
				},
				{
					"name": "p25",
					"lxly": [
						0,
						24
					],
					"rxry": [
						45,
						55
					]
				},
				{
					"name": "p24.3",
					"lxly": [
						0,
						56
					],
					"rxry": [
						45,
						72
					]
				},
				{
					"name": "p24.2",
					"lxly": [
						0,
						73
					],
					"rxry": [
						45,
						81
					]
				},
				{
					"name": "p24.1",
					"lxly": [
						0,
						82
					],
					"rxry": [
						45,
						95
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						94
					],
					"rxry": [
						45,
						108
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						107
					],
					"rxry": [
						45,
						120
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						121
					],
					"rxry": [
						45,
						196
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						196
					],
					"rxry": [
						45,
						205
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						206
					],
					"rxry": [
						45,
						222
					]
				},
				{
					"name": "p14.3",
					"lxly": [
						0,
						222
					],
					"rxry": [
						45,
						238
					]
				},
				{
					"name": "p14.2",
					"lxly": [
						0,
						239
					],
					"rxry": [
						45,
						243
					]
				},
				{
					"name": "p14.1",
					"lxly": [
						0,
						243
					],
					"rxry": [
						45,
						257
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						258
					],
					"rxry": [
						45,
						297
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						298
					],
					"rxry": [
						45,
						326
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						326
					],
					"rxry": [
						45,
						337
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						338
					],
					"rxry": [
						45,
						343
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						344
					],
					"rxry": [
						45,
						353
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						354
					],
					"rxry": [
						45,
						370
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						371
					],
					"rxry": [
						45,
						375
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						375
					],
					"rxry": [
						45,
						407
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						407
					],
					"rxry": [
						45,
						422
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						422
					],
					"rxry": [
						45,
						452
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						452
					],
					"rxry": [
						45,
						508
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						509
					],
					"rxry": [
						45,
						532
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						533
					],
					"rxry": [
						45,
						553
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						554
					],
					"rxry": [
						45,
						570
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						570
					],
					"rxry": [
						45,
						574
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						575
					],
					"rxry": [
						45,
						583
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						584
					],
					"rxry": [
						45,
						617
					]
				},
				{
					"name": "q26.1",
					"lxly": [
						0,
						617
					],
					"rxry": [
						45,
						636
					]
				},
				{
					"name": "q26.2",
					"lxly": [
						0,
						636
					],
					"rxry": [
						45,
						654
					]
				},
				{
					"name": "q26.3",
					"lxly": [
						0,
						655
					],
					"rxry": [
						45,
						674
					]
				},
				{
					"name": "q27",
					"lxly": [
						0,
						675
					],
					"rxry": [
						45,
						711
					]
				},
				{
					"name": "q28",
					"lxly": [
						0,
						711
					],
					"rxry": [
						45,
						724
					]
				},
				{
					"name": "q29",
					"lxly": [
						0,
						724
					],
					"rxry": [
						45,
						747
					]
				}
			]
		},
		{
			"name": "Chrom04ISCN09",
			"element": [{
					"name": "p16.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						44,
						10
					]
				},
				{
					"name": "p16.2",
					"lxly": [
						0,
						11
					],
					"rxry": [
						44,
						17
					]
				},
				{
					"name": "p16.1",
					"lxly": [
						0,
						18
					],
					"rxry": [
						44,
						53
					]
				},
				{
					"name": "p15.3",
					"lxly": [
						0,
						54
					],
					"rxry": [
						44,
						76
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						77
					],
					"rxry": [
						44,
						96
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						96
					],
					"rxry": [
						44,
						126
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						126
					],
					"rxry": [
						44,
						155
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						155
					],
					"rxry": [
						44,
						173
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						174
					],
					"rxry": [
						44,
						180
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						181
					],
					"rxry": [
						44,
						188
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						189
					],
					"rxry": [
						44,
						199
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						200
					],
					"rxry": [
						44,
						226
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						227
					],
					"rxry": [
						44,
						251
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						252
					],
					"rxry": [
						44,
						273
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						254
					],
					"rxry": [
						44,
						272
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						272
					],
					"rxry": [
						44,
						296
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						297
					],
					"rxry": [
						44,
						314
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						315
					],
					"rxry": [
						44,
						333
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						333
					],
					"rxry": [
						44,
						364
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						364
					],
					"rxry": [
						44,
						384
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						383
					],
					"rxry": [
						44,
						398
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						398
					],
					"rxry": [
						44,
						416
					]
				},
				{
					"name": "q26",
					"lxly": [
						0,
						418
					],
					"rxry": [
						44,
						450
					]
				},
				{
					"name": "q27",
					"lxly": [
						0,
						451
					],
					"rxry": [
						44,
						472
					]
				},
				{
					"name": "q28",
					"lxly": [
						0,
						473
					],
					"rxry": [
						44,
						514
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						515
					],
					"rxry": [
						44,
						541
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						542
					],
					"rxry": [
						44,
						554
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						555
					],
					"rxry": [
						44,
						578
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						578
					],
					"rxry": [
						44,
						612
					]
				},
				{
					"name": "q33",
					"lxly": [
						0,
						612
					],
					"rxry": [
						44,
						645
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						645
					],
					"rxry": [
						44,
						674
					]
				},
				{
					"name": "q35",
					"lxly": [
						0,
						674
					],
					"rxry": [
						44,
						702
					]
				}
			]
		},
		{
			"name": "Chrom05ISCN09",
			"element": [{
					"name": "p15.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						44,
						36
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						37
					],
					"rxry": [
						44,
						49
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						50
					],
					"rxry": [
						44,
						70
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						71
					],
					"rxry": [
						44,
						116
					]
				},
				{
					"name": "p13.3",
					"lxly": [
						0,
						116
					],
					"rxry": [
						44,
						139
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						140
					],
					"rxry": [
						44,
						144
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						145
					],
					"rxry": [
						44,
						154
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						154
					],
					"rxry": [
						44,
						167
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						168
					],
					"rxry": [
						44,
						175
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						176
					],
					"rxry": [
						44,
						184
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						185
					],
					"rxry": [
						44,
						214
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						214
					],
					"rxry": [
						44,
						236
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						237
					],
					"rxry": [
						44,
						257
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						259
					],
					"rxry": [
						44,
						268
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						269
					],
					"rxry": [
						44,
						291
					]
				},
				{
					"name": "q14",
					"lxly": [
						0,
						291
					],
					"rxry": [
						44,
						331
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						331
					],
					"rxry": [
						44,
						357
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						358
					],
					"rxry": [
						44,
						398
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						398
					],
					"rxry": [
						44,
						420
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						421
					],
					"rxry": [
						44,
						440
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						440
					],
					"rxry": [
						44,
						445
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						445
					],
					"rxry": [
						44,
						463
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						464
					],
					"rxry": [
						44,
						510
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						512
					],
					"rxry": [
						44,
						517
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						518
					],
					"rxry": [
						44,
						542
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						542
					],
					"rxry": [
						44,
						562
					]
				},
				{
					"name": "q33.1",
					"lxly": [
						0,
						562
					],
					"rxry": [
						44,
						574
					]
				},
				{
					"name": "q33.2",
					"lxly": [
						0,
						575
					],
					"rxry": [
						44,
						580
					]
				},
				{
					"name": "q33.3",
					"lxly": [
						0,
						581
					],
					"rxry": [
						44,
						605
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						605
					],
					"rxry": [
						44,
						625
					]
				},
				{
					"name": "q35.1",
					"lxly": [
						0,
						625
					],
					"rxry": [
						44,
						637
					]
				},
				{
					"name": "q35.2",
					"lxly": [
						0,
						638
					],
					"rxry": [
						44,
						643
					]
				},
				{
					"name": "q35.3",
					"lxly": [
						0,
						644
					],
					"rxry": [
						44,
						665
					]
				}
			]
		},
		{
			"name": "Chrom06ISCN09",
			"element": [{
					"name": "p25",
					"lxly": [
						0,
						0
					],
					"rxry": [
						44,
						27
					]
				},
				{
					"name": "p24",
					"lxly": [
						0,
						27
					],
					"rxry": [
						44,
						38
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						38
					],
					"rxry": [
						44,
						57
					]
				},
				{
					"name": "p22.3",
					"lxly": [
						0,
						57
					],
					"rxry": [
						44,
						79
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						79
					],
					"rxry": [
						44,
						84
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						84
					],
					"rxry": [
						44,
						96
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						97
					],
					"rxry": [
						44,
						138
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						139
					],
					"rxry": [
						44,
						147
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						148
					],
					"rxry": [
						44,
						181
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						182
					],
					"rxry": [
						44,
						212
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						213
					],
					"rxry": [
						44,
						215
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						216
					],
					"rxry": [
						44,
						223
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						224
					],
					"rxry": [
						44,
						231
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						232
					],
					"rxry": [
						44,
						263
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						263
					],
					"rxry": [
						44,
						296
					]
				},
				{
					"name": "q14",
					"lxly": [
						0,
						296
					],
					"rxry": [
						44,
						326
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						326
					],
					"rxry": [
						44,
						355
					]
				},
				{
					"name": "q16.1",
					"lxly": [
						0,
						355
					],
					"rxry": [
						44,
						378
					]
				},
				{
					"name": "q16.2",
					"lxly": [
						0,
						379
					],
					"rxry": [
						44,
						384
					]
				},
				{
					"name": "q16.3",
					"lxly": [
						0,
						385
					],
					"rxry": [
						44,
						400
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						401
					],
					"rxry": [
						44,
						441
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						441
					],
					"rxry": [
						44,
						462
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						462
					],
					"rxry": [
						44,
						468
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						469
					],
					"rxry": [
						44,
						493
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						494
					],
					"rxry": [
						44,
						501
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						502
					],
					"rxry": [
						44,
						506
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						508
					],
					"rxry": [
						44,
						538
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						539
					],
					"rxry": [
						44,
						560
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						560
					],
					"rxry": [
						44,
						586
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						586
					],
					"rxry": [
						44,
						599
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						600
					],
					"rxry": [
						44,
						619
					]
				},
				{
					"name": "q26",
					"lxly": [
						0,
						621
					],
					"rxry": [
						44,
						633
					]
				},
				{
					"name": "q27",
					"lxly": [
						0,
						634
					],
					"rxry": [
						44,
						651
					]
				}
			]
		},
		{
			"name": "Chrom07ISCN09",
			"element": [{
					"name": "p22",
					"lxly": [
						0,
						0
					],
					"rxry": [
						44,
						19
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						19
					],
					"rxry": [
						44,
						43
					]
				},
				{
					"name": "p15.3",
					"lxly": [
						0,
						44
					],
					"rxry": [
						44,
						64
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						65
					],
					"rxry": [
						44,
						71
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						72
					],
					"rxry": [
						44,
						112
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						111
					],
					"rxry": [
						44,
						136
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						136
					],
					"rxry": [
						44,
						175
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						176
					],
					"rxry": [
						44,
						191
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						191
					],
					"rxry": [
						44,
						200
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						201
					],
					"rxry": [
						44,
						207
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						208
					],
					"rxry": [
						44,
						215
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						216
					],
					"rxry": [
						44,
						239
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						240
					],
					"rxry": [
						44,
						254
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						255
					],
					"rxry": [
						44,
						291
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						291
					],
					"rxry": [
						44,
						322
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						322
					],
					"rxry": [
						44,
						331
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						332
					],
					"rxry": [
						44,
						351
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						350
					],
					"rxry": [
						44,
						421
					]
				},
				{
					"name": "q31.1",
					"lxly": [
						0,
						421
					],
					"rxry": [
						44,
						434
					]
				},
				{
					"name": "q31.2",
					"lxly": [
						0,
						434
					],
					"rxry": [
						44,
						441
					]
				},
				{
					"name": "q31.3",
					"lxly": [
						0,
						441
					],
					"rxry": [
						44,
						479
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						479
					],
					"rxry": [
						44,
						517
					]
				},
				{
					"name": "q33",
					"lxly": [
						0,
						518
					],
					"rxry": [
						44,
						526
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						527
					],
					"rxry": [
						44,
						539
					]
				},
				{
					"name": "q35",
					"lxly": [
						0,
						539
					],
					"rxry": [
						44,
						555
					]
				},
				{
					"name": "q36",
					"lxly": [
						0,
						555
					],
					"rxry": [
						44,
						592
					]
				}
			]
		},
		{
			"name": "Chrom08ISCN09",
			"element": [{
					"name": "p23.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						45,
						15
					]
				},
				{
					"name": "p23.2",
					"lxly": [
						0,
						6
					],
					"rxry": [
						45,
						15
					]
				},
				{
					"name": "p23.1",
					"lxly": [
						0,
						15
					],
					"rxry": [
						45,
						38
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						39
					],
					"rxry": [
						45,
						57
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						57
					],
					"rxry": [
						45,
						86
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						88
					],
					"rxry": [
						45,
						95
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						96
					],
					"rxry": [
						45,
						115
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						115
					],
					"rxry": [
						45,
						133
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						133
					],
					"rxry": [
						45,
						152
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						153
					],
					"rxry": [
						45,
						158
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						159
					],
					"rxry": [
						45,
						167
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						168
					],
					"rxry": [
						45,
						174
					]
				},
				{
					"name": "q11.22",
					"lxly": [
						0,
						174
					],
					"rxry": [
						45,
						190
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						190
					],
					"rxry": [
						45,
						209
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						210
					],
					"rxry": [
						45,
						227
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						226
					],
					"rxry": [
						45,
						263
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						264
					],
					"rxry": [
						45,
						296
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						296
					],
					"rxry": [
						45,
						310
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						310
					],
					"rxry": [
						45,
						337
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						337
					],
					"rxry": [
						45,
						354
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						355
					],
					"rxry": [
						45,
						363
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						364
					],
					"rxry": [
						45,
						394
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						395
					],
					"rxry": [
						45,
						427
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						427
					],
					"rxry": [
						45,
						471
					]
				},
				{
					"name": "q24.21",
					"lxly": [
						0,
						472
					],
					"rxry": [
						45,
						480
					]
				},
				{
					"name": "q24.22",
					"lxly": [
						0,
						481
					],
					"rxry": [
						45,
						487
					]
				},
				{
					"name": "q24.23",
					"lxly": [
						0,
						487
					],
					"rxry": [
						45,
						503
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						504
					],
					"rxry": [
						45,
						523
					]
				}
			]
		},
		{
			"name": "Chrom09ISCN09",
			"element": [{
					"name": "p24",
					"lxly": [
						0,
						0
					],
					"rxry": [
						46,
						33
					]
				},
				{
					"name": "p23",
					"lxly": [
						0,
						33
					],
					"rxry": [
						46,
						54
					]
				},
				{
					"name": "p22",
					"lxly": [
						0,
						54
					],
					"rxry": [
						46,
						81
					]
				},
				{
					"name": "p21",
					"lxly": [
						0,
						82
					],
					"rxry": [
						46,
						108
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						108
					],
					"rxry": [
						46,
						155
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						155
					],
					"rxry": [
						46,
						163
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						164
					],
					"rxry": [
						46,
						172
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						173
					],
					"rxry": [
						46,
						178
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						179
					],
					"rxry": [
						46,
						234
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						235
					],
					"rxry": [
						46,
						262
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						262
					],
					"rxry": [
						46,
						289
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						289
					],
					"rxry": [
						46,
						302
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						302
					],
					"rxry": [
						46,
						328
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						329
					],
					"rxry": [
						46,
						350
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						351
					],
					"rxry": [
						46,
						356
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						357
					],
					"rxry": [
						46,
						387
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						387
					],
					"rxry": [
						46,
						411
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						412
					],
					"rxry": [
						46,
						426
					]
				},
				{
					"name": "q33",
					"lxly": [
						0,
						427
					],
					"rxry": [
						46,
						454
					]
				},
				{
					"name": "q34.1",
					"lxly": [
						0,
						454
					],
					"rxry": [
						46,
						478
					]
				},
				{
					"name": "q34.2",
					"lxly": [
						0,
						479
					],
					"rxry": [
						46,
						487
					]
				},
				{
					"name": "q34.3",
					"lxly": [
						0,
						488
					],
					"rxry": [
						46,
						522
					]
				}
			]
		},
		{
			"name": "Chrom10ISCN09",
			"element": [{
					"name": "p15",
					"lxly": [
						0,
						0
					],
					"rxry": [
						45,
						24
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						24
					],
					"rxry": [
						45,
						40
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						41
					],
					"rxry": [
						45,
						67
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						67
					],
					"rxry": [
						45,
						88
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						88
					],
					"rxry": [
						45,
						99
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						100
					],
					"rxry": [
						45,
						112
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						112
					],
					"rxry": [
						45,
						158
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						159
					],
					"rxry": [
						45,
						164
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						165
					],
					"rxry": [
						45,
						172
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						173
					],
					"rxry": [
						45,
						202
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						202
					],
					"rxry": [
						45,
						230
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						230
					],
					"rxry": [
						45,
						239
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						239
					],
					"rxry": [
						45,
						262
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						262
					],
					"rxry": [
						45,
						288
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						289
					],
					"rxry": [
						45,
						294
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						295
					],
					"rxry": [
						45,
						310
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						310
					],
					"rxry": [
						45,
						325
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						326
					],
					"rxry": [
						45,
						338
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						338
					],
					"rxry": [
						45,
						353
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						353
					],
					"rxry": [
						45,
						361
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						362
					],
					"rxry": [
						45,
						367
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						368
					],
					"rxry": [
						45,
						397
					]
				},
				{
					"name": "q25.1",
					"lxly": [
						0,
						397
					],
					"rxry": [
						45,
						416
					]
				},
				{
					"name": "q25.2",
					"lxly": [
						0,
						416
					],
					"rxry": [
						45,
						425
					]
				},
				{
					"name": "q25.3",
					"lxly": [
						0,
						426
					],
					"rxry": [
						45,
						437
					]
				},
				{
					"name": "q26.1",
					"lxly": [
						0,
						437
					],
					"rxry": [
						45,
						475
					]
				},
				{
					"name": "q26.2",
					"lxly": [
						0,
						476
					],
					"rxry": [
						45,
						485
					]
				},
				{
					"name": "q26.3",
					"lxly": [
						0,
						484
					],
					"rxry": [
						45,
						508
					]
				}
			]
		},
		{
			"name": "Chrom11ISCN09",
			"element": [{
					"name": "p15.5",
					"lxly": [
						0,
						0
					],
					"rxry": [
						46,
						22
					]
				},
				{
					"name": "p15.4",
					"lxly": [
						0,
						22
					],
					"rxry": [
						46,
						30
					]
				},
				{
					"name": "p15.3",
					"lxly": [
						0,
						31
					],
					"rxry": [
						46,
						47
					]
				},
				{
					"name": "p15.2",
					"lxly": [
						0,
						48
					],
					"rxry": [
						46,
						53
					]
				},
				{
					"name": "p15.1",
					"lxly": [
						0,
						54
					],
					"rxry": [
						46,
						84
					]
				},
				{
					"name": "p14",
					"lxly": [
						0,
						85
					],
					"rxry": [
						46,
						108
					]
				},
				{
					"name": "p13",
					"lxly": [
						0,
						108
					],
					"rxry": [
						46,
						138
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						139
					],
					"rxry": [
						46,
						153
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						154
					],
					"rxry": [
						46,
						186
					]
				},
				{
					"name": "p11.12",
					"lxly": [
						0,
						186
					],
					"rxry": [
						46,
						195
					]
				},
				{
					"name": "p11.11",
					"lxly": [
						0,
						196
					],
					"rxry": [
						46,
						201
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						202
					],
					"rxry": [
						46,
						213
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						214
					],
					"rxry": [
						46,
						229
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						229
					],
					"rxry": [
						46,
						269
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						270
					],
					"rxry": [
						46,
						275
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						276
					],
					"rxry": [
						46,
						316
					]
				},
				{
					"name": "q13.4",
					"lxly": [
						0,
						317
					],
					"rxry": [
						46,
						322
					]
				},
				{
					"name": "q13.5",
					"lxly": [
						0,
						323
					],
					"rxry": [
						46,
						332
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						332
					],
					"rxry": [
						46,
						353
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						354
					],
					"rxry": [
						46,
						355
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						356
					],
					"rxry": [
						46,
						371
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						371
					],
					"rxry": [
						46,
						380
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						380
					],
					"rxry": [
						46,
						395
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						396
					],
					"rxry": [
						46,
						398
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						399
					],
					"rxry": [
						46,
						413
					]
				},
				{
					"name": "q23.1",
					"lxly": [
						0,
						413
					],
					"rxry": [
						46,
						425
					]
				},
				{
					"name": "q23.2",
					"lxly": [
						0,
						426
					],
					"rxry": [
						46,
						431
					]
				},
				{
					"name": "q23.3",
					"lxly": [
						0,
						432
					],
					"rxry": [
						46,
						471
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						472
					],
					"rxry": [
						46,
						482
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						483
					],
					"rxry": [
						46,
						499
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						500
					],
					"rxry": [
						46,
						505
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						505
					],
					"rxry": [
						46,
						509
					]
				}
			]
		},
		{
			"name": "Chrom12ISCN09",
			"element": [{
					"name": "p13.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						43,
						37
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						36
					],
					"rxry": [
						43,
						43
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						43
					],
					"rxry": [
						43,
						58
					]
				},
				{
					"name": "p12.3",
					"lxly": [
						0,
						58
					],
					"rxry": [
						43,
						75
					]
				},
				{
					"name": "p12.2",
					"lxly": [
						0,
						75
					],
					"rxry": [
						43,
						79
					]
				},
				{
					"name": "p12.1",
					"lxly": [
						0,
						79
					],
					"rxry": [
						43,
						89
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						89
					],
					"rxry": [
						43,
						122
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						123
					],
					"rxry": [
						43,
						128
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						129
					],
					"rxry": [
						43,
						136
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						137
					],
					"rxry": [
						43,
						153
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						153
					],
					"rxry": [
						43,
						195
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						196
					],
					"rxry": [
						43,
						201
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						202
					],
					"rxry": [
						43,
						221
					]
				},
				{
					"name": "q14",
					"lxly": [
						0,
						222
					],
					"rxry": [
						43,
						246
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						246
					],
					"rxry": [
						43,
						268
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						268
					],
					"rxry": [
						43,
						287
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						287
					],
					"rxry": [
						43,
						298
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						299
					],
					"rxry": [
						43,
						324
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						324
					],
					"rxry": [
						43,
						352
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						352
					],
					"rxry": [
						43,
						376
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						376
					],
					"rxry": [
						43,
						428
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						429
					],
					"rxry": [
						43,
						441
					]
				},
				{
					"name": "q24.31",
					"lxly": [
						0,
						442
					],
					"rxry": [
						43,
						469
					]
				},
				{
					"name": "q24.32",
					"lxly": [
						0,
						469
					],
					"rxry": [
						43,
						479
					]
				},
				{
					"name": "q24.33",
					"lxly": [
						0,
						479
					],
					"rxry": [
						43,
						493
					]
				}
			]
		},
		{
			"name": "Chrom13ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						44,
						21
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						22
					],
					"rxry": [
						44,
						32
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						33
					],
					"rxry": [
						44,
						54
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						55
					],
					"rxry": [
						44,
						57
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						58
					],
					"rxry": [
						44,
						69
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						70
					],
					"rxry": [
						44,
						93
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						94
					],
					"rxry": [
						44,
						99
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						100
					],
					"rxry": [
						44,
						117
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						118
					],
					"rxry": [
						44,
						136
					]
				},
				{
					"name": "q14.1",
					"lxly": [
						0,
						137
					],
					"rxry": [
						44,
						162
					]
				},
				{
					"name": "q14.2",
					"lxly": [
						0,
						163
					],
					"rxry": [
						44,
						168
					]
				},
				{
					"name": "q14.3",
					"lxly": [
						0,
						169
					],
					"rxry": [
						44,
						184
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						184
					],
					"rxry": [
						44,
						206
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						206
					],
					"rxry": [
						44,
						214
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						215
					],
					"rxry": [
						44,
						245
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						245
					],
					"rxry": [
						44,
						284
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						285
					],
					"rxry": [
						44,
						318
					]
				},
				{
					"name": "q32",
					"lxly": [
						0,
						318
					],
					"rxry": [
						44,
						366
					]
				},
				{
					"name": "q33",
					"lxly": [
						0,
						367
					],
					"rxry": [
						44,
						392
					]
				},
				{
					"name": "q34",
					"lxly": [
						0,
						392
					],
					"rxry": [
						44,
						415
					]
				}
			]
		},
		{
			"name": "Chrom14ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						45,
						21
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						22
					],
					"rxry": [
						45,
						31
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						32
					],
					"rxry": [
						45,
						55
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						56
					],
					"rxry": [
						45,
						61
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						62
					],
					"rxry": [
						45,
						69
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						70
					],
					"rxry": [
						45,
						105
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						105
					],
					"rxry": [
						45,
						125
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						125
					],
					"rxry": [
						45,
						144
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						144
					],
					"rxry": [
						45,
						176
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						176
					],
					"rxry": [
						45,
						217
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						218
					],
					"rxry": [
						45,
						234
					]
				},
				{
					"name": "q24.1",
					"lxly": [
						0,
						235
					],
					"rxry": [
						45,
						252
					]
				},
				{
					"name": "q24.2",
					"lxly": [
						0,
						253
					],
					"rxry": [
						45,
						262
					]
				},
				{
					"name": "q24.3",
					"lxly": [
						0,
						263
					],
					"rxry": [
						45,
						301
					]
				},
				{
					"name": "q31",
					"lxly": [
						0,
						301
					],
					"rxry": [
						45,
						327
					]
				},
				{
					"name": "q32.1",
					"lxly": [
						0,
						327
					],
					"rxry": [
						45,
						359
					]
				},
				{
					"name": "q32.2",
					"lxly": [
						0,
						360
					],
					"rxry": [
						45,
						368
					]
				},
				{
					"name": "q32.3",
					"lxly": [
						0,
						369
					],
					"rxry": [
						45,
						401
					]
				}
			]
		},
		{
			"name": "Chrom15ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						45,
						21
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						22
					],
					"rxry": [
						45,
						31
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						32
					],
					"rxry": [
						45,
						50
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						51
					],
					"rxry": [
						45,
						57
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						58
					],
					"rxry": [
						45,
						70
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						71
					],
					"rxry": [
						45,
						94
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						95
					],
					"rxry": [
						45,
						102
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						103
					],
					"rxry": [
						45,
						117
					]
				},
				{
					"name": "q14",
					"lxly": [
						0,
						118
					],
					"rxry": [
						45,
						134
					]
				},
				{
					"name": "q15",
					"lxly": [
						0,
						134
					],
					"rxry": [
						45,
						172
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						172
					],
					"rxry": [
						45,
						190
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						190
					],
					"rxry": [
						45,
						196
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						196
					],
					"rxry": [
						45,
						210
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						210
					],
					"rxry": [
						45,
						219
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						220
					],
					"rxry": [
						45,
						226
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						227
					],
					"rxry": [
						45,
						252
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						252
					],
					"rxry": [
						45,
						262
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						263
					],
					"rxry": [
						45,
						310
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						310
					],
					"rxry": [
						45,
						327
					]
				},
				{
					"name": "q26.1",
					"lxly": [
						0,
						327
					],
					"rxry": [
						45,
						355
					]
				},
				{
					"name": "q26.2",
					"lxly": [
						0,
						355
					],
					"rxry": [
						45,
						368
					]
				},
				{
					"name": "q26.3",
					"lxly": [
						0,
						368
					],
					"rxry": [
						45,
						379
					]
				}
			]
		},
		{
			"name": "Chrom16ISCN09",
			"element": [{
					"name": "p13.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						45,
						27
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						28
					],
					"rxry": [
						45,
						42
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						43
					],
					"rxry": [
						45,
						76
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						77
					],
					"rxry": [
						45,
						101
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						102
					],
					"rxry": [
						45,
						139
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						140
					],
					"rxry": [
						45,
						144
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						145
					],
					"rxry": [
						45,
						150
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						151
					],
					"rxry": [
						45,
						191
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						192
					],
					"rxry": [
						45,
						216
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						217
					],
					"rxry": [
						45,
						222
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						223
					],
					"rxry": [
						45,
						247
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						247
					],
					"rxry": [
						45,
						266
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						266
					],
					"rxry": [
						45,
						289
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						289
					],
					"rxry": [
						45,
						321
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						322
					],
					"rxry": [
						45,
						350
					]
				}
			]
		},
		{
			"name": "Chrom17ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						39,
						50
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						50
					],
					"rxry": [
						39,
						72
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						72
					],
					"rxry": [
						39,
						108
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						109
					],
					"rxry": [
						39,
						114
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						115
					],
					"rxry": [
						39,
						123
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						124
					],
					"rxry": [
						39,
						135
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						136
					],
					"rxry": [
						39,
						150
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						151
					],
					"rxry": [
						39,
						166
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						167
					],
					"rxry": [
						39,
						171
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						172
					],
					"rxry": [
						39,
						237
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						237
					],
					"rxry": [
						39,
						262
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						262
					],
					"rxry": [
						39,
						278
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						279
					],
					"rxry": [
						39,
						300
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						301
					],
					"rxry": [
						39,
						340
					]
				}
			]
		},
		{
			"name": "Chrom18ISCN09",
			"element": [{
					"name": "p11.32",
					"lxly": [
						0,
						0
					],
					"rxry": [
						43,
						5
					]
				},
				{
					"name": "p11.31",
					"lxly": [
						0,
						6
					],
					"rxry": [
						43,
						24
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						23
					],
					"rxry": [
						43,
						73
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						74
					],
					"rxry": [
						43,
						81
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						82
					],
					"rxry": [
						43,
						89
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						90
					],
					"rxry": [
						43,
						116
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						116
					],
					"rxry": [
						43,
						134
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						134
					],
					"rxry": [
						43,
						145
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						145
					],
					"rxry": [
						43,
						163
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						163
					],
					"rxry": [
						43,
						211
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						211
					],
					"rxry": [
						43,
						225
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						226
					],
					"rxry": [
						43,
						238
					]
				},
				{
					"name": "q22",
					"lxly": [
						0,
						239
					],
					"rxry": [
						43,
						274
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						274
					],
					"rxry": [
						43,
						304
					]
				}
			]
		},
		{
			"name": "Chrom19ISCN09",
			"element": [{
					"name": "p13.3",
					"lxly": [
						0,
						0
					],
					"rxry": [
						44,
						44
					]
				},
				{
					"name": "p13.2",
					"lxly": [
						0,
						45
					],
					"rxry": [
						44,
						66
					]
				},
				{
					"name": "p13.1",
					"lxly": [
						0,
						67
					],
					"rxry": [
						44,
						100
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						101
					],
					"rxry": [
						44,
						108
					]
				},
				{
					"name": "p11",
					"lxly": [
						0,
						109
					],
					"rxry": [
						44,
						119
					]
				},
				{
					"name": "q11",
					"lxly": [
						0,
						120
					],
					"rxry": [
						44,
						128
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						129
					],
					"rxry": [
						44,
						138
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						139
					],
					"rxry": [
						44,
						174
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						175
					],
					"rxry": [
						44,
						194
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						194
					],
					"rxry": [
						44,
						254
					]
				},
				{
					"name": "q13.4",
					"lxly": [
						0,
						254
					],
					"rxry": [
						44,
						268
					]
				}
			]
		},
		{
			"name": "Chrom20ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						43,
						28
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						28
					],
					"rxry": [
						43,
						53
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						53
					],
					"rxry": [
						43,
						106
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						107
					],
					"rxry": [
						43,
						111
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						112
					],
					"rxry": [
						43,
						120
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						121
					],
					"rxry": [
						43,
						160
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						161
					],
					"rxry": [
						43,
						182
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						183
					],
					"rxry": [
						43,
						211
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						210
					],
					"rxry": [
						43,
						234
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						234
					],
					"rxry": [
						43,
						256
					]
				}
			]
		},
		{
			"name": "Chrom21ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						47,
						21
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						22
					],
					"rxry": [
						47,
						34
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						35
					],
					"rxry": [
						47,
						58
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						59
					],
					"rxry": [
						47,
						63
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						64
					],
					"rxry": [
						47,
						81
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						73
					],
					"rxry": [
						47,
						81
					]
				},
				{
					"name": "q21",
					"lxly": [
						0,
						81
					],
					"rxry": [
						47,
						117
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						117
					],
					"rxry": [
						47,
						143
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						144
					],
					"rxry": [
						47,
						157
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						158
					],
					"rxry": [
						47,
						197
					]
				}
			]
		},
		{
			"name": "Chrom22ISCN09",
			"element": [{
					"name": "p13",
					"lxly": [
						0,
						0
					],
					"rxry": [
						46,
						20
					]
				},
				{
					"name": "p12",
					"lxly": [
						0,
						21
					],
					"rxry": [
						46,
						32
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						33
					],
					"rxry": [
						46,
						51
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						52
					],
					"rxry": [
						46,
						61
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						62
					],
					"rxry": [
						46,
						69
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						70
					],
					"rxry": [
						46,
						123
					]
				},
				{
					"name": "q12.1",
					"lxly": [
						0,
						124
					],
					"rxry": [
						46,
						129
					]
				},
				{
					"name": "q12.2",
					"lxly": [
						0,
						130
					],
					"rxry": [
						46,
						137
					]
				},
				{
					"name": "q12.3",
					"lxly": [
						0,
						138
					],
					"rxry": [
						46,
						147
					]
				},
				{
					"name": "q13.1",
					"lxly": [
						0,
						147
					],
					"rxry": [
						46,
						175
					]
				},
				{
					"name": "q13.2",
					"lxly": [
						0,
						176
					],
					"rxry": [
						46,
						181
					]
				},
				{
					"name": "q13.3",
					"lxly": [
						0,
						182
					],
					"rxry": [
						46,
						214
					]
				}
			]
		},
		{
			"name": "ChromXISCN09",
			"element": [{
					"name": "p22.33",
					"lxly": [
						0,
						0
					],
					"rxry": [
						43,
						14
					]
				},
				{
					"name": "p22.32",
					"lxly": [
						0,
						14
					],
					"rxry": [
						43,
						25
					]
				},
				{
					"name": "p22.31",
					"lxly": [
						0,
						25
					],
					"rxry": [
						43,
						37
					]
				},
				{
					"name": "p22.2",
					"lxly": [
						0,
						38
					],
					"rxry": [
						43,
						46
					]
				},
				{
					"name": "p22.1",
					"lxly": [
						0,
						47
					],
					"rxry": [
						43,
						86
					]
				},
				{
					"name": "p21.3",
					"lxly": [
						0,
						86
					],
					"rxry": [
						43,
						107
					]
				},
				{
					"name": "p21.2",
					"lxly": [
						0,
						107
					],
					"rxry": [
						43,
						112
					]
				},
				{
					"name": "p21.1",
					"lxly": [
						0,
						112
					],
					"rxry": [
						43,
						133
					]
				},
				{
					"name": "p11.4",
					"lxly": [
						0,
						133
					],
					"rxry": [
						43,
						159
					]
				},
				{
					"name": "p11.3",
					"lxly": [
						0,
						160
					],
					"rxry": [
						43,
						168
					]
				},
				{
					"name": "p11.23",
					"lxly": [
						0,
						169
					],
					"rxry": [
						43,
						192
					]
				},
				{
					"name": "p11.22",
					"lxly": [
						0,
						193
					],
					"rxry": [
						43,
						203
					]
				},
				{
					"name": "p11.21",
					"lxly": [
						0,
						203
					],
					"rxry": [
						43,
						208
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						209
					],
					"rxry": [
						43,
						214
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						214
					],
					"rxry": [
						43,
						222
					]
				},
				{
					"name": "q11.2",
					"lxly": [
						0,
						223
					],
					"rxry": [
						43,
						227
					]
				},
				{
					"name": "q12",
					"lxly": [
						0,
						228
					],
					"rxry": [
						43,
						240
					]
				},
				{
					"name": "q13",
					"lxly": [
						0,
						240
					],
					"rxry": [
						43,
						306
					]
				},
				{
					"name": "q21.1",
					"lxly": [
						0,
						306
					],
					"rxry": [
						43,
						324
					]
				},
				{
					"name": "q21.2",
					"lxly": [
						0,
						324
					],
					"rxry": [
						43,
						334
					]
				},
				{
					"name": "q21.3",
					"lxly": [
						0,
						334
					],
					"rxry": [
						43,
						375
					]
				},
				{
					"name": "q22.1",
					"lxly": [
						0,
						375
					],
					"rxry": [
						43,
						412
					]
				},
				{
					"name": "q22.2",
					"lxly": [
						0,
						413
					],
					"rxry": [
						43,
						421
					]
				},
				{
					"name": "q22.3",
					"lxly": [
						0,
						421
					],
					"rxry": [
						43,
						444
					]
				},
				{
					"name": "q23",
					"lxly": [
						0,
						445
					],
					"rxry": [
						43,
						460
					]
				},
				{
					"name": "q24",
					"lxly": [
						0,
						460
					],
					"rxry": [
						43,
						479
					]
				},
				{
					"name": "q25",
					"lxly": [
						0,
						480
					],
					"rxry": [
						43,
						505
					]
				},
				{
					"name": "q26",
					"lxly": [
						0,
						505
					],
					"rxry": [
						43,
						557
					]
				},
				{
					"name": "q27",
					"lxly": [
						0,
						558
					],
					"rxry": [
						43,
						579
					]
				},
				{
					"name": "q28",
					"lxly": [
						0,
						580
					],
					"rxry": [
						43,
						599
					]
				}
			]
		},
		{
			"name": "ChromYISCN09",
			"element": [{
					"name": "p11.32",
					"lxly": [
						0,
						0
					],
					"rxry": [
						46,
						9
					]
				},
				{
					"name": "p11.31",
					"lxly": [
						0,
						9
					],
					"rxry": [
						46,
						23
					]
				},
				{
					"name": "p11.2",
					"lxly": [
						0,
						24
					],
					"rxry": [
						46,
						60
					]
				},
				{
					"name": "p11.1",
					"lxly": [
						0,
						61
					],
					"rxry": [
						46,
						69
					]
				},
				{
					"name": "q11.1",
					"lxly": [
						0,
						70
					],
					"rxry": [
						46,
						77
					]
				},
				{
					"name": "q11.21",
					"lxly": [
						0,
						78
					],
					"rxry": [
						46,
						87
					]
				},
				{
					"name": "q11.221",
					"lxly": [
						0,
						87
					],
					"rxry": [
						46,
						101
					]
				},
				{
					"name": "q11.222",
					"lxly": [
						0,
						101
					],
					"rxry": [
						46,
						108
					]
				},
				{
					"name": "q11.223",
					"lxly": [
						0,
						108
					],
					"rxry": [
						46,
						122
					]
				},
				{
					"name": "q11.23",
					"lxly": [
						0,
						122
					],
					"rxry": [
						46,
						139
					]
				},
				{
					"name": "q11.24",
					"lxly": [
						0,
						140
					],
					"rxry": [
						46,
						200
					]
				}
			]
		}
	]
}
