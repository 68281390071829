/**
 * 染色体相互替换
 * @param {Object} current
 * @param {Object} target
 */
function replaceChromos(current_chromo,target_chromo){
	// console.warn(current,target);
	if(current_chromo === target_chromo){
		return;
	}
	
	const cid = current_chromo.chromoId;
	const tid = target_chromo.chromoId;
	current_chromo.chromoId = target_chromo.chromoId;
	target_chromo.chromoId = cid;
	if(cid != tid){
		// current.yoffsetInGroup = 0;
		// target.yoffsetInGroup = 0;
		current_chromo.yoffsetInGroup = 0;
		target_chromo.yoffsetInGroup = 0;
	}
	
	const cindex = this.chromoList.indexOf(current_chromo);
	const tindex = this.chromoList.indexOf(target_chromo);
	
	cindex > -1 && this.chromoList.splice(cindex, 1, target_chromo);
	cindex > -1 && this.chromoList.splice(tindex, 1, current_chromo);
	
}
/**
 * 染色体选中
 */
function chooseArrange(stage, targetStage){
	let arrangeTarget, karyoTarget;
	/**
	 * 获取染色体源数据
	 */
	const getChromoByShape = (shape)=>{
		let chromo;
		Object.keys(this.arrangeController.group).forEach(g=>{
			const group = this.arrangeController.group[g];
			group.list.forEach(item=>{
				if(item.shape === shape){
					chromo = item.chromo;
				}
			})
		})
		return chromo;
	}
	
	/**
	 * 鼠标移入效果
	 * @param {*} shape 
	 * @param {*} color 
	 */
	const hover = (shape, color)=>{
		shape._color = shape.color;
		shape.color = color;
		let chromo = getChromoByShape(shape);
		
		stage.draw();
		const karyoShape = this.karyoController.getShapeByChromo(chromo);
		karyoShape && (karyoShape.color = color);
		if(this.choosedChromo && this.choosedChromo === chromo){
			const _shape = this.karyoController.getShapeByChromo(this.choosedChromo);
			_shape && (_shape.color = "#f00")
		}
		this.karyoController.stage.draw();
	}
	
	//触发条件
	const condition = {
		getStatus : ()=>{
			const typeArr = ['count','general','select','delete','cutting','cross','edge','rotate'];
			return typeArr.indexOf(this.toolsType) > -1
		}
	}

	//鼠标移入图形
	stage.event.mouseenter(condition,({
		e,
		shape
	}) => {
		hover(shape, 'blue')
	})
	
	/**
	 * 鼠标移出效果
	 * @param {*} shape 
	 */
	const mouseout = (shape)=>{
		shape.color = shape._color;
		delete shape._color;
		
		stage.draw();
		let chromo = getChromoByShape(shape);
		const karyoShape = this.karyoController.getShapeByChromo(chromo);
		!this.assistantMarker && this.karyoController.chromoMap.forEach(item=>{
			if(item.chromo === chromo){
				item.text.color = "transparent"
			}
		})
		karyoShape && (karyoShape.color = !this.assistantMarker ? "transparent" : "#51A749");
		if(this.choosedChromo){
			const _shape = this.karyoController.getShapeByChromo(this.choosedChromo);
			_shape && (_shape.color =!this.assistantMarker ? "transparent" : "#f00")
		}
		this.karyoController.stage.draw();
	}
	
	//鼠标移出图形
	stage.event.mouseout(condition,({
		e,
		shape
	}) => {
		mouseout(shape);
	})
	
	//点击事件的触发条件
	const conditionClick = {
		getStatus : ()=>{
			const typeArr = ['arrow','delete','eraser','banding','mirror'];
			return typeArr.indexOf(this.toolsType) < 0
		}
	}
	let clickTime = 0;
	const chromoScreenCenter = (chromo)=>{
		const pos = chromo._cimgBoundaryPointsList;
		const minx = Math.min.apply(null, pos.map(a=>a.x));
		const maxx = Math.max.apply(null, pos.map(a=>a.x));
		const miny = Math.min.apply(null, pos.map(a=>a.y));
		const maxy = Math.max.apply(null, pos.map(a=>a.y));
		const box = chromo._cimgBoundingbox;
		const cx = box.x + (maxx - minx) / 2;
		const cy = box.y + (maxy - miny) / 2;
		// console.log(cx,cy,box.x,minx,maxx, box.y,miny,maxy,chromo.countPoints);
		this.karyoController.stage.scaleStageByCenterPoint(cx,cy);
	}
	const chooseChromo = (shape,chromo)=>{
		this.choosedChromo = chromo;
		this.karyoController.chromoMap.forEach(item=>{
			if(item.chromo === chromo){
				item.shape.color = "#f00";
				this.assistantMarker && (item.text.color = "#f00");
				karyoTarget = item.shape;
			}else{
				item.shape.color = !this.assistantMarker ? "transparent" : "#51A749";
				item.text.color = !this.assistantMarker ? "transparent" : "#f00";
			}
		})
		chromoScreenCenter(chromo);
		arrangeTarget = shape;
		stage.draw();
	}
	//点击选中染色体 并将分裂相图中的对应染色体显示在具中的位置
	stage.event.click(conditionClick,({shape})=>{
		stage.objects.filter(item=>item.type==="polygon").forEach(item=>{
			item.color = item.chromoId < 26 ? "transparent" : "#aaa";
			delete item._color;
		})
		if(shape && this.toolsType !== 'diff'){
			let chromo = getChromoByShape(shape);
			if(chromo === this.choosedChromo){
				return;
			}
			shape._color = shape.color;
			shape.color = "#f00";
			const t = +new Date();
			if(t - clickTime > 1000){
				chooseChromo(shape,chromo);
				clickTime = t;
			}else if(this.toolsType !== 'diff'){
				replaceChromos.call(this,this.choosedChromo, chromo);
				console.log('this.choosedChromo',this.choosedChromo);
				this.cachePool.save();
				clickTime = 0;
				this.karyoController.resetKaryo();
				this.arrangeController.reArrange();
				chromoScreenCenter(this.choosedChromo);
			}
		}
		if(shape && this.toolsType === 'diff'){
			let chromo = getChromoByShape(shape);
			shape._color = shape.color;
			shape.color = "#f00";
			chooseChromo(shape,chromo);
		}
		if(!shape){
			this.choosedChromo = null;
			arrangeTarget = shape;
			stage.draw();
		}
	})

	let TEXT = null;
	//显示标准染色体的条带信息
	stage.element.addEventListener("mousemove",(e)=>{
		if(!this.standardVisible){
			return;
		}
		// console.warn(pos);
		const standard = stage.isPointInner(e,false,'image');
		if(standard){
			const pos = stage.getPos(e);
			// console.log(pos);
			const y = standard.getPosRelative(pos.y);
			// console.warn(y);
			const item = this.standardInfo.banding.filter(item=>{
				return item.name === `Chrom${standard.no}ISCN09`
			})[0];
			if(item){
				const _item = item.element.filter(a=>{
					return a.lxly[1] <= y && y <= a.rxry[1]
				})[0];
				if(_item){
					if(TEXT){
						TEXT.x = pos.x + 10;
						TEXT.y = pos.y;
						TEXT.text = _item.name;
					}else{
						TEXT = stage.graphs.standardText({
							color : "#f00",
							font: `normal normal 500 36px Arial`,
							text : _item.name,
							x : pos.x + 10,
							y : pos.y,
							zindex: 600
						})
						stage.addChild(TEXT);
					}
					stage.draw();
				}
			}
		}else if(TEXT){
			stage.removeChild(TEXT);
			TEXT = null;
			stage.draw();
		}
	})

	//canvas mouseout事件
	stage.element.addEventListener("mouseout",(e)=>{
		stage.removeChild(TEXT);
		TEXT = null;
		stage.draw();
	})

	stage.event.click({
		getStatus : ()=>{
			return !this.isArrangeOnLeft && this.globalMode==='arrange'
		}
	},({e,shape})=>{
		if(!shape){
			this.switchStage();
		}
	})
	// stage.element.addEventListener("click",()=>{
	// 	this.$refs["standardSelect"].blur();
	// })
}
export default chooseArrange