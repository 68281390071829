/*
 * @Author: your name
 * @Date: 2021-03-10 11:36:24
 * @LastEditTime: 2021-05-11 17:13:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \autovision-vue\src\assets\utils\downloadZip.js
 */
function unZip(file, reslove) {

    const un = new window.UnZipArchive(file);

    un.getData(function () {
        let arr = [],
            count = 0;
        un.entries.forEach((entry, i) => {
            // console.log(entry);
            un.getData(entry.filename, function (blob) {
                // console.log(blob);
                blobToDataURL(blob, function (str) {
                    // console.log(str);
                    arr[i] = str;
                    entry.base64 = str;
                    count++;
                    if (count === un.entries.length) {
                        // console.log(arr)
                        // console.log('zip解压完成',new Date().getTime());
                        setTimeout(() => {
                            reslove(un.entries);
                        }, 200)
                    }
                })
            })
        })
    })

}

function blobToDataURL(blob, callback) {
    let a = new FileReader();
    a.onload = function (e) {
        callback(e.target.result);
    }
    a.readAsDataURL(blob);
}
export function getBase64FromEntriesPool(id, src) {
    const _src = !src ? id : src;
    if (_src.indexOf("base64,") > -1 || !id || !window.entiresPool) {
        return _src
    }
    const _name = _src.split('?')[0].split('/').pop();

    if (!src) {
        const reg = new RegExp(`${_name}$`, 'g')
        const key = Object.keys(window.entiresPool).filter(key => reg.test(key))[0];
        return key ? (window.entiresPool[key] || _src) : _src
    } else {
        const key = ["karyo", id, _name].join("_");
        return window.entiresPool[key] || _src;
    }
}
export function downloadZip(url) {
    // console.log('开始下载zip', new Date().getTime());
    return new Promise((reslove, reject) => {
        var xhr = new XMLHttpRequest(); //定义http请求对象
        xhr.open("GET", url, true);
        xhr.responseType = "blob"; // 返回类型blob
        xhr.onload = function () { // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
            // console.log(this);
            // console.log(this.status, url.split("?")[0].split('/').pop());
            if (this.status === 200) {
                // console.log('压缩文件下载完成', new Date().getTime());
                var blob = this.response;
                unZip(blob, reslove);
            } else {
                // alert("出现了未知的错误!");
                setTimeout(() => {
                    reject(this.status);
                }, 300)
            }
        }
        xhr.send();
    })
}