import shortKey from '../../../../assets/utils/shortKey.js'
import { genLineMaskAndCut4Arrange} from "./cutting.js"
import cvCommon from './opencv-common.js'
import {getRotateObj} from "./rotate"
import { cloneDeep } from 'lodash';

/**
 * 橡皮擦效果
 * @param {Object} stage
 */
function easer(stage){
	const app = this;
    const arrange = this.arrangeController;
    const karyo = this.karyoController;
	const controller = app.arrangeController;
	let lineShape;
	const condition = {
		getStatus : ()=>{
			return this.toolsType === 'eraser'
		}
	}
	
	let isMove = false;
	let brushShape;
	stage.element.addEventListener("mouseout",(e)=>{
		isMove = false
		if(this.toolsType === 'eraser'){
			stage.removeChild(brushShape);
			brushShape = null;
			stage.draw();
		}
	})
	stage.element.addEventListener("mousemove",(e)=>{
		isMove = true;
		if(this.toolsType === 'eraser'){
			const pos = stage.getPos(e);
			if(brushShape){
				Object.assign(brushShape,{
					brushThickness : this.brushThickness,
					x : pos.x,
					y : pos.y
				})
				!stage.objects.includes(brushShape) && stage.addChild(brushShape);
			}else{
				brushShape = stage.graphs.brush({
					brushThickness : this.brushThickness,
					x : pos.x,
					y : pos.y,
					zindex: 1000
				})
				stage.addChild(brushShape);
				window.brushShape = brushShape;
			}
			stage.draw();
			// drawCircle(stage, pos.x, pos.y);
		}
	})
	const stageK = this.karyoController.stage;
	shortKey.on("keyup",{key:'shiftleft'},()=>{
		if(this.toolsType === "general"){
			stageK.draw();
		}
	})
	shortKey.on("keydown",{key:'shiftleft'},()=>{
		if(this.toolsType === "general" && isMove){
			stageK.draw();
		}
	},true)
	


	// move过程划线
    stage.event.dragMove(condition, ({ posList, shape }) => {
        let matrix = posList.map((pos) => {
            return [pos.x, pos.y];
        })
        if (lineShape) {
			lineShape.matrix = matrix;
			lineShape.lineWidth = this.brushThickness * stage.offset.zoom
        } else {
            lineShape = stage.graphs.line({
                x: 0,
                y: 0,
				color: "rgba(255, 255, 255, 1)",
				// lineWidth: this.brushThickness,
                lineWidth: this.brushThickness * stage.offset.zoom,
				zindex: 999,
				hold: false,
                matrix: matrix,
                lineCap: "round",
                lineJoin: "round"
            })
            stage.addChild(lineShape);
        }
        stage.draw();
	})
	// 连接结束
    stage.event.dragEnd(condition, ({ posList }) => {
		console.log("右键 dragEnd");
		//获取线条经过的染色体集合
		const shapes = stage.getObjectsByPointList(posList, 'polygon').filter(obj => { return obj.realType === 'chromo' });
		// console.warn(stage.getObjectsByPointList(posList, 'polygon'));
		//获取当前选中的染色体图形
		const choosedShape = controller.getShapeByChromo(this.choosedChromo);
		//如果线条经过了选中的图形 则对选中图形进行擦除 否则擦除第一个
		const shape = shapes.includes(choosedShape) ? choosedShape : shapes[0];
		// console.log(shape);
		if(!shape){
			clearEaserPath();
			return;
		}
		// const chromo = controller.getChromoByShape(shape);
		let startTime = new Date().getTime()
		doEaserByAlg(shape, posList);
		console.log("easer Chromo use time :", new Date().getTime() - startTime)
        // stage.draw();
    })

	const clearEaserPath = ()=>{
		stage.removeChild(lineShape);
		lineShape = null;
		stage.draw();
	}

	const doEaser = (shape, posList)=>{
		if(posList.length === 1){
			posList.push(posList[0])
		}
		const element = document.createElement("canvas");
		const canvas = element.getContext("2d");
		const width = shape.image.width;
		const height = shape.image.height;
		element.width = width;
		element.height = height;
		// console.log(shape.image);
		canvas.drawImage(shape.image, 0, 0, width, height);
		// canvas.scale(shape.scale,shape.scale)
		// document.body.append(element);
		canvas.beginPath();
		canvas.lineJoin = "round";
		canvas.lineCap = "round";
		canvas.strokeStyle = "#fff";
		// canvas.lineWidth = this.brushThickness / stage.offset.scale / stage.scale / shape.scale;
		canvas.lineWidth = this.brushThickness / stage.offset.scale / shape.scale;
		posList.forEach((pos,i)=>{
			const x = (pos.x - shape.x) / shape.scale;
			const y = (pos.y - shape.y) / shape.scale;
			i === 0 ? canvas.moveTo(x,y) : canvas.lineTo(x,y)
		})
		canvas.stroke();
		canvas.closePath();
		const base64 = element.toDataURL('image/png', 1.0);
		const image = new Image();
		image.width = width;
		image.height = height;
		image.src = base64;
		image.onload = ()=>{
			// shape.image = image;
			const chromo = controller.getChromoByShape(shape);
			chromo.justUrl = base64;
			app.cachePool.checkBase64(app.chromoList);
			// controller.reArrange();
			Object.keys(controller.group).forEach(key=>{
				controller.group[key].list.forEach(item=>{
					if(item.image === shape.image){
						item.image = image;
						item.shape.image = image;
					}
				})
			})
			// 删除线条图形
			clearEaserPath();
			app.cachePool.save();
			app.cachePool.record({
				type : 'easer',
				message : '橡皮擦工具',
				data : chromo,
				result : app.chromoList
			})
		}
	}

    /**
     * 调用算法进行擦除
     * @param {擦除图形} shape 
     * @param {擦除的点集合} posList 
     */
	const doEaserByAlg = (shape, posList)=>{
		if(posList.length === 1){
			posList.push(posList[0])
		}
        // 删除线条图形
        clearEaserPath();

		let lineWidth = this.brushThickness / stage.offset.scale / shape.scale;
		let targetChromo = controller.getChromoByShape(shape);
        genLineMaskAndCut4Arrange(targetChromo, shape, posList, lineWidth).then(([chromoDataJS, allLinesJS]) => {
            doEaserChromos(chromoDataJS, allLinesJS, targetChromo)
        }).catch((err) => {
            console.error(err)
        });
	}

    const doEaserChromos = (chromoDataJS, allLinesJS, srcChromo)=>{
        let newChromo = getEaserChromos(chromoDataJS, allLinesJS, srcChromo)
        if (!newChromo) {
            console.warn("涂抹擦除失败")
            return;
        }

        let sChromoIndex = controller.chromoList.indexOf(srcChromo);
        newChromo.chromoId = srcChromo.chromoId;
        newChromo.id = srcChromo.id;
        if (newChromo.index !== srcChromo.index) {
            newChromo.index = srcChromo.index;
        }

        let angle_old = srcChromo.cimgOrientation > 0
            ? (90 - srcChromo.cimgOrientation)
            : (270 - srcChromo.cimgOrientation)
        let angle_new = newChromo.cimgOrientation > 0 ? (90 - newChromo.cimgOrientation)
            : (270 - newChromo.cimgOrientation)
        // 记录变化角度还原，以免切割后发生角度轻微旋转
        console.warn(angle_old, angle_new)
        let changeAngle = angle_new - angle_old
        let degree = srcChromo.imgHandledRotatedDegree + changeAngle;
        newChromo.imgHandledRotatedDegree = degree % 360
        getRotateObj(newChromo.invertedUrl, degree).then(rotateBase64 => {
            var _img = new Image();
            _img.src = rotateBase64;
            _img.onload = function () {
                newChromo.justUrl = rotateBase64;
                newChromo.image = _img;
                // 如果区域擦除后，不做镜像旋转归位，则需要修改默认没有旋转
                newChromo.imgHandledFlip = 0;
                sChromoIndex > -1 && controller.chromoList.splice(sChromoIndex, 1, newChromo);
                
				srcChromo = null
				if(brushShape){
					stage.beforeDraw = ()=>{
						stage.addChild(brushShape);
					}
				}
                arrange.reArrange();
				
				// stage.draw();
                karyo.resetKaryo();
				
                app.cachePool.save();
                app.cachePool.record({
                    type : 'easer',
                    message : '橡皮擦工具',
                    data : srcChromo,
                    result : app.chromoList
                })
            }
        })
    }

}


/**
 * 调用C++算法，获取染色体切割后列表
 * 
 * 函数功能描述 ：		                            涂抹生成新染色体
 * 入参：
 *   JSchromo &chrom_js								要擦出的染色体对象
 *   JSMat &erase_mask_js                            mask需要去除的部分
 * 函数返回值 ：										JSResult jsResult.result等于0成功，其他失败
 * JSResult EraserFun(JSchromo &chrom_js, JSMat &erase_mask_js);
 * @param {*} chromoDataJS 
 * @param {*} allLinesJS 
 * @param {*} targetChromo 
 */
 export function getEaserChromos(chromoDataJS, allLinesJS, targetChromo) {
    try {
        let manualSegmentFun = window.Module.EraserFun(chromoDataJS, allLinesJS);
        if (manualSegmentFun.result == 0) {
            let chromoData = manualSegmentFun.chr
            manualSegmentFun = null
            let newChromo = cloneDeep(cvCommon.getCuttingData(chromoData, targetChromo))
            newChromo.chromoDataJS = cvCommon._getChromoDataJS(chromoData, targetChromo)

            return newChromo;
        }
    } catch (error) {
        console.warn("getEaserChromos", error)
    }
    return false;
}
                  
export default easer