import cvCommon from './opencv-common.js'
/**
 * 染色体旋转
 */
function rotate(stage, controller) {
    const arrange = this.arrangeController;
    const karyo = this.karyoController;
    const app = this;
    let currentShape = null, rotatedChromo = null, centerPoint = null, firstAnage = 0, startRotate = false;
    
    let isDragMove = false;
    const dragStart = ({ shape, posList }) => {
        if (shape) {
            shape.color = shape.color === "transparent" ? "blue" : shape.color;
            rotatedChromo = controller.getChromoByShape(shape)
            if (!rotatedChromo) {
                return;
            }
            startRotate = true
            currentShape = shape
            centerPoint = [(currentShape.matrix[2][0] + currentShape.matrix[0][0]) / 2,
            (currentShape.matrix[2][1] + currentShape.matrix[0][1]) / 2];
            firstAnage = getAngle(centerPoint[0], centerPoint[1], posList[posList.length - 1].x, posList[posList.length - 1].y);
        }
    }
    const dragMove = ({ posList }) => {
        if (currentShape && centerPoint && startRotate) {
            isDragMove = true;
            let nowAngle = getAngle(centerPoint[0], centerPoint[1], posList[posList.length - 1].x, posList[posList.length - 1].y);
            currentShape.substitute.hide = false;
            currentShape.substitute.rotate = nowAngle - firstAnage;
            currentShape.zindex = currentShape._zindex;
            stage.draw();
        }
    }
    const dragEnd = ({ posList }) => {
        isDragMove = false;
        if (!currentShape || !centerPoint || !startRotate) {
            clearData()
            return
        } else {
            currentShape.substitute.hide = true;
            stage.draw();
        }
        let nowAngle = getAngle(centerPoint[0], centerPoint[1], posList[posList.length - 1].x, posList[posList.length - 1].y);
        let angle = nowAngle - firstAnage;
        if (!rotatedChromo.imgHandledRotatedDegree) {
            rotatedChromo.imgHandledRotatedDegree = 0;
        }
        let rotateDegree = rotatedChromo.imgHandledRotatedDegree + angle;
        getRotateObj(rotatedChromo.invertedUrl, rotateDegree).then(res => {
            let rotateBase64 = res;

            var _img = new Image();
            _img.src = rotateBase64;
            _img.onload = () => {
                rotatedChromo.justUrl = rotateBase64;
                rotatedChromo.image = _img;
                rotatedChromo.imgHandledRotatedDegree = rotateDegree;

                clearData()
                karyo.resetKaryo();
                arrange.reArrange()
                app.cachePool.save();
                app.cachePool.record({
                    type : 'rotate',
                    message : '染色体旋转',
                    data : rotatedChromo,
                    result : angle
                })
            }
        })
    }

    const clearData = () => {
        currentShape = null
        centerPoint = null
        startRotate = false
    }

    const condition = {
        getStatus: () => {
            return this.toolsType === 'rotate' || isDragMove
        }
    }
    const condition1 = {
        ctrlKey: true,
        getStatus: () => {
            return this.toolsType === 'general' || this.toolsType === 'select';
        }
    }

    stage.event.dragStart(condition, dragStart)
    stage.event.dragStart(condition1, dragStart)
    stage.event.dragMove(condition, dragMove)
    stage.event.dragMove(condition1, dragMove)
    // end执行旋转
    stage.event.dragEnd(condition, dragEnd)
    stage.event.dragEnd(condition1, dragEnd)

    const conditionDblclick = {
        getStatus: () => {
            return ['count', 'general', 'select'].indexOf(this.toolsType) >= 0
        }
    }
    
    // dblclick执行上下旋转180度
    stage.event.dblclick(conditionDblclick, ({ shape }) => {
        let rotatedChromo = controller.getChromoByShape(shape);
        if (!rotatedChromo) {
            return;
        }
        if (!rotatedChromo.imgHandledRotatedDegree) {
            rotatedChromo.imgHandledRotatedDegree = 0;
        }
        let rotateDegree = rotatedChromo.imgHandledRotatedDegree + 180;
        getRotateObj(rotatedChromo.invertedUrl, rotateDegree).then(res => {
            let rotateBase64 = res;

            var _img = new Image();
            _img.src = rotateBase64;
            _img.onload = () => {
                rotatedChromo.justUrl = rotateBase64;
                rotatedChromo.image = _img;
                rotatedChromo.imgHandledRotatedDegree = rotateDegree;

                karyo.resetKaryo();
                arrange.reArrange()
                app.cachePool.save();
                app.cachePool.record({
                    type : 'rotate',
                    message : '染色体水平翻转',
                    data : rotatedChromo,
                    result : 180
                })
            }
        })
    })

    const mirrorRotate = ({ shape })=>{
        let rotatedChromo = controller.getChromoByShape(shape);
        if (!rotatedChromo) {
            return;
        }
        if (!rotatedChromo.imgHandledRotatedDegree) {
            rotatedChromo.imgHandledRotatedDegree = 0;
        }
        cvCommon.onRotaterMirror(rotatedChromo.justUrl).then(res => {
            let rotateBase64 = res;

            var _img = new Image();
            _img.src = rotateBase64;
            _img.onload = () => {
                rotatedChromo.justUrl = rotateBase64;
                rotatedChromo.imgHandledFlip = rotatedChromo.imgHandledFlip ? 0 : 1;

                karyo.resetKaryo();
                arrange.reArrange()
                app.cachePool.save();
                app.cachePool.record({
                    type : 'rotate',
                    message : '染色体垂直翻转',
                    data : rotatedChromo,
                    result : rotatedChromo.imgHandledRotatedDegree
                })
            }
        })
    }
    const conditionMirrorClick = {
        getStatus : ()=>{
            return this.toolsType === 'mirror'
        }
    }
    // dblclick执行左右镜像旋转180度
    stage.event.dblclick(Object.assign({ ctrlKey: true, shiftKey: true }, conditionDblclick), mirrorRotate);
    stage.event.click(conditionMirrorClick, mirrorRotate);
}

/**
 * 生成旋转后图片
 * @param {图片} src img 
 * @param {角度} rotate 
 */
export function getRotateObj(src, rotate) {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.crossOrigin = "anonymous";
        img.src = src;
        img.onload = () => {
            // 计算图片旋转后坐标 (x, y, width, height)
            let rotatedPos = getRotatedPosition(0, 0, img.width, img.height, rotate)
            // 旋转后，重新设置 位置
            let new_w = rotatedPos[2], new_h = rotatedPos[3];
            var _canvas2 = document.createElement('canvas');
            _canvas2.width = new_w;
            _canvas2.height = new_h;
            var _ctx2 = _canvas2.getContext('2d');
            _ctx2.fillStyle = "#fff";
            _ctx2.fillRect(0, 0, new_w, new_h);
            _ctx2.translate(new_w / 2, new_h / 2)
            _ctx2.rotate(Math.PI / 180 * rotate)
            _ctx2.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);
            // console.warn(_canvas2.toDataURL('image/png'))
            resolve(cvCommon.tailorImg(_canvas2));
        }
        img.onerror = (e) => {
            reject(e);
        }
    })
}

/**
 * 得到矩形按照中心点旋转后的位置
 * @param {*} x 
 * @param {*} y 
 * @param {*} width 
 * @param {*} height 
 * @param {*} angle 旋转角度
 */
function getRotatedPosition(x, y, width, height, angle) {
    // 旋转后，重新设置 位置
    let pCenter = [x + width / 2, y + height / 2];
    let a = getNewPoint([x, y], pCenter, angle);
    let b = getNewPoint([x + width, y], pCenter, angle);
    let c = getNewPoint([x + width, y + height], pCenter, angle);
    let d = getNewPoint([x, y + height], pCenter, angle);
    let xlist = [a[0], b[0], c[0], d[0]];
    let ylist = [a[1], b[1], c[1], d[1]];
    let xsort = xlist.sort(function (a, b) {
        return a - b;
    });
    let ysort = ylist.sort(function (a, b) {
        return a - b;
    });
    let max_x = xsort[xlist.length - 1];
    let min_x = xsort[0];
    let max_y = ysort[ylist.length - 1];
    let min_y = ysort[0];

    return [min_x, min_y, max_x - min_x, max_y - min_y]
}


//根据坐标点旋转得到新的坐标
function getNewPoint(p, pCenter, angle) {
    var l = (angle * Math.PI) / 180;
    var cosv = Math.cos(l);
    var sinv = Math.sin(l);

    // calc new point
    var newX = (p[0] - pCenter[0]) * cosv - (p[1] - pCenter[1]) * sinv + pCenter[0];
    var newY = (p[0] - pCenter[0]) * sinv + (p[1] - pCenter[1]) * cosv + pCenter[1];
    return [newX, newY];
}

/**
 * 获得中心和鼠标坐标连线，与y轴正半轴之间的夹角
 * @param {*} center_x 
 * @param {*} center_y 
 * @param {*} mouse_x 
 * @param {*} mouse_y 
 */
function getAngle(center_x, center_y, mouse_x, mouse_y) {
    var x = Math.abs(center_x - mouse_x);
    var y = Math.abs(center_y - mouse_y);
    var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    var cos = y / z;
    var radina = Math.acos(cos);//用反三角函数求弧度
    var angle = Math.floor(180 / (Math.PI / radina));//将弧度转换成角度

    if (mouse_x < center_x && mouse_y < center_y) {//鼠标在第二象限
        angle = 360 - angle;
    } else if (mouse_x < center_x && mouse_y > center_y) {//鼠标在第三象限
        angle = 180 + angle;
    } else if (mouse_x > center_x && mouse_y > center_y) {//鼠标在第四象限
        angle = 180 - angle;
    } else if (mouse_x > center_x && mouse_y == center_y) {//鼠标在x轴正方向上
        angle = 90;
    } else if (mouse_x == center_x && mouse_y > center_y) {//鼠标在y轴负方向上
        angle = 180;
    } else if (mouse_x < center_x && mouse_y == center_y) {//鼠标在x轴负方向
        angle = 270;
    }

    return angle;
}

export default rotate