<template>
  <div class="completeAgencyCertificationBox">
    <div class="cards" v-show="!detailShow && !editShow">
      <div class="info-short">
        <!-- <p class="title">{{$t('accountInfo.organ_manage')}}</p> -->
        <div class="info-card fl" v-for="(item, index) in mechanismList" :key="index" @click="detailInfo(item)">
          <span class="top-line" v-if="item.isExamine == 1">{{$t('accountInfo.certified')}}</span>
          <ul class="info-list" v-if="item.isExamine === 1">
            <li class="li-line">
              <span :class="{'english': $store.state.language=='en'}">{{$t('accountInfo.organ_name')}}</span>
              <label>{{item.mechanismName}}</label>
            </li>
            <li class="li-line">
              <span :class="{'english': $store.state.language=='en'}">{{$t('accountInfo.department')}}</span>
              <label>{{item.departmentName}}</label>
            </li>
          </ul>
          <div class="in_review" v-if="item.isExamine === 0">
            <h1>{{$t('accountInfo.audit')}}</h1>
            <p class="line">{{$t('accountInfo.audit_waiting')}}</p>
          </div>
          <div class="in_review" v-if="item.isExamine == 2">
            <h1 class="color_red">{{$t('accountInfo.audit_nopass')}}</h1>
            <button class="retry-btn" @click="retryToSubmit(item)">{{$t('accountInfo.re_auth')}}</button>
          </div>
          <img src="../../../assets/images/personalSettings/angecy.png" class="bottom-img" alt="" />
        </div>
        <div class="card-add fl" @click="addNew()">
          <img src="../../../assets/images/personalSettings/icon_add_normal.png" />
          <p>{{$t('accountInfo.add_organ')}}</p>
        </div>
      </div>

      <ul class="ul-list">
        <p class="p-tit">{{$t('accountInfo.auth_rights')}}</p>
        <li class="ul-li">{{$t('accountInfo.auth_pass_rights')}}</li>
        <li class="ul-li">{{$t('accountInfo.auth_right_one')}}</li>
        <li class="ul-li">{{$t('accountInfo.auth_right_two')}}</li>
        <li class="ul-li">{{$t('accountInfo.auth_right_three')}}</li>
      </ul>
    </div>

    <div class="info-detail" v-show="detailShow && !editShow">
      <a class="back-btn" @click="goback()"> 〈 {{$t('accountInfo.back')}}</a>
      <div class="completeImg">
        <img src="../../../assets/images/personalSettings/iconIdentity submitSuccessfully.svg" alt="" />
      </div>
      <div class="completeAgencyCertificationText">{{$t("organize_approve.complete_inst_licens")}}</div>
      <div class="subjectContent">
        <div class="subjectContentLabel">
          <span>{{$t("organize_approve.organName")}}</span>
          <span v-html="mechanismDetail.mechanismName"></span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('accountInfo.organ_shortname')}}</span>
          <span v-html="mechanismDetail.shortName"></span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t("organize_approve.office_name")}}</span>
          <span v-html="mechanismDetail.departmentName"></span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t("organize_approve.organ_type")}}</span>
          <template v-if="mechanismDetail.mechanismType == 1">
            <span>{{$t("organize_approve.public_hospital")}}</span>
          </template>
          <template v-if="mechanismDetail.mechanismType == 2">
            <span>{{$t("organize_approve.private_hospital")}}</span>
          </template>
          <template v-if="mechanismDetail.mechanismType == 3">
            <span>{{$t("organize_approve.theother_lab")}}</span>
          </template>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t("organize_approve.organ_site")}}</span>
          <span>
            {{ mechanismDetail.province }}
            {{ mechanismDetail.city ? "/" + mechanismDetail.city : "" }}
            {{ mechanismDetail.area ? "/" + mechanismDetail.area : "" }}
            {{
                        mechanismDetail.mechanismAddress
                            ? "/" + mechanismDetail.mechanismAddress
                            : ""
                    }}</span>
        </div>
        <div class="subjectContentLabel">
          <span v-html="
                        mechanismDetail.mechanismCodeType == 1
                            ? $t('organize_approve.organ_code')
                            : $t('organize_approve.unify_cridet_code')
                    "></span>
          <span v-html="mechanismDetail.mechanismCode"></span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('organize_approve.organ_code_certificate')}}</span>
          <span @click="showLayerImage(0)">
            <img :src="mechanismDetail.mechanismCodeFile" alt="" v-if="mechanismDetail.mechanismCodeFile" />
          </span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('organize_approve.medc_inst_licensNum')}}</span>
          <span v-html="mechanismDetail.practiceLicenseCode"></span>
        </div>
        <div class="subjectContentLabel">
          <span>{{$t('organize_approve.medc_inst_licens_document')}}</span>
          <span @click="showLayerImage(1)">
            <img :src="mechanismDetail.practiceLicense" alt="" v-if="mechanismDetail.practiceLicense" />
          </span>
        </div>
      </div>
      <!-- <div class="btns">
                <el-button type="primary" @click="edit()" class="save-btn btn">{{$t('btns.eidt')}}</el-button>
            </div> -->
    </div>
    <AgencyCertification v-show="editShow" :userId="userId" :detailInfo="detail" @childFun="popupUpdateFun($event)" @submitFun="editSubmit($event)"></AgencyCertification>
    <ImageLarge :isShowLayer="isShowLayer" :currentImageIndex="currentImageIndex" :currentImage="currentImage" :totalNum="totalNum" @changeCurrentImage="showCurImg" @showImage="showImg"></ImageLarge>

  </div>
</template>

<script>
import AgencyCertification from "./agencyCertification";
import ImageLarge from "../components/imageLarge.vue"
export default {
  name: "completeAgencyCertification",
  data() {
    return {
      mechanismList: [],
      detailShow: false,
      editShow: false,
      mechanismDetail: "",
      userId: "",
      detail: "",
      isShowLayer: false,
      currentImageIndex: 0,
      currentImage: '',
      totalNum: 2,
      imgArr: []//图片集合
    };
  },
  components: {
    AgencyCertification,
    ImageLarge
  },
  created() {
    const { mechanism, user } = this.$store.state.queryUserInfo;
    if (mechanism) {
      this.mechanismList = mechanism;
      this.userId = user.id;
    }

  },
  methods: {
    detailInfo(item) {
      if (item.isExamine != 1) return;
      this.mechanismDetail = item;
      this.imgArr = [this.mechanismDetail.mechanismCodeFile, this.mechanismDetail.practiceLicense];
      this.totalNum = this.imgArr.length;
      this.detailShow = true;
    },
    goback() {
      this.detailShow = false;
    },
    addNew() {
      this.editShow = true;
    },
    retryToSubmit(item) {
      this.detail = item;
      this.editShow = true;
    },
    popupUpdateFun(payload) {
      this.detailShow = payload;
      this.editShow = payload;
    },
    editSubmit(payload) {
      if (payload) {
        this.mechanismList = this.$store.state.queryUserInfo.mechanism;
      }
    },
    showLayerImage(index) {
      this.currentImageIndex = index;
      this.currentImage = this.imgArr[index];
      this.isShowLayer = true;
    },
    showCurImg(payload) {
      this.currentImage = this.imgArr[payload];
    },
    showImg(payload) {
      this.isShowLayer = payload;
    }
  },
  watch: {
    '$store.state.queryUserInfo': function (val) {
      const { mechanism, user } = val;
      if (mechanism) {
        this.mechanismList = mechanism;
        this.userId = user.id;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.completeAgencyCertificationBox {
  .cards {
    padding: 20px;
    .fl {
      float: left;
    }
  }
  .info-short {
    padding: 20px;
    overflow: hidden;
    .title {
      margin-bottom: 14px;
      font-size: 18px;
      font-weight: bold;
    }
    .info-card {
      position: relative;
      width: 300px;
      height: 118px;
      margin: 0 30px 30px 0;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
      }
      .top-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 56px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        font-weight: 600;
        background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
        border-radius: 8px 0px 8px 0px;
      }
      .info-list {
        padding: 0 5px;
        margin-top: 45px;
        font-size: 15px;
        .li-line {
          display: flex;
          // float: left;
          width: 100%;
          margin-top: 8px;
          font-size: 12px;
          span {
            &.english {
              width: 150px;
            }
            width: 82px;
            text-align: right;
          }
          label {
            width: calc(100% - 90px);
            font-weight: bold;
            word-wrap: break-word;
          }
        }
      }
      .bottom-img {
        position: absolute;
        bottom: 4px;
        right: 5px;
      }
    }
    .card-add {
      width: 300px;
      height: 118px;
      margin-top: 2px;
      // border: 1px solid #ddd;
      text-align: center;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      img {
        width: 29px;
        margin-top: 34px;
      }
      p {
        margin-top: 4px;
        font-size: 12px;
      }
    }
  }
  .ul-list {
    margin: 35px 0 0 20px;
    .p-tit {
      font-size: 16px;
      font-weight: bold;
    }
    .ul-li {
      color: #666;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .info-detail {
    position: relative;
    margin-top: 110px;
    .back-btn {
      position: absolute;
      top: -50px;
      left: 0;
      text-decoration: none;
      color: #333333;
      cursor: pointer;
    }
    .btns {
      // width: 115px;
      margin-top: 70px;
      margin-left: 215px;
    }
    .save-btn {
      width: 110px;
      height: 30px;
      padding: 0;
      margin: 0 auto 6px;
      border: 0;
      font-size: 14px;
      text-align: center;
      color: #fff;
      background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
      border-radius: 4px;
      &:hover {
        background: linear-gradient(90deg, #64a7c9 0%, #416ab0 100%);
        transition-duration: 0.5s;
      }
    }
  }
  .completeImg,
  .completeAgencyCertificationText {
    text-align: center;
  }

  .completeAgencyCertificationText {
    margin-top: 21px;
    font-size: 18px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;

    color: #4773b8;
  }

  .subjectContent {
    margin-top: 20px;

    .subjectContentLabel {
      margin-top: 18px;
      display: flex;

      & > :nth-child(1) {
        text-align: right;
        width: 200px;
        font-size: 16px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #666666;
      }

      & > :nth-child(2) {
        margin-left: 12px;
        font-size: 18px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #333333;

        img {
          width: 58px;
          // height: 58px;
          cursor: pointer;
        }
      }
    }
  }
  .in_review {
    padding-top: 25px;
    text-align: center;
    h1 {
      font-size: 16px;
      &.color_red {
        color: #f53500;
      }
    }
    .line {
      margin-top: 0;
      color: #999;
      font-size: 14px;
    }
    .retry-btn {
      background: none;
      border: 0;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: #4773b8;
      }
    }
  }
}
</style>
