<template>
  <div id="analysissystem" class="analysissystem">
    <span class="zoomImg" v-if="dataList.karyotypeSwitchVal == 1">
      <span @click="smallImg()"></span>
      <span @click="bigImg()"></span>
    </span>
    <div class="searchLink">
      <searchLink :additionalBtnSVal.sync="additionalBtnSVal" :dataList='dataList' :patientId="caseId">
      </searchLink>
    </div>
    <!-- 主区域 -->
    <div class="analys-wrap">
      <!-- 左侧 -->
      <div class="analys-patients">
        <!-- 病例列表区域 -->
        <div class="analys-patients-content">
          <analysLeftList :dataList="dataList" ref="mychildList">
          </analysLeftList>
        </div>
        <!-- 病例列表区域end -->
        <div class="analys-patients-footer">
          <information :dataList="dataList">
          </information>
        </div>
      </div>
      <!-- 左侧end -->
      <!-- 主体区域 -->
      <div class="analys-main" v-show="dataList.casesList == 1 && !dataList.beingAnalyzed">
        <div class="analys_main_select">
          <aaSortingFixed :batchAnalysisListSHow.sync="batchAnalysisListSHow" :isVisible.sync="isVisible" :dataList="dataList">
          </aaSortingFixed>
        </div>
        <!-- tab切换 筛选条件 核型图列表等等 -->
        <div class="analys-container">
          <div class="analysCenter" v-show="this.karyotypeSwitchIndex == 0" id="orderFullScreenName">
            <analysCenter ref="mychild" :dataList="dataList"></analysCenter>
          </div>
          <div v-if="this.karyotypeSwitchIndex == 1 &&!dataList.beingAnalyzed" style="height: calc(100% - 40px)">
            <DiagnosisReport :patientId="caseId"> </DiagnosisReport>
          </div>
        </div>
        <!-- tab切换 筛选条件 核型图列表等等end -->
      </div>
      <div class="analys-main" v-show="dataList.casesList == 2">
        <span class="casesListSpan">
          <img class="casesListSpanImg" src="../../assets/images/analysissystem/logo_main_interface.png" height="182" width="545" />
        </span>
      </div>
      <div class="analys-main" v-show="dataList.beingAnalyzed">
        <span class="beingAnalyzedSv">
          <span class="progressSvg">
            <el-progress type="circle" :percentage="dataList.percentage" :width="152" :stroke-width="7" color="#3A83CE" class="progressSvgCircle">{{$t("case_overview.analyzing")}}</el-progress>
          </span>
          <span class="loadingPer">{{$t("case_overview.auto_analyzing")}}</span>
        </span>
      </div>
      <!-- 主体区域 -->
    </div>
    <!-- 主区域end -->
  </div>
</template>

<script>
// import CaseAllocation from "./caseAllocation";
// import uploadCase from "./uploadCase.vue";
// import AvHeader from "../common/header.vue";
import "../../assets/scss/analysissystem.scss";
import analysLeftList from "./analysLeftList";
import analysCenter from "./analysCenter";
import DiagnosisReport from "./diagnosisReport";
import searchLink from "./searchLink";
// import NameSorting from "./nameSorting"; //顶部搜索模块
// import nameSorting from './nameSorting'; //名字排序
import information from './information'; //病例信息
import aaSortingFixed from './aaSortingFixed'; //打分排序
// import ExhibitionDiagnosisReportBox from "./exhibitionDiagnosisReport";

/*import {ossUrlWebFir,login} from '../../assets/utils/ossUrlWeb.js'*/
export default {
  name: "analysissystem",

  components: {
    // NameSorting,
    // AvHeader,
    analysLeftList,
    analysCenter,
    // CaseAllocation,
    // uploadCase,
    DiagnosisReport,
    searchLink,
    // nameSorting,
    information,
    aaSortingFixed
    // ExhibitionDiagnosisReportBox,
  },
  data() {
    return {
      karyotypeSwitch: [
        {
          value: this.$t("case_overview.karyoImage"),
        },
        {
          value: this.$t("case_overview.report"),
        },
      ],
      batchAnalysisListSHow: 1,//批量分析 批量删除 显示还是隐藏
      karyotypeSwitchIndex: 0,
      additionalBtnSVal: true,//追加分析
      dataList: {
        karyotypeSwitchVal: this.$store.state.karyotypeSwitchVal, //类型 1是缩略图 2是排列图
        automaticType: 1, //自动分析 1不可点击 2可点击
        hasAdditional: 1, //默认不可点击 追加分析
        rowList: {
          //默认一行显示多少照片
          row: this.$store.state.rowVal,
          myWidth: "300px",
        },

        searchValue: "", //搜索的的值
        casesList: 2,
        beingAnalyzed: false, //是不是正在分析
        percentage: 0, //分析进度
        id: localStorage.getItem("id"),
      },
      //多选
      checkAll: true,
      checkedCities: [],
      cities: this.$store.state.glassList,
      isIndeterminate: false,
      searchVal: "",

      //单选
      radio: this.$store.state.radioCheck,
      showImageVal: 1,
      caseId: this.$store.state.caseId,
      isShowCaseAllocation: false,
      changeListLeft: this.$store.state.changeListLeft, //监听上传病例之后刷新页面
      ossUploadAccess: this.$store.state.ossUploadAccess,
      timer: null, //防抖时间
      lengths: false,
      zoomingImgList: [ //zoomeingList 缩放比例
        {
          val: 8,
          myWidth: "12%",
        },
        {
          val: 7,
          myWidth: "13.7%",
        },
        {
          val: 6,
          myWidth: "16%",
        },
        {
          val: 5,
          myWidth: "19.3%",
        },
        {
          val: 4,
          myWidth: "24.3%",
        },
        {
          val: 3,
          myWidth: "32.7%",
        },
        {
          val: 2,
          myWidth: "49.5%",
        },
      ],
      isVisible: true,
      aiUnitNum: 20,
      titleMsg: "",
    };
  },
  created() {

  },
  methods: {



    uploadFinishHandle(type) {
      //type = autovision 说明是导入的autovision病例
      if (type != "autovision") {
        localStorage.removeItem("id");
      }
      //上传完成刷新左侧列表
      this.changeListLeft = new Date().getTime();
      this.$store.commit("changeListLeftType", this.changeListLeft);
    },

    //图片缩放公共
    zoomingImg() {
      this.zoomingImgList.forEach((item) => {
        if (this.dataList.rowList.row == item.val) {
          this.dataList.rowList.row = item.val;
          this.$store.commit("rowVal", item.val);
          this.dataList.rowList.myWidth = (this.$store.state.analysCenterClassWidth - (item.val - 1) * 10) / item.val + "px";

        }
      });
    },

    //缩小图片
    smallImg() {

      if (this.dataList.rowList.row < 8) {
        this.dataList.rowList.row = this.dataList.rowList.row + 1;
        this.zoomingImg();
      }
    },

    //放大图片
    bigImg() {

      if (this.dataList.rowList.row > 2) {
        this.dataList.rowList.row = this.dataList.rowList.row - 1;
        this.zoomingImg();
      }
    },

    //追加分析封装
    titleMsgList(){
          this.$confirm(this.titleMsg, this.$t("popup.add_analysis.title"), {
              confirmButtonText: this.$t("btns.confirm"),
              cancelButtonText: this.$t("btns.cancel"),
              type: "warning",
              closeOnClickModal: false,
          }).then(() => {
              let post_data = {
                  patientId: this.dataList.id,
                  type: 3,
              };
              this.$api.autoSegment(post_data).then((res) => {
                  if (res.code == 200) {
                      this.$store.commit('beingAnalyzed', 1);
                      let obj = {
                          patientId: this.dataList.id,
                          status: 1
                      }
                      this.$store.commit('patientIdStatusSave', obj);
                      this.$refs.mychild.queryKaryoListByPatients();
                  } else if(res.code != 122){
                      this.$message.error(res.message);
                  }
              });
          }).catch(() => {
              this.$message({
                  type: "info",
                  message: this.$t("popup.add_analysis.cancel_add"),
              });
          });
      },

    async queryKaryoNum() {

      return this.$api.queryKaryoNum({ patientId: this.dataList.id }).then((res) => {
        if (res.code == 200) {
          this.aiUnitNum = res.data.aiUnitNum;//追加分析核型图个数
        } else {
          this.$message.error(res.message);
        }
      });
    },


    async queryKaryoCom() {
      return this.$api.karyoAutoSegmen({
        patientId: this.dataList.id,
        type: 1
      }).then((res) => {
        if (res.code == 200) {

          if (res.data) {  //真
            this.titleMsg = this.$t("popup.add_analysis.title") + this.aiUnitNum + this.$t("popup.add_analysis.content")
          } else { //假
            this.titleMsg = this.$t("popup.add_analysis.title") + this.aiUnitNum + this.$t("popup.add_analysis.contentNext")
          }

            this.titleMsgList()
        } else {
          this.$message.error(res.message);
        }
      });
    },

  },
  mounted() {
  },
  computed: {},

  watch: {
    //进入核型分析
    'isVisible': function () {
      if (!this.isVisible) {
        this.$refs.mychild.IntoAnalysis();
      }
      this.isVisible = true;
    },

    //追加分析
    'additionalBtnSVal': async function () {
      if (!this.additionalBtnSVal) {
        await this.queryKaryoNum();
        await this.queryKaryoCom();

      }
      this.additionalBtnSVal = true;

    },

    "dataList.id": {
      immediate: true,
      handler: function (value, oldVal) {
        if (oldVal == undefined) return false;
        this.radio = 1;
        this.checked = false;
      },
    },

    //监听宽度
    "$store.state.analysCenterClassWidth": function () {
      this.dataList.rowList.myWidth = (this.$store.state.analysCenterClassWidth - (this.dataList.rowList.row - 1) * 10) / this.dataList.rowList.row + "px";
    },
    //监听缩略图 放大缩小 按钮的显示隐藏
    "$store.state.showImage": function () {
      this.showImageVal = this.$store.state.showImage;
    },

    //监听是排列图还是缩略图
    "$store.state.karyotypeSwitchVal": function (newValue) {
      this.dataList.karyotypeSwitchVal = newValue;
    },

    "$store.state.caseId": function () {
      this.caseId = this.$store.state.caseId;
      // this.dataList.id = localStorage.getItem("id");
    },

    //监听有没有单选框 复查有没有选中
    "$store.state.status": function () {
      this.radio = this.$store.state.status
    },

    //批量分析
    "$store.state.checkChangeVal": function () {
      this.$refs.mychild.queryKaryoListByPatients();
    },

  },

};
</script>
<style lang="scss" scoped>
.IntoAnalysis {
  position: absolute;
  display: block;
  width: 11px;
  height: 14px;
  right: 30px;
  bottom: 10px;
  cursor: pointer;
}
.searchLink {
  height: 60px;
  width: 100%;
  background: #ffffff;
  position: relative;
}
.analys-patients-footer {
  height: 328px;
  width: 100%;
  background: #eff0f2;
}

.analys_main_select {
  display: block;
  width: 100%;
  height: 38px;
  // background: #fff;
  margin: 0 0 5px 0;
}
</style>
