<template>
  <div class="diagnosisReportBox">
    <!-- <div ></div> -->
    <el-button class="pointer" type="button" @click="showSetup">{{$t("new_interaction.addnew_report")}}</el-button>
    <DialogPopup :visible="isShow" :title="$t('new_interaction.addnew_report')" @visibleChange="visibleChange" :closeFlag="false">
      <div slot="content" class="pop-cnt">
        <div class="emptyReportBox" v-if="!aloneReportInfo">
          <div>
            <img src="../../assets/images/analysissystem/iconEmptyReport.svg" alt="" />
          </div>
          <div class="btn" :class="[isAddReport ? 'determineNomal' : 'disableAddReport']" @click="addReport">
            {{$t('report.addnew_report')}}
          </div>
        </div>
        <div class="reporContentBox" v-if="aloneReportInfo">
          <div id="printingReport" class="center" ref="print">
            <div class="inside" id="list" ref="templateSelf" v-if="!isReportTemplate">
              <VueDragResize v-for="(rect, index) in rects" :key="index" :preventActiveBehavior="true" :w="rect.styles.width" :h="rect.styles.height" :x="rect.styles.left" :y="rect.styles.top" :ftSize="rect.styles.fontSize" :ftWeight="rect.styles.fontWeight" :ftColor="rect.styles.color" :ftFamily="rect.styles.fontFamily" :isActive="false" :isDraggable="false" :isResizable="false">
                <!-- 图片 -->
                <div class="outer-wrap img" v-if="rect.prop == LOGOIMG" ref="img">
                  <img v-if="rect.imgUrl" :src="rect.imgUrl" alt="image" class="avatar" />
                </div>
                <!-- label/input/select/date sca-->
                <div class="outer-wrap" v-else-if="
                                rect.prop == LABEL ||
                                rect.prop == SELECT ||
                                rect.prop == INPUT ||
                                rect.prop == DATE ||
                                rect.prop == LABELTEXTAREA
                            ">
                  <label class="label-width" v-if="
                                    rect.prop != INPUT &&
                                    rect.prop != LABELTEXTAREA
                                ">{{ rect.title || $t('selfDefined_template.designation') }}：</label>
                  <label class="label-textarea" :style="{
                                    height:
                                        utils.textWidth(rect.title, rect)
                                            .height + 'px',
                                }" v-if="rect.prop == LABELTEXTAREA">{{ rect.title || $t('selfDefined_template.designation') }}：</label>
                  <label v-if="rect.prop == INPUT" class="input-label" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }">{{ rect.value }}</label>
                  <el-input v-if="rect.prop == LABELTEXTAREA" class="lb-textarea" v-model="rect.value" type="textarea" readonly :style="{
                                    fontSize: rect.styles.fontSize + 'px',
                                    fontWeight: rect.styles.fontWeight,
                                    fontFamily: rect.styles.fontFamily,
                                    color: rect.styles.color,
                                }" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }"></el-input>
                  <label v-if="
                                    rect.prop != INPUT &&
                                    rect.prop != LABELTEXTAREA
                                " class="line" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }" readonly>{{ rect.value }}</label>
                </div>
                <!-- 核型图排列图占位区 -->
                <div class="outer-wrap img-position" v-else-if="rect.prop == IMGPOSITION" :class="{noline: !rect.configObj.showLabelLine,'dashed-line': rect.configObj.showDashedLine,}">
                  <div class="sp" v-if="rect.prop == IMGPOSITION &&rect.field == KARYO">
                    <img v-if="!!rect.imgUrl" :key="index" :src="rect.imgUrl" :style="{width: rect.imgWidth + 'px'}" class="avatar" :alt="$t('case_overview.karyoImage')" />
                  </div>
                  <div class="sp" v-if="rect.prop == IMGPOSITION &&rect.field == SEQUENCE">
                    <img v-if="!!rect.imgUrl" :key="index" :src="rect.imgUrl" :style="{width: rect.imgWidth + 'px'}" class="avatar" :alt="$t('case_overview.array_image')" />
                  </div>
                  <div class="sp" v-if="rect.type == CUSTOM &&rect.prop == IMGPOSITION">
                    <img :src="rect.imgUrl" v-if="!!rect.imgUrl" class="avatar" :alt="$t('selfDefined_template.image')" />
                  </div>
                </div>
                <div class="outer-wrap esignature" v-else-if="
                                rect.type == CUSTOM && rect.prop == ESIGNATURE
                            ">
                  <label class="label-width">{{ rect.title || $t('selfDefined_template.designation') }}：</label>

                  <img v-if="rect.value" :src="rect.value" :alt="$t('selfDefined_template.e-signature')" />
                </div>
                <div v-else-if="rect.prop == BORDERKUG" class="outer-wrap">
                  <div class="border-kug" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }"></div>
                </div>
                <!-- 横线 -->
                <div v-else-if="rect.prop == LINE" class="outer-wrap controlline">
                  <p class="control-line" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }"></p>
                </div>
                <!-- 输入框 -->
                <div v-else class="outer-wrap">
                  <textarea v-model="rect.value" readonly class="textarea" :style="{
                                    fontSize: rect.styles.fontSize + 'px',
                                    fontWeight: rect.styles.fontWeight,
                                    fontFamily: rect.styles.fontFamily,
                                    color: rect.styles.color,
                                }" :class="{
                                    noline: !rect.configObj.showLabelLine,
                                    'dashed-line':
                                        rect.configObj.showDashedLine,
                                }"></textarea>
                </div>
              </VueDragResize>
            </div>
            <div v-if="isReportTemplate" class="originalTemplateOuterBox">
              <OriginalTemplate :aloneReportInfo="aloneReportInfo"></OriginalTemplate>
            </div>
          </div>
          <div class="exhibitionDiagnosisReporBtn">
            <el-select v-model="reportId" :placeholder="$t('select_button.choose')" @change="selectReport">
              <el-option v-for="item in diagnosisReportList" :key="item.reportId" :label="item.label" :value="item.reportId">
              </el-option>
            </el-select>
            <div v-print="printObj" ref="printingReport"></div>
            <div class="reportBtn" @click="updateReportName" style="margin-top: 24px;">{{$t('report.print_report')}}</div>
            <div class="reportBtn" @click="editReport">{{$t('report.edit_report')}}</div>
            <div class="reportBtn" @click="deleteReport" :disabled="delClick">
              {{$t('report.delete_report')}}
            </div>
            <div class="reportBtn" @click="addReport">{{$t('report.addnew_report')}}</div>
          </div>
        </div>
      </div>
    </DialogPopup>
    <!-- 确认删除弹窗 -->
    <MessageBox :updateType="isDelete" :updateTips="deleteTips" :openStatus="openStatus" @childFun="parentFun($event)"></MessageBox>
    <!-- 新增报告 -->
    <GenerateForm :isVisible="dialogVisibleStatus" :patientId="newPatientId" :templateList="templateList" :reportId="newReportId" @childFun="parentFormFun($event)" @onsubmit="parentSubmit($event)"></GenerateForm>
    <!-- 编辑 -->
    <AddDiagnosisReport :dialogVisible="dialogOldStatus" :reportId="newReportId" :patientId="newPatientId" @childFun="addReportDialogVisibleFun($event)"></AddDiagnosisReport>
  </div>
</template>

<script>
import "../../assets/scss/button.scss";
import MessageBox from "./../messageBox";
import GenerateForm from "../selfDefineTemplate/generateForm";
import AddDiagnosisReport from "./addDiagnosisReport";
import VueDragResize from "../selfDefineTemplate/components/vue-drag-resize/vue-drag-resize.vue";
import common from "../../assets/js/common.js";
import OriginalTemplate from "./originalTemplate"; //老版本报告
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import utils from "../selfDefineTemplate/config/utils";
import DialogPopup from "../selfDefineTemplate/components/dialogPopup";

import {
  SELECT,
  INPUT,
  TEXTAREA,
  LABEL,
  DATE,
  LOGOIMG,
  IMGPOSITION, //图片占位区
  KARYO,
  SEQUENCE,
  OFFICIAL,
  CUSTOM,
  ESIGNATURE,
  BORDERKUG,
  LINE,
  LABELTEXTAREA,
} from "../selfDefineTemplate/config/props";
import shortKey from '../../assets/utils/shortKey';

export default {
  name: "DiagnosisReport",
  props: ["patientId"],
  created() {
    // this.newPatientId = this.patientId;
    // this.getReportListByPatientId();
  },
  components: {
    MessageBox,
    GenerateForm,
    VueDragResize,
    OriginalTemplate,
    html2canvas,
    AddDiagnosisReport,
    DialogPopup
  },
  data() {
    return {
      printObj: {
        id: "printingReport",//打印区域 Dom ID
        // popTitle: '打印页面标题文字',
        // extraCss: 'https://www.google.com,https://www.google.com',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>,<style> #printingReport { transform: scale(0.8); } </style>',//  可以传进去  style tag 标签；注意要逗号分隔   解决特定区域不显示问题；
      },
      isShow: false,//报告弹窗
      deleteTips: this.$t('popup.warning.isDelete_report'),
      isDelete: "delete",
      diagnosisReportList: [],
      templateList: [],
      rects: [],
      reportId: "", //选择框中reportId
      newReportId: "", //新增修改中的newReportId
      newPatientId: "",
      aloneReportInfo: "", //报告信息
      dialogVisibleStatus: false,
      dialogOldStatus: false,
      openStatus: false,
      isAddReport: true, //是否添加报告
      isReportTemplate: false,
      loadPdf: false,
      onsubmit: false,
      templateLen: 0, //模板个数
      delClick: false, //删除模板之后，获取报告成功后，delClick为false
      SELECT,
      INPUT,
      TEXTAREA,
      LABEL,
      DATE,
      LOGOIMG,
      IMGPOSITION, //图片占位区
      KARYO,
      SEQUENCE,
      OFFICIAL,
      CUSTOM,
      ESIGNATURE,
      BORDERKUG,
      LINE,
      LABELTEXTAREA,
      utils,
      owidth: 1000,
      oheight: 906,
      no_power: false
    };
  },
  methods: {
    cancel() {
      this.isShow = false;
    },
    visibleChange(val) {
      this.isShow = val;
    },
    async showSetup() {
      this.newPatientId = this.patientId;
      if (!this.patientId) {
        this.$message(this.$t('report_template.no_cases'));
        return;
      }
      await this.getQueryKaryoList();
      if (this.isAddReport == false) {
        this.$message(this.$t("new_interaction.Karyotype_diagram_statistics"));
        return;
      }
      await this.getReportListByPatientId();
      this.isShow = true;
      if (!this.templateLen) {
        this.$confirm(this.$t("popup.warning.add_report_remind"), this.$t("popup.warning.remind"), {
          confirmButtonText: this.$t('btns.add_template'),
          cancelButtonText: this.$t('btns.cancel'),
          closeOnClickModal: false,

          type: "warning",
        }).then(() => {
          this.$router.push({
            name: "个人设置页",
            params: { tabType: "template_info" },
          });
          this.isShow = false;
        }).catch({});
        return;
      }
      // if (!this.diagnosisReportList.length) {
      //     this.dialogVisibleStatus = true;
      // }
      this.newReportId = "";
    },
    /**
     * @method: 获取病例所有报告单
     * @Date: 2020-12-28 12:35:15
     */
    async getReportListByPatientId() {
      if (this.newPatientId == "") return;
      return this.$api.reportListByPatientId({ patientId: this.newPatientId }).then(async (res) => {
        if (res.code == 200) {
          await this.templateListFn();
          const { list } = res.data;
          this.diagnosisReportList = [];
          this.newReportId = '';
          this.reportId = '';
          if (list.length > 0) {
            list.forEach((item) => {
              item.reportId = item.id;
              item.label =
                item.patientName +
                "/" +
                item.glassName +
                "/" +
                item.cellName;
            });
            this.diagnosisReportList = list;
            this.newReportId = list[0].id;
            this.reportId = list[0].id;
            this.getReportQuery(this.newReportId);
          } else {
            this.aloneReportInfo = "";
            this.$store.commit("caseImgType", true);
          }
        } else {
          this.no_power = true;
          this.$message.error(res.message);
        }
      });
    },
    async templateListFn() {
      return this.$api.templateList({ patientId: this.patientId }).then((res) => {
        if (res.code == 200) {
          this.templateList = [];
          const { template, offcialTemplate } = res.data;
          this.templateList = template;
          this.templateLen = template.length;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @method: 查询报告单
     * @Date: 2020-12-29 09:13:37
     */
    getReportQuery(id) {
      this.$api.reportQuery({ id: id }).then((res) => {
        if (res.code == 200) {
          const { report, reportTemplate } = res.data;
          this.karyotypeId = report.karyoId;
          report.optUrl = this.ossUrlWebFir(
            report.optUrl,
            1,
            200,
            150
          );
          report.arrayUrl = this.ossUrlWebFir(
            report.arrayUrl,
            1,
            500,
            400
          );
          if (!reportTemplate) {
            // 没有自定义模板
            this.isReportTemplate = true;
            this.aloneReportInfo = [report];
          } else {
            this.isReportTemplate = false;
            this.aloneReportInfo = report;
            this.mapData(reportTemplate);
          }

          if (this.loadPdf && this.onsubmit) {
            this.$nextTick(() => {
              this.htmlToImage();
            });
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @method: 对比数据
     * @Date: 2020-12-28 13:25:27
     */
    mapData(reportTemplate) {
      const content =
        typeof reportTemplate.content == "string"
          ? JSON.parse(reportTemplate.content)
          : {};
      const KARYOArr =
        typeof this.aloneReportInfo[KARYO] == "string"
          ? JSON.parse(this.aloneReportInfo[KARYO])
          : [];
      const SEQUENCEArr =
        typeof this.aloneReportInfo[SEQUENCE] == "string"
          ? JSON.parse(this.aloneReportInfo[SEQUENCE])
          : [];

      this.loadPdf = content.loadPdf;

      content.rects.forEach((item) => {
        item.imgUrl = !!item.imgUrl ? this.ossUrlWebFir(
          item.imgUrl.split('?')[0],
          1,
          item.styles.width,
          item.styles.height
        ) : ''
        if (item.type == OFFICIAL) {
          if (item.field === KARYO) {
            KARYOArr.forEach((it) => {
              if (item.id === it.id) {
                // if (it.imgUrl.indexOf('.online') == -1) {
                item.imgUrl = this.ossUrlWebFir(
                  it.imgUrl,
                  1,
                  item.styles.width,
                  item.styles.height
                );
                // } else {
                //     let width;
                //     if (item.styles.width > item.styles.height) {
                //         width = parseInt((4 * item.styles.height) / 3);
                //     } else {
                //         width = item.style.width;
                //     }
                //     item.imgUrl = it.imgUrl;
                //     item.imgWidth = width;
                // }
              }
            });
          } else if (item.field === SEQUENCE) {
            SEQUENCEArr.forEach((it) => {
              if (item.id === it.id) {
                // if (it.imgUrl.indexOf('.online') == -1) {
                item.imgUrl = this.ossUrlWebFir(
                  it.imgUrl,
                  1,
                  item.styles.width,
                  item.styles.height
                );
                // } else {
                //     let width;
                //     if (item.styles.width > item.styles.height) {
                //         width = parseInt((4 * item.styles.height) / 3);
                //     } else {
                //         width = item.style.width;
                //     }
                //     item.imgUrl = it.imgUrl;
                //     item.imgWidth = width;
                // }
              }
            });
          } else if (item.prop === DATE) {
            item.value = common.timestampFormat(
              this.aloneReportInfo[item.field],
              item.configObj.format
            );
          } else {
            item.value = this.aloneReportInfo[item.field];
          }
        } else {
          if (!!this.aloneReportInfo.extInfo) {
            let extInfo = JSON.parse(this.aloneReportInfo.extInfo);
            item.value = extInfo[item.field];
            if (item.prop === ESIGNATURE) {
              !!item.value
                ? (item.value = this.ossUrlWebFir(
                  extInfo[item.field],
                  1,
                  80,
                  40
                ))
                : "";
            }
          }
        }
      });
      this.rects = content.rects;
    },
    /**
     * @method: 是否添加报告
     * @Date: 2020-12-28 12:35:54
     */
    async getQueryKaryoList() {
      if (this.newPatientId == "") return;
      return this.$api.queryKaryoList({ patientId: this.newPatientId }).then((res) => {
        if (res.code == 200) {
          if (res.data.statisInfo.typeSureNum > 0) {
            this.isAddReport = true;
          } else {
            this.isAddReport = false;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    selectReport(id) {
      this.reportId = id;
      this.newReportId = id;
      !!id ? this.getReportQuery(id) : "";
    },
    deleteReport() {
      this.openStatus = true;
    },
    /**
     * @method: 删除报告
     * @Date: 2020-12-28 12:36:48
     */
    postReportDel() {
      this.delClick = true;
      this.$api.reportDel({ id: this.reportId }).then(async (res) => {
        if (res.code == 200) {
          for (let i = 0; i < res.data.length; i++) {
            setTimeout(() => {
              this.$store.commit("reviseGlassIdStyle", res.data[i]);
            }, 50);
          }
          this.newReportId = "";
          await this.getReportListByPatientId();
          this.delClick = false;
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    parentFun(payload) {
      this.openStatus = false;
      if (!payload) return;
      this.postReportDel();
    },
    // 旧版
    addReportDialogVisibleFun(payload) {
      this.dialogOldStatus = payload;
    },
    editReport() {
      if (this.isReportTemplate) {
        this.dialogOldStatus = true;
      } else {
        this.dialogVisibleStatus = true;
      }
      this.newReportId = this.reportId;
    },
    addReport() {
      this.newReportId = "";
      this.dialogVisibleStatus = true;
    },
    /**
     * @method: 生成表单关闭按钮
     * @Date: 2020-12-22 10:19:56
     */
    parentFormFun(payload) {
      this.dialogVisibleStatus = payload;
    },
    parentSubmit(payload) {
      this.onsubmit = payload;
    },
    templateLength(len) {
      this.templateLen = len;
    },
    /**
     * @method: 打印更改pdf名称
     * @Date: 2021-01-05 09:24:31
     */
    updateReportName() {
      let title = document.title;
      document.title = this.aloneReportInfo[0]
        ? this.aloneReportInfo[0].patientName
        : this.aloneReportInfo.patientName;
      this.$refs.printingReport.click();
      setTimeout(() => {
        document.title = title;
      }, 500);
    },

    htmlToImage() {
      const template = this.$refs["templateSelf"];
      return html2canvas(template, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png", 1);
        let pdf = new jsPDF("1", "pt", [canvas.width, canvas.height]);
        pdf.addImage(dataURL, "JPEG", 0, 0, canvas.width, canvas.height);
        pdf.save(this.aloneReportInfo[0] ? this.aloneReportInfo[0].patientName : this.aloneReportInfo.patientName + ".pdf");
      });
    },
  },
  mounted() {
    // this.getQueryKaryoList();
  },
  watch: {
    patientId() {
      this.newPatientId = this.patientId;
      //   if (this.isShow) {
      //     this.getQueryKaryoList();
      //     this.getReportListByPatientId();
      //   }
    },
    isShow(newVal) {
      if (newVal) {
        this.newPatientId = this.patientId;
      }
      shortKey.disabled = newVal;
    }
  },
};
</script>

<style lang="scss" scoped>
.searchLink .diagnosisReportBox {
  padding: 0 14px;
}
.diagnosisReportBox {
  width: 100%;
  height: 100%;
  // padding: 0 14px;
  // background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  .pointer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    border-radius: 4px;
    background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
    border: none;
    color: #fff;
    margin: 0;
    &:disabled {
      background: #fff;
      color: rgba(0, 0, 0, 0.25);
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
  }

  .emptyReportBox {
    display: flex;
    height: 100%;
    margin-top: -50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .determineNomal {
      margin-top: 27px;
      width: 128px;
      height: 40px;
      font-size: 16px;
    }
    .disableAddReport {
      margin-top: 27px;
      width: 128px;
      height: 40px;
      font-size: 16px;
    }
  }
  .pop-cnt {
    height: 866px;
    border-radius: 6px;
    overflow: hidden;
  }
  .reporContentBox {
    display: flex;
    border-radius: 4px;
    height: 100%;
    .exhibitionDiagnosisReporBtn {
      width: 224px;
      height: 100%;
      height: 100%;
      padding: 0 10px;
      background: #fff;
      .reportBtn {
        margin-top: 12px;
      }
    }
  }
}
</style>
<style lang="scss">
.diagnosisReportBox {
  .el-dialog__footer {
    display: none !important;
  }
  .el-dialog__wrapper {
    .el-dialog {
      width: 1000px;
      height: 906px;
    }
    .el-dialog__header {
      div {
        padding: 0 16px !important;
        box-sizing: border-box;
      }
    }
    .el-dialog--center .el-dialog__body {
      height: 866px;
      padding: 0 !important;
      background: #eff0f2;
      border-radius: 6px !important;
    }
  }
  .exhibitionDiagnosisReporBtn {
    .el-select {
      width: 224px;
      height: 40px;
      background: #ffffff;
      border-radius: 2px;
    }
    .el-input {
      width: 224px;
      height: 40px;
      background: #ffffff;
      border-radius: 2px;
      margin-top: 16px;
    }
  }
}
.el-message-box__btns button:first-child,
.el-message-box__btns button:nth-child(2) {
  width: unset !important;
  padding: 0 6px !important;
  min-width: 50px;
}
#printingReport {
  width: 756px;
  height: 1080px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 14px 0px #d8d8d8;
  transform: scale(0.78) translateY(-136px);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex: 1;
  // font-family: SimSun;
  .inside {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 40px;
    left: 26px;
    transform: scale(0.95);
  }
  .outer-wrap {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 26px;
    box-sizing: border-box;
    .el-input {
      flex: 1;
      outline: none;
    }
    .label-width {
      display: flex;
      height: 100%;
      align-items: center;
    }
    .input-label {
      display: flex;
      width: 100%;
      height: 100%;
      white-space: pre;
      justify-content: center;
      align-items: center;
      border: 1px solid #6b6a6a;
      border-radius: 4px;
      &.dashed-line {
        border-bottom: 1px dashed #6b6a6a;
      }
      &.noline {
        border: 0;
      }
    }
    .colon {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .line {
      display: flex;
      flex: 1;
      height: 100%;
      align-items: center;
      border: 0;
      border-bottom: 1px solid #6b6a6a;
      background: none;
      overflow: hidden;
      outline: none;
      font-weight: normal;
      white-space: pre-wrap;
      &.custom-mrg {
        margin: 0 0 0 -8px;
      }
      &.dashed-line {
        border-bottom: 1px dashed #6b6a6a;
      }
      &.noline {
        border: 0;
      }
    }
    &.controlline {
      justify-content: center;
      align-items: center;
      min-height: 1px;
    }
    .control-line {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #6b6a6a;
      margin: 0;
      &.dashed-line {
        border: 0;
        border-bottom: 1px dashed #6b6a6a;
      }
      &.noline {
        border: 0;
      }
    }
    .nokug {
      width: 100%;
      border: 1px solid #6b6a6a;
      text-align: center;
      border-radius: 4px;
      input {
        border: 0;
        font-weight: inherit;
        font-family: inherit;
        height: 100%;
        padding: 0;
        text-align: center;
        background: none;
      }
      &.dashed-line {
        border: 1px dashed #6b6a6a;
      }
      &.noline {
        border: 0;
      }
    }
    .el-input__inner {
      color: #333;
    }
    .label-textarea {
      position: relative;
      display: flex;
      height: auto;
      min-height: 24px;
      align-items: center;
    }
    .lb-textarea {
      flex: 1;
      textarea {
        outline: none;
        resize: none;
        line-height: 22px;
        height: 100%;
        border: 1px solid #6b6a6a;
      }
      .el-textarea__inner {
        padding: 2px;
      }
      &.dashed-line textarea {
        border: 1px dashed #6b6a6a;
      }
      &.noline textarea {
        border: 0;
      }
    }
    &.img-position {
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
      align-items: center;
      border: 1px solid #6b6a6a;
      border-radius: 4px;
      overflow: hidden;
      &.dashed-line {
        border: 1px dashed #6b6a6a;
      }
      &.noline {
        border: 0;
      }
      .sp {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      .el-upload--picture-card {
        width: 100% !important;
        height: 100% !important;
      }
      img {
        width: calc(100% - 6px);
        margin-top: 1px;
      }
    }
    .textarea {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 5px;
      line-height: 20px;
      border: 1px solid #6b6a6a;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;
      resize: none;
      overflow: hidden;
      &.dashed-line {
        border: 1px dashed #6b6a6a;
      }
      &.noline {
        border: 0;
      }
    }
    &.esignature {
      img {
        height: 26px;
      }
    }
    .border-kug {
      border: 1px solid #6b6a6a;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      z-index: 0;
      &.dashed-line {
        border: 1px dashed #6b6a6a;
      }
      &.noline {
        border: 0;
      }
    }
  }
  .originalTemplateOuterBox {
    width: 100%;
    height: 100%;
  }
}
@media print {
  html,
  body {
    overflow: hidden !important;
    #printingReport {
      margin: auto;
      // margin-top: 150px;
      box-shadow: none;
      transform: translateX(-20px) translateY(-15px) scale(1.03) !important;
      // transform: scale(1.3, 1.45) !important;
      // -webkit-transform: scale(1.3, 1.45) !important;
      // -moz-transform: scale(1.3, 1.45) !important;
    }
  }
}
</style>
