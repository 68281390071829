<template>
    <!-- 新的支付页面 -->
    <div class="divForm" v-html="divFormHtml"></div>
</template>

<script>
export default {
    name: "PaymentPage",
    data() {
        return {
            divFormHtml: ""
        };
    },
    methods: {},
    created() {
        this.divFormHtml = localStorage.getItem("divFormHtml");
        let setDivForm = setInterval(function() {
            let forms = document.forms[0];
            if (forms) {
                forms.submit();
                clearInterval(setDivForm);
            }
        });
    }
};
</script>

<style></style>
