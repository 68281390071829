<template id="A">
  <div class="aaSortingFixed">

    <!--打分筛选-->
    <span class="aaSortingFixed_fir">
      <span class="aaSortingFixed_fir_title">{{$t('new_interaction.sort')}}</span>
      <span class="aaSortingFixed_fir_span" @click="daFenBtn(1)">
        <span :class="sortTypeList == 1?'daFenActive':''">{{$t('new_interaction.mark')}}</span>
      </span>

      <span class="aaSortingFixed_fir_span" @click="daFenBtn(2)">
        <span :class="sortTypeList == 2?'daFenActive':''">{{$t('new_interaction.glass')}}</span>

      </span>
      <span class="aaSortingFixed_fir_span" @click="daFenBtn(3)">
        <span :class="sortTypeList == 3?'daFenActive':''">{{$t('new_interaction.progress_bar')}}</span>
      </span>
    </span>

    <!--颜色筛选-->
    <span class="get_color_output">
      <span class="get_color_output_title">{{$t('new_interaction.color_filter')}}</span>
      <ul class="get_color_output_ul">
        <li @click="getColorOutput(4)"><img :src="colorVal==4?red_select:red_normal"></li>
        <li @click="getColorOutput(1)" :class="colorVal==1?'red_normal':'red_select'"></li>
        <li @click="getColorOutput(2)" :class="colorVal==2?'yellow_normal':'yellow_select'"></li>
        <li @click="getColorOutput(3)" :class="colorVal==3?'blue_normal':'blue_select'"></li>
        <li @click="getColorOutput(0)" :class="colorVal==0?'wight_normal':'wight_select'"></li>
      </ul>

    </span>
    <!--排列图切换-->
    <span class="BuildChart_check">
      <span>
        <img :src="icon_Thumbnail_select" @click="karyotypeSwitch(1)" />
        <img :src="icon_List_normal" @click="karyotypeSwitch(2)" />
      </span>
    </span>

    <span class="list_batches" v-if="batchAnalysisListSHow == 2">
      <span @click="automaticBtnS()" :class="additionalDisabledSo==0?'additionalSo':'additionalDisabledSo'">{{$t('new_interaction.batch_analysis')}}</span>
      <span @click="deleteAll()">{{$t('new_interaction.batch_delete')}}</span>
    </span>

    <span class="karyotypeDiagram" @click="handleClose()">
      <span>{{$t('new_interaction.enter_karyotype_analysis')}}</span>
      <img :src="icon_more_normal" />
    </span>
  </div>
</template>

<script>
export default {
  props: ['batchAnalysisListSHow', 'dataList'],
  name: "aaSortingFixed",
  data() {
    return {
      selectImg: [
        require('@/assets/images/analysissystem/icon_sort_normal.png'),
        require('@/assets/images/analysissystem/icon_sort_bottom.png'),
        require('@/assets/images/analysissystem/icon_sort_top.png')
      ],
      sortTypeList: this.$store.state.sortTypeList,
      red_normal: require('@/assets/images/analysissystem/all_hover.png'),
      red_select: require('@/assets/images/analysissystem/all_select.png'),
      allSelect: require('@/assets/images/analysissystem/all_hover.png'),
      icon_Thumbnail_select: require('@/assets/images/analysissystem/icon_Thumbnail_select.svg'),
      icon_List_normal: require('@/assets/images/analysissystem/icon_List_normal.svg'),
      icon_more_normal: require('@/assets/images/analysissystem/icon_more_normal.png'),
      colorVal: this.$store.state.color,
      karyotypeSwitchVal: this.$store.state.karyotypeSwitchVal, //类型 1是缩略图 2是排列图
      additionalDisabledSo:0
    }
  },
  methods: {

    handleClose() {
      this.$emit('update:isVisible', false);
    },

    daFenBtn(value) {

      this.sortTypeList = value;
      this.$store.commit("sortTypeList", value);
    },

    //颜色选择
    getColorOutput(val) {
      this.colorVal = val;
      this.$store.commit("color", val);
    },

    //排列图 缩略图
    karyotypeSwitch(val) {
      switch (val) {
        case 1:
          this.karyotypeSwitchVal = 1;
          this.icon_Thumbnail_select = require('@/assets/images/analysissystem/icon_Thumbnail_select.svg');
          this.icon_List_normal = require('@/assets/images/analysissystem/icon_List_normal.svg');
          this.$store.commit("karyotypeSwitchVal", val);
          break;
        case 2:
          this.karyotypeSwitchVal = 2;
          this.icon_Thumbnail_select = require('@/assets/images/analysissystem/icon_Thumbnail_normal.svg');
          this.icon_List_normal = require('@/assets/images/analysissystem/icon_List_select.svg');
          this.$store.commit("karyotypeSwitchVal", val);
          break;
      }
    },

    //批量分析
    automaticBtnS() {
        //this.$store.commit('beingAnalyzed', 0);

      if(this.$store.state.beingAnalyzed ==1) return false;
      this.$api.karyoAutoSegmen({
        patientId: this.dataList.id,closeOnClickModal: false,
          karyoIds: this.batchAnalysisList.toString(),
        type: 2
      }).then((res) => {
        if (res.code == 200) {
          if (res.data) {  //真
            this.AnalysisAll();
          } else { //假
            this.titleMsg = this.$t("popup.add_analysis.contentAnalysis");
            this.$confirm(this.titleMsg, this.$t("popup.add_analysis.title"), {
              confirmButtonText: this.$t("btns.confirm"),
              cancelButtonText: this.$t("btns.cancel"),
              type: "warning",
             closeOnClickModal: false
            }).then(() => {
              this.AnalysisAll();
            }).catch(() => {
              this.$message({ type: "info", message: this.$t("popup.add_analysis.cancel_add") });
            });
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },

    //批量分析
    AnalysisAll: function () {
      let post_data = {
        patientId: this.dataList.id,
        ids: this.batchAnalysisList.toString(),
        type: 2,
      };
      this.$api.autoSegment(post_data).then((res) => {
        if (res.code == 200) {
          let checkChangeVal1 = this.$store.state.checkChangeVal + 1;
          this.$store.commit("checkChangeVal", checkChangeVal1);
          this.$emit('update:batchAnalysisListSHow', 1);
        } else if(res.code != 122){
          this.$message.error(res.message);
        }
      });
    },
    //批量删除
    deleteAll() {
      this.$confirm(this.$t("popup.warning.delete_cell"), this.$t("popup.warning.remind"), {
        confirmButtonText: this.$t("btns.confirm"),
        cancelButtonText: this.$t("btns.cancel"),
        type: "warning",
          closeOnClickModal: false
      }).then(() => {

        let post_data = {
          patientId: this.dataList.id,
          ids: this.batchAnalysisList.toString(),
        }; this.$api.delete(post_data).then((res) => {
          if (res.code == 200) {
            this.$emit('update:batchAnalysisListSHow', 1);
            let checkChangeDeleteVal = this.$store.state.checkChangeDelete + 1;
            this.$store.commit("checkChangeDelete", checkChangeDeleteVal);
          } else {
            this.$message.error(res.message);
          }
        });

      }).catch(() => {

      });

    }

  },
  watch: {
    '$store.state.sortTypeList': function () {
      this.sortTypeList = this.$store.state.sortTypeList;
    },
    '$store.state.color': function () {
      this.colorVal = this.$store.state.color;
    },
    //监听玻片有没有正在分析中的
    '$store.state.beingAnalyzed':function (val) {
        if(val == 0){
            this.additionalDisabledSo = 0
        }
        if(val == 1){
            this.additionalDisabledSo = 1
        }
    },
    //监听批量分析
    "$store.state.batchAnalysisList": function () {
      this.batchAnalysisList = this.$store.state.batchAnalysisList;
      if (this.batchAnalysisList.length > 0) {
        console.log(1)
        this.$emit('update:batchAnalysisListSHow', 2);
      } else {
        console.log(2)
        this.$emit('update:batchAnalysisListSHow', 1);
      }
    },

  }
}
</script>

<style scoped>
.aaSortingFixed {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  border-top: 1px solid #e4e5e6;
  border-left: 1px solid #e4e5e6;
}
.aaSortingFixed_fir {
  display: block;
  height: 20px;
  border-right: 1px solid #c6c6c6;
  margin: 0 0 0 12px;
  padding-right: 10px;
}
.aaSortingFixed_fir_title {
  float: left;
  height: 20px;
  width: 40px;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
}
.aaSortingFixed_fir_span {
  float: left;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  cursor: pointer;
}
.aaSortingFixed_fir_span span {
  color: #333333;
  float: left;
  line-height: 16px;
  height: 20px;
  border-radius: 4px;
  text-align: center;
  padding: 0px 7px;
}
.aaSortingFixed_fir_span span:hover {
  color: #4773b8;
}
.aaSortingFixed_fir_span img {
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-top: 4px;
}

.get_color_output {
  height: 20px;
  width: 425px;
  border-right: 1px solid #c6c6c6;
  margin-left: 10px;
}
.get_color_output_title {
  float: left;
  height: 20px;
  width: 65px;
  font-size: 12px;
  line-height: 20px;
  color: #333333;
}
.get_color_output_ul {
  width: 360px;
  height: 20px;
  float: left;
}
.get_color_output_ul li {
  width: 60px;
  height: 20px;
  float: left;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
}
.red_select {
  background: #e72323;
}
.red_select:hover {
  background: #fc5050;
}
.red_normal {
  background: #f84e4e;
  border: 2px solid #3982f4;
  height: 16px !important;
  width: 56px !important;
}

.yellow_select {
  background: #fff25c;
}
.yellow_select:hover {
  background: #fff57e;
}
.yellow_normal {
  background: #ffeb68;
  border: 2px solid #3982f4;
  height: 16px !important;
  width: 56px !important;
}

.blue_select {
  background: #429dff;
}
.blue_select:hover {
  background: #65afff;
}
.blue_normal {
  background: #5c99ef;
  border: 2px solid #3982f4;
  height: 16px !important;
  width: 56px !important;
}

.wight_select {
  height: 18px !important;
  width: 58px !important;
  background: #ffffff;
  border: 1px solid #d8d8d8;
}
.wight_select:hover {
  background: #d8d8d8;
}
.wight_normal {
  background: #ffffff;
  border: 2px solid #3982f4;
  height: 16px !important;
  width: 56px !important;
}

.get_color_output_ul li img {
  width: 60px;
  height: 20px;
  display: inline-block;
}

.BuildChart_check {
  height: 20px;
  width: 109px;
  border-right: 1px solid #c6c6c6;
}
.BuildChart_check span {
  display: block;
  width: 68px;
  height: 24px;
  margin: -2px auto;
  cursor: pointer;
}

.list_batches {
  height: 24px;
  width: 190px;
  display: inline-block;
  float: left;
  position: absolute;
  top: 9px;
  left: 750px;
}

.additionalSo {
  width: 68px;
  height: 24px;
  background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
  border-radius: 3px;
  display: inline-block;
  float: left;
  margin: 0 10px 0 20px;
  line-height: 24px;
  font-size: 12px;
  cursor: pointer;
  color: #f5f5f5;
  text-align: center;
}
.additionalDisabledSo {
    width: 68px;
    height: 24px;
    background: linear-gradient(180deg, #F5F5F6 0%, #D7D9DF 100%);
    border-radius: 3px;
    display: inline-block;
    float: left;
    margin: 0 10px 0 20px;
    line-height: 24px;
    font-size: 12px;
    cursor: pointer;
    color: #999999;
    text-align: center;
}
.additionalSo:hover{
    background:#2D86EF;
}
.list_batches span:nth-child(2) {
  width: 68px;
  height: 22px;
  background: #e4e5e6;
  border-radius: 3px;
  border: 1px solid #f53500;
  display: inline-block;
  float: left;
  font-size: 12px;
  text-align: center;
  color: #f53500;
  line-height: 24px;
  cursor: pointer;
}

.karyotypeDiagram {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 141px;
  height: 30px;
  right: 20px;
  top: 5px;
  background: linear-gradient(90deg, #3a83ce 0%, #416ab0 100%);
  border-radius: 4px;
  line-height: 30px;
  text-align: center;
  color: #f5f5f5;
  cursor: pointer;
}
.karyotypeDiagram span {
  height: 30px;
  font-size: 14px;
}
.karyotypeDiagram img {
  vertical-align: middle;
}
.daFenActive {
  color: #4773b8 !important;
  background: #ffffff;
}
</style>