import Vue from "vue";
import Vuex from "vuex";

import rect from "./modules/rect";
import state from "./modules/rect/state";
const ChangeRatio = () => {
    var ratio = 0;
    var screen = window.screen;
    var ua = navigator.userAgent.toLowerCase();

    if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
    } else if (~ua.indexOf("msie")) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
            ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
    } else if (
        window.outerWidth !== undefined &&
        window.innerWidth !== undefined
    ) {
        ratio = window.outerWidth / window.innerWidth;
    }
    if (ratio) {
        ratio = Math.round(ratio * 100);
    }
    return ratio;
};
//挂载Vuex
Vue.use(Vuex);

//创建VueX对象

const store = new Vuex.Store({
    modules: {
        rect: rect
    },

    state: {
        messArray: [],
        autovision: {},
        heartbeating: false,
        fullScreen: false,
        fullScreenType: "",
        showImage: "",
        karyotypeNumberlist: {}, //玻片数 细胞数 列表数据
        glassList: [], //病例个数
        ossUploadAccess: {
            accessid: "",
            SecurityToken: "",
            signature: "",
            accesskey: "",
            policyBase64: ""
        },

        //分析工具也监听
        batchAnalysisList: [], //批量分析
        glassIds: [], //玻片选择
        status: -1, //选择分析状态
        firstLoading: 1, //首次加载
        radioCheck: 1, //全部 已分析 未分析 默认全部
        checkAllName: true,
        changeListLeft: 1, //病例列表监听 上传完成之后刷新
        clickGlass: false,
        clickSlide: false,
        clickSomeReview: false,

        isUpdateList: false, // 是否更新左侧病例列表
        isUpdateSetting: false, // 是否更新个人设置
        caseId: "", // 病例id
        caseImg: false, // 病例状态
        isDeleteCase: false, // 是否删除病例
        isSearchUpdate: false, // 搜索后更新报告列表
        /*zoomingShow: 1, //放大缩小监听*/
        template: false, // 是否选择模板
        userName: "",
        headUrl: "",
        reviseGlassId: "", //模板变成修改图片id
        addReviseGlassId: "",
        metatype: "", //个人列表切换类型
        metatypeValue: 1, //切换类型的值
        karyotypeSwitchVal: 1,
        sortType: 1, //按照时间排序
        sort: 1, //排序 0:顺序   1倒序
        sortTypeList: 1, //1 ：打分倒叙  2：玻片倒叙  3：进度倒叙
        color: 4, //color  = 4 ：所有颜色 0：白色1：红色 2：黄色3：蓝色
        checkChangeVal: 1,
        checkChangeDelete: 1,
        analysisInfo: "", //分析进度信息
        language: "",
        caseValue: "", // 病例类型（0 个人病例、1 会诊病例 2 团队病例 3 转入病例）、来源机构ID(左侧病例列表)
        accountType: "",
        labValue: "", //头部实验室切换值
        mechanismList: [], //机构
		labValueUpdatedTime: +new Date(),
        currentTabIndex: 0, //病例总览tab切换值
        queryUserInfo: "", //个人信息、专家信息、机构信息
        coMechanism: {
            mechanismId: "",
            mechanismName: ""
        }, //合作机构
        transferIds: "", //转交的病例id
        caseStatus: "",
        isOpenDistribution: 1,
        isAcceptMechanism: 0, //是不是被转交机构
        analysCenterClassWidth: "", //默认浏览器的宽度
        rowVal: 3,
        allocUserColor: "", //分配颜色
        patientIdStatus: "", //病例状态
        belongUserColorItem: "", //归属人颜色
        beingAnalyzed:0,//0 没有正在分析 1有正在分析
        fastPay:0,
        isLoading:1
    },
    mutations: {
        //快捷支付
        fastPay(state, fastPay) {
            state.fastPay = fastPay;
        },
        //病例有没有正在分析中的病例
        beingAnalyzed(state, beingAnalyzed) {
            state.beingAnalyzed = beingAnalyzed;
        },
        rowVal(state, rowVal) {
            state.rowVal = rowVal;
        },
        //获取浏览器的宽度
        analysCenterClassWidth(state, analysCenterClassWidth) {
            state.analysCenterClassWidth = analysCenterClassWidth;
        },
        belongUserColorSave(state, belongUserColorItem) {
            state.belongUserColorItem = belongUserColorItem;
        },
        isLoading(state, isLoading) {
            state.isLoading = isLoading;
        },
        changeLanguange(state, language) {
            state.language = language;
        },
        changeCaseValue(state, caseValue) {
            state.caseValue = caseValue;
        },
        changeAccountType(state, accountType) {
            state.accountType = accountType;
        },
        changeLabValue(state, labValue) {
            state.labValue = labValue;
        },
        changeMechanismList(state, mechanismList) {
            state.mechanismList = mechanismList;
        },
		updateLabValue(state){
			state.labValueUpdatedTime = +new Date();
		},
        changeHeadUrl(state, headUrl) {
            state.headUrl = headUrl;
        },
        changeCurrentTabIndex(state, currentTabIndex) {
            state.currentTabIndex = currentTabIndex;
        },
        caseStatusChange(state, status) {
            state.caseStatus = status;
        },
        checkChangeDelete(state, checkChangeDelete) {
            state.checkChangeDelete = checkChangeDelete;
        },
        changeQueryUserInfo(state, queryUserInfo) {
            state.queryUserInfo = queryUserInfo;
        },
        changeTransferIds(state, transferIds) {
            state.transferIds = transferIds;
        },
        coMechanismSave(state, coMechanism) {
            state.coMechanism = coMechanism;
        },
        isAcceptMechanismSave(state, isAcceptMechanism) {
            state.isAcceptMechanism = isAcceptMechanism;
        },
        checkChangeVal(state, checkChangeVal) {
            state.checkChangeVal = checkChangeVal;
        },
        color(state, color) {
            state.color = color;
        },
        //分析进度存储
        analysisInfoStore(state, obj) {
            state.analysisInfo = obj;
        },
        sortTypeList(state, sortTypeList) {
            state.sortTypeList = sortTypeList;
        },

        //排序类型0名称   1时间
        sortType(state, sortType) {
            state.sortType = sortType;
        },

        //排序 0:顺序   1倒序
        sort(state, sort) {
            state.sort = sort;
        },

        //核型图缩略图切换
        karyotypeSwitchVal(state, karyotypeSwitchVal) {
            state.karyotypeSwitchVal = karyotypeSwitchVal;
        },

        //个人列表切换类型
        metatype(state, metatype) {
            state.metatype = metatype;
        },

        metatypeValue(state, metatypeValue) {
            state.metatypeValue = metatypeValue;
        },

        //自定义模板控件获取
        handleVal(state, payload) {
            state.rect.rects = payload;
        },

        //模板删除id
        reviseGlassIdStyle(state, reviseGlassId) {
            state.reviseGlassId = reviseGlassId;
        },
        //模板新增id
        addReviseGlassIdStyle(state, addReviseGlassId) {
            state.addReviseGlassId = addReviseGlassId;
        },

        userName(state, userName) {
            state.userName = userName;
        },

        /*修改显示状态*/
        changeShowImage(state, showImageValue) {
            state.showImage = showImageValue;
        },

        //玻片数 细胞数 列表数据
        karyotypeNumberlistType(state, karyotypeNumberlist) {
            state.karyotypeNumberlist = karyotypeNumberlist;
        },

        //病例数
        glassListType(state, glassList) {
            state.glassList = glassList;
        },

        //批量分析 分析
        batchAnalysisListType(state, batchAnalysisList) {
            state.batchAnalysisList = batchAnalysisList;
        },

        //选择拨片
        glassIdsType(state, glassIds) {
            state.glassIds = glassIds;
        },

        //选择分析状态
        statusType(state, status) {
            state.status = status;
        },

        //全选初次进入的时候加载问题
        firstLoadingType(state, firstLoading) {
            state.firstLoading = firstLoading;
        },

        radioCheckType(state, radioCheck) {
            state.radioCheck = radioCheck;
        },

        //全选反选监听
        checkAllNameType(state, checkAllName) {
            state.checkAllName = checkAllName;
        },

        //病例列表监听 上传完成之后刷新
        changeListLeftType(state, changeListLeft) {
            state.changeListLeft = changeListLeft;
        },

        //点击玻片选择
        clickGlassType(state, clickGlass) {
            state.clickGlass = clickGlass;
        },

        //点击玻片类型
        clickSlideType(state, clickSlide) {
            state.clickSlide = clickSlide;
        },

        //点击玻片复审
        clickSomeReviewType(state, clickSomeReview) {
            state.clickSomeReview = clickSomeReview;
        },
        // 分配人颜色
        allocUserColorSave(state, allocUserColor) {
            state.allocUserColor = allocUserColor;
        },
        // 病例分析状态
        patientIdStatusSave(state, patientIdStatus) {
            state.patientIdStatus = patientIdStatus;
        },
        //打开全屏
        openFullScreen() {
            let ele = document.documentElement;
            if (ele.requestFullscreen) {
                ele.requestFullscreen();
            } else if (ele.mozRequestFullScreen) {
                ele.mozRequestFullScreen();
            } else if (ele.webkitRequestFullscreen) {
                ele.webkitRequestFullscreen();
            } else if (ele.msRequestFullscreen) {
                ele.msRequestFullscreen();
            }
            this.state.fullScreen = true;
            this.state.fullScreenType = "mouseClick";
        },
        //退出全屏
        quitFullScreen() {
            // if(this.state.fullScreenType === 'shortKey'){
            // 	return;
            // }
            if (document.exitFullScreen) {
                document.exitFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            this.state.fullScreen = false;
        },

        isFullscreenForNoScroll(state, payload) {
            let isFull =
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement;
            if (isFull === undefined) isFull = false;
            state.fullScreen = !!isFull;
        },
        isUpdateListType(state, isUpdateList) {
            state.isUpdateList = isUpdateList;
        },
        isUpdateSettingType(state, isUpdateSetting) {
            state.isUpdateSetting = isUpdateSetting;
        },
        caseIdType(state, caseId) {
            state.caseId = caseId;
        },
        caseImgType(state, caseImg) {
            state.caseImg = caseImg;
        },
        isDeleteCaseType(state, isDeleteCase) {
            state.isDeleteCase = isDeleteCase;
        },
        isSearchUpdateType(state, isSearchUpdate) {
            state.isSearchUpdate = isSearchUpdate;
        },
        templateType(state, template) {
            state.template = template;
        },
        openDistribution(state, isOpenDistribution) {
            state.isOpenDistribution = isOpenDistribution;
        }
    }
});

export default store;
