<template>
  <div id="autovision" class="autovision">
    <!-- 上传文件流所必需的隐藏组件 -->
    <div style="display: none">
      <input type="file" id="selectfiles" style="display: none" />
    </div>
    <progress-setup :karyoNum="SingleSureCount" :arrayNum="TypeSureArray"></progress-setup>
    <primarySetup></primarySetup>
    <div id="autovision-header" class="autovision-header">
      <div class="returnAnalys" @click="returnAnalys">
        &lt;{{$t('backto_case_overview')}}
      </div>
      <!-- <span
                >已确认计数：<em>{{ SingleSureCount }}</em></span
            >
            <span
                >已确认排列：<em>{{ TypeSureArray }}</em></span
            > -->
      <span id="fps"></span>
      <div @click="fullscreen" :class="[
                    $store.state.fullScreen ? 'fullscreenNext' : 'fullscreen',
                ]">
        {{ $store.state.fullScreen ?  $t("quit_screen") : $t("full_screen") }}
      </div>
    </div>
    <!-- 主体 -->
    <div id="autovision-container" class="autovision-container">
      <!-- 左侧 -->
      <div class="autovision-left">
        <div class="autovision-title-bar">
          <div class="autovision-image-name">
            {{$t('case_analysis.image_num')}}：{{ caseName }}/{{ glassName }}/{{
                            karyoInfo.cellName
                        }}
          </div>
          <div class="title" v-if="isArrangeOnLeft">{{$t('case_analysis.array_image')}}</div>
          <div class="title" v-else>
            {{$t('case_analysis.mesophase')}}-{{ isShowPrimaryImage ? $t('case_analysis.original_image'):$t('case_analysis.optimization_image') }}
          </div>
        </div>
        <div class="autovision-left-stage">
          <div class="autovision-arrange-stage">
            <div class="arrange-stage-tools">
              <label>{{$t('case_analysis.choose_band_info')}}：</label>
              <el-select ref="standardSelect" v-model="selectStripNum" :placeholder="$t('select_button.choose')" @change="reSelectStripNum" size="mini" style="width: 70px">
                <el-option value="320"></el-option>
                <el-option value="400"></el-option>
                <el-option value="550"></el-option>
                <el-option value="700"></el-option>
                <el-option value="850"></el-option>
              </el-select>
            </div>
            <div class="arrange-stage-canvas" :class="[{ 'need-check': whetherRepeatInspect }]">
              <canvas id="arrange-stage" :class="[{ nopointer: toolsType === 'eraser' }]" style="width: 100%; height: 100%"></canvas>
            </div>
          </div>
        </div>
      </div>
      <!-- 中间工具栏 -->
      <div class="autovision-tools">
        <div class="autovision-title-bar">{{$t('case_analysis.tool_kit')}}</div>
        <div class="autovision-tools-box">
          <div class="tools-box">
            <div class="tools-btn tools-btn-undo" :title="$t('tools_tip.undo')" @click="undo" ref="undoBtn"></div>
            <div class="tools-btn tools-btn-redo" :title="$t('tools_tip.redo')" @click="redo" ref="redoBtn"></div>
          </div>
          <div class="tools-box">
            <label>{{$t('case_analysis.karyoArray_change')}}</label>
            <div class="tools-btn tools-btn-switch" :title="$t('tools_tip.karyo_array_change')" @click="switchStage"></div>
          </div>
          <div class="tools-box">
            <label>{{$t('uploadPdf.userful_core_tool')}}</label>
            <div class="tools-btn tools-btn-count" :class="{ active: toolsType == 'count' }" :title="$t('tools_tip.count')" @click="toolsType = 'count'"></div>
            <div class="tools-btn tools-btn-general" :class="{ active: toolsType == 'general' }" :title="$t('tools_tip.powerful_tool')" @click="toolsType = 'general'"></div>
            <div class="tools-btn tools-btn-arrange" :title="$t('tools_tip.autoArray_pendings')" @click="arrange26"></div>
            <div class="tools-btn tools-btn-arrange-all" :title="$t('tools_tip.autoArray_all')" @click="arrangeAll"></div>
          </div>
          <div class="tools-box">
            <label>{{$t('uploadPdf.deep_analysis_tool')}}</label>
            <div class="tools-btn tools-btn-same" :class="{ active: toolsType == 'same' }" :title="$t('tools_tip.sameKaryos_contrast')" @click="toolsType = 'same'"></div>
            <div class="tools-btn tools-btn-double" :class="{ active: toolsType == 'diff' }" :title="$t('tools_tip.twoKaryos_contrast')" @click="toolsType = 'diff'"></div>
            <div class="tools-btn tools-btn-standard" :title="$t('tools_tip.standard_karyos')" @click="toggleStandard"></div>
            <div class="tools-btn tools-btn-arrow" :class="{ active: toolsType == 'arrow' }" :title="$t('tools_tip.arrows_mark')" @click="toolsType = 'arrow'"></div>
            <div class="tools-btn tools-btn-choose" :class="{ active: toolsType == 'select' }" :title="$t('tools_tip.choose')" @click="toolsType = 'select'"></div>
            <div class="tools-btn tools-btn-delete" :class="{ active: toolsType == 'delete' }" :title="$t('tools_tip.delete')" @click="toolsType = 'delete'"></div>
            <div class="tools-btn tools-btn-eraser" :class="{ active: toolsType == 'eraser' }" :title="$t('tools_tip.eraser')" @click="toolsType = 'eraser'"></div>
            <div class="tools-btn tools-btn-areaeraser" :class="{ active: toolsType == 'area' }" :title="$t('tools_tip.area_wipe')" @click="toolsType = 'area'"></div>
            <div class="tools-btn tools-btn-paint" :class="{ active: toolsType == 'paint' }" :title="$t('tools_tip.smeartoAdd')" @click="toolsType = 'paint'"></div>
            <div class="tools-btn tools-btn-connect" :class="{ active: toolsType == 'connect' }" :title="$t('tools_tip.karyos_connect')" @click="toolsType = 'connect'"></div>
            <div class="tools-btn tools-btn-cutting" :class="{ active: toolsType == 'cutting' }" :title="$t('tools_tip.karyos_cut')" @click="toolsType = 'cutting'"></div>
            <div class="tools-btn tools-btn-crosscutting" :class="{ active: toolsType == 'cross' }" :title="$t('tools_tip.karyos_cross_cut')" @click="toolsType = 'cross'"></div>
            <div class="tools-btn tools-btn-edgecutting" :class="{ active: toolsType == 'edge' }" :title="$t('tools_tip.eade_cut')" @click="toolsType = 'edge'"></div>
            <div class="tools-btn tools-btn-rotate" :class="{ active: toolsType == 'rotate' }" :title="$t('tools_tip.rotate')" @click="toolsType = 'rotate'"></div>
          </div>
          <div class="tools-box">
            <label>{{$t('case_analysis.current_count')}}</label>
            <div :class="[
                                'current-count',
                                { normal: karyoInfo.singleNum == 46 },
                            ]">
              {{ karyoInfo.singleNum }}
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="autovision-right">
        <div class="autovision-title-bar" v-if="isArrangeOnLeft">
          {{$t('case_analysis.mesophase')}}-{{ isShowPrimaryImage ? $t('case_analysis.original_image'):$t('case_analysis.optimization_image') }}
        </div>
        <div class="autovision-title-bar" v-else>{{$t('case_analysis.array_image')}}</div>
        <div class="autovision-right-stage">
          <div class="autovision-karyo-stage">
            <div class="karyo-stage-tools">
              <div class="karyo-tooltip">
                <div class="karyo-tool-name">
                  {{ tooltipConfig[toolsType].title }}
                </div>
                <el-tooltip>
                  <div slot="content">
                    <div class="tool-tips-item" v-for="(
                                                item, index
                                            ) in tooltipConfig[toolsType].tip" :key="index">
                      {{ item }}
                    </div>
                  </div>
                  <i class="tooltip-icon"></i>
                </el-tooltip>
              </div>
              <div class="karyo-primary-image">
                <label>{{$t('case_analysis.original_image')}}：</label>
                <el-switch v-model="isShowPrimaryImage" @change="switchPrimaryOrOpt" active-color="#64bd63" inactive-color="#ddd"></el-switch>
              </div>
              <div class="karyo-brush-thickness" v-if="
                                    toolsType === 'eraser' ||
                                    toolsType === 'paint' ||
                                    ((shortKeyType === 'shiftleft' ||
                                        shortKeyType === 'shiftright') &&
                                        toolsType === 'general')
                                ">
                <label>{{$t('case_analysis.brush_thickness')}}：</label>
                <div class="brush-slider">
                  <el-slider v-model="brushThickness" :min="5" :max="50" :show-tooltip="false" @change="brushThicknessChange"></el-slider>
                </div>
                <label style="margin-left: 10px">{{
                                    brushThickness
                                }}</label>
              </div>
            </div>
            <div class="karyo-stage-canvas" :class="[{ 'need-check': whetherRepeatInspect }]">
              <canvas id="karyo-stage" :class="[
                                    {
                                        nopointer:
                                            toolsType === 'paint' ||
                                            (shortKeyType === 'shiftleft' &&
                                                (toolsType === 'general' ||
                                                    toolsType === 'count')),
                                    },
                                ]" style="width: 100%; height: 100%"></canvas>
            </div>
          </div>
        </div>
        <div class="karyo-expression">
          <label>{{$t('case_analysis.karyotype_express')}}</label>
          <input class="karyo-expression-input" :value="
                            karyoInfo.isPreserSure
                                ? karyotypeExpression
                                : karyoInfo.karyotypeExpression
                        " @change="karyotypeExpressionChange" @focus="karyotypeExpressionFocus" @blur="karyotypeExpressionBlur" />
        </div>
        <div class="karyo-notation">
          <label>{{$t('case_analysis.annotation')}}</label>
          <textarea class="karyo-notation-input" :placeholder="$t('case_analysis.input_analys_content')" :value="remark" @focus="shortKeyDisabled = true" @blur="shortKeyDisabled = false" @change="remarkChange"></textarea>
        </div>
        <div class="recheck">
          <label><input type="checkbox" :checked="whetherRepeatInspect" @change="changeRepeatInspect" />{{$t('case_analysis.need_recheck')}}</label>
        </div>
        <div class="save-btns">
          <div class="btn btn-default btn-confirm-count" @click="confirmCount">
            <i v-if="isSingleSure" class="el-icon-check"></i>{{$t('case_overview.confirm_count')}}
          </div>
          <div class="btn btn-default btn-confirm-arrange" @click="confirmArrange">
            <i v-if="isTypeSure" class="el-icon-check"></i>{{$t('case_overview.confirm_array')}}
          </div>
          <div class="btn btn-default btn-save" @click="save" ref="saveBtn">
            {{$t('case_analysis.save')}}
          </div>
          <div class="btn btn-default btn-save-quit" @click="saveAndQuit">
            {{$t('case_analysis.save_exit')}}
          </div>
        </div>
      </div>
      <!-- 最后侧核星图列表 -->
      <div class="autovision-karyolist" :class="{ shrink: isKaryoListShrink }">
        <div class="autovision-title-bar">
          <i class="el-icon-d-arrow-left" v-if="isKaryoListShrink" @click="haryListShrink(false)"></i>
          <i class="el-icon-d-arrow-right" v-if="!isKaryoListShrink" @click="haryListShrink(true)"></i>
          <div>{{$t('case_analysis.karyoImage_list')}}</div>
        </div>
        <karyo-list :karyoList="karyoList" :shrink="isKaryoListShrink" :karyoId="karyoId" :switchKaryoHandle="switchKaryoHandle"></karyo-list>
      </div>
    </div>
    <div id="autovision-footer" class="autovision-footer">
      <div class="download-image" @click="downloadKaryoImage">
        {{$t('case_analysis.load_karyoImage')}}
      </div>
      <div class="download-image" @click="downloadArrangeImage">
        {{$t('case_analysis.load_arrayImage')}}
      </div>
    </div>
    <el-dialog :title="$t('popup.tips.input_confirm')" width="470px" :visible.sync="arrowMarkVisible" class="arrow-mark-dialog">
      <el-input type="textarea" :rows="2" :maxlength="30" resize="none" :placeholder="$t('report.input_content')" class="arrowMark" v-model="arrowMarkValue">
      </el-input>
      <div class="arrow-mark-tip">
        <em>{{ arrowMarkValue.length }}</em>/30
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="btn btn-default" @click="arrowMarkVisible = false">
          {{$t('btns.cancel')}}
        </div>
        <div class="btn btn-primary" @click="arrowMarkConfirm">
          {{$t('btns.confirm')}}
        </div>
      </div>
    </el-dialog>
    <div id="div01" style="display: none"></div>
    <div id="div02" style="display: none"></div>
    <contrast :visible.sync="contrastVisible" :chromos="contrastChromos" :chromoList="chromoList" :patientId="caseId" :karyoId="karyoId" :type="contrastType" @closeContrast="closeContrast"></contrast>
    <edge-cutting :chromo="choosedChromo" :visible.sync="edgeCuttingVisible" @comfirm="edgeCuttingConfirm"></edge-cutting>
  </div>
</template>

<script>
import KaryoList from "./karyoList.vue";
import contrast from "./contrast.vue";
import edgeCutting from "./edgeCutting.vue";
import "../../assets/scss/autovision.scss";
import shortKey from "../../assets/utils/shortKey.js";
import { downloadZip } from "../../assets/utils/downloadZip.js";
import karyoCanvasController from "./modules/karyoCanvasController.js";
import arrangeCanvasController from "./modules/arrangeCanvasController.js";
import Tools from "./modules/tools.js";
import standard_320 from "../../assets/images/autovision/320.png";
import standard_400 from "../../assets/images/autovision/400.png";
import standard_550 from "../../assets/images/autovision/550.png";
import standard_700 from "../../assets/images/autovision/700.png";
import standard_850 from "../../assets/images/autovision/850.png";
import CachePool from "./modules/tools/cachePool.js";
import uploader from "../../assets/utils/fileUploader.js";
import tooltipConfig from "../../struction/airstruction.js";
import { getRotateObj } from "./modules/tools/rotate.js";
import config from "../../assets/js/config.js";
import heartbeatMixin from "./modules/heartbeat.js";
import progressSetup from "./modules/components/progressSetup.vue";
import primarySetup from "./modules/components/primarySetup.vue";

const pako = window.pako;
let RANDOM_STRING = "";
export default {
  components: {
    KaryoList,
    contrast,
    edgeCutting,
    progressSetup,
    primarySetup,
  },
  mixins: [heartbeatMixin],
  data() {
    const param = this.resolveQuery(this.$route.query);
    return {
      caseName: param[0] || "",
      caseId: parseInt(param[1]),
      karyoId: parseInt(param[2]),
      isSingleSure: 0, //是否已确认计数
      isTypeSure: 0, //是否已确认排列
      isPageLoading: true, //页面正在加载
      isPageInit: false, //页面是否第一次加载
      glassName: "", //玻片名称
      karyoList: [], //核型图列表
      chromoList: [], //染色体列表
      karyoInfo: {}, //当前核型图信息
      karyotypeExpression: "", //核型表达式
      remark: this.$t('case_analysis.annotation'), //批注
      SingleSureCount: 0, //已确认计数
      TypeSureArray: 0, //已确认排列
      selectStripNum: 320, //条带信息数
      standardInfo: {}, //条带信息
      standardPics: {
        //标准染色体图片集合
        320: standard_320,
        400: standard_400,
        550: standard_550,
        700: standard_700,
        850: standard_850,
      },
      standardImage: null, //标准染色体图片
      standardVisible: false, //标准染色体是否显示
      downloadQueue: [], //zip下载队列
      entries: {}, //zip解压图片后缓存
      whetherRepeatInspect: false, //是否需要复检
      isShowPrimaryImage: false, //是否显示原图
      brushThickness: 5, //画笔粗细
      eraserBrushThickness: 5, //橡皮擦画笔粗细
      paintBrushThickness: 5, //涂抹工具画笔粗细
      isKaryoListShrink: false, //核型图列表是否收缩 false为展开 true为收起
      toolsType: "general", //工具类型
      shortKeyType: "", //快捷键类型
      arrowMarkVisible: false, //箭头备注弹窗
      arrowMarkValue: "", //箭头备注内容
      arrowMarkCallback: new Function(), //箭头备注弹窗回调
      shortKeyDisabled: false, //快捷键启用状态
      contrastType: "single", //染色体对比类型
      contrastChromos: [], //需要对比的染色体集合
      contrastVisible: false, //染色体对比弹窗界面
      cachePool: new CachePool({ constructor: this }), //数据缓存池
      choosedChromo: null, //当前选中的染色体
      edgeCuttingVisible: false, //边缘切割弹窗显示状态
      tooltipConfig: tooltipConfig, //工具提示配置
      randomString: "", //随机字符串
      version: 0, //心跳次数记录
      currentVersion: 0, //当前心跳
      isArrangeOnLeft: true, //排列图是否显示在左侧位置
      remoteVersion: 0, //服务端版本号
      isNotMoveOverFiveMinites: false, //是否已经超过5分钟未操作
    };
  },
  created() {
    console.log("页面 created", +new Date());
    this.isPageLoading = true;
    //检查patient_opened
    const isOpened = localStorage.getItem("patient_opened_" + this.caseId);
    //如果当前病例在缓存中被记录为打开状态 则关闭页面
    if (isOpened) {
      alert("病例" + this.caseName + "正在操作中，即将关闭该页面");
      window.close();
      this.$router.replace("/analysissystem");
      return;
    }
    //如果当前病例在缓存中没有被记录为打开状态 则记录为打开
    localStorage.setItem("patient_opened_" + this.caseId, true);
    //检查patient_randomString
    const patient_randomString = localStorage.getItem(
      "patient_randomString_" + this.caseId
    );
    if (patient_randomString) {
      const randomStringMap = JSON.parse(patient_randomString);
      const item = randomStringMap[this.karyoId];
      if (item && +new Date() - item.time < 1000 * 300) {
        RANDOM_STRING = item.value;
      }
      if (RANDOM_STRING) {
        delete randomStringMap[this.karyoId];
        localStorage.setItem(
          "patient_randomString_" + this.caseId,
          JSON.stringify(randomStringMap)
        );
      }
      // localStorage.removeItem("patient_randomString_"+ this.caseId)
    }

    this.queryOss(this.karyoId);
    this.queryKaryoList();
    let count = 0;
    Object.keys(this.standardPics).forEach((key) => {
      const src = this.standardPics[key];
      const img = new Image();
      img.src = src;
      img.onload = () => {
        count++;
        this.standardPics[key] = img;
        count >= 5 && this.requireStandard(320);
      };
    });
    shortKey.clear();
    shortKey.bindAPP(this);
    this.$parent.getOssSign();
    window.onbeforeunload = () => {
      localStorage.removeItem("patient_opened_" + this.caseId);
      let patient_randomString = localStorage.getItem(
        "patient_randomString_" + this.caseId
      );
      if (patient_randomString) {
        patient_randomString = JSON.parse(patient_randomString);
      } else {
        patient_randomString = {};
      }
      patient_randomString[this.karyoId] = {
        value: this.randomString,
        time: +new Date(),
      };
      console.warn(JSON.stringify(patient_randomString));
      localStorage.setItem(
        "patient_randomString_" + this.caseId,
        JSON.stringify(patient_randomString)
      );
    };
    const fn = () => {
      clearTimeout(this.heartTimer);
      this.heartTimer = null;
      localStorage.removeItem("patient_opened_" + this.caseId);
      window.removeEventListener("blur", visibility);
    };
    window.removeEventListener("popstate", fn);
    window.addEventListener("popstate", fn);
    const visibility = () => {
      this.shortKeyType = "";
    };
    window.removeEventListener("blur", visibility);
    window.addEventListener("blur", visibility);
  },
  mounted() {
    window.APP = this;
    console.log("页面 mounted", +new Date());
    window.startTime = +new Date();
    window.loadingInstance = this.$loading({
      background: "rgba(255,255,255,.3)",
      customClass: "autovision-loading",
      text: this.$t('case_analysis.loading_data')
    });
  },
  destroyed() {
    console.log("destroyed");
    RANDOM_STRING = "";
    // localStorage.removeItem("patient_opened_"+ this.caseId);
  },
  methods: {
    //全屏
    fullscreen() {
      if (this.$store.state.fullScreen) {
        this.$store.commit("quitFullScreen");
      } else {
        this.$store.commit("openFullScreen");
      }
    },

    /**
     * 解析路由hash信息
     */
    resolveQuery(query) {
      const hash = query.t;
      if (!hash) {
        return [];
      }
      const param = decodeURIComponent(window.atob(hash));
      return param.split(",");
    },

    /**
     * 选择条带信息
     */
    reSelectStripNum(e) {
      this.selectStripNum = e;
      this.requireStandard(e);
    },

    /**
     * 获取条带信息json
     * @param {Object} v
     */
    requireStandard(v) {
      this.standardInfo = require(`./modules/json/standard-${v}`).standard;
      this.standardImage = this.standardPics[v];
      if (this.arrangeController) {
        this.arrangeController.toggleStandard(
          this.standardInfo,
          this.standardImage,
          this.standardVisible
        );
      }
    },

    /**
     * checkbox 需要复检
     */
    changeRepeatInspect(e) {
      this.whetherRepeatInspect = e.target.checked;
    },

    /**
     * 切换原图与优化图
     */
    switchPrimaryOrOpt(v) {
      this.isShowPrimaryImage = v;
      if (this.karyoController.primaryImage) {
        this.karyoController.primaryImageShape.hide = !v;
      } else {
        this.karyoController.loadPrimaryUrl();
      }
      if (this.karyoController.optImage) {
        this.karyoController.optImageShape.hide = v;
      }
      this.karyoController.stage.draw();
    },

    /**
     * queryOss
     */
    queryOss(karyoId, callback) {
      const param = {
        id: karyoId,
        type: 3,
      };
      !!RANDOM_STRING && (param.randomString = RANDOM_STRING);
      // RANDOM_STRING = "";
      this.$api.queryOss(param).then((res) => {
        if (res.code == 200) {
          RANDOM_STRING = res.data.randomString;
          this.$store.state.autovision = res.data;
          this.version = 0;
          this.chromoList = res.data.chromoList.map((a) => {
            a.yoffsetInGroup = a.yoffsetInGroup || 0;
            return a;
          });
          this.karyoInfo = res.data.karyoList[0];
          this.isSingleSure = this.karyoInfo.isSingleSure;
          this.isTypeSure = this.karyoInfo.isTypeSure;
          this.remoteVersion = this.karyoInfo.version;
          // this.isArrangeOnLeft = !!this.isTypeSure;
          if (this.karyoInfo.isTypeSure && !this.isArrangeOnLeft) {
            this.switchStage();
          } else if (
            !this.karyoInfo.isTypeSure &&
            this.isArrangeOnLeft
          ) {
            this.switchStage();
          }
          if (this.karyoList.length) {
            this.glassName = this.karyoList.filter(
              (item) => item.id === karyoId
            )[0].glassName;
          }
          this.whetherRepeatInspect = this.karyoInfo.whetherRepeatInspect;
          this.karyotypeExpression = this.karyoInfo.karyotypeExpression;
          this.expressionManuallyModify =
            this.karyoInfo.isPreserSure === 1;
          this.remark = this.karyoInfo.remark;
          this.randomString = res.data.randomString;
          typeof callback === "function" && callback();
          if (!this.isPageInit) {
            this.isPageInit = true;
            this.downloadOptImages(this.karyoId);
          }
          this.$store.state.heartbeating = true;
          this.heartbeat();
        } else {
          this.$message.error(res.message);
        }
        if (res.code == 248) {
          this.returnAnalys();
        }
      });
    },

    /**
     * 获取核型图列表
     */
    queryKaryoList(notloadZip) {
      this.$api
        .queryOss({
          id: this.caseId,
          type: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.karyoList = res.data;
            this.glassName = this.karyoList.filter(
              (item) => item.id === this.karyoId
            )[0].glassName;
            // this.downloadZip();

            let TypeSureArray = 0,
              SingleSureCount = 0;
            this.karyoList.forEach((item) => {
              item._optUrl = this.ossUrlWebFir(
                item.optUrl.split("?")[0],
                1,
                100,
                100
              );
              if (item.zipJEOssUrl && !notloadZip) {
                this.addUrlToQueue(item.id, item.zipJEOssUrl);
              }
              if (item.zipOssUrl && !notloadZip) {
                this.addUrlToQueue(item.id, item.zipOssUrl);
              }
              !!item.isSingleSure && SingleSureCount++;
              !!item.isTypeSure && TypeSureArray++;
            });
            this.SingleSureCount = SingleSureCount;
            this.TypeSureArray = TypeSureArray;
            !notloadZip && this.asyncLoadZip();
            this.preloadPrimaryImage();
          } else {
            this.$message.error(res.message);
          }
        });
    },

    /**
     * 预加载原图大图
     */
    preloadPrimaryImage() {
      this.karyoList.forEach((item) => {
        const image = new Image();
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = () => {
          item.primaryImage = image;
        };
        image.src = item.primaryUrl;

        const image1 = new Image();
        image1.setAttribute("crossOrigin", "anonymous");
        image1.onload = () => {
          item.optImage = image1;
        };
        image1.src = item.optUrl;
      });
    },
    /**
     * 核型图列表收缩与展开
     */
    haryListShrink(value) {
      this.isKaryoListShrink = value;
      this.$nextTick(() => {
        this.karyoController.resetStage();
        this.arrangeController.resetStage();
      });
    },

    /**
     * 初始化中期分裂相与排列图
     */
    initKaryoAndArrange() {
      console.log("页面 初始化中期分裂相", +new Date());
      this.karyoController = new karyoCanvasController({
        element: document.querySelector("#karyo-stage"),
        chromoList: this.chromoList,
        karyoInfo: this.karyoInfo,
        arrowList: !this.karyoInfo.originalArrowLines
          ? []
          : JSON.parse(this.karyoInfo.originalArrowLines),
      });
      // console.log(this.karyoController);
      this.arrangeController = new arrangeCanvasController({
        element: document.querySelector("#arrange-stage"),
        chromoList: this.chromoList,
        karyoInfo: this.karyoInfo,
        arrowList: !this.karyoInfo.arrangeArrowLines
          ? []
          : JSON.parse(this.karyoInfo.arrangeArrowLines),
        entries: this.entries,
      });
      this.karyoController.app = this;
      this.arrangeController.app = this;

      window.maxfps = 0;
      window.minfps = 0;
      let timer = setInterval(() => {
        if (
          this.arrangeController.initFinished &&
          this.karyoController.initFinished
        ) {
          clearTimeout(timer);
          timer = null;
          this.isPageLoading = false;
          window.loadingInstance.close();
          this.addEventListener();
          this.addShortKeys();
          this.arrangeController.toggleStandard(
            this.standardInfo,
            this.standardImage,
            this.standardVisible
          );
          this.cachePool.save();
        }
      }, 100);
      // this.render();
    },

    /**
     * 渲染画布-用来调试绘制效率
     */
    render() {
      const t = new Date().getTime();
      window.timer = window.requestAnimationFrame(() => {
        if (this.karyoController.stage) {
          this.karyoController.stage.draw();
        }
        if (this.arrangeController.stage) {
          this.arrangeController.stage.draw();
        }
        this.render();
        const _t = new Date().getTime() - t;
        if (_t > 0) {
          const fps = parseInt(1000 / _t);
          window.maxfps = Math.max(window.maxfps || fps, fps);
          window.minfps = Math.min(window.minfps || fps, fps);
          document.querySelector("#fps").innerHTML =
            this.$t('popup.tips.currentfps') +
            fps +
            this.$t('popup.tips.smallestfps') +
            window.minfps +
            this.$t('popup.tips.bigestfps') +
            window.maxfps;
        }
      });
    },

    /**
     * 切换核型图
     */
    switchKaryoHandle(karyoid) {
      if (this.cachePool.isInit()) {
        this.switchKaryoCallback(karyoid);
      } else {
        this.saveConfirm = this.$confirm(
          this.$t('popup.change_karyo.content'),
          this.$t('popup.change_karyo.title'),
          {
            type: "warning",
            distinguishCancelAndClose: true,
            customClass: "save-confirm-box",
              closeOnClickModal: false,
          }
        )
          .then(() => {
            this.preservation(() => {
              this.switchKaryoCallback(karyoid);
            });
            this.saveConfirm = null;
          })
          .catch((action) => {
            if (action === "cancel") {
              this.switchKaryoCallback(karyoid);
            } else {
              this.isPageLoading = false;
            }
            this.saveConfirm = null;
          });
      }
    },

    /**
     * 切换核型图过程
     */
    switchKaryoCallback(karyoid) {
      if (this.ajaxLoading) {
        return;
      }
      clearTimeout(this.heartTimer);
      this.heartTimer = null;
      this.isPageLoading = true;
      this.karyoId = karyoid;
      window.startTime = +new Date();
      window.loadingInstance = this.$loading({
        background: "rgba(255,255,255,.3)",
        customClass: "autovision-loading",
        text: this.$t('popup.change_karyo.changing'),
      });
      this.karyoController.stage.clearAllChild();
      this.arrangeController.stage.clearAllChild();
      this.karyoController.stage.draw();
      this.arrangeController.stage.draw();
      this.queryOss(this.karyoId, () => {
        // console.log(this.karyoController);
        this.downloadOptImages(this.karyoId, () => {
          this.karyotypeExpression = this.karyoInfo.karyotypeExpression;
          this.remark = this.karyoInfo.remark;
          if (this.standardVisible) {
            this.toggleStandard();
          }
          this.karyoController.reset(
            this.chromoList,
            this.karyoInfo,
            !this.karyoInfo.originalArrowLines
              ? []
              : JSON.parse(this.karyoInfo.originalArrowLines)
          );
          this.arrangeController.reset(
            this.chromoList,
            this.karyoInfo,
            !this.karyoInfo.arrangeArrowLines
              ? []
              : JSON.parse(this.karyoInfo.arrangeArrowLines)
          );
          // this.isShowPrimaryImage = false;
          this.cachePool.reset();
          this.cachePool.save();
          this.toolsType = "general";
          const str = window.btoa(
            [
              encodeURIComponent(this.caseName),
              this.caseId,
              this.karyoId,
            ].join(",")
          );
          this.$router.replace("?t=" + str);
          setTimeout(() => {
            this.isPageLoading = false;
          }, 1000);
        });
      });
    },

    /**
     * 显示隐藏标准染色体
     */
    toggleStandard() {
      this.standardVisible = !this.standardVisible;
      this.arrangeController.toggleStandard(
        this.standardInfo,
        this.standardImage,
        this.standardVisible
      );
    },

    /**
     * 下载当前优化小图
     */
    downloadOptImages(id, callback) {
      let count = 0;
      this.originOptUrl = {};
      this.chromoList.forEach((chromo) => {
        const image = new Image();
        image.setAttribute("crossOrigin", "Anonymous");
        image.onload = () => {
          this.originOptUrl[chromo.index].img = image;
          check();
        };
        image.onerror = () => {
          check();
        };
        const src = this.getBase64FromEntries(id, chromo.justUrl);
        image.src = src;

        this.originOptUrl[chromo.index] = {
          src: src,
          scale: 1,
        };
      });
      const check = () => {
        count++;
        if (count >= this.chromoList.length) {
          typeof callback === "function"
            ? callback()
            : this.initKaryoAndArrange();
        }
      };
    },

    /**
     * 获取图片对应的base64
     * @param {Object} src
     */
    getBase64FromEntries(id, src) {
      if (src.indexOf("base64,") > -1 || !id || !this.entries) {
        return src;
      }
      const _name = src.split("?")[0].split("/").pop();
      const key = ["karyo", id, _name].join("_");
      return this.entries[key] || src;
    },

    /**
     * 将zip包链接加入到下载队列
     */
    addUrlToQueue(id, url, isLoaded = false) {
      const name = url.split("?")[0].split("/").pop();
      const exist = this.downloadQueue.some((item) => item.name === name);
      if (!exist) {
        this.downloadQueue.push({
          id,
          name,
          url,
          isLoaded,
        });
      }
    },

    /**
     * 预加载下一个核型图的zip包
     */
    asyncLoadZip() {
      const load = (n) => {
        if (
          n >= this.downloadQueue.length ||
          !this.$store.state.heartbeating
        ) {
          return;
        }
        const item = this.downloadQueue[n];
        if (!item.isLoaded) {
          downloadZip(item.url)
            .then((entries) => {
              entries.forEach((data) => {
                const key = [
                  "karyo",
                  item.id,
                  data.filename,
                ].join("_");
                this.entries[key] = data.base64;
              });
              // !this.entries[item.id] && (this.entries[item.id] = []);
              // this.entries[item.id] = this.entries[item.id].concat(entries);
              // console.log(entries.map(item=>item.filename));
              item.isLoaded = true;
              window.entiresPool = this.entries;
              load(n + 1);
            })
            .catch((status) => {
              if (status == 404) {
                item.isLoaded = true;
                item.status = status;
              }
              load(n + 1);
            });
        } else {
          load(n + 1);
        }
      };
      load(0);
    },

    /**
     * 画笔粗细大小改变
     */
    brushThicknessChange(e) {
      if (this.toolsType === "eraser") {
        this.eraserBrushThickness = e;
      } else {
        this.paintBrushThickness = e;
      }
    },

    /**
     * 批注
     */
    remarkChange(e) {
      this.remark = e.target.value;
    },

    /**
     * 核型表达式修改
     */
    karyotypeExpressionChange(e) {
      this.karyotypeExpression = e.target.value;
    },

    /**
     * 核型表达式输入框获得焦点
     */
    karyotypeExpressionFocus() {
      this._karyotypeExpression = this.karyotypeExpression;
    },

    /**
     * 核型表达式输入框失去焦点
     */
    karyotypeExpressionBlur() {
      if (
        this.karyotypeExpression !== this._karyotypeExpression &&
        !this.karyoInfo.isPreserSure
      ) {
        this.karyoInfo.isPreserSure = 1;
      }
    },

    /**
     * 加密
     * @param {Object} str
     */
    binaryzip(str) {
      var binaryString = pako.gzip(str, {
        to: "string",
      });
      return window.btoa(binaryString);
    },

    /**
     * 解压
     * @param {Object} key
     */
    unzip(key) {
      // 将二进制字符串转换为字符数组
      if (key) {
        if (Object.prototype.toString.call(key) !== "[object String]") {
          return [];
        }
        let atobData =
          (window.atob(key) && window.atob(key).split("")) || [];
        var charData =
          atobData &&
          atobData.map(function (x) {
            return x.charCodeAt(0);
          });
        // 将数字数组转换成字节数组
        var binData = new Uint8Array(charData); // 解压
        var data = pako.inflate(binData);
        return String.fromCharCode.apply(null, new Uint16Array(data));
      } else {
        return [];
      }
    },

    /**
     * 自动排列所有待排列染色体
     */
    arrange26() {
      let list = this.chromoList
        .filter((a) => a.chromoId >= 26)
        .map((chromo) => {
          return {
            id: chromo.id,
            chromoId: chromo.chromoId,
            justUrl: chromo.invertedUrl,
            karyoId: chromo.karyoId,
            chromoScore: chromo.chromoScore
              ? chromo.chromoScore
              : 0,
            index: chromo.index,
          };
        });
      list = this.cachePool.replaceBase64(list);
      this.arrangeByNewChromolist(list, 1);
    },

    /**
     * 自动排列所有染色体
     */
    arrangeAll() {
      let list = this.chromoList.map((chromo) => {
        return {
          id: chromo.id,
          chromoId: chromo.chromoId,
          justUrl: chromo.invertedUrl,
          karyoId: chromo.karyoId,
          chromoScore: chromo.chromoScore ? chromo.chromoScore : 0,
          index: chromo.index,
        };
      });
      list = this.cachePool.replaceBase64(list);
      this.arrangeByNewChromolist(list, 2);
    },

    /**
     * 请求服务端进行自动排列 对返回的结果与本地染色体列表进行关联更改属性
     */
    arrangeByNewChromolist(chromos, type) {
      console.log(chromos);
      const param = {
        karyoId: this.karyoId,
        chromos: this.binaryzip(JSON.stringify(chromos)),
        type,
      };
      let loading = this.$loading({
        background: "rgba(255,255,255,.3)",
        text: this.$t('popup.change_karyo.arraying'),
        customClass: "autovision-loading",
      });
      this.ajaxLoading = true;
      this.$api.chromoRecog(param).then((res) => {
        if (res.code == 200) {
          const result = JSON.parse(this.unzip(res.data));
          console.warn(result);
          let count = 0;
          if (result.length) {
            result.forEach((item) => {
              this.chromoList.forEach((chromo) => {
                if (
                  (chromo.id === item.id ||
                    chromo.index === item.index) &&
                  item.justUrl
                ) {
                  // item.invertedUrl = item.justUrl;
                  delete item.justUrl;
                  chromo = Object.assign(chromo, item);
                  if (item.chromoUpright == 1) {
                    getRotateObj(
                      chromo.invertedUrl,
                      180
                    ).then((res) => {
                      var _img = new Image();
                      _img.src = res;
                      _img.onload = () => {
                        chromo.justUrl = res;
                        chromo.image = _img;
                        chromo.imgHandledRotatedDegree = 180;
                        count++;
                        if (count >= result.length) {
                          this.karyoController.resetKaryo();
                          this.arrangeController.reArrange();
                        }
                      };
                    });
                  } else {
                    count++;
                    if (count >= result.length) {
                      this.karyoController.resetKaryo();
                      this.arrangeController.reArrange();
                    }
                  }
                }
              });
            });
            this.cachePool.save();
            this.cachePool.record({
              type: "chromoRecog",
              message: this.$t('popup.change_karyo.auto_array'),
              data: param,
              result: this.chromoList,
            });
            this.karyoController.resetKaryo();
            this.arrangeController.reArrange();
          }
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
        loading.close();
        this.ajaxLoading = false;
      });
    },

    /**
     * 更新确认计数与确认排列的数量
     */
    uploadSureCount() {
      this.$api
        .queryOss({
          id: this.caseId,
          type: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            this.karyoList = res.data;
            let TypeSureArray = 0,
              SingleSureCount = 0;
            this.karyoList.forEach((item) => {
              item._optUrl = this.ossUrlWebFir(
                item.optUrl.split("?")[0],
                1,
                100,
                100
              );
              if (item.zipJEOssUrl) {
                this.addUrlToQueue(item.id, item.zipJEOssUrl);
              }
              if (item.zipOssUrl) {
                this.addUrlToQueue(item.id, item.zipOssUrl);
              }
              !!item.isSingleSure && SingleSureCount++;
              !!item.isTypeSure && TypeSureArray++;
            });
            this.SingleSureCount = SingleSureCount;
            this.TypeSureArray = TypeSureArray;
          }
        });
    },

    /**
     * 确认计数
     */
    confirmCount() {
      this.saveConfirm = this.$confirm(
        this.$t('popup.warning.confirm_count'),
        this.$t('popup.warning.remind'),
        {
          type: "warning",
          customClass: "save-confirm-box",
            closeOnClickModal: false,
        }
      )
        .then((res) => {
          // const param = this.cachePool.getAjaxParams();
          let loading = this.$loading({
            background: "rgba(255,255,255,.3)",
            text: this.$t('popup.warning.confirming'),
            customClass: "autovision-loading",
          });
          this.ajaxLoading = true;
          this.getParamAfterUploadArrayUrl((param) => {
            this.$api.confirmCount(param).then((res) => {
              loading.close();
              this.ajaxLoading = false;
              this.$message({
                message:
                  res.code == 200 ? this.$t('popup.warning.confirm_success') : res.message,
                type: res.code == 200 ? "success" : "warning",
              });
              if (res.code == 200) {
                this.remoteVersion = res.data.karyoVersion;
                this.isSingleSure = 1;
                this.karyoInfo.isSingleSure = 1;
                this.cachePool.initData.karyoInfo = JSON.stringify(
                  this.karyoInfo
                );
                this.uploadSureCount();
                this.cachePool.initData = this.cachePool.getData();
              }
            });
          });

          this.saveConfirm = null;
        })
        .catch(() => {
          //TODO
          this.saveConfirm = null;
        });
    },

    /**
     * 确认排列
     */
    confirmArrange() {
      this.saveConfirm = this.$confirm(
        this.$t('popup.warning.confirm_array'),
        this.$t('popup.warning.remind'),
        {
          type: "warning",
          customClass: "save-confirm-box",
            closeOnClickModal: false,
        }
      )
        .then((res) => {
          // const param = this.cachePool.getAjaxParams();
          let loading = this.$loading({
            background: "rgba(255,255,255,.3)",
            text: this.$t('popup.warning.confirming'),
            customClass: "autovision-loading",
          });
          this.ajaxLoading = true;
          this.getParamAfterUploadArrayUrl((param) => {
            this.$api.confirmArrangement(param).then((res) => {
              loading.close();
              this.ajaxLoading = false;
              this.$message({
                message:
                  res.code == 200 ? this.$t('popup.warning.confirm_success') : res.message,
                type: res.code == 200 ? "success" : "warning",
              });
              if (res.code == 200) {
                this.remoteVersion = res.data.karyoVersion;
                this.isSingleSure = 1;
                this.karyoInfo.isSingleSure = 1;
                this.isTypeSure = 1;
                this.karyoInfo.isTypeSure = 1;
                this.cachePool.initData.karyoInfo = JSON.stringify(
                  this.karyoInfo
                );
                this.uploadSureCount();
                this.cachePool.initData = this.cachePool.getData();
              }
            });
          });

          this.saveConfirm = null;
        })
        .catch(() => {
          //TODO
          this.saveConfirm = null;
        });
    },

    /**
     * 保存
     */
    save() {
      this.preservation();
    },

    /**
     * 保存并退出
     */
    saveAndQuit() {
      this.preservation(() => {
        setTimeout(() => {
          this.clearStorage();
          this.$router.replace("/analysissystem");
        }, 300);
      });
    },

    /**
     * ajax保存
     */
    preservation(cb) {
      const param = this.cachePool.getAjaxParams();
      let loading = this.$loading({
        background: "rgba(255,255,255,.3)",
        text: this.$t('popup.warning.saving'),
        customClass: "autovision-loading",
      });
      this.ajaxLoading = true;
      this.getParamAfterUploadArrayUrl((param) => {
        this.$api.preservation(param).then((res) => {
          loading.close();
          this.ajaxLoading = false;
          this.$message({
            message: res.code == 200 ? this.$t('popup.warning.save_success') : res.message,
            type: res.code == 200 ? "success" : "warning",
          });
          this.cachePool.initData = this.cachePool.getData();
          res.code == 200 &&
            (this.remoteVersion = res.data.karyoVersion);
          this.uploadSureCount();
          typeof cb === "function" && res.code == 200 && cb();
        });
      });
    },

    clearStorage() {
      localStorage.removeItem("patient_opened_" + this.caseId);
      localStorage.removeItem("patient_randomString_" + this.caseId);
      clearTimeout(this.heartTimer);
      this.heartTimer = null;
    },

    /**
     * 返回分析页
     */
    returnAnalys() {
      if (this.cachePool.isInit()) {
        this.clearStorage();
        this.$router.replace("/analysissystem");
      } else if (!this.isNotMoveOverFiveMinites) {
        this.saveConfirm = this.$confirm(
          this.$t('popup.change_karyo.content'),
          this.$t('popup.change_karyo.title'),
          {
            type: "warning",
            distinguishCancelAndClose: true,
            customClass: "save-confirm-box",
            closeOnClickModal: false,
          }
        )
          .then(() => {
            this.preservation(() => {
              this.clearStorage();
              this.$router.replace("/analysissystem");
            });
            this.saveConfirm = null;
          })
          .catch((action) => {
            if (action === "cancel") {
              this.clearStorage();
              this.$router.replace("/analysissystem");
            } else {
              this.isPageLoading = false;
            }
            this.saveConfirm = null;
          });
      } else {
        this.clearStorage();
        this.$router.replace("/analysissystem");
      }
    },

    getParamAfterUploadArrayUrl(fn) {
      const param = this.cachePool.getAjaxParams();
      param.chromos = this.binaryzip(param.chromos);
      const getName = (name, format) => {
        return [
          this.caseName,
          this.caseId,
          this.karyoId,
          [name, format].join("."),
        ].join("_");
      };
      const sp = this.karyoInfo.optUrl.split("?")[0].split("/");
      sp.splice(-2, 2, "array", "");
      const path = sp.splice(3).join("/");
      const format = "jpeg";
      const name = getName("arrayUrl", format);
      const fullUrl = [config.ossUrl, path + name].join("/");
      param.arrayUrl = fullUrl;
      this.arrangeController
        .exportBase64({ isHideXY: false, format: format, zoom: 0.5 })
        .then((base64) => {
          // const format = base64.split(";")[0].split("/")[1];
          const file = this.cachePool.dataURLtoBlob(base64, name);
          this.doupload([file], path, (url) => {
            // !!url && (param.arrayUrl = url);
            if (url) {
              // this.$api.updateKaryoArrayPic({
              //     patientId: this.caseId,
              //     karyoId: this.karyoId,
              //     arrayUrl: url
              // })
              typeof fn === "function" && fn(param);
            }
          });
        });
    },

    /**
     * 导出的base64上传至OSS
     */
    doupload(files, path, cb) {
      uploader({
        files: files,
        path: path,
        ossUploadAccess: this.$store.state.ossUploadAccess,
      })
        .then((filelist) => {
          const target = filelist[0];
          cb(target.path);
        })
        .catch((filelist) => {
          cb();
        });
    },

    /**
     * 获取下载文件的名称
     */
    getDownLoadFileName(type) {
      let sparr = this.karyoInfo.optUrl
        .split("?")[0]
        .split("/")
        .pop()
        .split("_");
      let patientName = this.karyoInfo.patientName || sparr[0];
      let glassName = this.karyoInfo.glassName || sparr[1];
      let cellName = this.karyoInfo.cellName;
      return [patientName, glassName + cellName, type, "png"].join(".");
    },

    /**
     * 下载图片
     */
    downloadImage(controller, isHideXY, type) {
      controller.exportBase64({ isHideXY }).then((base64) => {
        let event = new MouseEvent("click");
        let a = document.createElement("a");
        const name = this.getDownLoadFileName(type);
        a.download = name || "download";
        a.target = "_blank";
        a.href = base64;
        a.dispatchEvent(event);
      });
    },

    /**
     * 下载图片确认是否隐藏性染色体弹窗
     */
    downloadImageConfirm(type) {
      const title = type === "K" ? this.$t('case_overview.karyoImage') : this.$t('case_overview.array_image');
      const controller =
        type === "K" ? this.arrangeController : this.karyoController;
      this.$confirm(this.$t('popup.warning.hide_sexKaryo'), this.$t('popup.warning.load') + title + this.$t('popup.warning.remind'), {
        confirmButtonText: this.$t('popup.warning.need'),
        cancelButtonText: this.$t('popup.warning.no_need'),
        type: "info",
        iconClass: "el-icon-question",
        closeOnClickModal: false,
        distinguishCancelAndClose: true,
      })
        .then((res) => {
          this.downloadImage(controller, true, type);
        })
        .catch((action) => {
          if (action === "cancel") {
            this.downloadImage(controller, false, type);
          }
        });
    },

    /**
     * 下载排列图
     */
    downloadArrangeImage() {
      this.downloadImageConfirm("K");
    },

    /**
     * 下载核型图
     */
    downloadKaryoImage() {
      this.downloadImageConfirm("A");
    },

    /**
     * 切换视图
     */
    switchStage() {
      const leftParentNode = document.querySelector(
        ".autovision-left-stage"
      );
      const rightParentNode = document.querySelector(
        ".autovision-right-stage"
      );
      const leftChild = leftParentNode.childNodes[0];
      const rightChild = rightParentNode.childNodes[0];
      // console.log(leftChild,rightChild);
      leftParentNode.appendChild(rightChild);
      rightParentNode.appendChild(leftChild);
      this.isArrangeOnLeft = !this.isArrangeOnLeft;
      if (this.karyoController) {
        this.karyoController.resetStage();
      }
      if (this.arrangeController) {
        this.arrangeController.resetStage();
      }
    },

    /**
     * 确认箭头标注
     */
    arrowMarkConfirm() {
      this.arrowMarkVisible = false;
      this.arrowMarkCallback();
    },

    /**
     * 关闭染色体对比弹窗
     */
    closeContrast() {
      this.contrastChromos = [];
      this.contrastVisible = false;
    },

    /**
     * 边缘切割-确认
     */
    edgeCuttingConfirm(chromos) {
      console.warn(chromos, this.choosedChromo);
      this.edgeCuttingVisible = false;
      if (chromos) {
        let maxIndex =
          Math.max.apply(
            Math,
            this.chromoList.map((item) => {
              return item.index;
            })
          ) + 1;
        const index = this.chromoList.indexOf(this.choosedChromo);
        index > -1 && this.chromoList.splice(index, 1);
        chromos.forEach((item) => {
          item.index = maxIndex++;
        });
        this.chromoList = this.chromoList.concat(chromos);
        this.arrangeController.chromoList = this.chromoList;
        this.karyoController.chromoList = this.chromoList;
        this.arrangeController.reArrange();
        this.karyoController.resetKaryo();
        this.cachePool.save();
        this.cachePool.record({
          type: "edgeCutting",
          mmessage: this.$t('tools_tip.eade_cut'),
          data: this.choosedChromo,
          result: this.chromoList,
        });
      }
    },

    /**
     * 撤销
     */
    undo() {
      console.log("撤销");
      this.cachePool.undo((item) => {
        this.resetControllerStage(item);
      });
    },

    /**
     * 恢复
     */
    redo() {
      console.log("恢复");
      this.cachePool.redo((item) => {
        this.resetControllerStage(item);
      });
    },

    /**
     * 重置控制器的stage
     */
    resetControllerStage({
      chromoList,
      karyoInfo,
      arrangeArrows,
      karyoArrows,
      countPoints,
    }) {
      window.startTime = +new Date();
      this.chromoList = chromoList;
      this.karyoInfo = karyoInfo;
      this.arrangeController.karyoInfo = karyoInfo;
      this.karyoController.karyoInfo = karyoInfo;
      this.arrangeController.chromoList = chromoList;
      this.karyoController.chromoList = chromoList;
      this.arrangeController.arrowList = arrangeArrows;
      this.karyoController.arrowList = karyoArrows;
      this.karyoController.countPoints = countPoints.map((point) => {
        return this.karyoController.stage.graphs.point({
          point: point,
          lineWidth: 2,
          hide: true,
          length: 10,
          zindex: 2,
          color: "#f00",
        });
      });
      this.arrangeController.reArrange();
      this.karyoController.resetKaryo();
      if (this.toolsType === "count") {
        this.karyoController.stage.objects.forEach((item) => {
          if (item.type === "point") {
            item.hide = false;
          }
        });
        this.karyoController.stage.draw();
      }
      // this.arrangeController.reset(this.chromoList, this.karyoInfo, item.arrangeArrows);
      // this.karyoController.reset(this.chromoList, this.karyoInfo, item.karyoArrows);
    },

    /**
     * 监听空格事件
     */
    onShortKeySpace() {
      let isSingleSure = this.karyoInfo.isSingleSure;
      let isTypeSure = this.karyoInfo.isTypeSure;
      const check = () => {
        isSingleSure = this.karyoInfo.isSingleSure;
        isTypeSure = this.karyoInfo.isTypeSure;
        if (!isSingleSure) {
          this.confirmCount();
        } else if (!isTypeSure) {
          this.confirmArrange();
        } else {
          this.save();
        }
      };

      //复检
      shortKey.on(
        {
          key: "space",
        },
        () => {
          if (this.saveConfirm) {
            let nodes = document.getElementsByClassName(
              "save-confirm-box"
            )[0].childNodes[2].childNodes[1];
            nodes.click();
          } else {
            // this.whetherRepeatInspect = !this.whetherRepeatInspect;
            check();
          }
        },
        true
      );
      //复检
      shortKey.on(
        {
          key: "enter",
        },
        () => {
          if (this.saveConfirm) {
            let nodes = document.getElementsByClassName(
              "save-confirm-box"
            )[0].childNodes[2].childNodes[1];
            nodes.click();
          }
        },
        true
      );
    },

    /**
     * 监听事件
     */
    addEventListener() {
      let resizeTimer = null;
      window.addEventListener("resize", () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
          this.karyoController.resetStage();
          this.arrangeController.resetStage();
        }, 200);
      });

      const stageA = this.arrangeController.stage;
      const stageK = this.karyoController.stage;

      //排列图 缩放
      Tools.stageScale.call(this, stageA);
      //排列图 拖动
      Tools.stageDrag.call(this, stageA);
      //排列图 染色体拖动
      Tools.listener.chromoDrag.call(this, stageA);
      //排列图 箭头的添加与移动
      Tools.listener.arrow.call(this, stageA, this.arrangeController);
      //缩放染色体
      stageA.event.mousewheel({ ctrlKey: true }, ({ e }) => {
        window.startTime = +new Date();
        this.arrangeController.scaleChromos(
          this.arrangeController.chromoScale - 5 / e.deltaY
        );
      });
      //选中排列图染色体 同时选中核型图染色体
      Tools.listener.chooseArrange.call(this, stageA, stageK);
      //选中核型图染色体 同时选中排列图染色体
      Tools.listener.chooseKaryo.call(this, stageK, stageA);

      // 展示推荐切割线
      Tools.listener.recommendCutting.call(
        this,
        stageK,
        this.karyoController
      );
      //染色体对比
      Tools.listener.chromoContrast.call(this);
      //染色体计数
      Tools.listener.count.call(this, stageK, this.karyoController);
      // 染色体连接
      Tools.listener.connect.call(this, stageK, this.karyoController);

      //划线切割
      Tools.listener.cutting.call(this, stageK, this.karyoController);
      //划线切割
      Tools.listener.cutting.call(this, stageA, this.arrangeController);
      //区域擦除切割
      Tools.listener.easerArea.call(this, stageK, this.karyoController);
      //区域擦除切割
      Tools.listener.easerArea.call(this, stageA, this.arrangeController);
      // 涂抹功能
      Tools.listener.paint.call(this, stageK, this.karyoController);
      // 旋转功能
      Tools.listener.rotate.call(this, stageA, this.arrangeController);

      //删除
      Tools.listener.deleteShape.call(
        this,
        stageA,
        this.arrangeController
      );
      //删除
      Tools.listener.deleteShape.call(this, stageK, this.karyoController);
      //边缘切割
      Tools.listener.edgeCutting.call(
        this,
        stageA,
        this.arrangeController
      );
      //边缘切割
      Tools.listener.edgeCutting.call(this, stageK, this.karyoController);
      //橡皮擦
      Tools.listener.easer.call(this, stageA, this.arrangeController);
      //点击空白切换窗口
      Tools.listener.switchStage.call(this, stageK);
      Tools.listener.switchStage.call(this, stageA);
      Tools.listener.scaleSingleChromo.call(
        this,
        stageA,
        this.arrangeController
      );

      //优化图 缩放
      Tools.stageScale.call(this, stageK);
      //优化图 拖动
      Tools.stageDrag.call(this, stageK);
      //优化图 箭头的添加与移动
      Tools.listener.arrow.call(this, stageK, this.karyoController);
    },

    /**
     * 绑定快捷键
     */
    addShortKeys() {
      const changeKaryo = (isNext) => {
        if (this.isPageLoading || this.ajaxLoading) {
          return;
        }
        this.isPageLoading = true;
        let index = this.karyoList
          .map((a) => a.id)
          .indexOf(this.karyoId);
        if (isNext) {
          index += index < this.karyoList.length - 1 ? 1 : -index;
        } else {
          index -= index > 0 ? 1 : 1 - this.karyoList.length;
        }
        let id = this.karyoList[index].id;
        this.switchKaryoHandle(id);
      };

      //向下切换核型图
      shortKey.on(
        {
          key: "arrowdown",
        },
        () => {
          changeKaryo(true);
        },
        true
      );

      //向上切换核型图
      shortKey.on(
        {
          key: "arrowup",
        },
        () => {
          changeKaryo(false);
        },
        true
      );

      this.onShortKeySpace();

      //切换原图与优化图
      shortKey.on(
        {
          key: "o",
        },
        () => {
          this.switchPrimaryOrOpt(!this.isShowPrimaryImage);
        },
        true
      );

      //M 切换显示MAR
      shortKey.on(
        "keypress",
        {
          key: "m",
        },
        () => {
          window.startTime = +new Date();
          this.arrangeController.hasMar = !this.arrangeController
            .hasMar;
          this.arrangeController.reArrange();
        }
      );

      //撤销
      shortKey.on(
        "keypress",
        {
          key: "z",
          ctrlKey: true,
        },
        () => {
          // this.$refs['undoBtn'].click();
          this.undo();
        }
      );

      //恢复
      shortKey.on(
        "keypress",
        {
          key: "y",
          ctrlKey: true,
        },
        () => {
          // this.$refs['redoBtn'].click();
          this.redo();
        }
      );

      //保存
      shortKey.on(
        "keypress",
        {
          key: "s",
          ctrlKey: true,
        },
        () => {
          this.$refs["saveBtn"].click();
        }
      );

      //删除染色体
      const deleteChromo = () => {
        if (this.choosedChromo) {
          const index = this.chromoList.indexOf(this.choosedChromo);
          const chromo =
            index > -1 ? this.chromoList.splice(index, 1) : null;
          this.karyoController.resetKaryo();
          this.arrangeController.reArrange();
          this.cachePool.save();
          this.cachePool.record({
            type: "deleteChromo",
            message: this.$t('popup.warning.delete_karyo'),
            data: chromo,
            result: null,
          });
        }
      };

      //删除
      shortKey.on(
        "keypress",
        {
          key: "d",
        },
        deleteChromo
      );

      //删除
      shortKey.on(
        "keypress",
        {
          key: "delete",
        },
        deleteChromo
      );

      //删除
      shortKey.on(
        "keypress",
        {
          key: "backspace",
        },
        deleteChromo
      );

      let isHidePolygon = false;
      //万能工具模式下 M键绑定
      shortKey.on(
        "keypress",
        {
          key: "w",
        },
        () => {
          isHidePolygon = !isHidePolygon;
          this.karyoController.stage.objects.forEach((shape) => {
            if (
              shape.realType === "chromo" ||
              shape.type === "text"
            ) {
              shape.hide = isHidePolygon;
              if (isHidePolygon) {
                shape.isHidePolygon = isHidePolygon;
              } else {
                delete shape.isHidePolygon;
              }
            }
          });
          this.karyoController.stage.draw();
        }
      );
      //TAB键 切换工具
      shortKey.on(
        "keypress",
        {
          key: "tab",
        },
        () => {
          if (this.toolsType === "general") {
            this.toolsType = "count";
          } else {
            this.toolsType = "general";
          }
        }
      );
    },
  },
  watch: {
    chromoList(newValue, oldValue) {
      if (!this.chromoList.includes(this.choosedChromo)) {
        this.choosedChromo = null;
      }
      const unum = JSON.parse(
        this.karyoInfo.undetectedChromoPoints || "[]"
      ).length;
      this.karyoInfo.singleNum =
        this.chromoList
          .map((a) => {
            if (typeof a.countPoints === "string") {
              return JSON.parse(a.countPoints).length;
            }
            if (typeof a.countPoints === "object") {
              return a.countPoints.length || 1;
            }
            return 0;
          })
          .reduce((a, b) => a + b) + unum;
      // console.warn(this.karyoInfo.singleNum, this.chromoList.length)
      if (this.arrangeController) {
        this.arrangeController.createKaryotypeExpression();
      }
      if (
        Math.abs(newValue.length - oldValue.length) >= 2 &&
        oldValue.length > 0
      ) {
        this.arrangeController.reArrange();
        this.karyoController.resetKaryo();
      }
    },
    //工具类型
    toolsType(newValue, oldValue) {
      if (newValue === "same") {
        this.contrastType = "signle";
      }
      if (newValue === "diff") {
        this.contrastType = "double";
      }
      if (newValue === "count") {
        this.karyoController.stage.objects.forEach((item) => {
          if (item.type === "point") {
            item.hide = false;
          }
        });
        this.karyoController.stage.draw();
      } else if (oldValue === "count") {
        this.karyoController.stage.objects.forEach((item) => {
          if (item.type === "point") {
            item.hide = true;
          }
        });
        this.karyoController.stage.draw();
      }
      if (newValue === "eraser") {
        this.brushThickness = this.eraserBrushThickness;
      }
      if (newValue === "paint") {
        this.brushThickness = this.paintBrushThickness;
      }
    },
    shortKeyType(newValue, oldValue) {
      if (newValue === "shiftleft" || newValue === "shiftright") {
        this.brushThickness = this.paintBrushThickness;
      }
    },
    //快捷键启用与禁用
    arrowMarkVisible(newValue, oldValue) {
      shortKey.disabled = newValue;
    },
    shortKeyDisabled(newValue, oldValue) {
      shortKey.disabled = newValue;
    },
    contrastVisible(newValue, oldValue) {
      if (!newValue) {
        this.contrastChromos = [];
      }
    },
    version(newValue, oldValue) {
      if (!window.isNetworkError) {
        return;
      }
      if (
        new Date().getTime() - this.heartbeatLastSuccessTime <= 30000 &&
        window.isNetworkError
      ) {
        this.networkErrorMsg(this.$t('popup.warning.network_abnormal'));
      }
      if (
        new Date().getTime() - this.heartbeatLastSuccessTime > 30000 &&
        window.isNetworkError
      ) {
        this.networkErrorMsg(this.$t('popup.warning.off_connect'));
      }
    },
  },
};
</script>

<style lang="scss">
</style>
