import Stage from '../../../assets/utils/stage.js'
/**
 * 排列图 逻辑梳理
 * 1.初始化-加载优化图，根据优化图的宽度，将画布的宽度设置为优化图的宽度，根据固定宽高比设置画布高度
 * 2.根据边距配置 计算可分配绘制区域大小 
 * 3.根据源数据对染色体进行分组 获取每行染色体的最大高度 并计算所有行最大高度的总和sum
 * 4.每行最大高度 / sum * 可分配绘制区域高度  得到每行实际可绘制的最大高度
 * 5.根据边距配置 计算出每个组对应的位置
 * 6.计算每个组内的每个染色体的位置
 * 7.生成 组线、组编号、组容器、染色体图形 并建立 组容器、编号、染色体图形、源数据之间的映射关系
 * 8.将生成的图形 加入舞台并渲染
 * 每次重置从 3 开始
 */
class ArrangeCanvasController {
    constructor({
        element,
        chromoList,
        karyoInfo,
        arrowList,
        banding,
        entries,
        simple = false,
        app = {}
    }) {
        this.name = 'arrange'
        this.element = element;
        this.canvas = this.element.getContext('2d');
        this.parentNode = this.element.parentNode;
        this.entries = entries;
        this.canCapture = false;
        //视图区域属性配置
        this.bounding = {
            padding: [2, 0, 60, 20],	//上右下左 画布内边距
            margin: 52,	//组边x与组线x的距离
            space: 184, //3组与4组之间的间隔
            between: 4, //染色体图片之间的间距
            defaultRowHeight: 0,    //默认行高
            lineWidth: 8,   //组线条宽度
            textHeight: 48, //组编号文字区域高度
            textFontSize: 60, //组编号文字大小
            textFontWeight: '500'
        },
		//每行分组范围
		this.groupRowRange = {
			1: {
				min: 1,
				max: 5
			},
			2: {
				min: 6,
				max: 12
			},
			3: {
				min: 13,
				max: 18
			},
			4: {
				min: 19,
				max: 25
			},
			5: {
				min: 26,
				max: 999
			}
		}
        //每行中最大的染色体图片高度
        this.rows = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
        //每个分组内 源数据、组、染色体图片、染色体虚拟图形之间的映射关系
        this.group = {};
        this.arrowMap = {};
        //组容器的宽高以及位置信息
        this.groupBounding = {};
        this.defaultScale = 2;
        //当前染色体缩放比
        this.chromoScale = this.defaultScale * 0.7;
        //最大染色体缩放比
        this.maxChromoScale = 1;
        //最小染色体缩放比
        this.minChromoScale = 0.5;
        //舞台宽度
        this.width = 2332;
        //舞台高度
        this.height = 1882;
        //源数据 染色体列表
        this.chromoList = chromoList;
        //源数据 核型图信息
        this.karyoInfo = karyoInfo;
        //箭头列表
        this.arrowList = arrowList || [];
        //标准染色体集合
        this.standardChromos = [];
        //定带集合
        this.banding = banding ? (typeof banding === 'string' ? JSON.parse(banding) : banding) : {
            standchromoInfo : [],
            standchromoModal : "320"
        };
        this.app = app;
        this.bandingMap = {};
        this.chromoMap = [];
        //是否包含mar
        this.hasMar = this.chromoList.some(a=>a.chromoId==25);
        //是否为简易版 简易版只有基础渲染染色体
        this.simple = simple;
        //开启主线程
        this.init();
    }

    /**
     * 主进程入口
     */
    init() {
        this.loadOptUrl();
    }

    /**
     * 重置
     * @param {Object} chromoList
     * @param {Object} karyoInfo
     * @param {Object} arrowList
     */
    reset(chromoList, karyoInfo, arrowList) {
        this.chromoList = chromoList;
        this.karyoInfo = karyoInfo;
        this.arrowList = arrowList || [];
        this.standardChromos = [];
        this.banding = karyoInfo.banding ? (typeof karyoInfo.banding === 'string' ? JSON.parse(karyoInfo.banding) : karyoInfo.banding) : {
            standchromoInfo : [],
            standchromoModal : "320"
        };
        this.bandingMap = {};
        this.group = {};
        this.chromoMap = [];
        this.arrowMap = {};
        this.rows = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
		this.initFinished = false;
		this.hasMar = this.chromoList.some(a=>a.chromoId==25);
        this.initStage();
        this.initGroup(true);
    }

    /**
     * 加载优化图
     */
    loadOptUrl() {
        this.initStage();
        this.initGroup(true);
    }

    /**
     * 初始化舞台
     */
    initStage() {
        this.setCanvasParentSize(this.width, this.height);
        if (this.stage) {
            this.stage.reset();
        } else {
            this.stage = new Stage({
                element: this.element,
                offset: this.offset,
                scale: 1,
            });
            this.stage.$parent = this.app;
            this.stage.controller = this;
        }
        window.stage = this.stage;
    }

    /**
     * 重新排列
     */
    reArrange() {
        if(this.reArranging) return
        this.reArranging = true;
        
        // this.group = {};
        this.arrowMap = {};
        this.bandingMap = {};
        this.rows = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
		this.initFinished = false;
        this.initGroup(false);
    }

    /**
     * 计算分组
     */
    async initGroup(isResetScale) {
        // const assignable = this.assignable;
        // const textHeight = this.bounding.textHeight * this.height;
        
        console.warn('async initGroup', +new Date());
        await this.getHeightList().then((heightList) => {
            console.warn('getHeightList', +new Date());
            // console.warn(heightList)
            this.heightList = heightList;
            //被占用的高度
            const occupyHeight = this.bounding.padding[0] + this.bounding.padding[3] + this.bounding.lineWidth * 5 * 0;
            //可分配高度
            const assignedHeight = this.offset.height - occupyHeight;
            //最小高度
            // const minHeight = heightList.reduce((a,b)=>a+b) / heightList.length;
            const hs = Object.keys(this.rows).map(r => this.rows[r]).filter(h => h > 0);
            if(!hs.length){
                Object.keys(this.rows).forEach((a, b) => {
                    this.rows[a] = 10;
                }, 0)
            }
            // console.log('非空行高数组',hs);
            const minHeight = Math.min.apply(null, hs) / 2;
            // console.log('minHeight',minHeight);
            //在染色体缩放倍率为1的情况下，每行最高的染色体高度相加起来的总高度
            const allChromoTotalHeight = Object.keys(this.rows).reduce((a, b) => {
                !this.rows[b] && (this.rows[b] = minHeight)
                return a + this.rows[b];
            }, 0)
            //每行可分配到的高度
            this.rowsHeight = new Array(5).fill(0).map((item, i) => {
                return Math.round(this.rows[i + 1] / allChromoTotalHeight * assignedHeight)
            })

            const maxScale = assignedHeight / allChromoTotalHeight;
            this.maxChromoScale = Math.floor(maxScale * 100) / 100;
            if (isResetScale) {
                // this.chromoScale = this.maxChromoScale * .8;
                // this.chromoScale = this.karyoInfo.chromoArrangeScale || this.defaultScale * 0.7;
                this.chromoScale = this.karyoInfo.chromoArrangeScale || this.maxChromoScale * 0.7;
            }
            if (!isResetScale && this.chromoScale > this.maxChromoScale) {
                this.chromoScale = this.maxChromoScale;
            }
            this.initShapes();
            console.warn('initShapes done', +new Date());
        })
        console.warn('await', +new Date());
        this.reArranging = false;
        this.app && this.app.$refs && this.app.$refs.allTheKaryos && this.app.$refs.allTheKaryos.init(this.chromoList);
    }

    /**
     * 获取每个染色体的高度
     */
    getHeightList() {
        let arr = [];
        // console.log(this.group[1] && this.group[1].list && this.group[1].list[0].chromo);
        return new Promise((resolve) => {
            let group = {};
            let chromoMap = [];
            for (let i = 1; i <= 26; i++) {
                group[i] = { box: {}, list: [] }
            }
            const add = (chromo, img) => {
                const height = img.height || 0;
                this.app.originOptUrl[chromo.index] && (this.app.originOptUrl[chromo.index].img = img);
                const id = chromo.chromoId;
                // console.log(id,parseInt(id) !== 25 || this.hasMar);
                if (parseInt(id) !== 25 || this.hasMar) {
                    const row = Object.keys(this.groupRowRange).filter(row => {
                        const obj = this.groupRowRange[row];
                        return id >= obj.min && id <= obj.max;
                    })[0]
                    this.rows[row] = Math.max(height, this.rows[row] || 0);
                    arr.push({id,height});
                } else {
                    arr.push({id,height: 0})
                }
            }
            let count = 0;
            const t = +new Date()
            const check = () => {
                count++;
                if (count === this.chromoList.length) {
                    // console.log(count);
                    console.log("-------------------",+new Date() - t, 'ms')
                    // console.log(this.app.cachePool.cache);
                    this.group = group;
                    this.chromoMap = chromoMap;
                    resolve(arr);
                }
            }
            if(!this.chromoList.length){
                this.group = group;
                this.chromoMap = chromoMap;
                resolve([])
            }
            this.app.originOptUrl = {};
            this.chromoList.forEach(chromo => {
                group[chromo.chromoId].list.push({
                    chromo: chromo
                })
                
                const src = this.getBase64FromEntries(this.karyoInfo.id, chromo.justUrl);
                const item = this.chromoMap.filter(a=>a.chromo === chromo)[0];
                const _src = item && item.src;
                let target;
                this.app.originOptUrl[chromo.index] = {
                    src: src,
                    chromo,
                    scale: 1,
                };
                // console.log(chromo.justUrl === _src,src);
                if(chromo.justUrl === _src){
                    Object.keys(this.group).forEach(key=>{
                        const list = this.group[key].list;
                        list.forEach(a=>{
                            if(a.chromo === chromo){
                                target = a.image || chromo.image;
                            }
                        })
                    })
                }
                // console.log(target);
                if(target){
                    
                    group[chromo.chromoId].list.forEach(item=>{
                        if(item.chromo === chromo){
                            item.image = target;
                        }
                    })
                    add(chromo,target);
                    check();
                }else{
                    // console.log("-------------------")
                    const img = new Image();
                    img.setAttribute('crossOrigin', 'anonymous');
                    img.onload = () => {
                        group[chromo.chromoId].list.forEach(item=>{
                            if(item.chromo === chromo){
                                item.image = img;
                            }
                        })
                        add(chromo, img);
                        check();
                    }
                    img.onerror = () => {
                        add(chromo, img);
                        check();
                    }
                    img.src = src;
                }
                chromoMap.push({
                    chromo,
                    src : chromo.justUrl
                })
                
            })
        })
    }

    /**
     * 初始化图形
     */
    initShapes() {
		this.stage.clearAllChild();
        const groups = this.createGroups();
        // console.log(this.arrowList);
        let arrows = [];
        if(!this.simple){
            this.arrowList = this.arrowList.map(a=>this.arrowLocal(a));
            arrows = this.createArrows();
        }
        const chromos = this.createChromoImages();
        this.stage.addChild(groups.concat(chromos).concat(arrows));
        if(!this.simple){
            this.createKaryotypeExpression();
            if(this.standardInfo){
                this.standardChromos = [];
                this.toggleStandard(this.standardInfo, this.standardImage, this.standardVisible);
            }
            if(this.app && this.app.choosedChromo){
                const shape = this.getShapeByChromo(this.app.choosedChromo);
                shape && (shape.color = "#f00");
            }
            if(!this.standardVisible && this.banding.standchromoModal && this.banding.standchromoInfo){
                const standChromos = this.createStandChromo();
                this.stage.addChild(standChromos);
            }
        }

        this.stage.draw();
		this.initFinished = true;
		if(window.loadingInstance && window.startTime){
			window.loadingInstance.close();
			const time = +new Date() - window.startTime;
			// window.APP.$message.success('数据加载并排列图渲染完成，用时' + (time) + "毫秒");
		}
        window.APP.initA = true;
        this.canCapture = true;
    }

    /**
     * 创建组
     */
    createGroups() {
        //顶部空白边距
        const top = this.bounding.padding[0];
        //左侧空白边距
        const left = this.bounding.padding[3];
        //画布有效区域宽度
        const width = this.offset.width - left - this.bounding.padding[1];
        //组边x与组线x的距离
        const margin = this.bounding.margin;
        //国际标准分类间隔
        const space = this.bounding.space;
        //组号区域高度
        const textHeight = this.bounding.textHeight;
        //组号字体大小
        const fontSize = this.bounding.textFontSize;
        //组号字体加粗
        const fontWeight = this.bounding.textFontWeight;
        //组线 线条粗细程度
        const lineWidth = this.bounding.lineWidth;
        //x轴偏移量
        const TRANS_X = this.offset.TRANS_X;
        //y轴偏移量
        const TRANS_Y = this.offset.TRANS_Y;
        const lines = [];
        const groups = [];
        const texts = [];
        // console.log(TRANS_X,TRANS_Y);
        Object.keys(this.group).forEach(g => {
            !this.groupBounding[g] && (this.groupBounding[g] = {})
            if (parseInt(g) === 25 && !this.hasMar) {
                return;
            }
            let row = Object.keys(this.groupRowRange).filter(row => {
                const obj = this.groupRowRange[row];
                return g >= obj.min && g <= obj.max;
            })[0];
            row = parseInt(row);
            // console.log(row);
            //获取每行分配的行高
            let height = this.rowsHeight[row - 1];
            //当前组 在 当前行中是第几个
            const col = g - this.groupRowRange[row].min;
            //计算当前行  染色体组区域的y值
            // const boxTop = top + (row - 1) * lineWidth + this.rowsHeight.filter((a, i) => i < row - 1).concat([0]).reduce((a, b) => a + b);
            const boxTop = this.rowsHeight.filter((a, i) => i < row - 1).concat([0]).reduce((a, b) => a + b);
            //组的x值
            let g_left = 0;
            //组的宽度
            let g_width = 0;
            if (row === 1) {
                g_width = parseInt((width - space) / 5);
                g_left = left + col * g_width + (col >= 3 ? space : 0);
            } else if (row === 2) {
                g_width = parseInt(width / 7);
                g_left = left + col * g_width;
            } else if (row === 3) {
                g_width = parseInt((width - space) / 6);
                g_left = left + col * g_width + (col >= 3 ? space : 0);
            } else if (row === 4) {
                const cols = this.hasMar ? 7 : 6;
                g_width = parseInt((width - space) / cols);
                g_left = left + col * g_width + (col >= 4 ? space : 0);
            } else {
                g_width = width;
                g_left = left;
            }
            // console.warn(`第${row}行，第${col}列，x距离为${l_left}，y距离为${boxTop}，宽度为${l_width}，高度为${height}`)
            this.groupBounding[g] = {
                x: g_left,
                y: boxTop,
                width: g_width,
                height: height
            }
            const rect = this.stage.graphs.rectangle({
                x: g_left,
                y: boxTop + lineWidth,
                width: g_width,
                height: height - lineWidth/2,
                // color: "#" + new Array(6).fill(0).map(()=>parseInt(Math.random()*16).toString(16)).join(""),
                color: "transparent",
                realType: 'groupbox',
                zindex: -2,
                group : g
            })
            groups.push(rect)
            this.group[g].box = rect;
            const line = this.stage.graphs.groupLine({
                color: "#51A749",
                x: g_left + margin,
                y: boxTop + height + lineWidth/2,
                width: g_width - margin * 2,
                height: lineWidth,
                zindex: 300,
            })
            lines.push(line);
            const count = this.chromoList.filter(a=>a.chromoId == g).length;
            const _color = g > 24 ? "#f53500" : (g > 22 ? "#416AB0" : (count === 2 ? "#51A749" : "#f53500"));
            const text = this.stage.graphs.text({
                color: _color,
                // font: g > 25 ? '24px Arial' : `${fontSize}px Arial`,
                font: `normal normal ${fontWeight} ${(g > 25 ? 40 : fontSize)}px Arial`,
                // width: g_width - 10,
                height: textHeight,
                // x: g_left,
                x: g_left + g_width / 2,
                y: top + boxTop + height + fontSize,
                text: g < 23 ? g : (g > 25 ? '' : (g == 23 ? 'X' : (g == 24 ? 'Y' : 'mar'))),
                zindex: 500,
                TRANS_X : 0,
                TRANS_Y : 0
            })
            texts.push(text)
        })
        return groups.concat(lines).concat(texts)
    }

    /**
     * 生成箭头
     */
    createArrows() {
        return this.arrowList.map(arrow => {
            const distance = arrow.target_x - arrow.start_x;
            const target_x = this.translateXfromLocal(arrow.target_x,arrow.lineid);
            const shape = this.stage.graphs.arrow({
                id: arrow.guid,
                beginPoint: {
                    x : target_x - distance,
                    y : arrow.start_y,
                },
                stopPoint: {
                    x : target_x,
                    y : arrow.target_y,
                },
                color: "#f00",
                lineWidth: 6,
                txt: arrow.note || '',
                zindex: 600
            });
            this.arrowMap[arrow.guid] = {
                shape, arrow
            };
            return shape;
        })
    }

    getGuid(){
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    getLineIdAndOffsetY(y){
        let lineid = 0, height = 0, offsety = 0;
        for(let i=0; i < this.rowsHeight.length; i++){
            const current = this.rowsHeight[i];
            if(y >= height && y <= height + current){
                lineid = i+1;
                offsety = height + current - y;
                break;
            }else{
                height += current;
            }
        }
        return {lineid,offsety};
    }
    /**
     * 将箭头属性转换成与local对应一致的格式
     * @param {*} arrow 
     */
    arrowLocal(arrow){
        if(!arrow.beginPoint){
            const lineid = arrow.lineid;
            if(lineid > 0){
                const distance = arrow.target_y - arrow.start_y;
                const height = this.rowsHeight.filter((a, i) => i < lineid).reduce((a, b) => a + b, 0);
                arrow.target_y = height - arrow.offsety;
                arrow.start_y = arrow.target_y - distance;
            }
            !arrow.guid && (arrow.guid = this.getGuid());
            return arrow
        }else{
            let lineid = 0;
            const start_x = arrow.beginPoint.x * this.offset.w;
            const start_y = arrow.beginPoint.y * this.offset.h;
            const target_x = arrow.stopPoint.x * this.offset.w;
            const target_y = arrow.stopPoint.y * this.offset.h;
            let height = 0, offsety = 0;
            for(let i=0; i < this.rowsHeight.length; i++){
                const current = this.rowsHeight[i];
                if(target_y >= height && target_y <= height + current){
                    lineid = i+1;
                    offsety = height + current - target_y;
                    break;
                }else{
                    height += current;
                }
            }
            return {
                start_x,
                start_y,
                target_x,
                target_y,
                note : arrow.text || arrow.txt || '',
                offsety,
                lineid,
                guid : arrow.guid || arrow.id || this.getGuid()
            }
        }
    }

    shapeToArrow(shape){
        let lineid = 0, height = 0, offsety = 0, ey = shape.stopPoint.y;
        for(let i=0; i < this.rowsHeight.length; i++){
            const current = this.rowsHeight[i];
            if(ey <= height + current){
                lineid = i+1;
                offsety = height + current - ey;
                break;
            }else{
                height += current;
            }
        }
        console.warn(lineid);
        const distance = shape.stopPoint.x - shape.beginPoint.x;
        const target_x = this.translateXtoLocal(shape.stopPoint.x,lineid);
        return {
            start_x : target_x - distance,
            start_y : shape.beginPoint.y,
            target_x : target_x,
            target_y : shape.stopPoint.y,
            note : shape.txt,
            offsety : offsety,
            lineid : lineid,
            guid : shape.id || shape.guid
        }
    }

    translateXfromLocal(x = 0,lineid = 0){
        const [t,r,b,l] = this.bounding.padding;
        const space = this.bounding.space;
        const margin = this.bounding.margin;
        const lines = [{n:5, space: 1, col:2},{n:7, space: 0,col:0},{n:6, space: 1,col:2},{n:this.hasMar ? 6 : 7, space: 1,col:3},{n:1, space:0,col:0}];
        const {w, h, width, height} = this.offset;
        const con = lines[lineid < 1 ? 4 : (lineid-1)];
        const groupWidthLocal = (w - l - r - con.space * space) / con.n;
        let g = 0, left = 0, add = l;
        for(let i = 0; i < con.n; i++){
            const right = add + groupWidthLocal;
            if((x >= add && x <= right) || x < add){
                g = i;
                const center = add + groupWidthLocal / 2;
                left = x - center;
                break;
            }else{
                add += groupWidthLocal + (con.space && i===con.col ? space : 0);
            }
        }
        // console.warn('from',g,left,add,con)
        const groupWidthOnline = (width - l - r - con.space * space) / con.n;
        let total = groupWidthOnline * (g + 0.5) + l + (con.space && g > con.col ? space : 0)
        // let total = l;
        // for(let i = 0; i <= g; i++){
        //     if(i < g){
        //         total += groupWidthOnline + (con.space && i===con.col ? space : 0);
        //     }else{
        //         total += groupWidthOnline / 2;
        //     }
        // }
        // console.warn(g,lineid,x,groupWidthLocal,left,add,groupWidthOnline,total,total + left);
        // return Math.round(total + left * groupWidthOnline / groupWidthLocal);
        console.warn('from',x,'left',left,'total',total,'g',g,con,'groupWidthOnline',groupWidthOnline,"groupWidthLocal",groupWidthLocal)
        return total + left
    }

    translateXtoLocal(x = 0, lineid = 0){
        const [t,r,b,l] = this.bounding.padding;
        const space = this.bounding.space;
        const margin = this.bounding.margin;
        const lines = [{n:5, space: 1, col:2},{n:7, space: 0,col:0},{n:6, space: 1,col:2},{n:this.hasMar ? 6 : 7, space: 1,col:3},{n:1, space:0,col:0}];
        const {w, h, width, height} = this.offset;
        const con = lines[lineid < 1 ? 4 : (lineid-1)];
        const groupWidthLocal = (w - l - r - con.space * space) / con.n;
        const groupWidthOnline = (width - l - r - con.space * space) / con.n;
        let g = 0, left = 0, add = l;
        for(let i = 0; i < con.n; i++){
            const right = add + groupWidthOnline;
            if((x >= add && x <= right) || x < add){
                g = i;
                const center = add + groupWidthOnline / 2;
                left = x - center;
                break;
            }else{
                add += groupWidthOnline + (con.space && i===con.col ? space : 0);
            }
        }
        // let total = l;
        // const groupWidthOnline = (width - l - r - con.space * space) / con.n;
        let total = groupWidthLocal  * (g + 0.5) + l + (con.space && g > con.col ? space : 0)

        // for(let i = 0; i <= g; i++){
        //     if(i < g){
        //         total += groupWidthLocal + (con.space && i===con.col ? space : 0);
        //     }else{
        //         total += groupWidthLocal / 2;
        //     }
        // }
        // console.warn(g,lineid,x,groupWidthLocal,left,add,groupWidthOnline,total,total + left);
        // return Math.round(total + left * groupWidthLocal / groupWidthOnline);
        console.warn('to',x,'left',left,'total',total,'g',g,con,'groupWidthOnline',groupWidthOnline,'groupWidthLocal',groupWidthLocal)
        return total + left
    }

    /**
     * 创建染色体图形
     */
    createChromoImages() {
        const chromos = [];
        let total = 0, count = 0;
        let zindex = 1;
        //x轴偏移量
        const TRANS_X = this.offset.TRANS_X;
        //y轴偏移量
        const TRANS_Y = this.offset.TRANS_Y;
        Object.keys(this.group).forEach(g => {
            //如果当前组号为25且不显示mar组 则不进行计算
            if (parseInt(g) === 25 && !this.hasMar) {
                return
            }
            const list = this.group[g].list;
            const bottom = this.groupBounding[g].y + this.groupBounding[g].height;
            const length = list.length;
            if (length < 1) {
                this.groupBounding[g].totalWidth = 0;
                return;
            }
            //图片总宽度
            let totalWidth = 0;
            //间隔
            let between = this.bounding.between;
            //该组内所有图片宽度集合
            const widtharr = list.map((a) => { return a.image ? a.image.width * this.chromoScale : 0; });
            //计算该组内所有图片总宽度
            totalWidth = widtharr.reduce((a, b) => a + b);
            this.groupBounding[g].totalWidth = totalWidth;
            const gWidth = this.groupBounding[g].width;

            //如果图片总宽度 小于 组宽度
            if (totalWidth < gWidth && length === 1) {
                between = 0;
            }
            if (totalWidth < gWidth && length > 1) {
                between = Math.min(between, (gWidth - totalWidth) / (length - 1));
            }
            if (totalWidth >= gWidth && length === 1) {
                between = 0;
            }
            if (totalWidth >= gWidth && length > 1) {
                between = Math.min(between, (gWidth - totalWidth) / (length - 1));
            }
            // console.warn(`第${g}组，染色体数量为${length}，间隔为${between}`)
            //计算组内第一个染色体的x值
            const left = this.groupBounding[g].x + (this.groupBounding[g].width - totalWidth - (length - 1) * between) / 2;
            let addx = 0;
            list.forEach((item, i) => {
                if(!item.image) return
                const imgwidth = this.chromoScale * item.image.width;
                const imgheight = this.chromoScale * item.image.height;
                const yoffsetInGroup = item.chromo.yoffsetInGroup || 0;
                // const _y = bottom - imgheight + yoffsetInGroup;
                const y = Math.max(this.groupBounding[g].y, Math.min(bottom - imgheight, bottom - imgheight - yoffsetInGroup));
                const yoffset = Math.min(0, Math.max(imgheight - this.groupBounding[g].height, yoffsetInGroup));
                // console.log(imgheight, yoffsetInGroup, this.groupBounding[g].y, bottom - imgheight, bottom - imgheight - yoffsetInGroup);
                let x = left + addx;
                addx += imgwidth + between;
                // item.chromo.yoffsetInGroup = yoffset;
                const shape = this.stage.graphs.chromo({
                    image: item.image,
                    src: item.image.src,
                    x: x,
                    y: y,
                    yoffsetInGroup: yoffset,
                    scale: this.chromoScale,
                    zindex: zindex,
                    chromoId: item.chromo.chromoId,
                    chromo_id: item.chromo.id,
                    drag: true,
                    color: g > 25 ? '#aaa' : 'transparent'
                });
                chromos.push(shape);
                item.shape = shape;
                zindex++;
            })

        })
        this.chromoShapeList = chromos;
        return chromos;
    }

    /**
     * 生成核型表达式
     */
    createKaryotypeExpression(){
        if(this.karyoInfo.isPreserSure){
            return;
        }
        let count = this.karyoInfo.singleNum,
            expression = [],
            sex = [];
        Object.keys(this.group).forEach(g=>{
            const length = this.group[g].list.length;
            if(parseInt(g) < 23 && length !== 2){
                expression.push((length < 2 ? '-' : '+') + g);
            }
            if(parseInt(g) > 22 && parseInt(g) < 25){
                const str = new Array(this.group[g].list.length).fill(g == 23?'X':'Y').join("")
                sex.push(str)
            }
        })
        const result = [count, sex.join(""), ...expression].join(",");
        this.karyoInfo.karyotypeExpression = result;
        !this.app.expressionManuallyModify && (this.app.karyotypeExpression = this.karyoInfo.karyotypeExpression);
        // console.log(result);
        return result;
    }

    /**
     * 设置画布父元素宽高
     */
    setCanvasParentSize(w, h) {
        const win_width = this.parentNode.clientWidth; //1208
        const win_height = this.parentNode.clientHeight; // 553
        if(!win_width){
            return this.offset;
        }
        let realWidth = win_width,
            realHeight = win_height;
        const localWidth = w;
        const localHeight = h;
        let drawWidth = localWidth;
        let drawHeight = win_height / win_width * drawWidth;
        let TRANS_X = 0, TRANS_Y = 0;
        const rate = drawWidth/drawHeight;
        if(w/h >= drawWidth/drawHeight){
            //图像宽度与绘制区域宽度相同时，图像高度小于等于绘制区域高度
            //显示结果应为 图片宽度与容器宽度相等 图片垂直居中
            drawWidth = w;
            drawHeight = w / rate;
            TRANS_X = 0;
            TRANS_Y = (drawHeight - h) / 2;
        }else{
            //图像宽度与绘制区域宽度相同时，图像高度大于绘制区域高度
            //显示结果应为 图片高度与容器高度相等 图片水平居中
            drawWidth = h * rate;
            drawHeight = h;
            TRANS_X = (drawWidth - w) / 2;
            TRANS_Y = 0;
        }

        const scale = realWidth / drawWidth;
        const l_height = h / w * localWidth;
        const l_width = w / h * localHeight;
        let LocalWidth = 0,
            LocalHeight = 0;
        if(l_height <= localHeight){
            LocalWidth = localWidth;
            LocalHeight = l_height;
        }else if(l_width <= localWidth){
            LocalWidth = l_width;
            LocalHeight = localHeight;
        }

        this.offset = {
            realWidth,
            realHeight,
            parentWidth: win_width,
            parentHeight: win_height,
            width: drawWidth,
            height: drawHeight,
            scale,
            zoom: drawWidth / realWidth,
            localWidth: LocalWidth,
            localHeight: LocalHeight,
            w,
            h,
            TRANS_X,
            TRANS_Y
        }
    }
    setCanvasParentSize1(w, h) {
        const win_width = this.parentNode.clientWidth;
        const win_height = this.parentNode.clientHeight;
        const scaleHeight = h / w * win_width;
        const scaleWidth = w / h * win_height;
        let realWidth = 0,
            realHeight = 0;
        if (scaleHeight <= win_height) {
            realWidth = win_width;
            realHeight = scaleHeight;
        } else if (scaleWidth <= win_width) {
            realWidth = scaleWidth;
            realHeight = win_height;
        }
        const scale = realWidth / w;
        this.offset = {
            realWidth,
            realHeight,
            parentWidth: win_width,
            parentHeight: win_height,
            width: w,
            height: h,
            scale,
            zoom: w / realWidth,
            TRANS_X : 0,
            TRANS_Y : 0
        }
    }

    /**
     * 设置染色体缩放比
     * @param {Object} scale
     */
    scaleChromos(scale) {
        const _scale = Math.min(Math.max(scale, this.minChromoScale), this.maxChromoScale);
        // console.log(scale, _scale, this.chromoScale);
        if (_scale != this.chromoScale) {
            this.chromoScale = _scale;
            this.reCreateChromoImages();
        }
    }

    /**
     * 重新生成染色体
     */
    reCreateChromoImages(){
        this.stage.removeChild(this.chromoShapeList);
        this.stage.removeChild(this.standardChromos);
        this.createChromoImages();
        this.stage.addChild(this.chromoShapeList);
        if(this.standardInfo){
            this.standardChromos = [];
            this.toggleStandard(this.standardInfo, this.standardImage, this.standardVisible);
        }
        if(this.app.choosedChromo){
            const shape = this.getShapeByChromo(this.app.choosedChromo);
            shape && shape.color && (shape.color = "#f00");
            // console.warn(shape)
        }
        this.stage.draw();
    }

    /**
     * 窗口发生变化时 重置舞台大小
     */
    resetStage() {
        this.setCanvasParentSize(this.width, this.height);
        if (this.stage) {
            this.stage.offset = Object.assign({}, this.offset)
            this.stage.realWidth = this.offset.realWidth;
            this.stage.realHeight = this.offset.realHeight;
            this.stage.setCanvasSize(this.stage.scale);
        }
    }

    /**
     * 显示切换标准染色体
     * @param {Object} info
     * @param {Object} img
     * @param {Object} visible
     */
    toggleStandard(info, img, visible) {
        // console.log(this.chromoScale);
        !this.standardInfo && (this.standardInfo = info);
        !this.standardImage && (this.standardImage = img);
        !this.standardVisible && (this.standardVisible = visible)
        this.standardVisible = visible;
        
        this.stage.objects.forEach(shape=>{
            if(shape.realType === 'banding'){
                shape.hide = visible
            }
        })

        if (info && this.standardInfo.type === info.type && this.standardChromos.length) {
            this.standardChromos.forEach(item => {
                item.hide = !visible;
            })
            this.stage.draw();
        } else if(this.heightList) {
            this.standardInfo = info;
            this.standardImage = img;
            this.stage.removeChild(this.standardChromos)
            this.standardChromos = [];
            const position = this.standardInfo.position;
            if(!position){
                return
            }
            const padding = this.standardInfo.padding;
            let left = 0;
            // const arrHeight = this.heightList.filter(a=>a.id==1).map(a=>a.height);
            const arrSort = this.heightList.sort((a,b)=>a.id-b.id);
            const id = arrSort[0] && arrSort[0].id;
            if(id > 24 || !id){
                return;
            }
            const arrHeight = arrSort.filter(a=>a.id==id).map(a=>a.height);
            const maxHeight = Math.max.apply(null,arrHeight);
            const _id = id > 9 ? id : ('0'+id);
            const standard = position.filter(item => item.name === `Chrom${_id}ISCN09`)[0].element[0].lxly;
            let scale = maxHeight / standard[1];
            // console.warn('scale',scale);
            Object.keys(this.groupBounding).forEach(key => {
                if (key < 25) {
                    const no = key < 10 ? ("0" + key) : (key < 23 ? key : (key == 23 ? "X" : "Y"))
                    const item = position.filter(item => item.name === `Chrom${no}ISCN09`)[0];
                    // console.log(no, item);
                    const [w, h] = item.element[0].lxly;
                    const bound = this.groupBounding[key];
                    let row = Object.keys(this.groupRowRange).filter(row => {
                        const obj = this.groupRowRange[row];
                        const g = parseInt(key);
                        return g >= obj.min && g <= obj.max;
                    })[0];
                    row = parseInt(row);

                    const _width = this.bounding.margin / this.maxChromoScale * this.chromoScale;
                    // const _height = this.rowsHeight[row - 1] - this.bounding.textHeight;
                    const _height = this.rowsHeight[row - 1];
                    // console.log(_height);
                    const $height = Math.max.apply(null,this.group[key].list.map(a=>a.image && a.image.height)) || h;
                    const shape = this.stage.graphs.standard({
                        width: _width,
                        // height: _height,
                        height : $height,
                        x: bound.x + bound.width - this.bounding.margin,
                        // y: bound.y + bound.height - _height,
                        y: bound.y + bound.height,
                        scale: this.chromoScale,
                        maxScale: this.maxChromoScale,
                        dx: left,
                        dy: 0,
                        dw: w,
                        dh: h,
                        no: no,
                        image: img,
                        hide: !visible,
                        realType: 'standard',
                        zindex: 200
                    })
                    left += w + padding;
                    this.standardChromos.push(shape);
                }
            })
            this.stage.addChild(this.standardChromos)
            this.stage.draw();
        }
    }
    
    /**
     * 创建定带
     */
    createStandChromo(){
        
        this.bandingMap = {};
        if(typeof this.banding === 'string') this.banding = JSON.parse(this.banding);
        const image = this.app.standardPics[this.banding.standchromoModal];
        const standardInfo = require(`./json/standard-${this.banding.standchromoModal}`).standard;
        this.standardInfo = standardInfo;
        const padding = standardInfo.padding;
        // console.log(standardInfo);
        return this.banding.standchromoInfo.filter(item=>{
            !item.key && (item.key = [])
            return this.groupBounding[item.ChromoID].totalWidth > 0;
        }).map(item=>{
            let list = this.group[item.ChromoID].list, img;
            let left = 0, dx = 0;
            const key = item.ChromoID;
            const no = key < 10 ? ("0" + key) : (key < 23 ? key : (key == 23 ? "X" : "Y"))
            for(let i = 1; i <= key; i++){
                const _no = i < 10 ? ("0" + i) : (i < 23 ? i : (i == 23 ? "X" : "Y"))
                const _obj = standardInfo.position.filter(item => item.name === `Chrom${_no}ISCN09`)[0];
                const [_w, _h] = _obj.element[0].lxly;
                // console.warn(_w);
                // dx += parseInt(_w) + parseInt(padding);
                dx += i > 1 ? (_w + padding) : 0;
            }
            // console.log(key, no, dx);
            const width = this.bounding.margin / this.maxChromoScale * this.chromoScale;
            const bound = this.groupBounding[key];
            const obj = standardInfo.banding.filter(item => item.name === `Chrom${no}ISCN09`)[0];
            const position = standardInfo.position.filter(item => item.name === `Chrom${no}ISCN09`)[0];
            const [w, h] = position.element[0].lxly;
            const l = (bound.width - bound.totalWidth) / 2;
            if(item.IsRight){
                img = list[list.length-1].image;
                left = bound.width + bound.x - l + this.bounding.between;
            }else{
                img = list[0].image;
                left = bound.x + l - width - this.bounding.between;
            }
            const height = img.height;
            const guid = this.getGuid();
            let keys = [];
            if(item.key){
                // console.log(obj,no);
                keys = item.key.map(_key=>{
                    const data = obj.element.filter(o=>{
                        // console.log(o,_key.Name,o.name === _key.Name);
                        return o.name === _key.Name
                    })[0];
                    if(data){
                        const sy = data.lxly[1]
                        const ey = data.rxry[1]
                        return {
                            y : sy + (ey - sy) / 2,
                            name : _key.Name,
                        }
                    }
                }).filter(v=>!!v)
            }
            const shape = stage.graphs.banding({
                width,
                height,
                image,
                x : left,
                y : bound.y + bound.height,
                dx: dx - 5,
                dy: 0,
                dw: w + 7,
                dh: h,
                no: no,
                keys: keys,
                scale: this.chromoScale,
                realType: 'banding',
                zindex: 200,
                id : guid,
                isRight : item.IsRight,
                hide : this.standardVisible
            })
            this.bandingMap[guid] = {
                shape, item
            }
            return shape;
        })
    }

    reCreateStandChromo(){
        // if(this.standardVisible){
        //     return;
        // }
        var list = Object.keys(this.bandingMap).map(key=>{
            return this.bandingMap[key].shape
        })
        this.stage.removeChild(list);
        const standChromos = this.createStandChromo();
        this.stage.addChild(standChromos);

        this.stage.draw();
    }

    /**
     * 从缓存中读取图片
     * @param {Object} id
     * @param {Object} src
     */
    getBase64FromEntries(id, src) {
        if (!src || src.indexOf("base64,") > -1 || !id || !this.entries) {
            return src
        }
        const _name = src.split('?')[0].split('/').pop();
        const key = ["karyo", id, _name].join("_");
        return this.entries[key] || src;
    }

    /**
     * 导出图片base64
     */
    exportBase64({ isHideXY, format, zoom}) {
        return new Promise((resolve, reject) => {
			let timer = setInterval(()=>{
				if(this.initFinished){
					clearInterval(timer);
					timer = null;
					const tx = this.stage.transX;
					const ty = this.stage.transY;
					const scale = this.stage.scale;
					this.stage.setScale(1, 0, 0);
					this.stage.draw();
					this.stage.objects.forEach(shape => {
					    if (shape.realType === 'chromo') {
					        shape._color = shape.color;
					        shape.color = "transparent";
					        if (isHideXY && shape.chromoId > 22 && shape.chromoId <= 25) {
					            shape.hide = true;
					        }
					        if (shape.chromoId > 25) {
					            shape.hide = true;
					        }
					    }
					    if (shape.realType === 'standard' && this.standardVisible) {
					        shape.hide = true;
					    }
					})
					this.stage.draw();
                    const bound = this.bounding;
					const imageData = this.stage.canvas.getImageData(bound.padding[3], bound.padding[0], this.stage.width, this.stage.height);
					// const height = this.height * (1 - this.bounding.padding[2] - this.bounding.textHeight * 2);
                    // const height = this.stage.height - bound.textHeight * 2 - bound.lineWidth + bound.padding[0];
                    // const height = this.stage.height - bound.padding[0] - bound.padding[0]
                    const height = this.stage.height - bound.padding[0] - this.rowsHeight[4] + bound.textHeight;
					const canvas = document.createElement('canvas');
					canvas.width = this.stage.width - bound.padding[3];
					canvas.height = height;
					const ctx = canvas.getContext('2d');
                    ctx.putImageData(imageData, 0, 0);
                    // console.log();
					const base64 = canvas.toDataURL('image/'+(format || "png"), 1);
					this.stage.objects.forEach(shape => {
					    if (shape.realType === 'chromo') {
					        shape.color = shape._color;
					        delete shape._color;
					        shape.hide = false;
					    }
					    if (shape.realType === 'standard' && this.standardVisible) {
					        shape.hide = false;
					    }
					})
					this.stage.setScale(scale, 0, 0);
					this.stage.transX = tx;
					this.stage.transY = ty;
                    this.stage.draw();
                    if(zoom){
                        const image = new Image();
                        image.onload = ()=>{
                            let $canvas = document.createElement('canvas');
                            let $context = $canvas.getContext('2d');
                            $canvas.width = canvas.width * zoom;
                            $canvas.height = canvas.height * zoom;
                            $context.drawImage(image,0,0,canvas.width,canvas.height, 0,0,$canvas.width,$canvas.height);
                            const $base64 = $canvas.toDataURL('image/'+(format || "png"), 0.9);
                            resolve($base64);
                        }
                        image.src = base64;
                    }else{
                        resolve(canvas.toDataURL('image/'+(format || "png"), 0.9));
                    }
				}
			},20)
            
        })
    }

    /**
     * 根据图形获取源数据
     */
    getChromoByShape(shape) {
        let chromo;
        Object.keys(this.group).forEach(key => {
            const group = this.group[key];
            group.list.forEach(item => {
                if (item.shape === shape) {
                    chromo = item.chromo;
                }
            })
        })
        return chromo
    }

    /**
     * 根据chromo源数据获取图形
     */
    getShapeByChromo(chromo) {
        let shape;
        Object.keys(this.group).forEach(key => {
            const group = this.group[key];
            group.list.forEach(item => {
                if (item.chromo === chromo) {
                    shape = item.shape;
                }
            })
        })
        return shape
    }
}
export default ArrangeCanvasController
