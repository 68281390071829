<template>
    <div class="mark-line">
        <div
            v-for="line in lines"
            :key="line"
            class="line"
            :class="line.includes('x') ? 'xline' : 'yline'"
            :ref="line"
            v-show="lineStatus[line] || false"
        ></div>
    </div>
</template>

<script>
import eventBus from "../config/eventBus";
export default {
    data() {
        return {
            lines: ["xt", "xc", "xb", "yl", "yc", "yr"], // 分别对应三条横线和三条竖线
            diff: 3, // 相距 dff 像素将自动吸附
            lineStatus: {
                xt: false,
                xc: false,
                xb: false,
                yl: false,
                yc: false,
                yr: false,
            },
            editor: null,
        };
    },
    mounted() {
        this.editor = document.querySelector("#list");
        // 监听元素移动和不移动的事件
        eventBus.$on("move", (dragNode, isDownward, isRightward) => {
            let isUpdateHeight = true;
            this.showLine(
                dragNode,
                isDownward,
                isRightward,
                "drag",
                isUpdateHeight
            );
        });
        eventBus.$on("resize", (dragNode, isDownward, isRightward) => {
            let isUpdateHeight = false;
            this.showLine(
                dragNode,
                isDownward,
                isRightward,
                "resize",
                isUpdateHeight
            );
        });
        eventBus.$on("unmove", () => {
            this.hideLine();
        });
    },
    methods: {
        hideLine() {
            Object.keys(this.lineStatus).forEach((line) => {
                this.lineStatus[line] = false;
            });
        },
        showLine(dragNode, isDownward, isRightward, action, isUpdateHeight) {
            const lines = this.$refs;
            const components = document.querySelectorAll(".vdr");
            const dragNodeRectInfo = this.getNodeRelativePosition(dragNode);
            const dragNodeHalfwidth = dragNodeRectInfo.width / 2;
            const dragNodeHalfHeight = dragNodeRectInfo.height / 2;
            if (isUpdateHeight) {
                sessionStorage.setItem(
                    "primaryRect",
                    JSON.stringify(dragNodeRectInfo)
                );
            }

            const primaryRectInfo = JSON.parse(
                sessionStorage.getItem("primaryRect")
            );

            this.hideLine();
            components.forEach((node) => {
                if (node == dragNode) return;
                const {
                    top,
                    height,
                    bottom,
                    left,
                    width,
                    right,
                } = this.getNodeRelativePosition(node);

                const nodeHalfwidth = width / 2;
                const nodeHalfHeight = height / 2;
                const conditions = {
                    top: [
                        //向上移动 顶部那条线
                        {
                            isNearly: this.isNearly(dragNodeRectInfo.top, top),
                            lineNode: lines.xt[0], // xt
                            line: "xt",
                            dragShift: top,
                            lineShift: top,
                            directiveVal: top,
                            isPorM:
                                dragNodeRectInfo.height +
                                dragNodeRectInfo.top -
                                top,
                        },
                        //向下移动 底部那条线
                        {
                            isNearly: this.isNearly(
                                dragNodeRectInfo.bottom,
                                top
                            ),
                            lineNode: lines.xt[0], // xt
                            line: "xt",
                            dragShift: top - dragNodeRectInfo.height,
                            lineShift: top,
                            directiveVal:
                                top -
                                dragNodeRectInfo.height -
                                (top - dragNodeRectInfo.bottom),
                            isPorM:
                                dragNodeRectInfo.height +
                                top -
                                dragNodeRectInfo.bottom,
                        },
                        //中间那条线
                        {
                            // 组件与拖拽节点的中间是否对齐
                            isNearly: this.isNearly(
                                dragNodeRectInfo.top + dragNodeHalfHeight,
                                top + nodeHalfHeight
                            ),
                            lineNode: lines.xc[0], // xc
                            line: "xc",
                            dragShift:
                                top + nodeHalfHeight - dragNodeHalfHeight,
                            lineShift: top + nodeHalfHeight,
                            directiveVal:
                                top + nodeHalfHeight - dragNodeHalfHeight,
                            isPorM:
                                dragNodeRectInfo.height +
                                dragNodeRectInfo.top +
                                dragNodeHalfHeight -
                                (top + nodeHalfHeight),
                        },
                        // 向上移动 顶部那条线
                        {
                            isNearly: this.isNearly(
                                dragNodeRectInfo.top,
                                bottom
                            ),
                            lineNode: lines.xb[0], // xb
                            line: "xb",
                            dragShift: bottom,
                            lineShift: bottom,
                            directiveVal: bottom,
                            isPorM:
                                dragNodeRectInfo.height +
                                bottom -
                                dragNodeRectInfo.top,
                        },
                        //向下移动 底部那条线
                        {
                            isNearly: this.isNearly(
                                dragNodeRectInfo.bottom,
                                bottom
                            ),
                            lineNode: lines.xb[0], // xb
                            line: "xb",
                            dragShift: bottom - dragNodeRectInfo.height,
                            lineShift: bottom,
                            directiveVal:
                                bottom -
                                dragNodeRectInfo.height -
                                (bottom - dragNodeRectInfo.bottom),
                            isPorM:
                                dragNodeRectInfo.height +
                                bottom -
                                dragNodeRectInfo.bottom,
                        },
                    ],
                    left: [
                        //向左移动 靠近左边那条线
                        {
                            isNearly: this.isNearly(
                                dragNodeRectInfo.left,
                                left
                            ),
                            lineNode: lines.yl[0], // yl
                            line: "yl",
                            dragShift: left,
                            lineShift: left,
                            directiveVal: left,
                            isPorM:
                                dragNodeRectInfo.width +
                                dragNodeRectInfo.left -
                                left,
                        },
                        // 向左移动 靠近右边那条线
                        {
                            isNearly: this.isNearly(
                                dragNodeRectInfo.right,
                                left
                            ),
                            lineNode: lines.yl[0], // yl
                            line: "yl",
                            dragShift: left - dragNodeRectInfo.width,
                            lineShift: left,
                            directiveVal:
                                left -
                                dragNodeRectInfo.width -
                                (left - dragNodeRectInfo.right),
                            isPorM:
                                dragNodeRectInfo.width +
                                left -
                                dragNodeRectInfo.right,
                        },
                        //向左移动 靠近中间那条线
                        {
                            // 组件与拖拽节点的中间是否对齐
                            isNearly: this.isNearly(
                                dragNodeRectInfo.left + dragNodeHalfwidth,
                                left + nodeHalfwidth
                            ),
                            lineNode: lines.yc[0], // yc
                            line: "yc",
                            dragShift: left + nodeHalfwidth - dragNodeHalfwidth,
                            lineShift: left + nodeHalfwidth,
                            directiveVal:
                                left + nodeHalfwidth - dragNodeHalfwidth,
                            isPorM:
                                dragNodeRectInfo.width +
                                dragNodeRectInfo.left +
                                dragNodeHalfwidth -
                                (left + nodeHalfwidth),
                        },
                        // 向右移动 靠近左边那条线
                        {
                            isNearly: this.isNearly(
                                dragNodeRectInfo.left,
                                right
                            ),
                            lineNode: lines.yr[0], // yr
                            line: "yr",
                            dragShift: right,
                            lineShift: right,
                            directiveVal: right,
                            isPorM:
                                dragNodeRectInfo.width +
                                dragNodeRectInfo.left -
                                right,
                        },
                        // 向右移动 靠近右边那条线
                        {
                            isNearly: this.isNearly(
                                dragNodeRectInfo.right,
                                right
                            ),
                            lineNode: lines.yr[0], // yr
                            line: "yr",
                            dragShift: right - dragNodeRectInfo.width,
                            lineShift: right,
                            directiveVal:
                                right -
                                dragNodeRectInfo.width -
                                (right - dragNodeRectInfo.right),
                            isPorM:
                                dragNodeRectInfo.width +
                                right -
                                dragNodeRectInfo.right,
                        },
                    ],
                };

                const needToShow = [];
                Object.keys(conditions).forEach((key) => {
                    // 遍历符合的条件并处理
                    conditions[key].forEach((condition) => {
                        if (!condition.isNearly) return;

                        // 修改当前组件位移
                        if (action == "drag") {
                            let obj = {
                                key: key,
                                value: condition.dragShift,
                            };
                            this.$emit("setShowLine", obj);
                        } else if (action == "resize") {
                            let obj = {
                                key: key,
                                value: {
                                    k: condition.directiveVal,
                                    v: condition.isPorM,
                                },
                            };
                            this.$emit("setResize", obj);
                        }

                        condition.lineNode.style[
                            key
                        ] = `${condition.lineShift}px`;
                        needToShow.push(condition.line);
                    });
                });

                // 同一方向上同时显示三条线可能不太美观，因此才有了这个解决方案
                // 同一方向上的线只显示一条，例如多条横条只显示一条横线
                if (needToShow.length) {
                    this.chooseTheTureLine(needToShow, isDownward, isRightward);
                }
            });
        },
        chooseTheTureLine(needToShow, isDownward, isRightward) {
            // 如果鼠标向右移动 则按从右到左的顺序显示竖线 否则按相反顺序显示
            // 如果鼠标向下移动 则按从下到上的顺序显示横线 否则按相反顺序显示
            if (isRightward) {
                if (needToShow.includes("yr")) {
                    this.lineStatus.yr = true;
                } else if (needToShow.includes("yc")) {
                    this.lineStatus.yc = true;
                } else if (needToShow.includes("yl")) {
                    this.lineStatus.yl = true;
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (needToShow.includes("yl")) {
                    this.lineStatus.yl = true;
                } else if (needToShow.includes("yc")) {
                    this.lineStatus.yc = true;
                } else if (needToShow.includes("yr")) {
                    this.lineStatus.yr = true;
                }
            }

            if (isDownward) {
                if (needToShow.includes("xb")) {
                    this.lineStatus.xb = true;
                } else if (needToShow.includes("xc")) {
                    this.lineStatus.xc = true;
                } else if (needToShow.includes("xt")) {
                    this.lineStatus.xt = true;
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (needToShow.includes("xt")) {
                    this.lineStatus.xt = true;
                } else if (needToShow.includes("xc")) {
                    this.lineStatus.xc = true;
                } else if (needToShow.includes("xb")) {
                    this.lineStatus.xb = true;
                }
            }
        },
        isNearly(dragValue, targetValue) {
            return Math.abs(dragValue - targetValue) <= this.diff;
        },
        // 获取节点相对编辑器的位置
        getNodeRelativePosition(node) {
            let {
                top,
                height,
                bottom,
                left,
                width,
                right,
            } = node.getBoundingClientRect();
            const editorRectInfo = this.editor.getBoundingClientRect();

            left -= editorRectInfo.left;
            top -= editorRectInfo.top;
            right -= editorRectInfo.left;
            bottom -= editorRectInfo.top;

            return { top, height, bottom, left, width, right };
        },
    },
};
</script>

<style lang="scss" scoped>
.mark-line {
    height: 100%;
}
.line {
    background: #59c7f9;
    position: absolute;
    z-index: 1000;
}
.xline {
    width: 100%;
    height: 1px;
}
.yline {
    width: 1px;
    height: 100%;
}
</style>
